import React from 'react';
import { useIntl } from 'react-intl';
import { Select, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import Avatar from 'components/Avatar';
import AplanetIcon from 'components/AplanetIcon';

const { Option } = Select;

const OrganizationLogoLabel = ({
  name,
  logo_small
}) => (
  <>
    <Avatar className="ThirdPartyRequestsHeader__option-logo" size="large" src={logo_small} name={name} />
    <span className="ThirdPartyRequestsHeader__option-name">{name}</span>
  </>
);

const Header = ({
  profile,
  organizations,
  currentTopOrganization,
  onSelectOrganization,
}) => {
  const intl = useIntl();
  return(
    <section className="ThirdPartyRequestsHeader">
      <div className="ThirdPartyRequestsHeader__product-wrapper">
        <span className="ThirdPartyRequestsHeader__product-name">{intl.formatMessage({id: 'product_atlas'})}</span>
      </div>
      <div className='ThirdPartyRequestsHeader__organization-selector-profile-info'>
        {organizations?.length ? (
          <div className='ThirdPartyRequestsHeader__organization-selector-wrapper' id='organization-selector-wrapper'>
            {organizations.length === 1 ? (
              <OrganizationLogoLabel
                name={currentTopOrganization?.name}
                logo_small={currentTopOrganization?.logo_small}
              />
            ) : (
              <Select
                className="ThirdPartyRequestsHeader__organization-selector"
                popupClassName="ThirdPartyRequestsHeader__organization-dropdown"
                value={currentTopOrganization?.slug}
                onChange={onSelectOrganization}
                suffixIcon={<AplanetIcon name='Chevron down' className='ThirdPartyRequestsHeader__selector-arrow' size="18px"/>}
                getPopupContainer={() => document.getElementById('organization-selector-wrapper')}
                bordered={false}
                dropdownMatchSelectWidth={false}
                optionLabelProp='label'
              >
                {organizations.map(({slug, name, logo_small}) => (
                  <Option
                    key={slug}
                    name={name}
                    label={
                      <OrganizationLogoLabel
                        name={name}
                        logo_small={logo_small}
                      />
                    }
                  >
                    <Tooltip title={name} overlayClassName="ThirdPartyRequestsTooltip">
                      <OrganizationLogoLabel
                        name={name}
                        logo_small={logo_small}
                      />
                    </Tooltip>
                  </Option>
                ))}
              </Select>
            )}
          </div>
        ) : null}
        <div className="ThirdPartyRequestsHeader__user-info">
          <Avatar className="ThirdPartyRequestsHeader__user-avatar" size="large" src={profile.avatar} name={profile.name} />
          <span className="ThirdPartyRequestsHeader__user-name">{profile.name}</span>
        </div>
      </div>
    </section>
  );
}

Header.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    avatar: PropTypes.string,
  }).isRequired,
  organizations: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    logo_small: PropTypes.string,
    logo: PropTypes.string,
  })),
  currentTopOrganization: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    logo_small: PropTypes.string,
    logo: PropTypes.string,
  }),
  onSelectOrganization: PropTypes.func.isRequired,
};

export default Header;
