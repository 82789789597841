const TARGET = 'kpi_history';

const isTarget = (target) => [TARGET].includes(target);
//const isGeneralTarget = (target) => target === TARGET;

const initialState = {
  count: 0,
  data: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;
      return {
        count: (action.response.result || {}).count || state.count,
        data: (action.response.endpoint || '').indexOf('page=0') >= 0
          ? (action.response.result || {}).data || []
          : state.data.concat(
            (action.response.result || {}).data || []
          ),
        loading: false,
        error: null,
      };
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        count: 0,
        data: [],
        loading: false,
        error: action.code,
      };
    case 'RESET_DATA':
      if(!isTarget(action.target)) return state;
      return {
        ...state,
        count: 0,
        data: [],
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as kpi_history,
};
