import React, { useState, useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { IntlProvider } from 'react-intl-redux';
import { Route, Switch } from 'react-router-dom';
import { notification } from 'antd';
import { omit } from 'lodash';

import { history } from './store';

import { Root } from 'containers/Root';
import BackErrorNotification from 'components/BackErrorNotification';
import WebSocketProvider from 'components/WebsocketContext';

import config from 'config';

import { saveState, loadState } from 'store/persistence';

import { Loading } from 'tsComponents/emptyStates/Loading';

import { hydrateStore } from 'actions/store';
import { requestIdentity } from 'actions/auth';
import {
  patchStateWithTopLevelOrg,
} from 'utils/topLevelOrgInUrl';

import {
  requestProfile,
  requestTaxonomies,
  requestOrganizationTree,
} from 'actions/api';
import { SCREENS } from 'hooks/useScreenFilters/constants';

const MainApp = () =>{ 
    
    const [, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();

    const {
        auth,
        organization,
        screen_filters, // NOTICE: For saveState, though I'd rather have this somewhere else...
        table_custom_view,
        organization_tree,
        profile
      } = useSelector(state => state);
    
      const [hydrated, setHydrated] = useState(false);
      const [refreshStarted, setRefreshStarted] = useState(false);
      const [requested, setRequested] = useState(false);
    
      // Save state upon auth/org change. Don't save third_party_requests filters
      useEffect(() => {
        saveState({
          organization_slug: organization.slug,
          screen_filters: omit(screen_filters, SCREENS.third_party_requests),
          table_custom_view,
        });
      }, [
        organization.slug,
        screen_filters,
        table_custom_view,
      ]);
    
      // Load state when loading the app
      useEffect(() => {
        loadState()
          .then(state => patchStateWithTopLevelOrg(state))
          .then(state => dispatch( hydrateStore(state) ))
          .then(() => setHydrated(true));
      }, [ dispatch ]);
    
      // Load state when loading the app
      useEffect(() => {
        if(hydrated && !refreshStarted) {
          dispatch(requestIdentity());
          setRefreshStarted(true);
        }
      }, [
        hydrated,
        dispatch,
        refreshStarted,
      ]);
    
      // Request the profile upon login / org changes
      useEffect(() => {
        if(auth.logged_in && organization.data && !requested) {
          setRequested(true);
          dispatch(requestProfile(organization.data.slug));
          dispatch(requestTaxonomies(organization.data.slug));
          dispatch(requestOrganizationTree(organization.data.slug));
        }
        if(auth.logged_in && !organization.data && requested) {
          setRequested(false);
        }
      }, [
        auth.logged_in,
        organization.data,
        requested,
        dispatch,
      ]);
    
      // Append Usetiful script and Segment users by language
      useEffect(() => {
        if ( config.USETIFUL_TOKEN && profile.data && profile.data.language ){
        const headHtml = document.getElementsByTagName('head')[0];
        const usetifulScript = document.createElement('script');
    
          window.usetifulTags = {
            lang: String(profile?.data?.language || profile?.data?.user_language || null)
          };
        usetifulScript.setAttribute('id','usetifulScript');
        usetifulScript.src = "https://www.usetiful.com/dist/usetiful.js";
        usetifulScript.dataset.token = config.USETIFUL_TOKEN;
        usetifulScript.async = true;
        
        headHtml.appendChild(usetifulScript);
        return () => {
          headHtml.removeChild(usetifulScript);
        }
      }
      }, [
          profile.data
      ]);
    
      const isReady = hydrated && (
        refreshStarted
        && (auth.logged_in || !auth.refreshing_token)
        && (!auth.logged_in || !organization.data || (requested && !organization_tree.fetching))
      );

    // NOTICE: return null until profile.data.language exists to avoid loading twice (once before having the profile language and again with the language) 
    if (!isReady && !profile?.data?.language) return null;

    return (
      <WebSocketProvider> 
        <IntlProvider>
          {
            isReady 
              ? (
                <>
                  {contextHolder}
                  <BackErrorNotification /> 
                  <ConnectedRouter history={history}>
                      <Switch>
                          <Route path="/" component={Root} />
                      </Switch>
                  </ConnectedRouter>
                </>
              ) : <Loading />
          }
        </IntlProvider>
      </WebSocketProvider>
    )
    
};

export default MainApp;
