import React from 'react';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'antd';
import './style.less';

const HiddenLogo = ({
  intl,
}) => {
  const t = intl.messages;
  return (
    <Tooltip
      title={t.hidden_logo_tooltip}
    >
      <img
        src={`/images/assets/icon-hidden.svg`}
        alt={t.hidden_logo_tooltip}
      />
    </Tooltip>
  );
}

export default injectIntl(HiddenLogo);
