/* eslint-disable communist-spelling/communist-spelling */
import React, { Fragment, useEffect, useMemo, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { formatDate, DATE_ONLY } from 'utils/date';

import useOrganizations from 'utils/useOrganizations';
import MainLayout from 'components/MainLayout';
import CustomTable from 'components/CustomTable';
import AddAnalysisModal from './AddAnalysisModal';
import { DeleteOutlined } from '@ant-design/icons';
import CustomButton from 'components/CustomButton';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';

import {
  Popconfirm,
  Button,
  Row,
} from 'antd';

import {
  requestAnalyses,
  createAnalysis,
  deleteAnalysis,
} from '../../actions/api';

import './style.less';

const Materiality = ({
  intl,
  requestAnalyses,
  createAnalysis,
  deleteAnalysis,
  analyses,
  history,
}) => {
  const t = intl.messages;
  const { organization, suborganization } = useOrganizations();
  const [showNewAnalysisModal, setShowNewAnalysisModal] = useState(false);
  const [analysisToClone, setAnalysisToClone] = useState('');

  const {
    slug: mainOrgSlug,
  } = organization;
  const {
    slug: subOrgSlug,
  } = suborganization;

  useEffect(() => {
    requestAnalyses(mainOrgSlug, subOrgSlug);
  }, [mainOrgSlug, subOrgSlug, requestAnalyses]);

  const onCreateAnalysis = useCallback(
    ({ name, start_date, end_date, method }) => {
      createAnalysis(mainOrgSlug, subOrgSlug, {
        name,
        start_date,
        end_date,
        method,
        use_analysis: analysisToClone,
      });
    },
    [createAnalysis, mainOrgSlug, subOrgSlug, analysisToClone]
  );

  const handleCreateAnalysisModal = (analysis_id) => {
    if (analysis_id) {
      setAnalysisToClone(analysis_id);
    }
    setShowNewAnalysisModal(true);
  };

  const columns = useMemo(
    () => [
      {
        title: t.name,
        dataIndex: 'name',
        render: (text) => text,
      },
      {
        title: t.start_date,
        dataIndex: 'start_date',
        render: (text) => text && formatDate(text, DATE_ONLY, intl)
      },
      {
        title: t.end_date,
        dataIndex: 'end_date',
        render: (text) => text && formatDate(text, DATE_ONLY, intl)
      },
      {
        title: t.created_on,
        dataIndex: 'created_at',
        render: (text) => formatDate(text, DATE_ONLY, intl),
      },
      {
        title: t.last_update,
        dataIndex: 'updated_at',
        render: (text) => formatDate(text, DATE_ONLY, intl),
      },
      {
        title: t.status,
        dataIndex: 'status',
        render: (text) => t[text],
      },
      {
        title: t.action,
        dataIndex: 'action',
        width: 150,
        render: (_, record) => (
          <div className="Materiality-actions">
            <Link
              to={`/analysis/${record.id}/`}
              className={`CustomButton ant-btn-primary${record.status === 'completed' ? ' completed' : ''}`}
            >
              {record.status === 'completed' ? t.view : t.continue}
            </Link>
            <Button
              className="CustomButton ant-btn-primary"
              onClick={() => handleCreateAnalysisModal(record.id)}
            >
              {t.materiality_use_as_base}
            </Button>
            <Popconfirm
              title={t.analysis_delete_confirm}
              onConfirm={() => deleteAnalysis(mainOrgSlug, subOrgSlug, record.id)}
              okText={t.yes}
              cancelText={t.no}
            >
              <CustomButton
                icon={<DeleteOutlined />}
                type="danger"
              >
                { t.analysis_delete }
              </CustomButton>
            </Popconfirm>
          </div>
        )
      }
    ],
    [t, intl, deleteAnalysis, mainOrgSlug, subOrgSlug]
  );

  return (
    <Fragment>
      <MainLayout>
        <Sentry.ErrorBoundary
          fallback={
          <ErrorBoundaryFallback
            titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
            buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
            descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_materiality_message' })}
            customErrorImage="/images/error_image.png"
          />
        }>
          <div className="Materiality">
            <Row
              className="Materiality-header"
              type="flex"
              justify="space-between"
              align="bottom"
            >
              <span className="title">
                {t.materiality}
              </span>
              <Button
                className="CustomButton ant-btn-primary"
                onClick={() => handleCreateAnalysisModal()}
              >
                {t.start_new}
              </Button>
            </Row>
            <CustomTable
              columns={columns}
              dataSource={analyses}
              rowKey='id'
            />
            <AddAnalysisModal
              visible={showNewAnalysisModal}
              onCreate={onCreateAnalysis}
              onClose={() => setShowNewAnalysisModal(false)}
            />
          </div>
        </Sentry.ErrorBoundary>
      </MainLayout>
    </Fragment>
  )
};

const mapStateToProps = ({
  analysis,
}) => ({
  analyses: analysis.data,
  error: analysis.error,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      requestAnalyses,
      createAnalysis,
      deleteAnalysis,
    }
  )(Materiality)
);
