import React, {
    useMemo,
    useState,
    useCallback
} from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
  
import CustomSelect from 'components/CustomSelect';


const LanguagesDropdown = ({
    intl,
    organization,
    onChange,
    defaultValue = null
}) => {
    const [selectedLocale, setSelectedLocale] = useState(defaultValue);

    const preferred_languages = useMemo(() => organization?.languages || [], [organization.languages]);

    const languages = useMemo(
        () => preferred_languages.map(value => ({ slug: value, name: intl.formatMessage({id: value}) })),
        [preferred_languages, intl]
    );

    const handleOnChange = useCallback((value) => {
        setSelectedLocale(value);
        onChange(value);
    }, [onChange]);
  
    return (
        <CustomSelect
            options={languages}
            onSelect={handleOnChange}
            selected = {selectedLocale}
        />
    );
};

export default injectIntl(
    withRouter(
      LanguagesDropdown
    )
);
