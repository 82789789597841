import React, {
  useEffect,
  useMemo,
  //useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { groupBy } from 'lodash'

import {
  makeSearchString,
  makeSearchStrings,
} from 'utils/strings';

import useOrganizations from 'utils/useOrganizations';

import {
  requestFilesForKpi,
} from 'actions/api';

import KpiDetailFilesFilters from 'components/KpiDetailFilesFilters';
// import FilesFilters from 'components/FilesFilters';
import FilesCard from 'components/FilesCard'
// import T from 'components/T'
import { Loading } from 'tsComponents/emptyStates/Loading';


import useScreenFilters from 'hooks/useScreenFilters';
import { SCREENS } from 'hooks/useScreenFilters/constants';

import {
  formatDate,
  DATE_ONLY,
  SHORT_FORMAT_WITH_YEAR,
  fromDb,
  isBetween,
  totalCalendarDays,
  rangeOverlap
} from 'utils/date';

import {
  // Row,
  // Col,
  Timeline
} from 'antd';

import { Empty } from 'tsComponents/emptyStates/Empty';
import { getEmptyResultsProps, emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';
import { checkByFileTypes } from 'utils/validation';

const Files = ({
  intl,
  kpi_slug,
  location,
}) => {
  const t = intl.messages;

  const dispatch = useDispatch();
  // const [searchTerm, setSearchTerm] = useState('');

  const {
    organization,
    suborganization,
  } = useOrganizations();

  //Same order as defined in useScreenFilters/constants.js
  const {
    values: [
      [filteredUser, userFilterSet],
      [filteredUploadDate, uploadDateFilterSet],
      [filteredPeriodDate, periodDateFilterSet],
      [filteredPeriodicity, periodicityFilterSet],
      [filteredAttachment, attachmentFilterSet],
      [textFilter, textFilterSet], // eslint-disable-line no-unused-vars
    ],
    handleFilter,
    filterState,
    isFilterSet, // eslint-disable-line no-unused-vars
    resetAllFilters
  } = useScreenFilters({
    screen: SCREENS.kpidetail_files
  });

  const areFiltersSet = useMemo(
    () => userFilterSet || uploadDateFilterSet || periodDateFilterSet || periodicityFilterSet || attachmentFilterSet,
    [
      userFilterSet,
      uploadDateFilterSet,
      periodDateFilterSet,
      periodicityFilterSet,
      attachmentFilterSet
    ]
  );

  const {
    data,
    loading,
    //error,
  } = useSelector(state => state.kpi_files);

  useEffect(() => {
    dispatch(
      requestFilesForKpi(
        organization.slug,
        suborganization.slug,
        kpi_slug,
      )
    )
  }, [
    dispatch,
    organization,
    suborganization,
    kpi_slug,
  ]);

  const processed = useMemo(() => {

    const sorted = (data || [])
      .filter(datum => {

        if (!areFiltersSet && !textFilterSet) {
          return true;
        }
        let resultTextFilter = true,
          resultUserFilter = true,
          resultUploadDateFilter = true,
          resultPeriodDateFilter = true,
          resultPeriodicityFilter = true,
          resultAttachmentFilter = true;

        if (textFilterSet) {
          const searchStrings = makeSearchStrings(textFilter);
          const searchableKpi = makeSearchString(datum.kpi_name);
          const searchableMember = makeSearchString(datum.member_name);
          const searchableFile = makeSearchString(
            (datum.activity_params || {}).filename || ''
          );
          resultTextFilter = searchStrings.every(
            (searchString) => searchableKpi.includes(searchString) ||
              searchableMember.includes(searchString) ||
              searchableFile.includes(searchString)
          );
        }

        if (userFilterSet) {
          resultUserFilter = filteredUser.includes(datum.member_slug);
        }

        if (uploadDateFilterSet) {
          resultUploadDateFilter = isBetween(datum.created_at, filteredUploadDate);
        }

        if (periodDateFilterSet) {
          resultPeriodDateFilter = rangeOverlap([datum.period_start, datum.period_end], filteredPeriodDate);
        }

        if (periodicityFilterSet) {
          resultPeriodicityFilter = filteredPeriodicity.includes(datum.collection_frequency);
        }

        if (attachmentFilterSet) {
          let fileExtension = datum.filename.split('.').pop();
          resultAttachmentFilter = checkByFileTypes(fileExtension, filteredAttachment);
        }

        return resultTextFilter && resultUserFilter && resultUploadDateFilter && resultPeriodDateFilter && resultPeriodicityFilter && resultAttachmentFilter;

      })
      .map(datum => ({
        ...datum,
        date: formatDate(
          datum.created_at,
          DATE_ONLY,
          intl,
        ),
      }))
      .sort((a, b) => {
        const dateA = fromDb(a.created_at);
        const dateB = fromDb(b.created_at);
        return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
      });

    const dateMap = new Map();
    sorted.forEach(datum => {
      const {
        date,
        member_slug,
      } = datum;

      if (!dateMap.has(date)) {
        dateMap.set(date, new Map());
      }

      const thisDateMap = dateMap.get(date);

      if (!thisDateMap.has(member_slug)) {
        thisDateMap.set(member_slug, []);
      }

      thisDateMap.get(member_slug).push(datum);
    });

    // NOTICE: weird format we got there...
    return Array
      .from(dateMap.entries())
      .map(([date, dateEntry]) => [date, Array.from(dateEntry.entries())]);
  }, [
    intl,
    data,
    areFiltersSet,
    textFilterSet,
    textFilter,
    userFilterSet,
    filteredUser,
    uploadDateFilterSet,
    filteredUploadDate,
    periodDateFilterSet,
    filteredPeriodDate,
    periodicityFilterSet,
    filteredPeriodicity,
    attachmentFilterSet,
    filteredAttachment
  ]);

  const hasResult = Boolean(processed.length);

  const emptyState = textFilterSet || areFiltersSet
    ? <Empty {...getEmptyResultsProps(textFilterSet, areFiltersSet)} />
    : <Empty {...emptyPropsMap.get("noAttachmentsKPI")} />

  return (
    <div className="KpiDetailFiles__files">
      {loading
        ? <Loading />
        : <>
          {/* <FilesFilters
        searchTerm={searchTerm}
        setSearchValue={setSearchTerm}
      /> */}
          <KpiDetailFilesFilters
            setFilterState={handleFilter}
            filterState={filterState}
            resetAllFilters={resetAllFilters}
            areFiltersSet={areFiltersSet}
            isTextFilterSet={textFilterSet}
          />

          {hasResult
            ? <div className='DataManagement__files__timeline__container'>
              <Timeline className='ant-timeline-label' >
                {
                  processed.map(([date, dateEntries], outerIndex) => {
                    const totalDays = totalCalendarDays(date, Date.now());

                    return (
                      <React.Fragment key={date}>
                        <span className='DataManagement__files__timeline-day'>{totalDays === 0 ? t.timeline_day_today : totalDays === 1 ? t.timeline_day_yesterday : date}</span>
                        {
                          (dateEntries || []).map(([member_slug, entries], innerIndex) => {
                            const formattedDate = formatDate(entries[0].created_at, SHORT_FORMAT_WITH_YEAR, intl);
                            let additionalClasses = (innerIndex === dateEntries.length - 1 && outerIndex < processed.length - 1) ? 'DataManagement__files__timeline-item-last' : '';
                            additionalClasses += innerIndex === 0 ? ' DataManagement__files__timeline-item-first' : '';

                            return (
                              <Timeline.Item className={`ant-timeline-item-left DataManagement__files__timeline-item ${additionalClasses}`} label={<span>{formattedDate}</span>}>
                                {Object.values(groupBy(entries, 'period')).map(entries => {
                                  const period = {
                                    label: entries[0].period,
                                    start_date: entries[0].period_start,
                                    end_date: entries[0].period_end
                                  };
                                  return (
                                    <FilesCard
                                      key={member_slug}
                                      name={entries[0].member_name}
                                      avatar={entries[0].member_avatar}
                                      email={entries[0].member_email}
                                      kpi_name={entries[0].kpi_name}
                                      kpi_slug={entries[0].kpi_slug}
                                      esg_type={entries[0].esg_type}
                                      period={period}
                                      attachments={entries}
                                      standard_info={entries[0].standard_info}
                                      showKpi
                                      collection_frequency={entries[0].collection_frequency}
                                      urlBase="data/files"
                                    />
                                  )
                                })}
                              </Timeline.Item>
                            )
                          })
                        }
                      </React.Fragment>
                    )
                  })
                }
              </Timeline>
            </div>
            : emptyState
          }
        </>
      }

    </div>
  )
}

export default injectIntl(withRouter(Files));
