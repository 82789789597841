import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { uniqueId } from 'lodash';
import LocaleFlag from 'components/LocaleFlag';

import { Loading } from 'tsComponents/emptyStates/Loading';

import useOrganizations from 'utils/useOrganizations';
import { getSlug } from 'utils/strings';
import {
  getKpiVariableStatus,
  syncKpiVariablesToChildOrganizations,
  updateKpiVariable,
  createKpiVariable,
  deleteKpiVariable,
} from 'actions/api';


import CustomButton from 'components/CustomButton';
import {
  Col,
  Divider,
  Input,
  Popconfirm,
  Row,
  Form,
  Alert,
  Tag,
  Tooltip,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  SyncOutlined,
} from '@ant-design/icons';

import './style.less';


const Variable = ({
  intl,
  allVariables,
  data: defaultData,
  canDelete,
  canManage,
  editingVariables,
  setEditingVariables,
  status,
  fetchingStatus,
}) => {
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const [data, setData] = useState(defaultData);
  const [duplicateInLocales, setDuplicateInLocales] = useState([]);
  const [missingRequiredTranslation, setMissingRequiredTranslation] = useState(false);

  useEffect(
    () => setData(defaultData),
    [defaultData]
  );

  useEffect(
    () => {
      if (data.modified && !editingVariables.includes(data.slug)) {
        setEditingVariables([...editingVariables, data.slug]);
      } else if (!data.modified && editingVariables.includes(data.slug)) {
        setEditingVariables(editingVariables.filter(slug => slug !== data.slug));
      }
    },
    [
      data,
      editingVariables,
      setEditingVariables,
    ]
  );

  const {
    organization,
    suborganization,
  } = useOrganizations();

  useEffect(
    () => {
      if (canDelete) { // This means it's owned variable
        dispatch(
          getKpiVariableStatus(
            organization.slug,
            suborganization.slug,
            defaultData.slug,
          )
        );
      }
    },
    [
      dispatch,
      defaultData,
      organization,
      suborganization,
      canDelete,
    ]
  );

  const preferredLanguages = useMemo(
    () => {
      const languages = organization?.languages || [organization.language];
      languages.sort((a, b) => a > b ? 1 : -1);
      return languages.sort((_, b) => b === suborganization.language ? 1 : -1)
    }, [
      organization,
      suborganization
    ]
  );

  const handleOnChange = useCallback(
    (optionSlug, locale, value) => {
      let variableData = {
        ...data,
        modified: true,
        modifiedVariableName: !optionSlug || data.modifiedVariableName,
      };

      if (optionSlug) {
        variableData.options = {
          ...(variableData.options || {}),
          [optionSlug]: {
            ...(variableData.options?.[optionSlug] || {}),
            [locale]: value,
          }
        }
      } else {
        if (duplicateInLocales.includes(locale)) {
          setDuplicateInLocales(
            duplicateInLocales.filter(_locale => locale !== _locale)
          );
        }
        variableData.translations = {
          ...(variableData.translations || {}),
          [locale]: value,
        };
      }
      setData(variableData);
    },
    [
      data,
      duplicateInLocales,
    ],
  );

  const handleAddNewOption = useCallback(
    () => {
      setTimeout(() => {
        // Scroll to the bottom of the content
        const contentElement = componentRef.current.closest('.ant-tabs-content-holder');
        contentElement.scrollTop = contentElement.scrollHeight;
      }, 0);

      setData({
        ...data,
        options: {
          ...(data.options || {}),
          [`__option-${uniqueId()}`]: {},
        }
      })
    },
    [data],
  );

  const slugifyAndAutofillOptions = useCallback(() => {
    const updatedOptions = Object.fromEntries(
      Object.entries(data.options).map(([optionSlug, optionTranslations]) => {
        let newSlug = optionSlug;
        let newTranslations = { ...optionTranslations };

        if (optionSlug.startsWith('__option-')) {
          newSlug = getSlug(
            optionTranslations['en-US'] || optionTranslations[suborganization.language],
            Object.keys(data.options),
          );
        }

        preferredLanguages.filter(locale => locale !== suborganization.language).forEach(locale => {
          if (!newTranslations[locale]) {
            newTranslations[locale] = newTranslations[suborganization.language];
          }
        });

        return [newSlug, newTranslations];
      })
    );

    const updatedData = {
      ...data,
      options: updatedOptions,
    };
    setData(updatedData);
    return updatedData;
  }, [data, preferredLanguages, suborganization.language]);

  const validateAndProcessData = useCallback(
    () => {
      let duplicates = [];
      let missingRequiredTranslation = false;

      if (
        !data.translations[suborganization.language]
        || Object.values(data.options).find(optionTranslations => !optionTranslations[suborganization.language])
      ) {
        missingRequiredTranslation = true;
      }

      allVariables.forEach(variable => {
        Object.entries(data.translations)
          .filter(([locale, _]) => preferredLanguages.includes(locale))
          .forEach(([locale, value]) => {
            if (
              variable.slug !== data.slug
              && value
              && value.trim().toLowerCase() === variable.translations[locale]?.trim().toLowerCase()
            ) {
              duplicates.push(locale);
            }
          });
      });

      if (duplicates.length) {
        setDuplicateInLocales(duplicates);
      }
      setMissingRequiredTranslation(missingRequiredTranslation);

      const dataWithUpdatedOptions = missingRequiredTranslation
        ? data
        : slugifyAndAutofillOptions(data);
      return {
        result: !duplicates.length && !missingRequiredTranslation,
        data: dataWithUpdatedOptions,
      };
    },
    [
      allVariables,
      data,
      suborganization.language,
      preferredLanguages,
      slugifyAndAutofillOptions,
    ]
  );

  const handleSave = useCallback(
    () => {
      const validationData = validateAndProcessData();

      if (validationData.result) {
        if (!data.default_variable_id && !data.organization_id) {
          dispatch(
            createKpiVariable(
              organization.slug,
              suborganization.slug,
              {
                slug: data.slug,
                translations: data.translations,
                options: validationData.data.options,
                default_variable_id: data.id,
              },
            )
          );
        } else {
          dispatch(
            updateKpiVariable(
              organization.slug,
              suborganization.slug,
              data.slug,
              {
                translations: data.translations,
                options: validationData.data.options,
                modifiedVariableName: data.modifiedVariableName,
              },
            )
          );
        }
      }
    },
    [
      dispatch,
      data,
      organization,
      suborganization,
      validateAndProcessData,
    ],
  );

  const handleDeleteKpiVariable = useCallback(() => {
    dispatch(
      deleteKpiVariable(
        organization.slug,
        suborganization.slug,
        data.slug,
      )
    );
  }, [data.slug, dispatch, organization.slug, suborganization.slug]);

  const handleDeprecateKpiVariable = useCallback(() => {
    dispatch(
      updateKpiVariable(
        organization.slug,
        suborganization.slug,
        data.slug,
        {
          status: 'deprecated',
        },
      )
    );
  }, [data.slug, dispatch, organization.slug, suborganization.slug]);

  const handleOnDeleteOption = useCallback((optionSlug) => {
    setData(prevData => {
      const updatedOptions = { ...prevData.options };
      delete updatedOptions[optionSlug];
      return {
        ...prevData,
        modified: true,
        options: updatedOptions,
      };
    });
  }, []);

  const canResetVariable = useMemo(() => {
    return data.default_variable_id && data.organization_id;
  }, [data]);

  const handleOnSyncVariableToChildOrganizations = useCallback(
    () => {
      dispatch(
        syncKpiVariablesToChildOrganizations(
          organization.slug,
          suborganization.slug,
          data.slug,
        )
      )
    },
    [
      dispatch,
      organization,
      suborganization,
      data,
    ]
  );

  if (fetchingStatus) {
    return <Loading />;
  }

  return (
    <Row
      className="VariablesConfiguration__variable"
      gutter={[10, 10]}
      ref={componentRef}
    >
      <Row type="flex" gutter={5} className="VariablesConfiguration__variable_actions">
        { canManage && !(data.status === 'deprecated') && !!suborganization.children.length &&
          <Col>
            <Popconfirm
              title={intl.formatMessage({id: 'variables_configuration_sync_to_all_children_confirm'})}
              placement="topLeft"
              onConfirm={handleOnSyncVariableToChildOrganizations}
              okText={intl.formatMessage({id: 'variables_configuration_sync_to_all_children_confirm_yes'})}
              cancelText={intl.formatMessage({id: 'variables_configuration_sync_to_all_children_confirm_no'})}
            >
              <CustomButton
                icon={<SyncOutlined />}
                disabled={data.modified}
              >
                {intl.formatMessage({id: 'variables_configuration_sync_to_all_children'})}
              </CustomButton>
            </Popconfirm>
          </Col>
        }
        { canManage && !canDelete && canResetVariable &&
          <Col>
            <Popconfirm
              title={intl.formatMessage({id: 'variables_configuration_reset_confirm'})}
              placement="topLeft"
              onConfirm={handleDeleteKpiVariable}
              okText={intl.formatMessage({id: 'variables_configuration_reset_confirm_yes'})}
              cancelText={intl.formatMessage({id: 'variables_configuration_reset_confirm_no'})}
            >
              <CustomButton danger={true}>
                {intl.formatMessage({id: 'variables_configuration_reset'})}
              </CustomButton>
            </Popconfirm>
          </Col>
        }
        { canDelete && !status?.is_used &&
          <Col>
            <Tooltip
              title={
                !suborganization.parent_id
                ? null
                : intl.formatMessage({id: 'variables_configuration_delete_variable_from_suborg_tooltip'})
              }
              mouseEnterDelay={1}
            >
              <span>
                <Popconfirm
                  title={intl.formatMessage({id: 'variables_configuration_delete_confirm'})}
                  placement="topLeft"
                  onConfirm={handleDeleteKpiVariable}
                  okText={intl.formatMessage({id: 'variables_configuration_delete_confirm_yes'})}
                  cancelText={intl.formatMessage({id: 'variables_configuration_delete_confirm_no'})}
                >
                  <CustomButton danger={true} disabled={!!suborganization.parent_id}>
                    {intl.formatMessage({id: 'variables_configuration_delete'})}
                  </CustomButton>
                </Popconfirm>
              </span>
            </Tooltip>
          </Col>
        }
        { canDelete && status?.is_used && !(data.status === 'deprecated') &&
          <Col>
            <Popconfirm
              title={
                intl.formatMessage(
                  { id: 'variables_configuration_deprecate_confirm' },
                  { numberOfKpis: status.number_of_kpis }
                )
              }
              placement="topLeft"
              onConfirm={handleDeprecateKpiVariable}
              okText={intl.formatMessage({id: 'variables_configuration_deprecate_confirm_yes'})}
              cancelText={intl.formatMessage({id: 'variables_configuration_deprecate_confirm_no'})}
            >
              <CustomButton danger={true}>
                {intl.formatMessage({id: 'variables_configuration_deprecate'})}
              </CustomButton>
            </Popconfirm>
          </Col>
        }
        { canManage && !(data.status === 'deprecated') &&
          <Col>
            <CustomButton
              type="primary"
              onClick={handleSave}
              disabled={!data.modified}
            >
              {intl.formatMessage({id: 'variables_configuration_save'})}
            </CustomButton>
          </Col>
        }
      </Row>
      <Col span={24}>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <Row gutter={10}>
              <Col>
                <h4>{intl.formatMessage({id: 'variables_configuration_name'})}</h4>
              </Col>
              { (data.status === 'deprecated') &&
                <Col>
                  <Tag color="red">
                    {intl.formatMessage({id: 'variables_configuration_deprecated'})}
                  </Tag>
                </Col>
              }
            </Row>
          </Col>
          
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={[5, 5]}>
          { !!duplicateInLocales.length &&
            <Col span={24}>
              <Alert
                type="error"
                message={intl.formatMessage({id: 'variables_configuration_duplicate_existing_variable'})}
              />
            </Col>
          }
          { missingRequiredTranslation &&
            <Col span={24}>
              <Alert
                type="error"
                message={intl.formatMessage({id: 'variables_configuration_missing_required_translation'})}
              />
            </Col>
          }
          { preferredLanguages.map(locale =>
            <Col span={8} key={locale}>
              <Tooltip
                title={
                  !suborganization.parent_id
                  ? null
                  : intl.formatMessage({id: 'variables_configuration_edit_variable_from_suborg_tooltip'})
                }
                mouseEnterDelay={1}
              >
                <Form.Item
                  validateStatus={duplicateInLocales.includes(locale) ? 'error' : null}
                  style={{marginBottom: 0}}
                  required={locale === suborganization.language}
                >
                  <Input
                    addonBefore={
                      <LocaleFlag intl={intl} locale={locale} />
                    }
                    value={data.translations[locale]}
                    onChange={e => handleOnChange(null, locale, e.target.value)}
                    suffix={locale === suborganization.language ? <span className="VariablesConfiguration__variable__required">*</span> : null}
                    disabled={(data.status === 'deprecated') || suborganization.parent_id || !canManage}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
          )}
        </Row>
      </Col>
      <Col span={24}>
        <Row type="flex" gutter={10} align="middle">
          <Col>
            <h4 className="VariablesConfiguration__variable__options_header">
              {intl.formatMessage({id: 'variables_configuration_values'})}
            </h4>
          </Col>
          { canManage && !(data.status === 'deprecated') &&
            <Col>
              <CustomButton
                className="VariablesConfiguration__variable__add_option_button"
                icon={<PlusOutlined />}
                type="primary"
                onClick={handleAddNewOption}
              />
            </Col>
          }
        </Row>
      </Col>
      { Object.entries(data.options)
        .sort((a, b) => (a[1][suborganization.language] || a[0]) > (b[1][suborganization.language] || b[0]) ? 1 : -1)
        .map(([optionSlug, optionTranslations], index) =>
        <React.Fragment key={`${optionSlug}`}>
          { index > 0 &&
            <Col span={24}>
              <Divider />
            </Col>
          }
          <Col span={24} className="VariablesConfiguration__variable_option">
            <Row gutter={[5, 5]}>
              { preferredLanguages.map(locale => {
                return (
                  <Col span={8} key={locale}>
                    <Input
                      addonBefore={
                        <LocaleFlag intl={intl} locale={locale} />
                      }
                      value={optionTranslations[locale]}
                      onChange={e => handleOnChange(optionSlug, locale, e.target.value)}
                      suffix={locale === suborganization.language ? <span className="VariablesConfiguration__variable__required">*</span> : null}
                      disabled={!canManage || (data.status === 'deprecated')}
                    />
                  </Col>
                );
              })}
              { canManage && !(data.status === 'deprecated') &&
                <Col>
                  <CustomButton
                    className="VariablesConfiguration__variable__delete_option_button"
                    icon={<DeleteOutlined />}
                    danger={true}
                    onClick={() => handleOnDeleteOption(optionSlug)}
                  />
                </Col>
              }
            </Row>
          </Col>
        </React.Fragment>
      )}
    </Row>
  );
}

export default injectIntl(Variable);
