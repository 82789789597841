import {
  toDb,
} from 'utils/date';

const bulkUploadSuccess = (
  suborganization_slug,
  filename,
  member_name,
  member_email,
  member_avatar,
) => ({
  type: 'ADD_BULK_UPLOAD',
  suborganization_slug,
  filename,
  member_name,
  member_email,
  member_avatar,
  updated_at: toDb(new Date()),
});

const updateBulkManageFromClipboard = (data) => ({
  type: 'BULK_MANAGE_CLIPBOARD_UPDATE',
  data,
});

const resetBulkManagePasteErrors = (data) => ({
  type: 'BULK_MANAGE_CLIPBOARD_RESET_ERRORS',
});

export {
  bulkUploadSuccess,
  updateBulkManageFromClipboard,
  resetBulkManagePasteErrors,
}
