import React,{
    useEffect,
    useState,
    useMemo,
    useCallback,
    useContext
} from 'react';
import { withRouter } from "react-router";
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

import {
//    useDispatch,
    useSelector,
  } from 'react-redux';

//  import useOrganizations from 'utils/useOrganizations';

  // import {
  //   getArchives,
  //   //deleteArchive,
  // } from 'actions/api';  

  import OrganizationArchiveFilters from 'components/OrganizationArchiveFilters';
  import FilesDownloadCard from 'components/FilesDownloadCard';
  import {
    makeSearchString,
    makeSearchStrings,
  } from 'utils/strings';
  import useScreenFilters from 'hooks/useScreenFilters';
  import {SCREENS} from 'hooks/useScreenFilters/constants';
  //import AplanetIcon from 'components/AplanetIcon';
  
  import {
    formatDate,
    DATE_ONLY,
    SHORT_FORMAT_WITH_YEAR,
    fromDb,
    isBetween,
    totalCalendarDays
  } from 'utils/date';
  
  
  import {
    Timeline,
  } from 'antd';
  import CustomModalType from 'components/CustomModalType';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { getEmptyResultsProps, emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';
import { WebSocketContext } from 'components/WebsocketContext';

const FilesDownloadTab = ({
intl
}) => {

    const t = intl.messages;
    //const dispatch = useDispatch();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteFileId, setDeleteFileId] = useState('');
    const [deleteFilename, setDeleteFilename] = useState('');
    const {sendMessage} = useContext(WebSocketContext);

    //Same order as defined in useScreenFilters/constants.js
    const {
    values: [
        [filteredStatuses, statusFilterSet],
        [filteredUser, userFilterSet],
        [filteredDate, dateFilterSet],
        [textFilter, textFilterSet], // eslint-disable-line no-unused-vars
    ],
    handleFilter,
    filterState,
    isFilterSet, // eslint-disable-line no-unused-vars
    resetAllFilters
    } = useScreenFilters({
    screen: SCREENS.downloads
    });


    // const {
    // organization,
    // suborganization,
    //   } = useOrganizations();

    const areFiltersSet = useMemo(
    () => statusFilterSet || userFilterSet || dateFilterSet
    ,
    [
        statusFilterSet,
        userFilterSet,
        dateFilterSet
    ]
    );

    const {
        data,
    } = useSelector(state => state.organization_archive);


  //   const refreshView = useCallback(()=>{
  //     dispatch(
  //         getArchives(
  //             organization.slug,
  //             suborganization.slug
  //         )
  //     )
  // },[
  //     dispatch,
  //     organization,
  //     suborganization
  // ]);

    useEffect(()=>{
      sendMessage('org_archive:get');
  },[
    //refreshView,
    sendMessage
  ]);

    const processedFiles = useMemo(()=> {
        const filteredData = (data || [])
        .filter(item => {
            if ( !areFiltersSet && !textFilterSet ){
                return true;
              }
            let resultTextFilter =true,
                resultStatusFilter = true,
                resultUserFilter = true,
                resultDateFilter = true;
            
            if (textFilterSet){
                const searchStrings = makeSearchStrings(textFilter);
                const searchableFilenmae = makeSearchString(item.filename);
                const searchableMember = makeSearchString(item.member_name);
                resultTextFilter = searchStrings.every((searchString)=> searchableFilenmae.includes(searchString) || searchableMember.includes(searchString));
            }
            if(statusFilterSet){
              resultStatusFilter = false;
                if (filteredStatuses.includes('downloaded')){
                  resultStatusFilter ||= (item.downloaded_at !== null);
                }
                if (filteredStatuses.includes('not_downloaded')){
                  resultStatusFilter ||= (item.downloaded_at === null);
                }
                if (filteredStatuses.includes('bookmarked')){
                  resultStatusFilter ||= item.bookmark === true;
                }
                if (filteredStatuses.includes('not_bookmarked')){
                  resultStatusFilter ||= (item.bookmark === false);
                }
            }

            if(dateFilterSet){
                resultDateFilter = isBetween(item.created_at,filteredDate);
            }
            if(userFilterSet){
                resultUserFilter = filteredUser.includes(item.member_slug);
            }
            return resultTextFilter && resultStatusFilter && resultUserFilter && resultDateFilter;  
        })
        .map(datum=>({
            ...datum,
            date: formatDate(
              datum.created_at,
              DATE_ONLY,
              intl,
            ),
          }))
          .sort((a, b) => {
            const dateA = fromDb(a.created_at);
            const dateB = fromDb(b.created_at);
            return dateA>dateB ? -1 : dateA<dateB ? 1 : 0;
          });
          const dateMap = new Map();

          filteredData.forEach(datum => {
            const {
             date
            } = datum;

            if(!dateMap.has(date)) {
                dateMap.set(date, []);
            }
            dateMap.get(date).push(datum);
          });
          return Array
          .from(dateMap.entries());
    }, [
        data,
        intl,
        areFiltersSet,
        textFilterSet,
        textFilter,
        dateFilterSet, 
        filteredDate,
        userFilterSet,
        filteredUser,
        filteredStatuses,
        statusFilterSet,
    ]);

    const handleUpdateArchive = useCallback((file_id,params) =>{

      // dispatch(
      //   (updateArchive)(
      //     organization.slug,
      //     suborganization.slug,
      //     file_id,
      //     params
      //   )
      // );

      sendMessage('org_archive:action',{
        id: file_id,
        ...params
      });

    },[
      // dispatch,
      // organization,
      // suborganization
      sendMessage
    ]);
    
    const openModal = useCallback((file_id,filename) => {
      setDeleteFileId(file_id);
      setDeleteFilename(filename);
      setOpenDeleteModal(true);
    },[]);

    const cancelDelete = useCallback(()=>{
      setDeleteFileId('');
      setDeleteFilename('');
      setOpenDeleteModal(false);
    },[]);

    const handleDeleteArchive = useCallback(() => {
      sendMessage('org_archive:delete',{id: deleteFileId});

      // dispatch(
      //   (deleteArchive)(
      //     organization.slug,
      //     suborganization.slug,
      //     deleteFileId
      //   )
      // );
      setDeleteFileId('');
      setDeleteFilename('');
      setOpenDeleteModal(false);
    },[
      // dispatch,
      // organization,
      // suborganization,
      sendMessage,
      deleteFileId
    ]);

    const onDownloadEvent = useCallback((file_id) => {
      //A member clicked on the download button.
      sendMessage('org_archive:action',{
        id: file_id,
        action: 'download'
      });

    },[
      sendMessage
    ]);

  const hasResult = Boolean(processedFiles.length);

  const emptyState = textFilterSet || areFiltersSet 
    ? <Empty {...getEmptyResultsProps(textFilterSet, areFiltersSet)} />
    : <Empty {...emptyPropsMap.get("noDownloads")} />

return (
    <div className="DataManagement__files">
            <OrganizationArchiveFilters
              setFilterState={handleFilter}
              filterState={filterState}
              resetAllFilters={resetAllFilters}
              areFiltersSet={areFiltersSet}
              isTextFilterSet={textFilterSet}
              // onRefresh = {() =>refreshView()}
          />
        {/* <div className='DataManagement__files__refresh_notice'>
          <div>
            <AplanetIcon name='Refresh' size="18px" />
            <FormattedHTMLMessage
                      id="datamanagement_files_refresh_notice"
                      defaultMessage={ t.datamanagement_files_refresh_notice }
                      tagName="span"
                      values={
                        {
                          refresh_button : t.datamanagement_download_button_refresh
                        }
                      }
                    />
          </div>
        </div> */}
  
        <div className='DataManagement__files__timeline__container'>
          { hasResult
            ? <Timeline className='ant-timeline-label' >
              {
                processedFiles.map(([ date, dateEntries],outerIndex) => {
                  const totalDays = totalCalendarDays(date,Date.now());
                  return (
                    <React.Fragment key={date}>
                    <span className='DataManagement__files__timeline-day'>{totalDays === 0 ? t.timeline_day_today : totalDays === 1 ? t.timeline_day_yesterday : date }</span>
                      {
                        (dateEntries || []).map((entry,innerIndex) => {

                            const formattedDate = formatDate(entry.created_at,SHORT_FORMAT_WITH_YEAR,intl);
                            let additionalClasses = (innerIndex === dateEntries.length -1 && outerIndex < processedFiles.length -1 ) ? 'DataManagement__files__timeline-item-last':'';
                                  additionalClasses += innerIndex === 0 ? ' DataManagement__files__timeline-item-first': '';
                            
                                  return (
                                    
                                    <Timeline.Item className={`ant-timeline-item-left DataManagement__files__timeline-item ${additionalClasses}`} label={<span>{formattedDate}</span>}>
                                        <FilesDownloadCard
                                          key={entry.member_slug}
                                          name={entry.member_name}
                                          avatar={entry.member_avatar}
                                          email = { entry.member_email}
                                          file={entry}
                                          urlBase="data/files/download"
                                          onUpdate = {handleUpdateArchive}
                                          onDelete = {openModal}
                                          onDownload = {(id) => onDownloadEvent(id)}
                                        />
                                    </Timeline.Item>
                                    
                                  )

                          })
                        }
                    </React.Fragment> 
                  )
                })
              }
              </Timeline>
            : emptyState 
          }

        </div>
        <CustomModalType
          showModal={openDeleteModal}
          type="warning"
          title={t.datamanagement_download_modal_title}
          onOk = {handleDeleteArchive}
          onCancel = {cancelDelete}
          disabledOk = {false}
          onOkText = {t.datamanagement_download_modal_ok}
          onCancelText= {t.datamanagement_download_modal_cancel}
          content = {
            <section className="DataManagement__Download__DeleteModal__form">
              <FormattedHTMLMessage
                id="datamanagement_download_modal_delete_form_notice"
                defaultMessage={ t.datamanagement_download_modal_delete_form_notice }
                tagName="p"
                values={
                  {
                    filename : deleteFilename
                  }
                }
              />
            </section>
          }
        >
  
        </CustomModalType>
  
    </div>
);
}

export default injectIntl(
    withRouter(
        FilesDownloadTab
    )
  )
