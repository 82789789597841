import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Button,
  Modal,
  Space,
  Tooltip,
} from 'antd';

import {
  PlusOutlined,
  LineChartOutlined,
  EditOutlined,
  CheckOutlined,
} from '@ant-design/icons';

import { Loading } from 'tsComponents/emptyStates/Loading';
import { dateToDb, fromDb } from 'utils/date';
import Form from 'components/EqualityPlanActions/Form';
import EqualityPlanHeader from 'components/EqualityPlan/Header';
import CustomTable from 'components/CustomTable';
import { formatDate } from 'utils/date';

import './style.less';


const ActionsList = ({
  intl,
  history,
  stage = "actions",
  plan,
  actions,
  objectives,
  fetchObjectives,
  fetchingObjectives,
  fetchActions,
  createAction,
  updateAction,
  fetchingActions,
  updatePlanProgress,
  measures,
  createMeasure,
}) => {
  const t = intl.messages;
  const [activeAction, setActiveAction] = useState({});

  useEffect(
    () => {
      fetchActions(stage === 'actions' ? 'general' : 'followup');
      if (stage === 'actions') {
        fetchObjectives();
      }
    },
    [plan.id, fetchActions, fetchObjectives, stage]
  );

  const handleAddNewAction = useCallback(
    () => {
      setActiveAction({new: true});
    },
    [setActiveAction]
  );

  const getActionProgress = useCallback(
    (action) => {
      const indicatorsProgress = action.indicators.map(indicator => {
        return Math.min(
          Math.round(indicator.current_value / indicator.target_value * 100), 100
        );
      });
      return Math.round(
        indicatorsProgress.reduce( ( p, c ) => p + c, 0 ) / indicatorsProgress.length
      );
    },
    []
  );

  const getActionStatus = useCallback(
    (action) => {
      const actionProgress = getActionProgress(action);
      let type = '';
      let status = `${t.in_progress.toUpperCase()} (${actionProgress}%)`;

      if (action.cancel_reason) {
        status = `${t.canceled.toUpperCase()} - ${action.cancel_reason}`;
        type = 'danger';
      } else if (actionProgress >= 100 || action.completed) {
        status = t.completed.toUpperCase();
        type = 'success';
      } else if (new Date() > fromDb(action.end_date)) {
        status = t.not_achieved.toUpperCase();
        type = 'warning';
      }
      return <span className={`action-status ${type}`}>{status}</span>;
    },
    [t, getActionProgress]
  );

  const onGoToActionTrackingPage = useCallback(
    (actionID) => {
      return history.push(`/equality-plan/${plan.id}/${stage === 'actions' ? 'tracking' : 'tracking-followup'}`, { actionID });
    },
    [history, plan, stage]
  );

  const handleEditAction = useCallback(
    (action) => {
      setActiveAction({...action});
    },
    [setActiveAction]
  );

  const handleBackOrSkip = useCallback(
    (action) => {
      const actionUrl = `/equality-plan/${plan.id}/`;

      if (Object.keys(activeAction).length > 0) {
        Modal.confirm({
          title: t.equality_plan_exit_without_save,
          content: t.equality_plan_exit_without_save_description,
          okText: t.equality_plan_exit_without_save_ok,
          cancelText: t.equality_plan_exit_without_save_cancel,
          onOk() {
            history.push(actionUrl);
          },
        });
      } else {
        history.push(actionUrl);
      }
    },
    [plan, history, t, activeAction]
  );

  const handleOnDone = useCallback(
    (action) => {
      Modal.confirm({
        title: t.equality_actions_complete_title,
        okText: t.equality_actions_complete_ok,
        cancelText: t.equality_actions_complete_cancel,
        onOk() {
          updateAction(
            action.id,
            {
              ...action,
              end_date: dateToDb(new Date()),
              completed: true,
            }
          );
        },
      })
    },
    [updateAction, t]
  );

  const columns = useMemo(
    () => (
      [{
        title: t.equality_actions_table_name,
        dataIndex: 'name',
      }, {
        title: t.equality_actions_table_responsibles,
        dataIndex: 'details',
        render: details => (
          details.people_responsible && details.people_responsible.length > 0
          ? details.people_responsible.map(person =>
              <div
                className="EqualityPlanActions-responsibles"
                key={person.name}
              >
                <Row type="flex" gutter={5}>
                  <Col>{t.equality_actions_table_responsibles_name}:</Col>
                  <Col>{person.name}</Col>
                </Row>
                <Row
                  type="flex"
                  gutter={5}
                  className="EqualityPlanActions-responsibles-email"
                >
                  <Col>{t.equality_actions_table_responsibles_email}:</Col>
                  <Col>{person.email || '-'}</Col>
                </Row>
              </div>
          )
          : '-'
        )
      }, {
        title: t.equality_actions_table_start_date,
        dataIndex: 'start_date',
        render: (start_date) => (
          <span>
            {`${formatDate(start_date, 'D MMM YYYY', intl)}`}
          </span>
        ),
      }, {
        title: t.equality_actions_table_end_date,
        dataIndex: 'end_date',
        render: (end_date) => (
          <span>
            {`${formatDate(end_date, 'D MMM YYYY', intl)}`}
          </span>
        ),
      }, {
        title: t.equality_actions_table_status,
        key: 'status',
        render: (_, record) => getActionStatus(record)
      }, {
        title: t.equality_actions_table_actions,
        key: 'actions',
        render: (_, record) => (
          <Space size="middle">
            <Tooltip title={t.equality_actions_table_actions_edit}>
              <EditOutlined
                className='form-item-preview-icon'
                onClick={() => handleEditAction(record)}
              />
            </Tooltip>
            <Tooltip title={t.equality_actions_table_actions_done}>
              <CheckOutlined
                className='form-item-preview-icon'
                onClick={() => handleOnDone(record)}
              />
            </Tooltip>
            <Tooltip title={t.equality_actions_table_actions_track}>
              <LineChartOutlined
                className='form-item-preview-icon'
                onClick={() => onGoToActionTrackingPage(record.id)}
              />
            </Tooltip>
          </Space>
        )
      }]
    ),
    [t, intl, getActionStatus, onGoToActionTrackingPage, handleEditAction, handleOnDone]
  );

  if (fetchingActions || fetchingObjectives) {
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t[`equality_${stage}`]}
        isDocumentManager={false}
        handleBackOrSkip={handleBackOrSkip}
      />
      <Row gutter={10}>
        <Col span={24}>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => handleAddNewAction()}
          >{t.new_action}</Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="form-input-wrapper">
          { Object.keys(activeAction).length > 0 &&
            <Form
              visible={true}
              stage={stage}
              plan={plan}
              objectives={objectives}
              activeAction={activeAction}
              setActiveAction={setActiveAction}
              createAction={createAction}
              updateAction={updateAction}
              measures={measures}
              createMeasure={createMeasure}
              updatePlanProgress={updatePlanProgress}
            />
          }
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            columns={columns}
            dataSource={actions}
            rowKey='id'
          />
        </Col>
      </Row>
    </>
  )
};

export default withRouter(injectIntl(ActionsList));
