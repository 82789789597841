import { isNumber } from "lodash";

export const fullValidation = (t, jobFactors) => ({
  salary_min,
  salary_max,
  salary_function,
}) => {
  let errors = {};

  if (!isNumber(salary_min)) {
    errors.salary_min = t.form_error_required;
  }

  if (!isNumber(salary_max)) {
    errors.salary_max = t.form_error_required;
  }

  if (!isNumber(salary_function)) {
    errors.salary_function = t.form_error_required;
  }

  return {
    ...errors,
  };
};