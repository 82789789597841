import React, {
  useMemo,
} from 'react';
import { withRouter } from 'react-router';

import useOrganizations from 'utils/useOrganizations';

import MainLayout from 'components/MainLayout';
import Button from 'components/CustomButton'
import A from 'components/A';
import AplanetIcon from 'components/AplanetIcon';

import { formatMessage } from '../../intl';


import './style.less';

const NeutralityDisabledRedirect = ({ history }) => {

  const {
    organization,
  } = useOrganizations();

  const contactEmail = useMemo(
    () => {
      return organization?.general_config?.contact_email
        || `${formatMessage("contact_email_address")}@aplanet.org`;
    },
    [organization]
  );

  return (
    <MainLayout>
      <div className='NeutralityDisabledRedirect'>
        <img
          className="NeutralityDisabledRedirect__img"
          src='/images/carbon_footprint.svg'
          alt='carbon-footprint'
        />
        <span>
          {formatMessage("neutralitydisabledredirect_first_message")}
        </span>
        <b>
          {formatMessage("neutralitydisabledredirect_second_message")}
        </b>
        <span>
          <b className="NeutralityDisabledRedirect__name">{formatMessage("neutrality")}</b>, {formatMessage("neutralitydisabledredirect_third_message")}
        </span>
        <Button className="NeutralityDisabledRedirect__contact" icon={<AplanetIcon name="Contact" size="15px" />}>
          <A href={`mailto:${contactEmail}`}>
            <span className="navLabel">
              {formatMessage("nav_contact_us")}
            </span>
          </A>
        </Button>
        {/* This history.go(-2) is a quick fix for some weird middleware we have somewhere which mess the history and goBack don't work properly*/}
        <A className='NeutralityDisabledRedirect__back' onClick={() => history.go(-2)}>
          {formatMessage("neutralitydisabledredirect_back_message")}
        </A>
      </div>
    </MainLayout>
  );
};

export default withRouter(NeutralityDisabledRedirect);
