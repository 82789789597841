import React from 'react';

import ColumnTitle from 'components/TableAnswer/ColumnTitle';
import BooleanAnswer from 'components/BooleanAnswer';
import ChoiceAnswer from 'components/ChoiceAnswer';
import QualitativeAnswer from 'components/QualitativeAnswer';
import QuantitativeAnswer from 'components/QuantitativeAnswer';

import { convertToUnit } from 'utils/units';
import objectMap from 'utils/objectMap';

import {
  SUM_SLUG,
  PRODUCT_SLUG,
  PERCENTAGE_SLUG,
  META_SLUG,
  FULL_VALUE_SLUG,
  HETEROGENEOUS_META_TYPE,
  HETEROGENEOUS_META_SCHEMA,
  translateSlug,
} from 'components/TableAnswer/converters/common';

import {
  Button,
} from 'antd';
import {
  DeleteOutlined,
} from '@ant-design/icons';

const EDIT_COMPONENTS_BY_TYPE = {
  boolean: BooleanAnswer.TableEdit,
  choice: ChoiceAnswer.TableEdit,
  qualitative: QualitativeAnswer.TableEdit,
  quantitative: QuantitativeAnswer.TableEdit,
  default: () => <div>???</div>,
};

const SHOW_COMPONENTS_BY_TYPE = {
  boolean: BooleanAnswer.TableShow,
  choice: ChoiceAnswer.TableShow,
  qualitative: QualitativeAnswer.TableShow,
  quantitative: QuantitativeAnswer.TableShow,
  default: () => <div>???</div>,
};

const renderExtensibleColumn = (key, edit, onPaste) => (value, record, index) => {
  const fullValue = record[FULL_VALUE_SLUG]
  const onChange = edit && fullValue
    ? (newName) => {
      const filterFunc = (obj) => {
        return typeof obj === 'object' && obj !== null && Array.isArray(obj._address)
      };
      const mapFunc = (obj) => {
        const newObj = objectMap(
          {
            ...obj,
            _address: undefined,
          },
          filterFunc,
          mapFunc,
        );
        return {
          ...newObj,
          _address: [
            newName.text,
            ...(obj._address.slice(1)),
          ],
        };
      };
      let newValue = Object.keys(fullValue)
        .reduce((obj, k) => {
          if(k === value) {
            obj[newName.text] = {
              ...(
                objectMap(
                  (fullValue[k] || {}),
                  filterFunc,
                  mapFunc,
                )
              ),
              [key]: newName.text,
            };
          } else {
            obj[k] = fullValue[k];
          }
          return obj;
        }, {});

      return edit([], newValue);
    }
    : null

  const onDelete = edit && fullValue
    ? () => {
      let newValue = Object.keys(fullValue)
        .filter(k => k !== value)
        .reduce((obj, k) => {
          obj[k] = fullValue[k];
          return obj;
        }, {});

      return edit([], newValue);
    }
    : null

  const Component = EDIT_COMPONENTS_BY_TYPE.qualitative;

  if(record[META_SLUG]) {
    return renderMetaColumn({ type: 'qualitative' }, {}, {})({ text: value }, record, index);
  }

  return (
    <div
      style={{ display: 'flex' }}
    >
      <Button
        icon={<DeleteOutlined />}
        type="text"
        onClick={onDelete}
        style={{
          marginTop: '5px',
          marginRight: '10px',
          fontSize: '16px',
        }}
      />
      <Component
        schema={{ type: 'qualitative' }}
        value={{ text: value }}
        onChange={onChange}
        onPaste={onPaste}
      />
    </div>
  );
};

const renderMetaColumn = (schema, config, schemaLabels, targetEdit, availableUnits=[]) => (value, record, index) => {
  const isHeterogeneous = schema.type === 'tuple';
  // WORKAROUND: heterogeneous tables only have quantitative meta
  const Component = isHeterogeneous
    ? SHOW_COMPONENTS_BY_TYPE[HETEROGENEOUS_META_TYPE]
    : SHOW_COMPONENTS_BY_TYPE[schema.type];
  return (
    <Component
      schema={isHeterogeneous ? HETEROGENEOUS_META_SCHEMA : schema}
      schemaLabels={schemaLabels}
      config={config}
      value={value}
    />
  );
};

const renderColumn = (
  schema,
  edit,
  hasPercentage = false,
  config,
  schemaLabels,
  targetEdit = false,
  availableUnits,
  tableDimensions = null, // for choice_variable sub-components
  renderAsMeta = false,
  onPaste,
) => (value, record, index) => {
  //const isHeterogeneous = schema.type === 'tuple';
  if(renderAsMeta || (record && record[META_SLUG])) {
    return renderMetaColumn(schema, config, schemaLabels, availableUnits)(value, record, index);
  }
  let Component = edit
    ? EDIT_COMPONENTS_BY_TYPE[schema.type]
    : SHOW_COMPONENTS_BY_TYPE[schema.type];

  if (targetEdit && schema.type === 'qualitative'){
    Component = SHOW_COMPONENTS_BY_TYPE[schema.type];
  }

  const onChange = edit
    ? (newValue) => {
      return edit(value._address, newValue);
    }
    : null

  const handleOnPaste = onPaste
    ? (newValue) => {
      return onPaste(value._address, newValue);
    }
    : null;

  const showPercentage = (
    hasPercentage &&
    value &&
    typeof value[PERCENTAGE_SLUG] !== 'undefined' &&
    value[PERCENTAGE_SLUG] !== null
  );

  // 2022.06.08: Please note as of today, only heterogeneous tables have proper
  //             availableUnits and tableDimensions. This is due to urgency implementing
  //             and not careful planning.
  // TODO: Refactor all this so that all tables have availableUnits / tableDimensions
  return (
    <Component
      schema={schema}
      schemaLabels={schemaLabels}
      config={config}
      value={value}
      onChange={onChange}
      onPaste={handleOnPaste}
      showPercentage={showPercentage}
      tableDimensions={tableDimensions}
    />
  );
};

const tupleComponentToColumn = (
  schema,
  config,
  edit,
  availableUnits,
  tableDimensions,
  schemaLabels,
  kpiValue,
  isTarget,
  targetEdit,
  onPaste,
) => (component) => {
  // TODO: translate and all
  const name = component.name;
  const metricSlug = schema?.components?.find(
    component => component?.name === name
  )?.metricSlug;

  // TODO: When more types are rendered as meta refactor this
  const renderAsMeta = component.source && component.source !== 'manual';
  return {
    title: (
      <ColumnTitle
        schemaLabels={schemaLabels}
        availableUnits={availableUnits[name]}
        metricSlug={metricSlug}
        kpiValue={kpiValue || {}}
        name={name}
        edit={edit}
        text={
          `${ translateSlug( (schemaLabels || {}).componentLabels )(name)}`
        }
        schema = { component }
        isTarget = {isTarget}
        targetEditTooltip = {targetEdit && component.type === 'qualitative'}
      />
    ),
    dataIndex: name,
    key: name,
    type: component.type,
    render: renderColumn(component, edit, false, config, schemaLabels, targetEdit, availableUnits[name], tableDimensions, renderAsMeta, onPaste),
  };
};

const dimensionToColumns = (
  intl,
  schema,
  config,
  edit,
  availableUnits = [],
  forcePercentage = false,
  schemaLabels,
  tableDimensions,
  kpiValue,
  metricSlug,
  isTarget,
  targetEdit,
  onPaste,
) => (dimension) => {
  const t = intl.messages;

  const titleSuffix = schema.type === 'quantitative' && availableUnits.length > 0 && availableUnits[0].symbol
    ? ` (${availableUnits[0].symbol})`
    : '';

  // NOTICE: Forced, or having 'percentage' in the LAST dimension
  const hasPercentageValue = forcePercentage || (dimension.calculations || []).includes('percentage');

  let columns = [];
  // TODO: translate and all
  if(dimension.source === 'organization') {
    columns = (
      tableDimensions[dimension.by] || []
    )
    .map(slug => ({
      slug,
      text: `${ translateSlug(((schemaLabels || {}).dimensionValues || {})[dimension.by] )(slug) }`,
    }))
    .sort((a, b) => {
      if(a.text < b.text) { return -1; }
      if(a.text > b.text) { return 1; }
      return 0;
    })
    .map(({slug, text}) => ({
      title: (
        <ColumnTitle
          schemaLabels={schemaLabels}
          availableUnits={availableUnits}
          metricSlug={metricSlug}
          kpiValue={kpiValue || {}}
          edit={edit}
          name={slug}
          schema = { schema }
          isTarget = { isTarget }
          targetEditTooltip = {targetEdit && schema.type === 'qualitative'}
          text={text}
        />
      ),
      dataIndex: slug,
      key: slug,
      render: renderColumn(schema, edit, hasPercentageValue, config, schemaLabels, targetEdit, null, null, false, onPaste),
      hasPercentageValue,
    }))
  }
  if(dimension.source === 'singleton') {
    const renderAsMeta = schema && schema.source === 'calculated' && schema.type === 'quantitative';
    columns = [{
      title: (
        <ColumnTitle
          schemaLabels={schemaLabels}
          availableUnits={renderAsMeta ? availableUnits.filter(({slug}) => schema.allowedUnitSlugs.includes(slug)) : availableUnits}
          metricSlug={metricSlug}
          kpiValue={kpiValue || {}}
          edit={edit}
          name={dimension.by}
          text={
            `${ translateSlug((schemaLabels || {}).dimensionNames)(dimension.by) }`
          }
          schema={schema}
          isTarget = { isTarget }
          targetEditTooltip = {targetEdit && schema.type === 'qualitative'}
        />
      ),
      dataIndex: dimension.by,
      key: dimension.by,
      render: renderColumn(schema, edit, hasPercentageValue, config, schemaLabels, targetEdit, null, null, renderAsMeta, onPaste),
      hasPercentageValue,
    }];
  }
  if(dimension.source === 'standard' || dimension.source === 'user') {
    columns = (dimension.standardItems || []).map(({ slug, name }) => ({ // TODO: translate?
      title: `${ name || translateSlug(((schemaLabels || {}).dimensionValues || {})[dimension.by] )(slug) }${titleSuffix}`,
      dataIndex: slug,
      key: slug,
      render: renderColumn(schema, edit, hasPercentageValue, config, schemaLabels, targetEdit, null, null, false, onPaste),
      hasPercentageValue,
    }));
  }

  // NOTICE: this is for HOMOGENEOUS tables only, so 'rowproduct' is not considered
  return  (dimension.calculations || []).includes('total')
    ? columns.concat({
      title: () => <em>{ t.sum_total }</em>,
      dataIndex: SUM_SLUG,
      key: SUM_SLUG,
      render: renderMetaColumn(schema, config, schemaLabels),
      className: 'KpiDetail__answer-table-meta',
    })
    : columns
};

const getColumnTotals = (value, schema, columns, countBase, availableUnits, aggregationUnit, t) => {
  
  if(!value) {
    return [];
  }

  const isHeterogeneous = schema?.innerSchema.type === 'tuple';

  return columns.map((data, columnIndex) => {
    if(columnIndex === 0) {
      return t.sum_total;
    }
    if(columnIndex < countBase) {
      return '';
    }

    if(data.dataIndex === PRODUCT_SLUG) {
      // The bottom-right corner with the grand total
      const result = Object.keys(value || {}).reduce((acc, v) => {
        const row = value[v];
        const product = Object.keys(row || {}).reduce((acc, v) => {
          const val = row[v]?.value;
          return acc * val;
        }, 1)
        return acc + product;
      }, 0)

      return isNaN(result) ? 0 : result;
    }


    let results = Object.values(value);
    for(let i=0; i < countBase; i++) {
      if(i < countBase-1) {
        // Flatten all levels except the last one
        results = results
          .map(result => Object.values(result))
          .reduce((arr, el) => arr.concat(el),[])
      } else {
        results = results
          .map(result => Object.entries(result))
          .reduce((arr, el) => arr.concat(el), [])
      }
    }

    if(data.dataIndex === SUM_SLUG) {
      // The bottom-right corner with the grand total
      const baseUnit = availableUnits?.find(unit => unit.is_base);
      const convertToBase = baseUnit && convertToUnit(baseUnit.slug, availableUnits);
      return results
        .map(([key, val]) => convertToBase ? convertToBase(val) : val)
        .reduce((acc, el) => {
          const value = Number((el || {}).value || 0);
          return acc + value;
        }, 0);
    }

    // NOTICE: In the case of heterogeneous tables, availableUnits will not be an Array but an Object
    const units = isHeterogeneous 
                    ? availableUnits?.[columnIndex - countBase] 
                    : availableUnits?.[data.dataIndex] || (
                      Array.isArray(availableUnits)
                      ? availableUnits
                      : []
                    );

    const firstDeclaredUnit = (Array.isArray(units) && units.length && units[0]?.slug) || null;

    const totalUnit = Object.values(value)?.find(_value => _value[data.dataIndex]?.__total_unit)?.[data.dataIndex].__total_unit 
                      || aggregationUnit 
                      || firstDeclaredUnit 
                      || units?.find(unit => unit.is_base)?.slug;

    const unit = units?.find(
      unit => unit.slug === totalUnit
    );

    const convertToTargetUnit = unit && convertToUnit(unit.slug, units);

    // Every other sub-total
    return results
      .filter(([key, val]) => key === data.dataIndex)
      .map(([key, val]) => convertToTargetUnit ? convertToTargetUnit(val) : val)
      .reduce((acc, el) => {
        const value = Number((el || {}).value || 0);
        return acc + value;
      }, 0);
  });
};

export {
  renderExtensibleColumn,
  renderMetaColumn,
  renderColumn,
  tupleComponentToColumn,
  dimensionToColumns,
  getColumnTotals,
}
