import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import CustomTable from 'components/CustomTable';
import Avatar from 'components/Avatar';
import { formatDate } from 'utils/date';

import {
  Row,
  Col
} from 'antd';
import { UserOutlined } from '@ant-design/icons';


const History = ({
  intl,
  activity,
}) => {
  const t = intl.messages;
  const columns = useMemo(
    () => (
      [{
        title: t.name,
        dataIndex: 'member_name',
        render: (name, record) => (
          <React.Fragment>
            <Row
              type="flex"
              gutter={10}
              justify="start"
              align="middle"
              style={{ flexWrap: 'nowrap' }}
            >
              <Col>
                <Avatar
                  src={record.member_avatar}
                  name={record.member_name}
                  shape="circle"
                  size={38}
                  icon={<UserOutlined />}
                />
              </Col>
              <Col>
                <Row type="flex" gutter={2}>
                  <Col className="DataManagement__nobreak">
                    { name }
                  </Col>
                </Row>
                <div className="DataManagement__nobreak">
                  { record.member_email }
                </div>
              </Col>
            </Row>
          </React.Fragment>
        ),
      }, {
        title: t.date,
        dataIndex: 'created_at',
        key: 'date',
        render: (created_at) => (
          <span>
            {`${formatDate(created_at, 'D MMM YYYY HH:mm', intl)}`}
          </span>
        ),
      }, {
        title: t.comment,
        dataIndex: 'comment',
      }]
    ),
    [t, intl]
  );

  return (
    <Row>
      <Col span={24}>
        <h3>{t.analysis_result_history}</h3>
      </Col>
      <Col span={24}>
        <CustomTable
          columns={columns}
          dataSource={activity}
          rowKey='id'
        />
      </Col>
    </Row>
  )
}

export default injectIntl(History);
