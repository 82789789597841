import React, {  Fragment, useCallback } from 'react';
import { injectIntl } from 'react-intl';

import CustomButton from 'components/CustomButton';

import {
  Row,
  Col,
  Form,
  Input,
  Tag,
} from 'antd';

import './style.less';

const { TextArea } = Input;

const SurveyEmailForm = ({
  intl,
  recipients,
  surveySubjects,
  sendSurveyEmail,
  emailContext,
}) => {
  const t = intl.messages;
  const [form] = Form.useForm();

  const onSubmit = useCallback(
    () => {
      form.validateFields().then(values => {
        sendSurveyEmail(values);
        form.resetFields();
      });
    },
    [form, sendSurveyEmail]
  )

  return (
    <Fragment>
      <Row justify="center" type="flex">
        <Col span={16}>
          <Form
            form={form}
            initialValues={{
              subject: emailContext.subject,
              email: emailContext.email
            }}
          >
            <Form.Item
              label={t.title}
              name='subject'
              rules={[{
                required: true,
                message: t.form_error_required,
              }]}
            >
              <Input placeholder={ '' } />
            </Form.Item>
            <Form.Item
              label={t.text}
              name='email'
              rules={[{
                required: true,
                message: t.form_error_required,
              }]}
            >
              <TextArea placeholder="" rows="10" />
            </Form.Item>
          </Form>
          <h4>{t.recipients}:</h4>
          {recipients.map(recipient => (
            <Tag key={recipient.email} color="#107065">{recipient.email}</Tag>
          ))}
          <h4>{t.issues}:</h4>
          {surveySubjects.map(subject => (
            <Tag key={subject.id} color="#107065">{ subject.issue || subject.subject}</Tag>
          ))}
        </Col>
      </Row>
      <Row justify="center" type="flex">
        <Col span={16}>
          <Row justify="end" type="flex">
            <CustomButton
              type="primary"
              onClick={onSubmit}
            >
              {t.send}
            </CustomButton>
          </Row>
        </Col>
      </Row>
    </Fragment>
  );
}

export default injectIntl(SurveyEmailForm);
