import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import './style.css';

const T = ({ intl, ...props }) => {
  const t = intl.messages;
  return (
    <React.Fragment>
      {
        Object.entries(props).map(([prop, value]) => {
          if(value === true) {
          return <span key={prop}>{ t[prop] }</span>;
          } else {
            return (
              <FormattedMessage
                key={prop}
                id={prop}
                defaultMessage={t[prop]}
                values={value}
              />
            );
          }
        })
      }
    </React.Fragment>
  );
}

export default injectIntl(T);
