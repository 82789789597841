import React, {useMemo, useCallback, useState} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import {
  Radio,
} from 'antd';

import { getUnionExistingPeriodicities } from 'utils/dataRequests';
import RecurrentNotificationDate from './RecurrentNotificationDate';
import Reminder from './Reminder';
import Calendar from './Calendar';
import InfoTooltip from 'components/InfoTooltip';
import PeriodsSelector from 'components/PeriodsSelector';
import CustomModalType from 'components/CustomModalType';
import InfoTooltipContent from './InfoTooltipContent';

import './style.less';

const FORM_KEY_RECURRENCY = 'recurrency';
const FORM_KEY_RECURRENCY_TYPES = {
    recurrent: 'recurrent',
    one_time: 'one_time',
};

const DEFAULT_FORM_VALUES = [
    {key: 'periodicities', defaultValue: []},
    {key: 'periods', defaultValue: []},
    {key: 'notif_number', defaultValue: null},
    {key: 'notif_time', defaultValue: null},
    {key: 'notif_relative', defaultValue: null},
    {key: 'reminder', defaultValue: false},
    {key: 'reminder_periodicity', defaultValue: 'week'},
    {key: 'notif_date', defaultValue: ''}
];

const WarningModalContent = ({
    intl,
    newRequestType,
}) => {
  
    const currentRequestType = useMemo(() => {
      return newRequestType ===  FORM_KEY_RECURRENCY_TYPES.recurrent ? FORM_KEY_RECURRENCY_TYPES.one_time : FORM_KEY_RECURRENCY_TYPES.recurrent;
    }, [newRequestType])
  
    return (
      <div className="WarningModalContent">
        <div className="WarningModalContent__message">
          {intl.formatMessage(
            {id: `step_2_warning_modal_message`},
            {
              current: <b style={{color: '#D38106'}}>{intl.formatMessage({id: `step_2_warning_modal_message_${currentRequestType}`})}</b>,
              new: <b style={{color: '#D38106'}}>{intl.formatMessage({id: `step_2_warning_modal_message_${newRequestType}`})}</b>,
              currentData: intl.formatMessage({id: `step_2_warning_modal_message_${currentRequestType}`}),
            }
          )}
        </div>
        <div className="WarningModalContent__question">
          {intl.formatMessage({id: `step_2_warning_modal_question`})}
        </div>
      </div>
    );
};

const Step2 = ({
    intl,
    handleChange,
    values,
    notificationNumberError,
}) => {

    const [showWarningModal, setShowWarningModal] = useState(false);
    const [typeToChange, setTypeToChange] = useState(null);

    const isRecurrent = useMemo(() => values?.recurrency === FORM_KEY_RECURRENCY_TYPES.recurrent, [values]);
    const isOneTime = useMemo(() => values?.recurrency === FORM_KEY_RECURRENCY_TYPES.one_time, [values]);

    const allowedPeriodicities = useMemo(
        () => getUnionExistingPeriodicities(values?.selected_kpis, values?.selected_nodes),
        [ values?.selected_kpis, values?.selected_nodes ]
    );

    const formValues = useMemo(() => {
        return values?.recurrency ? { [values?.recurrency]: values } : null;
    }, [values])

    const handleFormChange = useCallback((type) => {
        DEFAULT_FORM_VALUES.forEach(({key, defaultValue}) => handleChange(key)(defaultValue));
        handleChange(FORM_KEY_RECURRENCY)(type);
        if (values?.recurrency) {
            setTypeToChange(null);
            setShowWarningModal(false);
        }
    }, [
        handleChange,
        values?.recurrency,
    ]);

    const onChangeRequestType = useCallback(() => {
        handleFormChange(typeToChange);
    }, [
        handleFormChange,
        typeToChange,
    ]);

    const handleChangeRequestType = useCallback((type) => {
        if (values?.recurrency) {
            setTypeToChange(type);
            setShowWarningModal(true);
        } else {
            handleFormChange(type);
        }
    }, [
        handleFormChange,
        values?.recurrency
    ]);

    const onCancelWarningModal = useCallback(() => {
        setShowWarningModal(false);
    }, []);
    
    return (
        <div className="Step2">
            <div className="Step2__message">{intl.formatMessage({id: 'step_2_when_to_send'})}</div>
            <div className="Step2__totalkpis">
                {intl.formatMessage(
                    {id: 'step_2_total_kpis_selected'},
                    {value: values?.selected_kpis?.length || 0}
                )}
            </div>
            <div className="Step2__forms">
                <div className="Step2__form">
                    <div className="Step2__typeSelector">               
                        <Radio
                            className="Step2__recurrent"
                            onChange={(e) => handleChangeRequestType(e.target.value)}
                            value={FORM_KEY_RECURRENCY_TYPES.recurrent}
                            checked={isRecurrent}
                        >
                            {intl.formatMessage({id: 'step_2_radio_recurrent'})}
                        </Radio>
                        <InfoTooltip
                            content={(
                                <InfoTooltipContent
                                    type={FORM_KEY_RECURRENCY_TYPES.recurrent}
                                />
                            )}
                        />
                    </div>
                    <div className="Step2__PeriodsSelector">
                        <PeriodsSelector
                            type={FORM_KEY_RECURRENCY_TYPES.recurrent}
                            disabled={isOneTime}
                            handleChange={handleChange}
                            periodicityValues={formValues?.recurrent?.periodicities || []}
                            allowedPeriodicities={allowedPeriodicities}
                            periodValues={formValues?.recurrent?.periods || []}
                            recurrency={values?.recurrency}
                        />
                    </div>
                    <RecurrentNotificationDate
                        intl={intl}
                        disabled={isOneTime}
                        handleChange={handleChange}
                        notificationNumber={formValues?.recurrent?.notif_number}
                        notificationTime={formValues?.recurrent?.notif_time}
                        notificationRelative={formValues?.recurrent?.notif_relative}
                        notificationNumberError={notificationNumberError}
                        recurrency={values?.recurrency}
                    />
                    <div className="Step2__Reminder">
                        <Reminder
                            recurrencyType={FORM_KEY_RECURRENCY_TYPES.recurrent}
                            intl={intl}
                            disabled={isOneTime}
                            handleChange={handleChange}
                            checked={formValues?.recurrent?.reminder}
                            reminderPeriodicity={formValues?.recurrent?.reminder_periodicity || 'week'}
                            recurrency={values?.recurrency}
                        />
                    </div>
                </div>
                <div className="Step2__form">
                    <div className="Step2__typeSelector">
                        <Radio
                            className="Step2__oneTime"
                            onChange={(e) => handleChangeRequestType(e.target.value)}
                            value={FORM_KEY_RECURRENCY_TYPES.one_time}
                            checked={isOneTime}
                        >
                            {intl.formatMessage({id: 'step_2_radio_one_time'})}
                        </Radio>
                        <div className="Step2__infoTooltipWrapper">
                            <InfoTooltip
                                content={(
                                    <InfoTooltipContent
                                        type={FORM_KEY_RECURRENCY_TYPES.one_time}
                                    />
                                )}
                            />
                            <InfoTooltip
                                type='warning'
                                content={
                                    <span className="Step2__warningTooltipContent">
                                        {intl.formatMessage(
                                            {id: `step_2_warning_tooltip_limit_periods`},
                                            {boldValue: <b>{intl.formatMessage({id: `step_2_warning_tooltip_limit_periods_bold`})}</b>}
                                        )}
                                    </span>
                                }
                            />
                        </div>
                    </div>
                    <div className="Step2__PeriodsSelector">
                        <PeriodsSelector
                            type={FORM_KEY_RECURRENCY_TYPES.one_time}
                            disabled={isRecurrent}
                            handleChange={handleChange}
                            periodicityValues={formValues?.one_time?.periodicities || []}
                            allowedPeriodicities={allowedPeriodicities}
                            periodValues={formValues?.one_time?.periods || []}
                            recurrency={values?.recurrency}
                        />
                    </div>
                    <Calendar
                        title= {intl.formatMessage({id: 'step_2_select_unique_date'})}
                        disabled={isRecurrent}
                        handleChange={handleChange}
                        formName='notif_date'
                        value={formValues?.one_time?.notif_date || ''}
                        recurrency={values?.recurrency}
                    />
                    <div className="Step2__Reminder">
                        <Reminder
                            recurrencyType={FORM_KEY_RECURRENCY_TYPES.one_time}
                            intl={intl}
                            disabled={isRecurrent}
                            handleChange={handleChange}
                            checked={formValues?.one_time?.reminder}
                            reminderPeriodicity={formValues?.one_time?.reminder_periodicity || 'week'}
                            recurrency={values?.recurrency}
                        />
                    </div>
                </div>
            </div>
            <CustomModalType
                showModal={showWarningModal}
                type='warning'
                icon='Alert'
                iconType='fas'
                onOk={() => onChangeRequestType()}
                onCancel={() => onCancelWarningModal()}
                onOkText={intl.formatMessage({id: `step_2_warning_modal_ok`})}
                onCancelText={intl.formatMessage({id: `step_2_warning_modal_cancel`})}
                title={intl.formatMessage({id: `step_2_warning_modal_title`})}
                content={(
                    <WarningModalContent newRequestType={typeToChange} intl={intl}/>
                )}
            />
        </div>
    );
};

Step2.propTypes = {
    notificationNumberError: PropTypes.string,
    values: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
};

export default injectIntl(Step2);