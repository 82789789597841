import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  useSelector,
} from 'react-redux';

import CustomTag from 'components/CustomTag'
import A from 'components/A';
import AplanetIcon from 'components/AplanetIcon';
import SearchFilter from 'components/SearchFilter';
import FiltersPanel from 'components/FiltersPanel';

import useOrganizations from 'utils/useOrganizations';
import { useEventTracking } from 'hooks/useEventTracking';
import { useFeatureList } from 'components/FeatureSwitch';

import {
  FILTER_TYPES,
  FILTERS_COMPONENT_TYPES_CATALOG,
} from 'hooks/useScreenFilters/constants';

import {
  Col,
  Row,
  Button,
} from 'antd';

import './style.less';


const REPORTING_STANDARDS = [
  'aplanet',
  'gri',
  'gri-2021',
  'equality',
  'sdg',
  'sdgc',
  'bcorp',
  'einf',
  'euss',
  'ungc',
  'tefce',
  'prme',
  'sasb-hc-dy',
  'sasb-hc-di',
  'sasb-hc-dr',
  'sasb-hc-mc',
  'sasb-hc-ms',
  'sasb-hc-bp',
  'sasb-fn-ac',
  'sasb-fn-cb',
  'sasb-fn-cf',
  'sasb-fn-in',
  'sasb-fn-ib',
  'sasb-fn-mf',
  'sasb-fn-ex',
  'sasb-cg-aa',
  'sasb-cg-mr',
  'tcfd',
  'sasb-fb-fr',
  'sasb-if-re',
  'sfdr',
  'shift',
  'scority',
  'ghg',
  'neutrality',
  'custom',
];

const PERIODICITY_OPTIONS = ['year', 'semester', 'quarter', 'month'];
const SCHEMA_TYPE_OPTIONS = ['qualitative', 'table', 'quantitative', 'boolean'];
const KPI_SOURCE_OPTIONS = ['manual', 'children', 'calculated', 'aggregated'];

const Filters = ({
  intl,
  filterState,
  resetAllFilters,
  setAllFilters,
  areFiltersSet,
  isKpiNameSearchSet,
  kpiNameSearch,
  onChangeKpiSearch,
  handleDownload,
}) => {
  // Filters
  const [standardFilterValues, setStandardFilterValues] = useState(filterState.standard_array_filter);
  const [kpiFilterValues, setKpiFilterValues] = useState(filterState.kpis);
  const [organizationFilterValues, setOrganizationFilterValues] = useState(filterState.suborganizations_filter);
  const [statusFilterValues, setStatusFilterValues] = useState(filterState.value_status_filter);
  const [schemaTypeFilterValues, setSchemaTypeFilterValues] = useState(filterState.schema_type_filter);
  const [sourceFilterValues, setSourceFilterValues] = useState(filterState.source_filter);
  const [periodDateFilterValues, setPeriodDateFilterValues] = useState(filterState.period_date_filter);
  const [periodicityFilterValues, setPeriodicityFilterValues] = useState(filterState.periodicity_filter);
  const [tagsFilterValues, setTagsFilterValues] = useState(filterState.tag_filter);
  const [validationStatusFilterValues, setValidationStatusFilterValues] = useState(filterState.validation_status_filter);

  // Search
  const [hasToClearKpiSearch, setHasToClearKpiSearch] = useState(false);

  const {
    tags = [],
  } = useSelector(state => state.taxonomies);

  const {
    features: featureList,
  } = useFeatureList();

  const {
    data: userProfile,
  } = useSelector(state => state.profile);

  const {
    data: reportingStructure,
    loading: loadingReportingStructure,
  } = useSelector(state => state.reporting_structure);

  const {
    organizationTree,
    organization,
    isMainOrganization,
  } = useOrganizations();
  const eventTracking = useEventTracking();

  const enabledReports = useMemo(() => [
    ...organization?.config?.enabled_reports || [],
    organization.slug,
], [ organization ]);

  const patchedTags = useMemo(() => {
    return tags.map(tag => ({
      slug: tag,
      name: tag,
    }));
  }, [
    tags,
  ]);

  const reportingStandards = useMemo(() => {
    const enabled = new Set([
      ...((organization.config || {}).enabled_reports || []),
      organization.slug,
    ]);
    return REPORTING_STANDARDS
      .filter(slug => enabled.has(slug))
      .map(slug => ({
        slug,
        name: intl.formatMessage({
          id: `reporting_standard_${slug === organization.slug ? 'custom' : slug}`
        }),
        key: slug
      }));
  }, [
    organization,
    intl,
  ]);

  const kpiStatuses = useMemo(() => {
    return ['pending', 'uptodate'].map(slug => ({
      value: slug,
      label: intl.formatMessage({id: `kpi_status_${slug}`}),
    }));
  }, [
    intl,
  ]);

  const validationStatuses = useMemo(() => {
    return ['null', 'pending', 'validated'].map(slug => ({
      value: slug,
      label: intl.formatMessage({id: `item_manager_validation_status_${slug}`}),
    }));
  }, [
    intl,
  ]);

  const handleSelectOrganization = useCallback(
    ({checked}) => setOrganizationFilterValues(checked),
    [],
  );

  const allKpis = useMemo(
    () => {
      let kpis = [];

      for (const node of (reportingStructure || [])) {
        if (node.kpis?.length) {
          node.kpis.forEach(kpi => kpis.push(kpi));
        }
      }
      return kpis;
    },
    [reportingStructure]
  );

  const selectedKpis = useMemo(
    () => allKpis.filter(({uuid}) => kpiFilterValues.includes(uuid)),
    [
      allKpis,
      kpiFilterValues
    ],
  );

  const handleSelectKpis = useCallback(
    (kpis) => {
      setKpiFilterValues(kpis.map(({section_child_id}) => section_child_id));
    },
    [],
  );

  const filtersConfig = useMemo(() => (
    [
      {
        columnTitle: intl.formatMessage({id: `filter_bulk_manage_column_kpis`}),
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.kpiTreeSelect, // TODO: Take out KpiTreeSelector from Data Requests
            reportingStructure,
            onSelectKpis: handleSelectKpis,
            selectedKpis: selectedKpis,
            enabledReports,
            loading: loadingReportingStructure,
            allowSelectionDisable: false,
            showScroll: true,
          },
        ]
      },
      {
        columnTitle: intl.formatMessage({id: `filter_bulk_manage_column_organization`}),
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.organizationSelect,
            tree: organizationTree,
            currentSelection: organizationFilterValues,
            onSelect: handleSelectOrganization,
            autoFocus: false,
            isMultipleOrgSelection: true,
            showEmptySearchIcon: true,
            showCurrentOrganizationTree: !isMainOrganization,
          },
        ]
      },
      {
        columnTitle: intl.formatMessage({id: `filter_bulk_manage_column_status`}),
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.checkbox,
            listOptions: kpiStatuses,
            setSelectedOptions: setStatusFilterValues,
            selectedOptions: statusFilterValues,
            hideDivider: true,
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.heading,
            columnTitle: intl.formatMessage({id: `filter_bulk_manage_column_validation_status`}),
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.checkbox,
            listOptions: validationStatuses,
            setSelectedOptions: setValidationStatusFilterValues,
            selectedOptions: validationStatusFilterValues,
            hideDivider: true,
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.heading,
            columnTitle: intl.formatMessage({id: `filter_bulk_manage_column_schema_type`}),
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.checkbox,
            listOptions: SCHEMA_TYPE_OPTIONS.map(option => ({
              value: option,
              label: intl.formatMessage({id: `filter_bulk_manage_schema_type_option_${option}`})
            })),
            setSelectedOptions: setSchemaTypeFilterValues,
            selectedOptions: schemaTypeFilterValues,
            hideDivider: true,
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.heading,
            columnTitle: intl.formatMessage({id: `filter_bulk_manage_column_source`}),
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.checkbox,
            listOptions: KPI_SOURCE_OPTIONS.map(option => ({
              value: option,
              label: intl.formatMessage({id: `kpi_source_${option}`})
            })),
            setSelectedOptions: setSourceFilterValues,
            selectedOptions: sourceFilterValues,
            hideDivider: true,
          },
        ]
      },
      {
        columnTitle: intl.formatMessage({id: `filter_bulk_manage_column_periods`}),
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.date,
            filterTitle: intl.formatMessage({id: `filter_bulk_manage_periods_date`}),
            picker: 'date',
            format: 'YYYY-MM-DD',
            suffixIcon: (<AplanetIcon name="Calendar" />),
            value: periodDateFilterValues,
            onChange: setPeriodDateFilterValues,
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.list,
            filterTitle: intl.formatMessage({ id: `filter_bulk_manage_periodicity` }),
            listOptions: PERIODICITY_OPTIONS.map(option => ({
              value: option,
              title: intl.formatMessage({id: `filter_bulk_manage_periodicity_option_${option}`}),
              label: <CustomTag name={intl.formatMessage({id: `filter_bulk_manage_periodicity_option_${option}`})} colorclass={option} />
            })),
            setSelectedOptions: setPeriodicityFilterValues,
            selectedOptions: periodicityFilterValues,
            hideDivider: true,
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.heading,
            columnTitle: intl.formatMessage({id: `filter_bulk_manage_column_standards`}),
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.multiSelect,
            filterTitle: intl.formatMessage({ id: `filter_bulk_manage_standard` }),
            title: intl.formatMessage({id: `filter_bulk_manage_standard_placeholder`}),
            options: reportingStandards,
            onSelect: setStandardFilterValues,
            selected: standardFilterValues,
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.heading,
            columnTitle: intl.formatMessage({id: `filter_bulk_manage_tags`}),
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.multiSelect,
            title: intl.formatMessage({id: `filter_bulk_manage_tags_placeholder`}),
            options: patchedTags,
            onSelect: setTagsFilterValues,
            selected: tagsFilterValues,
          }
        ],
      },
    ]
  ), [
    intl,
    patchedTags,
    reportingStandards,
    organizationTree,
    reportingStructure,
    enabledReports,
    kpiStatuses,
    validationStatuses,

    standardFilterValues,
    selectedKpis,
    organizationFilterValues,
    statusFilterValues,
    schemaTypeFilterValues,
    sourceFilterValues,
    periodDateFilterValues,
    periodicityFilterValues,
    tagsFilterValues,
    validationStatusFilterValues,

    loadingReportingStructure,
    isMainOrganization,
    handleSelectOrganization,
    handleSelectKpis,
  ]);

  const onSetFilters = useCallback(() => {
    eventTracking.capture('bulkManage.filters', {
      standard: standardFilterValues?.length ? 1 : 0,
      'Indicator tree': kpiFilterValues?.length ? 1 : 0,
      'Organization tree': organizationFilterValues?.length ? 1 : 0,
      'Status: need data': statusFilterValues?.includes('pending') ? 1 : 0,
      'Status: contains data': statusFilterValues?.includes('uptodate') ? 1 : 0,
      'Validation status: not under validation': validationStatusFilterValues?.includes('null') ? 1 : 0,
      'Validation status: in progress': validationStatusFilterValues?.includes('pending') ? 1 : 0,
      'Validation status: validated': validationStatusFilterValues?.includes('validated') ? 1 : 0,
      'Indicator type: text': schemaTypeFilterValues?.includes('qualitative') ? 1 : 0,
      'Indicator type: table': schemaTypeFilterValues?.includes('table') ? 1 : 0,
      'Indicator type: number': schemaTypeFilterValues?.includes('quantitative') ? 1 : 0,
      'Indicator type: boolean': schemaTypeFilterValues?.includes('boolean') ? 1 : 0,
      'Data source: manual': sourceFilterValues?.includes('manual') ? 1 : 0,
      'Data source: nodes': sourceFilterValues?.includes('children') ? 1 : 0,
      'Data source: formula': sourceFilterValues?.includes('calculated') ? 1 : 0,
      'Data source: aggregation': sourceFilterValues?.includes('aggregated') ? 1 : 0,
      'Periods': periodicityFilterValues,
      'date_range': periodDateFilterValues?.length ? 1 : 0,
      'tags': tagsFilterValues?.length ? 1 : 0,
    });
    setAllFilters({
      [FILTER_TYPES.standard_array_filter]: standardFilterValues,
      [FILTER_TYPES.kpis]: kpiFilterValues,
      [FILTER_TYPES.suborganizations_filter]: organizationFilterValues,
      [FILTER_TYPES.value_status_filter]: statusFilterValues,
      [FILTER_TYPES.schema_type_filter]: schemaTypeFilterValues,
      [FILTER_TYPES.source_filter]: sourceFilterValues,
      [FILTER_TYPES.period_date_filter]: periodDateFilterValues,
      [FILTER_TYPES.periodicity_filter]: periodicityFilterValues,
      [FILTER_TYPES.tag_filter]: tagsFilterValues,
      [FILTER_TYPES.text_filter]: filterState.text_filter,
      [FILTER_TYPES.validation_status_filter]: validationStatusFilterValues,
    });
  }, [
    setAllFilters,
    standardFilterValues,
    kpiFilterValues,
    organizationFilterValues,
    statusFilterValues,
    schemaTypeFilterValues,
    sourceFilterValues,
    periodDateFilterValues,
    periodicityFilterValues,
    tagsFilterValues,
    validationStatusFilterValues,
    filterState,
    eventTracking,
  ]);

  const onCancelFilter = useCallback(() => {
    setStandardFilterValues(filterState.standard_array_filter);
    setKpiFilterValues(filterState.kpis);
    setOrganizationFilterValues(filterState.suborganizations_filter);
    setStatusFilterValues(filterState.value_status_filter);
    setSchemaTypeFilterValues(filterState.schema_type_filter);
    setSourceFilterValues(filterState.source_filter);
    setPeriodDateFilterValues(filterState.period_date_filter);
    setPeriodicityFilterValues(filterState.periodicity_filter);
    setTagsFilterValues(filterState.tag_filter);
    setValidationStatusFilterValues(filterState.validation_status_filter);
  }, [
    filterState.standard_array_filter,
    filterState.kpis,
    filterState.suborganizations_filter,
    filterState.value_status_filter,
    filterState.schema_type_filter,
    filterState.source_filter,
    filterState.period_date_filter,
    filterState.periodicity_filter,
    filterState.tag_filter,
    filterState.validation_status_filter,
  ]);

  const onClearSearchAndFilters = useCallback(() => {
    setStandardFilterValues([]);
    setKpiFilterValues([]);
    setOrganizationFilterValues([]);
    setStatusFilterValues([]);
    setSchemaTypeFilterValues([]);
    setSourceFilterValues([]);
    if (filterState.period_date_filter?.length) {
      setPeriodDateFilterValues([]);
    }
    setPeriodicityFilterValues([]);
    setTagsFilterValues([]);
    setValidationStatusFilterValues([]);
    setHasToClearKpiSearch(true);
    resetAllFilters();
  }, [
    resetAllFilters,
    filterState.period_date_filter,
  ]);

  return (
    <Row
      className="BulkManageFilters"
      type="flex"
      justify='space-between'
      align='middle'
      gutter={[10, 10]}
    >
      <Col>
        <Row
          gutter={16}
          type="flex"
          align="middle"
        >
          <Col>
            <FiltersPanel
              areFiltersSet={areFiltersSet}
              onCancelFilter={onCancelFilter}
              onSetFilters={onSetFilters}
              filtersConfig={filtersConfig}
            />
          </Col>
          <Col>
            <SearchFilter
              textFilter={kpiNameSearch}
              onSetSearch={onChangeKpiSearch}
              hasToClearSearch={hasToClearKpiSearch}
              setHasToClearKpiSearch={setHasToClearKpiSearch}
              defaultOpen={isKpiNameSearchSet}
              placeholder={intl.formatMessage({id: `filter_list_search_placeholder`})}
              autoFocus
            />
          </Col>
          <Col>
            <A
              className="BulkManageFilters__clear_filters_button"
              onClick={onClearSearchAndFilters}
              disabled={!areFiltersSet && !isKpiNameSearchSet}
            >
              {intl.formatMessage({ id: 'filter_list_clear_filters' })}
            </A>
          </Col>
          <Col>
            {(featureList.has('bulk_manage_kpis_export') || (userProfile.role === 'system')) &&
            <Button
              type="primary"
              onClick={handleDownload}
              icon={<AplanetIcon name="arrow-down-to-line" />}
            />
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default injectIntl(Filters);
