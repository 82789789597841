export const fullValidation = (t) => ({
  size_of_company,
  composition_of_template,
  activity_of_company,
  positioning_of_company,
  start_date,
  end_date,
}) => {
  let errors = {};

  if (!start_date) {
    errors.start_date = t.form_error_required;
  }

  if (!end_date) {
    errors.end_date = t.form_error_required;
  }

  return errors;
};

export const measuresValidation = (t) => ({
  area,
  name,
}) => {
  let errors = {};
  if (!area) {
    errors.area = t.form_error_required;
  }

  if (!name) {
    errors.name = t.form_error_required;
  }

  return errors;
};