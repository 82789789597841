export const fullValidation = (t) => ({
  employee_id,
  gender,
  position_id,
  salary,
  salary_cgp,
  salary_cp,
  salary_f,
  points,
}) => {
  let errors = {};

  if (!employee_id) {
    errors.employee_id = t.form_error_required;
  }

  if (!gender) {
    errors.gender = t.form_error_required;
  }

  if (!position_id) {
    errors.position_id = t.form_error_required;
  }

  if (!salary) {
    errors.salary = t.form_error_required;
  }

  return {
    ...errors,
  };
};