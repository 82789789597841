import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';

import {
  requestEqualityPlanPositions,
  requestEqualityPlanProfessionalGroups,
  updateEqualityPlanPosition,
  updateEqualityPlanProfessionalGroup,
} from 'actions/api';

import {
  Row,
  Col,
  InputNumber,
  Space,
} from 'antd';
import CustomTable from 'components/CustomTable';
import FloatingButton from 'components/FloatingButton';
import { withRouter } from 'react-router';


const WageData = ({
  intl,
  history,
  plan,
  updatePlanProgress,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();
  const [positionSalaries, setPositionSalaries] = useState();
  const [professionalGroupSalaries, setProfessionalGroupSalaries] = useState();

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanPositions(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanProfessionalGroups(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetchingPositions,
    data: positions,
  } = useSelector(state => state.equality_plan_position);
  const {
    fetchingProfessionalGroups,
    data: professionalGroups,
  } = useSelector(state => state.equality_plan_professional_group);
  const {
    metrics,
  } = useSelector(state => state.taxonomies);

  useEffect(
    () => {
      if (positions && !positionSalaries) {
        setPositionSalaries(
          positions.reduce(
            (salaries, current) => {
              return {
                ...salaries,
                [current.id]: current.salary,
              };
            },
            {},
          )
        )
      }
    },
    [positions, positionSalaries]
  );

  useEffect(
    () => {
      if (professionalGroups && !professionalGroupSalaries) {
        setProfessionalGroupSalaries(
          professionalGroups.reduce(
            (salaries, current) => {
              return {
                ...salaries,
                [current.id]: current.salary,
              };
            },
            {},
          )
        )
      }
    },
    [professionalGroups, professionalGroupSalaries]
  );

  const currency = useMemo(
    () => {
      const orgCurrency = suborganization?.product_config?.atlas?.preferred_units?.currency?.[0];

      if (!orgCurrency) {
        return;
      }

      return metrics.find(
        metric => metric.slug === 'currency'
      )?.metrics.find(
        metric => metric.slug === 'currency'
      )?.units.find(unit => unit.slug === orgCurrency);
    },
    [suborganization, metrics]
  );

  const positionColumns = useMemo(
    () => ([{
      title: t.equality_job_evaluation_wage_data_position,
      dataIndex: 'name',
    }, {
      title: t.equality_job_evaluation_wage_data_position_department,
      dataIndex: 'department_name',
    }, {
      title: t.equality_job_evaluation_wage_data_positions_professional_group,
      dataIndex: 'professional_group_name',
    }, {
      title: t.equality_job_evaluation_wage_data_position_salary,
      dataIndex: 'salary',
      render: (_, record) => (
        <Space>
          <InputNumber
            value={positionSalaries?.[record.id]}
            onChange={
              value => setPositionSalaries({
                ...positionSalaries, [record.id]: value
              })
            }
          />
          {currency?.symbol || ''}
        </Space>
      )
    }]),
    [t, positionSalaries, currency]
  );

  const professionalGroupColumns = useMemo(
    () => ([{
      title: t.equality_job_evaluation_wage_data_professional_group,
      dataIndex: 'name',
    }, {
      title: t.equality_job_evaluation_wage_data_professional_group_salary,
      dataIndex: 'salary',
      render: (_, record) => (
        <Space>
          <InputNumber
            value={professionalGroupSalaries?.[record.id]}
            onChange={
              value => setProfessionalGroupSalaries({
                ...professionalGroupSalaries, [record.id]: value
              })
            }
          />
          {currency?.symbol}
        </Space>
      )
    }]),
    [t, professionalGroupSalaries, currency]
  );

  const handleOnSave = useCallback(
    () => {
      positions.forEach(
        position => {
          const previousSalary = position.salary && parseFloat(position.salary);
          const newSalary = positionSalaries[position.id]
            && parseFloat(positionSalaries[position.id]);
          if (previousSalary !== newSalary) {
            dispatch(
              updateEqualityPlanPosition(
                organization.slug,
                suborganization.slug,
                plan.id,
                position.id,
                {
                  ...position,
                  salary: newSalary,
                }
              )
            );
          }
        }
      );

      professionalGroups.forEach(
        professionalGroup => {
          const previousSalary = professionalGroup.salary
            && parseFloat(professionalGroup.salary);
          const newSalary = professionalGroupSalaries[professionalGroup.id]
            && parseFloat(professionalGroupSalaries[professionalGroup.id]);
          if (previousSalary !== newSalary) {
            dispatch(
              updateEqualityPlanProfessionalGroup(
                organization.slug,
                suborganization.slug,
                plan.id,
                professionalGroup.id,
                {
                  ...professionalGroup,
                  salary: newSalary,
                }
              )
            );
          }
        }
      );

      updatePlanProgress('wages_by_agreements');
    },
    [
      organization,
      suborganization,
      plan,
      dispatch,
      positionSalaries,
      professionalGroupSalaries,
      positions,
      professionalGroups,
      updatePlanProgress,
    ]
  );

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  if (
    fetchingPositions
    || !positions
    || fetchingProfessionalGroups
    || !professionalGroups
  ) {
    return <Loading />;
  }
  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_wages_by_agreements}
        handleBackOrSkip={handleBack}
      />
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_wage_data_professional_groups}</h2>
        </Col>
        <Col span={24}>
          <CustomTable
            columns={professionalGroupColumns}
            dataSource={professionalGroups}
            rowKey="id"
            pagination={false}
          />
        </Col>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_wage_data_positions}</h2>
        </Col>
        <Col span={24}>
          <CustomTable
            columns={positionColumns}
            dataSource={positions}
            rowKey="id"
            pagination={false}
          />
        </Col>
      </Row>
      <FloatingButton
        onClick={() => handleOnSave()}
        iconUrl='/images/fa-save-regular.svg'
      />
    </>
  );
}

export default withRouter(injectIntl(WageData));
