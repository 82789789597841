import React from 'react';
import { injectIntl } from 'react-intl';
import { Layout, Spin } from 'antd';

import './style.less';

const { Content } = Layout;

const Big = ({ intl }) => {
  const t = intl.messages;
  return (
    <Layout className="Layout">
      <Content className="Content-noheader">
        <div className="Loading-back">
          <img src="/images/aplanet.png" alt={t.loading} />
        </div>
      </Content>
    </Layout>
  );
};

const Block = () => (
  <div data-testid="loading" className="Loading__container--block">
    <Spin size="large" />
  </div>
);

const Loading = {
  Big: injectIntl(Big),
  Block
};

export default Loading;
