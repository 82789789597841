import React from 'react';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'antd';
import './style.less';

const SdgMiniLogo = ({
  sdg,
  intl,
  unColored,
  text
}) => {

  const codeText = text || sdg.code;

  return (
    <Tooltip title={intl.formatMessage({id: `sdg_${sdg.slug}`})} overlayClassName="sdgLogo__tooltip">
      <figure className={`sdgLogo ${sdg.parent_slug}`} style={{borderLeft: unColored ? "3px solid #919897" :""}}>{codeText}</figure>
    </Tooltip>
  );
}

export default injectIntl(SdgMiniLogo);
