const TARGET = 'analysis_sus_issues';

const initialState = {
  error: null,
  data: [],
  fetching: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        fetching: action.method === 'GET',
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || response.target !== TARGET) return state;
      const result = response.result;
      switch(response.method) {
        case 'GET':
          return {
            ...state,
            data: result,
            fetching: false,
          };
        case 'POST':
          return {
            ...state,
            data: [...state.data, ...result],
          };
        case 'PUT':
          return {
            ...state,
            data: result,
          };
        case 'DELETE':
          const data = state.data.filter(d => !result.includes(d.id));
          return {...state, data};
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        error: action.code,
        fetching: false,
      };
    case 'LOAD_SUS_ISSUES':
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export {
  reducer as analysis_sus_issues,
};
