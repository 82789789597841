import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  useDispatch,
} from 'react-redux';

import CustomSelect from 'components/CustomSelect';
import ReadOnlyValue from './ReadOnlyValue';

import useOrganizations from 'utils/useOrganizations';
import { useAvailableUnits } from 'utils/useAvailableUnits';

import {
  requestKpiUpdate,
} from 'actions/api';


const Unit = ({
  kpi,
  position,
  mode,
  onChangeMode,
  canEdit = true,
}) => {
  const dispatch = useDispatch();
  const [initialValue, setInitialValue] = useState(kpi.kpi_value?.unit);
  const [value, setValue] = useState(kpi.kpi_value?.unit);

  const availableUnits = useAvailableUnits(kpi.schema) || [];
  const {
    organization: root_organization,
    permissions,
  } = useOrganizations();

  useEffect(
    () => {
      if (mode !== 'edit') {
        if (
          (initialValue === value)
          && (value !== kpi.kpi_value?.unit)
        ) {
          // Update all other duplicate records if one of them was changed
          setValue(kpi.kpi_value?.unit);
          setInitialValue(kpi.kpi_value?.unit);
        }

        if (
          (value === kpi.kpi_value?.unit)
          && (value !== initialValue)
        ) {
          // Update own initial value when reducer has same value as current
          setInitialValue(kpi.kpi_value?.unit);
        }
      }
    },
    [
      kpi.kpi_value,
      mode,
      value,
      initialValue,
    ],
  );

  const onChange = useCallback((unit) => {
    dispatch(
      requestKpiUpdate(
        root_organization.slug,
        kpi.organization_slug,
        kpi.slug,
        kpi.period,
        {
          value: {
            unit,
          },
        },
        { fieldToUpdate: 'unit' },
      )
    );
  }, [
    root_organization,
    dispatch,
    kpi,
  ]);

  const onSelectUnit = useCallback(unit => {
    setValue(unit);
    onChange(unit);
  }, [
    onChange,
  ]);

  const handleOnChangeMode = useCallback((mode) => {
    if (
      (kpi.kpi_source === 'manual') &&
      (kpi.ready_to_validate_level === 0) &&
      permissions.can_write_kpi
    ) {
      onChangeMode({
        column: 'unit',
        key: position,
        mode,
      });
    }
  }, [
    onChangeMode,
    permissions,
    position,
    kpi,
  ]);

  const renderOption = useCallback(
    ({ name, symbol }) => (
      symbol
        ? `${name} (${symbol})`
        : name
    ),
    [],
  );

  useEffect(() => {
    if(kpi.pendingUnitUpdate &&
      (value === kpi.kpi_value?.unit) && (value === initialValue)
    ) {
      onChange(kpi.kpi_value?.unit);
    }
  }, [
    initialValue,
    onChange,
    value,
    kpi,
  ]);

  if (mode === 'edit') {
    return (
      <CustomSelect
        title={null}
        options={availableUnits}
        onSelect={onSelectUnit}
        selected={value}
        renderOption={renderOption}
        autoFocus
      />
    );
  }

  return (
    <ReadOnlyValue
      kpi={kpi}
      value={renderOption(availableUnits.find(({slug}) => value === slug) || { name: value })}
      mode={mode}
      changeMode={handleOnChangeMode}
      position={position}
      cellType='unit'
      canEdit={canEdit}
    />
  );
}

export default Unit;
