import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Input,
  Form,
  Select,
  Modal,
} from 'antd';

import {measuresValidation as validation} from './validation';
import useForm from 'utils/useForm';

import './style.less';

const AREA_OPTIONS = [
  'conciliation',
  'non_sexist_communication',
  'occupational_health',
  'organizational_culture_and_work_environment',
  'other',
  'professional_classification',
  'promotion',
  'remuneration',
  'representation',
  'selection',
  'social_policy',
  'sexism_and_sexual_harassment',
  'training',
  'women_risk_of_exclusion',
  'working_conditions',
];

const MeasuresForm = ({
  intl,
  measure,
  createMeasure,
  updateMeasure,
  onClose,
}) => {
  const t = intl.messages;
  const defaults = measure;
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    const {
      area, name
    } = values;

    if (measure.id) {
      updateMeasure(measure.id, { area, name });
    } else {
      createMeasure({ area, name });
    }
    onClose();
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  return (
    <Modal
      visible={!!measure}
      title={t.equality_plan_measures}
      onOk={handleSubmit}
      onCancel={onClose}
      okText={t.save}
      cancelText={t.cancel}
    >
      <Row>
        <Col span={24} className="form-input-wrapper">
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  validateStatus={ showError('area') ? 'error' : '' }
                  help={ showError('area') }
                  label={ t.equality_plan_area }
                  colon={false}
                  required
                >
                  <Select
                    placeholder={ t.equality_plan_area }
                    name="area"
                    value={ getValue('area') }
                    onChange={handleChange('area')}
                  >
                    {AREA_OPTIONS.sort((a, _) => t['equality_plan_area_' + a]).map(value => (
                      <Select.Option
                        key={value}
                        value={value}
                      >
                        {t['equality_plan_area_' + value]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  validateStatus={ showError('name') ? 'error' : '' }
                  help={ showError('name') }
                  label={ t.equality_plan_measure }
                  colon={false}
                  required
                >
                  <Input
                    placeholder={ t.equality_plan_measure }
                    name="name"
                    value={ getValue('name') }
                    onChange={handleChangeEvent}
                  />
                </Form.Item>
              </Col>
            </Row>
        </Col>
      </Row>
    </Modal>
  )
};

export default injectIntl(MeasuresForm);
