import React from 'react'

import { injectIntl } from 'react-intl';
import MathJax from 'react-mathjax2';
import './style.less';

const KpiFormula = ({
  //intl,
  tex,
  inline = false,
}) => {
  return (
    <div
      className={`KpiFormula ${inline ? 'KpiFormula__inline' : 'KpiFormula__block'}`}
    >
      <MathJax.Context input="tex">
        <div>
          {
            !tex ? null : (
              <MathJax.Node inline={inline}>{ tex }</MathJax.Node>
            )
          }
        </div>
      </MathJax.Context>
    </div>
  );
};

export default injectIntl(KpiFormula);
