import { convertHex2RGB, lightenDarkenRgbColor } from "utils/colorGenerator";

const createRoundRect = (context, isHorizontal, x, y, w, h, objRadius) => {
    var r = x + w;
    var b = y + h;
    
    const radius = {
        'topLeft': 0,
        'topRight': 0,
        'bottomLeft': 0,
        'bottomRight': 0,
        ...objRadius || {}
    }

    if ( isHorizontal ){
        context.moveTo(x+radius.topLeft, y);
    } else {
        context.moveTo(x, b-radius.bottomLeft);
        context.lineTo(x, y+radius.topLeft);
        if (radius.topLeft) context.quadraticCurveTo(x, y, x+radius.topLeft, y);
    }
    context.lineTo(r-radius.topRight, y);
    if (radius.topRight) context.quadraticCurveTo(r, y, r, y+radius.topRight);
    context.lineTo(r, y+h-radius.bottomRight);
    if (radius.bottomRight) context.quadraticCurveTo(r, b, r-radius.bottomRight, b);

    if ( isHorizontal ){
        context.lineTo(x+radius.bottomLeft, b);
        if (radius.bottomLeft) context.quadraticCurveTo(x, b, x, b-radius.bottomLeft);
    } 

}

const getBorderRadius = (barWidth) => {
    let borderRadius = 4;
    if (barWidth < borderRadius) borderRadius = 0;
    return borderRadius;
}

export const pluginLineTarget = {
    id: 'lineTarget',
    beforeUpdate: (chart, args, options) => {

        const numDatasets = chart.config.data.datasets.length / 2;

        // If user hides a dataset, hide the target dataset
        chart.config.data.datasets.forEach((dataset, inxDataset) => { 
            
            if ((inxDataset + 1) > numDatasets){
                if (chart.isDatasetVisible(inxDataset)){ 
                    if (!chart.isDatasetVisible(inxDataset - numDatasets)) chart.show(inxDataset - numDatasets);
                }else{
                    if (chart.isDatasetVisible(inxDataset - numDatasets)) chart.hide(inxDataset - numDatasets);
                }
            }

        });

    },
}

export const pluginBarTarget = {
  
    id: 'barTarget',
    beforeUpdate: (chart, args, options) => {

        // update the suggestedMax, maybe the user has toggle one dataset
        const indexAxis = chart.config.options.indexAxis === 'x' ? 'y' : 'x';
        const oldSuggestedMax = chart.options.scales[indexAxis].suggestedMax;
        let suggestedMax = 0;

        chart.config.data.datasets.forEach((dataset, inxDataset) => {

            if (chart.isDatasetVisible(inxDataset)){

                const suggestedMaxDataset = Math.max( ...dataset.data.map( ({ value, target }) => target ? Math.max( value, target ) : value));
                suggestedMax = Math.max(suggestedMax, suggestedMaxDataset );
                
            }

        })

        if (oldSuggestedMax !== suggestedMax){
            chart.options.scales[indexAxis].suggestedMax = suggestedMax;
            chart.update();
        }

    },
    
    afterDatasetDraw: (chart, args, options) => {

        const { ctx } = chart;
        const borderWidth = 2;

        const inxDataset = args.index;
        const dataset = chart.config.data.datasets[args.index];

        if (chart.isDatasetVisible(inxDataset)){

            dataset.data.forEach( (data, inxElement) => { 

                const vm = args.meta.data[inxElement];
                const target = data.target;

                if (target){

                    const rgbColor = convertHex2RGB(dataset.backgroundColor);

                    ctx.strokeStyle = `rgb(${ lightenDarkenRgbColor(rgbColor, -50).join(',') })`;
                    ctx.lineWidth = borderWidth;
                    ctx.setLineDash([6, 6]);
                    
                    let barWidth, borderRadius;
                    
                    if (vm.horizontal){

                        // Horizontal bars
                        const rectangleInitialY = vm.y - vm.height / 2;
                        const xTarget = chart.scales.x.getPixelForValue(target);
                        barWidth = xTarget - vm.base;

                        if (barWidth <= borderWidth/2) return;
                        borderRadius = getBorderRadius(barWidth);

                        ctx.beginPath();
                        createRoundRect(ctx, vm.horizontal, vm.base, rectangleInitialY + borderWidth / 2, barWidth - borderWidth / 2, vm.height - borderWidth, { 'topRight': borderRadius, 'bottomRight': borderRadius });
                        ctx.stroke();

                        ctx.fillStyle = `rgb(${ rgbColor.join(',') }, 0.3)`;
                        ctx.beginPath();
                        createRoundRect(ctx, vm.horizontal, vm.base, rectangleInitialY, barWidth, vm.height, { 'topRight': borderRadius, 'bottomRight': borderRadius });
                        ctx.closePath();
                        ctx.fill();
                        ctx.save();
                       
                    } else {

                        // Vertical bars
                        const rectangleInitialX = vm.x - vm.width / 2;
                        const yTarget = chart.scales.y.getPixelForValue(target);

                        barWidth = (vm.base - yTarget);

                        if (barWidth <= borderWidth/2) return;
                        borderRadius = getBorderRadius(barWidth);

                        ctx.beginPath();
                        createRoundRect(ctx, vm.horizontal, rectangleInitialX + borderWidth / 2, yTarget, vm.width - borderWidth, barWidth - borderWidth / 2, { 'topRight': borderRadius, 'topLeft': borderRadius });
                        ctx.stroke();

                        ctx.fillStyle = `rgb(${ rgbColor.join(',') }, 0.3)`;
                        ctx.beginPath();
                        createRoundRect(ctx, vm.horizontal, rectangleInitialX, yTarget, vm.width, barWidth, { 'topRight': borderRadius, 'topLeft': borderRadius });
                        ctx.closePath();
                        ctx.fill();
                        ctx.save();
                        
                    }
                    
                }
    
            });

        }

    }

  } 