import { injectIntl } from "react-intl";
import SdgMiniLogo from "../SdgMiniLogo";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useSDGsClustering } from "../../hooks/sdg";
import CustomMultiSelect from 'components/CustomMultiSelect';

const unfoldSelection = (selection, sdgs) => sdgs
  .filter((s) => selection.includes(s.slug))
  .map((s) => s.targets)
  .flat()
  .map((s) => s.slug);

const SDGFilter = ({
  intl,
  selected,
  className,
  wideMenu = true,
  inModal = true,
  onSelect,
}) => {
  const { sdgs = [] } = useSelector((state) => state.taxonomies);
  const clusterSDGs = useSDGsClustering();
  const clusteredSelection = useMemo(
    () => clusterSDGs(selected).map((s) => s.slug),
    [selected, clusterSDGs]
  );
  const patchedSdgs = useMemo(() => {
    return sdgs.map(({ slug, code }) => ({
      slug,
      code,
      parent_slug: slug,
      name: intl.formatMessage({ id: `sdg_${slug}` }),
    }));
  }, [sdgs, intl]);

  return (
    <CustomMultiSelect
      title={intl.formatMessage({ id: "item_manager_sdg" })}
      options={patchedSdgs}
      selected={clusteredSelection}
      onSelect={(selection) => onSelect(unfoldSelection(selection, sdgs))}
      renderOptionPrefix={(slug) => <SdgMiniLogo key={slug} sdg={patchedSdgs.find(sdg => sdg.slug === slug)} />}
      className={className}
      wideMenu={wideMenu}
      inModal={inModal}
      getPopupContainer ={ () => document.getElementById(`CustomMultiSelect-${intl.formatMessage({ id: "item_manager_sdg" })}`)}
    />
  );
};

export default injectIntl(SDGFilter);
