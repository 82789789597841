import React from 'react';
import CustomLanguageInput from '../CustomLanguageInput';

const EditCategory = ({
  intl,
  values,
  handleChange,
  errors,
}) => {

  return (
    <section className='CreateEditCustomKPI-form'>
      <CustomLanguageInput
        values={values}
        errors={errors.name_translations}
        handleChange={handleChange}
        placeholder={intl.formatMessage({ id: 'createcustomkpi_name_placeholder' })}
      />
    </section>
  );
};

export default EditCategory;
