export const departmentValidation = (t) => ({
  name,
}) => {
  let errors = {};

  if (!name) {
    errors.name = t.form_error_required;
  }

  return {
    ...errors,
  };
};

export const professionalGroupValidation = (t) => ({
  name,
}) => {
  let errors = {};

  if (!name) {
    errors.name = t.form_error_required;
  }

  return {
    ...errors,
  };
};

export const positionValidation = (t) => ({
  code,
  name,
  department_id,
  professional_group_id,
}) => {
  let errors = {};

  if (!code) {
    errors.code = t.form_error_required;
  }

  if (!name) {
    errors.name = t.form_error_required;
  }

  if (!department_id) {
    errors.department_id = t.form_error_required;
  }

  if (!professional_group_id) {
    errors.professional_group_id = t.form_error_required;
  }

  return {
    ...errors,
  };
};
