const TARGET = 'data_sources_kpi_permissions';
const DATA_SOURCES_TARGET = 'data_sources';

const isTarget = (target) => [TARGET, DATA_SOURCES_TARGET].includes(target);

const initialState = {};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        ...state,
        [ action.kpi_slug ]: {
          ...(state[action.kpi_slug] || {}),
          [ action.organization_slug ]: {
            ...state[action.kpi_slug]?.[ action.organization_slug ] || {},
            fetching: action.method === 'GET',
            pushing: action.method === 'PUT',
          },
        },
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;

      switch(action.response.method) {
        case 'GET':
          return {
            ...state,
            [ action.response.kpi_slug ]: {
              ...(state[action.response.kpi_slug] || {}),
              ...(action.response.result?.permissions || {}),
            },
          };
        case 'PUT':
          return {
            ...state,
            [ action.response.kpi_slug ]: {
              ...(state[action.response.kpi_slug] || {}),
              [action.response.organization_slug]: {
                ...action.response.result,
              },
            },
          };
        default:
          return state;
      }

    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state,
        [action.request.kpi_slug]: {
          ...(state[action.request.kpi_slug] || {}),
          [ action.request.organization_slug ] : {
            error: {
              code: action.code,
            },
          },
        },
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as data_sources_kpi_permissions,
};
