const TARGET='documentation';

const initialState = {
    data: null,
    loading: false,
    error: null,
  };

  const reducer = (state = initialState, action) => {
    switch(action.type) {
      case 'API_CALL_REQUEST':
        if(action.target !== TARGET) return state;
        return {
          ...state,
          loading: true,
          error: null,
        };
      case 'API_CALL_COMPLETE':
        if(!action.response || action.response.target !== TARGET) return state;
        return {
          ...state,
          data: action.response.result,
          loading:false,
          fetching: false,
        };
      case 'API_CALL_FAILED':
        if(!action.request || action.request.target !== TARGET) return state;
        return {
          ...state,
          loading: false,
          error: action.code,
        };
      default:
        return state;
    }
  };
  
  export {
    reducer as documentation,
  };
  
