import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import { useDispatch, useSelector } from 'react-redux';
import useOrganizations from 'utils/useOrganizations';

import {useSDGsClustering} from "../../hooks/sdg";
import { useAnswerState } from 'utils/useAnswerState';

import {
  createKpiMonitor,
  updateKpiMonitor,
  deleteKpiMonitor,
} from 'actions/api';

import EsgLogo from 'components/EsgLogo';
import CustomButton from 'components/CustomButton';
import SdgMiniLogo from 'components/SdgMiniLogo';
import Answer from 'components/Answer';
import ReportingStandardTag from 'components/ReportingStandardTag';

import ConfirmModal from './confirmModal';

import CustomModal from 'components/CustomModal';
import CustomCheckbox from 'components/CustomCheckbox';

import { Col, Row } from 'antd';
import './style.less';


const DataSdgs = ({
  sdgs = [],
}) => {
  const clusterSDGs = useSDGsClustering();
  const clusteredSDGs = useMemo(() => clusterSDGs(sdgs), [sdgs, clusterSDGs])
  return (
    <Col className='KpiSummaryCard__card-sdgs'>
      {
        clusteredSDGs.map(sdg => (
          <SdgMiniLogo key={sdg.slug} sdg={sdg}/>
        ))
      }
    </Col>
  );
};

const DataReportingStandards = ({
  standard_info = [],
}) => {
  return (
    <div className="KpiSummaryCard__card-reporting-standards">
      {
        (standard_info || [])
        .filter(({code}) => !!code)
        .map(({standard, code}) => (
          <ReportingStandardTag
            key={standard}
            standard={standard}
            name={code}
            code={code}
            showTooltip
          />
        ))
      }
    </div>
  );
};


const ModalTarget = ({
  intl,
  visible,
  onClose,
  level, // Defaults to 'undefined', which means "all levels I can approve/reject"
  loading = false,
  name,
  slug,
  // organization_slug,
  // suborganization_slug,
  esgs,
  sdgs,
  period,
  periods,
  schema,
  schemaLabels,
  standard_info,
  target,
  kpi_value,
  has_target = false
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const [ action, setAction ] = useState(false);
  const [ targetForAllPeriods, setTargetForAll ] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const config = useMemo(() => {
    return ((suborganization || {}).product_config || {}).atlas || {};
  }, [
    suborganization,
  ]);
  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);

  // AnswerState hook:
  const {
    values: targetValue,
    update: updateTargetValue,
  } = useAnswerState({
    schema: schema ? schema : {},
    tableDimensions,
    initialState: target ? target : {},
    slug,
    organization: suborganization.slug,
    organization_parent: suborganization.parent_slug,
    period,
  });



  const onSubmit = useCallback(() => {
    let kpi_monitor = {type: 'target', value : targetValue};
    (target && schema) ? setAction('edit') : setAction('save');
    //Check if there are targets already defined
    if( (has_target && targetForAllPeriods && !confirmed ) ){
        setConfirmed(true);
        //Show alert Modal
        setShowConfirmModal(true);
    }else{
        setSubmitting(true);
        dispatch(
          (action === 'edit' ? updateKpiMonitor : (action === 'delete' ? deleteKpiMonitor : createKpiMonitor) )(
            organization.slug,
            suborganization.slug,
            slug,
            period,
            targetForAllPeriods,
            kpi_monitor
          )
        )
    }
  }, [
    schema,
    target,
    dispatch,
    action,
    organization,
    suborganization,
    slug,
    targetValue,
    period,
    targetForAllPeriods,
    confirmed,
    has_target
  ]);

  const onDelete = useCallback(()=> {
    let kpi_monitor = {type: 'target', value : targetValue};
    setAction('delete');
    if( (has_target && targetForAllPeriods && !confirmed) ){
      setConfirmed(true);
      //Show alert Modal
      setShowConfirmModal(true);
    }else{

      setSubmitting(true);
      dispatch(
        ( deleteKpiMonitor )(
          organization.slug,
          suborganization.slug,
          slug,
          period,
          targetForAllPeriods,
          JSON.stringify(kpi_monitor)
        )
      )
    }
  },[
    dispatch,
    organization,
    suborganization,
    slug,
    targetValue,
    period,
    targetForAllPeriods,
    confirmed,
    has_target
  ]);


  // Confirm modal methods

  const onOkConfirm = useCallback(()=>{
    action === 'delete' ? onDelete() : onSubmit();
  },[
    onDelete,
    onSubmit,
    action
  ]);

  const onCloseConfirm = useCallback(()=>{
    setConfirmed(false);
    setShowConfirmModal(false);
  },[]);

  useEffect(()=>{
    (target && schema) ? setAction('edit') : setAction('save');
  },[
    target,
    schema,
  ]);

  // Auto close modal
  useEffect(() => {
    if (submitting){
      setSubmitting(false);
      onClose();
    }
  },[submitting, onClose]);


  return (
    <div>
    <CustomModal
      className="ModalTarget"
      shown={visible}
      title={ t[`modaltarget_title`] }
      onOk={onSubmit}
      onCancel={onClose}
      footer={
        <div className="ModalTarget__footer">
          <CustomButton
            className="ModalTarget__btn-footer__cancel"
            key="back"
            type="text"
            danger
            onClick={onClose}
          >
            { t.modalapproverejectkpi_cancel }
          </CustomButton>

        { action === 'save' ? null : (
          <CustomButton
            className="ModalTarget__btn-footer"
            key="delete"
            danger
            onClick={onDelete}
          >
              { t[`modaltarget_actions_delete`] }
          </CustomButton>
        )}
           <CustomButton
            className="ModalTarget__btn-footer__save"
            key="submit"
            type="primary"
            onClick={onSubmit}
          >
              { t[`modaltarget_actions_save`] }
          </CustomButton>
 
        </div>
      }
    >
      <Row
              type="flex"
              gutter={8}
              justify="space-between"
      >
        { (esgs || []).map(esg =>
          <Col>
            <EsgLogo
              type={esg}
            />
          </Col>
        )}
        <Col>
        <Row>
        <DataSdgs
              sdgs={sdgs}
          />
          <Col>
          {name}
          </Col>
        </Row>


        </Col>

        <Col>
            <DataReportingStandards
              standard_info={standard_info}
            />
        </Col>
      </Row>

      <section className="ModalTarget__form">
        <div>
          <span>
            { t[`modaltarget_instructions_${schema ? schema.type: null}`]  }
          </span>
        </div>

        <Answer.Edit
          compact
          schema={schema}
          schemaLabels={schemaLabels}
          config={config}
          value={targetValue}
          onChange={updateTargetValue}
          disabled={loading}
          columnsNoScroll={3}
          targetEdit = 'true'
          kpi_slug={slug}
          organization={suborganization.slug}
          organization_parent={suborganization.parent_slug}
          period={period}
        />


      </section>
      <section className='ModalTarget__checkboxcontainer'>
          <CustomCheckbox
              checked={targetForAllPeriods}
              onChange={()=> setTargetForAll(!targetForAllPeriods)}
          />
       <span className='ModalTarget__checkboxlabel'>
        { t['modaltarget_apply_all_periods']}
        </span>
      </section>
    </CustomModal>

      <ConfirmModal
        visible={showConfirmModal}
        onOk = {onOkConfirm}
        onCancel = {onCloseConfirm}
        action = {action}
      />


    </div>
  )
};

export default injectIntl(ModalTarget);
