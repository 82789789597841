import React, { useState } from 'react';
import {
  Steps,
  Modal,
  ConfigProvider,
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CustomButton from 'components/CustomButton';
import { isEmpty } from 'lodash';
import { Loading } from 'tsComponents/emptyStates/Loading';
import { formatMessage } from '../../intl';

const { Step } = Steps;

const NO_OP = () => { };

const ModalWizard = ({ title, visible, steps, className, onCancel, onFinish, afterClose, isCreating = false, saveAction = NO_OP, loading = false, errors }) => {
  const [current, setCurrent] = useState(0);

  const switchStep = (n) => {
    setCurrent(current + n)
  }

  const warning = () => {
    Modal.confirm({
      title: formatMessage('modal_wizard_unsave_changes_title'),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage('modal_wizard_unsave_changes_content'),
      centered: true,
      onOk: () => { setCurrent(0); onCancel() }
    });
  };
  return (
    <Modal
      destroyOnClose={true}
      maskClosable={false}
      afterClose={() => { setCurrent(0); afterClose(); }}
      centered
      visible={visible}
      title={title}
      className={className}
      onCancel={warning}
      bodyStyle={{maxHeight: '65vh', minHeight: '65vh', position: 'relative'}}
      footer={(
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {current !== 0 && <CustomButton onClick={() => switchStep(-1)}>{formatMessage('modal_wizard_back_button')}</CustomButton>}
          {isCreating && <CustomButton onClick={() => saveAction(false)}>{formatMessage('modal_wizard_back_button')}</CustomButton>}
          {current === steps.length - 1
            ? <CustomButton type="primary" onClick={() => { onFinish(); setCurrent(0); }} disabled={!steps[current].isValid()}>{formatMessage('modal_wizard_finish_button')}</CustomButton>
            : <CustomButton
              type="primary"
              onClick={() => isCreating ? saveAction(true) : switchStep(1)} disabled={!steps[current].isValid() || !isEmpty(errors)}
            >
              {formatMessage(isCreating ? 'modal_wizard_save_button' : 'modal_wizard_next_button')}
            </CustomButton>
          }
        </div>
      )}
    >
      {loading
        ? <Loading />
        : (
          <ConfigProvider getPopupContainer={() => document.querySelector(".ant-modal-body div[role=main]")}>
            <Steps current={current}>
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div style={{ marginTop: '25px' }} role="main" >
              {steps[current].content}
            </div>
          </ConfigProvider>
        )
      }

    </Modal>
  )
}

export default ModalWizard;
