const TARGET = 'kpi_dependency';
const isTarget = (target) => target === TARGET;

const initialState = {};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        ...state,
        [action.hash]: {
          error: null,
          loading: true,
          data: [],
        }
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target) || !action.response.hash) return state;

      return {
        ...state,
        [action.response.hash]: {
          ...(state[action.response.hash] || {}),
          error: null,
          loading: false,
          data: action.response.result || [],
        },
      };
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target) || !action.request.hash) return state;
      return {
        ...state,
        [action.request.hash]: {
          ...(state[action.request.hash] || {}),
          error: action.code,
          loading: false,
          data: [],
        },
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
    //case 'RESET_KPI_DETAIL':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as kpi_dependency,
};
