import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from 'tsComponents/emptyStates/Loading';
import Variables from 'components/VariablesConfiguration/Variables';
import useOrganizations from 'utils/useOrganizations';
import { getKpiVariables } from 'actions/api';
import CustomModal from 'components/CustomModal';

import {
  Tabs,
  Modal,
} from 'antd';

import './style.less';


const { TabPane } = Tabs;

const VariablesConfiguration = ({
  intl,
  visible,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [editingVariables, setEditingVariables] = useState([]);

  const {
    organization,
    suborganization,
    permissions,
  } = useOrganizations();

  useEffect(
    () => {
      if (organization?.slug && suborganization?.slug) {
        dispatch(
          getKpiVariables(organization.slug, suborganization.slug)
        );
      }
    },
    [
      dispatch,
      organization,
      suborganization,
    ]
  );

  const {
    fetching,
    data: unsortedData,
  } = useSelector(state => state.kpi_variable);

  const data = useMemo(() => {
    return [...(unsortedData || [])].sort((a, b) =>
      (a.translations[intl.locale] || a.slug).toLowerCase() < (b.translations[intl.locale] || b.slug).toLowerCase()
        ? -1
        : (a.translations[intl.locale] || a.slug).toLowerCase() > (b.translations[intl.locale] || b.slug).toLowerCase()
          ? 1
          : 0
    );
  }, [intl.locale, unsortedData]);

  const preOwnedVariables = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.filter(item => !item.organization_id || item.default_variable_id);
  }, [data]);

  const ownVariables = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.filter(
      item => item.organization_id
        && !item.default_variable_id
    );
  }, [data]);

  const handleOnClose = useCallback(
    () => {
      if (editingVariables.length === 0) {
        onClose();
        return;
      }
      Modal.confirm({
        title: intl.formatMessage({ id: 'variables_configuration_close_without_save' }),
        okText: intl.formatMessage({ id: 'variables_configuration_close_without_save_yes' }),
        cancelText: intl.formatMessage({ id: 'variables_configuration_close_without_save_no' }),
        onOk: onClose,
      });
    },
    [
      intl,
      editingVariables,
      onClose,
    ]
  );

  return (
    <CustomModal
      className="VariablesConfiguration"
      shown={visible}
      onCancel={handleOnClose}
      title={intl.formatMessage({ id: 'variables_configuration' })}
      footer={null}
      width={'90%'}
    >
      { 
        (fetching || !data)
        ? <Loading />
        : <Tabs defaultActiveKey="preOwned">
            <TabPane
              tab={intl.formatMessage({ id: 'variables_configuration_preowned' })}
              key="preOwned"
            >
              <Variables
                allVariables={unsortedData}
                data={preOwnedVariables}
                editingVariables={editingVariables}
                setEditingVariables={setEditingVariables}
                canAdd={false}
                canManage={permissions.can_manage_variables_configuration}
              />
            </TabPane>
            <TabPane
              tab={intl.formatMessage({ id: 'variables_configuration_own' })}
              key="own"
            >
              <Variables
                allVariables={unsortedData}
                data={ownVariables}
                canAdd={permissions.can_manage_variables_configuration}
                canManage={permissions.can_manage_variables_configuration}
                editingVariables={editingVariables}
                setEditingVariables={setEditingVariables}
              />
            </TabPane>
          </Tabs>
      }
    </CustomModal>
  );
}

export default injectIntl(VariablesConfiguration);
