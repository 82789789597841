import React from 'react'

import './style.less'


const Required = (props) => {
  return (
    <span className='Required'>*</span>
  );
};

export default Required
