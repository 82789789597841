import {injectIntl} from "react-intl";
import CustomButton from "../CustomButton";
import React from "react";

export default injectIntl(({
  intl,
  schema, // TODO: Consider schema?
  value,
  onClick,
}) => {
  const t = intl.messages;
  return (
    <CustomButton
      onClick={onClick}
    >
      {t.data_answer_card_show_more}
    </CustomButton>
  );
});
