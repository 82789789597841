import React from 'react';
import { injectIntl } from 'react-intl';

import Show from './Show';
import ShowTable from './ShowTable';
import SummaryComponent from "./SummaryComponent";
import Edit from './Edit';

import { useFeatureList } from 'components/FeatureSwitch';

const NO_OP = () => {};
const NO_DEPENDEES = [];

const TableAnswer = ({
  intl,
  value,
  previous_value,
  previous_comment,
  previous_attachments,
  kpi_name,
  kpi_slug,
  org_name,
  org_slug,
  period,
  comment,
  source,
  source_params,
  schema,
  schemaLabels,
  edit,
  loading,
  onEdit = NO_OP,
  onSave = NO_OP,
  onClear = NO_OP,
  onCancel = NO_OP,
  onShowLastPeriod = NO_OP,
  onUseLastValue = NO_OP,
  onUseLastWholeData = NO_OP,
  onUseLastAttachments = NO_OP,
  onUseLastPeriod = NO_OP,
  canWrite = false,
  target,
  onTarget,
  targetEdit = false,
  dependees = NO_DEPENDEES,
  suborganization_data,
  config,
}) => {
  const t = intl.messages;

  //Detect if target is present
  const {
    suborganizationFeatures: featureList,
  } = useFeatureList();

  const isTarget =  featureList.has('targets') && target && target.value && Object.keys(target.value).length > 0;

  return (
    <section className='KpiDetail__answer'>
      <div className="KpiDetail__title">{ t.answer }</div>
      {
        edit && canWrite
        ? <Edit
            source={source}
            source_params={source_params}
            schema={schema}
            schemaLabels={schemaLabels}
            value={value}
            comment={comment}
            kpi_name={kpi_name}
            org_name={org_name}
            kpi_slug={kpi_slug}
            org_slug={org_slug}
            period={period}
            onSave={onSave}
            onClear={onClear}
            onCancel={onCancel}
            previous_value={previous_value}
            previous_comment={previous_comment}
            previous_attachments={previous_attachments}
            onShowLastPeriod={onShowLastPeriod}
            onUseLastValue={onUseLastValue}
            onUseLastWholeData={onUseLastWholeData}
            onUseLastAttachments={onUseLastAttachments}
            onUseLastPeriod={onUseLastPeriod}
            target={target}
            isTarget = {isTarget}
            onTarget={onTarget}
            targetEdit={targetEdit}
            dependees={dependees}
            config={config}
          />
        : <Show
            source={source}
            source_params={source_params}
            schema={schema}
            schemaLabels={schemaLabels}
            value={value}
            comment={comment}
            kpi_name={kpi_name}
            org_name={org_name}
            kpi_slug={kpi_slug}
            org_slug={org_slug}
            period={period}
            previous_value={previous_value}
            previous_comment={previous_comment}
            previous_attachments={previous_attachments}
            onEdit={onEdit}
            onShowLastPeriod={onShowLastPeriod}
            onUseLastValue={onUseLastValue}
            onUseLastWholeData={onUseLastWholeData}
            onUseLastAttachments={onUseLastAttachments}
            onUseLastPeriod={onUseLastPeriod}
            canWrite={canWrite}
            target={target}
            isTarget={isTarget}
            onTarget={onTarget}
            suborganization_data={suborganization_data}
            dependees={dependees}
            config={config}
          />
      }
    </section>
  );
}

TableAnswer.Show = ShowTable;
TableAnswer.Edit = Edit.Component;
TableAnswer.Summary = SummaryComponent;

export default injectIntl(TableAnswer);
