const TARGET = 'taxonomies';
const META_TARGET = 'data_meta';
const KPI_VARIABLE_TARGET = 'taxonomies_kpi_variable';
const ORGANIZATION_KPI_MEMBER_TARGET = 'taxonomies_organization_kpi_member';

const isTarget = (target) => [
  TARGET, META_TARGET, KPI_VARIABLE_TARGET, ORGANIZATION_KPI_MEMBER_TARGET,
].includes(target);
const isGeneralTarget = (target) => target === TARGET;

const initialState = {
  sdgs: [],
  metrics: [],
  tags: [],
  dashboard: [],
  kpi_variables: [],
  reporting_standards: {},
  reporting_standard_kpis: {},
  requests: [],
  assignees: [],
  loading: false,
  error: false,
};

const convertKpiVariablesToTableDimensions = (variables) => {
  let tableDimensions = {};

  Object.entries(variables).forEach(([variable_slug, { options = {} }]) => {
    tableDimensions[variable_slug] = Object.keys(options)
      .sort(
        (a, b) => a > b ? 0 : -1
      );
  });
  return tableDimensions;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API_CALL_REQUEST':
      if (!isTarget(action.target)) return state;
      return {
        ...state, // We leave the old state just in case
        loading: true,
        error: false,
      };
    case 'THIRD_PARTY_TAXONOMIES':
      return {
        sdgs: action.taxonomies?.sdgs || state.sdgs,
        metrics: action.taxonomies?.metrics || state.metrics,
        kpi_variables: action.taxonomies?.kpi_variables || state.kpi_variables,
        table_dimensions: convertKpiVariablesToTableDimensions(
          action.taxonomies?.kpi_variables || state.kpi_variables
        ),
        loading: false,
        error: false,
      };
    case 'API_CALL_COMPLETE':
      if (!action.response || !isTarget(action.response.target)) return state;

      if (isGeneralTarget(action.response.target)) {
        return {
          ...state,
          sdgs: action.response.result.sdg || state.sdgs,
          metrics: action.response.result.metric || state.metrics,
          tags: action.response.result.tag || state.tags,
          dashboard: action.response.result.dashboard || state.dashboard,
          reporting_standards: action.response.result.reporting_standard || state.reporting_standards,
          reporting_standard_kpis: action.response.result.reporting_standard_kpis || state.reporting_standard_kpis,
          requests: action.response.result.requests || state.requests,
          loading: false,
          error: false,
        }
      }

      if (action.response.target === META_TARGET) {
        if (
          action.response.result &&
          action.response.result.add_tags
        ) {
          return {
            ...state,
            tags: [
              ...state.tags,
              ...(
                action.response.result.add_tags.filter(
                  tag => !state.tags.includes(tag)
                )
              ),
            ],
            loading: false,
            error: false,
          }
        }
      }

      if (action.response.target === KPI_VARIABLE_TARGET) {
        return {
          ...state,
          kpi_variables: action.response.result || state.kpi_variables,
          table_dimensions: convertKpiVariablesToTableDimensions(
            action.response.result || state.kpi_variables
          ),
          loading: false,
          error: false,
        };
      }

      if (action.response.target === ORGANIZATION_KPI_MEMBER_TARGET) {
        return {
          ...state,
          assignees: action.response.result,
          loading: false,
          error: false,
        };
      }

      return {
        ...state,
        loading: false,
        error: false,
      }
    case 'API_CALL_FAILED':
      if (!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state, // We leave the old state just in case
        loading: false,
        error: action.code,
      };
    default:
      return state;
  }
};

export {
  reducer as taxonomies,
};
