const TARGET = 'kpi_permissions';
const RESTRICTION_TARGET = 'kpi_restriction';

const isTarget = (target) => [TARGET, RESTRICTION_TARGET].includes(target);
const isGeneralTarget = (target) => target === TARGET;

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        data: action.method === 'GET' ? null : state.data,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;
      if(isGeneralTarget(action.response.target)) {
        return {
          data: action.response.result,
          loading: false,
          error: null,
        };
      };

      if(state.data && action.response.target === RESTRICTION_TARGET) {
        switch(action.response.method) {
          case 'POST':
          case 'DELETE':
            return {
              data: {
                ...state.data,
                ...(action.response.result || {}),
                restricted: (action.response.method === 'POST'),
              },
              loading: false,
              error: null,
            };
          default:
            break;
        }
      }

      // Should never happen
      return state;
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        data: null,
        loading: false,
        error: action.code,
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as kpi_permissions,
};
