import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';

import { useDispatch } from 'react-redux';
import useOrganizations from 'utils/useOrganizations';

import {
  approveKpis,
  rejectKpis,
} from 'actions/api';

import T from 'components/T';
import CustomButton from 'components/CustomButton';
import CustomModal from 'components/CustomModal';

import {
  levelToBackendLevel,
} from 'utils/kpi_validation';

import { Input, Form } from 'antd';
import './style.less';

const ModalApproveRejectKpi = ({
  intl,
  visible,
  onClose,
  mode = 'approve', // or 'reject'
  level, // Defaults to 'undefined', which means "all levels I can approve/reject"
  loading = false,
  kpis = [],
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();

  const [comment, setComment] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    dispatch(
      (mode === 'approve' ? approveKpis : rejectKpis)(
        organization.slug,
        suborganization.slug,
        kpis,
        comment,
        levelToBackendLevel(level),
      )
    )
  }, [
    dispatch,
    mode,
    organization,
    suborganization,
    kpis,
    comment,
    level,
  ]);

  const handleCommentChange = useCallback((e) => {
    setComment(e.target.value || '');
  }, []);

  useEffect(() => {
    if(submitting && !loading) {
      setSubmitting(false);
      setComment('');
      onClose();
    }
  }, [
    submitting,
    loading,
    onClose,
  ]);

  return (
    <CustomModal
      className="ModalApproveRejectKpi"
      shown={visible}
      title={ mode === "undo" ? t[`modalapproverejectkpi_title_undo`] : <T modalapproverejectkpi_title={{ mode, count: (kpis || []).length }} /> }
      onOk={onSubmit}
      onCancel={onClose}
      footer={
        <div className="ModalApproveRejectKpi__footer">
          <CustomButton
            className="ModalApproveRejectKpi__btn-footer"
            key="back"
            onClick={onClose}
          >
            { t.modalapproverejectkpi_cancel }
          </CustomButton>
          <CustomButton
            className="ModalApproveRejectKpi__btn-footer"
            key="submit"
            type="primary"
            disabled={mode === 'reject' && !comment}
            onClick={onSubmit}
          >
            { t[`modalapproverejectkpi_ok_${mode}`] }
          </CustomButton>
        </div>
      }
    >
      <section>
        <Form onFinish={onSubmit} layout="vertical">
          <Form.Item label={t[`modalapproverejectkpi_comment_${mode}`]} colon={false}>
            <Input.TextArea
              type="text"
              value={comment}
              onChange={handleCommentChange}
            />
          </Form.Item>
        </Form>
      </section>
    </CustomModal>
  )
};

export default injectIntl(ModalApproveRejectKpi);
