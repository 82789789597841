import React, {useState, useCallback, useEffect} from 'react';

import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import './style.less';

const DEBOUNCE_MS = 250;

const CustomInputSearch = ({
    value,
    onChange,
    placeholder,
    suffixIcon,
    hasToClearSearch,
    onEscPress,
    className = '',
    allowClear,
    autoFocus,
    onPressEnter,
    debounceMs = DEBOUNCE_MS
  }) => {
    const [searchTerm, setSearchTerm] = useState(() => _.isString(value) ? value : '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedValue = useCallback(_.debounce(value => onChange(value), debounceMs), []);
    const handleOnChange = e => {
        if (e.keyCode === 27 && onEscPress) {
          onEscPress(e);
          setSearchTerm('');
          onChange('');
          return;
        }
        if (e.keyCode === 13 && onPressEnter) {
          onPressEnter(e);
          return;
        }
        const term = e.target.value;
        setSearchTerm(term);
        debouncedValue(term);
    };

    useEffect(() => {
      if(hasToClearSearch) {
        setSearchTerm('');
        debouncedValue('');
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasToClearSearch]);
  
    return (
      <Input
        className={`CustomInputSearch ${className}`}
        placeholder={placeholder}
        value={searchTerm}
        onChange={(event) => handleOnChange(event)}
        suffix={suffixIcon || (<SearchOutlined className="CustomInputSearch__searchIcon" />)}
        onKeyDown={(event) => handleOnChange(event)}
        allowClear={allowClear}
        autoFocus={autoFocus}
      />
    )
}
  
export default CustomInputSearch;
