import React from 'react';
import { injectIntl } from 'react-intl';

import T from "components/T";
import DataTargetIcon from 'components/DataTargets/DataTargetIcon';

import {
  Button,
  Tooltip,
} from 'antd';

import {
  CheckOutlined,
  RedoOutlined,
  SaveOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import {
  useKpiValidationLevels,
  canValidateWithLevel,
} from 'utils/kpi_validation';

import CustomButton from 'components/CustomButton';
import { useFeatureList } from 'components/FeatureSwitch';
import AplanetIcon from 'components/AplanetIcon';

const DEFAULT_APPROVAL_LEVELS = 1;
const MANUAL_SOURCE = 'manual';

const DataActions = injectIntl(({
  intl,
  source,
  permissions,
  status,
  editting,
  onEdit,
  onSave,
  onCancel,
  onApprove,
  onReject,
  onStartValidation,
  schema,
  targetDefined,
  onTarget,
  loading,
  ready_to_validate_level,
  validated_level,
  config,
  rejection_count,
  rejection_comment,
  validationEnabled,
}) => {
  const t = intl.messages;
  const userApprovalLevel = permissions.validate_kpi_level || 0;
  const canApproveKpis = ready_to_validate_level === 0
    ? permissions.can_write_kpi
    : permissions.can_validate_kpi && canValidateWithLevel(
      ready_to_validate_level,
      userApprovalLevel,
    )

  const {
    steps,
  } = useKpiValidationLevels(
    (config.approval_levels || DEFAULT_APPROVAL_LEVELS),
    validated_level,
    ready_to_validate_level,
    [],
    [],
    userApprovalLevel,
  );
  const {
    suborganizationFeatures: featureList,
  } = useFeatureList();

  if (ready_to_validate_level > 0) {
    if (
      !canApproveKpis ||
      !steps.find(step => step.isActive && step.canApprove && !step.isApproved)
    ) {
      if (steps.slice(-1)[0].isApproved) {
        return (
          <span className="KpiSummaryCard__card-status uptodate">
            {t.kpisummarycard_validated}
          </span>
        );

      }
      return (
        <span className="KpiSummaryCard__card-status restricted">
          {t.kpisummarycard_waiting}
        </span>
      );
    }

    // NOTICE: This is a hack: we map but we only expect one to be not null
    return (
      <React.Fragment>
        {
          steps.map(({
            level,
            isApproved,
            isActive,
            canApprove,
            action,
          }) => {
            if (isActive && canApproveKpis && canApprove) {
              return (
                <React.Fragment key={level}>
                  <div key={level}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<CheckOutlined />}
                      title={t.kpisummarycard_approve}
                      className="KpiSummaryCard__minibutton"
                      onClick={() => onApprove(level)}
                      disabled={loading}
                    />
                    <Button
                      type="danger"
                      shape="circle"
                      icon={<RedoOutlined />}
                      title={t.kpisummarycard_reject}
                      className="KpiSummaryCard__minibutton"
                      onClick={() => onReject(level)}
                      disabled={loading}
                    />
                  </div>
                  <div>
                    <T level_of_approval_n={{ level }} />
                  </div>
                </React.Fragment>
              );
            }
            return null;
          })
        }
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {
        editting ? null : (
          <div className="KpiSummaryCard__buttongroup">
            {
              !permissions.can_write_kpi || source !== MANUAL_SOURCE
                ? null
                : (
                  <CustomButton
                    className="DataActions_btn"
                    icon={
                      <AplanetIcon name="Edit" />

                    }
                    onClick={onEdit}
                    tooltip={t.kpisummarycard_edit}
                  >
                    {/* { t.kpisummarycard_edit } */}
                  </CustomButton>
                )
            }
            {
              !canApproveKpis || status !== 'uptodate' || !validationEnabled ? null :
                <CustomButton
                  className="DataActions_btn"
                  onClick={onStartValidation}
                  icon={
                    <AplanetIcon name="Validation" />
                  }
                  tooltip={t.validation_start}
                >
                  {/* { t.kpisummarycard_startvalidation } */}
                </CustomButton>
            }
            {
              (
                featureList.has('targets')
                && permissions.can_configure_kpi
                && ['table', 'boolean', 'quantitative'].indexOf(schema.type) !== -1
              )
                ? <CustomButton
                  type={`${targetDefined ? 'primary' : ''}`}
                  onClick={onTarget}
                  icon={
                    <DataTargetIcon className={`${targetDefined ? 'aplanet-white' : ''}`} />
                  }
                  tooltip={t.kpi_detail_target_create}
                >
                  {/* { t.kpisummarycard_edit } */}
                </CustomButton>
                : null
            }
            {
              !rejection_count ? null :
                <span className="KpiSummaryCard__card-status restricted">
                  <Tooltip title={rejection_comment} placement="left">
                    {t.kpisummarycard_rejected}
                  </Tooltip>
                </span>
            }
          </div>
        )
      }
      {
        !editting ? null : (
          <div className="KpiSummaryCard__buttongroup">
            <Tooltip title={t.kpisummarycard_cancel}>
              <CustomButton
                danger
                onClick={onCancel}
                icon={<CloseOutlined />}
              >
              </CustomButton>
            </Tooltip>
            <Tooltip title={t.kpisummarycard_save}>
              <CustomButton
                type="primary"
                disabled={loading}
                onClick={onSave}
                icon={<SaveOutlined />}
              >
              </CustomButton>
            </Tooltip>
          </div>
        )
      }
    </React.Fragment>
  );
});

export default DataActions;

