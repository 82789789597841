import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import IndicatorProgress from './IndicatorProgress';

import {
  Row,
  Col,
  Card,
} from 'antd';

import {
} from '../../actions/api';

import './style.less';

const ActionProgress = ({
  intl,
  stage,
  action,
  objectives,
  showObjectives = true,
  updateActionIndicator,
}) => {
  const t = intl.messages;

  const onUpdateActionIndicator = useCallback(
    (id, current_value, comment) => {
      updateActionIndicator(action.id, id, { current_value, comment });
    },
    [action, updateActionIndicator]
  );

  const actionObjectives = useMemo(
    () => {
      return action.objectives.map(objective => {
        return objectives.filter(obj => obj.id === objective)[0];
      });
    },
    [action, objectives]
  )

  return (
    <Row>
      <Col span={24}>
        <h4>{t.measurement_indicators}</h4>
      </Col>
      <Col span={24}>
        {action.indicators.map((indicator, index) => {
          return <Card key={index} className="form-item-preview">
            <IndicatorProgress
              action={action}
              indicator={indicator}
              updateActionIndicator={onUpdateActionIndicator}
              sequence={index + 1} />
          </Card>
        })}
      </Col>
      { showObjectives && (
        <>
          <Col span={24}>
            <h4>{t.objectives_associated}</h4>
          </Col>
          <Col span={24}>
            <Card>
              {actionObjectives.map(objective => {
                return <h4 key={objective.id}>{objective.name}</h4>;
              })}
            </Card>
          </Col>
        </>
      )}
    </Row>
  )
};

export default injectIntl(ActionProgress);
