import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import ReactMarkdown from 'react-markdown';
import {debounce} from 'lodash';

import useQuantitativeSchemaProps from 'utils/useQuantitativeSchemaProps';
import useOrganizations from 'utils/useOrganizations';
import { formatNumberWithIntl } from 'utils/formatNumber';
import { getNumberFormatOptions } from 'utils/kpi';

import { getOptions } from 'components/ChoiceAnswer/utils';
import CustomInput from 'components/CustomInput';
import CustomToggle from 'components/CustomToggle';
import CustomSelect from 'components/CustomSelect';
import AplanetIcon from 'components/AplanetIcon';
import CustomTextArea from 'components/CustomTextArea';
import TableValueModal from './TableValueModal';
import ReadOnlyValue from './ReadOnlyValue';

import BooleanTruePNG from 'assets/boolean-true.png';
import BooleanFalsePNG from 'assets/boolean-false.png';

import {
  Popover,
} from 'antd';

import {
  requestKpiUpdate,
} from 'actions/api';


const Qualitative = ({
  kpi,
  onChange,
  mode = 'show',
  onChangeMode,
  position,
  canEdit = true,
}) => {
  const [initialValue, setInitialValue] = useState(kpi.kpi_value?.text || null);
  const [value, setValue] = useState(kpi.kpi_value?.text || null);
  const [isPopoverOpen, setIsPopoverOpen] = useState();

  useEffect(
    () => {
      if (mode !== 'edit') {
        if (
          (initialValue === value)
          && (value !== kpi.kpi_value?.text)
        ) {
          // Update all other duplicate records if one of them was changed
          setValue(kpi.kpi_value?.text);
          setInitialValue(kpi.kpi_value?.text);
        }

        if (
          (value === kpi.kpi_value?.text)
          && (value !== initialValue)
        ) {
          // Update own initial value when reducer has same value as current
          setInitialValue(kpi.kpi_value?.text);
        }
      }
    },
    [
      kpi.kpi_value,
      mode,
      value,
      initialValue,
    ],
  );

  const onChangeDebounced = debounce(function(value) {
    onChange(value);
  }, 2000);

  const handleValueChange = useCallback(e => {
    const newValue = e?.target ? e.target.value : e;

    setValue(newValue);
    onChangeDebounced({
      ...kpi.kpi_value,
      text: newValue,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    kpi,
    onChange,
  ]);

  useEffect(() => {
    if(kpi.pendingValueUpdate &&
      (value === kpi.kpi_value?.text) && (value === initialValue)
    ) {
      onChange(kpi.kpi_value);
    }
  }, [
    initialValue,
    onChange,
    value,
    kpi,
  ]);

  if (mode === 'edit') {
    return (
      <CustomInput
        value={value}
        suffix={
          <Popover
            placement="bottomRight"
            title=""
            trigger="click"
            open={isPopoverOpen}
            onOpenChange={setIsPopoverOpen}
            content={
              <CustomTextArea
                value={value}
                onChange={handleValueChange}
                style={{width: '35vw'}}
              />
            }
            arrowPointAtCenter
          >
            <AplanetIcon
              className="DataManagement__bulk-manage__action_icon"
              name="arrow-up-right-and-arrow-down-left-from-center"
              size="15px"
            />
          </Popover>
        }
        onChange={handleValueChange}
        autoFocus
      />
    );
  }

  return (
    <ReadOnlyValue
      kpi={kpi}
      value={value && <ReactMarkdown className="ReadOnlyValue--markdown" source={value} />}
      mode={mode}
      changeMode={onChangeMode}
      position={position}
      cellType='value'
      canEdit={canEdit}
    />
  );
}

const Quantitative = ({
  intl,
  onChange,
  kpi,
  mode = 'show',
  onChangeMode,
  position,
  canEdit = true,
}) => {
  const [initialValue, setInitialValue] = useState(kpi.kpi_value?.value || null);
  const [value, setValue] = useState(kpi.kpi_value?.value || null);
  const schemaProps = useQuantitativeSchemaProps(
    kpi.schema,
    kpi.config,
    intl
  );

  const {
    suborganization,
  } = useOrganizations();
  const { config = {} } = suborganization;

  useEffect(
    () => {
      if (mode !== 'edit') {
        if (
          (initialValue === value)
          && (value !== kpi.kpi_value?.value)
        ) {
          // Update all other duplicate records if one of them was changed
          setValue(kpi.kpi_value?.value);
          setInitialValue(kpi.kpi_value?.value);
        }

        if (
          (value === kpi.kpi_value?.value)
          && (value !== initialValue)
        ) {
          // Update own initial value when reducer has same value as current
          setInitialValue(kpi.kpi_value?.value);
        }
      }
    },
    [
      kpi.kpi_value,
      mode,
      value,
      initialValue,
    ],
  );

  const onChangeDebounced = debounce(function(value) {
    onChange(value);
  }, 1000);

  const handleValueChange = useCallback(newValue => {
    setValue(newValue);
    onChangeDebounced({
      ...kpi.kpi_value,
      value: newValue,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    kpi,
    onChange,
  ]);

  useEffect(() => {
    if(kpi.pendingValueUpdate &&
      (value === kpi.kpi_value?.value) && (value === initialValue)
    ) {
      onChange(kpi.kpi_value);
    }
  }, [
    initialValue,
    onChange,
    value,
    kpi,
  ]);

  const readOnlyValue = useMemo(() => {
    return typeof value === 'undefined' || value === null
      ? ''
      : formatNumberWithIntl(intl)(
        value,
        getNumberFormatOptions(kpi.schema, config)
      )
  }, [
    intl,
    value,
    config,
    kpi,
  ]);

  if (mode === 'edit') {
    return (
      <CustomInput.Number
        value={value}
        onChange={handleValueChange}
        {...schemaProps}
        controls={false}
        autoFocus
      />
    );
  }

  return (
    <ReadOnlyValue
      kpi={kpi}
      value={readOnlyValue}
      mode={mode}
      changeMode={onChangeMode}
      position={position}
      cellType='value'
      canEdit={canEdit}
    />
  );
}

const BooleanValue = ({
  intl,
  onChange,
  kpi,
  mode = 'show',
  onChangeMode,
  position,
  canEdit = true,
}) => {
  const [initialValue, setInitialValue] = useState(kpi.kpi_value?.boolean || null);
  const [value, setValue] = useState(kpi.kpi_value?.boolean);

  useEffect(
    () => {
      if (mode !== 'edit') {
        if (
          (initialValue === value)
          && (value !== kpi.kpi_value?.boolean)
        ) {
          // Update all other duplicate records if one of them was changed
          setValue(kpi.kpi_value?.boolean);
          setInitialValue(kpi.kpi_value?.boolean);
        }

        if (
          (value === kpi.kpi_value?.boolean)
          && (value !== initialValue)
        ) {
          // Update own initial value when reducer has same value as current
          setInitialValue(kpi.kpi_value?.boolean);
        }
      }
    },
    [
      kpi.kpi_value,
      mode,
      value,
      initialValue,
    ],
  );

  const handleValueChange = useCallback(newValue => {
    setValue(newValue);
    onChange({
      ...kpi.kpi_value,
      boolean: newValue,
    });
  }, [
    kpi,
    onChange,
  ]);

  useEffect(() => {
    if(kpi.pendingValueUpdate &&
      (value === kpi.kpi_value?.boolean) && (value === initialValue)
    ) {
      onChange(kpi.kpi_value);
    }
  }, [
    initialValue,
    onChange,
    value,
    kpi,
  ]);

  if (mode === 'edit') {
    return (
      <CustomToggle
        options={[intl.formatMessage({id: 'yes'}), intl.formatMessage({id: 'no'})]}
        checked={value}
        onChange={handleValueChange}
      />
    );
  }

  return (
    <ReadOnlyValue
      kpi={kpi}
      value={
        typeof value === 'boolean'
        ? <img alt="boolean" src={value ? BooleanTruePNG : BooleanFalsePNG} />
        : value
      }
      mode={mode}
      changeMode={onChangeMode}
      position={position}
      cellType='value'
      canEdit={canEdit}
    />
  );
}

const Choice = ({
  intl,
  onChange,
  schemaLabels = {}, // TODO: Get schemalabels
  kpi,
  mode = 'show',
  onChangeMode,
  position,
  canEdit = true,
}) => {
  const [initialValue, setInitialValue] = useState(kpi.kpi_value?.choice || null);
  const [value, setValue] = useState(kpi.kpi_value?.choice);

  useEffect(
    () => {
      if (mode !== 'edit') {
        if (
          (initialValue === value)
          && (value !== kpi.kpi_value?.choice)
        ) {
          // Update all other duplicate records if one of them was changed
          setValue(kpi.kpi_value?.choice);
          setInitialValue(kpi.kpi_value?.choice);
        }

        if (
          (value === kpi.kpi_value?.choice)
          && (value !== initialValue)
        ) {
          // Update own initial value when reducer has same value as current
          setInitialValue(kpi.kpi_value?.choice);
        }
      }
    },
    [
      kpi.kpi_value,
      mode,
      value,
      initialValue,
    ],
  );

  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);

  const options = getOptions(kpi.schema, schemaLabels, tableDimensions);

  const handleValueChange = useCallback(choice => {
    setValue(choice);
    onChange({ ...kpi.kpi_value, choice});
  }, [
    kpi,
    onChange,
  ]);

  if (mode === 'edit') {
    return (
      <CustomSelect
        title={intl.formatMessage({id: 'choiceanswer_placeholder'})}
        selected={value}
        options={options}
        onSelect={handleValueChange}
        className="KpiDetail__answer-choice-dropdown"
        autoFocus
      />
    );
  }

  return (
    <ReadOnlyValue
      kpi={kpi}
      value={value}
      mode={mode}
      changeMode={onChangeMode}
      position={position}
      cellType='value'
      canEdit={canEdit}
    />
  );
}

const Table = ({
  kpi,
  onChange,
  canEdit = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`KpiValue__table${canEdit ? '' : ' KpiValue__table--disabled'}`}>
      <AplanetIcon
        className={`DataManagement__bulk-manage__action_icon ${kpi.kpi_value ? '' : 'is_empty'}`}
        name="table"
        size="15px"
        onClick={() => setIsOpen(true)}
      />
      { isOpen &&
        <TableValueModal
          visible={isOpen}
          kpi={kpi}
          onClose={() => setIsOpen(false)}
          onSave={onChange}
        />
      }
    </div>
  );
};

const COMPONENT_PER_TYPE = {
  'qualitative': Qualitative,
  'quantitative': Quantitative,
  'boolean': BooleanValue,
  'choice': Choice,
  'table': Table,
}

const KpiValue = ({
  intl,
  position,
  kpi,
  onChangeMode,
  ...rest
}) => {
  const dispatch = useDispatch();

  const {
    organization: root_organization,
    permissions,
  } = useOrganizations();

  const Component = COMPONENT_PER_TYPE[kpi.schema.type];

  const onChange = useCallback((value, period = kpi.period) => {
    dispatch(
      requestKpiUpdate(
        root_organization.slug,
        kpi.organization_slug,
        kpi.slug,
        period,
        {
          value,
          comment: kpi.comment,
        },
        { fieldToUpdate: 'value' },
      )
    )
  }, [
    root_organization,
    dispatch,
    kpi,
  ]);

  const handleOnChangeMode = useCallback((mode) => {
    if (
      (kpi.kpi_source === 'manual') &&
      (kpi.ready_to_validate_level === 0) &&
      permissions.can_write_kpi
    ) {
      onChangeMode({
        column: 'kpi_value',
        key: position,
        mode,
      });
    }
  }, [
    onChangeMode,
    permissions,
    position,
    kpi,
  ]);

  if(!Component) {
    return kpi.schema.type;
  }

  return <Component
    intl={intl}
    kpi={kpi}
    onChange={onChange}
    onChangeMode={handleOnChangeMode}
    position={position}
    {...rest}
  />
}

export default injectIntl(KpiValue);
