import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import EditableComment from 'components/EditableComment'
import CustomSelect from 'components/CustomSelect';
import AnswerFooter from 'components/AnswerFooter';

import { getOptions } from './utils';

import {
  Divider,
  Modal,
} from 'antd';
import useOrganizations from 'utils/useOrganizations';
import { useFeatureList } from 'components/FeatureSwitch';

const DEFAULT_INITIAL_VALUE = { choice: undefined };
const QUALITATIVE_SCHEMA_TYPE= 'qualitative';
const SELECT_MIN_WIDTH = "450px"

const Component = injectIntl(({
  intl,
  schema,
  schemaLabels,
  value,
  onChange,
}) => {
  const t = intl.messages;
  const { choice } = value || {};
  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);
  const handleChange = useCallback((choice) => {
    onChange({
      ...value,
      choice,
    });
  }, [
    value,
    onChange,
  ]);

  const options = getOptions(schema, schemaLabels, tableDimensions);

  // TODO: Maybe use a radio here...
  return (
    <CustomSelect
      title={t.choiceanswer_placeholder}
      selected={choice}
      options={options}
      onSelect={handleChange}
      className="KpiDetail__answer-choice-dropdown"
    />
  );
});

const TableComponent = ({
  schema,
  schemaLabels,
  value,
  onChange,
  tableDimensions: overrideTableDimensions,
}) => {
  const { choice } = value || {};
  const {
    table_dimensions: taxonomyTableDimensions = {},
  } = useSelector(state => state.taxonomies);

  const tableDimensions = useMemo(
    () => overrideTableDimensions || taxonomyTableDimensions,
    [
      overrideTableDimensions,
      taxonomyTableDimensions,
    ]
  );

  const handleChange = useCallback((choice) => {
    onChange({
      ...value,
      choice
    });
  }, [
    value,
    onChange,
  ]);

  const options = getOptions(schema, schemaLabels, tableDimensions);

  return (
    <CustomSelect
      title={''}
      selected={choice}
      options={options}
      onSelect={handleChange}
      dropdownStyle={{ minWidth: SELECT_MIN_WIDTH }}
      className="KpiDetail__answer-choice-mini-dropdown"
    />
  );
};

const Edit = ({
  intl,
  schema,
  schemaLabels,
  value: initialValue,
  comment: initialComment,
  loading,
  previous_value,
  previous_comment,
  previous_attachments,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
  onShowLastPeriod,
  onCancel,
  onSave,
  onClear,
  config
}) => {
  const {
    permissions,
  } = useOrganizations();

  const {
    features: featureList,
  } = useFeatureList();

  const t = intl.messages;
  const [ value, setValue ] = useState(initialValue || DEFAULT_INITIAL_VALUE);
  const [ comment, setComment ] = useState(initialComment || '');
  const [ edittingComment, setEdittingComment ] = useState(false);
  const [ dirty, setDirty ] = useState(false);

  const handleChange = useCallback((value) => {
    setDirty(true);
    setValue(value);
  }, []);

  const handleUseLastValue = useCallback(() => {
    onCancel();
    onUseLastValue();
  }, [
    onUseLastValue,
    onCancel,
  ]);

  const handleUseLastWholeData = useCallback(() => {
    onCancel();
    onUseLastWholeData();
  }, [
    onUseLastWholeData,
    onCancel,
  ]);

  const handleUseLastAttachments = useCallback(() => {
    onCancel();
    onUseLastAttachments();
  }, [
    onUseLastAttachments,
    onCancel,
  ]);

  const handleUseLastPeriod = useCallback(() => {
    onCancel();
    onUseLastPeriod();
  }, [
    onUseLastPeriod,
    onCancel,
  ]);

  const handleCommentChange = useCallback((comment) => {
    setDirty(true);
    setComment(comment);
  }, []);

  const isEmpty = useMemo(() => {
    return typeof (value || {}).choice === 'undefined';
  }, [
    value,
  ]);

  // TODO: Choice fields shoul perhaps auto-save!!!
  const handleCancel = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    onCancel();
  }, [
    dirty,
    onCancel,
  ]);

  const handleClear = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    Modal.confirm({
      title: t.kpi_detail_clear_title,
      content: t.kpi_detail_clear_content,
      okText: t.kpi_detail_clear_ok,
      okType: 'danger',
      cancelText: t.kpi_detail_clear_cancel,
      onOk() {
        onClear ? onClear() : onSave(null, '');
      },
      onCancel() {},
    });
  }, [
    t,
    dirty,
    onSave,
    onClear,
  ]);

  const handleSave = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    onSave(
      value,
      comment,
    );
  }, [
    dirty,
    value,
    comment,
    onSave,
  ]);

  const hasTargetPermissions = useMemo(() => {
    return featureList && featureList.has("targets")
      && permissions.can_configure_kpi
      && schema.type !== QUALITATIVE_SCHEMA_TYPE;
  }, [
    featureList,
    permissions.can_configure_kpi,
    schema.type
  ]);

  return (
    <React.Fragment>
      <section>
        <Component
          schema={schema}
          schemaLabels={schemaLabels}
          value={value}
          onChange={handleChange}
        />
      </section>
      <Divider/>
      <section>
        <EditableComment.Component
          title={t.privateComment}
          value={comment}
          onChange={handleCommentChange}
          editMode={edittingComment}
          setEditMode={setEdittingComment}
          editable
          titleClass='KpiDetail__title'
          config={config}
        />
      </section>
      <Divider/>
      <AnswerFooter
        editting
        canWrite
        hasLastPeriod={!!previous_value}
        hasLastComment={!!previous_comment}
        hasLastAttachments={!!previous_attachments?.length}
        onShowLastPeriod={onShowLastPeriod}
        onUseLastValue={handleUseLastValue}
        onUseLastWholeData={handleUseLastWholeData}
        onUseLastAttachments={handleUseLastAttachments}
        onUseLastPeriod={handleUseLastPeriod}
        hasInitialValue={!!initialValue}
        onCancelEdit={handleCancel}
        onClearEdit={handleClear}
        onSaveEdit={handleSave}
        dirty={dirty}
        empty={isEmpty}
        isTargetElegible={hasTargetPermissions}
      />
    </React.Fragment>
  );
};

Edit.Component = Component;
Edit.TableComponent = TableComponent;

export default injectIntl(Edit);

