import { loop, Cmd } from 'redux-loop';

const TARGET = 'goi_member';

const initialState = {
  error: null,
  data: [],
  currentGroup: {},
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || response.target !== TARGET) return state;
      switch(response.method) {
        case 'POST':
          return loop(state, Cmd.action({ ...action, type: 'ADD_GOI_MEMBER' }));
        case 'PUT':
          return loop(state, Cmd.action({ ...action, type: 'EDIT_GOI_MEMBER' }));
        default:
          return loop(state, Cmd.action({ ...action, type: 'DELETE_GOI_MEMBER' }));
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        error: action.code,
      };
    default:
      return state;
  }
};

export {
  reducer as goi_member,
};
