import { loop, Cmd } from 'redux-loop';
import { replace } from 'connected-react-router'

const PROFILE_ENDPOINT_REGEX = new RegExp('^[a-z0-9_-]+/profile$');
const initialState = null;

const reducer = (state = initialState, action) => {
  if(
    action.type === 'API_CALL_FAILED' &&
    action.code === 402 &&
    action.request &&
    (
      PROFILE_ENDPOINT_REGEX.test(action.request.endpoint) &&
      action.request.method === 'GET'
    )
  )
  {
    return loop(
      state,
      Cmd.action(replace('/not_subscribed'))
    )
  }
  return state;
};

export {
  reducer as profile_request_failed,
};
