import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { injectIntl } from 'react-intl';

import { CSVLink } from "react-csv";
import { createExcel, excelToArray } from 'utils/excel';

import { formatDate, DATE_ONLY } from 'utils/date';
import CustomTable from 'components/CustomTable';
import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';

import {
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
} from 'antd';
import {
  DownOutlined,
} from '@ant-design/icons';

import './style.less';

const AnalysisGoi = ({
  intl,
  createSurvey,
  requestSurveys,
  analysis,
  mainOrgSlug,
  surveys,
  closeSurveys,
  analysisSusIssues,
  surveyType,
}) => {
  const [showNewSurveyModal, setShowNewSurveyModal] = useState(false);
  const [surveyName, setSurveyName] = useState();
  const t = intl.messages;
  const EXCEL_SHEET_NAME = t[`analysis_${analysis.method}_goi_surveys_${surveyType}`];

  useEffect(() => {
    requestSurveys();
  }, [
    requestSurveys, mainOrgSlug, analysis,
  ]);

  const onUploadSurveys = useCallback((e) => {
    const file = e.target.files[0];
    const issueColIndex = 3;
    excelToArray(
      file,
      EXCEL_SHEET_NAME,
      (rows, isCsvFile) => {
        let data = rows;

        data = rows.map((row, rowIndex) => {
          if(rowIndex === 0) {
            // Skip header
            return row;
          }
          return row.map((col, colIndex) => {
            if(colIndex === issueColIndex) {
              const issue_slug = analysisSusIssues.find(
                analysisIssue => analysisIssue.issue === col?.replace(/ ̡/g, ',')
              )?.issue_slug

              if (issue_slug) {
                return issue_slug;
              }

              const subject_slug =  analysisSusIssues.find(
                analysisIssue => analysisIssue.subject === col
              )?.subject_slug
              return subject_slug;
            }
            return col;
          });
        });

        createSurvey(file.name, surveyType, data);
      }
    );
  }, [
    surveyType,
    analysisSusIssues,
    createSurvey,
    EXCEL_SHEET_NAME,
  ]);

  const surveyColumns = useMemo(
    () => [
      {
        title: t.name,
        dataIndex: 'name',
      },
      {
        title: t.date_of_survey,
        dataIndex: 'created_at',
        render: (text) => formatDate(text, DATE_ONLY, intl),
      },
      {
        title: t.status,
        dataIndex: 'status',
        render: (text) => text==='close' ? t.closed : t[text],
      },
      {
        title: t.action,
        width: 100,
        render: (text, record) => (
          <Link
            to={`/analysis/${analysis.id}/survey/${record.id}/${record.status === 'close' ? 'results' : ''}`}
            className='CustomButton ant-btn-primary'
          >
            {record.status === 'close' ? t.view : t.open}
          </Link>
        )
      },
    ],
    [analysis.id, intl, t]
  );

  const surveyCsvTemplate = useMemo( () => [[
    t.analysis_goi_name,
    t.analysis_goi_email,
    t.analysis_goi_group,
    t.analysis_goi_issue,
    t.analysis_goi_weight,
    t.analysis_goi_comment,
  ]], [ t ]);

  const onDownloadXlsx = useCallback(() => {
    const issueNames = analysisSusIssues.map(
      analysisIssue => analysisIssue.issue || analysisIssue.subject
    );
    createExcel(
      `analysis_${analysis.id}_survey_template.xlsx`,
      EXCEL_SHEET_NAME,
      surveyCsvTemplate,
      true,
      [
        {col: 'D', values: issueNames},
        {col: 'E', values: [1, 2, 3, 4, 5] }
      ]
    );
  }, [
    analysis,
    surveyCsvTemplate,
    analysisSusIssues,
    EXCEL_SHEET_NAME,
  ]);

  const filteredSurveys = useMemo(
    () => (surveys || []).filter(survey => survey.type === surveyType),
    [surveys, surveyType]
  );

  const onCloseSurveys = useCallback(
    () => closeSurveys(surveyType),
    [closeSurveys, surveyType]
  );

  return (
    <>
      <p>{ t.goi_description }</p>
      <h3>{ t[`analysis_${analysis.method}_goi_surveys_${surveyType}`] }</h3>
      <CustomTable
        columns={surveyColumns}
        dataSource={filteredSurveys}
        rowKey='id'
      />
      {analysis.status !== 'completed' && (
        <>
          <Row type="flex" justify="space-between" className="survey-actions">
            <Col>
              {filteredSurveys.some(survey => survey.status === 'pending') &&
                <CustomButton
                  type="primary"
                  onClick={onCloseSurveys}
                >
                  { t.close_surveys }
                </CustomButton>
              }
            </Col>
            <Col>
              <CustomButton
                type="primary"
                onClick={() => setShowNewSurveyModal(true)}
              >
                { t.make_new_survey }
              </CustomButton>
            </Col>
          </Row>
          <Row type="flex" className="survey-actions">
            <Col span={5}>
              <Dropdown
                className="downloadTemplate"
                overlay={
                  <Menu>
                    <Menu.Item key="csv">
                      <CustomButton type="primary">
                        <CSVLink data={surveyCsvTemplate} filename={`analysis_${analysis.id}_survey_template.csv`}>
                          {t.analysis_goi_download_template_csv}
                        </CSVLink>
                      </CustomButton>
                    </Menu.Item>
                    <Menu.Item key="xlsx">
                      <CustomButton
                        type="primary"
                        onClick={onDownloadXlsx}
                      >
                        {t.analysis_goi_download_template_xlsx}
                      </CustomButton>
                    </Menu.Item>
                  </Menu>
                }
              >
                <CustomButton>
                  {t.analysis_goi_download_template} <DownOutlined />
                </CustomButton>
              </Dropdown>
            </Col>
            <Col>
              <CustomButton type="primary">
                <label htmlFor={`csv_input_${surveyType}`}>{ t.analysis_goi_upload_surveys }</label>
              </CustomButton>
                <input
                  className="csv_input"
                  id={`csv_input_${surveyType}`}
                  type="file"
                  accept=".csv,.xls,.xlsx"
                  onChange={onUploadSurveys}
                />
            </Col>
          </Row>
        </>
      )}
      <Modal
        title={t.analysis_goi_new_survey_title}
        visible={showNewSurveyModal}
        okText={t.analysis_goi_new_survey_ok}
        cancelText={t.analysis_goi_new_survey_cancel}
        okButtonProps={{
          disabled: !surveyName,
        }}
        onOk={() => createSurvey(surveyName, surveyType)}
        onCancel={() => setShowNewSurveyModal(false)}
      >
        <CustomInput onChange={(e) => setSurveyName(e.target.value)} />
      </Modal>
    </>
  );
}


export default injectIntl(
  withRouter(AnalysisGoi)
);
