import { loop, Cmd } from 'redux-loop';
import { history } from 'store';

const requestPush = async (url) => {
  history.push(url);
  return Promise.resolve();
};

const NON_REDIRECT_PARAMS = ['avatar', 'language'];
const PROFILE_ENDPOINT_REGEX = new RegExp('^[a-z0-9_-]+/profile$');
const initialState = null;

const reducer = (state = initialState, action) => {
  if(
    action.type === 'API_CALL_COMPLETE' &&
    action.response &&
    (
      PROFILE_ENDPOINT_REGEX.test(action.response.endpoint) &&
      action.response.method === 'PUT' &&
      Object.keys(action.response.body).filter(param => !NON_REDIRECT_PARAMS.includes(param)).length > 0
    )
  )
  {
    return loop(state, Cmd.run(requestPush, { args: [`/`] }));
  }
  return state;
};

export {
  reducer as profile_edit_done,
};
