import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

import { CSVLink } from "react-csv";

import { Loading } from 'tsComponents/emptyStates/Loading';
import AddActiveListeningForm from 'components/AddActiveListeningForm';
import CustomTable from 'components/CustomTable';
import SdgMiniLogo from 'components/SdgMiniLogo';
import EsgLogo from 'components/EsgLogo';
import CustomButton from 'components/CustomButton';
import InformationModal from 'components/InformationModal';
import { sdgSlugToNumber } from 'utils/sdg';
import { createExcel, excelToArray } from 'utils/excel';

import {
  Row,
  Col,
  Alert,
  Checkbox,
  Rate,
  Button,
  Dropdown,
  Menu,
} from 'antd';
import {
  PlusOutlined,
  DownOutlined,
  CommentOutlined,
} from '@ant-design/icons';

import './style.less';
import {useSDGsClustering} from "../../hooks/sdg";

const shortStandard = {
  'gri': 'GRI',
  'gri-2021': 'GRI-2021',
  'equality': 'EQ',
  'aplanet': 'Aplanet',
  'sdg': 'SDG',
  'sdgc': 'SDG-C',
  'bcorp': 'B-Corp',
  'einf': 'EINF',
  'euss': 'Electric Utilities Sector Supplement',
  'ungc': 'UNGC',
  'tefce': 'TEFCE',
  'prme': 'PRME',
  'sasb-hc-dy': 'SASB-HC-DY',
  'sasb-hc-di': 'SASB-HC-DI',
  'sasb-hc-dr': 'SASB-HC-DR',
  'sasb-hc-mc': 'SASB-HC-MC',
  'sasb-hc-ms': 'SASB-HC-MS',
  'sasb-hc-bp': 'SASB-HC-BP',
  'sasb-fn-ac': 'SASB-FN-AC',
  'sasb-fn-cb': 'SASB-FN-CB',
  'sasb-fn-cf': 'SASB-FN-CF',
  'sasb-fn-in': 'SASB-FN-IN',
  'sasb-fn-ib': 'SASB-FN-IB',
  'sasb-fn-mf': 'SASB-FN-MF',
  'sasb-fn-ex': 'SASB-FN-EX',
  'sasb-cg-aa': 'SASB-CG-AA',
  'sasb-cg-mr': 'SASB-CG-MR',
  'tcfd': 'TCFD',
  'sasb-fb-fr': 'SASB-FB-FR',
  'sasb-if-re': 'SASB-IF-RE',
  'sfdr': 'SFDR',
  'shift': 'SHIFT',
  'scority': 'SCORITY',
  'ghg': 'GHG',
  'neutrality': 'Neutrality',
};

const ActiveListening = ({
  intl,
  analysis,
  mediums,
  addMedium,
  analysisSusIssues,
  fetchingAnalysisSusIssues,
  startActiveListening,
  uploadActiveListeningData,
  error,
  userProfile,
  mainOrgSlug,
}) => {
  const t = intl.messages;
  const [showAddMediumModal, setShowAddMediumModal] = useState(false);
  const activeListeningStarted = analysis && analysis.active_listening_mediums.length;
  const [selectedMediums, setSelectedMediums] = useState([]);
  const [selectedIssueComments, setSelectedIssueComments] = useState();

  const toggleMediumSelection = useCallback(
    (mediumId, selectedMedium) => {
      if(selectedMedium) {
        setSelectedMediums(
          selectedMediums.filter(medium => medium.id !== mediumId)
        );
      } else {
        setSelectedMediums([...selectedMediums, {id: mediumId}]);
      }
    },
    [selectedMediums]
  );

  const selectedMediumNames = useMemo(
    () => analysis.active_listening_mediums
      .map(medium => `${medium.id}: ${medium.name}`),
    [analysis]
    );

  const handleWeightChange = useCallback((mediumId, weight) => {
    const index = selectedMediums
      .map((m) => m.id)
      .indexOf(mediumId);

    setSelectedMediums([
      ...selectedMediums.slice(0, index),
      {...selectedMediums[index], weight},
      ...selectedMediums.slice(index + 1)
    ]);
  }, [selectedMediums]);

  const dataUploaded = useMemo(
    () => (
      !!(analysisSusIssues
        .map(issue => issue.active_listening_weight)
        .filter(weight => weight)
      ).length
    ),
    [analysisSusIssues]
  );

  const csvData = useMemo(
    () => (
      analysisSusIssues.map(issue => ([
        issue.id,
        `${issue.esg_types.map(type => t[`esg_type_${type}`]).join(' - ')}`,
        `${issue.sdgs.map(sdg => sdgSlugToNumber(sdg)).join(' - ')}`,
        `${issue.standards.map(standard => shortStandard[standard]).join(' - ')}`,
        issue.subject,
        issue.issue || '-',
      ]))
    ),
    [analysisSusIssues, t]
  );

  const csvHeader = useMemo(
    () => [
      'ID',
      t.analysis_csv_esg_type,
      t.analysis_csv_sdg,
      t.analysis_csv_standard,
      t.analysis_csv_subject,
      t.analysis_csv_issue,
      ...selectedMediumNames,
      t.analysis_csv_comments,
    ],
    [selectedMediumNames, t]
  );

  const csv = useMemo(
    () => [csvHeader, ...csvData],
    [csvData, csvHeader]
  );

  const onUploadCsv = useCallback(
    (e) => {
      const file = e.target.files[0];
      excelToArray(file, t[`analysis_${analysis.method}_tab_active_listening`], (data) => {
        uploadActiveListeningData(data);
      });
    },
    [
      uploadActiveListeningData,
      t,
      analysis,
    ]
  );

  const onDownlaod = useCallback(() => {
    createExcel(
      `analysis_${analysis.id}_active_listening_template.xlsx`,
      t[`analysis_${analysis.method}_tab_active_listening`],
      csv,
      true,
    );
  }, [
    analysis,
    csv,
    t,
  ]);
  const clusterSDGs = useSDGsClustering();

  const columns = useMemo(
    () => (
      [{
        title: t.subject,
        dataIndex: 'subject',
      }, {
        title: t.issue || t.issue,
        width: 300,
        dataIndex: 'issue',
      }, {
        title: t.standard,
        dataIndex: 'standards',
        render: (standards) => `${standards.map(standard => shortStandard[standard]).join(', ')}`,
      }, {
        title: t.sdg,
        dataIndex: 'sdgs',
        render: (sdgs) => clusterSDGs(sdgs).map(sdg => <SdgMiniLogo key={sdg.slug} sdg={sdg} />)
      }, {
        title: t.type,
        dataIndex: 'esg_types',
        render: (types) => types.map(type => <EsgLogo key={type} type={type} />)
      }, {
        title: t.weight,
        dataIndex: 'active_listening_weight',
      }, {
        title: t.mediums,
        dataIndex: 'active_listening_medium_results',
        render: data => data.map(medium => `${medium.name}: ${medium.weight}`).join(`, `),
      }, {
        title: t.comments,
        dataIndex: 'active_listening_comments',
        render: (text) => (
          text
          ? <CommentOutlined
              onClick={() => setSelectedIssueComments(text)}
            />
          : '-'
        ),
      }]
    ),
    [t, clusterSDGs]
  );

  if (fetchingAnalysisSusIssues) {
    return <Loading />;
  }

  if (!dataUploaded && analysis.status === 'completed') {
    return (
      <div className="ActiveListening">
        <Row type="flex" justify="space-around" style={{marginTop: 50}}>
          <h2>{ t.analysis_active_listening_no_data_closed}</h2>
        </Row>
      </div>
    );
  }

  if (!activeListeningStarted) {
    return (
      <div className="ActiveListening">
        <FormattedHTMLMessage
          id="active_listening_description"
          defaultMessage={ t.active_listening_description }
          tagName="h4"
        />
        <Row type="flex" justify="space-around" style={{marginTop: 50}}>
          <h2>{ t.select_active_listening_mediums }</h2>
        </Row>
        <Row type="flex" justify="center" gutter={[20, 20]}>
          <Col span={24}>
            <Row type="flex" justify="center" gutter={[5, 5]}>
              { selectedMediums.length > 0
                ? <Col span={12} offset={1}>
                    <Row>
                      <Col span={14} offset={10}>
                        <h4>{ t.weight }</h4>
                      </Col>
                    </Row>
                  </Col>
                : null
              }
              {mediums.map(medium => {
                const selectedMedium = selectedMediums.find(m => m.id === medium.id);
                return (
                  <Col map={medium.id} span={12} offset={1}>
                    <Row>
                      <Col
                        className="ActiveListening-competitor-option"
                        span={10}
                        key={medium.id}
                      >
                        <Checkbox
                          className="ActiveListening-checkbox"
                          onChange={() => toggleMediumSelection(medium.id, selectedMedium)}
                        >
                          {medium.name}
                        </Checkbox>
                      </Col>
                      <Col span={14}>
                        {selectedMedium && (
                          <Rate
                            character={({ index }) => (
                              <Button
                                className={`ant-btn-primary RatingButton${selectedMedium.weight === index + 1 ? ' focus' : ''}`}
                              >
                                <span>{index + 1}</span>
                              </Button>
                            )}
                            onChange={(value) => handleWeightChange(medium.id, value)}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                )
              })}
            </Row>
          </Col>
          <Col
            span={24}
            style={{marginTop: 20, textAlign: "center"}}
            className='inline-button-container'
          >
            <CustomButton
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShowAddMediumModal(true)}
            >
              {t.others}
            </CustomButton>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <CustomButton
            type="primary"
            onClick={() => startActiveListening({
              mediums: selectedMediums,
            })}
            disabled={selectedMediums.length > 0 ? false : true}
          >
            { t.start_active_listening }
          </CustomButton>
        </Row>

        <AddActiveListeningForm
          visible={showAddMediumModal}
          setVisibility={setShowAddMediumModal}
          addMedium={addMedium}
        />
      </div>
    );
  }
  if (dataUploaded) {
    return (
      <div className="ActiveListening">
        <Row type="flex" justify="end" className='active-listening-actions'>
          <Col>
            <Link
              to={analysis.method === 'internal_external' ? 'results' : 'external'}
              className="CustomButton ant-btn-primary"
            >
              {t.next}
            </Link>
          </Col>
        </Row>
        <CustomTable
          columns={columns}
          dataSource={analysisSusIssues}
          childrenColumnName='not-children'
          rowKey='id'
        />
        <InformationModal
          visible={selectedIssueComments ? true : false}
          title={t.comments}
          message={selectedIssueComments}
          onClose={() => setSelectedIssueComments()}
        />
      </div>
    )
  }
  return (
    <div className="ActiveListening">
      <Row type="flex" justify="center">
        {error &&
        <Alert message={t[error]} type="error" />
        }
      </Row>
      <Row type="flex" justify="center" style={{marginTop: 50, textAlign: 'center'}}>
        <FormattedHTMLMessage
          id="analysis_processing_message"
          defaultMessage={ t.analysis_processing_message }
          tagName="h2"
        />
      </Row>
      {userProfile && userProfile.role === 'system' &&
      <Row type="flex" justify="center" style={{marginTop: 50, textAlign: 'center'}}>
        <Col span={18}>
          <Row type="flex" justify="center">
            <Col span={8} className='inline-button-container'>
              <Dropdown
                className="downloadTemplate"
                overlay={
                  <Menu>
                    <Menu.Item key="csv">
                     <CustomButton type="primary">
                        <CSVLink
                          data={csv}
                          filename={`analysis_${analysis.id}_active_listening_template.csv`}
                        >
                          {t.download_template_csv}
                        </CSVLink>
                      </CustomButton>
                    </Menu.Item>
                    <Menu.Item key="xlsx">
                      <CustomButton
                        type="primary"
                        onClick={onDownlaod}
                      >
                        {t.download_template_xlsx}
                      </CustomButton>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button>
                  {t.download_template} <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
            <Col span={8} className='inline-button-container'>
              <>
                <CustomButton type="primary">
                  <label htmlFor="active_listening_csv_input">{t.upload_active_listening}</label>
                </CustomButton>
                <input id="active_listening_csv_input" type="file" accept=".csv,.xls,.xlsx" onChange={onUploadCsv}/>
              </>
            </Col>
          </Row>
        </Col>
      </Row>
      }
    </div>
  );
}


export default injectIntl(ActiveListening);
