import {
  useReducer,
  useCallback,
} from 'react';

import { deepUpdate } from './functional';

import {
  adaptInitialState as adaptTableState,
} from './useTableState';

export const adaptInitialState = (props, initialState) => {
  const { schema = {}, tableDimensions } = props || {};
  const components = schema.components || [];
  return components.reduce((carry, component) => {
    const key = component.name;
    carry[key] = (
      component.type === 'table'
        ? adaptTableState(
            component,
            tableDimensions,
            (initialState || {})[key]
          )
        : ((initialState || {})[key] || undefined)
    );
    return carry;
  }, {})
};

// NOTICE: We ignore the first curried argument
export const getReducer = () => (state, action) => {
  switch(action.type) {
    case 'UPDATE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'UPDATE_TABLE':
      return {
        ...state,
        [action.name]: deepUpdate(state[action.name], action.address, action.value),
      };
    case 'DELETE':
      return {
        ...state,
        [action.name]: undefined,
      };
    case 'DELETE_TABLE':
      return {
        ...state,
        [action.name]: deepUpdate(state[action.name], action.address, undefined),
      };
    default:
      return state;
  }
};

export const useTupleState = ({
  schema,
  tableDimensions,
  initialState: _initialState,
  slug,
}) => {
  const initialState = adaptInitialState({ schema, tableDimensions }, _initialState);

  const reducer = getReducer({ schema, slug });
  const [state, dispatch] = useReducer(reducer, initialState);
  const update = useCallback(
    (name, ...params) => {
      const components = schema.components || [];
      if(components.find(c => c.name === name && c.type === 'table')) {
        dispatch({
          type: 'UPDATE_TABLE',
          name,
          address: params[0],
          value: params[1],
        });
      } else {
        dispatch({
          type: 'UPDATE',
          name,
          value: params[0],
        });
      }
    },
    [
      schema,
      dispatch,
    ]
  );
  const _delete = useCallback(
    (name, ...params) => {
      const components = schema.components || {};
      if(components[name] && components[name].type === 'table') {
        dispatch({
          type: 'DELETE_TABLE',
          name,
          address: params[0],
        });
      } else {
        dispatch({
          type: 'DELETE',
          name,
        });
      }
    },
    [
      schema,
      dispatch,
    ]
  );

  return {
    update,
    delete: _delete,
    values: state,
  };
};

