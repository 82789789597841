import React, { useCallback } from 'react';
import { injectIntl} from 'react-intl';

import {
  Input,
  Form,
  Modal,
  Select,
} from 'antd';

import './style.less';

const AREA_OPTIONS = [
  'conciliation',
  'non_sexist_communication',
  'occupational_health',
  'organizational_culture_and_work_environment',
  'other',
  'professional_classification',
  'promotion',
  'remuneration',
  'representation',
  'selection',
  'social_policy',
  'sexism_and_sexual_harassment',
  'training',
  'women_risk_of_exclusion',
  'working_conditions',
];

const AddMeasureForm = ({
  intl,
  visible,
  setVisibility,
  onSubmit,
}) => {
  const t = intl.messages;
  const [form] = Form.useForm();
  const { validateFields } = form;

  const handleOnOk = useCallback(
    () => {
      validateFields().then(() => {
        const { area, name } = form.getFieldsValue();
        onSubmit({ area, name });
        form.setFieldsValue({area: '', name: ''});
        setVisibility(false);
      });
    },
    [validateFields, form, onSubmit, setVisibility]
  );

  return (
    <Modal
      title={`${t.add} ${t.equality_plan_measure}`}
      visible={visible}
      onOk={handleOnOk}
      onCancel={() => setVisibility(false)}
      okText={t.save}
      cancelText={t.cancel}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t.equality_plan_area}
          name='area'
          rules={[{
            required: true, message: t.form_error_required
          }]}
        >
          <Select>
            {AREA_OPTIONS.sort((a, _) => t['equality_plan_area_' + a]).map(value => (
              <Select.Option
                key={value}
                value={value}
              >
                {t['equality_plan_area_' + value]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t.equality_plan_measure}
          name='name'
          rules={[{
            required: true, message: t.form_error_required
          }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default injectIntl(
  AddMeasureForm
);
