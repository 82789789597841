import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import useOrganizations from 'utils/useOrganizations';

import {
  getKpiStatus,
  byCode,
} from 'utils/kpi'

import {
  findTree,
  mapOverTreeFromLeafs,
  flattenTree,
  idField,
  childrenField,
} from 'utils/tree';

import {
  requestDataCategories,
  approveKpis,
} from 'actions/api';
import { toggleSidebar } from 'actions/app';

import CustomButton from 'components/CustomButton';
import Filters from 'components/ListFilters';
import ListSidebar from 'components/ListSidebar';
import KpiSummaryCard from 'components/KpiSummaryCard';
import ModalApproveRejectKpi from 'components/ModalApproveRejectKpi';
import {
  makeSearchString,
  makeSearchStrings,
} from 'utils/strings';

import CustomProgress from 'components/CustomProgress';
import {
  Row,
  Col,
} from 'antd';

import useScreenFilters from 'hooks/useScreenFilters';
import {SCREENS} from 'hooks/useScreenFilters/constants';

import './style.less';

const ROOT_SLUG = '__root';

const RESULTS_PER_PAGE = 10;

const COLORS = {
  environmental: '#44B381',
  social: '#3679FF',
  governance: '#7C29FF',
  economic: '#F9D942',
}

const DEFAULT_APPROVAL_LEVELS = 1;

const getStandardInfo = (kpi) => {
  const { standard_info } = kpi;
  if(!kpi || !kpi.code) {
    return [];
  }

  return standard_info.filter(info => info.standard === 'equality');
};

const DataCollection = ({
  intl,
  stage = 'data_collection',
  plan,
  updatePlanProgress,
}) => {
  const t = intl.messages;

  // Store and actions: Get organization and kpi_detail state
  const dispatch = useDispatch();

  const {
    organization,
    suborganization,
    permissions,
  } = useOrganizations();

  const {
    values: [
      [filteredSdgs, sdgFilterSet],
      [filteredTypes, typeFilterSet],
      [filteredTags, tagFilterSet],
      [filteredStatuses, statusFilterSet],
      [filteredValidationStatuses, validationStatusFilterSet],
      [dateFilter],
      [assigneeFilter],
      [textFilter, textFilterSet]
    ],
    handleFilter,
    filterState,
    resetAllFilters,
    isFilterSet
  } = useScreenFilters({
    screen: SCREENS.data_collection
  });

  const [selectedCategory, setSelectedCategory ] = useState(null);
  const [approveRejectParams, setApproveRejectParams] = useState(null);
  const [resultCount, setResultCount] = useState(RESULTS_PER_PAGE);

  const handleApprove = useCallback(({
    level,
    slug,
    period,
  }) => {
    setApproveRejectParams({
      mode: 'approve',
      level,
      kpis: [{
        slug,
        period,
      }]
    });
  }, []);

  const handleReject = useCallback(({
    level,
    slug,
    period,
  }) => {
    setApproveRejectParams({
      mode: 'reject',
      level,
      kpis: [{
        slug,
        period,
      }]
    });
  }, []);

  const handleStartValidation = useCallback((
    slug,
    period,
  ) => {
    dispatch(
      approveKpis(
        organization.slug,
        suborganization.slug,
        [{ slug, period, }],
        '',
        0,
      )
    )
  }, [
    dispatch,
    organization,
    suborganization,
  ]);

  const handleSelectCategory = useCallback((category) => {
    setSelectedCategory(category);
    setResultCount(RESULTS_PER_PAGE);
  }, []);

  const validationApprovalLevels = useMemo(() => {
    return (organization.config || {}).approval_levels || DEFAULT_APPROVAL_LEVELS;
  }, [
    organization,
  ]);

  // Request new data from API
  useEffect(() => {
    // Get slug and period from URL
    if(
      !organization ||
      !suborganization
    ) return

    // Dispach action to request categories
    dispatch(
      requestDataCategories({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        reporting_standard: 'equality',
        includeAllPeriods: true,
        dateFilterValues: dateFilter || [],
      })
    )
  }, [
    organization,
    suborganization,
    dispatch,
    dateFilter,
  ])

  // Always collapse the sidebar in this view
  useEffect(() => {
    dispatch(toggleSidebar(true));
  }, [
    dispatch,
  ]);

  const {
    data: rawCategories,
    loading,
    //error,
  } = useSelector(state => state.data_categories);

  // <FilterLogic>

  const applyTaxonomyFilters = useCallback((kpis = []) => {
    const patchedKpis = (kpis || []).map(kpi => ({
      ...kpi,
      periods: (kpi.periods || []).map(period => {
        // TODO: Move this to reducers when fetting the info?
        const {
          status,
          progress,
          validationStatus,
        } = getKpiStatus({
          schema: kpi.schema,
          kpi_value: period.kpi_value,
          applies: kpi.applies,
          source: period.source,
          ready_to_validate_level: period.ready_to_validate_level,
          validation_level: period.validation_level,
          rejection_count: period.rejection_count,
          suborganization_values_uptodate: period.suborganization_values_uptodate,
          dependee_kpi_values_uptodate: period.dependee_kpi_values_uptodate,
        }, validationApprovalLevels);
        return {
          ...period,
          status,
          progress,
          validationStatus,
        };
      })
    }));

    if(!isFilterSet && !statusFilterSet) {
      // Bail-out early
      return patchedKpis;
    }

    const filteredKpis = patchedKpis.filter(kpi => {
      const sdgMatch = (
        !sdgFilterSet ||
        (kpi.sdgs || []).find(param => filteredSdgs.includes(param))
      );
      const typeMatch = (
        !typeFilterSet ||
        filteredTypes.includes(kpi.type)
      );
      const tagMatch = (
        !tagFilterSet ||
        (kpi.tags || []).find(param => filteredTags.includes(param))
      );
      const statusOfPeriods = (kpi.periods || []).map(({ status }) => status);
      const statusMatch = (
        !statusFilterSet ||
        statusOfPeriods.find(param => filteredStatuses.includes(param))
        || (
          filteredStatuses.includes('no_data')
          && statusOfPeriods.every(status => status === 'pending')
        )
        || (
          filteredStatuses.includes('all_data')
          && statusOfPeriods.every(status => status === 'uptodate')
        )
      );

      const assigneeMatch = !assigneeFilter
        || assigneeFilter === 'all'
        || kpi.assigned_to_current_user;

      const validationOfPeriods = (kpi.periods || []).map(({ validationStatus }) => validationStatus).filter(Boolean);
      
      const validationMatch = !validationStatusFilterSet || validationOfPeriods.some(vs => filteredValidationStatuses.includes(vs));

      return statusMatch &&
        kpi.periods.length > 0 && (
        !isFilterSet || (
          sdgMatch &&
          typeMatch &&
          tagMatch &&
          assigneeMatch &&
          validationMatch
        )
      );
    });

    const patchedKpisPeriods = (filteredKpis || []).map(kpi => ({
      ...kpi,
      periods: (kpi.periods || [])
        .filter(period => {
          return (
            ( !statusFilterSet 
              || filteredStatuses.includes(period.status) 
              || (period.status  === 'pending' && filteredStatuses.includes('no_data')) 
              || (period.status  === 'uptodate' && filteredStatuses.includes('all_data'))
            )
          );
        })
    }));

    return patchedKpisPeriods;
    
  }, [isFilterSet, statusFilterSet, validationApprovalLevels, sdgFilterSet, typeFilterSet, filteredTypes, tagFilterSet, filteredStatuses, assigneeFilter, validationStatusFilterSet, filteredSdgs, filteredTags, filteredValidationStatuses]);

  const categories = useMemo(() => {
    if(!rawCategories) {
      return;
    }

    // NOTICE: whis is a map, but has local side-effects (OMG!)
    const categories = mapOverTreeFromLeafs(rawCategories)((category, children = []) => {
      const kpis = applyTaxonomyFilters(category.kpis || []);

      const doesApply = children.some(({ applies }) => applies) || kpis.some(({ applies }) => applies);
      const isEmpty = children.every(({ empty }) => empty) && kpis.length === 0;

      return {
        ...category,
        kpis,
        applies: doesApply,
        empty: isEmpty,
        children: children.sort(byCode(intl.locale)),
      };
    });

    return categories.sort(byCode(intl.locale));
  }, [
    intl,
    applyTaxonomyFilters,
    rawCategories,
  ]);

  const allKpis = useMemo(() => {
     const kpis = Object.values(
      flattenTree({
        slug: ROOT_SLUG,
        [idField]: ROOT_SLUG,
        [childrenField]: rawCategories,
      })
    ).filter(({ slug }) => slug !== ROOT_SLUG)
      .map(({ kpis }) => kpis || [])
      .reduce((arr, el) => arr.concat(el), []);

    return applyTaxonomyFilters(kpis);
  }, [
    rawCategories,
    applyTaxonomyFilters,
  ]);

  const kpis = useMemo(() => {
    if((!selectedCategory  && !textFilterSet) || !rawCategories) {
      return categories?.[0].kpis?.length
        ? categories[0].kpis
        : categories?.[0]?.children?.[0]?.kpis || [];
    }

    if(textFilterSet) {
      // Search mode
      // NOTICE: 'allKpis' already includes a taxonomy filter
      const searchStrings = makeSearchStrings(textFilter);

      return allKpis.filter((kpi) => {
        const searchableName = makeSearchString(kpi.name);
        const searchableCode = makeSearchString(kpi.code || '');
        return (searchStrings || []).every(
          (searchString) => (
            searchableName.includes(searchString) || searchableCode.includes(searchString)
          )
        );
      });
    } else {
      // Selected category mode
      // NOTICE: Since we use 'rawCategories' kpis are not patched...
      const category = findTree(rawCategories, ({ slug }) => slug === selectedCategory);

      if(!category) {
        return null;
      }

      const kpis = Object.values(flattenTree(category))
        .map(({ kpis }) => kpis || [])
        .reduce((arr, el) => arr.concat(el), []);

      return applyTaxonomyFilters(kpis);
    }
  }, [selectedCategory, textFilterSet, rawCategories, categories, textFilter, allKpis, applyTaxonomyFilters]);

  const getFilledPercentage = useCallback((type) => {
    const kpiValues = kpis
      .filter(kpi => kpi.type === type)
      .reduce((accu, current) => accu.concat(current.periods), [])
      .map(period => period.kpi_value);

    const filledValues = kpiValues.filter(value => value);
    if (!kpiValues.length) {
      return;
    }
    return (
      filledValues.length * 100 / kpiValues.length
    ).toFixed(2);
  }, [
    kpis,
  ]);

  const [
    filledEnvironmentalPercent,
    filledSocialPercent,
    filledGovernancePercent,
    filledEconomicPercent,
  ] = useMemo(() => ([
    getFilledPercentage('environmental'),
    getFilledPercentage('social'),
    getFilledPercentage('governance'),
    getFilledPercentage('economic'),
  ]), [
    getFilledPercentage,
  ]);

  const progressFormat = useCallback(
    percent => typeof(percent) === 'number' ? 'N/A' : `${percent}%`,
    []
  );

  return (
    <section>
      <section className="DataManagement__all">
        <div className="DataManagement__all_info">
          <Filters
            filteredCategories={categories}
            filteredKpis={kpis}
            filterState={filterState}
            setFilterState={handleFilter}
            loading={loading}
            permissions={permissions}
            showStandard={false}
            showEsgType={false}
            showStandardFilter={false}
            resetAllFilters={resetAllFilters}
            reportActions={
              <CustomButton
                type="primary"
                onClick={updatePlanProgress}
              >
                { t[`equality_${stage}_done`] }
              </CustomButton>
            }
            isFilterSet={isFilterSet}
          />
          { !loading &&
            <Row
              type="flex"
              justify="start"
              style={{
                marginTop: 20,
                marginBottom: 40,
              }}
            >
              <Col>
                <CustomProgress
                  title={t.esg_type_environmental}
                  strokeColor={COLORS.environmental}
                  type="circle"
                  percent={filledEnvironmentalPercent}
                  format={progressFormat}
                />
              </Col>
              <Col>
                <CustomProgress
                  title={t.esg_type_social}
                  strokeColor={COLORS.social}
                  type="circle"
                  percent={filledSocialPercent}
                  format={progressFormat}
                />
              </Col>
              <Col>
                <CustomProgress
                  title={t.esg_type_governance}
                  strokeColor={COLORS.governance}
                  type="circle"
                  percent={filledGovernancePercent}
                  format={progressFormat}
                />
              </Col>
              <Col>
                <CustomProgress
                  title={t.esg_type_economic}
                  strokeColor={COLORS.economic}
                  type="circle"
                  percent={filledEconomicPercent}
                  format={progressFormat}
                />
              </Col>
            </Row>
          }
          <div className="DataManagement__all_container">
            <ListSidebar
              className="DataManagement__all_sidebar"
              categories={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={handleSelectCategory}
              standard="equality"
              showCode
            />
            <div className="DataManagement__all_list">
              {
                (kpis || []).filter((_, i) => i < resultCount).map(
                  kpi => (
                    <KpiSummaryCard
                      key={kpi.slug}
                      urlBase={`equality-plan/${plan.id}/data-collection`}
                      permissions={permissions}
                      onApprove={handleApprove}
                      onReject={handleReject}
                      onStartValidation={handleStartValidation}
                      {...kpi}
                      standard_info={getStandardInfo(kpi)}
                      reporting_standard='equality'
                    />
                  )
                )
              }
              {
                (kpis || []).length <= resultCount ? null :
                <CustomButton
                  type="primary"
                  block
                  onClick={() => setResultCount(resultCount + RESULTS_PER_PAGE)}
                >
                  { t.datamanagement_all_loadmore }
                </CustomButton>
              }
            </div>
          </div>
        </div>
        <ModalApproveRejectKpi
          visible={!!approveRejectParams}
          onClose={() => setApproveRejectParams(null)}
          {...approveRejectParams}
        />
      </section>
    </section>
  )
};

export default injectIntl(DataCollection);
