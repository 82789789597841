import React, { useCallback, useMemo } from 'react';
import { injectIntl} from 'react-intl';

import { validation } from './validation';
import useForm from 'utils/useForm';
import { DATE_ONLY, dateToDb, formatDateMoment } from 'utils/date';

import {
  Input,
  Form,
  Modal,
  Row,
  Col,
  DatePicker
} from 'antd';

import './style.less';

const StartEqualityPlanForm = ({
  intl,
  visible,
  setVisibility,
  onStartNewPlan,
}) => {
  const t = intl.messages;
  const validateForm = useMemo(() => validation(t), [ t ]);

  const startNewPlan = () => {
    const {
      name, start_date, end_date
    } = values;
    onStartNewPlan({
      name,
      start_date: start_date ? dateToDb(start_date) : null,
      end_date: end_date ? dateToDb(end_date) : null,
    });
    setVisibility(false);
  };

  const defaults = useMemo(() => ({}), []);
  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: startNewPlan,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  const handleChangeDate = useCallback((name, value) => {
    handleChange(name)(value && dateToDb(value));
  }, [ handleChange ]);

  const handleOnCancel = useCallback(
    () => {
      resetForm();
      setVisibility(false);
    },
    [resetForm, setVisibility]
  );

  const getDisabled = useCallback(
    (type, current) => {
      if (type === 'start_date') {
        const endDate = getValue('end_date');
        return endDate ? current > formatDateMoment(endDate).endOf('day') : false;
      } else {
        const startDate = getValue('start_date');
        return startDate ? current < formatDateMoment(startDate).startOf('day') : false;
      }
    },
    [getValue]
  );

  return (
    <Modal
      title={t.new_plan_confirm_title}
      visible={visible}
      onOk={handleSubmit}
      onCancel={handleOnCancel}
      okText={t.create_new_plan}
      cancelText={t.create_new_plan_cancel}
      className="StartEqualityPlanForm"
    >
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('name') ? 'error' : '' }
              help={ showError('name') }
              label={ t.name }
              required
            >
              <Input
                placeholder={ t.name }
                name="name"
                value={ getValue('name') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('start_date') ? 'error' : '' }
              label={t.start_date}
              help={ showError('start_date') }
              required={getValue('end_date') ? true : false}
            >
              <DatePicker
                className="StartEqualityPlanForm-datepicker"
                format={DATE_ONLY}
                name="start_date"
                placeholder={t.start_date}
                value={formatDateMoment(getValue('start_date'))}
                onChange={(value) => handleChangeDate('start_date', value)}
                disabledDate={(current) => getDisabled('start_date', current)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('end_date') ? 'error' : '' }
              label={t.end_date}
              help={ showError('end_date') }
              required={getValue('start_date') ? true : false}
            >
              <DatePicker
                className="StartEqualityPlanForm-datepicker"
                format={DATE_ONLY}
                name="end_date"
                placeholder={t.end_date}
                value={formatDateMoment(getValue('end_date'))}
                onChange={(value) => handleChangeDate('end_date', value)}
                disabledDate={(current) => getDisabled('end_date', current)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default injectIntl(StartEqualityPlanForm);
