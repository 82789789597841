import React, {
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import Applies from './Applies';
import NotApplies from './NotApplies';

import { useSelector } from 'react-redux';

import useOrganizations from 'utils/useOrganizations';

const Data = ({
  kpi_slug,
  period,
  data = {},
  changePeriod,
  loading = false,
  error = null,
  onOpenAnswer,
  onUseValue,
  onUseComment,
  onUseAttachments,
  onUsePeriod,
  onUseAttachment,
  onRemind,
  permissions = {},
  org_mandatory_data = {},
  onShowDataRequests,
  handleUseDataRequests,
  handleUseAggregatedDataRequests,
}) => {

  const {
    suborganization,
  } = useOrganizations();
  
  const handleOpenAnswer = useCallback((props = {}) => {
    onOpenAnswer({
      can_use: !data.ready_to_validate_level,
      kpi_name: data.name,
      schema: data.schema,
      schemaLabels: data.schemaLabels,
      org_name: suborganization.name,
      period,
      ...props, // This goes last so we can re-write stuff like period
    });
  }, [
    onOpenAnswer,
    period,
    data,
    suborganization,
  ]);

  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);

  useMemo(() => {
    // NOTICE: This is here to help the CS department create formulas. Please remove it when
    //         formulas can be created from within the platform easily
    const schema = data.schema;
    if(schema && schema.type === 'table') {
      const isHeterogeneous = schema.innerSchema?.type === 'tuple';
      // Heterogeneous table
      console.log(`THIS TABLE HAS ${(schema.dimensions || []).length + ( isHeterogeneous ? 1 : 0 )} DIMENSIONS`);
      (schema.dimensions || []).forEach((dimension, index) => {
        console.log(`- #${index}: "${dimension.by}" with the following options:`);
        switch(dimension.source) {
          case 'singleton':
            console.log(`  - #ONLY "${dimension.by}" is the only option for this dimension`)
            break;
          case 'standard':
            (dimension.standardItems || []).forEach((item, subindex) => {
              console.log(`  - #${index}.${subindex}: "${item.slug}"`)
            });
            console.log(`  - #ALL: "*" to select every value in this dimension`)
            break;
          case 'organization':
            ((tableDimensions || {})[dimension.by] || []).forEach((option, subindex) => {
              console.log(`  - #${index}.${subindex}: "${option}"`)
            });
            console.log(`  - #ALL: "*" to select every value in this dimension`)
            break;
          case 'user':
          default:
            console.log(`  - #ALL: "*" to select every value in this dimension`)
        }
      });

      if(isHeterogeneous) {
        console.log(`- COLUMNS: choose the column:`);
        (schema?.innerSchema?.components || []).forEach((component, subindex) => {
          console.log(`  - #COLUMN.${subindex}: "${component.name}"`)
        });
      }
    }
  }, [
    data,
    tableDimensions,
  ]);

  return (
    <React.Fragment>
      {
        data.applies ? 
        <Applies
          kpi_slug={kpi_slug}
          period={period}
          data = {data}
          changePeriod={changePeriod}
          loading = {loading}
          error = {error}
          handleOpenAnswer={handleOpenAnswer}
          onUseValue={onUseValue}
          onUseComment={onUseComment}
          onUseAttachments={onUseAttachments}
          onUsePeriod={onUsePeriod}
          onUseAttachment={onUseAttachment}
          onRemind={onRemind}
          permissions = {permissions}
          org_mandatory_data = {org_mandatory_data}
          onShowDataRequests={onShowDataRequests}
          handleUseDataRequests={handleUseDataRequests}
          handleUseAggregatedDataRequests={handleUseAggregatedDataRequests}
        /> :
        <NotApplies
          kpi_slug={kpi_slug}
          data = {data}
          loading = {loading}
          permissions = {permissions}
          period={period}
          handleOpenAnswer={handleOpenAnswer}
          onUseValue={onUseValue}
          onUseAttachment={onUseAttachment}
        />
      }
    </React.Fragment>
  )
}

export default injectIntl(withRouter(Data));
