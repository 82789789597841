import {
  useCallback,
  useMemo,
} from 'react';

import { useAvailableUnits } from 'utils/useAvailableUnits';

import { convertToUnit } from 'utils/units';

const DIVIDER = `\n\n---------------------------------------\n\n`;
const CARRIAGE_RETURN = `\r`;
const INDENTATION = `&emsp;`;

const NO_OP = () => {};

const convertToBold = (value) => `**${value}**`;

const AGGREGATION_TYPE = {
  one: 'one',
  concat: 'concat',
  sum: 'sum',
  average: 'average',
  one_all_units: 'one_all_units',
  sum_all_units: 'sum_all_units',
  average_all_units: 'average_all_units'
};

const SCHEMA_TYPE = {
  qualitative: 'qualitative',
  quantitative: 'quantitative',
  table: 'table',
  boolean: 'boolean',
  tuple: 'tuple',
};

// NOTICE: This is used to consolidate values in homogeneous numeric tables.
// The compose function is called for each prop of the object and assigned as a result
// It has the "keys" parameter to make sure we include the keys from children orgs
// and not rely in the parent's schema
const consolidateValues = (
  values = [],
  keys = [],
  compose = NO_OP,
  defaultValue = {},
) => {
  //console.log('CALLED consolidateValues', keys, values, compose);
  let result = {};
  keys.forEach(key => {
    result[key] = compose(...(
      values.map(
        value => !value || typeof value[key] === 'undefined' || value[key] === null
          ? defaultValue
          : value[key]
      )
    ));
  });
  return result;
}

// TODO: Find a better way to identify leaf values
const isLeafValue = (value) => {
  return !value || Object.keys(value).filter(key => !key.startsWith('_') && !['value', 'unit'].includes(key)).length === 0
};

// This composes a set of values using 'func' is these are leaf nodes
// Otherwise it calls consolidateValues to browse the tree.
const composeValuesFunc = (
  func,
  defaultValue = { value: 0 },
) => (...values) => {
  //console.log('CALLED composeValuesFunc', values.every(isLeafValue), values, func);
  if( values.every(isLeafValue) ) {
    const _address = (values.find(value => value._address) || {})._address;
    return {
      value: func(
        values.map(
          value => !value || typeof value.value === 'undefined' || value.value === null
            ? defaultValue
            : value
        )
      ),
      _address,
    }
  }

  return consolidateValues(
    values,
    Array.from(
      new Set(
        values
          .map(value => Object.keys(value))
          .reduce((a,b) => a.concat(b), [])
      )
    ),
    composeValuesFunc(func)
  );
};

const useAggregateValues = (handler, schema, values) => {

  const concatComment = useMemo(() => {
    const finalComment = values.filter(({comment}) => comment !== '').map(({ name, comment }) => {
      let subOrgValue = `${convertToBold(`${name}:`)}${CARRIAGE_RETURN}${comment?.replaceAll(DIVIDER, CARRIAGE_RETURN)}`;
      if (comment?.includes(DIVIDER) || comment?.split(CARRIAGE_RETURN).length > 1)
        subOrgValue = subOrgValue.split(CARRIAGE_RETURN).join(`${CARRIAGE_RETURN} ${INDENTATION}`);
      return subOrgValue
    });
    return finalComment.length ? finalComment.join(DIVIDER) : '';
  }, [values]);

  const useOne = useCallback(() => {
    handler(
      {value: values?.[0]?.kpi_value, comment: concatComment},
      AGGREGATION_TYPE.one
    );
  }, [concatComment, handler, values]);

  const useConcat = useCallback(() => {
    const finalValues = values.map(({ name, kpi_value }) => {
      let subOrgValue = `${convertToBold(`${name}:`)}${CARRIAGE_RETURN}${kpi_value.text?.replaceAll(DIVIDER, CARRIAGE_RETURN)}`;
      if (kpi_value.text?.includes(DIVIDER) || kpi_value.text?.split(CARRIAGE_RETURN).length > 1)
        subOrgValue = subOrgValue.split(CARRIAGE_RETURN).join(`${CARRIAGE_RETURN} ${INDENTATION}`);
      return subOrgValue
    });
    const value = {
      text: finalValues.join(DIVIDER),
    };
    handler({value, comment: concatComment}, AGGREGATION_TYPE.concat);
  }, [concatComment, handler, values]);

  const useSum = useCallback(() => {
    const kpi_value = {
      value: values.map(({ kpi_value }) => Number(kpi_value?.value || 0)).reduce((a, b) => a+b, 0),
      unit: values[0].kpi_value.unit,
    };
    handler({value: kpi_value, comment: concatComment}, AGGREGATION_TYPE.sum);
  }, [concatComment, handler, values]);

  const useAverage = useCallback(() => {
    const sum = values.map(({ kpi_value }) => Number(kpi_value?.value || 0)).reduce((a, b) => a+b, 0);
    const divisor = values.length;

    const kpi_value = {
      value: divisor === 0 ? 0 : (sum / divisor),
      unit: values[0].kpi_value.unit,
    };
    handler({value: kpi_value, comment: concatComment}, AGGREGATION_TYPE.average);
  }, [concatComment, handler, values]);

  const useTableSum = useCallback(() => {
    // NOTICE: We COULD call compareValuesFunc directly here, but the top level is always going to be a tree
    const realValues = values.map(({ kpi_value }) => kpi_value);
    const kpi_value = consolidateValues(
      realValues,
      Array.from(
        new Set(
          realValues
            .map(value => Object.keys(value || {}))
            .reduce((a,b) => a.concat(b), [])
        )
      ),
      composeValuesFunc(
        values => {
          return values.map(({ value }) => Number(value || 0)).reduce((a, b) => a+b, 0)
        }
      ),
    );
    handler({value: kpi_value, comment: concatComment}, AGGREGATION_TYPE.sum);
  }, [concatComment, handler, values]);

  const useTableAverage = useCallback(() => {
    // NOTICE: We COULD call compareValuesFunc directly here, but the top level is always going to be a tree
    const realValues = values.map(({ kpi_value }) => kpi_value);
    const kpi_value = consolidateValues(
      realValues,
      Array.from(
        new Set(
          realValues
            .map(value => Object.keys(value || {}))
            .reduce((a,b) => a.concat(b), [])
        )
      ),
      composeValuesFunc(
        values => {
          const sum = values.map(({ value }) => Number(value || 0)).reduce((a, b) => a+b, 0);
          const divisor = values.length;
          return divisor === 0 ? 0 : (sum / divisor);
        }
      ),
    );
    handler({value: kpi_value, comment: concatComment}, AGGREGATION_TYPE.average);
  }, [concatComment, handler, values]);

  const availableUnits = useAvailableUnits(schema);
  const baseUnit = useMemo(() => {
    if(!availableUnits || !Array.isArray(availableUnits)) {
      return undefined;
    }
    // NOTICE: This is a workaround for KPIs that cannot be specified in base units
    //         The conversion WILL BE WRONG, but if all of them are in the same unit we will survive
    //         The only possible error is if we allow many units, none of them is the base and we try
    //         To aggregate several differnt types of units
    // TODO: Fix this: for conversion all the units for the metric should be available, not only the
    //       available ones
    return availableUnits.length === 1
      ? availableUnits[0]
      : (availableUnits.find(unit => unit.is_base) || availableUnits[0])
  }, [
    availableUnits,
  ]);

  const getUseOneAllUnits = useCallback(() => {
    return (availableUnits || []).map(unit => {
      const convertToBase = convertToUnit(baseUnit?.slug, availableUnits);
      const convertToTargetUnit = convertToUnit(unit.slug, availableUnits);
      const valuesInBaseUnit = values.map(({ kpi_value }) => convertToBase(kpi_value));
  
      return {
        key: unit.slug,
        label: `${unit.name} (${unit.symbol})`,
        onClick: () => {
          const kpi_value = convertToTargetUnit({
            value: valuesInBaseUnit[0]?.value,
            unit: baseUnit?.slug,
          });
          handler({value: kpi_value, comment: concatComment}, AGGREGATION_TYPE.one_all_units);
        },
      }
    })
  }, [availableUnits, baseUnit, concatComment, handler, values]);

  const getUseSumAllUnits = useCallback(() => {
    return (availableUnits || []).map(unit => {
      const convertToBase = convertToUnit(baseUnit.slug, availableUnits);
      const convertToTargetUnit = convertToUnit(unit.slug, availableUnits);
      const valuesInBaseUnit = values.map(({ kpi_value }) => convertToBase(kpi_value));
  
      return {
        key: unit.slug,
        label: `${unit.name} (${unit.symbol})`,
        onClick: () => {
          const kpi_value = convertToTargetUnit({
            value: valuesInBaseUnit.map(({ value }) => Number(value)).reduce((a, b) => a+b, 0),
            unit: baseUnit?.slug,
          });
          handler({value: kpi_value, comment: concatComment}, AGGREGATION_TYPE.sum_all_units);
        },
      }
    })
  }, [availableUnits, baseUnit, concatComment, handler, values]);

  const getUseAverageAllUnits = useCallback(() => {
    return (availableUnits || []).map(unit => {
      const convertToBase = convertToUnit(baseUnit?.slug, availableUnits);
      const convertToTargetUnit = convertToUnit(unit.slug, availableUnits);
      const valuesInBaseUnit = values.map(({ kpi_value }) => convertToBase(kpi_value));

      return {
        key: unit.slug,
        label: `${unit.name} (${unit.symbol})`,
        onClick: () => {
          const sum = valuesInBaseUnit.map(({ value }) => Number(value)).reduce((a, b) => a+b, 0);
          const divisor = values.length;

          const kpi_value = convertToTargetUnit({
            value: divisor === 0 ? 0 : (sum / divisor),
            unit: baseUnit?.slug,
          });
          handler({value: kpi_value, comment: concatComment}, AGGREGATION_TYPE.average_all_units);
        },
      }
    })
  }, [availableUnits, baseUnit, concatComment, handler, values]);

  if(values.length === 0) {
    return {};
  }

  switch(schema.type) {
    case SCHEMA_TYPE.qualitative:
      if(values.length > 1) {
        return {
          concat: useConcat,
        };
      }
      return {
        one: useOne,
      }
    case SCHEMA_TYPE.quantitative:
      if(values.length > 1) {

        if(availableUnits.length <= 1 || !baseUnit) {
          return {
            sum: useSum,
            average: useAverage,
          };
        }

        return {
          sum: getUseSumAllUnits(),
          average: getUseAverageAllUnits(),
        };
      }
      return {
        one: getUseOneAllUnits(),
      }
    case SCHEMA_TYPE.table:
      if(
        ![SCHEMA_TYPE.quantitative, SCHEMA_TYPE.tuple].includes(schema?.innerSchema.type)
      ) {
        return {};
      }
      if(schema.innerSchema?.type === SCHEMA_TYPE.tuple) {
        const hasOnlyQuantitativeFields = schema.innerSchema.components?.map(
          component => component.type
        ).every(type => type === SCHEMA_TYPE.quantitative);

        if (!hasOnlyQuantitativeFields) {
          return {};
        }
      }
      if(values.length > 1) {
        return {
          sum: useTableSum,
          average: useTableAverage,
        };
      }
      return {
        one: useOne,
      }
    case SCHEMA_TYPE.boolean:
      return {
        one: useOne,
      }
    default:
      return {};
  }
};

export default useAggregateValues;

