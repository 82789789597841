import { useCallback, useRef } from 'react';

const DEFAULT_COLORS = [
  '#117065',
  '#F0C419',
  '#E57E25',
  '#D65050',
  '#2E8979',
  '#FFC600',
  '#ACEF6A',
  '#43AB5F',
  '#42B7A6',
];

const DASH_AND_DULL_COLORS = [
  '#117065',
  '#A0A0A0',
  '#909090',
  '#808080',
  '#989898',
  '#888888',
  '#A8A8A8',
  '#787878',
  '#B0B0B0',
];

const DEFAULT_COLOR_SUFFIX = '_color';

const nameToColor = (colorSet = [], name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  return colorSet[hash % colorSet.length];
};


const useColors = (colorSet = DEFAULT_COLORS, props, cache = true) => {
  const {
    colorSuffix,
    colorProp,
  } = props;

  const order = useRef(new Map());

  return useCallback((e) => {
    const key = e.id;
    const data = e.data;
    const index = e.index;
    const dataColor = data && (
      colorProp
      ? data[colorProp]
      : data[`${key}${colorSuffix || DEFAULT_COLOR_SUFFIX}`]
    );

    let orderColor;
    if(cache && order.current) {
      // Use that cache!
      if(order.current.has(key)) {
        orderColor = colorSet[order.current.get(key)];
      } else {
        const index = order.current.size % colorSet.length;
        order.current.set(key, index);
        orderColor = colorSet[index];
      }
    } else {
      // No cache, just order
      orderColor = colorSet[index % colorSet.length];
    }

    return dataColor || orderColor || nameToColor(colorSet, key);
  }, [
    colorSet,
    colorSuffix,
    cache,
    colorProp,
  ]);
};

export default useColors;
export const defaultColors = DEFAULT_COLORS;
export const dullColors = DASH_AND_DULL_COLORS;

