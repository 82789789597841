import React, {
} from 'react'
import './style.less'

const CustomTagSimple = (props) => {
  const {
    name,
    colorclass,
    className = '',
  } = props


  return (
    <span
      {...props}
      className={`
        CustomTagSimple
        ${className || ''}
        CustomTagSimple__color_${colorclass}
      `}
    >
      { name }
    </span>
  );
}

export default CustomTagSimple
