import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { injectIntl } from 'react-intl'
import {
  useDispatch,
} from 'react-redux';

import {
  createKpiCategory,
  updateKpiCategory,
  deleteKpiCategory,
} from 'actions/api'

// TODO: use this
import useForm from 'utils/useForm';

import useOrganizations from 'utils/useOrganizations';

import CustomModal from 'components/CustomModal';
import CustomButton from 'components/CustomButton'

import T from 'components/T'
import CustomTag from 'components/CustomTag'
import SdgMiniLogo from 'components/SdgMiniLogo'
import EsgLogo from 'components/EsgLogo'

import CustomLanguageInput from 'components/CustomLanguageInput';

import {
  Form,
  Row,
  Col,
  Modal,
  Tooltip,
} from 'antd';
import {
  DeleteOutlined,
} from '@ant-design/icons';

import './style.less';
import {useSDGsClustering} from "../../hooks/sdg";

const validation = (t) => ({
  locale,
  name_translations,
}) => {
  let errors = {};
  if(!((name_translations || []).find(nt => nt.locale === locale)?.name !== '')) {
    errors.name_translations = t.form_error_required;
  }

  return errors;
};

const CreateOrEditCategory = ({
  intl,
  shown,
  parentCategorySlug,
  category,
  onClose,
  permissions = {},
  loading,
  error,
  setLoadingCategory,
  loadingCategory = false
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const {
    organization,
    suborganization,
  } = useOrganizations();
  const clusterSDGs = useSDGsClustering();
  const clusteredSDGs = useMemo(() => clusterSDGs(category?.sdgs), [category, clusterSDGs])

  const [ submitting, setSubmitting ] = useState(false);

  const editting = !!category;
  const isCustom = !editting || category.is_custom;

  const submitForm = () => {
    const rest = editting ? {category_slug: category.slug} : {parent_slug: parentCategorySlug};
    dispatch(
      (
        editting
        ? updateKpiCategory
        : createKpiCategory
      )({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        name_translations: values.name_translations.filter(({name}) => name !== ''),
        ...rest
      })
    );
    setSubmitting(true);
  };

  const validateForm = useMemo(() => validation(t), [ t ]);

  const defaultValues = useMemo(() => {
    return editting ? {
      ...category,
      name_translations: category?.name_translations.filter(({locale}) => (organization?.config?.preferred_languages || []).includes(locale))
    } : 
    {
      name_translations: (organization?.config?.preferred_languages || []).map(locale => ({locale, name: ''}))
    }
  }, [category, editting, organization]);

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues,
    validationDefaults: {locale: suborganization.language}
  });

  const closeModal = useCallback(() => {
    setSubmitting(false);
    resetForm();
    onClose();
    setLoadingCategory(false);
  }, [resetForm, onClose, setLoadingCategory]);

  useEffect(() => {
    if(loadingCategory && editting) {
      resetForm();
      setLoadingCategory(false);
    }
  }, [editting, loadingCategory, resetForm, setLoadingCategory]);

  const onDelete = useCallback(() => {
    Modal.confirm({
        title: t.create_or_edit_category_delete_category,
        content: t.create_or_edit_category_delete_confirm,
        okText: t.create_or_edit_category_delete_ok,
        cancelText: t.create_or_edit_category_delete_cancel,
        onOk() {
          dispatch(
            deleteKpiCategory(
              organization.slug,
              suborganization.slug,
              category.slug,
            )
          );
          setSubmitting(true);
        },
      })
  }, [
    t,
    dispatch,
    category,
    organization,
    setSubmitting,
    suborganization,
  ]);

  // Effect: close form when success
  useEffect(() => {
    if(submitting && !loading && !error) {
      onClose();
    }
  }, [
    onClose,
    submitting,
    loading,
    error,
  ]);

  return (
    <CustomModal
      shown={shown}
      onCancel={closeModal}
      afterClose={closeModal}
      title={
        !editting
        ? t.create_or_edit_category_title
        : isCustom && permissions.can_configure_kpi
          ? t.create_or_edit_category_title_update
          : t.create_or_edit_category_title_info
      }
      footer={
        <Row type="flex" justify="space-between">
          <Col>
            <CustomButton
              type="primary"
              className='CreateOrEditCategory-createbutton'
              loading={loading}
              onClick={editting && (!isCustom || !permissions.can_configure_kpi) ? closeModal : handleSubmit}
            >
              {
                !editting
                ? t.create_or_edit_category_create
                : isCustom && permissions.can_configure_kpi
                  ? t.create_or_edit_category_update
                  : t.create_or_edit_category_close
              }
            </CustomButton>
          </Col>
          { isCustom && editting && permissions.can_configure_kpi &&
          <Col>
            <Tooltip title={
                category?.kpi_count ? t.create_or_edit_category_delete_requisite : t.create_or_edit_category_delete_category 
              }
            >
              <span>
                <CustomButton
                  key="delete"
                  disabled={loading || !!category?.kpi_count}
                  danger={true}
                  icon={<DeleteOutlined />}
                  onClick={onDelete}
                  loading={loading}
                />
              </span>
            </Tooltip>
          </Col>
          }
        </Row>
      }
      width={600}
    >
      <Form onFinish={handleSubmit}>
        <section className='CreateOrEditCategory'>
          {
            isCustom && permissions.can_configure_kpi
            ? (
              <CustomLanguageInput
                values={values}
                errors={errors?.name_translations}
                isDirty={isDirty}
                handleChange={handleChange}
                placeholder={t.create_or_edit_category_name_placeholder}
                subtitle={t.form_error_max_title_length}
                maxLength={75}
              />
            ) : (
              <h4 className="CreateOrEditCategory-title CreateOrEditCategory-title-name">
                { (values?.name_translations || []).find(({locale}) => locale === suborganization.language)?.name ?? '' }
              </h4>
            )
          }
        </section>

        {
          !category || isCustom
            ? null // Creating new KPI, no info to show
            : (
              <section className="CreateOrEditCategory-info">
                <div>
                  <T
                    create_or_edit_category_info_number={{
                      count: category.kpi_count,
                    }}
                  />
                  {
                    !category.children || category.children.length === 0
                      ? null
                      : (
                        <React.Fragment>
                          { ' ' }
                          <T
                            create_or_edit_category_info_children={{
                              count: category.children.length,
                            }}
                          />
                        </React.Fragment>
                      )
                  }
                </div>
                {
                  !category.standards || category.standards.length === 0
                    ? null
                    : (
                      <Row
                        type="flex"
                        gutter={20}
                      >
                        <Col>
                          <h5>{ t.item_manager_standard }</h5>
                        </Col>
                        <Col
                          className="CreateOrEditCategory-bigcollection"
                        >
                          {
                            category.standards.map(standard => (
                              <div key={standard}>
                                { t[`reporting_standard_${standard}`] }
                              </div>
                            ))
                          }
                        </Col>
                      </Row>
                    )
                }
                {
                  !category.esg_types || category.esg_types.length === 0
                    ? null
                    : (
                      <Row
                        type="flex"
                        gutter={20}
                      >
                        <Col>
                          <h5>{ t.item_manager_type }</h5>
                        </Col>
                        <Col
                          className="CreateOrEditCategory-bigcollection"
                        >
                          {
                            category.esg_types.map(type => (
                              <div key={type}>
                                <EsgLogo type={type} />
                                { '  ' }
                                { t[`esg_type_${type}`] }
                              </div>
                            ))
                          }
                        </Col>
                      </Row>
                    )
                }
                {
                  !category.tags || category.tags.length === 0
                    ? null
                    : (
                      <Row
                        type="flex"
                        gutter={20}
                      >
                        <Col>
                          <h5>{ t.tags }</h5>
                        </Col>
                        <Col
                          className="CreateOrEditCategory-smallcollection"
                        >
                          {
                            category.tags.map(tag => (
                              <CustomTag key={tag} name={tag}/>
                            ))
                          }
                        </Col>
                      </Row>
                    )
                }
                {
                  !clusteredSDGs || clusteredSDGs.length === 0
                    ? null
                    : (
                      <Row
                        type="flex"
                        gutter={20}
                      >
                        <Col>
                          <h5>{ t.sdg }</h5>
                        </Col>
                        <Col
                          className="CreateOrEditCategory-smallcollection"
                        >
                          {
                            clusteredSDGs.map(sdg => (
                              <SdgMiniLogo key={sdg.slug} sdg={sdg}/>
                            ))
                          }
                        </Col>
                      </Row>
                    )
                }
              </section>
            )
        }
      </Form>
    </CustomModal>
  );
};

export default injectIntl(
  CreateOrEditCategory
);

