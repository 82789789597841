import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import Form from './Form';

import {
  Row,
  Col,
  Modal,
} from 'antd';

import './style.less';


const EqualityPlanCreation = ({
  intl,
  history,
  plan,
  updatePlan,
}) => {
  const t = intl.messages;
  const [formIsDirty, setFormIsDirty] = useState(false);

  const handleBack = useCallback(
    () => {
      if (formIsDirty) {
        Modal.confirm({
          title: t.equality_plan_exit_without_save,
          content: t.equality_plan_exit_without_save_description,
          okText: t.equality_plan_exit_without_save_ok,
          cancelText: t.equality_plan_exit_without_save_cancel,
          onOk() {
            history.push(`/equality-plan/${plan.id}/`);
          },
        })
      } else {
        history.push(`/equality-plan/${plan.id}/`);
      }
    },
    [formIsDirty, plan, history, t]
  );

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_plan_creation_details}
        isDocumentManager={false}
        handleBackOrSkip={handleBack}
      />
      <Row>
        <Col span={24}>
          <Form
            plan={plan}
            updatePlan={updatePlan}
            setFormIsDirty={setFormIsDirty} />
        </Col>
      </Row>
    </>
  )
};

export default withRouter(injectIntl(EqualityPlanCreation));
