const initialState = {
  error: null
};

const TARGET = 'error_notification';

const isTarget = (target) => target === TARGET;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API_CALL_FAILED':
      if(action.errorBody) return {error: action.errorBody}
      if(action.code === 500) return {error: {copy_description: 'analysis_error', values: true}}
      return state
    case 'RESET_STATE':
      if (!isTarget(action.target)) return state;
      return initialState
    default:
      return state;
  }
};

export { reducer as error_notification };
