import React from 'react';
import PropTypes from 'prop-types';

const DataRequestsTableHeader = ({
  headerData,
}) => {
  return (
    <thead>
      <tr className='DataRequests__header-row'>
        {headerData.map(({
          id,
          title,
          className,
          hide
        }) => (
          hide ? null : (
            <th key={id} className={className}>{title ? title : null}</th>
          )
        ))}
      </tr>
    </thead>
  );
};

DataRequestsTableHeader.propTypes = {
  headerData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    className: PropTypes.string,
    hide: PropTypes.bool,
  })).isRequired
};

export default DataRequestsTableHeader;