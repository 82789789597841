import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { changeKpiPermissions } from 'actions/api';

import CustomCheckbox from 'components/CustomCheckbox';
import {
  Col,
  Row,
} from 'antd';


const DataSourcesPermissionCheckbox = ({
  organization_slug,
  suborganization_slug,
  kpi_slug,
  member,
  permission,
}) => {
  const dispatch = useDispatch();
  const permissions = useMemo(
    () => member.permissions || member.org_permissions,
    [member]
  );
  let [checked, setChecked] = useState(permissions[permission]);

  const handleOnChangePermission = useCallback(
    () => {
      setChecked(!checked);
      let enabledPermissions = Object.keys(permissions)
        .filter(permission => !!permissions[permission]);

      if (checked) {
        enabledPermissions = enabledPermissions.filter(
          enabledPermission => enabledPermission !== permission
        );
      } else {
        enabledPermissions.push(permission)
      }
      dispatch(
        changeKpiPermissions('data_sources_kpi_permissions')(
          organization_slug,
          suborganization_slug,
          kpi_slug,
          member.member_slug,
          enabledPermissions,
          {},
        )
      );
    },
    [
      dispatch,
      checked,
      organization_slug,
      suborganization_slug,
      kpi_slug,
      member,
      permissions,
      permission,
    ]
  );

  return (
    <Row gutter={[10, 10]}>
      <Col>
        <CustomCheckbox
          checked={checked}
          onChange={handleOnChangePermission}
        />
      </Col>
    </Row>
  );
}

export default DataSourcesPermissionCheckbox;
