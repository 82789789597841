import React, {
  useState,
  useMemo,
} from 'react';
import './style.less';

import {
  Select,
} from 'antd';

const NO_OP = () => {};
const DEFAULT_POPUP = () => document.body;
const DEFAULT_HIGHLIGHT_WHEN = (selected) => selected.length > 0;

const CustomMultiSelect = ({
  disabled,
  title,
  options = [],
  selected = [],
  onSelect = NO_OP,
  renderOption,
  renderOptionPrefix,
  className,
  highlightWhen = DEFAULT_HIGHLIGHT_WHEN,
  wideMenu = false,
  inModal = false,
  disabledOptions = [],
  notFoundContent,
  getPopupContainer = DEFAULT_POPUP
}) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const isHighlighted = useMemo(() => {
    return highlightWhen(selected);
  }, [
    highlightWhen,
    selected,
  ]);

  return (
    <section className={`CustomMultiSelect ${className ? className : ''}`}>
      <section id={`CustomMultiSelect${title ? `-${title}` : ''}`}>
        <Select
          mode="multiple"
          size="large"
          disabled={disabled}
          className={`CustomMultiSelect-button ${selectOpen || isHighlighted ? 'CustomMultiSelect-open' : 'CustomMultiSelect-closed'} ${disabled ? 'CustomMultiSelect-disabled' : ''}`}
          dropdownClassName={`CustomMultiSelect-menu${inModal ? ` CustomMultiSelect-menu-modal` : ''}${wideMenu ? ' CustomMultiSelect-menu-wide' : ''}`}
          optionFilterProp="title"
          value={selected || undefined}
          onChange={onSelect}
          tagRender={() => null}
          open={selectOpen}
          onDropdownVisibleChange={setSelectOpen}
          showArrow={true}
          virtual={false}
          notFoundContent={notFoundContent}
          getPopupContainer={getPopupContainer}
        >
          {
            options.map(({ slug, name, className = '',...rest }) => (
              <Select.Option
                key={slug}
                value={slug}
                title={ name || slug }
                className="CustomMultiSelect-option"
                disabled={disabledOptions.length && disabledOptions.includes(slug)}
              >
                { renderOption
                  ? renderOption({ name, slug })
                  : <>
                    { !renderOptionPrefix ? null :
                      <section className='CustomMultiSelect-option-prefix'>
                        { renderOptionPrefix(slug) }
                      </section>
                    }
                    <span className={`CustomMultiSelect-option-name ${className}`}>
                      { name }
                    </span>
                  </>
                }
              </Select.Option>
            ))
          }
        </Select>
        <div className="fakePlaceholder" onClick={() => setSelectOpen(true)}>
          {selectOpen ? null : `${title}${isHighlighted ? ` • ${selected.length}` : ''}`}
        </div>
      </section>
    </section>
  );
}

export default CustomMultiSelect;
