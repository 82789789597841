import { loop, Cmd } from 'redux-loop';
import { history } from 'store';

const TARGET = 'survey';

const initialState = {
  data: [],
  item: null,
  error: null,
  sending_survey_email: false
};

const loadSurveyRecipients = (data) => ({
  type: 'LOAD_RECIPIENTS',
  data,
});

const updateSurvey = (state, survey) => {
  const index = state.data.map(
    survey => survey.id
  ).indexOf(parseInt(survey.id));
  return {
    ...state,
    data: [
      ...state.data.slice(0, index),
      survey,
      ...state.data.slice(index + 1)
    ],
    item: survey
  };
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      const { target } = action;
      if (target === 'send_survey') {
        return {
          ...state,
          sending_survey_email: true
        }
      }
      return state;
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if (response && response.target === TARGET) {
        const result = response.result;
        switch(response.method) {
          case 'GET':
            if (Array.isArray(result)) {
              return {
                ...state,
                error: null,
                data: result,
              };
            }
            return loop(
              {...state, item: result, error: null},
              Cmd.action(loadSurveyRecipients(result.recipients))
            );
          case 'POST':
            return loop(
              {...state, error: null},
              Cmd.run(
                history.push,
                { args: [`/analysis/${result.analysis_id}/survey/${result.id}/${result.status === 'close' ? 'results' : ''}`]
              })
            );
          case 'PUT':
            return updateSurvey({...state, error: null}, response.result);
          default:
            return {...state, error: null};
        }
      } else if (response && response.target === 'send_survey') {
        return {
          ...updateSurvey(state, response.result),
          sending_survey_email: false
        };
      } else {
        return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        error: action.code,
      };
    case 'LOAD_SURVEYS':
      return {
        ...state,
        data: action.data
      }
    default:
      return state;
  }
};

export {
  reducer as survey,
};
