import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Button,
  Card,
  Input,
  Form,
} from 'antd';

import {targetGroupValidation as validation} from './validation';
import useForm from 'utils/useForm';
import {
} from '../../actions/api';

import './style.less';

const TargetGroupForm = ({
  intl,
  type,
  person,
  index,
  onSave,
  onCancel,
}) => {
  const t = intl.messages;
  const defaults = person;
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    const { name, email } = values;
    onSave(
      type, index, { name, email }
    );
  };

  const {
    values,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  return (
    <Row>
      <Col span={24} className="form-input-wrapper">
        <Card className="form-input-card">
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('name') ? 'error' : '' }
                help={ showError('name') }
                label={ t.name }
                colon={false}
                required
              >
                <Input
                  placeholder={ t.name }
                  name="name"
                  value={ getValue('name') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Col>
            <Col>
              <Button
                  type="primary"
                  onClick={handleSubmit}
                >
                  { t.save }
              </Button>
            </Col>
            <Col>
              <Button onClick={() => onCancel(type, index)}>
                { t.cancel }
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
};

export default injectIntl(TargetGroupForm);
