import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import Title from './Title';
import A from 'components/A';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { getEmptyResultsProps, emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';
import { ButtonGroup } from 'tsComponents/button/ButtonGroup';
import AplanetIcon from 'components/AplanetIcon';
import {
  flattenTree,
  mapOverTreeFromLeafs,
  idField,
  childrenField,
} from 'utils/tree';

import {
  getRSAncestorsCategoriesOfCategory,
  getRSAncestorsCategoriesOfKpi,
} from 'utils/reporting_structure';
import useOrganizations from 'utils/useOrganizations';
import {
  canDropNode,
  getParentCategoryUuid,
  getPrevReferenceCategoryUuid,
  getPrevReferenceIndicatorUuid,
} from 'utils/drag_and_drop';
import { useEventTracking } from 'hooks/useEventTracking';

import CustomCheckbox from 'components/CustomCheckbox';

import {
  Tree,
  Switch,
  notification,
} from 'antd';

import './style.less';


const ROOT_ID = '__root'; // Needs not to be a valid slug, hence __ 
const DROP_POSITION_WHEN_NODE_DROPPED_TOP_ROOT_REPORTING_STRUCTURE = -1;

const NO_FUNC = () => { }

const KpiTree = ({
  intl,
  categories,
  selectedCategory,
  setSelectedCategory = NO_FUNC,
  checkedCategories = [],
  setCheckedCategories = NO_FUNC,
  selectedKpi,
  setSelectedKpi = NO_FUNC,
  checkedKpis = [],
  setCheckedKpis = NO_FUNC,
  showOnlyCategories = false,
  isDirectory = false,
  search = '',
  previousSelectedCategories = [],
  subtreeCategories = [],
  expandedTreeCategories = [],
  onUpdateTreeOrder,
  setShowNotApplies,
  showNotApplies = false,
  clearSelection,
  reportingStructure,
  areFiltersSet,
  textFilterSet,
  enabled_reports,
  showNotApplyToggle = true,
  setShowCategoryWizard,
  setShowIndicatorWizard,
  isCreateMode = false,
  enabledOrderTree = false,
  ...props
}) => {
  const eventTracking = useEventTracking();

  const {
    organization,
    isMainOrganization,
    permissions
  } = useOrganizations();

  const [expandedKeys, setExpandedKeys] = useState([]);

  useEffect(() => {
    if (search) setExpandedKeys([...expandedKeys, ...expandedTreeCategories])
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  )

  const treeData = useMemo(
    () => {
      return mapOverTreeFromLeafs(categories)((reportingStructureNode, children = []) => {
        if (!!reportingStructureNode.category) {
          return {
            isCategory: true,
            title: reportingStructureNode.category.name,
            name: reportingStructureNode.category.name,
            name_translations: reportingStructureNode.category.name_translations,
            is_custom: reportingStructureNode.category.is_custom,
            slug: reportingStructureNode.category.slug,
            code: reportingStructureNode.category.code,
            standard: reportingStructureNode.category.standard,
            uuid: reportingStructureNode.category.uuid,
            key: reportingStructureNode.category.uuid,
            disabled: previousSelectedCategories.includes(reportingStructureNode.category.uuid) || subtreeCategories.includes(reportingStructureNode.category.uuid),
            checkable: showOnlyCategories || !!reportingStructureNode.kpis?.length || !!children.length,
            children,
            ancestorsCategories: getRSAncestorsCategoriesOfCategory(reportingStructure, reportingStructureNode.category.uuid),
            position: reportingStructureNode.position,
            parent_uuid: reportingStructureNode.parent_uuid,
          }
        }
        if (showOnlyCategories) return;

        const kpiCodes = (reportingStructureNode.standard_info || [])
                          .filter(({ standard }) => reportingStructureNode.is_custom || enabled_reports.includes(standard))
                          .map(({ code }) => code); 

        return {
          ...reportingStructureNode,
          title: reportingStructureNode.name,
          key: reportingStructureNode.uuid,
          isLeaf: true,
          code: kpiCodes.length ? kpiCodes[0] : undefined, 
          ancestorsCategories: getRSAncestorsCategoriesOfKpi(reportingStructure, reportingStructureNode.uuid),
          position: reportingStructureNode.position,
          parent_uuid: reportingStructureNode.parent_uuid,
        };
      });
    },
    [
      categories,
      previousSelectedCategories,
      reportingStructure,
      showOnlyCategories,
      subtreeCategories,
      enabled_reports,
    ]
  );

  //temporal patch - store tree state locally until we implement new design and solve performance problems
  const [treeNodes, setTreeNodes] = useState()
  useEffect(() => {
    setTreeNodes(treeData);
  }, [treeData])

  const handleCheck = useCallback(
    (_, { checked, checkedNodes, node }) => {
      let checkedCategoriesData = [...checkedCategories];
      let checkedKpisData = [...checkedKpis];
      checkedNodes.forEach(
        node => {
          if (node.isCategory && !checkedCategoriesData.includes(node.uuid)) {
            checkedCategoriesData.push(node.uuid)
          } else {
            !checkedKpisData.includes(node.uuid) && checkedKpisData.push(node.uuid);
          }
        }
      );
      if (!checked) {
        const checkedUuidsFromCurrentNodeAndParents = [...Object.keys(flattenTree(node, 'uuid')), ...node.ancestorsCategories.map(({uuid}) => uuid)];
        checkedCategoriesData = checkedCategoriesData.filter(uuid => !checkedUuidsFromCurrentNodeAndParents.includes(uuid))
        checkedKpisData = checkedKpisData.filter(uuid => !checkedUuidsFromCurrentNodeAndParents.includes(uuid))
      }
      setCheckedCategories(checkedCategoriesData);
      setCheckedKpis(checkedKpisData);
    },
    [checkedCategories, checkedKpis, setCheckedCategories, setCheckedKpis]
  );

  const handleSelect = useCallback(
    (_, { node }) => {
      if (node.isCategory) {
        setSelectedKpi(null);
        setSelectedCategory(node);
      }
      else {
        setSelectedCategory(null);
        setSelectedKpi(node.uuid)
      }
    },
    [setSelectedCategory, setSelectedKpi]
  );

  const checkedKeys = useMemo(
    () => showOnlyCategories ? [...checkedKpis, ...checkedCategories] : [...checkedKpis],
    [checkedCategories, checkedKpis, showOnlyCategories]
  );
  const selectedKey = useMemo(
    () => [selectedCategory?.uuid || selectedKpi?.uuid].filter(Boolean),
    [selectedCategory, selectedKpi]
  );

  const flatTreeData = useMemo(
    () => Object.values(
      flattenTree({
        slug: ROOT_ID,
        [idField]: ROOT_ID,
        [childrenField]: treeNodes || [],
      }, 'uuid')
    ).filter(({ slug }) => slug !== ROOT_ID),
    [treeNodes]
  );

  const allCategories = useMemo(
    () => flatTreeData
      .filter(({ children, disabled }) => !!children & !disabled).map(({ uuid }) => uuid)
    ,
    [flatTreeData]
  );

  const allKpis = useMemo(
    () => flatTreeData
      .filter(({ children }) => !children).map(({ uuid }) => uuid)
    ,
    [flatTreeData]
  );

  const areAllNodesSelected = useMemo(
    () => showOnlyCategories ? allCategories.length === checkedKeys.length : allKpis.length === checkedKeys.length,
    [allCategories.length, allKpis.length, checkedKeys.length, showOnlyCategories]
  );

  const handleOnToggleSelectAll = useCallback(
    (e) => {
      if (e.target.checked) {
        setCheckedCategories(allCategories);
        setCheckedKpis && setCheckedKpis(allKpis);
      } else {
        clearSelection();
      }
    },
    [setCheckedCategories, allCategories, setCheckedKpis, allKpis, clearSelection]
  );

  const handleOnClickExpand = useCallback((expandedKeysValues) => {
    setExpandedKeys(expandedKeysValues);
  }, []);

  const handleDrop = useCallback(({
    dragNode: droppedNode,
    dropToGap, // NOTICE: true if droppedNode ISN'T dropped inside referenceNodeWhenDrop but in a gap. But if referenceNodeWhenDrop.expanded=true and dropToGap=false then droppedNode is being placed the 1st of the children of referenceNodeWhenDrop
    node: referenceNodeWhenDrop, // NOTICE: this is the above node of the position where droppedNode is dropped. Except when droppedNode is placed at the top of the root level, in this case referenceNodeWhenDrop would be the node below
    dropPosition,
  }) => {
    let dropResult = 0;
    const dropProps = {
      dropToGap,
      referenceNodeWhenDrop,
      isNodeDroppedAtTheTopOfAllRoots: dropPosition === DROP_POSITION_WHEN_NODE_DROPPED_TOP_ROOT_REPORTING_STRUCTURE,
    };
    
    if (canDropNode(droppedNode, dropProps, (reportingStructure || []))) {
      dropResult = 1; // success
      const prevReferenceNodeUuid = droppedNode.isCategory ? getPrevReferenceCategoryUuid(dropProps, (reportingStructure || [])) : getPrevReferenceIndicatorUuid(dropProps);
      const parentCategoryUuid = getParentCategoryUuid(dropProps);
      onUpdateTreeOrder(droppedNode, parentCategoryUuid, prevReferenceNodeUuid);
    } else {
      dropResult = 0; // failed
      notification.error({
        message: intl.formatMessage({ id: 'reporting_structure_movement_error_message' }),
        description: intl.formatMessage({ id: 'reporting_structure_movement_not_allowed_description' })
      });
    }
    eventTracking.capture('configure.orderRelocation', { result: dropResult });
  } , [
    intl,
    eventTracking,
    onUpdateTreeOrder,
    reportingStructure,
  ]);

  const hasContent = Boolean(categories.length)
  const emptySearchAndFilter = (areFiltersSet || textFilterSet);
  const hasNewButton =
  (setShowCategoryWizard &&
    setShowIndicatorWizard &&
    (isMainOrganization || permissions.can_configure_kpi)) ||
  (!organization.config.features.restricted_admins &&
    permissions.can_affect_all_kpis);

  const emptyState = emptySearchAndFilter
    ? <Empty {...getEmptyResultsProps(textFilterSet, areFiltersSet)} />
    : <Empty {...emptyPropsMap.get("noTreeCreated")}>
        { hasNewButton && <ButtonGroup>
          <button           
            className="button--main"
            onClick={() => setShowCategoryWizard(true)} 
          >
            <AplanetIcon name='Add' />
            <FormattedMessage id="new" />
          </button>
        </ButtonGroup>}
      </Empty>

  return (
    <div className="KpiTree" data-testid="KpiTree">
      <div className="KpiTree__header">
        {enabledOrderTree ? null : (
          <>
            <div className="KpiTree__header__select-all">
              <CustomCheckbox
                checked={areAllNodesSelected}
                indeterminate={checkedKeys.length > 0 && !areAllNodesSelected}
                onChange={handleOnToggleSelectAll}
              />
              {areAllNodesSelected && isCreateMode
                ? intl.formatMessage({ id: 'datamanagement_config_all_selected' })
                : intl.formatMessage({ id: 'datamanagement_config_select_all' })
              }
            </div>
            <div className="KpiTree__header__remove-selection">
              {(checkedKeys.length > 0 || selectedCategory || selectedKpi) &&
                <A onClick={clearSelection}>
                  {intl.formatMessage({ id: 'datamanagement_config_clear_selection' })}
                </A>
              }
            </div>
          </>
        )}
        {showNotApplyToggle && (
          <div className="KpiTree__header__apply-switcher">
            <Switch
              size="small"
              onChange={setShowNotApplies}
              disabled={enabledOrderTree}
              checked={showNotApplies}
            />
            <span>{intl.formatMessage({ id: 'not_apply' })}</span>
          </div>
        )}
      </div>
      <div className={`KpiTree__list ${enabledOrderTree ? 'is-draggable' : ''}`}>
        { hasContent 
          ? <Tree
            {...props}
            expandedKeys={expandedKeys}
            onExpand={handleOnClickExpand}
            multiple={showOnlyCategories || isDirectory}
            treeData={treeNodes}
            titleRender={nodeData => (
              <Title
                nodeData={nodeData}
                enabled_reports={enabled_reports}
                searchText={search}
                expandedKeys={expandedKeys}
                showDetailedInfo={!selectedCategory && !selectedKpi && checkedKpis.length === 0}
                enabledOrderTree={enabledOrderTree}
              />
            )}
            expandAction={false}
            checkable={!enabledOrderTree}
            selectable={!enabledOrderTree}
            onSelect={handleSelect}
            showIcon={false}
            onCheck={handleCheck}
            checkedKeys={checkedKeys}
            selectedKeys={selectedKey}
            checkStrictly={showOnlyCategories}
            onDrop={handleDrop}
            draggable={enabledOrderTree}
            allowDrop={({ dropNode: nodeWhereDrop, dropPosition }) => {
              // NOTICE: if dropPosition is 0 then the droppedNode is dropped inside nodeWhereDrop
              const dropInside = dropPosition === 0;
              const dropInsideIndicator = !nodeWhereDrop.isCategory && dropInside;
              return !dropInsideIndicator;
            }}
          />
          : emptyState
        }
      </div>
    </div>
  );
}
export default injectIntl(KpiTree);
