import React, { useCallback, useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  message,
} from 'antd';
import {
  useDispatch, useSelector,
} from 'react-redux';
import {
  deleteKpi, deleteKpiCategory
} from 'actions/api';

import useOrganizations from 'utils/useOrganizations';

import KpiCategorySummaryHeader from './KpiCategorySummaryHeader';
import ActionsOverCheckedItems from './ActionsOverCheckedItems';
import CollapseEditKPI from 'components/CollapseEditKpi';
import CollapseEditCategory from 'components/CollapseEditCategory';
import { useFeatureList } from 'components/FeatureSwitch';

import './style.less';
import { flatten, compact, intersection, uniq, uniqBy } from "lodash";

const KpiTreeActionsSection = ({
  intl,
  checkedKpis = [],
  selectedKpi,
  checkedCategories = [],
  selectedCategory,
  organizationTree,
  organization,
  suborganization,
  onClearSelecteds,
  reporting_structure,
  enabled_reports,
  savingReportingStructure
}) => {

  const {
    permissions,
  } = useOrganizations();
  const {
    features: featureList,
  } = useFeatureList();

  const isRestrictedAdmin = useMemo(() => (
    featureList.has('restricted_admins') && !permissions?.can_affect_all_kpis
  ), [
    featureList,
    permissions,
  ]);

  const dispatch = useDispatch()

  const getRecursiveChildren = useCallback((category) => flatten(category?.children?.map(child => {
    if (child.isCategory) return getRecursiveChildren(child)
    else return child
  })), [])

  // -- data bindings
  const setOrganizationsRunning = useSelector(state => state.kpi_organizations.loading)
  const setOrganizationsError = useSelector(state => state.kpi_organizations.error)
  // -- memo
  const checkedKPIsSlugs = useMemo(() => (checkedKpis.map(({ slug }) => slug)), [checkedKpis]);
  const checkedAndSelectedKpis = useMemo(() => uniqBy(compact([...checkedKpis, selectedKpi, ...getRecursiveChildren(selectedCategory)]), 'uuid'), [checkedKpis, getRecursiveChildren, selectedCategory, selectedKpi]);
  const allSDGs = useMemo(() => uniq(checkedAndSelectedKpis.reduce((acc, kpiInfo) => [...acc, ...(kpiInfo.sdgs || [])], [])), [checkedAndSelectedKpis])
  const checkedAllSDGs = useMemo(() => uniq(checkedKpis.reduce((acc, kpiInfo) => [...acc, ...(kpiInfo.sdgs || [])], [])), [checkedKpis])
  const commonSDGs = useMemo(() => intersection(...(checkedKpis.map(({ sdgs }) => sdgs))), [checkedKpis])
  const allESGs = useMemo(() => uniq(checkedAndSelectedKpis.reduce((acc, kpiInfo) => [...acc, ...(kpiInfo.esgs || [])], [])), [checkedAndSelectedKpis])
  const checkedAllESGs = useMemo(() => uniq(checkedKpis.reduce((acc, kpiInfo) => [...acc, ...(kpiInfo.esgs || [])], [])), [checkedKpis])
  const allTags = useMemo(() => uniq(checkedAndSelectedKpis.reduce((acc, kpiInfo) => [...acc, ...(kpiInfo.tags || [])], [])), [checkedAndSelectedKpis])
  const allFieldTypes = useMemo(() => uniq(checkedAndSelectedKpis.reduce((acc, kpiInfo) => [...acc, kpiInfo.schema.type], [])), [checkedAndSelectedKpis])
  const allPeriodicities = useMemo(() => uniq(checkedAndSelectedKpis.reduce((acc, kpiInfo) => [...acc, ...kpiInfo.periodicities], [])), [checkedAndSelectedKpis])
  const checkedAllPeriodicities = useMemo(() => uniq(checkedKpis.reduce((acc, kpiInfo) => [...acc, ...kpiInfo.periodicities], [])), [checkedKpis])
  const commonPeriodicities = useMemo(() => intersection(...(checkedKpis.map(({ periodicities }) => periodicities))), [checkedKpis])
  const allOrgs = useMemo(() => uniq(checkedKpis.reduce((acc, kpiInfo) => [...acc, ...kpiInfo.orgs], [])), [checkedKpis])
  const commonOrgs = useMemo(() => intersection(...(checkedKpis.map(({ orgs }) => orgs))), [checkedKpis])
  const allStandards = useMemo(() => uniq(checkedAndSelectedKpis.reduce((acc, kpiInfo) => [...acc, ...kpiInfo.standard_info.filter(({ standard, code }) => kpiInfo.is_custom ? code : enabled_reports.includes(standard))], []).map(({ standard }) => standard)), [checkedAndSelectedKpis, enabled_reports])
  const allAnnualStartDate = useMemo(() => uniq(checkedKpis.reduce((acc, kpiInfo) => [...acc, kpiInfo.annual_start_date], [])), [checkedKpis])
  // -- events

  useEffect(() => {
    if (!setOrganizationsRunning && checkedAndSelectedKpis.length > 0) {
      message.info(intl.formatMessage({ id: "kpi_tree_action_section_organizations_saved" }))
    }
  }, [setOrganizationsRunning]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (setOrganizationsError) {
      message.error(intl.formatMessage({ id: "kpi_tree_action_section_organizations_error" }))
    }
  }, [setOrganizationsError]) //eslint-disable-line react-hooks/exhaustive-deps

  const handleKpiDeleteFromFolder = () => {
    dispatch(deleteKpi(organization.slug, suborganization.slug, selectedKpi.slug, selectedKpi.uuid))
    onClearSelecteds()
  }
  const handleKpiDeleteFromPlatform = () => {
    dispatch(deleteKpi(organization.slug, suborganization.slug, selectedKpi.slug))
    onClearSelecteds()
  }
  const handleCategoryDeleteFromPlatform = () => {
    dispatch(deleteKpiCategory(organization.slug, suborganization.slug, selectedCategory.slug))
    onClearSelecteds()
  }
  const handleCategoryDeleteFromTree = () => {
    dispatch(deleteKpiCategory(organization.slug, suborganization.slug, selectedCategory.slug, selectedCategory.uuid))
    onClearSelecteds()
  }
  // --
  return (
    <Row className="KpiTreeActionsSection">
      <Col span={24} className="KpiTreeActionsSection__actions">
        <KpiCategorySummaryHeader
          checkedKpis={checkedKpis}
          selectedKpi={selectedKpi}
          checkedCategories={checkedCategories}
          selectedCategory={selectedCategory}
          checkedAndSelectedKpis={checkedAndSelectedKpis}
          checkedKpisNumber={uniqBy(compact([...checkedKpis, selectedKpi]), 'uuid').length}
          checkedCategoriesNumber={uniqBy(compact([...checkedCategories, selectedCategory]), 'uuid').length}
          esgTypes={allESGs}
          sdgs={allSDGs}
          tags={allTags}
          fieldTypes={allFieldTypes}
          periodicities={allPeriodicities}
          allStandards={allStandards}
        //loading={loading}
        />
      </Col>
      {selectedKpi?.slug && permissions.can_write_kpi && (
        <Col span={24}>
          <React.Fragment key={selectedKpi.slug}>
            <CollapseEditKPI
              {...selectedKpi}
              handleKpiDeleteFromFolder={handleKpiDeleteFromFolder}
              handleKpiDeleteFromPlatform={handleKpiDeleteFromPlatform}
              reporting_structure={reporting_structure}
              loading={savingReportingStructure}
            />
          </React.Fragment>
        </Col>
      )}
      {selectedCategory?.slug && permissions.can_write_kpi && (
        <Col span={24}>
          <React.Fragment key={selectedCategory.slug}>
            <CollapseEditCategory
              {...selectedCategory}
              handleCategoryDeleteFromPlatform={handleCategoryDeleteFromPlatform}
              handleCategoryDeleteFromTree={handleCategoryDeleteFromTree}
              reporting_structure={reporting_structure}
              loading={savingReportingStructure}
            />
          </React.Fragment>
        </Col>
      )}
      {checkedKpis.length > 0 && permissions.can_configure_kpi && !isRestrictedAdmin && (
        <Col span={24} key={checkedKpis.length}>
          <ActionsOverCheckedItems
            checkedKPIsSlugs={checkedKPIsSlugs}
            checkedKpis={checkedKpis}
            checkedCategories={checkedCategories}
            organizationTree={organizationTree}
            allSDGs={checkedAllSDGs}
            commonSDGs={commonSDGs}
            allESGs={checkedAllESGs}
            allOrgs={allOrgs}
            commonOrgs={commonOrgs}
            allPeriodicities={checkedAllPeriodicities}
            commonPeriodicities={commonPeriodicities}
            allAnnualStartDate={allAnnualStartDate}
          />
        </Col>
      )
      }
    </Row>
  );
}

KpiTreeActionsSection.propTypes = {
  checkedKpis: PropTypes.array,
  organizationTree: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  suborganization: PropTypes.object.isRequired,
};

export default injectIntl(KpiTreeActionsSection);
