import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';

import {
  Modal,
} from 'antd';

import {formatMessage} from '../../../intl'

const NameForm = ({dashboardName, setDashboardName, handle}) => {
  return (
    <Form>
      <Form.Item
        label={formatMessage('modal_cud_label_name')}
      >
        <Input
          autoFocus
          value={dashboardName}
          onChange={(e) => setDashboardName(e.target.value)}
          onPressEnter={handle}
        />
      </Form.Item>
    </Form>
  )
}

const DEFAULT_PARAMETERS = {
  dashboardName: ''
}

const ModalCud = ({
  type,
  onCancel,
  handleNewDashboard,
  handleDashboardUpdate,
  handleDashboardDelete,
  defaultParameters
}) => {

  const parameters = { ...DEFAULT_PARAMETERS, ...defaultParameters };
  const [dashboardName, setDashboardName] = useState('');

  useEffect(() => {
    setDashboardName(type === 'update' ? parameters.dashboardName : '');
  }, [type, parameters.dashboardName]);

  const handleFunction = {
    create: () => {handleNewDashboard(dashboardName); setDashboardName("")},
    update: () => {handleDashboardUpdate({name: dashboardName}); setDashboardName("")},
    delete: handleDashboardDelete
  }

  const modalContent = {
    create: <NameForm dashboardName={dashboardName} setDashboardName={setDashboardName} handle={handleFunction.create}/>,
    update: <NameForm dashboardName={dashboardName} setDashboardName={setDashboardName} handle={handleFunction.update}/>,
    delete: <span>{formatMessage('modal_cud_delete_confirmation')}</span>
  }

  const titles = {
    create: formatMessage('modal_cud_create_dashboard'),
    update: formatMessage('modal_cud_update_dashboard'),
    delete: formatMessage('modal_cud_delete_dashboard')
  }
  
  return (
    <Modal
      destroyOnClose={true}
      centered
      onOk={handleFunction[type]}
      onCancel={onCancel}
      visible={type}
      title={titles[type]}
    >
      {modalContent[type]}
    </Modal>
  )
};

export default injectIntl(ModalCud);