import React, { useMemo, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Tabs,
  Switch,
  Rate,
  Button,
  Checkbox,
} from 'antd';

import './style.less';

const { TabPane } = Tabs;


const SurveySelectRecipients = ({
  intl,
  groups,
  recipients,
  addRecipient,
  updateRecipient,
  deleteRecipient,
}) => {
  const t = intl.messages;
  const [currentTab, setCurrentTab] = useState(
    (groups && groups[0]) ? groups[0].id.toString() : null
  );

  const onRecipientChange = useCallback(
    (recipient, weight, checked) => {
      const updateFunc = checked ? addRecipient : deleteRecipient;
      if (checked && weight) {
        updateRecipient({ goi_member_id: recipient.id, weight: weight });
      } else {
        updateFunc({ goi_member_id: recipient.id });
      }
    },
    [addRecipient, deleteRecipient, updateRecipient]
  );

  const showWeightColumn = useMemo(
    () => {
      if (!currentTab || !groups || !recipients) {
        return false;
      }

      const group = groups.find(group => group.id === parseInt(currentTab));
      return (
        group
        && recipients.find(
          recipient => group.members.map(m => m.id).includes(recipient.id)
        )
      )
      ? true
      : false;
    },
    [currentTab, groups, recipients]
  );

  const areAllMembersSelected = useCallback(members => {
    if (!members.length) {
      return false;
    }
    return members?.every(
      member => recipients.map(r => r.id).includes(member.id)
    )
  }, [
    recipients,
  ]);

  const areSomeMembersSelected = useCallback(members => {
    if (!members.length || areAllMembersSelected(members)) {
      return false;
    }
    return members?.some(
      member => recipients.map(r => r.id).includes(member.id)
    )
  }, [
    recipients,
    areAllMembersSelected,
  ]);

  const allGroupMembers = useMemo(
    () => groups?.reduce((members, group) => [...members, ...group.members], []),
    [groups ]
  );

  const toggleMembers = useCallback((members, checked) => {
    const recipientIds = recipients.map(r => r.id);
    return members?.filter(
      member => {
        if (checked) {
          return !recipientIds.includes(member.id);
        }
        return recipientIds.includes(member.id);
      }).forEach(
      member => onRecipientChange(member, null, checked)
    );
  }, [
    onRecipientChange,
    recipients,
  ]);

  return (
    <div className="SurveySelectRecipients">
      <Row>
        <Col span={24} className="SurveySelectRecipients-group-header">
          <Checkbox
            checked={areAllMembersSelected(allGroupMembers)}
            indeterminate={areSomeMembersSelected(allGroupMembers)}
            onChange={e => (
              toggleMembers(allGroupMembers, e.target.checked)
            )}
          > </Checkbox>
          <h3>{t.groups}</h3>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs
            tabPosition="left"
            type="card"
            activeKey={currentTab}
            onChange={key => setCurrentTab(key)}
          >
            {groups.map(group => {
              return (
                <TabPane
                  tab={
                    <>
                      <Checkbox
                        checked={areAllMembersSelected(group.members)}
                        indeterminate={areSomeMembersSelected(group.members)}
                        onChange={e => toggleMembers(group.members, e.target.checked)}
                      > </Checkbox>
                      {`${t['goi_' + group.type]} : ${group.name}`}
                    </>
                  }
                  key={group.id}
                >
                  <Row className="SurveySelectRecipients-recipient-header">
                    <Col span={10}>
                      <h4>{t.member}</h4>
                    </Col>
                    <Col span={4}>
                      <h4>{t.send_in_survey}</h4>
                    </Col>
                    {showWeightColumn && (
                      <Col span={10}>
                        <h4>{t.weight}</h4>
                      </Col>
                    )}
                  </Row>
                  {group.members.map(member => {
                    const isRecipient = recipients.find(
                      recipient => recipient.id === member.id
                    );
                    return (
                      <Row
                        className="SurveySelectRecipients-recipient"
                        type="flex"
                        key={member.id}
                      >
                        <Col span={10}>
                          {member.name} - {member.email}
                        </Col>
                        <Col span={4}>
                          <Switch
                            checked={isRecipient}
                            onChange={(checked) => onRecipientChange(member, null, checked)}
                          />
                        </Col>
                        {isRecipient && (
                          <Col span={10}>
                            <Rate
                              character={({ index }) => (
                                <Button
                                  className={`ant-btn-primary RatingButton${isRecipient.weight === index + 1 ? ' focus' : ''}`}
                                >
                                  {index + 1}
                                </Button>
                              )}
                              onChange={(value) => onRecipientChange(member, value, true)}
                            />
                          </Col>
                        )}
                      </Row>
                    )
                  })}
                </TabPane>
              )
            })}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
}


export default injectIntl(SurveySelectRecipients);
