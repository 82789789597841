import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  Col,
  Row,
} from 'antd';

import {
  requestKpiUpdate,
  requestKpiValueDependencies,
} from 'actions/api';

import { useFeatureList } from 'components/FeatureSwitch';
import useOrganizations from 'utils/useOrganizations';
import { selectTargetValue } from 'utils/kpiMonitoring';

import PeriodLabel from 'components/PeriodLabel';
import KpiSourceIcon from 'components/KpiSourceIcon';
import HiddenLogo from 'components/HiddenLogo'
import DataTargets from 'components/DataTargets'

import KpiAttachmentIcon from './KpiAttachmentIcon';
import DataStatus from './DataStatus';
import DataActions from './DataActions';
import DataAnswer from './DataAnswer';

const NO_OP = () => { };

const DataPeriod = injectIntl(({
  permissions,
  source, // NOTICE: Source for this period
  source_params,
  schema,
  kpi_slug,
  period,
  period_start,
  period_end,
  kpi_value,
  previous_value,
  comment,
  ready_to_validate_level,
  validated_level,
  status,
  onOpenAnswer = NO_OP,
  onApprove,
  onReject,
  onStartValidation,
  onTarget,
  schemaLabels,
  rejection_count,
  rejection_comment,
  has_attachments,
  applies,
  monitoring_value,
  config: kpiConfig,
}) => {
  const [editting, setEditting] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [payload, setPayload] = useState(null);

  const {
    organization,
    suborganization,
  } = useOrganizations();
  const dispatch = useDispatch();

  const {
    suborganizationFeatures: featureList,
  } = useFeatureList();

  const { mandatory_data } = kpiConfig

  const config = useMemo(() => {
    return ((suborganization || {}).product_config || {}).atlas || {};
  }, [
    suborganization,
  ]);

  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);

  const condition = useMemo(() => {
    return {
      attachment: has_attachments,
      value: kpi_value,
      comment: comment,
    };
  }, [has_attachments, kpi_value, comment])

  const validationEnabled = useMemo(() => {
    if (source === 'children') {
      // NOTICE: This is a bit sad. We do not validate kpis with aggregation = 'children'
      //         because they do not really have a value.
      // TODO: Consider conditions for validating other aggregated kpis
      return true;
    }

    return Object.keys(config?.mandatory_data_in_kpis || {}).reduce((acc, key) => {
      acc = config?.mandatory_data_in_kpis[key] && mandatory_data[key] ? condition[key] && acc : acc;
      return acc;
    }, true);
  }, [source, config?.mandatory_data_in_kpis, mandatory_data, condition]);

  const dependencyHash = useMemo(() => {
    return [
      organization.slug,
      suborganization.slug,
      kpi_slug,
      period,
    ].join('|')
  }, [
    organization.slug,
    suborganization.slug,
    kpi_slug,
    period,
  ]);

  const allKpiDependencies = useSelector(state => state.kpi_dependency);

  const {
    loading: loadingDependencies = false,
    //error: errorDependencies,
    data: dependees,
  } = allKpiDependencies[dependencyHash] || {};

  const requestDependencies = useCallback(() => {
    dispatch(
      requestKpiValueDependencies(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
        dependencyHash,
      )
    );
  }, [
    dispatch,
    organization.slug,
    suborganization.slug,
    kpi_slug,
    period,
    dependencyHash,
  ]);

  const handleSetEditting = useCallback(() => {
    setEditting(true);
    requestDependencies();
  }, [
    requestDependencies,
  ]);

  const {
    loading,
    error,
  } = useSelector(state => state.data_categories);

  const isTargetDefined = useCallback((monitoring_arr = []) => {
    let result = monitoring_arr.filter(el => el.type === 'target' && el.value != null);
    return result.length > 0 ? true : false;
  }, []);

  // NOTICE: This comes from a child component and simply clones
  // the value submitted in "payload"
  const handleUpdateValue = useCallback((value) => {
    console.log('Updating', value);
    setPayload(value);
  }, []);

  const handleSave = useCallback(() => {
    const body = {
      value: payload,
    };
    dispatch(
      requestKpiUpdate(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
        body,
      )
    )
    setSubmitting(true);
  }, [
    dispatch,
    organization,
    suborganization,
    kpi_slug,
    period,
    payload,
  ]);

  const handleApprove = useCallback((level) => {
    onApprove(period, level);
  }, [
    onApprove,
    period,
  ]);

  const handleReject = useCallback((level) => {
    onReject(period, level);
  }, [
    onReject,
    period,
  ]);

  const handleStartValidation = useCallback(() => {
    onStartValidation(period);
  }, [
    onStartValidation,
    period,
  ]);

  const handleTarget = useCallback((arg) => {
    //period, kpi_value,target_value
    onTarget(period, kpi_value, selectTargetValue(monitoring_value));
  }, [
    onTarget,
    period,
    kpi_value,
    monitoring_value
  ]);

  useEffect(() => {
    if (submitting && !loading && !error) {
      setEditting(false);
      setSubmitting(false);
    }
  }, [
    submitting,
    loading,
    error,
  ]);

  return (
    <div className="KpiSummaryCard__innercard KpiSummaryCard__periodcard">
      <Row
        type="flex"
        gutter={30}
      >
        <Col className="KpiSummaryCard__iconsrow">
          {!applies && (
            <div className="ListSidebar__hiddenlogo">
              <HiddenLogo />
            </div>
          )}
          <PeriodLabel
            period={{
              label: period,
              start_date: period_start,
              end_date: period_end,
            }}
          />
          <KpiSourceIcon
            source={source}
          />
          <KpiAttachmentIcon
            has_attachments={has_attachments}
          />
        </Col>
        <Col
          style={{
            flex: '1',
            maxWidth: '100%',
            overflow: 'hidden',
          }}
        >
          <Row
            type="flex"
            gutter={16}
            justify="space-between"
            style={{
              width: '100%',
            }}
          >
            <Col
              style={{
                flex: '1',
                maxWidth: '100%',
                overflow: 'hidden',
              }}
            >
              {
                loadingDependencies
                ? <span>...</span> // TODO: Empty state
                : editting
                  ? (
                    <DataAnswer
                      editting
                      schema={schema}
                      tableDimensions={tableDimensions}
                      schemaLabels={schemaLabels}
                      kpi_slug={kpi_slug}
                      organization={suborganization.slug}
                      organization_parent={suborganization.parent_slug}
                      period={period}
                      dependees={dependees}
                      config={config}
                      kpi_value={kpi_value}
                      disabled={loading || loadingDependencies}
                      onUpdateValue={handleUpdateValue}
                      target={selectTargetValue(monitoring_value)}
                      isTarget={featureList.has('targets') && (selectTargetValue(monitoring_value) ? true : false)}
                      source={source}
                      source_params={source_params}
                    />
                  ) : !kpi_value || source === 'children'
                    ? (
                      <DataStatus
                        status={status}
                        source={source}
                      />
                    ) : (
                      <DataAnswer
                        editting={false}
                        schema={schema}
                        tableDimensions={tableDimensions}
                        schemaLabels={schemaLabels}
                        kpi_slug={kpi_slug}
                        organization={suborganization.slug}
                        organization_parent={suborganization.parent_slug}
                        period={period}
                        dependees={dependees}
                        config={config}
                        kpi_value={kpi_value}
                        previous_value={previous_value}
                        onOpenAnswer={onOpenAnswer}
                        target={selectTargetValue(monitoring_value)}
                        isTarget={featureList.has('targets') && (selectTargetValue(monitoring_value) ? true : false)}
                        source={source}
                        source_params={source_params}
                      />
                    )
              }
            </Col>
            {featureList.has('targets') && !['table', 'qualitative'].includes(schema.type) && !editting &&
              <Col flex="auto" justify="end">
                <DataTargets
                  target={selectTargetValue(monitoring_value)}
                  kpi_value={kpi_value}
                  schema={schema}
                  schemaLabels={schemaLabels}
                  config={config}
                />
              </Col>
            }

            <Col>
              <DataActions
                status={status}
                source={source}
                editting={editting}
                onEdit={handleSetEditting}
                onCancel={() => setEditting(false)}
                onSave={handleSave}
                permissions={permissions}
                loading={loading || loadingDependencies}
                ready_to_validate_level={ready_to_validate_level}
                validated_level={validated_level}
                onApprove={handleApprove}
                onReject={handleReject}
                schema={schema}
                onStartValidation={handleStartValidation}
                targetDefined={isTargetDefined(monitoring_value)}
                onTarget={handleTarget}
                rejection_count={rejection_count}
                rejection_comment={rejection_comment}
                config={config}
                validationEnabled={validationEnabled}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
});

export default DataPeriod;

