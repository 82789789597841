import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react'
import io from 'socket.io-client';
import config from 'config';
import { useSelector, useDispatch } from 'react-redux';
import useOrganizations from 'utils/useOrganizations';
import { digestMessage } from 'actions/websocket';
import { refreshAccessToken } from 'actions/auth';
import { isExternalPath } from 'utils/validation';
import { queryStringToObject } from 'utils/queryParameters';

const WebSocketContext = createContext(null)
export { WebSocketContext }

export default ({ children }) => {
    const {api_requests, auth } = useSelector(state => state);
    const { topLevelOrg } = useSelector(state => state.third_party);
    const [ connectionInfo, setConnectionInfo ] = useState([]);
    const dispatch = useDispatch();
    const {
        data: member
      } = useSelector(state => state.profile);

    const {
    organization,
    suborganization,
    } = useOrganizations();

    // NOTICE: We cache this in its own useMemo so it generates just a string (that can be compared below)
    const memberString = useMemo(() => {
      if(!member) {
        return null;
      }
      return JSON.stringify({
        id: member.id,
        username: member.username,
        organization_id: member.organization_id
      });
    }, [
      member,
    ]);

    const closeOldSocket = () => {
      if(connectionInfo.length === 0){
        return;
      }
      setConnectionInfo(connectionInfo => connectionInfo.filter(el => {
        if (el.channel === 'private') {
          if (el.token !== api_requests.access_token || el.organization !== organization.slug || el.suborganization !== suborganization.slug ) {
            el.socket.close();
            return false;
          }else {
            return true;
          }
        }else {
          let parts = window.location.pathname.split('/');
          if (el.token !== parts[2]) {
            el.socket.close();
            return false;
          }else {
            return true;
          }
        }
      }));
    };

    const socket = useMemo(() => {
       if((!organization || !suborganization || !auth?.logged_in || !memberString) && !isExternalPath(window?.location?.pathname)) {
        return null;
      }
      closeOldSocket();
    
      // Private channel: only for logged in member
      let URL_SUFFIX, customHeaders, TOKEN, channel;

      if (auth.logged_in && memberString) {
        URL_SUFFIX = '/private';
        channel = 'private';
        customHeaders = {
            'Authorization': `Bearer ${api_requests.access_token}`,
            'X-Aplanet-Organization': organization.slug,
            'X-Aplanet-Suborganization': suborganization.slug
        };
        TOKEN = api_requests.access_token;
      }

      // Public Chanel: only for external paths /request/:token
      if (isExternalPath(window.location.pathname)) {
        const queryStringObject = queryStringToObject(window.location?.search);
        if (!queryStringObject?.toplevelorg && !topLevelOrg) {
          return null;
        }

        URL_SUFFIX = '/public';
        channel = 'public';

        TOKEN = window.location.pathname.split('/')[2];
        
        customHeaders = {
            'Authorization': `Bearer ${TOKEN}`,
            'X-Aplanet-Token': TOKEN,
            'X-Aplanet-Organization': queryStringObject?.toplevelorg || topLevelOrg,
        };
      }
             
      const socket = io.connect(config.WS_APP_URL + URL_SUFFIX,{
          extraHeaders:customHeaders
          // query:{
          //   organization: organization.slug,
          //   suborganization: suborganization.slug,
          //   member: memberString,
          // },
          // auth:{
          //   organization: organization.slug,
          //   suborganization: suborganization.slug,
          //   member: memberString,
          //   token: api_requests.access_token
          // },
          //withCredentials: true
        });
        setConnectionInfo(connectionInfo => ([...connectionInfo, {
          socket: socket, 
          token: TOKEN,
          channel: channel,
          organization: organization? organization.slug: '',
          suborganization: suborganization? suborganization.slug: ''
        }]));
        return socket;
    }, [ // eslint-disable-line react-hooks/exhaustive-deps
      auth.logged_in,
      memberString,
      organization,           // Uncomment to reconnect after changing main org
      suborganization,        // Uncomment to reconnect after changing suborg
      api_requests.access_token, // Uncomment to reconnect after refreshing token
    ]);

    const sendMessage = useCallback((event, payload) => {
        socket.emit(event, payload);
    }, [
      socket
    ]);




    useEffect(() => {    
      if(!socket) {
        return;
      }
      socket.onAny((event,msg) => {
        dispatch(digestMessage(event,msg));
      });

      // socket.on("notification:get", (msg) => {
      //     console.log('notification:get ',msg);
      // })
      // socket.on("notification:create", (msg) => {
      //     console.log('notification:create ',msg);
      // })

      socket.on("connect_error", (reason) => {
          //socket.auth.token = api_requests.access_token;
          console.log('socket connect_error ', reason);
          if(reason === 'TokenExpiredError: jwt expired'){
            dispatch(refreshAccessToken());
          }

      });

      socket.on("disconnect", (reason) => {
          console.log('socket disconnected ',reason);
        });

      // get all notifications on connection
      socket.emit('notification:get');
    }, [
      socket,
      dispatch
    ]);

    return (
        <WebSocketContext.Provider value={socket ? { socket, sendMessage } : {}}>
            {children}
        </WebSocketContext.Provider>
    )
}
