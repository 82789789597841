import React, {
  useCallback
} from 'react';
import { injectIntl } from 'react-intl';
import { Checkbox } from 'antd';
import {
  useCollectionFrequencyOptions,
} from 'utils/collectionFrequency';
import CustomTag from 'components/CustomTag';
import './style.less';

const PeriodicitiesSelector = ({
  intl,
  value = {},
  onChange,
  error = '',
  disabled = false
}) => {

  const collectionFrequencyOptions = useCollectionFrequencyOptions(intl);
  const { checked = [], halfChecked = [] } = value
  const isChecked = useCallback((slug) => checked.includes(slug), [checked])
  const isPartial = useCallback((slug) => halfChecked.includes(slug), [halfChecked])

  return (
    <>
      <div className='PeriodicitiesSelector'>
        {
          collectionFrequencyOptions.map(({ slug, name }) =>
            <Checkbox key={slug} value={slug} checked={isChecked(slug)} indeterminate={isPartial(slug)} onChange={e => onChange(slug, e.target.checked)} disabled={disabled}>
              <CustomTag
                key={slug}
                name={name}
                colorclass={disabled ? 'grey' : isChecked(slug) ? slug : isPartial(slug) ? 'indeterminate' : 'grey'}
              />
            </Checkbox>
          )
        }
      </div>
      <p className='PeriodicitiesSelector__error-message'>{error}</p>
    </>
  )
}
export default injectIntl(PeriodicitiesSelector);
