import React, {
    useMemo,
    useCallback
  } from 'react';
  import { injectIntl } from 'react-intl';
  import { useSelector } from 'react-redux';
  import useOrganizations from 'utils/useOrganizations';
  import CustomInput from 'components/CustomInput';
  import {
    TreeSelect,
  } from 'antd';
  
  import {
    Row,
    Col,
  } from 'antd';
  import { isNull } from 'lodash';
  import '../style.less';
  
  const { SHOW_PARENT } = TreeSelect;
  
  
  const NOT_NUMBER_RE = new RegExp('\\D', 'g');
  const METRIC_FIELD = 'metricSlug';
  const UNITS_FIELD = 'allowedUnitSlugs';
  const DECIMALS_FIELD = 'decimalPoints';
  
  const Quantitative = injectIntl(({
    intl,
    values,
    onChange,
    error,
    setDirty,
  }) => {
    const INTEGER_FORMATTER = useCallback(intl => (value) => `${value}`.replace(NOT_NUMBER_RE, ''), []);
    const INTEGER_PARSER = useCallback(intl => value => value.replace(NOT_NUMBER_RE, ''), []);
  
    const {
      metrics = [], // This is really aggregated metrics
    } = useSelector(state => state.taxonomies);
  
    const {
      organization,
      suborganization,
    } = useOrganizations();

    const metricsAndUnits = useMemo(() => {
      const preferredUnits = (suborganization?.config || organization?.config || {}).preferred_units || {};
      return metrics.map((metric, metricIndex) => {
        return metric.metrics.map((submetric, submetricIndex) => {
          const disabled = values[METRIC_FIELD] ? !(values[METRIC_FIELD] === submetric.slug) : false;
          let units = submetric.units || [];
  
          if(preferredUnits[submetric.slug] && preferredUnits[submetric.slug].length > 0) {
            const preferredSet = new Set(preferredUnits[submetric.slug]);
            units = units.filter(({ slug }) => preferredSet.has(slug));
            if(units.length === 0) {
              units = submetric.units;
            }
          }
          return {
            title: metric.name === submetric.name || metric.name === '...'
            ? submetric.name
            : `${metric.name} - ${submetric.name}`,
            value: `${metricIndex}-${submetricIndex}-${submetric.name}-${submetric.symbol ?? ''}`,
            key: `${metricIndex}-${submetricIndex}-${submetric.name}-${submetric.symbol ?? ''}`,
            slug: submetric.slug,
            disabled,
            children: units.map((unit, unitIndex) => ({
              title: unit.symbol ? `${unit.name} (${unit.symbol})` : unit.name,
              value: `${metricIndex}-${submetricIndex}-${unitIndex}-${unit.name}-${unit.symbol ?? ''}`,
              key: `${metricIndex}-${submetricIndex}-${unitIndex}-${unit.name}-${unit.symbol ?? ''}`,
              slug: unit.slug,
              disabled
            })),
          };
        });
      }).reduce((acc, val) => acc.concat(val), []);
    }, [metrics, organization.config, suborganization.config, values]);

    const initialUnitValues = useMemo(() => {
      let defaultCheckedKeys = [];
      const preferredUnits = (suborganization?.config || organization?.config || {}).preferred_units || {};
      metrics.forEach((metric, metricIndex) => {
        metric.metrics.forEach((submetric, submetricIndex) => {
          let units = submetric.units || [];
  
          if(preferredUnits[submetric.slug] && preferredUnits[submetric.slug].length > 0) {
            const preferredSet = new Set(preferredUnits[submetric.slug]);
            units = units.filter(({ slug }) => preferredSet.has(slug));
            if(units.length === 0) {
              units = submetric.units;
            }
          }
          const checkedIndexes = (values[UNITS_FIELD] || []).map(vu => units.map(({slug}) => slug).indexOf(vu)).filter(vu => vu !== -1);
          if (checkedIndexes.length) {
            const checkedUnits = checkedIndexes.map(ci => `${metricIndex}-${submetricIndex}-${ci}-${units[ci].name}-${units[ci].symbol ?? ''}`);
            defaultCheckedKeys = checkedUnits;
          }
        });
      });
      return defaultCheckedKeys;
    }, [metrics, organization.config, suborganization.config, values]);

  const handleChangeUnit = useCallback((unitSlugs) => {
    const metric = metricsAndUnits.find(({ children = [], key }) => unitSlugs.includes(key) || children.find(({ key }) => unitSlugs.includes(key)));
    const units = metric?.children.filter(child => unitSlugs.includes(child.key)) || [];

    if(setDirty) {
      setDirty(true);
    }
    onChange(METRIC_FIELD)(metric?.slug);
    onChange(UNITS_FIELD)(units.length ? units.map(u => u.slug) : (metric?.children || []).map(c => c.slug));
  }, [
    setDirty,
    metricsAndUnits,
    onChange,
  ]);

  const tProps = useMemo(() => ({
    treeData: metricsAndUnits,
    defaultValue: initialUnitValues,
    onChange: handleChangeUnit,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: intl.formatMessage({ id: "createcustomkpi_select_unit"}),
    style: {
      width: '100%',
    },
    size: 'large',
    getPopupContainer: (trigger => trigger.parentNode)
  }), [handleChangeUnit, initialUnitValues, metricsAndUnits, intl]);

  const schemaProps = useMemo(() => {
    return {
      precision: 0,
      step: 1,
      min: 0,
      max: 6,
      formatter: (INTEGER_FORMATTER)(intl),
      parser: (INTEGER_PARSER)(intl),
    };
  }, [INTEGER_FORMATTER, INTEGER_PARSER, intl]);

  const handleChangeDecimals = useCallback((decimals) => {
    const finalValue = (decimals < schemaProps.min || decimals === '' || isNull(decimals)) ? schemaProps.min : decimals > schemaProps.max
      ? schemaProps.max : decimals;
    if(setDirty) {
      setDirty(true);
    }
    onChange(DECIMALS_FIELD)(finalValue);
  }, [
    onChange,
    setDirty,
    schemaProps.max,
    schemaProps.min,
  ]);

  const decimalPoints = useMemo(() => {
    return values[DECIMALS_FIELD];
  }, [values])

  return (
    <React.Fragment>
      <div className='CreateEditCustomKPI-title'>{intl.formatMessage({ id: "createcustomkpi_metric_and_unit"})}</div>
      <Row
        style={{ width: '100%' }}
        type="flex"
        gutter={24}
      >
        <Col xs={24} md={16}>
          <div className='CreateEditCustomKPI-miniTitle'>{intl.formatMessage({ id: "createcustomkpi_unit"})}</div>
          <TreeSelect {...tProps} />
        </Col>
        <Col xs={24} md={16} style={{marginTop: '10px'}}>
          <div className='CreateEditCustomKPI-miniTitle'>{intl.formatMessage({ id: "createcustomkpi_default_decimals"})}</div>
          <Row
            type="flex"
          >
            <CustomInput.Number
              value={decimalPoints}
              onChange={handleChangeDecimals}
              {...schemaProps}
              className="KpiDetail__settings-quantitative-input-medium"
            />
            {
              typeof decimalPoints !== 'undefined' &&
              <div className="CreateEditCustomKPI" style={{marginLeft: '5px'}}>{`${intl.formatMessage({ id: "createcustomkpi_example"})}: ${parseFloat(`1e-${decimalPoints}`)}`}</div>
            }
          </Row>
        </Col>
      </Row>
      {
        error &&
        <div className="CreateEditCustomKPI__error">{ error }</div>
      }
    </React.Fragment>
  )
});

export default Quantitative;
