import { loop, Cmd } from 'redux-loop';
import { history } from 'store';

const TARGET = 'analysis';

const loadSurveys = (data) => ({
  type: 'LOAD_SURVEYS',
  data
});

const initialState = {
  error: null,
  data: [],
  item: {},
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || response.target !== TARGET) return state;
      const result = response.result;
      switch(response.method) {
        case 'GET':
          if (Array.isArray(result)) {
            return {
              ...state,
              error: null,
              data: result,
            };
          }
          return {
            ...state,
            error: null,
            item: result,
          };
        case 'DELETE':
          return {
            ...state,
            error: null,
            data: state.data.filter(d => d.id !== result),
          };
        case 'POST':
          const newState = {
            ...state,
            error: null,
            data: [...state.data, result],
          };
          return loop(
            newState,
            Cmd.run(history.push, { args: [`/analysis/${result.id}/`] })
          );
        case 'PUT': {
          const index = state.data.map(
            analysis => analysis.id
          ).indexOf(parseInt(response.result.id));
          let analysis = response.result;
          const surveys = [...(response.result.surveys || [])];
          delete analysis.surveys;
          const newState = {
            ...state,
            error: null,
            data: [
              ...state.data.slice(0, index),
              analysis,
              ...state.data.slice(index + 1)
            ],
            item: analysis,
          };

          if (surveys) {
            return loop(
              newState,
              Cmd.action(loadSurveys(surveys))
            );
          }
          return newState;
        }
        default:
          return {...state, error: null};
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        error: action.code,
      };
    default:
      return state;
  }
};

export {
  reducer as analysis,
};
