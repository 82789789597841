import React from 'react';

import DataSourcesCategoryRow from 'components/DataSources/CategoryRow';


const DataSourcesTableBody = ({
  tree,
}) => {
  return (
    <tbody>
      { tree.map(node => <DataSourcesCategoryRow {...node}/>) }
    </tbody>
  );
};

export default DataSourcesTableBody;