import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import T from 'components/T';
import Loading from 'containers/Loading';
import SurveyInput from 'components/SurveyInput';
import NotFound from 'containers/NotFound';
import SignedOutFooter from 'components/SignedOutFooter';
import CustomButton from 'components/CustomButton';

import {
  Row,
  Col,
  Progress,
  Layout,
} from 'antd';

import { changeLocale } from '../../intl';

import {
  startSurvey,
  submitSurvey
} from 'actions/goi';

import './style.less';

const { Content } = Layout;

const Survey = ({
  intl,
  match,
  loading,
  error,
  startSurvey,
  subjects,
  goiMemberName,
  organizationName,
  locale,
  submitSurvey,
  status,
  changeLocale,
}) => {
  const t = intl.messages;
  const { mainOrgSlug, subOrgSlug, uuid } = match.params;
  const [responses, setResponses] = useState([]);
  const [subjectIndex, setSubjectIndex] = useState(0);

  useEffect(() => {
    startSurvey(mainOrgSlug, subOrgSlug, uuid);
  }, [mainOrgSlug, subOrgSlug, uuid, startSurvey]);

  useEffect(() => {
    if (locale) {
      changeLocale(locale);
    }
  }, [locale, changeLocale]);

  const onSubmitSurvey = useCallback(
    () => {
      const data = subjects.map((subject, index) => ({
        subjectId: subject.id,
        weight: responses[index].weight,
        comment: responses[index].comment
      }));
      submitSurvey(mainOrgSlug, subOrgSlug, uuid, data);
    },
    [submitSurvey, subjects, responses, mainOrgSlug, subOrgSlug, uuid]
  );

  if (loading) {
    return <Loading.Block />;
  }

  if (error) {
    return <NotFound />;
  }

  return (
    <Layout className="Layout">
      <Content className="Content-noheader">
        <Row type="flex" justify="center">
          <Col span={18} className="Survey">
            {status === 'close' ? (
              <h2>
                {t.survey_closed}
              </h2>
            ) : (
              status === 'replied' ? (
                <h2>
                  <T
                    survey_complete_response={{organizationName, goiMemberName}}
                  />
                </h2>
              ) : (
                <Fragment>
                  <h3>
                    <T survey_description={{ organization: organizationName }} />
                  </h3>
                  <Progress
                    percent={subjects ? ((subjectIndex + 1) / subjects.length * 100) : 0}
                    showInfo={false}
                    strokeColor="#117065"
                  />
                  <SurveyInput
                    mainOrgSlug={mainOrgSlug}
                    subjects={subjects}
                    responses={responses}
                    subjectIndex={subjectIndex}
                    setResponses={setResponses}
                  />
                  <Row type="flex" justify="center">
                    {subjects && ((subjectIndex + 1) === subjects.length) ? (
                      <CustomButton
                        type="primary"
                        size="large"
                        disabled={!(responses[subjectIndex] && responses[subjectIndex].weight)}
                        onClick={onSubmitSurvey}
                      >
                        { t.send }
                      </CustomButton>
                    ) : (
                      <CustomButton
                        type="primary"
                        size="large"
                        disabled={!(responses[subjectIndex] && responses[subjectIndex].weight)}
                        onClick={() => setSubjectIndex(subjectIndex + 1)}
                      >
                        { t.next }
                      </CustomButton>
                    )}
                  </Row>
                </Fragment>
              )
            )}
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col span={18}>
            <SignedOutFooter />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
};

const mapStateToProps = ({
  goi_survey
}) => ({
  error: goi_survey.error,
  loading: goi_survey.loading,
  organizationName: goi_survey.data && goi_survey.data.organizationName,
  locale: goi_survey.data && goi_survey.data.locale,
  goiMemberName: goi_survey.data && goi_survey.data.goiMemberName,
  subjects: goi_survey.data && goi_survey.data.subjects,
  status: goi_survey.data && goi_survey.data.status,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      startSurvey,
      submitSurvey,
      changeLocale,
    }
  )(Survey)
);
