import React, { useMemo, useCallback } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import { fromDb, formatDate, dateToDb } from 'utils/date';

import {
  Row,
  Col,
  Button,
  Badge,
  Progress,
  Tooltip,
  Modal,
  Space,
  Popover,
} from 'antd';

import {
  DeleteOutlined,
  CheckOutlined,
} from '@ant-design/icons';

import './style.less';


const ActionProgressHeader = ({
  intl,
  action,
  sequence,
  onDelete,
  updateAction,
}) => {
  const t = intl.messages;

  const actionProgress = useMemo(
    () => {
      if (action.completed) {
        return 100;
      }
      const indicatorsProgress = action.indicators.map(indicator => {
        return Math.min(
          Math.round(indicator.current_value / indicator.target_value * 100), 100
        );
      });
      return Math.round(
        indicatorsProgress.reduce( ( p, c ) => p + c, 0 ) / indicatorsProgress.length
      );
    },
    [action]
  );

  const progressBarAndStatus = useMemo(
    () => {
      let status = null;
      let type = null;
      let strokeColor = '#309309';

      if (action.cancel_reason) {
        status = `${t.canceled.toUpperCase()} - ${action.cancel_reason}`;
        type = 'danger';
        strokeColor = '#df1f1e';
      } else if (actionProgress >= 100 || action.completed) {
        status = (
          <span>
            {t.completed.toUpperCase()} (<FormattedMessage
              id="equality_actions_completed_on"
              values={{
                end_date: formatDate(action.end_date, 'D MMM YYYY', intl)
              }}
            />)
          </span>
        );
        type = 'success';
      } else if (new Date() > fromDb(action.end_date)) {
        status = (
          <span>
            {t.expired.toUpperCase()} (<FormattedMessage
              id="equality_actions_days_from_to"
              values={{
                start_date: formatDate(action.start_date, 'D MMM YYYY', intl),
                end_date: formatDate(action.end_date, 'D MMM YYYY', intl)
              }}
            />)
          </span>
        );
        type = 'warning';
        strokeColor = '#ed9150';
      } else {
        const daysLeft = Math.round(
          (fromDb(action.end_date) - new Date()) / (1000 * 3600 * 24)
        );
        status = (
          <span>
            <FormattedMessage
              id="days_left"
              values={{ days: daysLeft }}
            /> (<FormattedMessage
              id="equality_actions_days_from_to"
              values={{
                start_date: formatDate(action.start_date, 'D MMM YYYY', intl),
                end_date: formatDate(action.end_date, 'D MMM YYYY', intl)
              }}
            />)
          </span>
        );
      }
      return (
        <>
          <Col span={24}>
            <Progress
              className='action-progress-bar large'
              percent={actionProgress}
              strokeColor={strokeColor} />
          </Col>
          <Col span={24}>
            <span className={`action-status ${type}`}>{status}</span>
          </Col>
        </>
      );
    },
    [t, action, actionProgress, intl]
  );

  const handleOnDone = useCallback(
    (action) => {
      Modal.confirm({
        title: t.equality_actions_complete_title,
        okText: t.equality_actions_complete_ok,
        cancelText: t.equality_actions_complete_cancel,
        onOk() {
          updateAction(
            action.id,
            {
              ...action,
              end_date: dateToDb(new Date()),
              completed: true,
            }
          );
        },
      })
    },
    [updateAction, t]
  );

  return (
    <Row>
      <Col span={21}>
        <Row className='action-progress-header'>
          <Col span={24}>
            <Space size="small">
              <span className="action-progress-header-title">{action.name}</span>
              { 
                action.details?.activities_description && (
                  <Popover
                    placement="bottom"
                    content={
                      <p className='popover-text'>
                        <ReactMarkdown
                          source={action.details.activities_description}
                        />
                      </p>
                    }
                    trigger="click"
                  >
                    { // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a>{t.equality_actions_view_activities}</a>
                    }
                  </Popover>
                )
              }
            </Space>
          </Col>
          {progressBarAndStatus}
        </Row>
      </Col>
      <Col span={3}>
        <Row gutter={5}>
          {!action.cancel_reason && 
            <Col>
              <Tooltip title={t.equality_actions_table_actions_delete}>
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => onDelete()}
                ></Button>
              </Tooltip>
            </Col>
          }
          <Col>
            <Tooltip title={t.equality_actions_table_actions_done}>
              <Button
                icon={<CheckOutlined />}
                onClick={() => handleOnDone(action)}
              ></Button>
            </Tooltip>
          </Col>
          <Col>
            <Badge className='form-item-preview-badge' count={sequence} />
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

export default injectIntl(ActionProgressHeader);
