import React from 'react';

import List from 'components/EqualityPlanActions/List';

import {
  Row,
  Col,
} from 'antd';

import './style.less';

const EqualityPlanActions = ({
  stage = "actions",
  plan,
  updatePlan,
  objectives,
  fetchObjectives,
  fetchingObjectives,
  actions,
  fetchingActions,
  fetchActions,
  createAction,
  updateAction,
  updatePlanProgress,
  measures,
  createMeasure,
}) => {
  return (
    <Row>
      <Col span={24}>
        <List
          stage={stage}
          plan={plan}
          updatePlan={updatePlan}
          objectives={objectives}
          fetchObjectives={fetchObjectives}
          fetchingObjectives={fetchingObjectives}
          actions={actions}
          fetchingActions={fetchingActions}
          fetchActions={fetchActions}
          createAction={createAction}
          updateAction={updateAction}
          updatePlanProgress={updatePlanProgress}
          measures={measures}
          createMeasure={createMeasure}
        />
      </Col>
    </Row>
  );
};

export default EqualityPlanActions;
