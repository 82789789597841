import { parse } from 'math-parser';

import {
  kebabToCamel,
} from './string';

import BracesReplacer from '../js/bracesReplacer';

import {
  DEFAULT_PERIOD,
  DEFAULT_ORGANIZATION,
} from './math';

const FORMULA_ERRORS = [
  'null_kpi',
  'parse',
];

const KPI_FIELDS = [
  'kpi',
  'address',
  'period',
  'organization',
  'unit',
  'filterRows',
];

const validateFormula = (
  formula = '',
  context = {},
) => {
  let kpis = [];
  let kpiCache = {};
  let errors = [];

  const kpiFormula = formula.replace(
    new BracesReplacer(),
    function(match) {
      // NOTICE: here we abuse the "pure" replace function to emit a side effect
      //         this is ugly but pretty damn convenient
      try {
        const info = JSON.parse(match);
        kpis.push({
          ...info,
          period: info.period || DEFAULT_PERIOD,
          organization: info.organization || DEFAULT_ORGANIZATION,
        });

        const kpiSlug = info.kpi || (context || {}).default_kpi_slug;

        if(!kpiSlug) {
          throw new Error('null_kpi');
        }

        const base = kebabToCamel(kpiSlug);

        const subindex = Object.keys(kpiCache[base] || {}).length;
        const identifier = `${base}_${subindex}`;
        kpiCache[base] = kpiCache[base] || {};
        kpiCache[base][subindex] = info;
        return identifier;
      } catch(err) {
        if(FORMULA_ERRORS.includes(err.message)) {
          errors.push(err.message)
        } else {
          errors.push('json_syntax');
        }
        return 'x';
      }
    }
  );

  try {
    parse(kpiFormula);
  } catch(err) {
    errors.push('parse');
  }

  const hasErrors = errors.length > 0;
  return { hasErrors, errors, kpis };
};

const validateKpis = (kpis = []) => {
  let errors = [];

  for(let kpi of kpis) {
    let unknownKeys = Object.keys(kpi).filter(key => !KPI_FIELDS.includes(key))
    if(unknownKeys.length > 0) {
      errors.push({ error: 'unknown_key', param: { keys: unknownKeys.join(', ') } });
    }
  }

  const hasErrors = errors.length > 0;
  return { hasErrors, errors };
};

const fullValidation = (intl) => ({ formula, context }) => {
  let errors = {};
  let validationResult = validateFormula(formula, context);

  // First handle parse errors and the like
  for(let error of Array.from( new Set ( validationResult.errors ))) {
    errors[`formula_${error}`] = intl.formatMessage({ id: `formula_error_${error}` });
  }

  const kpiValidationResult = validateKpis(validationResult.kpis);

  for(let e of kpiValidationResult.errors ) {
    const { error, param } = e || {};
    errors[`formula_kpi_${error}`] = intl.formatMessage({ id: `formula_kpi_error_${error}` }, param);
  }

  return errors;
};

export default fullValidation;

