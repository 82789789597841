import React, {
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import CollapsableCard from 'components/CollapsableCard';
import KpiLabel from 'components/KpiLabel';
//import PeriodLabel from 'components/PeriodLabel';
import KpiFormula from 'components/KpiFormula';

import {
  formulaToTex,
} from 'utils/kpi_formula/tex';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Dependencies = ({
  intl,
  source,
  formula,
  kpis,
  urlBase = 'kpi', // NOTICE: For KpiLabel link
}) => {
  const t = intl.messages;

  const [
    tex,
    variableMap = {},
  ] = useMemo(() => {
    if (source !== 'calculated' || !formula) {
      return [];
    }
    let tex, kpiMap
    try {
      const data = formulaToTex(
        formula,
        true, // Set to 'true' to use short variable names 'A', 'B', 'C'...
      );
      tex = data.tex
      kpiMap = data.kpis
    } catch (error) {
      console.error(error);
    }

    let variableMap = {};
    for (let v in kpiMap) {
      const kpi = kpiMap[v]['0'];
      variableMap[kpi.kpi] = v;
    }

    return [
      tex,
      variableMap,
    ];
  }, [
    source,
    formula,
  ]);

  // TODO: Print formula
  return (
    <CollapsableCard
      defaultExpanded
      title={
        t.kpidetail_dependencies
      }
      icon={
        <FontAwesomeIcon
          className={
            'KpiDetail__90deg icon-yellow'
          }
          icon={'sitemap'}
        />
      }
      className='KpiDetail__dependencies'
    >
      <section className='KpiDetail__dependencies_section'>
        {
          source !== 'calculated' || !tex
            ? null
            : (
              <KpiFormula
                tex={tex}
              />
            )
        }
        <div>
          {
            kpis.map(({
              name,
              slug,
              esg_type,
              period,
            }) => (
              <div
                className="KpiDetail__dependencies_kpirow"
              >
                {
                  !variableMap || !variableMap[slug] || source !== 'calculated'
                    ? null
                    : (
                      <>
                        <KpiFormula
                          inline={true}
                          tex={variableMap[slug]}
                        />
                        {' '}
                      </>
                    )
                }
                <KpiLabel
                  name={name}
                  slug={slug}
                  esg_type={esg_type}
                  urlBase={urlBase}
                  period={period}
                />
              </div>
            ))
          }
        </div>
      </section>
    </CollapsableCard>
  );
};

export default injectIntl(Dependencies);
