import {some} from 'lodash'

import {
  mapOverTree,
  mapOverTreeFromLeafs,
  appendLeafToTree,
} from 'utils/tree';

import {
  patchKpiStatus,
} from 'utils/kpi';

import objectFilter from 'utils/objectFilter';

const TARGET = 'data_categories_no_periods';
const ORIGINAL_TARGET = 'data_categories'; // We also need to take POST / PUT / DELETE to this target, but not GET
const KPI_TARGET = 'data_kpi';
const META_TARGET = 'data_meta';
const APPROVAL_TARGET = 'data_approval';

const SKIP_META_TARGETS = ['descendants'];

const isTarget = (target, method) => [
  TARGET,
  ORIGINAL_TARGET,
  KPI_TARGET,
  META_TARGET,
  APPROVAL_TARGET,
].includes(target) && (target !== ORIGINAL_TARGET || method !== 'GET');
const isGeneralTarget = (target, method) => target === TARGET || (target === ORIGINAL_TARGET && method !== 'GET');

const ID_FIELD = 'slug';
const CHILD_FIELD_FOR_PARENT = 'category_slug';
const PREV_ID_FIELD = 'old_slug';

const initialState = {
  data: null,
  tags: [],
  loading: false,
  error: null,
};

const getTags = (categories) => {
  const getTagsRepeated = (tree) => 
    tree.reduce((acc, node) => node.children 
      ? [...acc, ...getTagsRepeated(node.kpis || []), ...getTagsRepeated(node.children || [])]
      : [...acc, ...(node.tags || [])], []);
    
  return Array.from(new Set(getTagsRepeated(categories)))
}

const reducer = (state = initialState, action) => {
  let data, entry;
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target, action.method)) return state;
      return {
        data: action.method === 'GET' ? null : state.data,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target, action.response.method)) return state;
      data = state.data || [];
      if(isGeneralTarget(action.response.target, action.response.method)) {
        switch(action.response.method) {
          case 'GET':
            // Categories obtained
            data = mapOverTree(action.response.result)((node) => {
              return {
                ...node,
                kpis: (node.kpis || []).map(patchKpiStatus),
              }
            });
            break;
          case 'POST':
            // Category created
            if(!action.response.result.length) {
              entry = {
                ...(action.response.result),
                kpis: [],
                esg_types: [],
                sdgs: [],
                standards: [],
                is_custom: true,
              };
              data = !action.response.body.parent_slug
                ? data.concat(entry)
                : appendLeafToTree(data)(
                  (node) => (
                    node.slug === action.response.body.parent_slug
                  ),
                  entry,
                );

            } else {
              data = action
                .response
                .result
                .map(c => ({
                  ...c,
                  kpis: [],
                  esg_types: [],
                  sdgs: [],
                  standards: [],
                  is_custom: true
                })).reduce((acc, newCategory) =>
                    !newCategory.parent
                      ? acc.concat(newCategory)
                      : appendLeafToTree(acc)(
                        (node) => (
                          node.slug === newCategory.parent
                        ),
                        newCategory,
                      )
                  , data)
            }
            break;
          case 'PUT':
            // Category updated
            data = mapOverTree(data)((node) => {
              if(node[ID_FIELD] === action.response.result[PREV_ID_FIELD]) {
                return {
                  ...node,
                  ...action.response.result,
                };
              }
              return node;
            })
            break;
          case 'DELETE':
            data = mapOverTreeFromLeafs(data)((node, children) => 
             { 
              if (node.slug === action.response.result[ID_FIELD]) return null
              return {
              ...node,
              children: children.filter(category => category.slug !== action.response.result[ID_FIELD])
            }});
            break;
          default:
        }
        return {
          data,
          tags: getTags(data),
          loading: false,
          error: null,
        };
      }

      if(action.response.target === KPI_TARGET) {
        if(action.response.result) {
          // Category updated
          entry = action.response.result;
          data = mapOverTree(data)((node) => {
            if(
              action.response.method === 'POST' &&
              node[ID_FIELD] === entry[CHILD_FIELD_FOR_PARENT]
            ) {
              const kpis = [
                ...(node.kpis || []),
                {
                  ...(
                    objectFilter([
                      'name',
                      'slug',
                      'tags',
                      'type',
                      'applies',
                      'kpi_value',
                      'standards',
                      'standards_info',
                      'previous_kpi_value',
                      'sdgs',
                    ])(entry)
                  ),
                  period: (entry.period || {}).label,
                  periods: (entry.all_periods || []),
                  status: 'pending',
                },
              ];
              return {
                ...node,
                applies: true,
                kpis,
              };
            }
            if(
              action.response.method === 'PUT' &&
              (node.kpis || []).find(kpi => kpi.slug === entry.slug)
            ) {
              const kpis = (node.kpis || []).map(
                kpi => {
                  if(kpi.slug === entry.slug && kpi.periods) {
                    return {
                      ...kpi,
                      periods: kpi.periods.map(
                        period => {
                          if(period.period === entry.period.label) {
                            return {
                              ...period,
                              kpi_value: entry.kpi_value,
                            };
                          }
                          return period
                        }
                      )
                    };
                  }
                  return kpi;
                }
              );
              return {
                ...node,
                kpis,
              };
            }
            return node;
          })
        }
        return {
          data,
          loading: false,
          error: null,
        };
      }

      if(action.response.target === APPROVAL_TARGET) {
        if(action.response.result && Array.isArray(action.response.result)) {
          const slugMap = new Map(
            action.response.result.map(kpi => [kpi.slug, kpi])
          );
          data = mapOverTree(data)((node) => {
            return {
              ...node,
              kpis: (node.kpis || []).map(
                kpi => {
                  if(slugMap.has(kpi.slug) && kpi.periods) {
                    entry = slugMap.get(kpi.slug)
                    return {
                      ...kpi,
                      periods: kpi.periods.map(
                        period => {
                          if(period.period === entry.period.label) {
                            return {
                              ...period,
                              ready_to_validate_level: entry.ready_to_validate_level,
                              validated_level: entry.validated_level,
                              rejection_count: entry.rejections?.length || 0,
                              rejection_comment: entry.rejections?.length
                                ? entry.rejections[0].comment
                                : null
                            };
                          }
                          return period
                        }
                      )
                    };
                  }
                  return kpi;
                }
              ),
            };
          });
        }
        return {
          data,
          loading: false,
          error: null,
        };
      }

      if(action.response.target === META_TARGET) {
        if(
          !action.response.result ||
          !action.response.result.kpi_slugs ||
          SKIP_META_TARGETS.includes(action.response.meta_target)
          // NOTICE: Prevent from reacting to META_TARGET response if this org was not affected
        ) {
          return {
            data,
            loading: false,
            error: null,
          };
        }
        const slugSet = new Set(action.response.result.kpi_slugs);

        const {
          add_tags,
          remove_tags
        } = action.response.result;

        if(add_tags || remove_tags) {
          // React to adding tags
          data = mapOverTreeFromLeafs(data)((node, children) => {
            const kpis = (node.kpis || []).map(kpi => {
              if(!slugSet.has(kpi.slug)) {
                return kpi;
              }
              let tags = [];

              if (add_tags) {
                tags = Array.from(new Set([
                  ...(kpi.tags || []),
                  ...(action.response.result.add_tags),
                ]));
              }
              if (remove_tags) {
                tags = tags.filter(
                  tag => !action.response.result.remove_tags.includes(tag)
                );
              }

              return {
                ...kpi,
                tags,
              }
            });

            return {
              ...node,
              kpis,
              tags: Array.from(
                new Set(
                  [
                    ...kpis,
                    ...(children || []),
                  ].map(
                    ({ tags }) => (tags || [])
                  ).reduce((arr, el) => arr.concat(el), [])
                )
              ),
              children,
            }
          });
          return {
            data,
            tags: getTags(data),
            loading: false,
            error: null,
          }
        }

        if(typeof action.response.result.applies !== 'undefined') {
          data = mapOverTreeFromLeafs(data)((node, children) => {
            const kpis = (node.kpis || []).map(kpi => {
              if(!slugSet.has(kpi.slug)) {
                return kpi;
              }
              return patchKpiStatus({
                ...kpi,
                applies: action.response.result.applies,
              })
            });
            return {
              ...node,
              applies: some(children, ['applies', true]) || some(kpis, ['applies', true]) ? true : false,
              kpis,
              children,
            }
          });
          return {
            data,
            tags: getTags(data),
            loading: false,
            error: null,
          }
        }
      }

      return {
        data,
        tags: getTags(data),
        loading: false,
        error: null,
      };
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target, action.request.method)) return state;
      return {
        ...state,
        loading: false,
        error: action.code,
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as data_categories_no_periods,
};
