import React, { useCallback, useEffect, useMemo } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import Form from './Form'

import {
  Row,
  Col,
} from 'antd';

import {
} from '../../actions/api';

import './style.less';
import EqualityPlanDocumentManager from 'components/EqualityPlanDocumentManager';
import useOrganizations from 'utils/useOrganizations';
import { dateToDb, formatDateOnly, DATE_ONLY } from 'utils/date';

const planStage = 'diagnosis';

const EqualityPlanDiagnosis = ({
  intl,
  history,
  plan,
  updatePlan,
  documents,
  fetchingDocuments,
  onFetchDocuments,
  onDownloadDocument,
  downloadingDocument,
  updatePlanProgress,
  measures,
  createMeasure,
  updateMeasure,
  deleteMeasure,
  actions,
  fetchActions,
}) => {
  const t = intl.messages;
  const {
    suborganization: organization,
  } = useOrganizations();

  useEffect(
    () => {
      fetchActions('general');
    },
    [fetchActions, plan]
  );

  useEffect(
    () => {
      if (
        plan.progress.includes(planStage)
        || plan.progress.includes(`${planStage}_form`)
      ) {
        history.push(`/equality-plan/${plan.id}/diagnosis/document-manager`)
      }
    },
    [plan, history]
  );

  const onUpdatePlanProgress = useCallback(
    () => {
      updatePlanProgress(planStage);
    },
    [updatePlanProgress]
  );

  const fetchDocuments = useCallback(
    () => {
      onFetchDocuments(planStage);
    },
    [onFetchDocuments]
  );

  const downloadDocument = useCallback(
    (format) => {
      onDownloadDocument(planStage, format);
    },
    [onDownloadDocument]
  );

  const onUpdatePlan = useCallback(
    (data) => {
      updatePlan({
        ...data,
        progress: [...new Set([...plan.progress, `${planStage}_form`])],
      });
    },
    [plan, updatePlan]
  );

  const formURLHelpText = useMemo(
    () => {
      const start_date = plan?.diagnosis_data?.start_date
        || organization.product_config.atlas.reporting_start_date;
      const end_date = plan?.diagnosis_data?.end_date || dateToDb(new Date());

      return `${t.equality_diagnosis_dates} ${formatDateOnly(start_date, DATE_ONLY, intl)} - ${formatDateOnly(end_date, DATE_ONLY, intl)}`;
    },
    [organization, plan, intl, t]
  )

  return (
    <Row>
      <Col span={24}>
        <Switch>
          <Route path={`/equality-plan/${plan.id}/diagnosis/document-manager`}>
            <EqualityPlanDocumentManager
              title={t.equality_diagnosis}
              planId={plan.id}
              helpText={t.equality_diagnosis_help_text}
              description={t.equality_diagnosis_desc}
              changeDataTitle={t.equality_diagnosis_change_data}
              documents={documents}
              stage={planStage}
              onFetchDocuments={fetchDocuments}
              fetchingDocuments={fetchingDocuments}
              onDownloadDocument={downloadDocument}
              downloadingDocument={downloadingDocument}
              formURL={`/equality-plan/${plan.id}/diagnosis`}
              formURLHelpText={formURLHelpText}
              updatePlanProgress={onUpdatePlanProgress}
              otherDocumentsGuidelines={t.equality_diagnosis_other_documents_guidelines}
            />
          </Route>
          <Route>
            <Form
              plan={plan}
              updatePlan={onUpdatePlan}
              actions={actions}
              measures={measures}
              createMeasure={createMeasure}
              updateMeasure={updateMeasure}
              deleteMeasure={deleteMeasure} />
          </Route>
        </Switch>
      </Col>
    </Row>
  );
};

export default withRouter(injectIntl(EqualityPlanDiagnosis));
