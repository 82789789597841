import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import A from 'components/A';
import {
  Menu,
  Dropdown,
  Tooltip
} from 'antd';

import { FILTERS_COMPONENT_TYPES_CATALOG, FILTER_TYPES } from 'hooks/useScreenFilters/constants';
import FiltersPanel from 'components/FiltersPanel';
import SearchFilter from 'components/SearchFilter';
import SdgMiniLogo from 'components/SdgMiniLogo';
import EsgLogo from 'components/EsgLogo';
import AplanetIcon from 'components/AplanetIcon';
import { ESG_TYPES } from 'utils/kpi';
import useOrganizations from 'utils/useOrganizations';

import './style.less';

const REPORTING_STANDARDS = [
  'gri',
  'gri-2021',
  'equality',
  'sdg',
  'sdgc',
  'bcorp',
  'einf',
  'euss',
  'ungc',
  'tefce',
  'prme',
  'sasb-hc-dy',
  'sasb-hc-di',
  'sasb-hc-dr',
  'sasb-hc-mc',
  'sasb-hc-ms',
  'sasb-hc-bp',
  'sasb-fn-ac',
  'sasb-fn-cb',
  'sasb-fn-cf',
  'sasb-fn-in',
  'sasb-fn-ib',
  'sasb-fn-mf',
  'sasb-fn-ex',
  'sasb-cg-aa',
  'sasb-cg-mr',
  'tcfd',
  'sasb-fb-fr',
  'sasb-if-re',
  'sfdr',
  'shift',
  'scority',
  'ghg',
  'neutrality',
  'custom',
];

const ConfigureTopActions = ({
  intl,
  setAllFilterState,
  filterState,
  resetAllFilters,
  areFiltersSet,
  isKpiNameSearchSet,
  kpiNameSearch,
  onChangeKpiSearch,
  setShowCategoryWizard,
  setShowIndicatorWizard,
  setShowVariabledConfiguration,
  hasContent,
  onChangeEnableOrderTree,
  enabledOrderTree = false,
}) => {

  const {
    organization,
    isMainOrganization,
    permissions
  } = useOrganizations();

  const {
    sdgs = [],
    tags = [],
  } = useSelector((state) => state.taxonomies);

  // Search
  const [hasToClearKpiSearch, setHasToClearKpiSearch] = useState(false);

  // Filters
  const [standardFilterValues, setStandardFilterValues] = useState(filterState.standard_array_filter);
  const [esgTypeFilterValues, setEsgTypeFilterValues] = useState(filterState.type_filter);
  const [sdgFilterValues, setSdgFilterValues] = useState(filterState.sdg_filter);
  const [tagsFilterValues, setTagsFilterValues] = useState(filterState.tag_filter);

  const reportingStandards = useMemo(() => {
    const enabled = new Set([
      ...((organization.config || {}).enabled_reports || []),
      'custom',
    ]);

    return REPORTING_STANDARDS
      .filter(slug => enabled.has(slug))
      .map(slug => ({
        value: slug,
        label: intl.formatMessage({ id: `reporting_standard_${slug}` })
      }));
  }, [
    organization,
    intl,
  ]);

  const esgTypes = useMemo(() => {
    return ESG_TYPES.map(slug => ({
      value: slug,
      title: intl.formatMessage({ id: `esg_type_${slug}` }),
      label: (
        <React.Fragment key={slug}>
          <EsgLogo key={slug} type={slug} />
          <span>{intl.formatMessage({ id: `esg_type_${slug}` })}</span>
        </React.Fragment>)
    }));
  }, [
    intl,
  ]);

  const patchedSdgs = useMemo(() => {
    return sdgs.map(({ slug, code }) => ({
      value: slug,
      code,
      parent_slug: slug,
      title: intl.formatMessage({ id: `sdg_${slug}` }),
      label: (
        <React.Fragment key={slug}>
          <SdgMiniLogo
            sdg={{
              slug,
              code,
              parent_slug: slug,
            }}
          />
          <span>{intl.formatMessage({ id: `sdg_${slug}` })}</span>
        </React.Fragment>
      )
    }));
  }, [sdgs, intl]);

  const patchedTags = useMemo(() => {
    return tags.map(tag => ({
      value: tag,
      label: tag,
    }));
  }, [
    tags,
  ]);

  const filtersConfig = useMemo(() => (
    [
      {
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.list,
            filterTitle: intl.formatMessage({ id: `filter_list_standard` }),
            listOptions: reportingStandards,
            setSelectedOptions: setStandardFilterValues,
            selectedOptions: standardFilterValues,
          },
        ]
      },
      {
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.list,
            filterTitle: intl.formatMessage({ id: `filter_list_esgType` }),
            listOptions: esgTypes,
            setSelectedOptions: setEsgTypeFilterValues,
            selectedOptions: esgTypeFilterValues,
          },
        ]
      },
      {
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.sdg,
            filterTitle: intl.formatMessage({ id: `filter_list_sdg` }),
            listOptions: patchedSdgs,
            setSelectedOptions: setSdgFilterValues,
            selectedOptions: sdgFilterValues,
          },
        ]
      },
      {
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.tags,
            filterTitle: intl.formatMessage({ id: `filter_list_tags` }),
            listOptions: patchedTags,
            setSelectedOptions: setTagsFilterValues,
            selectedOptions: tagsFilterValues,
          },
        ]
      },
    ]
  ), [esgTypeFilterValues, esgTypes, intl, patchedSdgs, patchedTags, reportingStandards, sdgFilterValues, standardFilterValues, tagsFilterValues]);

  const onSetFilters = useCallback(() => {
    setAllFilterState({
      [FILTER_TYPES.standard_array_filter]: standardFilterValues,
      [FILTER_TYPES.type_filter]: esgTypeFilterValues,
      [FILTER_TYPES.sdg_filter]: sdgFilterValues,
      [FILTER_TYPES.tag_filter]: tagsFilterValues,
      [FILTER_TYPES.text_filter]: kpiNameSearch,
    });
  }, [
    setAllFilterState,
    standardFilterValues,
    esgTypeFilterValues,
    sdgFilterValues,
    tagsFilterValues,
    kpiNameSearch
  ]);

  const onCancelFilter = useCallback(() => {
    setStandardFilterValues(filterState.standard_array_filter);
    setEsgTypeFilterValues(filterState.type_filter);
    setSdgFilterValues(filterState.sdg_filter);
    setTagsFilterValues(filterState.tag_filter);
  }, [
    filterState.sdg_filter,
    filterState.standard_array_filter,
    filterState.tag_filter,
    filterState.type_filter,
  ]);

  const onClearSearchAndFilters = useCallback(() => {
    setStandardFilterValues([]);
    setEsgTypeFilterValues([]);
    setSdgFilterValues([]);
    setTagsFilterValues([]);
    setHasToClearKpiSearch(true);
    resetAllFilters();
  }, [
    resetAllFilters,
  ]);

  const onToggleEnableOrderTree = useCallback(() => {
    if (!enabledOrderTree) {
      onClearSearchAndFilters();
    }
    onChangeEnableOrderTree(!enabledOrderTree);
  }, [
    enabledOrderTree,
    onChangeEnableOrderTree,
    onClearSearchAndFilters
  ]);

  const isCreateNewDisabled = enabledOrderTree || !isMainOrganization || !permissions.can_configure_kpi || (organization.config.features.restricted_admins && !permissions.can_affect_all_kpis)
  const NewButton = hasContent 
    ? <Dropdown
        className='ConfigureTopActions__new-button'
        overlay={(
          <Menu onClick={({ key }) => {
            if (key === 'category') setShowCategoryWizard(true);
            if (key === 'indicator') setShowIndicatorWizard(true);
          }}
          >
            <Menu.Item key="category">{intl.formatMessage({ id: 'category' })}</Menu.Item>
            <Menu.Item key="indicator">{intl.formatMessage({ id: 'indicator' })}</Menu.Item>
          </Menu>
        )}
        placement="bottomLeft"
        trigger={['click']}
        disabled={isCreateNewDisabled}
      >
        <button style={{ pointerEvents: !isMainOrganization ? 'none' : 'auto' }}>
          <AplanetIcon name='Add' size="14px" />
          {intl.formatMessage({ id: 'new' })}
        </button>
      </Dropdown>
    : <button 
        style={{ pointerEvents: !isMainOrganization ? 'none' : 'auto' }} 
        onClick={() => setShowCategoryWizard(true)} 
        className='ConfigureTopActions__new-button' 
        disabled={isCreateNewDisabled} 
      >
        <AplanetIcon name='Add' size="14px" />
        {intl.formatMessage({ id: 'new' })}
      </button>

  return (
    <div className="ConfigureTopActions">
      <div className="ConfigureTopActions__new-and-filters">
        <Tooltip title={!isMainOrganization ? intl.formatMessage({ id: 'only_available_at_parent' }) : ""}>
          <div style={{ cursor: !isMainOrganization ? 'not-allowed' : 'pointer' }}>
            {NewButton}
          </div>
        </Tooltip>
        <FiltersPanel
          areFiltersSet={areFiltersSet}
          onCancelFilter={onCancelFilter}
          onSetFilters={onSetFilters}
          filtersConfig={filtersConfig}
          disabled={enabledOrderTree}
        />
        <SearchFilter
          textFilter={kpiNameSearch}
          onSetSearch={onChangeKpiSearch}
          hasToClearSearch={hasToClearKpiSearch}
          setHasToClearKpiSearch={setHasToClearKpiSearch}
          defaultOpen={isKpiNameSearchSet}
          placeholder={intl.formatMessage({ id: `configure_search_placeholder` })}
          disabled={enabledOrderTree}
          autoFocus
        />
        {!permissions.can_configure_kpi || (organization.config.features.restricted_admins && !permissions.can_affect_all_kpis) ? null : (
          <button
            className={`ConfigureTopActions__order-tree-button ${enabledOrderTree ? 'is-set' : ''}`}
            onClick={onToggleEnableOrderTree}
            disabled={!isMainOrganization}
          >
            <AplanetIcon name="Drag & drop" size="18px" />
            <span>{intl.formatMessage({ id: `configure_order_tree_button_${enabledOrderTree ? 'enabled' : 'disabled'}`})}</span>
          </button>
        )}
        <div className='ConfigureTopActions__remove-filters-wrapper'>
          <A
            className='ConfigureTopActions__remove-filters'
            onClick={onClearSearchAndFilters}
            disabled={(!areFiltersSet && !isKpiNameSearchSet) || enabledOrderTree}
          >
            {intl.formatMessage({ id: 'list_filter_delete_all' })}
          </A>
        </div>
      </div>
      <div className="ConfigureTopActions__variables">
        <button
          className='ConfigureTopActions__variables-button'
          onClick={() => setShowVariabledConfiguration(true)}
          disabled={enabledOrderTree}
        >
          <AplanetIcon name='Settings' size="18px" />
          {intl.formatMessage({ id: 'datamanagement_config_variables_configuration' })}
        </button>
      </div>
    </div>
  );
}

ConfigureTopActions.propTypes = {
  setAllFilterState: PropTypes.func.isRequired,
  filterState: PropTypes.object.isRequired,
  resetAllFilters: PropTypes.func.isRequired,
  areFiltersSet: PropTypes.bool,
  isKpiNameSearchSet: PropTypes.bool,
  kpiNameSearch: PropTypes.string,
  onChangeKpiSearch: PropTypes.func.isRequired,
  setShowCategoryWizard: PropTypes.func.isRequired,
  setShowIndicatorWizard: PropTypes.func.isRequired,
  setShowVariabledConfiguration: PropTypes.func.isRequired,
  onChangeEnableOrderTree: PropTypes.func.isRequired,
  enabledOrderTree: PropTypes.bool,
};

export default injectIntl(ConfigureTopActions);
