import React, {
  useCallback,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import useOrganizations from 'utils/useOrganizations';

import {
  Modal,
} from 'antd';

import {
  deleteKpiAttachment,
} from 'actions/api';


const DeleteAttachmentLink = ({
  intl,
  kpi,
  attachment,
  canEdit,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();

  const {
    organization,
  } = useOrganizations();

  const handleOnDelete = useCallback(
    (e, attachment) => {
      e.preventDefault();
      if(!canEdit) {
        return;
      }
      Modal.confirm({
        title: t.datamanagement_delete_attachment_modal_title,
        content: t.datamanagement_delete_attachment_modal_description,
        okText: t.datamanagement_delete_attachment_modal_ok,
        cancelText: t.datamanagement_delete_attachment_modal_cancel,
        onOk() {
          dispatch(
            deleteKpiAttachment(
              organization.slug,
              kpi.organization_slug,
              kpi.slug,
              kpi.period,
              attachment.filename,
            )
          );
        },
      })
    },
    [
      t,
      dispatch,
      organization,
      canEdit,
      kpi,
    ]
  );

  if(!canEdit) {
    return '';
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href=""
      className="BulkManage_Attachments__popover-delete"
      onClick={e => handleOnDelete(e, attachment)}
    >
      { t.delete }
    </a>
  );
};


export default DeleteAttachmentLink;
