import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import * as Sentry from '@sentry/react';

import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';

import MainLayout from 'components/MainLayout'

import {
  useSelector,
  useDispatch
} from 'react-redux';

import {
  changeKpiCategorySlug
} from 'actions/api';

import useOrganizations from 'utils/useOrganizations';

import Configure from './tabs/Configure';
import Files from './tabs/Files';
import History from './tabs/History';
import Upload from './tabs/Upload';
import DataSources from './tabs/DataSources';
import DataRequests from './tabs/DataRequests';
import FillAndReport from './tabs/FillAndReport';

import CreateOrEditCategory from 'containers/ModalCreateOrEditCategory';
import DataRequestsWizardScreen from 'containers/DataRequests';
import ModalDataAnswer from 'components/ModalDataAnswer';
import { useEventTracking } from 'hooks/useEventTracking';

import './style.less';

const ROOT_CATEGORY = '/';
const DEFAULT_TAB = 'fill'
const DEFAULT_STANDARD = 'aplanet';
const EVENT_TRACKING_TABS = {
  'manage': 'configure',
  'fill': 'fill_report',
  'upload': 'data_files',
  'requests': 'requests',
  'files': 'attachments',
  'history': 'history',
  'bulk_manage': 'bulk manage',
}

const DataManagement = ({
  intl,
  history,
  match,
}) => {
  const dispatch = useDispatch();
  const eventTracking = useEventTracking();

  const {
    suborganization,
    permissions,
  } = useOrganizations();

  const { config = {} } = suborganization;
  const { new_atlas_enabled = {} } = suborganization?.product_config?.atlas || {};
  const {
    data_request = false,
  } = new_atlas_enabled;

  const {
    loading,
    error,
  } = useSelector(state => state.data_categories);

  const {
    data: category,
  } = useSelector(state => state.category_detail);

  // Tab active
  const activeTab = match.params.activeTab || DEFAULT_TAB;
  const activeStandard = match.params.category || DEFAULT_STANDARD; // NOTICE: We share manage.category and list.standard

  const tabEventTracking = useCallback((tab) => {
    eventTracking.capture('dataManagement.tabAccess', {
      tab_name: Object.keys(EVENT_TRACKING_TABS).includes(tab) ? EVENT_TRACKING_TABS[tab] : tab
    });
  }, [eventTracking]);

  useEffect(() => {
    tabEventTracking(DEFAULT_TAB);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create custom category modal visibility ( null / parent_category_slug / object )
  const closeCategoryModal = useCallback(() => {
    dispatch(
      changeKpiCategorySlug(null)
    )
  }, [dispatch]);

  // Modal for answer details
  const [ modalAnswer, setModalAnswer ] = useState(null);
  const handleModalAnswerOpen = useCallback((answer) => {
    setModalAnswer(answer);
  }, []);
  const handleModalAnswerClose = useCallback(() => setModalAnswer(null), []);

  const [loadingCategory, setLoadingCategory] = useState(false);
  const [showDataRequests, setShowDataRequests] = useState(false);
  const [checkedKpis, setCheckedKpis] = useState([]);

  const onCloseDataRequests = useCallback(() => {
    setShowDataRequests(false);
  }, []);

  const onCheckKpis = useCallback((kpisSelected) => {
    setCheckedKpis(kpisSelected);
  }, []);

  return (
      <MainLayout
        className="DataManagementLayout"
        disabledOrganizationTreeSelector={data_request && showDataRequests}
      >
        <Sentry.ErrorBoundary
          fallback={
          <ErrorBoundaryFallback
            titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
            buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
            descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_data_management_message' })}
            customErrorImage="/images/error_image.png"
          />
        }>
        { data_request && showDataRequests
          ? <DataRequestsWizardScreen
              onCloseDataRequests={onCloseDataRequests}
              checkedKpisUuids={checkedKpis}
            />
          : <>
              {(activeTab === 'manage' || activeTab === 'search') && <Configure/>}
              {(activeTab === 'sources') && <DataSources />}
              {(activeTab === 'fill' || activeTab === 'all') &&
                <FillAndReport
                  history={history}
                  match={match}
                  onOpenAnswer={handleModalAnswerOpen}
                  standard={activeStandard}
                  onShowDataRequests={() => setShowDataRequests(true)}
                  onCheckKpis={onCheckKpis}
                />
              }
              { (activeTab === 'files') &&
                <Files
                  history={history}
                  match={match}
                />
              }
              {(activeTab === 'history') && <History onOpenAnswer={handleModalAnswerOpen} />}
              {(activeTab === 'upload') && <Upload />}
              {(activeTab === 'requests') && <div className="DataManagement--requests"><DataRequests /></div>}
              <CreateOrEditCategory
                shown={!!category}
                category={typeof category === 'object' && category}
                parentCategorySlug={
                  (
                    typeof category === 'string' &&
                    category !== ROOT_CATEGORY &&
                    category
                  ) || undefined
                }
                onClose={closeCategoryModal}
                permissions={permissions}
                loading={loading}
                error={error}
                loadingCategory={loadingCategory}
                setLoadingCategory={setLoadingCategory}
              />
              <ModalDataAnswer
                {...modalAnswer || {}}
                config={config}
                shown={!!modalAnswer}
                onClose={handleModalAnswerClose}
                showUseButtons={false}
                loading={loading}
                error={error}
              />
            </>
          }
        </Sentry.ErrorBoundary>
      </MainLayout>
)}

export default injectIntl(
  withRouter(
    DataManagement
  )
);
