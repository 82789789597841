import React, { useCallback, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { changeKpiPermissions } from 'actions/api';
import UpdateValidationLevels from 'components/DataSources/UpdateValidationLevels';
import { backendLevelToLevel } from 'utils/validation';
import { levelToBackendLevel } from 'utils/kpi_validation';

import {
  Col,
  Popover,
  Row,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';


const DataSourcesPermissionValidationLevels = ({
  intl,
  organization_slug,
  suborganization_slug,
  kpi_slug,
  member,
  approvalLevelOptions,
}) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const permissions = useMemo(
    () => member.permissions || member.org_permissions,
    [member]
  );

  const updateValidationLevel = useCallback(
    (approvalLevel) => {
      let enabledPermissions = Object.keys(permissions)
        .filter(permission => !!permissions[permission]);

      const levels = {
        validate_kpi_level: approvalLevel
          .map(v => levelToBackendLevel(v))
          .reduce((a, b) => a | b, 0),
      };
      dispatch(
        changeKpiPermissions('data_sources_kpi_permissions')(
          organization_slug,
          suborganization_slug,
          kpi_slug,
          member.member_slug,
          enabledPermissions,
          levels,
        )
      );
      setEdit(false);
    },
    [
      dispatch,
      organization_slug,
      suborganization_slug,
      kpi_slug,
      member,
      permissions,
    ],
  );

  if (!permissions.can_validate_kpi) {
    return '-';
  }

  return (
    <Row
      type="flex"
      align="middle"
      className="DataSourcesPermissionValidationLevels"
    >
      <Col>
        { permissions.validate_kpi_level
          ? backendLevelToLevel(permissions.validate_kpi_level).join(', ')
          : '-'
        }
      </Col>
      <Col>
        <Popover
          placement="bottom"
          content={
            <UpdateValidationLevels
              approvalLevelOptions={approvalLevelOptions}
              permissions={permissions}
              updateValidationLevel={updateValidationLevel}
            />
          }
          title={intl.formatMessage({id: "level_of_approval"})}
          trigger="click"
          visible={edit}
          onVisibleChange={visible => setEdit(visible)}
        >
          <CaretDownOutlined
            className="DataSourcesPermissionValidationLevels__dropdown"
          />
        </Popover>
      </Col>
    </Row>
  );
}

export default injectIntl(DataSourcesPermissionValidationLevels);
