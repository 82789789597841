import React, { useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
//import { difference } from 'lodash';
import moment from 'moment';
import useForm from 'utils/useForm';
import useOrganizations from 'utils/useOrganizations';
//import { useCycleDateDisabledDate } from 'utils/collectionFrequency';
import CustomCollapse from 'components/CustomCollapse';
//import CustomDatepicker } from 'components/CustomDatepicker';
//import PeriodicitiesSelector from 'components/PeriodicitiesSelector';
import AplanetIcon from 'components/AplanetIcon';
import { editBulkKpi } from 'actions/api';
import { formatMessage } from '../../../intl';
import validation from './validation';
import './style.less';

function ActionsForCurrentOrg({
  checkedCategories,
  checkedKpis,
  checkedKPIsSlugs,
  allPeriodicities,
  commonPeriodicities,
  allAnnualStartDate,
  intl,
}) {
  const dispatch = useDispatch();

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const defaultValues = useMemo(() => ({
    annual_start_date: allAnnualStartDate.length === 1 ? allAnnualStartDate[0] : null,
    apply_all_children: false,
  }), [allAnnualStartDate]);

  const defaultSetValues = useMemo(() => ({
    apply_periodicities: commonPeriodicities,
    not_apply_periodicities: [],
  }), [commonPeriodicities]);

  const validateForm = useMemo(() => validation(intl), [intl]);
  const {
    values,
    //handleChange,
    handleSubmit,
    //errors,
    resetForm,
    dirtyFields,
  } = useForm({
    callback: (_, newValues) => {
      dispatch(editBulkKpi({ organization_slug: organization.slug, suborganization_slug: suborganization.slug, kpi_slugs: checkedKPIsSlugs }, newValues.esg_type ? { ...newValues, esg_type: newValues.esg_type[0] } : newValues));
    },
    validate: validateForm,
    defaultValues,
    defaultSetValues,
    validationDefaults: { locale: suborganization.language },
    setNames: [
      'apply_periodicities',
      'not_apply_periodicities',
    ],
  });
  //const handlePeriodicitiesChange = useCallback(
  //  (slug, checked) => {
  //    if (checked) {
  //      handleChange('apply_periodicities')(slug);
  //      if (values.not_apply_periodicities.includes(slug)) handleChange('not_apply_periodicities')(slug);
  //    } else {
  //      handleChange('not_apply_periodicities')(slug);
  //      if (values.apply_periodicities.includes(slug)) handleChange('apply_periodicities')(slug);
  //    }
  //  },
  //  [handleChange, values.apply_periodicities, values.not_apply_periodicities],
  //);

  //const handleDateChange = useCallback((value) => {
  //  handleChange('annual_start_date')(value.format('YYYY-MM-DD'));
  //}, [handleChange]);

  //const halfCheckedPeriodicities = useMemo(
  //  () => difference(allPeriodicities, [...values.apply_periodicities, ...values.not_apply_periodicities]),
  //  [allPeriodicities, values.apply_periodicities, values.not_apply_periodicities],
  //);

  //const disabledDate = useCycleDateDisabledDate(values.apply_periodicities);
  const dateValue = useMemo(() => values.annual_start_date ? moment(values.annual_start_date) : null, [values.annual_start_date]);

    const PanelHeader = useCallback(() => (
    <div className="ActionsOverCheckedItems-header-title">
      <span>
        {formatMessage('actions_over_current_orgs_title')}
      </span>
      <span>
        {formatMessage('KpiTreeActionsSection_actions_info_subtitle', { checkedCategoriesNumber: checkedCategories.length, checkedKpisNumber: checkedKpis.length })}
      </span>
    </div>
  ),
    [checkedCategories.length, checkedKpis.length]
  )

  return (
    <CustomCollapse
      className="ActionsOverCheckedItems"
      header={<PanelHeader/>}
      //tooltip={{ title: formatMessage('actions_over_current_orgs_tooltip') }}
    >
      <div className="ActionsOverCheckedItems__body">
        {/*<p className="warning-message">{formatMessage('actions_over_current_orgs_warning')}</p>
        <div className="sync-message">
          <Checkbox
            checked={values.apply_all_children}
            onChange={(e) => handleChange('apply_all_children')(e.target.checked)}
          />
          <p>{formatMessage('actions_over_current_orgs_sync')}</p>
        </div>*/}
        <CustomCollapse className="ValidPeriod" header={formatMessage('actions_over_current_orgs_date_title')}>
          <p className="instructions-message">{formatMessage('actions_over_current_orgs_instructions_date')}</p>
          <b>{allAnnualStartDate.length > 1 ? formatMessage('multiple_dates') : dateValue.format('YYYY-MM-DD')}</b>
          {/*
            <CustomDatepicker
              disabledDate={disabledDate}
              value={dateValue}
              onChange={handleDateChange}
              allowClear={false}
              placeholder={allAnnualStartDate.length > 1 ? formatMessage('multiple_dates') : formatMessage('select_date')}
              disabled
            />
           */}
        </CustomCollapse>
        {/*
          <CustomCollapse className="Periodicities" header={formatMessage('periodicity')}>
            <PeriodicitiesSelector
              value={{ checked: values.apply_periodicities, halfChecked: halfCheckedPeriodicities }}
              onChange={handlePeriodicitiesChange}
              error={errors.apply_periodicities}
              disabled
            />
          </CustomCollapse>
        */}
        {dirtyFields.length
          ? (
            <div className="buttons-container">
              {dirtyFields.length
                ? <p className="buttons-container__unsaved-message">{formatMessage('changes_not_saved')}</p>
                : ''}
              <button className="buttons-container__cancel" onClick={resetForm}>
                <AplanetIcon name="Cancel" />
                {formatMessage('cancel')}
              </button>
              <button className="buttons-container__apply" onClick={handleSubmit}>
                <AplanetIcon name="Save" faStyle="fad" />
                {formatMessage('save_changes')}
              </button>
            </div>
          )
          : ''}
      </div>
    </CustomCollapse>
  );
}

export default injectIntl(ActionsForCurrentOrg);
