import React, {
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import { formatShortWithYear } from 'utils/date';

import T from 'components/T';
import CustomButton from 'components/CustomButton';

import { ReactComponent as CheckmarkIcon } from 'assets/checkmark.svg';
import { ReactComponent as UndoIcon } from 'assets/undo.svg';

const ReadyToValidate = ({
  intl,
  canApprove,
  onStart,
  levels,
  loading,
  lastUpdate,
  lastRejection,
  kpiStatus,
  mandatory_data,
  org_mandatory_data,
  attachments = [],
  comment,
  source,
}) => {

  const t = intl.messages;

  const condition = useMemo(() => {
    return {
      attachment: !!attachments.length,
      value: kpiStatus === 'uptodate',
      comment: comment !== '' || source !== 'manual',
    };
  }, [
    source,
    attachments,
    comment,
    kpiStatus,
  ])

  const validationEnabled = useMemo(() => {
    if (source === 'children') {
      // NOTICE: This is a bit sad. We do not validate kpis with aggregation = 'children'
      //         because they do not really have a value.
      // TODO: Consider conditions for validating other aggregated kpis
      return true;
    }

    return Object.keys(org_mandatory_data || {}).reduce((acc, key) => {
      acc = org_mandatory_data[key] && mandatory_data[key] ? condition[key] && acc : acc;
      return acc;
    }, true);
  }, [
    source,
    condition,
    mandatory_data,
    org_mandatory_data,
  ]);

  const showRejection = useMemo(() => {
    if (!lastRejection) {
      return false
    }
    if (!lastUpdate) {
      return true
    }
    return lastRejection.created_at > lastUpdate.updated_at
  }, [
    lastRejection,
    lastUpdate,
  ]);


  return (
    <div className="KpiDetail__validation-banner">
      {
        !showRejection
          ? null
          : (
            <div
              className="KpiDetail__validation-rejectionreason"
            >
              <div>
                <UndoIcon />
                {' '}
                {
                  lastRejection.implicit && lastRejection.originating_kpi_value.organization_name
                    ? (
                      <T
                        validation_lastrejected_message_implicit={{
                          name: lastRejection.name,
                          org: lastRejection.originating_kpi_value.organization_name,
                          date: formatShortWithYear(lastRejection.created_at, intl),
                        }}
                      />
                    ) : (
                      <T
                        validation_lastrejected_message={{
                          name: lastRejection.name,
                          date: formatShortWithYear(lastRejection.created_at, intl),
                        }}
                      />
                    )
                }
              </div>
              <div
                className="KpiDetail__validation-rejectionreason-comment"
              >{lastRejection.comment}</div>
            </div>
          )
      }
      {
        !!Object.keys(org_mandatory_data || {}).length &&
        <>
          <div className="KpiDetail__validation-startmessage">
            {validationEnabled
              ? (
                <T
                  validation_start_message={{
                    allowed: canApprove,
                    levels,
                  }}
                />
              ) : (
                t.validation_start_message_info
              )
            }
          </div>
          <ul className="KpiDetail__flex-column">
            {
              Object.keys(org_mandatory_data || {}).map(key => {
                return org_mandatory_data[key] && mandatory_data[key] &&
                  <li key={key}>
                    {t[`mandatory_${key}`]} {condition[key] && <CheckmarkIcon />}
                  </li>

              })
            }
          </ul>
        </>
      }
      {
        !canApprove ? null :
          <CustomButton
            className="KpiDetail__validation-startbutton"
            onClick={onStart}
            disabled={loading || !validationEnabled}
          >
            <CheckmarkIcon />
            <span>
              {
                !lastRejection
                  ? t.validation_start
                  : t.validation_start_rejected
              }
            </span>
          </CustomButton>
      }
    </div>
  );
};

export default injectIntl(ReadyToValidate);
