import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ShowTable from 'components/TableAnswer/Show';
import useOrganizations from 'utils/useOrganizations';
import {
  dimensionsHaveCalculation,
  changeCalculationOnDimensions,
  canAddCalculationToDimensions,
} from 'components/CreateEditCustomKPI/forms/Table/utils';

import CustomCheckbox from 'components/CustomCheckbox';
import {
  Col,
  Row,
} from 'antd';


const Overview = ({
  intl,
  mode = 'create',
  values,
  onChange,
}) => {
  const t = intl.messages;

  const {
    suborganization,
  } = useOrganizations();
  const { config = {} } = suborganization;

  const {
    kpi_variables = [],
  } = useSelector(state => state.taxonomies);

  const handleChangeCalculation = useCallback(
    (e, calculation, target = 'row') => {
      let updatedDimensions = changeCalculationOnDimensions(
        values.dimensions, calculation, target,
        e.target.checked ? 'add' : 'remove',
      );

      if (
        e.target.checked
        && calculation === 'percentage'
        && dimensionsHaveCalculation(
          updatedDimensions,
          calculation,
          target === 'row' ? 'column' : 'row'
        )
      ) {
        // Toggle percentage for the other target
        // There can be percentage in either rows or columns
        updatedDimensions = changeCalculationOnDimensions(
          updatedDimensions, calculation, target === 'row' ? 'column' : 'row',
          'remove',
        );
      }

      onChange('dimensions')(updatedDimensions);
    },
    [values, onChange]
  );

  const labeledSchemaAndLabels = useMemo(
    () => {
      let innerSchema = {
        ...values.innerSchema,
      };
      let schemaLabels = {
        ...(values.schemaLabels || {})
      };

      const dimensions = values.dimensions.map(
        dimension => {
          let updatedDimension = {...dimension};
          if (updatedDimension.source === 'user' && updatedDimension.byName) {
            updatedDimension.by = updatedDimension.byName;
          } else if (updatedDimension.source === 'standard') {
            if (updatedDimension.byName) {
              updatedDimension.by = updatedDimension.byName;
            }
            updatedDimension.standardItems = updatedDimension.standardItems.map(
              option => ({
                slug: option.slug || option.name,
                name: option.name,
              })
            );
          } else if (updatedDimension.source === 'organization') {
            if (!schemaLabels.dimensionNames?.[updatedDimension.by]) {
              schemaLabels.dimensionNames = {
                ...(schemaLabels.dimensionNames),
                [updatedDimension.by]: kpi_variables[updatedDimension.by]?.name
                  || updatedDimension.by,
              };
            }
            if (!schemaLabels.dimensionValues?.[updatedDimension.by]) {

              schemaLabels.dimensionValues = {
                ...(schemaLabels.dimensionValues),
                [updatedDimension.by]: { ...kpi_variables[updatedDimension.by]?.options },
              };
              
            }
          } else if (updatedDimension.source === 'singleton') {
            if (updatedDimension.byName) {
              updatedDimension.by = updatedDimension.byName;
            } else if (
              innerSchema.type === 'choice'
              && innerSchema.options.source === 'organization'
            ) {
              updatedDimension.by = kpi_variables[innerSchema.options.by]?.name || updatedDimension.by;
            }
          }
          return updatedDimension;
        }
      );

      if (innerSchema.type === 'tuple') {
        innerSchema.components = innerSchema.components.map(
          component => {
            let updatedColumn = {
              ...component,
            };

            if (updatedColumn.componentName) {
              updatedColumn.name = updatedColumn.componentName;
            } else if (
              updatedColumn.type === 'choice'
              && updatedColumn.options.source === 'organization'
            ) {
              updatedColumn.name = kpi_variables[updatedColumn.options.by]?.name || updatedColumn.name;
            }

            if (updatedColumn.type === 'choice') {
              updatedColumn.options = {
                ...updatedColumn.options,
                standardItems: updatedColumn.options.standardItems.map(
                  option => ({
                    slug: option.name || option.slug,
                    name: option.name,
                  })
                ),
              }
            }
            return updatedColumn;
          }
        );
      }

      return {
        schema: {
          type: 'table',
          dimensions,
          innerSchema,
        },
        schemaLabels,
      };
    },
    [values, kpi_variables]
  );

  const calculationsCheckedStatus = useMemo(
    () => ({
      count: dimensionsHaveCalculation(values.dimensions, 'count', 'row'),
      total_row: dimensionsHaveCalculation(values.dimensions, 'total', 'row'),
      total_column: dimensionsHaveCalculation(values.dimensions, 'total', 'column'),
      percentage_row: dimensionsHaveCalculation(values.dimensions, 'percentage', 'row'),
      percentage_column: dimensionsHaveCalculation(values.dimensions, 'percentage', 'column'),
    }),
    [values]
  );

  const calculationsDisabledStatus = useMemo(
    () => ({
      count: !canAddCalculationToDimensions(values.dimensions, 'count', 'row'),
      total_row: !canAddCalculationToDimensions(values.dimensions, 'total', 'row'),
      total_column: !canAddCalculationToDimensions(values.dimensions, 'total', 'column'),
      percentage_row: !canAddCalculationToDimensions(values.dimensions, 'percentage', 'row'),
      percentage_column: !canAddCalculationToDimensions(values.dimensions, 'percentage', 'column'),
    }),
    [values]
  );

  const isExtensibleTable = useMemo(
    () => values?.dimensions?.[0]?.source === 'user',
    [ values ]
  );

  return (
    <div className="CreateCustomKpi-table-columns ModalEditKPI-table-columns">
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <span><b>{t.createcustomkpi_table_tab_overview_calculations}</b></span>
        </Col>
        { isExtensibleTable &&
          <Col span={24}>
            <CustomCheckbox
              checked={calculationsCheckedStatus.count}
              onChange={(e) => handleChangeCalculation(e, 'count', 'row')}
              disabled={calculationsDisabledStatus.count && !calculationsCheckedStatus.count}
            >
              {t.createcustomkpi_table_tab_overview_calculations_count}
            </CustomCheckbox>
          </Col>
        }
        <Col span={24}>
          <CustomCheckbox
            checked={calculationsCheckedStatus.total_row}
            onChange={(e) => handleChangeCalculation(e, 'total', 'row')}
            disabled={calculationsDisabledStatus.total_row && !calculationsCheckedStatus.total_row}
          >
            {t.createcustomkpi_table_tab_overview_calculations_total_row}
          </CustomCheckbox>
        </Col>
        <Col span={24}>
          <CustomCheckbox
            checked={calculationsCheckedStatus.total_column}
            onChange={(e) => handleChangeCalculation(e, 'total', 'column')}
            disabled={calculationsDisabledStatus.total_column && !calculationsCheckedStatus.total_column}
          >
            {t.createcustomkpi_table_tab_overview_calculations_total_column}
          </CustomCheckbox>
        </Col>
        <Col span={24}>
          <CustomCheckbox
            checked={calculationsCheckedStatus.percentage_row}
            onChange={(e) => handleChangeCalculation(e, 'percentage', 'row')}
            disabled={calculationsDisabledStatus.percentage_row && !calculationsCheckedStatus.percentage_row}
          >
            {t.createcustomkpi_table_tab_overview_calculations_percentage_row}
          </CustomCheckbox>
        </Col>
        <Col span={24}>
          <CustomCheckbox
            checked={calculationsCheckedStatus.percentage_column}
            onChange={(e) => handleChangeCalculation(e, 'percentage', 'column')}
            disabled={calculationsDisabledStatus.percentage_column && !calculationsCheckedStatus.percentage_column}
          >
            {t.createcustomkpi_table_tab_overview_calculations_percentage_column}
          </CustomCheckbox>
        </Col>
        <Col span={24}>
          <ShowTable.Component
            {...labeledSchemaAndLabels}
            config={config}
          />
        </Col>
      </Row>
    </div>
  );
};

export default injectIntl(Overview);
