import React, { useCallback } from 'react';
import { injectIntl} from 'react-intl';

import {
  Form,
  Modal,
  Input
} from 'antd';

import './style.less';

const AddActiveListeningForm = ({
  intl,
  visible,
  setVisibility,
  addMedium,
}) => {
  const t = intl.messages;
  const [form] = Form.useForm();
  const { validateFields } = form;

  const onAdd = useCallback(
    () => {
      validateFields().then(() => {
        const {name, url} = form.getFieldsValue();
        addMedium({name, url});
        form.setFieldsValue({name: '', url: ''});
        setVisibility(false);
      });
    },
    [validateFields, form, addMedium, setVisibility]
  );

  return (
    <Modal
      title={t.add_active_listening_medium}
      visible={visible}
      okText={t.add}
      onOk={onAdd}
      onCancel={() => setVisibility(false)}
    >
      <Form form={form}>
        <Form.Item
          label={t.name}
          name='name'
          rules={[{
            required: true,
            message: t.input_medium_name,
          }]}
        >
          <Input placeholder={ t.medium_name } />
        </Form.Item>
        <Form.Item
          label="URL"
          name='url'
        >
          <Input placeholder="URL" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default injectIntl(AddActiveListeningForm);
