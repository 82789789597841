import {
  validateSchema,
} from 'utils/kpi_schema';

const fullValidation = (intl) => ({
  name_translations,
  esg_type,
  guidelines,
  collection_frequency,
  cycle_date,
  first_reported_at,
  sdgs = [],
  locale,
  code,
  gri_enabled = false,
  equality_enabled = false,
  gri = [],
  equality = [],
  isNew = false,
  periodicities,
  kpi_associations = {},
  ...schema
}) => {
  let errors = {};
  if (!(name_translations.find(nt => nt.locale === locale)?.name !== '')) {
    errors.name_translations = intl.formatMessage({ id: "form_error_required" });
  }
  if (!esg_type) {
    errors.esg_type = intl.formatMessage({ id: "form_error_required" });
  }
  if (!schema) {
    errors.schema = intl.formatMessage({ id: "form_error_required" });
  } else if (
    !validateSchema(schema)
    || (
      schema.type === 'table'
      && schema.innerSchema.type === 'tuple'
      && schema.innerSchema.components
      && schema.innerSchema.components.filter(
        component => !component.name && !component.componentName
      ).length > 0
    )
  ) {
    console.log('DEBUG: INVALID SCHEMA', schema);
    errors.schema = intl.formatMessage({ id: "form_error_invalid_schema" });
  }
  if (!periodicities || periodicities.length === 0) {
    errors.periodicities = intl.formatMessage({ id: "form_error_required" });
  }

  if (code && code.length > 20) {
    errors.code = intl.formatMessage({ id: "form_error_max_length" }, { max_length: 20 });
  }
  if ((gri_enabled && gri.find(g => !g.category_slug || !g.code))
    || (equality_enabled && equality.find(g => !g.category_slug || !g.code))) {
    errors.reporting_standards = intl.formatMessage({ id: "form_error_invalid_schema" });
  }

  return errors;
};

export default fullValidation;
