import React from 'react';
  import { injectIntl } from 'react-intl';
  import './style.less';
  
  import CustomMultiTagSelector from 'components/CustomMultiTagSelector'
  import CustomModal from 'components/CustomModal';
  
  const CustomMultiTagSelectorModal = ({
    intl,
    selectedKpis,
    loading,
    onSubmit,
    onClose,
    visible,
  }) => {
    const t = intl.messages;

     return (
      <CustomModal
        className="CustomMultiTagSelectorModal"
        shown={visible}
        title={t.custom_multi_tag_selector_title}
        onCancel={onClose}
        footer=""
      >
        <CustomMultiTagSelector
          onSubmit={onSubmit}
          selectedKpisAndCategories={selectedKpis}
          loading={loading}
        />
  
      </CustomModal>
    );
  };
  
  export default injectIntl(CustomMultiTagSelectorModal);
