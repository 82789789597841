import React, { useMemo, useState, useCallback, useRef } from 'react';
import { injectIntl } from 'react-intl';

import { flattenTree } from 'utils/tree';
import Avatar from 'components/Avatar';

import OrganizationTreeSelector from 'components/OrganizationTreeSelector';
import useOutsideClick from 'utils/useOutsideClick';
import {
  organizationHierarchyText,
  getOrganizationsRootToLeaf,
} from 'utils/organization';

import {
  Tooltip,
} from 'antd';
import {
  UserOutlined,
} from '@ant-design/icons';

import './style.less';


const OrganizationSelector = ({
  tree,
  current,
  onSelect,
  disabled
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const flat_tree = useMemo(() => flattenTree(tree), [ tree ]);

  const handleSelect = useCallback((selectedKey) => {
    setIsOpen(false);
    onSelect(flat_tree[selectedKey]);
  }, [
    onSelect,
    flat_tree
  ]);

  const onClickKeyEsc = useCallback(() => {
    setIsOpen(false);
  }, []);

  const ref = useRef();

  useOutsideClick(ref, () => {
    setIsOpen(false)
  });

  const disableDropdown = useMemo(
    () => !tree || !tree.children.length || disabled,
    [disabled, tree]
  );

  const organizationsRootToLeaf = useMemo(() => {
    return getOrganizationsRootToLeaf(tree, current);
  }, [
    tree,
    current,
  ]);

  const orgLabel = useMemo(() => {
    return organizationHierarchyText(tree, current);
  }, [
    tree,
    current,
  ]);

  return (
      isOpen ? (
        <div className="OrganizationSelector" ref={ref}>
          <OrganizationTreeSelector
            tree={tree}
            currentSelection={[current.id]}
            onClickKeyEsc={onClickKeyEsc}
            onSelect={handleSelect}
          />
        </div>
      ) : (
        <section onClick={() => !disableDropdown && setIsOpen(true)}>
          <Avatar
            shape="square"
            src={current.logo_small || undefined}
            name={current.name || undefined}
            icon={<UserOutlined />}
            className='OrganizationSelector-avatar'
          />
          <div className='OrganizationSelector-profileName'>
            <Tooltip title={organizationsRootToLeaf.join(' >> ')}>
              { orgLabel }
            </Tooltip>
          </div>
          { !disableDropdown &&
            <img
              className='OrganizationSelector-chevron'
              src='/images/assets/chevron-down.svg'
              alt='chevron-down'
            />
          }
        </section>
      )
  );
}

export default injectIntl(OrganizationSelector);
