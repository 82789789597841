import React, {
  useState,
  useCallback,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  getDataRequestAttachments,
} from 'actions/api';

import {
  resetAttachments,
} from 'actions/attachments';

import CustomModalType from 'components/CustomModalType';
import { Loading } from 'tsComponents/emptyStates/Loading';
import AplanetIcon from 'components/AplanetIcon';
import FileItem from 'components/FileItem';
import DataRequestsInfoModalHeader from './DataRequestsInfoModalHeader';
import { DATA_REQUEST_STATUS } from 'utils/dataRequests';
import { DELETED_USER_SLUG } from 'utils/user';
import { TableDataRequestsCellsContext } from './context';

const DataRequestsAttachmentsCell = ({
  intl,
  requestId,
  requestedToName,
  requestStatus,
  totalAttachments,
}) => {
  const dispatch = useDispatch();
  const {
    data,
    error,
    loading,
  } = useSelector(state => state.attachments);
  const {
    organizationSlug,
    suborganizationSlug,
  } = useContext(TableDataRequestsCellsContext);

  const [showModal, setShowModal] = useState(false);

  const handleOpenAttachmentsModal = useCallback(() => {
    setShowModal(true);
    dispatch(
      getDataRequestAttachments(
        organizationSlug,
        suborganizationSlug,
        requestId,
      )
    );
  },
  [
    dispatch,
    organizationSlug,
    requestId,
    suborganizationSlug,
  ]);

  const handleCloseAttachmentsModal = useCallback(() => {
    setShowModal(false);
    dispatch(resetAttachments());
  },
  [dispatch]);

  return (
    <td className='DataRequests__response-attachments'>
      {totalAttachments && (requestStatus === DATA_REQUEST_STATUS.done || requestStatus === DATA_REQUEST_STATUS.in_use || requestStatus === DATA_REQUEST_STATUS.partially_in_use) ? (
        <>
          <div className='DataRequests__response-attachments-wrapper'>
            <span>{intl.formatMessage({ id: "admin_data_requests_table_total_attachments"}, {total: totalAttachments})}</span>
            <AplanetIcon
              name="Eye open"
              onClick={handleOpenAttachmentsModal}
              title={intl.formatMessage({ id: "admin_data_requests_table_see_attachments"})}
              size="18px"
            />
          </div>
          <CustomModalType
            width={800}
            showModal={showModal}
            onCancel={handleCloseAttachmentsModal}
            // onOk={() => {}}
            // onOkText={intl.formatMessage({ id: "admin_data_requests_table_download_attachments"})}
            title={intl.formatMessage({ id: "admin_data_requests_table_modal_attachments_title"}, { value: requestedToName === DELETED_USER_SLUG ? intl.formatMessage({ id: requestedToName }) : requestedToName })}
            content={showModal ? (
              <>
                <DataRequestsInfoModalHeader />
                {loading ? (<Loading />) : 
                  error ? null : (
                    <div className="DataRequests__attachments-modal-list">
                      {data.map(({filename, mimetype, size, download}) => (
                        <FileItem
                          key={filename}
                          filename={filename}
                          mimetype={mimetype}
                          size={size}
                          download={download}
                        />
                      ))}
                    </div>
                  )
                }
              </>
            ) : null}
            hasCloseButton
          />
        </>
      ) : null}
    </td>
  );
}

DataRequestsAttachmentsCell.propTypes = {
  requestId: PropTypes.number.isRequired,
  requestedToName: PropTypes.string.isRequired,
  requestStatus: PropTypes.oneOf([
    DATA_REQUEST_STATUS.done,
    DATA_REQUEST_STATUS.pending,
    DATA_REQUEST_STATUS.not_notified,
    DATA_REQUEST_STATUS.partially_in_use,
    DATA_REQUEST_STATUS.in_use,
  ]).isRequired,
  totalAttachments: PropTypes.number.isRequired,
};

export default injectIntl(DataRequestsAttachmentsCell);