import React, {
  useEffect,
  useLayoutEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import { withRouter } from "react-router";
import _, { intersection, isEqual, groupBy, flattenDeep } from 'lodash';
import moment from 'moment';

import {
  Row,
  Col,
  Alert,
} from 'antd';

import {
  EyeOutlined,
  EyeInvisibleOutlined,
  BookOutlined,
  UserAddOutlined,
  SendOutlined
} from '@ant-design/icons';

import AplanetIcon from 'components/AplanetIcon';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import CustomProgress from 'components/CustomProgress';
import CustomWarningModal from 'components/CustomWarningModal';

import {
  Tooltip,
  notification,
} from 'antd';

import { Loading } from 'tsComponents/emptyStates/Loading';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { getEmptyResultsProps, emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';
import LoaderModal from 'components/LoaderModal';
import { useFeatureList } from 'components/FeatureSwitch';
import useOrganizations from 'utils/useOrganizations';
import objectFilter from 'utils/objectFilter';
import useScreenFilters from 'hooks/useScreenFilters';
import { SCREENS, SCREEN_FILTERS, FILTER_TYPES } from 'hooks/useScreenFilters/constants';

import {
  makeSearchString,
  makeSearchStrings,
} from 'utils/strings';

import {
  getKpiStatus,
} from 'utils/kpi'

import {
  findTree,
  mapOverTreeFromLeafs,
  flattenTree,
  idField,
  childrenField,
} from 'utils/tree';

import {
  queryStringToObject,
} from 'utils/queryParameters';

import {
  requestReportHistory,
  approveKpis,
  addRemoveTags,
  requestReportingStructureWithValues,
  requestAllOrganizationKpiMembers,
} from 'actions/api';

import {
  reportDownloaded,
} from 'actions/app';

import { toggleSidebar } from 'actions/app';

import NewReportModal from 'components/NewReportModal';
import CustomButton from 'components/CustomButton';
import Filters from 'components/ListFilters';
import ListSidebar from 'components/ListSidebar';
import KpiSummaryCard from 'components/KpiSummaryCard';
import ModalApproveRejectKpi from 'components/ModalApproveRejectKpi';
import ModalTarget from 'components/ModalTarget';
import CustomCheckbox from 'components/CustomCheckbox';
import ModalApplyNotApply from 'components/ModalApplyNotApply'
import CustomMultiTagSelectorModal from 'components/CustomMultiTagSelectorModal'
import NewAssigneeModal from 'components/NewAssigneeModal';
import A from 'components/A';
import T from 'components/T';

import useAuthorizedRequest from 'utils/useAuthorizedRequest';
import {
  reportingStructureToTree,
  getSortedIndicatorsFromCategoryAndDescendants,
  getSortedIndicatorsList,
} from 'utils/reporting_structure';

const ROOT_SLUG = '__root';

const MIME_TYPES = {
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  odt: 'application/vnd.oasis.opendocument.text',
  pdf: 'application/pdf',
};

const RESULTS_PER_PAGE = 5;

const COLORS = {
  environmental: '#44B381',
  social: '#3679FF',
  governance: '#7C29FF',
  economic: '#F9D942',
  cultural: '#a03707',
};

const DEFAULT_APPROVAL_LEVELS = 1;

const WARNING_COLOR = '#D38106';
const LIMIT_KPI_SELECTION = 50;

const DEFAULT_STATUS_FILTER_STATE = [
  'pending',
  'uptodate',
];

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};


const ListContainer = ({
  intl,
  history,
  onOpenAnswer,
  standard,
  onShowDataRequests,
  onCheckKpis,
}) => {
  const t = intl.messages;

  // Store and actions: Get organization and kpi_detail state
  const dispatch = useDispatch();

  const {
    organization,
    suborganization,
    permissions,
  } = useOrganizations();

  const {
    assignees = [],
  } = useSelector(state => state.taxonomies);
  const {
    showValidationChangedNotification,
  } = useSelector(state => state.data_categories);

  const { default_filters: defaultFilters = EMPTY_OBJECT } = organization?.config || {};
  const { new_atlas_enabled = {} } = suborganization?.product_config?.atlas || {};
  const { enabled_reports = [] } = suborganization.config
  const {
    data_request = false,
  } = new_atlas_enabled;

  const defaultPeriodDateFilter = useMemo(() => {
    const { [SCREENS.fill_and_report]: defaultFillAndReportFilters } = defaultFilters;

    if (defaultFillAndReportFilters?.period_date_filter) {
      switch(defaultFillAndReportFilters.period_date_filter) {
        case 'last_12_months':
          const currentDate = moment();
          const currentYearAndMonth = currentDate.format('YYYY-MM');

          return [
            currentDate.subtract(1, 'years').add(1, 'months').format('YYYY-MM'),
            currentYearAndMonth
          ];
        default:
          return EMPTY_ARRAY;
      }
    }

    return EMPTY_ARRAY;
  }, [
    defaultFilters,
  ]);

  const {
    values: [
      [filteredSdgs, sdgFilterSet],
      [filteredTypes, typeFilterSet],
      [filteredTags, tagFilterSet],
      [filteredStatuses, statusFilterSet],
      [filteredValidationStatuses, validationStatusFilterSet],
      [filteredStandards, standardFilterSet],
      [filteredPeriodicities, periodicityFilterSet],
      [periodDateFilter, periodDateFilterSet],
      [assigneeFilter],
      [selectedKpiIds],
      [selectedCategory],
      [textFilter, textFilterSet],
      [filteredDataManagers, dataManagerFilterSet],
      [filteredDataEditors, dataEditorFilterSet],
      [filteredDataValidators, dataValidatorFilterSet],
      [filteredAssociatedKpis, associatedKpisFilterSet],
    ],
    handleFilter,
    filterState,
    resetAllFilters,
    isFilterSet,
    setAllFilters,
  } = useScreenFilters({
    screen: SCREENS.fill_and_report,
    defaultFiltersSet: {
      [FILTER_TYPES.period_date_filter]: {
        defaultValue: defaultPeriodDateFilter,
        condition: (set) => !_.isEqual(set, defaultPeriodDateFilter)
      },
    }
  });

  const [approveRejectParams, setApproveRejectParams] = useState(null);
  const [resultCount, setResultCount] = useState(RESULTS_PER_PAGE);
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [applyModalParams, setApplyModalParams] = useState(null);
  const [tagSelectorOpen, setTagSelectorOpen] = useState(false);
  const [showNewAssigneeModal, setShowNewAssigneeModal] = useState(false);
  const [targetModalParams, setTargetModalParams] = useState(null);
  const [showReadyToValidateModal, setShowReadyToValidateModal] = useState(false);
  const [updatingReadyToValidate, setUpdatingReadyToValidate] = useState(false);

  const {
    features: featureList,
  } = useFeatureList();

  const isRestrictedAdmin = useMemo(() => (
    featureList.has('restricted_admins') && !suborganization?.permissions?.can_affect_all_kpis
  ), [
    featureList,
    suborganization,
  ]);

  const onApplies = useCallback((applying) => () => {
    setApplyModalParams({ applying });
  }, []);

  const openNewModal = useCallback(() => {
    setNewModalOpen(true);
  }, []);

  const closeNewModal = useCallback(() => {
    setNewModalOpen(false);
  }, []);

  const {
    data: reportHistory,
    report,
  } = useSelector(state => state.report_history);

  const handleApprove = useCallback(({
    level,
    slug,
    period,
  }) => {
    setApproveRejectParams({
      mode: 'approve',
      level,
      kpis: [{
        slug,
        period,
      }]
    });
  }, []);

  const handleReject = useCallback(({
    level,
    slug,
    period,
  }) => {
    setApproveRejectParams({
      mode: 'reject',
      level,
      kpis: [{
        slug,
        period,
      }]
    });
  }, []);

  const handleStartValidation = useCallback((
    slug,
    period,
  ) => {
    dispatch(
      approveKpis(
        organization.slug,
        suborganization.slug,
        [{ slug, period, }],
        '',
        0,
      )
    )
  }, [
    dispatch,
    organization,
    suborganization,
  ]);

  // TARGET METHODS
  const openTargetModal = useCallback((
    name,
    slug,
    esgs,
    sdgs,
    period,
    periods,
    schema,
    schemaLabels,
    standard_info,
    kpi_value,
    target_value
  ) => {
    setTargetModalParams({
      name,
      slug,
      esgs,
      sdgs,
      period,
      periods,
      schema,
      schemaLabels,
      standard_info,
      target: target_value ? target_value.value : null,
      kpi_value,
      dependees: [], // TODO
      has_target: periods[0].has_target
    }
    );
  }, []);

  // Request new data from API
  const [requested, setRequested] = useState(false);
  useEffect(() => {
    // Get slug and period from URL
    if (
      !organization ||
      !suborganization ||
      !organization.slug ||
      !suborganization.slug
    ) return;

    // Dispach action to request categories
    dispatch(
      requestReportingStructureWithValues({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        dateFilterValues: periodDateFilter,
      })
    );
    setRequested(true);
  }, [
    organization,
    suborganization,
    dispatch,
    periodDateFilter,
  ]);

  useEffect(() => {
    if (
      !organization ||
      !suborganization ||
      !organization.slug ||
      !suborganization.slug
    ) return;

    if (suborganization.product_config?.atlas?.features?.data_owners) {
      dispatch(
        requestAllOrganizationKpiMembers(
          organization.slug,
          suborganization.slug,
        )
      );
    }
  }, [
    organization,
    suborganization,
    dispatch,
  ])

  useEffect(() => {
    // Get slug and period from URL
    if (
      !organization ||
      !suborganization
    ) return

    // Dispach action to request history
    dispatch(
      requestReportHistory(
        organization.slug,
        suborganization.slug,
      )
    );
  }, [
    organization,
    suborganization,
    dispatch,
  ])

  // Always collapse the sidebar in this view
  useEffect(() => {
    dispatch(toggleSidebar(true));
  }, [
    dispatch,
  ]);

  const {
    data: reportingStructure,
    loading,
    //error,
  } = useSelector(state => state.data_categories);

  const [scrollAmount, setScrollAmount] = useState(false);
  const [scrollDone, setScrollDone] = useState(false);
  useEffect(() => {
    if (scrollDone || !history?.location?.search || history?.location?.search === '?') {
      return;
    }
    const { FillAndReportScroll } = queryStringToObject(history.location.search);
    if (typeof FillAndReportScroll === "undefined") {
      return;
    }

    setScrollAmount(Number(FillAndReportScroll));
  }, [scrollDone, history]);

  useLayoutEffect(() => {
    if (scrollDone || !requested || !reportingStructure || loading || scrollAmount === null) {
      return;
    }
    window.scrollTo(0, scrollAmount);
    setScrollDone(true);
  }, [
    scrollDone,
    scrollAmount,
    requested,
    loading,
    reportingStructure,
  ]);

  const rawCategories = useMemo(
    () => reportingStructure
      ? reportingStructureToTree(
        reportingStructure,
        'uuid',
        'parent',
        'children',
        true,
      )
      : null,
    [reportingStructure]
  );

  const isKpiSelected = useMemo(() => {
    return (uuid) => selectedKpiIds.includes(uuid);
  }, [
    selectedKpiIds,
  ]);

  const validationApprovalLevels = useMemo(() => {
    return (organization.config || {}).approval_levels || DEFAULT_APPROVAL_LEVELS;
  }, [
    organization,
  ]);

  // <FilterLogic>
  const handleSelectCategory = useCallback((value) => {
    handleFilter(FILTER_TYPES.selected_category)(value);
    setResultCount(RESULTS_PER_PAGE);
  }, [handleFilter]);

  const getDataOwnersHaveAccessToKpi = useCallback(
    (role, organizationKpiId) => {
      const filterSet = role === 'manager'
        ? dataManagerFilterSet
        : role === 'editor'
          ? dataEditorFilterSet
          : dataValidatorFilterSet;

      if (!filterSet) {
        return true;
      }

      const filteredOwners = role === 'manager'
        ? filteredDataManagers
        : role === 'editor'
          ? filteredDataEditors
          : filteredDataValidators;

      return assignees
        .filter(({ id }) => filteredOwners.includes(id))
        .some(
          ({ assignments }) => {
            return !!assignments.find(({ roles, organization_kpi_id }) => {
              return (
                (organization_kpi_id === organizationKpiId)
                && (roles.includes(role))
              )
            })
          }
        );
    },
    [
      dataManagerFilterSet,
      dataEditorFilterSet,
      dataValidatorFilterSet,
      filteredDataManagers,
      filteredDataEditors,
      filteredDataValidators,
      assignees,
    ]
  );

  const applyTaxonomyFilters = useCallback((kpis = []) => {
    const patchedKpis = (kpis || []).map(kpi => ({
      ...kpi,
      periods: (kpi.periods || [])
        .filter(Boolean)
        .map(period => {
          // TODO: Move this to reducers when fetting the info?
          const {
            status,
            progress,
            validationStatus,
          } = getKpiStatus({
            slug: kpi.slug,
            config: kpi.config,
            schema: kpi.schema,
            kpi_value: period.kpi_value,
            applies: kpi.applies,
            source: period.source,
            ready_to_validate_level: period.ready_to_validate_level,
            validated_level: period.validated_level,
            rejection_count: period.rejection_count,
            suborganization_values_uptodate: period.suborganization_values_uptodate,
            dependee_kpi_values_uptodate: period.dependee_kpi_values_uptodate,
            has_attachments: period.has_attachments,
            comment: period.comment,
          }, validationApprovalLevels);
          return {
            ...period,
            status,
            progress,
            validationStatus,
          };
        })
    }));

    if (!isFilterSet && !filteredStatuses.length && !validationStatusFilterSet) {
      // Bail-out early
      return patchedKpis;
    }

    const filteredKpis = patchedKpis.filter(kpi => {
      const sdgMatch = (
        !sdgFilterSet ||
        (kpi.sdgs || []).find(param => filteredSdgs.includes(param))
      );
      const typeMatch = (
        !typeFilterSet ||
        !!intersection(filteredTypes, kpi.esgs).length
      );
      const tagMatch = (
        !tagFilterSet ||
        (kpi.tags || []).find(param => filteredTags.includes(param))
      );
      const statusOfPeriods = (kpi.periods || []).map(({ status }) => status);

      const statusMatch = (
        !filteredStatuses.length ||
        statusOfPeriods.find(param => filteredStatuses.includes(param))
        || (
          filteredStatuses.includes('no_data')
          && statusOfPeriods.every(status => status === 'pending')
        )
        || (
          filteredStatuses.includes('all_data')
          && statusOfPeriods.every(status => status === 'uptodate')
        )
      );

      const standardMatch = !filteredStandards.length
        || filteredStandards.some(
          (standard) => !standard
            || standard === 'all'
            || (standard === 'standard' && !kpi.is_custom)
            || (standard === 'custom' && kpi.is_custom)
            || (kpi.standard_info.some(({ standard: _standard }) => _standard === standard))
        );

      const assigneeMatch = !assigneeFilter
        || assigneeFilter === 'all'
        || kpi.assigned_to_current_user;

      const dataManagerMatch = !dataManagerFilterSet
        || getDataOwnersHaveAccessToKpi('manager', kpi.organization_kpi_id);

      const dataEditorMatch = !dataEditorFilterSet
        || getDataOwnersHaveAccessToKpi('editor', kpi.organization_kpi_id);

      const dataValidatorMatch = !dataValidatorFilterSet
        || getDataOwnersHaveAccessToKpi('validator', kpi.organization_kpi_id);

      const periodicityMatch = !periodicityFilterSet
        || kpi.periods.find(
          ({ collection_frequency }) => filteredPeriodicities.includes(collection_frequency)
        );

      const associatedKpisMatch = !associatedKpisFilterSet
        || (flattenDeep(Object.values(kpi.kpi_associations || {})).some(kpi => filteredAssociatedKpis.includes(kpi.code)))

      // ESTO ES LO QUE LO ROMPE
      const validationOfPeriods = (kpi.periods || []).map(({ validationStatus }) => validationStatus).filter(Boolean);

      const validationMatch = !validationStatusFilterSet || validationOfPeriods.some(vs => filteredValidationStatuses.includes(vs));

      return statusMatch &&
        kpi.periods.length > 0 && (
          !isFilterSet || (
            sdgMatch &&
            typeMatch &&
            tagMatch &&
            standardMatch &&
            assigneeMatch &&
            validationMatch &&
            dataManagerMatch &&
            dataEditorMatch &&
            dataValidatorMatch &&
            periodicityMatch &&
            associatedKpisMatch
          )
        );
    });

    const patchedKpisPeriods = (filteredKpis || []).map(kpi => ({
      ...kpi,
      periods: (kpi.periods || [])
        .filter(period => {
          return (
            (!filteredStatuses.length
              || filteredStatuses.includes(period.status)
              || (period.status === 'pending' && filteredStatuses.includes('no_data'))
              || (period.status === 'uptodate' && filteredStatuses.includes('all_data'))
            ) && (
              !periodicityFilterSet
              || filteredPeriodicities.includes(period.collection_frequency)
            ) && (
              !validationStatusFilterSet
              || filteredValidationStatuses.includes(period.validationStatus)
            )
          );
        })
    })).filter(kpi => !!kpi.periods.length);

    return patchedKpisPeriods;
  }, [
    isFilterSet,
    validationStatusFilterSet,
    validationApprovalLevels,
    sdgFilterSet,
    typeFilterSet,
    filteredTypes,
    tagFilterSet,
    filteredStatuses,
    filteredStandards,
    assigneeFilter,
    filteredSdgs,
    filteredTags,
    filteredValidationStatuses,
    filteredAssociatedKpis,
    periodicityFilterSet,
    filteredPeriodicities,
    dataManagerFilterSet,
    dataEditorFilterSet,
    dataValidatorFilterSet,
    getDataOwnersHaveAccessToKpi,
    associatedKpisFilterSet,
  ]);

  const [categories, checkedCategories] = useMemo(() => {
    let checked = [];
    let halfChecked = [];

    if (!rawCategories) {
      return [
        rawCategories,
        { checked, halfChecked },
      ];
    }

    // NOTICE: this is a map, but has local side-effects (OMG!)
    const categories = mapOverTreeFromLeafs(rawCategories)((category, children = []) => {
      const kpis = applyTaxonomyFilters(category.kpis || []);

      const doesApply = children.some(({ applies }) => applies) || kpis.some(({ applies }) => applies);
      const isEmpty = children.every(({ empty }) => empty) && kpis.length === 0;
      const isChecked = children.every(({ checked }) => checked) && kpis.every(({ uuid }) => isKpiSelected(uuid));
      const isHalfChecked = children.some(({ halfChecked }) => halfChecked) || kpis.some(({ uuid }) => isKpiSelected(uuid));

      if (!isEmpty && isChecked) {
        checked.push(category.uuid);
      }
      if (!isEmpty && !isChecked && isHalfChecked) {
        halfChecked.push(category.uuid);
      }

      return {
        ...category,
        kpis,
        applies: doesApply,
        empty: isEmpty,
        checked: isChecked,
        halfChecked: isHalfChecked,
        children: children.sort((a, b) => a.position - b.position),
      };
    });

    return [
      categories.sort((a, b) => a.position - b.position),
      { checked, halfChecked },
    ];
  }, [
    applyTaxonomyFilters,
    rawCategories,
    isKpiSelected,
  ]);

  const allKpis = useMemo(() => {
    const kpis = Object.values(
      flattenTree({
        slug: ROOT_SLUG,
        [idField]: ROOT_SLUG,
        [childrenField]: rawCategories,
      }, 'uuid')
    ).filter(({ slug }) => slug !== ROOT_SLUG)
      .map(({ kpis }) => getSortedIndicatorsList(kpis) || [])
      .reduce((arr, el) => arr.concat(el), []);
    return applyTaxonomyFilters(kpis);
  }, [
    rawCategories,
    applyTaxonomyFilters,
  ]);

  const kpis = useMemo(() => {

    if ((!selectedCategory && !textFilterSet) || !rawCategories) {
      return allKpis;
      // NOTICE: You can use this to show only the first category??
      //         For now we prefer allKpis;
      //return categories?.[0].kpis?.length
      //  ? categories[0].kpis
      //  : categories?.[0]?.children?.[0]?.kpis || [];
    }

    if (textFilterSet) {
      // Search mode
      // NOTICE: 'allKpis' already includes a taxonomy filter
      const searchStrings = makeSearchStrings(textFilter);

      return allKpis.filter((kpi) => {
        const searchableName = makeSearchString(kpi.name);
        const searchableCode = makeSearchString(kpi.code || '');
        return (searchStrings || []).every(
          (searchString) => (
            searchableName.includes(searchString) || searchableCode.includes(searchString)
          )
        );
      });
    } else {
      // Selected category mode
      // NOTICE: Since we use 'rawCategories' kpis are not patched...
      const foundSelectedCategory = findTree(rawCategories, ({ uuid }) => uuid === selectedCategory);

      if (!foundSelectedCategory) {
        return null;
      }

      const sortedIndicators = getSortedIndicatorsFromCategoryAndDescendants(foundSelectedCategory);
      return applyTaxonomyFilters(sortedIndicators);
    }
  }, [
    textFilterSet,
    selectedCategory,
    rawCategories,
    allKpis,
    textFilter,
    applyTaxonomyFilters,
  ]);
  // </FilterLogic>

  const handleOpenAnswer = useCallback((props = {}) => {
    onOpenAnswer({
      ...props,
      org_name: suborganization.name,
    })
  }, [
    suborganization,
    onOpenAnswer,
  ]);

  const getFilledPercentage = useCallback((type) => {
    const periods = (kpis || [])
      .filter(kpi => kpi.esgs.includes(type))
      .reduce((accu, current) => accu.concat(current.periods), [])

    const filledPeriods = periods.filter(
      period => period.status === 'uptodate'
    );
    if (!periods.length) {
      return;
    }
    return (
      filledPeriods.length * 100 / periods.length
    ).toFixed(2);
  }, [
    kpis,
  ]);

  const [
    filledEnvironmentalPercent,
    filledSocialPercent,
    filledGovernancePercent,
    filledEconomicPercent,
    filledCulturalPercent,
  ] = useMemo(() => ([
    getFilledPercentage('environmental'),
    getFilledPercentage('social'),
    getFilledPercentage('governance'),
    getFilledPercentage('economic'),
    getFilledPercentage('cultural'),
  ]), [
    getFilledPercentage,
  ]);

  const progressFormat = useCallback(
    percent => typeof (percent) === 'number' ? 'N/A' : `${percent}%`,
    []
  );

  const hasApply = useMemo(() => {
    const kpis = allKpis.filter(
      ({ uuid }) => selectedKpiIds.includes(uuid),
    );
    const hasAppliesFalse = !!kpis.find(({ restricted, applies }) => !restricted && !applies);
    const hasAppliesTrue = !!kpis.find(({ restricted, applies }) => !restricted && applies);
    return [hasAppliesFalse, hasAppliesTrue];
  }, [
    selectedKpiIds,
    allKpis,
  ]);

  // START Selection madness
  const allSelected = useMemo(() => {
    return kpis?.every(({ uuid }) => selectedKpiIds.includes(uuid));
  }, [
    selectedKpiIds,
    kpis,
  ]);

  const handleCheckCategory = useCallback((changedUuid) => {
    if (!categories) {
      return null;
    }
    const category = findTree(categories, ({ uuid }) => uuid === changedUuid);

    if (!category) {
      return null;
    }

    const categoryKpis = Object.values(flattenTree(category))
      .map(({ kpis }) => kpis || [])
      .reduce((arr, el) => arr.concat(el), []);

    if (category.checked) {
      // Remove all KPIs from the selection
      const uuidSet = new Set(categoryKpis.map(({ uuid }) => uuid));
      handleFilter(FILTER_TYPES.kpis)(
        selectedKpiIds.filter(uuid => !uuidSet.has(uuid))
      );
    } else {
      // Add all KPIs from the selection
      const allSelection = [
        ...selectedKpiIds,
        ...categoryKpis.filter(({ uuid }) => !selectedKpiIds.includes(uuid)).map(({ uuid }) => uuid),
      ];
      handleFilter(FILTER_TYPES.kpis)(allSelection);
    }
  }, [categories, handleFilter, selectedKpiIds]);

  const handleCheckAll = useCallback(() => {
    if (allKpis.length === 0) {
      return null;
    }

    handleFilter(FILTER_TYPES.kpis)(allKpis.map(({ uuid }) => uuid));
  }, [allKpis, handleFilter]);

  const handleUncheckAll = useCallback(() => {
    handleFilter(FILTER_TYPES.kpis)([]);
  }, [handleFilter]);

  const handleSelectAll = useCallback(() => {
    if (!kpis) {
      return;
    }

    if (!textFilterSet) {
      if (selectedCategory) {
        handleCheckCategory(selectedCategory);
      } else if (allSelected) {
        handleUncheckAll();
      } else {
        handleCheckAll();
      }
      return;
    }

    const checked = kpis
      .filter(({ uuid }) => selectedKpiIds.includes(uuid));

    const unchecked = kpis
      .filter(({ uuid }) => !selectedKpiIds.includes(uuid));

    if (checked.length === kpis.length) {
      // All are selected, deselect
      const checkedUuids = new Set(checked.map(({ uuid }) => uuid));
      handleFilter(FILTER_TYPES.kpis)(
        selectedKpiIds.filter(uuid => !checkedUuids.includes(uuid))
      );
    } else {
      // None or some ar checked, check all
      const allChecked = [
        ...selectedKpiIds,
        ...unchecked.filter(({ uuid }) => !selectedKpiIds.includes(uuid)),
      ];
      handleFilter(FILTER_TYPES.kpis)(allChecked);
    }
  }, [
    kpis,
    textFilterSet,
    selectedKpiIds,
    selectedCategory,
    allSelected,
    handleCheckCategory,
    handleUncheckAll,
    handleCheckAll,
    handleFilter,
  ]);

  const localKpisSelected = useMemo(() => {
    return kpis?.filter(({ uuid }) => selectedKpiIds.includes(uuid));
  }, [
    selectedKpiIds,
    kpis,
  ]);

  const someSelected = useMemo(() => {
    return kpis?.some(({ uuid }) => selectedKpiIds.includes(uuid));
  }, [
    selectedKpiIds,
    kpis,
  ]);

  const getStandardInfo = useCallback((kpi) => {
    const { standard_info, is_custom } = kpi;
    return (standard_info || []).filter(({ standard }) => is_custom ? true : enabled_reports.includes(standard))
  }, [enabled_reports]);


  // END Selection madness

  const hasEquality = (organization.products || []).includes('equality');

  // Automatically download generated reports
  const handleDownload = useCallback((content) => {
    let anchor = linkRef.current;

    if (!anchor) {
      console.log('ERROR: cannot handle download without an anchor');
      return;
    }

    const windowUrl = window.URL || window.webkitURL;
    const blob = content instanceof Blob
      ? content
      : new Blob(
        [content],
        { type: MIME_TYPES[report.url?.split('.').pop()] }
      );

    const url = windowUrl.createObjectURL(blob);
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', report.url?.split('/').pop());
    anchor.click();
    windowUrl.revokeObjectURL(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const handleError = useCallback((error) => {
    console.log('Error fetching', error);
  }, []);


  const {
    linkRef,
    onClick: downloadFile,
  } = useAuthorizedRequest({
    url: report.url,
    onSuccess: handleDownload,
    onError: handleError,
  });

  useEffect(
    () => {
      if (!report.processing && report.done) {
        downloadFile();
        dispatch(
          reportDownloaded()
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report]
  );

  const onAddTag = useCallback((kpis, addTags, removeTags) => {
    dispatch(
      addRemoveTags(
        organization.slug,
        suborganization.slug,
        kpis,
        addTags,
        removeTags,
      )
    );
    handleFilter(FILTER_TYPES.kpis)([]);
    setTagSelectorOpen(false)
  }, [dispatch, organization.slug, suborganization.slug, handleFilter]);

  // When new data arrives, check integrity of selection
  useEffect(() => {
    if (allKpis.length === 0 || !selectedKpiIds || selectedKpiIds.length === 0) {
      // We are loading, wait...
      return;
    }

    // Reset all local state

    const allKpiUuidsSet = new Set(
      allKpis.map(({ uuid }) => uuid)
    );

    const filteredSelectedKpis = selectedKpiIds.filter(uuid => allKpiUuidsSet.has(uuid));

    if (filteredSelectedKpis.length !== selectedKpiIds.length) {
      handleFilter(FILTER_TYPES.kpis)(filteredSelectedKpis);
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    allKpis.length, // Slight optimization
    handleFilter,
  ]);

  useEffect(() => {
    onCheckKpis(selectedKpiIds);
  }, [
    onCheckKpis,
    selectedKpiIds
  ]);

  const setNewAssigneeModalVisibility = useCallback((isVisible) => {
    setShowNewAssigneeModal(isVisible);
    if (!isVisible) {
      handleFilter(FILTER_TYPES.kpis)([]);
    }
  }, [handleFilter]);

  const onCheckKpi = useCallback((kpi) => {
    if (isKpiSelected(kpi.uuid)) {
      handleFilter(FILTER_TYPES.kpis)(
        selectedKpiIds.filter(uuid => kpi.uuid !== uuid)
      );
    } else {
      const allSelection = [
        ...selectedKpiIds,
        kpi.uuid,
      ];
      handleFilter(FILTER_TYPES.kpis)(allSelection);
    }
  }, [handleFilter, isKpiSelected, selectedKpiIds]);

  const selectedKpis = useMemo(
    () => allKpis.filter(({ uuid }) => selectedKpiIds.includes(uuid)),
    [
      allKpis,
      selectedKpiIds,
    ]
  );

  const selectedKpisUnderValidation = useMemo(() => (selectedKpis || []).reduce((acc, curr) => {
    const isUnderValidation = curr?.periods?.some(period => !!period.ready_to_validate_level);
    return isUnderValidation ? [...acc, curr.slug] : acc;
  }, []), [
    selectedKpis,
  ]);

  const areFiltersSet = useMemo(
    () => (
      sdgFilterSet
      || typeFilterSet
      || tagFilterSet
      || standardFilterSet
      || periodicityFilterSet
      || periodDateFilterSet
      || (
        statusFilterSet
        && !_.isEqual(
          filteredStatuses.filter(slug => slug !== 'restricted'),
          DEFAULT_STATUS_FILTER_STATE
        )
      )
      || validationStatusFilterSet
      || assigneeFilter
      || dataManagerFilterSet
      || dataEditorFilterSet
      || dataValidatorFilterSet
      || associatedKpisFilterSet
    ),
    [
      sdgFilterSet,
      standardFilterSet,
      tagFilterSet,
      typeFilterSet,
      periodicityFilterSet,
      periodDateFilterSet,
      statusFilterSet,
      validationStatusFilterSet,
      assigneeFilter,
      dataManagerFilterSet,
      dataEditorFilterSet,
      dataValidatorFilterSet,
      filteredStatuses,
      associatedKpisFilterSet
    ],
  );

  const onChangeSearch = useCallback((term) => {
    handleFilter(FILTER_TYPES.text_filter)(term);
  }, [handleFilter]);

  const getDetailQueryString = useCallback(() => ({ FillAndReportScroll: window.scrollY }), []);

  const isEmpty = !kpis || kpis.length === 0;
  // HACK - we can't rely on "isFilterSet" because "status_filter" is always set
  // but there's not way to unset it.
  const isFilterReallySet = Object.entries(filterState)
    .filter(([key, value]) => key !== "status_filter")
    .some(([key, value]) => _.isBoolean(value) ? value : !_.isEmpty(value));
  const emptyState =
    textFilterSet || isFilterReallySet ? (
      <Empty {...getEmptyResultsProps(textFilterSet, isFilterReallySet)} />
    ) : (
      <Empty {...emptyPropsMap.get("nothingSelected")} />
    );

  const readyToValidateBulkPayload = useMemo(() => {
    if (!showReadyToValidateModal) {
      return [];
    }

    const periods = selectedKpis.map(kpi => {
      const mandatoryData = kpi?.config?.mandatory_data || {};
      return kpi.periods.filter(period => {
        return !(
          (mandatoryData.value && period.status !== 'uptodate') ||
          (mandatoryData.attachment && !period.has_attachments) ||
          (mandatoryData.comment && !period.comment) ||
          (period.ready_to_validate_level !== 0) ||
          (period.validated_level !== 0)
        )
      })
        .map(period => ({
          kpi_slug: kpi.slug,
          ...period,
        }));
    }).flat();

    return periods.map(({ kpi_slug: slug, period }) => ({ slug, period }));
  }, [
    selectedKpis,
    showReadyToValidateModal,
  ]);

  const onSetReadyToValidate = useCallback(() => {
    if (readyToValidateBulkPayload?.length) {
      setUpdatingReadyToValidate(true);
      dispatch(
        approveKpis(
          organization.slug,
          suborganization.slug,
          readyToValidateBulkPayload,
          '',
          0,
        )
      );
    } else {
      setShowReadyToValidateModal(false);
    }
  }, [
    dispatch,
    organization,
    suborganization,
    readyToValidateBulkPayload,
  ]);

  useEffect(() => {
    if (showValidationChangedNotification) {
      notification.success({
        message: intl.formatMessage({ id: 'validation_success_notification_title' }),
        description: intl.formatMessage({ id: 'validation_success_notification' }),
      });
      dispatch({
        type: 'RESET_SHOW_VALIDATION_NOTIFICATION',
        target: 'data_categories',
      });
    }
  }, [
    showValidationChangedNotification,
    dispatch,
    intl,
  ]);

  useEffect(() => {
    if (!loading && updatingReadyToValidate) {
      setShowReadyToValidateModal(false);
      setUpdatingReadyToValidate(false);
    }
  }, [
    loading,
    updatingReadyToValidate,
  ]);

  const readyToValidateBulkChangeText = useMemo(() => {
    if (showReadyToValidateModal) {
      const kpiPeriodsGroup = groupBy(readyToValidateBulkPayload, 'slug');
      const totalPeriods = Object.values(kpiPeriodsGroup).flat().length;
      return intl.formatMessage({
        id: `ready_to_validate_bulk_modal_change_text`,
      }, {
        totalPeriods,
        totalKpis: Object.keys(kpiPeriodsGroup).length,
      });
    }
  }, [
    intl,
    showReadyToValidateModal,
    readyToValidateBulkPayload,
  ]);

  const validQueryStringFilters = useMemo(() => {
    const queryString = queryStringToObject(history.location.search);
    return objectFilter(
      Object.keys(SCREEN_FILTERS[SCREENS.fill_and_report])
    )(queryString);
  }, [
    history.location.search,
  ]);

  /*
   * Load filters from querystring
   */
  useEffect(() => {
    const filtersInQueryString = Object.fromEntries(
      Object.entries(validQueryStringFilters).map(([key, value]) => {
        try { return [key, JSON.parse(value)] }
        catch { return [key, value] }
      })
    )

    const filters = { ...filterState, ...filtersInQueryString };
    if (!isEqual(filterState, filters)) {
      setAllFilters(filters);
    }
  }, [
    filterState,
    setAllFilters,
    validQueryStringFilters,
  ]);

  const onResetAllFilters = useCallback(() => {
    if (!!Object.keys(validQueryStringFilters).length) {
      const url = new URL(window.location);
      Object.keys(validQueryStringFilters).forEach(
        key => url.searchParams.delete(key)
      );
      history.push(url.pathname + url.search);
    }

    resetAllFilters();
  }, [
    history,
    resetAllFilters,
    validQueryStringFilters,
  ]);

  if (loading && (!categories || categories.length === 0)) {
    return <Loading />;
  }

  return (
    <section className="DataManagement__all">
      <div className="DataManagement__all_info">
        <Filters
          filterState={filterState}
          resetAllFilters={onResetAllFilters}
          setAllFilters={setAllFilters}
          areFiltersSet={areFiltersSet}
          isKpiNameSearchSet={textFilterSet}
          kpiNameSearch={textFilter}
          onChangeKpiSearch={onChangeSearch}
          showDataOwnersFilters={featureList.has('data_owners') && !isRestrictedAdmin}
          reportActions={
            !hasEquality && standard === 'equality' ? null : (
              <>
                {
                  permissions.can_write_kpi && (
                    <Col>
                      <Tooltip
                        placement="bottom"
                        trigger={['hover', 'focus', 'click']}
                        title={intl.formatMessage({ id: 'reports_bulk_ready_to_validate_tooltip' })}
                      >
                        <CustomButton
                          type="primary"
                          icon={<AplanetIcon name="Validation" />}
                          disabled={!selectedKpis.length}
                          onClick={() => setShowReadyToValidateModal(true)}
                        />
                      </Tooltip>
                    </Col>
                  )
                }
                {permissions.can_configure_kpi &&
                  <>
                    <Col>
                      <Tooltip
                        placement="bottom"
                        trigger={['hover', 'focus', 'click']}
                        title={t.reports_applies_tooltip}
                      >
                        <CustomButton
                          type="primary"
                          disabled={!hasApply[0]}
                          icon={<EyeOutlined />}
                          onClick={onApplies(true)}
                        />
                      </Tooltip>
                    </Col>
                    <Col>
                      <Tooltip
                        placement="bottom"
                        trigger={['hover', 'focus', 'click']}
                        title={t.reports_not_applies_tooltip}
                      >
                        <CustomButton
                          type="primary"
                          disabled={!hasApply[1]}
                          icon={<EyeInvisibleOutlined />}
                          onClick={onApplies(false)}
                        />
                      </Tooltip>
                    </Col>
                    <Col>
                      <Tooltip
                        placement="bottom"
                        trigger={['hover', 'focus', 'click']}
                        title={t.reports_manage_tags_tooltip}
                      >
                        <CustomButton
                          type="primary"
                          disabled={!selectedKpiIds.length}
                          icon={<BookOutlined />}
                          onClick={() => setTagSelectorOpen(true)}
                        />
                      </Tooltip>
                    </Col>
                  </>
                }
                {featureList.has('data_owners') && permissions.can_configure_kpi &&
                  <Col>
                    <Tooltip
                      placement="bottom"
                      trigger={['hover', 'focus', 'click']}
                      title={t.reports_assignee_tooltip}
                    >
                      <CustomButton
                        type="primary"
                        disabled={!selectedKpiIds.length}
                        icon={<UserAddOutlined />}
                        onClick={() => setShowNewAssigneeModal(true)}
                      />
                    </Tooltip>
                  </Col>
                }
                {permissions.can_request_kpi && data_request &&
                  <Col>
                    <Tooltip
                      placement="bottom"
                      color={selectedKpiIds.length > LIMIT_KPI_SELECTION && WARNING_COLOR}
                      trigger={['hover', 'focus', 'click']}
                      title={selectedKpiIds.length > LIMIT_KPI_SELECTION ? (intl.formatMessage(
                        { id: `step_1_warning_tooltip_limit_kpis` },
                        { boldValue: <b>{intl.formatMessage({ id: `step_1_warning_tooltip_limit_kpis_bold` })}</b> }
                      )) : t.reports_external_assignee_tooltip}
                    >
                      <CustomButton
                        type="primary"
                        icon={<SendOutlined />}
                        onClick={selectedKpiIds.length <= LIMIT_KPI_SELECTION && onShowDataRequests}
                        disabled={hasApply[0]}
                      />
                    </Tooltip>
                  </Col>
                }
                {
                  standard === 'equality'
                    ? (
                      <Col>
                        <CustomButton
                          onClick={() => history.push('/equality-plan')}
                        >
                          {t.list_report_equality_button}
                        </CustomButton>
                      </Col>
                    ) : (!isRestrictedAdmin &&
                      <>
                        {
                          suborganization?.permissions?.can_report_kpi &&
                          (
                            selectedKpiIds.length === 0
                              ? (
                                <Col>
                                  <Tooltip
                                    placement="bottomRight"
                                    trigger={['hover', 'focus', 'click']}
                                    title={t.reports_generate_button_disabled_tooltip}
                                  >
                                    <CustomButton icon={<AplanetIcon name="File new" size="18px" style={{ marginLeft: "4px" }} />} />
                                  </Tooltip>
                                </Col>
                              ) : (
                                <Col>
                                  <CustomButton
                                    type="primary"
                                    onClick={openNewModal}
                                    icon={<AplanetIcon name="File new" size="18px" style={{ marginLeft: "4px" }} />}
                                  />
                                </Col>
                              )
                          )
                        }
                        {
                          suborganization?.permissions?.can_report_kpi &&
                          (
                            <Col>
                              <Tooltip
                                placement="bottomRight"
                                trigger={['hover', 'focus', 'click']}
                                title={t.report}
                              >
                                <CustomButton
                                  disabled={!reportHistory || reportHistory.length === 0}
                                  icon={<AplanetIcon name="Report" />}
                                  onClick={() => history.push(`/report/${standard}`)}
                                />
                              </Tooltip>
                            </Col>
                          )
                        }
                      </>
                    )
                }
              </>
            )
          }
        />

        {!loading &&
          <Row
            type="flex"
            justify="start"
            style={{
              marginTop: 20,
              marginBottom: 40,
            }}
          >
            <Col>
              <CustomProgress
                title={intl.formatMessage({ id: 'esg_type_environmental' })}
                strokeColor={COLORS.environmental}
                type="circle"
                percent={filledEnvironmentalPercent}
                format={progressFormat}
              />
            </Col>
            <Col>
              <CustomProgress
                title={intl.formatMessage({ id: 'esg_type_social' })}
                strokeColor={COLORS.social}
                type="circle"
                percent={filledSocialPercent}
                format={progressFormat}
              />
            </Col>
            <Col>
              <CustomProgress
                title={intl.formatMessage({ id: 'esg_type_governance' })}
                strokeColor={COLORS.governance}
                type="circle"
                percent={filledGovernancePercent}
                format={progressFormat}
              />
            </Col>
            <Col>
              <CustomProgress
                title={intl.formatMessage({ id: 'esg_type_economic' })}
                strokeColor={COLORS.economic}
                type="circle"
                percent={filledEconomicPercent}
                format={progressFormat}
              />
            </Col>
            <Col>
              <CustomProgress
                title={intl.formatMessage({ id: 'esg_type_cultural' })}
                strokeColor={COLORS.cultural}
                type="circle"
                percent={filledCulturalPercent}
                format={progressFormat}
              />
            </Col>
          </Row>
        }

        <div className="DataManagement__all_container">
          <ListSidebar
            selectable
            className="DataManagement__all_sidebar"
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={handleSelectCategory}
            checkedCategories={checkedCategories}
            onCheckCategory={handleCheckCategory}
            onCheckAll={handleCheckAll}
            onUncheckAll={handleUncheckAll}
            showCode
            hideEmpty
          />
          <div className="DataManagement__all_list">
            {isEmpty ? (
              emptyState
            ) : (
              <>
                <div className='DataManagement__all__kpis-topBar'>
                  <section>
                    <div>
                      {!!selectedKpiIds.length &&
                        `${intl.formatMessage({ id: 'data_grid_topBar_total_selected' })}: ${selectedKpiIds.length}`
                      }
                    </div>
                    <div className='DataManagement__all__kpis-topBar-selected'>
                      {intl.formatMessage({ id: 'data_grid_topBar_indicators_shown' })}: {kpis.length} -
                      <CustomCheckbox
                        size='small'
                        checked={allSelected}
                        onChange={handleSelectAll}
                        indeterminate={!allSelected && someSelected}
                      />
                      {!!localKpisSelected.length && localKpisSelected.length} {intl.formatMessage({ id: (someSelected ? 'data_grid_topBar_some_selected' : 'data_grid_topBar_not_selected') })}
                    </div>
                    {true || (localKpisSelected.length === kpis.length) ? null :
                      <A
                        onClick={handleSelectAll}
                        className='DataManagement__all__kpis-topBar-selectAll'
                      >
                        <T
                          data_grid_topBar_select_all={{
                            count: kpis.length,
                          }}
                        />
                      </A>
                    }
                  </section>
                </div>
                {
                  (kpis || []).filter((_, i) => i < resultCount).map(
                    kpi => (
                      <KpiSummaryCard
                        selectable
                        selected={isKpiSelected(kpi.uuid)}
                        onSelect={() => onCheckKpi(kpi)}
                        key={kpi.uuid}
                        urlBase={`data/fill/${standard}`}
                        getDetailQueryString={getDetailQueryString}
                        permissions={permissions}
                        onOpenAnswer={handleOpenAnswer}
                        onApprove={handleApprove}
                        onReject={handleReject}
                        onStartValidation={handleStartValidation}
                        onTarget={openTargetModal}
                        {...kpi}
                        standard_info={getStandardInfo(kpi)}
                        showAssociations={true}
                      />
                    )
                  )
                }
              </>
            )}
            {(kpis || []).length <= resultCount ? null : (
              <CustomButton
                type="primary"
                block
                onClick={() => setResultCount(resultCount + RESULTS_PER_PAGE)}
              >
                {t.datamanagement_all_loadmore}
              </CustomButton>
            )}
          </div>
        </div>
      </div>
      <ModalApproveRejectKpi
        visible={!!approveRejectParams}
        onClose={() => setApproveRejectParams(null)}
        {...approveRejectParams}
      />
      <NewReportModal
        shown={newModalOpen}
        kpi_slugs={selectedKpis.map(({ slug }) => slug)}
        dates={periodDateFilter}
        onClose={closeNewModal}
      />
      <LoaderModal
        visible={report.processing}
        message={t.downloading_report}
      />
      <ModalApplyNotApply
        visible={!!applyModalParams}
        onClose={() => setApplyModalParams(null)}
        kpi_slugs={selectedKpis.map(({ slug }) => slug)}
        selectedKpisUnderValidation={selectedKpisUnderValidation}
        clearSelection={() => handleFilter(FILTER_TYPES.kpis)([])}
        hasNodesSelector
        {...(applyModalParams || {})}
      />
      <CustomMultiTagSelectorModal
        visible={tagSelectorOpen}
        onSubmit={onAddTag}
        onClose={() => setTagSelectorOpen(false)}
        selectedKpis={selectedKpis}
        loading={loading}
      />
      <NewAssigneeModal
        kpis={selectedKpis.map(({ slug }) => ({ slug }))}
        visible={showNewAssigneeModal}
        setVisible={setNewAssigneeModalVisibility}
        assignedMembers={[]}
      />

      {
        !targetModalParams ? null : (
          <ModalTarget
            visible={!!targetModalParams}
            onClose={() => setTargetModalParams(null)}
            {...targetModalParams}
          />
        )
      }

      <CustomWarningModal
        className='ReadyToValidateBulkModal'
        showModal={showReadyToValidateModal}
        icon='check-double'
        iconType='fad'
        onOkText={intl.formatMessage({ id: `ready_to_validate_bulk_modal_action_ok` })}
        onCancelText={intl.formatMessage({ id: `ready_to_validate_bulk_modal_action_cancel` })}
        title={intl.formatMessage({ id: `ready_to_validate_bulk_modal_title` })}
        onOk={onSetReadyToValidate}
        okButtonProps={{
          disabled: !readyToValidateBulkPayload.length
        }}
        onCancel={() => setShowReadyToValidateModal(false)}
        loading={updatingReadyToValidate}
        content={
          <Row gutter={[0, 10]}>
            <Col span={24}>
              {!readyToValidateBulkPayload.length &&
                <Alert
                  message={intl.formatMessage({ id: 'reports_bulk_ready_to_validate_invalid_warning' })}
                  type="warning"
                />
              }
              <FormattedHTMLMessage
                id={`ready_to_validate_bulk_modal_content`}
                defaultMessage={'Bulk validation'}
                tagName="span"
                values={{ changeText: readyToValidateBulkChangeText }}
              />
            </Col>
            <Col span={24}>
              <b>
                {intl.formatMessage({ id: `ready_to_validate_bulk_modal_confirmation` })}
              </b>
            </Col>
          </Row>
        }
      />

      { /* eslint-disable-next-line */}
      <a ref={linkRef}></a>
    </section>
  );
}

export default injectIntl(
  withRouter(
    ListContainer
  )
);
