import React, { useCallback } from 'react';

import {
  Row,
  Col,
  Input,
  InputNumber,
} from 'antd';
import {
  DeleteOutlined,
} from '@ant-design/icons';


const LevelFormItem = ({
  index,
  level,
  canEdit,
  onChange,
  onDelete,
}) => {
  const handleChange = useCallback(
    (field) => (e) => {
      onChange(index, {...level, [field]: e.target.value});
    },
    [onChange, index, level]
  );
  const handleChangeNumber = useCallback(
    (field) => (value) => {
      onChange(index, {...level, [field]: value});
    },
    [onChange, index, level]
  );
  return (
    <Row gutter={[5, 5]}>
      <Col span={4}>{index}</Col>
      <Col span={7}>
        <Input
          value={level.name}
          onChange={handleChange('name')}
        />
      </Col>
      <Col span={8}>
        <Input
          value={level.description}
          onChange={handleChange('description')}
        />
      </Col>
      <Col span={4}>
        {canEdit
          ? <InputNumber
              value={level.points}
              onChange={handleChangeNumber('points')}
            />
          : level.points
        }
      </Col>
      <Col span={1}>
        {canEdit &&
          <DeleteOutlined onClick={() => onDelete(index)} />
        }
      </Col>
    </Row>
  );
}

export default LevelFormItem;