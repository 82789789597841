import React from 'react';
import { injectIntl } from 'react-intl';
import CustomInput from 'components/CustomInput';
import {
  Form,
} from 'antd';

const Code = ({
  intl,
  value,
  onChange,
  error,
}) => {
  return (
    <React.Fragment>
      <Form.Item
        className='CreateEditCustomKPI-header'
        colon={false}
        label={intl.formatMessage({ id: "createcustomkpi_code_header" })}
      >
        {
          !error ? null :
          <div className="CreateEditCustomKPI__error">{ error }</div>
        }
        <CustomInput
          value={value}
          onChange={(e) => onChange( e.target.value )}
        />
      </Form.Item>
    </React.Fragment>
  )
}

export default injectIntl(Code);
