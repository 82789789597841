import { useState, useEffect, useRef, useCallback } from "react";

export const useComponentVisible = ({
  initialIsVisible = false,
  onEscPress,
  onClickOutside
}) => {
    const [isComponentVisible, setIsComponentVisible] = useState(
      initialIsVisible
    );
    const ref = useRef(null);

    const handleHideDropdown = useCallback((event) => {
        if (isComponentVisible && event.key === "Escape") {
          setIsComponentVisible(false);
          onEscPress && onEscPress();
        }
      },
      [onEscPress, isComponentVisible]
    );

    const handleClickOutside = useCallback((event) => {
        if (isComponentVisible && ref.current && !ref.current.contains(event.target)) {
          setIsComponentVisible(false);
          onClickOutside && onClickOutside();
        }
      },
      [onClickOutside, isComponentVisible]
    );

    const toggleIsComponentVisible = useCallback(() => {
      setIsComponentVisible(!isComponentVisible);
    },
    [isComponentVisible]
  );
  
    useEffect(() => {
      document.addEventListener("keydown", handleHideDropdown, true);
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("keydown", handleHideDropdown, true);
        document.removeEventListener("click", handleClickOutside, true);
      };
    }, [handleClickOutside, handleHideDropdown]);
  
    return {
      ref,
      isComponentVisible,
      setIsComponentVisible,
      toggleIsComponentVisible,
    };
  };