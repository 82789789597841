import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Bar } from 'react-chartjs-2';
import { groupBy, sum } from 'lodash';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import CustomChart from 'components/CustomChart';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';
import { defaultColors } from 'components/Dashboard/useColors';

import {
  requestEqualityPlanDepartments,
  requestEqualityPlanFactors,
  requestEqualityPlanPositions,
  requestEqualityPlanProfessionalGroups,
} from 'actions/api';

import CustomButton from 'components/CustomButton';
import {
  Row,
  Col,
} from 'antd';


const GenderAnalysisByPosition = ({
  intl,
  history,
  plan,
  updatePlanProgress,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanFactors(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanPositions(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanDepartments(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanProfessionalGroups(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetching: fetchingFactors,
    data: factors,
  } = useSelector(state => state.equality_plan_factor);
  const {
    fetching: fetchingPositions,
    data: positions,
  } = useSelector(state => state.equality_plan_position);
  const {
    fetching: fetchingDepartments,
    data: departments,
  } = useSelector(state => state.equality_plan_department);
  const {
    fetching: fetchingProfessionalGroups,
    data: professionalGroups,
  } = useSelector(state => state.equality_plan_professional_group);

  const departmentIds = useMemo(
    () => departments?.map(department => department.id) || [],
    [departments],
  );
  const professionalGroupIds = useMemo(
    () => professionalGroups?.map(professionalGroup => professionalGroup.id) || [],
    [professionalGroups],
  );

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  const handleOnClickReviewed = useCallback(
    () => {
      updatePlanProgress('gender_analysis_by_position');
      handleBack();
    },
    [updatePlanProgress, handleBack]
  );

  const positionsWithPointsByFactors = useMemo(
    () => {
      if (!positions || !factors) {
        return [];
      }
      return positions.map(position => {
        return {
          ...position,
          factors: position.factors.map(positionFactor => {
            const factor = factors
              .find(factor => factor.id === positionFactor.factor_id);
            const levelPoints = factor.levels
              .find(level => level.id === positionFactor.factor_level_id)
              .points;
            return {
              ...positionFactor,
              gender: factor.gender,
              points: (
                levelPoints
                * factor.percentage
                * (plan.job_evaluation?.type_points?.[factor.type] || 0)
              ),
            };
          }),
        };
      });
    },
    [factors, plan, positions]
  );

  const positionPointsByGender = useMemo(
    () => {
      if (!positions || !factors) {
        return {};
      }

      let graphData = {
        labels: [],
        datasets: ['male', 'female', 'neutral'].map((gender, index) => ({
          label: t[`equality_job_evaluation_factor_gender_option_${gender}`],
          data: [],
          backgroundColor: defaultColors[index],
        })),
      };

      positionsWithPointsByFactors.forEach(
        position => {
          graphData.labels.push(`${position.code}: ${position.name}`);

          ['male', 'female', 'neutral'].forEach((gender, index) => {
            graphData.datasets[index].data.push(
              sum(
                position.factors
                  .filter(factor => factor.gender === gender)
                  .map(factor => factor.points)
              )
            );
          });
        }
      );
      return graphData;
    },
    [positionsWithPointsByFactors, t, positions, factors]
  );

  const departmentPointsByGender = useMemo(
    () => {
      if (!positions || !factors) {
        return {};
      }

      let graphData = {
        labels: [],
        datasets: ['male', 'female', 'neutral'].map((gender, index) => ({
          label: t[`equality_job_evaluation_factor_gender_option_${gender}`],
          data: [],
          backgroundColor: defaultColors[index],
        })),
      };

      Object.entries(
        groupBy(
          positionsWithPointsByFactors.sort(
            (a, b) => departmentIds.indexOf(a.department_id) - departmentIds.indexOf(b.department_id)
          ),
          'department_name'
        )
      )
      .forEach(
        ([department, positions]) => {
          positions.forEach(
            position => {
              graphData.labels.push(
                `${department} - ${position.code}: ${position.name}`
              );
              ['male', 'female', 'neutral'].forEach((gender, index) => {
                graphData.datasets[index].data.push(
                  sum(
                    position.factors
                      .filter(factor => factor.gender === gender)
                      .map(factor => factor.points)
                  )
                );
              });
            }
          );
        }
      );
      return graphData;
    },
    [positionsWithPointsByFactors, t, positions, factors, departmentIds]
  );

  const professionalGroupPointsByGender = useMemo(
    () => {
      if (!positions || !factors) {
        return {};
      }

      let graphData = {
        labels: [],
        datasets: ['male', 'female', 'neutral'].map((gender, index) => ({
          label: t[`equality_job_evaluation_factor_gender_option_${gender}`],
          data: [],
          backgroundColor: defaultColors[index],
        })),
      };

      Object.entries(
        groupBy(
          positionsWithPointsByFactors.sort(
            (a, b) => professionalGroupIds.indexOf(a.professional_group_id) - professionalGroupIds.indexOf(b.professional_group_id)
          ),
          'professional_group_name'
        )
      )
      .forEach(
        ([professional_group, positions]) => {
          positions.forEach(
            position => {
              graphData.labels.push(
                `${professional_group} - ${position.code}: ${position.name}`
              );
              ['male', 'female', 'neutral'].forEach((gender, index) => {
                graphData.datasets[index].data.push(
                  sum(
                    position.factors
                      .filter(factor => factor.gender === gender)
                      .map(factor => factor.points)
                  )
                );
              });
            }
          );
        }
      );
      return graphData;
    },
    [positionsWithPointsByFactors, t, positions, factors, professionalGroupIds]
  );

  if (
    fetchingFactors
    || !factors
    || fetchingPositions
    || !positions
    || fetchingDepartments
    || !departments
    || fetchingProfessionalGroups
    || !professionalGroups  
  ) {
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_gender_analysis_by_position}
        handleBackOrSkip={handleBack}
      />
      <Row type="flex" justify="end">
        <CustomButton
          type="primary"
          onClick={handleOnClickReviewed}
        >
          {t.equality_plan_reviewed}
        </CustomButton>
      </Row>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_gender_analysis_by_position_graph_position}</h2>
        </Col>
        <Col span={24}>
          <CustomChart
            title={t.equality_job_evaluation_gender_analysis_by_position_graph_position}
            Chart={Bar}
            data={positionPointsByGender}
            height='300px'
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
              plugins: {
                legend: { 
                  align: 'start',
                  position: 'bottom',
                  labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded'
                  }
                }
              }
            }}
          />
        </Col>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_gender_analysis_by_position_graph_department}</h2>
        </Col>
        <Col span={24}>
          <CustomChart
            title={t.equality_job_evaluation_gender_analysis_by_position_graph_department}
            Chart={Bar}
            data={departmentPointsByGender}
            height='300px'
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
              plugins: {
                legend: { 
                  align: 'start',
                  position: 'bottom',
                  labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded'
                  }
                }
              }
            }}
          />
        </Col>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_gender_analysis_by_position_graph_professional_group}</h2>
        </Col>
        <Col span={24}>
          <CustomChart
            title={t.equality_job_evaluation_gender_analysis_by_position_graph_professional_group}
            Chart={Bar}
            data={professionalGroupPointsByGender}
            height='300px'
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              },
              plugins: {
                legend: { 
                  align: 'start',
                  position: 'bottom',
                  labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded'
                  }
                }
              }
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default withRouter(injectIntl(GenderAnalysisByPosition));
