import React from 'react';

import {
  Tooltip,
} from 'antd';

import './style.less';

const LocaleFlag = ({
  intl,
  locale: selectedLocale,
  style,
  className,
}) => {
  const locale = selectedLocale || intl.locale;

  return (
    <Tooltip title={intl.formatMessage({id: locale})}>
      <span
        className={`LocaleFlag ${className}`}
        style={style}
      >
        <img
          alt={locale}
          src={`/images/flags/${locale}.svg`}
        />
      </span>
    </Tooltip>
  );
}

export default LocaleFlag;
