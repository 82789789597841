import React, { useContext, useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { Col, Form, Row, Select } from 'antd';
import CustomInput from 'components/CustomInput';
import { DashboardCardFormContext } from '../..';
import CardFormStepWrapper from '../CardFormStepWrapper';
import { FORM_KEY_PERIODICITY, FORM_KEY_PERIODSNUMBER } from 'containers/DashboardCardForm/constants';

const { Option } = Select;

const PERIODICITIES = ['month', 'quarter', 'semester', 'year'];

const SelectPeriodicity = ({ intl }) => {

    const { values, handleChange } = useContext(DashboardCardFormContext);

    const maxPeriodSelection = useMemo(() => {
        switch(values.selectedRows[0]?.schema?.type){
          case "qualitative":
            return 1
          default: return 12
        }
      }, [values.selectedRows]);

    const kpiCommonPeriodicities = useMemo(() => {

        return PERIODICITIES.filter( periodicity => 
                values.selectedRows.every( kpi => kpi.periodicities.includes(periodicity) ) 
        )

    }, [values.selectedRows]);

    useEffect(() => {

        if (!values.periodicity){
            handleChange(FORM_KEY_PERIODICITY)(kpiCommonPeriodicities[kpiCommonPeriodicities.length-1]);
        }

    }, [handleChange, kpiCommonPeriodicities, values.periodicity]);
        
    return (
        <CardFormStepWrapper className="DashboardCardForm__periodicitySelection">
            <Form>
                <div className='DashboardCardForm__stepTitle'>
                    { intl.formatMessage({ id: 'dashboard_card_form_select_periodicity' }) }
                </div>
                <Row>
                    <Col span={12}>
                        <Form.Item label={intl.formatMessage({ id: 'select_periodicity_label' })}>
                            <Select
                                className="SelectPeriodicity__selector"
                                showArrow
                                allowClear
                                placeholder={intl.formatMessage({id: 'select_periodicity_label'})}
                                onChange={periodicity => handleChange(FORM_KEY_PERIODICITY)(periodicity)}
                                value={values.periodicity}
                            >
                                {kpiCommonPeriodicities.map(periodicity => (
                                    <Option key={periodicity}>{intl.formatMessage({ id: `select_periodicity_${periodicity}` })}</Option>
                                ))}
                            </Select>
                        </Form.Item>        
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label={intl.formatMessage({ id: 'select_periodicity_number_label' })}>
                            <CustomInput.Number 
                                autoFocus 
                                value={values.periodsNumber} 
                                min={1} 
                                max={maxPeriodSelection} 
                                onChange={ (periodsNumber) => { 
                                    handleChange(FORM_KEY_PERIODSNUMBER)(periodsNumber); 
                                }}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </CardFormStepWrapper>
    )
}

export default injectIntl(SelectPeriodicity);
