import React from "react";
import CustomButton from "components/CustomButton";

import { Row, Col, Tag } from "antd";

const Manager = ({
  intl,
  tags,
  onNewTag,
  onGoToVisor,
  onDeleteTag,
}) => {
  return (
    <div className="CustomMultiTagSelector">
      <div className="CustomMultiTagSelector-values">
        {tags.map((tag) => (
          <Tag
            className="tag"
            key={tag}
            data-testid={`tag_${tag}`}
            closable
            color="default"
            onClose={() => onDeleteTag(tag)}
          >
            {tag}
          </Tag>
        ))}
      </div>
      <div className="CustomMultiTagSelector-buttons">
        <Row gutter={5} justify="end" style={{ width: "100%" }}>
          <Col>
            <CustomButton key="submit" type="primary" onClick={onGoToVisor}>
              {intl.formatMessage({
                id: "custom_multi_tag_selector_view_tags",
              })}
            </CustomButton>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Manager;
