import React, {
  useCallback,
} from 'react';
import { withRouter } from "react-router";

import FilesTab from './FilesTabs/Files';
import FilesDownloadTab from './FilesTabs/FilesDownload';

const DEFAULT_TAB = 'files';


const Files = ({
  history,
  match,
}) => {

  const activeTab = match.params.category || DEFAULT_TAB;

  const setActiveTab = useCallback((tab) => {
    history.push(`/data/files/${tab}`);
  }, [
    history,
  ]);

  const handleDownload = useCallback(() => {
      setActiveTab('downloads');
  }, [
    setActiveTab,
  ]);

  return (
    <div className="DataManagement__filesRoot">

    {
      activeTab === 'files' &&
      <FilesTab onDownload={handleDownload}/>
    }

    {
      activeTab === 'downloads' &&
      <FilesDownloadTab />
    }


    </div>
  );
}

export default withRouter(Files)
