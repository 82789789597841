import React from 'react';
import ActionsForAllOrgs from './ActionsForAllOrgs';
import ActionsForCurrentOrg from './ActionsForCurrentOrg';
import './style.less';

const ActionsOverCheckedItems = ({
  checkedKPIsSlugs,
  checkedKpis,
  checkedCategories,
  organizationTree,
  allSDGs = [],
  commonSDGs,
  allESGs,
  allOrgs,
  commonOrgs,
  allPeriodicities,
  commonPeriodicities,
  allAnnualStartDate
}) => {

  return (
    <>
      <ActionsForAllOrgs
        checkedKPIsSlugs={checkedKPIsSlugs}
        checkedKpis={checkedKpis}
        checkedCategories={checkedCategories}
        organizationTree={organizationTree}
        allSDGs={allSDGs}
        commonSDGs={commonSDGs}
        allESGs={allESGs}
        allOrgs={allOrgs}
        commonOrgs={commonOrgs}
      />
      <ActionsForCurrentOrg
        checkedKpis={checkedKpis}
        checkedCategories={checkedCategories}
        checkedKPIsSlugs={checkedKPIsSlugs}
        allPeriodicities={allPeriodicities}
        commonPeriodicities={commonPeriodicities}
        allAnnualStartDate={allAnnualStartDate}
      />
    </>
  )
}

export default ActionsOverCheckedItems