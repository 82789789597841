import React from 'react';
import { injectIntl } from 'react-intl';
import {Alert} from 'antd';
import {
  StopOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import './style.less';

const IconByCode = {
  '422': ClockCircleOutlined,
  default: StopOutlined,
};

const Error = ({intl, code, description, message = 'ERROR', type = 'error'}) => {
    const t = intl.messages;

    const Icon = IconByCode[code] || IconByCode.default;

    return (
        <Alert
            className="Alert"
            message={t[`error_message_${code}`] || message}
            description={t[`error_description_${code}`] || description || code}
            type={type}
            showIcon
            icon={<Icon />}
        />
    )
};

export default injectIntl(Error);
