import React, { useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertToUnit } from 'utils/units';

import './style.less';

const NO_OP = a => a;


const Variance = ({
  intl,
  availableUnits,
  kpiValue,
  previousKpiValue,
}) => {
  const t = intl.messages;

  const percentChange = useMemo(() => {
    if(!kpiValue?.value || !previousKpiValue?.value) {
      return;
    }

    const baseUnit = availableUnits?.find(unit => unit.is_base);
    if(baseUnit?.is_currency && kpiValue.unit !== previousKpiValue.unit) {
      // We don't want to convert currency
      return;
    }

    const convertToBase = baseUnit ? convertToUnit(baseUnit.slug, availableUnits) : NO_OP;

    const baseValue = convertToBase(kpiValue);
    const basePreviousValue = convertToBase(previousKpiValue);

    return parseFloat((
      100 * (baseValue.value - basePreviousValue.value) / basePreviousValue.value
    ).toFixed(2));
  }, [
    kpiValue,
    previousKpiValue,
    availableUnits,
  ]);

  const comparison = useMemo(() => {
    if(isNaN(percentChange)) {
      return;
    }

    let icon, className, text;

    if(percentChange === 0) {
      className = 'compare_equal';
      text = t.variance_equal;
    } else if(Math.sign(percentChange) === -1) {
      icon = 'arrow-down';
      className = 'compare_lower';
      text = `${percentChange} %`;
    } else {
      icon = 'arrow-up';
      className = 'compare_greater';
      text = `${percentChange} %`;
    }

    return {
      icon,
      className,
      text,
    };
  }, [
    t,
    percentChange,
  ]);

  if(!comparison) {
    return '';
  }

  return (
    <div className={`Variance ${comparison.className}`}>
      { comparison.icon &&
      <FontAwesomeIcon
        icon={ comparison.icon }
        size="sm"
      />
      }
      { ' ' }
      { comparison.text }
    </div>
  )
}

export default Variance;
