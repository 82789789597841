const DEFAULT_START = '{';
const DEFAULT_END = '}';

const callReplacer = (replacer, match) => {
  if(typeof replacer === 'string') {
    return replacer
  }

  if(typeof replacer === 'function') {
    return replacer(match);
  }

  return '';
};

class NonRecursiveBracesReplacer {
  constructor(start, end) {
    this.start = start || DEFAULT_START;
    this.end = end || DEFAULT_END;
  }

  [Symbol.replace](string, replacer) {
    let result = '';
    let match = '';
    let depth = 0;

    for(let index = 0 ; index < string.length; index++) {
      const char = string.charAt(index);
      if(char === this.end) {
        depth -= 1;
        match += char;
        if(depth <= 0) {
          // Found a match, replace it and
          result += callReplacer(replacer, match);
          match = '';
        }
        continue;
      }
      if(char === this.start) {
        depth += 1;
      }
      if(depth > 0) {
        match += char;
      } else {
        result += char;
      }
    }
    if(match) {
      result += callReplacer(replacer, match);
    }

    return result;
  }
}

export default NonRecursiveBracesReplacer;

