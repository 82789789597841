import React from "react";
import styled from "@emotion/styled";

interface ButtonGroupProps {
  /**
   * Child components
   */
  children?: React.ReactNode;
}

const defaultPrimaryColor = "#147F74";

const StyledButtonGroup = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  .button--main {
    align-items: center;
    background-color: ${(props) =>
      props.theme["@primary-color"] || defaultPrimaryColor};
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 16px 0 0;
    padding: 8px 8px;
    svg {
      margin-right: 6px;
    }
    &:hover {
      background-color: ${(props) =>
        `${props.theme["@primary-color"] || defaultPrimaryColor}e3`};
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .button--main-secondary {
    align-items: center;
    background-color: #fff;
    border: 1px solid
      ${(props) => props.theme["@primary-color"] || defaultPrimaryColor};
    border-radius: 4px;
    box-sizing: border-box;
    color: ${(props) => props.theme["@primary-color"] || defaultPrimaryColor};
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 16px 0 0;
    padding: 8px;
    svg {
      margin-right: 6px;
    }
    &:hover {
      border-color: ${(props) =>
        `${props.theme["@primary-color"] || defaultPrimaryColor}e3`};
      color: ${(props) =>
        `${props.theme["@primary-color"] || defaultPrimaryColor}e3`};
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .button--primary {
    align-items: center;
    background-color: ${(props) =>
      props.theme["@primary-color"] || defaultPrimaryColor};
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 16px 0 0;
    padding: 4px 8px;
    svg {
      margin-right: 6px;
    }
    &:hover {
      background-color: ${(props) =>
        `${props.theme["@primary-color"] || defaultPrimaryColor}e3`};
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .button--secondary {
    align-items: center;
    background-color: #fff;
    border: 1px solid
      ${(props) => props.theme["@primary-color"] || defaultPrimaryColor};
    border-radius: 4px;
    box-sizing: border-box;
    color: ${(props) => props.theme["@primary-color"] || defaultPrimaryColor};
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 16px 0 0;
    padding: 4px 8px;
    svg {
      margin-right: 6px;
    }
    &:hover {
      border-color: ${(props) =>
        `${props.theme["@primary-color"] || defaultPrimaryColor}e3`};
      color: ${(props) =>
        `${props.theme["@primary-color"] || defaultPrimaryColor}e3`};
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .button--tertiary {
    align-items: center;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    color: ${(props) => props.theme["@primary-color"] || defaultPrimaryColor};
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0 16px 0 0;
    padding: 4px 8px;
    svg {
      margin-right: 6px;
    }
    &:hover {
      color: ${(props) =>
        `${props.theme["@primary-color"] || defaultPrimaryColor}e3`};
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

/**
 * Button Group wraper with button basic styles
 */
export const ButtonGroup = ({ children, ...props }: ButtonGroupProps) => {
  return <StyledButtonGroup {...props}>{children}</StyledButtonGroup>;
};
