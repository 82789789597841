import React, { Fragment } from 'react';
import { injectIntl } from "react-intl";

import {
  Row,
  Col,
  Tag,
  Input,
} from 'antd';

const { CheckableTag } = Tag;
const { TextArea } = Input;

const SurveyInput = ({
  intl,
  subjects,
  subjectIndex,
  responses,
  setResponses,
  mainOrgSlug,
}) => {
  const t = intl.messages;
  const subject = subjects[subjectIndex];
  const response = responses[subjectIndex] || {};

  return (
    <Fragment>
      <Row>
        <Col span={12}>
          <h3>{t.subject}</h3>
        </Col>
        <Col span={12}>
          <h3>{t.issue}</h3>
        </Col>
        <Col span={12}>
          <h3>
            { subject.subject }
          </h3>
        </Col>
        <Col span={12}>
          <h3>
            { subject.issue }
          </h3>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        {[1, 2, 3, 4, 5].map(i =>
          <CheckableTag
            key={i} checked={response.weight === i} color="green"
            onChange={
              () => setResponses([
                ...responses.slice(0, subjectIndex),
                {...response, weight: i},
                ...responses.slice(subjectIndex + 1)
              ])
            }
          >{i}</CheckableTag>
        )}
      </Row>
      <Row type="flex" justify="center">
        <TextArea
          rows={2} cols={10} placeholder={t.comment}
          value={response.comment}
          onChange={
            (e) => setResponses([
              ...responses.slice(0, subjectIndex),
              {...response, comment: e.target.value},
              ...responses.slice(subjectIndex + 1)
            ])
          }
        />
      </Row>
    </Fragment>
  );
}

export default injectIntl(SurveyInput);
