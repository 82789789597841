import { loop, Cmd } from 'redux-loop';

const TARGET_BASE = 'active_wait__';
const DEFAULT_TIMEOUT = 2000; // TODO: Exponential backoff
const initialState = []; // No ongoing requests

const waitDone = (endpoint) => ({
  type: 'ACTIVE_WAIT_COMPLETE',
  endpoint,
});

const waitFailed = (endpoint) => ({
  type: 'ACTIVE_WAIT_FAILED',
  endpoint,
});

const launchRequest = (endpoint) => ({
  type: 'API_CALL_REQUEST',
  target: `${TARGET_BASE}${endpoint}`,
  endpoint,
  method: 'GET',
  body: null,
});

const delayValue = async (value, delay) => {
  await new Promise(resolve => setTimeout(resolve, delay));
  return value;
};


const reducer = (state = initialState, action) => {
  let endpoint = '';
  switch(action.type) {
    case 'ACTIVE_WAIT_REQUEST':
      endpoint = action.endpoint;
      if(state.find(el => el === endpoint)) {
        return state; // Already running for this endpoint...
      }

      return loop(
        [ ...state, endpoint ],
        Cmd.action(launchRequest(endpoint)));
    case 'API_CALL_COMPLETE':
      if(!action.response || !(action.response.target || '').startsWith(TARGET_BASE)) return state;
      endpoint = action.response.target.replace(TARGET_BASE, '');

      switch(action.response.result) {
        case 'created': // Meaning: we are still waiting in pg-boss
        case 'active': // Meaning: we are still waiting in pg-boss
        case 'retry': // Meaning: we are still waiting in pg-boss
        case 'pending': // Meaning: we are still waiting in not-pg-boss
          return loop(state, Cmd.run(delayValue,
                                     {
                                       successActionCreator: launchRequest,
                                       args: [ endpoint, DEFAULT_TIMEOUT ],
                                     }))
        case 'completed': // Meaning we are done in pg-boss
        case 'done': // Meaning we are done in not-pg-boss
          return loop(
            state.filter(el => el !== endpoint),
            Cmd.action(waitDone(endpoint))
          );
        case 'failed': // Meaning we failed in both pg-boss and not-pg-boss
        case 'cancelled': // Meaning we failed in pg-boss
        case 'expired': // Meaning we failed in pg-boss
          return loop(
            state.filter(el => el !== endpoint),
            Cmd.action(waitFailed(endpoint))
          );
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.response || !(action.response.target || '').startsWith(TARGET_BASE)) return state;
      endpoint = action.response.target.replace(TARGET_BASE, '');

      // TODO: MAybe consider API errors as not errors for a while, but rather bad connection, etc.
      return loop(
        state.filter(el => el !== endpoint),
        Cmd.action(waitFailed(endpoint))
      );
    default:
      return state;
  }
}

export {
  reducer as active_wait,
};
