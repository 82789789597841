import React, { useState, useCallback } from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import {
  Select,
} from 'antd';
const { Option } = Select;

const UsersSearchSelector = ({
  intl,
  usersData,
  onSelectUser,
  disabled = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = useCallback((valueSelected) => {
    valueSelected && onSelectUser(valueSelected);
  }, [onSelectUser]); 

  return (
      <div className="UsersSearchSelector">
        <Select
          className="UsersSearchSelector__selector"
          disabled={disabled}
          placeholder={intl.formatMessage({id: 'step_3_search_users'})}
          searchValue={searchTerm}
          onSearch={setSearchTerm}
          value={null}
          onChange={handleChange}
          filterOption={(input, option) => (
            option.name.toLowerCase().includes(input.toLowerCase()) || option.email.toLowerCase().includes(input.toLowerCase())
          )}
          showArrow={false}
          allowClear
          showSearch
        >
          {usersData.map(user => (
            <Option
              key={user.member_slug}
              name={user.member_name}
              email={user.member_email}
            >
              {user.member_name} - {user.member_email}
            </Option>
          ))}
        </Select>
      </div>
  );
};

UsersSearchSelector.propTypes = {
  disabled: PropTypes.bool,
  onSelectUser: PropTypes.func.isRequired,
  usersData: PropTypes.array.isRequired,
};

export default injectIntl(UsersSearchSelector);