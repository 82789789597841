import React from 'react'
import {
  Input,
  InputNumber,
} from 'antd'
import './style.less'

const CustomInput = (props) => (
  <Input className='CustomInput' {...props}/>
)

CustomInput.Password = (props) => (
  <Input.Password className='CustomInput' {...props}/>
)

CustomInput.TextArea = (props) => (
  <Input.TextArea className='CustomInput' {...props}/>
)

CustomInput.Number = (props) => (
  <InputNumber className='CustomInput' {...props}/>
)

export default CustomInput
