import config from 'config';

// URL generators for forms
const getAttachThirdPartyFileUrl = (
  token,
  organization_slug,
  kpi_slug,
  period,
  data_request_id,
  topLevelOrg,
) => {
  return `${config.API_URL}/external/${token}/${kpi_slug}/${period}/${data_request_id}/attachment?organization_slug=${organization_slug}&top_level_org=${topLevelOrg}`;
};

const uploadKpiThirdPartyAttachmentSuccess = (
  data_request_id,
  attachment,
) => ({
  type: 'ADD_THIRD_PARTY_KPI_ATTACHMENT',
  data_request_id,
  attachment,
});

const deleteKpiAttachment = (
  token,
  topLevelOrg,
  suborganization_slug,
  kpi_slug,
  period,
  filename,
  data_request_id
) => ({
  type: 'DELETE_THIRD_PARTY_KPI_ATTACHMENT',
  token,
  topLevelOrg,
  suborganization_slug,
  kpi_slug,
  period,
  filename,
  data_request_id,
})

const initializeAttachmentsUpload = attachmentIds => ({
  type: 'THIRD_PARTY_UPLOAD_ATTACHMENTS',
  attachmentIds,
});

const startUploadingAttachments = () => ({
  type: 'THIRD_PARTY_UPLOAD_ATTACHMENTS_START',
});

const attachmentUploaded = attachmentId => ({
  type: 'THIRD_PARTY_UPLOAD_ATTACHMENT_UPLOADED',
  attachmentId,
});

const attachmentUploadFailed = attachmentId => ({
  type: 'THIRD_PARTY_UPLOAD_ATTACHMENT_UPLOAD_FAILED',
  attachmentId,
});

// Action creators proper
const submit = ({
  token,
  topLevelOrg,
  suborganization_slug,
  kpi_slug,
  data_request_id,
  value,
  comment,
}) => ({
  type: 'SUBMIT_THIRD_PARTY',
  token,
  topLevelOrg,
  suborganization_slug,
  kpi_slug,
  data_request_id,
  value,
  comment,
});

const setTaxonomies = taxonomies => ({
  type: 'THIRD_PARTY_TAXONOMIES',
  taxonomies,
});

// NEW:

const saveQueryFilters = queryFilters => ({
  type: 'THIRD_PARTY_SAVE_QUERY_FILTERS',
  queryFilters,
});

const fetchConfigData = (
  token,
  topLevelOrg,
) => ({
  type: 'THIRD_PARTY_CONFIG_DATA_REQUEST',
  token,
  topLevelOrg,
});

const fetchRequestsStatusProgress = (
  token,
  topLevelOrg,
  filters
) => ({
  type: 'THIRD_PARTY_STATUS_PROGRESS_REQUEST',
  token,
  topLevelOrg,
  filters
});

const fetchSuborgsDataRequests = (
  token,
  topLevelOrg,
  suborganizations,
  filters
) => ({
  type: 'THIRD_PARTY_SUBORGANIZATIONS_DATA_REQUESTS_REQUEST',
  token,
  topLevelOrg,
  suborganizations,
  filters
});

const fetchDataRequests = (
  token,
  topLevelOrg,
  suborganization,
  page,
  filters
) => ({
  type: 'THIRD_PARTY_DATA_REQUESTS_REQUEST',
  token,
  topLevelOrg,
  suborganization,
  page,
  filters
});

const fetchRequestDetail = (
  token,
  topLevelOrg,
  suborganization,
  requestId
) => ({
  type: 'THIRD_PARTY_REQUEST_DETAIL_REQUEST',
  token,
  topLevelOrg,
  suborganization,
  requestId
});

export {
  saveQueryFilters,
  fetchConfigData,
  fetchRequestsStatusProgress,
  fetchSuborgsDataRequests,
  fetchDataRequests,
  fetchRequestDetail,
  getAttachThirdPartyFileUrl,
  submit,
  setTaxonomies,
  deleteKpiAttachment,
  uploadKpiThirdPartyAttachmentSuccess,
  initializeAttachmentsUpload,
  startUploadingAttachments,
  attachmentUploaded,
  attachmentUploadFailed,
};
