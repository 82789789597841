import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { injectIntl} from 'react-intl';

import useForm from 'utils/useForm';
import validation from './validation';
import CustomButton from 'components/CustomButton';

import {
  Select,
  Input,
  Form,
  Row,
} from 'antd';

import './style.less';

const { Option } = Select;
const { TextArea } = Input;


const GroupOfInterestForm = ({
  intl,
  error,
  onSubmit,
  defaultValues,
  ...props
}) => {
  const t = intl.messages;

  const errorRefs = {
    name: useRef(null),
    email: useRef(null),
  };

  const submitForm = () => {
    onSubmit(values);
  };

  const validateForm = useMemo(() => validation(t, props.formType), [ t, props.formType ]);

  const defaults = useCallback(() => defaultValues, [defaultValues]);

  const {
    values,
    handleChangeEvent,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    validationDefaults: {},
    setNames: [],
    defaultValues: defaultValues,
    defaultSetValues: {}
  });

  const showError = useCallback((name) => (!isDirty(name) && errors[name]) || '', [isDirty, errors]);
  const getValue = useCallback((name) => values[name] ? values[name] : defaults[name], [values, defaults]);

  useEffect(() => {
    if(errors._firstError && errorRefs[errors._firstError]) {
      errorRefs[errors._firstError].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    errors,
  ]);

  return (
    <Form
      className="GroupOfInterestForm"
      id="group_of_interest_form"
      onFinish={ handleSubmit }
      layout="vertical"
    >
      <div ref={ errorRefs.name }></div>
      <Form.Item
        hasFeedback
        required
        validateStatus={ showError('name') ? 'error' : '' }
        help={ showError('name') }
        className="GroupOfInterestForm__field"
      >
        <Input
          allowClear
          name="name"
          placeholder={ t.goi_member_name }
          value={ getValue('name') }
          onChange={ handleChangeEvent }
        />
      </Form.Item>

      {
        props.targetItemType !==  'member' &&
        <>
          <div ref={ errorRefs.type }></div>
          <Form.Item
            hasFeedback
            required
            validateStatus={ showError('type') ? 'error' : '' }
            help={ showError('type') }
            className="GroupOfInterestForm__field"
          >
            <Select
              value={getValue('type')}
              onChange={handleChange('type')}
              placeholder={t.goi_type_placeholder}
            >
              {
                ['internal', 'external'].map(type => (
                  <Option key={type} value={type}>
                    {t[`goi_${type}`]}
                  </Option>
                ))
              }
            </Select>
          </Form.Item>
        </>
      }

      {
        props.targetItemType ===  'member' &&
        <>
          <div ref={ errorRefs.email }></div>
          <Form.Item
            hasFeedback
            required
            validateStatus={ showError('email') ? 'error' : '' }
            help={ showError('email') }
            className="GroupOfInterestForm__field"
          >
            <Input
              allowClear
              name="email"
              placeholder={ t.email }
              value={ getValue('email') }
              onChange={ handleChangeEvent }
            />
          </Form.Item>

          <div ref={ errorRefs.description }></div>
          <Form.Item
            hasFeedback
            required
            validateStatus={ showError('description') ? 'error' : '' }
            help={ showError('description') }
            className="GroupOfInterestForm__field"
          >
            <TextArea
              allowClear
              name="description"
              placeholder={ t.goi_member_description_placeholder }
              value={ getValue('description') }
              onChange={ handleChangeEvent }
            />
          </Form.Item>
        </>
      }
      <div className="GroupOfInterestForm__actions inline-button-container">
        <Row>
          <CustomButton onClick={ props.onCancel }>
            { t.cancel }
          </CustomButton>
          <CustomButton
            form="group_of_interest_form"
            type="primary"
            htmlType="submit"
          >
            { props.actionLabel }
          </CustomButton>
        </Row>
      </div>
    </Form>
  );
}


export default injectIntl(GroupOfInterestForm);
