import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import DepartmentForm from './DepartmentForm';
import ProfessionalGroupForm from './ProfessionalGroupForm';
import PositionForm from './PositionForm';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';
import Description from 'components/Description';

import {
  requestEqualityPlanDepartments,
  createEqualityPlanDepartment,
  updateEqualityPlanDepartment,
  deleteEqualityPlanDepartment,
  requestEqualityPlanProfessionalGroups,
  createEqualityPlanProfessionalGroup,
  updateEqualityPlanProfessionalGroup,
  deleteEqualityPlanProfessionalGroup,
  requestEqualityPlanPositions,
  createEqualityPlanPosition,
  updateEqualityPlanPosition,
  deleteEqualityPlanPosition,
} from 'actions/api';

import CustomTable from 'components/CustomTable';
import CustomButton from 'components/CustomButton';
import {
  Row,
  Col,
  Space,
  Tooltip,
  Popconfirm,
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';


const Determination = ({
  intl,
  history,
  plan,
  updatePlanProgress,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();
  const [currentDepartment, setCurrentDepartment] = useState();
  const [currentProfessionalGroup, setCurrentProfessionalGroup] = useState();
  const [currentPosition, setCurrentPosition] = useState();

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanDepartments(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanProfessionalGroups(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanPositions(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetchingDepartments,
    pushingDepartment,
    data: departments,
  } = useSelector(state => state.equality_plan_department);
  const {
    fetching: fetchingProfessionalGroups,
    pushing: pushingProfessionalGroup,
    data: professionalGroups,
  } = useSelector(state => state.equality_plan_professional_group);
  const {
    fetching: fetchingPositions,
    pushing: pushingPosition,
    data: positions,
  } = useSelector(state => state.equality_plan_position);

  const handleAddNewDepartment = useCallback(
    () => setCurrentDepartment({}),
    []
  );

  const handleEditDepartment = useCallback(
    (department) => {
      setCurrentDepartment({...department});
    },
    []
  );

  const handleSaveDepartment = useCallback(
    (data) => {
      if (data.id) {
        dispatch(
          updateEqualityPlanDepartment(
            organization.slug,
            suborganization.slug,
            plan.id,
            data.id,
            data,
          )
        );
      } else {
        dispatch(
          createEqualityPlanDepartment(
            organization.slug,
            suborganization.slug,
            plan.id,
            data,
          )
        );
      }
      updatePlanProgress('job_determination');
    },
    [dispatch, plan, organization, suborganization, updatePlanProgress]
  );

  const handleDeleteDepartment = useCallback(
    (data) => {
      dispatch(
        deleteEqualityPlanDepartment(
          organization.slug,
          suborganization.slug,
          plan.id,
          data.id,
        )
      );
    },
    [dispatch, plan, organization, suborganization]
  );

  const departmentColumns = useMemo(
    () => (
      [{
        title: t.equality_job_evaluation_determination_department_name,
        dataIndex: 'name',
      }, {
        title: t.equality_job_evaluation_determination_department_description,
        dataIndex: 'description',
        render: value => value ? <Description description={value} /> : '-'
      }, {
        title: t.equality_actions_table_actions,
        key: 'actions',
        render: (_, record) => (
          <Space size="middle">
            <Tooltip title={t.equality_actions_table_actions_edit}>
              <EditOutlined
                className='form-item-preview-icon'
                onClick={() => handleEditDepartment(record)}
              />
            </Tooltip>
            <Tooltip title={t.equality_actions_table_actions_delete}>
              <Popconfirm
                title={t.equality_job_evaluation_determination_department_delete_confirm}
                onConfirm={() => handleDeleteDepartment(record)}
                okText={t.yes}
                cancelText={t.no}
              >
                <DeleteOutlined
                  className='form-item-preview-icon'
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      }]
    ),
    [t, handleEditDepartment, handleDeleteDepartment]
  );

  const handleAddNewProfessionalGroup = useCallback(
    () => setCurrentProfessionalGroup({}),
    []
  );

  const handleEditProfessionalGroup = useCallback(
    (professional_group) => {
      setCurrentProfessionalGroup({...professional_group});
    },
    []
  );

  const handleSaveProfessionalGroup = useCallback(
    (data) => {
      if (data.id) {
        dispatch(
          updateEqualityPlanProfessionalGroup(
            organization.slug,
            suborganization.slug,
            plan.id,
            data.id,
            data,
          )
        );
      } else {
        dispatch(
          createEqualityPlanProfessionalGroup(
            organization.slug,
            suborganization.slug,
            plan.id,
            data,
          )
        );
      }
      updatePlanProgress('job_determination');
    },
    [dispatch, plan, organization, suborganization, updatePlanProgress]
  );

  const handleDeleteProfessionalGroup = useCallback(
    (data) => {
      dispatch(
        deleteEqualityPlanProfessionalGroup(
          organization.slug,
          suborganization.slug,
          plan.id,
          data.id,
        )
      );
    },
    [dispatch, plan, organization, suborganization]
  );

  const professionalGroupColumns = useMemo(
    () => (
      [{
        title: t.equality_job_evaluation_determination_professional_group_name,
        dataIndex: 'name',
      }, {
        title: t.equality_job_evaluation_determination_professional_group_description,
        dataIndex: 'description',
        render: value => value ? <Description description={value} /> : '-'
      }, {
        title: t.equality_actions_table_actions,
        key: 'actions',
        render: (_, record) => (
          <Space size="middle">
            <Tooltip title={t.equality_actions_table_actions_edit}>
              <EditOutlined
                className='form-item-preview-icon'
                onClick={() => handleEditProfessionalGroup(record)}
              />
            </Tooltip>
            <Tooltip title={t.equality_actions_table_actions_delete}>
              <Popconfirm
                title={t.equality_job_evaluation_determination_professional_group_delete_confirm}
                onConfirm={() => handleDeleteProfessionalGroup(record)}
                okText={t.yes}
                cancelText={t.no}
              >
                <DeleteOutlined
                  className='form-item-preview-icon'
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      }]
    ),
    [t, handleEditProfessionalGroup, handleDeleteProfessionalGroup]
  );

  const handleAddNewPosition = useCallback(
    () => setCurrentPosition({}),
    []
  );

  const handleEditPosition = useCallback(
    (position) => {
      setCurrentPosition({...position});
    },
    []
  );

  const handleSavePosition = useCallback(
    (data) => {
      if (data.id) {
        dispatch(
          updateEqualityPlanPosition(
            organization.slug,
            suborganization.slug,
            plan.id,
            data.id,
            data,
          )
        );
      } else {
        dispatch(
          createEqualityPlanPosition(
            organization.slug,
            suborganization.slug,
            plan.id,
            data,
          )
        );
      }
      updatePlanProgress('job_determination');
    },
    [dispatch, plan, organization, suborganization, updatePlanProgress]
  );

  const handleDeletePosition = useCallback(
    (data) => {
      dispatch(
        deleteEqualityPlanPosition(
          organization.slug,
          suborganization.slug,
          plan.id,
          data.id,
        )
      );
    },
    [dispatch, plan, organization, suborganization]
  );

  const positionColumns = useMemo(
    () => (
      [{
        title: t.equality_job_evaluation_determination_position_code,
        dataIndex: 'code',
      }, {
        title: t.equality_job_evaluation_determination_position_name,
        dataIndex: 'name',
      }, {
        title: t.equality_job_evaluation_determination_position_department,
        dataIndex: 'department_name',
      }, {
        title: t.equality_job_evaluation_determination_position_professional_group,
        dataIndex: 'professional_group_name',
      }, {
        title: t.equality_actions_table_actions,
        key: 'actions',
        render: (_, record) => (
          <Space size="middle">
            <Tooltip title={t.equality_actions_table_actions_edit}>
              <EditOutlined
                className='form-item-preview-icon'
                onClick={() => handleEditPosition(record)}
              />
            </Tooltip>
            <Tooltip title={t.equality_actions_table_actions_delete}>
              <Popconfirm
                title={t.equality_job_evaluation_determination_position_delete_confirm}
                onConfirm={() => handleDeletePosition(record)}
                okText={t.yes}
                cancelText={t.no}
              >
                <DeleteOutlined
                  className='form-item-preview-icon'
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      }]
    ),
    [
      t,
      handleEditPosition,
      handleDeletePosition,
    ]
  );

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  if (
    fetchingDepartments
    || !departments
    || fetchingProfessionalGroups
    || !professionalGroups
    || fetchingPositions
    || !positions
  ) {
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_determination}
        handleBackOrSkip={handleBack}
      />
      <Row>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_determination_departments}</h2>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={24}>
          <CustomButton
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleAddNewDepartment}
          >
            {t.equality_job_evaluation_determination_add}
          </CustomButton>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="form-input-wrapper">
          { currentDepartment &&
            <DepartmentForm
              visible={true}
              plan={plan}
              department={currentDepartment}
              closeForm={() => setCurrentDepartment()}
              handleSave={handleSaveDepartment}
              pushing={pushingDepartment}
            />
          }
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            columns={departmentColumns}
            dataSource={departments}
            rowKey='id'
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_determination_professional_groups}</h2>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={24}>
          <CustomButton
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleAddNewProfessionalGroup}
          >
            {t.equality_job_evaluation_determination_add}
          </CustomButton>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="form-input-wrapper">
          { currentProfessionalGroup &&
            <ProfessionalGroupForm
              visible={true}
              plan={plan}
              professionalGroup={currentProfessionalGroup}
              closeForm={() => setCurrentProfessionalGroup()}
              handleSave={handleSaveProfessionalGroup}
              pushing={pushingProfessionalGroup}
            />
          }
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            columns={professionalGroupColumns}
            dataSource={professionalGroups}
            rowKey='id'
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_determination_positions}</h2>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={24}>
          <CustomButton
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleAddNewPosition}
          >
            {t.equality_job_evaluation_determination_add}
          </CustomButton>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="form-input-wrapper">
          { currentPosition &&
            <PositionForm
              visible={true}
              plan={plan}
              position={currentPosition}
              departments={departments}
              professionalGroups={professionalGroups}
              closeForm={() => setCurrentPosition()}
              handleSave={handleSavePosition}
              pushing={pushingPosition}
            />
          }
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            columns={positionColumns}
            dataSource={positions}
            rowKey='id'
          />
        </Col>
      </Row>
    </>
  );
}

export default withRouter(injectIntl(Determination));
