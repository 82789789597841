import React, {
    useState,
    useCallback,
    useEffect,
    useMemo
  } from 'react';
  import { useSelector, useDispatch } from "react-redux";
  import { injectIntl } from 'react-intl';
  import A from 'components/A';
  import { FILTERS_COMPONENT_TYPES_CATALOG, FILTER_TYPES } from 'hooks/useScreenFilters/constants';
  import FiltersPanel from 'components/FiltersPanel';
  import SearchFilter from 'components/SearchFilter';
  import AplanetIcon from 'components/AplanetIcon';
  import useOrganizations from 'utils/useOrganizations';
  import { requestOrganizationDetail } from 'actions/api';
  //import { Progress } from 'antd';
  import './style.less';
   
  const STATUS_OPTIONS = ['downloaded','not_downloaded','bookmarked','not_bookmarked'];

 
  const OrganizationArchiveFilters = ({
    intl,
    setFilterState,
    filterState,
    resetAllFilters,
    areFiltersSet,
    isTextFilterSet,
    onRefresh
  }) => {
    //const t = intl.messages;
    const dispatch = useDispatch();
    // Search
    const [hasToClearKpiSearch, setHasToClearKpiSearch] = useState(false);
    // const [onCountdown, setOnCountdown] = useState(false);
    // const [countdown, setCountdown] = useState(0);

    // Filters
    const [periodDateFilterValues, setPeriodDateFilterValues] = useState(filterState.period_date_filter);
    const [statusFilterValues, setStatusFilterValues] = useState(filterState.archive_status_filter);
    const [userFilterValues, setUserFilterValues] = useState(filterState.user_filter);
  
  
    // Data
    const {
      organization,
      suborganization,
    } = useOrganizations();
   
 
    const {
      data: profile
    } = useSelector(state => state.profile);
  
    const {
      data: organizationDetail,
    } = useSelector((state) => state.organization_detail);
  
  const members = useMemo(()=>{
    let users = organizationDetail?.users || [], external_users = organizationDetail?.external_users || [];
    const patchedProfile = [{
      member_slug : profile.slug,
      member_name: profile.name,
      member_email: profile.email,
      member_status: profile.status,
    }];
    users = [...users,...patchedProfile, ...external_users];
  
    return users
    .filter(({member_status}) => member_status !== 'deleted')
    .map(user => {
      return {
        slug: user.member_slug ,
        name: `${user.member_name} (${user.member_email})`,
      }  
    })
    .sort((a,b) => {
      const A = a.name.toUpperCase();
      const B = b.name.toUpperCase();
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    });
  
  },[organizationDetail, profile]);
  
    useEffect(() => {
    
        dispatch(
          requestOrganizationDetail(organization.slug, suborganization.slug)
        )
    }, [organization, suborganization, dispatch])
  
    const filtersConfig = useMemo(()=>(
      [
        {
          columnTitle: intl.formatMessage({id: `datamanagement_download_filter_column_title_users_dates`}),
          filters: [
            {
                filterType: FILTERS_COMPONENT_TYPES_CATALOG.multiSelect,
                filterTitle: intl.formatMessage({id: `datamanagement_download_filter_title_users`}),
                title: intl.formatMessage({id: `datamanagement_download_filter_title_users`}),
                selected: userFilterValues,
                onSelect: setUserFilterValues,
                options : members
              },
              {
                filterType: FILTERS_COMPONENT_TYPES_CATALOG.date,
                filterTitle: intl.formatMessage({id: `datamanagement_download_filter_title_date`}),
                picker: 'date',
                format: 'YYYY-MM-DD',
                suffixIcon: (<AplanetIcon name="Calendar" />),
                value: periodDateFilterValues,
                onChange: setPeriodDateFilterValues,
              },
          ]
        },
        {
          columnTitle: intl.formatMessage({id: `datamanagement_download_filter_column_title_status`}),
          filters: [
            {
              filterType: FILTERS_COMPONENT_TYPES_CATALOG.list,
              filterTitle: intl.formatMessage({id: `datamanagement_download_filter_title_status`}),
            listOptions: STATUS_OPTIONS.map(option => ({
              value: option,
              title: intl.formatMessage({id: `organization_archive_${option}`}),
              label: (<span className={`Files__periodicity ${option}`}>{intl.formatMessage({id: `organization_archive_${option}`})}</span>)
            })),
            setSelectedOptions: setStatusFilterValues,
            selectedOptions: statusFilterValues,
          },
        ]
      },

      ]),[
        intl,
        periodDateFilterValues,
        statusFilterValues,
        userFilterValues,
        members
      ]);
  
    const onSetFilters = useCallback(() => {
      setFilterState(FILTER_TYPES.archive_status_filter)(statusFilterValues);
      setFilterState(FILTER_TYPES.user_filter)(userFilterValues);
      setFilterState(FILTER_TYPES.period_date_filter)(periodDateFilterValues);
    },[
      statusFilterValues,
      userFilterValues,
      periodDateFilterValues,
      setFilterState
    ]);
  
    const onCancelFilter = useCallback(() => {
      setStatusFilterValues(filterState.archive_status_filter);
      setUserFilterValues(filterState.user_filter);
      setPeriodDateFilterValues(filterState.period_date_filter);
    },[
      filterState.archive_status_filter,
      filterState.user_filter,
      filterState.period_date_filter
    ]);
  
  
  
    const onClearSearchAndFilters = useCallback(() => {
      setStatusFilterValues([]);
      setUserFilterValues([]);
      setPeriodDateFilterValues([]);
      setHasToClearKpiSearch(true);
      resetAllFilters();
    }, [
      resetAllFilters,
    ]);
  
    // const launchCountdownRefresh = useCallback(()=>{
    //   setOnCountdown(true);
    //   onRefresh();
    //   const intervalId = setInterval(function(){
    //     setCountdown(prev => {
    //       if(prev < 100){
    //         return prev+(10/3);
    //       }else if (prev >= 100){
    //         setOnCountdown(false);
    //         clearInterval(intervalId);
    //         return 0;
    //       }
    //     });
    //   }, 1000);
    // },[
    //   onRefresh
    // ]);

    return (
      <div className="Archives__container">
        <div className="Archives__table-filters">
          <FiltersPanel
            areFiltersSet={areFiltersSet}
            onCancelFilter={onCancelFilter}
            onSetFilters={onSetFilters}
            filtersConfig={filtersConfig}
            columnSpan={{ xs: 24, sm: 24, md: 12, lg: 12 }}
          />
          <SearchFilter
            textFilter={filterState.text_filter}
            onSetSearch={(searchText) => setFilterState(FILTER_TYPES.text_filter)(searchText)}
            hasToClearSearch={hasToClearKpiSearch}
            setHasToClearKpiSearch={setHasToClearKpiSearch}
            defaultOpen={isTextFilterSet}
            placeholder={intl.formatMessage({id: `datamanagement_files_filter_search__placeholder`})}
            autoFocus
          />
  
          <div className='Archives__remove-filters-wrapper'>
            <A
              className='Archives__remove-filters'
              onClick={onClearSearchAndFilters}
              disabled={!areFiltersSet && !isTextFilterSet}
            >
              {intl.formatMessage({id: 'datamanagement_files_filter_delete_all'})}
            </A>
          </div>
        </div>
        <div className="Archives__refresh_container">


        {/* <button
          className='Archives__refresh__button'
          onClick={launchCountdownRefresh}
          disabled={onCountdown}
        >
          { !onCountdown ?
              <AplanetIcon name='Refresh' size="18px" />
            :
              <Progress className="Archives__refresh__progress" type="circle" percent={countdown} showInfo={false} status="exception" width={18} strokeColor="#fff" strokeWidth="12"/>
          }
          <span>{t.datamanagement_download_button_refresh}</span>
        </button> */}
        </div>
      </div>
    );
  }
  
  export default injectIntl(OrganizationArchiveFilters);
  
