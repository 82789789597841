import {isEmpty, isNull, isUndefined} from 'lodash';

const RECURRENT_RECURRENCY = 'recurrent';
const ONE_TIME_RECURRENCY = 'one_time';

const MAX_PERIODS_SELECTED = 15;

export const validateStep2  = (intl, values) => {
    const {
        recurrency,
        periodicities,
        periods,
        notif_number,
        notif_relative,
        notif_time,
        notif_date,
    } = values;

    let errors = {};

    if (isEmpty(recurrency)) {
        errors.recurrency = intl.formatMessage({id: 'step_2_error_recurrency_empty'});
    }

    if (isEmpty(periodicities)) {
        errors.periodicities = intl.formatMessage({id: 'step_2_error_periodicities_empty'});
    }

    if (isEmpty(periods)) {
        errors.periods = intl.formatMessage({id: 'step_2_error_periods_empty'});
    }

    if (periods.length > MAX_PERIODS_SELECTED) {
        errors.periods_limit = intl.formatMessage({id: 'step_2_error_periods_limit'});
    }

    if (recurrency === RECURRENT_RECURRENCY) {
        const periodicitiesInPeriods = periods.map(({type}) => type);
        const allPeriodicitiesHasPeriods = periodicities.every(periodicity => periodicitiesInPeriods.includes(periodicity));
        if (!allPeriodicitiesHasPeriods) {
            errors.periods = intl.formatMessage({id: 'step_2_error_periods_empty'});
        }

        if (isNull(notif_number) || isUndefined(notif_number)) {
            errors.notif_number = intl.formatMessage({id: 'step_2_error_notif_number_empty'});
        }

        if ((!isNull(notif_number) && !isUndefined(notif_number)) && (notif_number > 100 || notif_number < 1)) {
            errors.notif_number_value = intl.formatMessage({id: 'step_2_error_notification_number'});
        }

        if (isNull(notif_time) || isUndefined(notif_time)) {
            errors.notif_time = intl.formatMessage({id: 'step_2_error_notif_time_empty'});
        }
    
        if (isNull(notif_relative) || isUndefined(notif_relative)) {
            errors.notif_relative = intl.formatMessage({id: 'step_2_error_notif_relative_empty'});
        }
    }

    if (recurrency === ONE_TIME_RECURRENCY) {
        if (isEmpty(notif_date)) {
            errors.notif_date = intl.formatMessage({id: 'step_2_error_notif_date_empty'});
        }
    }

    return errors;
};