import React, { useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import { Loading } from 'tsComponents/emptyStates/Loading';
import EqualityPlanHeader from 'components/EqualityPlan/Header';
import ActionsCalendar from 'components/EqualityPlanCreation/ActionsCalendar';
import CustomButton from 'components/CustomButton';

import {
  Row,
} from 'antd';

import './style.less';


const planStage = 'calendar_followup';

const EqualityPlanCalendar = ({
  intl,
  history,
  planId,
  actions,
  fetchActions,
  fetchingActions,
  updatePlanProgress,
}) => {
  const t = intl.messages;

  useEffect(() => {
    fetchActions();
  }, [
    fetchActions, planId,
  ]);

  const handleOnClickReviewed = useCallback(
    () => {
      updatePlanProgress(planStage);
      history.push(`/equality-plan/${planId}/`);
    },
    [history, updatePlanProgress, planId]
  );

  if (fetchingActions) {
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={planId}
        title={t[`equality_calendar_followup`]}
        isDocumentManager={false}
      />
      <Row type="flex" justify="end">
        <CustomButton
          type="primary"
          onClick={handleOnClickReviewed}
        >
          {t.equality_plan_reviewed}
        </CustomButton>
      </Row>
      <ActionsCalendar actions={actions} />
    </>
  )
};

export default withRouter(injectIntl(EqualityPlanCalendar));