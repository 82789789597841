import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { uniq } from "lodash";

import {
  DATA_REQUEST_STATUS,
  DATA_REQUEST_RECURRENCE,
  getPeriodsAndKpisOfDataRequests
} from 'utils/dataRequests';
import AplanetIcon from 'components/AplanetIcon';
import CustomModalType from 'components/CustomModalType';
import { useEventTracking } from 'hooks/useEventTracking';

const ACTION_TYPES = {
  delete: 'delete',
  copy: 'copy',
  forbidden: 'forbidden',
  send_reminder: 'send_reminder'
};

const MODAL_TYPE_PER_ACTION = {
  delete: 'warning',
  copy: 'main',
  forbidden: 'error',
  send_reminder: 'warning'
};

const ICON_PER_MODAL_TYPE = {
  delete: 'Bin',
  copy: 'Copy',
  forbidden: 'Alert',
  send_reminder: 'Alarm'
};

const DataRequestsBulkActions = ({
  intl,
  onDeleteDataRequests,
  onCopyDataRequests,
  checkedDataRequests,
  checkedDataRequestIds,
  canCopyData,
  onSendReminderDataRequests
}) => {
  const eventTracking = useEventTracking();

  const [showModalType, setShowModalType] = useState('');

  const dataRequestByActionSelected = useMemo(() => {
    return checkedDataRequests.filter(({status, type, groupedRecurrentRequests, ready_to_validate_level}) => {
      if (showModalType === ACTION_TYPES.delete && (status === DATA_REQUEST_STATUS.pending || status === DATA_REQUEST_STATUS.not_notified)) {
        return type === DATA_REQUEST_RECURRENCE.one_time || (type === DATA_REQUEST_RECURRENCE.recurrent && groupedRecurrentRequests.every((id) => checkedDataRequestIds.includes(id)));
      }
      if (showModalType === ACTION_TYPES.copy) return showModalType === ACTION_TYPES.copy && (status === DATA_REQUEST_STATUS.done) && !ready_to_validate_level;
      return showModalType === ACTION_TYPES.send_reminder && (status === DATA_REQUEST_STATUS.pending);
    });
  }, [
    checkedDataRequestIds,
    checkedDataRequests,
    showModalType,
  ]);

  const requestModalInfo = useMemo(() => {
    const { totalKpis, totalPeriods } = getPeriodsAndKpisOfDataRequests(dataRequestByActionSelected);
    return {
      totalKpis,
      totalPeriods,
      totalUsers: uniq(dataRequestByActionSelected.map(({email}) => email)).length,
      hasRecurrent: dataRequestByActionSelected.some(r => r.type === DATA_REQUEST_RECURRENCE.recurrent),
    }
  }, [dataRequestByActionSelected]);

  const showWarningMessage = useMemo(
    () => dataRequestByActionSelected.length !== checkedDataRequests.length,
    [checkedDataRequests.length, dataRequestByActionSelected.length]
  );

  const onOkModal = useCallback(() => {
    const eventTrackedParams = {
      total_indicators: requestModalInfo.totalKpis,
      one_time_requests: dataRequestByActionSelected.filter(r => r.type === DATA_REQUEST_RECURRENCE.one_time).length,
      recurrent_requests: dataRequestByActionSelected.filter(r => r.type === DATA_REQUEST_RECURRENCE.recurrent).length,
    };

    if (showModalType === ACTION_TYPES.delete) {
      onDeleteDataRequests(dataRequestByActionSelected.map(r => r.id));
      eventTracking.capture('dataRequestTab.deleteRequestsBulk', eventTrackedParams);
    }
    if (showModalType === ACTION_TYPES.copy) {
      onCopyDataRequests(dataRequestByActionSelected.map(r => r.id), true, true);
      eventTracking.capture('dataRequestTab.useRequestsBulk', eventTrackedParams);
    }
    if (showModalType === ACTION_TYPES.send_reminder) {
      onSendReminderDataRequests(dataRequestByActionSelected.map(r => r.id));
      eventTracking.capture('dataRequestTab.sendReminderRequestsBulk', eventTrackedParams);
    }
    setShowModalType('');
  }, [
    showModalType,
    onDeleteDataRequests,
    dataRequestByActionSelected,
    onCopyDataRequests,
    onSendReminderDataRequests,
    eventTracking,
    requestModalInfo.totalKpis
  ]);

  const onClickBulkCopy = useCallback(() => {
    const type = checkedDataRequests.some(({id, status, kpi_slug, value_source, period}) => {
      return value_source !== "manual"
        || status === DATA_REQUEST_STATUS.in_use
        || status === DATA_REQUEST_STATUS.partially_in_use
        || (checkedDataRequests.some(dr => {
          return dr.id !== id
            && dr.status === DATA_REQUEST_STATUS.done && status === DATA_REQUEST_STATUS.done
            && dr.kpi_slug === kpi_slug && dr.period === period;
        }));
    }) ? ACTION_TYPES.forbidden : ACTION_TYPES.copy;
    setShowModalType(type);
  }, [checkedDataRequests]);

  return (
    <div className="DataRequests__bulk-actions">
      {canCopyData ? (
        <button
          className="DataRequests__copy-bulk-button"
          onClick={onClickBulkCopy}
          disabled={
            checkedDataRequests.every(
              ({status, ready_to_validate_level}) => 
                (status !== DATA_REQUEST_STATUS.done && status !== DATA_REQUEST_STATUS.in_use && status !== DATA_REQUEST_STATUS.partially_in_use) ||
                ready_to_validate_level
            )
          }
        >
          <AplanetIcon name="Copy" size="18px" title={intl.formatMessage({ id: "admin_data_requests_table_actions_copy_title"})} />
        </button>
      ) : null}
      <button
        className="DataRequests__reminder-bulk-button"
        onClick={() => setShowModalType(ACTION_TYPES.send_reminder)}
        disabled={checkedDataRequests.every(({status}) => status !== DATA_REQUEST_STATUS.pending)}
      >
        <AplanetIcon name="Alarm" size="18px" title={intl.formatMessage({ id: "admin_data_requests_table_actions_reminder_title"})} />
      </button>
      <button
        className="DataRequests__delete-bulk-button"
        onClick={() => setShowModalType(ACTION_TYPES.delete)}
        disabled={checkedDataRequests.every(({status, groupedRecurrentRequests, type}) => {
          return (status !== DATA_REQUEST_STATUS.pending && status !== DATA_REQUEST_STATUS.not_notified)
            || (type === DATA_REQUEST_RECURRENCE.recurrent && groupedRecurrentRequests.some((id) => !checkedDataRequestIds.includes(id)))
        })}
      >
        <AplanetIcon name="Bin" size="18px" title={intl.formatMessage({ id: "admin_data_requests_table_actions_delete_title"})} />
      </button>
      <CustomModalType
        showModal={!!showModalType}
        type={MODAL_TYPE_PER_ACTION[showModalType]}
        title={!!showModalType ? intl.formatMessage({id: `admin_data_requests_table_modal_title_${showModalType}`}) : null}
        icon={ICON_PER_MODAL_TYPE[showModalType]}
        iconType='fad'
        onOk={showModalType === ACTION_TYPES.forbidden ? null : onOkModal}
        onOkText={!!showModalType ? intl.formatMessage({id: `admin_data_requests_table_modal_ok_${showModalType}`}) : null}
        onCancel={() => setShowModalType('')}
        onCancelText={showModalType === ACTION_TYPES.forbidden ? intl.formatMessage({id: 'admin_data_requests_table_modal_close'}) : intl.formatMessage({id: 'admin_data_requests_table_modal_cancel'})}
        content={!!showModalType ? (
          <div className="DataRequests__modal-content">
            {showModalType === ACTION_TYPES.forbidden ? (
              <div>
                <span className="DataRequests__modal-content-message">{intl.formatMessage({id: `admin_data_requests_table_modal_message_bulk_${showModalType}`})}</span>
                <span className="DataRequests__modal-content-bold">{intl.formatMessage({id: `admin_data_requests_table_modal_bold_message_bulk_${showModalType}`})}</span>
              </div>
            ) : (
              <>
                <div className="DataRequests__modal-content-wrapper">
                  {showWarningMessage ? (
                    <div className="DataRequests__modal-content-warning-message">{intl.formatMessage({id: `admin_data_requests_table_modal_warning_message_bulk_${showModalType}`})}</div>
                  ) : null}
                  <span className="DataRequests__modal-content-message">{intl.formatMessage({id: `admin_data_requests_table_modal_message_bulk_${showModalType}`})}</span>
                  <div className="DataRequests__modal-content-info">
                    <div className="DataRequests__modal-content-kpi">
                      <AplanetIcon name="KPI" faStyle="fas" size="18px" />
                      <span className="DataRequests__modal-content-value">{intl.formatMessage({ id: "admin_data_requests_table_modal_indicators"}, {total: requestModalInfo.totalKpis || 0})}</span>
                    </div>
                    <div className="DataRequests__modal-content-user">
                      <AplanetIcon name="User" faStyle="fad" size="18px" />
                      <span className="DataRequests__modal-content-value">{intl.formatMessage({ id: "admin_data_requests_table_modal_users"}, {total: requestModalInfo.totalUsers || 0})}</span>
                    </div>
                    <div className="DataRequests__modal-content-period">
                      <AplanetIcon name="Calendar" size="18px" />
                      <span className="DataRequests__modal-content-value">{intl.formatMessage({ id: "admin_data_requests_table_modal_periods"}, {total: requestModalInfo.totalPeriods || 0})}</span>
                    </div>
                  </div>
                  {requestModalInfo.hasRecurrent && showModalType === ACTION_TYPES.delete ? (
                    <span className="DataRequests__modal-content-message">{intl.formatMessage({id: `admin_data_requests_table_modal_action_recurrent_message_bulk_delete`})}</span>
                  ) : (
                    <span className="DataRequests__modal-content-message">{intl.formatMessage({id: `admin_data_requests_table_modal_action_message_bulk_${showModalType}`})}</span>
                  )}
                </div>
                <div className="DataRequests__modal-content-question">{intl.formatMessage({id: `admin_data_requests_table_modal_question_bulk_${showModalType}`})}</div>
              </>
            )}
          </div>
        ) : null}
      />
    </div>
  );
}

DataRequestsBulkActions.propTypes = {
  onDeleteDataRequests: PropTypes.func.isRequired,
  onCopyDataRequests: PropTypes.func.isRequired,
  checkedDataRequests: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    status: PropTypes.oneOf([
      DATA_REQUEST_STATUS.done,
      DATA_REQUEST_STATUS.pending,
      DATA_REQUEST_STATUS.not_notified,
      DATA_REQUEST_STATUS.partially_in_use,
      DATA_REQUEST_STATUS.in_use,
    ]).isRequired,
    period: PropTypes.string.isRequired,
    kpi_slug: PropTypes.string.isRequired,
    value_source: PropTypes.oneOf(['manual', 'calculated', 'aggregated', 'parent', 'children']),
    type: PropTypes.oneOf([
      DATA_REQUEST_RECURRENCE.one_time,
      DATA_REQUEST_RECURRENCE.recurrent
    ]).isRequired,
    groupedRecurrentRequests: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  checkedDataRequestIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  canCopyData: PropTypes.bool,
};

export default injectIntl(DataRequestsBulkActions);
