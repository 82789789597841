import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import EditableComment from 'components/EditableComment'
import CustomInput from 'components/CustomInput';
import CustomTextArea from 'components/CustomTextArea';
import AnswerFooter from 'components/AnswerFooter';
import EditButton from 'components/EditButton';
import ModalEditTableText from 'components/ModalEditTableText';

import {
  //Row,
  //Col,
  Divider,
  Modal,
} from 'antd';

const DEFAULT_INITIAL_VALUE = { text: '' };

const Component = ({
  schema, // TODO: Consider schema
  value,
  onChange,
}) => {
  const { text = '' } = value || {}
  const handleChange = useCallback((text) => {
    onChange({
      ...value,
      text
    });
  }, [
    value,
    onChange,
  ]);

  return (
    <CustomTextArea
      value={text}
      onChange={handleChange}
    />
  );
};

const TableComponent = ({
  schema, // TODO: Consider schema
  value,
  onChange,
  onPaste,
}) => {
  const [ visible, setVisible ] = useState(false);
  const { text = '' } = value || {}
  const switchModalStatus = () => setVisible(!visible)
  
  const handleChange = useCallback((event) => {
    const text = event.target ? event.target.value : event;

    onChange({
      ...value,
      text
    });
  }, [
    value,
    onChange,
  ]);

  const handlePaste = useCallback((event) => {
    let clipboardData = event.clipboardData.getData('text').split(/\r\n?|\n/g);
    let canNotPaste = false;

    if (clipboardData[clipboardData.length - 1] === '') {
      // Remove the last empty element inserted by browser
      clipboardData.pop();
    }

    canNotPaste = clipboardData.length === 1
      && clipboardData[0].split('\t').length === 1;

    if (onPaste && !canNotPaste) {
      event.preventDefault();
      onPaste({
        ...value,
        clipboardData,
      });
    }
  }, [
    onPaste,
    value,
  ]);

  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <CustomInput
      value={text}
      onChange={handleChange}
      onPaste={handlePaste}
    />
      <EditButton onClick={switchModalStatus}/>
      <ModalEditTableText visible={visible} value={text} onClose={switchModalStatus} onChange={handleChange}/>
    </div>
    
  );
};

const Edit = ({
  intl,
  value: initialValue,
  comment: initialComment,
  previous_value,
  previous_comment,
  previous_attachments,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
  onShowLastPeriod,
  onCancel,
  onSave,
  onClear,
  config
}) => {
  const t = intl.messages;

  const [ value, setValue ] = useState(initialValue || DEFAULT_INITIAL_VALUE);
  const [ comment, setComment ] = useState(initialComment || '');
  const [ edittingComment, setEdittingComment ] = useState(false);
  const [ dirty, setDirty ] = useState(false);

  const handleChange = useCallback((value) => {
    setDirty(true);
    setValue(value);
  }, []);

  const handleUseLastValue = useCallback(() => {
    onCancel();
    onUseLastValue();
  }, [
    onUseLastValue,
    onCancel,
  ]);

  const handleUseLastWholeData = useCallback(() => {
    onCancel();
    onUseLastWholeData();
  }, [
    onUseLastWholeData,
    onCancel,
  ]);

  const handleUseLastAttachments = useCallback(() => {
    onCancel();
    onUseLastAttachments();
  }, [
    onUseLastAttachments,
    onCancel,
  ]);

  const handleUseLastPeriod = useCallback(() => {
    onCancel();
    onUseLastPeriod();
  }, [
    onUseLastPeriod,
    onCancel,
  ]);

  const handleCommentChange = useCallback((comment) => {
    setDirty(true);
    setComment(comment);
  }, []);

  const isEmpty = useMemo(() => {
    return typeof (value || {}).text === 'undefined' || !value.text;
  }, [
    value,
  ]);

  const handleCancel = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    onCancel();
  }, [
    dirty,
    onCancel,
  ]);

  const handleClear = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    Modal.confirm({
      title: t.kpi_detail_clear_title,
      content: t.kpi_detail_clear_content,
      okText: t.kpi_detail_clear_ok,
      okType: 'danger',
      cancelText: t.kpi_detail_clear_cancel,
      onOk() {
        onClear ? onClear() : onSave(null, '')
      },
      onCancel() {},
    });
  }, [
    t,
    dirty,
    onSave,
    onClear
  ]);

  const handleSave = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    onSave(
      value,
      comment,
    );
  }, [comment, dirty, onSave, value]);

  return (
    <React.Fragment>
      <Component
        value={value}
        onChange={handleChange}
      />
      <Divider/>
      <section>
        <EditableComment.Component
          title={t.privateComment}
          value={comment}
          onChange={handleCommentChange}
          editMode={edittingComment}
          setEditMode={setEdittingComment}
          editable
          titleClass='KpiDetail__title'
          config={config}
        />
      </section>
      <Divider/>
      <AnswerFooter
        editting
        canWrite
        hasLastPeriod={!!previous_value}
        hasLastComment={!!previous_comment}
        hasLastAttachments={!!previous_attachments?.length}
        onShowLastPeriod={onShowLastPeriod}
        onUseLastValue={handleUseLastValue}
        onUseLastWholeData={handleUseLastWholeData}
        onUseLastAttachments={handleUseLastAttachments}
        onUseLastPeriod={handleUseLastPeriod}
        hasInitialValue={!!initialValue}
        onCancelEdit={handleCancel}
        onClearEdit={handleClear}
        onSaveEdit={handleSave}
        dirty={dirty}
        empty={isEmpty}
      />
    </React.Fragment>
  );
};

Edit.Component = Component;
Edit.TableComponent = TableComponent;

export default injectIntl(Edit);

