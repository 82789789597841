import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { injectIntl } from 'react-intl';
import { withRouter } from "react-router";

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import InfiniteScroll from 'react-infinite-scroller';

import useOrganizations from 'utils/useOrganizations';

import {
  requestHistoryForOrganization,
} from 'actions/api';

import {
  resetDataHistory,
} from 'actions/resetData';

import HistoryCard from 'components/HistoryCard'
import { Loading } from 'tsComponents/emptyStates/Loading';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';

import { Timeline } from 'antd';

const History = ({
  intl,
  onOpenAnswer,
}) => {
  const dispatch = useDispatch();
  const scrollParentRef = useRef();
  const [loadedPage, setLoadedPage] = useState(0);

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const {
    count,
    data,
    loading, // TODO: handle loading states
    //error,
  } = useSelector(state => state.data_history);

  useEffect(() => {
    dispatch(resetDataHistory());
    dispatch(
      requestHistoryForOrganization(
        organization.slug,
        suborganization.slug,
        0,
      )
    );
    setLoadedPage(0);
  }, [
    dispatch,
    organization,
    suborganization,
  ]);

  const handleLoadMore = useCallback((_page) => {
    let page = _page;
    if (page === 0) {
      console.log('WARNING: loading more with no data');
      dispatch(resetDataHistory());
    }

    if (page <= loadedPage) {
      // This is a dirty workaround because react-infinite-scroller sucks...
      console.log('WARNING: attempting to reload a page', page);
      page = loadedPage + 1;
    }

    dispatch(
      requestHistoryForOrganization(
        organization.slug,
        suborganization.slug,
        page,
      )
    )

    setLoadedPage(page);
  }, [
    dispatch,
    loadedPage,
    organization.slug,
    suborganization.slug
  ]);

  const handleOpenAnswer = useCallback((props = {}) => {
    onOpenAnswer({
      ...props,
      org_name: suborganization.name,
    })
  }, [
    suborganization,
    onOpenAnswer,
  ]);

  const hasResult = Boolean(data.length);

  if (loading && loadedPage === 0) {
    return <Loading />
  }

  if (!hasResult) {
    return (
      <Empty {...emptyPropsMap.get("noHistory")} />
    )
  }

  return (
    <div className="DataManagement__history" ref={scrollParentRef}>
      <InfiniteScroll
        pageStart={0}
        loadMore={handleLoadMore}
        hasMore={!loading && parseInt(count, 10) > data.length}
        useWindow={false}
        getScrollParent={() => scrollParentRef.current}
      >
        <Timeline className="DataManagement__timeline">
          {
            (data || []).map(entry => (
              <Timeline.Item
                key={entry.type + entry.created_at}
                color="gray"
                className={`KpiDetail__timeline_entry KpiDetail__timeline_entry_${entry.type}`}
              >
                <HistoryCard
                  showKpi
                  urlBase="data/history"
                  onOpenAnswer={handleOpenAnswer}
                  {...entry}
                />
              </Timeline.Item>
            ))
          }
        </Timeline>
      </InfiniteScroll>
    </div>
  );
}

export default injectIntl(
  withRouter(
    History
  )
)
