import React, {
  useMemo,
  useState,
  useCallback
} from 'react';
import { useDispatch } from 'react-redux';
import { difference } from 'lodash'
import useForm from 'utils/useForm';
import useOrganizations from 'utils/useOrganizations';
import CustomCollapse from 'components/CustomCollapse';
import { formatMessage } from '../../../intl';
import CustomMultiTagSelector from 'components/CustomMultiTagSelector';
import OrganizationTreeSelector from 'components/OrganizationTreeSelector';
import EsgSelector from 'components/EsgSelector';
import SdgsSelector from 'components/SDGsSelector'
import AplanetIcon from 'components/AplanetIcon';
import { editBulkKpi } from 'actions/api';
import ModalApplyNotApply from 'components/ModalApplyNotApply';
import './style.less';

const ActionsForAllOrgs = ({
  checkedKPIsSlugs,
  checkedKpis,
  checkedCategories,
  organizationTree,
  allSDGs = [],
  commonSDGs,
  allESGs,
  allOrgs,
  commonOrgs,
}) => {

  const dispatch = useDispatch()

  const {
    organization,
    suborganization,
    isMainOrganization
  } = useOrganizations();

  const [showNotApplyWarningModal, setShowNotApplyWarningModal] = useState(false);

  const defaultValues = useMemo(() => {
    return {
      esg_type: allESGs
    }
  }, [allESGs]);

  const defaultSetValues = useMemo(() => {
    return {
      apply_sdgs: commonSDGs,
      not_apply_sdgs: [],
      apply_organizations: commonOrgs,
      not_apply_organizations: [],
      apply_tags: [],
      not_apply_tags: [],
    };
  }, [commonOrgs, commonSDGs]);


  //const validateForm = useMemo(() => validation(intl), [ intl ]);
  const {
    values,
    handleChange,
    handleSubmit,
    //errors,
    resetForm,
    dirtyFields,
  } = useForm({
    callback: (_, newValues) => {
      dispatch(editBulkKpi({ organization_slug: organization.slug, suborganization_slug: suborganization.slug, kpi_slugs: checkedKPIsSlugs }, newValues.esg_type ? { ...newValues, esg_type: newValues.esg_type[0] } : newValues))
    },
    validate: () => "",
    defaultValues,
    defaultSetValues,
    validationDefaults: { locale: suborganization.language },
    setNames: [
      'apply_sdgs',
      'not_apply_sdgs',
      'apply_organizations',
      'not_apply_organizations',
      'apply_tags',
      'not_apply_tags',
    ],
  });

  const handleSdgsChange = (slug, checked) => {
    if (checked) {
      handleChange('apply_sdgs')(slug)
      if (values.not_apply_sdgs.includes(slug)) handleChange('not_apply_sdgs')(slug)
    }
    else {
      handleChange('not_apply_sdgs')(slug)
      if (values.apply_sdgs.includes(slug)) handleChange('apply_sdgs')(slug)
    }
  }

  const handleTagsChange = (slug, checked) => {
    if (checked) {
      handleChange('apply_tags')(slug)
      if (values.not_apply_tags.includes(slug)) handleChange('not_apply_tags')(slug)
    }
    else {
      handleChange('not_apply_tags')(slug)
      if (values.apply_tags.includes(slug)) handleChange('apply_tags')(slug)
    }
  }

  const handleOrgsChange = (_, { slug, checked }) => {
    if (checked) {
      handleChange('apply_organizations')(slug)
      if (values.not_apply_organizations.includes(slug)) handleChange('not_apply_organizations')(slug)
    }
    else {
      handleChange('not_apply_organizations')(slug)
      if (values.apply_organizations.includes(slug)) handleChange('apply_organizations')(slug)
    }
  }

  const halfCheckedSdgs = useMemo(() => {
    return difference(allSDGs, [...values.apply_sdgs, ...values.not_apply_sdgs])
  },
    [allSDGs, values.apply_sdgs, values.not_apply_sdgs]
  )

  const halfCheckedOrgs = useMemo(() => {
    return difference(allOrgs, [...values.apply_organizations, ...values.not_apply_organizations])
  },
    [allOrgs, values.apply_organizations, values.not_apply_organizations]
  )

  const PanelHeader = useCallback(() => (
    <div className="ActionsOverCheckedItems-header-title">
      <span>
        {formatMessage("actions_over_all_orgs_title")}
      </span>
      <span>
        {formatMessage('KpiTreeActionsSection_actions_info_subtitle', { checkedCategoriesNumber: checkedCategories.length, checkedKpisNumber: checkedKpis.length })}
      </span>
    </div>
  ),
    [checkedCategories.length, checkedKpis.length]
  )

  return (
    <>
      <CustomCollapse
        className="ActionsOverCheckedItems"
        header={<PanelHeader/>}
        tooltip={{ title: formatMessage("actions_over_all_orgs_tooltip") }}
      >
        <div className="ActionsOverCheckedItems__body">
          <p className='warning-message'>{formatMessage("actions_over_all_orgs_warning")}</p>
          <CustomCollapse className="OrgsCollapse" header={formatMessage("kpi_tree_actions_organizations")}>
            <p className='instructions-message'>{formatMessage("actions_over_all_orgs_instructions_apply")}</p>
            <OrganizationTreeSelector
              tree={organizationTree}
              currentSelection={{ checked: values.apply_organizations, halfChecked: halfCheckedOrgs }}
              onSelect={handleOrgsChange}
              isMultipleOrgSelection
              showCurrentOrganizationTree={!isMainOrganization}
            />
          </CustomCollapse>
          {isMainOrganization && (
            <CustomCollapse className="TagsCollapse" header={formatMessage("kpi_tree_actions_tags")}>
              <CustomMultiTagSelector
                onChange={handleTagsChange}
                selectedKpisAndCategories={checkedKpis}
              />
            </CustomCollapse>
          )}
          {isMainOrganization && (
            <CustomCollapse className="EsgsCollapse" header={formatMessage("kpi_tree_actions_esgs")}>
              <p className='instructions-message'>{formatMessage("actions_over_all_orgs_instructions_esgs")}</p>
              <EsgSelector
                value={values.esg_type}
                onChange={handleChange('esg_type')}
                //error={errors.esg_type}
                showTitle={false}
              />
            </CustomCollapse>
          )}
          {
            isMainOrganization && (
              <CustomCollapse className="SdgsCollapse" header={formatMessage("kpi_tree_actions_sdgs")}>
                <SdgsSelector
                  value={{ checked: values.apply_sdgs, halfChecked: halfCheckedSdgs }}
                  showTitle={false}
                  onChange={handleSdgsChange}
                //error={errors.sdgs}
                />
              </CustomCollapse>
            )}
          {dirtyFields.length
            ? (
              <div className='buttons-container'>
                {dirtyFields.length
                  ? <p className='buttons-container__unsaved-message'>{formatMessage("changes_not_saved")}</p>
                  : ''
                }
                <button className="buttons-container__cancel" onClick={resetForm}>
                  <AplanetIcon name="Cancel" />
                  {formatMessage("cancel")}
                </button>
                <button className="buttons-container__apply" onClick={values.not_apply_organizations.length ? () => setShowNotApplyWarningModal(true) : handleSubmit}>
                  <AplanetIcon name="Save" faStyle="fad" />
                  {formatMessage("save_changes")}
                </button>
              </div>
            )
            : ''
          }
        </div>
      </CustomCollapse>
      <ModalApplyNotApply
        visible={showNotApplyWarningModal}
        kpi_slugs={checkedKPIsSlugs}
        onClose={() => setShowNotApplyWarningModal(false)}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default ActionsForAllOrgs