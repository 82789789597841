const TARGET = 'equality_plan_measure';

const initialState = {
  error: null,
  fetching: false,
  pushing: false,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        fetching: action.method === 'GET',
        pushing: action.method === 'PUT' || action.method === 'POST',
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || response.target !== TARGET) return state;
      const result = response.result;
      switch(response.method) {
        case 'GET':
          return {
            ...state,
            fetching: false,
            data: result,
          };
        case 'POST':
          return {
            ...state,
            pushing: false,
            data: [result, ...state.data],
          };
        case 'PUT':
          const index = state.data.map(
            measure => measure.id
          ).indexOf(parseInt(result.id));
          return {
            ...state,
            data: [
              ...state.data.slice(0, index),
              result,
              ...state.data.slice(index + 1)
            ],
            pushing: false,
          };
        case 'DELETE': {
          const index = state.data.map(
            measure => measure.id
          ).indexOf(parseInt(response.result.id));
          return {
            ...state,
            pushing: false,
            data: [
              ...state.data.slice(0, index),
              ...state.data.slice(index + 1)
            ],
          };
        }
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        fetching: false,
        pushing: false,
        error: action.code,
      };
    case 'SET_EQUALITY_PLAN_MEASURES':
      return {
        ...state,
        data: action.measures,
      }
    default:
      return state;
  }
};

export {
  reducer as equality_plan_measure,
};
