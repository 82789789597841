const objectMap = (
  obj,
  filter,
  map,
) => {
  let result = {};

  if(!obj || typeof obj !== 'object') {
    return filter(obj)
      ? map(obj)
      : obj;
  }

  if(Array.isArray(obj)) {
    result = obj.map(
      v => filter(v)
        ? map(v)
        : objectMap(
          v,
          filter,
          map,
        )
    )
  } else {
    Object.keys(obj).forEach(
      key => {
        const val = obj[key];
        if(filter(val)) {
          result[key] = map(val);
          return;
        }
        result[key] = typeof val === 'object'
          ? objectMap(val, filter, map)
          : val;
      }
    );
  }

  return result;
};

export default objectMap;
