import { set } from 'lodash/fp';
import _ from 'lodash';
import { objectToQueryParameters, queryStringToObject } from 'utils/queryParameters';
import objectFilter from 'utils/objectFilter';


const QUERIES_TO_KEEP = [
  'org',
];

export const queryKeeperMiddleWare = store => next => action => {
  const query = store.getState().router.location.query ?? {};
  const endpointQueries = action?.query ? {...action.query, ...query} : query;
  const stringQuery = objectToQueryParameters(
    objectFilter(QUERIES_TO_KEEP)(endpointQueries)
  );

  if((!action.type.includes('@@')) && _.has(action, 'endpoint')) {
    if (!_.isEmpty(endpointQueries)) {
      // NOTICE: parameters from action.endpoint take precedence just in case...
      const questIndex = action.endpoint.indexOf('?');
      if(questIndex  >= 0) {
        const actionEndpointStringQuery = objectToQueryParameters({
          ...endpointQueries,
          ...(
            queryStringToObject(
              action.endpoint.slice(
                questIndex
              )
            )
          )
        });
        action.endpoint = `${action.endpoint.slice(0, questIndex)}?${actionEndpointStringQuery}`;
      } else {
        // NOTICE: Delete question mark from text search because knex does not work properly
        if (endpointQueries?.search?.includes('?')) {
          endpointQueries.search = endpointQueries.search.replaceAll('?', '')
        }
        const actionEndpointStringQuery = objectToQueryParameters(endpointQueries);
        action.endpoint = `${action.endpoint}?${actionEndpointStringQuery}`;
      }
    }
  }

  if(action.type === "@@router/LOCATION_CHANGE" && !action.payload?.location?.isSwitching) {
    const payloadQueryParams = action.payload.location.search;
    const stringQueryForPayloadParams = stringQuery ? `?${stringQuery}` : '?';
    const newPayloadQueryParams = payloadQueryParams
      ? `${stringQueryForPayloadParams}${stringQuery ? '&' : ''}${payloadQueryParams.split('?')[1]}`
      : stringQueryForPayloadParams;

    next(
      set('payload.location.search', newPayloadQueryParams, action)
    )
  }
  else next(action);
}

