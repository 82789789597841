/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useRef, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import {
  useDispatch,
} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import ReactMarkdown from 'react-markdown';

import useOrganizations from 'utils/useOrganizations';
import EqualityPlanHeader from 'components/EqualityPlan/Header';
import EqualityPlanDocumentManagerOtherDocuments from 'components/EqualityPlanDocumentManager/Other';
import UploadButton from 'containers/UploadButton';
import { formatDate, DATE_ONLY } from 'utils/date';
import { Loading } from 'tsComponents/emptyStates/Loading';
import PDFViewer from 'components/PDFViewer';
import EqualityPlanDocumentManagerHistory from 'components/EqualityPlanDocumentManager/History';
import LoaderModal from 'components/LoaderModal';

import {
  uploadEqualityPlanDocumentSuccess,
} from 'actions/attachments';
import { getEqualityPlanUploadUrl } from 'actions/api';

import {
  Row,
  Col,
  Button,
  Popover,
  Dropdown,
  Menu,
  notification,
  Space,
} from 'antd';

import {
  EditOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  ContainerOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';

import './style.less';


const NoDocumentPreview = ({
  t,
  documentURL,
}) => {
  return (
    <div className='no-document-preview'>
      <Row type='flex' justify='center' align='middle'>
        <EyeInvisibleOutlined />
      </Row>
      <Row type='flex' justify='center' align='middle'>
        <h3>{t.no_document_preview}</h3>
      </Row>
      {documentURL && (
        <Row type='flex' justify='center' align='middle'>
          <a href={documentURL} download>{t.open_document}</a>
        </Row>
      )}
    </div>
  );
};

const EqualityPlanDocumentManager = ({
  intl,
  history,
  planId,
  title,
  helpText,
  description,
  stage,
  changeDataTitle,
  documents,
  fetchingDocuments,
  onFetchDocuments,
  onDownloadDocument,
  downloadingDocument,
  formURL,
  formURLHelpText,
  documentSet,
  updatePlanProgress,
  otherDocumentsGuidelines,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const downloadAnchorNode = useRef(null);
  const [ documentsType, setDocumentsType ] = useState('general');
  const { organization, suborganization } = useOrganizations();

  useEffect(
    () => {
      onFetchDocuments();
    },
    [stage, onFetchDocuments]
  );

  const onDownloadDocumentSet = useCallback(
    () => {
      if (!documentSet || Object.keys(documentSet).length === 0) {
        return;
      }

      const urlToPromise = (url) => {
        return new Promise((resolve, reject) => {
          JSZipUtils.getBinaryContent(url, (error, data) => {
            if (error) {
              reject(error);
            } else {
              resolve(data);
            }
          });
        });
      };
      let zip = new JSZip();

      for (const key of Object.keys(documentSet)) {
        zip.file(
          documentSet[key].filename,
          urlToPromise(documentSet[key].download),
          { binary: true }
        );
      }

      // when everything has been downloaded, we can trigger the download
      zip.generateAsync({ type: 'blob' })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        downloadAnchorNode.current.href = url;
        downloadAnchorNode.current.download = t.equality_plan_documents;
        downloadAnchorNode.current.click();
        window.URL.revokeObjectURL(url);
      }, () => {
        notification.error({
          message: t.download_failed,
          description: t.download_failed_try_later,
        });
      });
    },
    [documentSet, downloadAnchorNode, t]
  );

  const generalDocuments = useMemo(
    () => documents.filter(document => document.type === 'general'),
    [documents]
  );
  const otherDocuments = useMemo(
    () => documents.filter(document => document.type === 'attachment'),
    [documents]
  );

  const documentUploadUrl = useMemo(
    () => getEqualityPlanUploadUrl(
      organization.slug,
      suborganization.slug,
      planId,
      stage,
      documentsType
    ),
    [organization, suborganization, planId, stage, documentsType],
  );

  const onDocumentUploadSuccess = useCallback(
    (response) => {
      dispatch(
        uploadEqualityPlanDocumentSuccess(JSON.parse(response))
      );
      updatePlanProgress();
    },
    [dispatch, updatePlanProgress]
  );

  const headerTabs = useMemo(
    () => [{
      key: 'general',
      title: t.documents_general,
    }, {
      key: 'attachment',
      title: t.documents_other,
    }],
    [t]
  );

  if (fetchingDocuments) {
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={planId}
        title={title}
        tabs={headerTabs}
        currentTab={documentsType}
        changeTab={setDocumentsType}
      />
      { documentsType === 'attachment'
        ? <EqualityPlanDocumentManagerOtherDocuments
            documents={otherDocuments}
            stage={stage}
            planId={planId}
            guidelines={otherDocumentsGuidelines}
          />
        : <>
            <Row type="flex" justify="space-between">
              <Col>
                <span>{description}</span>&nbsp;&nbsp;
                { helpText && (
                  <Popover
                    placement="bottom"
                    content={
                      <p className='popover-text'>
                        <ReactMarkdown
                          source={helpText}
                        />
                      </p>
                    }
                    trigger="click"
                  >
                    <FontAwesomeIcon
                      className='icon-large icon-yellow' 
                      icon={'lightbulb'}
                    />
                  </Popover>
                )}
              </Col>
              <Col>
                <Space size="middle">
                  <Dropdown
                    overlay={(
                      <Menu>
                        <Menu.Item key="docx">
                          <Button
                            type="secondary"
                            onClick={() => onDownloadDocument('docx')}
                            icon={<FileWordOutlined />}
                            block
                          >DOCX</Button>
                        </Menu.Item>
                        <Menu.Item key="pdf">
                          <Button
                            type="secondary"
                            onClick={() => {onDownloadDocument('pdf')}}
                            icon={<FilePdfOutlined />}
                            block
                          >PDF</Button>
                        </Menu.Item>
                        {documentSet && Object.keys(documentSet).length > 0 && (
                          <Menu.Item key="all">
                            <Button
                              type="danger"
                              onClick={() => {onDownloadDocumentSet()}}
                              icon={<ContainerOutlined />}
                              block
                            >{t.download_all_equality_documents}</Button>
                          </Menu.Item>
                        )}
                      </Menu>
                    )}
                  >
                    <Button type="primary" block>
                      {t.download_model_to_sign} <DownOutlined />
                    </Button>
                  </Dropdown>
                  <UploadButton
                    actionUrl={documentUploadUrl}
                    onSuccess={onDocumentUploadSuccess}
                    accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    component={(
                      <Button type="primary" block>
                        { t.upload_signed_update }
                      </Button>
                    )}
                    section='equality_plan'
                  >
                  </UploadButton>
                </Space>
              </Col>
            </Row>
            <br />
            <Row type="flex" justify="space-between">
              <Col>
                {generalDocuments.length > 0 && (
                  <>
                    <h5 style={{display: 'inline-block'}}>{t.last_update}: {formatDate(generalDocuments[0].created_at, DATE_ONLY, intl)}</h5> <a
                      className='download-icon'
                      href={generalDocuments[0].download}
                      target="_blank" rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon className='icon-large' icon={'download'} />
                    </a>
                  </>
                )}
              </Col>
              <Col>
                <Space size="middle">
                  {formURL && (
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => history.push(formURL)}>
                      { changeDataTitle || t.change_data }
                    </Button>
                  )}
                  <Popover
                    placement="bottomLeft"
                    title={`${t.document_history_of} ${title}`}
                    content={<EqualityPlanDocumentManagerHistory documents={generalDocuments} />}
                    trigger={generalDocuments.length <= 0 ? '' : "click"}
                  >
                    <Button
                      className='orange-button'
                      type="primary"
                      disabled={generalDocuments.length <= 0}>
                      {t.historic}
                    </Button>
                  </Popover>
                </Space>
              </Col>
            </Row>
            {(formURL && formURLHelpText) && (
              <Row type="flex" justify="end">
                <Col>
                  <span className="form-url-help-text">
                    { formURLHelpText }
                  </span>
                </Col>
              </Row>
            )}
            <Row className='document-viewer' type='flex' justify='center'>
              <Col>
                {generalDocuments.length > 0 ? (
                  generalDocuments[0].mimetype === 'application/pdf' ? (
                    <PDFViewer
                      key={generalDocuments[0].id}
                      href={generalDocuments[0].download}
                    />
                  ) : (
                    <NoDocumentPreview t={t} documentURL={generalDocuments[0].download} />
                  )
                ) : (
                  <NoDocumentPreview t={t} />
                )}
              </Col>
            </Row>
            <LoaderModal
              visible={downloadingDocument}
              message={t.downloading_document}
            />
            <a ref={downloadAnchorNode} className='hidden' download>Hidden</a>
          </>
      }
    </>
  );
}

export default withRouter(
  injectIntl(EqualityPlanDocumentManager)
);
