import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateKpiSettings } from 'actions/api';

import CustomCheckbox from 'components/CustomCheckbox';
import {
  Col,
  Row,
} from 'antd';


const DataSourcesMandatoryDataCheckbox = ({
  organization_slug,
  suborganization_slug,
  kpi_slug,
  mandatory_data,
  type = 'value',
}) => {
  const dispatch = useDispatch();
  let [checked, setChecked] = useState(mandatory_data[type]);

  const handleOnChange = useCallback(
    () => {
      setChecked(!checked);
      dispatch(
        updateKpiSettings('data_sources_kpi_config')({
          organization_slug,
          suborganization_slug,
          kpi_slugs: [kpi_slug],
          mandatory_data: {
            ...mandatory_data,
            [type]: !checked,
          },
        })
      );
    },
    [
      dispatch,
      checked,
      organization_slug,
      suborganization_slug,
      kpi_slug,
      mandatory_data,
      type,
    ]
  );

  return (
    <Row gutter={[10, 10]}>
      <Col>
        <CustomCheckbox
          checked={checked}
          onChange={handleOnChange}
        />
      </Col>
    </Row>
  );
}

export default DataSourcesMandatoryDataCheckbox;
