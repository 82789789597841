export const fullValidation = (t) => ({
  code,
  name,
  type,
  gender,
  levels,
}) => {
  let errors = {};

  if (!code) {
    errors.code = t.form_error_required;
  }

  if (!name) {
    errors.name = t.form_error_required;
  }

  if (!type) {
    errors.type = t.form_error_required;
  }

  if (!gender) {
    errors.gender = t.form_error_required;
  }

  for (const level of levels) {
    if (!level.name || !level.description || !level.points) {
      errors.levels = t.form_error_required;
    } else if (level.points && (level.points > 10 || level.points < 1)) {
      errors.levels = t.equality_job_evaluation_factor_level_invalid_points;
    }
  }

  return {
    ...errors,
  };
};