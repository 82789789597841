import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';

import EqualityPlanDocumentManager from 'components/EqualityPlanDocumentManager';

import {
  Row,
  Col,
} from 'antd';

import './style.less';

const planStage = 'org_commitment';

const EqualityPlanOrganizationCommitment = ({
  intl,
  plan,
  documents,
  fetchingDocuments,
  onFetchDocuments,
  onDownloadDocument,
  downloadingDocument,
  updatePlanProgress,
}) => {
  const t = intl.messages;

  const onUpdatePlanProgress = useCallback(
    () => {
      updatePlanProgress(planStage);
    },
    [updatePlanProgress]
  );

  const fetchDocuments = useCallback(
    () => {
      onFetchDocuments(planStage);
    },
    [onFetchDocuments]
  );

  const downloadDocument = useCallback(
    (format) => {
      onDownloadDocument(planStage, format);
    },
    [onDownloadDocument]
  );

  return (
    <Row>
      <Col span={24}>
        <EqualityPlanDocumentManager
          title={t.commitment_of_organisation}
          planId={plan.id}
          helpText={t.commitment_of_organisation_help_text}
          description={t.org_commitment_desc}
          documents={documents}
          stage={planStage}
          onFetchDocuments={fetchDocuments}
          fetchingDocuments={fetchingDocuments}
          onDownloadDocument={downloadDocument}
          downloadingDocument={downloadingDocument}
          updatePlanProgress={onUpdatePlanProgress}
        />
      </Col>
    </Row>
  )
};

export default injectIntl(EqualityPlanOrganizationCommitment);
