import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import AplanetIcon from 'components/AplanetIcon';

import './style.less';

const BackToTopButton = ({
  intl,
  className,
  text,
  icon = 'Arrow up',
  iconType = 'fal',
  onClick,
  scrollableComponentRef
}) => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  const handleScrollUp = useCallback(() => {
    const scrollableElement = scrollableComponentRef?.current || window;
    scrollableElement.scrollTo({ top: 0, behavior: "smooth" });
    if (onClick) {
      onClick();
    }
  }, [scrollableComponentRef, onClick]);

  useEffect(() => {
    const scrollableElement = scrollableComponentRef?.current || window;
    const checkScroll = () => {
      const currentContainer = scrollableComponentRef?.current || document.documentElement;
      const totalContainerHeight = currentContainer?.scrollHeight;
      const containerOffsetHeight = currentContainer?.offsetHeight;
      const containerScrollTop = currentContainer?.scrollTop;
      if (!showBackToTop && totalContainerHeight > containerOffsetHeight && containerScrollTop !== 0) {
        setShowBackToTop(true);
      }
      if (containerScrollTop === 0) {
        setShowBackToTop(false);
      }
    }; 

    scrollableElement.addEventListener("scroll", checkScroll);

    return () => {
      scrollableElement.removeEventListener('scroll', checkScroll);
    };
  }, [scrollableComponentRef, showBackToTop]);

  if (!showBackToTop) {
    return null;
  }

  return (
    <button
      className={`BackToTopButton ${className ? className : ''}`}
      onClick={handleScrollUp}
    >
      <AplanetIcon faStyle={iconType} name={icon} size="16px" />
      <span className='BackToTopButton__text'>{text ? text : intl.formatMessage({ id: "back_to_top_button_text"})}</span>
    </button>
  );
}

BackToTopButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  iconType: PropTypes.string,
  onClick: PropTypes.func,
  scrollableComponentRef: PropTypes.object.isRequired
};

export default injectIntl(BackToTopButton);
