const TARGET = 'groups_of_interest';

const initialState = {
  error: null,
  data: [],
  currentGroup: {},
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || response.target !== TARGET) return state;
      switch(response.method) {
        case 'POST':
          return {
            ...state,
            currentGroup: response.result,
            data: [...state.data, response.result],
          };
        case 'DELETE':
          let data = state.data.filter(group => group.id !== response.result.id);
          let currentGroup = state.currentGroup;
          if(currentGroup.id === response.result.id) {
            currentGroup = data.length ? data[0] : {};
          }
          return {
            ...state, currentGroup, data,
          };
        case 'PUT':
          const index = state.data.map(
            group => group.id
          ).indexOf(parseInt(response.result.id));
          return {
            ...state,
            data: [...state.data.slice(0, index), response.result, ...state.data.slice(index + 1)]
          };
        case 'GET':
          return {
            ...state,
            currentGroup: response.result.length ? response.result[0] : {},
            data: response.result,
          };
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        error: action.code,
      };
    case 'CHANGE_CURRENT_GROUP':
      return {
        ...state,
        currentGroup: action.group,
      }
    case 'ADD_GOI_MEMBER': {
      const { result } = action.response;
      let group = {...state.data.find(group => group.id === parseInt(result.goi_id))};
      group.members = [...group.members, result];
      const index = state.data.map(group => group.id).indexOf(parseInt(group.id));
      return {
        ...state,
        data: [...state.data.slice(0, index), group, ...state.data.slice(index + 1)],
        currentGroup: group,
      }
    }
    case 'EDIT_GOI_MEMBER': {
      const { result } = action.response;
      let group = {...state.data.find(group => group.id === parseInt(result.goi_id))};
      const groupIndex = state.data.map(group => group.id).indexOf(parseInt(group.id));
      const memberIndex = group.members.map(member => member.id).indexOf(parseInt(result.id));
      group.members = [...group.members.slice(0, memberIndex), result, ...group.members.slice(memberIndex + 1)];
      return {
        ...state,
        data: [...state.data.slice(0, groupIndex), group, ...state.data.slice(groupIndex + 1)],
        currentGroup: group,
      }
    }
    case 'DELETE_GOI_MEMBER':
      const { result } = action.response;
      let group = {...state.data.find(group => group.id === parseInt(result.goi_id))};
      const groupIndex = state.data.map(group => group.id).indexOf(parseInt(group.id));
      group.members = group.members.filter(member => member.id !== parseInt(result.id));
      return {
        ...state,
        data: [...state.data.slice(0, groupIndex), group, ...state.data.slice(groupIndex + 1)],
        currentGroup: group,
      }
    default:
      return state;
  }
};

export {
  reducer as groups_of_interest,
};
