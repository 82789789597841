import ExcelJS from 'exceljs';
import Papa from "papaparse";


const CSV_MIME_TYPE = 'text/csv';
const ALTERNATE_COMMA = ' ̡';

const saveFile = (
  data,
  fileName='file',
  type='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
) => {
  var blob = new Blob([data], {type});
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

const createExcel = (
  fileName = 'Spreadsheet',
  sheetName = 'Sheet',
  rows = [],
  save = false,
  dropDownValidation = [],
  options = {},
) => {
  // Strip out invalid chars
  sheetName = sheetName?.replace(/[*?:\\/[\]]/g, ' ');

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);
  worksheet.addRows(rows);

  const headerRow = worksheet.getRow(1);

  if(options.headerStyle) {
    headerRow.eachCell(cell => {
      cell = Object.assign(cell, options.headerStyle);
    });
  }

  worksheet.properties = Object.assign(
    worksheet.properties,
    options?.worksheetProperties || {}
  );

  for (const validation of  dropDownValidation) {
    const validationValues = validation.values.map(
      value => value.toString().replace(/,/g, ALTERNATE_COMMA)
    );
    for(let i=2; i < 1000; i++){
      worksheet.getCell(`${validation.col}${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: [`"${validationValues}"`]
      }
    }
  }

  if(save) {
    workbook.xlsx.writeBuffer().then(
      buffer => saveFile(buffer, fileName)
    );
  }
  return workbook;
}

const excelToArray = (file, sheetName, onComplete) => {
  if(file.type === CSV_MIME_TYPE) {
    Papa.parse(file, {
      complete: (results) => {
        onComplete(results.data, true);
      }
    });
  } else {
    // Strip out invalid chars
    sheetName = sheetName?.replace(/[*?:\\/[\]]/g, ' ');

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const workbook = new ExcelJS.Workbook();
      workbook.xlsx.load(e.target.result).then(file => {
        let sheet = workbook.getWorksheet(sheetName)

        if (!sheet) {
          // Sheet name may have been changed, let's fall back to the first sheet.
          sheet = workbook.getWorksheet();
        }

        const values = sheet.getSheetValues().map(
          row => row.map(
            value => {
              if(typeof(value) === 'object') {
                return value.text;
              }
              return value.toString().replace(/ ̡/g, ',')
            }
          )
        );

        // Remove first item from all rows - https://github.com/exceljs/exceljs/issues/100
        values.shift();
        values.map(row => row.shift());

        onComplete(
          values.filter(row => row && row.length)
        );
      });
    };
    fileReader.readAsArrayBuffer(file);
  }
}

export {
  createExcel,
  saveFile,
  excelToArray,
}
