import React, { useMemo } from 'react';

import useOrganizations from 'utils/useOrganizations';

import {
  FileFilled,
} from '@ant-design/icons';
import DataSourcesOrganizationKpiRow from './OrganizationKpiRow';


const DataSourcesKpiRow = ({
  kpi,
  level,
}) => {
  const {
    organizationTree,
  } = useOrganizations();

  const cellStyle = useMemo(
    () => ({
      paddingLeft: `${5 + (level * 20)}px`,
      background: '#DBEAEA',
      borderRight: 'none',
    }),
    [level],
  );

  return (
    <>
      <tr>
        <td
          className="DataManagement__data-sources_table-name"
          style={cellStyle}
          colspan={5}
        >
          <FileFilled /> {kpi.name}
        </td>
        <td style={{background: '#DBEAEA'}} colspan={20}></td>
      </tr>
      <DataSourcesOrganizationKpiRow
        kpi={kpi}
        organization={organizationTree}
      />
    </>
  );
};

export default DataSourcesKpiRow;