import React, {
  useMemo,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import CustomStepsWizard from 'components/CustomStepsWizard';
import CustomModalType from 'components/CustomModalType';

import {
  createDataRequests,
} from 'actions/api';

import useSteps from './useSteps';
import useForm from 'utils/useForm';
import { getKpisWithNotExistingPeriodicitiesAndNotApplyNodes } from 'utils/dataRequests';

import {
  validate
} from "./validators";

import './style.less';

const DEFAULT_KPIS = [];
const DEFAULT_NODES = [];
const DEFAULT_PERIODICITIES = [];
const DEFAULT_PERIODS = [];
const DEFAULT_REMINDER_PERIODICITY = 'week';
const DEFAULT_USERS = [];
const RECURRENT_TYPE = 'recurrent';

const WARNING_MODAL_DATA_LIMIT_TYPE = 'dataLimit';
const LIMIT_AMOUNT_REQUEST = 500;

const WarningModalContent = ({
  intl,
  warningType = '',
  totalRequests,
}) => {
return (
  <div className="WarningModalContent">
    <div className="WarningModalContent__message">
      {intl.formatMessage(
        {id: `${warningType === WARNING_MODAL_DATA_LIMIT_TYPE ? 'data_requests_warning_modal_message_send_limit' : 'data_requests_warning_modal_message_close'}`},
        {
          dataRequest: (
                <b style={{color: '#D38106'}}>
                    {intl.formatMessage({id: `${warningType === WARNING_MODAL_DATA_LIMIT_TYPE ? 'data_requests_warning_modal_limit' : 'data_requests_warning_modal_data_request'}`}, {totalRequests})}
                </b>
            ),
        }
      )}
    </div>
    <div className="WarningModalContent__question">
      {intl.formatMessage({id: `${warningType === WARNING_MODAL_DATA_LIMIT_TYPE ? 'data_requests_warning_modal_question_send_limit' : 'data_requests_warning_modal_question_close'}`})}
    </div>
  </div>
);
};

const DataRequestsWizard = ({
  intl,
  onCloseDataRequests,
  kpis = DEFAULT_KPIS,
  reporting_structure,
  organization,
  suborganization,
  organizationsData,
}) => {
  const dispatch = useDispatch();

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningModalType, setWarningModalType] = useState('');
  const [activeAdmins, setActiveAdmins] = useState([]);

  const defaultValues = useMemo(() => {
      return {
          selected_kpis: kpis,
          selected_nodes: DEFAULT_NODES,
          recurrency: null,
          periodicities: DEFAULT_PERIODICITIES,
          periods: DEFAULT_PERIODS,
          notif_number: null,
          notif_relative: null,
          notif_time: null,
          notif_date: '',
          reminder: false,
          reminder_periodicity: DEFAULT_REMINDER_PERIODICITY,
          users: DEFAULT_USERS,
      }
  }, [kpis]);

  const submitForm = (values) => {
      const {
          selected_kpis,
          selected_nodes,
          recurrency,
          periodicities,
          periods,
          notif_number,
          notif_time,
          notif_relative,
          notif_date,
          reminder,
          reminder_periodicity,
          users,
      } = values;

      const recurrencyTypeData = recurrency === RECURRENT_TYPE ? {
          notif_number,
          notif_time,
          notif_relative,
      } : {
          notif_date,
      };

      // NOTICE: filter kpis that not apply in any node and does not have any selected periodicity in applying nodes
      const selectedKpisWithSomeNodeApplyAndPeriodicity = selected_kpis.filter(kpi => {
        return selected_nodes.some(node => kpi.orgs?.includes(node))
          && periodicities.some(periodicities => kpi.periodicities_by_organizations.some(el => el.periodicities?.includes(periodicities)));
      });
      dispatch(createDataRequests(organization.slug, suborganization.slug, {
          kpis: selectedKpisWithSomeNodeApplyAndPeriodicity.map(({slug}) => slug),
          nodes: selected_nodes,
          users: users.map(user => {
              const {slug, ...restUser} = user;
              return restUser;
          }),
          periodicities,
          recurrency: reminder ? {
              ...recurrencyTypeData,
              type: recurrency,
              periods,
              reminder_periodicity,
          } : {
              ...recurrencyTypeData,
              type: recurrency,
              periods,
          },
      }));
      onCloseDataRequests();
  };

  const validateForm = useMemo(() => validate(intl), [ intl ]);

  const {
      values,
      handleChange,
      handleSubmit,
      errors,
      validateStep,
  } = useForm({
      callback: submitForm,
      validate: validateForm,
      defaultValues,
  });

  const kpisWithNotExistingPeriodicitiesAndNotApply = useMemo(() => {
    const selectedKpisSlugs = (values?.selected_kpis || []).map(({slug}) => slug);
    return getKpisWithNotExistingPeriodicitiesAndNotApplyNodes(
      reporting_structure,
      organizationsData,
      selectedKpisSlugs,
      values?.selected_nodes,
      values?.periodicities,
    );
  }, [
    reporting_structure,
    values?.periodicities,
    values?.selected_kpis,
    values?.selected_nodes,
    organizationsData
  ]);

  const totalRequestsToBeSend = useMemo(() => {
    const totalRequestByKpisApplyNodesAndPeriods = values.selected_kpis.reduce((total, { periodicities_by_organizations }) => {
        const totalByNodeAndPeriods = periodicities_by_organizations.reduce((acc, {organization_slug, periodicities}) => {
          if (values?.selected_nodes.includes(organization_slug)) {
            const totalPeriods = values?.periods.filter(({type}) => periodicities.includes(type)).length;
            return acc += totalPeriods;
          }
          return acc;
        }, 0);
  
        return total += totalByNodeAndPeriods;
    }, 0);
    return totalRequestByKpisApplyNodesAndPeriods * values.users.length;
  }, [values]);

  const onFinishStepsWizard = useCallback(() => {
      if (totalRequestsToBeSend >= LIMIT_AMOUNT_REQUEST) {
          setWarningModalType(WARNING_MODAL_DATA_LIMIT_TYPE);
          setShowWarningModal(true);
      } else {
          warningModalType === WARNING_MODAL_DATA_LIMIT_TYPE && setWarningModalType('');
          showWarningModal && setShowWarningModal(false);
          handleSubmit();
      }
  }, [
    totalRequestsToBeSend,
      handleSubmit,
      showWarningModal,
      warningModalType
  ]);

  const steps = useSteps({
      intl,
      values,
      handleChange,
      onFinishStepsWizard,
      errors,
      kpisWithNotExistingPeriodicitiesAndNotApply,
      activeAdmins,
      setActiveAdmins,
  });

  const onClickNextStep = useCallback((currentStep) => {
      validateStep(currentStep);
  }, [validateStep]);

  const onCancelStepsWizard = useCallback(() => {
      setShowWarningModal(true);
  }, []);

  const onCancelWarningModal = useCallback(() => {
      setWarningModalType('');
      setShowWarningModal(false);
  }, []);

  const onSubmitWarningModal = useCallback(() => {
      if (warningModalType === WARNING_MODAL_DATA_LIMIT_TYPE) {
          setWarningModalType('');
          setShowWarningModal(false);
          handleSubmit();
      } else {
          onCloseDataRequests();
      }
  }, [
      handleSubmit,
      onCloseDataRequests,
      warningModalType,
  ]);

  return (
      <div className="DataRequestsWizard">
          <CustomStepsWizard
              intl={intl}
              steps={steps}
              title={intl.formatMessage({id: 'data_requests_title'})}
              finishButtonTitle={intl.formatMessage({id: 'data_requests_finish_button'})}
              onCancel={onCancelStepsWizard}
              onFinish={onFinishStepsWizard}
              onClickNextStep={onClickNextStep}
          />
          <CustomModalType
              showModal={showWarningModal}
              type='warning'
              icon='Alert'
              iconType='fas'
              onOk={() => onSubmitWarningModal()}
              onCancel={() => onCancelWarningModal()}
              onOkText={warningModalType === WARNING_MODAL_DATA_LIMIT_TYPE
                  ? intl.formatMessage({id: `data_requests_warning_modal_ok_send`})
                  : intl.formatMessage({id: `data_requests_warning_modal_ok_close`})
              }
              onCancelText={intl.formatMessage({id: `data_requests_warning_modal_cancel_close`})}
              title={warningModalType === WARNING_MODAL_DATA_LIMIT_TYPE
                  ? intl.formatMessage({id: `data_requests_warning_modal_title_send_limit`})
                  : intl.formatMessage({id: `data_requests_warning_modal_title_close`})
              }
              content={(
                  <WarningModalContent
                      intl={intl}
                      warningType={warningModalType}
                      totalRequests={totalRequestsToBeSend}
                  />
              )}
          />
      </div>
  );
};

DataRequestsWizard.propTypes = {
  kpis: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    orgs: PropTypes.array.isRequired,
    periodicities_by_organizations: PropTypes.arrayOf(PropTypes.shape({
        organization_slug: PropTypes.string.isRequired,
        periodicities: PropTypes.array.isRequired,
    })).isRequired,
  })),
  reporting_structure: PropTypes.arrayOf(PropTypes.shape({
    kpis: PropTypes.array.isRequired,
  })).isRequired,
  organization: PropTypes.object.isRequired,
  suborganization: PropTypes.object.isRequired,
  organizationsData: PropTypes.arrayOf(PropTypes.object.isRequired),
};

export default injectIntl(DataRequestsWizard);