import { useEffect, useState, useCallback, useMemo } from 'react';
import { difference, isEqual } from 'lodash';
import useSetState from './useSetState';

const mergeValueKeys = (values, defaults = {}) => {
  const keys = Array.from(new Set([...Object.keys(values), ...Object.keys(defaults)]));
  let key, result = {};

  for (key of keys) {
    result[key] = typeof values[key] === 'undefined' ? defaults[key] : values[key];
  }
  return result;
};

const useForm = ({
  callback,
  validate,
  validationDefaults = {},
  setNames = [],
  defaultValues = {},
  defaultSetValues = {},
  continousValidation = false
}) => {

  const [flatValues, setFlatValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [runContinousValidation, setRunContinousValidation] = useState(false)
  const {
    has: isDirty,
    set: dirtyFields,
    add: setDirty,
    replaceAll: clearDirty,
  } = useSetState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let sets = {};
  setNames.forEach(name => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    sets[name] = useSetState(defaultSetValues[name] || []);
  });

  const values = useMemo(() => ({
    ...flatValues,
    ...setNames.map(name => [name, sets[name].set]).reduce((obj, [name, set]) => {
      obj[name] = set; return obj;
    }, {})
  }),
    [flatValues, setNames, sets]
  )

  useEffect(() => {
    if (runContinousValidation) {
      setRunContinousValidation(false)
      runValidation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const newValues = useMemo(() => {
    let newvalues = {}
    dirtyFields.forEach(field => {
      if (setNames.includes(field)) {
        const differenceArr = difference(values[field], defaultSetValues[field])
        if (differenceArr.length > 0) newvalues = { ...newvalues, [field]: difference(values[field], defaultSetValues[field]) }
      }
      else if (!isEqual(values[field], defaultValues[field])) {
        newvalues = { ...newvalues, [field]: values[field] }
      }
    })
    return newvalues
  },
    [defaultSetValues, defaultValues, dirtyFields, setNames, values]
  )

  const runValidation = () => {
    setErrors(validate(mergeValueKeys(values, validationDefaults)));
  }

  const validateStep = (currentStep) => {
    clearDirty([]);
    setErrors(validate(mergeValueKeys(values, validationDefaults), currentStep));
  }

  const handleSubmit = () => {
    setIsSubmitting(true);
    runValidation();
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      setIsSubmitting(false);
      callback(values, newValues);
      clearDirty([]);
    }
  }, [errors, isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (name) => (value) => {
    setDirty(name);
    if (setNames.includes(name)) {
      // This is a set
      if (Array.isArray(value)) {
        sets[name].replaceAll(value);
      } else {
        sets[name].toggle(value);
      }
    } else {
      // This is a flat
      setFlatValues(flatValues => ({ ...flatValues, [name]: value }));
    }
    if (continousValidation) {
      setRunContinousValidation(true)
    }
  };

  const handleChangeEvent = (event) => {
    event.persist();
    handleChange(event.target.name)(event.target.value);
  };

  const resetForm = useCallback(
    () => {
      clearDirty([]);
      setIsSubmitting(false);
      setErrors({});
      setFlatValues(defaultValues);
      setNames.forEach(name => {
        sets[name].replaceAll(defaultSetValues[name] || []);
      });
    },
    [setFlatValues, defaultValues, clearDirty, defaultSetValues, setNames, sets]
  );

  return {
    handleChange,
    handleChangeEvent,
    runValidation,
    handleSubmit,
    values,
    isDirty,
    dirtyFields,
    errors,
    resetForm,
    validateStep,
  }
};

export default useForm;
