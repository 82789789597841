import React, { useCallback, useMemo } from 'react';
import { formatMessage } from '../../intl';
import CustomModalType from 'components/CustomModalType';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Button } from 'antd';

const { Panel } = Collapse;

const DeleteModal = ({slug, reporting_structure, name, showModal, setShowModal, handleCategoryDeleteFromTree, handleCategoryDeleteFromPlatform}) => {
  const repeatedCategories = useMemo(() => (reporting_structure?.filter(({category} )=> category?.slug === slug) || []), [reporting_structure, slug])

  const RepeatedIndicatorContent = useCallback(() => {
    if(repeatedCategories.length > 1) {
      return (
        <>
          <span>{formatMessage('delete_modal_repeated_category')}</span>
          <br/>
          <Collapse defaultActiveKey={['1']} ghost style={{display: "inline-block"}} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
            <Panel header={formatMessage('delete_modal_category_list')} key="1">
              {repeatedCategories.map(({parent}) => (
                <>
                  <span>
                  <b className='warning'>{parent ? parent.name : "Main root"}</b>
                  {" > "} 
                  {name}
                  </span>
                  <br/>
                </>
              ))}
            </Panel>
          </Collapse>
          <br/>
        </>
        
      )
    }
    return ""
  },
    [name, repeatedCategories]
  )

  const DeleteModalContent = useCallback(() => (
    <div className='DeleteModal'>
      <span>{formatMessage('delete_modal_delete_category_message_1')} </span>
      <b className='warning'>{name}</b>
      <br/>
      <span>{formatMessage('delete_modal_delete_category_message_2')} </span>
      <b className='warning'>{formatMessage('delete_modal_delete_category_message_3')}</b>
      <br/>
      <RepeatedIndicatorContent />
      <div className='wrapper'>
        <span>{formatMessage('delete_modal_delete_category_message_4')} </span>
        <b className='question'>{formatMessage('delete_modal_delete_category_confirm_message')}</b>
      </div>
    </div>
  ),
    [name]
  )

  return (
    <CustomModalType 
      showModal={showModal} 
      type="warning" 
      iconType="fad"
      title={formatMessage('delete_modal_category_title')}
      icon="Alert"
      content={<DeleteModalContent/>}
      footer={(
        <div className="CustomModalType__footer">
          <div className={`CustomModalType__buttons warning`}>
              <Button onClick={(() => setShowModal(false))}>
                {formatMessage('cancel')}
              </Button>
              {
                repeatedCategories.length > 1 ? (
                  <Button onClick={() => handleCategoryDeleteFromTree()}>
                    {formatMessage('delete_modal_delete_from_folder')}
                  </Button>
                )
                : ""
              }
              <Button type="primary" onClick={() => handleCategoryDeleteFromPlatform()}>
                {formatMessage('delete_modal_delete_from_platform')}
              </Button>
          </div>
        </div>
      )}
    />
  )
}

export default DeleteModal
