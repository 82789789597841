import React, { useEffect, useRef } from 'react';
import {useState} from 'react';
import { injectIntl } from 'react-intl';

import { useDispatch, useSelector } from 'react-redux';
import useOrganizations from 'utils/useOrganizations';
import { getDocumentation } from 'actions/api';
import { NotionRenderer } from 'react-notion-x';
import { Code } from 'react-notion-x/build/cjs/third-party/code';
import { Drawer  } from 'antd';
import { Loading } from 'tsComponents/emptyStates/Loading';

const DocDrawer = ({
    intl,
    visible,
    pageOrigin = '5bb9c3a767884da4835c80cddafa8123',
    onCloseDocDrawer
    //lang
}) => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(pageOrigin);
    const drawerRef = useRef(null);
    const history = [];

    const {
        organization,
        //suborganization,
      } = useOrganizations();

    const {
        data: docPage,
        loading
      } = useSelector(state=> state.documentation)


    useEffect(() => {
    dispatch(
        getDocumentation(
        organization.slug,
        currentPage
    ))
    },[
    dispatch,
    organization,
    currentPage
    ]);

   
    const changePage = (toPage) => {
      history.push(currentPage);
      setCurrentPage(toPage);
      dispatch(
        getDocumentation(
          organization.slug,
          toPage
        ));
      drawerRef.current.scrollTop = 0;
    };

    const clickEvListener = (event) => {
      event.preventDefault();
      const target = event.currentTarget.href;
      const urlPath = target.split('/');
      const pageInfo = urlPath.length > 0 ? urlPath[urlPath.length -1].split('-') : '';
      const pageId = pageInfo.length > 0 && pageInfo[pageInfo.length -1] !== '' ? pageInfo[pageInfo.length -1]: pageOrigin;
      if (pageId){
        changePage(pageId);
      }
    };

    const DocLink = ({
      onClick,
      ...props}) => {
        // eslint-disable-next-line jsx-a11y/anchor-has-content
      return (<a {...props} onClick={clickEvListener} />)
    };

    // const defaultMapPageUrl = (rootPageId) => (pageId) => {
    //     pageId = (pageId || '').replace(/-/g, '')
      
    //     if (rootPageId && pageId === rootPageId) {
    //       return '/bla/'
    //     } else {
    //       return `/bla/${pageId}`
    //     }
    //   }

    return(
        <Drawer
        visible={visible}
        placement="right"
        size="large" // Undefined in this ant version
        onClose={onCloseDocDrawer}
        footer={[]}
        className="docDrawer"
        width='50vw'
      >

        <div className="DocDrawerContainer"
            ref={drawerRef}
        >
        { loading 
          ? <Loading /> 
          : <NotionRenderer 
            recordMap={docPage} 
            fullPage={true} 
            darkMode={false} 
            components={{
              Code, 
              PageLink: DocLink
              }} 
            // mapPageUrl={defaultMapPageUrl(pageOrigin)}

            />
        }

        </div>
      </Drawer>
    );
};
  
export default injectIntl(DocDrawer);
