import React from "react";
import styled from "@emotion/styled";
import CustomTagSimple from "components/CustomTagSimple";

interface TooltipTagListProps {
  tags: string[];
  className?: string;
}

const StyledTooltipTagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 250px;

  .CustomTagSimple {
    margin-bottom: 4px;
    margin-top: 4px;
  }
`;

export const TooltipTagList = ({
  tags,
  className = "",
}: TooltipTagListProps) => {
  return (
    <StyledTooltipTagList className={`${className}`}>
      {tags.map((tag) => (
        <CustomTagSimple key={tag} name={tag} />
      ))}
    </StyledTooltipTagList>
  );
};
