const TARGET = 'attachments';

const isTarget = (target) => [TARGET].includes(target);
const isGeneralTarget = (target) => target === TARGET;

const initialState = {
  data: [],
  error: null,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        data: action.method === 'GET' && !action.keep ? null : state.data,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;
      switch(action.response.method) {
        case 'GET':
          return {
            data: action.response.result,
            loading: false,
            error: null,
          };
        default:
          return {
            ...state,
            loading: false,
            error: null,
          };
      }
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        data: isGeneralTarget(action.request.target) ? null : state.data,
        loading: false,
        error: action.code,
      };
    case 'RESET_ATTACHMENTS_DATA':
      return initialState;
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as attachments,
};
