import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

import Loading from 'containers/Loading';
import CustomModal from 'components/CustomModal';
import AplanetIcon from 'components/AplanetIcon';
import CustomButton from 'components/CustomButton';
import CustomTextArea from 'components/CustomTextArea';
import FileItem from 'components/FileItem';
import UploadArea from 'containers/UploadArea';
import PeriodSwitcher from 'containers/KpiDetail/screens/Data/PeriodSwitcher';
import OrganizationHierarchyLabel from './OrganizationHierarchyLabel';
import TableComponent from './TableComponent';
import DeleteAttachmentLink from './DeleteAttachmentLink';
import Badge from './Badge';

import useOrganizations from 'utils/useOrganizations';
import { findTree } from 'utils/tree';

import {
  requestKpiDetail,
  requestKpiUpdate,
  getAttachFileUrl,
} from 'actions/api';

import {
  uploadKpiAttachmentSuccess,
} from 'actions/attachments';

import {
  Col,
  Row,
  Collapse,
  Divider,
} from 'antd';


const { Panel } = Collapse;

const Footer = injectIntl(({
  intl,
  canEdit,
  onSave,
  isDirty,
  comment,
  kpi,
  onCommentChange,
  loading,
  organization,
  suborganization,
}) => {
  const dispatch = useDispatch();

  if(loading) {
    return '';
  }

  const onAttachmentUploadSuccess = useCallback((response) => {
    try {
      const attachment = JSON.parse(response);
      dispatch(
        uploadKpiAttachmentSuccess(
          suborganization.slug,
          kpi.slug,
          kpi.period,
          attachment,
        )
      )
    } catch(err) {
      console.error(err);
    }
  }, [
    dispatch,
    suborganization.slug,
    kpi,
  ]);

  const uploadProps = useMemo(() => ({
    intl: intl,
    actionUrl: getAttachFileUrl(
      organization.slug,
      suborganization.slug,
      kpi.slug,
      kpi.period,
    ),
    onSuccess: onAttachmentUploadSuccess,
    component: (
      <AplanetIcon
        className="BulkManage_Attachments__icon_button"
        name="plus"
        size="10px"
      />
    )
  }), [
    kpi,
    intl,
    onAttachmentUploadSuccess,
    organization,
    suborganization,
  ]);

  return (
    <div className="TableValueModal_footer">
      <Row type="flex" gutter={[10, 10]}>
        <>
          <Divider />
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Collapse accordion ghost>
                  <Panel
                    key="comment"
                    header={
                      <>
                        {intl.formatMessage({id: 'comment'})} <Badge>{!!comment ? 1 : 0}</Badge>
                      </>
                    }
                  >
                    <CustomTextArea
                      readOnly={loading || !canEdit}
                      value={comment}
                      onChange={onCommentChange}
                      style={{width: '100%'}}
                    />
                  </Panel>
                </Collapse>
              </Col>
              <Col span={12}>
                <Collapse accordion ghost>
                  <Panel
                    key="attachments"
                    header={
                      <>
                        {intl.formatMessage({id: 'bulk_manage_table_attachments'})} <Badge>{kpi?.attachments.length || 0}</Badge>
                      </>
                    }
                  >
                    <div className="TableValueModal_footer__upload_panel_content">
                      <UploadArea {...uploadProps} disabled={!canEdit} className="TableValueModal_footer__upload_panel_content__upload_area"></UploadArea>

                      {kpi.attachments.map(attachment => (
                        <FileItem {...attachment}>
                          <DeleteAttachmentLink
                            intl={intl}
                            kpi={kpi}
                            canEdit={canEdit}
                            attachment={attachment}
                          />
                        </FileItem>
                      ))}
                    </div>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Col>
          { canEdit &&
            <Col span={24}>
              <Row type="flex" justify="end">
                <Col>
                  <CustomButton
                    loading={loading}
                    type="primary"
                    onClick={onSave}
                    disabled={!isDirty}
                  >{intl.formatMessage({id: 'save'})}</CustomButton>
                </Col>
              </Row>
            </Col>
          }
        </>
      </Row>
      <Row>
        {!canEdit && (
          <Col>
            <b>{intl.formatMessage({id: 'bulk_manage_disclaimer_note'})}:</b> {intl.formatMessage({id: 'bulk_manage_table_disclaimer'})}
          </Col>
        )}
      </Row>
    </div>
  );
});

const TableValueModal = ({
  visible,
  onClose,
  kpi,
}) => {
  const [dirty, setDirty] = useState(false);
  const [comment, setComment] = useState(kpi.comment);
  const [tableValues, setTableValues] = useState();
  const [period, setPeriod] = useState(kpi.period);
  const dispatch = useDispatch();

  const {
    organization,
    suborganization: currentOrganization,
    permissions,
  } = useOrganizations();

  const {
    data: kpiDetail,
    loading,
  } = useSelector(state => state.kpi_detail);

  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);

  const {
    organizationTree,
  } = useOrganizations();

  const suborganization = useMemo(
    () => findTree([organizationTree], (node) => node.slug === kpiDetail?.suborganization_slug),
    [
      organizationTree,
      kpiDetail,
    ]
  );
  const suborganization_parent_slug = useMemo(
    () => {
      if (!suborganization?.parent_id) {
        return;
      }

      return findTree(
        [organizationTree],
        (node) => node.id === suborganization?.parent_id,
      )?.slug;
    },
    [
      organizationTree,
      suborganization,
    ]
  );

  useEffect(() => {
    if (kpiDetail?.schema?.type === 'table') {
      setComment(kpiDetail.comment);
    }
  }, [
    kpiDetail,
  ])

  useEffect(() => {
    // Dispach action to request data detail
    dispatch(
      requestKpiDetail(
        organization.slug,
        kpi.organization_slug,
        kpi.slug,
        period,
      )
    );
  }, [
    kpi.organization_slug,
    kpi.slug,
    period,
    organization,
    dispatch,
  ]);

  const onCommentChange = useCallback(value => {
    setComment(value);
    setDirty(true);
  }, [
  ]);

  const handleOnChangePeriod = useCallback(
    (period) => {
      if (period !== kpiDetail.period) {
        setPeriod(period);
      }
    },
    [kpiDetail]
  );

  const updateOrderOfValues = useCallback(
    (values) => {
      let updatedValues = {};
      Object.keys(values).forEach(
        (key, index) => {
          updatedValues[key] = {
            ...values[key],
            _order: index + 1,
          };
        }
      );
      return updatedValues;
    },
    []
  );

  const handleOnSave = useCallback(() => {
    dispatch(
      requestKpiUpdate(
        organization.slug,
        kpiDetail.suborganization_slug,
        kpiDetail.slug,
        period,
        {
          value: updateOrderOfValues(tableValues),
          comment,
        },
      )
    )},
    [
      period,
      kpiDetail,
      dispatch,
      organization,
      comment,
      updateOrderOfValues,
      tableValues,
    ]);

  const canEdit = useMemo(() => (
    (kpiDetail?.source === 'manual') &&
    (kpiDetail?.ready_to_validate_level === 0) &&
    permissions.can_write_kpi
  ), [
    kpiDetail,
    permissions,
  ]);

  const updatedTableDimensionsForCurrentOrg = useMemo(() => {
    if (!kpiDetail || (kpiDetail.suborganization_slug === currentOrganization.slug)) {
      return tableDimensions;
    }

    // We are doing this to avoid fetching table dimensions for orgs
    // other than current org
    let dimensionsToUpdate = {};

    (kpiDetail.schema.dimensions || [])
      .filter(({source}) => source === 'organization')
      .forEach(({by}) => {
        if (kpiDetail.schemaLabels.dimensionValues?.[by]) {
          dimensionsToUpdate[by] = Object.keys(
            kpiDetail.schemaLabels.dimensionValues[by]
          );
        }
      });

    return {
      ...tableDimensions,
      ...dimensionsToUpdate,
    };
  }, [
    tableDimensions,
    kpiDetail,
    currentOrganization,
  ])

  return (
    <CustomModal
      className="TableValueModal"
      shown={visible}
      onCancel={onClose}
      bodyStyle={{ height: '50vh' }}
      title={
        <span><AplanetIcon name="table" />{kpi.name}</span>
      }
      width="70%"
        footer={(
          <Footer
            loading={loading}
            canEdit={canEdit}
            kpi={kpi}
            onSave={handleOnSave}
            isDirty={dirty}
            comment={comment}
            onCommentChange={onCommentChange}
            organization={organization}
            suborganization={currentOrganization}
          />
        )}
    >
      { (loading || !kpiDetail || (kpiDetail.slug !== kpi.slug))
        ? <Loading.Block />
        : <>
            <Row type="flex" justify="space-between">
              <Col>
                <OrganizationHierarchyLabel
                  organization={{
                    slug: kpi.organization_slug,
                    name: kpi.organization_name,
                  }}
                />
              </Col>
              <Col>
                <PeriodSwitcher
                  loading={loading}
                  period={kpiDetail.period}
                  all_periods={kpiDetail.all_periods}
                  showLabel={false}
                  changePeriod={handleOnChangePeriod}
                />
              </Col>
            </Row>
            <Row className="TableValueModal__table">
              <Col span={24} className={kpiDetail.source !== 'manual' ? `TableValueModal__table_readonly` : ''}>
                <TableComponent
                  period={period}
                  kpi={kpiDetail}
                  setDirty={setDirty}
                  canEdit={canEdit}
                  setTableValues={setTableValues}
                  tableDimensions={updatedTableDimensionsForCurrentOrg}
                  suborganization={suborganization}
                  suborganization_parent_slug={suborganization_parent_slug}
                />
              </Col>
            </Row>
          </>
      }
    </CustomModal>
  );
};

export default TableValueModal;
