import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import CustomButton from 'components/CustomButton'
import DataAnswerCard from 'components/DataAnswerCard';
import CollapsableCard from 'components/CollapsableCard';
import SubOrgTable from './SubOrgTable';
import useSetState from 'utils/useSetState';
import useAggregateValues from 'utils/useAggregateValues';
import { ReactComponent as SubOrgIcon } from 'assets/icon-suborganizations.svg';
import T from 'components/T';
import CustomModal from 'components/CustomModal';
import { Loading } from 'tsComponents/emptyStates/Loading';
import {
  formatLong,
} from 'utils/date';
import {
  useDispatch,
} from 'react-redux';

import {
  Menu,
  Dropdown,
  Switch,
  Row,
  Col,
  Space,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  requestKpiDetailSuborganizations,
} from 'actions/api';

const Footer = injectIntl(({
  intl,
  selected,
  schema,
  loading,
  onUseComment,
  onUseAttachment,
}) => {
  const selectedAttachments = useMemo(() => {
    return (selected || [])
      .map(card => card.attachments || [])
      .reduce((arr, el) => arr.concat(el), []);
  }, [
    selected,
  ]);

  const hasAttachments = selectedAttachments.length > 0;

  const selectedWithValue = useMemo(() => {
    return (selected || []).filter(card => !!card.kpi_value)
  }, [
    selected,
  ]);

  const valueHandlers = useAggregateValues(
    onUseComment,
    schema,
    selectedWithValue.map(({ name, kpi_value, comment }) => ({ name, kpi_value, comment })),
  );

  const handleUseAttachments = useCallback(() => {
    selectedAttachments
      .forEach(onUseAttachment);
  }, [
    selectedAttachments,
    onUseAttachment,
  ]);

  const handleMenuClick = useCallback((key) => (e) => {
    const handlers = valueHandlers[key] || [];
    const handler = handlers.find(({ key }) => key === (e || {}).key);
    if(handler && handler.onClick) {
      handler.onClick();
    }
  }, [
    valueHandlers,
  ]);

  return (
    <React.Fragment>
      {
        !hasAttachments ? null :
        <CustomButton
          disabled={loading}
          onClick={handleUseAttachments}
        >
          { intl.formatMessage({ id: "data_answer_card_use_attachment"}) }
        </CustomButton>
      }
      {
        Object.keys(valueHandlers).map(
          key => {
            const handler = valueHandlers[key];

            if(Array.isArray(handler)) {
              return (
                <Dropdown
                  key={key}
                  overlay={
                    <Menu onClick={handleMenuClick(key)}>
                      {
                        handler.map(({
                          key,
                          label,
                        }) => (
                          <Menu.Item key={key}>
                            <T
                              convert_to_unit={{ unit: label }}
                            />
                          </Menu.Item>
                        ))
                      }
                    </Menu>
                  }
                >
                  <CustomButton>
                    { intl.formatMessage({ id: `data_answer_card_use_data_${key}`}) } <DownOutlined />
                  </CustomButton>
                </Dropdown>
              );
            }

            return (
              <CustomButton
                key={key}
                onClick={handler}
              >
                { intl.formatMessage({ id: `data_answer_card_use_data_${key}`}) }
              </CustomButton>
            )
          }
        )
      }
    </React.Fragment>
  );
});

const SubOrg = ({
  intl,
  cards = [],
  kpi_name,
  kpi_slug,
  period,
  schema,
  schemaLabels,
  loading,
  onOpenAnswer,
  onUseComment,
  onUseAttachment,
  permissions = {},
  canAggregate,
  defaultExpanded = false,
  source,
  source_params,
  organization,
  suborganization,
  loadingSuborganizations,
}) => {
  const [showTable, setShowTable] = useState(false);
  const [isSubOrgKpiTableVisible, setIsSubOrgKpiTableVisible] = useState(false);

  const dispatch = useDispatch();

  const onOpenPanel = useCallback(() => {
    if(cards.length) {
      return;
    }

    dispatch(
      requestKpiDetailSuborganizations(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
      )
    );
  }, [
    organization,
    suborganization,
    kpi_slug,
    period,
    dispatch,
    cards,
  ]);

  const suborgAppliesCards = useMemo(() => {
    return cards.filter(({ applies }) => applies)
  }, [
    cards,
  ]);

  // Control suborgs selected
  const {
    set: selected,
    toggle: toggleSelected,
    //replaceAll: setSelected,
  } = useSetState([]);

  const selectedCards = useMemo(() => {
    return suborgAppliesCards.filter(({ slug }) => selected.includes(slug));
  }, [
    suborgAppliesCards,
    selected,
  ]);

  const handleOpenAnswer = useCallback((answerProps) => {
    return () => {
      onOpenAnswer({
        ...answerProps,
        mode: 'suborg',
      });
    };
  }, [
    onOpenAnswer,
  ]);

  return (
    <CollapsableCard
      title={ intl.formatMessage({ id: "suborg"}) }
      icon={<SubOrgIcon />}
      footer={!showTable}
      className={`CollapsableCard__grid ${showTable ? 'CollapsableCard__nogrid' : ''}`}
      defaultExpanded={defaultExpanded}
      onClick={onOpenPanel}
    >
        {loadingSuborganizations ? (
          <Loading />
        ) : (
        <>
          <Row
            className="CollapsableCard__fullwidth CollapsableCard__actions"
            gutter={[20, 20]}
            justify="end"
          >
            {!!cards.length &&
            <Col>
              <Space>
                { intl.formatMessage({ id: "data_suborg_view_as_table"}) }
                <Switch
                  checked={showTable}
                  disabled={!cards.length}
                  onChange={(val) => setShowTable(val)}
                />
              </Space>
            </Col>
            }

            {showTable &&
            <Col>
              <span>
                <Row gutter={[5, 5]}>
                  <Col>
                    { intl.formatMessage({ id: "data_suborg_full_screen"}) }
                  </Col>
                  <Col>
                    <FontAwesomeIcon
                      className="icon-large"
                      icon={['fal', 'expand']}
                      onClick={() => setIsSubOrgKpiTableVisible(true)}
                    />
                  </Col>
                </Row>
              </span>
            </Col>
            }
          </Row>

          {showTable
          ? (
            <SubOrgTable
              schema={schema}
              schemaLabels={schemaLabels}
              kpi_name={kpi_name}
              subOrgValues={cards}
              source={source}
            />
          )
          : (
          <>
            {
              !cards.length &&
              <div>{intl.formatMessage({ id: "organization_without_suborgs"})}</div>
            }
            {
              !!cards.length && !suborgAppliesCards.length &&
              <div>{intl.formatMessage({ id: "organization_without_applies_suborgs"})}</div>
            }
            {suborgAppliesCards.map(({
              slug,
              name,
              avatar,
              kpi_value,
              tableDimensions,
              schemaLabels,
              status,
              attachments = [],
              comment,
              updated_at,
              applies,
              source,
              not_apply_comment = '',
            }) => (
              <DataAnswerCard
                key={slug}
                className=""
                selectable={permissions.can_write_kpi && canAggregate}
                name={name}
                avatar={avatar}
                value={kpi_value}
                status={status}
                attachments={attachments}
                date={formatLong(updated_at, intl)}
                selected={selected.includes(slug)}
                select={toggleSelected}
                id={slug}
                schema={schema}
                loading={loading}
                showRemind={false}
                supportNotApply
                applies={applies}
                not_apply_comment={not_apply_comment}
                source={source}
                onClick={handleOpenAnswer({
                  name,
                  avatar,
                  email: '',
                  value: kpi_value,
                  comment,
                  attachments,
                  updated_at,
                  applies,
                  not_apply_comment,
                  tableDimensions,
                  schemaLabels,
                })}
              />
            ))}
            {
              !permissions.can_write_kpi || !canAggregate
              ? null
              : (
                <section className='CollapsableCard__footer'>
                  <Footer
                    selected={selectedCards}
                    schema={schema}
                    loading={loading}
                    onUseComment={onUseComment}
                    onUseAttachment={onUseAttachment}
                  />
                </section>
              )
            }
          </>
          )}

          <CustomModal
            title={ intl.formatMessage({ id: "suborg"}) }
            width={'90%'}
            shown={isSubOrgKpiTableVisible}
            footer={null}
            onCancel={() => setIsSubOrgKpiTableVisible(false)}
          >
            <SubOrgTable
              schema={schema}
              schemaLabels={schemaLabels}
              kpi_name={kpi_name}
              subOrgValues={cards}
              source={source}
            />
          </CustomModal>
        </>
      )}
    </CollapsableCard>
  );
};

export default injectIntl(SubOrg);
