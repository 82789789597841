// Cartesian product out of: https://stackoverflow.com/a/15310051
export const cartesian = (...args) => {
  let result = [];
  const maxDepth = args.length - 1;
  if(maxDepth < 0) return [];

  const helper = (arr, depth) => {
    const thisLen = args[depth].length;
    for(let i=0; i < thisLen; i++) {
      let arrClone = arr.slice(0); // clone arr
      arrClone.push(args[depth][i]);
      if (depth === maxDepth) {
        result.push(arrClone);
      } else {
        helper(arrClone, depth + 1);
      }
    }
  }
  helper([], 0);
  return result;
}
