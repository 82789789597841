import React from 'react';
import { injectIntl } from 'react-intl';

import { formatShortWithYear } from 'utils/date';

import {
  Button,
  Steps,
} from 'antd';

const ValidationSteps = ({
  intl,
  canApprove,
  currentStep,
  approvalLevels,
  steps = [],
  onApprove,
  loading,
}) => {
  const t = intl.messages;

  return (
    <>
      {currentStep < approvalLevels &&
        <p>{t.datamanagement_validation_started}</p>
      }
      <Steps
        className='KpiDetail__validation-steps'
        direction="vertical"
        size="small"
        current={currentStep}
      >
        {
          steps.map(({
            level,
            isApproved,
            isActive,
            action,
          }) => {
            const status = isApproved
              ? 'finish'
              : !isActive
                ? 'wait'
                : action && action.type === 'rejection'
                  ? 'error'
                  : 'process';

            const title = isActive && canApprove ? (
              <div
                className="KpiDetail__validation-buttonrow"
              >
                <Button
                  type="primary"
                  className="KpiDetail__validation-minibutton"
                  onClick={() => onApprove(level, true)}
                  disabled={loading}
                >
                  {t.validate}
                </Button>
                <Button
                  className="KpiDetail__validation-minibutton"
                  onClick={() => onApprove(level, false)}
                  disabled={loading}
                >
                  {t.validate_with_comment}
                </Button>
              </div>
            ) : isApproved
              ? (
                action
                  ? (
                    <span>
                      {t.validated_by}
                      {' '}
                      <strong>{action.name}</strong>
                    </span>
                  ) : t.validated
              ) : isActive
                ? t.waiting
                : t.pending;

            const description = (action && isApproved) || (isActive && action && action.type === 'rejection')
              ? (
                <span className="KpiDetail__validation-step-description">
                  {formatShortWithYear(action.created_at, intl)}
                </span>
              ) : '';

            return (
              <Steps.Step
                className={`KpiDetail__validation-step ${canApprove ? 'KpiDetail__validation-step-allowed' : ''}`}
                key={level}
                title={title}
                status={status}
                description={description}
              />
            )
          })
        }
      </Steps>
    </>
  );
};

export default injectIntl(ValidationSteps);
