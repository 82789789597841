import _ from 'lodash';

export const DEFAULT_STATUS_FILTER_STATE = [
    'pending',
    'uptodate',
    'restricted',
];
export const ARCHIVE_STATUS_FILTER_STATE = [
    'downloaded',
    'not downloaded',
    'bookmarked',
    'not bookmarked'
];

// Screens logic
export const SCREENS = {
    fill_and_report: 'fill_and_report',
    manage: 'manage',
    data_collection: 'data_collection',
    admin_data_requests: 'admin_data_requests',
    files: 'files',
    kpidetail_files: 'kpidetail_files',
    downloads: 'downloads',
    third_party_requests: 'third_party_requests',
    bulk_manage: 'bulk_manage',
};

export const SCREEN_FILTERS = {
    [SCREENS.fill_and_report]: {
        sdg_filter: 'sdg_filter',
        type_filter: 'type_filter',
        tag_filter: 'tag_filter',
        status_filter: 'status_filter',
        validation_status_filter: 'validation_status_filter',
        standard_array_filter: 'standard_array_filter',
        periodicity_filter: 'periodicity_filter',
        period_date_filter: 'period_date_filter',
        assignee_filter: 'assignee_filter',
        kpis: 'kpis',
        selected_category: 'selected_category',
        text_filter: 'text_filter',
        data_manager_filter: 'data_manager_filter',
        data_editor_filter: 'data_editor_filter',
        data_validator_filter: 'data_validator_filter',
        associated_kpis_filter: 'associated_kpis_filter'
    },
    [SCREENS.manage]: {
        sdg_filter: 'sdg_filter',
        type_filter: 'type_filter',
        tag_filter: 'tag_filter',
        status_filter: 'status_filter',
        standard_array_filter: 'standard_array_filter',
        text_filter: 'text_filter'
    },
    [SCREENS.data_collection]: {
        sdg_filter: 'sdg_filter',
        type_filter: 'type_filter',
        tag_filter: 'tag_filter',
        status_filter: 'status_filter',
        validation_status_filter: 'validation_status_filter',
        date_filter: 'date_filter',
        assignee_filter: 'assignee_filter',
        text_filter: 'text_filter'
    },
    [SCREENS.admin_data_requests]: {
        period_date_filter: 'period_date_filter',
        periodicity_filter: 'periodicity_filter',
        request_type_filter: 'request_type_filter',
        requested_by_filter: 'requested_by_filter',
        requested_to_filter: 'requested_to_filter',
        request_date_filter: 'request_date_filter',
        request_status_filter: 'request_status_filter',
        response_date_filter: 'response_date_filter',
        kpi_value_type_filter: 'kpi_value_type_filter',
        request_comment_attachment_filter: 'request_comment_attachment_filter',
        request_action_filter: 'request_action_filter',
        text_filter: 'text_filter',
    },
    [SCREENS.kpidetail_files]: {
        user_filter : 'user_filter',
        upload_date_filter: 'upload_date_filter',
        period_date_filter: 'period_date_filter',
        periodicity_filter: 'periodicity_filter',
        attachment_filter: 'attachment_filter',
        text_filter: 'text_filter',
    },
    [SCREENS.files]: {
        periodicity_filter: 'periodicity_filter',
        attachment_filter: 'attachment_filter',
        standard_array_filter: 'standard_array_filter',
        sdg_filter: 'sdg_filter',
        type_filter: 'type_filter',
        user_filter : 'user_filter',
        period_date_filter: 'period_date_filter',
        status_filter: 'status_filter',
        text_filter: 'text_filter',
    },
    [SCREENS.downloads]: {
        archive_status_filter: 'archive_status_filter',
        user_filter : 'user_filter',
        period_date_filter: 'period_date_filter',
        text_filter: 'text_filter',
    },
    [SCREENS.third_party_requests]: {
        suborganizations_filter: 'suborganizations_filter',
        periodicity_filter: 'periodicity_filter',
        period_date_filter: 'period_date_filter',
        request_date_filter: 'request_date_filter',
        response_date_filter: 'response_date_filter',
        request_status_filter: 'request_status_filter',
        text_filter: 'text_filter',
    },
    [SCREENS.bulk_manage]: {
      standard_array_filter: 'standard_array_filter',
      kpis: 'kpis',
      suborganizations_filter: 'suborganizations_filter',
      value_status_filter: 'value_status_filter',
      schema_type_filter: 'schema_type_filter',
      source_filter: 'source_filter',
      period_date_filter: 'period_date_filter',
      periodicity_filter: 'periodicity_filter',
      tag_filter: 'tag_filter',
      text_filter: 'text_filter',
      validation_status_filter: 'validation_status_filter',
  },
};
  
export const FILTER_TYPES = Object.keys(SCREENS).reduce((acc, curr) => ({...acc, ...SCREEN_FILTERS[curr]}), {});

export const DEFAULT_FILTER_SET = {
    [FILTER_TYPES.text_filter]: {
        defaultValue: '',
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.selected_category]: {
        defaultValue: null,
        condition: (set) => !_.isNull(set)
    },
    [FILTER_TYPES.kpis]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.assignee_filter]: {
        defaultValue: null,
        condition: (set) => !_.isNull(set) && set !== 'all'
    },
    [FILTER_TYPES.standard_filter]: {
        defaultValue: null,
        condition: (set) => !_.isNull(set)
    },
    [FILTER_TYPES.standard_array_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.sdg_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.type_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.tag_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.validation_status_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.status_filter]: {
        defaultValue: DEFAULT_STATUS_FILTER_STATE,
        condition: (set) => !_.isEqual(_.sortBy(set), _.sortBy(DEFAULT_STATUS_FILTER_STATE))
    },
    [FILTER_TYPES.date_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0 && !set.some(s => _.isEmpty(s))
    },
    [FILTER_TYPES.period_date_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0 && !set.some(s => _.isEmpty(s))
    },
    [FILTER_TYPES.upload_date_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0 && !set.some(s => _.isEmpty(s))
    },    
    [FILTER_TYPES.periodicity_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.request_type_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.requested_by_filter]: {
        defaultValue: '',
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.requested_to_filter]: {
        defaultValue: '',
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.request_date_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0 && !set.some(s => _.isEmpty(s))
    },
    [FILTER_TYPES.request_status_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.response_date_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0 && !set.some(s => _.isEmpty(s))
    },
    [FILTER_TYPES.kpi_value_type_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.request_comment_attachment_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.request_action_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.user_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.attachment_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.archive_status_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.data_manager_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.data_editor_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.data_validator_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.suborganizations_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.associated_kpis_filter]: {
        defaultValue: [],
        condition: (set) => set.length > 0
    },
    [FILTER_TYPES.schema_type_filter]: {
      defaultValue: [],
      condition: (set) => set.length > 0
    },
    [FILTER_TYPES.source_filter]: {
      defaultValue: [],
      condition: (set) => set.length > 0
    },
    [FILTER_TYPES.value_status_filter]: {
      defaultValue: [],
      condition: (set) => set.length > 0
  },
};
  
export const getFilterSet = (screen, defaultFiltersSet = {}) => {
    return Object.keys(SCREEN_FILTERS[screen]).map(type => ({...(defaultFiltersSet[type] || DEFAULT_FILTER_SET[type]), type})).filter(Boolean);
};

const SCREEN_FILTERS_TO_QUERY_FILTERS = {
    [SCREENS.admin_data_requests]: {
        period_date_filter: 'period_date',
        periodicity_filter: 'periodicity',
        request_type_filter: 'request_type',
        requested_by_filter: 'requested_by',
        requested_to_filter: 'requested_to',
        request_date_filter: 'requested_date',
        request_status_filter: 'request_status',
        response_date_filter: 'response_date',
        kpi_value_type_filter: 'kpi_type',
        request_comment_attachment_filter: 'request_comment_attachment',
        request_action_filter: 'request_action',
        text_filter: 'search',
    },
    [SCREENS.third_party_requests]: {
        suborganizations_filter: 'suborganizations',
        periodicity_filter: 'periodicity',
        period_date_filter: 'period_date',
        request_date_filter: 'requested_date',
        request_status_filter: 'request_status',
        response_date_filter: 'response_date',
        text_filter: 'search',
    },
    [SCREENS.bulk_manage]: {
      standard_array_filter: 'standard',
      kpis: 'kpis',
      suborganizations_filter: 'suborganizations',
      value_status_filter: 'status',
      schema_type_filter: 'schema_type',
      source_filter: 'source',
      period_date_filter: 'period_date',
      periodicity_filter: 'periodicity',
      tag_filter: 'tag',
      text_filter: 'search',
      validation_status_filter: 'validation_status',
  },
};

export const getQueryFiltersNames = (screen) => {
    return SCREEN_FILTERS_TO_QUERY_FILTERS[screen];
};

export const getQueryFilters = (screen, filterValues) => {
    const queryFiltersNames = getQueryFiltersNames(screen);
    return Object.entries(queryFiltersNames).reduce((obj, [filterName, queryFilterName]) => {
        if(filterValues[filterName] && DEFAULT_FILTER_SET[filterName].condition(filterValues[filterName])) {
          obj[queryFilterName] = filterValues[filterName];
        }
        return obj;
      }, {});
};

// NOTICE: match with FILTERS_COMPONENT_CATALOG in FiltersPanel component
export const FILTERS_COMPONENT_TYPES_CATALOG = {
    text: 'text',
    list: 'list',
    checkbox: 'checkbox',
    date: 'date',
    multiSelect: 'multiSelect',
    select: 'select',
    sdgSelect: 'sdgSelect',
    sdg: 'sdg',
    tags: 'tags',
    heading: 'heading',
    organizationSelect: 'organizationSelect',
    kpiTreeSelect: 'kpiTreeSelect',
};
