import React, { useMemo, useCallback } from "react";
import CustomLanguageInput from "../CustomLanguageInput";
import CategoryTreeParentSelector from "../CategoryTreeParentSelector";
import KpiCategoryIndicatorSearch from "../KpiCategoryIndicatorSearch";
import ConfigureTimeSelector from "../ConfigureTimeSelector";
import { isEmpty, flattenDeep, intersectionBy, differenceBy, compact, uniqBy } from "lodash";

export default ({
  intl,
  values,
  reportingStructure,
  errors,
  isDirty,
  isCreatingNewCategory,
  handleChange,
  handleNewCategory,
  categories,
  organization,
  suborganization,
  organizationName,
  standardsKpis,
  reporting_structure_kpis
}) => {

  const kpisAlreadyAdded = useMemo(() => intersectionBy(uniqBy(values.selected_kpis, 'slug'), reporting_structure_kpis, 'slug'), [reporting_structure_kpis, values.selected_kpis])
  const kpisNotAlreadyAdded = useMemo(() => differenceBy(uniqBy(values.selected_kpis, 'slug'), reporting_structure_kpis, 'slug'), [reporting_structure_kpis, values.selected_kpis])

  const getRecursiveKpis = useCallback((uuid) => {
    const kpis = standardsKpis.filter(({ parent }) => parent?.uuid === uuid)
    const subcategories = categories.filter(category => {
      const { parent = {} } = category;
      return parent?.uuid === uuid
    })

    return [...kpis, ...subcategories.map(({ uuid }) => getRecursiveKpis(uuid))]
  },
    [categories, standardsKpis])

  return useMemo(() => compact([
    {
      title: intl.formatMessage({ id: "create_or_edit_category_search" }),
      content: !isCreatingNewCategory ? (<KpiCategoryIndicatorSearch
        selection={values.selected_categories?.map(c => ({ value: c.uuid, useSubtree: values.useSubtree?.find(s => s === c.uuid) }))}
        subtreeCategories={values?.subtree_categories}
        listItems={categories}
        onCreate={handleNewCategory}
        onSelectItem={({ uuid }, checked, slug) => {
          const selectedCategories = checked ? [...(values.selected_categories || []), { slug, uuid }] : values.selected_categories?.filter(c => c.uuid !== uuid)
          handleChange('selected_categories')(selectedCategories)
          if (!checked && values?.useSubtree.includes(uuid)) {
            const useSubtree = values?.useSubtree.filter(us => us !== uuid);
            handleChange('useSubtree')(useSubtree)
            const subtreeCategories = categories.filter(category => {
              const { parent = {} } = category;
              return useSubtree.includes(parent?.uuid);
            }).map(ft => ft.uuid);
            handleChange('subtree_categories')(subtreeCategories)
            handleChange('selected_kpis')(values.selected_kpis.filter(({ parent }) => subtreeCategories.some(cat => parent.uuid === cat)))
          }
        }}
        onToggleUseSubtree={(uuid, checked) => {
          let subtreeCategories = categories.filter(category => {
            const { parent = {} } = category;
            return parent?.uuid === uuid
          }).map(ft => ft.uuid);


          handleChange('selected_kpis')(checked ? [...values.selected_kpis, ...flattenDeep(getRecursiveKpis(uuid))] : values.selected_kpis.filter(({ parent }) => ![uuid, ...subtreeCategories].some(cat => parent.uuid === cat)))

          subtreeCategories = checked ? [...subtreeCategories, ...values?.subtree_categories] : values?.subtree_categories.filter(sc => !subtreeCategories.includes(sc));
          handleChange('subtree_categories')(subtreeCategories);

          let useSubtree = checked ? [...(values.useSubtree || []), uuid] : values.useSubtree?.filter(c => c !== uuid);
          useSubtree = useSubtree.filter(sc => !subtreeCategories.includes(sc));
          handleChange('useSubtree')(useSubtree);

          const selectedCategories = values?.selected_categories.filter(sc => !subtreeCategories.includes(sc.uuid));
          handleChange('selected_categories')(selectedCategories);
        }}
        type='category'
        customStandardName={organizationName}
      />) : (
        <CustomLanguageInput
          values={values}
          errors={errors?.name_translations}
          isDirty={isDirty}
          handleChange={handleChange}
          subtitle={intl.formatMessage({ id: "form_error_max_title_length" })}
          placeholder={intl.formatMessage({ id: "create_or_edit_category_name_placeholder" })}
          maxLength={75}
        />
      ),
      isValid: () => (isCreatingNewCategory && !isEmpty(values.name_translations.find(({ locale }) => locale === suborganization.language).name)) || values.selected_categories?.length > 0,
    },
    values.selected_kpis.length ? {
      title: "Select start dates",
      content: (
        <ConfigureTimeSelector
          handleChange={handleChange}
          organization={organization}
          suborganization={suborganization}
          selectedKpis={uniqBy(values.selected_kpis, 'slug')}
          reporting_structure_kpis={reporting_structure_kpis}
          kpisAlreadyAdded={kpisAlreadyAdded}
          kpisNotAlreadyAdded={kpisNotAlreadyAdded}
        />
      ),
      isValid: () => kpisNotAlreadyAdded.every(kpi => kpi.periodicities?.length && kpi.cycle_date && kpi.year)
    }
      : null,
    {
      title: intl.formatMessage({ id: "create_or_edit_category_parent" }),
      content: <CategoryTreeParentSelector
        reportingStructure={(reportingStructure || [])}
        selectedCategories={(values?.selected_categories || []).map(({ uuid }) => uuid)}
        onSelectParent={handleChange('parents')}
        selectedParents={values?.parents}
        subtreeCategories={values?.subtree_categories}
        isForCategoryParentSelect
      />,
      isValid: () => true
    }
  ]), [intl, isCreatingNewCategory, values, categories, handleNewCategory, organizationName, errors?.name_translations, isDirty, handleChange, organization, suborganization, reporting_structure_kpis, kpisAlreadyAdded, kpisNotAlreadyAdded, reportingStructure, getRecursiveKpis])
}
