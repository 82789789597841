import config from 'config';

const DEFAULT_LANGUAGES = [
  'en-US',
  'es-ES',
  'pt-PT',
  'pt-BR',
  'fr-FR',
  'it-IT',
  'ca-ES',
];

const INVISIBLE_PRODUCTS = [
  'sso',
];

const FEATURE_PRODUCTS = [
  'materiality',
  'equality',
];

const VALID_STATUSES = [
  'active',

  // TODO: remove these two after the statuses are fixed
  'invited',
  'pending_email',
];

const DEFAULT_ORGANIZATION = {
  logo: `/images/aplanet.png`,
  logo_small: `/images/aplanet_a.png`,
  name: 'APlanet',
  slug: 'aplanet',
  id: 1,
  region: 'ES',
  is_default_org: true,
  languages: DEFAULT_LANGUAGES,
  role: 'admin',
  status: 'active',
  features: config.DEFAULT_FEATURES,
  features_upselling: config.DEFAULT_FEATURES_UPSELLING,
  products: config.DEFAULT_PRODUCTS,
};

const initialState = {
  fetching: false,
  slug: null, // NOTICE: We do not prefer if you mark the default org
  data: DEFAULT_ORGANIZATION,
  memberships: [
    DEFAULT_ORGANIZATION,
  ],
  error: null
};

const getOrganizationFromResponse = (response) => {
  const {
    organization_name,
    organization_slug,
    organization_logo,
    organization_logo_small,
    name: provided_name,
    slug: provided_slug,
    logo: provided_logo,
    logo_small: provided_logo_small,
    region,
    product_config: base_config,
    products: base_products,
    role,
    status
  } = response;

  // NOTICE: if we are processing a member we prefer organization_*
  const name = organization_name || provided_name;
  const slug = organization_slug || provided_slug;
  const logo = organization_logo || provided_logo;
  const logo_small = organization_logo_small || provided_logo_small;

  //if(slug === initialState.data.slug) {
  //  return initialState.data;
  //}

  const features = base_config && base_config.atlas && base_config.atlas.features
    ? Object.keys(base_config.atlas.features).filter(feature => base_config.atlas.features[feature])
    : [];

  const features_upselling = base_config && base_config.atlas && base_config.atlas.features_upselling
    ? Object.keys(base_config.atlas.features_upselling).filter(feature => base_config.atlas.features_upselling[feature])
    : [];

  const products = (base_products || [])
    .filter(
      product => !INVISIBLE_PRODUCTS.includes(product)
    )
    .concat(
      features.filter(feature => FEATURE_PRODUCTS.includes(feature))
    )

  let product_config = (base_config || {}).atlas || base_config;

  // NOTICE: Workaround because the password policy is a 'community' setting, but applies to both products
  if(base_config.community && base_config.community.password_policy) {
    product_config.password_policy = base_config.community.password_policy;
  }

  return {
    name,
    slug,
    logo,
    logo_small,
    region,
    features,
    features_upselling,
    config: product_config,
    general_config: base_config.general,
    languages: product_config?.preferred_languages || DEFAULT_LANGUAGES,
    products,
    role,
    status,
  };
};

const reducer = (state = initialState, action) => {
  let org, memberships;
  switch (action.type) {
    case 'IDENTITY_COMPLETE':
      if(
        !action.user ||
        !action.user.organizations ||
        action.user.organizations.length === 0
      ) {
        return state;
      }
      if(action.user.organizations.length > 1) {
        memberships = action.user.organizations.filter(org => VALID_STATUSES.includes(org.status)).map(getOrganizationFromResponse);
        org = memberships.find(
          ({
            slug,
            products = [],
          }) => {
            return state.slug &&
              slug === state.slug &&
              products.find(product => product === 'atlas')
          }
        );
        return {
          ...state,
          data: org || null,
          slug: (org || {}).slug || null,
          //data: null,
          //slug: null,
          memberships,
        };
      }

      org = getOrganizationFromResponse(action.user.organizations[0]);
      return {
        ...state,
        data: org,
        slug: org.slug,
        memberships: [ org ],
      };
    case 'ORGANIZATION_RESET':
      if(state.memberships.length > 1) {
        return {
          ...state,
          data: null,
          slug: null,
        };
      }
      return state;
    case 'ORGANIZATION_SET':
      org = state.memberships.find(({ slug }) => slug === action.slug);

      if(org) {
        return {
          ...state,
          data: org,
          slug: org.slug,
        };
      }
      return state;

    case 'REFRESH_TOKEN_FAILED':
      return initialState;
    case 'HYDRATE_STORE':
      return action.state && action.state.organization_slug ? {
        ...state,
        slug: action.state.organization_slug,
      } : state;
    default:
      return state;
  }
};

export {
  reducer as organization,
  DEFAULT_ORGANIZATION as defaultOrganization,
};
