import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

import ReportingStandardTag from '../../../../components/ReportingStandardTag';
import AplanetIcon from 'components/AplanetIcon';

const KpiTreeTitle = ({
  nodeData,
  searchText = '',
  enabledReports = []
}) => {
  if (nodeData.isCategory) {
    return (
      <div className="DataRequestKpiTreeSelector__wrapper">
        <AplanetIcon name="Folder open" size="18px" className="DataRequestKpiTreeSelector__icon" />
        {nodeData.code
          ? (
            <ReportingStandardTag
              className="DataRequestKpiTreeSelector__standard_tag"
              standard={nodeData.standard}
              name={nodeData.code}
              code={nodeData.code}
              key={`${nodeData.standard}__${nodeData.code}`}
            />
          )
          : null}
        <span className="DataRequestKpiTreeSelector__title">
          { !searchText
            ? nodeData.title
            : <Highlighter
                highlightClassName="DataRequestKpiTreeSelector__highlighter"
                searchWords={[searchText]}
                textToHighlight={nodeData.title}
                autoEscape
              />
          }
        </span>
      </div>
    );
  }

  return (
    <div className="DataRequestKpiTreeSelector__wrapper">
      <AplanetIcon name="KPI" faStyle="fas" size="18px" className="DataRequestKpiTreeSelector__icon" />
      { (nodeData.standard_info || [])
        .filter(({ standard, code }) => !!code && (nodeData.is_custom || enabledReports.includes(standard)))
        .map(({standard, code}) => (
          <React.Fragment
            key={`${standard}__${code}`}
          >
            <ReportingStandardTag
              className="DataRequestKpiTreeSelector__standard_tag"
              standard={standard}
              name={code}
              code={code}
            />
            {' '}
          </React.Fragment>
        ))
      }
      <span className="DataRequestKpiTreeSelector__title">
        { !searchText
          ? nodeData.title
          : <Highlighter
              highlightClassName="DataRequestKpiTreeSelector__highlighter"
              searchWords={[searchText]}
              textToHighlight={nodeData.title}
              autoEscape
            />
        }
      </span>
    </div>
  );
}

KpiTreeTitle.propTypes = {
  nodeData: PropTypes.object.isRequired,
  searchText: PropTypes.string,
  enabledReports: PropTypes.arrayOf(PropTypes.string),
};

export default KpiTreeTitle;
