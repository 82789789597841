import React, {
  useMemo,
  useCallback,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import { useSelector } from 'react-redux';
import { flatten } from 'lodash';

  import {
    Pagination,
    Tree,
  } from 'antd';

  import { Empty } from 'tsComponents/emptyStates/Empty';
  import { getEmptyResultsProps } from 'tsComponents/emptyStates/emptyProps';
  import KpiTreeTitle from './KpiTreeTitle';
  
  import {
    searchInReportingStructureTree,
    getFlattenKpis,
    reportingStructureToTree,
    getRSAncestorsCategoriesOfCategory,
    getRSAncestorsCategoriesOfKpi,
    filterReportingStructureTree,
    appliesReportingStructureTree,
  } from 'utils/reporting_structure';
  import {
    flattenTree,
    mapOverTreeFromLeafs,
    reduceOverTree,
  } from 'utils/tree';
  
  import './style.less';

  const PAGINATION_PAGESIZE = 10;
  
  const KpiTreeSelector = ({
    intl,
    reportingStructure,
    onSelectKpis,
    selectedKpis,
    isCategoryCheckable = true,
    isNodeDisabled,
    extraColumns = [],
    filters = [],
    enabledReports = []
  }) => {

    const [expandedTreeNodes, setExpandedTreeNodes] = useState([]);
    const [treeNodes, setTreeNodes] = useState();
    const [checkedKpis, setCheckedKpis] = useState([]);
    const [pagination, setPagination] = useState({
      totalRows: 0,
      currentPage: 1,
      minIndex: 0,
      maxIndex: 0
    });
    const { sdgs = [] } = useSelector((state) => state.taxonomies);

    const isFilterSet = useMemo(() => {
      return filters.search || filters.standards || filters.sdg_codes || filters.esg_types;
    }, [filters]);

    const hasSearch = Boolean(filters.search);
    const hasFilters = Boolean(filters.standards || filters.sdg_codes || filters.esg_types);

    const reportingStructureTree = useMemo(
        () => reportingStructureToTree(reportingStructure || []),
        [reportingStructure]
    );
  
    const filteredReportingStructureTree = useMemo(
      () => {

        let filteredTreeData = reportingStructureTree;

        filteredTreeData = appliesReportingStructureTree(filteredTreeData, false);

        if (!isFilterSet){
          return filteredTreeData;
        }

        const expandedNodes = [];

        if (filters.search) {
          filteredTreeData = searchInReportingStructureTree(filteredTreeData, filters.search.toLowerCase(), expandedNodes);
          setExpandedTreeNodes(expandedNodes);
        } else {
          setExpandedTreeNodes([]);
        }

        if (filters.standards && filters.standards.length > 0) {
          filteredTreeData = filterReportingStructureTree(filteredTreeData, 'standard_info', filters.standards);
        }

        if (filters.sdg_codes && filters.sdg_codes.length > 0) {
          const theSdgs = flatten(filters.sdg_codes.map( selectedSlug => sdgs.find( sdg => sdg.slug === selectedSlug ).targets.map( target => target.slug )));
          filteredTreeData = filterReportingStructureTree(filteredTreeData, 'sdgs', theSdgs);
        }

        if (filters.esg_types && filters.esg_types.length > 0) {
          filteredTreeData = filterReportingStructureTree(filteredTreeData, 'esgs', filters.esg_types);
        }

        return filteredTreeData;
      },
      [
        reportingStructureTree,
        filters,
        isFilterSet,
        sdgs
      ]
    );

    const columnHeaders = {
      periodicity: intl.formatMessage({ id: 'dashboard_card_form_select_kpis_column_periodicity' }),
      standard: intl.formatMessage({ id: 'standard' }),
      esg: intl.formatMessage({ id: 'type' }),
      sdg: intl.formatMessage({ id: 'sdg' }),
    }

    const treeData = useMemo(
      () => {
        return mapOverTreeFromLeafs(filteredReportingStructureTree)((reportingStructureNode, children = []) => {
          if (!!reportingStructureNode.category) {
            const kpisInside = getFlattenKpis([reportingStructureNode])() || [];
            return {
              uuid: reportingStructureNode.category.uuid,
              checkable: isCategoryCheckable && kpisInside.length > 0,
              isCategory: true,
              title: reportingStructureNode.category.name,
              name: reportingStructureNode.category.name,
              slug: reportingStructureNode.category.slug,
              code: reportingStructureNode.category.code,
              standard: reportingStructureNode.category.standard,
              key: reportingStructureNode.category.uuid,
              children,
              disabled: isCategoryCheckable && kpisInside.length > 0 ? isNodeDisabled(reportingStructureNode, checkedKpis) : false,
              ancestorsCategories: getRSAncestorsCategoriesOfCategory(reportingStructure, reportingStructureNode.category.uuid),
            }
          }

          const kpiCodes = (reportingStructureNode.standard_info || [])
                          .filter(({ standard }) => reportingStructureNode.is_custom || enabledReports.includes(standard))
                          .map(({ code }) => code); 
          return {
            ...reportingStructureNode,
            uuid: reportingStructureNode.uuid,
            title: reportingStructureNode.name,
            key: reportingStructureNode.uuid,
            isLeaf: true,
            disabled: isNodeDisabled(reportingStructureNode, checkedKpis),
            code: kpiCodes.length ? kpiCodes[0] : undefined, 
            ancestorsCategories: getRSAncestorsCategoriesOfKpi(reportingStructure, reportingStructureNode.uuid),
          };
        });
      },
      [
        filteredReportingStructureTree,
        reportingStructure,
        isCategoryCheckable,
        isNodeDisabled,
        checkedKpis,
        enabledReports,
      ]
    );
  
    useEffect(() => {
      let expandedNodes = [...expandedTreeNodes];
      mapOverTreeFromLeafs(filteredReportingStructureTree)((reportingStructureNode) => {
        if (!!reportingStructureNode.category) {
          const kpisInside = getFlattenKpis([reportingStructureNode])() || [];
          if (kpisInside.some(kpi => (checkedKpis.map(({uuid}) => uuid)).includes(kpi.uuid)) && !expandedTreeNodes.includes(reportingStructureNode.category.uuid)) {
            expandedNodes = [...expandedNodes, reportingStructureNode.category.uuid];
          }
        }
      });
      setExpandedTreeNodes(expandedNodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      setPagination(prevPagination => ({
        ...prevPagination,
        totalRows: filteredReportingStructureTree.length,
        minIndex: 0,
        maxIndex: PAGINATION_PAGESIZE,
        currentPage: 1
      }));
    }, [filteredReportingStructureTree]);

    const handleChangePagination = (page) => {
      setPagination(prevPagination => ({
        ...prevPagination,
        currentPage: page,
        minIndex: (page - 1) * PAGINATION_PAGESIZE,
        maxIndex: page * PAGINATION_PAGESIZE
      }));
    }
  
    const handleCheck = useCallback(
      (_, {checked, checkedNodes, node}) => {
        let checkedKpisData = [...checkedKpis];
        let checkedKpisDataUUids = checkedKpisData.map(({uuid}) => uuid);
  
        checkedNodes.forEach(nodeData => {
            if (!nodeData.isCategory && !checkedKpisDataUUids.includes(nodeData.uuid)) {
              checkedKpisData.push(nodeData);
            }
          }
        );
  
        if(!checked) {
          const checkedUuidsFromCurrentNodeAndParents = [...Object.keys(flattenTree(node, 'uuid')), ...node.ancestorsCategories.map(({uuid}) => uuid)];
          checkedKpisData = checkedKpisData.filter(k => !checkedUuidsFromCurrentNodeAndParents.includes(k.uuid))
        }
  
        setCheckedKpis(checkedKpisData);
        onSelectKpis(checkedKpisData);
      },
      [checkedKpis, onSelectKpis]
    );
  
    const checkedKeys = useMemo(
      () => checkedKpis.map(({uuid}) => uuid),
      [checkedKpis]
    );

    const kpisBySlugs = useMemo(() => {

        if (!Array.isArray(selectedKpis) || selectedKpis.length < 0) return [];

        return reduceOverTree(filteredReportingStructureTree)((arr, node) => {
          if (!node.category && selectedKpis.some( kpi => kpi.slug === node.slug && ((!kpi.uuid) || (kpi.uuid === node.uuid)))){
            if (!arr.some(item => item.slug === node.slug)){
              arr.push(node);
            }
          }
          return arr;
        }, []);
      
    }, [filteredReportingStructureTree, selectedKpis]);

    useEffect(() => {
      if (Array.isArray(treeData)){
        setTreeNodes(treeData.slice(pagination.minIndex, pagination.maxIndex));
      }
    }, [treeData, pagination.minIndex, pagination.maxIndex]);

    useEffect(() => {

      if (Array.isArray(treeData) && Array.isArray(kpisBySlugs) && kpisBySlugs.length > 0 && checkedKpis.length === 0){
        setCheckedKpis(kpisBySlugs);
        onSelectKpis(kpisBySlugs);
      }

    }, [treeData, kpisBySlugs, onSelectKpis, checkedKpis.length]);
  
    return (
      <>
        <div className="KpiTreeSelector">
          <div className="KpiTreeSelector__header">
            <span className="KpiTreeSelector__header-indent"></span>
            <div className="KpiTreeSelector__header__columns">
              <div className='KpiTreeSelector__column__title'>{ intl.formatMessage({ id: 'dashboard_card_form_select_kpis_column_kpi' }) }</div>
              {
                extraColumns.map( column => (
                    <div key={ column } className={`KpiTreeSelector__column__${ column }`}>{ columnHeaders[column] }</div>
                ))
              }
            </div>
            <span className="KpiTreeSelector__header-scrollIndent"></span>
          </div>
          <div className="KpiTreeSelector__tree">
            {treeData?.length === 0  
              ? <Empty {...getEmptyResultsProps(hasSearch, hasFilters)} />
              : (
                <Tree 
                  expandedKeys={expandedTreeNodes}
                  onExpand={setExpandedTreeNodes}
                  treeData={treeNodes}
                  titleRender={nodeData => <KpiTreeTitle 
                                              nodeData={nodeData} 
                                              searchText={filters.search} 
                                              extraColumns={extraColumns} 
                                              isExpanded={expandedTreeNodes.includes(nodeData.key)} 
                                              enabledReports={enabledReports}
                                            />}
                  checkedKeys={checkedKeys}
                  onCheck={handleCheck}
                  showIcon={false}
                  expandAction={false}
                  selectable={false}
                  multiple
                  checkable 
                  height={600}
                />
            )}
          </div>
        </div>
        {
          treeData && 
          <Pagination
            pageSize={PAGINATION_PAGESIZE}
            current={pagination.currentPage}
            total={pagination.totalRows}
            onChange={handleChangePagination}  
            showSizeChanger={false}
          />
        }
      </>
    );
  }
  
  KpiTreeSelector.propTypes = {
    reportingStructure: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedKpis: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
    })).isRequired,
    onSelectKpis: PropTypes.func.isRequired,
    extraColumns: PropTypes.arrayOf(PropTypes.string),
    isCategoryCheckable: PropTypes.bool,
    isNodeDisabled: PropTypes.func,
    enabledReports: PropTypes.arrayOf(PropTypes.string),
  };
  
  export default injectIntl(KpiTreeSelector);  