/* eslint-disable communist-spelling/communist-spelling */
import React, { Fragment, useEffect, useState, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { chain } from 'lodash';

import { formatDate, DATE_ONLY } from 'utils/date';
import useOrganizations from 'utils/useOrganizations';
import { flattenTree, mapOverTreeFromLeafs } from 'utils/tree';

import { Loading } from 'tsComponents/emptyStates/Loading';
import MainLayout from 'components/MainLayout';
import SustainableIssues from 'components/SustainableIssues';
import AnalysisBenchmark from 'components/AnalysisBenchmark';
import AnalysisGoi from 'components/AnalysisGoi';
import ActiveListening from 'components/ActiveListening';
import AnalysisResult from 'components/AnalysisResult';

import {
  Row,
  Col,
  Tabs,
  message
} from 'antd';

import {
  requestAnalysis,
  requestAnalyses,
  createAnalysis,
  requestAnalysisSusIssues,
  requestSusIssues,
  addAnalysisSusIssue,
  deleteAnalysisSusIssue,
  addCompany,
  addActiveListeningMedium,
  uploadBenchmark,
  uploadActiveListeningData,
  requestCompanies,
  requestActiveListeningMediums,
  startAnalysis,
  startActiveListening,
  requestSurveys,
  requestAnalysisResult,
  updateAnalysisResult,
  updateAnalysis,
  createSurvey,
  closeSurveys,
  closeAnalysis,
} from '../../actions/api';

import './style.less';
import CustomButton from 'components/CustomButton';

const { TabPane } = Tabs;

const Analysis = ({
  intl,
  match,
  error,
  requestAnalysis,
  requestAnalyses,
  closeAnalysis,
  requestAnalysisSusIssues,
  requestSusIssues,
  requestCompanies,
  requestActiveListeningMediums,
  startAnalysis,
  startActiveListening,
  requestSurveys,
  requestAnalysisResult,
  updateAnalysisResult,
  updateAnalysis,
  addAnalysisSusIssue,
  deleteAnalysisSusIssue,
  addCompany,
  addActiveListeningMedium,
  uploadBenchmark,
  uploadActiveListeningData,
  createSurvey,
  analyses,
  analysis,
  analysisSusIssues: unprocessedAnalysisSusIssues,
  fetchingAnalysisSusIssues,
  susIssues: unprocessedSusIssues,
  fetchingSusIssues,
  companies,
  activeListeningMediums,
  surveys,
  closeSurveys,
  result,
  benchmarkUploadError,
  activeListeningUploadError,
  history,
  userProfile,
}) => {
  const t = intl.messages;
  const { organization, suborganization } = useOrganizations();
  const { id, stage } = match.params;

  const {
    slug: mainOrgSlug,
  } = organization;
  const {
    slug: subOrgSlug,
  } = suborganization;

  const tabs = useMemo(() => ({
    'issues': '0',
    'internal_financial_environment_social_impact': '1',
    'internal_integration_into_strategy_action_plans_and_supply': '2',
    'internal_public_position_taking_on_issues': '3',
    'benchmark': '4',
    'active_listening': '5',
    'external': '6',
    'results': '7',
  }), []);

  const [activeTabKey, setActiveTabKey] = useState(tabs[stage]);

  useEffect(() => {
    requestAnalyses(mainOrgSlug, subOrgSlug);
    if (id) {
      requestAnalysis(mainOrgSlug, subOrgSlug, id);
      requestAnalysisSusIssues(mainOrgSlug, subOrgSlug, id);
    }
  }, [
    mainOrgSlug, subOrgSlug, requestAnalysis, requestAnalyses, requestAnalysisSusIssues, id
  ]);

  useEffect(() => {
    if (id) {
      requestActiveListeningMediums(mainOrgSlug, subOrgSlug, id);
    }
  }, [
    mainOrgSlug, subOrgSlug, requestActiveListeningMediums, id
  ]);

  useEffect(() => {
    if(error){
      message.error(intl.formatMessage({id: 'analysis_error'}))
    }
  }, [error, intl]);

  useEffect(() => {
    if (id) {
      requestCompanies(mainOrgSlug, subOrgSlug, id);
    }
  }, [
    mainOrgSlug, subOrgSlug, requestCompanies, id
  ]);

  useEffect(() => {
    setActiveTabKey(tabs[stage || 'issues']);
  },
    [stage, tabs]
  );

  const analysisSusIssues = useMemo(
    () => {
      return unprocessedAnalysisSusIssues.map(category => {
        const kpis = Object.values(
          flattenTree(category)
        )
        .map(({ kpis }) => kpis || [])
        .reduce((arr, el) => arr.concat(el), []);

        return {
          ...category,
          esg_types: chain(kpis.map(kpi => kpi.esgs)).flatten().uniq().compact().value(),
          sdgs: chain(kpis.map(kpi => kpi.sdgs)).flatten().uniq().compact().value(),
          standards: chain(kpis.map(kpi => Object.values(kpi.standard_info).map(({standard}) => standard))).flatten().uniq().compact().value(),
        };
      });
    },
    [unprocessedAnalysisSusIssues]
  );

  const susIssues = useMemo(
    () => {
      return unprocessedSusIssues && mapOverTreeFromLeafs(unprocessedSusIssues)((category, children=[]) => {
        const kpis = Object.values(
          flattenTree(category)
        )
        .map(({ kpis }) => kpis || [])
        .reduce((arr, el) => arr.concat(el), []);

        return {
          ...category,
          children,
          esg_types: chain(kpis.map(kpi => kpi.esgs)).flatten().uniq().compact().value(),
          sdgs: chain(kpis.map(kpi => kpi.sdgs)).flatten().uniq().compact().value(),
          standards: chain(kpis.map(kpi => Object.values(kpi.standard_info).map(({standard}) => standard))).flatten().uniq().compact().value(),
        };
      });
    },
    [unprocessedSusIssues]
  );

  const onRequestSusIssues = useCallback(
    (filter) => {
      requestSusIssues(mainOrgSlug, subOrgSlug, filter);
    },
    [requestSusIssues, mainOrgSlug, subOrgSlug]
  );

  const onAddAnalysisSusIssue = useCallback(
    (issues) => {
      addAnalysisSusIssue(mainOrgSlug, subOrgSlug, id, issues);
    },
    [addAnalysisSusIssue, mainOrgSlug, subOrgSlug, id]
  );

  const onAddCompany = useCallback(
    (data) => {
      addCompany(mainOrgSlug, subOrgSlug, data);
    },
    [addCompany, mainOrgSlug, subOrgSlug]
  );

  const onStartAnalysis = useCallback(
    (selectedCompanies) => {
      startAnalysis(mainOrgSlug, subOrgSlug, analysis.id, selectedCompanies);
    },
    [startAnalysis, mainOrgSlug, subOrgSlug, analysis]
  );

  const onUploadBenchmark = useCallback(
    (data) => {
      uploadBenchmark(mainOrgSlug, subOrgSlug, analysis.id, data);
    },
    [uploadBenchmark, analysis, mainOrgSlug, subOrgSlug]
  );

  const onUpdateAnalysis = useCallback(
    (data) => {
      updateAnalysis(mainOrgSlug, subOrgSlug, analysis.id, data);
    },
    [analysis, mainOrgSlug, subOrgSlug, updateAnalysis]
  );

  const onCreateSurvey = useCallback(
    (name, type, csvData) => {
      createSurvey(mainOrgSlug, subOrgSlug, analysis.id, name, type, csvData);
    },
    [analysis, mainOrgSlug, subOrgSlug, createSurvey]
  );

  const onCloseSurveys = useCallback(
    (type) => {
      closeSurveys(mainOrgSlug, subOrgSlug, analysis.id, type);
    },
    [analysis, mainOrgSlug, subOrgSlug, closeSurveys]
  );

  const onRequestAnalysisResult = useCallback(
    () => {
      if (analysis.id) {
        requestAnalysisResult(mainOrgSlug, subOrgSlug, analysis.id)
      }
    },
    [requestAnalysisResult, analysis, mainOrgSlug, subOrgSlug]
  );

  const onUpdateAnalysisResult = useCallback(
    (data) => {
      if (analysis.id) {
        updateAnalysisResult(mainOrgSlug, subOrgSlug, analysis.id, data)
      }
    },
    [updateAnalysisResult, analysis, mainOrgSlug, subOrgSlug]
  );

  const onRequestSurveys = useCallback(
    () => {
      if (analysis.id) {
        requestSurveys(mainOrgSlug, subOrgSlug, analysis.id);
      }
    },
    [requestSurveys, mainOrgSlug, subOrgSlug, analysis]
  )

  const changeTab = useCallback(
    tab => {
      const tabKey = Object.keys(tabs).find(key => tabs[key] === tab)
      history.push(`${tabKey}`);
    },
    [history, tabs]
  );

  const isDisabled = useCallback(
    (tabKey) => {
      if (!analysis) {
        return true;
      }
      return parseInt(tabs[analysis.stage]) < parseInt(tabKey);
    },
    [analysis, tabs]
  );

  const enabledReports = useMemo(() => {
    return [
      ...suborganization.product_config?.atlas?.enabled_reports || [],
      'custom',
    ];
  }, [
    suborganization,
  ]);

  const handleOnClose = useCallback(
    () => {
      if (analysis.id) {
        closeAnalysis(mainOrgSlug, subOrgSlug, analysis.id)
      }
    },
    [closeAnalysis, analysis, mainOrgSlug, subOrgSlug]
  );

  if (!analysis.id) {
    return <Loading />;
  }
  return (
    <Fragment>
      <MainLayout>
        <div className="Analysis">
          <Row>
            <Col span={24} className="Analysis-title">
              {analysis.name}
            </Col>
            <Col>
              {(analysis.start_date || analysis.end_date) && (
                <h4>
                  {t.scope}: {
                    formatDate(analysis.start_date, DATE_ONLY, intl)
                  } - {
                    formatDate(analysis.end_date, DATE_ONLY, intl)
                  }
                </h4>
              )}
            </Col>
          </Row>
          <Tabs activeKey={activeTabKey} onChange={changeTab}>
            <TabPane tab={t[`analysis_${analysis.method}_tab_topics`]} key={tabs.issues}>
              <SustainableIssues
                mainOrgSlug={mainOrgSlug}
                enabledReports={enabledReports}
                analysisSusIssues={analysisSusIssues}
                fetchingAnalysisSusIssues={fetchingAnalysisSusIssues}
                susIssues={susIssues}
                fetchingSusIssues={fetchingSusIssues}
                addAnalysisSusIssue={onAddAnalysisSusIssue}
                deleteAnalysisSusIssue={(id) => deleteAnalysisSusIssue(mainOrgSlug, subOrgSlug, analysis.id, id)}
                closeStep={() => onUpdateAnalysis({stage: 'results'})}
                analysis={analysis}
                requestSusIssues={onRequestSusIssues} />
            </TabPane>
            { analysis.method === 'internal_external' &&
              <>
                <TabPane
                  tab={t[`analysis_${analysis.method}_tab_goi_internal_financial_environment_social_impact`]}
                  key={tabs.internal_financial_environment_social_impact}
                  disabled={isDisabled(tabs.internal_financial_environment_social_impact)}>
                  <AnalysisGoi
                    createSurvey={onCreateSurvey}
                    requestSurveys={onRequestSurveys}
                    analysis={analysis}
                    mainOrgSlug={mainOrgSlug}
                    surveys={surveys}
                    closeSurveys={onCloseSurveys}
                    analysisSusIssues={analysisSusIssues}
                    surveyType="internal_financial_environment_social_impact"
                  />
                </TabPane>
                <TabPane
                  tab={t[`analysis_${analysis.method}_tab_goi_internal_integration_into_strategy_action_plans_and_supply`]}
                  key={tabs.internal_integration_into_strategy_action_plans_and_supply}
                  disabled={isDisabled(tabs.internal_integration_into_strategy_action_plans_and_supply)}>
                  <AnalysisGoi
                    createSurvey={onCreateSurvey}
                    requestSurveys={onRequestSurveys}
                    analysis={analysis}
                    mainOrgSlug={mainOrgSlug}
                    surveys={surveys}
                    closeSurveys={onCloseSurveys}
                    analysisSusIssues={analysisSusIssues}
                    surveyType="internal_integration_into_strategy_action_plans_and_supply"
                  />
                </TabPane>
                <TabPane
                  tab={t[`analysis_${analysis.method}_tab_goi_internal_public_position_taking_on_issues`]}
                  key={tabs.internal_public_position_taking_on_issues}
                  disabled={isDisabled(tabs.internal_public_position_taking_on_issues)}>
                  <AnalysisGoi
                    createSurvey={onCreateSurvey}
                    requestSurveys={onRequestSurveys}
                    analysis={analysis}
                    mainOrgSlug={mainOrgSlug}
                    surveys={surveys}
                    closeSurveys={onCloseSurveys}
                    analysisSusIssues={analysisSusIssues}
                    surveyType="internal_public_position_taking_on_issues"
                  />
                </TabPane>
                <TabPane tab={t[`analysis_${analysis.method}_tab_goi`]} key={tabs.external} disabled={isDisabled(tabs.external)}>
                  <AnalysisGoi
                    createSurvey={onCreateSurvey}
                    requestSurveys={onRequestSurveys}
                    analysis={analysis}
                    mainOrgSlug={mainOrgSlug}
                    surveys={surveys}
                    closeSurveys={onCloseSurveys}
                    analysisSusIssues={analysisSusIssues}
                    surveyType="external"
                  />
                </TabPane>
              </>
            }
            <TabPane tab={t[`analysis_${analysis.method}_tab_benchmark`]} key={tabs.benchmark} disabled={isDisabled(tabs.benchmark)}>
              <AnalysisBenchmark
                mainOrgSlug={mainOrgSlug}
                analysis={analysis}
                analysisSusIssues={analysisSusIssues}
                fetchingAnalysisSusIssues={fetchingAnalysisSusIssues}
                companies={companies}
                addCompany={onAddCompany}
                startAnalysis={onStartAnalysis}
                uploadBenchmark={onUploadBenchmark}
                error={benchmarkUploadError}
                userProfile={userProfile}
              />
            </TabPane>
            <TabPane tab={t[`analysis_${analysis.method}_tab_active_listening`]} key={tabs.active_listening} disabled={isDisabled(tabs.active_listening)}>
              <ActiveListening
                mainOrgSlug={mainOrgSlug}
                analysis={analysis}
                addMedium={(data) => addActiveListeningMedium(mainOrgSlug, subOrgSlug, data)}
                startActiveListening={mediums => startActiveListening(mainOrgSlug, subOrgSlug, analysis.id, mediums)}
                mediums={activeListeningMediums}
                error={activeListeningUploadError}
                analysisSusIssues={analysisSusIssues}
                fetchingAnalysisSusIssues={fetchingAnalysisSusIssues}
                uploadActiveListeningData={data => uploadActiveListeningData(mainOrgSlug, subOrgSlug, analysis.id, data)}
                userProfile={userProfile}
              />
            </TabPane>
            { analysis.method !== 'internal_external' &&
              <TabPane tab={t[`analysis_${analysis.method}_tab_goi`]} key={tabs.external} disabled={isDisabled(tabs.external)}>
                <AnalysisGoi
                  createSurvey={onCreateSurvey}
                  requestSurveys={onRequestSurveys}
                  analysis={analysis}
                  mainOrgSlug={mainOrgSlug}
                  surveys={surveys}
                  closeSurveys={onCloseSurveys}
                  analysisSusIssues={analysisSusIssues}
                  surveyType="external"
                />
              </TabPane>
            }
            <TabPane tab={t[`analysis_${analysis.method}_tab_matrix`]} key={tabs.results} disabled={isDisabled(tabs.results)}>
              {analysis.status === 'completed'
                ? <AnalysisResult
                    analysis={analysis}
                    mainOrgSlug={mainOrgSlug}
                    requestAnalysisResult={onRequestAnalysisResult}
                    updateAnalysisResult={onUpdateAnalysisResult}
                    result={result.data}
                    activity={result.activity}
                    loading={result.fetching}
                  />
                : <Row type="flex" justify="center">
                    <CustomButton
                      type="primary"
                      onClick={handleOnClose}
                    >
                      {t.analysis_close}
                    </CustomButton>
                  </Row>
              }
            </TabPane>
          </Tabs>
        </div>
      </MainLayout>
    </Fragment>
  )
};

const mapStateToProps = ({
  analysis,
  analysis_sus_issues,
  sus_issues,
  company,
  survey,
  active_listening_medium,
  analysis_result,
  benchmark,
  active_listening,
  profile,
}) => ({
  analysis: analysis.item,
  analyses: analysis.data,
  analysisSusIssues: analysis_sus_issues.data,
  fetchingAnalysisSusIssues: analysis_sus_issues.fetching,
  companies: company.data,
  activeListeningMediums: active_listening_medium.data,
  susIssues: sus_issues.data,
  fetchingSusIssues: sus_issues.fetching,
  surveys: survey.data,
  result: analysis_result,
  error: analysis.error || survey.error,
  benchmarkUploadError: benchmark.error,
  activeListeningUploadError: active_listening.error,
  userProfile: profile.data,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      requestAnalysis,
      requestAnalyses,
      createAnalysis,
      closeAnalysis,
      requestAnalysisSusIssues,
      requestCompanies,
      requestActiveListeningMediums,
      startAnalysis,
      startActiveListening,
      requestSurveys,
      requestAnalysisResult,
      updateAnalysisResult,
      updateAnalysis,
      requestSusIssues,
      addAnalysisSusIssue,
      deleteAnalysisSusIssue,
      addCompany,
      addActiveListeningMedium,
      uploadBenchmark,
      uploadActiveListeningData,
      createSurvey,
      closeSurveys,
    }
  )(Analysis)
);
