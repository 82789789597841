import React, {
  useState,
  useCallback,
  useMemo
} from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  List,
  Switch,
  Button
} from 'antd';

import CustomCheckbox from 'components/CustomCheckbox';
import CustomInputSearch from 'components/CustomInputSearch';
import CustomSelect from 'components/CustomSelect';
import A from 'components/A';

import ReportingStandardTag from 'components/ReportingStandardTag';

import './style.less';
import { byCode } from 'utils/kpi';
import { multipleArraySort } from 'utils/array';

const CHECKED_UNCHECKED_FILTER = {
  checked: 'checked',
  unchecked: 'unchecked'
};

const SEARCH_TYPE = {
  category: 'category',
  indicator: 'indicator',
  DEFAULT: 'indicator'
};

const CREATE_KPI_TYPES = ['quantitative', 'qualitative', 'boolean', 'table'];

const KpiCategoryIndicatorSearch = ({
  intl,
  listItems = [],
  selection = [],
  onSelectItem,
  onToggleUseSubtree,
  onCreate,
  subtreeCategories = [],
  customStandardName,
  type = SEARCH_TYPE.DEFAULT
}) => {

  const [searchTerm, setSearchTerm] = useState('');
  const [checkedUncheckedFilter, setCheckedUncheckedFilter] = useState('');
  const [onlyNewFilter, setOnlyNewFilter] = useState(false);

  const isItemChecked = useCallback(item => {
    return selection.some((itemSelected) => itemSelected.value === item);
  }, [selection]);

  const sortedItems = useMemo(() => {

    const sortFunction = multipleArraySort([
      (a, b) => {
        const aIsNew = a && 'isNew' in a && !!a.isNew;
        const bIsNew = b && 'isNew' in b && !!b.isNew;
        if (aIsNew !== bIsNew) {
          return !!aIsNew ? -1 : 1;
        }
        return 0;
      },
      (a, b) => (a.standard || '').localeCompare((b.standard || ''), intl.locale),
      (a, b) => byCode(intl.locale)(a, b),
      (a, b) => (a.name || '').localeCompare((b.name || ''), intl.locale),
    ]);

    return listItems.map(item => ({
      standard: item.standard_info && item.standard_info[0].standard,
      code: item.standard_info && item.standard_info[0].code,
      ...item
    })).sort(sortFunction);
  },
    [listItems, intl.locale]
  )
  const filteredItems = useMemo(() => {
    const newFilters = onlyNewFilter ? sortedItems.filter(item => item?.isNew) : sortedItems;

    if (!searchTerm && !checkedUncheckedFilter) {
      return newFilters;
    }
    return newFilters.filter(item => {
      return (searchTerm && item.name?.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (searchTerm && item.code?.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (searchTerm && item.standard?.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (checkedUncheckedFilter === CHECKED_UNCHECKED_FILTER.checked && isItemChecked(item.uuid)) ||
        (checkedUncheckedFilter === CHECKED_UNCHECKED_FILTER.unchecked && !isItemChecked(item.uuid));
    });
  }, [searchTerm, checkedUncheckedFilter, sortedItems, isItemChecked, onlyNewFilter]);

  const handleOnChange = useCallback(search => {
    setSearchTerm(search);
  }, []);

  const resetAllFilters = useCallback(() => {
    setSearchTerm('');
    setCheckedUncheckedFilter('');
    setOnlyNewFilter(false);
  }, []);

  const hasUseSubtree = useCallback(item => {
    const itemSelected = selection.find(itemSelected => itemSelected.value === item);
    return itemSelected?.useSubtree;
  }, [selection]);

  const isSubtree = useCallback(uuid => subtreeCategories.includes(uuid), [subtreeCategories]);

  const checkedUncheckedFilterOptions = useMemo(
    () => {
      return Object.keys(CHECKED_UNCHECKED_FILTER).map(op => ({
        name: intl.formatMessage({ id: `kpi_category_search_${op}_filter` }),
        slug: op,
      }));
    },
    [intl]
  );

  return (
    <Row className="KpiCategoryIndicatorSearch">
      <Col className="KpiCategoryIndicatorSearch__filters">
        <CustomInputSearch
          value={searchTerm}
          placeholder={intl.formatMessage({ id: 'kpi_category_search_placeholder' })}
          onChange={handleOnChange}
          allowClear
        />
        <figure className='KpiCategoryIndicatorSearch__divider' />
        <CustomSelect
          title={intl.formatMessage({ id: 'kpi_category_search_selected_items_filter' })}
          options={checkedUncheckedFilterOptions}
          onSelect={(filterValue) => setCheckedUncheckedFilter(filterValue)}
          selected={checkedUncheckedFilter}
        />
        <A onClick={resetAllFilters} className='KpiCategoryIndicatorSearch__removeAllFilters'>
          {intl.formatMessage({ id: 'kpi_category_search_delete_all_filter' })}
        </A>
      </Col>
      {searchTerm !== '' &&
        <Row className="KpiCategoryIndicatorSearch__create-link">
          <Col span={24}>
            {type === 'category' &&
              <span>
                <A onClick={() => onCreate(searchTerm)}>
                  &nbsp;{intl.formatMessage({ id: 'kpi_category_search_create_category' })}
                </A>
              </span>
            }
            {type === 'indicator' &&
              <Row>
                <div style={{ marginRight: '2px' }}>{intl.formatMessage({ id: 'createcustomkpi_create' })}:</div>
                {CREATE_KPI_TYPES.map(ckt =>
                  <Button
                    className="KpiCategoryIndicatorSearch__kpi-buttons"
                    onClick={() => onCreate(searchTerm, ckt)}
                    size='small'
                  >
                    <img src={`/images/assets/icon-${ckt}.svg`} alt='' />
                    <div style={{ marginLeft: '2px' }}>{intl.formatMessage({ id: `createcustomkpi_${ckt}` })}</div>
                  </Button>
                )}
              </Row>
            }
          </Col>
        </Row>
      }
      <Col span={24} className="KpiCategoryIndicatorSearch__list">
        <Row className="KpiCategoryIndicatorSearch__filters-only-new">
          <Switch
            checked={onlyNewFilter}
            onChange={(checked) => setOnlyNewFilter(checked)}
          />
          <div>&nbsp;{intl.formatMessage({ id: `kpi_category_search_only_new_filter_${type}` })}</div>
        </Row>
        <List
          dataSource={filteredItems}
          pagination
          header={<>
            <Row>{intl.formatMessage({ id: 'kpi_category_search_total_created' }, { value: sortedItems.filter(({ isNew }) => !!isNew).length })}</Row>
            <Row>{intl.formatMessage({ id: 'kpi_category_search_total_selected' }, { value: selection.length })}</Row>
          </>}
          renderItem={item => (
            <Row key={item.uuid} align="bottom" justify="space-between" wrap={false} className="KpiCategoryIndicatorSearch__listItem">
              <Col className={`KpiCategoryIndicatorSearch__standardWrapper KpiCategoryIndicatorSearch__standardWrapper-${type}`}>
                <CustomCheckbox
                  checked={isItemChecked(item.uuid) || isSubtree(item.uuid)}
                  onChange={(e) => onSelectItem(item, e.target.checked, item.slug)}
                  title={item.name}
                  disabled={isSubtree(item.uuid)}
                >
                  <Row align="bottom" gutter={10} wrap={false} >
                    <Col className="KpiCategoryIndicatorSearch__standardName">
                      {item.is_custom || !item.standard ? customStandardName : intl.formatMessage({ id: `reporting_standard_${item.standard}` })}
                    </Col>
                    {item.code ? (
                      <Col className="KpiCategoryIndicatorSearch__standardTagWrapper">
                        <ReportingStandardTag
                          className="KpiCategoryIndicatorSearch__standardTag"
                          name={item.name}
                          code={item.code}
                          standard={item.standard}
                        />
                      </Col>
                    ) : null}
                    <Col className="KpiCategoryIndicatorSearch__standardItem" title={item.name}>
                      {item.name}
                    </Col>
                    {
                      item?.isNew &&
                      <Col className="KpiCategoryIndicatorSearch__newTag" title={intl.formatMessage({ id: 'kpi_category_search_new_tag' })}>
                        <div>
                          <p>{intl.formatMessage({ id: 'kpi_category_search_new_tag' })}</p>
                        </div>
                      </Col>
                    }
                  </Row>
                </CustomCheckbox>
              </Col>
              {!item?.isNew && type === 'category' &&
                <Col className="KpiCategoryIndicatorSearch__useSubtreeWrapper">
                  <CustomCheckbox
                    className="KpiCategoryIndicatorSearch__useSubtree"
                    checked={hasUseSubtree(item.uuid) || isSubtree(item.uuid)}
                    onChange={(e) => onToggleUseSubtree(item.uuid, e.target.checked)}
                    disabled={!isItemChecked(item.uuid) || isSubtree(item.uuid)}
                  >
                    {intl.formatMessage({ id: 'kpi_category_search_use_subtree' })}
                  </CustomCheckbox>
                </Col>
              }
            </Row>
          )}
        />
      </Col>
    </Row>
  )
};

KpiCategoryIndicatorSearch.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.object),
  onSelectItem: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onToggleUseSubtree: PropTypes.func.isRequired,
  selection: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    useSubtree: PropTypes.string
  })),
  customStandardName: PropTypes.string.isRequired,
};

export default injectIntl(KpiCategoryIndicatorSearch);
