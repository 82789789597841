import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  useDispatch,
} from 'react-redux';

import useOrganizations from 'utils/useOrganizations';

import {
  useKpiValidationLevels,
  canValidateWithLevel,
  levelToBackendLevel,
} from 'utils/kpi_validation';

// NOTICE: Only used to approval without comment + start validation
//         The case with comments is handled by ModalApproveRejectKpi
import {
  approveKpis,
} from 'actions/api';

import ModalApproveRejectKpi from 'components/ModalApproveRejectKpi';
import CustomWarningModal from 'components/CustomWarningModal';

import {
  Button,
  notification,
} from 'antd';

import ValidationSteps from './ValidationSteps';
import ReadyToValidate from './ReadyToValidate';
import WarningModalContent from './WarningModalContent';

const DEFAULT_APPROVAL_LEVELS = 1;
const DEFAULT_WARNING = 'none';

const Validation = ({
  intl,
  kpiStatus,
  kpi_slug,
  period,
  validated_level = 0,
  ready_to_validate_level = 0,
  approvals = [],
  rejections = [],
  loading,
  permissions = {},
  config = {},
  org_mandatory_data = {},
  attachments,
  comment,
  source,
  dependent_kpis = [],
  dependee_kpis = [],
  value_source,
  last_update,
  showValidationChangedNotification,
}) => {
  const t = intl.messages;
  const [modalType, setModalType] = useState(null);
  const [warningType, setWarningType] = useState(null);
  const [actionLevel, setActionLevel] = useState(0);
  const [skipComment, setSkipComment] = useState(false);

  const dispatch = useDispatch();
  const {
    organization,
    suborganization,
  } = useOrganizations();

  const userApprovalLevel = permissions.validate_kpi_level || 0;

  // NOTICE: this seems cheaper than useMemo for now
  const approveAffectsOthers = (dependee_kpis || []).length > 0;
  const rejectAffectsOthers = (dependent_kpis || []).length > 0;
  const startAffectsOthers = approveAffectsOthers; // Same thing
  const undoAffectsOthers = rejectAffectsOthers; // Same thing

  const isPartialValue = useMemo(() => {
    return (dependee_kpis || []).some(
      kpi => kpi?.empty_value && kpi?.applies
    );
  }, [
    dependee_kpis,
  ]);

  const canApprove = ready_to_validate_level === 0
    ? permissions.can_write_kpi
    : permissions.can_validate_kpi && canValidateWithLevel(
      ready_to_validate_level,
      userApprovalLevel,
    );

  const approvalLevels = useMemo(() => {
    return (suborganization.config || {}).approval_levels || DEFAULT_APPROVAL_LEVELS;
  }, [
    suborganization,
  ]);

  useEffect(() => {
    if (showValidationChangedNotification) {
      notification.success({
        message: intl.formatMessage({ id: 'validation_success_notification_title' }),
        description: intl.formatMessage({ id: 'validation_success_notification' }),
      });
      dispatch({
        type: 'RESET_SHOW_VALIDATION_NOTIFICATION',
        target: 'kpi_detail',
      });
    }
  }, [
    showValidationChangedNotification,
    dispatch,
    intl,
  ]);

  const handleStartApproval = useCallback(() => {
    dispatch(
      approveKpis(
        organization.slug,
        suborganization.slug,
        [{ slug: kpi_slug, period: period.label, }],
        '',
        levelToBackendLevel(0),
      )
    )
  }, [
    dispatch,
    organization,
    suborganization,
    kpi_slug,
    period,
  ]);

  const maybeHandleStartApproval = useCallback(() => {
    if (startAffectsOthers) {
      setWarningType('start');
    } else {
      handleStartApproval();
    }
  }, [
    startAffectsOthers,
    handleStartApproval,
  ]);

  const handleApprove = useCallback((level, skip) => {
    setActionLevel(level);
    if (skip) {
      dispatch(
        approveKpis(
          organization.slug,
          suborganization.slug,
          [{ slug: kpi_slug, period: period.label, }],
          '',
          levelToBackendLevel(level),
        )
      )
    } else {
      setModalType('approve');
    }
  }, [
    dispatch,
    kpi_slug,
    organization,
    period,
    suborganization,
  ]);

  const maybeHandleApprove = useCallback((level, skip = false) => {
    if (approveAffectsOthers) {
      setWarningType('approve');
      setActionLevel(level);
      setSkipComment(skip);
    } else {
      handleApprove(level, skip);
    }
  }, [
    approveAffectsOthers,
    handleApprove,
  ]);

  const handleReject = useCallback((level) => {
    setModalType('reject');
    setActionLevel(level);
    setSkipComment(false);
  }, []);

  const maybeHandleReject = useCallback((level) => {
    if (rejectAffectsOthers) {
      setWarningType('reject');
      setActionLevel(level);
      setSkipComment(false);
    } else {
      handleReject(level);
    }
  }, [
    rejectAffectsOthers,
    handleReject,
  ]);

  const handleUndo = useCallback(() => {
    setModalType('undo');
    setActionLevel(-1);
    setSkipComment(false);
  }, []);

  const maybeHandleUndo = useCallback(() => {
    if (undoAffectsOthers) {
      setWarningType('undo');
      setActionLevel(-1);
      setSkipComment(false);
    } else {
      handleUndo();
    }
  }, [
    undoAffectsOthers,
    handleUndo,
  ]);

  const onCloseModal = useCallback(() => {
    setModalType(null);
    setActionLevel(0);
    setSkipComment(false);
  }, []);

  const onCloseWarning = useCallback(() => {
    setWarningType(null);
    setActionLevel(0);
    setSkipComment(false);
  }, []);

  const handleWarningOk = useCallback(() => {
    setWarningType(null);

    // NOTICE: warningType still has a value in this call
    switch (warningType) {
      case 'start':
        return handleStartApproval();
      case 'approve':
        return handleApprove(actionLevel, skipComment);
      case 'reject':
        return handleReject(actionLevel);
      case 'undo':
        return handleUndo();
      default:
      // console.log('WARNING: unknown warning type');
    }
  }, [
    warningType,
    actionLevel,
    handleStartApproval,
    handleApprove,
    handleReject,
    handleUndo,
    skipComment,
  ]);

  const handleRejectButton = useCallback(() => {
    if (canApprove) {
      maybeHandleReject(actionLevel);
    } else {
      maybeHandleUndo();
    }
  }, [
    canApprove,
    maybeHandleReject,
    actionLevel,
    maybeHandleUndo,
  ]);

  const {
    lastRejection,
    showSteps,
    currentStep,
    steps,
  } = useKpiValidationLevels(
    approvalLevels,
    validated_level,
    ready_to_validate_level,
    approvals,
    rejections,
    userApprovalLevel,
  );

  const kpiSources = useMemo(() => {
    switch (warningType) {
      case 'start':
      case 'approve':
        return [value_source];
      case 'reject':
      case 'undo':
        return Array.from(
          new Set(
            (dependent_kpis || []).map(({ kpi_source }) => kpi_source).sort()
          )
        );
      default:
        return [];
    }
  }, [
    warningType,
    value_source,
    dependent_kpis,
  ]);

  return (
    <React.Fragment>
      <div className='KpiDetail__title'>{t.validation}</div>
      <section className="KpiDetail__card KpiDetail__validation">
        {!showSteps
          ? (
            <ReadyToValidate
              canApprove={canApprove}
              onStart={maybeHandleStartApproval}
              levels={approvalLevels}
              loading={loading}
              lastUpdate={last_update}
              lastRejection={lastRejection}
              kpiStatus={kpiStatus}
              mandatory_data={config?.mandatory_data}
              org_mandatory_data={org_mandatory_data}
              attachments={attachments}
              comment={comment}
              source={source}
            />
          ) : (
            <ValidationSteps
              canApprove={canApprove}
              currentStep={currentStep}
              approvalLevels={approvalLevels}
              steps={steps}
              onApprove={maybeHandleApprove}
              onReject={maybeHandleReject}
              loading={loading}
            />
          )
        }
        {
          showSteps && (canApprove || permissions.can_undo_validate_kpi) && (
            <div className="KpiDetail__validation-undorow">
              <Button
                className="KpiDetail__validation-undo"
                onClick={handleRejectButton}
                disabled={loading}
              >
                {
                  canApprove
                    ? t.reject
                    : t.undo_validation
                }
              </Button>
            </div>
          )
        }
      </section>
      <ModalApproveRejectKpi
        visible={!!modalType}
        mode={modalType}
        level={actionLevel}
        onClose={onCloseModal}
        kpis={[{ slug: kpi_slug, period: period.label, }]}
      />
      <CustomWarningModal
        showModal={!!warningType}
        onOk={handleWarningOk}
        onCancel={onCloseWarning}
        onOkText={intl.formatMessage({ id: `data_validation_warning_${warningType || DEFAULT_WARNING}_ok` })}
        onCancelText={intl.formatMessage({ id: `data_validation_warning_${warningType || DEFAULT_WARNING}_cancel` })}
        title={intl.formatMessage({ id: `data_validation_warning_${warningType || DEFAULT_WARNING}_title` })}
        content={(
          <WarningModalContent
            warningType={warningType || DEFAULT_WARNING}
            intl={intl}
            kpiSources={kpiSources}
            isPartialValue={isPartialValue}
          />
        )}
      />
    </React.Fragment>
  );
};

export default injectIntl(Validation);
