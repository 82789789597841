import React from "react";
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import './style.less';

const { Panel } = Collapse;

const KPI_NEW_PERIODICITIES_DEFAULT = [];

const WarningModalContentStep2 = ({
  intl,
  kpisWithNewPeriodicities = KPI_NEW_PERIODICITIES_DEFAULT,
}) => (
  <div className="WarningModalContentStep2">
    <div className="WarningModalContentStep2__message">
      <span>
        {intl.formatMessage(
          {id: `data_requests_warning_modal_message_step_2_next`},
          {periodicities: <b>{intl.formatMessage({id: `data_requests_warning_modal_periodicities`})}</b>}
        )}
      </span>
      <span>{intl.formatMessage({id: `data_requests_warning_modal_message_2_step_2_next`})}</span>
    </div>
    <div>
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className="WarningModalContentStep2__collapse"
      >
        <Panel header={intl.formatMessage({id: `data_requests_warning_modal_see_indicators_list`})} key="1" className="WarningModalContentStep2__panel">
          {kpisWithNewPeriodicities.map(kpiInfo => (
            <div key={kpiInfo.slug} className="WarningModalContentStep2__wrapper">
              <div className="WarningModalContentStep2__newPeriodicities">{kpiInfo.nodesWithNotExistingPeriodicities.map(o => o.name).join(", ")}</div>
              <div title={kpiInfo.name} className="WarningModalContentStep2__kpiName">{kpiInfo.name}</div>
            </div>
          ))}
        </Panel>
      </Collapse>
    </div>
  </div>
);

WarningModalContentStep2.propTypes = {
  kpisWithNewPeriodicities: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(WarningModalContentStep2);
