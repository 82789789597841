import React from 'react';
import { injectIntl } from 'react-intl';

import {
  isDayBefore,
  formatDate,
} from 'utils/date';

import T from 'components/T';
import AplanetIcon from 'components/AplanetIcon';

import './style.less';

const HeaderStatusLabel = ({
  intl,
  last_updated_at,
  last_collection_at,
}) => {

  let status = 'nodata';
  let date = null;
  if(last_updated_at) {
    status = isDayBefore(last_updated_at, last_collection_at) ? 'pending' : 'uptodate';
    date = last_updated_at;
  }

  return (
    <section
      className={`HeaderStatusLabel HeaderStatusLabel__${status}`}
    >
      <AplanetIcon name="Bell"/>
      <span className="HeaderStatusLabel__title">
        { intl.formatMessage({ id: `headerstatus_${status}`}) }
      </span>
      { !date ? null :
        <span className="HeaderStatusLabel__text">
          <T
            {...({
              [`headerstatus_${status}_sub`]: {
                date: formatDate(date, intl.formatMessage({ id: "headerstatus_date_format"}), intl)
              }
            })}
          />
        </span>
      }
    </section>
  );
};

export default injectIntl(HeaderStatusLabel);
