import React, { useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from "react-router";
import { useDispatch, useSelector } from 'react-redux';

import { Loading } from 'tsComponents/emptyStates/Loading';
import DataSourcesTableBody from 'components/DataSources/TableBody';
import { requestReportingStructure } from 'actions/api';
import useOrganizations from 'utils/useOrganizations';
import { reportingStructureToTree } from 'utils/reporting_structure';

import {
  EyeOutlined,
  EditOutlined,
  SendOutlined,
  CheckOutlined,
  ReloadOutlined,
  SettingOutlined,
  FileOutlined,
  FileTextOutlined,
  PaperClipOutlined,
  MessageOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';


const DataSources = ({
  intl,
}) => {
  const dispatch = useDispatch();

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const {
    data: reporting_structure,
    loading: loadingReportingStructure,
  } = useSelector(state => state.reporting_structure);

  const reportingStructureTree = useMemo(
    () => reportingStructureToTree(reporting_structure || []),
    [reporting_structure]
  );

  useEffect(() => {
    if (
      !organization ||
      !suborganization
    ) return;

    dispatch(
      requestReportingStructure({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
      })
    );
  }, [
    organization,
    suborganization,
    dispatch,
  ]);

  if (loadingReportingStructure) {
    return <Loading />;
  }

  return (
    <div className="DataManagement__data-sources">
      <div className="DataManagement__data-sources_table-container">
        <table>
          <thead>
            <tr>
              <th colSpan="5">{intl.formatMessage({id: 'data_management_sources_general_info'})}</th>
              <th colSpan="8">{intl.formatMessage({id: 'data_management_sources_permissions'})}</th>
              <th colSpan="3">{intl.formatMessage({id: 'data_management_sources_mandatory'})}</th>
              <th colSpan="2">{intl.formatMessage({id: 'data_management_sources_periods'})}</th>
              <th colSpan="2">{intl.formatMessage({id: 'data_management_sources_sources'})}</th>
              <th colSpan="5">{intl.formatMessage({id: 'data_management_sources_petitions'})}</th>
            </tr>
            <tr>
              <th className="DataManagement__data-sources_table-name">{intl.formatMessage({id: 'data_management_sources_general_info_name'})}</th>
              <th>{intl.formatMessage({id: 'data_management_sources_general_info_esg'})}</th>
              <th>{intl.formatMessage({id: 'data_management_sources_general_info_sdg'})}</th>
              <th>{intl.formatMessage({id: 'data_management_sources_general_info_tags'})}</th>
              <th>{intl.formatMessage({id: 'data_management_sources_general_info_suborg'})}</th>

              <th>{intl.formatMessage({id: 'data_management_sources_user'})}</th>
              <th><EyeOutlined /></th>
              <th><EditOutlined /></th>
              <th><SendOutlined /></th>
              <th><CheckOutlined /></th>
              <th><ReloadOutlined /></th>
              <th><SettingOutlined /></th>
              <th><FileOutlined /></th>

              <th><FileTextOutlined /></th>
              <th><PaperClipOutlined /></th>
              <th><MessageOutlined /></th>

              <th>{intl.formatMessage({id: 'data_management_sources_periods_periodicity'})}</th>
              <th>{intl.formatMessage({id: 'data_management_sources_periods_period'})}</th>

              <th>{intl.formatMessage({id: 'data_management_sources_sources_source'})}</th>
              <th>{intl.formatMessage({id: 'data_management_sources_sources_automation_type'})}</th>

              <th><SyncOutlined /></th>
              <th>{intl.formatMessage({id: 'data_management_sources_petitions_when'})}</th>
              <th><SendOutlined /> {intl.formatMessage({id: 'data_management_sources_petitions_send_to'})}</th>
              <th><ClockCircleOutlined /></th>
              <th><PlusCircleOutlined /></th>
            </tr>
          </thead>

          <DataSourcesTableBody tree={reportingStructureTree} />
        </table>
      </div>
    </div>
  );
}

export default injectIntl(
  withRouter(DataSources)
);

