const sdgNumbers = {
  'no-poverty': '1',
  'zero-hunger': '2',
  'good-health-and-wellbeing': '3',
  'quality-education': '4',
  'gender-equality': '5',
  'clean-water-and-sanitation': '6',
  'affordable-and-clean-energy': '7',
  'decent-work-and-economic-growth': '8',
  'industry-innovation-and-infrastructure': '9',
  'reduced-inequalities': '10',
  'sustainable-cities-and-communities': '11',
  'responsible-consumption-and-production': '12',
  'climate-action': '13',
  'life-below-water': '14',
  'life-on-land': '15',
  'peace-justice-and-strong-institutions': '16',
  'partnership-for-the-goals': '17',
};

const sdgTargetNumbers = {
    "eradicate-extreme-poverty": "1.1",
    "reduce-proportion": "1.2",
    "social-protection": "1.3",
    "equal-rights": "1.4",
    "build-resilience": "1.5",
    "mobilization-resources": "1.a",
    "policy-frameworks": "1.b",
    "end-hunger": "2.1",
    "end-malnutrition": "2.2",
    "double-productivity": "2.3",
    "sustainable-production": "2.4",
    "genetic-diversity": "2.5",
    "increase-investment": "2.a",
    "correct-trade-restrictions": "2.b",
    "food-markets": "2.c",
    "maternal-mortality": "3.1",
    "newborns": "3.2",
    "epidemics": "3.3",
    "premature-mortality": "3.4",
    "substance-abuse": "3.5",
    "traffic-accidents": "3.6",
    "health-care-services": "3.7",
    "health-coverage": "3.8",
    "hazardous-chemicals": "3.9",
    "tobacco-control": "3.a",
    "vaccines": "3.b",
    "health-workforce": "3.c",
    "early-warning": "3.d",
    "education-outcomes": "4.1",
    "childhood-development": "4.2",
    "tertiary-education": "4.3",
    "relevant-skills": "4.4",
    "equal-access": "4.5",
    "literacy-numeracy": "4.6",
    "promote-sustainable": "4.7",
    "education-facilities": "4.a",
    "scholarships": "4.b",
    "teachers": "4.c",
    "discrimination": "5.1",
    "violence": "5.2",
    "harmful-practices": "5.3",
    "unpaid-care": "5.4",
    "effective-participation": "5.5",
    "reproductive-health": "5.6",
    "economic-resources": "5.a",
    "technology": "5.b",
    "empowerment": "5.c",
    "drinking-water": "6.1",
    "sanitation-hygiene": "6.2",
    "water-quality": "6.3",
    "water-use": "6.4",
    "water-management": "6.5",
    "water-ecosystems": "6.6",
    "international-cooperation": "6.a",
    "local-communities": "6.b",
    "access-energy": "7.1",
    "renewable-energy": "7.2",
    "energy-efficiency": "7.3",
    "international-coop-access": "7.a",
    "infrastructure-upgrade": "7.b",
    "economic-growth": "8.1",
    "economic-productivity": "8.2",
    "development-oriented-policies": "8.3",
    "resource-efficiency": "8.4",
    "productive-employment": "8.5",
    "youth-employment": "8.6",
    "forced-labour": "8.7",
    "labour-rights": "8.8",
    "sustainable-tourism": "8.9",
    "financial-services": "8.10",
    "trade-support": "8.a",
    "global-jobs-pact": "8.b",
    "develop-infrastructure": "9.1",
    "sustainable-industrialization": "9.2",
    "small-scale-industrial": "9.3",
    "retrofit-industries": "9.4",
    "enhance-scientific": "9.5",
    "infrastructure-development": "9.a",
    "domestic-technology": "9.b",
    "access-information": "9.c",
    "income-growth": "10.1",
    "inclusion": "10.2",
    "reduce-inequalities": "10.3",
    "adopt-policies": "10.4",
    "financial-markets": "10.5",
    "representation-voice": "10.6",
    "migration-mobility": "10.7",
    "special-treatment": "10.a",
    "financial-flows": "10.b",
    "migrant-remittances": "10.c",
    "housing-services": "11.1",
    "transport-systems": "11.2",
    "human-settlement": "11.3",
    "cultural-heritage": "11.4",
    "disasters": "11.5",
    "environmental-impact-cities": "11.6",
    "green-spaces": "11.7",
    "environmental-links": "11.a",
    "plans-inclusion": "11.b",
    "least-developed-countries": "11.c",
    "production-patterns": "12.1",
    "natural-resources": "12.2",
    "food-waste": "12.3",
    "environmentally-wastes": "12.4",
    "waste-generation": "12.5",
    "sustainable-practices": "12.6",
    "procurement-practices": "12.7",
    "relevant-information": "12.8",
    "scientific-tech-capacity": "12.a",
    "monitor-impacts": "12.b",
    "fossil-fuel": "12.c",
    "resilience": "13.1",
    "climate-change": "13.2",
    "education-climate": "13.3",
    "commitment-undertaken": "13.a",
    "raising-capacity": "13.b",
    "marine-pollution": "14.1",
    "fisheries-subsidies": "14.6",
    "marine-ecosystems": "14.2",
    "small-island": "14.7",
    "ocean-acidification": "14.3",
    "marine-technology": "14.a",
    "end-overfishing": "14.4",
    "artisanal-fishers": "14.b",
    "marine-areas": "14.5",
    "conservation-oceans": "14.c",
    "freshwater-ecosystems": "15.1",
    "management-forests": "15.2",
    "combat-desertification": "15.3",
    "mountain-ecosystems": "15.4",
    "natural-habitats": "15.5",
    "genetic-resources": "15.6",
    "protected-species": "15.7",
    "invasive-species": "15.8",
    "biodiversity-values": "15.9",
    "conserve-biodiversity": "15.a",
    "finance-forest": "15.b",
    "poaching-trafficking": "15.c",
    "violence-death": "16.1",
    "exploitation-children": "16.2",
    "rule-of-law": "16.3",
    "illicit-financial": "16.4",
    "corruption": "16.5",
    "transparent-institutions": "16.6",
    "decision-making": "16.7",
    "global-governance": "16.8",
    "legal-identity": "16.9",
    "public-access-information": "16.10",
    "strengthen-institutions": "16.a",
    "non-discriminatory-laws": "16.b",
    "domestic-resource-mobilization": "17.1",
    "assistance-commitments": "17.2",
    "mobilize-financial-resources": "17.3",
    "debt-sustainability": "17.4",
    "investment-promotion": "17.5",
    "knowledge-sharing": "17.6",
    "diffusion-technologies": "17.7",
    "operationalize-technology": "17.8",
    "capacity-building": "17.9",
    "multilateral-trading": "17.10",
    "exports": "17.11",
    "duty-quota-free": "17.12",
    "macroeconomic-stability": "17.13",
    "policy-coherence": "17.14",
    "respect-country": "17.15",
    "gpsd": "17.16",
    "society-partnerships": "17.17",
    "capacity-building-support": "17.18",
    "measurements-progress": "17.19",
}

const sdgSlugToNumber = (slug) => ({ ...sdgNumbers, ...sdgTargetNumbers }[slug]);

export {
  sdgNumbers,
  sdgSlugToNumber,
}
