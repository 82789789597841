import React from 'react'
import {DatePicker} from 'antd';
import './style.less'

const CustomDatepicker = (props) => (
  <DatePicker
    {...props}
    className='CustomDatepicker'
  />
)

export default CustomDatepicker
