import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AplanetIcon from 'components/AplanetIcon';
import CustomModalType from 'components/CustomModalType';

const ModalDashboardCardKpiSummary = ({ intl, show, onCancel, kpis }) => {
    
    return (
        <CustomModalType  
            showModal={show} 
            type='main'
            onCancel={ onCancel }
            onCancelText={ intl.formatMessage({id: 'dashboard_card_form_summary_modal_cancel' }) }
            title={ intl.formatMessage({id: 'dashboard_card_form_summary_modal_title' }) }
            className={`ModalDashboardCardKpiSummary`}
            content={
                <dl className='ModalDashboardCardKpiSummary__modal_content'>
                    <dt>{ intl.formatMessage({id: 'dashboard_card_form_summary_modal_content_indicators' }) }</dt>
                    {
                        kpis.map( kpi => (
                            <dd key={ kpi.slug }>
                                <AplanetIcon size="15px" name="file" faStyle="fal" />
                                {' '}
                                <Link to={`${kpi.link}`} target="_blank">{ kpi.name }</Link> 
                            </dd>
                        ))
                    }
                </dl>
            }
            width={ 880 }
        />    
    )

}

ModalDashboardCardKpiSummary.propTypes = {
    show: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    kpis: PropTypes.arrayOf(PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired
    })).isRequired
}

export default injectIntl(ModalDashboardCardKpiSummary);