import React, {
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { sortBy } from 'lodash';

import { fullValidation as validation } from './validation';
import useForm from 'utils/useForm';
import Description from 'components/Description';

import {
  Row,
  Col,
  Form,
} from 'antd';
import CustomTextArea from 'components/CustomTextArea';
import CustomSelect from 'components/CustomSelect';
import CustomTable from 'components/CustomTable';
import FloatingButton from 'components/FloatingButton';


const AssessmentForm = ({
  intl,
  position,
  positions,
  factors,
  handleSave,
}) => {
  const t = intl.messages;
  const defaults = position;
  const validateForm = useMemo(() => validation(t, factors), [ t, factors ]);

  const submitForm = () => {
    handleSave(values);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    dirtyFields,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  const positionOptions = useMemo(
    () => {
      return positions
        .filter(p => p.id !== position.id)
        .map(position => ({
          name: position.name,
          slug: position.id,
        }));
    },
    [positions, position]
  );

  const handleChangeFactorLevel = useCallback(
    (factor_id, level_id) => {
      let positionFactors = values.factors;

      const index = positionFactors.map(p => p.factor_id).indexOf(factor_id);
      if (index < 0) {
        positionFactors.push({factor_id, factor_level_id: level_id});
      } else {
        positionFactors = [
          ...positionFactors.slice(0, index),
          {factor_id, factor_level_id: level_id},
          ...positionFactors.slice(index + 1)
        ];
      }
      handleChange('factors')(positionFactors);
    },
    [values.factors, handleChange]
  );

  const getSelectedLevelId = useCallback(
    (factor_id) => {
      const positionFactor = values.factors.find(
        factor => factor.factor_id === factor_id
      );
      return positionFactor ? positionFactor.factor_level_id : null;
    },
    [values.factors]
  );

  const getSelectedLevel = useCallback(
    (factor_id) => {
      const positionFactor = values.factors.find(
        factor => factor.factor_id === factor_id
      );

      if (positionFactor && positionFactor.factor_level_id) {
        const factor = factors.find(
          factor => factor.id === positionFactor.factor_id
        );
        return factor.levels.find(
          level => level.id === positionFactor.factor_level_id
        );
      }
      return;
    },
    [values.factors, factors]
  );

  const factorColumns = useMemo(
    () => ([{
      title: t.equality_job_evaluation_assessment_factor_type,
      dataIndex: 'type',
    }, {
      title: t.equality_job_evaluation_assessment_factor,
      dataIndex: 'name',
    }, {
      title: t.equality_job_evaluation_assessment_factor_description,
      dataIndex: 'description',
    }, {
      title: t.equality_job_evaluation_assessment_factor_level,
      dataIndex: 'levels',
      render: (levels, factor) => {
        return <CustomSelect
          title={t.equality_job_evaluation_assessment_factor_level}
          options={
            sortBy(levels, 'level').map(
              level => ({
                slug: level.id,
                name: `${level.level}: ${level.name}`,
              })
            )
          }
          onSelect={(value) => handleChangeFactorLevel(factor.id, value)}
          selected={getSelectedLevelId(factor.id)}
        />
      }
    }, {
      title: t.equality_job_evaluation_assessment_factor_description_level,
      dataIndex: 'description',
      render: (_, factor) => {
        const level = getSelectedLevel(factor.id);
        return (level && level.description)
          ? <Description description={level.description} />
          : '-';
      }
    }]),
    [t, getSelectedLevelId, handleChangeFactorLevel, getSelectedLevel]
  );

  return (
    <>
    <Form
      className="form-input-wrapper"
    >
      <Row>
        <Col span={24}>
          <Form.Item
            hasFeedback
            label={ t.equality_job_evaluation_assessment_position_name }
            colon={true}
          >
            {position.name}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            hasFeedback
            label={ t.equality_job_evaluation_assessment_position_department }
            colon={true}
          >
            {position.department_name}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            hasFeedback
            label={ t.equality_job_evaluation_assessment_position_professional_group }
            colon={true}
          >
            {position.professional_group_name}
          </Form.Item>
        </Col>
      </Row>
    </Form>
    <Form
      className="form-input-wrapper"
      layout="vertical"
    >
      <Row>
        <Col span={24}>
          <Form.Item
            hasFeedback
            validateStatus={ showError('immediate_superior_position_id') ? 'error' : '' }
            help={ showError('immediate_superior_position_id') }
            label={ t.equality_job_evaluation_assessment_position_immediate_superior }
            colon={false}
          >
            <CustomSelect
              title={t.equality_job_evaluation_assessment_position_immediate_superior}
              options={positionOptions}
              onSelect={handleChange('immediate_superior_position_id')}
              selected={getValue('immediate_superior_position_id')}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            hasFeedback
            validateStatus={ showError('mission') ? 'error' : '' }
            help={ showError('mission') }
            label={ t.equality_job_evaluation_assessment_position_mission }
            colon={false}
          >
            <CustomTextArea
              value={getValue('mission')}
              onChange={handleChange('mission')}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            hasFeedback
            validateStatus={ showError('main_functions') ? 'error' : '' }
            help={ showError('main_functions') }
            label={ t.equality_job_evaluation_assessment_position_main_functions }
            colon={false}
          >
            <CustomTextArea
              value={getValue('main_functions')}
              onChange={handleChange('main_functions')}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            validateStatus={ showError('factors') ? 'error' : '' }
            help={ showError('factors') }
            colon={false}
          >
            <CustomTable
              columns={factorColumns}
              dataSource={factors}
              rowKey='id'
              pagination={false}
            />
          </Form.Item>
        </Col>
      </Row>
      <FloatingButton
        onClick={handleSubmit}
        iconUrl='/images/fa-save-regular.svg'
        disabled={!dirtyFields.length}
      />
    </Form>
    </>
  );
}

export default injectIntl(AssessmentForm);
