import React, { useEffect, useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Tree, Checkbox } from 'antd';
import {compact, flattenDeep} from 'lodash'
import Avatar from 'components/Avatar';
import { InfoCircleOutlined } from '@ant-design/icons';
import useOrganizations from 'utils/useOrganizations';
import {uniq} from "lodash";

import {
  Modal,
} from 'antd';

import './style.less';

import {formatMessage} from '../../../intl'

const treeData = (
  nodes,
  dashboard_organization_id,
  suborganization_id,
  hideLogo,
  parent = {},
) => {
  return flattenDeep(nodes.map(_node => {
    if(!_node) {
      return null;
    }

    const node = {..._node, parent};

    const {
      name,
      children = [],
    } = node;
    const hideChildrenLogo = hideLogo || node.logo_small === node.parent.logo_small;
    const formattedChildren = compact(treeData(children, dashboard_organization_id, suborganization_id, hideChildrenLogo, node))

    return {
      title: name,
      key: node.id,
      disabled: !node.permissions || node.id === dashboard_organization_id || suborganization_id === node.id,
      children: formattedChildren,
      icon: !hideLogo &&
      <Avatar
        shape="square"
        src={node.logo_small || undefined}
        name={node.name || undefined}
        icon={<InfoCircleOutlined />}
        className='OrganizationSelector-avatar'
      />
    }
  }));
};


const ModalShareDashboard = ({
  visible,
  dashboard_organization_id,
  shared_dashboard_ids = [],
  handleDashboardUpdate,
  onCancel,
}) => {
  const [checkedKeys, setCheckedKeys] = useState(shared_dashboard_ids);

  useEffect(() => setCheckedKeys(shared_dashboard_ids) , [shared_dashboard_ids])

  const {
    suborganization,
    organizationTree,
  } = useOrganizations();

  const treeDataIds = useCallback((
    nodes,
    parent = {},
  ) => {
    return flattenDeep(nodes.map(_node => {
      if(!_node) {
        return null;
      }
  
      const node = {..._node, parent};
  
      const {
        children = [],
      } = node;
      const formattedChildren = compact(treeDataIds(children, node))
      if(node.id === dashboard_organization_id) return formattedChildren
      return [...formattedChildren, node.id]
    }));
  }, [dashboard_organization_id])
  
  const onCheck = (checkedKeysValue) => {
    handleDashboardUpdate({shared_dashboard_ids: uniq(checkedKeysValue.checked)})
    setCheckedKeys(uniq(checkedKeysValue.checked));
  };

  const onAllCheck = (e) => {
    if(e.target.checked) {
      handleDashboardUpdate({shared_dashboard_ids: treeDataIds([organizationTree])})
      setCheckedKeys(treeDataIds([organizationTree]));
    } 
    else {
      const neededSharedIds = suborganization.id === dashboard_organization_id ? [] : [suborganization.id]
      handleDashboardUpdate({shared_dashboard_ids: neededSharedIds})
      setCheckedKeys(neededSharedIds);
    }
  }
  return (
    <Modal
      className="ModalShareDashboard"
      destroyOnClose={true}
      centered
      onCancel={onCancel}
      cancelText={formatMessage("close")}
      visible={visible}
      title={formatMessage("modal_share_dashboard_title")}
    >
      <Checkbox className="select-all-checkbox" onChange={onAllCheck}>Select all</Checkbox>
      <Tree
        checkable
        checkStrictly
        showIcon
        checkedKeys={[dashboard_organization_id, ...(checkedKeys || [])]}
        onCheck={onCheck}
        defaultExpandAll
        treeData={treeData([organizationTree], dashboard_organization_id, suborganization.id)}
      />
    </Modal>
  )
};

export default injectIntl(ModalShareDashboard);
