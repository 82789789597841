import React, { 
  useCallback,
  useMemo,
  useState,
  useContext,
} from 'react';
import { injectIntl } from 'react-intl';

import { Checkbox } from 'antd';
import { DATA_REQUEST_STATUS } from 'utils/dataRequests';
import AplanetIcon from 'components/AplanetIcon';
import DataRequestsRequestedByRequestedTo from './DataRequestsRequestedByRequestedTo';
import DataRequestsActionsCell from './DataRequestsActionsCell';
import CustomModalType from 'components/CustomModalType';
import DataRequestsRow from './DataRequestsRow';
import { TABLE_CUSTOM_VIEW_OPTIONS } from 'hooks/useTableCustomView/constants';
import { TableDataRequestsCellsContext } from './context';

const ICON_PER_MODAL_TYPE = {
  delete_recurrent: 'Bin',
  send_reminder_recurrent: 'Alarm'
};

const ACTION_TYPES = {
  delete_recurrent: 'delete_recurrent',
  send_reminder_recurrent: 'send_reminder_recurrent'
};

const ModalContent = ({
  intl,
  actionType,
  periodicity,
  kpiName,
  requests_params,
  notificationNumber,
  notificationTime,
  notificationRelative,
}) => (
  <div className="DataRequests__modal-content">
    <div className="DataRequests__modal-content-wrapper">
      <span className="DataRequests__modal-content-message">{intl.formatMessage({id: `admin_data_requests_table_modal_message_${actionType}`})}</span>
      <div className="DataRequests__modal-content-info">
        <div className="DataRequests__modal-content-kpi">
          <AplanetIcon name="KPI" faStyle="fas" size="18px"/>
          <span className={`DataRequests__periodicity ${periodicity}`}>{intl.formatMessage({ id: `admin_data_requests_table_request_periodicity_${periodicity}`})}</span>
          <span>{kpiName}</span>
        </div>
        <div className="DataRequests__modal-content-user">
          <AplanetIcon name="User" faStyle="fad" size="18px"/>
          {requests_params.length > 1 ? (
            <span className="DataRequests__modal-content-value">{intl.formatMessage({ id: "admin_data_requests_table_modal_users"}, {total: requests_params.length || 0})}</span>
          ) : (
              <div className="DataRequests__modal-content-user-info">
              <span>{requests_params[0].params.request?.name}</span>
              <span>{requests_params[0].params.request?.email}</span>
            </div>
          )}
        </div>
        {notificationTime && notificationRelative && notificationNumber ? (
          <div className="DataRequests__modal-content-period">
            <AplanetIcon name="Calendar" size="18px"/>
            <span>{intl.formatMessage({ id: `admin_data_requests_table_notification_time_${notificationTime}`}, {total: notificationNumber})}</span>
            <span>{intl.formatMessage({ id: `admin_data_requests_table_notification_relative_${notificationRelative}`})}</span>
          </div>
        ) : null}
      </div>
      <span className="DataRequests__modal-content-message">{intl.formatMessage({id: `admin_data_requests_table_modal_action_message_${actionType}`})}</span>
    </div>
    <div className="DataRequests__modal-content-question">{intl.formatMessage({id: `admin_data_requests_table_modal_question_${actionType}`})}</div>
  </div>
);

const DataRequestsRecurrentRows = ({ intl }) => {
  const {
    kpi_slug,
    kpi_name,
    type,
    periodicity,
    notif_number,
    notif_time,
    notif_relative,
    periods,
    handleCheckRecurrentRequests,
    checkedDataRequestIds,
    handleDeleteDataRequests,
    selectedTableCustomView,
    handleSendReminderDataRequests,
    canSendRequest
  } = useContext(TableDataRequestsCellsContext);

  const [expanded, setExpanded] = useState(false);
  const [showModalType, setShowModalType] = useState('');

  const isRequestToOneUser = useMemo(() => {
    return periods[0].requests_params.length === 1;
  }, [periods]);

  const allRequestIds = useMemo(() => {
    return periods.reduce((acc, period) => [...acc, ...period.requests_params.map(({request_id}) => request_id)], []);   
  }, [periods]);

  const allRequest = useMemo(() => {
    return periods.reduce((acc, {period, requests_params, value_source}) => [
        ...acc,
        ...requests_params.map(({request_id, params, status}) => ({
        id: request_id,
        email: params.request.email,
        status,
        period,
        kpi_slug,
        value_source,
        type,
        groupedRecurrentRequests: allRequestIds,
    }))], []);
  }, [
    kpi_slug,
    periods,
    type,
    allRequestIds,
  ]);

  const onActionAll = useCallback(() => {
    if (showModalType === ACTION_TYPES.delete_recurrent) {
      const pendingNotNotifiedRequest = allRequest.filter(r => r.status === DATA_REQUEST_STATUS.pending || r.status === DATA_REQUEST_STATUS.not_notified);
      handleDeleteDataRequests(pendingNotNotifiedRequest.map(r => r.id));
    }
    if (showModalType === ACTION_TYPES.send_reminder_recurrent) {
      const pendingRequest = allRequest.filter(r => r.status === DATA_REQUEST_STATUS.pending);
      handleSendReminderDataRequests(pendingRequest.map(r => r.id));
    }
    setShowModalType('');
  },[
    showModalType,
    allRequest,
    handleDeleteDataRequests,
    handleSendReminderDataRequests
  ])

  return (
    <>
      <tr>
        <td className={`DataRequests__request-checkbox-cell ${expanded ? 'is-expanded' : ''}`}>
          <Checkbox
           className="DataRequests__request-checkbox"
           onChange={() => handleCheckRecurrentRequests(allRequest)} 
           checked={allRequestIds.every(id => checkedDataRequestIds.includes(id))}
          />
        </td>
        <td
          className='DataRequests__period'
          onClick={() => setExpanded(!expanded)}
        >
          <div className='DataRequests__type-periodicity-wrapper'>
            <AplanetIcon name={`${expanded ? "Drop down" : "Drop right"}`} faStyle="fas" size="16px" />
            <span className={`DataRequests__type ${type}`}>{intl.formatMessage({ id: `admin_data_requests_table_request_type_${type}`})}</span>
            <span className={`DataRequests__periodicity ${periodicity}`}>{intl.formatMessage({ id: `admin_data_requests_table_request_periodicity_${periodicity}`})}</span>
          </div>
        </td>
        {selectedTableCustomView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.requested_by_to_column) ? (
          <td
            className='DataRequests__requested-by-to'
          >
            <DataRequestsRequestedByRequestedTo
              requestedToEmail={periods[0].requests_params[0].params.request?.email}
              requestedToName={periods[0].requests_params[0].params.request?.name}
              requestedToAvatar={periods[0].requests_params[0].request?.avatar}
              requesterComment={isRequestToOneUser ? periods[0].requests_params[0].params.request?.comment : null}
              notificationDate={notif_time && notif_number && notif_relative ?  (
                <>
                  <span>{intl.formatMessage({ id: `admin_data_requests_table_notification_time_${notif_time}`}, {total: notif_number})}</span>
                  <span>{intl.formatMessage({ id: `admin_data_requests_table_notification_relative_${notif_relative}`})}</span>
                </>
              ) : null}
              hideUsersInfo={!isRequestToOneUser}
            />
          </td>
        ) : null}
        {selectedTableCustomView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.value_column) ? (
          <td
            className='DataRequests__response-value is-empty'
          >
          </td>
        ) : null}
        {selectedTableCustomView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.comment_column) ? (
          <td
            className='DataRequests__response-comment is-empty'
          >
          </td>
        ) : null}
        {selectedTableCustomView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.attachment_column) ? (
          <td
            className='DataRequests__response-attachments is-empty'
          >
          </td>
        ) : null}
        {selectedTableCustomView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.actions_column) ? (
          <>
            <DataRequestsActionsCell
              onDelete={() => setShowModalType(ACTION_TYPES.delete_recurrent)}
              showDelete={canSendRequest}
              showSendReminder = {canSendRequest && allRequest.some(({status}) => status === DATA_REQUEST_STATUS.pending)}
              onSendReminder = {() => setShowModalType(ACTION_TYPES.send_reminder_recurrent)}
            />
            <CustomModalType
              showModal={!!showModalType}
              type='warning'
              title={!!showModalType ? intl.formatMessage({id: `admin_data_requests_table_modal_title_${showModalType}`}) : null}
              icon={ICON_PER_MODAL_TYPE[showModalType]}
              iconType='fad'
              onOk={onActionAll}
              onOkText={!!showModalType ? intl.formatMessage({id: `admin_data_requests_table_modal_ok_${showModalType}`}) : null}
              onCancel={() => setShowModalType('')}
              onCancelText={intl.formatMessage({id: 'admin_data_requests_table_modal_cancel'})}
              content={!!showModalType ? (
                <ModalContent
                  intl={intl}
                  actionType={showModalType}
                  periodicity={periodicity}
                  kpiName={kpi_name}
                  requests_params={periods[0].requests_params}
                  notificationNumber={notif_number}
                  notificationTime={notif_time}
                  notificationRelative={notif_relative}
                />
              ) : null}
            />
          </>
        ) : null}
      </tr>
      {expanded ? (<DataRequestsRow isRequestToOneUser={isRequestToOneUser} />) : null}
    </>
  );
}

export default injectIntl(DataRequestsRecurrentRows);