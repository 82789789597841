import React, { useState } from 'react'
import { withRouter } from 'react-router';
import { Layout } from 'antd';

import AplanetIcon from 'components/AplanetIcon';
import ProductSelector from 'components/ProductSelector';
import DataManagementMenu from './DataManagementMenu';
import DashboardMenu from './DashboardMenu';

import { formatMessage } from '../../../intl';
import useOrganizations from 'utils/useOrganizations';
import { injectIntl } from 'react-intl';

const { Sider } = Layout;

const allowedProducts = ['data', 'dashboard']
const titles = {
  data: 'datamanagement_data',
  dashboard: 'dashboard_title'
}


const LateralMenu = ({
  intl,
  location
}) => {
  const path = location.pathname;
  const {
    suborganization,
  } = useOrganizations();

  const [collapse, setCollapse] = useState(true)

  const product = path.split('/')[1]

  return (
    <>
      {!allowedProducts.includes(product) ? null : (
        <Sider
          theme="light"
          className="sider LateralMenu"
          collapsedWidth={0}
          collapsed={collapse}
          trigger={null}
          collapsible
          style={{ overflow: 'unset' }}
        >
          <section className='LateralMenu-body'>
            <ProductSelector
              organization={suborganization}
            />
            <span className='LateralMenu-title'>{formatMessage(titles[product])}</span>
            {product !== 'data' ? null : <DataManagementMenu />}
            {product !== 'dashboard' ? null : <DashboardMenu />}
          </section>
          <div className={`LateralMenu-collapse ${collapse ? 'LateralMenu-collapse-close' : 'LateralMenu-collapse-open'}`} onClick={() => setCollapse(!collapse)}>
            <AplanetIcon name='Chevron right' faStyle='fas' />
          </div>
        </Sider>
      )}
    </>
  )
}

export default injectIntl(withRouter(LateralMenu))