import { useDispatch } from "react-redux"
import useOrganizations from "utils/useOrganizations";
import { createDashboardCard, updateDashboardCard } from "actions/api";

export const useDashboardCardForm = ( dashboardSlug, cardSlug ) => { 
  
    const dispatch = useDispatch();
    const { 
        organization, 
        suborganization 
    } = useOrganizations();

    const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

    const getValueType = ( graphAddress ) => {

        const valueType = [
          'kpi_value',
          'kpi_value_array',
          'kpi_value_matrix'
        ]
        const ocurrences = countOccurrences(graphAddress, '*');
        return valueType[ocurrences >= 2 ? 2 : ocurrences];
    
    };

    const getAddresFinalValue = ( selectedGraph ) => {
    
        switch(selectedGraph) {
          case 'boolean':
            return 'boolean';
          case 'text':
            return 'text';
          default:
            return 'value';
        }
    
      }

    const getTableDataByKpis  = (kpis) => {

        const kpi = kpis[0];
        const schemaLabels = kpi?.schemaLabels;
        const componentLabels = schemaLabels?.innerSchema?.componentLabels || {};
        const firstQuantitativeComponent = kpi?.schema?.innerSchema?.components?.find( component => component.type === 'quantitative');

        const dimensions = Object.keys(schemaLabels?.dimensionNames || [])
            .map((dimension, i) => {
                if(Object.keys(schemaLabels?.dimensionValues[dimension]).length === 0 ) {
                    return i === 0 ? '*' : dimension
                }
                if(Object.keys(schemaLabels?.dimensionValues[dimension]).length === 1 ) {
                    return Object.keys(schemaLabels?.dimensionValues[dimension])[0]
                }
                if(Object.keys(schemaLabels?.dimensionValues[dimension]).length > 1 ) {
                    return '*'
                }
                return '*'
            });

        return {
            dimensions: dimensions,
            columns: componentLabels && Object.values(componentLabels).length > 1 
                            ? [firstQuantitativeComponent ? firstQuantitativeComponent.name : Object.keys(componentLabels)[0]] 
                            : []
        }

    };

    const saveCardForm = (data) => {

        const valueType = getValueType(data.graphAddress);
        const addressFinalValue = getAddresFinalValue(data.selectedGraph);

        const body = {
            name: data.cardName,
            // description: data.cardDescription, // TODO DVL
            params: {
              id: data.cardName,
              chart_type: data.selectedGraph,
              chart_size: ['number', 'boolean'].includes(data.selectedGraph) ? 'small' : 'medium',
              chart_icon: data.cardIcon,
              chart_data_labels: !!data.showDataLabels,
              chart_data_target: !!data.showDataTarget,
              value_type: valueType,
              value_size: data.periodsNumber,
              value_periodicity: data.periodicity,
              value_node_level: data.selectedNodeLevel,
              value_unit: data.selectedUnit,
              value_kpis: data.selectedRows.map(kpi => ({ slug: kpi.slug, category: kpi.uuid, address: [...data.graphAddress, addressFinalValue] }))
            }
      }

      if (cardSlug){

        dispatch(
            updateDashboardCard(
              organization.slug,
              suborganization.slug,
              dashboardSlug,
              cardSlug,
              body
            )
        );

      } else {

          dispatch(
              createDashboardCard(
                organization.slug,
                suborganization.slug,
                dashboardSlug,
                body
              )
          );

      }


    }

    return {
        saveCardForm,
        getTableDataByKpis
    }

}
