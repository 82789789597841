import React, { useState, useCallback, useEffect, useMemo } from "react";
import { injectIntl } from "react-intl";
import { isEmpty, isEqual } from "lodash";
import { useSelector } from "react-redux";
import Tags from "./Tags";
import { diff, getCommonSelectedTags, getPartiallySelectedTags } from "utils/kpi";

const EMPTY_ARRAY = []
const NO_OP = () => {};

const CustomMultiTagSelector = ({
  loading,
  selectedKpisAndCategories = EMPTY_ARRAY,
  onSubmit,
  onChange = NO_OP,
}) => {
  const { tags: initialTags, loading: reducerLoading } = useSelector((state) => state.taxonomies);
  const [tags, setTags] = useState();
  const [selection, setSelection] = useState([]);
  const [partialSelection, setPartialSelection] = useState([]);
  useEffect(() => {
    if (initialTags) {
      const selection = getCommonSelectedTags(initialTags, selectedKpisAndCategories);

      setTags(initialTags);
      setSelection(selection);
      setPartialSelection(getPartiallySelectedTags(selection, selectedKpisAndCategories))
    }
  }, [initialTags, selectedKpisAndCategories]); //eslint-disable-line

  const addNewTag = useCallback(
    (newTag) => {
      if (isEmpty(newTag)) return;
      if (!tags.includes(newTag)) {
        setTags([newTag, ...tags]);
        onChange(newTag, true)
      }
      if (!selection.includes(newTag)) {
        setSelection([newTag, ...selection]);
      }
    },
    [tags, selection, onChange]
  );

  const _onChange = useCallback(
    (tag, checked) => {
      if(onChange) {
        onChange(tag, checked)
      }
      if (checked) {
        setSelection([...selection, tag]);
      } else {
        setSelection(selection?.filter((_tag) => _tag !== tag));
      }
      setPartialSelection(partialSelection.filter((t) => t !== tag));
    },
    [onChange, partialSelection, selection]
  );

  const _onSubmit = useCallback(() => {
    const initialCommon = getCommonSelectedTags(tags, selectedKpisAndCategories)
    const initialPartially = getPartiallySelectedTags(initialCommon, selectedKpisAndCategories)

    onSubmit(
      selectedKpisAndCategories.map((el) => el.slug),
      selection,
      diff([...initialCommon, ...initialPartially], [...selection, ...partialSelection])
    );
  }, [onSubmit, selectedKpisAndCategories, tags, selection, partialSelection]);

  const deleteTag = useCallback(
    ({tag}) => {
      onSubmit(selectedKpisAndCategories.map((el) => el.slug), [], [tag]);
    },
    [onSubmit, selectedKpisAndCategories]
  );

  const areThereChanges = useMemo(() => {
    if(!tags) return false;
    const initialCommon = getCommonSelectedTags(initialTags, selectedKpisAndCategories)
    const initialPartially = getPartiallySelectedTags(initialCommon, selectedKpisAndCategories)
    
    return !isEqual(selection, initialCommon) || !isEqual(partialSelection, initialPartially)
  }, [tags, initialTags, selectedKpisAndCategories, selection, partialSelection])
  return (<Tags
    loading={loading || reducerLoading}
    tags={tags}
    selection={selection}
    partialSelection={partialSelection}
    areThereChanges={areThereChanges}
    onDeleteTag={deleteTag}
    onChange={_onChange}
    onApply={onSubmit && _onSubmit}
    onNewTag={addNewTag}
  />)
};

export default injectIntl(CustomMultiTagSelector);
