import React, { useCallback, useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Button,
  Input,
  Form,
  Modal,
  Select,
  DatePicker,
  Divider,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { fullValidation as validation } from './validation';
import AddObjectiveForm from './AddObjectiveForm';
import PeopleForm from './PeopleForm';
import IndicatorForm from './IndicatorForm';
import TargetGroupForm from './TargetGroupForm';
import useForm from 'utils/useForm';
import { formatDateMoment, dateToDb, DATE_ONLY } from 'utils/date';
import FormItemPreview from 'components/EqualityPlan/FormItemPreview';
import CustomTextArea from 'components/CustomTextArea';
import AddMeasureForm from 'components/EqualityPlanObjectives/AddMeasureForm';

import './style.less';


const ActionsForm = ({
  intl,
  stage,
  objectives,
  activeAction,
  setActiveAction,
  createAction,
  updateAction,
  measures,
  updatePlanProgress,
  createMeasure,
}) => {
  const t = intl.messages;
  const [showAddObjectiveForm, setShowAddObjectiveForm] = useState(false);
  const [showAddMeasureForm, setShowAddMeasureForm] = useState(false);
  const defaults = activeAction;
  const validateForm = useMemo(() => validation(t, stage), [ t, stage ]);

  const submitForm = () => {
    if (values.id) {
      updateAction(values.id, values);
    } else {
      createAction({
        ...values,
        type: stage === 'actions' ? 'general' : 'followup',
      });
      updatePlanProgress(stage);
    }
    resetForm();
    setActiveAction({});
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  const handleChangeDate = useCallback((name, value) => {
    handleChange(name)(value && dateToDb(value));
  }, [ handleChange ]);

  const handleOnCloseAction = useCallback(
    () => {
      resetForm();
      setActiveAction({});
    },
    [resetForm, setActiveAction]
  )

  const handleAddNewObjective = useCallback(
    (objective_id) => {
      let value = [...(getValue('objectives') || [])];
      if (!value.includes(objective_id)) {
        value.push(objective_id);
        handleChange('objectives')(value);
      }
      setShowAddObjectiveForm(false);
    },
    [handleChange, getValue]
  );

  const handleDeleteObjective = useCallback(
    (objective_id) => {
      let value = [...getValue('objectives')];
      value.splice(value.indexOf(objective_id), 1);
      handleChange('objectives')(value);
    },
    [getValue, handleChange]
  )

  const selectedObjectivesIds = getValue('objectives');
  const selectedObjectives = useMemo(
    () => {
      if (!selectedObjectivesIds) {
        return [];
      }
      return objectives.filter(
        objective => selectedObjectivesIds.includes(objective.id)
      );
    },
    [selectedObjectivesIds, objectives]
  );

  const details = useMemo(() => getValue('details') || {}, [getValue]);
  const handleChangeDetails = useCallback(
    (key, value) => {
      let newDetails = { ...details };
      newDetails[key] = value;
      handleChange('details')(newDetails);
    },
    [details, handleChange]
  )

  const handleAddToDetailsList = useCallback(
    (name) => {
      let value = [...details[name] || []];
      const alreadyActiveItem = value.find(item => item.active);
      if (alreadyActiveItem) {
        if (alreadyActiveItem.new) {
          value.splice(
            value.findIndex(item => item.active), 1
          )
        } else {
          delete alreadyActiveItem.active;
        }
      }
      value.push({new: true, active: true});
      handleChangeDetails(name, value);
    },
    [handleChangeDetails, details]
  );

  const handleSaveToDetailsList = useCallback(
    (name, index, item) => {
      let value = [...details[name]];
      if (typeof index == 'number') {
        value[index] = item;
      } else {
        value.push(item);
      }
      handleChangeDetails(name, value);
    },
    [handleChangeDetails, details]
  );

  const makeListItemInactive = useCallback(
    (list, index) => {
      let item = list[index];
      if (item.new) {
        list.splice(index, 1);
      }
      else {
        delete item.active;
      }
      return list;
    },
    []
  );

  const handleCancelSaveToDetailsList = useCallback(
    (name, index) => {
      let value = makeListItemInactive([...details[name]], index);
      handleChangeDetails(name, value);
    },
    [details, handleChangeDetails, makeListItemInactive]
  );

  const handleEditDetailsListItem = useCallback(
    (name, index) => {
      let value = [...details[name]];
      const alreadyActiveItemIndex = value.findIndex(item => item.active);
      value[index].active = true;
      if (alreadyActiveItemIndex >= 0) {
        value = makeListItemInactive(value, alreadyActiveItemIndex);
      }
      handleChangeDetails(name, value);
    },
    [handleChangeDetails, details, makeListItemInactive]
  );

  const handleDeleteDetailsListItem = useCallback(
    (name, index) => {
      let value = [...details[name]];
      value.splice(index, 1);
      handleChangeDetails(name, value);
    },
    [handleChangeDetails, details]
  );

  // People Responsible
  const peopleResponsible = useMemo(() => details['people_responsible'] || [], [details]);
  const validPeopleResponsible = useMemo(
    () => {
      if (peopleResponsible && peopleResponsible.length) {
        return peopleResponsible.filter(person => !person.active);
      } else {
        return [];
      }
    },
    [peopleResponsible]
  );
  const activePersonResponsible = useMemo(
    () => {
      if (peopleResponsible && peopleResponsible.length) {
        return peopleResponsible.find(person => person.active);
      }
    },
    [peopleResponsible]
  );
  const activePersonResponsibleIndex = useMemo(
    () => {
      if (peopleResponsible && peopleResponsible.length) {
        return peopleResponsible.findIndex(person => person.active);
      }
    },
    [peopleResponsible]
  );

  // People Participating
  const peopleParticipating = useMemo( () => details['people_participating'] || [], [details]);
  const validPeopleParticipating = useMemo(
    () => {
      if (peopleParticipating && peopleParticipating.length) {
        return peopleParticipating.filter(person => !person.active);
      } else {
        return [];
      }
    },
    [peopleParticipating]
  );
  const activePersonParticipating = useMemo(
    () => {
      if (peopleParticipating && peopleParticipating.length) {
        return peopleParticipating.find(person => person.active);
      }
    },
    [peopleParticipating]
  );
  const activePersonParticipatingIndex = useMemo(
    () => {
      if (peopleParticipating && peopleParticipating.length) {
        return peopleParticipating.findIndex(person => person.active);
      }
    },
    [peopleParticipating]
  );

  // target groups
  const target_groups = useMemo(() => details['target_groups'] || [], [details]);
  const validTargetGroups = useMemo(
    () => {
      if (target_groups && target_groups.length) {
        return target_groups.filter(target_group => !target_group.active);
      } else {
        return [];
      }
    },
    [target_groups]
  );
  const activeTargetGroup = useMemo(
    () => {
      if (target_groups && target_groups.length) {
        return target_groups.find(target_group => target_group.active);
      }
    },
    [target_groups]
  );
  const activeTargetGroupIndex = useMemo(
    () => {
      if (target_groups && target_groups.length) {
        return target_groups.findIndex(target_group => target_group.active);
      }
    },
    [target_groups]
  );

  const indicators = useMemo(() => getValue('indicators') || [], [getValue]);
  const validIndicators = useMemo(
    () => {
      if (indicators && indicators.length) {
        return indicators.filter(indicator => !indicator.active);
      } else {
        return [];
      }
    },
    [indicators]
  );
  const activeIndicator = useMemo(
    () => {
      if (indicators && indicators.length) {
        return indicators.find(indicator => indicator.active);
      }
    },
    [indicators]
  );
  const activeIndicatorIndex = useMemo(
    () => {
      if (indicators && indicators.length) {
        return indicators.findIndex(indicator => indicator.active);
      }
    },
    [indicators]
  );

  const handleAddToList = useCallback(
    (name) => {
      let value = [...getValue(name) || []];
      const alreadyActiveItem = value.find(item => item.active);
      if (alreadyActiveItem) {
        if (alreadyActiveItem.new) {
          value.splice(
            value.findIndex(item => item.active), 1
          )
        } else {
          delete alreadyActiveItem.active;
        }
      }
      value.push({new: true, active: true});
      handleChange(name)(value);
    },
    [handleChange, getValue]
  );

  const handleSaveToList = useCallback(
    (name, index, item) => {
      let value = [...getValue(name)];
      if (typeof index == 'number') {
        value[index] = item;
      } else {
        value.push(item);
      }
      handleChange(name)(value);
    },
    [handleChange, getValue]
  );

  const handleCancelSaveToList = useCallback(
    (name, index) => {
      let value = makeListItemInactive([...getValue(name)], index);
      handleChange(name)(value);
    },
    [getValue, handleChange, makeListItemInactive]
  );

  const handleEditListItem = useCallback(
    (name, index) => {
      let value = [...getValue(name)];
      const alreadyActiveItemIndex = value.findIndex(item => item.active);
      value[index].active = true;
      if (alreadyActiveItemIndex >= 0) {
        value = makeListItemInactive(value, alreadyActiveItemIndex);
      }
      handleChange(name)(value);
    },
    [handleChange, getValue, makeListItemInactive]
  );

  const handleDeleteListItem = useCallback(
    (name, index) => {
      let value = [...getValue(name)];
      value.splice(index, 1);
      handleChange(name)(value);
    },
    [handleChange, getValue]
  );

  const measure_id = getValue('measure_id');
  const filteredObjectives = useMemo(
    () => {
      return objectives
        .filter(
          objective => objective.measures.find(measure => measure.id === measure_id)
        );
    },
    [objectives, measure_id]
  );

  const onAddMeasure = useCallback(
    (data) => {
      createMeasure(data);
      setShowAddMeasureForm(false);
    },
    [createMeasure]
  );

  const getDisabled = useCallback(
    (type, current) => {
      if (type === 'start_date') {
        const endDate = getValue('end_date');
        return endDate ? current > formatDateMoment(endDate).endOf('day') : false;
      } else {
        const startDate = getValue('start_date');
        return startDate ? current < formatDateMoment(startDate).startOf('day') : false;
      }
    },
    [getValue]
  );

  if (Object.keys(activeAction).length === 0) {
    return <></>;
  }
  return (
    <Modal
      title={getValue('id') ? t.equality_actions_form_edit : t.equality_actions_form_add_new}
      visible={true}
      onOk={handleSubmit}
      onCancel={handleOnCloseAction}
      okText={t.save}
      cancelText={t.cancel}
      width={800}
      className="EqualityPlanActions-form"
    >
      <Form
        className="form-input-wrapper"
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('name') ? 'error' : '' }
              help={ showError('name') }
              label={ t.name }
              colon={false}
              required
            >
              <Input
                placeholder={ t.name }
                name="name"
                value={ getValue('name') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
        </Row>
        { stage === 'actions' && (
          <>
            <Row>
              <Col span={12}>
                <Form.Item
                  hasFeedback
                  validateStatus={ showError('measure_id') ? 'error' : '' }
                  help={ showError('measure_id') }
                  label={ t.equality_plan_measure }
                  colon={false}
                  required
                >
                  <Select
                    name="measure_id"
                    value={ getValue('measure_id') }
                    placeholder={ t.equality_plan_measure }
                    onChange={(value) => handleChange('measure_id')(value)}
                    dropdownRender={menu => (
                      <div className='add-selectoption-container'>
                        {menu}
                        <Divider />
                        <div
                          className='add-selectoption'
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => setShowAddMeasureForm(true)}
                        >
                          <PlusOutlined /> {`${t.add} ${t.equality_plan_measure}`}
                        </div>
                      </div>
                    )}
                  >
                    {measures && measures.map(
                      (measure) => {
                        return <Select.Option
                          key={measure.id}
                          value={measure.id}
                        >
                          {measure.name}
                        </Select.Option>
                      }
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {getValue('measure_id') && (
              <Row>
                <Col span={24} className="form-input-wrapper">
                  <div className="form-input-card">
                    <h4>{t.objectives}</h4>
                    <Button
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => setShowAddObjectiveForm(true)}
                    ></Button>
                  </div>
                </Col>
                {showAddObjectiveForm && (
                  <AddObjectiveForm
                    objectives={filteredObjectives}
                    onCancel={() => setShowAddObjectiveForm(false)}
                    onSave={handleAddNewObjective} />
                )}
                {selectedObjectives.length > 0 && (
                  <Col span={24} className="form-input-wrapper">
                    {selectedObjectives.map((objective, index) => {
                      return <FormItemPreview
                        key={index}
                        sequence={index + 1}
                        onDelete={() => handleDeleteObjective(objective.id)}>
                          <div><b>{objective.name}</b></div>
                          <div>{t[objective.type]}</div>
                      </FormItemPreview>
                    })}
                  </Col>
                )}
              </Row>
            )}
          </>
        )}
        <Row gutter={5}>
          <Col span={12}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('start_date') ? 'error' : '' }
              help={ showError('start_date') }
              label={ t.start_date }
              colon={false}
              required
            >
              <DatePicker
                className="EqualityPlanActions-form-datepicker"
                format={DATE_ONLY}
                name="start_date"
                placeholder={t.start_date}
                value={formatDateMoment(getValue('start_date'))}
                onChange={(value) => handleChangeDate('start_date', value)}
                disabledDate={(current) => getDisabled('start_date', current)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('end_date') ? 'error' : '' }
              help={ showError('end_date') }
              label={ t.end_date }
              colon={false}
              required
            >
              <DatePicker
                className="EqualityPlanActions-form-datepicker"
                format={DATE_ONLY}
                name="end_date"
                placeholder={t.end_date}
                value={formatDateMoment(getValue('end_date'))}
                onChange={(value) => handleChangeDate('end_date', value)}
                disabledDate={(current) => getDisabled('end_date', current)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('activities_description') ? 'error' : '' }
              help={ showError('activities_description') }
              label={ t.activities_desc }
              colon={false}
            >
              <CustomTextArea
                placeholder={ t.activities_desc }
                value={details['activities_description']}
                onChange={(value) => handleChangeDetails('activities_description', value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="form-input-wrapper">
            <div className="form-input-card">
              <h4>{t.people_responsible}</h4>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => handleAddToDetailsList('people_responsible')}
              ></Button>
            </div>
          </Col>
          { activePersonResponsible && (
            <Col span={24}>
              <PeopleForm
                type="people_responsible"
                person={activePersonResponsible}
                index={activePersonResponsibleIndex}
                onSave={handleSaveToDetailsList}
                onCancel={handleCancelSaveToDetailsList} />
            </Col>
          )}
          {validPeopleResponsible.length > 0 && (
            <Col span={24} className="form-input-wrapper">
              {validPeopleResponsible.map((person, index) => {
                return <FormItemPreview
                  key={index}
                  sequence={index + 1}
                  onEdit={() => handleEditDetailsListItem('people_responsible', index)}
                  onDelete={() => handleDeleteDetailsListItem('people_responsible', index)}
                >
                  <div>{`${t.name}: ${person.name}`}</div>
                  <div>{`${t.email}: ${person.email}`}</div>
                </FormItemPreview>;
              })}
            </Col>
          )}
        </Row>
        <Row>
          <Col span={24} className="form-input-wrapper">
            <div className="form-input-card">
              <h4>{t.people_participating}</h4>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => handleAddToDetailsList('people_participating')}
              ></Button>
            </div>
          </Col>
          { activePersonParticipating && (
            <Col span={24}>
              <PeopleForm
                type="people_participating"
                person={activePersonParticipating}
                index={activePersonParticipatingIndex}
                onSave={handleSaveToDetailsList}
                onCancel={handleCancelSaveToDetailsList} />
            </Col>
          )}
          {validPeopleParticipating.length > 0 && (
            <Col span={24} className="form-input-wrapper">
              {validPeopleParticipating.map((person, index) => {
                return <FormItemPreview
                  key={index}
                  sequence={index + 1}
                  onEdit={() => handleEditDetailsListItem('people_participating', index)}
                  onDelete={() => handleDeleteDetailsListItem('people_participating', index)}
                >
                  <div>{`${t.name}: ${person.name}`}</div>
                  <div>{`${t.email}: ${person.email}`}</div>
                </FormItemPreview>;
              })}
            </Col>
          )}
        </Row>
        { stage === 'actions' && (
          <>
            <Row>
              <Col span={24} className="form-input-wrapper">
                <div className="form-input-card">
                  <h4>{t.target_groups}</h4>
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => handleAddToDetailsList('target_groups')}
                  ></Button>
                </div>
              </Col>
              { activeTargetGroup && (
                <Col span={24}>
                  <TargetGroupForm
                    type="target_groups"
                    person={activeTargetGroup}
                    index={activeTargetGroupIndex}
                    onSave={handleSaveToDetailsList}
                    onCancel={handleCancelSaveToDetailsList} />
                </Col>
              )}
              {validTargetGroups.length > 0 && (
                <Col span={24} className="form-input-wrapper">
                  {validTargetGroups.map((group, index) => {
                    return <FormItemPreview
                      key={index}
                      sequence={index + 1}
                      onEdit={() => handleEditDetailsListItem('target_groups', index)}
                      onDelete={() => handleDeleteDetailsListItem('target_groups', index)}
                    >
                      <div>{`${t.name}: ${group.name}`}</div>
                    </FormItemPreview>;
                  })}
                </Col>
              )}
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  validateStatus={ showError('material_and_hr') ? 'error' : '' }
                  help={ showError('material_and_hr') }
                  label={ t.material_and_hr }
                  colon={false}
                >
                  <CustomTextArea
                    placeholder={ t.material_and_hr }
                    value={details['material_and_hr']}
                    onChange={(value) => handleChangeDetails('material_and_hr', value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  validateStatus={ showError('communication_mechanism') ? 'error' : '' }
                  help={ showError('communication_mechanism') }
                  label={ t.communication_mechanism }
                  colon={false}
                >
                  <CustomTextArea
                    placeholder={ t.communication_mechanism }
                    value={details['communication_mechanism']}
                    onChange={(value) => handleChangeDetails('communication_mechanism', value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  validateStatus={ showError('implementation_calendar') ? 'error' : '' }
                  help={ showError('implementation_calendar') }
                  label={ t.implementation_calendar }
                  colon={false}
                >
                  <CustomTextArea
                    placeholder={ t.implementation_calendar }
                    value={details['implementation_calendar']}
                    onChange={(value) => handleChangeDetails('implementation_calendar', value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  validateStatus={ showError('budget_approach') ? 'error' : '' }
                  help={ showError('budget_approach') }
                  label={ t.budget_approach }
                  colon={false}
                >
                  <CustomTextArea
                    placeholder={ t.budget_approach }
                    value={details['budget_approach']}
                    onChange={(value) => handleChangeDetails('budget_approach', value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  validateStatus={ showError('evaluation_mechanisms') ? 'error' : '' }
                  help={ showError('evaluation_mechanisms') }
                  label={ t.equality_actions_evaluation_mechanisms }
                  colon={false}
                >
                  <CustomTextArea
                    placeholder={ t.equality_actions_evaluation_mechanisms }
                    value={details['evaluation_mechanisms']}
                    onChange={(value) => handleChangeDetails('evaluation_mechanisms', value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('comments') ? 'error' : '' }
              help={ showError('comments') }
              label={ t.comments }
              colon={false}
            >
              <CustomTextArea
                placeholder={ t.comments }
                value={details['comments']}
                onChange={(value) => handleChangeDetails('comments', value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="form-input-wrapper">
            <div className="form-input-card">
              <h4>
                <span className="required">*</span> {t.tracking_indicators}
              </h4>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => handleAddToList('indicators')}
              ></Button>
            </div>
            { showError('indicators')
              ? <div className="required">
                  { showError('indicators') }
                </div>
              : null
            }
          </Col>
          <Col span={24}>
            <IndicatorForm
              indicator={activeIndicator ? activeIndicator : {}}
              key={activeIndicatorIndex}
              index={activeIndicatorIndex}
              actionStartDate={formatDateMoment(getValue('start_date'))}
              actionEndDate={formatDateMoment(getValue('end_date'))}
              onSave={handleSaveToList}
              onCancel={handleCancelSaveToList}
              visible={activeIndicator && activeIndicator.active ? true : false}
              setVisibility={handleCancelSaveToList} />
          </Col>
          {validIndicators.length > 0 && (
            <Col span={24} className="form-input-wrapper">
              {validIndicators.map((indicator, index) => {
                return <FormItemPreview
                  key={index}
                  sequence={index + 1}
                  onEdit={() => handleEditListItem('indicators', index)}
                  onDelete={() => handleDeleteListItem('indicators', index)}
                >
                  <div>{indicator.name}</div>
                  <div>{`${t.information_tool}: ${indicator.information_tool}`}</div>
                </FormItemPreview>;
              })}
            </Col>
          )}
        </Row>
        <AddMeasureForm
          visible={showAddMeasureForm}
          setVisibility={setShowAddMeasureForm}
          onSubmit={onAddMeasure} />
      </Form>
    </Modal>
  );
};

export default injectIntl(ActionsForm);
