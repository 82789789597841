import React, { useMemo } from 'react';
import { Provider } from 'react-redux';

import {
  Row,
  Col,
} from 'antd';

import AplanetIcon from 'components/AplanetIcon';
import AuthorizedLink from 'containers/AuthorizedLink';

import { store } from 'App';

import './style.less';


const Notification = ({
  intl,
  content,
}) => {
  const {
    file,
    url: download,
    mimetype
  } = content;

  const fileName = useMemo(() => file.split('/').pop(), [file]);

  return (
    <Col span={24}>
      <Row className='notification_body_container_bulk_manage_export' type="flex" justify="space-between">
        <Col span={16} className='notification_body_bulk_manage_export_fileinfo'>
          <AplanetIcon name="File excel" />
          {fileName}
        </Col>
        <Col span={8} className="notification_body_bulk_manage_export_download">
          <Provider store={store}>
            <AuthorizedLink
              href={download}
              download={fileName}
              mimeType={mimetype}
              className="notification_body_bulk_manage_export_download_button"
            >
              <AplanetIcon name='Download' faStyle='fas' />
              { intl.formatMessage({id: 'download'}) }
            </AuthorizedLink>
          </Provider>
        </Col>
      </Row>
    </Col>
)
};

Notification.Popup = Notification;
export default Notification;
