import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Input,
  Form,
  InputNumber,
  DatePicker,
  Select,
  Modal,
} from 'antd';

import {indicatorValidation as validation} from './validation';
import useForm from 'utils/useForm';
import { formatDateMoment, dateToDb, DATE_ONLY } from 'utils/date';

import './style.less';

const IndicatorForm = ({
  intl,
  indicator,
  index,
  actionStartDate,
  actionEndDate,
  onSave,
  onCancel,
  visible,
}) => {
  const t = intl.messages;
  const defaults = indicator;
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    const {
      id, name, current_value, target_value, start_date, end_date,
      information_tool, periodicity
    } = values;
    onSave(
      'indicators', index, {
        id, name, current_value, target_value, start_date, end_date,
        information_tool, periodicity, initial_value: current_value
      }
    );
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  const handleChangeDate = useCallback((name, value) => {
    handleChange(name)(value && dateToDb(value));
  }, [ handleChange ]);

  const getDisabledDate = useCallback(
    (current) => {
      if (actionStartDate && actionEndDate) {
        return current < actionStartDate.startOf('day') || current > actionEndDate.endOf('day');
      } else if (actionStartDate) {
        return current < actionStartDate.startOf('day');
      } else if (actionEndDate) {
        return current > actionEndDate.endOf('day');
      } else {
        return false;
      }
    },
    [actionStartDate, actionEndDate]
  );

  return (
    <Modal
      title={`${t.add} ${t.indicator}`}
      visible={visible}
      onOk={handleSubmit}
      onCancel={() => onCancel('indicators', index)}
      okText={t.save}
      cancelText={t.cancel}
      className="IndicatorForm"
    >
      <Row>
        <Col span={24} className="form-input-wrapper">
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('name') ? 'error' : '' }
                help={ showError('name') }
                label={ t.indicator }
                colon={false}
                required
              >
                <Input
                  placeholder={ t.indicator }
                  name="name"
                  value={ getValue('name') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('current_value') ? 'error' : '' }
                help={ showError('current_value') }
                label={ t.value_current }
                colon={false}
                required
              >
                <InputNumber
                  name="current_value"
                  value={ getValue('current_value') }
                  onChange={(value) => handleChange('current_value')(value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('target_value') ? 'error' : '' }
                help={ showError('target_value') }
                label={ t.value_target }
                colon={false}
                required
              >
                <InputNumber
                  name="target_value"
                  value={ getValue('target_value') }
                  onChange={(value) => handleChange('target_value')(value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('start_date') ? 'error' : '' }
                label={t.measurement_start_date}
                help={ showError('start_date') }
                colon={false}
              >
                <DatePicker
                  className="IndicatorForm-datepicker"
                  format={DATE_ONLY}
                  name="start_date"
                  placeholder={t.start_date}
                  value={formatDateMoment(getValue('start_date'))}
                  onChange={(value) => handleChangeDate('start_date', value)}
                  disabledDate={getDisabledDate}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('end_date') ? 'error' : '' }
                label={t.measurement_end_date}
                help={ showError('end_date') }
                colon={false}
              >
                <DatePicker
                  className="IndicatorForm-datepicker"
                  format={DATE_ONLY}
                  name="end_date"
                  placeholder={t.end_date}
                  value={formatDateMoment(getValue('end_date'))}
                  onChange={(value) => handleChangeDate('end_date', value)}
                  disabledDate={getDisabledDate}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('periodicity') ? 'error' : '' }
                help={ showError('periodicity') }
                label={t.equality_actions_indicators_periodicity}
                colon={false}
                required
              >
                <Select
                  name='periodicity'
                  value={ getValue('periodicity') }
                  onChange={(value) => handleChange('periodicity')(value)}
                >
                  <Select.Option value={'monthly'}>{t['monthly']}</Select.Option>
                  <Select.Option value={'quarterly'}>{t['quarterly']}</Select.Option>
                  <Select.Option value={'biannually'}>{t['biannually']}</Select.Option>
                  <Select.Option value={'annually'}>{t['annually']}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('information_tool') ? 'error' : '' }
                help={ showError('information_tool') }
                label={ t.information_tool }
                colon={false}
                required
              >
                <Input
                  placeholder={ t.information_tool }
                  name="information_tool"
                  value={ getValue('information_tool') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
};

export default injectIntl(IndicatorForm);
