import React, { useMemo, useState } from 'react';

import DataSourcesKpiRow from 'components/DataSources/KpiRow';

import {
  CaretRightOutlined,
  CaretDownOutlined,
  FolderOpenOutlined,
  FolderOutlined,
} from '@ant-design/icons';


const DataSourcesCategoryRow = ({
  category,
  children,
  level = 0,
}) => {
  const [expanded, setExpanded] = useState(false);

  const cellStyle = useMemo(
    () => ({
      paddingLeft: `${5 + (level * 20)}px`,
    }),
    [level],
  );

  return (
    <>
      <tr>
        <td
          className="DataManagement__data-sources_table-name"
          style={cellStyle}
          onClick={() => setExpanded(!expanded)}
        >
          { expanded
            ? <><CaretDownOutlined /> <FolderOpenOutlined /></>
            : <><CaretRightOutlined /> <FolderOutlined /></>
          } {category.name}
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      { expanded &&
        <>
          { children.map(node => {
            if (node.category) {
              return <DataSourcesCategoryRow
                key={node.slug}
                category={node.category}
                children={node.children}
                level={level + 1}
              />;
            } else {
              return <DataSourcesKpiRow
                key={node.slug}
                kpi={node}
                level={level + 1}
              />;
            }
          })}
        </>
      }
    </>
  );
}

export default DataSourcesCategoryRow;