import React, {
  useCallback,
  useMemo,
} from 'react';

import { injectIntl } from 'react-intl';

import { fullValidation as validation } from './validation';
import useForm from 'utils/useForm';
import LevelFormItem from './LevelFormItem';

import {
  Row,
  Col,
  Form,
} from 'antd';

import CustomTextArea from 'components/CustomTextArea';
import CustomSelect from 'components/CustomSelect';
import CustomInput from 'components/CustomInput';
import CustomModal from 'components/CustomModal';
import CustomButton from 'components/CustomButton';


const FactorsForm = ({
  intl,
  factor,
  closeForm,
  handleSave,
}) => {
  const t = intl.messages;
  const defaults = useMemo(() => factor.id
    ? {
      ...factor,
      levels: factor.levels.sort((a, b) => a.level - b.level),
    }
    : {
      levels: [{
        level: 0,
        name: '',
        description: '',
        points: 1,
      }, {
        level: 1,
        name: '',
        description: '',
        points: 10,
      }],
    }, [factor]);
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    handleSave({
      ...values,
      levels: values.levels
        .sort((a, b) => a.points - b.points)
        .map((level, index) => ({...level, level: index})),
    });
    resetForm();
    closeForm();
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  const handleOnCloseForm = useCallback(
    () => {
      resetForm();
      closeForm();
    },
    [resetForm, closeForm]
  );

  const typeOptions = useMemo(
    () => {
      return ['A', 'B', 'C', 'D'].map(slug => ({
        name: t[`equality_job_evaluation_factor_type_option_${slug}`],
        slug,
      }));
    },
    [t]
  );

  const genderOptions = useMemo(
    () => {
      return ['male', 'female', 'neutral'].map(slug => ({
        name: t[`equality_job_evaluation_factor_gender_option_${slug}`],
        slug,
      }));
    },
    [t]
  );

  const handleChangeLevel = useCallback(
    (index, level) => {
      const levels = [
        ...values.levels.slice(0, index),
        level,
        ...values.levels.slice(index + 1)
      ];
      handleChange('levels')(levels);
    },
    [values.levels, handleChange]
  );

  const handleAddLevel = useCallback(
    () => {
      const levels = [
        ...values.levels.slice(0, values.levels.length - 1),
        {},
        values.levels[values.levels.length - 1]
      ];
      handleChange('levels')(levels);
    },
    [values.levels, handleChange]
  );

  const handleRemoveLevel = useCallback(
    (index) => {
      const levels = [
        ...values.levels.slice(0, index),
        ...values.levels.slice(index + 1)
      ];
      handleChange('levels')(levels);
    },
    [values.levels, handleChange]
  );

  return (
    <CustomModal
      title={getValue('id') ? t.equality_job_evaluation_factor_edit : t.equality_job_evaluation_factor_add_new}
      shown={true}
      onOk={handleSubmit}
      onCancel={handleOnCloseForm}
      okText={t.save}
      cancelText={t.cancel}
      width={800}
    >
      <Form
        className="form-input-wrapper"
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('code') ? 'error' : '' }
              help={ showError('code') }
              label={ t.equality_job_evaluation_factor_code }
              colon={false}
              required
            >
              <CustomInput
                placeholder={ t.equality_job_evaluation_factor_code }
                name="code"
                value={ getValue('code') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('name') ? 'error' : '' }
              help={ showError('name') }
              label={ t.equality_job_evaluation_factor_name }
              colon={false}
              required
            >
              <CustomInput
                placeholder={ t.equality_job_evaluation_factor_name }
                name="name"
                value={ getValue('name') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('type') ? 'error' : '' }
              help={ showError('type') }
              label={ t.equality_job_evaluation_factor_type }
              colon={false}
              required
            >
              <CustomSelect
                title={t.equality_job_evaluation_factor_type}
                options={typeOptions}
                onSelect={handleChange('type')}
                selected={getValue('type')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('gender') ? 'error' : '' }
              help={ showError('gender') }
              label={ t.equality_job_evaluation_factor_gender }
              colon={false}
              required
            >
              <CustomSelect
                title={t.equality_job_evaluation_factor_gender}
                options={genderOptions}
                onSelect={handleChange('gender')}
                selected={getValue('gender')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('description') ? 'error' : '' }
              help={ showError('description') }
              label={ t.equality_job_evaluation_factor_description }
              colon={false}
            >
              <CustomTextArea
                value={getValue('description')}
                onChange={handleChange('description')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('levels') ? 'error' : '' }
              help={ showError('levels') }
              label={ t.equality_job_evaluation_factor_levels }
              colon={false}
              required
            >
              <Row gutter={[5, 5]}>
                <Col span={4}>
                  <b>{t.equality_job_evaluation_factor_level}</b>
                </Col>
                <Col span={7}>
                  <b>{t.equality_job_evaluation_factor_level_name}</b>
                </Col>
                <Col span={8}>
                  <b>{t.equality_job_evaluation_factor_level_description}</b>
                </Col>
                <Col span={4}>
                  <b>{t.equality_job_evaluation_factor_level_points}</b>
                </Col>
              </Row>
              { getValue('levels').map(
                (level, index) => <LevelFormItem
                  key={index}
                  index={index}
                  level={level}
                  onChange={handleChangeLevel}
                  onDelete={handleRemoveLevel}
                  canEdit={![0, getValue('levels').length - 1].includes(index)}
                />
              )}
              <Row gutter={[5, 5]}>
                <CustomButton
                  type="primary"
                  onClick={handleAddLevel}
                  disabled={getValue('levels').length === 10}
                >
                  {t.equality_job_evaluation_factor_level_add}
                </CustomButton>
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
}

export default injectIntl(FactorsForm);
