import React from 'react';
import { injectIntl } from 'react-intl';
import CustomButton from 'components/CustomButton';
import CustomModal from 'components/CustomModal';
import { Col, Row } from 'antd';

const ConfirmModal = ({
    intl,
    visible,
    onOk,
    onCancel,
    action
}) => {
    const t = intl.messages;
return (
 <CustomModal
      className="ModalTarget"
      shown={visible}
      title={ action === 'delete' ? t[`modalconfirmtarget_title_delete`] :t[`modalconfirmtarget_title`] }
      onOk={onOk}
      onCancel={onCancel}
      footer={
        <div className="ModalTarget__footer">
          <CustomButton
            className="ModalTarget__btn-footer__cancel"
            key="back"
            type="text"
            danger
            onClick={onCancel}
          >
            { t.modalapproverejectkpi_cancel }
          </CustomButton>

          <CustomButton
            className="ModalTarget__btn-footer__save"
            key="submit"
            type="primary"
            onClick={onOk}
          >
              { action === 'delete' ? t[`modaltarget_actions_delete`] : t[`modaltarget_actions_save`] }
          </CustomButton>
        </div>
      }
    >
     <Row>
        <Col>{ action === 'delete' ? t['modalconfirmtarget_delete_instructions'] : t['modalconfirmtarget_instructions']}</Col>
      </Row>
    </CustomModal>
)
};


export default injectIntl(ConfirmModal);
