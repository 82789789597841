import { convertToUnit } from 'utils/units';
import {
  translateSlug,
} from 'components/TableAnswer/converters/common';


const dimensionToRows = (
  schema,
  value,
  schemaLabels = {},
  tableDimensions = {},
) => (dimension, depth) => {
  // TODO: translate and all
  if(dimension.source === 'organization') {
    return (
      tableDimensions[dimension.by] || []
    ).map(slug => ({
      name: translateSlug(((schemaLabels || {}).dimensionValues || {})[dimension.by])(slug),
      slug,
    }))
    .sort((a, b) => {
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    })
  }

  // NOTICE: By convention this should never happen
  if(dimension.source === 'singleton') {
    return [{
      name: translateSlug((schemaLabels || {}).dimensionNames)(dimension.by),
      slug: dimension.by,
    }];
  }

  if(dimension.source === 'standard') {
    // TODO: Make sure we translate standard names....
    return (dimension.standardItems || []).map(({ slug, name }) => {
      return {
        name: translateSlug(((schemaLabels || {}).dimensionValues || {})[dimension.by] )(slug) || name || slug,
        slug,
      }
    });
  }

  if(dimension.source === 'user') {
    // NOTICE: maximum ONE user dimension, and it MUST be the first
    //         but we try to work around just in case the data is corrupted

    // TODO: Add standardItems
    const stdItemMap = new Map(
      (dimension.standardItems || []).map(({ slug, name }) => {
        return [
          slug,
          {
            name: name || translateSlug(((schemaLabels || {}).dimensionValues || {})[dimension.by] )(slug),
            slug,
          },
        ]
      })
    );

    let listObject = value || {};
    for(let i=0; i < depth; i++) {
      listObject = listObject[0] || {};
    }

    const customSlugs = Object.keys(listObject).filter(slug => !stdItemMap.has(slug));
    return Array.from(stdItemMap.values()).concat(
      customSlugs.map(slug => ({
        slug,
        name: slug, // NOTICE: Name is provided by the user here, and saved as a slug... sigh
      }))
    ).filter(Boolean);
  }

  return [];
};

const getRowTotal = (rowTarget, columns, countBase, availableUnits) => {
  const baseUnit = availableUnits?.find(unit => unit.is_base);
  const convertToBase = baseUnit && convertToUnit(baseUnit.slug, availableUnits);

  return columns.slice(countBase)
    .reduce(
      (acc, { dataIndex }) => {
        let value = rowTarget[dataIndex]?.value || 0;

        if (baseUnit && rowTarget[dataIndex]) {
          value = Number(
            convertToBase(rowTarget[dataIndex])?.value || 0
          );
        }

        return acc + value;
      }, 0)
};

export {
  dimensionToRows,
  getRowTotal,
};
