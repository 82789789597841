import React from 'react'
import {
  Table,
} from 'antd'
import './style.less'

const CustomTable = ({
  className,
  dataSource,
  columns,
  ...props
}) => {
  return (
    <Table
      className={`CustomTable${className ? ` ${className}` : ''}`}
      columns={columns}
      dataSource={dataSource}
      {...props}
    />
  );
}

export default CustomTable
