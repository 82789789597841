import {
  isLastIndicator,
  isFirstCategory,
  isNextCategory,
} from "utils/reporting_structure";

const isCategoryDroppedInsideIndicator = (
  dropProps,
) => {
  const {
    dropToGap,
    referenceNodeWhenDrop,
  } = dropProps;

  return !dropToGap && !referenceNodeWhenDrop.isCategory;
};

const isCategoryDroppedInsideSameCategoryOrDescendant = (
  category,
  dropProps,
) => {
  const {
    uuid: uuidCategory,
  } = category;
  const {
    ancestorsCategories: ancestorsCategoriesReferenceNodeWhenDrop,
    uuid: uuidReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return (!dropProps.dropToGap && uuidReferenceNodeWhenDrop === uuidCategory)
    || (ancestorsCategoriesReferenceNodeWhenDrop.map(({uuid}) => uuid)).includes(uuidCategory);
};

const isCategoryDroppedInsideTopOfCategoryWithIndicators = (
  dropProps,
) => {
  const {
    children: childrenReferenceNodeWhenDrop,
    expanded: isExpandedReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return !dropProps.dropToGap
    && isExpandedReferenceNodeWhenDrop
    && childrenReferenceNodeWhenDrop.some(({ isCategory }) => !isCategory);
};

const isCategoryDroppedInsideTopOfCategoryWithoutIndicatorsAndAlreadyHasFirstPosition = (
  category,
  dropProps,
  reportingStructure,
) => {
  const {
    parent_uuid: parentUuidCategory,
    uuid: uuidCategory
  } = category;
  const {
    children: childrenReferenceNodeWhenDrop,
    uuid: uuidReferenceNodeWhenDrop,
    expanded: isExpandedReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return !dropProps.dropToGap
    && isExpandedReferenceNodeWhenDrop
    && uuidReferenceNodeWhenDrop === parentUuidCategory
    && childrenReferenceNodeWhenDrop.every(({ isCategory }) => isCategory)
    && isFirstCategory(reportingStructure, uuidCategory);
};

const isCategoryDroppedBetweenIndicators = (
  dropProps,
  reportingStructure,
) => {
  const {
    isCategory: isCategoryReferenceNodeWhenDrop,
    uuid: uuidReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return dropProps.dropToGap
    && !isCategoryReferenceNodeWhenDrop
    && !isLastIndicator(reportingStructure, uuidReferenceNodeWhenDrop);
};

const isCategoryDroppedInSameParentAndPosition = (
  category,
  dropProps,
  reportingStructure,
) => {
  const {
    parent_uuid: parentUuidCategory,
    uuid: uuidCategory
  } = category;
  const {
    parent_uuid: parentUuidReferenceNodeWhenDrop,
    uuid: uuidReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return dropProps.dropToGap
    && parentUuidCategory === parentUuidReferenceNodeWhenDrop
    && ((dropProps.isNodeDroppedAtTheTopOfAllRoots && isFirstCategory(reportingStructure, uuidCategory))
      || (isFirstCategory(reportingStructure, uuidCategory) && isLastIndicator(reportingStructure, uuidReferenceNodeWhenDrop))
      || (isNextCategory(reportingStructure, uuidReferenceNodeWhenDrop, uuidCategory) && !dropProps.isNodeDroppedAtTheTopOfAllRoots)
    );
};

export const canDropCategory = (
  droppedCategory,
  dropProps,
  reportingStructure,
) => {

  if (isCategoryDroppedInsideIndicator(dropProps)) {
    return false;
  }

  if (isCategoryDroppedInsideSameCategoryOrDescendant(droppedCategory, dropProps)) {
    return false;
  }

  if (isCategoryDroppedInsideTopOfCategoryWithIndicators(dropProps)) {
    return false;
  }

  if (isCategoryDroppedInsideTopOfCategoryWithoutIndicatorsAndAlreadyHasFirstPosition(droppedCategory, dropProps, reportingStructure)) {
    return false;
  }

  if (isCategoryDroppedBetweenIndicators(dropProps, reportingStructure)) {
    return false;
  }

  if (isCategoryDroppedInSameParentAndPosition(droppedCategory, dropProps, reportingStructure)) {
    return false;
  }

  return true;
};

const isCategoryDroppedAtTopOfTheRoot = (
  dropProps,
  reportingStructure,
) => {
  const {
    parent_uuid: parentUuidReferenceNodeWhenDrop,
    uuid: uuidReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return dropProps.dropToGap
    && !parentUuidReferenceNodeWhenDrop
    && isFirstCategory(reportingStructure, uuidReferenceNodeWhenDrop)
    && dropProps.isNodeDroppedAtTheTopOfAllRoots;
};

const isCategoryDroppedFirstOfCategoriesBlock = (
  dropProps,
) => {
  const {
    children: childrenReferenceNodeWhenDrop,
    expanded: isExpandedReferenceNodeWhenDrop,
    isCategory: isCategoryReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  // NOTICE: when there is block of children indicators above || there is NOT any block of indicators
  return (dropProps.dropToGap && !isCategoryReferenceNodeWhenDrop)
    || (!dropProps.dropToGap && isExpandedReferenceNodeWhenDrop && childrenReferenceNodeWhenDrop.every(({ isCategory }) => isCategory));
};

const isCategoryDroppedInsideNotExpandedOrEmptyCategory = (
  dropProps,
) => {
  const {
    children: childrenReferenceNodeWhenDrop,
    expanded: isExpandedReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return !dropProps.dropToGap && (!isExpandedReferenceNodeWhenDrop || childrenReferenceNodeWhenDrop?.length === 0);
};

export const getPrevReferenceCategoryUuid = (
  dropProps,
  reportingStructure
) => {

  if (!dropProps.hasOwnProperty('dropToGap') || !dropProps.hasOwnProperty('referenceNodeWhenDrop') || !dropProps.hasOwnProperty('isNodeDroppedAtTheTopOfAllRoots')) {
    throw new Error(`Wrong dropProps object`);
  }

  if (!reportingStructure) {
    throw new Error(`reportingStructure does not exist`);
  }

  const {
    uuid: uuidReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  if (
    isCategoryDroppedAtTopOfTheRoot(dropProps, reportingStructure)
    || isCategoryDroppedFirstOfCategoriesBlock(dropProps)
    || isCategoryDroppedInsideNotExpandedOrEmptyCategory(dropProps)
  ) {
    return null;
  }

  return uuidReferenceNodeWhenDrop;
};