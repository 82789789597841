import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

import { CSVLink } from "react-csv";

import { Loading } from 'tsComponents/emptyStates/Loading';
import AddCompanyForm from 'components/AddCompanyForm';
import CustomTable from 'components/CustomTable';
import CustomButton from 'components/CustomButton';
import SdgMiniLogo from 'components/SdgMiniLogo';
import EsgLogo from 'components/EsgLogo';
import InformationModal from 'components/InformationModal';
import { sdgSlugToNumber } from 'utils/sdg';
import { createExcel, excelToArray } from 'utils/excel';

import {
  Row,
  Col,
  Alert,
  Checkbox,
  Rate,
  Button,
  Dropdown,
  Menu,
} from 'antd';
import {
  PlusOutlined,
  DownOutlined,
  CommentOutlined,
} from '@ant-design/icons';

import './style.less';
import {useSDGsClustering} from "../../hooks/sdg";

const shortStandard = {
  'gri': 'GRI',
  'gri-2021': 'GRI-2021',
  'equality': 'EQ',
  'aplanet': 'Aplanet',
  'sdg': 'SDG',
  'sdgc': 'SDG-C',
  'bcorp': 'B-Corp',
  'einf': 'EINF',
  'euss': 'Electric Utilities Sector Supplement',
  'ungc': 'UNGC',
  'tefce': 'TEFCE',
  'prme': 'PRME',
  'sasb-hc-dy': 'SASB-HC-DY',
  'sasb-hc-di': 'SASB-HC-DI',
  'sasb-hc-dr': 'SASB-HC-DR',
  'sasb-hc-mc': 'SASB-HC-MC',
  'sasb-hc-ms': 'SASB-HC-MS',
  'sasb-hc-bp': 'SASB-HC-BP',
  'sasb-fn-ac': 'SASB-FN-AC',
  'sasb-fn-cb': 'SASB-FN-CB',
  'sasb-fn-cf': 'SASB-FN-CF',
  'sasb-fn-in': 'SASB-FN-IN',
  'sasb-fn-ib': 'SASB-FN-IB',
  'sasb-fn-mf': 'SASB-FN-MF',
  'sasb-fn-ex': 'SASB-FN-EX',
  'sasb-cg-aa': 'SASB-CG-AA',
  'sasb-cg-mr': 'SASB-CG-MR',
  'tcfd': 'TCFD',
  'sasb-fb-fr': 'SASB-FB-FR',
  'sasb-if-re': 'SASB-IF-RE',
  'sfdr': 'SFDR',
  'shift': 'SHIFT',
  'scority': 'SCORITY',
  'ghg': 'GHG',
  'neutrality': 'Neutrality',
};

const AnalysisBenchmark = ({
  intl,
  companies,
  addCompany,
  startAnalysis,
  analysis,
  analysisSusIssues,
  fetchingAnalysisSusIssues,
  uploadBenchmark,
  error,
  userProfile,
}) => {
  const t = intl.messages;
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const analysisStarted = analysis && analysis.companies.length;
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedIssueComments, setSelectedIssueComments] = useState();

  const toggleCompanySelection = useCallback(
    (companyId, selectedCompany) => {
      if(selectedCompany) {
        setSelectedCompanies(
          selectedCompanies.filter(company => company.id !== companyId)
        );
      } else {
        setSelectedCompanies([...selectedCompanies, {id: companyId}]);
      }
    },
    [selectedCompanies]
  );

  const handleWeightChange = useCallback((companyId, weight) => {
    const index = selectedCompanies
      .map((c) => c.id)
      .indexOf(companyId);

    setSelectedCompanies([
      ...selectedCompanies.slice(0, index),
      {...selectedCompanies[index], weight },
      ...selectedCompanies.slice(index + 1)
    ]);
  }, [selectedCompanies]);

  const _startAnalysis = useCallback(
    () => {
      startAnalysis({companies: selectedCompanies});
    },
    [startAnalysis, selectedCompanies]
  );

  const selectedCompanyNames = useMemo(
    () => analysis.companies
      .map(company => `${company.id}: ${company.name}`),
    [analysis]
  );

  const benchmarkCsvData = useMemo(
    () => (
      analysisSusIssues.map(issue => ([
        issue.id,
        `${issue.esg_types.map(type => t[`esg_type_${type}`]).join(' - ')}`,
        `${issue.sdgs.map(sdg => sdgSlugToNumber(sdg)).join(' - ')}`,
        `${issue.standards.map(standard => shortStandard[standard]).join(' - ')}`,
        issue.subject,
        issue.issue || '-',
      ]))
    ),
    [analysisSusIssues, t]
  );

  const benchmarkCsvHeader = useMemo(() => [
    'ID',
    t.analysis_csv_esg_type,
    t.analysis_csv_sdg,
    t.analysis_csv_standard,
    t.analysis_csv_subject,
    t.analysis_csv_issue,
    ...selectedCompanyNames,
    t.analysis_csv_comments,
  ], [selectedCompanyNames, t.analysis_csv_comments, t.analysis_csv_esg_type, t.analysis_csv_issue, t.analysis_csv_sdg, t.analysis_csv_standard, t.analysis_csv_subject]);
  const benchmarkCsv = useMemo(() => [benchmarkCsvHeader, ...benchmarkCsvData], [benchmarkCsvData, benchmarkCsvHeader]);

  const benchmarkUploaded = useMemo(
    () => (
      !!(analysisSusIssues && analysisSusIssues
        .map(issue => issue.weight)
        .filter(weight => weight)
      ).length
    ),
    [analysisSusIssues]
  );

  const onUploadBenchmarkCsv = useCallback(
    (e) => {
      const file = e.target.files[0];
      excelToArray(file, t[`analysis_${analysis.method}_tab_benchmark`], (data) => {
        uploadBenchmark(data);
      });
    }, [
      uploadBenchmark,
      t,
      analysis,
    ]
  );

  const onDownload = useCallback(() => {
    createExcel(
      `analysis_${analysis.id}_benchmark_template.xlsx`,
      t[`analysis_${analysis.method}_tab_benchmark`],
      benchmarkCsv,
      true,
    );
  }, [
    analysis,
    benchmarkCsv,
    t,
  ]);

  const clusterSDGs = useSDGsClustering();

  const benchmarkColumns = useMemo(
    () => (
      [{
        title: t.subject,
        dataIndex: 'subject',
      }, {
        title: t.issue,
        width: 300,
        dataIndex: 'issue',
        render: (text) => text || '-',
      }, {
        title: t.standard,
        dataIndex: 'standards',
        render: (standards) => `${standards.map(standard => shortStandard[standard]).join(', ')}`,
      }, {
        title: t.sdg,
        dataIndex: 'sdgs',
        render: (sdgs) => clusterSDGs(sdgs).map(sdg => <SdgMiniLogo key={sdg.slug} sdg={sdg} />)
      }, {
        title: t.type,
        dataIndex: 'esg_types',
        render: (types) => types.map(type => <EsgLogo key={type} type={type} />)
      }, {
        title: t.weight,
        dataIndex: 'weight',
      }, {
        title: t.companies_compared_with,
        dataIndex: 'benchmark_company_results',
        render: data => data.map(company => `${company.name}: ${company.weight}`).join(`, `),
      }, {
        title: t.comments,
        dataIndex: 'benchmark_comments',
        render: (text) => (
          text
          ? <CommentOutlined
              onClick={() => setSelectedIssueComments(text)}
            />
          : '-'
        ),
      }]
    ),
    [t, clusterSDGs]
  );

  if (fetchingAnalysisSusIssues) {
    return <Loading />;
  }

  if (!benchmarkUploaded && analysis.status === 'completed') {
    return (
      <div className="AnalysisBenchmark">
        <Row type="flex" justify="space-around" style={{marginTop: 50}}>
          <h2>{ t.analysis_benchmark_no_data_closed}</h2>
        </Row>
      </div>
    )
  }

  if (!analysisStarted) {
    return (
      <div className="AnalysisBenchmark">
        <Row type="flex" justify="space-around" style={{marginTop: 50}}>
          <h2>{ t.who_are_your_competitors }</h2>
        </Row>
        <Row type="flex" justify="center" gutter={[20, 20]}>
          <Col span={24}>
            <Row type="flex" justify="center" gutter={[5, 5]}>
              { selectedCompanies.length > 0
                ? <Col span={12} offset={1}>
                    <Row>
                      <Col span={14} offset={10}>
                        <h4>{ t.weight }</h4>
                      </Col>
                    </Row>
                  </Col>
                : null
              }
              {companies.map(company => {
                const selectedCompany = selectedCompanies.find(
                  c => c.id === company.id
                );
                return (
                  <Col span={12} offset={1}>
                    <Row>
                      <Col
                        className="AnalysisBenchmark-company-option"
                        key={company.id}
                        span={10}
                      >
                        <Checkbox
                          className="AnalysisBenchmark-checkbox"
                          onChange={() => toggleCompanySelection(company.id, selectedCompany)}
                        >
                          {company.name}
                        </Checkbox>
                      </Col>
                      <Col span={14}>
                        {selectedCompany && (
                          <Rate
                            character={({ index }) => {
                              return (
                                <Button
                                  className={`ant-btn-primary RatingButton${selectedCompany.weight === index + 1 ? ' focus' : ''}`}
                                >
                                  {index + 1}
                                </Button>
                              );
                            }}
                            onChange={(value) => handleWeightChange(company.id, value)}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                )
              })}
            </Row>
          </Col>
          <Col
            span={24}
            className='add-company inline-button-container'
          >
            <CustomButton
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShowAddCompanyModal(true)}
            >
              {t.others}
            </CustomButton>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <CustomButton
            type="primary"
            onClick={_startAnalysis}
            disabled={selectedCompanies.length > 0 ? false : true}
          >
            { t.start_benchmark }
          </CustomButton>
        </Row>

        <AddCompanyForm
          visible={showAddCompanyModal}
          setVisibility={setShowAddCompanyModal}
          onAddCompany={addCompany}
        />
      </div>
    );
  }
  if (benchmarkUploaded) {
    return (
      <div className="AnalysisBenchmark">
        <FormattedHTMLMessage
          id="benchmark_description"
          defaultMessage={ t.benchmark_description }
          tagName="h4"
        />
        <Row type="flex" justify="end">
          <Col className='benchmark-actions'>
            <Link
              to='active_listening'
              className="CustomButton ant-btn-primary"
            >
              {t.next}
            </Link>
          </Col>
        </Row>
        <CustomTable
          columns={benchmarkColumns}
          dataSource={analysisSusIssues}
          childrenColumnName='not-children'
          rowKey='id'
        />
        <InformationModal
          visible={selectedIssueComments ? true : false}
          title={t.comments}
          message={selectedIssueComments}
          onClose={() => setSelectedIssueComments()}
        />
      </div>
    )
  }
  return (
    <div className="AnalysisBenchmark">
      <Row
        className="AnalysisBenchmark-benchmark-update"
        type="flex"
        justify="center"
      >
        <Col span={18}>
          {error &&
          <Alert message={t[error]} type="error" />
          }
          <FormattedHTMLMessage
            id="analysis_processing_message"
            defaultMessage={ t.analysis_processing_message }
            tagName="h2"
          />
          {userProfile && userProfile.role === 'system' &&
          <Row
            className="AnalaysisBenchmark-button-row"
            type="flex"
            justify="center"
          >
            <Col span={8} className='inline-button-container'>
              <Dropdown
                className="downloadTemplate"
                overlay={
                  <Menu>
                    <Menu.Item key="csv">
                      <CustomButton type="primary">
                        <CSVLink data={benchmarkCsv} filename={`analysis_${analysis.id}_benchmark_template.csv`}>
                          {t.download_template_csv}
                        </CSVLink>
                      </CustomButton>
                    </Menu.Item>
                    <Menu.Item key="xlsx">
                      <CustomButton
                        type="primary"
                        onClick={onDownload}
                      >
                        {t.download_template_xlsx}
                      </CustomButton>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button>
                  {t.download_template} <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
            <Col span={8} className='inline-button-container'>
              <>
                <CustomButton type="primary">
                  <label htmlFor="benchmark_csv">{t.upload_benchmark}</label>
                </CustomButton>
                <input id="benchmark_csv" type="file" accept=".csv,.xls,.xlsx" onChange={onUploadBenchmarkCsv}/>
              </>
            </Col>
          </Row>
          }
        </Col>
      </Row>
    </div>
  );
}


export default injectIntl(AnalysisBenchmark);
