import React, { useCallback, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import Form from './Form'

import {
  Row,
  Col,
} from 'antd';

import {
} from '../../actions/api';

import './style.less';
import EqualityPlanDocumentManager from 'components/EqualityPlanDocumentManager';


const EqualityPlanCommittee = ({
  intl,
  history,
  stage = 'committee',
  plan,
  updatePlan,
  documents,
  fetchingDocuments,
  onFetchDocuments,
  onDownloadDocument,
  downloadingDocument,
  updatePlanProgress,
}) => {
  const t = intl.messages;

  useEffect(
    () => {
      if (
        plan.progress.includes(stage)
        || plan.progress.includes(`${stage}_form`)
      ) {
        history.push(`/equality-plan/${plan.id}/${stage === 'committee' ? 'committee' : 'committee-followup'}/document-manager`)
      }
    },
    [plan, history, stage]
  );

  const onUpdatePlanProgress = useCallback(
    () => {
      updatePlanProgress(stage);
    },
    [updatePlanProgress, stage]
  );

  const fetchDocuments = useCallback(
    () => {
      onFetchDocuments(stage);
    },
    [onFetchDocuments, stage]
  );

  const downloadDocument = useCallback(
    (format) => {
      onDownloadDocument(stage, format);
    },
    [onDownloadDocument, stage]
  );

  const onUpdatePlan = useCallback(
    (data) => {
      updatePlan({
        ...data,
        progress: [...new Set([...plan.progress, `${stage}_form`])],
      });
    },
    [plan, updatePlan, stage]
  );

  return (
    <>
      <Row>
        <Col span={24}>
          <Switch>
            <Route path={`/equality-plan/${plan.id}/${stage === 'committee' ? 'committee' : 'committee-followup'}/document-manager`}>
              <EqualityPlanDocumentManager
                planId={plan.id}
                title={t[`equality_${stage}`]}
                helpText={t[`equality_${stage}_help_text`]}
                description={t[`equality_${stage}_desc`]}
                documents={documents}
                stage={stage}
                onFetchDocuments={fetchDocuments}
                fetchingDocuments={fetchingDocuments}
                onDownloadDocument={downloadDocument}
                downloadingDocument={downloadingDocument}
                formURL={`/equality-plan/${plan.id}/${stage === 'committee' ? 'committee' : 'committee-followup'}`}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route>
              <Form
                stage={stage}
                plan={plan}
                updatePlan={onUpdatePlan} />
            </Route>
          </Switch>
        </Col>
      </Row>
    </>
  )
};

export default withRouter(injectIntl(EqualityPlanCommittee));
