import React from 'react'
import './style.less'

const CustomToggle = ({
  options = [],
  disabled,
  checked,
  onChange,
}) => {
  const isSet = !(typeof checked === 'undefined' || checked === null);

  return (
    <section className={`CustomToggle ${ disabled ? 'CustomToggle-disabled' : ''}`}>
      <section
        className={`CustomToggle__option CustomToggle__option-left ${isSet && checked ? 'CustomToggle__option-left-selected' : ''}`}
        onClick={() => !disabled && onChange(true)}
      >
        <span className='CustomToggle__text'>{options[0]}</span>
      </section>
      <section
        className={`CustomToggle__option CustomToggle__option-right ${isSet && !checked ? 'CustomToggle__option-right-selected' : ''}`}
        onClick={() => !disabled && onChange(false)}
      >
        <span className='CustomToggle__text'>{options[1]}</span>
      </section>
    </section>
  );
}

export default CustomToggle
