import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Row,
  Col,
  Tooltip
} from 'antd';
import CustomSelect from 'components/CustomSelect';
import DataTargetIcon from 'components/DataTargets/DataTargetIcon';
import AplanetIcon from 'components/AplanetIcon';
/*
 * Get array of values for table from KPI value
 */
const getColumnValues = (value = {}) => {
  return typeof value !== 'object' || value === null || value._address
    ? [ value ]
    : Object.values(value).map(getColumnValues).flat();
};

const ColumnTitle = injectIntl(({
  intl,
  schemaLabels,
  availableUnits = [],
  metricSlug,
  kpiValue = {},
  text,
  name,
  edit,
  schema,
  isTarget,
  targetEditTooltip
}) => {
  const t = intl.messages;
  const columnValues = getColumnValues(kpiValue);

  const {
    metrics: taxonomyMetrics,
  } = useSelector(state => state.taxonomies);

  const unit = Object.values(kpiValue)?.find(
    _value => _value[name]?.__total_unit
  )?.[name].__total_unit
  || availableUnits?.find(unit => unit.is_base)?.slug;

  const onSelectUnit = edit
    ? (unit) => {
      columnValues
        .filter(value => value?._address?.slice(-1)[0] === name)
        .map(value => {
          return edit(
            value._address,
            {
              ...value,
              unit,
              __total_unit: unit,
            }
          );
        });
    }
    : null

  const metric = useMemo(
    () => {
      if (availableUnits?.length > 1 && metricSlug) {
        const metrics = (
          taxonomyMetrics && taxonomyMetrics.length > 0
          ? taxonomyMetrics
          : []
        ).map(({ metrics }) => metrics || []).reduce((acc, arr) => acc.concat(arr), []);

        return metrics.find(
          metric => metric.slug === metricSlug
        );
      }
    },
    [
      availableUnits,
      metricSlug,
      taxonomyMetrics,
    ]
  );

  return (
      <Row
        type="flex"
        align="middle"
        justify={ (isTarget && schema.type !== 'qualitative') || targetEditTooltip ? 'space-between' : 'start' }
        // gutter={[5, 5]}
      >
        <Col>
          {text}
        </Col>
        <Col>
          {
            availableUnits?.length > 1 && edit
            ? <CustomSelect
                title={t.createcustomkpi_select_unit}
                options={availableUnits}
                onSelect={onSelectUnit}
                className="KpiDetail__answer-quantitative-table-unit-dropdown"
                selected={unit}
                renderOption={({ name, symbol }) => (
                  symbol
                  ? `${name} (${symbol})`
                  : name
                )}
              />
            : availableUnits?.length === 1 && availableUnits?.[0].symbol
            ? <span>({ availableUnits?.[0].symbol })</span>
            : (availableUnits?.length > 1 && metricSlug)
            ? <span>({ metric?.name || metricSlug })</span>
            : null
          }
        </Col>

        {
          isTarget && schema.type !== 'qualitative' && 
          <Col>
            <DataTargetIcon />
          </Col>
        }
        {
          targetEditTooltip &&
          <Col>
            <Tooltip title={t.modaltarget_table_tooltip}>
              <i>
                <AplanetIcon name="Information" />
              </i>
            </Tooltip>
          </Col>
        }

      </Row>
  );
});

export default injectIntl(ColumnTitle);
