import React from 'react';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Tooltip } from 'antd';
import './style.less';

const MANUAL_SOURCE = 'manual';
const SOURCE_SET = new Set([
  'calculated',
  'aggregated',
  'parent',
  'children',
]);

const KpiSourceIcon = ({
  className = '',
  source = MANUAL_SOURCE,
  gray = false,
  intl,
}) => {
  const t = intl.messages;
  return (
    <span
      className={`${className} KpiSourceIcon`}
    >
      {
        !SOURCE_SET.has(source) || source === MANUAL_SOURCE
        ? null
        : (
          <Tooltip title={t[`kpi_source_${source}`] || source}>
            {
              (() => {
                switch(source) {
                  case 'calculated':
                    return (
                      <FontAwesomeIcon
                        className={
                          gray ? 'KpiSourceIcon__gray' : 'KpiSourceIcon__yellow'
                        }
                        icon={'calculator'}
                      />
                    );
                  case 'aggregated':
                    return (
                      <FontAwesomeIcon
                        className={
                          gray ? 'KpiSourceIcon__gray' : 'KpiSourceIcon__yellow'
                        }
                        icon={'sitemap'}
                      />
                    );
                  case 'parent':
                    return (
                      <FontAwesomeIcon
                        style={{ transform: 'rotate(180deg)' }}
                        className={
                          gray ? 'KpiSourceIcon__gray' : 'KpiSourceIcon__yellow'
                        }
                        icon={'sitemap'}
                      />
                    );
                  case 'children':
                    return (
                      <FontAwesomeIcon
                        className={
                          gray ? 'KpiSourceIcon__gray' : 'KpiSourceIcon__yellow'
                        }
                        icon={'list'}
                      />
                    );
                  default:
                    return null;
                }
              })()
            }
          </Tooltip>
        )
      }
    </span>
  );
}

export default injectIntl(KpiSourceIcon);
