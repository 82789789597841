import qs from 'query-string';

const QUERY_SLUG = 'toplevelorg';
const STATE_SLUG = 'organization_slug';

const patchStateWithTopLevelOrg = (state, location) => {
  location = location || window.location;

  const search = qs.parse(location?.search || '') || {};

  return {
    ...state,
    ...(
      search[QUERY_SLUG]
      ? { [STATE_SLUG]: search[QUERY_SLUG] }
      : {}
    ),
  };
};

export {
  patchStateWithTopLevelOrg,
};

