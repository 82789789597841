import React, { useMemo, useCallback } from 'react';
import {useState} from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './style.less';

import A from 'components/A';
import { useFeatureList } from 'components/FeatureSwitch';
import useOrganizations from 'utils/useOrganizations';

import { ReactComponent as DashboardLogo } from './images/icon-stats.svg';
import { ReactComponent as DataManagementLogo } from './images/icon-data.svg';
import { ReactComponent as GroupsOfInterestLogo } from './images/icon-goi.svg';
import { ReactComponent as MaterialityLogo } from './images/icon-materiality.svg';
import { ReactComponent as EqualityLogo } from './images/icon-equality.svg';
import { ReactComponent as ReportLogo } from './images/icon-reports.svg';
import { ReactComponent as ContactUsLogo } from './images/icon-contact-us.svg';

import AplanetIcon from 'components/AplanetIcon';

import DocDrawer from 'components/DocDrawer';
import { useEventTracking } from 'hooks/useEventTracking';

import {
//  Drawer,
  Menu,
  Modal,
} from 'antd';

const Nav = ({
  intl,
  location
}) => {
  const path = location.pathname;
  const {
    features: featureList,
    upselling: upsellingList,
  } = useFeatureList();
  const {
    organization,
    suborganization,
  } = useOrganizations();

  const [ modalActive, setModalActive ] = useState(false)
  const [ docDrawer, setDocDrawerActive ] = useState(false)
  const eventTracking = useEventTracking();

  let selected = [path.split('/')[1]];
  if(selected[0] === 'kpi') {
    selected = ['data']
  }

  const contactEmail = useMemo(
    () => {
      return organization?.general_config?.contact_email
        || `${ intl.formatMessage({ id: "contact_email_address"}) }@aplanet.org`;
    },
    [organization, intl]
  );

  const isRestrictedAdmin = useMemo(() => (
    featureList.has('restricted_admins') && !suborganization?.permissions?.can_affect_all_kpis
  ), [
    featureList,
    suborganization,
  ]);

  const {
    data: profile
  } = useSelector(state => state.profile);

  const choosePageOrigin = () => {
    const lang = profile?.language || profile?.user_language || 'en-US';
    let pageOrigin = '';
    switch(lang){
      case 'es-ES':
        pageOrigin = '7eefdff9c38d4ceb86d4daf2fc0eca93';
        break;
      case 'ca-ES':
        pageOrigin = '7eefdff9c38d4ceb86d4daf2fc0eca93'; //ES
        break;
      case 'it-IT':
        pageOrigin = 'ea8eb5a4ea42446badfbb91d6f0a0e07';
      break;
      case 'pt-PT':
        pageOrigin = 'de55642a6a13456f9af56ee956a4168a';
        break;
      case 'pt-BR':
        pageOrigin = '9bd19c2b5b294d5084b879e9921ef0f5';
        break;
      case 'fr-FR':
        pageOrigin = 'c60d27286d094c34bd97a3d2d6976984'; //EN
        break;      
      default:
        pageOrigin = 'c60d27286d094c34bd97a3d2d6976984';
        break;
    }
    return pageOrigin
  }

  const openDocDrawer = useCallback(()=>{
    setDocDrawerActive(true);
    eventTracking.capture('nav.documentation', {
      lang: profile.language || profile.user_language
    });
  },[
    eventTracking,
    profile
  ]);

  return (
    <div className="Nav">
      <Menu
        mode="vertical"
        theme="light"
        className="Nav-menu"
        selectable={false}
        selectedKeys={selected}
      >
        { isRestrictedAdmin || (!featureList.has('dashboard')) ? null :
          <Menu.Item
            id="dashboard_icon" 
            className='dashboard'
            key="dashboard"
            title={intl.formatMessage({ id: "nav_dashboard"})}
            icon={<DashboardLogo className="anticon" />}
          >
            <Link to="/dashboard">
              <span className="Nav-menulabel navLabel">{ intl.formatMessage({ id: "nav_dashboard"}) }</span>
            </Link>
          </Menu.Item>
        }
        <Menu.Item
          className='data'
          key="data"
          title={intl.formatMessage({ id: "nav_data_management"})}
          icon={<DataManagementLogo className="anticon" />}
        >
          <Link to="/data">
            <span className="Nav-menulabel navLabel">{ intl.formatMessage({ id: "nav_data_management"}) }</span>
          </Link>
        </Menu.Item>
        { isRestrictedAdmin || (!featureList.has('groups_of_interest') && !upsellingList.has('groups_of_interest')) ? null :
          !featureList.has('groups_of_interest') ?
          <Menu.Item
            key="groups-of-interest"
            title={intl.formatMessage({ id: "nav_groups_of_interest"})}
            onClick={() => setModalActive(true)}
            className='groupsOfInterest'
            icon={<GroupsOfInterestLogo className="anticon" />}
          >
            <span className="Nav-menulabel navLabel">{ intl.formatMessage({ id: "nav_groups_of_interest"}) }</span>
          </Menu.Item>
        :
          <Menu.Item
            key="groups-of-interest"
            className='groupsOfInterest'
            title={intl.formatMessage({ id: "nav_groups_of_interest"})}
            icon={<GroupsOfInterestLogo className="anticon" />}
          >
            <Link to="/groups-of-interest">
              <span className="Nav-menulabel navLabel">{ intl.formatMessage({ id: "nav_groups_of_interest"}) }</span>
            </Link>
          </Menu.Item>
        }
        { isRestrictedAdmin || (!featureList.has('materiality') && !upsellingList.has('materiality')) ? null :
          featureList.has('materiality') ?
          <Menu.Item
            key="materiality"
            className='materiality'
            title={intl.formatMessage({ id: "nav_materiality"})}
            icon={<MaterialityLogo className="anticon" />}
          >
            <Link to="/materiality">
              <span className="Nav-menulabel navLabel">{ intl.formatMessage({ id: "nav_materiality"}) }</span>
            </Link>
          </Menu.Item>
          :
          <Menu.Item
            key="materiality"
            onClick={() => setModalActive(true)}
            className='materiality'
            title={intl.formatMessage({ id: "nav_materiality"})}
            icon={<MaterialityLogo className="anticon" />}
          >
            <span className="Nav-menulabel navLabel">{ intl.formatMessage({ id: "nav_materiality"}) }</span>
          </Menu.Item>
        }
        { isRestrictedAdmin || (!featureList.has('equality') && !upsellingList.has('equality')) ? null :
          featureList.has('equality') ?
          <Menu.Item
            key="equality-plan"
            className='equality'
            title={intl.formatMessage({ id: "nav_equality_plan"})}
            icon={<EqualityLogo className="anticon" />}
          >
            <Link to="/equality-plan">
              <span className="Nav-menulabel navLabel">{ intl.formatMessage({ id: "nav_equality_plan"}) }</span>
            </Link>
          </Menu.Item>
          :
          <Menu.Item
            key="equality"
            onClick={() => setModalActive(true)}
            className='equality'
            title={intl.formatMessage({ id: "nav_equality_plan"})}
            icon={<EqualityLogo className="anticon" />}
          >
            <span className="Nav-menulabel navLabel">{ intl.formatMessage({ id: "nav_equality_plan"}) }</span>
          </Menu.Item>
        }
        {!isRestrictedAdmin && suborganization?.permissions?.can_report_kpi &&
          <Menu.Item
            className='report'
            key="report"
            title={intl.formatMessage({ id: "nav_reports"})}
            icon={<ReportLogo className="anticon" />}
          >
            <Link to="/report">
              <span className="Nav-menulabel navLabel">{ intl.formatMessage({ id: "nav_reports"}) }</span>
            </Link>
          </Menu.Item>
        }
        <Menu.Item
          key="documentation"
          id="info_documentation_icon"
          className="documentation"
          onClick={openDocDrawer}
          title={intl.formatMessage({ id: "nav_documentation"})}
          icon={<AplanetIcon name="Information"/>}
        >
        <span className="Nav-menulabel navLabel">{ intl.formatMessage({ id: "nav_documentation"}) }</span>
        </Menu.Item>

        <Menu.Item
          key="contact-us"
          title={intl.formatMessage({ id: "nav_contact_us"})}
          icon={<ContactUsLogo className="anticon" />}
        >
          <A href={`mailto:${ contactEmail }`}>
            <span className="navLabel">
              {intl.formatMessage({ id: "nav_contact_us"})}
            </span>
          </A>
        </Menu.Item>
      </Menu>
      <Modal
        visible={modalActive}
        centered
        title={intl.formatMessage({ id: "feature_not_enabled"})}
        onCancel={() => setModalActive(false)}
        footer={[]}
        className={'modalComingSoon'}
        width={'auto'}
      >
        <div>
          {
            <div>{ intl.formatMessage({ id: "feature_not_enabled_desc"}) } <A href={`mailto:${ contactEmail }`} > { contactEmail } </A> </div>
          }
        </div>
      </Modal>

      <DocDrawer 
        visible={docDrawer}
        pageOrigin={choosePageOrigin()}
        onCloseDocDrawer={()=>setDocDrawerActive(false)}
      />

    </div>
  );
};

export default injectIntl(withRouter(Nav));
