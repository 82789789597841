export const fullValidation = (t) => ({
  name,
  start_date,
  end_date,
}) => {
  let errors = {};
  if (!name) {
    errors.name = t.form_error_required;
  }
  if (!start_date && end_date) {
    errors.start_date = t.form_error_required;
  }
  if (!end_date && start_date) {
    errors.end_date = t.form_error_required;
  }

  return errors;
};