import React from 'react';
import { Provider } from 'react-redux';

import configureStore from './store';
import './App.less';

// Setup icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

import { isExternalPath } from 'utils/validation';
import MainApp from './MainApp';
import ExternalApp from './ExternalApp';

library.add(
  fas,
  fab,
  fal,
  fad,
);

// TODO: Split in two stores, one for app and another for external app
export const store = configureStore();

const App = () =>{ 
  
  return (
    <Provider store={store}>
      {isExternalPath(window?.location?.pathname)
        ? (<ExternalApp />)
        : (<MainApp />)
      }
    </Provider>
  )
};

export default App;
