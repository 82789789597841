import { combineReducers } from 'redux-loop';
import { intlReducer } from 'react-intl-redux';
import { connectRouter } from 'connected-react-router'
import { active_listening } from './active_listening';
import { active_listening_medium } from './active_listening_medium';
import { active_wait } from './active_wait';
import { analysis } from './analysis';
import { analysis_result } from './analysis_result';
import { analysis_subjects } from './analysis_subjects';
import { analysis_sus_issues } from './analysis_sus_issues';
import { api_requests } from './api_requests';
import { attachments } from './attachments';
import { auth } from './auth';
import { benchmark } from './benchmark';
import { category_detail } from './category_detail';
import { company } from './company';
import { dashboard_list } from './dashboard_list';
import { dashboard } from './dashboard';
import { data_bulk_upload } from './data_bulk_upload';
import { data_categories } from './data_categories';
import { data_categories_no_periods } from './data_categories_no_periods';
import { data_files } from './data_files';
import { data_history } from './data_history';
import { data_request } from './data_request';
import { data_sources_kpi_permissions } from './data_sources_kpi_permissions';
import { data_sources_kpi_config } from './data_sources_kpi_config';
import { equality_plan } from './equality_plan';
import { equality_plan_action } from './equality_plan_action';
import { equality_plan_document } from './equality_plan_document';
import { equality_plan_objective } from './equality_plan_objective';
import { equality_plan_measure } from './equality_plan_measure';
import { equality_plan_factor } from './equality_plan_factor';
import { equality_plan_department } from './equality_plan_department';
import { equality_plan_professional_group } from './equality_plan_professional_group';
import { equality_plan_position } from './equality_plan_position';
import { equality_plan_salary } from './equality_plan_salary';
import { kpi_excel_download } from './kpi_excel_download';
import { goi_member } from './goi_member';
import { goi_survey } from './goi_survey';
import { groups_of_interest } from './groups_of_interest';
import { kpi_detail } from './kpi_detail';
import { kpi_dependency } from './kpi_dependency';
import { kpi_files } from './kpi_files';
import { kpi_history } from './kpi_history';
import { kpi_permissions } from './kpi_permissions';
import { organization } from './organization';
import { organization_detail } from './organization_detail';
import { organization_tree } from './organization_tree';
import { profile } from './profile';
import { profile_edit_done } from './profile_edit_done';
import { profile_request_failed } from './profile_request_failed';
import { report_history } from './report_history';
import { reporting_structure } from './reporting_structure';
import { screen_filters } from './screen_filters';
import { standards_categories_kpis } from './standards_categories_kpis';
import { survey } from './survey';
import { survey_recipient } from './survey_recipient';
import { survey_responses } from './survey_responses';
import { survey_subjects } from './survey_subjects';
import { sus_issues } from './sus_issues';
import { table_custom_view } from './table_custom_view';
import { taxonomies } from './taxonomies';
import { third_party } from './third_party';
import { organization_kpi_member } from './organization_kpi_member';
import { error_notification } from 'reducers/error_notification';
import { kpi_variable } from 'reducers/kpi_variable';
import { kpi_organizations } from "./kpi_organizations";
import { app } from './app';
import { organization_archive } from './organization_archive';
import { documentation } from './documentation';
import { bulk_manage_kpis } from './bulk_manage_kpis';

import { notification } from './notification';

const reducer = (history) =>
  combineReducers({
    intl: intlReducer,
    active_listening,
    active_listening_medium,
    active_wait,
    analysis,
    analysis_result,
    analysis_subjects,
    analysis_sus_issues,
    api_requests,
    app,
    auth,
    attachments,
    benchmark,
    bulk_manage_kpis,
    category_detail,
    company,
    dashboard_list,
    dashboard,
    data_bulk_upload,
    data_categories,
    data_categories_no_periods,
    data_files,
    data_history,
    data_request,
    data_sources_kpi_permissions,
    data_sources_kpi_config,
    documentation,
    equality_plan,
    equality_plan_action,
    equality_plan_document,
    equality_plan_objective,
    equality_plan_measure,
    equality_plan_factor,
    equality_plan_department,
    equality_plan_professional_group,
    equality_plan_position,
    equality_plan_salary,
    error_notification,
    kpi_excel_download,
    goi_member,
    goi_survey,
    groups_of_interest,
    kpi_detail,
    kpi_dependency,
    kpi_files,
    kpi_history,
    kpi_permissions,
    organization,
    organization_archive,
    organization_detail,
    organization_kpi_member,
    organization_tree,
    profile,
    profile_edit_done,
    profile_request_failed,
    report_history,
    reporting_structure,
    screen_filters,
    standards_categories_kpis,
    router: connectRouter(history),
    survey,
    survey_recipient,
    survey_responses,
    survey_subjects,
    sus_issues,
    table_custom_view,
    taxonomies,
    third_party,
    kpi_variable,
    kpi_organizations,
    notification
  });

export default reducer;
