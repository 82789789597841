import { useMemo } from 'react';
import { findTree } from '../utils/tree';

export const useHasChildren = (suborganization, organizationTree) => {
  return useMemo(() => {
    const treeNode = findTree(
      [ organizationTree ],
      ({ slug }) => slug === suborganization.slug,
    );

    // TODO: MAYBE consider 'restricted' children as non-existing??
    return treeNode && treeNode.children && treeNode.children.length > 0;
  }, [suborganization, organizationTree]);
}
