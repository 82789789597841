import React, { useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';

import {
  Modal,
  List,
  InputNumber,
  Input,
} from 'antd';

import { formatDate, DATE_ONLY } from 'utils/date';
import './style.less';

const ActionProgressHeader = ({
  intl,
  visible,
  indicator,
  indicatorStatus,
  onClose,
  updateActionIndicator,
}) => {
  const t = intl.messages;
  const [currentValue, setCurrentValue] = useState(indicator.current_value);
  const [comment, setComment] = useState('');
  const { TextArea } = Input;

  const onOk = useCallback(
    () => {
      updateActionIndicator(indicator.id, currentValue, comment);
      setComment('');
      onClose();
    },
    [updateActionIndicator, indicator, currentValue, comment, onClose]
  );

  const onCancel = useCallback(
    () => {
      onClose();
      if (indicator.current_value !== currentValue) {
        setCurrentValue(indicator.current_value);
      }
    },
    [onClose, setCurrentValue, indicator, currentValue]
  );

  return (
    <Modal
      visible={visible}
      okText={t.update}
      cancelText={t.cancel}
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{
        disabled: indicatorStatus !== 'in_progress' || indicator.current_value === currentValue
      }}
    >
      <List
        size="small"
      >
        <List.Item>
          {`${t.value_current}: `} <InputNumber
            disabled={indicatorStatus !== 'in_progress'}
            value={currentValue}
            onChange={(value) => setCurrentValue(value)} />
        </List.Item>
        <List.Item>{`${t.value_target}: ${indicator.target_value}`}</List.Item>
        <List.Item>
          {`${t.measurement_end_date}: ${formatDate(indicator.end_date, DATE_ONLY, intl)}`}
        </List.Item>
        <List.Item>{`${t.value_initial}: ${indicator.initial_value}`}</List.Item>
        <List.Item>
          {`${t.measurement_start_date}: ${formatDate(indicator.start_date, DATE_ONLY, intl)}`}
        </List.Item>
        <List.Item>
          {`${t.comment}: `} <TextArea
            disabled={indicatorStatus !== 'in_progress'}
            value={comment}
            onChange={(e) => setComment(e.target.value)} />
        </List.Item>
      </List>
    </Modal>
  )
};

export default injectIntl(ActionProgressHeader);
