import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import A from 'components/A';
import CustomButton from 'components/CustomButton';
import { Checkbox } from 'antd';

import AplanetIcon from 'components/AplanetIcon';
import { useComponentVisible } from 'hooks/useComponentVisible';

import './style.less';

const TableCustomViewPanel = ({
  intl,
  isCustomViewSet,
  onCancelCustomView,
  onSetCustomView,
  customViewConfig,
  onChangeCustomView,
  customView,
  menuPosition = 'bottom',
}) => {

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible({
    onClickOutside: onSetCustomView,
    onEscPress: onCancelCustomView
  });

  const handleCancel = useCallback(() => {
    setIsComponentVisible(false); 
    onCancelCustomView();
  }, [onCancelCustomView, setIsComponentVisible]);

  const handleSet = useCallback(() => {
    setIsComponentVisible(false);
    onSetCustomView();
  }, [onSetCustomView, setIsComponentVisible]);

  const handleSelection = useCallback((optionSelected) => {
    const selectedCustomView = customView.includes(optionSelected)
      ? customView.filter(view => view !== optionSelected)
      : [optionSelected, ...customView];
    onChangeCustomView(selectedCustomView);
  }, [
    customView,
    onChangeCustomView
  ]);
 
  return (
    <div className="TableCustomViewPanel" ref={ref}>
      <button
        className={`TableCustomViewPanel__button ${isComponentVisible ? 'open-panel' : ''} ${isCustomViewSet ? 'is-set' : ''}`}
        onClick={!isComponentVisible ? () => setIsComponentVisible(true) : handleSet}
      >
        <AplanetIcon name='Column' size="18px" />
        <span>{intl.formatMessage({ id: "table_custom_view_panel_button_title"})}</span>
      </button>
      { isComponentVisible ? (
        <div className={`TableCustomViewPanel__panel ${menuPosition === 'top' ? 'menuTop' : ''}`} >
          <div className="TableCustomViewPanel__panel-wrapper">
            {customViewConfig.map(({ columnTitle, options }) => (
              <div key={columnTitle} className="TableCustomViewPanel__column">
                {columnTitle ? (
                  <>
                    <span className="TableCustomViewPanel__column-title">{columnTitle}</span>
                    <hr/>
                  </>
                ) : null}
                {options.map(({ value, label, title, disabled = false }) => (
                  <div
                    key={value}
                    title={title ? title : label}
                    className={`TableCustomViewPanel__option ${disabled ? 'is-disabled' : ''}`}
                    onClick={disabled ? null : () => handleSelection(value)}
                  >
                    <Checkbox
                      className="TableCustomViewPanel__checkbox"
                      onChange={() => handleSelection(value)}
                      checked={customView.includes(value)}
                      disabled={disabled}
                    />
                    { label }
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="TableCustomViewPanel__panel-buttons">
            <A
              className="TableCustomViewPanel__cancel-button"
              onClick={handleCancel}
            >
              {intl.formatMessage({ id: "table_custom_view_panel_cancel_button_title"})}
            </A>
            <CustomButton
              type="primary"
              className="TableCustomViewPanel__set-button"
              onClick={handleSet}
              icon={<AplanetIcon name='Eye open' size="18px" />}
            >
              {intl.formatMessage({ id: "table_custom_view_panel_view_button_title"})}
            </CustomButton>
          </div>
        </div>
      ) : null}
    </div>
  );
}

TableCustomViewPanel.propTypes = {
  isCustomViewSet: PropTypes.bool,
  onCancelCustomView: PropTypes.func.isRequired,
  onSetCustomView: PropTypes.func.isRequired,
  customViewConfig: PropTypes.arrayOf(PropTypes.shape({
    columnTitle: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      title: PropTypes.string,
    }))
  })).isRequired,
  onChangeCustomView: PropTypes.func.isRequired,
  customView: PropTypes.arrayOf(PropTypes.string).isRequired,
  menuPosition: PropTypes.string,
};

export default injectIntl(TableCustomViewPanel);
