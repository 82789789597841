import React from 'react';

const KpiAttachmentIcon = ({
  has_attachments,
}) => {
  return (
    !has_attachments
      ? <div style={{ width: '16px', display: 'inline-block' }}></div>
      : (
        <img src="/images/assets/icon-attachments.svg" alt="" />
      )
  );
};

export default KpiAttachmentIcon;
