import { findIndex } from "lodash";

export const DASHBOARD_GRID_LAYOUT_COLS = 4;
export const DASHBOARD_GRID_LAYOUT_ROW_HEIGHT = 300;
export const DASHBOARD_DEFAULT_SIZE_FOR_CARD = 'medium';

const sizeToXCols = {
    'small': 1,
    'medium': 2,
    'large': 3
}
  
const sizeToYCols = {
    'small': 1,
    'medium': 2,
    'large': 3
}

export const calculateGridLayoutFromTemplate = (template) => {

    let x = 0;
    let y = 0;
    let maxHperRow = 0;

    const foo = []
    template.forEach(({ id, size = DASHBOARD_DEFAULT_SIZE_FOR_CARD }, inx) => {

        const w = sizeToXCols[size];
        const h = sizeToYCols[size];

        if (inx !== 0){

            if ((x + w) > DASHBOARD_GRID_LAYOUT_COLS){
                y += maxHperRow; 
                x = 0;
                maxHperRow = 0;
            }

        }

        foo.push({ i: id, x, y, w, h});
        x += w;
        maxHperRow = Math.max(maxHperRow, h);

    });

    return foo;

}

export const sortGridLayoutByLoadLinearOrder = (gridLayout) => {

    // new order => 
    // 1) y: 0, x: 0
    // 2) y: 1, x: 0
    // 3) y: 1, x: 1
    // 4) y: 2, x: 0

    const orderedGridLayout = JSON.parse(JSON.stringify( gridLayout ));
    orderedGridLayout.sort((a, b)=> {
        if (a.y === b.y){
            if (a.x === b.x){
                return findIndex(orderedGridLayout, (item) => item.i === a.i) 
                        < findIndex(orderedGridLayout, (item) => item.i === b.i) ? -1 : 1;
            }
            return a.x < b.x ? -1 : 1;
        }
        return a.y < b.y ? -1 : 1;
    });

    return orderedGridLayout;

}