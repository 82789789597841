import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import { fromDb } from 'utils/date';
import EqualityPlanDocumentManager from 'components/EqualityPlanDocumentManager';

import {
  Row,
  Col,
} from 'antd';

import './style.less';

const planStage = 'plan_documents';

const EqualityPlanDocument = ({
  intl,
  plan,
  documents,
  fetchingDocuments,
  onFetchDocuments,
  onDownloadDocument,
  downloadingDocument,
  updatePlanProgress,
}) => {
  const t = intl.messages;

  const onUpdatePlanProgress = useCallback(
    () => {
      updatePlanProgress(planStage);
    },
    [updatePlanProgress]
  );

  const fetchDocuments = useCallback(
    () => {
      onFetchDocuments('all');
    },
    [onFetchDocuments]
  );

  const downloadDocument = useCallback(
    (format) => {
      onDownloadDocument(planStage, format);
    },
    [onDownloadDocument]
  );

  const planDocuments = useMemo(
    () => {
      return documents.filter(document => document.step === planStage);
    },
    [documents]
  );

  const finalDocumentSet = useMemo(
    () => {
      let docs = {};
      const sortedDocuments = documents
        .filter(document => document.type === 'general')
        .sort(
          (a, b) => fromDb(a.created_at) > fromDb(b.created_at) ? -1 : 1
        )
        .sort(
          (a, b) => a.step > b.step ? -1 : 1
        );
      ['org_commitment', 'committee', 'diagnosis', 'plan_documents'].forEach(
        step => {
          const doc = sortedDocuments.find(document => document.step === step);

          if (doc) {
            docs[step] = doc;
          }
        }
      );
      return docs;
    },
    [documents]
  );

  return (
    <Row>
      <Col span={24}>
        <EqualityPlanDocumentManager
          title={t.equality_plan_document}
          planId={plan.id}
          description={t.equality_plan_document_desc}
          documents={planDocuments}
          stage={planStage}
          onFetchDocuments={fetchDocuments}
          fetchingDocuments={fetchingDocuments}
          onDownloadDocument={downloadDocument}
          downloadingDocument={downloadingDocument}
          documentSet={finalDocumentSet}
          updatePlanProgress={onUpdatePlanProgress}
        />
      </Col>
    </Row>
  )
};

export default injectIntl(EqualityPlanDocument);
