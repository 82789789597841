import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Menu, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import AplanetIcon from 'components/AplanetIcon';

const MENU_ITEMS = {
  requests_list: 'requests-list'
};

const Sidemenu = ({
  onClickMenuItem
}) => {
  const intl = useIntl();

  const menuItems = useMemo(() => ([{
    key: MENU_ITEMS.requests_list,
    className: 'ThirdPartyRequestsSidemenu__requests-list',
    title: intl.formatMessage({ id: "third_party_requests_list"}),
    icon: (<AplanetIcon name="Data management" faStyle='fad'/>),
    label: (
      <Tooltip title={intl.formatMessage({ id: "third_party_requests_list"})} overlayClassName="ThirdPartyRequestsTooltip">
        <span className="ThirdPartyRequestsSidemenu__menu-item">{intl.formatMessage({ id: "third_party_requests_list"})}</span>
      </Tooltip>
    )
  }]), [intl]);

  return(
    <div className="Nav">
      <Menu
        mode="vertical"
        theme="light"
        className="ThirdPartyRequestsSidemenu"
        selectedKeys={[MENU_ITEMS.requests_list]}
        onClick={onClickMenuItem}
        selectable={false}
        items={menuItems}
      />
    </div>
  );
}

Sidemenu.propTypes = {
  onClickMenuItem: PropTypes.func,
};

export default Sidemenu;
