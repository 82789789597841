import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Form,
  InputNumber,
  Select,
  Modal,
} from 'antd';

import { actionByAreaValidation as validation } from './validation';
import useForm from 'utils/useForm';

import './style.less';


const AREA_OPTIONS = [
  'conciliation',
  'non_sexist_communication',
  'occupational_health',
  'organizational_culture_and_work_environment',
  'other',
  'professional_classification',
  'promotion',
  'remuneration',
  'representation',
  'selection',
  'social_policy',
  'sexism_and_sexual_harassment',
  'training',
  'women_risk_of_exclusion',
  'working_conditions',
];

const ActionsByAreaForm = ({
  intl,
  record,
  index,
  onSave,
  onCancel,
  visible,
}) => {
  const t = intl.messages;
  const defaults = record;
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    const {
      area, actions_old, actions_new
    } = values;
    onSave(
      'results', 'actions_by_area', index, {
        area, actions_old, actions_new
      }
    );
  };

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  return (
    <Modal
      title={t.equality_evaluation_of_results_actions_by_area_add}
      visible={visible}
      onOk={handleSubmit}
      onCancel={() => onCancel('results', 'actions_by_area', index)}
      okText={t.save}
      cancelText={t.cancel}
    >
      <Row>
        <Col span={24} className="form-input-wrapper">
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                label={t.equality_evaluation_of_results_area}
                hasFeedback
                validateStatus={ showError('area') ? 'error' : '' }
                help={ showError('area') }
                colon={false}
                required
              >
                <Select
                  value={getValue('area')}
                  onChange={value => handleChange('area')(value)}
                >
                  {AREA_OPTIONS.sort((a, _) => t['equality_plan_area_' + a]).map(value => (
                    <Select.Option
                      name='area'
                      key={value}
                      value={value}
                    >
                      {t['equality_plan_area_' + value]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('actions_old') ? 'error' : '' }
                help={ showError('actions_old') }
                label={ t.equality_evaluation_of_results_actions_old }
                colon={false}
                required
              >
                <InputNumber
                  name="actions_old"
                  value={ getValue('actions_old') }
                  onChange={(value) => handleChange('actions_old')(value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('actions_new') ? 'error' : '' }
                help={ showError('actions_new') }
                label={ t.equality_evaluation_of_results_actions_new }
                colon={false}
                required
              >
                <InputNumber
                  name="actions_new"
                  value={ getValue('actions_new') }
                  onChange={(value) => handleChange('actions_new')(value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
};

export default injectIntl(ActionsByAreaForm);
