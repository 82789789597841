import { orderBy } from 'lodash'
import { flattenTree } from './tree';

// This is based on https://stackoverflow.com/a/41145788
// Which is a two-pass O(n) solution which abuses JS references a bit

export const reportingStructureToTree = (
  originalFlatData = [],
  key = 'uuid',
  parentsKey = 'parent',
  childrenKey = 'children',
  explicitKpis = false, // Explicitly include KPIs array in the tree
) => {
  let roots = [];
  let nodesByParentKey = new Map();

  // Clone to the first level and add children
  let flatData = originalFlatData.map(data => ({ ...data, [childrenKey]: [] }));

  flatData.forEach(data => {
    if (!data[parentsKey]) {
      roots.push(data);
      return;
    }
    else {
      const parent = data[parentsKey]
      if (nodesByParentKey.has(parent.uuid)) {
        nodesByParentKey.get(parent.uuid).push(data);
      } else {
        nodesByParentKey.set(parent.uuid, [data]);
      }
    }
  });

  function processChildren(parent, children) {
    return orderBy(children.reduce((acc, child) => {
      return [
        ...acc,
        child.category ? {
          category: child.category,
          position: child.position,
          parent_uuid: child.parent?.uuid,
          children: processChildren(
            child,
            [
              ...(explicitKpis ? [] : child.kpis),
              ...nodesByParentKey.get(child.category.uuid) || []
            ]
          ),
          ...(explicitKpis ? { kpis: child.kpis.sort((a, b) => a.position - b.position), ...child.category } : {}),
        } : {
          ...child,
          parent_uuid: parent?.uuid,
        }
    ]
    }, []), [ 'category', c => (c.category && c.category.position) || c.position], ['desc', 'asc'])
  }

  return roots.reduce((acc, node) => {
    return [
      ...acc,
      {
        category: node.category,
        position: node.position,
        parent_uuid: null,
        children: processChildren(
          node,
          [
            ...(explicitKpis ? [] : node.kpis.sort((a, b) => a.position - b.position)),
            ...nodesByParentKey.get(node.category?.uuid) || []
          ]
        ),
        ...(explicitKpis ? { kpis: node.kpis.sort((a, b) => a.position - b.position), ...node.category } : {}),
      }]
  }, []).sort((a, b) => a.position - b.position);
};

export function treeToReportingStructure(tree) {
  return tree.reduce((acc, node) => {
    if (node.isLeaf) {
      return []
    }

    const children = node.children.map((node, i) => ({ ...node, position: i }))
    return [
      ...acc,
      node.parents ? {
        slug: node.slug,
        children: children.filter(c => c.isLeaf).map(c => ({ slug: c.slug, position: c.position })),
        parents: node.parents
      } : {
        slug: node.slug,
        children: children.filter(c => c.isLeaf).map(c => ({ slug: c.slug, position: c.position })),
        position: acc.length,
        parents: []
      },
      ...treeToReportingStructure(children.filter(c => !c.isLeaf)
        .map(c => (
          {
            ...c,
            parents: [{ slug: node.slug, position: c.position }]
          }))
      )
    ]
  }, [])
}

export const haveChildrenTextInside = ({ children = [], category }, text, expandedNodes = []) => {
  const descentHasText = children.map(child => {
    if (child?.category) {
      return haveChildrenTextInside(child, text, expandedNodes) || child?.category.name?.toLowerCase()?.includes(text) || child?.category?.code?.toLowerCase()?.includes(text);
    }
    return child?.name?.toLowerCase()?.includes(text) || child?.standard_info?.some(standard => standard?.code?.toLowerCase()?.includes(text));
  })
  const descentHasSomeText = descentHasText.some(bool => bool)
  if (descentHasSomeText && !expandedNodes?.includes(category.uuid)) expandedNodes.push(category.uuid);
  return descentHasSomeText
};

export const searchInReportingStructureTree = (categories = [], text, expandedNodes = []) => {
  return categories
    .filter(
      category => {
        const childrenHaveTextInside = haveChildrenTextInside(category, text, expandedNodes);
        const categoryHasText = category?.category.name?.toLowerCase()?.includes(text) || category?.category?.code?.toLowerCase()?.includes(text);
        return categoryHasText || childrenHaveTextInside;
      })
    .map(category => {
      const categoryHasText = category?.category.name?.toLowerCase()?.includes(text) || category?.category?.code?.toLowerCase()?.includes(text);
      if (categoryHasText) {
        return category;
      }
      return {
        ...category,
        children: category.children
          ? [
            ...searchInReportingStructureTree(category.children.filter(c => !!c.category), text, expandedNodes),
            ...category.children.filter(c => !c.category).filter(kpi => kpi?.name?.toLowerCase().includes(text) || kpi.standard_info.some(standard => standard?.code?.toLowerCase().includes(text)))
          ] : []
      };
    });
};

export const filterReportingStructureTree = (categories = [], key, values) => {
  return categories
    .filter(
      category => {
        return category.children.filter(c => !(c.category || c.category === null)).some(kpi => kpi[key].some(kpiValue => key === 'standard_info' ? ((values.includes('custom') && kpi.is_custom) || values.includes(kpiValue.standard)) : values.includes(kpiValue))
          )
          || filterReportingStructureTree(category.children.filter(c => !!c.category), key, values).length > 0;
      }
    ).map(category => {
      return {
        ...category,
        children: category.children
          ? [
            ...filterReportingStructureTree(category.children.filter(c => !!c.category), key, values),
            ...category.children
                .filter(c => !c.category)
                .filter(kpi => kpi[key].some(kpiValue => 
                                              (
                                                key === 'standard_info' && 
                                                (values.includes(kpiValue.standard) || (values.includes('custom') && kpi.is_custom))
                                              )
                                              || values.includes(kpiValue) )) 
          ] : undefined,
      };
    });
};

export const appliesReportingStructureTree = (categories = [], showNotApplies) => {
  if (!showNotApplies) {
    return categories
      .filter(category => {
        return category.children.length === 0 || !category.children.filter(c => !c.category).every(kpi => !kpi.applies)
          || appliesReportingStructureTree(category.children.filter(c => !!c.category), showNotApplies).length > 0;
      })
      .map(category => {
        return {
          ...category,
          children: category.children
            ? [
              ...category.children.filter(c => !c.category).filter(kpi => kpi.applies === true),
              ...appliesReportingStructureTree(category.children.filter(c => !!c.category), showNotApplies),
            ] : undefined,
        };
      });
  }
  else return categories
};

const childrenField = 'children';
export const getFlattenKpis = (tree) => (kpis = []) => {
  if (!tree?.length) {
    return kpis;
  }
  return [...(tree || []).map(child => {
    return getFlattenKpis(child[childrenField])(child?.category ? kpis : [child, ...kpis]);
  })].reduce((a, b) => a.concat(b), []);
};

export const getRSAncestorsCategoriesOfCategory = (reportingStructure, categoryUuid, ancestorsCategories = []) => {
  const parent = reportingStructure?.find(section => section.uuid === categoryUuid)?.parent;
  if (parent) {
    ancestorsCategories.push(parent);
    return getRSAncestorsCategoriesOfCategory(reportingStructure, parent.uuid, ancestorsCategories);
  }
  return ancestorsCategories;
};

export const getRSAncestorsCategoriesOfKpi = (reportingStructure, kpiUuid, ancestorsCategories = []) => {
  const parentCategory = reportingStructure?.find(section => section.kpis.find(kpi => kpi.uuid === kpiUuid))?.category;
  if (parentCategory) {
    ancestorsCategories.push(parentCategory);
    return getRSAncestorsCategoriesOfCategory(reportingStructure, parentCategory.uuid, ancestorsCategories);
  }
  return ancestorsCategories;
};

export const isFirstIndicator = (reportingStructure, kpiUuid) => {
  return (reportingStructure || []).some(({ kpis }) => {
    const sortedKpis = kpis.sort((a, b) => a.position - b.position);
    const kpiFoundIndex = sortedKpis.findIndex(kpi => kpi?.uuid === kpiUuid);
    return kpiFoundIndex !== -1 && kpiFoundIndex === 0;
  });
};

export const isLastIndicator = (reportingStructure, kpiUuid) => {
  return (reportingStructure || []).some(({ kpis }) => {
    const sortedKpis = kpis.sort((a, b) => a.position - b.position);
    const kpiFoundIndex = sortedKpis.findIndex(kpi => kpi?.uuid === kpiUuid);
    return kpiFoundIndex !== -1 && kpiFoundIndex === (kpis.length - 1);
  });
};

export const isNextIndicator = (reportingStructure, kpiUuid, maybeNextKpiUuid) => {
  return (reportingStructure || []).some(({ kpis }) => {
    const sortedKpis = kpis.sort((a, b) => a.position - b.position);
    const kpiIndex = sortedKpis.findIndex(({uuid}) => uuid === kpiUuid);
    const maybeNextKpiIndex = sortedKpis.findIndex(({uuid}) => uuid === maybeNextKpiUuid);
    return kpiIndex !== -1 && maybeNextKpiIndex !== -1 && kpiIndex === maybeNextKpiIndex - 1;
  });
};

export const getSortedChildrenCategories = (reportingStructure, parentCategoryUuid) => {
  let childrenCategories = [];
  if (parentCategoryUuid) {
    childrenCategories = reportingStructure?.filter(section => section.parent?.uuid === parentCategoryUuid);
  } else {
    childrenCategories = reportingStructure?.filter(section => !section.parent);
  }
  return childrenCategories.sort((a, b) => a.position - b.position);
};

export const isFirstCategory = (reportingStructure, categoryUuid) => {
  const parent = reportingStructure?.find(section => section.uuid === categoryUuid)?.parent;
  const sortedChildrenCategories = getSortedChildrenCategories(reportingStructure, parent?.uuid);
  const categoryFoundIndex = sortedChildrenCategories.findIndex(({uuid}) => uuid === categoryUuid);
  return categoryFoundIndex === 0;
};

export const isNextCategory = (reportingStructure, categoryUuid, maybeNextCategoryUuid) => {
  const parentCategory = reportingStructure?.find(section => section.uuid === categoryUuid)?.parent;
  const parentMaybeNextCategory = reportingStructure?.find(section => section.uuid === maybeNextCategoryUuid)?.parent;
  if ((!parentCategory && !parentMaybeNextCategory) || (parentCategory?.uuid === parentMaybeNextCategory?.uuid)) {
    const sortedChildrenCategories = getSortedChildrenCategories(reportingStructure, parentCategory?.uuid);
    const categoryIndex = sortedChildrenCategories.findIndex(({uuid}) => uuid === categoryUuid);
    const maybeNextCategoryIndex = sortedChildrenCategories.findIndex(({uuid}) => uuid === maybeNextCategoryUuid);
    return categoryIndex !== -1 && maybeNextCategoryIndex !== -1 && categoryIndex === maybeNextCategoryIndex - 1;
  }
  return false;
};

export const isAlreadyChild = (reportingStructure, parent_uuid, node) => {
  const {
    uuid,
    slug,
  } = node;

  return (reportingStructure || []).some(({ category, kpis, parent }) => {
    return (category?.slug === slug && category?.uuid !== uuid && parent?.uuid === parent_uuid)
      || (category?.uuid === parent_uuid && kpis?.some(kpi => kpi?.slug === slug && kpi?.uuid !== uuid))
  });
};

export const getSortedIndicatorsList = (indicators) => {
  return indicators.sort((a, b) => a.position - b.position);
}

export const getSortedIndicatorsFromCategoryAndDescendants = (category) => {
  return Object.values(flattenTree(category, 'uuid'))
    .map(({ kpis }) => getSortedIndicatorsList(kpis) || [])
    .reduce((arr, el) => arr.concat(el), []);
}