import React, { useCallback, useMemo } from 'react';
import { formatMessage } from '../../intl';
import { some } from 'lodash'
import CustomModalType from 'components/CustomModalType';
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Button } from 'antd';

const { Panel } = Collapse;

const DeleteModal = ({slug, reporting_structure, name, showModal, setShowModal, handleKpiDeleteFromFolder, handleKpiDeleteFromPlatform}) => {

  const categoriesWithIndicator = useMemo(() => reporting_structure.filter(cat => some(cat.kpis, ['slug', slug])), [reporting_structure, slug])

  const RepeatedIndicatorContent = useCallback(() => {
    if(categoriesWithIndicator.length > 1) {
      return (
        <>
          <span>{formatMessage('delete_modal_repeated_indicator')}</span>
          <br/>
          <Collapse defaultActiveKey={['1']} ghost style={{display: "inline-block"}} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
            <Panel header={formatMessage('delete_modal_category_list')} key="1">
              {categoriesWithIndicator.map(({category}) => (
                <>
                  <span>
                    <b className='warning'>
                    {category.name} 
                    </b>
                    {" > "} 
                    {name}
                  </span>
                  <br/>
                </>
              ))}
            </Panel>
          </Collapse>
          <br/>
        </>
        
      )
    }
    return ""
  },
    [categoriesWithIndicator, name]
  )

  const DeleteModalContent = useCallback(() => (
    <div className='DeleteModal'>
      <span>{formatMessage('delete_modal_delete_indicator_message')} </span>
      <b className='warning'>{name}</b>
      <br/>
      <RepeatedIndicatorContent />
      <div className='wrapper'>
        <span>{formatMessage('delete_modal_delete_indicator_message_1', { categoriesWithIndicator: categoriesWithIndicator.length})} </span>
        <b className='question'>{formatMessage('delete_modal_delete_indicator_confirm_message')}</b>
      </div>
    </div>
  ),
    [name, categoriesWithIndicator]
  )

  return (
    <CustomModalType 
      showModal={showModal} 
      type="warning" 
      iconType="fad"
      title={formatMessage('delete_modal_indicator_title')}
      icon="Alert"
      content={<DeleteModalContent/>}
      footer={(
        <div className="CustomModalType__footer">
          <div className={`CustomModalType__buttons warning`}>
              <Button onClick={(() => setShowModal(false))}>
                {formatMessage('cancel')}
              </Button>
              {
                categoriesWithIndicator.length > 1 ? (
                  <Button onClick={() => handleKpiDeleteFromFolder()}>
                    {formatMessage('delete_modal_delete_from_folder')}
                  </Button>
                )
                : ""
              }
              <Button type="primary" onClick={() => handleKpiDeleteFromPlatform()}>
                {formatMessage('delete_modal_delete_from_platform')}
              </Button>
          </div>
        </div>
      )}
    />
  )
}

export default DeleteModal