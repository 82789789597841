import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Scatter as ScatterComponent } from 'react-chartjs-2';
import { injectIntl } from 'react-intl';

import { formatNumberAbbr } from 'utils/formatNumber';
import CustomChart from 'components/CustomChart';
import { convertStringToArrayWordSegments } from 'utils/strings';

const DEFAULT_PARAMETERS = {
  nodata: 'No data',
  locale: undefined,
};

const Scatter = injectIntl(forwardRef(({
  intl,
  slug,
  analysisMethod,
  parameters = DEFAULT_PARAMETERS,
  data,
  onDownload,
}, ref) => {
  const t = intl.messages;
  const {
    labels = [],
    values: datasets = [],
  } = data;

  const {
    nodata = DEFAULT_PARAMETERS.nodata,
    locale = DEFAULT_PARAMETERS.locale,
  } = parameters;

  const commonAxesOptions = useMemo(() => ({
    beginAtZero: true,
    suggestedMin: 0,
    suggestedMax: 5,
    ticks: {
      precision: 0,
      callback: value => {
        if(isNaN(value)) {
          return value;
        }
        return formatNumberAbbr(value, { locale });
      }
    },
  }), [
    locale,
  ]);

  const options = useMemo(() => ({
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        ...commonAxesOptions,
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: t[`analysis_${analysisMethod}_matrix_axis_x`],
        }
      },
      y: {
        ...commonAxesOptions,
        position: 'left',
        title: {
          display: true,
          text: t[`analysis_${analysisMethod}_matrix_axis_y`],
        }
      }
    },
    plugins: {
      legend: {
        align: 'start',
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded'
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = `${t[`analysis_${analysisMethod}_matrix_axis_x`]} - ${context.label}, ${t[`analysis_${analysisMethod}_matrix_axis_y`]} - ${context.formattedValue}`;
            const tooltip = convertStringToArrayWordSegments(`${label}: ${value}`, 50);
            return tooltip;
          },
        },
      }
    }
  }), [
    t,
    commonAxesOptions,
    analysisMethod,
  ]);

  return (
    <div className="DashboardComponent__card_content">
      {
        !data.values || data.values.length === 0
        ?
          <div
            className="DashboardComponent__nodata"
          >
            { nodata }
          </div>
        :
          <CustomChart
            title={t.materiality}
            Chart={ScatterComponent}
            ref={ref}
            data={{ labels, datasets }}
            height='500px'
            options={options}
          />
      }
    </div>
  );
}));

Scatter.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.object,
  onDownload: PropTypes.func,
};

export default Scatter;

