import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import CustomButton from 'components/CustomButton';

import {
  Modal,
} from 'antd';

import {
  MessageOutlined,
} from '@ant-design/icons';

const DataStatus = injectIntl(({
  intl,
  source,
  status,
  not_apply_comment = '',
}) => {
  const t = intl.messages;
  const handleNotApply = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    Modal.info({
      title: t.reason,
      content: (
        <div>
          {not_apply_comment}
        </div>
      ),
    });
  }, [
    t,
    not_apply_comment,
  ]);
  if (source === 'children') {
    return <span className='KpiSummaryCard__card-status children'>{t.kpisummarycard_children_source}</span>
  }
  switch (status) {
    case 'pending':
      return <span className='KpiSummaryCard__card-status pending'>{t.kpisummarycard_needs_update}</span>
    case 'uptodate':
      return <span className='KpiSummaryCard__card-status done'>{t.kpisummarycard_all_completed}</span>
    case 'restricted':
      return <span className='KpiSummaryCard__card-status restricted'>{t.kpisummarycard_restricted}</span>
    case 'notapplies':
      return (
        <span className='KpiSummaryCard__card-status notapplies'>
          <span>
            {t.kpisummarycard_not_applies}
          </span>
          {
            !not_apply_comment ? null :
              <CustomButton
                shape="circle"
                icon={<MessageOutlined />}
                onClick={handleNotApply}
              />
          }
        </span>
      );
    default:
      return <span className='KpiSummaryCard__card-status'></span>;
  }
});

export default DataStatus;

