import React, { useCallback, useMemo } from 'react';
import { useSelector } from "react-redux";
import { injectIntl } from 'react-intl';

import SdgMiniLogo from 'components/SdgMiniLogo';
import EsgLogo from 'components/EsgLogo';
import CustomInput from 'components/CustomInput';
import CustomMultiSelect from 'components/CustomMultiSelect';

import {
  Row,
  Col,
} from 'antd';

const REPORTING_STANDARDS = [
  'gri',
  'equality',
  'sdg',
  'sdgc',
  'bcorp',
  'custom',
];
const ESG_TYPES = [
  'environmental',
  'social',
  'governance',
  'economic',
];

const SelecKpiFilter = ({
  intl,
  enabledReports,
  filters,
  setFilters
}) => {
  const t = intl.messages;

  const { sdgs = [] } = useSelector((state) => state.taxonomies);

  const reportingStandards = useMemo(() => {
    return REPORTING_STANDARDS
      .filter(slug => enabledReports.includes(slug))
      .map(slug => ({
        slug,
        name: t[`reporting_standard_${slug}`],
      }));
  }, [
    t,
    enabledReports,
  ]);

  const patchedSdgs = useMemo(() => {
    return sdgs.map(( { code, slug, targets } )=> ({
      slug,
      name: t[`sdg_${slug}`],
      parent_slug: slug,
      code,
      targets
    }));
  }, [
    t, sdgs
  ]);

  const esgTypes = useMemo(() => {
    return ESG_TYPES.map(slug => ({
      slug,
      name: t[`esg_type_${slug}`],
    }));
  }, [
    t,
  ]);

  const onFilter = useCallback(
    (key, value) => {
      const newFilters = {
        ...filters,
        [ key ]: value,
      };
      setFilters(newFilters);
    },
    [filters, setFilters]
  );
  const onSearch = useCallback(
    value => {
      const newFilters = { ...filters, search: value };
      setFilters(newFilters);
    },
    [filters, setFilters]
  );

  return (
    <Row className="filters" gutter={10}>
      <Col span={9}>
        <CustomInput
          allowClear
          placeholder={t.search}
          onChange={e => onSearch(e.target.value)}
          value={filters.search}
        />
      </Col>
      <Col span={5}>
        <CustomMultiSelect
          title={t.item_manager_sdg}
          // options={sdgs}
          options={patchedSdgs}
          selected={filters.sdg_codes}
          onSelect={ val => onFilter('sdg_codes', val)}
          // renderOptionPrefix={slug => <SdgMiniLogo key={slug} sdg={slug}/>}
          renderOptionPrefix={slug => <SdgMiniLogo key={slug} sdg={patchedSdgs.find(sdg => sdg.slug === slug)} />}
          wideMenu={true}
          inModal={true}
        />
      </Col>
      <Col span={5}>
        <CustomMultiSelect
          title={t.item_manager_standard}
          options={reportingStandards}
          selected={filters.standards}
          onSelect={val => onFilter('standards', val)}
          inModal={true}
        />
      </Col>
      <Col span={5}>
        <CustomMultiSelect
          title={t.item_manager_type}
          options={esgTypes}
          selected={filters.esg_types}
          onSelect={val => onFilter('esg_types', val)}
          renderOptionPrefix={slug => <EsgLogo key={slug} type={slug}/>}
          inModal={true}
        />
      </Col>
    </Row>
  );
};

export default injectIntl(SelecKpiFilter);
