import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { injectIntl } from 'react-intl';

import { notification, Row, Space, Col, Form } from 'antd';

import { requestKpiUpdate } from 'actions/api';

import useOrganizations from 'utils/useOrganizations';

import CustomModal from 'components/CustomModal';
import LoaderModal from 'components/LoaderModal';

import CustomInput from 'components/CustomInput';
import DateRangePicker from 'components/DateRangePicker';

import './style.less';

const ModalUfinetAddKpiData = ({
  intl,
  error,
  onClose,
  kpi_slug,
  name,
  name_translations,
  all_periods,
  schema
}) => {
  const [submitting, setSubmitting] = useState(false);  
  const [form] = Form.useForm();

  const {
    organization,
    suborganization,
  } = useOrganizations();

  let values = useMemo(() => {
    return {
      invoiceNumber: '',
      dateRange: [],
      newValue: ''
    }
  }, []);

  const _getPeriod = useCallback (
    (d) => {
      return d.getMonth() + 1 > 9 
          ? `${d.getFullYear()}-${d.getMonth() + 1 }`
          : `${d.getFullYear()}-0${d.getMonth() + 1 }`;
    },
    []
  );

  const _generatePeriods = useCallback(
    (startDate, endDate, totalValue, invoiceNumber) => {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      const oneDay = 24 * 60 * 60 * 1000;
      const diffDays = Math.round(Math.abs((startDateObj - endDateObj) / oneDay));
      const coefByDay = parseFloat(totalValue) / diffDays;

      const startDateEndOfMonthDay = new Date(startDateObj.getFullYear(), startDateObj.getMonth() + 1, 0);
      const startDateDiffDays = Math.round(Math.abs((startDateEndOfMonthDay - startDateObj) / oneDay));
      
      const endDateStartOfTheMonthDay = new Date(endDateObj.getFullYear(), endDateObj.getMonth(), 0);
      const endDateDiffDays = Math.round(Math.abs((endDateObj - endDateStartOfTheMonthDay) / oneDay));

      const previousStartDateValues = all_periods.filter((i) => i.label === _getPeriod(startDateObj));

      const commentBaseText = intl.formatMessage({ id: 'modalufinet_invoice_number' });
      let periods = [
        {
            period: _getPeriod(startDateObj),
            value: startDateDiffDays * coefByDay,
            comment: `${commentBaseText}: "${invoiceNumber}"`
        }, 
        {
            period: _getPeriod(endDateObj),
            value: endDateDiffDays * coefByDay,
            comment: `${commentBaseText}: "${invoiceNumber}"`
        }
      ];

      if (previousStartDateValues.length > 0 && previousStartDateValues[0].kpi_value) {
        periods[0].value += parseFloat(previousStartDateValues[0].kpi_value.value);

        const commentPreviousText = intl.formatMessage({ id: 'modalufinet_invoice_previous_number' });
        periods[0].comment = `${periods[0].comment}\n\n>${commentPreviousText}: ${previousStartDateValues[0].kpi_value.value}`;
      }

      return periods;
    },
    [
      _getPeriod,
      all_periods,
      intl
    ]
  );

  const validateForm = useCallback(
    () => {
      let errors = {};
      if (!values.invoiceNumber) {
        errors.invoiceNumber = intl.formatMessage({ id: "form_error_required" });
      }
      
      if (!values.dateRange || values.dateRange.length !== 2) {
        errors.dateRange = intl.formatMessage({ id: "form_error_required" });
      }

      if (values.dateRange.length === 2) {
        const DATE_REGEXP = new RegExp('^[0-9]{4}/[0-9]{2}/[0-9]{2}$');
        if (!DATE_REGEXP.test(values.dateRange[0])) {
          errors.dateRange = intl.formatMessage({ id: "modalufinet_error_invalid_format_date"});

        } else if (!DATE_REGEXP.test(values.dateRange[1])) {
          errors.dateRange = intl.formatMessage({ id: "modalufinet_error_invalid_format_date"});
        
        } else {
          const startDate = new Date(values.dateRange[0]);
          const endDate = new Date(values.dateRange[1]);

          if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
            errors.dateRange = intl.formatMessage({ id: "modalufinet_error_invalid_date_range"});
          } else if(endDate.getMonth() - startDate.getMonth() > 1) {
            errors.dateRange = intl.formatMessage({ id: "modalufinet_error_invalid_date_range"});
          }
        } 
      }

      if (!values.newValue) {
        errors.newValue = intl.formatMessage({ id: "form_error_required"});
      
      } else if (isNaN(values.newValue)) {
        errors.newValue = intl.formatMessage({ id: "modalufinet_error_invalid_value"});
      }
      
      return errors;
    },
    [
      values,
      intl
    ]
  );

  useEffect(() => {
    if(submitting && !error) {
      setSubmitting(false);
      onClose();
    }
  }, [
    submitting,
    error,
    onClose,
  ]);
  
  const onModalCancel = useCallback(
    () => {
      onClose();
    },
    [
      onClose
    ]
  );
  
  const dispatch = useDispatch();
  const onModalSubmit = useCallback(
    () => {
      const errors = validateForm();
      if (Object.keys(errors).length > 0) {
        notification.error({
          message: intl.formatMessage({ id: 'modaleditkpi_update_error_title' }),
          description: errors[Object.keys(errors)[0]]
        })
        return;
      }
      setSubmitting(true);
      
      _generatePeriods(values.dateRange[0], values.dateRange[1], values.newValue, values.invoiceNumber)
        .forEach((i) => {
          dispatch(
            requestKpiUpdate(
              organization.slug,
              suborganization.slug,
              kpi_slug,
              i.period,
              {
                value: {
                  value: parseFloat(i.value).toFixed(schema.decimalPoints), 
                  unit: schema.allowedUnitSlugs[0]
                },
                comment: i.comment
              }
            )
          );
        });
    },
    [
      values,
      organization,
      suborganization,
      kpi_slug,
      schema,
      intl,
      validateForm,
      _generatePeriods,
      dispatch
    ]
  );

  return (
    <>
    <CustomModal
      shown={!submitting}
      className="ModalUfinetAddKpiData"
      title={intl.formatMessage({ id: "modalufinet_title" })}
      width={800}
      destroyOnClose={true}
      onCancel={onModalCancel}
      onOk={() => onModalSubmit()}
    >
      <Form
        form={form}
        initialValues={values}
      >
        <Space 
          direction="vertical" 
          size="large"
          style={{ display: 'flex' }}
        >
          <Row>
              <CustomInput 
                size="large"
                placeholder={intl.formatMessage({ id: 'modalufinet_invoice_number' })}
                allowClear={true}
                onChange={ (evt) => values.invoiceNumber = evt.target.value }
              />
          </Row>
          <Row>
            <Form.Item
              name="dateRange"
            >
              <DateRangePicker
                picker='day'
                value={values.dateRange}
                format='YYYY/MM/DD'
                allowClear={true}
                onChange={ (val) => values.dateRange = val }
              />
            </Form.Item>
          </Row>
          <Row>
            <Col>
              <CustomInput
                className="KpiDetail__answer-quantitative-input-small"
                placeholder={intl.formatMessage({ id: 'modalufinet_invoice_value' })}
                allowClear={true}
                onChange={ (evt) => values.newValue = evt.target.value }
              />
            </Col>
          </Row>
        </Space>

      </Form>
    </CustomModal>
    <LoaderModal
      visible={submitting}
      type='spinner'
      title={intl.formatMessage({ id: 'modalufinet_title' })}
      message={intl.formatMessage({ id: 'modaleditkpi_update_saving_message' })}
    />
    </>
  )
};

export default injectIntl(ModalUfinetAddKpiData);
