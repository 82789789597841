const uploadKpiAttachmentSuccess = (
  organization_slug,
  kpi_slug,
  period,
  attachment,
) => ({
  type: 'ADD_KPI_ATTACHMENT',
  organization_slug,
  kpi_slug,
  period,
  attachment,
});

const uploadEqualityPlanDocumentSuccess = (
  document,
) => ({
  type: 'ADD_EQUALITY_PLAN_DOCUMENT',
  document,
});

const resetAttachments = () => ({
  type: 'RESET_ATTACHMENTS_DATA',
});

export {
  uploadKpiAttachmentSuccess,
  uploadEqualityPlanDocumentSuccess,
  resetAttachments,
}
