import React, {
  useState,
  useCallback,
  useEffect,
} from 'react'
import { injectIntl } from 'react-intl'

import CustomButton from 'components/CustomButton'
import CustomTextArea from 'components/CustomTextArea';
import CustomSelect from 'components/CustomSelect';
import { ORGANIZATION_TARGET_OPTIONS } from 'components/CustomSelect/targets';
import Description from 'components/Description'
import Required from 'components/Required';

import './style.less'

// This is the fully controlled version
const Component = injectIntl(({
  intl,
  title = '',
  editable = false,
  titleClass,
  loading,
  value,
  values = {},
  onChange,
  editMode = false,
  setEditMode,
  hasChildren,
  config
}) => {
  
  const t = intl.messages;

  const handleValueChange = useCallback((comment) => {
    onChange(comment)
  }, [onChange])

  const handleCommentChange = useCallback((comment) => {
    onChange({...values, comment})
  }, [values, onChange])

  const handleTargetChange = useCallback((target) => {
    onChange({...values, target})
  }, [values, onChange])

  const handleChangeEditMode = useCallback(() => {
    setEditMode(!editMode);
  }, [
    editMode,
    setEditMode,
  ]);
  return editMode
    ? (
      <React.Fragment>
        <span className={titleClass}>{ title }</span>
        {
          value ?
          (
            <CustomTextArea
              disabled={loading}
              value={value}
              onChange={handleValueChange}
            />
          ) : (
            <CustomTextArea
              disabled={loading}
              value={values?.comment}
              onChange={handleCommentChange}
            />
          )
        }
        {
          hasChildren && (
            <CustomSelect
              title={t.applies_not_applies_direction}
              selected={values?.target}
              options={ORGANIZATION_TARGET_OPTIONS.map(slug => ({
                name: t[`applies_not_applies_direction_${slug}`],
                slug,
              }))}
              onSelect={handleTargetChange}
            />
          )
        }
      </React.Fragment>
    ) : (
      <React.Fragment>
        <section className='KpiDetail__flex-baseline'>
          <span className={titleClass}>{ title }{config?.mandatory_data?.comment && <Required/>}</span>
          { !editable ? null :

            <button
              className='KpiDetail__guidelines-edit'
              onClick={() => !loading && handleChangeEditMode()}
            >
              <img
                src='/images/assets/icon-edit.svg'
                alt='edit'
              />
            </button>
          }
        </section>
        { value && value !== '' && 
          (
            <Description
              className='KpiDetail__guidelines-text'
              description={value}
            />
          )
        }
        { values?.comment && values?.comment !== '' && 
          (
            <Description
            className='KpiDetail__guidelines-text'
            description={values?.comment}
          />
          )
        }
      </React.Fragment>
    );
});

const EditableComment = ({
  intl,
  title = '',
  value = '',
  editable = false,
  titleClass,
  loading,
  onChange,
  hasChildren = false,
  config
}) => {
  const t = intl.messages

  const [editMode, setEditMode] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [values, setValues] = useState({comment: value, target: 'self'});
  const [submitting, setSubmitting] = useState(false);

  const handleChangeEditMode = useCallback(() => {
    setEditMode(!editMode);
    setValues({comment: value, target: 'self'});
    setDirty(false);
  }, [
    editMode,
    value,
  ]);

  const handleChangeValue = useCallback(({comment, target}) => {
    setDirty(true);
    setValues({comment, target});
  }, []);

  const handleSave = useCallback(() => {
    setSubmitting(true);
    onChange(values);
  }, [
    values,
    onChange,
  ]);

  useEffect(() => {
    if(editMode && submitting && !loading) {
      // We assume done (TODO: handle errors)
      setSubmitting(false);
      handleChangeEditMode();
    }
  }, [
    editMode,
    submitting,
    loading,
    handleChangeEditMode,
  ]);

  // TODO: Keeeping state here sucks big time...
  useEffect(() => {
    setEditMode(false);
    setDirty(false);
    setValues({comment: value, target: 'self'});
    setSubmitting(false);
  }, [
    value,
  ]);

  return (
    <section className='KpiDetail__flex-column EditableComment'>
      <Component
        title={title}
        editable={editable}
        titleClass={titleClass}
        loading={loading}
        values={values}
        onChange={handleChangeValue}
        editMode={editMode}
        setEditMode={setEditMode}
        hasChildren={hasChildren}
        config={config}
      />
      { !editMode
        ? null
        : <section className='KpiDetail__flex KpiDetail__flex-alignRight'>
            <CustomButton
              onClick={handleChangeEditMode}
              disabled={loading}
              className='KpiDetail__guidelines-cancel'
              type='default'
            >
              { t.cancel }
            </CustomButton>
            <CustomButton
              onClick={handleSave}
              disabled={loading || !dirty}
              className='KpiDetail__guidelines-save'
              type='primary'
            >
              { t.save }
            </CustomButton>
          </section>
      }
    </section>
  );
}

EditableComment.Component = Component;

export default injectIntl(EditableComment)
