import { useMediaQuery } from 'react-responsive';

const useScreenSize = () => {
  const xs = useMediaQuery({ query: '(max-width: 480px)' });
  const sm = useMediaQuery({ query: '(max-width: 576px)' });
  const md = useMediaQuery({ query: '(max-width: 768px)' });
  const lg = useMediaQuery({ query: '(max-width: 992px)' });
  const xl = useMediaQuery({ query: '(max-width: 1200px)' });
  const xxl = useMediaQuery({ query: '(max-width: 1600px)' });

  if (xs) {
    return 'xs';
  }
  if (sm) {
    return 'sm';
  }
  if (md) {
    return 'md';
  }
  if (lg) {
    return 'lg';
  }
  if (xl) {
    return 'xl';
  }
  if (xxl) {
    return 'xxl';
  }
}

export default useScreenSize;
