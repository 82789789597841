import React, {
  useState,
  useCallback,
} from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import CustomCheckbox from 'components/CustomCheckbox';
import CustomButton from 'components/CustomButton';
import KpiSourceIcon from 'components/KpiSourceIcon';
import HiddenLogo from 'components/HiddenLogo'
import { TagList } from "tsComponents/TagList"

import {
  objectToQueryParameters
} from 'utils/queryParameters';

import {
  Col,
  Row,
} from 'antd';

import DataSdgs from './DataSdgs';
import DataEsgLogo from './DataEsgLogo';
import DataReportingStandards from './DataReportingStandards';
import DataPeriod from './DataPeriod';
import KpiAssociationsTags from 'components/KpiAssociationsTags';

import './style.less';

const NO_OP = () => { };

const NUMBER_OF_PERIODS_SHOWN = 3;

const KpiSummaryCard = ({
  intl,
  history,
  name,
  esgs,
  tags,
  standard_info = [],
  sdgs = [],
  periods = [],
  restricted,
  source, // NOTICE: Current source for new values of this KPI
  schema,
  schemaLabels = {},
  slug,
  kpi_associations,
  applies,
  urlBase = 'kpi',
  onOpenAnswer,
  onApprove,
  onReject,
  onStartValidation,
  onTarget,
  permissions = {},
  showReportingStandards = true,
  showAssociations = false,
  selectable = false,
  selected = false,
  onSelect = NO_OP,
  getDetailQueryString = NO_OP,
  config,
}) => {
  const [numberOfPeriodsShown, setNumberOfPeriodsShown] = useState(NUMBER_OF_PERIODS_SHOWN);
  const t = intl.messages;

  const toggleNumberOfPeriodsShown = useCallback(() => {
    if (numberOfPeriodsShown) {
      return setNumberOfPeriodsShown(undefined);
    }
    setNumberOfPeriodsShown(NUMBER_OF_PERIODS_SHOWN);
  }, [
    numberOfPeriodsShown,
  ]);

  const handleOpenAnswer = useCallback((props = {}) => {
    onOpenAnswer({
      ...props,
      kpi_name: name,
      schema,
      showUser: false,
    });
  }, [
    onOpenAnswer,
    name,
    schema,
  ]);

  const handleApprove = useCallback((period, level) => {
    onApprove({
      slug,
      period,
      level,
    });
  }, [
    onApprove,
    slug,
  ]);

  const handleReject = useCallback((period, level) => {
    onReject({
      slug,
      period,
      level,
    });
  }, [
    onReject,
    slug,
  ]);

  const handleStartValidation = useCallback((period) => {
    onStartValidation(
      slug,
      period,
    );
  }, [
    onStartValidation,
    slug,
  ]);

  const handleTarget = useCallback((period, kpi_value, target_value) => {
    onTarget(
      name,
      slug,
      esgs,
      sdgs,
      period,
      periods,
      schema,
      schemaLabels,
      standard_info,
      kpi_value,
      target_value
    );
  }, [
    onTarget,
    name,
    slug,
    esgs,
    sdgs,
    periods,
    schema,
    schemaLabels,
    standard_info
  ]);

  const goToDetail = useCallback(() => {
    const detailQueryString = getDetailQueryString();
    const qs = !detailQueryString
      ? ''
      : `?${objectToQueryParameters(detailQueryString)}`;

    history.replace(`/${urlBase}${qs}`);
    history.push(`/${urlBase}/${slug}/last/data${qs}`);
  }, [
    history,
    urlBase,
    slug,
    getDetailQueryString,
  ]);

  return (
    <article
      className={`KpiSummaryCard__item ${selectable && selected ? 'KpiSummaryCard__selected' : ''}`}
    >
      <header className="KpiSummaryCard__header">
        <Row
          className="KpiSummaryCard__titlerow"
          type="flex"
          gutter={16}
          align="top"
          justify="space-between"
        >
          {
            !selectable
              ? null
              : (
                <Col>
                  <CustomCheckbox
                    checked={selected}
                    onChange={onSelect}
                  />
                </Col>
              )
          }
          <Col style={{ flex: '1' }}>
            <h3 className="KpiSummaryCard__title">
              {name}
            </h3>
          </Col>
          <Col>
            {
              !restricted ? null :
                <span className="KpiSummaryCard__card-status restricted">
                  {t.kpisummarycard_restricted}
                </span>
            }
            {
              restricted || applies ? null :
                <span className="KpiSummaryCard__card-status notapplies">
                  {t.kpisummarycard_not_applies}
                </span>
            }
            <CustomButton
              className="KpiSummaryCard__actionbutton_main"
              onClick={goToDetail}
            >
              {t.kpisummarycard_gotodetail}
            </CustomButton>
          </Col>
        </Row>
        <Row
          type="flex"
          justify="space-between"
          gutter={16}
        >
          <Col>
            <Row
              className="KpiSummaryCard__iconsrow"
              type="flex"
              gutter={8}
            >
              {
                !applies && (
                  <Col>
                    <div className="ListSidebar__hiddenlogo">
                      <HiddenLogo />
                    </div>
                  </Col>
                )
              }
              {(esgs || []).map(esg =>
                <Col key={esg}>
                  <DataEsgLogo
                    type={esg}
                  />
                </Col>
              )}
              <Col>
                <DataSdgs
                  sdgs={sdgs}
                />
              </Col>
              <KpiSourceIcon
                source={source}
              />
            </Row>
          </Col>
          <Col>
            <Row
              type="flex"
              gutter={8}
            >
              <Col>
                <TagList
                  tags={tags} 
                  showNTags={2}
                />
              </Col>
              {
                !showReportingStandards ? null :
                  <Col>
                    <DataReportingStandards
                      standard_info={standard_info}
                    />
                  </Col>
              }
              {
                showAssociations && Object.keys(kpi_associations || {}).length > 0 ? 
                (
                  <Col>
                    <KpiAssociationsTags 
                      kpi_associations={ kpi_associations } 
                      max={2}
                    />
                  </Col>
                ) : null
              }
            </Row>
          </Col>
        </Row>
      </header>
      <section className="KpiSummaryCard__body">
        {
          restricted || periods.length === 0 ? null :
            periods.map((period, index) => (
              <DataPeriod
                key={period.period}
                source={period.source}
                schema={schema}
                schemaLabels={schemaLabels}
                kpi_slug={slug}
                applies={applies}
                onOpenAnswer={handleOpenAnswer}
                onApprove={handleApprove}
                onReject={handleReject}
                onStartValidation={handleStartValidation}
                onTarget={handleTarget}
                permissions={permissions}
                previous_value={periods[index + 1]?.kpi_value}
                config={config}
                {...period}
              />
            )).slice(0, numberOfPeriodsShown)
        }
        {
        (periods?.length > NUMBER_OF_PERIODS_SHOWN) &&
        <CustomButton
          type="primary"
          className="KpiSummaryCard__actionbutton_period"
          onClick={toggleNumberOfPeriodsShown}
        >
          {
            !!numberOfPeriodsShown
            ? intl.formatMessage({ id: 'kpisummarycard_showMorePeriods' })
            : intl.formatMessage({ id: 'kpisummarycard_showLessPeriods' })
          }
        </CustomButton>
        }
      </section>
    </article>
  )
};

export default injectIntl(
  withRouter(
    KpiSummaryCard
  )
);
