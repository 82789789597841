import { useState, useEffect } from 'react';

const useElementSize = (element) => {
  const [elementSize, setElementSize] = useState({
    clientHeight: undefined,
    scrollHeight: undefined,
    clientWidth: undefined,
    scrollWidth: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      const { clientHeight, scrollHeight, clientWidth, scrollWidth } = element;
      setElementSize({ clientHeight, scrollHeight, clientWidth, scrollWidth });
    }

    window.addEventListener('scroll', handleResize);

    handleResize();

    return () => window.removeEventListener('scroll', handleResize);
  }, [ element ]);

  return elementSize;
}

export default useElementSize;
