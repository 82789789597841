import React, { useCallback, useMemo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import {
  Row,
  Col,
  Button,
  Input,
  Form,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { fullValidation as validation } from './validation';
import useForm from 'utils/useForm';
import BudgetForm from './BudgetForm';
import ManagerForm from './ManagerForm';
import FormItemPreview from 'components/EqualityPlan/FormItemPreview';

import './style.less';


const PlanCreationForm = ({
  intl,
  history,
  plan,
  updatePlan,
  setFormIsDirty,
}) => {
  const t = intl.messages;
  const defaults = plan.plan_creation_data;
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    updatePlan({
      plan_creation_data: values,
      progress: [...new Set([...plan.progress, `plan_creation`])],
    });
    history.push(`/equality-plan/${plan.id}/`);
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    dirtyFields,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  useEffect(
    () => setFormIsDirty(dirtyFields.length > 0),
    [dirtyFields, setFormIsDirty]
  );

  // Budget lines
  const budgetLines = getValue('budget_lines');
  const validBudgetLines = useMemo(
    () => {
      if (budgetLines && budgetLines.length) {
        return budgetLines.filter(record => !record.active);
      } else {
        return [];
      }
    },
    [budgetLines]
  );
  const activeBudgetLine = useMemo(
    () => {
      if (budgetLines && budgetLines.length) {
        return budgetLines.find(record => record.active);
      }
    },
    [budgetLines]
  );
  const activeBudgetLineIndex = useMemo(
    () => {
      if (budgetLines && budgetLines.length) {
        return budgetLines.findIndex(record => record.active);
      }
    },
    [budgetLines]
  );

  // Grants
  const grants = getValue('grants');
  const validGrants = useMemo(
    () => {
      if (grants && grants.length) {
        return grants.filter(record => !record.active);
      } else {
        return [];
      }
    },
    [grants]
  );
  const activeGrant = useMemo(
    () => {
      if (grants && grants.length) {
        return grants.find(record => record.active);
      }
    },
    [grants]
  );
  const activeGrantIndex = useMemo(
    () => {
      if (grants && grants.length) {
        return grants.findIndex(record => record.active);
      }
    },
    [grants]
  );

  // Ultimate managers
  const managers = getValue('managers');
  const validManagers = useMemo(
    () => {
      if (managers && managers.length) {
        return managers.filter(record => !record.active);
      } else {
        return [];
      }
    },
    [managers]
  );
  const activeManager = useMemo(
    () => {
      if (managers && managers.length) {
        return managers.find(record => record.active);
      }
    },
    [managers]
  );
  const activeManagerIndex = useMemo(
    () => {
      if (managers && managers.length) {
        return managers.findIndex(record => record.active);
      }
    },
    [managers]
  );

  const handleAddToList = useCallback(
    (name) => {
      let value = [...getValue(name) || []];
      const alreadyActiveItem = value.find(item => item.active);
      if (alreadyActiveItem) {
        if (alreadyActiveItem.new) {
          value.splice(
            value.findIndex(item => item.active), 1
          )
        } else {
          delete alreadyActiveItem.active;
        }
      }
      value.push({new: true, active: true});
      handleChange(name)(value);
    },
    [handleChange, getValue]
  );

  const handleSaveToList = useCallback(
    (name, index, item) => {
      let value = [...getValue(name)];
      if (typeof index == 'number') {
        value[index] = item;
      } else {
        value.push(item);
      }
      handleChange(name)(value);
    },
    [handleChange, getValue]
  );

  const makeListItemInactive = useCallback(
    (list, index) => {
      let item = list[index];
      if (item.new) {
        list.splice(index, 1);
      }
      else {
        delete item.active;
      }
      return list;
    },
    []
  );

  const handleCancelSaveToList = useCallback(
    (name, index) => {
      let value = makeListItemInactive([...getValue(name)], index);
      handleChange(name)(value);
    },
    [getValue, handleChange, makeListItemInactive]
  );

  const handleEditListItem = useCallback(
    (name, index) => {
      let value = [...getValue(name)];
      const alreadyActiveItemIndex = value.findIndex(item => item.active);
      value[index].active = true;
      if (alreadyActiveItemIndex >= 0) {
        value = makeListItemInactive(value, alreadyActiveItemIndex);
      }
      handleChange(name)(value);
    },
    [handleChange, getValue, makeListItemInactive]
  );

  const handleDeleteListItem = useCallback(
    (name, index) => {
      let value = [...getValue(name)];
      value.splice(index, 1);
      handleChange(name)(value);
    },
    [handleChange, getValue]
  );

  return (
    <Form onFinish={handleSubmit} layout="vertical">
      <Row type='flex' justify='end' gutter={10}>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
          >
            { t.save }
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="form-input-wrapper">
          <Form.Item
            hasFeedback
            validateStatus={ showError('about_company') ? 'error' : '' }
            help={ showError('about_company') }
            label={ t.equality_plan_creation_text_company }
            colon={false}
          >
            <Input.TextArea
              placeholder={ t.equality_plan_creation_text_company }
              autoSize={{ minRows: 2 }}
              name='about_company'
              value={ getValue('about_company') }
              onChange={handleChangeEvent}
            />
          </Form.Item>
        </Col>
        <Col span={24} className="form-input-wrapper">
          <Form.Item
            hasFeedback
            validateStatus={ showError('objective') ? 'error' : '' }
            help={ showError('objective') }
            label={ t.equality_plan_creation_text_objective }
            colon={false}
          >
            <Input.TextArea
              placeholder={ t.equality_plan_creation_text_objective }
              autoSize={{ minRows: 2 }}
              name='objective'
              value={ getValue('objective') }
              onChange={handleChangeEvent}
            />
          </Form.Item>
        </Col>
        <Col span={24} className="form-input-wrapper">
          <Form.Item
            hasFeedback
            validateStatus={ showError('territorial_scope_of_action') ? 'error' : '' }
            help={ showError('territorial_scope_of_action') }
            label={ t.equality_plan_creation_text_territorial_scope_of_action }
            colon={false}
          >
            <Input.TextArea
              placeholder={ t.equality_plan_creation_text_territorial_scope_of_action }
              autoSize={{ minRows: 2 }}
              name='territorial_scope_of_action'
              value={ getValue('territorial_scope_of_action') }
              onChange={handleChangeEvent}
            />
          </Form.Item>
        </Col>
        <Col span={24} className="form-input-wrapper">
          <Form.Item
            hasFeedback
            validateStatus={ showError('validity') ? 'error' : '' }
            help={ showError('validity') }
            label={ t.equality_plan_creation_text_validity }
            colon={false}
          >
            <Input.TextArea
              placeholder={ t.equality_plan_creation_text_validity }
              autoSize={{ minRows: 2 }}
              name='validity'
              value={ getValue('validity') }
              onChange={handleChangeEvent}
            />
          </Form.Item>
        </Col>
        <Col span={24} className="form-input-wrapper">
          <div className="form-input-card">
            <h4>{t.budget_lines}</h4>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => handleAddToList('budget_lines')}
            ></Button>
          </div>
        </Col>
        { activeBudgetLine && (
          <Col span={24}>
            <BudgetForm
              type='budget_lines'
              record={activeBudgetLine}
              index={activeBudgetLineIndex}
              onSave={handleSaveToList}
              onCancel={handleCancelSaveToList} />
          </Col>
        )}
        {validBudgetLines.length > 0 && (
          <Col span={24} className="form-input-wrapper">
            {validBudgetLines.map((line, index) => {
              return <FormItemPreview
                key={index}
                sequence={index + 1}
                onEdit={() => handleEditListItem('budget_lines', index)}
                onDelete={() => handleDeleteListItem('budget_lines', index)}
              >
                <div>{`${t.concept}: ${line.concept}`}</div>
                <div>{`${t.amount}: ${line.amount}`}</div>
              </FormItemPreview>;
            })}
          </Col>
        )}
        <Col span={24} className="form-input-wrapper">
          <div className="form-input-card">
            <h4>{t.grants}</h4>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => handleAddToList('grants')}
            ></Button>
          </div>
        </Col>
        { activeGrant && (
          <Col span={24}>
            <BudgetForm
              type='grants'
              record={activeGrant}
              index={activeGrantIndex}
              onSave={handleSaveToList}
              onCancel={handleCancelSaveToList} />
          </Col>
        )}
        {validGrants.length > 0 && (
          <Col span={24} className="form-input-wrapper">
            {validGrants.map((grant, index) => {
              return <FormItemPreview
                key={index}
                sequence={index + 1}
                onEdit={() => handleEditListItem('grants', index)}
                onDelete={() => handleDeleteListItem('grants', index)}
              >
                <div>{`${t.concept}: ${grant.concept}`}</div>
                <div>{`${t.amount}: ${grant.amount}`}</div>
              </FormItemPreview>;
            })}
          </Col>
        )}
        <Col span={24} className="form-input-wrapper">
          <Form.Item
            hasFeedback
            validateStatus={ showError('legal_representative_role') ? 'error' : '' }
            help={ showError('legal_representative_role') }
            label={ t.equality_plan_creation_text_role_legal }
            colon={false}
          >
            <Input.TextArea
              placeholder={ t.equality_plan_creation_text_role_legal }
              autoSize={{ minRows: 2 }}
              name='legal_representative_role'
              value={ getValue('legal_representative_role') }
              onChange={handleChangeEvent}
            />
          </Form.Item>
        </Col>
        <Col span={24} className="form-input-wrapper">
          <div className="form-input-card">
            <h4>{t.equality_plan_creation_ultimate_managers}</h4>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              onClick={() => handleAddToList('managers')}
            ></Button>
          </div>
        </Col>
        { activeManager && (
          <Col span={24}>
            <ManagerForm
              type='managers'
              record={activeManager}
              index={activeManagerIndex}
              onSave={handleSaveToList}
              onCancel={handleCancelSaveToList} />
          </Col>
        )}
        {validManagers.length > 0 && (
          <Col span={24} className="form-input-wrapper">
            {validManagers.map((manager, index) => {
              return <FormItemPreview
                key={index}
                sequence={index + 1}
                onEdit={() => handleEditListItem('managers', index)}
                onDelete={() => handleDeleteListItem('managers', index)}
              >
                <div>{`${t.name}: ${manager.name}`}</div>
              </FormItemPreview>;
            })}
          </Col>
        )}
      </Row>
    </Form>
  )
};

export default withRouter(injectIntl(PlanCreationForm));
