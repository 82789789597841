import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useTableUnits } from 'utils/useTableUnits';
import { schemaToColumns, valueToDataSource } from './converters';
import CustomTable from '../CustomTable';

// NOTICE: This must match ./converters.js
const META_SLUG = '__meta';

// NOTICE: This must match ./Edit.js
const COLUMN_WIDTH = 200;

const COLUMN_WIDTH_BY_TYPE = {
  boolean: 50,
  choice: 100,
  qualitative: 250,
  quantitative: 100,
  tuple:100
};

const ShowTable = injectIntl(({
  intl,
  schema,
  schemaLabels,
  config,
  value,
  previous_value,
  tableDimensions: overrideTableDimensions,
  target,
  isTarget,
  sourceData
}) => {
  
  const { availableUnits } = useTableUnits(schema);

  const {
    table_dimensions: taxonomyTableDimensions = {},
  } = useSelector(state => state.taxonomies);

  const tableDimensions = useMemo(
    () => overrideTableDimensions || taxonomyTableDimensions,
    [
      overrideTableDimensions,
      taxonomyTableDimensions,
    ]
  );

  const columns = useMemo(() => {
    return schemaToColumns(
      intl,
      schema,
      config,
      false,
      availableUnits,
      schemaLabels,
      tableDimensions,
      value,
      isTarget,
      false // targetEdit
    );
  }, [
    intl,
    schema,
    config,
    availableUnits,
    schemaLabels,
    tableDimensions,
    value,
    isTarget,
  ]);

  const dataSource = useMemo(() => {
    return valueToDataSource(
      intl,
      columns,
      schema,
      value,
      previous_value,
      availableUnits,
      schemaLabels,
      target?target.value:{},
      tableDimensions,
      sourceData
    );
  }, [
    intl,
    columns,
    schema,
    value,
    previous_value,
    availableUnits,
    schemaLabels,
    target,
    tableDimensions,
    sourceData
  ]);

  const patchedColumns = useMemo(() => {

    const rowDimensions = schema.innerSchema.type === 'tuple'
      ? schema.dimensions
      : schema.dimensions.filter(({ presentation }) => presentation !== 'column');

    return columns.map((column, index) => {
      return ({
        ...column,
        width: COLUMN_WIDTH_BY_TYPE[column.type] || COLUMN_WIDTH,
        fixed: index < rowDimensions.length ? 'left' : undefined
      });
    });
  }, [
    schema,
    columns
  ]);

  return (
    <CustomTable
      columns={patchedColumns}
      dataSource={dataSource}
      pagination={false}
      rowClassName={(record) => record[META_SLUG] ? 'KpiDetail__answer-table-metarow' : ''}
      scroll={{ x: true }}
    />
  );
});

export default ShowTable;
