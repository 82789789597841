import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { groupBy } from 'lodash';
import { Bar } from 'react-chartjs-2';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import CustomChart from 'components/CustomChart';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';
import T from 'components/T';
import { defaultColors } from 'components/Dashboard/useColors';

import {
  requestEqualityPlanFactors,
  updateEqualityPlanFactor,
} from 'actions/api';

import {
  Row,
  Col,
  InputNumber,
  Empty,
  Typography,
} from 'antd';
import CustomTable from 'components/CustomTable';
import FloatingButton from 'components/FloatingButton';


const FACTOR_TYPES = ['A', 'B', 'C', 'D'];
const { Text } = Typography;

const FactorWeight = ({
  intl,
  history,
  plan,
  updatePlan,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();
  const [typeWeights, setTypeWeights] = useState(
    plan?.job_evaluation?.type_points || {}
  );
  const [factors, setFactors] = useState();
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanFactors(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetching,
    data,
  } = useSelector(state => state.equality_plan_factor);

  useEffect(
    () => {
      if (data) {
        setFactors(data);
      }
    },
    [data]
  );

  const typeWeightColumns = useMemo(
    () => ([{
      title: t.equality_job_evaluation_factor_type_points_type,
      dataIndex: 'type',
      render: value => t[`equality_job_evaluation_factor_type_option_${value}`]
    }, {
      title: t.equality_job_evaluation_factor_type_points_recommended,
      dataIndex: 'recommended',
    }, {
      title: t.equality_job_evaluation_factor_type_points_percentage,
      dataIndex: 'percentage',
      render: (_, record) => (
        <InputNumber
          value={typeWeights[record.type]}
          onChange={
            value => {
              setTypeWeights({...typeWeights, [record.type]: value});
              setIsFormDirty(true);
            }
          }
        />
      )
    }]),
    [t, typeWeights]
  );

  const typeWeightData = useMemo(
    () => FACTOR_TYPES.map(type => ({
      type,
      recommended: t[`equality_job_evaluation_factor_type_points_recommendation_${type}`],
      points: typeWeights[type] || 0,
    })),
    [t, typeWeights]
  );

  const handleSaveTypePoints = useCallback(
    () => {
      updatePlan({
        job_evaluation: {
          ...plan.job_evaluation,
          type_points: typeWeights,
        },
        progress: [...new Set([
          ...plan.progress,
          'factor_weight',
          'factor_point_graphs',
        ])],
      });
    },
    [plan, updatePlan, typeWeights]
  );

  const sumOfTypePoints = useMemo(
    () => typeWeightData.reduce((total, type) => total + type.points, 0),
    [typeWeightData]
  );

  const getPointsForFactor = useCallback(
    (factor) => {
      return (
        (factor.percentage || 0) * (typeWeights[factor.type] || 0)
      ) / 10;
    },
    [typeWeights]
  );

  const handleSetFactorWeight = useCallback(
    (factor, percentage) => {
      setIsFormDirty(true);
      const index = factors.map(
        factor => factor.id
      ).indexOf(factor.id);
      setFactors([
        ...factors.slice(0, index),
        {
          ...factor,
          percentage,
        },
        ...factors.slice(index + 1)
      ]);
    },
    [factors]
  );

  const factorWeightColumns = useMemo(
    () => ([{
      title: t.equality_job_evaluation_factor_type_points_type,
      dataIndex: 'type',
      render: value => t[`equality_job_evaluation_factor_type_option_${value}`],
      width: 280,
    }, {
      title: t.equality_job_evaluation_factor_type_points_factor,
      dataIndex: 'code',
      render: (code, factor) => `${code}: ${factor.name}`,
      width: 280,
    }, {
      title: t.equality_job_evaluation_factor_gender,
      dataIndex: 'gender',
      render: value => t[`equality_job_evaluation_factor_gender_option_${value}`]
    }, {
      title: t.equality_job_evaluation_factor_type_points_percentage,
      dataIndex: 'percentage',
      render: (_, record) => (
        <InputNumber
          value={record.percentage}
          onChange={
            value => handleSetFactorWeight(record, value)
          }
        />
      )
    }, {
      title: t.equality_job_evaluation_factor_type_points,
      dataIndex: 'id',
      render: (_, record) => getPointsForFactor(record)
    }]),
    [t, getPointsForFactor, handleSetFactorWeight]
  );

  const getTotalFactorTypeWeights = useCallback(
    (type) => {
      return factors
        .filter(factor => factor.type === type)
        .reduce((total, factor) => total += (factor.percentage || 0), 0);
    },
    [factors]
  );

  const getTotalFactorTypePoints = useCallback(
    (type) => {
      return factors
        .filter(factor => factor.type === type)
        .reduce((total, factor) => total += getPointsForFactor(factor), 0);
    },
    [factors, getPointsForFactor]
  );

  const handleOnSave = useCallback(
    () => {
      handleSaveTypePoints();

      // TODO: Bulk update using one API call
      factors.forEach(
        factor => dispatch(
          updateEqualityPlanFactor(
            organization.slug,
            suborganization.slug,
            plan.id,
            factor.id,
            factor,
          )
        )
      );
      setIsFormDirty(false);
    },
    [
      factors,
      organization,
      suborganization,
      plan,
      dispatch,
      handleSaveTypePoints,
    ]
  );

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  const graphData = useMemo(
    () => {
      if (!factors) {
        return {};
      }

      let graphData = {
        labels: FACTOR_TYPES,
        datasets: factors.map((factor, index) => ({
          label: `${factor.code}: ${factor.name}`,
          data: FACTOR_TYPES.map(type => factor.type === type ? factor.percentage : 0),
          backgroundColor: defaultColors[index],
        })),
      };
      return graphData;
    },
    [factors]
  );

  const typeGraphData = useMemo(
    () => {
      if (!typeWeights) {
        return {};
      }

      let graphData = {
        labels: FACTOR_TYPES,
        datasets: FACTOR_TYPES.map((type, index) => ({
          label: type,
          data: FACTOR_TYPES
            .map(_type => _type === type ? (typeWeights[type] || 0) : 0),
          backgroundColor: defaultColors[index],
        })),
      };
      return graphData;
    },
    [typeWeights]
  );

  if (fetching || !factors) {
    return <Loading />;
  }
  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_factor_weight}
        handleBackOrSkip={handleBack}
      />
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_factor_weight_each_factor_tyoe}</h2>
        </Col>
        <Col span={24}>
          <CustomTable
            columns={typeWeightColumns}
            dataSource={typeWeightData}
            rowKey='type'
            pagination={false}
          />
        </Col>
      </Row>
      <Row type="flex" justify="end" align="middle" gutter={[15, 15]}>
        <Col>
          <Text type={`${sumOfTypePoints > 100 ? 'danger' : ''}`}>
            <T
              equality_job_evaluation_total={{total: sumOfTypePoints}}
            />
          </Text>
        </Col>
      </Row>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_factor_weight_each_factor}</h2>
        </Col>
      </Row>
      {factors.length === 0 &&
        <Empty
          image={'/images/icon-empty-data.svg'}
          description={t.equality_job_evaluation_factor_weight_empty}
        />
      }
      { Object.entries(groupBy(factors, 'type')).map(([type, factors]) => {
        return (
          <React.Fragment key={type}>
            <Row>
              <Col span={24}>
                <Text type={`${getTotalFactorTypePoints(type) > 1000 ? 'danger' : ''}`}>
                  <T
                    equality_job_evaluation_total_points={{
                      total: getTotalFactorTypePoints(type)
                    }}
                  />
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <CustomTable
                  columns={factorWeightColumns}
                  dataSource={factors}
                  rowKey='id'
                  pagination={false}
                />
              </Col>
            </Row>
            <Row type="flex" justify="end" align="middle" gutter={[15, 15]}>
              <Col>
                <Text type={`${getTotalFactorTypeWeights(type) > 100 ? 'danger' : ''}`}>
                  <T
                    equality_job_evaluation_total={{
                      total: getTotalFactorTypeWeights(type)
                    }}
                  />
                </Text>
              </Col>
            </Row>
          </React.Fragment>
        );
      })}
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_factor_weight_graph_type}</h2>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        { !Object.values(typeWeights).some(value => value)
          ? <Empty
              image={'/images/icon-empty-data.svg'}
              description={t.equality_job_evaluation_factor_weight_graph_type_empty}
            />
          : <Col span={24}>
              <CustomChart
                title={t.equality_job_evaluation_factor_weight_graph_type}
                Chart={Bar}
                data={typeGraphData}
                height='300px'
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                    },
                  },
                  plugins: {
                    legend: { 
                      align: 'start',
                      position: 'bottom',
                      labels: {
                        usePointStyle: true,
                        pointStyle: 'rectRounded'
                      }
                    }
                  }
                }}
              />
            </Col>
        }
      </Row>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_factor_weight_graph}</h2>
        </Col>
      </Row>
      <Row type="flex" justify="center" gutter={[0, 40]}>
        {factors.length === 0
          ? <Empty
              image={'/images/icon-empty-data.svg'}
              description={t.equality_job_evaluation_factor_weight_empty}
            />
          : <Col span={24}>
              <CustomChart
                title={t.equality_job_evaluation_factor_weight_graph}
                Chart={Bar}
                data={graphData}
                height='300px'
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                    },
                  },
                  plugins: {
                    legend: { 
                      align: 'start',
                      position: 'bottom',
                      labels: {
                        usePointStyle: true,
                        pointStyle: 'rectRounded'
                      }
                    }
                  }
                }}
              />
            </Col>
        }
      </Row>
      <FloatingButton
        onClick={() => handleOnSave()}
        iconUrl='/images/fa-save-regular.svg'
        disabled={!isFormDirty}
      />
    </>
  );
}

export default withRouter(injectIntl(FactorWeight));
