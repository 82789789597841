import { loop, Cmd } from 'redux-loop';

const TARGET = 'benchmark';

const initialState = {
  error: null,
};

const loadSusIssues = (data) => ({
  type: 'LOAD_SUS_ISSUES',
  data,
});

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || response.target !== TARGET) return state;
      switch(response.method) {
        case 'POST':
          return loop(
            {...state, error: null},
            Cmd.action(loadSusIssues(response.result))
          );
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        error: 'csv_upload_error',
      };
    default:
      return state;
  }
};

export {
  reducer as benchmark,
};
