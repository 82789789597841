import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { install } from 'redux-loop';

import { setupIntl } from '../intl'; // NOTICE: 'intl' is a node package
import createRootReducer from 'reducers';
import { queryKeeperMiddleWare } from './queryKeeperMiddleWare';

const history = createBrowserHistory(); 

const middlewares = [ routerMiddleware(history), queryKeeperMiddleWare ];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {
  intl: setupIntl(),
};

export default function configureStore(preLoadedState = {}) {
  const store = createStore(
    createRootReducer (history),
    {...initialState, ...preLoadedState},
    composeEnhancers(install(), applyMiddleware(...middlewares))
  );

  if (module.hot) {
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export { history };

