import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import {
  Row,
  Col,
  Button,
  Card,
  Input,
  Form,
  DatePicker,
  Radio,
  Modal,
  Popover,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { fullValidation as validation } from './validation';
import useForm from 'utils/useForm';
import { formatDateMoment, toDb, DATE_TIME } from 'utils/date';
import AttendeesForm from './AttendeesForm';
import MembersForm from './MembersForm';
import FormItemPreview from 'components/EqualityPlan/FormItemPreview';
import EqualityPlanHeader from 'components/EqualityPlan/Header';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.less';

const CommitteeForm = ({
  intl,
  stage = 'committee',
  history,
  plan,
  updatePlan,
}) => {
  const t = intl.messages;
  const defaults = useMemo(() => Object.keys(plan[`${stage}_data`] || {}).length === 0
    ? {collaborated_with_consultant: 'no'}
    : plan[`${stage}_data`], [plan, stage]);
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    updatePlan({[`${stage}_data`]: values});
    history.push(`/equality-plan/${plan.id}/${stage === 'committee' ? 'committee' : 'committee-followup'}/document-manager`);
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    dirtyFields,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  // Attendees
  const attendees = getValue('attendees');
  const validAttendees = useMemo(
    () => {
      if (attendees && attendees.length) {
        return attendees.filter(attendee => !attendee.active);
      } else {
        return [];
      }
    },
    [attendees]
  );
  const activeAttendee = useMemo(
    () => {
      if (attendees && attendees.length) {
        return attendees.find(attendee => attendee.active);
      }
    },
    [attendees]
  );
  const activeAttendeeIndex = useMemo(
    () => {
      if (attendees && attendees.length) {
        return attendees.findIndex(attendee => attendee.active);
      }
    },
    [attendees]
  );

  // Members
  const members = getValue('members');
  const validMembers = useMemo(
    () => {
      if (members && members.length) {
        return members.filter(member => !member.active);
      } else {
        return [];
      }
    },
    [members]
  );
  const activeMember = useMemo(
    () => {
      if (members && members.length) {
        return members.find(member => member.active);
      }
    },
    [members]
  );
  const activeMemberIndex = useMemo(
    () => {
      if (members && members.length) {
        return members.findIndex(member => member.active);
      }
    },
    [members]
  );

  // Contact Members
  const contact_members = getValue('contact_members');
  const validContactMembers = useMemo(
    () => {
      if (contact_members && contact_members.length) {
        return contact_members.filter(member => !member.active);
      } else {
        return [];
      }
    },
    [contact_members]
  );
  const activeContactMember = useMemo(
    () => {
      if (contact_members && contact_members.length) {
        return contact_members.find(member => member.active);
      }
    },
    [contact_members]
  );
  const activeContactMemberIndex = useMemo(
    () => {
      if (contact_members && contact_members.length) {
        return contact_members.findIndex(member => member.active);
      }
    },
    [contact_members]
  );

  const handleChangeDate = useCallback((name, value) => {
    handleChange(name)(value && toDb(value));
  }, [ handleChange ]);

  const handleChangeRadio = useCallback((e) => {
    handleChange(e.target.name)(e.target.value);
  }, [ handleChange ]);

  const handleAddToList = useCallback(
    (name) => {
      let value = [...getValue(name) || []];
      const alreadyActiveItem = value.find(item => item.active);
      if (alreadyActiveItem) {
        if (alreadyActiveItem.new) {
          value.splice(
            value.findIndex(item => item.active), 1
          )
        } else {
          delete alreadyActiveItem.active;
        }
      }
      value.push({new: true, active: true});
      handleChange(name)(value);
    },
    [handleChange, getValue]
  );

  const handleSaveToList = useCallback(
    (name, index, item) => {
      let value = [...getValue(name)];
      if (typeof index == 'number') {
        value[index] = item;
      } else {
        value.push(item);
      }
      handleChange(name)(value);
    },
    [handleChange, getValue]
  );

  const makeListItemInactive = useCallback(
    (list, index) => {
      let item = list[index];
      if (item.new) {
        list.splice(index, 1);
      }
      else {
        delete item.active;
      }
      return list;
    },
    []
  );

  const handleCancelSaveToList = useCallback(
    (name, index) => {
      let value = makeListItemInactive([...getValue(name)], index);
      handleChange(name)(value);
    },
    [getValue, handleChange, makeListItemInactive]
  );

  const handleEditListItem = useCallback(
    (name, index) => {
      let value = [...getValue(name)];
      const alreadyActiveItemIndex = value.findIndex(item => item.active);
      value[index].active = true;
      if (alreadyActiveItemIndex >= 0) {
        value = makeListItemInactive(value, alreadyActiveItemIndex);
      }
      handleChange(name)(value);
    },
    [handleChange, getValue, makeListItemInactive]
  );

  const handleDeleteListItem = useCallback(
    (name, index) => {
      let value = [...getValue(name)];
      value.splice(index, 1);
      handleChange(name)(value);
    },
    [handleChange, getValue]
  );

  const handleBackOrSkip = useCallback(
    (action) => {
      const actionUrl = action === 'skip'
        ? `/equality-plan/${plan.id}/${stage === 'committee' ? 'committee' : 'committee-followup'}/document-manager`
        : `/equality-plan/${plan.id}/`;

      if (dirtyFields.length > 0) {
        Modal.confirm({
          title: t.equality_plan_exit_without_save,
          content: t.equality_plan_exit_without_save_description,
          okText: t.equality_plan_exit_without_save_ok,
          cancelText: t.equality_plan_exit_without_save_cancel,
          onOk() {
            history.push(actionUrl);
          },
        })
      } else {
        history.push(actionUrl);
      }
    },
    [dirtyFields, plan, history, t, stage]
  );

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t[`equality_${stage}`]}
        isDocumentManager={false}
        handleBackOrSkip={handleBackOrSkip}
      />
      <Form onFinish={handleSubmit} layout="vertical">
        <Row>
          <Col span={20}>
            <Row type="flex" align="middle">
              <span>{t[`equality_${stage}_complete_data`]}</span>&nbsp;&nbsp;
              <Popover
                placement="bottom"
                content={
                  <p className='popover-text'>
                    <ReactMarkdown
                      source={t[`equality_${stage}_form_help_text`]}
                    />
                  </p>
                }
                trigger="click"
              >
                <FontAwesomeIcon
                  className='icon-large icon-yellow' 
                  icon={'lightbulb'}
                />
              </Popover>
            </Row>
          </Col>
          <Col span={2}>
            <Button
                type="primary"
                onClick={() => handleBackOrSkip('skip')}
              >
                { t.skip }
            </Button>
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              htmlType="submit"
            >
              { t.done }
            </Button>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Card className="form-input-card">
              <Form.Item
                hasFeedback
                validateStatus={ showError('organization_name') ? 'error' : '' }
                help={ showError('organization_name') }
                label={ t.organization_name }
                colon={false}
              >
                <Input
                  placeholder={ t.organization_name }
                  name="organization_name"
                  value={ getValue('organization_name') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Card>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Card className="form-input-card">
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    validateStatus={ showError('place') ? 'error' : '' }
                    help={ showError('place') }
                    label={t[`equality_${stage}_place_date_time`]}
                    colon={false}
                  >
                    <Input
                      name="place"
                      placeholder={ t.place }
                      value={ getValue('place') }
                      onChange={handleChangeEvent}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    validateStatus={ showError('date') ? 'error' : '' }
                    label={' '}
                    help={ showError('date') }
                    colon={false}
                  >
                    <DatePicker
                      className='equality-plan-datepicker'
                      dropdownClassName='equality-plan-datepicker-calendar'
                      format={DATE_TIME}
                      name="date"
                      placeholder={t.select_date_and_time}
                      showTime={{ format: 'HH:mm' }}
                      value={formatDateMoment(getValue('date'))}
                      onChange={(value) => handleChangeDate('date', value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Card className="form-input-card">
              <Form.Item
                hasFeedback
                validateStatus={ showError('collaborated_with_consultant') ? 'error' : '' }
                help={ showError('collaborated_with_consultant') }
                label={t[`equality_${stage}_collaborate`]}
                colon={false}
              >
                <Row gutter={10}>
                  <Col span={2}>
                  <Radio.Group
                    size='large'
                    name='collaborated_with_consultant'
                    onChange={handleChangeRadio}
                    value={getValue('collaborated_with_consultant')}
                  >
                    <Radio value={'yes'}>{t.yes}</Radio>
                    <Radio value={'no'}>{t.no}</Radio>
                  </Radio.Group>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      hasFeedback
                      validateStatus={ showError('consultant_name') ? 'error' : '' }
                      help={ showError('consultant_name') }
                      colon={false}
                    >
                      <Input
                        placeholder={ t.consultant_name }
                        name="consultant_name"
                        value={ getValue('consultant_name') }
                        onChange={handleChangeEvent}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Card>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <div className="form-input-card">
              <h4>{t[`equality_${stage}_attendees`]}</h4>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => handleAddToList('attendees')}
              ></Button>
            </div>
          </Col>
          { activeAttendee && (
            <Col span={24}>
              <AttendeesForm
                attendee={activeAttendee}
                index={activeAttendeeIndex}
                onSave={handleSaveToList}
                onCancel={handleCancelSaveToList} />
            </Col>
          )}
          {validAttendees.length > 0 && (
            <Col span={24} className="form-input-wrapper">
              {validAttendees.map((attendee, index) => {
                return <FormItemPreview
                  key={index}
                  sequence={index + 1}
                  onEdit={() => handleEditListItem('attendees', index)}
                  onDelete={() => handleDeleteListItem('attendees', index)}
                >
                  <div>{`${t.name}: ${attendee.name}`}</div>
                  <div>{`${t.nationality}: ${attendee.nationality}`}</div>
                  <div>{`${t.id}: ${attendee.id}`}</div>
                  <div>{`${t.phone}: ${attendee.phone}`}</div>
                  { attendee.post
                    ? <div>{`${t.equality_plan_member_post}: ${attendee.post}`}</div>
                    : null
                  }
                  <div>{`${t.address}: ${attendee.address}`}</div>
                </FormItemPreview>;
              })}
            </Col>
          )}
          <Col span={24} className="form-input-wrapper">
            <div className="form-input-card">
              <h4>{t[`equality_${stage}_members`]}</h4>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => handleAddToList('members')}
              ></Button>
            </div>
          </Col>
          { activeMember && (
            <Col span={24}>
              <MembersForm
                type="members"
                member={activeMember}
                index={activeMemberIndex}
                onSave={handleSaveToList}
                onCancel={handleCancelSaveToList} />
            </Col>
          )}
          {validMembers.length > 0 && (
            <Col span={24} className="form-input-wrapper">
              {validMembers.map((member, index) => {
                return <FormItemPreview
                  key={index}
                  sequence={index + 1}
                  onEdit={() => handleEditListItem('members', index)}
                  onDelete={() => handleDeleteListItem('members', index)}
                >
                  <div>{`${t.name}: ${member.name}`}</div>
                  { member.email
                    ? <div>{`${t.email}: ${member.email}`}</div>
                    : null
                  }
                  { member.post
                    ? <div>{`${t.equality_plan_member_post}: ${member.post}`}</div>
                    : null
                  }
                  <div>{`${t.is_legal_representative}: ${member.is_legal_representative}`}</div>
                </FormItemPreview>;
              })}
            </Col>
          )}
          <Col span={24} className="form-input-wrapper">
            <div className="form-input-card">
              <h4>{t[`equality_${stage}_members_contact`]}</h4>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => handleAddToList('contact_members')}
              ></Button>
            </div>
          </Col>
          { activeContactMember && (
            <Col span={24}>
              <MembersForm
                type="contact_members"
                member={activeContactMember}
                index={activeContactMemberIndex}
                onSave={handleSaveToList}
                onCancel={handleCancelSaveToList} />
            </Col>
          )}
          {validContactMembers.length > 0 && (
            <Col span={24} className="form-input-wrapper">
              {validContactMembers.map((member, index) => {
                return <FormItemPreview
                  key={index}
                  sequence={index + 1}
                  onEdit={() => handleEditListItem('contact_members', index)}
                  onDelete={() => handleDeleteListItem('contact_members', index)}
                >
                  <div>{`${t.name}: ${member.name}`}</div>
                  { member.email
                    ? <div>{`${t.email}: ${member.email}`}</div>
                    : null
                  }
                  { member.post
                    ? <div>{`${t.equality_plan_member_post}: ${member.post}`}</div>
                    : null
                  }
                  <div>{`${t.is_legal_representative}: ${member.is_legal_representative}`}</div>
                </FormItemPreview>;
              })}
            </Col>
          )}
        </Row>
      </Form>
    </>
  )
};

export default withRouter(injectIntl(CommitteeForm));
