import { DEFAULT_TABLE_CUSTOM_VIEWS_SET } from "hooks/useTableCustomView/constants";

const TARGET = 'table_custom_view';
const isTarget = (target) => target === TARGET;

const initialState = DEFAULT_TABLE_CUSTOM_VIEWS_SET;

const reducer = (state = initialState, action) => {
  const tableCustomViewState = state[action.table] || []; 
  switch (action.type) {
    case 'SET_STATE_TOGGLE':
      if(!isTarget(action.target)) {
        return state;
      }
      const tableCustomViewValue = tableCustomViewState.includes(action.param) ? tableCustomViewState.filter((e) => e !== action.param) : [...tableCustomViewState, action.param];
      return {
        ...state,
        [action.table]: tableCustomViewValue
      };
    case 'SET_ALL':
    case 'RESET_ALL':
      if(!isTarget(action.target)) {
        return state;
      }
      return {
        ...state,
        [action.table]: action.param
      };
    case 'ORGANIZATION_RESET':
    case 'LOGOUT_REQUEST':
      return initialState;
    case 'HYDRATE_STORE':
      return action.state && action.state.table_custom_view
        ? action.state.table_custom_view
        : state;
    default:
      return state;
  }
};

export { reducer as table_custom_view };
