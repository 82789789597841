import { loop, Cmd } from 'redux-loop';
import { changeLocale } from '../intl';  // NOTICE: 'intl' is a node package

const TARGET = 'profile';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(action.target !== TARGET) return state;
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || action.response.target !== TARGET) return state;
      const result = action.response.result;
      if(result && result.language !== (state.data || {}).language) {
        return loop({
          data: result,
          loading: false,
          error: null,
        }, Cmd.action(changeLocale(result.language)));
      }
      return {
        data: result,
        loading: false,
        error: null,
      };
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        loading: false,
        error: action.code,
      };
    default:
      return state;
  }
};

export {
  reducer as profile,
};
