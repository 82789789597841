import React from 'react';
import PropTypes from 'prop-types';
import {formatMessage} from '../../../intl'
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import BooleanTableCard from './BooleanTableCard';

const BooleanCard = React.forwardRef(({ parameters, data }, ref) => {
  
  const intl = useIntl();
  const {
    nodata = intl.messages.no_data,
  } = parameters;

  const isTable = !isEmpty(data) && (parameters.nodeLevel === 2 || data.labels.length > 1);
  const hasData = isTable 
                    ? data.values.some(nodeData => nodeData.data.some( periodValue => typeof periodValue.value === 'boolean'))
                    : !isEmpty(data) && typeof data.values[0].data[0].value === 'boolean'; 
  
  return (
    <div className="DashboardComponent__card_content">
      {
        !hasData
        ?
          <div
            className="DashboardComponent__nodata"
          >
            { nodata }
          </div>
        : (
          <div className="DashboardComponent__booleancard">
            {
              isTable 
                ? <BooleanTableCard data={ data } parameters={ parameters } />
                : <output>{ data.values[0].data[0].value ? formatMessage('yes') : formatMessage('no') }</output>
            }
          </div>
        )
      }
    </div>
  );
});

BooleanCard.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.object,
};

export default BooleanCard;

