import {
  isAlreadyChild,
  isFirstIndicator,
  isNextIndicator,
} from "utils/reporting_structure";

const isIndicatorDroppedInsideIndicator = (
  dropProps,
) => {
  const {
    dropToGap,
    referenceNodeWhenDrop,
  } = dropProps;

  return !dropToGap && !referenceNodeWhenDrop.isCategory;
};

const isIndicatorDroppedOutsideCategory = (
  dropProps,
) => {
  const {
    isCategory: isCategoryReferenceNodeWhenDrop,
    parent_uuid: parentUuidReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return dropProps.dropToGap
    && isCategoryReferenceNodeWhenDrop
    && !parentUuidReferenceNodeWhenDrop;
};

// NOTICE: Cannot be same slug and different uuid
const isIndicatorDroppedInsideCategoryThatAlreadyHasIt = (
  indicator,
  dropProps,
  reportingStructure,
) => {
  const {
    uuid: uuidIndicator,
    slug: slugIndicator,
  } = indicator;
  const {
    children: childrenReferenceNodeWhenDrop,
    parent_uuid: parentUuidReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return (!dropProps.dropToGap && childrenReferenceNodeWhenDrop.some(({slug, uuid, isCategory}) => !isCategory && slug === slugIndicator && uuid !== uuidIndicator))
  || (dropProps.dropToGap && isAlreadyChild(reportingStructure, parentUuidReferenceNodeWhenDrop, indicator));
};

const isIndicatorDroppedInsideTopOfCategoryAndAlreadyHasFirstPosition = (
  indicator,
  dropProps,
  reportingStructure,
) => {
  const {
    uuid: uuidIndicator,
    parent_uuid: parentUuidIndicator,
  } = indicator;
  const {
    uuid: uuidReferenceNodeWhenDrop,
    expanded: isExpandedReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return !dropProps.dropToGap
    && isExpandedReferenceNodeWhenDrop
    && uuidReferenceNodeWhenDrop === parentUuidIndicator
    && isFirstIndicator(reportingStructure, uuidIndicator);
};

const isIndicatorDroppedBetweenCategories = (
  dropProps,
) => {
  const {
    isCategory: isCategoryReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return dropProps.dropToGap && isCategoryReferenceNodeWhenDrop;
};

const isIndicatorDroppedInSameParentAndPosition = (
  indicator,
  dropProps,
  reportingStructure,
) => {
  const {
    uuid: uuidIndicator,
    parent_uuid: parentUuidIndicator,
  } = indicator;
  const {
    parent_uuid: parentUuidReferenceNodeWhenDrop,
    uuid: uuidReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return dropProps.dropToGap
    && parentUuidIndicator === parentUuidReferenceNodeWhenDrop
    && isNextIndicator(reportingStructure, uuidReferenceNodeWhenDrop, uuidIndicator);
};

export const canDropIndicator = (
  indicator,
  dropProps,
  reportingStructure,
) => {
  if (isIndicatorDroppedInsideIndicator(dropProps)) {
    return false;
  }

  if (isIndicatorDroppedOutsideCategory(dropProps)) {
    return false;
  }

  if (isIndicatorDroppedInsideCategoryThatAlreadyHasIt(indicator, dropProps, reportingStructure)) {
    return false;
  }

  if (isIndicatorDroppedInsideTopOfCategoryAndAlreadyHasFirstPosition(indicator, dropProps, reportingStructure)) {
    return false;
  }

  if (isIndicatorDroppedBetweenCategories(dropProps)) {
    return false;
  }

  if (isIndicatorDroppedInSameParentAndPosition(indicator, dropProps, reportingStructure)) {
    return false;
  }

  return true;
};

const isIndicatorDroppedInsideNotExpandedOrEmptyCategory = (
  dropProps,
) => {
  const {
    children: childrenReferenceNodeWhenDrop,
    expanded: isExpandedReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return !dropProps.dropToGap && (!isExpandedReferenceNodeWhenDrop || childrenReferenceNodeWhenDrop?.length === 0);
};

const isIndicatorDroppedInsideTopOfCategory = (
  dropProps,
) => {
  const {
    expanded: isExpandedReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;

  return !dropProps.dropToGap && isExpandedReferenceNodeWhenDrop;
};

export const getPrevReferenceIndicatorUuid = (
  dropProps,
) => {

  if (!dropProps.hasOwnProperty('dropToGap') || !dropProps.hasOwnProperty('referenceNodeWhenDrop') || !dropProps.hasOwnProperty('isNodeDroppedAtTheTopOfAllRoots')) {
    throw new Error(`Wrong dropProps object`);
  }

  const {
    uuid: uuidReferenceNodeWhenDrop,
  } = dropProps.referenceNodeWhenDrop;


  if (isIndicatorDroppedInsideNotExpandedOrEmptyCategory(dropProps) || isIndicatorDroppedInsideTopOfCategory(dropProps)) {
    return null;
  }

  return uuidReferenceNodeWhenDrop;
};