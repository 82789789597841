import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Line } from 'react-chartjs-2';
import { isNumber } from 'lodash';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';
import useForm from 'utils/useForm';
import { fullValidation as validation } from './validation';
import T from 'components/T';
import {
  getPointsRangeForPositions,
  getSalaryForPoints,
} from 'utils/equality';

import {
  requestEqualityPlanFactors,
  requestEqualityPlanPositions,
} from 'actions/api';

import {
  Row,
  Col,
  Form,
  InputNumber,
  Radio,
} from 'antd';
import FloatingButton from 'components/FloatingButton';

import './style.less';
import { defaultColors } from 'components/Dashboard/useColors';


const PointsSalarySuggestion = ({
  intl,
  history,
  plan,
  updatePlan,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();
  const defaults = useMemo(() => ({
    salary_min: plan.job_evaluation?.salary_min,
    salary_max: plan.job_evaluation?.salary_max,
    salary_function: plan.job_evaluation?.salary_function,
  }), [plan]);
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    updatePlan({
      job_evaluation: {
        ...plan.job_evaluation,
        ...values,
      },
      progress: [...new Set([...plan.progress, `points_salary_suggestion`])],
    });
  };

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    dirtyFields,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanFactors(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanPositions(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetching: fetchingFactors,
    data: factors,
  } = useSelector(state => state.equality_plan_factor);
  const {
    fetching: fetchingPositions,
    data: positions,
  } = useSelector(state => state.equality_plan_position);

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  const pointsRange = useMemo(
    () => {
      if (!positions || !factors) {
        return [0, 100];
      }

      return getPointsRangeForPositions(
        positions, factors, plan.job_evaluation.type_points
      );
    },
    [positions, factors, plan]
  );

  const getFunctionValues = useCallback(
    (functionNumber) => {
      const minSalary = getValue('salary_min');
      const maxSalary = getValue('salary_max');

      return [
        minSalary,
        getSalaryForPoints(
          pointsRange[0], pointsRange[1], minSalary, maxSalary
        )(
          functionNumber
        )(
          pointsRange[0] + ((pointsRange[1] - pointsRange[0]) / 2)
        ),
        maxSalary,
      ]
    },
    [getValue, pointsRange]
  );

  const graphData = useMemo(
    () => ({
      labels: [
        `${t.equality_job_evaluation_points_salary_suggestion_points_min} (${pointsRange[0]})`,
        t.equality_job_evaluation_points_salary_suggestion_points_mid,
        `${t.equality_job_evaluation_points_salary_suggestion_points_max} (${pointsRange[1]})`,
      ],
      datasets: [1, 2, 3, 4, 5].map(functionNumber => ({
        label: `${t.equality_job_evaluation_points_salary_suggestion_salary_function_title} ${functionNumber}`,
        data: getFunctionValues(functionNumber - 1),
        backgroundColor: defaultColors[functionNumber - 1],
        borderColor: defaultColors[functionNumber - 1],
        fill: false,
      })),
    }),
    [getFunctionValues, t, pointsRange]
  );

  if (
    fetchingFactors
    || !factors
    || fetchingPositions
    || !positions
  ) {
    return <Loading />;
  }

  return (
    <div className="PointsSalarySuggestion">
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_points_salary_suggestion}
        handleBackOrSkip={handleBack}
      />
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <Form.Item
            hasFeedback
            validateStatus={ showError('salary_max') ? 'error' : '' }
            help={ showError('salary_max') }
            label={ t.equality_job_evaluation_points_salary_suggestion_salary_max }
            colon={false}
            required
          >
            <InputNumber
              placeholder={ t.equality_job_evaluation_points_salary_suggestion_salary_max }
              name="salary_max"
              value={ getValue('salary_max') }
              onChange={handleChange('salary_max')}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            hasFeedback
            validateStatus={ showError('salary_min') ? 'error' : '' }
            help={ showError('salary_min') }
            label={ t.equality_job_evaluation_points_salary_suggestion_salary_min }
            colon={false}
            required
          >
            <InputNumber
              placeholder={ t.equality_job_evaluation_points_salary_suggestion_salary_min }
              name="salary_min"
              value={ getValue('salary_min') }
              onChange={handleChange('salary_min')}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            hasFeedback
            validateStatus={ showError('salary_function') ? 'error' : '' }
            help={ showError('salary_function') }
            label={ t.equality_job_evaluation_points_salary_suggestion_salary_function }
            colon={false}
            required
          >
            <Radio.Group
              onChange={e => handleChange('salary_function')(e.target.value)}
              value={getValue('salary_function')}
            >
              {
                [1, 2, 3, 4, 5].map(value =>
                  <Radio
                    value={value}
                    key={value}
                  >
                    <T equality_job_evaluation_points_salary_suggestion_salary_function_option={{number: value}} />
                  </Radio>
                )
              }
            </Radio.Group>
          </Form.Item>
        </Col>
        {(isNumber(getValue('salary_min')) && isNumber(getValue('salary_max')))
          && <Col span={24} className="PointsSalarySuggestion-graph">
              <Line
                data={graphData}
                height='500px'
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      min: pointsRange[0],
                      max: pointsRange[1],
                      ticks: {
                        
                      },
                    },
                    y: {
                      min: getValue('salary_min'),
                      max: getValue('salary_max'),
                      ticks: {
                      },
                    }
                  },
                  lineTension: 0,
                  plugins: {
                    legend: { 
                      align: 'start',
                      position: 'bottom',
                      labels: {
                        usePointStyle: true,
                        pointStyle: 'rectRounded'
                      }
                    }
                  }
                }}
              />
            </Col>
        }
      </Row>
      <FloatingButton
        onClick={handleSubmit}
        iconUrl='/images/fa-save-regular.svg'
        disabled={dirtyFields.length === 0}
      />
    </div>
  );
}

export default withRouter(injectIntl(PointsSalarySuggestion));
