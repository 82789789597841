import React, {
  useEffect,
  useMemo,
} from 'react';
import { withRouter } from 'react-router';
import { Link } from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { FormattedMessage } from 'react-intl';

import {
  fetchConfigData,
  fetchRequestDetail,
} from 'actions/thirdParty';

import { queryStringToObject } from 'utils/queryParameters';
import useScreenSize from 'utils/useScreenSize';

import { Loading } from 'tsComponents/emptyStates/Loading';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { Error } from 'tsComponents/emptyStates/Error';
import { emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';
import { getErrorProps } from 'tsComponents/emptyStates/errorProps';
import { goBack, useContactUs} from 'tsComponents/emptyStates/helpers';
import { ButtonGroup } from 'tsComponents/button/ButtonGroup';
import AplanetIcon from 'components/AplanetIcon';
import Kpi from './Kpi';

import './style.less';

// TODO: System to handle backend errors on the frontend
const ERROR_CODES = {
  badRequest: 400,
  notFound: 404,
  internalServerError: 500
};

const KpiRequestScreen = ({
  match,
  location
}) => {
  const dispatch = useDispatch();
  const screenSize = useScreenSize();

  const { token, request_id } = match.params;

  const {
    kpi_request_detail,
    orgs_with_dr,
    selector_orgs,
    initialLoading,
    code,
  } = useSelector(state => state.third_party);

  const queryStringObject = useMemo(() => {
    const obj = queryStringToObject(location?.search);
    return obj;
  }, [location?.search])

  useEffect(() => {
    if (!orgs_with_dr) {
      dispatch(fetchConfigData(token, queryStringObject.toplevelorg));
    }
  }, [
    dispatch,
    orgs_with_dr,
    queryStringObject.toplevelorg,
    token,
  ]);

  const organization = useMemo(
    () => selector_orgs?.find(({slug}) => slug === queryStringObject.toplevelorg),
    [queryStringObject.toplevelorg, selector_orgs]
  );

  const suborganization = useMemo(() => {
    return orgs_with_dr?.find(({ slug }) => slug === queryStringObject.suborganization);
  }, [orgs_with_dr, queryStringObject.suborganization]);

  useEffect(() => {
    if (organization?.slug && suborganization?.slug) {
      dispatch(fetchRequestDetail(token, organization.slug, suborganization.slug, request_id));
    }
  }, [
    dispatch,
    organization?.slug,
    suborganization?.slug,
    request_id,
    token,
  ]);

  const contactUs = useContactUs()

  if(screenSize === 'sm' || screenSize === 'xs') {
    return (
      <section className='ThirdPartyRequestsKpiRequestScreen'>
        <Empty {...emptyPropsMap.get("landingMobile")} />
      </section>
    )
  }

  if(code === ERROR_CODES.internalServerError || !queryStringObject?.toplevelorg || !queryStringObject?.suborganization) {
    return <Error {...getErrorProps("internalServerError")}>
      <ButtonGroup>
        <button className='button--secondary' onClick={goBack}>
          <AplanetIcon name='Arrow to left' />
          <FormattedMessage id="back" />
        </button>
        <a className='button--primary' href={`mailto:${contactUs}`}>
          <AplanetIcon name='Contact' />
          <FormattedMessage id="nav_contact_us" />
        </a>
      </ButtonGroup>
    </Error>
  }

  // NOTICE: This is a temporary empty state for deleted DR until the screen is redesigned. 
  if(code === ERROR_CODES.notFound || code === ERROR_CODES.badRequest || (orgs_with_dr && !orgs_with_dr.length)) {
    return <Empty {...emptyPropsMap.get("noAccess")}>
      <ButtonGroup>
        <Link className='button--tertiary' to={`/request/${token}`}>
          <AplanetIcon name='Arrow to left' />
          <FormattedMessage id="back" />
        </Link>
      </ButtonGroup>
    </Empty>
  }

  return !orgs_with_dr || !selector_orgs || !organization || !suborganization || !kpi_request_detail || initialLoading ? (
    <Loading />
  ) : (
    <Kpi
      token={token}
      organization={organization}
      suborganization={suborganization}
    />
  );
};

export default withRouter(KpiRequestScreen);

