import SdgLogo from "../SdgLogo";
import React, {useMemo} from "react";
import { injectIntl } from "react-intl";
import { useSDGsClustering } from "../../hooks/sdg";
import SdgMiniLogo from "../SdgMiniLogo";
import './style.less';

function getParents(clusteredSDGs) {
  return clusteredSDGs.reduce((acc, sdg) => [...acc.filter(s => s !== sdg.parent_slug), sdg.parent_slug], []);
}

function getParentsTargets(clusteredSDGs, parent_slug) {
  return clusteredSDGs.filter(sdg => sdg.slug !== sdg.parent_slug && sdg.parent_slug === parent_slug);
}

const List = ({intl, sdgs, className, square}) => {
  const clusterSDGs = useSDGsClustering();
  const clusteredSDGs = useMemo(() => clusterSDGs(sdgs), [sdgs, clusterSDGs]);

  if (!clusteredSDGs) return null;

  return getParents(clusteredSDGs).map(parent_slug => (
    <div key={parent_slug} className='SDGList-targets'>
      <SdgLogo
        key={parent_slug}
        className={className}
        sdg={parent_slug}
        square={square}
        alt={intl.formatMessage({ id: `sdg_${parent_slug}` })}
      />
      {getParentsTargets(clusteredSDGs, parent_slug).map(target => (
        <SdgMiniLogo
          key={target.slug}
          sdg={target}
        />
      ))}
    </div>
  ))
}

export const SDGLogoList = injectIntl(List);
