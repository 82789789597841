import { loop, Cmd } from 'redux-loop';


const TARGET = 'organization_kpi_member';
const BULK_TARGET = 'organization_kpi_member_bulk';
const SYNC_TARGET = 'organization_kpi_member_sync_child_nodes';

const isTarget = (target) => [
  TARGET,
  BULK_TARGET,
  SYNC_TARGET,
].includes(target);

const initialState = {
  error: null,
  data: [],
  loading: false,
  pushing: false,
};

const setOwnersUptodate = (owners_uptodate) => {
  return {
    type: 'SET_OWNERS_UPTODATE',
    owners_uptodate,
  };
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if (!isTarget(action.target)) return state;
      return {
        ...state,
        error: null,
        loading: action.method === 'GET',
        pushing: action.method !== 'GET',
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || !isTarget(response.target)) return state;
      const result = response.result;
      switch(response.method) {
        case 'GET':
          return {
            ...state,
            loading: false,
            pushing: false,
            data: result,
          };
        case 'POST':
          if (response.target === TARGET) {
            return loop({
              ...state,
              loading: false,
              pushing: false,
              data: [...result],
            }, Cmd.action(setOwnersUptodate(false)));
          }
          if (response.target === SYNC_TARGET) {
            return loop({
              ...state,
              loading: false,
              pushing: false,
            }, Cmd.action(setOwnersUptodate(true)));
          }
          return {
            ...state,
            loading: false,
            pushing: false,
          };
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state,
        loading: false,
        pushing: false,
        error: action.code,
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        loading: false,
        pushing: false,
        error: null
      };
    default:
      return state;
  }
};

export {
  reducer as organization_kpi_member,
};
