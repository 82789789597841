// These affect the MAIN org
const resetOrganization = () => ({
  type: 'ORGANIZATION_RESET',
});

const setOrganization = (slug) => ({
  type: 'ORGANIZATION_SET',
  slug,
});

// This affects the suborg
const switchCurrentOrganization = (organization) => ({
  type: 'SWITCH_CURRENT_ORGANIZATION',
  organization,
});

export {
  resetOrganization,
  setOrganization,
  switchCurrentOrganization,
}
