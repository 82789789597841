import React, {
  useCallback,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import config from 'config';

import { Layout, Card } from 'antd';

import './style.less';

import OrganizationLogo from 'components/OrganizationLogo';
import SignedOutFooter from 'components/SignedOutFooter';

import {logout} from 'actions/auth';

import {
  useDispatch,
} from 'react-redux';

const {
  Content,
} = Layout;

// This is a horrible workaround for themes
const HOST_TO_ORG_MAPPING = {
  'esg.cafgroup.net': 'caf-global-caf',
  'solina.aplanet.org': 'solina',
  'solina-pre.aplanet.org': 'solina',
  // 'makeithappen-backoffice.novasbe.pt': 'nova-sbe',
  //'localhost:3003': 'aplanet',
};

const AccountsRedirect = ({
  intl,
  organization,
  location,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();

  const handlePathname = useCallback((pathname) => {
    switch(pathname) {
      case '/logout':
        dispatch(logout());
        break;
      default:
        console.warn(`${pathname} no action`);
    }
  }, [dispatch]);

  useEffect(() => {
    const orgSuffix =
      HOST_TO_ORG_MAPPING[window.location.host]
      ? `&org=${HOST_TO_ORG_MAPPING[window.location.host]}`
      : (
        organization.data &&
        organization.data.slug &
        !organization.data.is_default_org
      ) ? `&org=${organization.data.slug}`
        : '';

    handlePathname(location.pathname);

    (
      window.location || document.location
    )?.replace(
      location.search
      ? `${config.ACCOUNTS_URL}${location.pathname}${location.search}&app=atlas${orgSuffix}`
      : `${config.ACCOUNTS_URL}${location.pathname}?app=atlas${orgSuffix}`
    );
  }, [organization, location.search, location.pathname, location, handlePathname]);

  return (
    <Layout className="Layout">
      <Content className="Content-noheader">
        <div className="AccountsRedirect-cardwrapper">
          <Card className="AccountsRedirect-card">
            <div className="AccountsRedirect-heading">
              <OrganizationLogo className="AccountsRedirect-logo" {...organization.data} />
              <h3>
                { t.sign_in }
              </h3>
            </div>
            <div className="AccountsRedirect-message">
              <p>
                { t.redirecting_to_accounts }
              </p>
            </div>
          </Card>
        </div>
      </Content>
      <SignedOutFooter />
    </Layout>
  )
}

const mapStateToProps = ({
  organization,
}) => ({
  organization,
});

export default injectIntl(
  connect(
    mapStateToProps,
  )(withRouter(AccountsRedirect))
);
