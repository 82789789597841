const fullValidation = (intl) => ({
  apply_periodicities,
}) => {
  let errors = {};

  if(!apply_periodicities || apply_periodicities.length === 0) {
    errors.apply_periodicities = intl.formatMessage({ id: "form_error_required"});
  }

  return errors;
};

export default fullValidation;