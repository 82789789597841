import React, { useMemo } from 'react';
import SdgMiniLogo from 'components/SdgMiniLogo';
import { useSDGsClustering } from "../../hooks/sdg";

const DataSdgs = ({
  sdgs = [],
}) => {
  const clusterSDGs = useSDGsClustering();
  const clusteredSDGs = useMemo(() => clusterSDGs(sdgs), [sdgs, clusterSDGs])
  return (
    <section className='KpiSummaryCard__card-sdgs'>
      {
        clusteredSDGs.map(sdg => (
          <SdgMiniLogo key={sdg.slug} sdg={sdg} />
        ))
      }
    </section>
  );
};

export default DataSdgs;

