import React from 'react';
import AplanetIcon from 'components/AplanetIcon';

const DataTargetIcon = ({
    className,
    style={}
})=>{
    return (
        <AplanetIcon
          name='Target'
          className={className}
          style={{...style}}
         />

    );
};

export default DataTargetIcon;