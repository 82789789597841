import React, {useCallback, useState, useEffect, useMemo, useRef} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import { reverse } from 'lodash';

import {
  DownOutlined,
  CheckOutlined,
  CaretRightOutlined,
  UpOutlined,
} from '@ant-design/icons';

import {
  getPeriodsFormat,
  toDbDate,
} from 'utils/date';

import {
  getAllPeriodsUntilTwoFromNow,
} from 'utils/collectionFrequency';

import useOutsideClick from 'utils/useOutsideClick'

import './style.less';

const PERIODICITIES = ['month', 'quarter', 'semester', 'year'];
const FORM_KEY_RECURRENCY_TYPES = {
  recurrent: 'recurrent',
  one_time: 'one_time',
};
const FORM_KEY_PERIODS = 'periods';
const FORM_KEY_PERIODICITIES = 'periodicities';
const FORM_KEY_RECURRENCY = 'recurrency';
const START_DATE_PERIODS = '2010-01-01';
const MAX_PERIODS_SELECTED = 15;

const PeriodsSelector = ({
  intl,
  type,
  handleChange,
  disabled = false,
  periodicityValues,
  allowedPeriodicities,
  periodValues,
  recurrency,
}) => {
  const [periodicitySelected, setPeriodicitySelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (disabled && isOpen) {
      setIsOpen(false);
      setPeriodicitySelected(null);
    }
    if (!isOpen) {
      setPeriodicitySelected(null);
    }
  }, [
    disabled,
    isOpen
  ]);

  const ref = useRef();

  useOutsideClick(ref, () => {
    setIsOpen(false);
    setPeriodicitySelected(null);
  });

  const periodicitiesOptions = useMemo(() => {
    return PERIODICITIES.map(periodicity => ({
      label: intl.formatMessage({id: `step_2_periods_selector_${periodicity}`}),
      value: periodicity,
      disabledOption: !allowedPeriodicities.includes(periodicity)
        || (type === FORM_KEY_RECURRENCY_TYPES.one_time && periodValues.length >= MAX_PERIODS_SELECTED && !periodicityValues.includes(periodicity)),
    }));
  }, [
    intl,
    periodValues.length,
    periodicityValues,
    allowedPeriodicities,
    type
  ]);

  const isPeriodDateChecked = useCallback((periodDate) => {
    return periodValues.some(({type, start_range_date}) => type === periodicitySelected && periodDate === start_range_date);
  }, [
    periodicitySelected,
    periodValues
  ]);

  const periodDatesByPeriodicityOptions = useMemo(() => {
    return periodicitySelected
      ? reverse(getAllPeriodsUntilTwoFromNow({startPeriodDate: START_DATE_PERIODS, periodicity: periodicitySelected}).map(({startPeriodDate, endPeriodDate}) => {
        return {
          value: toDbDate(startPeriodDate),
          label: getPeriodsFormat(startPeriodDate, endPeriodDate, intl),
          disabledOption: (type === FORM_KEY_RECURRENCY_TYPES.one_time && periodValues.length >= MAX_PERIODS_SELECTED && !isPeriodDateChecked(toDbDate(startPeriodDate))),
        };
      })) : null;
  }, [
    intl,
    isPeriodDateChecked,
    periodValues.length,
    periodicitySelected,
    type
  ]);

  const hasPeriodDateSelected = useCallback((periodicity) => {
    return periodValues.some(({type}) => type === periodicity);
  }, [
    periodValues
  ]);

  const handleOnChangePeriod = useCallback((periodDateSelected) => {
    if (!recurrency) {
      handleChange(FORM_KEY_RECURRENCY)(type);
    }

    const filteredPeriods = periodValues.filter(({type, start_range_date}) => type !== periodicitySelected || periodDateSelected !== start_range_date);
    if (isPeriodDateChecked(periodDateSelected)) {
      handleChange(FORM_KEY_PERIODICITIES)([...new Set(filteredPeriods.map(period => period.type))]);
      handleChange(FORM_KEY_PERIODS)(filteredPeriods);
    } else {
      const currentPeriods = type === FORM_KEY_RECURRENCY_TYPES.recurrent ? filteredPeriods.filter(p => p.type !== periodicitySelected) : filteredPeriods;
      const newPeriods = [...currentPeriods, {type: periodicitySelected, start_range_date: periodDateSelected}];
      handleChange(FORM_KEY_PERIODICITIES)([...new Set(newPeriods.map(period => period.type))]);
      handleChange(FORM_KEY_PERIODS)(newPeriods);
    }
  }, [
    periodicitySelected,
    isPeriodDateChecked,
    handleChange,
    periodValues,
    recurrency,
    type,
  ]);

  return (
    <div className="PeriodsSelector">
      <label className={`PeriodsSelector__label is-required ${disabled ? 'is-disabled' : ''}`}>
        {intl.formatMessage({id: `step_2_periods_selector_label_${type}`})}
      </label>
      <div className="PeriodsSelector__wrapper" ref={ref}>
        <div
          className={`
          PeriodsSelector__totalSelectedContainer
          ${disabled ? 'is-disabled' : ''}
          ${isOpen ? 'is-open' : ''}
        `}
          onClick={() => !disabled && setIsOpen(!isOpen)}
        >
          <input
            className="PeriodsSelector__totalSelected"
            value={periodValues.length > 0 ?
              intl.formatMessage({id: `step_2_periods_total_selected_${type}`}, {totalSelected: periodValues.length})
              : ''
            }
            disabled={disabled}
            placeholder={intl.formatMessage({id: 'step_2_periods_total_selected_placeholder'})}
            readOnly
          />
          <div className={`PeriodsSelector__totalSelectedIcon ${disabled ? 'is-disabled' : ''}`}>
            <DownOutlined />
          </div>
        </div>
        {isOpen ? (
          <div className="PeriodsSelector__dropdown">
            <div className="PeriodsSelector__periodicities">
              {periodicitiesOptions.map(({label, value, disabledOption}) => (
                <div
                  key={value}
                  className={`
                    PeriodsSelector__periodicityContent
                    ${hasPeriodDateSelected(value) ? 'has-selection' : ''}
                    ${periodicitySelected === value ? 'is-active' : ''}
                    ${disabledOption ? 'is-disabled' : ''}
                  `}
                  onClick={() => !disabledOption && setPeriodicitySelected(value)}
                >
                  <div title={label} className={`PeriodsSelector__periodicityLabel`}>{label}</div>
                  <CaretRightOutlined className={`PeriodsSelector__arrowRight`}/>
                </div>
              ))}
            </div>
            {periodicitySelected ? (
              <div className="PeriodsSelector__periodsContainer">
                <UpOutlined className="PeriodsSelector__arrowUp" />
                  <div className="PeriodsSelector__periods">
                    {periodDatesByPeriodicityOptions.map(({label, value, disabledOption}) => (
                      <div
                        key={value}
                        className={`
                          PeriodsSelector__periodContent
                          ${disabledOption ? 'is-disabled' : ''}
                        `}
                        onClick={() => !disabledOption && handleOnChangePeriod(value)}
                      >
                        <CheckOutlined className={`PeriodsSelector__periodChecked ${isPeriodDateChecked(value) ? 'is-checked' : ''}`}/>
                        <div title={label} className={`PeriodsSelector__periodLabel ${isPeriodDateChecked(value) ? 'is-checked' : ''}`}>{label}</div>
                      </div>
                    ))}
                  </div>
                <DownOutlined className="PeriodsSelector__arrowDown" />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
};

PeriodsSelector.propTypes = {
  type: PropTypes.oneOf(['recurrent', 'one_time']).isRequired,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  periodicityValues: PropTypes.array.isRequired,
  allowedPeriodicities: PropTypes.array.isRequired,
  periodValues: PropTypes.array.isRequired,
  recurrency: PropTypes.string,
};

export default injectIntl(PeriodsSelector);
