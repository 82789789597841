import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Pie as PieChart, Doughnut } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { getDatalabelOptions } from './types';

const DEFAULT_PARAMETERS = {
  type: 'pie',
  showDataLabels: false,
};

const COMPONENT_PER_TYPE = {
  donut: Doughnut,
  pie: PieChart,
}

// TODO: Take out parameters and expose a way to get the params for a nivo-api call
const Pie = forwardRef(({
  slug,
  parameters = DEFAULT_PARAMETERS,
  data,
  onDownload,
  dashboardTheme
}, ref) => {
  const intl = useIntl();
  const {
    nodata = intl.messages.no_data,
    type = DEFAULT_PARAMETERS.type,
    showDataLabels = DEFAULT_PARAMETERS.showDataLabels
  } = parameters;

  const Chart = COMPONENT_PER_TYPE[type];

  const chartData = useMemo(() => ({
    labels: data.labels,
    datasets: [{
      data: data.values,
      backgroundColor: dashboardTheme,
    }],
  }), [
    data,
    dashboardTheme
  ]);

  const chartOptions = useMemo(() => ({
    maintainAspectRatio: false,
    plugins: {
      legend: {
        align: 'start',
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded'
        }
      },
      datalabels: showDataLabels ? getDatalabelOptions(parameters) : false,
    }
  }), [parameters, showDataLabels]);

  // TODO: Having to set the height sucks a lot
  return (
    <div className="DashboardComponent__card_content">
      {
        !data.values || data.values.length === 0
        ?
          <div
            className="DashboardComponent__nodata"
          >
            { nodata }
          </div>
        :
          <Chart
            ref={ref}
            data={chartData}
            height='300px'
            options={chartOptions}
          />
      }
    </div>
  );
});

Pie.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.object,
};

export default Pie;
