import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import {
  useDispatch,
} from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { debounce } from 'lodash';

import useOrganizations from 'utils/useOrganizations';

import CustomInput from 'components/CustomInput';
import CustomTextArea from 'components/CustomTextArea';
import AplanetIcon from 'components/AplanetIcon';
import ReadOnlyValue from './ReadOnlyValue';

import {
  Popover,
} from 'antd';

import {
  requestKpiUpdate,
} from 'actions/api';


const Comment = ({
  kpi,
  position,
  mode,
  onChangeMode,
  canEdit = true,
}) => {
  const [initialComment, setInitialComment] = useState(kpi.comment || null);
  const [comment, setComment] = useState(kpi.comment || null);
  const [isPopoverOpen, setIsPopoverOpen] = useState();

  const dispatch = useDispatch();
  const {
    organization: root_organization,
    permissions,
  } = useOrganizations();

  useEffect(
    () => {
      if (mode !== 'edit') {
        if (
          (initialComment === comment)
          && (comment !== kpi.comment)
        ) {
          // Update all other duplicate records if one of them was changed
          setComment(kpi.comment);
          setInitialComment(kpi.comment);
        }

        if (
          (comment === kpi.comment)
          && (comment !== initialComment)
        ) {
          // Update own initial value when reducer has same value as current
          setInitialComment(kpi.comment);
        }
      }
    },
    [
      kpi.comment,
      mode,
      comment,
      initialComment,
    ],
  );

  const onChange = useCallback(comment => {
    dispatch(
      requestKpiUpdate(
        root_organization.slug,
        kpi.organization_slug,
        kpi.slug,
        kpi.period,
        {
          value: kpi.kpi_value,
          comment,
        },
        { fieldToUpdate: 'comment' },
      )
    )
  }, [
    root_organization,
    dispatch,
    kpi,
  ]);

  const onChangeDebounced = debounce(function(value) {
    onChange(value);
  }, 2000);

  const handleOnChange = useCallback(
    e => {
      const newComment = e?.target ? e.target.value : e;

      setComment(newComment);
      onChangeDebounced(newComment);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  const handleOnChangeMode = useCallback((mode) => {
    if (
      (kpi.kpi_source === 'manual') &&
      (kpi.ready_to_validate_level === 0) &&
      permissions.can_write_kpi
    ) {
      onChangeMode({
        column: 'comment',
        key: position,
        mode,
      });
    }
  }, [
    onChangeMode,
    permissions,
    position,
    kpi,
  ]);

  useEffect(() => {
    if(kpi.pendingCommentUpdate &&
      (comment === kpi.comment) && (comment === initialComment)
    ) {
      onChange(kpi.comment);
    }
  }, [
    initialComment,
    onChange,
    comment,
    kpi,
  ]);

  if (mode === 'edit') {
    return (
      <CustomInput
        value={comment}
        suffix={
          <Popover
            placement="bottomRight"
            trigger="click"
            open={isPopoverOpen}
            onOpenChange={setIsPopoverOpen}
            content={
              <CustomTextArea
                value={comment}
                onChange={handleOnChange}
                style={{width: '35vw'}}
              />
            }
            arrowPointAtCenter
          >
            <AplanetIcon
              className="DataManagement__bulk-manage__action_icon"
              name="arrow-up-right-and-arrow-down-left-from-center"
              size="15px"
            />
          </Popover>
        }
        onChange={handleOnChange}
        autoFocus
      />
    );
  }

  return (
    <ReadOnlyValue
      kpi={kpi}
      value={comment && <ReactMarkdown source={comment} />}
      mode={mode}
      changeMode={handleOnChangeMode}
      position={position}
      cellType='comment'
      canEdit={canEdit}
    />
  );
}

export default Comment;
