const toggle = ({
  target,
  param,
  filter,
  defaultValue = null,
  screen = null,
  table = null
}) => ({
  type: 'SET_STATE_TOGGLE',
  target,
  param,
  filter,
  defaultValue,
  screen,
  table,
});

const replaceAll = ({
  target,
  param = [],
  filter,
  screen = null,
  table = null
}) => ({
  type: 'SET_STATE_REPLACE_ALL',
  target,
  param,
  filter,
  screen,
  table,
});

const setAll = ({
  target,
  screen = null,
  table = null,
  param = null,
}) => ({
  type: 'SET_ALL',
  target,
  screen,
  table,
  param,
});

const resetAll = ({
  target,
  screen = null,
  table = null,
  param = null,
}) => ({
  type: 'RESET_ALL',
  target,
  screen,
  table,
  param,
});

export {
  toggle,
  replaceAll,
  setAll,
  resetAll
}
