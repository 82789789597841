import React, {
  useCallback,
  useMemo,
} from 'react';

import { injectIntl } from 'react-intl';

import { positionValidation as validation } from './validation';
import useForm from 'utils/useForm';

import {
  Row,
  Col,
  Form,
} from 'antd';

import CustomInput from 'components/CustomInput';
import CustomModal from 'components/CustomModal';
import CustomSelect from 'components/CustomSelect';


const PositionForm = ({
  intl,
  position,
  departments,
  professionalGroups,
  closeForm,
  handleSave,
}) => {
  const t = intl.messages;
  const defaults = position;
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    handleSave(values);
    resetForm();
    closeForm();
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  const handleOnCloseForm = useCallback(
    () => {
      resetForm();
      closeForm();
    },
    [resetForm, closeForm]
  );

  const departmentOptions = useMemo(
    () => {
      return departments.map(department => ({
        name: department.name,
        slug: department.id,
      }));
    },
    [departments]
  );

  const professionalGroupOptions = useMemo(
    () => {
      return professionalGroups.map(professionalGroup => ({
        name: professionalGroup.name,
        slug: professionalGroup.id,
      }));
    },
    [professionalGroups]
  );

  return (
    <CustomModal
      title={getValue('id')
        ? t.equality_job_evaluation_determination_position_edit
        : t.equality_job_evaluation_determination_position_add
      }
      shown={true}
      onOk={handleSubmit}
      onCancel={handleOnCloseForm}
      okText={t.save}
      cancelText={t.cancel}
      width={800}
    >
      <Form
        className="form-input-wrapper"
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('code') ? 'error' : '' }
              help={ showError('code') }
              label={ t.equality_job_evaluation_determination_position_code }
              colon={false}
              required
            >
              <CustomInput
                placeholder={ t.equality_job_evaluation_determination_position_code }
                name="code"
                value={ getValue('code') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('name') ? 'error' : '' }
              help={ showError('name') }
              label={ t.equality_job_evaluation_determination_position_name }
              colon={false}
              required
            >
              <CustomInput
                placeholder={ t.equality_job_evaluation_determination_position_name }
                name="name"
                value={ getValue('name') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('department_id') ? 'error' : '' }
              help={ showError('department_id') }
              label={ t.equality_job_evaluation_determination_position_department }
              colon={false}
              required
            >
              <CustomSelect
                title={t.equality_job_evaluation_determination_position_department}
                options={departmentOptions}
                onSelect={handleChange('department_id')}
                selected={getValue('department_id')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('professional_group_id') ? 'error' : '' }
              help={ showError('professional_group_id') }
              label={ t.equality_job_evaluation_determination_position_professional_group }
              colon={false}
              required
            >
              <CustomSelect
                title={t.equality_job_evaluation_determination_position_professional_group}
                options={professionalGroupOptions}
                onSelect={handleChange('professional_group_id')}
                selected={getValue('professional_group_id')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
}

export default injectIntl(PositionForm);
