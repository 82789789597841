import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { sortBy } from 'lodash';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import Form from './Form';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';
import Description from 'components/Description';

import {
  createEqualityPlanFactor,
  requestEqualityPlanFactors,
  updateEqualityPlanFactor,
  deleteEqualityPlanFactor,
} from 'actions/api';

import CustomTable from 'components/CustomTable';
import CustomButton from 'components/CustomButton';
import {
  Row,
  Col,
  Space,
  Tooltip,
  Popconfirm,
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import './style.less';


const FactorDefinition = ({
  intl,
  history,
  plan,
  updatePlanProgress,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();
  const [currentFactor, setCurrentFactor] = useState();

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanFactors(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetching,
    pushing,
    data: factors,
  } = useSelector(state => state.equality_plan_factor);

  const handleAddNewFactor = useCallback(
    () => setCurrentFactor({}),
    []
  );

  const handleEditFactor = useCallback(
    (factor) => {
      setCurrentFactor({...factor});
    },
    []
  );

  const handleSave = useCallback(
    (data) => {
      if (data.id) {
        dispatch(
          updateEqualityPlanFactor(
            organization.slug,
            suborganization.slug,
            plan.id,
            data.id,
            data,
          )
        );
      } else {
        dispatch(
          createEqualityPlanFactor(
            organization.slug,
            suborganization.slug,
            plan.id,
            data,
          )
        );
      }
      updatePlanProgress('factor_definition');
    },
    [dispatch, plan, organization, suborganization, updatePlanProgress]
  );

  const handleDeleteFactor = useCallback(
    (data) => {
      dispatch(
        deleteEqualityPlanFactor(
          organization.slug,
          suborganization.slug,
          plan.id,
          data.id,
        )
      );
    },
    [dispatch, plan, organization, suborganization]
  );

  const columns = useMemo(
    () => (
      [{
        title: t.equality_job_evaluation_factor_code,
        dataIndex: 'code',
      }, {
        title: t.equality_job_evaluation_factor_name,
        dataIndex: 'name',
      }, {
        title: t.equality_job_evaluation_factor_type,
        dataIndex: 'type',
        render: value => t[`equality_job_evaluation_factor_type_option_${value}`]
      }, {
        title: t.equality_job_evaluation_factor_gender,
        dataIndex: 'gender',
        render: value => t[`equality_job_evaluation_factor_gender_option_${value}`]
      }, {
        title: t.equality_job_evaluation_factor_description,
        dataIndex: 'description',
        render: value => value ? <Description description={value} /> : '-'
      }, {
        title: t.equality_actions_table_actions,
        key: 'actions',
        render: (_, record) => (
          <Space size="middle">
            <Tooltip title={t.equality_actions_table_actions_edit}>
              <EditOutlined
                className='form-item-preview-icon'
                onClick={() => handleEditFactor(record)}
              />
            </Tooltip>
            <Tooltip title={t.equality_actions_table_actions_delete}>
              <Popconfirm
                title={t.equality_job_evaluation_factor_delete_confirm}
                onConfirm={() => handleDeleteFactor(record)}
                okText={t.yes}
                cancelText={t.no}
              >
                <DeleteOutlined
                  className='form-item-preview-icon'
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      }]
    ),
    [t, handleEditFactor, handleDeleteFactor]
  );

  const levelColumns = useMemo(
    () => ([{
      title: t.equality_job_evaluation_factor_level,
      dataIndex: 'level',
    }, {
      title: t.equality_job_evaluation_factor_level_name,
      dataIndex: 'name',
    }, {
      title: t.equality_job_evaluation_factor_level_description,
      dataIndex: 'description',
    }, {
      title: t.equality_job_evaluation_factor_level_points,
      dataIndex: 'points',
    }]),
    [t]
  );

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  if (fetching || !factors) {
    return <Loading />;
  }

  return (
    <div className="FactorDefinition">
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_definition_of_factors}
        handleBackOrSkip={handleBack}
      />
      <Row gutter={10}>
        <Col span={24}>
          <CustomButton
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleAddNewFactor}
          >
            {t.equality_job_evaluation_factor_add_new}
          </CustomButton>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="form-input-wrapper">
          { currentFactor &&
            <Form
              visible={true}
              plan={plan}
              factor={currentFactor}
              closeForm={() => setCurrentFactor()}
              handleSave={handleSave}
              pushing={pushing}
            />
          }
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CustomTable
            columns={columns}
            dataSource={factors}
            rowKey='id'
            expandable={{
              expandedRowRender: record => (
                <Row className="FactorDefinition-levels">
                  <Col span={24}>
                    <CustomTable
                      columns={levelColumns}
                      dataSource={sortBy(record.levels, 'level')}
                      rowKey='id'
                      pagination={false}
                    />
                  </Col>
                </Row>
              ),
              rowExpandable: () => true,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default withRouter(injectIntl(FactorDefinition));
