import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Pie, Bar } from 'react-chartjs-2';
import { groupBy, sumBy } from 'lodash';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import CustomChart from 'components/CustomChart';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';
import { defaultColors } from 'components/Dashboard/useColors';

import {
  requestEqualityPlanFactors,
} from 'actions/api';

import CustomButton from 'components/CustomButton';
import {
  Row,
  Col,
} from 'antd';


const FactorPointGraphs = ({
  intl,
  history,
  plan,
  updatePlanProgress,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanFactors(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetching: fetchingFactors,
    data: factors,
  } = useSelector(state => state.equality_plan_factor);

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  const handleOnClickReviewed = useCallback(
    () => {
      updatePlanProgress('factor_point_graphs');
      handleBack();
    },
    [updatePlanProgress, handleBack]
  );

  const factorsWithPoints = useMemo(
    () => {
      if (!factors) {
        return [];
      }
      return factors.map(
        factor => ({
          ...factor,
          points: (
            factor.percentage
            * (plan.job_evaluation?.type_points?.[factor.type] || 0)
          ) / 10,
        })
      )
    },
    [plan, factors]
  );

  const totalPointsByGender = useMemo(
    () => {
      let data = {
        labels: [],
        datasets: [{
          data: [],
          backgroundColor: defaultColors,
        }],
      };

      ['male', 'female', 'neutral'].forEach(gender => {
        data.labels.push(
          t[`equality_job_evaluation_factor_gender_option_${gender}`]
        );
        const factors = factorsWithPoints
          .filter(factor => factor.gender === gender);

        data.datasets[0].data.push(sumBy(factors, 'points'));
      });
      return data;
    },
    [factorsWithPoints, t]
  );

  const factorPointsByType = useMemo(
    () => {
      let graphData = {
        labels: [],
        datasets: ['male', 'female', 'neutral'].map((gender, index) => ({
          label: t[`equality_job_evaluation_factor_gender_option_${gender}`],
          data: [],
          backgroundColor: defaultColors[index],
        })),
      };
      Object.entries(
        groupBy(factorsWithPoints, 'type')
      ).forEach(
        ([type, factors]) => {
          factors.forEach(factor => {
            graphData.labels.push(`${type}: ${factor.code}`);
            ['male', 'female', 'neutral'].forEach((gender, index) => {
              if (factor.gender === gender) {
                graphData.datasets[index].data.push(factor.points);
              } else {
                graphData.datasets[index].data.push(0);
              }
            });
          })
        }
      )
      return graphData;
    },
    [factorsWithPoints, t]
  );

  if (fetchingFactors || !factors) {
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_factor_point_graph}
        handleBackOrSkip={handleBack}
      />
      <Row type="flex" justify="end">
        <CustomButton
          type="primary"
          onClick={handleOnClickReviewed}
        >
          {t.equality_plan_reviewed}
        </CustomButton>
      </Row>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_factor_point_graph_total_points}</h2>
        </Col>
        <Col span={24}>
          <CustomChart
            title={t.equality_job_evaluation_factor_point_graph_total_points}
            Chart={Pie}
            data={totalPointsByGender}
            height='300px'
            options={{ 
              maintainAspectRatio: false,
              plugins: {
                legend: { 
                  align: 'start',
                  position: 'bottom',
                  labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded'
                  }
                }
              }
            }}
          />
        </Col>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_factor_point_graph_factor_points}</h2>
        </Col>
        <Col span={24}>
          <CustomChart
            title={t.equality_job_evaluation_factor_point_graph_factor_points}
            Chart={Bar}
            data={factorPointsByType}
            height='300px'
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: { 
                  align: 'start',
                  position: 'bottom',
                  labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded'
                  }
                }
              },
              scales: {
                x: {
                  stacked: true,
                },
                y: {
                  stacked: true,
                },
              }
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default withRouter(injectIntl(FactorPointGraphs));
