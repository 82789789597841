import { replace } from 'math-traverse';
import { cloneDeep } from 'lodash';

import {
  DEFAULT_PERIOD,
  DEFAULT_ORGANIZATION,
  ORGANIZATION_NOPARENT,
  applyAst,
  formulaToAst,
} from './math';

import {
  deepGet,
  deepUpdate,
} from 'utils/functional';

import { cartesian as cartesianProduct } from 'utils/cartesian';
import { getAdjacentPeriods } from 'utils/kpi';

const DEFAULT_UNIT = 'number'; // A sane default for ratios and all

const VALUE_PROPS = {
  'quantitative': 'value',
  'boolean': 'boolean',
  'choice': 'choice',
  'qualitative': 'text',
};

const calculateReplacements = (schema, address, value) => {
  // Calculate the replacements from the schema and address
  let replacements = {};
  let target = value;
  let targetOk = true;
  (schema?.dimensions || []).forEach((dimension, index) => {
    const { by } = dimension || {};
    const addrElem = address[index];

    if(!by || !addrElem) {
      console.log('Something went wrong processing dimension', index, dimension, addrElem);
      targetOk = false;
      return;
    }

    replacements[`&${by}`] = addrElem;
    target = target[addrElem];
  })

  if(targetOk && schema?.innerSchema?.type === 'tuple') {
    let targetReplacements = {};
    Object.entries(target).filter(([ col ]) => col && !col.startsWith('_')).forEach(([col, val]) => {
      // NOTICE: We replace the value according to the schema
      const schemaComponent = schema.innerSchema.components?.find(({ name }) => name === col)

      if(
        !val ||
        !schemaComponent ||
        !schemaComponent.type ||
        !VALUE_PROPS[schemaComponent.type] ||
        !val[VALUE_PROPS[schemaComponent.type]]
      ) {
        return;
      }

      targetReplacements[`&${col}`] = String(val[VALUE_PROPS[schemaComponent.type]]);;
    });

    return {
      ...replacements,
      ...targetReplacements,
    };
  }

  return replacements;
};

const patchAstWithReplacements = (ast, replacements = {}) => {
  const astClone = cloneDeep( ast );
  const result = replace(
    astClone,
    {
      enter: (node) => {
        if(!node){
          return node;
        }

        const filterRows = !node.filterRows || typeof node.filterRows !== 'object'
          ? node.filterRows
          : Object
              .entries(node.filterRows)
              .map(([key, val]) => {
                if(!val || !val.startsWith('&')) {
                  return [key, val];
                }

                return [key, (replacements[val] || val)];
              })
              .reduce((obj, [key, val]) => {
                obj[key] = val;
                return obj;
              }, {})

        const address = !node.address || !Array.isArray(node.address)
          ? node.address
          : node.address.map((el) => {
              if(!el || !el.startsWith('&')) {
                return el;
              }

              return replacements[el] || el;
            });

        return {
          ...node,
          address,
          filterRows,
        };
      },
    }
  );
  return result;
};

export const recalculateColumn = ({
  schema, // Full table schema
  slug,   // KPI slug
  tableDimensions,
  organization,        // This suborg slug
  organization_parent, // Parent suborg slug (important because formula engine)
  period,
  column,
  address: originalAddress = [], // NOTICE: the address where the trigerring change was made
  dependees = [],
  availableUnits = [],
}) => (value) => {
  // NOTICE: In the FE we only trigger column recalculations from different columns in the same row
  // Since this is a heterogeneous table, we just replace the last member of the address...
  const address = [
    ...((originalAddress || []).slice(0, -1)),
    column.name,
  ];

  const {
    previous: prevPeriod,
    next: nextPeriod,
  } = getAdjacentPeriods(period || DEFAULT_PERIOD);
  const context = {
    schema,
    tableDimensions,
    organization: organization || DEFAULT_ORGANIZATION,
    period: period || DEFAULT_PERIOD,
    slug,
    units: availableUnits,
    prevPeriod,
    nextPeriod,
  };

  const astValues = [
    {
      schema,
      value,
      comment: '', // TODO: get this from context/parameters
      organization: organization || DEFAULT_ORGANIZATION,
      //organization_id: organization, // TODO
      parent_organization: organization_parent || ORGANIZATION_NOPARENT,
      period: period || DEFAULT_PERIOD,
      slug,
    },
    ...dependees,
  ];

  const replacements = calculateReplacements(
    schema,
    originalAddress,
    value,
  );

  const ast = patchAstWithReplacements(
    column.ast,
    replacements,
  );

  const astResult = applyAst(
    ast,
    astValues, // TODO: more values, for dependent KPIs
    context,
  );

  const addressValue = deepGet(value, address);
  const result = deepUpdate(
    value,
    address,
    {
      value: astResult,
      _address: address,
      target_value: null, // TODO
      unit: addressValue.unit || DEFAULT_UNIT
    },
  );

  return result;
};

export const hasCalculatedColumns = (schema, slug) => {
  return getCalculatedColumns(schema, slug).length > 0;
};

export const getCalculatedColumns = (schema, slug) => {
  if(
    !schema ||
    schema.type !== 'table' ||
    !schema.innerSchema ||
    !schema.innerSchema.type
  ) {
    return [];
  }

  let components = [];
  const singletonColumnDimension = schema.dimensions.find(
    ({presentation, source}) => presentation === 'column' && source === 'singleton'
  );
  if (singletonColumnDimension) {
    components = [{
      ...schema.innerSchema,
      name: singletonColumnDimension.by,
    }];
  } else if (schema.innerSchema.type === 'tuple') {
    components = schema.innerSchema.components || [];
  }
  return components
    .map((component, index) => {
      if (!(component.type === 'quantitative' && component.source === 'calculated' && !!component.formula)) {
        return null;
      }

      let ast = null;
      try {
        // NOTICE: Addresses might include &references
        ast = formulaToAst(
          component.formula,
          {
            schema: component,
            default_kpi_slug: slug,
            schemaUnit: component.allowedUnitSlugs[0],
          },
        );
      } catch(err) {
        console.log('Failed to parse column formula', component.formula, '... ignoring');
      }
      return {
        ...component,
        ast,
        position: index,
      }
    }).filter(component => component && !!component.ast)
};

export const recalculateAllColumns = ({
  schema,
  slug,
  value,
  tableDimensions,
  organization,
  organization_parent,
  period,
  dependees = [],
  availableUnits = [],
}) => {
  const calculatedColumns = getCalculatedColumns(schema, slug);

  if(calculatedColumns.length <= 0) {
    return value;
  }

  let finalValue = cloneDeep( value );

  const dimensionValues = ((schema || {}).dimensions || []).filter(({presentation}) => presentation === 'row').map(({
    source,
    by,
    standardItems = [],
  }, depth) => {
    let result = [];

    switch(source) {
      case 'organization':
        result = (
          // TODO: Check this...
          tableDimensions[by] || []
        );
        break;
      case 'singleton':
        result = [by];
        break;
      case 'standard':
        result = standardItems.map(({ slug }) => slug);
        break;
      case 'user':
        result = standardItems.map(({ slug }) => slug);
        result = (depth === 0)
          ? result
              .concat(
                Object.keys(value || {})
                  .filter(slug => !result.includes(slug))
                  .sort(
                    (a, b) => ((value || {})[a].order || 1)
                      - ((value || {})[b].order || 1)
                  )
              )
              .filter(Boolean)
          : []
        break;
      default:
        result = (depth === 0)
          ? Object.keys(value)
          : []
    }

    return result;
  });

  const rows = cartesianProduct(
    ...dimensionValues
  );

  rows.forEach((rowAddress) => {
    calculatedColumns.forEach(column => {
      // NOTICE: We "fake" changing this very same value
      const address = [
        ...(rowAddress || []),
        column.name,
      ];

      finalValue = recalculateColumn({
        schema,
        slug,
        tableDimensions,
        organization,
        organization_parent,
        period,
        column,
        address,
        dependees,
        availableUnits: schema.innerSchema.type === 'tuple' ? availableUnits[column.position] : availableUnits,
      })(finalValue);
    });
  });

  return finalValue;
};

// NOTICE: This adapts the backend "dependee_kpis" field in KpiDetail
//         To the format used by the formula engine.
//         It needs to exist to setup the defaults, so renaming a few fields
//         here was deemed not great code, but good enough for now (:
export const adaptDependeeKpiForColumns = (dependee) => {
  const {
    applies,
    schema,
    value,
    comment,
    organization_slug,
    organization_parent_slug,
    period,
    slug,
  } = dependee || {};

  return {
    applies,
    schema,
    value,
    comment,
    organization: organization_slug || DEFAULT_ORGANIZATION,
    parent_organization: organization_parent_slug || ORGANIZATION_NOPARENT,
    period: period || DEFAULT_PERIOD,
    slug,
  };
};

