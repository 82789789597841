import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Button,
  Card,
  Input,
  Form,
  Checkbox,
} from 'antd';

import {membersValidation as validation} from './validation';
import useForm from 'utils/useForm';
import {
} from '../../actions/api';

import './style.less';

const MembersForm = ({
  intl,
  type,
  member,
  index,
  onSave,
  onCancel,
}) => {
  const t = intl.messages;
  const defaults = member;
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    const {
      name, email, is_legal_representative, post,
    } = values;
    onSave(
      type, index, {
        name,
        email,
        is_legal_representative: is_legal_representative || t.no,
        post,
      }
    );
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  return (
    <Row>
      <Col span={24} className="form-input-wrapper">
        <Card className="form-input-card">
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('name') ? 'error' : '' }
                help={ showError('name') }
                label={ t.name }
                colon={false}
                required
              >
                <Input
                  placeholder={ t.name }
                  name="name"
                  value={ getValue('name') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('email') ? 'error' : '' }
                help={ showError('email') }
                label={ t.email }
                colon={false}
              >
                <Input
                  placeholder={ t.email }
                  name="email"
                  value={ getValue('email') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('post') ? 'error' : '' }
                help={ showError('post') }
                label={ t.equality_plan_member_post }
                colon={false}
              >
                <Input
                  placeholder={ t.equality_plan_member_post }
                  name="post"
                  value={ getValue('post') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('is_legal_representative') ? 'error' : '' }
                label={t.is_legal_representative}
                help={ showError('is_legal_representative') }
                colon={false}
              >
                <Checkbox
                  placeholder={ t.is_legal_representative }
                  name="is_legal_representative"
                  checked={ getValue('is_legal_representative') === t.yes ? true : false }
                  onChange={(e) => handleChange('is_legal_representative')(e.target.checked ? t.yes : t.no)}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button
                  type="primary"
                  onClick={handleSubmit}
                >
                  { t.save }
              </Button>
            </Col>
            <Col span={2}>
              <Button onClick={() => onCancel(type, index)}>
                { t.cancel }
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
};

export default injectIntl(MembersForm);
