import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';

import {
  requestEqualityPlanFactors,
  requestEqualityPlanPositions,
} from 'actions/api';

import CustomButton from 'components/CustomButton';
import {
  Row,
  Col,
} from 'antd';
import { groupBy, sum } from 'lodash';
import CustomTable from 'components/CustomTable';


const PointsByPosition = ({
  intl,
  history,
  plan,
  updatePlanProgress,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanFactors(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanPositions(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetching: fetchingFactors,
    data: factors,
  } = useSelector(state => state.equality_plan_factor);
  const {
    fetching: fetchingPositions,
    data: positions,
  } = useSelector(state => state.equality_plan_position);

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  const handleOnClickReviewed = useCallback(
    () => {
      updatePlanProgress('points_by_position');
      handleBack();
    },
    [updatePlanProgress, handleBack]
  );
  
  const factorsWithPositions = useMemo(
    () => {
      if (!factors || !positions) {
        return [];
      }
      return factors.map(
        factor => {
          let data = {...factor, positions: {}};

          positions.forEach(
            position => {
              const factorInPosition = position.factors
                .find(f => f.factor_id === factor.id);

              if (factorInPosition) {
                const levelPoints = factor.levels
                  .find(level => level.id === factorInPosition.factor_level_id)
                  .points;
                data.positions[position.id] = (
                  levelPoints
                  * factor.percentage
                  * (plan.job_evaluation?.type_points?.[factor.type] || 0)
                );
              } else {
                data.positions[position.id] = 0;
              }
            }
          );

          return data;
        }
      )
    },
    [plan, factors, positions]
  );

  const factorsByType = useMemo(
    () => {
      if (!factors || !positions) {
        return [];
      }

      return Object.entries(
        groupBy(factorsWithPositions, 'type')
      ).map(([type, factors]) => {
        const typeData = {
          type,
          positions: {},
        };

        positions.forEach(
          position => {
            typeData.positions[position.id] = sum(
              factors.map(factor => factor.positions[position.id] || 0)
            );
          }
        );
        return typeData;
      });
    },
    [factorsWithPositions, positions, factors]
  );

  const factorsByGender = useMemo(
    () => {
      if (!factors || !positions) {
        return [];
      }

      return Object.entries(
        groupBy(factorsWithPositions, 'gender')
      ).map(([gender, factors]) => {
        const genderData = {
          gender: t[`equality_job_evaluation_factor_gender_option_${gender}`],
          positions: {},
        };

        positions.forEach(
          position => {
            genderData.positions[position.id] = sum(
              factors.map(factor => factor.positions[position.id] || 0)
            );
          }
        );
        return genderData;
      });
    },
    [factorsWithPositions, positions, factors, t]
  );

  const columnsByFactor = useMemo(
    () => {
      if (!positions) {
        return [];
      }

      let columns = [{
        title: t.equality_job_evaluation_points_by_position_type,
        dataIndex: 'type',
      }, {
        title: t.equality_job_evaluation_points_by_position_factor,
        dataIndex: 'code',
      }, {
        title: t.equality_job_evaluation_points_by_position_gender,
        dataIndex: 'gender',
        render: value => t[`equality_job_evaluation_factor_gender_option_${value}`]
      }, {
        title: t.equality_job_evaluation_points_by_position_total,
        dataIndex: 'positions',
        render: value => sum(Object.values(value))?.toFixed(2),
      }];

      positions.forEach(position => {
        columns.push({
          title: position.code,
          dataIndex: 'positions',
          render: value => value[position.id]?.toFixed(2) || 0
        })
      });
      return columns;
    },
    [t, positions]
  );

  const columnsByType = useMemo(
    () => {
      if (!positions) {
        return [];
      }

      let columns = [{
        title: t.equality_job_evaluation_points_by_position_type,
        dataIndex: 'type',
      }, {
        title: t.equality_job_evaluation_points_by_position_total,
        dataIndex: 'positions',
        render: value => sum(Object.values(value))?.toFixed(2),
      }];

      positions.forEach(position => {
        columns.push({
          title: position.code,
          dataIndex: 'positions',
          render: value => value[position.id]?.toFixed(2) || 0
        })
      });
      return columns;
    },
    [t, positions]
  );

  const columnsByGender = useMemo(
    () => {
      if (!positions) {
        return [];
      }

      let columns = [{
        title: t.equality_job_evaluation_points_by_position_gender,
        dataIndex: 'gender',
      }, {
        title: t.equality_job_evaluation_points_by_position_total,
        dataIndex: 'positions',
        render: value => sum(Object.values(value))?.toFixed(2),
      }];

      positions.forEach(position => {
        columns.push({
          title: position.code,
          dataIndex: 'positions',
          render: value => value[position.id]?.toFixed(2) || 0
        })
      });
      return columns;
    },
    [t, positions]
  );

  if (
    fetchingFactors
    || !factors
    || fetchingPositions
    || !positions
  ) { 
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_points_by_position}
        handleBackOrSkip={handleBack}
      />
      <Row type="flex" justify="end">
        <CustomButton
          type="primary"
          onClick={handleOnClickReviewed}
        >
          {t.equality_plan_reviewed}
        </CustomButton>
      </Row>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_points_by_position_by_factors}</h2>
        </Col>
        <Col span={24}>
          <CustomTable
            columns={columnsByFactor}
            dataSource={factorsWithPositions}
            rowKey='id'
          />
        </Col>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_points_by_position_by_types}</h2>
        </Col>
        <Col span={24}>
          <CustomTable
            columns={columnsByType}
            dataSource={factorsByType}
            rowKey='id'
            pagination={false}
          />
        </Col>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_points_by_position_by_gender}</h2>
        </Col>
        <Col span={24}>
          <CustomTable
            columns={columnsByGender}
            dataSource={factorsByGender}
            rowKey='id'
            pagination={false}
          />
        </Col>
      </Row>
    </>
  );
}

export default withRouter(injectIntl(PointsByPosition));
