import React from 'react';
import { injectIntl } from 'react-intl';

import Show from './Show';
import Edit from './Edit';

const NO_OP = () => {};

const TupleAnswer = ({
  intl,
  value,
  previous_value,
  previous_comment,
  previous_attachments,
  comment,
  source,
  source_params,
  schema,
  schemaLabels,
  edit,
  loading,
  onEdit = NO_OP,
  onSave = NO_OP,
  onClear = NO_OP,
  onCancel = NO_OP,
  onShowLastPeriod = NO_OP,
  onUseLastValue = NO_OP,
  onUseLastWholeData = NO_OP,
  onUseLastAttachments = NO_OP,
  onUseLastPeriod = NO_OP,
  canWrite = false,
  config,
}) => {
  const t = intl.messages;

  return (
    <section className='KpiDetail__answer'>
      <div className="KpiDetail__title">{ t.answer }</div>
      {
        edit && canWrite
        ? <Edit
            source={source}
            source_params={source_params}
            schema={schema}
            schemaLabels={schemaLabels}
            value={value}
            comment={comment}
            onSave={onSave}
            onClear={onClear}
            onCancel={onCancel}
            previous_value={previous_value}
            previous_comment={previous_comment}
            previous_attachments={previous_attachments}
            onShowLastPeriod={onShowLastPeriod}
            onUseLastValue={onUseLastValue}
            onUseLastWholeData={onUseLastWholeData}
            onUseLastAttachments={onUseLastAttachments}
            onUseLastPeriod={onUseLastPeriod}
            config={config}
          />
          : <Show
              source={source}
              source_params={source_params}
              schema={schema}
              schemaLabels={schemaLabels}
              value={value}
              comment={comment}
              previous_value={previous_value}
              previous_comment={previous_comment}
              previous_attachments={previous_attachments}
              onEdit={onEdit}
              onShowLastPeriod={onShowLastPeriod}
              onUseLastValue={onUseLastValue}
              onUseLastWholeData={onUseLastWholeData}
              onUseLastAttachments={onUseLastAttachments}
              onUseLastPeriod={onUseLastPeriod}
              canWrite={canWrite}
              config={config}
          />
      }
    </section>
  );
}

TupleAnswer.Show = Show.Component;
TupleAnswer.Edit = Edit.Component;
TupleAnswer.Summary = Show.SummaryComponent;

export default injectIntl(TupleAnswer);
