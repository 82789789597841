export const TABLES = {
  admin_data_requests: 'admin_data_requests',
};

export const TABLE_CUSTOM_VIEW_OPTIONS = {
  [TABLES.admin_data_requests]: {
      periods_column: 'periods_column',
      requested_by_to_column: 'requested_by_to_column',
      value_column: 'value_column',
      comment_column: 'comment_column',
      attachment_column: 'attachment_column',
      actions_column: 'actions_column',
  },
};

export const DEFAULT_TABLE_CUSTOM_VIEWS_SET = {
  [TABLES.admin_data_requests]: Object.values(TABLE_CUSTOM_VIEW_OPTIONS[TABLES.admin_data_requests]),
};

export const getDefaultTableCustomViewSet = (screen) => {
  return DEFAULT_TABLE_CUSTOM_VIEWS_SET[screen];
};
