import React, { useState, useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import DataCollection from 'components/EqualityPlanDataCollection/DataCollection';
import SalaryAuditGraphs from './SalaryAuditGraphs';

import {
  Row,
  Col,
} from 'antd';

import './style.less';


const planStage = 'diagnosis_salary_audit';

const EqualityPlanDiagnosisSalaryAudit = ({
  intl,
  history,
  plan,
  documents,
  onFetchDocuments,
  currentUserRole,
  updatePlanProgress,
  reportingStartDate,
}) => {
  const t = intl.messages;
  const [currentTab, setCurrentTab] = useState('indicators');

  const headerTabs = useMemo(
    () => [{
      key: 'indicators',
      title: t.equality_diagnosis_salary_audit_tab_indicators,
    }, {
      key: 'audit_graphs',
      title: t.equality_diagnosis_salary_audit_tab_audit_graphs,
    }],
    [t]
  );

  const onUpdatePlanProgress = useCallback(
    () => {
      updatePlanProgress(planStage);
      history.push(`/equality-plan/${plan.id}`);
    },
    [history, plan, updatePlanProgress]
  );

  return (
    <Row>
      <Col span={24}>
        <EqualityPlanHeader
          planId={plan.id}
          title={t[`equality_${planStage}`]}
          tabs={headerTabs}
          currentTab={currentTab}
          changeTab={setCurrentTab}
        />
      </Col>
      <Col span={24}>
        { currentTab === 'indicators' &&
          <DataCollection
            stage={planStage}
            plan={plan}
            updatePlanProgress={onUpdatePlanProgress}
          />
        }
        { currentTab === 'audit_graphs' &&
          <SalaryAuditGraphs
            plan={plan}
            reportingStartDate={reportingStartDate}
          />
        }
      </Col>
    </Row>
  );
};

export default withRouter(injectIntl(EqualityPlanDiagnosisSalaryAudit));
