import React, {useState, useCallback, useMemo} from 'react';
import { injectIntl } from 'react-intl';
import CustomTextArea from 'components/CustomTextArea';
import LocaleFlag from 'components/LocaleFlag';
import { Select } from 'antd';
import useOrganizations from 'utils/useOrganizations';
import CustomIcon from 'components/CustomIcon';


const { Option } = Select;

const Instructions = ({
  intl,
  value,
  onChange,
  error,
}) => {
  const {
    suborganization
  } = useOrganizations();

  const maybeGuidelinesTranslations = useMemo(() => value?.guidelines, [value]);
  const guidelinesTranslations = useMemo(() => maybeGuidelinesTranslations || [], [maybeGuidelinesTranslations]);
  const [selectedLocale, setSelectedLocale] = useState(intl.locale);

  const addTranslation = useCallback(({locale, guidelines}) => {
    const lang = guidelinesTranslations.map(value => value?.locale === locale ? ({locale, guidelines}) : value);
    onChange('guidelines')(lang);
  }, [guidelinesTranslations, onChange]);

  return (
    <div className='CreateEditCustomKPI-instructions' id="CreateEditCustomKPI-instructions">
      <div className='CreateEditCustomKPI-title CreateEditCustomKPI-instructions-header'>
        <span>
          { intl.formatMessage({ id: "createcustomkpi_instructions"}) } 
        </span>
        <Select 
          defaultValue={intl?.locale || "en-US"} 
          bordered={false} 
          onChange={locale => setSelectedLocale(locale)} 
          dropdownMatchSelectWidth={false}
          placement="bottomRight"
          getPopupContainer={() => document.getElementById('CreateEditCustomKPI-instructions')}
        >
          {suborganization?.config?.preferred_languages?.map(lang => {
            const guidelinesValue = guidelinesTranslations.find(gl => gl.locale === lang)?.guidelines
            return (
              <Option key={lang} value={lang}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <LocaleFlag intl={intl} locale={lang} style={{display: "flex", alignItems: "center", marginRight: "4px"}}/>
                  {intl.formatMessage({ id: lang})}
                  <CustomIcon 
                    icon={[guidelinesValue ? 'fas' : 'fal', 'file-alt']} 
                    style={{color: guidelinesValue ? '' : 'black', width: '14px', height: '18px', marginLeft: "6px"}}
                  />
                </div>
              </Option>
            )
          }
          )}
        </Select>
      </div>
      {
        !error ? null : 
        <div className="CreateEditCustomKPI__error">{ error }</div>
      }
      <React.Fragment key={selectedLocale}> {/* We do this to force CustomTextArea to re-render on selectedLocale changes because it only accepts an initial */}
        <CustomTextArea
          value={guidelinesTranslations.find(gl => gl.locale === selectedLocale)?.guidelines}
          onChange={value => addTranslation({locale: selectedLocale, guidelines: value})}
        />
      </React.Fragment>
    </div>
  )
}

export default injectIntl(Instructions);
