import React, {
  useState,
  useCallback,
  useEffect,
  useMemo
} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { injectIntl } from 'react-intl';
import A from 'components/A';
import { ESG_TYPES } from 'utils/kpi';
import { FILTERS_COMPONENT_TYPES_CATALOG, FILTER_TYPES } from 'hooks/useScreenFilters/constants';
import FiltersPanel from 'components/FiltersPanel';
import SearchFilter from 'components/SearchFilter';
import AplanetIcon from 'components/AplanetIcon';
import useOrganizations from 'utils/useOrganizations';
import { requestOrganizationDetail } from 'actions/api';
import EsgLogo from 'components/EsgLogo';

import './style.less';

const NO_OP = () => {};

const REQUEST_PERIODICITY_OPTIONS = ['month', 'quarter', 'semester', 'year'];
const ATTACHMENT_OPTIONS = ['doc','pdf','xls','image','other'];
const ATTACHMENT_ICON_OPTIONS = {
  doc: 'File-word',
  pdf: 'File-pdf',
  xls: 'File number',
  image: 'File image',
  other: 'File text empty'
};

const REPORTING_STANDARDS = [
  'gri',
  'gri-2021',
  'equality',
  'sdg',
  'sdgc',
  'bcorp',
  'einf',
  'euss',
  'ungc',
  'tefce',
  'prme',
  'sasb-hc-dy',
  'sasb-hc-di',
  'sasb-hc-dr',
  'sasb-hc-mc',
  'sasb-hc-ms',
  'sasb-hc-bp',
  'sasb-fn-ac',
  'sasb-fn-cb',
  'sasb-fn-cf',
  'sasb-fn-in',
  'sasb-fn-ib',
  'sasb-fn-mf',
  'sasb-fn-ex',
  'sasb-cg-aa',
  'sasb-cg-mr',
  'tcfd',
  'sasb-fb-fr',
  'sasb-if-re',
  'sfdr',
  'shift',
  'scority',
  'ghg',
  'neutrality',
  'custom',
];

const FilesFilters = ({
  intl,
  setFilterState,
  filterState,
  resetAllFilters,
  areFiltersSet,
  isTextFilterSet,
  showDownloadButton,
  onDownload = NO_OP
}) => {
  const dispatch = useDispatch();
  // Search
  const [hasToClearKpiSearch, setHasToClearKpiSearch] = useState(false);

  // Filters

  const [periodDateFilterValues, setPeriodDateFilterValues] = useState(filterState.period_date_filter);
  const [periodicityFilterValues, setPeriodicityFilterValues] = useState(filterState.periodicity_filter);
  const [attachmentFilterValues, setAttachmentFilterValues] = useState(filterState.attachment_filter);
  const [standardFilterValues, setStandardFilterValues] = useState(filterState.standard_array_filter);
  const [sdgFilterValues, setSdgFilterValues] = useState(filterState.sdg_filter);
  const [esgFilterValues, setEsgFilterValues] = useState(filterState.type_filter);
  const [userFilterValues, setUserFilterValues] = useState(filterState.user_filter);


  // Data
  const {
    organization,
    suborganization,
  } = useOrganizations();
 
  const reportingStandards = useMemo(() => {
    const enabled = new Set([
      ...((organization.config || {}).enabled_reports || []),
      'custom',
    ]);

    return REPORTING_STANDARDS
      .filter(slug => enabled.has(slug))
  }, [
    organization,
  ]);

  const {
    data: profile
  } = useSelector(state => state.profile);

  const esgTypes = useMemo(() => {
    return ESG_TYPES.map(slug => ({
      value: slug,
      title: intl.formatMessage({id: `esg_type_${slug}`}),
      label: (<><EsgLogo type={slug} /><span>{intl.formatMessage({id: `esg_type_${slug}`})}</span></>)
    }));
  }, [
    intl,
  ]);


  const {
    data: organizationDetail,
  } = useSelector((state) => state.organization_detail);

const members = useMemo(()=>{
  let users = organizationDetail?.users || [], external_users = organizationDetail?.external_users || [];
  const patchedProfile = [{
    member_slug : profile.slug,
    member_name: profile.name,
    member_email: profile.email,
    member_status: profile.status
  }];
  users = [...users,...patchedProfile, ...external_users];

  return users
  .filter(({member_status}) => member_status !== 'deleted')
  .map(user => {
    return {
      slug: user.member_slug ,
      name: `${user.member_name} (${user.member_email})`,
    }  
  })
  .sort((a,b) => {
    const A = a.name.toUpperCase();
    const B = b.name.toUpperCase();
    if (A < B) {
      return -1;
    }
    if (A > B) {
      return 1;
    }
    return 0;
  });

},[organizationDetail, profile]);

  useEffect(() => {
  
      dispatch(
        requestOrganizationDetail(organization.slug, suborganization.slug)
      )
  }, [organization, suborganization, dispatch])

  const filtersConfig = useMemo(()=>(
    [
      {
        columnTitle: intl.formatMessage({id: `datamanagement_files_filter_column_title_periodicity`}),
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.list,
            filterTitle: intl.formatMessage({id: `datamanagement_files_filter_title_periodicity`}),
            listOptions: REQUEST_PERIODICITY_OPTIONS.map(option => ({
              value: option,
              title: intl.formatMessage({id: `datamanagement_files_filter_periodicity_${option}`}),
              label: (<span className={`Files__periodicity ${option}`}>{intl.formatMessage({id: `datamanagement_files_filter_periodicity_${option}`})}</span>)
            })),
            setSelectedOptions: setPeriodicityFilterValues,
            selectedOptions: periodicityFilterValues,
          },
        ]
      },
      {
        columnTitle: intl.formatMessage({id: `datamanagement_files_filter_column_title_attachment`}),
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.checkbox,
            filterTitle: intl.formatMessage({id: `datamanagement_files_filter_title_attachment`}),
            listOptions: ATTACHMENT_OPTIONS.map(option => ({
              value: option,
              title: intl.formatMessage({id: `attachment_${option}_type`}),
              label: (
              <>
                <AplanetIcon className="FilterList__value__attachment__logo" name={ATTACHMENT_ICON_OPTIONS[option]} />
              <span className='FilterList__value__attachment'>{intl.formatMessage({id: `attachment_${option}_type`})}</span>
              </>
              )
            })),
            setSelectedOptions: setAttachmentFilterValues,
            selectedOptions: attachmentFilterValues,
          },
        ]
      },
      {
      columnTitle: intl.formatMessage({id: `datamanagement_files_filter_column_title_standard`}),
      filters: [
        {
          filterType: FILTERS_COMPONENT_TYPES_CATALOG.multiSelect,
          filterTitle: intl.formatMessage({id: `datamanagement_files_filter_title_standard`}),
          title: intl.formatMessage({id: `datamanagement_files_filter_placeholder_standard`}),
          options: reportingStandards.map(standard => ({
            slug: standard,
            name: intl.formatMessage({id: `reporting_standard_${standard}`}),
            // renderOptionPrefix: (standard) =>{<span className={`Files__periodicity ${standard}`}>{intl.formatMessage({id: `reporting_standard_${standard}`})}</span>}
          })),
          onSelect: setStandardFilterValues,
          selected: standardFilterValues,
        },
        {
          filterType: FILTERS_COMPONENT_TYPES_CATALOG.sdgSelect,
          filterTitle: intl.formatMessage({id: `datamanagement_files_filter_title_sdg`}),
          selected : sdgFilterValues,
          onSelect : setSdgFilterValues,
        },
        {
          filterType: FILTERS_COMPONENT_TYPES_CATALOG.list,
          filterTitle: intl.formatMessage({id: `datamanagement_files_filter_title_esg`}),
          listOptions: esgTypes,
          setSelectedOptions: setEsgFilterValues,
          selectedOptions: esgFilterValues,
        },
      ]
    },
    {
      columnTitle: intl.formatMessage({id: `datamanagement_files_filter_column_title_users_dates`}),
      filters: [
        {
          filterType: FILTERS_COMPONENT_TYPES_CATALOG.multiSelect,
          filterTitle: intl.formatMessage({id: `datamanagement_files_filter_title_users`}),
          title: intl.formatMessage({id: `datamanagement_files_filter_title_users`}),
          selected: userFilterValues,
          onSelect: setUserFilterValues,
          options : members
        },
        {
          filterType: FILTERS_COMPONENT_TYPES_CATALOG.date,
          filterTitle: intl.formatMessage({id: `datamanagement_files_filter_title_dates`}),
          picker: 'date',
          format: 'YYYY-MM-DD',
          suffixIcon: (<AplanetIcon name="Calendar" />),
          value: periodDateFilterValues,
          onChange: setPeriodDateFilterValues,
        },
      ]
    },
    ]),[
      intl,
      periodDateFilterValues,
      periodicityFilterValues,
      standardFilterValues,
      attachmentFilterValues,
      esgFilterValues,
      sdgFilterValues,
      userFilterValues,
      reportingStandards,
      esgTypes,
      members
    ]);

  const onSetFilters = useCallback(() => {
    setFilterState(FILTER_TYPES.periodicity_filter)(periodicityFilterValues);
    setFilterState(FILTER_TYPES.attachment_filter)(attachmentFilterValues);
    setFilterState(FILTER_TYPES.standard_array_filter)(standardFilterValues);
    setFilterState(FILTER_TYPES.sdg_filter)(sdgFilterValues);
    setFilterState(FILTER_TYPES.type_filter)(esgFilterValues);
    setFilterState(FILTER_TYPES.user_filter)(userFilterValues);
    setFilterState(FILTER_TYPES.period_date_filter)(periodDateFilterValues);
  },[
    periodicityFilterValues,
    attachmentFilterValues,
    standardFilterValues,
    sdgFilterValues,
    esgFilterValues,
    userFilterValues,
    periodDateFilterValues,
    setFilterState
  ]);

  const onCancelFilter = useCallback(() => {
    setPeriodicityFilterValues(filterState.periodicity_filter);
    setAttachmentFilterValues(filterState.attachment_filter);
    setStandardFilterValues(filterState.standard_array_filter);
    setSdgFilterValues(filterState.sdg_filter);
    setEsgFilterValues(filterState.type_filter);
    setUserFilterValues(filterState.user_filter);
    setPeriodDateFilterValues(filterState.period_date_filter);

  },[
    filterState.periodicity_filter,
    filterState.attachment_filter,
    filterState.standard_array_filter,
    filterState.sdg_filter,
    filterState.type_filter,
    filterState.user_filter,
    filterState.period_date_filter
  ]);



  const onClearSearchAndFilters = useCallback(() => {
    setPeriodicityFilterValues([]);
    setAttachmentFilterValues([]);
    setStandardFilterValues([]);
    setSdgFilterValues([]);
    setEsgFilterValues([]);
    setUserFilterValues([]);
    setPeriodDateFilterValues([]);
    setHasToClearKpiSearch(true);
    resetAllFilters();
  }, [
    resetAllFilters,
  ]);

  return (
    <div className="Files__container">
      <div className="Files__table-filters">
        <FiltersPanel
          areFiltersSet={areFiltersSet}
          onCancelFilter={onCancelFilter}
          onSetFilters={onSetFilters}
          filtersConfig={filtersConfig}
        />
        <SearchFilter
          textFilter={filterState.text_filter}
          onSetSearch={(searchText) => setFilterState(FILTER_TYPES.text_filter)(searchText)}
          hasToClearSearch={hasToClearKpiSearch}
          setHasToClearKpiSearch={setHasToClearKpiSearch}
          defaultOpen={isTextFilterSet}
          placeholder={intl.formatMessage({id: `datamanagement_files_filter_search__placeholder`})}
          autoFocus
        />

        <div className='Files__remove-filters-wrapper'>
          <A
            className='Files__remove-filters'
            onClick={onClearSearchAndFilters}
            disabled={!areFiltersSet && !isTextFilterSet}
          >
            {intl.formatMessage({id: 'datamanagement_files_filter_delete_all'})}
          </A>
        </div>
      </div>
      { showDownloadButton &&
        <div className="Files__download_container">
        <button
          className={`Files__download__button`}
          onClick={() => onDownload()}
        >
          <AplanetIcon name='File zip' size="18px" />
          <span>{intl.formatMessage({id: areFiltersSet ? 'datamanagement_files_button_download_filtered' : 'datamanagement_files_button_download_all'})}</span>
        </button>
        </div>
      }
    </div>
  );
}

export default injectIntl(FilesFilters);
