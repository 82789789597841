import React, { useEffect } from 'react';

import Form from './Form'

import {
  Row,
  Col,
} from 'antd';

import './style.less';

const EqualityPlanObjectives = ({
  plan,
  updatePlan,
  objectives,
  fetchingObjectives,
  fetchObjectives,
  createObjective,
  updateObjective,
  updatePlanProgress,
  actions,
  fetchActions,
  measures,
  createMeasure,
}) => {
  useEffect(
    () => {
      fetchActions('general');
    },
    [fetchActions, plan]
  );

  return (
    <Row>
      <Col span={24}>
        <Form
          plan={plan}
          updatePlan={updatePlan}
          objectives={objectives}
          fetchingObjectives={fetchingObjectives}
          fetchObjectives={fetchObjectives}
          createObjective={createObjective}
          updateObjective={updateObjective}
          updatePlanProgress={updatePlanProgress}
          actions={actions}
          measures={measures}
          createMeasure={createMeasure}
        />
      </Col>
    </Row>
  );
};

export default EqualityPlanObjectives;
