import React, { useState, useEffect } from 'react';
import { Editor, Transforms, Range, Path, Node } from 'slate';
import {
  useSlate,
  ReactEditor,
  useSelected,
  useFocused,
} from 'slate-react';

import UploadButton from '../UploadButton';
import { URL_REGEXP } from '../../utils/validation';

import {
  Popover,
  Button,
  Row,
  Col,
  Input,
} from 'antd';
import { useCallback } from 'react';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
}
const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: n => n.type === format,
  });

  return !!match;
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
}

const insertImage = (editor, url) => {
  if (!url) return;

  const { selection } = editor;
  const image = { type: "image", url, children: [{ text: "" }] };

  ReactEditor.focus(editor);

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path
    );

    if (editor.isVoid(parentNode) || Node.string(parentNode).length) {
      // Insert the new image node after the void node or a node with content
      Transforms.insertNodes(editor, image, {
        at: Path.next(parentPath),
        select: true
      });
    } else {
      // If the node is empty, replace it instead
      Transforms.removeNodes(editor, { at: parentPath });
      Transforms.insertNodes(editor, image, { at: parentPath, select: true });
    }
  } else {
    // Insert the new image node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, image, { select: true });
  }
}

export const Toolbar = ({
  children,
}) => {
  return (
    <div
      className="CustomTextArea__toolbar"
    >
      { children }
    </div>
  );
};

export const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      type={isBlockActive(editor, format) ? 'primary' : 'ghost'}
      icon={icon}
      onClick={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    />
  );
}

export const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      type={isMarkActive(editor, format) ? 'primary' : 'ghost'}
      icon={icon}
      onClick={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    />
  );
}

const LinkButtonForm = ({ icon, onSubmit }) => {
  const [value, setValue] = useState();
  const handleOnChange = useCallback(
    (e) => {
      e.persist();
      setValue(e.target.value);
    },
    [setValue]
  );
  return (
    <Row gutter={10}>
      <Col span={20}>
        <Input
          value={value}
          placeholder="https://example.com"
          onChange={handleOnChange}
        />
      </Col>
      <Col span={4}>
        <Button
          icon={icon}
          type="primary"
          onClick={e => onSubmit(value)}
        />
      </Col>
    </Row>
  )
}

  export const LinkButton = ({ icon }) => {
    const [visible, setVisible] = useState(false);
    const [selection, setSelection] = useState();
    const editor = useSlate();
    const isActive = isLinkActive(editor);

    useEffect(
      () => {
        if (visible && editor.selection) {
          setSelection(editor.selection)
        }
      },
      [visible, editor]
    );

    const handleOnSubmit = useCallback(
      (url) => {
        setVisible(false);
        if (!url || !selection) {
          return;
        }

        editor.apply({
          type: 'set_selection',
          properties: selection,
          newProperties: selection,
        });
        insertLink(editor, url);
      },
      [editor, selection]
    );
    return (
      <Popover
        content={
          <LinkButtonForm
            icon={icon}
            onSubmit={handleOnSubmit}
          />
        }
        trigger="click"
        visible={visible}
        onVisibleChange={setVisible}
      >
        <Button
          type={isActive ? 'primary' : 'ghost'}
          icon={icon}
          disabled={isActive}
        />
      </Popover>
    );
  }

export const InsertImageButton = ({
  intl,
  icon,
  uploadUrl,
  api_requests,
  refreshAccessToken,
}) => {
  const editor = useSlate();
  
  const onImageUploadSuccess = useCallback(
    response => insertImage(editor, JSON.parse(response).download),
    [editor]
  );

  return (
    <UploadButton
      intl={intl}
      className="CustomTextArea__toolbar__image_button"
      actionUrl={uploadUrl}
      accept="image/*"
      onSuccess={onImageUploadSuccess}
      component={
        <Button
          icon={icon}
          type="ghost"
        />
      }
      api_requests={api_requests}
      refreshAccessToken={refreshAccessToken}
    />
  );
};

const Image = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <img
          src={element.url}
          alt={element.url}
          style={{
            display: 'block',
            maxWidth: '100%',
            maxHeight: '20em',
            boxShadow: `${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'}`,
          }}
        />
      </div>
      {children}
    </div>
  );
}

export const Element = props => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      return <a {...attributes} href={element.url}>{children}</a>;
    case 'image':
      return <Image {...props} />
    default:
      return <p {...attributes}>{children}</p>;
  }
}

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
}

const isLinkActive = editor => {
  const [link] = Editor.nodes(editor, { match: n => n.type === 'link' })
  return !!link
};

const unwrapLink = editor => {
  Transforms.unwrapNodes(editor, { match: n => n.type === 'link' })
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
}

const isUrl = (text) => {
  return URL_REGEXP.test(text);
}

export const withLinks = editor => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = element => {
    return element.type === 'link' ? true : isInline(element)
  };

  editor.insertText = text => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
}

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
}

export const withImage = (editor) => {
  const { isVoid, insertBreak, deleteBackward } = editor;

  editor.isVoid = (element) =>
    element.type === 'image' ? true : isVoid(element);

  editor.insertBreak = (...args) => {
    const parentPath = Path.parent(editor.selection.focus.path);
    const parentNode = Node.get(editor, parentPath);
  
    if (parentNode.type === 'image') {
      const nextPath = Path.next(parentPath);
      Transforms.insertNodes(
        editor,
        {
          type: 'paragraph',
          children: [{ text: '' }]
        },
        {
          at: nextPath,
          select: true // Focus on this node once inserted
        }
      );
    } else {
      insertBreak(...args);
    }
  }

  editor.deleteBackward = (...args) => {
    const parentPath = Path.parent(editor.selection.focus.path);
    const parentNode = Node.get(editor, parentPath);
  
    if (
      parentNode.type === 'image'
      || (
        !Node.string(parentNode).length
        && editor.children.length > 1
      )
    ) {
      Transforms.removeNodes(editor, { at: parentPath });

      if (editor.children.length === 0) {
        Transforms.insertNodes(editor, {type: 'paragraph', children: [{ text: '' }]},  { at: parentPath, select: true });
      }
    } else {
      deleteBackward(...args);
    }
  }

  return editor;
}
