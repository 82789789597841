import { loop, Cmd } from 'redux-loop';
import { requestReportingStructure, requestTaxonomies } from 'actions/api';

const TARGET = 'reporting_structure';
const isGeneralTarget = (target) => target === TARGET;

const KPI_DELETE_TARGET = 'kpi_delete';
const CATEGORY_DELETE_TARGET = 'category_delete';
const FOUR_0_KPI_EDIT_TARGET = '4.0_kpi_edit';
const FOUR_0_CATEGORY_EDIT_TARGET = '4.0_category_edit';
const FOUR_0_KPI_ADD_TARGET = '4.0_kpi_add';
const FOUR_0_CATEGORY_ADD_TARGET = '4.0_category_add';
const KPI_VALUE_STATUS = 'kpi_value_status';
const REPORTING_STRUCTURE_MOVEMENTS = 'reporting_structure_movements';
const SECONDARY_TARGET = [
  KPI_DELETE_TARGET,
  CATEGORY_DELETE_TARGET,
  FOUR_0_KPI_EDIT_TARGET,
  FOUR_0_CATEGORY_EDIT_TARGET,
  FOUR_0_KPI_ADD_TARGET,
  FOUR_0_CATEGORY_ADD_TARGET,
  KPI_VALUE_STATUS,
  REPORTING_STRUCTURE_MOVEMENTS,
]
const isSecondaryTarget = (target) => SECONDARY_TARGET.includes(target);

const initialState = {
  data: null,
  loading: false,
  error: null,
  errorMovement: null,
  saving: null,
  loadingKpiValueStatus: false,
  loaderModal: false,
  savingMovement: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if (action.target === KPI_VALUE_STATUS) {
        return {
          ...state,
          loadingKpiValueStatus: true,
        };
      }
      if (action.target === REPORTING_STRUCTURE_MOVEMENTS) {
        return {
          ...state,
          data: state.data,
          loading: false,
          saving: null,
          error: null,
          errorMovement: null,
          loaderModal: true,
          savingMovement: true,
        };
      }
      if(isSecondaryTarget(action.target)) return {
        data: state.data,
        loading: state.loading,
        error: null,
        errorMovement: null,
        saving: true,
        savingMovement: null,
      }
      if(!isGeneralTarget(action.target)) return state;
      return {
        ...state,
        data: state.data,
        loading: action.ignoreLoading ? false : true,
        error: null,
        errorMovement: null,
        saving: state.saving,
        savingMovement: state.savingMovement,
      };
    case 'API_CALL_COMPLETE':
      if (action.response && action.response.target === KPI_VALUE_STATUS) {
        let data = state.data && [...state.data];
        return {
          ...state,
          data: data && data.map(
            item => ({
              ...item,
              kpis: item.kpis.map(kpi => {
                if (kpi.slug === action.response.kpiSlug) {
                  return {
                    ...kpi,
                    kpi_value_status_for_whole_tree: action.response.result.status,
                  };
                }
                return kpi;
              })
            })
          ),
          loadingKpiValueStatus: false,
        };
      }
      if (action.response && action.response.target === REPORTING_STRUCTURE_MOVEMENTS) {
        const {
          organization_slug,
          suborganization_slug,
        } = action.response;
        return loop(
          {...state, error: null, errorMovement: null},
          Cmd.action(
            requestReportingStructure({
              organization_slug: organization_slug,
              suborganization_slug: suborganization_slug,
              ignoreLoading: true
            })
          )
        );
      }
      if(action.response && isSecondaryTarget(action.response.target)) {
        const {
          organization_slug,
          suborganization_slug,
        } = action.response;
        return loop(
          state,
          Cmd.list([
            Cmd.action(
              requestReportingStructure({
                organization_slug: organization_slug,
                suborganization_slug: suborganization_slug,
                ignoreLoading: true
              })
            ),
            Cmd.action(
              requestTaxonomies(organization_slug)
            )
          ])
        );
      }
      if(!action.response || !isGeneralTarget(action.response.target)) return state;
      switch(action.response.method) {
        case 'GET':
          return {
            data: action.response.result,
            loading: false,
            error: null,
            errorMovement: null,
            saving: state.saving === true ? false : null,
            loaderModal: false,
            savingMovement: state.savingMovement === true ? false : null,
          };
        case 'PUT':
          return state;
        case 'POST':
          const {
            organization_slug,
            suborganization_slug,
          } = action.response;
          return loop(
          {...state, error: null, errorMovement: null},
            Cmd.action(
              requestReportingStructure({
                organization_slug: organization_slug,
                suborganization_slug: suborganization_slug,
                ignoreLoading: true
              })
            )
          );
        default:
          return {
            data: action.response.result,
            loading: false,
            error: null,
            errorMovement: null,
            saving: state.saving === true ? false : null,
            loaderModal: false,
            savingMovement: state.savingMovement === true ? false : null
          };
      }
      case 'API_CALL_FAILED':
        if(!action.request || (!isGeneralTarget(action.request.target) && !isSecondaryTarget(action.request.target))) return state;
        if (action.request.target === REPORTING_STRUCTURE_MOVEMENTS) {
          return {
            ...state,
            loading: false,
            saving: null,
            error: null,
            loadingKpiValueStatus: false,
            loaderModal: false,
            errorMovement: action.code,
            savingMovement: null,
          };
        }
        return {
          ...state,
          loading: false,
          saving: null,
          error: action.code,
          loadingKpiValueStatus: false,
          loaderModal: false,
          savingMovement: null,
          errorMovement: null,
        };
    default:
      return state;
  }
};

export {
  reducer as reporting_structure,
};
