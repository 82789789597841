import React from "react";
import CustomButton from "components/CustomButton";
import { Row, Col } from "antd";

const Warning = ({ intl, tag, kpis, onConfirm, onCancel }) => {
  return (
    <div className="CustomMultiTagSelector">
      <div className="CustomMultiTagSelector-message">
        {intl.formatMessage(
          { id: "custom_multi_tag_selector_deletion_warning" },
          { kpis, tag }
        )}
      </div>
      <div className="CustomMultiTagSelector-buttons">
        <Row gutter={5} justify="end" style={{ width: "100%" }}>
          <Col>
            <CustomButton key="submit" type="secondary" onClick={onCancel}>
              {intl.formatMessage({
                id: "custom_multi_tag_selector_cancel_deletion",
              })}
            </CustomButton>
          </Col>
          <Col>
            <CustomButton key="submit" type="primary" onClick={onConfirm}>
              {intl.formatMessage({
                id: "custom_multi_tag_selector_confirm_deletion",
              })}
            </CustomButton>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Warning;
