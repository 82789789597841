import { validateStep1 } from "./step1";
import { validateStep2 } from "./step2";
import { validateStep3 } from "./step3";

const validate = (intl) => (values, currentStep) => {
  let errors = {};

  switch(currentStep) {
    case 0:
      return validateStep1(intl, values);
    case 1:
      return validateStep2(intl, values);
    case 2:
      return validateStep3(intl, values);
    default:
      return errors;
  }
}; 

export {
  validateStep1,
  validateStep2,
  validateStep3,
  validate,
}