import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  flattenTree,
  idField,
  childrenField,
} from 'utils/tree';

import {
  Tree,
} from 'antd';

import A from 'components/A'
import T from 'components/T'
import CustomCheckbox from 'components/CustomCheckbox';
import ReportingStandardTag from 'components/ReportingStandardTag';
import HiddenLogo from 'components/HiddenLogo'


import './style.less'
import { Loading } from 'tsComponents/emptyStates/Loading';

const { TreeNode } = Tree;

const NO_OP = () => {};

const NO_CHECKED_CATEGORIES = {
  checked: [],
  halfChecked: [],
};

const ROOT_ID = '__root'; // Needs not to be a valid slug, hence __

const Sidebar = ({
  intl,
  categories,
  selectedCategory,
  setSelectedCategory,
  selectable = false,
  showCode = false,
  hideEmpty = false,
  checkedCategories = NO_CHECKED_CATEGORIES,
  onCheckCategory = NO_OP,
  onCheckAll = NO_OP,
  onUncheckAll = NO_OP,
  className='',
}) => {
  const t = intl.messages;

  const [expanded, setExpanded] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(false);

  const handleExpand = useCallback((expandedKeys) => {
    setExpanded(expandedKeys);
    setAutoExpandParent(false);
  }, []);

  const handleCheck = useCallback((change, e) => {
    const uuid = (
      (((((e || {}).node || {}).props || {}).dataRef || {}).uuid) || ''
    );
    if(!uuid) {
      return;
    }
    onCheckCategory(uuid);
  }, [
    onCheckCategory,
  ]);

  const handleSelect = useCallback((selectedKeys) => {
    setSelectedCategory(
      selectedKeys && selectedKeys.length > 0
      ?  selectedKeys[0]
      : null
    );
  }, [
    setSelectedCategory,
  ]);

  const allCategoryUuids = useMemo(() => {
    return Object.values(
      flattenTree({
        slug: ROOT_ID,
        [idField]: ROOT_ID,
        [childrenField]: categories || [],
      }, 'uuid')
    ).filter(({ slug, empty }) => !empty && slug !== ROOT_ID).map(({ uuid }) => uuid);
  }, [
    categories,
  ]);

  const globalSelected = useMemo(() => {
    // Returns 'full', 'none' or a number for partial selections
    const {
      checked = [],
      halfChecked = [],
    } = checkedCategories || {};

    if(
      checked.length === allCategoryUuids.length
    ) {
      return 'full';
    }

    if(checked.length + halfChecked.length === 0) {
      return 'none';
    }

    return checked.length + halfChecked.length;
  }, [
    allCategoryUuids,
    checkedCategories,
  ]);

  const handleGlobalSelect = useCallback(() => {
    if(globalSelected === 'full') {
      onUncheckAll();
    } else {
      onCheckAll();
    }
  }, [
    globalSelected,
    onUncheckAll,
    onCheckAll,
  ]);

  const renderTreeNodes = useCallback((tree) => {
    return tree.map(item => {
      if(hideEmpty && item.empty) {
        return null;
      }
      if (item[childrenField]) {
        return (
          <TreeNode
            title={
              <div
                className="ListSidebar__item-title"
                title={item.name}
              >
                {!item.applies && (
                  <div className="ListSidebar__hiddenlogo">
                    <HiddenLogo/>
                  </div>
                )}
                {
                  showCode && item.code
                  ? (
                    <span className="ListSidebar-item">
                      <ReportingStandardTag
                        className="ListSidebar__reporting-standard-tag"
                        code={item.code || ''}
                        name={item.name}
                        standard={item.standard}
                        disabled={item.empty}
                        showTooltip
                      />
                      { ' ' }
                      { item.name }
                    </span>
                  )
                  : item.name
                }
              </div>
            }
            key={item.uuid}
            disabled={item.empty}
            dataRef={item}
          >
            { renderTreeNodes(item[childrenField]) }
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.uuid}
          title={
            showCode && item.code
            ? (
              <span
                className="ListSidebar-item"
                title={item.name}
              >
                <ReportingStandardTag
                  className="ListSidebar__reporting-standard-tag"
                  code={item.code || ''}
                  name={item.name}
                  standard={item.standard}
                  disabled={item.empty}
                  showTooltip
                />
                { ' ' }
                { item.name }
              </span>
            )
            : (
              <span
                className="ListSidebar-item"
                title={item.name}
              >
                { item.name }
              </span>
            )
          }
          disabled={item.empty}
          dataRef={item}
        />
      );
    }).filter(Boolean);
  }, [hideEmpty, showCode]);

  return (
    <aside className={`ListSidebar ${className}`}>
      <h4 className="ListSection_title">
        { t.reports_categories }
      </h4>
      {
        !selectable ? null : (
          <div className="ListSidebar__all">
            <CustomCheckbox
              checked={globalSelected === 'full'}
              indeterminate={globalSelected !== 'full' && globalSelected !== 'none'}
              onChange={handleGlobalSelect}
            >
            {
              (() => {
                switch(globalSelected) {
                  case 'full':
                    return (
                      <>
                        { t.reports_all_categories_selected }
                      </>
                    )
                  case 'none':
                    return (
                      <>
                        { t.reports_all_categories_unselected }
                      </>
                    )
                  default:
                    return (
                      <>
                        <T
                          reports_some_categories_selected={{
                            count: globalSelected,
                          }}
                        />
                        { ' ' }
                        <A onClick={(e) => {
                          e.preventDefault();
                          handleGlobalSelect();
                        }}>
                          { t.reports_select_all_categories_click }
                        </A>
                      </>
                    )
                }
              }
              )()
            }
            </CustomCheckbox>
          </div>
        )
      }
      { !categories
        ? <Loading />
        : <Tree
          checkable={selectable}
          checkStrictly
          onExpand={handleExpand}
          expandedKeys={expanded}
          autoExpandParent={autoExpandParent}
          checkedKeys={checkedCategories}
          onCheck={handleCheck}
          onSelect={handleSelect}
          selectedKeys={[selectedCategory]}
        >
          { renderTreeNodes(categories) }
        </Tree>
        }
    </aside>
  );
};

export default injectIntl(Sidebar);

