import { useMemo } from 'react';


const DECIMAL_SEPARATOR = {
  'es': ',',
  'es-ES': ',',
  'pt-PT': ',',
  'pt': ',',
  'pt-BR': ',',
  'fr-FR': ',',
  'ca-ES': ',',
  default: '.',
};

const TRUE_DECIMAL_SEPARATOR = '.';
const NOT_NUMBER_RE = new RegExp('\\D', 'g');
const NOT_NUMBER_OR_DECIMAL = new RegExp('[^\\d.]', 'g'); // TODO: Better

const printSign = (str) => {
  return `${str}`.startsWith('-') ? '-' : '';
};

const removeAdditionalDots = (str) => {
  let n = 0;
  return (str || '').replace(/\./g, function() { return n++ > 0 ? '' : TRUE_DECIMAL_SEPARATOR; });
};

// For decimals it's slightly complex
const DECIMAL_FORMATTER = intl => value => {
  const separator = DECIMAL_SEPARATOR[intl.locale] || DECIMAL_SEPARATOR.default;
  return printSign(value) + removeAdditionalDots(
    `${value}`
      .replace(NOT_NUMBER_OR_DECIMAL, '')
  ).replace(TRUE_DECIMAL_SEPARATOR, separator);
};
const DECIMAL_PARSER = intl => value => {
  const separator = DECIMAL_SEPARATOR[intl.locale] || DECIMAL_SEPARATOR.default;
  return printSign(value) + removeAdditionalDots(
    value
      .replace(separator, TRUE_DECIMAL_SEPARATOR)
      .replace(NOT_NUMBER_OR_DECIMAL, '')
  );
};

// For integers it's simpler
const INTEGER_FORMATTER = intl => (value = '') => printSign(value) + `${value}`.replace(NOT_NUMBER_RE, '');
const INTEGER_PARSER = intl => value => printSign(value) + value.replace(NOT_NUMBER_RE, '');

const useQuantitativeSchemaProps = (
  schema,
  _config,
  intl,
) => {
  const config = _config?.force_decimal_points || {};
  const forceDecimal = config.hasOwnProperty(schema.metricSlug)
    ? config[schema.metricSlug]
    : config['*']

  const decimalPoints = typeof forceDecimal === 'number'
    ? forceDecimal
    : (schema.decimalPoints || 0);

  const step = useMemo(() => {
    return 1 / (10 ** decimalPoints);
  }, [
    decimalPoints,
  ]);

  const min = useMemo(() => {
    if(typeof schema.minimum !== 'undefined' && schema.minimum !== null) {
      return Number(schema.minimum);
    }
    if(typeof schema.allowNegative === 'boolean' && !schema.allowNegative) {
      return 0;
    }
    return undefined;
  }, [
    schema,
  ]);

  const max = useMemo(() => {
    if(typeof schema.maximum !== 'undefined' && schema.maximum !== null) {
      return Number(schema.maximum);
    }
    return undefined;
  }, [
    schema,
  ]);

  const formatter = (decimalPoints > 0 ? DECIMAL_FORMATTER : INTEGER_FORMATTER)(intl);
  const parser = (decimalPoints > 0 ? DECIMAL_PARSER : INTEGER_PARSER)(intl);

  return {
    precision: decimalPoints,
    step,
    min,
    max,
    formatter,
    parser,
  }
};

export default useQuantitativeSchemaProps;
