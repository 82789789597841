import React, {useMemo} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import Avatar from 'components/Avatar';

import {
  Table,
  Select,
  Tooltip,
} from 'antd';

import {
  DeleteOutlined,
} from '@ant-design/icons';

import CommentColumn from './CommentColumn';

const { Option } = Select;

const LanguageSelector = ({
  intl,
  language,
  languages,
  onChangeUser,
  record
}) => {
  return (
    <Select
      className="UsersTable__languageSelector"
      placeholder={intl.formatMessage({id: 'step_3_table_language_placeholder'})}
      value={language}
      onChange={(newLanguage) => onChangeUser('language', newLanguage, record)}
      disabled={record.internal}
    >
      {languages.map(language => (
        <Option
          key={language}
        >
          {intl.formatMessage({id: language})}
        </Option>
      ))}
    </Select>
  );
};

const UsersTable = ({
  intl,
  usersSelected,
  languages,
  onChangeUser,
  onDeleteUser,
}) => {
    
  const columns = useMemo(() => [{
    title: intl.formatMessage({id: 'step_3_table_name_label'}),
    dataIndex: 'name',
    key: 'name',
    render: (name, record) => {
      return (
        <div className="UsersTable__nameCol">
          <Avatar size="large" src={record.avatar} name={name} />
          <span className="UsersTable__name">
            { name }
          </span>
        </div>
      );
    },
  }, {
    title: intl.formatMessage({id: 'step_3_table_email_label'}),
    dataIndex: 'email',
    key: 'email',
    render: (email) => {
      return (
        <span className="UsersTable__emailCol">{email}</span>
      );
    },
  }, {
    title: intl.formatMessage({id: 'step_3_table_language_label'}),
    dataIndex: 'language',
    key: 'language',
    maxWidth: 50,
    render: (language, record) => {
      return (
        <div className="UsersTable__languageCol">
          {record.internal ? (
            <Tooltip
              overlayClassName="UsersTable__languageTooltip"
              placement="bottom"
              title={intl.formatMessage({id: 'step_3_table_language_tooltip'})}
              arrowPointAtCenter
            >
              <div className="UsersTable__languageSelector">
                <LanguageSelector
                  intl={intl}
                  language={language}
                  languages={languages}
                  onChangeUser={onChangeUser}
                  record={record}
                />
              </div>
            </Tooltip>
          ) : (
            <LanguageSelector
              intl={intl}
              language={language}
              languages={languages}
              onChangeUser={onChangeUser}
              record={record}
            />
          )}
        </div>
      );
    },
  }, {
    title: intl.formatMessage({id: 'step_3_table_comment_label'}),
    dataIndex: 'comment',
    key: 'comment',
    render: (comment, record) => {
      return (
        <CommentColumn
          key={record.slug}
          comment={comment}
          onChangeComment={(newComment) => onChangeUser('comment', newComment, record)}
        />
      );
    },
  }, {
    title: (<DeleteOutlined className="UsersTable__deleteColTitle"/>),
    key: 'delete_action',
    width: 50,
    render: (text, record) => {
      return (
        <DeleteOutlined
          title={intl.formatMessage({id: 'step_3_table_delete_user'})}
          onClick={() => onDeleteUser(record)}
          className="UsersTable__deleteCol"
        />
      );
    },
  }], [
    intl,
    languages,
    onChangeUser,
    onDeleteUser,
  ]);


  return (
    <div className="UsersTable">
      <Table
        className="UsersTable__table"
        dataSource={usersSelected}
        columns={columns}
        pagination={{ hideOnSinglePage: true, pageSize: 10 }}
        bordered
      />
    </div>
  );
};

UsersTable.propTypes = {
  usersSelected: PropTypes.array.isRequired,
  onChangeUser: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string),
};

export default injectIntl(UsersTable);
