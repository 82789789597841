import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { findTree } from 'utils/tree';

const MAIN_PRODUCT = 'atlas';

const useOrganizations = () => {
  const {
    data: organization,
    memberships,
  } = useSelector(state => state.organization);

  const {
    tree: organizationTree,
    current_slug: currentSlug,
  } = useSelector(state => state.organization_tree);

  const suborganization = useMemo(() => {
    if(!organizationTree) {
      return organization;
    }

    const result = findTree(
      [organizationTree],
      ({ slug }) => slug === currentSlug,
    );

    if(!result) {
      return result;
    }

    const parentOrg = !result.parent_id
      ? null
      : findTree(
        [organizationTree],
        ({ id }) => id === result.parent_id,
      );

    return {
      ...result,
      parent_slug: parentOrg?.slug ?? null,
      config: (result?.product_config || {})[MAIN_PRODUCT],
    }
  }, [
    organization,
    organizationTree,
    currentSlug
  ]);

  const permissions = useMemo(() => {
    return suborganization ? suborganization.permissions : null;
  }, [
    suborganization,
  ]);

  const isMainOrganization = useMemo(() => {
    return organization && suborganization && organization.slug === suborganization.slug
  }, [organization, suborganization]);

  return {
    organization,
    memberships,
    suborganization,
    isMainOrganization,
    organizationTree,
    permissions,
  };
};

export default useOrganizations;
