import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox,
  Dropdown,
  Menu
} from 'antd';

import {
    DownOutlined,
  } from '@ant-design/icons';

import A from 'components/A';

const REMINDER_PERIODICITY = ['week', 'month'];
const FORM_KEYS_REMINDER = {
    reminder: 'reminder',
    reminder_periodicity: 'reminder_periodicity',
};

const FORM_KEY_RECURRENCY = 'recurrency';

const Reminder = ({
    intl,
    disabled,
    handleChange,
    checked,
    reminderPeriodicity,
    recurrencyType,
    recurrency,
}) => {

    const onChangeReminderValues = useCallback((type, value) => {
        if (!recurrency) {
            handleChange(FORM_KEY_RECURRENCY)(recurrencyType);
        }
        handleChange(type)(value);
    }, [
        handleChange,
        recurrency,
        recurrencyType,
    ]);

    return (
        <Checkbox
            className="Reminder__checkbox"
            disabled={disabled}
            onChange={e => onChangeReminderValues(FORM_KEYS_REMINDER.reminder, e.target.checked)} 
            checked={checked}
        >
            <span className={`Reminder__checkboxText ${disabled ? 'is-disabled' : ''}`}>
                {intl.formatMessage({id: 'step_2_send_reminder'})}
            </span>
            <Dropdown
                className="Reminder__dropdown"
                trigger={['click']}
                disabled={disabled}
                overlay={
                  <Menu onClick={(value) => onChangeReminderValues(FORM_KEYS_REMINDER.reminder_periodicity, value.key)}>
                    {REMINDER_PERIODICITY.map(reminder => (
                        <Menu.Item key={reminder}>
                            {intl.formatMessage({id: `step_2_send_reminder_${reminder}`})}
                        </Menu.Item>
                    ))}
                  </Menu>
                }
            >
                <A className={`Reminder__dropdownText ${disabled ? 'is-disabled' : ''}`}>
                    {intl.formatMessage({id: `step_2_send_reminder_${reminderPeriodicity}`})}
                    <DownOutlined />
                </A>
            </Dropdown>
        </Checkbox>
    )
};

Reminder.propTypes = {
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    reminderPeriodicity: PropTypes.oneOf(['week', 'month']).isRequired,
    recurrencyType: PropTypes.oneOf(['recurrent', 'one_time']).isRequired,
    recurrency: PropTypes.string,
};

export default Reminder;
