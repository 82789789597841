import {
  //Node,
  Text,
} from 'slate';
import Prism from 'prismjs';
require('prismjs/components/prism-markdown');


export const serialize = node => {
  if(Array.isArray(node)) {
    return node.map(serialize).join('');
  }

  if (Text.isText(node)) {
    let text = node.text;
    const spacesAtEnd = ' '.repeat(
      text.match(/(\s+$)/g)?.[0].length || 0
    );
    const spacesAtStart = ' '.repeat(
      text.match(/(^_\s+)/g)?.[0].length || 0
    );

    if(node.italic) {
      text = `${spacesAtStart}_${text.trim()}_ ${spacesAtEnd}`
    }

    if(node.bold) {
      text = `${spacesAtStart}**${text.trim()}**${spacesAtEnd}`
    }
    return text;
  }
  const children = (node.children || []).map(serialize);

  switch (node.type) {
    case 'bulleted-list':
      return children.map((child) => `* ${child}`).join('\n')
    case 'numbered-list':
      return children.map((child, index) => `${index+1}. ${child}`).join('\n')
    case 'quote':
      return `> ${children.join('')}\n`
    case 'paragraph':
      return `\n${children.join('')}\n`
    case 'link':
      return `[${children.join('')}](${node.url})`
    case 'image':
      return `![${node.url}](${node.url})`
    default:
      return children.join('')
  }
};

const prismTokenToSlateModel = (token) => {
  switch (token.type) {
    case 'bold': {
      let result = {
        bold: true,
      };

      if (token.content[1].content[0] instanceof Prism.Token) {
        result['italic'] = true;
        result['text'] = token.content[1].content[0].content[1].content[0];
      } else {
        result['text'] = token.content[1].content[0];
      }
      return result;
    }
    case 'italic': {
      let result = {
        italic: true,
      };

      if (token.content[1].content[0] instanceof Prism.Token) {
        result['bold'] = true;
        result['text'] = token.content[1].content[0].content[1].content[0];
      } else {
        result['text'] = token.content[1].content[0];
      }
      return result;
    }
    case 'url': {
      if (token.content[0].content === '!') {
        // Means this is an image
        return {
          type: 'image',
          url: token.content[4].content,
          children: [{ text: '' }],
        };
      }
      return {
        type: 'link',
        url: token.content[3].content || '',
        children: [prismTokenToSlateModel(token.content[1].content[0])],
      };
    }
    case 'list':
      // Just add the content for now in case there's a nested list until we
      // add support for it. This will add a '*' to the text
      return {
        text: token.content,
      }
    case 'title':
      // We don't support titles yet
      return {
        text: `${token.content[0].content}${token.content[1]}`,
      };
    default:
      if(typeof token === 'object') {
        return {
          text: token.content || '',
        }
      }
      return {
        text: token,
      };
  }
};

const groupListItems = components => {
  let data = [];
  components.forEach(component => {
    if (!component.key) {
      data.push(component);
    } else {
      const previousComponent = data.length > 0
        ? data[data.length - 1]
        : null;
      if (
        previousComponent
        && previousComponent.key === component.key
      ) {
        data[data.length - 1] = {
          ...previousComponent,
          children: [
            ...previousComponent.children,
            ...component.children,
          ]
        }
      } else {
        data.push(component);
      }
    }
  });
  return data;
};

export const deserialize = string => {
  let key = '';
  // Return a value array of children derived by splitting the string.
  const components = string.split('\n').map(line => {
    const tokens = Prism.tokenize(line, Prism.languages.markdown);
    if (
      tokens[0] instanceof Prism.Token
      && tokens[0].type === 'list'
    ) {
      const type = tokens[0].content === '*'
        ? 'bulleted-list'
        : 'numbered-list';
      if (!key || !key.includes(type === 'bulleted-list' ? '*>' : '1.>')) {
        key = `${tokens[0].content}>${new Date().toISOString()}`;
      }
      const nextContent = tokens[1];
      const restContent = tokens.splice(2);
      let children = [];
      if (nextContent) {
        children.push(prismTokenToSlateModel(nextContent.substr(1)));
        if (restContent.length > 0) {
          children = [
            ...children,
            ...restContent.map(token => prismTokenToSlateModel(token)),
          ];
        }
      } else {
        children = [{ text: '' }];
      }
      return {
        key,
        type,
        children: [{
          type: "list-item",
          children,
        }],
      };
    } else {
      key = '';
      return {
        children: tokens.map(token => prismTokenToSlateModel(token))
      }
    }
  });
  return groupListItems(components);
}
