import {
  useReducer,
  useCallback,
} from 'react';

const reducer = (state, action) => {
  switch(action.type) {
    case 'ADD':
      return state.includes(action.param) ? state : state.concat(action.param);
    case 'REMOVE':
      return state.filter((e) => e !== action.param );
    case 'TOGGLE':
      return state.includes(action.param) ? state.filter((e) => e !== action.param) : state.concat(action.param);
    case 'REPLACE_ALL':
      return action.param || [];
    default:
      return state;
  }
};

const useSetState = (initialState = []) => {
  // WARNING: using native JS Sets and treating them as immutable is a pain
  // We will emulate using Array for now, but at some point we can change to Immutable.js
  const [state, dispatch] = useReducer(reducer, initialState);

  const has = useCallback(param => state.includes(param), [ state ]);
  const add = useCallback(param => dispatch({ type: 'ADD', param }), [ dispatch ]);
  const remove = useCallback(param => dispatch({ type: 'REMOVE', param }), [ dispatch ]);
  const toggle = useCallback(param => dispatch({ type: 'TOGGLE', param }), [ dispatch ]);
  const replaceAll = useCallback(param => dispatch({ type: 'REPLACE_ALL', param }), [ dispatch ]);

  return {
    has,
    add,
    remove,
    toggle,
    set: state,
    replaceAll,
  }
};

export default useSetState;
