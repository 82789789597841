import React, { useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';

import { useDispatch } from 'react-redux';
import useOrganizations from 'utils/useOrganizations';

import { updateKpiSettings } from 'actions/api';

import './style.less';
import OrganizationTargets from '../OrganizationTargets';
import { useHasChildren } from '../../hooks/organization';

import CustomButton from 'components/CustomButton';
import CustomModal from 'components/CustomModal';

import {
  Switch,
  Row,
  Col,
} from 'antd';

const ModalKpiInstructions = ({
    intl,
    visible,
    onClose,
    loading,
    show_guidelines_std,
    kpi_slug
  }) => {
  
    const t = intl.messages;
  
    const dispatch = useDispatch();
    const [showStdGuidelines, setShowStdGuidelines] = useState(show_guidelines_std);
    const [target, setTarget] = useState('self');
  
    const {
        organization,
        suborganization,
        organizationTree,
    } = useOrganizations();

    const hasChildren = useHasChildren(suborganization, organizationTree);

    const handleOnClose = useCallback(() => {
        onClose();
        setShowStdGuidelines(show_guidelines_std);
    }, [onClose, show_guidelines_std]);
  
    const onSubmit = useCallback(() => {
      dispatch(
        updateKpiSettings()({
            organization_slug: organization.slug,
            suborganization_slug: suborganization.slug,
            kpi_slugs: [kpi_slug],
            show_guidelines_std: showStdGuidelines,
            target
        })
      )
    }, [dispatch, organization.slug, suborganization.slug, kpi_slug, showStdGuidelines, target]);
  
    return (
        <CustomModal
            className="ModalKpiInstructions"
            shown={visible}
            title={ t.modalkpiinstructions_title }
            onOk={onSubmit}
            onCancel={handleOnClose}
            footer={
            <div className="ModalKpiInstructions__footer">
                <CustomButton
                    className="ModalKpiInstructions__btn-footer"
                    key="back"
                    onClick={handleOnClose}
                >
                    { t.modalkpiinstructions_cancel }
                </CustomButton>
                <CustomButton
                    className="ModalKpiInstructions__btn-footer"
                    key="submit"
                    type="primary"
                    onClick={onSubmit}
                >
                    { t.modalkpiinstructions_save }
                </CustomButton>
            </div>
            }
        >
            <section className="ModalKpiInstructions__firstsection">
                <div className="ModalKpiInstructions__title">{ t.modalkpiinstructions_show_guidelines_std }</div>
                <Row
                    className="ModalKpiInstructions__list"
                    type="flex"
                    gutter={8}
                >
                    <Col>
                        <Switch
                            checked={showStdGuidelines}
                            onChange={(value) => setShowStdGuidelines(value)}
                            disabled={loading}
                            checkedChildren={t.yes}
                            unCheckedChildren={t.no}
                        />
                    </Col>
                    <Col
                        style={{ flex: 1 }}
                    >
                        {
                            t.modalkpiinstructions_show
                        }
                    </Col>
                </Row>
                <Row style={{ width: '70%' }}>
                  <OrganizationTargets intl={intl} selected={target} onSelect={value => setTarget(value)} hasChildren={hasChildren} />
                </Row>
            </section>
        </CustomModal>
    )
  };
  
  export default injectIntl(ModalKpiInstructions);
