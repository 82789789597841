import React, { useCallback } from 'react';
import { injectIntl} from 'react-intl';

import {
  Input,
  Form,
  Modal,
} from 'antd';

import './style.less';

const CancelObjectiveForm = ({
  intl,
  visible,
  setVisibility,
  cancelObjective,
}) => {
  const t = intl.messages;
  const [form] = Form.useForm();
  const { validateFields } = form;

  const onCancelObjective = useCallback(
    () => {
      validateFields().then(() => {
        const { cancel_reason } = form.getFieldsValue();
        cancelObjective(cancel_reason);
        form.setFieldsValue({cancel_reason: ''});
        setVisibility(false);
      });
    },
    [validateFields, form, cancelObjective, setVisibility]
  );

  return (
    <Modal
      title={t.equality_objectives_cancel_reason}
      visible={visible}
      onOk={onCancelObjective}
      onCancel={() => setVisibility(false)}
      okText={t.equality_objectives_cancel}
      cancelText={t.exit_without_save}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name='cancel_reason'
          rules={[{ required: true, message: t.form_error_required}]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default injectIntl(CancelObjectiveForm);
