const DEBUG=false;

export function digestMessage(event,msg){
    if(DEBUG){
        console.log(`event | msg  => ${event} `,msg);
    }
    return {
        type: event.toUpperCase(),
        data: msg
    }
}