import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import Avatar from 'components/Avatar';
import ReactMarkdown from 'react-markdown';

import AplanetIcon from 'components/AplanetIcon';
import { toBetweenDotsDateFormat } from 'utils/date';
import { TableDataRequestsCellsContext } from './context';
import { DATA_REQUEST_STATUS } from 'utils/dataRequests';
import { DELETED_USER_SLUG } from 'utils/user';

const DataRequestsRequestedByRequestedTo = ({
  intl,
  requestedToEmail,
  requestedToName,
  requestedToAvatar,
  requesterComment,
  notificationDate,
  requestStatus,
  responseDate,
  hideUsersInfo,
}) => {
  const {
    requesterName,
    requesterEmail,
    requesterAvatar,
  } = useContext(TableDataRequestsCellsContext);
  
  return (
    <>
      <div className="DataRequests__date-status-wrapper">
        <div className="DataRequests__date-comment-wrapper">
          {notificationDate ? (<span className="DataRequests__notif-date">{notificationDate}</span>) : null}
          {requesterComment ? (
            <Tooltip
              overlayClassName="DataRequests__comment-tooltip"
              placement='bottomLeft'
              title={(
                <ReactMarkdown
                  className='DataRequests__requester-comment'
                  source={requesterComment}
                />
              )}
              arrowPointAtCenter
            >
              <div><AplanetIcon name="Comment" faStyle="fas" size="18px" /></div>
            </Tooltip>
          ) : null}
        </div>
        <div className="DataRequests__response-status-date-wrapper">
          {requestStatus ? (
            <span
              className={`DataRequests__request-status ${requestStatus}`}
            >
              {intl.formatMessage({id: `admin_data_requests_table_request_status_${requestStatus}`})}
            </span>
          ) : null}
          {responseDate ? (<span className="DataRequests__response-date">{toBetweenDotsDateFormat(responseDate, intl)}</span>) : null}
        </div>
      </div>
     {hideUsersInfo ? null : (
        <div className="DataRequests__users-info-wrapper">
          <div className="DataRequests__user-info">
            <Avatar size="large" src={requesterAvatar === DELETED_USER_SLUG ? null : requesterAvatar} name={requesterName === DELETED_USER_SLUG ? intl.formatMessage({ id: requesterName }) : requesterName} />
            <div className="DataRequests__name-email-wrapper">
              <span>{requesterName === DELETED_USER_SLUG ? intl.formatMessage({ id: requesterName }) : requesterName}</span>
              {requesterEmail === DELETED_USER_SLUG ? null : <span>{requesterEmail}</span>}
            </div>
          </div>
          <AplanetIcon name="Chevron right" size="18px" />
          <div className="DataRequests__user-info">
            <Avatar size="large" src={requestedToAvatar === DELETED_USER_SLUG ? null : requestedToAvatar} name={requestedToName === DELETED_USER_SLUG ? intl.formatMessage({ id: requestedToName }) : requestedToName} />
            <div className="DataRequests__name-email-wrapper">
              <span>{requestedToName === DELETED_USER_SLUG ? intl.formatMessage({ id: requestedToName }) : requestedToName}</span>
              {requestedToEmail === DELETED_USER_SLUG ? null : <span>{requestedToEmail}</span>}
            </div>
          </div>
        </div>
     )}
    </>
  );
}

DataRequestsRequestedByRequestedTo.propTypes = {
  requestedToEmail: PropTypes.string.isRequired,
  requestedToName: PropTypes.string.isRequired,
  requestedToAvatar: PropTypes.string,
  requesterComment: PropTypes.string,
  notificationDate: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  requestStatus: PropTypes.oneOf([
    DATA_REQUEST_STATUS.done,
    DATA_REQUEST_STATUS.pending,
    DATA_REQUEST_STATUS.not_notified,
    DATA_REQUEST_STATUS.partially_in_use,
    DATA_REQUEST_STATUS.in_use,
  ]),
  responseDate: PropTypes.string,
  hideUsersInfo: PropTypes.bool,
};

export default injectIntl(DataRequestsRequestedByRequestedTo);
