import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';

import { Loading } from 'tsComponents/emptyStates/Loading';
import AplanetIcon from 'components/AplanetIcon';

import './style.less';

const LOADING_TYPE = {
  spinner: 'spinner',
  animation: 'animation',
};

const LoaderModal = ({
  visible,
  title,
  message,
  type = LOADING_TYPE.animation,
}) => {
  const intl = useIntl();

  return <Modal
    className='LoaderModal'
    open={visible}
    closable={false}
    footer={null}
    title={null}>
      {type === LOADING_TYPE.spinner ? (
        <>
          <AplanetIcon
            name='spinner'
            faStyle='fad'
            className='LoaderModal__spinner'
            size='38px'
          />
          <p className="text--bold">{title || intl.formatMessage({ id: 'emptystates_loading_text' })}</p>
          <p className="text--regular">{message || intl.formatMessage({ id: 'emptystates_loading_default_message' })}</p>
        </>
      ) : null}
      {type === LOADING_TYPE.animation ? (
        <Loading title={title} message={message} />
      ) : null}
      {}
  </Modal>;
};

LoaderModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.oneOf([LOADING_TYPE.animation, LOADING_TYPE.spinner]),
};

export default LoaderModal;