import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { Checkbox, Tooltip } from 'antd';
import { useIntl } from 'react-intl';

import { useSDGsClustering } from "../../hooks/sdg";
import AplanetIcon from "components/AplanetIcon";

import './style.less';

const FILTER_LIST_TYPE = {
  default: 'list',
  list: 'list',
  checkbox: 'checkbox',
  sdg: 'sdg',
  tags: 'tags',
}

const unfoldSelection = (selection, sdgs) => sdgs
  .filter((s) => selection.includes(s.slug))
  .map((s) => s.targets)
  .flat()
  .map((s) => s.slug);

const FilterList = ({
  title,
  listOptions,
  setSelectedOptions,
  selectedOptions,
  hasSelectAll = false,
  listType = FILTER_LIST_TYPE.default,
}) => {
  const intl = useIntl();

  const { sdgs = [] } = useSelector((state) => state.taxonomies);

  const clusterSDGs = useSDGsClustering();
  const filterSelection = useMemo(() => {
    return listType === FILTER_LIST_TYPE.sdg ? clusterSDGs(selectedOptions).map((s) => s.slug) : selectedOptions;
  }, [
    listType,
    clusterSDGs,
    selectedOptions
  ]);

  const handleSelection = useCallback((optionSelected) => {
    let selection = filterSelection.includes(optionSelected)
      ? filterSelection.filter(v => v !== optionSelected)
      : [...filterSelection, optionSelected];
    if (listType === FILTER_LIST_TYPE.sdg) {
      selection = unfoldSelection(selection, sdgs);
    }
    setSelectedOptions(selection);
  }, [
    filterSelection,
    listType,
    setSelectedOptions,
    sdgs
  ]);

  const allSelected = useMemo(() => {
    const allValues = listOptions.map(({value}) => value);
    return allValues.every(value => filterSelection.includes(value))
  }, [
    filterSelection,
    listOptions
  ]);

  const indeterminateSelected = useMemo(() => {
    const allValues = listOptions.map(({value}) => value);
    return allValues.some(value => filterSelection.includes(value)) && !allSelected;
  }, [
    allSelected,
    filterSelection,
    listOptions
  ]);

  const toggleSelectAll = useCallback(() => {
    setSelectedOptions(allSelected ? [] : listOptions.map(({value}) => value));
  }, [
    allSelected,
    listOptions,
    setSelectedOptions,
  ]);

  return (
    <section className="FilterList">
      {title ? (<span className="FilterList__title">{title}</span>) : null}
      {listType === FILTER_LIST_TYPE.checkbox && hasSelectAll ? (
        <div className="FilterList__select-all" onClick={toggleSelectAll}>
          <Checkbox
            className="FilterList__checkbox"
            onChange={toggleSelectAll}
            checked={allSelected}
            indeterminate={indeterminateSelected}
          />
          <span className="FilterList__select-all-label">{intl.formatMessage({id: `filter_list_${allSelected ? 'remove' : 'select'}_all`})}</span>
        </div>
      ) : null}
      <div className={`FilterList__list ${listType}`}>
        { listOptions.map((option) => {
          return listType === FILTER_LIST_TYPE.tags ? (
            <div
              key={option.value}
              className={`FilterList__tag ${filterSelection.includes(option.value) ? 'is-selected' : '' }`}
              onClick={() => handleSelection(option.value)}
              id={`FilterList-id-${option.value}`}
            >
              {option.disabledTitle ? option.label : (
                <Tooltip
                  title={option.title ? option.title : option.label}
                  getPopupContainer={() => document.getElementById(`FilterList-id-${option.value}`)}
                  overlayClassName="FilterList__tooltip"
                >
                  { option.label }
                </Tooltip>
              )}
            </div>
          ) : (
            <div
              key={option.value}
              className={`FilterList__value ${option.value} ${filterSelection.includes(option.value) ? 'is-selected' : ''}`}
              onClick={() => handleSelection(option.value)}
              id={`FilterList-id-${option.value}`}
            >
              { listType === FILTER_LIST_TYPE.checkbox ? (
                  <Checkbox
                    className="FilterList__checkbox"
                    onChange={() => handleSelection(option.value)}
                    checked={filterSelection.includes(option.value)}
                  />
                ) : (
                  listType !== FILTER_LIST_TYPE.checkbox && filterSelection.includes(option.value) ? (<AplanetIcon name='Check' className="FilterList__check" />) : null
                )
              }
              {option.disabledTitle ? option.label : (
                <Tooltip
                  title={option.title ? option.title : option.label}
                  overlayClassName="FilterList__tooltip"
                  getPopupContainer={() => document.getElementById(`FilterList-id-${option.value}`)}
                >
                  { option.label }
                </Tooltip>
              )}
            </div>
          );
        }) }
      </div>
    </section>
  );
};

FilterList.propTypes = {
  title: PropTypes.string,
  hasSelectAll: PropTypes.bool,
  listOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string,
    disabledTitle: PropTypes.bool,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element
    ]).isRequired
  })).isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  listType: PropTypes.oneOf([
    FILTER_LIST_TYPE.list,
    FILTER_LIST_TYPE.checkbox,
    FILTER_LIST_TYPE.sdg, 
    FILTER_LIST_TYPE.tags, 
  ]),
};

export default FilterList;
