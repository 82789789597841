import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { range } from 'lodash';

import { fromDb } from 'utils/date';

import {
  Row,
  Col,
  Table,
  Empty,
} from 'antd';

import './style.less';

const ActionsCalendar = ({
  intl,
  actions,
}) => {
  const t = intl.messages;

  const yearsRange = useMemo(
    () => {
      if (!actions.length) {
        return [];
      }

      const actionsOrderedByStartDate = [...actions].sort((a, b) => {
        return fromDb(a.start_date).getTime() < fromDb(b.start_date).getTime() ? -1 : 1;
      });
      const actionsOrderedDescByEndDate = [...actions].sort((a, b) => {
        return fromDb(a.end_date).getTime() > fromDb(b.end_date).getTime() ? -1 : 1;
      });

      return range(
        fromDb(actionsOrderedByStartDate[0].start_date).getFullYear(),
        fromDb(actionsOrderedDescByEndDate[0].end_date).getFullYear() + 1
      );
    },
    [actions]
  );

  const actionsByYear = useMemo(
    () => {
      let data = {};

      yearsRange.forEach(year => {
        data[year] = actions.filter(action => {
          return (
            fromDb(action.start_date).getFullYear() <= year &&
            year <= fromDb(action.end_date).getFullYear()
          );
        });
      });
      return data;
    },
    [yearsRange, actions]
  );

  const isActionActiveInMonth = useCallback(
    (action, month, year) => {
      const startDate = fromDb(action.start_date);
      const endDate = fromDb(action.end_date);
      const startMonth = startDate.getFullYear() === year ? startDate.getMonth() : 0;
      const endMonth = endDate.getFullYear() === year ? endDate.getMonth() : 11;

      if (startMonth <= month && endMonth >= month) {
        return <div className='filled'></div>;
      };
    },
    []
  );

  const getActionProgress = useCallback(
    (action) => {
      const indicatorsProgress = action.indicators.map(indicator => {
        return Math.min(
          Math.round(indicator.current_value / indicator.target_value * 100), 100
        );
      });
      return Math.round(
        indicatorsProgress.reduce( ( p, c ) => p + c, 0 ) / indicatorsProgress.length
      );
    },
    []
  );

  const getActionStatus = useCallback(
    (action) => {
      const actionProgress = getActionProgress(action);
      let type = '';
      let status = `${t.in_progress.toUpperCase()} (${actionProgress}%)`;

      if (action.cancel_reason) {
        status = `${t.canceled.toUpperCase()} - ${action.cancel_reason}`;
        type = 'danger';
      } else if (actionProgress >= 100 || action.completed) {
        status = t.completed.toUpperCase();
        type = 'success';
      } else if (new Date() > fromDb(action.end_date)) {
        status = t.not_achieved.toUpperCase();
        type = 'warning';
      }
      return <span className={`action-status ${type}`}>{status}</span>;
    },
    [t, getActionProgress]
  );

  const getTableColumns = useCallback(
    (year) => {
      return [
        {
          title: '',
          dataIndex: 'name',
          render: (value, record) => <span>{value} ({getActionStatus(record)})</span>,
        },
        {
          title: t.month_january,
          render: (value, action) => isActionActiveInMonth(action, 0, year)
        },
        {
          title: t.month_february,
          render: (value, action) => isActionActiveInMonth(action, 1, year)
        },
        {
          title: t.month_march,
          render: (value, action) => isActionActiveInMonth(action, 2, year)
        },
        {
          title: t.month_april,
          render: (value, action) => isActionActiveInMonth(action, 3, year)
        },
        {
          title: t.month_may,
          render: (value, action) => isActionActiveInMonth(action, 4, year)
        },
        {
          title: t.month_june,
          render: (value, action) => isActionActiveInMonth(action, 5, year)
        },
        {
          title: t.month_july,
          render: (value, action) => isActionActiveInMonth(action, 6, year)
        },
        {
          title: t.month_august,
          render: (value, action) => isActionActiveInMonth(action, 7, year)
        },
        {
          title: t.month_september,
          render: (value, action) => isActionActiveInMonth(action, 8, year)
        },
        {
          title: t.month_october,
          render: (value, action) => isActionActiveInMonth(action, 9, year)
        },
        {
          title: t.month_november,
          render: (value, action) => isActionActiveInMonth(action, 10, year)
        },
        {
          title: t.month_december,
          render: (value, action) => isActionActiveInMonth(action, 11, year)
        },
      ];
    },
    [t, isActionActiveInMonth, getActionStatus]
  );

  return (
    <>
    {yearsRange.length > 0 ? (
      yearsRange.map((year, index) => {
        return (
          <React.Fragment key={index}>
            <Row>
              <Col span={24}>
                {`${t.year} ${index + 1}: ${year}`}
              </Col>
            </Row>
            <Row className='action-calendar'>
              <Col span={24}>
                <Table
                  bordered
                  columns={getTableColumns(year)}
                  dataSource={actionsByYear[year]}
                  size="small"
                  pagination={false}
                  rowKey='id' />
              </Col>
            </Row>
          </React.Fragment>
        );
      })
    ) : (
      <Empty
        image={'/images/icon-empty-data.svg'}
        description={t.no_action_found}
      />
    )}
    </>
  );
};

export default injectIntl(ActionsCalendar);
