import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const DEFAULT_PARAMETERS = {};

const UnknownChart = ({
  parameters = DEFAULT_PARAMETERS,
}) => {
  const intl = useIntl();
  const {
    nodata = intl.messages.wrong_chart,
  } = parameters;

  return (
    <div className="DashboardComponent__card_content">
      <div
        className="DashboardComponent__nodata"
      >
        { nodata }
      </div>
    </div>
  );
};

UnknownChart.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.object,
};

export default UnknownChart;

