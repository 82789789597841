const toggleSidebar = (isCollapsed) => ({
  type: 'TOGGLE_SIDEBAR',
  isCollapsed,
});

const reportDownloaded = () => ({
  type: 'REPORT_DOWNLOADED',
});

export {
  toggleSidebar,
  reportDownloaded,
}
