import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Button,
  Card,
  Select,
  Form,
} from 'antd';

import {addObjectiveValidation as validation} from './validation';
import useForm from 'utils/useForm';
import {
} from '../../actions/api';

import './style.less';

const AddObjectiveForm = ({
  intl,
  objectives,
  onSave,
  onCancel,
}) => {
  const t = intl.messages;
  const defaults = useMemo(() => ({}), []);
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    const {
      objective_id
    } = values;
    resetForm();
    onSave(objective_id);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  return (
    <Col span={24} className="form-input-wrapper">
      <Card className="form-input-card">
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('objective_id') ? 'error' : '' }
              help={ showError('objective_id') }
              colon={false}
              required
            >
              <Select
                name='objective_id'
                value={ getValue('objective_id') }
                onChange={(value) => handleChange('objective_id')(value)}
              >
                { objectives
                    .filter(objective => !objective.cancel_reason)
                    .map(objective => {
                      return <Select.Option
                        key={objective.id}
                        value={objective.id}
                      >{objective.name}</Select.Option>
                    })
                }
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={handleSubmit}
            >
              { t.save }
            </Button>
          </Col>
          <Col>
            <Button onClick={() => onCancel()}>
              { t.cancel }
            </Button>
          </Col>
        </Row>
      </Card>
    </Col>
  )
};

export default injectIntl(AddObjectiveForm);
