import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import EditableComment from 'components/EditableComment'
import CustomButton from 'components/CustomButton'
import CustomInput from 'components/CustomInput';
import AnswerFooter from 'components/AnswerFooter';

import CustomTable from 'components/CustomTable';

import useOrganizations from 'utils/useOrganizations';

import {
  useTableState,
  adaptInitialState,
} from 'utils/useTableState';

import { useTableUnits } from 'utils/useTableUnits';
import { useFeatureList } from 'components/FeatureSwitch';

import {
  updateTableValueUnits,
  schemaToColumns,
  valueToDataSource,
} from './converters';

import {
  Divider,
  Row,
  Col,
  Modal,
} from 'antd';

// NOTICE: This must match ./converters.js
const META_SLUG = '__meta';

// NOTICE: This must match ./Show.js
const COLUMNS_NO_SCROLL = 5;
const COLUMN_WIDTH = 200;
const QUALITATIVE_SCHEMA_TYPE= 'qualitative';
const NO_DEPENDEES = [];

const Footer = ({
  schema,
  slug,
  organization,
  organization_parent,
  period,
  dependees = NO_DEPENDEES,
  onChange,
  onPaste,
}) => {
  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);

  const isExtensible = ((schema.dimensions || [])[0] || {}).source === 'user';
  const [ newEntry, setNewEntry ] = useState('');

  const addNewRow = useCallback((name) => {
    const newState = adaptInitialState({
      schema,
      slug,
      tableDimensions,
      organization,
      organization_parent,
      period,
      dependees,
    }, { [name]: {} });
    onChange([name], newState[name]);
  }, [
    onChange,
    schema,
    slug,
    tableDimensions,
    organization,
    organization_parent,
    period,
    dependees,
  ]);

  const handleNewEntryChange = useCallback((e) => {
    // TODO: Only allow certain characters?
    setNewEntry(e.target.value);
  }, []);

  const handleNewRow = useCallback(() => {
    if(!newEntry) {
      return;
    }

    addNewRow(newEntry);
    setNewEntry('');
  }, [
    newEntry,
    addNewRow,
  ]);

  const handlePaste = useCallback((event) => {
    let clipboardData = event.clipboardData.getData('text').split(/\r\n?|\n/g);
    let canNotPaste = false;

    if (clipboardData[clipboardData.length - 1] === '') {
      // Remove the last empty element inserted by browser
      clipboardData.pop();
    }

    canNotPaste = clipboardData.length === 1
      && clipboardData[0].split('\t').length === 1;

    if (onPaste && !canNotPaste) {
      event.preventDefault();
      onPaste({
        clipboardData,
      });
    }
  }, [
    onPaste,
  ]);

  // TODO: Calculated fields
  if(!isExtensible || !onChange) {
    return null;
  }
  return (
    <Row
      type="flex"
      gutter={8}
      align="middle"
    >
      <Col
        xs={20}
        lg={6}
      >
        <CustomInput
          value={newEntry}
          onChange={handleNewEntryChange}
          onPaste={handlePaste}
        />
      </Col>
      <Col
        xs={4}
        lg={2}
      >
        <CustomButton
          disabled={!newEntry}
          onClick={handleNewRow}
        >
          +
        </CustomButton>
      </Col>
    </Row>
  );
};

const Component = injectIntl(({
  intl,
  className,
  schema,
  schemaLabels,
  config,
  value,
  onChange,
  onPaste,
  compact = false,
  columnsNoScroll = COLUMNS_NO_SCROLL,
  target,
  isTarget,
  targetEdit,
  tableDimensions: overrideTableDimensions,
  kpi_slug,
  organization,
  organization_parent,
  period,
  dependees = NO_DEPENDEES,
  sourceData
}) => {

  const { availableUnits } = useTableUnits(schema);

  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);

  const columns = useMemo(() => {
    return schemaToColumns(
      intl,
      schema,
      config,
      onChange,
      availableUnits,
      schemaLabels,
      overrideTableDimensions || tableDimensions,
      value,
      isTarget,
      targetEdit,
      onPaste,
    );
  }, [
    intl,
    schema,
    config,
    onChange,
    availableUnits,
    schemaLabels,
    tableDimensions,
    overrideTableDimensions,
    value,
    isTarget,
    targetEdit,
    onPaste,
  ]);

  const dataSource = useMemo(() => {
    return valueToDataSource(
      intl,
      columns,
      schema,
      value || {},
      null,
      availableUnits,
      schemaLabels,
      target?target.value:{},
      overrideTableDimensions || tableDimensions,
      sourceData
    );
  }, [
    intl,
    columns,
    schema,
    value,
    availableUnits,
    schemaLabels,
    target,
    tableDimensions,
    overrideTableDimensions,
    sourceData
  ]);

  const renderFooter = useCallback(() => {
    // TODO: Calculated data
    return (
      <Footer
        schema={schema}
        onChange={onChange}
        onPaste={onPaste}
        tableDimensions={overrideTableDimensions || tableDimensions}
        slug={kpi_slug}
        organization={organization}
        organization_parent={organization_parent}
        period={period}
        dependees={dependees}
      />
    );
  }, [
    schema,
    onChange,
    tableDimensions,
    onPaste,
    kpi_slug,
    organization,
    organization_parent,
    period,
    dependees,
    overrideTableDimensions,
  ]);

  const tableScroll = useMemo(() => {
    if(columns.length <= columnsNoScroll) {
      return undefined;
    }
    return { x: columns.length * COLUMN_WIDTH };
  }, [
    columns,
    columnsNoScroll,
  ]);

  const patchedColumns = useMemo(() => {
    if(columns.length <= columnsNoScroll) {
      return columns;
    }

    const rowDimensions = schema.innerSchema.type === 'tuple'
      ? schema.dimensions
      : schema.dimensions.filter(({ presentation }) => presentation !== 'column');

    return columns.map((column, index) => ({
      ...column,
      width: COLUMN_WIDTH,
      fixed: !compact && index<rowDimensions.length ? 'left' : undefined,
    }));
  }, [
    schema,
    columns,
    columnsNoScroll,
    compact,
  ]);
  return (
    <CustomTable
      className={className}
      columns={patchedColumns}
      dataSource={dataSource}
      pagination={false}
      footer={renderFooter}
      rowClassName={(record) => record[META_SLUG] ? 'KpiDetail__answer-table-metarow' : ''}
      scroll={tableScroll}
    />
  );
});

const Edit = ({
  intl,
  schema,
  schemaLabels,
  kpi_slug,
  period,
  value: initialValue,
  comment: initialComment,
  previous_value,
  previous_comment,
  previous_attachments,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
  onShowLastPeriod,
  onCancel,
  onSave,
  target,
  isTarget,
  targetEdit,
  onTarget,
  tableDimensions: overrideTableDimensions,
  onClear,
  dependees = NO_DEPENDEES,
  config: kpiConfig,
  source,
  source_params
}) => {
  const t = intl.messages;
  const {
    suborganization,
    permissions,
  } = useOrganizations();

  const { config = {} } = suborganization;
  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);

  const { availableUnits } = useTableUnits(schema);
  const initialValuesUpdatedUnits = updateTableValueUnits({
    schema,
    value: initialValue,
    availableUnits,
    schemaLabels,
    tableDimensions,
    sourceData: {
      source,
      source_params
    }
  });

  const {
    values: tableValues,
    update: tableUpdate,
  } = useTableState({
    schema,
    tableDimensions: overrideTableDimensions || tableDimensions,
    initialState: initialValuesUpdatedUnits,
    slug: kpi_slug,
    organization: suborganization.slug,
    organization_parent: suborganization.parent_slug,
    period,
    dependees,
  });

  const {
    features: featureList,
  } = useFeatureList();  

  const [comment, setComment] = useState(initialComment);
  const [ dirty, setDirty ] = useState(false);
  const [ edittingComment, setEdittingComment ] = useState(false);

  const handleChange = useCallback((...params) => {
    setDirty(true);
    tableUpdate(...params);
  }, [
    tableUpdate,
  ]);

  const handleUseLastValue = useCallback(() => {
    onCancel();
    onUseLastValue();
  }, [
    onUseLastValue,
    onCancel,
  ]);

  const handleUseLastWholeData = useCallback(() => {
    onCancel();
    onUseLastWholeData();
  }, [
    onUseLastWholeData,
    onCancel,
  ]);

  const handleUseLastAttachments = useCallback(() => {
    onCancel();
    onUseLastAttachments();
  }, [
    onUseLastAttachments,
    onCancel,
  ]);

  const handleUseLastPeriod = useCallback(() => {
    onCancel();
    onUseLastPeriod();
  }, [
    onUseLastPeriod,
    onCancel,
  ]);

  const handleCommentChange = useCallback((comment) => {
    setDirty(true);
    setComment(comment);
  }, []);

  const isEmpty = useMemo(() => {
    // TODO: A table is empty if all of its cells are empty
    return false;
  }, [
  ]);

  const handleCancel = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    onCancel();
  }, [
    dirty,
    onCancel,
  ]);

  const handleClear = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    Modal.confirm({
      title: t.kpi_detail_clear_title,
      content: t.kpi_detail_clear_content,
      okText: t.kpi_detail_clear_ok,
      okType: 'danger',
      cancelText: t.kpi_detail_clear_cancel,
      onOk() {
        onClear ? onClear() : onSave(null, '')
      },
      onCancel() {},
    });
  }, [
    t,
    dirty,
    onSave,
    onClear,
  ]);

  const updateOrderOfValues = useCallback(
    (values) => {
      let updatedValues = {};
      Object.keys(values).forEach(
        (key, index) => {
          updatedValues[key] = {
            ...values[key],
            _order: index + 1,
          };
        }
      );
      return updatedValues;
    },
    []
  );

  const handleSave = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    onSave(
      updateOrderOfValues(tableValues),
      comment,
    );
  }, [
    dirty,
    tableValues,
    comment,
    onSave,
    updateOrderOfValues,
  ]);

  const hasTargetPermissions = useMemo(() => {
    return featureList && featureList.has("targets")
      && permissions.can_configure_kpi
      && schema.type !== QUALITATIVE_SCHEMA_TYPE;
  }, [
    featureList,
    permissions.can_configure_kpi,
    schema.type
  ]);

  return (
    <React.Fragment>
      <section>
        <Component
          schema={schema}
          schemaLabels={schemaLabels}
          config={config}
          value={tableValues}
          onChange={handleChange}
          target={target}
          isTarget={isTarget}
          targetEdit={targetEdit}
          tableDimensions={overrideTableDimensions || tableDimensions}
          kpi_slug={kpi_slug}
          organization={suborganization.slug}
          organization_parent={suborganization.parent_slug}
          period={period}
          dependees={dependees}
          sourceData={{ source, source_params }}
        />
        <Divider />
        <EditableComment.Component
          title={t.privateComment}
          value={comment}
          onChange={handleCommentChange}
          editMode={edittingComment}
          setEditMode={setEdittingComment}
          editable
          titleClass='KpiDetail__title'
          config={kpiConfig}
        />
      </section>
      <Divider/>
      <AnswerFooter
        editting
        canWrite
        hasLastPeriod={!!previous_value}
        hasLastComment={!!previous_comment}
        hasLastAttachments={!!previous_attachments?.length}
        onShowLastPeriod={onShowLastPeriod}
        onUseLastValue={handleUseLastValue}
        onUseLastWholeData={handleUseLastWholeData}
        onUseLastAttachments={handleUseLastAttachments}
        onUseLastPeriod={handleUseLastPeriod}
        hasInitialValue={!!initialValue}
        onCancelEdit={handleCancel}
        onClearEdit={handleClear}
        onSaveEdit={handleSave}
        dirty={dirty}
        empty={isEmpty}
        hasTarget={isTarget}
        onTarget={onTarget}
        isTargetElegible={hasTargetPermissions}
      />
    </React.Fragment>
  );
};

Edit.Component = Component;

export default injectIntl(Edit);

