import React, { useCallback, useEffect, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import Edit from 'components/TableAnswer/Edit';
import { dimensionToRows } from 'components/TableAnswer/converters/row';
import { getOptions } from 'components/ChoiceAnswer/utils';
import { translateSlug } from 'components/TableAnswer/converters/common';

import { selectTargetValue } from 'utils/kpiMonitoring';
import { adaptDependeeKpiForColumns } from 'utils/kpi_formula/column';
import { cartesian } from 'utils/cartesian';
import { useTableState } from 'utils/useTableState';
import useOrganizations from "utils/useOrganizations";

import { useEventTracking } from 'hooks/useEventTracking';

import {
  Col,
  Row,
} from 'antd';


const TableComponent = ({
  intl,
  period,
  kpi,
  setTableValues,
  setDirty,
  canEdit,
  tableDimensions,
  suborganization,
  suborganization_parent_slug,
}) => {
  const dependees = useMemo(() => {
    // NOTICE: Here we adapt the 'dependee_kpis' field to the formula engine format
    return (kpi.dependee_kpis || []).map(adaptDependeeKpiForColumns)
  }, [
    kpi,
  ]);
  const eventTracking = useEventTracking();
  const {
    organization,
    suborganization: currentOrganization,
  } = useOrganizations();

  const {
    values: tableValues,
    update: tableUpdate,
  } = useTableState({
    schema: kpi.schema || {},
    tableDimensions: tableDimensions,
    initialState: kpi.kpi_value,
    slug: kpi.slug,
    organization: suborganization?.slug,
    organization_parent: suborganization_parent_slug,
    period,
    dependees,
  });

  useEffect(() => setTableValues(tableValues), [setTableValues, tableValues]);

  const rows = useMemo(() => {
    const {
      dimensions = [],
      innerSchema = {},
    } = kpi.schema;
    const isHeterogeneous = innerSchema.type === 'tuple';

    const rowDimensions = isHeterogeneous
      ? dimensions
      : dimensions.filter(({ presentation }) => presentation !== 'column');
    const mapDimension = dimensionToRows(
      innerSchema,
      tableValues,
      kpi.schemaLabels,
      tableDimensions,
    );
    const ungroupedRows = rowDimensions
      .map(mapDimension);
    return cartesian(...ungroupedRows);
  }, [
    kpi,
    tableDimensions,
    tableValues,
  ]);

  const columns = useMemo(() => {
    const {
      dimensions = [],
      innerSchema = {},
    } = kpi.schema;

    const isHeterogeneous = innerSchema.type === 'tuple';

    if(isHeterogeneous) {
      return (innerSchema.components || []).map(component => {
        let data = {
          slug: component.name,
          type: component.type,
          calculated: component.type === 'quantitative'
            && component.source === 'calculated',
        };

        if (component.type === 'choice') {
          data.options = getOptions(
            component, kpi.schemaLabels.innerSchema, tableDimensions,
          )
        }

        if (component.type === 'quantitative') {
          data.metricSlug = component.metricSlug;
          data.decimalPoints = component.decimalPoints;
        }
        return data;
      });
    } else {
      const columnDimensions = dimensions
        .filter(({ presentation }) => presentation === 'column');
      if (columnDimensions.length) {
        return columnDimensions.map(dimension => {
          let columns = [];
          let commonData = {
            type: innerSchema.type,
            calculated: innerSchema.type === 'quantitative'
              && innerSchema.source === 'calculated',
          };

          if (innerSchema.type === 'choice') {
            commonData.options = getOptions(
              innerSchema, kpi.schemaLabels, tableDimensions,
            );
          }

          if (innerSchema.type === 'quantitative') {
            commonData.metricSlug = innerSchema.metricSlug;
            commonData.decimalPoints = innerSchema.decimalPoints;
          }

          if (dimension.source === 'organization') {
            columns = (
              tableDimensions[dimension.by] || []
            )
            .map(slug => ({
              slug,
              text: `${ translateSlug(((kpi.schemaLabels || {}).dimensionValues || {})[dimension.by] )(slug) }`,
            }))
            .sort((a, b) => {
              if(a.text < b.text) { return -1; }
              if(a.text > b.text) { return 1; }
              return 0;
            })
            .map(({slug}) => ({
              slug,
              ...commonData,
            }));
          } else if (dimension.source === 'singleton') {
            columns = [{
              slug: dimension.by,
              ...commonData,
            }];
          } else if (['standard', 'user'].includes(dimension.source)) {
            columns = (dimension.standardItems || []).map(({ slug }) => ({
              slug,
              ...commonData,
            }));
          }

          return columns;
        }).reduce((arr, el) => arr.concat(el), []);
      } else if (innerSchema.type === 'quantitative') {
        return [{
          slug: 'value',
          type: 'quantitative',
          metricSlug: innerSchema.metricSlug,
          decimalPoints: innerSchema.decimalPoints,
        }]
      }
    }
  }, [
    kpi,
    tableDimensions,
  ]);

  const generatePastedValue = useCallback((value, column) => {
    switch (column.type) {
      case 'quantitative':
        const parsedValue = value ? Number(value) : NaN;

        if (isNaN(parsedValue)) {
          return {
            value: null,
          };
        }

        const forceDecimal = kpi.config.hasOwnProperty(column.metricSlug)
          ? kpi.config[column.metricSlug]
          : kpi.config['*']

        const decimalPoints = typeof forceDecimal === 'number'
          ? forceDecimal
          : (column.decimalPoints || 0);
        return {
          value: parseFloat(parsedValue).toFixed(decimalPoints),
        };
      case 'qualitative':
        return {
          text: value,
        };
      case 'boolean':
        const booleans = {
          truthy: [
            1, '1', intl.formatMessage({id: 'yes'}).toLowerCase(),
          ],
          falsy: [
            0, '0', intl.formatMessage({id: 'no'}).toLowerCase(),
          ],
        };
        return {
          boolean: booleans.falsy.includes(value.toLowerCase()) ? false
          : booleans.truthy.includes(value.toLowerCase()) ? true : null,
        };
      case 'choice':
        return {
          choice: column.options?.find(({name}) =>
            name.toLowerCase() === value.toLowerCase()
          )?.slug || null,
        };
      default:
        return {};
    }
  }, [
    intl,
    kpi,
  ]);

  const handleOnPasteExtensibleValue = useCallback((text, clipboardData) => {
    const values = clipboardData;

    // Delete current row
    let newValue = Object.keys(tableValues)
      .filter(k => k !== text)
      .reduce((obj, k) => {
        obj[k] = tableValues[k];
        return obj;
      }, {});
    tableUpdate([], newValue);

    // Create new rows
    for (let i = 0; i < values.length; i++) {
      const [rowValue, ...columnValues] = values[i].split('\t');

      tableUpdate(...[
        [rowValue],
        {
          text: rowValue,
          _address: [rowValue],
        }
      ]);

      for (let i = 0; i < Math.min(columnValues.length, columns.length); i++) {
        const column = columns[i];
        if (column.calculated) {
          continue;
        }

        const updatedAddress = [rowValue, column.slug];
        tableUpdate(...[
          updatedAddress,
          {
            ...(generatePastedValue(columnValues[i], column)),
            _address: updatedAddress,
          }
        ]);
      }
    }
  }, [
    tableValues,
    columns,
    tableUpdate,
    generatePastedValue,
  ]);

  const handleOnPasteValue = useCallback((address, value) => {
    setDirty(true);
    const isExtensible = ((kpi.schema.dimensions || [])[0] || {}).source === 'user';

    eventTracking.capture('bulkManage.paste', {
      organization_name: organization.name,
      suborganization_name: currentOrganization.name,
      indicator_name: kpi.name,
      period: kpi.period,
      destination: 'modal table',
    });

    if (isExtensible && !value) {
      // This means that value was pasted in extensible field row cell
      return handleOnPasteExtensibleValue(address.text, address.clipboardData);
    }

    // Last item in address is column
    const column = address.pop();
    const values = value.clipboardData;

    const rowIndex = rows.map(row => row.map(r => r.slug).join('-')).indexOf(address.join('-'));
    const columnIndex = columns.map(({slug}) => slug).indexOf(column);

    for (let i = 0; i < Math.min(values.length, (rows.length - rowIndex)); i++) {
      const columnValues = values[i].split('\t');

      for (let j = 0; j < Math.min(columnValues.length, (columns.length - columnIndex)); j++) {
        const column = columns[j + columnIndex];
        if (column.calculated) {
          continue;
        }

        const updatedAddress = [...(rows[i + rowIndex].map(r => r.slug)), column.slug];
        tableUpdate(...[
          updatedAddress,
          {
            ...(generatePastedValue(columnValues[j], column)),
            _address: updatedAddress,
          }
        ]);
      }
    }
  }, [
    kpi,
    rows,
    columns,
    setDirty,
    tableUpdate,
    organization,
    eventTracking,
    currentOrganization,
    generatePastedValue,
    handleOnPasteExtensibleValue,
  ]);

  const handleOnChangeValue = useCallback((...params) => {
    setDirty(true);
    tableUpdate(...params);
  }, [
    tableUpdate,
    setDirty,
  ]);

  const target = useMemo(
    () => suborganization?.product_config?.atlas?.features?.targets
      ? selectTargetValue(kpi.monitoring_value)
      : null,
    [
      suborganization,
      kpi,
    ]
  );

  return (
    <Row>
      <Col span={24}>
        <Edit.Component
          className="TableEdit"
          schema={kpi.schema}
          schemaLabels={kpi.schemaLabels}
          config={suborganization?.config || {}}
          value={tableValues}
          onChange={canEdit && handleOnChangeValue}
          onPaste={handleOnPasteValue}
          target={target}
          isTarget={!!target}
          tableDimensions={tableDimensions}
          kpi_slug={kpi.slug}
          organization={suborganization?.slug}
          organization_parent={suborganization_parent_slug}
          period={period}
          dependees={dependees}
        />
      </Col>
    </Row>
  );
}

export default injectIntl(TableComponent);
