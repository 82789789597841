import React from "react";
//import { injectIntl } from 'react-intl';

import { Row, Col, Divider, Button } from "antd";
import AplanetIcon from 'components/AplanetIcon';
import PopupBody from "./Body";
import './style.less'

 
const NO_OP = ()=>{};
  
const Popup = ({
    intl,
    data,
    onRead = NO_OP,
    onDelete = NO_OP,
    onNavigation = NO_OP
}) => {
    const t = intl.messages;
    const {
        title,
        read,
        content,
        created_at,
    } = data;

    const {linkUrl, linkTitle} = ((title) => {
        let linkUrl = '', linkTitle = '';
        switch (title) {
            case 'org_archive_completed':
                linkUrl = '/data/files/downloads';
                linkTitle = 'org_archive';
                break;
            // case 'data_request_answered':
            //     linkUrl = `/${content.organization.slug}/${content.kpi.slug}/${content.kpi.period}/data` // or Backend built it?
            //     linkTitle = 'indicator;
            //     break;
            default:
                break;
        }
        return {linkUrl, linkTitle};
    })(title);

    return (
        <>
            <Row
                className="Popup__header"
                type="flex"
                justify="space-between"
            >
                <Col
                    className="Popup__header__notification__name"
                >
                    { intl.formatMessage( {id: `notification_header_title_${title}` }) }
                </Col>

            </Row>
            <Divider className="Popup__divider" />
                <PopupBody
                    intl={intl}
                    title={title}
                    content={{created_at, ...content}}
                    onNavigation={onNavigation}
                 />
            <Divider className="Popup__divider" />
            <Row justify={ read ? "end" :"space-between"}>
                { linkUrl &&         
                <Col span={21} className="Popup__Footer__Navigation">
                    <Button type="link" onClick={() => onNavigation(linkUrl)}>
                        <AplanetIcon name="Arrow to right" className="Popup__Footer__NavigationLink" />
                        {t[`notification_footer_navigation_link_${linkTitle}`]}
                    </Button>
                </Col>
                }
            </Row>
        </>
    )
};

export default Popup;
