import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatMessage } from '../../intl'
import CustomCollapse from 'components/CustomCollapse';
import EsgLogo from 'components/EsgLogo';
import SdgMiniLogo from 'components/SdgMiniLogo';
import CustomTag from 'components/CustomTag';
import { Loading } from 'tsComponents/emptyStates/Loading';
import AplanetIcon from 'components/AplanetIcon';
import ReportingStandardTag from 'components/ReportingStandardTag';
import { useSDGsClustering } from "../../hooks/sdg";
import { sortBy } from 'lodash';

const TypeToIcon = {
  table: "Table",
  quantitative: "Subscript",
  qualitative: "Text",
  boolean: "Checkbox fill",
  tuple: "Text",
  choice: "Text",
  default: "Text",
}

const KpiCategorySummaryHeader = ({
  checkedKpis,
  selectedKpi,
  checkedCategories,
  selectedCategory,
  esgTypes = [],
  sdgs = [],
  tags = [],
  fieldTypes = [],
  periodicities = [],
  checkedKpisNumber,
  checkedCategoriesNumber,
  checkedAndSelectedKpis,
  allStandards,
  loading
}) => {
  const clusterSDGs = useSDGsClustering();

  const clusteredSDGs = useCallback((sdgs) => {
    return clusterSDGs(sdgs).sort((a, b) => a.code - b.code);
  }, [clusterSDGs]);

  const hasDataToShow = useMemo(() => {
    return esgTypes.length > 0 || sdgs.length > 0 || tags.length > 0;
  }, [esgTypes, sdgs, tags]);

  const PanelHeaderMessage = useMemo(() => {
      if(checkedKpis.length === 0 && checkedCategories.length === 0 && (selectedKpi || selectedCategory)) return (selectedKpi || selectedCategory).name
      else return formatMessage('KpiTreeActionsSection_actions_info_subtitle', { checkedCategoriesNumber, checkedKpisNumber })
  }, [checkedCategories.length, checkedCategoriesNumber, checkedKpis.length, checkedKpisNumber, selectedCategory, selectedKpi])

  const PanelHeader = useCallback(() => (
    <div className="KpiCategorySummaryHeaderTitle">
      <span>
        {formatMessage('KpiTreeActionsSection_actions_info')}
      </span>
      <span>
        {PanelHeaderMessage}
      </span>
    </div>
  ),
    [PanelHeaderMessage]
  )
  const CheckIfEveryOneHas = (collection, value, key) => collection?.every(child => child[key].some(e => e === value))
  return hasDataToShow && (
    <CustomCollapse header={<PanelHeader />}>
      {loading 
      ? <Loading /> 
      : (
        <>
          {esgTypes.length > 0 && (
            <div className="KpiTreeActionsSection__actions__info__ESG">
              <span>
                ESG
              </span>
              <div>
                {esgTypes.map(type => (
                  <EsgLogo
                    key={type}
                    type={type}
                    faStyle={!CheckIfEveryOneHas(checkedAndSelectedKpis, type, "esgs") ? "fal" : "fad"}
                    uncolored={!CheckIfEveryOneHas(checkedAndSelectedKpis, type, "esgs")}
                  />
                ))}
              </div>
            </div>
          )}
          {clusteredSDGs(sdgs).length > 0 && (
            <div className="KpiTreeActionsSection__actions__info__SDG">
              <span>
                SDG
              </span>
              <div>
                {clusteredSDGs(sdgs).map(sdg => (
                  <SdgMiniLogo key={sdg.slug} sdg={sdg} unColored={!checkedAndSelectedKpis?.every(child => clusteredSDGs(child.sdgs).some(e => e.code === sdg.code))} />
                ))}
              </div>
            </div>
          )}
          {tags.length > 0 && ( //Tags are not implemented right now?
            <div className="KpiTreeActionsSection__actions__info__TAGS">
              <span>
                {formatMessage('tags').toUpperCase()}
              </span>
              <div>
                {sortBy(tags.map(tag => ({ tag, everyOneHas: !CheckIfEveryOneHas(checkedAndSelectedKpis, tag, "tags") })), ['everyOneHas', 'tag']).map(({ tag, everyOneHas }) => (
                  <CustomTag
                    key={tag}
                    name={tag}
                    colorclass={!everyOneHas ? "green" : "grey"}
                  />
                ))}
              </div>
            </div>
          )}
          {fieldTypes.length > 0 && (
            <div className="KpiTreeActionsSection__actions__info__FIELDTYPES">
              <span>
                {formatMessage('field_types').toUpperCase()}
              </span>
              <div>
                {fieldTypes.map(field => (
                  <div key={field} className={checkedAndSelectedKpis?.every(child => child.schema.type === field) ? "Shared" : "Unshared"}>
                    <AplanetIcon name={TypeToIcon[field] || TypeToIcon.default} size="18px" />
                    <span>{formatMessage(`field_type_${field}`)}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {periodicities.length > 0 && (
            <div className="KpiTreeActionsSection__actions__info__PERIODICITIES">
              <span>
                {formatMessage('periodicities').toUpperCase()}
              </span>
              <div>
                {periodicities.map(per => (
                  <CustomTag
                    key={per}
                    name={formatMessage(`periodicity_${per}`)}
                    colorclass={CheckIfEveryOneHas(checkedAndSelectedKpis, per, "periodicities") ? per : "grey"}
                  />
                ))}
              </div>
            </div>
          )}
          {allStandards.length > 0 && (
            <div className="KpiTreeActionsSection__actions__info__STANDARDS">
              <span>
                {formatMessage('standard').toUpperCase()}
              </span>
              <div>
                {allStandards.map(standard => (
                  <div key={standard}>
                    <ReportingStandardTag
                      className="KpiTree__standard_tag"
                      standard={standard}
                      name={standard}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </CustomCollapse>
  )
}

KpiCategorySummaryHeader.propTypes = {
  esgTypes: PropTypes.arrayOf(PropTypes.string),
  sdgs: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string)
};

export default KpiCategorySummaryHeader;
