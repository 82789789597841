import { canDropCategory } from "./drop_category";
import { canDropIndicator } from "./drop_indicator";

export const canDropNode = (
  droppedNode,
  dropProps,
  reportingStructure,
) => {

  if (!dropProps.hasOwnProperty('dropToGap') || !dropProps.hasOwnProperty('referenceNodeWhenDrop') || !dropProps.hasOwnProperty('isNodeDroppedAtTheTopOfAllRoots')) {
    throw new Error(`Wrong dropProps object`);
  }
  
  return droppedNode.isCategory
    ? canDropCategory(droppedNode, dropProps, reportingStructure)
    : canDropIndicator(droppedNode, dropProps, reportingStructure);
};

export const getParentCategoryUuid = (
  dropProps,
) => {
  if (!dropProps.hasOwnProperty('dropToGap') || !dropProps.hasOwnProperty('referenceNodeWhenDrop') || !dropProps.hasOwnProperty('isNodeDroppedAtTheTopOfAllRoots')) {
    throw new Error(`Wrong dropProps object`);
  }

  const {
    dropToGap,
    referenceNodeWhenDrop,
  } = dropProps;

  const isDroppedInsideTopOfCategory = !dropToGap;
  if (isDroppedInsideTopOfCategory) {
    return referenceNodeWhenDrop.uuid;
  }

  return referenceNodeWhenDrop.parent_uuid;
};