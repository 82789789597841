// Validation for equality plan committee form
import { isValidPhoneNumber } from 'react-phone-number-input';
import phoneMetadata from 'components/PhoneInput/metadata.custom.json';

import { EMAIL_REGEXP, isValidDNI } from 'utils/validation';

const allowedCountryCodes = Object.keys(
  phoneMetadata.country_calling_codes || {}
).map(code => `+${code}`);

export const fullValidation = (t) => ({
  collaborated_with_consultant,
  consultant_name,
}) => {
  let errors = {};

  if (collaborated_with_consultant === 'yes' && !consultant_name) {
    errors.consultant_name = t.form_error_required;
  }

  return errors;
};

export const attendeesValidation = (intl) => ({
  name,
  nationality,
  id,
  phone,
  address,
}) => {
  const t = intl.messages;
  let errors = {};

  if (!name) {
    errors.name = t.form_error_required;
  }

  if (!nationality) {
    errors.nationality = t.form_error_required;
  }

  if (intl.locale.includes('es') && id && !isValidDNI(id)) {
    errors.id = t.form_error_invalid_dni;
  }

  if (!phone) {
    errors.phone = t.form_error_required;
  } else if(!allowedCountryCodes.find(cc => phone.startsWith(cc))) {
    errors.phone = t.form_error_choose_phone_country;
  } else if(!isValidPhoneNumber(phone)) {
    errors.phone = t.form_error_invalid_phone;
  }

  if (!address) {
    errors.address = t.form_error_required;
  }

  return errors;
};

export const membersValidation = (t) => ({
  name,
  email,
}) => {
  let errors = {};
  if (!name) {
    errors.name = t.form_error_required;
  }

  if (email && !EMAIL_REGEXP.test(email)) {
    errors.email = t.form_error_invalid_email;
  }

  return errors;
};
