import { EMAIL_REGEXP } from 'utils/validation';

const GroupsOfInterestFormValidation = (t, formType) => ({
  name,
  email,
  type,
}) => {
  let errors = {};

  if(!name) {
    errors.name = t.form_error_required;
    errors._firstError = errors._firstError || 'name';
  }

  if (formType === 'member') {
    if(!email || !EMAIL_REGEXP.test(email)) {
      errors.email = t.form_error_required;
      errors._firstError = errors._firstError || 'email';
    }
  } else {
    if(!type) {
      errors.type = t.form_error_required;
      errors._firstError = errors._firstError || 'type';
    }
  }

  return errors;
}

export default GroupsOfInterestFormValidation;
