import React, { createContext, Fragment, useCallback, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import MainLayout from 'components/MainLayout';
import CustomStepsWizard from 'components/CustomStepsWizard';
import useSteps from './useSteps';
import useForm from 'utils/useForm';
import { validate } from './validators';
import { useDashboardCardForm } from './useDashboardCardForm';

import './style.less';

export const DashboardCardFormContext = createContext();

const DashboardCardForm = ({
    intl,
    history,
    match,
    data: initialValues
  }) => {

    const activeTab = match.params.activeTab;
    const activeCard = match.params.activeCard;
    const { saveCardForm } = useDashboardCardForm(activeTab, activeCard);
    const [currentStep, setCurrentStep] = useState(0); 
    const validateForm = useMemo(() => validate(intl), [ intl ]);

    const submitForm = (data) => {
        saveCardForm(data);
        history.replace(`/dashboard/${ activeTab }`);
    }

    const {
        values,
        handleChange,
        handleSubmit,
        validateStep
    } = useForm({
        callback: submitForm,
        validate: validateForm,
        defaultValues: initialValues,
    });
    
    const steps = useSteps({
        intl,
        values
    });

    const onCancelStepsWizard = () => {
        history.replace(`/dashboard/${ activeTab }`);
    }

    const onFinishStepsWizard = () => {
        handleSubmit();
    }
    
    const onClickNextStep = useCallback((step) => { 
        const validation = validateStep(step);
        if (isEmpty(validation)){
            setCurrentStep(step+1);
        }
    }, [validateStep]);

    const onClickPreviousStep = (step) => {
        setCurrentStep(step-1);
    }

    return (
        <Fragment>
            <DashboardCardFormContext.Provider value={{ handleChange, values, currentStep }}>
                <MainLayout className="DashboardCardFormLayout">
                    <div className="DashboardCardFormLayout__content">
                        <CustomStepsWizard
                            intl={intl}
                            steps={steps}
                            title={ intl.formatMessage({id: activeCard ? 'dashboard_card_form_title_edit' : 'dashboard_card_form_title' }) }
                            finishButtonTitle={intl.formatMessage({id: 'modal_add_kpi_finish_button'})}
                            onCancel={onCancelStepsWizard}
                            onFinish={onFinishStepsWizard}
                            onClickNextStep={onClickNextStep} 
                            onClickPreviousStep={onClickPreviousStep}
                        />
                    </div>
                </MainLayout>
            </DashboardCardFormContext.Provider>
        </Fragment>
    )
}

export default injectIntl(
    withRouter(
        DashboardCardForm
    )
);  