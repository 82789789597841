import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd';

import './style.less';

import Icon from './IconLibrary.json';

const AplanetIcon = ({
  name,
  faStyle = "fal",
  size = "20px",
  className = undefined,
  title = undefined,
  titlePosition = undefined,
  titleClassName = "",
  titleColor = undefined,
  onClick = undefined,
  style = {},
  iconProps = {}
}) => {
  if(!faStyle || !name) {
    return null;
  }

  return (title ? (
      <Tooltip
        title={title}
        placement={titlePosition}
        overlayClassName={ `AplanetIcon__tooltip ${titleClassName}` }
        color={titleColor}
      >
        <FontAwesomeIcon
          data-testid={name}
          className={`AplanetIcon ${className || ""}`}
          icon={[faStyle, Icon[name] || name]}
          onClick={onClick}
          style={{...style, fontSize: size}}
          {...iconProps}
        />
      </Tooltip>
    ) : (
      <FontAwesomeIcon
        data-testid={name}
        className={`AplanetIcon ${className || ""}`}
        icon={[faStyle, Icon[name] || name]}
        onClick={onClick}
        style={{...style, fontSize: size}}
        {...iconProps}
      />
    )
  );
}

export default AplanetIcon;
