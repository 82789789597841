const TARGET = 'category_detail';

const initialState = {
    data: undefined,
    fetching: false,
    error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API_CALL_REQUEST':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        fetching: true,
        error: null
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if (!response || response.target !== TARGET) return state;
      const result = action.response.result;

      return {
        ...state,
        data: {...result, is_custom: true},
        fetching: false,
        error: null
      };
    case 'API_CALL_FAILED':
      if (!action.request || action.request.target !== TARGET) return state;
      const { code, text } = action;
      return {
        ...state,
        fetching: false,
        error: {
          code,
          text
        }
      };
    case 'CHANGE_KPI_CATEGORY_SLUG':
        const category_slug = action.category_slug;
        return {
            ...state,
            data: category_slug,
            fetching: false,
            error: null
        };
    default:
      return state;
  }
};

export { reducer as category_detail };
