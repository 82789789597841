import React from 'react';
import { Button } from 'antd';
import './style.less';

const EditButton = ({
  onClick
}) => {
  return (
    <Button
        className='EditButton'
        onClick={onClick}
    >
        <img src='/images/assets/icon-edit.svg' alt="EditButton Icon"/>
    </Button>
  );
}

export default EditButton;
