import { getQueryFiltersNames, SCREENS, DEFAULT_FILTER_SET } from "hooks/useScreenFilters/constants";
import { uniq, flatten } from "lodash";

export const DATA_REQUEST_STATUS = {
  pending: 'pending',
  done: 'done',
  partially_in_use: 'partially-in-use',
  in_use: 'in-use',
  not_notified: 'not-notified',
};

export const DATA_REQUEST_PERIODICITIES = {
  month: 'month',
  quarter: 'quarter',
  semester: 'semester',
  year: 'year',
};

export const DATA_REQUEST_RECURRENCE = {
  one_time: 'one_time',
  recurrent: 'recurrent',
};

export const DATA_REQUEST_USER_TYPE = {
  external: 'external',
  internal: 'internal',
};

const MAX_KPIS_SELECTED_TO_CREATE_DR = 50;

export const isDisabledKpiInReportingStructureTree = (checkedKpis, currentKpi) => {
  const checkedKpiUuid = checkedKpis.map(({uuid}) => uuid);
  const checkedKpiSlugs = checkedKpis.map(({slug}) => slug);
  return (checkedKpis.length >= MAX_KPIS_SELECTED_TO_CREATE_DR || checkedKpiSlugs.includes(currentKpi.slug)) && !checkedKpiUuid.includes(currentKpi.uuid);
};

export const isDisabledCategoryInReportingStructureTree = (checkedKpis, kpisInside) => {
  const checkedKpiUuid = checkedKpis.map(({uuid}) => uuid);
  const kpisCanBeSelected = kpisInside.filter(kpi => !isDisabledKpiInReportingStructureTree(checkedKpis, kpi) && !checkedKpiUuid.includes(kpi.uuid));
  const allKpisDisabled = kpisInside.every(kpi => isDisabledKpiInReportingStructureTree(checkedKpis, kpi));
  return kpisInside.length > 0 && ((
    (checkedKpis.length === MAX_KPIS_SELECTED_TO_CREATE_DR || (kpisCanBeSelected.length + checkedKpis.length > MAX_KPIS_SELECTED_TO_CREATE_DR)) && kpisCanBeSelected.length !== 0
  ) || kpisCanBeSelected.length > MAX_KPIS_SELECTED_TO_CREATE_DR || allKpisDisabled); 
};

export const getDataRequestIdsByKpi = (kpiSlug, dataRequests) => {
  const requests = dataRequests.find(dreq => dreq.kpi_slug === kpiSlug)?.requests;
  return flatten(requests.reduce((acc, request) => {
    return [
      ...acc,
      ...request.periods.map(period => period.requests_params.map(({request_id}) => request_id))
    ]
  }, []));
};

export const getDataRequestByKpi = (kpiSlug, dataRequests) => {
  const kpiDataRequests = dataRequests.find(dreq => dreq.kpi_slug === kpiSlug);
  return flatten(kpiDataRequests.requests.reduce((acc, request) => {
    return [
      ...acc,
      ...request.periods.map(({ period, requests_params }) => requests_params.map(({request_id, params, status}) => ({
        id: request_id,
        email: params.request.email,
        status,
        period,
        kpi_slug: kpiSlug,
        kpi_source: kpiDataRequests.source,
        type: request.type,
        groupedRecurrentRequests: request.periods.reduce((acc, period) => [...acc, ...period.requests_params.map(({request_id}) => request_id)], []),
      })))
    ]
  }, []));
};

export const canUseResponseDataByKey = (
  key,
  status,
  dataInUse = {},
) => {
  return status === DATA_REQUEST_STATUS.done || (status === DATA_REQUEST_STATUS.partially_in_use && !dataInUse[key]);
};

export const getPeriodsAndKpisOfDataRequests = (dataRequests = []) => {
  const kpisPeriods = dataRequests.reduce((acc, {kpi_slug, period}) => {
    acc[kpi_slug] = acc[kpi_slug] || [];
    acc[kpi_slug] = uniq([period, ...acc[kpi_slug]]);
    return acc;
  }, {});

  return {
    totalKpis: Object.keys(kpisPeriods).length,
    totalPeriods: flatten(Object.values(kpisPeriods)).length,
  }
};

export const getDataRequestQueryFilters = (filterValues = {}) => {
  const queryFiltersNames = getQueryFiltersNames(SCREENS.admin_data_requests);
  return Object.entries(queryFiltersNames).reduce((obj, [filterName, queryFilterName]) => {
    // NOTICE: Now the request_action_filter only takes into account whether the data request has been used (or partially used), so the status filter will be used for the moment.
    if(filterValues[filterName] && filterName !== 'request_action_filter' && filterName !== 'request_comment_attachment_filter' && DEFAULT_FILTER_SET[filterName].condition(filterValues[filterName])) {
      obj[queryFilterName] = filterValues[filterName];
    } else if (filterValues[filterName] && filterName === 'request_comment_attachment_filter') {
      (filterValues[filterName] || []).forEach(key => {
        obj[key] = true;
      });
    }
    return obj;
  }, {});
};

export const getKpisWithNotExistingPeriodicitiesAndNotApplyNodes = (
  reporting_structure,
  organizationsData,
  selectedKpisSlugs,
  selectedNodes,
  selectedPeriodicities
) => {
  return uniq((reporting_structure || []).reduce((acc, { kpis }) => {
    const selectedKpis = kpis.filter(kpi => selectedKpisSlugs.includes(kpi.slug) && !(acc.map(({slug}) => slug)).includes(kpi.slug));

    const kpisWithInfo = selectedKpis.map((kpi) => {
      const notApplyNodesSlugs = selectedNodes.filter(org => !kpi.orgs?.includes(org));
      const notApplyNodes = organizationsData.filter(({slug}) => notApplyNodesSlugs.includes(slug));
      // NOTICE: Check periodicities by organization
      const nodesWithNotExistingPeriodicities = organizationsData.filter(({slug}) => {
        return kpi.periodicities_by_organizations.some(({ organization_slug, periodicities }) => {
          return slug === organization_slug
            && selectedNodes.includes(organization_slug)
            && !notApplyNodesSlugs.includes(organization_slug)
            && selectedPeriodicities.some(p => !periodicities?.includes(p));
        });
      });
      return {
        ...kpi,
        nodesWithNotExistingPeriodicities,
        notApplyNodes,
      }
    });

    return [
      ...acc,
      ...flatten(kpisWithInfo),
    ];
  }, []));
};

export const getUnionExistingPeriodicities = (
  selectedKpis,
  selectedNodes,
) => {
  const kpisWithSomeApplyNode = (selectedKpis || []).filter(({orgs}) => selectedNodes.some(node => orgs?.includes(node)));

  return uniq(kpisWithSomeApplyNode.reduce((acc, { periodicities_by_organizations }) => {
      const periodicitiesInApplyNodes = periodicities_by_organizations
          .filter(({ organization_slug }) => selectedNodes.includes(organization_slug))
          .map(({ periodicities }) => periodicities);
      return [...acc, ...flatten(periodicitiesInApplyNodes)];
  }, []));
};
