import React, {
  useCallback,
  useMemo,
} from 'react';

import { injectIntl } from 'react-intl';

import { fullValidation as validation } from './validation';
import useForm from 'utils/useForm';

import {
  Row,
  Col,
  Form,
  InputNumber,
  Space,
} from 'antd';

import CustomSelect from 'components/CustomSelect';
import CustomInput from 'components/CustomInput';
import CustomModal from 'components/CustomModal';


const SalaryForm = ({
  intl,
  salary,
  positions,
  closeForm,
  handleSave,
  currency,
}) => {
  const t = intl.messages;
  const defaults = salary;
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    handleSave(values);
    resetForm();
    closeForm();
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  const handleOnCloseForm = useCallback(
    () => {
      resetForm();
      closeForm();
    },
    [resetForm, closeForm]
  );

  const genderOptions = useMemo(
    () => {
      return ['male', 'female', 'neutral'].map(slug => ({
        name: t[`equality_job_evaluation_salary_data_gender_option_${slug}`],
        slug,
      }));
    },
    [t]
  );

  const positionOptions = useMemo(
    () => {
      return positions
        .map(position => ({
          name: position.name,
          slug: position.id,
        }));
    },
    [positions]
  );

  return (
    <CustomModal
      title={getValue('id') ? t.equality_job_evaluation_salary_data_edit : t.equality_job_evaluation_salary_data_add_new}
      shown={true}
      onOk={handleSubmit}
      onCancel={handleOnCloseForm}
      okText={t.save}
      cancelText={t.cancel}
      width={800}
    >
      <Form
        className="form-input-wrapper"
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('employee_id') ? 'error' : '' }
              help={ showError('employee_id') }
              label={ t.equality_job_evaluation_salary_data_id }
              colon={false}
              required
            >
              <CustomInput
                placeholder={ t.equality_job_evaluation_salary_data_id }
                name="employee_id"
                value={ getValue('employee_id') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('gender') ? 'error' : '' }
              help={ showError('gender') }
              label={ t.equality_job_evaluation_salary_data_gender }
              colon={false}
              required
            >
              <CustomSelect
                title={t.equality_job_evaluation_salary_data_gender}
                options={genderOptions}
                onSelect={handleChange('gender')}
                selected={getValue('gender')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('position_id') ? 'error' : '' }
              help={ showError('position_id') }
              label={ t.equality_job_evaluation_salary_data_position }
              colon={false}
              required
            >
              <CustomSelect
                title={t.equality_job_evaluation_salary_data_position}
                options={positionOptions}
                onSelect={handleChange('position_id')}
                selected={getValue('position_id')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('salary') ? 'error' : '' }
              help={ showError('salary') }
              label={ t.equality_job_evaluation_salary_data_salary }
              colon={false}
              required
            >
              <Space>
                <InputNumber
                  value={getValue('salary')}
                  onChange={handleChange('salary')}
                />
                {currency?.symbol || ''}
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
}

export default injectIntl(SalaryForm);
