import React, {
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
} from 'antd';
import { ESG_TYPES } from 'utils/kpi';
import DateRangePicker from 'components/DateRangePicker';
import EsgLogo from 'components/EsgLogo'
import './style.less';
import SDGFilter from "../SDGFilter";

import CustomMultiSelect from 'components/CustomMultiSelect';

const NO_OP = () => {};

const DashboardFilters = ({
  intl,
  dateFilter = [],
  onChangeDateFilter = NO_OP,
  taxonomyFilter = {},
  onChangeTaxonomyFilter = NO_OP,
}) => {
  const t = intl.messages;

  const esgTypes = useMemo(() => {
    return ESG_TYPES.map(slug => ({
      slug,
      name: t[`esg_type_${slug}`],
    }));
  }, [
    t,
  ]);

  return (
    <section className={`DashboardFilters`}>
      <Row className={'DashboardFilters-filters'}>
        <Col className='DashboardFilters-dates'>
          <DateRangePicker
            value={dateFilter}
            onChange={onChangeDateFilter}
            dropdownClassName="datepicker-dropdown"
          />
        </Col>
        <Col className='DashboardFilters-filters'>
          <SDGFilter
            selected={taxonomyFilter.sdg}
            onSelect={onChangeTaxonomyFilter('sdg')}
            className="DashboardFilters__multiselect"
            wideMenu={true}
          />
          <CustomMultiSelect
            title={t.item_manager_type}
            options={esgTypes}
            selected={taxonomyFilter.type}
            onSelect={onChangeTaxonomyFilter('type')}
            renderOptionPrefix={slug => <EsgLogo key={slug} type={slug}/>}
            className="DashboardFilters__multiselect"
          />
        </Col>
      </Row>
    </section>
  );
}

export default injectIntl(DashboardFilters);
