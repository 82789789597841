import { loop, Cmd } from 'redux-loop';

import {
  getDataRequests,
  getDataRequestStatusProgress,
} from 'actions/api';
import { loadState } from 'store/persistence';
import { SCREENS } from 'hooks/useScreenFilters/constants';
import { getDataRequestQueryFilters } from 'utils/dataRequests';

const TARGET = 'data_request';
const DATA_REQUEST_PROGRESS_TARGET = 'data_request_progress';

const isTarget = (target) => [TARGET, DATA_REQUEST_PROGRESS_TARGET].includes(target);

const initialState = {
  data: [],
  status_progress: {},
  previousPage: null,
  currentPage: null,
  nextPage: null,
  total: 0,
  loading: false,
  error: null,
};

const getDataRequestsFilters = async (organization_slug, suborganization_slug, firstPage) => {
  const storage = await loadState();
  const filters = getDataRequestQueryFilters(storage?.screen_filters[SCREENS.admin_data_requests])
  return firstPage ? {
    organization_slug,
    suborganization_slug,
    page: firstPage,
    filters
  } : {
    organization_slug,
    suborganization_slug,
    filters
  }
};

const getInternalRequests = (data, state) => {
  const {
    organization_slug,
    suborganization_slug,
  } = data;
  if(organization_slug && suborganization_slug) {
    const firstPage = 1;
    return loop(
      {...state, loading: true, error: null},
      Cmd.list([
        Cmd.run(getDataRequestsFilters, {
          successActionCreator: getDataRequests,
          args: [ organization_slug, suborganization_slug, firstPage ],
        }),
        Cmd.run(getDataRequestsFilters, {
          successActionCreator: getDataRequestStatusProgress,
          args: [ organization_slug, suborganization_slug ],
        })
      ])
    );

    
  }
  return {
    ...state,
    loading: false,
    error: null,
  }
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'SEND_REMINDER:COMPLETED':
      return getInternalRequests(action.data, state)
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;

      switch(action.response.method) {
        case 'GET':
          if(action.response.target === DATA_REQUEST_PROGRESS_TARGET) {
            return {
              ...state,
              status_progress: action.response.result || {},
              loading: false,
              error: null,
            };
          }
          return {
            ...state,
            data: action.response.result?.currentPage === 1
              ? action.response.result?.data || []
              : state.data.concat(action.response.result?.data || []),
            previousPage: action.response.result?.previousPage,
            currentPage: action.response.result?.currentPage,
            nextPage: action.response.result?.nextPage,
            total: action.response.result?.total,
            loading: false,
            error: null,
          };
        case 'DELETE':
        case 'PUT':
          return getInternalRequests(action.response, state)
        default:
          return {
            ...state,
            loading: false,
            error: null,
          };
      }

    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        ...initialState,
        error: action.code,
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as data_request,
};
