import React, {
    useState,
    useMemo,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';

import {
    Tooltip,
} from 'antd';
import {
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  CheckOutlined
} from '@ant-design/icons';

import CustomButton from 'components/CustomButton';

import CustomModalType from 'components/CustomModalType';

import './style.less';

const NO_OP = () => {};

const ProgressBar = ({current, totalSteps}) => {
    const width = useMemo(() => {
        return `${(100/totalSteps) * current}%`
    }, [current, totalSteps]);

    return <div className="CustomStepsWizard__progressbar" style={{width}}></div>
};

const Step = ({step_number, title, current}) => {
    return (
        <div className={`CustomStepsWizard__step ${step_number <= current ? '' : 'is-disabled'}`}>
            <div className="CustomStepsWizard__stepNumber">{step_number}</div>
            <div className="CustomStepsWizard__stepTitle">{title}</div>
        </div>
    )
};

const Steps = ({steps, current}) => {
    return (
        steps.map((item, index) => (
            <Step key={index} step_number={index + 1} title={item.title} current={current + 1} />
        ))
    );
};

const FinishOrNextButtons = ({
    intl,
    steps,
    current,
    onClickFinish,
    finishButtonTitle,
    onClickNext,
}) => {
    return (
        <>
            { current === steps.length - 1 ?
                <CustomButton
                    className={`CustomStepsWizard__saveButton ${steps[current].isValid() ? '' : 'is-disabled'}`}
                    type="primary" 
                    onClick={onClickFinish}
                >
                    {finishButtonTitle || intl.formatMessage({id: 'custom_steps_save_button'})} <CheckOutlined />
                </CustomButton> :
                <button
                    className={`CustomStepsWizard__nextButton ${steps[current].isValid() ? '' : 'is-disabled'}`}
                    onClick={onClickNext}
                >
                    {intl.formatMessage({id: 'custom_steps_wizard_next'})} <RightOutlined/>
                </button>
            }
        </>
    );
};

const CustomStepsWizard = ({
    steps,
    title,
    finishButtonTitle,
    intl,
    onCancel = NO_OP,
    onFinish = NO_OP,
    onClickNextStep = NO_OP,
    onClickPreviousStep = NO_OP,
}) => {
    const [current, setCurrent] = useState(0);
    const [showWarningModal, setShowWarningModal] = useState(false);

    const switchStep = useCallback((n) => {
        setCurrent(current + n);
    }, [
        current,
    ]);

    const onClickNext = useCallback(() => {
        onClickNextStep && onClickNextStep(current);
        if (steps[current].isValid()) {
            if (steps[current].hasWarningModal) {
                setShowWarningModal(true);
            } else {
                switchStep(1);
            }
        }
    }, [
        current,
        onClickNextStep,
        steps,
        switchStep,
    ]);

    const onClickPrev = useCallback(() => {
        onClickPreviousStep && onClickPreviousStep(current);
        switchStep(-1);
    }, [
        current,
        onClickPreviousStep,
        switchStep,
    ]);

    const onClickFinish = useCallback(() => {
        if (steps[current].isValid()) {
            if (steps[current].hasWarningModal) {
                setShowWarningModal(true);
            } else {
                onFinish && onFinish(current);
            }
        }
    }, [
        current,
        onFinish,
        steps,
    ]);

    const onCancelWarningModal = useCallback(() => {
        setShowWarningModal(false);
        steps[current].warningModal.stepWhenCancel && setCurrent(steps[current].warningModal.stepWhenCancel);
        steps[current].warningModal.onCancel && steps[current].warningModal.onCancel();
    }, [
        current,
        steps,
    ]);

    const onSubmitWarningModal = useCallback(() => {
        setShowWarningModal(false);
        steps[current].warningModal.stepWhenOk && setCurrent(steps[current].warningModal.stepWhenOk);
        steps[current].warningModal.onOk && steps[current].warningModal.onOk();
    }, [
        current,
        steps,
    ]);

    return (
        <div className="CustomStepsWizard">
            <div className="CustomStepsWizard__header">
                <div className="CustomStepsWizard__leftSide">
                    {onCancel ? (<div className="CustomStepsWizard__cancelIcon" onClick={onCancel}><CloseOutlined /></div>) : null}
                    <div className="CustomStepsWizard__title">{title}</div>
                </div>
                <div className="CustomStepsWizard__rightSide">
                    <button
                        className="CustomStepsWizard__previousButton"
                        disabled={current === 0}
                        onClick={() => onClickPrev()}
                    >
                        <LeftOutlined/> {intl.formatMessage({id: 'custom_steps_wizard_previous'})}
                    </button>
                    {!steps[current].isValid() && steps[current].warningTooltipMessage ? (
                        <Tooltip
                            overlayClassName="CustomStepsWizard__tooltip"
                            placement="bottomRight"
                            trigger="click"
                            title={steps[current].warningTooltipMessage}
                            arrowPointAtCenter
                        >
                            <div className="CustomStepsWizard__finishOrNextButtons">
                                <FinishOrNextButtons
                                    intl={intl}
                                    steps={steps}
                                    current={current}
                                    onClickFinish={onClickFinish}
                                    onClickNext={onClickNext}
                                    finishButtonTitle={finishButtonTitle}
                                />
                            </div>
                        </Tooltip>
                    ) : (
                        <FinishOrNextButtons
                            intl={intl}
                            steps={steps}
                            current={current}
                            onClickFinish={onClickFinish}
                            onClickNext={onClickNext}
                            finishButtonTitle={finishButtonTitle}
                        />
                    )}
                </div>
            </div>
            <ProgressBar {...{current: current + 1, totalSteps: steps.length}} />
            <div className="CustomStepsWizard__steps"><Steps steps={steps} current={current} /></div>
            <div className="CustomStepsWizard__content" role="main" >
                {steps[current].content}
            </div>
            {steps[current].hasWarningModal ? (
                <CustomModalType
                    showModal={showWarningModal}
                    type='warning'
                    icon='Alert'
                    iconType='fas'
                    onOk={() => onSubmitWarningModal()}
                    onCancel={() => onCancelWarningModal()}
                    onOkText={steps[current].warningModal.onOkText}
                    onCancelText={steps[current].warningModal.onCancelText}
                    title={steps[current].warningModal.title}
                    content={steps[current].warningModal.content}
                />
            ) : null}
        </div>
    )
};

CustomStepsWizard.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.element.isRequired,
        isValid: PropTypes.func.isRequired,
        warningTooltipMessage: PropTypes.string,
        hasWarningModal: PropTypes.bool,
        warningModal: PropTypes.shape({
            onOkText: PropTypes.string.isRequired,
            onCancelText: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            stepWhenOk: PropTypes.number,
            stepWhenCancel: PropTypes.number,
            content: PropTypes.element.isRequired,
            onCancel: PropTypes.func,
            onOk: PropTypes.func,
        }),
    })).isRequired,
    title: PropTypes.string.isRequired,
    finishButtonTitle: PropTypes.string,
    onCancel: PropTypes.func,
    onFinish: PropTypes.func,
    onClickNextStep: PropTypes.func,
    onClickPreviousStep: PropTypes.func,
};

export default CustomStepsWizard;
