import React, { useMemo, useCallback, useEffect } from 'react'
import { withRouter } from 'react-router';
import { Tree } from 'antd';

import { useSelector } from 'react-redux';

import { useFeatureList } from 'components/FeatureSwitch';

import useOrganizations from 'utils/useOrganizations';
import { useEventTracking } from 'hooks/useEventTracking';
import { injectIntl } from 'react-intl';

const DEFAULT_TAB = 'fill'
const EVENT_TRACKING_TABS = {
  'manage': 'configure',
  'fill': 'fill_report',
  'upload': 'data_files',
  'requests': 'requests',
  'files': 'attachments',
  'history': 'history',
  'bulk_manage': 'bulk manage',
}

const DataManagementMenu = ({
  intl,
  match,
  history,
}) => {
  const t = intl.messages;
  const {
    suborganization,
    permissions
  } = useOrganizations();
  const eventTracking = useEventTracking();

  const { new_atlas_enabled = {} } = suborganization?.product_config?.atlas || {};
  const {
    data_sources = false,
  } = new_atlas_enabled;

  const {
    requests = [],
  } = useSelector(state => state.taxonomies);

  const {
    features: featureList,
  } = useFeatureList();

  const isRestrictedAdmin = useMemo(() => (
    featureList.has('restricted_admins') && !suborganization?.permissions?.can_affect_all_kpis
  ), [
    featureList,
    suborganization,
  ]);

  const activeTab = match.url || DEFAULT_TAB;

  const canExportKpis = useMemo(() => (
    (!featureList.has('restricted_admins') || suborganization?.permissions?.can_affect_all_kpis) && suborganization?.permissions?.can_export_kpis
  ), [
    featureList,
    suborganization,
  ]);

  const {
    data: userProfile,
  } = useSelector(state => state.profile);

  const restrictions = {
    upload: !((!isRestrictedAdmin && (permissions?.can_import_kpis || permissions?.can_export_kpis || false))),
    requests: !(permissions?.can_request_kpi || (permissions?.can_write_kpi && requests.length > 0) || false),
    sources: !(data_sources || false),
    files: isRestrictedAdmin,
    history: isRestrictedAdmin,
    downloads: !canExportKpis,
    bulk_manage: !featureList.has('bulk_manage_kpis') && (userProfile?.role !== 'system'),
  }

  const treeData = ([
    { title: t['datamanagement_manage'], key: '/data/manage', disabled: restrictions.manage },
    { title: t['datamanagement_sources'], key: '/data/sources', disabled: restrictions.sources },
    {
      title: t['datamanagement_fill'],
      key: '/data/fill',
      disabled: restrictions.fill,
      children: [
        { title: <span style={{fontWeight: 400, marginLeft: '-8px'}}>{t['datamanagement_fill_aplanet']}</span>, key: '/data/fill/aplanet', disabled: restrictions.fill },
        { title: <span style={{fontWeight: 400, marginLeft: '-8px'}}>{t['datamanagement_fill_global']}</span>, key: '/data/fill/global', disabled: restrictions.bulk_manage }
      ],
    },
    { title: t['datamanagement_upload'], key: '/data/upload', disabled: restrictions.upload },
    { title: t['datamanagement_requests'], key: '/data/requests', disabled: restrictions.requests },
    {
      title: t['datamanagement_files'], key: '/data/files', disabled: restrictions.files, children: [
        { title: <span style={{fontWeight: 400, marginLeft: '-8px'}}>{t['datamanagement_files_files']}</span>, key: '/data/files/files', disabled: restrictions.files },
        { title: <span style={{fontWeight: 400, marginLeft: '-8px'}}>{t['datamanagement_files_downloads']}</span>, key: '/data/files/downloads', disabled: restrictions.downloads }
      ]
    },
    { title: t['datamanagement_history'], key: '/data/history', disabled: restrictions.history, isLeaf: false },
  ])

  const tabEventTracking = useCallback((tab) => {
    eventTracking.capture('dataManagement.tabAccess', {
      tab_name: Object.keys(EVENT_TRACKING_TABS).includes(tab) ? EVENT_TRACKING_TABS[tab] : tab
    });
  }, [eventTracking])

  useEffect(() => {
    tabEventTracking(DEFAULT_TAB);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setActiveTab = useCallback((url) => {
    history.push(url);
  }, [
    history,
  ]);

  const handleTabChange = useCallback(([tab]) => {
    setActiveTab(tab);
    tabEventTracking(tab.split('/').pop());
  }, [
    setActiveTab,
    tabEventTracking
  ]);

  return (
    <Tree defaultExpandAll treeData={treeData} onSelect={handleTabChange} defaultSelectedKeys={[activeTab]} />
  )
}

export default injectIntl(withRouter(DataManagementMenu))
