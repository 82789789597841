import React, { Fragment, useEffect, useCallback, useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';

import useOrganizations from 'utils/useOrganizations';

import MainLayout from 'components/MainLayout';
import StartEqualityPlanForm from 'components/StartEqualityPlanForm';
import { Loading } from 'tsComponents/emptyStates/Loading';
import CustomTable from 'components/CustomTable';
import CustomButton from 'components/CustomButton';
import { formatDate, DATE_ONLY } from 'utils/date';

import {
  Row,
  Col,
  Modal,
} from 'antd';
import {
  DeleteOutlined,
} from '@ant-design/icons';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';

import {
  requestEqualityPlans,
  createEqualityPlan,
  updateEqualityPlan,
  deleteEqualityPlan,
} from '../../actions/api';

import './style.less';
import { fromDb } from 'utils/date';

const EqualityPlans = ({
  intl,
  history,
  fetchingPlans,
  plans,
  requestEqualityPlans,
  createEqualityPlan,
  updateEqualityPlan,
  deleteEqualityPlan,
}) => {
  const t = intl.messages;
  const { organization, suborganization } = useOrganizations();
  const [showNewPlanModal, setShowNewPlanModal] = useState(false);
  const [showClosePlanModal, setShowClosePlanModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showDeletePlanModal, setShowDeletePlanModal] = useState(false);

  const {
    slug: mainOrgSlug,
  } = organization;
  const {
    slug: subOrgSlug,
  } = suborganization;

  useEffect(() => {
    requestEqualityPlans(mainOrgSlug, subOrgSlug);
  }, [
    mainOrgSlug, subOrgSlug, requestEqualityPlans
  ]);

  const startNewPlan = useCallback(
    (values) => {
      createEqualityPlan(mainOrgSlug, subOrgSlug, values);
    },
    [mainOrgSlug, subOrgSlug, createEqualityPlan]
  );

  const closePlan = useCallback(
    () => {
      updateEqualityPlan(mainOrgSlug, subOrgSlug, selectedPlan, {status: 'closed'});
      setShowClosePlanModal(false);
    },
    [updateEqualityPlan, mainOrgSlug, subOrgSlug, selectedPlan]
  );

  const deletePlan = useCallback(
    () => {
      deleteEqualityPlan(mainOrgSlug, subOrgSlug, selectedPlan);
      setShowDeletePlanModal(false);
    },
    [deleteEqualityPlan, mainOrgSlug, subOrgSlug, selectedPlan]
  );

  const sortedPlans = useMemo(
    () => {
      const sortedOpenPlans = plans.filter(
        plan => plan.status === 'in_progress'
      ).sort(
        (a, b) => (fromDb(a.start_date) < fromDb(b.start_date)) ? -1 : 1
      );
      const sortedClosedPlans = plans.filter(
        plan => plan.status === 'closed'
      ).sort(
        (a, b) => (fromDb(a.close_date) < fromDb(b.close_date)) ? -1 : 1
      );
      return [
        ...sortedOpenPlans,
        ...sortedClosedPlans,
      ]
    },
    [plans]
  );

  const renderDate = useCallback(
    (value) => value ? formatDate(value, DATE_ONLY, intl) : '',
    [intl]
  );

  const columns = useMemo(() => {
    return [{
      title: t.name,
      dataIndex: 'name',
      key: 'name',
    }, {
      title: t.start_date,
      dataIndex: 'start_date',
      key: 'start_date',
      render: renderDate,
    }, {
      title: t.end_date,
      dataIndex: 'end_date',
      key: 'end_date',
      render: renderDate,
    }, {
      title: t.status,
      dataIndex: 'status',
      key: 'status',
      render: (value, plan) => {
        return (
          <span>{`${t[value]}${(value === 'closed' && plan.close_date) ? ` (${formatDate(plan.close_date, DATE_ONLY, intl)})` : ''}`} </span>
        );
      }
    }, {
      title: t.action,
      dataIndex: 'status',
      key: 'action',
      width: 150,
      render: (value, plan) => {
        return (
          <div className="EqualityPlan-actions">
            <CustomButton
              style={{display: value === 'closed' ? 'none' : 'initial'}}
              className="EqualityPlan-actions-close"
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedPlan(plan.id);
                setShowClosePlanModal(true);
              }}
            >{t.close}
            </CustomButton>
            <CustomButton
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedPlan(plan.id);
                setShowDeletePlanModal(true);
              }}
            />
          </div>
        )
      }
    }];
  }, [
    t,
    intl,
    renderDate,
  ]);

  return (
    <div className="EqualityPlan">
      <MainLayout>
        <Sentry.ErrorBoundary
          fallback={
          <ErrorBoundaryFallback
            titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
            buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
            descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_equality_plan_message' })}
            customErrorImage="/images/error_image.png"
          />
        }>
          <span className="EqualityPlan-title">{ t.plan_of_equality }</span>
          <h3>{t.history_of_equality_plans}</h3>
          { fetchingPlans 
            ? <Loading />
            : (
            <Fragment>
              <CustomTable
                columns={columns}
                dataSource={sortedPlans}
                pagination={true}
                rowKey='id'
                onRow={plan => ({
                  onClick: () => history.push(`/equality-plan/${plan.id}`),
                  style: { cursor: 'pointer' }
                })}
              />
              <Row className='EqualityPlan-start-new-plan'>
                <Col span={4}>
                  <CustomButton
                    type="primary"
                    onClick={() => setShowNewPlanModal(true)}
                  >
                    {t.start_new_plan}
                  </CustomButton>
                </Col>
              </Row>
            </Fragment>
          )}
        </Sentry.ErrorBoundary>
      </MainLayout>

      <StartEqualityPlanForm
        visible={showNewPlanModal}
        setVisibility={setShowNewPlanModal}
        onStartNewPlan={(values) => startNewPlan(values)}
      />

      <Modal
        visible={showClosePlanModal}
        onOk={closePlan}
        onCancel={() => setShowClosePlanModal(false)}
        okText={t.close_plan}
        cancelText={t.no_leave_without_changes}
      >
        <h3>{t.close_plan_title}</h3>
      </Modal>
      <Modal
        visible={showDeletePlanModal}
        onOk={deletePlan}
        onCancel={() => setShowDeletePlanModal(false)}
        okText={t.equality_plan_delete}
        cancelText={t.no_leave_without_changes}
      >
        <h3>{t.equality_plan_delete_title}</h3>
      </Modal>
    </div>
  )
};

const mapStateToProps = ({
  equality_plan
}) => ({
  fetchingPlans: equality_plan.fetching,
  plans: equality_plan.data,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      requestEqualityPlans,
      createEqualityPlan,
      updateEqualityPlan,
      deleteEqualityPlan,
    }
  )(EqualityPlans)
);
