import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import { compact, flattenDeep } from 'lodash';

import {
  Tree
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import CustomInputSearch from 'components/CustomInputSearch';
import Avatar from 'components/Avatar';

import { findTree, flattenTree, searchTreeWithFullPath } from 'utils/tree';

import { Empty } from 'tsComponents/emptyStates/Empty';
import { getEmptyResultsProps } from 'tsComponents/emptyStates/emptyProps';

import useOrganizations from 'utils/useOrganizations';

import './style.less';

const MAX_NODES_SELECTED = 5;

const searchTree = (tree, val) => {
  if (!val) {
    return [tree];
  }
  return searchTreeWithFullPath(
    [tree], (node) => node.name.toLowerCase().includes(val.toLowerCase())
  );
};

const treeData = (
  nodes,
  onClick,
  searchText,
  hideLogo,
  parent = {},
  keyProperty = 'id',
  currentSelection = [],
  hasLimitedSelection = false
) => {
  return flattenDeep(nodes.map(_node => {
    if (!_node) {
      return null;
    }

    const node = { ..._node, parent };

    const {
      name,
      children = [],
    } = node;
    const hideChildrenLogo = hideLogo || node.logo_small === node.parent.logo_small;
    const formattedChildren = compact(treeData(children, onClick, searchText, hideChildrenLogo, node, keyProperty, currentSelection, hasLimitedSelection) || []);

    if (formattedChildren.length === 0 && !node.permissions) return [];

    if (formattedChildren.length > 0 && !node.permissions) return formattedChildren;

    return {
      title: <Highlighter
        searchWords={[searchText]}
        textToHighlight={name}
        autoEscape
      />,
      key: node[keyProperty],
      disabled: node.disabledSelection || !node.permissions || !node.permissions.can_read_kpi || (hasLimitedSelection && currentSelection.length >= MAX_NODES_SELECTED && !currentSelection.includes(node[keyProperty])),
      children: formattedChildren,
      icon: !hideLogo &&
        <Avatar
          shape="square"
          src={node.logo_small || undefined}
          name={node.name || undefined}
          icon={<InfoCircleOutlined />}
          className='OrganizationTreeSelector__avatar'
        />
    }
  }));
};

const OrganizationTreeSelector = ({
  intl,
  tree,
  currentSelection = [],
  onClickKeyEsc,
  onSelect,
  isMultipleOrgSelection = false,
  showEmptySearchIcon = false,
  autoFocus = true,
  hasLimitedSelection = false,
  showCurrentOrganizationTree = false
}) => {
  const [searchText, setSearchText] = useState('');
  const [expandedKeys, setExpandedKeys] = useState(currentSelection);
  const {
    suborganization,
    organizationTree,
  } = useOrganizations();

  const currentOrganizationTree = useMemo(
    () => findTree([organizationTree], (node) => node.slug === suborganization?.slug),
    [organizationTree, suborganization.slug]
  );

  const filteredTree = useMemo(() => searchTree(showCurrentOrganizationTree ? currentOrganizationTree : tree, searchText),
    [currentOrganizationTree, searchText, showCurrentOrganizationTree, tree]
  );

  useEffect(
    () => {
      if (searchText && filteredTree[0]) {
        setExpandedKeys(
          Object.values(
            flattenTree(filteredTree[0])
          ).map(node => node.id)
        );
      }
    },
    [
      searchText,
      filteredTree,
    ]
  );

  const handleSelect = useCallback(([selectedKey]) => {
    onSelect(selectedKey);
  }, [onSelect]);

  const onCheck = useCallback((selection, e) => {
    onSelect(selection, { checked: e.checked, slug: e.node.key });
  }, [onSelect]);

  const onEscPress = useCallback((e) => {
    onClickKeyEsc && onClickKeyEsc(e);
  }, [onClickKeyEsc]);

  const hasSearch = Boolean(searchText)

  return (
    <div className="OrganizationTreeSelector">
      <CustomInputSearch
        value={searchText}
        placeholder={intl.formatMessage({ id: 'search' })}
        onChange={setSearchText}
        onEscPress={onEscPress}
        autoFocus={autoFocus}
        allowClear
      />
      {showEmptySearchIcon && searchText && filteredTree?.length === 0
        ? <Empty {...getEmptyResultsProps(hasSearch, false)} />
        : (
        <>
          {isMultipleOrgSelection ? (
            <Tree
              showIcon
              defaultExpandAll
              checkedKeys={currentSelection}
              onCheck={onCheck}
              treeData={treeData(filteredTree, handleSelect, searchText, false, {}, 'slug', currentSelection, hasLimitedSelection) || []}
              selectable={false}
              checkStrictly
              checkable
            />
          ) : (
            <Tree
              showIcon
              expandedKeys={expandedKeys}
              onExpand={setExpandedKeys}
              defaultSelectedKeys={currentSelection}
              onSelect={handleSelect}
              treeData={treeData(filteredTree, handleSelect, searchText) || []}
            />
          )}
        </>
      )}
    </div>
  );
}

OrganizationTreeSelector.propTypes = {
  autoFocus: PropTypes.bool,
  currentSelection: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  hasLimitedSelection: PropTypes.bool,
  isMultipleOrgSelection: PropTypes.bool,
  showEmptySearchIcon: PropTypes.bool,
  onClickKeyEsc: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  tree: PropTypes.object.isRequired,
};

export default injectIntl(OrganizationTreeSelector);
