import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import { fullValidation as validation } from './validation';
import useForm from 'utils/useForm';
import EqualityPlanHeader from 'components/EqualityPlan/Header';
import ActionsByAreaForm from './ActionsByAreaForm';
import CustomTable from 'components/CustomTable';
import CustomButton from 'components/CustomButton';
import BeneficiariesByAreaForm from './BeneficiariesByAreaForm';
import BeneficiariesByCategoryForm from './BeneficiariesByCategoryForm';
import { formatDate, toDb } from 'utils/date';

import {
  Row,
  Col,
  Button,
  Card,
  Form,
  Modal,
  Space,
  Radio,
  Input,
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';


const planStage = 'evaluation_of_results';

const EvaluationOfResultsForm = ({
  intl,
  history,
  plan,
  updatePlan,
}) => {
  const t = intl.messages;
  const defaults = useMemo(() => plan?.evaluation_of_results_data || {}, [plan]);
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    updatePlan({
      'evaluation_of_results_data': {
        ...values,
        updated_at: toDb(new Date()),
      },
      progress: [...new Set([...plan.progress, planStage])],
    });
    history.push(`/equality-plan/${plan.id}/`);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
    dirtyFields,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  const handleBack = useCallback(
    () => {
      if (dirtyFields.length > 0) {
        Modal.confirm({
          title: t.equality_plan_exit_without_save,
          content: t.equality_plan_exit_without_save_description,
          okText: t.equality_plan_exit_without_save_ok,
          cancelText: t.equality_plan_exit_without_save_cancel,
          onOk() {
            history.push(`/equality-plan/${plan.id}/`);
          },
        })
      } else {
        history.push(`/equality-plan/${plan.id}/`);
      }
    },
    [plan, history, dirtyFields, t]
  );

  const handleAddToList = useCallback(
    (subForm, name) => {
      const subFormValue = getValue(subForm) || {};
      let value = [...subFormValue[name] || []];
      const alreadyActiveItem = value.find(item => item.active);
      if (alreadyActiveItem) {
        if (alreadyActiveItem.new) {
          value.splice(
            value.findIndex(item => item.active), 1
          )
        } else {
          delete alreadyActiveItem.active;
        }
      }
      value.push({new: true, active: true});
      handleChange(subForm)({
        ...subFormValue,
        [ name ]: value,
      });
    },
    [handleChange, getValue]
  );

  const handleSaveToList = useCallback(
    (subForm, name, index, item) => {
      const subFormValue = getValue(subForm) || {};
      let value = [...subFormValue[name] || []];
      if (typeof index == 'number') {
        value[index] = item;
      } else {
        value.push(item);
      }
      handleChange(subForm)({
        ...subFormValue,
        [ name ]: value,
      });
    },
    [handleChange, getValue]
  );

  const makeListItemInactive = useCallback(
    (list, index) => {
      let item = list[index];
      if (item.new) {
        list.splice(index, 1);
      }
      else {
        delete item.active;
      }
      return list;
    },
    []
  );

  const handleCancelSaveToList = useCallback(
    (subForm, name, index) => {
      const subFormValue = getValue(subForm) || {};
      let value = makeListItemInactive(
        [...subFormValue[name] || []],
        index,
      );
      handleChange(subForm)({
        ...subFormValue,
        [name]: value,
      });
    },
    [getValue, handleChange, makeListItemInactive]
  );

  const handleEditListItem = useCallback(
    (subForm, name, index) => {
      const subFormValue = getValue(subForm) || {};
      let value = [...subFormValue[name] || []];
      const alreadyActiveItemIndex = value.findIndex(item => item.active);
      value[index].active = true;
      if (alreadyActiveItemIndex >= 0) {
        value = makeListItemInactive(value, alreadyActiveItemIndex);
      }
      handleChange(subForm)({
        ...subFormValue,
        [name]: value,
      });
    },
    [handleChange, getValue, makeListItemInactive]
  );

  const handleDeleteListItem = useCallback(
    (subForm, name, index) => {
      const subFormValue = getValue(subForm) || {};
      let value = [...subFormValue[name] || []];
      value.splice(index, 1);
      handleChange(subForm)({
        ...subFormValue,
        [name]: value,
      });
    },
    [handleChange, getValue]
  );

  // Actions by area
  const actionsByArea = useMemo(() => (getValue('results') || {}).actions_by_area || [], [getValue]);
  const activeActionsByArea = useMemo(
    () => {
      if (actionsByArea && actionsByArea.length) {
        return actionsByArea.find(record => record.active);
      }
    },
    [actionsByArea]
  );
  const activeActionsByAreaIndex = useMemo(
    () => {
      if (actionsByArea && actionsByArea.length) {
        return actionsByArea.findIndex(record => record.active);
      }
    },
    [actionsByArea]
  );
  const actionsByAreaColumns = useMemo(
    () => [
      {
        title: t.equality_evaluation_of_results_area,
        dataIndex: 'area',
        render: area => t[`equality_plan_area_${area}`]
      }, {
        title: t.equality_evaluation_of_results_actions_old,
        dataIndex: 'actions_old',
      }, {
        title: t.equality_evaluation_of_results_actions_new,
        dataIndex: 'actions_new',
      }, {
        title: t.action,
        key: 'action',
        render: (_, record, index) => (
          <Space size="middle">
            <CustomButton
              icon={<EditOutlined />}
              onClick={() => handleEditListItem('results', 'actions_by_area', index)}
            />
            <CustomButton
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteListItem('results', 'actions_by_area', index)}
            />
          </Space>
        ),
      },
    ],
    [t, handleEditListItem, handleDeleteListItem]
  );

  // Beneficiaries by area
  const beneficiariesByArea = useMemo(() => (getValue('results') || {}).beneficiaries_by_area || [], [getValue]);
  const activeBeneficiariesByArea = useMemo(
    () => {
      if (beneficiariesByArea && beneficiariesByArea.length) {
        return beneficiariesByArea.find(record => record.active);
      }
    },
    [beneficiariesByArea]
  );
  const activeBeneficiariesByAreaIndex = useMemo(
    () => {
      if (beneficiariesByArea && beneficiariesByArea.length) {
        return beneficiariesByArea.findIndex(record => record.active);
      }
    },
    [beneficiariesByArea]
  );
  const beneficiariesByAreaColumns = useMemo(
    () => [
      {
        title: t.equality_evaluation_of_results_area,
        dataIndex: 'area',
        render: area => t[`equality_plan_area_${area}`]
      }, {
        title: t.equality_evaluation_of_results_beneficiaries_female,
        dataIndex: 'female',
      }, {
        title: t.equality_evaluation_of_results_beneficiaries_male,
        dataIndex: 'male',
      }, {
        title: t.equality_evaluation_of_results_beneficiaries_total,
        dataIndex: 'total',
        render: (_, record) => record.male + record.female
      }, {
        title: t.action,
        key: 'action',
        render: (_, record, index) => (
          <Space size="middle">
            <CustomButton
              icon={<EditOutlined />}
              onClick={() => handleEditListItem('results', 'beneficiaries_by_area', index)}
            />
            <CustomButton
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteListItem('results', 'beneficiaries_by_area', index)}
            />
          </Space>
        ),
      },
    ],
    [t, handleEditListItem, handleDeleteListItem]
  );

  // Beneficiaries by category
  const beneficiariesByCategory = useMemo(() => (getValue('results') || {}).beneficiaries_by_category || [], [getValue]);
  const activeBeneficiariesByCategory = useMemo(
    () => {
      if (beneficiariesByCategory && beneficiariesByCategory.length) {
        return beneficiariesByCategory.find(record => record.active);
      }
    },
    [beneficiariesByCategory]
  );
  const activeBeneficiariesByCategoryIndex = useMemo(
    () => {
      if (beneficiariesByCategory && beneficiariesByCategory.length) {
        return beneficiariesByCategory.findIndex(record => record.active);
      }
    },
    [beneficiariesByCategory]
  );
  const beneficiariesByCategoryColumns = useMemo(
    () => [
      {
        title: t.equality_evaluation_of_results_category,
        dataIndex: 'category',
      }, {
        title: t.equality_evaluation_of_results_beneficiaries_female,
        dataIndex: 'female',
      }, {
        title: t.equality_evaluation_of_results_beneficiaries_male,
        dataIndex: 'male',
      }, {
        title: t.equality_evaluation_of_results_beneficiaries_total,
        dataIndex: 'total',
        render: (_, record) => record.male + record.female
      }, {
        title: t.action,
        key: 'action',
        render: (_, record, index) => (
          <Space size="middle">
            <CustomButton
              icon={<EditOutlined />}
              onClick={() => handleEditListItem('results', 'beneficiaries_by_category', index)}
            />
            <CustomButton
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteListItem('results', 'beneficiaries_by_category', index)}
            />
          </Space>
        ),
      },
    ],
    [t, handleEditListItem, handleDeleteListItem]
  );

  const gradeOptions = useMemo(
    () => [{
      label: t.equality_evaluation_of_results_grade_low,
      value: 'low',
    }, {
      label: t.equality_evaluation_of_results_grade_medium,
      value: 'medium',
    }, {
      label: t.equality_evaluation_of_results_grade_high,
      value: 'high',
    }],
    [t]
  );

  const handleChangeInSubForm = useCallback(
    (subForm, key, value) => {
      const subFormValue = getValue(subForm) || {};
      handleChange(subForm)({
        ...subFormValue,
        [key]: value,
      })
    },
    [handleChange, getValue]
  );

  const resultsValue = useMemo(
    () => getValue('results') || {},
    [getValue]
  );
  const processValue = useMemo(
    () => getValue('process') || {},
    [getValue]
  );
  const impactValue = useMemo(
    () => getValue('impact') || {},
    [getValue]
  );

  return (
    <div className="EvaluationOfResultsForm">
      <EqualityPlanHeader
        planId={plan.id}
        title={t[`equality_${planStage}`]}
        isDocumentManager={false}
        handleBackOrSkip={handleBack}
      />
      <Form onFinish={handleSubmit} layout="vertical">
        <Row type="flex" justify="end">
          <Col>
            <Button
              type="primary"
              htmlType="submit"
            >
              { t.save_and_continue }
            </Button>
          </Col>
        </Row>
        { plan?.evaluation_of_results_data?.updated_at &&
          <Row
            className="EvaluationOfResultsForm-updated-at"
            type="flex" justify="end"
          >
            <Col>
              <span>{t.equality_evaluation_of_results_updated_at}: {formatDate(plan.evaluation_of_results_data.updated_at, 'D MMM YYYY HH:mm', intl)}</span>
            </Col>
          </Row>
        }
        <Row>
          <Col span={24}>
            <h2>{t[`equality_${planStage}_section_results`]}</h2>
          </Col>
          <Col span={24}>
            <Row>
              <Col span={24} className="form-input-wrapper">
                <div className="form-input-card">
                  <h4>{t.equality_evaluation_of_results_actions_by_area}</h4>
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => handleAddToList('results', 'actions_by_area')}
                  ></Button>
                </div>
              </Col>
              { activeActionsByArea && (
                <Col span={24}>
                  <ActionsByAreaForm
                    record={activeActionsByArea}
                    index={activeActionsByAreaIndex}
                    onSave={handleSaveToList}
                    onCancel={handleCancelSaveToList}
                    visible={true} />
                </Col>
              )}
              {actionsByArea.length > 0 && (
                <Col span={24} className="form-input-wrapper">
                  <CustomTable
                    columns={actionsByAreaColumns}
                    dataSource={actionsByArea}
                    rowKey='area'
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col span={24} className="form-input-wrapper">
                <div className="form-input-card">
                  <h4>{t.equality_evaluation_of_results_beneficiaries_by_area}</h4>
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => handleAddToList('results', 'beneficiaries_by_area')}
                  ></Button>
                </div>
              </Col>
              { activeBeneficiariesByArea && (
                <Col span={24}>
                  <BeneficiariesByAreaForm
                    record={activeBeneficiariesByArea}
                    index={activeBeneficiariesByAreaIndex}
                    onSave={handleSaveToList}
                    onCancel={handleCancelSaveToList}
                    visible={true} />
                </Col>
              )}
              {beneficiariesByArea.length > 0 && (
                <Col span={24} className="form-input-wrapper">
                  <CustomTable
                    columns={beneficiariesByAreaColumns}
                    dataSource={beneficiariesByArea}
                    rowKey='area'
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col span={24} className="form-input-wrapper">
                <div className="form-input-card">
                  <h4>{t.equality_evaluation_of_results_beneficiaries_by_category}</h4>
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => handleAddToList('results', 'beneficiaries_by_category')}
                  ></Button>
                </div>
              </Col>
              { activeBeneficiariesByCategory && (
                <Col span={24}>
                  <BeneficiariesByCategoryForm
                    record={activeBeneficiariesByCategory}
                    index={activeBeneficiariesByCategoryIndex}
                    onSave={handleSaveToList}
                    onCancel={handleCancelSaveToList}
                    visible={true} />
                </Col>
              )}
              {beneficiariesByCategory.length > 0 && (
                <Col span={24} className="form-input-wrapper">
                  <CustomTable
                    columns={beneficiariesByCategoryColumns}
                    dataSource={beneficiariesByCategory || []}
                    rowKey='category'
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col span={24} className="form-input-wrapper">
                <Form.Item
                  label={t.equality_evaluation_of_results_objectives_degree_of_development}
                  hasFeedback
                  validateStatus={ showError('objectives_degree_of_development') ? 'error' : '' }
                  help={ showError('objectives_degree_of_development') }
                  colon={false}
                >
                  <Radio.Group
                    name='objectives_degree_of_development'
                    options={gradeOptions}
                    onChange={e => handleChangeInSubForm('results', 'objectives_degree_of_development', e.target.value)}
                    value={resultsValue.objectives_degree_of_development}
                    optionType="button"
                  />
                </Form.Item>
              </Col>
              <Col span={24} className="form-input-wrapper">
                <Form.Item
                  label={t.equality_evaluation_of_results_unanticipated_plan_effects}
                  hasFeedback
                  validateStatus={ showError('unanticipated_plan_effects') ? 'error' : '' }
                  help={ showError('unanticipated_plan_effects') }
                  colon={false}
                >
                  <Input.TextArea
                    name='unanticipated_plan_effects'
                    onChange={e => handleChangeInSubForm('results', 'unanticipated_plan_effects', e.target.value)}
                    value={resultsValue.unanticipated_plan_effects}
                  />
                </Form.Item>
              </Col>
              <Col span={24} className="form-input-wrapper">
                <Form.Item
                  label={t.equality_evaluation_of_results_others}
                  hasFeedback
                  validateStatus={ showError('others') ? 'error' : '' }
                  help={ showError('others') }
                  colon={false}
                >
                  <Input.TextArea
                    name='others'
                    onChange={e => handleChangeInSubForm('results', 'others', e.target.value)}
                    value={resultsValue.others}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <h2>{t[`equality_${planStage}_section_process`]}</h2>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Card
              className="form-input-card"
              title={t.equality_evaluation_of_results_systematization_of_procedures}
            >
              <Form.Item
                label={t.equality_evaluation_of_results_systematization_of_procedures_general}
                hasFeedback
                validateStatus={ showError('systematization_of_procedures_general') ? 'error' : '' }
                help={ showError('systematization_of_procedures_general') }
                colon={false}
              >
                <Radio.Group
                  name='systematization_of_procedures_general'
                  options={gradeOptions}
                  onChange={e => handleChangeInSubForm('process', 'systematization_of_procedures_general', e.target.value)}
                  value={processValue.systematization_of_procedures_general}
                  optionType="button"
                />
              </Form.Item>
            </Card>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Card
              className="form-input-card"
              title={t.equality_evaluation_of_results_information_dissemination_staff}
            >
              {['communication_briefings', 'personal_interviews', 'equality_training', 'general'].map(
                key => (
                  <Form.Item
                    key={key}
                    label={t[`equality_evaluation_of_results_information_dissemination_staff_${key}`]}
                    hasFeedback
                    validateStatus={ showError(`information_dissemination_staff_${key}`) ? 'error' : '' }
                    help={ showError(`information_dissemination_staff_${key}`) }
                    colon={false}
                  >
                    <Radio.Group
                      name={`information_dissemination_staff_${key}`}
                      options={gradeOptions}
                      onChange={e => handleChangeInSubForm('process', `information_dissemination_staff_${key}`, e.target.value)}
                      value={processValue[`information_dissemination_staff_${key}`]}
                      optionType="button"
                    />
                  </Form.Item>
                )
              )}
            </Card>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Card
              className="form-input-card"
              title={t.equality_evaluation_of_results_adequacy_hr}
            >
              {['study_characteristics', 'quantitative_qualitative_analysis', 'calendar_implementation', 'general'].map(
                key => (
                  <Form.Item
                    key={key}
                    label={t[`equality_evaluation_of_results_adequacy_hr_${key}`]}
                    hasFeedback
                    validateStatus={ showError(`adequacy_hr_${key}`) ? 'error' : '' }
                    help={ showError(`adequacy_hr_${key}`) }
                    colon={false}
                  >
                    <Radio.Group
                      name={`adequacy_hr_${key}`}
                      options={gradeOptions}
                      onChange={e => handleChangeInSubForm('process', `adequacy_hr_${key}`, e.target.value)}
                      value={processValue[`adequacy_hr_${key}`]}
                      optionType="button"
                    />
                  </Form.Item>
                )
              )}
            </Card>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Card
              className="form-input-card"
              title={t.equality_evaluation_of_results_adequacy_material_resources}
            >
              {['material_training', 'workplace_violence_videos', 'news_of_interest_group_dynamics', 'general'].map(
                key => (
                  <Form.Item
                    key={key}
                    label={t[`equality_evaluation_of_results_adequacy_material_resources_${key}`]}
                    hasFeedback
                    validateStatus={ showError(`adequacy_material_resources_${key}`) ? 'error' : '' }
                    help={ showError(`adequacy_material_resources_${key}`) }
                    colon={false}
                  >
                    <Radio.Group
                      name={`adequacy_material_resources_${key}`}
                      options={gradeOptions}
                      onChange={e => handleChangeInSubForm('process', `adequacy_material_resources_${key}`, e.target.value)}
                      value={processValue[`adequacy_material_resources_${key}`]}
                      optionType="button"
                    />
                  </Form.Item>
                )
              )}
            </Card>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Card
              className="form-input-card"
              title={t.equality_evaluation_of_results_adequacy_data_collection_tools}
            >
              <Form.Item
                label={t.equality_evaluation_of_results_adequacy_data_collection_tools_general}
                hasFeedback
                validateStatus={ showError('adequacy_data_collection_tools_general') ? 'error' : '' }
                help={ showError('adequacy_data_collection_tools_general') }
                colon={false}
              >
                <Radio.Group
                  name='adequacy_data_collection_tools_general'
                  options={gradeOptions}
                  onChange={e => handleChangeInSubForm('process', 'adequacy_data_collection_tools_general', e.target.value)}
                  value={processValue.adequacy_data_collection_tools_general}
                  optionType="button"
                />
              </Form.Item>
            </Card>
          </Col>
          <Col span={24} className="form-input-wrapper">
            {['periodic_monitoring_mechanism', 'incidents_and_difficulties', 'incidents_and_difficulties_solution', 'others'].map(
              key => (
                <Form.Item
                  key={key}
                  label={t[`equality_evaluation_of_results_process_text_${key}`]}
                  hasFeedback
                  validateStatus={ showError(key) ? 'error' : '' }
                  help={ showError(key) }
                  colon={false}
                >
                  <Input.TextArea
                    name={key}
                    onChange={e => handleChangeInSubForm('process', key, e.target.value)}
                    value={processValue[key]}
                  />
                </Form.Item>
              )
            )}
          </Col>
          <Col span={24}>
            <h2>{t[`equality_${planStage}_section_impact`]}</h2>
          </Col>
          <Col span={24} className="form-input-wrapper">
            {[
              'reduction_of_inequalities',
              'decrease_vertical_segregation',
              'decrease_horizontal_segregation',
              'reduction_of_imbalances',
              'changes_in_staff_manager_relationship',
              'changes_in_staff_assessment',
              'changes_in_company_culture',
              'changes_in_company_image',
              'changes_in_company_external_relations',
              'working_conditions_improvement',
              'increased_knowledge_and_awareness',
              'degree_of_overall_achievement',
              'others'
            ].map(
              key => (
                <Form.Item
                  key={key}
                  label={t[`equality_evaluation_of_results_impact_text_${key}`]}
                  hasFeedback
                  validateStatus={ showError(key) ? 'error' : '' }
                  help={ showError(key) }
                  colon={false}
                >
                  <Input.TextArea
                    name={key}
                    onChange={e => handleChangeInSubForm('impact', key, e.target.value)}
                    value={impactValue[key]}
                  />
                </Form.Item>
              )
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default withRouter(injectIntl(EvaluationOfResultsForm));
