import React from 'react';
import { injectIntl } from 'react-intl';

import CollapsableCard from 'components/CollapsableCard';
import KpiLabel from 'components/KpiLabel';
//import PeriodLabel from 'components/PeriodLabel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Dependencies = ({
  intl,
  kpis,
  urlBase = 'kpi', // NOTICE: For KpiLabel link
}) => {
  const t = intl.messages;

  return (
    <CollapsableCard
      title={
        t.kpidetail_dependencies_reverse
      }
      icon={
        <FontAwesomeIcon
          className={
            'KpiDetail__270deg icon-yellow'
          }
          icon={'sitemap'}
        />
      }
      className='KpiDetail__dependencies'
    >
      <div>
        {
          kpis.map(({
            name,
            slug,
            esg_type,
            period,
          }) => (
            <KpiLabel
              name={name}
              slug={slug}
              esg_type={esg_type}
              urlBase={urlBase}
              period={period}
            />
          ))
        }
      </div>
    </CollapsableCard>
  );
};

export default injectIntl(Dependencies);
