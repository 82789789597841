import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';

import EditableComment from 'components/EditableComment'
import CustomToggle from 'components/CustomToggle';
import AnswerFooter from 'components/AnswerFooter';
import { useFeatureList } from 'components/FeatureSwitch';
import DataTargets from 'components/DataTargets';

import {
  Divider,
  Checkbox,
  Modal,
  Row,
  Col,
} from 'antd';
import useOrganizations from 'utils/useOrganizations';

const DEFAULT_INITIAL_VALUE = { boolean: undefined };

const QUALITATIVE_SCHEMA_TYPE= 'qualitative';

const Component = injectIntl(({
  intl,
  schema, // TODO: Consider schema ???
  value,
  onChange,
}) => {
  const t = intl.messages;
  const { boolean } = value || {};
  const handleChange = useCallback((boolean) => {
    onChange({
      ...value,
      boolean
    });
  }, [
    value,
    onChange,
  ]);

  return (
    <CustomToggle
      options={[t.yes, t.no]}
      checked={boolean}
      onChange={handleChange}
    />
  );
});

const TableComponent = ({
  schema, // TODO: Consider schema ???
  value,
  onChange,
}) => {
  const { boolean, target_value = null } = value || {};
  const handleChange = useCallback((event) => {
    const boolean = event.target.checked;
    onChange({
      ...value,
      boolean
    });
  }, [
    value,
    onChange,
  ]);
  const {
    suborganizationFeatures: featureList,
  } = useFeatureList();

if( featureList && featureList.has('targets') && target_value ){
    return (
      <Row justify="space-between">
        <Col>
        <Checkbox
          indeterminate={typeof boolean !== 'boolean'}
          checked={typeof boolean === 'boolean' && boolean}
          onChange={handleChange}
        />
        </Col>
        <Col>
            <DataTargets.BooleanTableShow
              target={target_value}
            />
        </Col>
      </Row>
    );
}else{
    return (
    <Checkbox
      indeterminate={typeof boolean !== 'boolean'}
      checked={typeof boolean === 'boolean' && boolean}
      onChange={handleChange}
    />
  );
}

};

const Edit = ({
  intl,
  schema,
  value: initialValue,
  comment: initialComment,
  loading,
  previous_value,
  previous_comment,
  previous_attachments,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
  onShowLastPeriod,
  onCancel,
  onSave,
  onClear,
  target,
  onTarget,
  config,
}) => {
  const t = intl.messages;
  const [ value, setValue ] = useState(initialValue || DEFAULT_INITIAL_VALUE);
  const [ comment, setComment ] = useState(initialComment || '');
  const [ edittingComment, setEdittingComment ] = useState(false);
  const [ dirty, setDirty ] = useState(false);
  const [hasTarget, setHasTarget] = useState(false);

  const {
    permissions,
  } = useOrganizations();

  const {
    suborganizationFeatures: featureList,
  } = useFeatureList();

  const handleChange = useCallback((value) => {
    setDirty(true);
    setValue(value);
  }, []);

  const handleUseLastValue = useCallback(() => {
    onCancel();
    onUseLastValue();
  }, [
    onUseLastValue,
    onCancel,
  ]);

  const handleUseLastWholeData = useCallback(() => {
    onCancel();
    onUseLastWholeData();
  }, [
    onUseLastWholeData,
    onCancel,
  ]);

  const handleUseLastAttachments = useCallback(() => {
    onCancel();
    onUseLastAttachments();
  }, [
    onUseLastAttachments,
    onCancel,
  ]);

  const handleUseLastPeriod = useCallback(() => {
    onCancel();
    onUseLastPeriod();
  }, [
    onUseLastPeriod,
    onCancel,
  ]);

  const handleCommentChange = useCallback((comment) => {
    setDirty(true);
    setComment(comment);
  }, []);

  const isEmpty = useMemo(() => {
    return typeof (value || {}).boolean === 'undefined';
  }, [
    value,
  ]);

  // TODO: Boolean-only fields shoul perhaps auto-save!!!
  const handleCancel = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    onCancel();
  }, [
    dirty,
    onCancel,
  ]);

  const handleClear = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    Modal.confirm({
      title: t.kpi_detail_clear_title,
      content: t.kpi_detail_clear_content,
      okText: t.kpi_detail_clear_ok,
      okType: 'danger',
      cancelText: t.kpi_detail_clear_cancel,
      onOk() {
        onClear ? onClear() : onSave(null, '');
      },
      onCancel() {},
    });
  }, [
    t,
    dirty,
    onSave,
    onClear
  ]);

  const handleSave = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    onSave(
      value,
      comment,
    );
  }, [
    dirty,
    value,
    comment,
    onSave,
  ]);

  useEffect(()=>{
    if( featureList && featureList.has('targets') && target){
      setHasTarget(true);
    }
  }, [target, featureList]);


  const hasTargetPermissions = useMemo(() => {
    return featureList && featureList.has("targets")
      && permissions.can_configure_kpi
      && schema.type !== QUALITATIVE_SCHEMA_TYPE;
  }, [
    featureList,
    permissions.can_configure_kpi,
    schema.type
  ]);

  return (
    <React.Fragment>
      <section>
        <Row>
          <Col span={6}>
            <Component
              schema={schema}
              value={value}
              onChange={handleChange}
            />
          </Col>
          <Col span={6} offset={2}>
            <DataTargets 
              schema={schema}
              kpi_value={value}
              target={target}
              justify="start"
            />
          </Col>
        </Row>

      </section>
      <Divider/>
      <section>
        <EditableComment.Component
          title={t.privateComment}
          value={comment}
          onChange={handleCommentChange}
          editMode={edittingComment}
          setEditMode={setEdittingComment}
          editable
          titleClass='KpiDetail__title'
          config={config}
        />
      </section>
      <Divider/>
      <AnswerFooter
        editting
        canWrite
        hasLastPeriod={!!previous_value}
        hasLastComment={!!previous_comment}
        hasLastAttachments={!!previous_attachments?.length}
        onShowLastPeriod={onShowLastPeriod}
        onUseLastValue={handleUseLastValue}
        onUseLastWholeData={handleUseLastWholeData}
        onUseLastAttachments={handleUseLastAttachments}
        onUseLastPeriod={handleUseLastPeriod}
        hasInitialValue={!!initialValue}
        onCancelEdit={handleCancel}
        onClearEdit={handleClear}
        onSaveEdit={handleSave}
        dirty={dirty}
        empty={isEmpty}
        onTarget={onTarget}
        hasTarget={hasTarget}
        isTargetElegible={hasTargetPermissions}
      />
    </React.Fragment>
  );
};

Edit.Component = Component;
Edit.TableComponent = TableComponent;

export default injectIntl(Edit);

