import React, {
    useMemo,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import { Loading } from 'tsComponents/emptyStates/Loading';

import {
    requestReportingStructure,
} from 'actions/api';

import { flattenTree } from 'utils/tree';
import useOrganizations from 'utils/useOrganizations';
import DataRequestsWizard from './DataRequestsWizard';

import './style.less';

const DEFAULT_KPIS = [];

const DataRequestsWizardScreen = ({
    onCloseDataRequests,
    checkedKpisUuids = DEFAULT_KPIS,
}) => {
    const dispatch = useDispatch();

    const {
        organization,
        suborganization,
        organizationTree,
    } = useOrganizations();

    const {
        data: reporting_structure,
    } = useSelector(state => state.reporting_structure);

    useEffect(() => {
        if (
            !organization?.slug ||
            !suborganization?.slug
        ) return
        
        dispatch(
            requestReportingStructure({
                organization_slug: organization.slug,
                suborganization_slug: suborganization.slug
            })
        );

    }, [
        organization?.slug,
        suborganization?.slug,
        dispatch,
    ]);

    const organizationsData = useMemo(
        () => Object.values(flattenTree(organizationTree)),
        [organizationTree]
    );

    const checkedKpis = useMemo(() => {
        return (reporting_structure || []).reduce((acc, { kpis }) => {  
            const kpisSelected = kpis?.filter(({uuid}) => checkedKpisUuids.includes(uuid));
            return kpisSelected?.length ? [...acc, ...kpisSelected] : acc;
        }, []);
    }, [
        checkedKpisUuids,
        reporting_structure
    ]);

    if (!reporting_structure || !organization || !suborganization || !organizationsData) {
        return <Loading />;
    }

    return (
        <DataRequestsWizard
            onCloseDataRequests={onCloseDataRequests}
            kpis={checkedKpis}
            reporting_structure={reporting_structure}
            organization={organization}
            suborganization={suborganization}
            organizationsData={organizationsData}
        />
    );
};

DataRequestsWizardScreen.propTypes = {
    checkedKpisUuids: PropTypes.arrayOf(PropTypes.string.isRequired),
    onCloseDataRequests: PropTypes.func.isRequired,
};

export default DataRequestsWizardScreen;