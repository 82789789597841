import React, {
  useRef,
  useState,
  useCallback,
} from 'react'
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types'
import {
  DeleteOutlined,
  EditOutlined
} from '@ant-design/icons';
import { defaultColors } from '../useColors.js';

import { hasDownloadButton } from './types';

import AplanetIcon from 'components/AplanetIcon';
import CustomButton from 'components/CustomButton';
import CustomTag from 'components/CustomTag';
import CustomWarningModal from 'components/CustomWarningModal/index.js';
import ModalDashboardCardKpiSummary from 'containers/Dashboard/screens/ModalDashboardCardKpiSummary.js';
import { isEmpty, isEqual } from 'lodash';
import CardChart from './CardChart.js';
import CustomCardChart from './CustomCardChart.js';

const CardLoading = () => {
    return (
      <div className='DashboardComponent__card_content'>
        <AplanetIcon size='200px' faStyle='fas' name='chart-mixed' className='fa-fade' />
      </div>
    )
}

const DEFAULTS = {
  size: 'medium',
  parameters: {},
  data: {},
  icon: undefined,
  title: '',
  description: '',
  actions: [],
  extras: {},
  renderExtras: undefined,
  periods: [],
  updated_at: undefined,
  permissions: {},
  dashboardTheme: defaultColors,
  theme: { '@primary-color': '#000000' },
  showDataLabels: false,
  showDataTarget: false,
  nodeLevel: 1
};

const Card = ({
  intl,
  id,
  type,
  customChart,
  icon = DEFAULTS.icon,
  size = DEFAULTS.size,
  parameters = DEFAULTS.parameters,
  data = DEFAULTS.data,
  title = DEFAULTS.title,
  showDataLabels = DEFAULTS.showDataLabels,
  showDataTarget = DEFAULTS.showDataTarget,
  nodeLevel = DEFAULTS.nodeLevel,
  actions = DEFAULTS.actions,
  renderExtras = DEFAULTS.renderExtras,
  permissions = DEFAULTS.permissions,
  dashboardTheme = DEFAULTS.dashboardTheme,
  theme = DEFAULTS.theme,
  handleDeleteCard,
  handleEditCard,
  canDeleteCard = true,
  canEditCard = true,
  activeTab
}) => {

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showCardKpiSummaryModal, setShowCardKpiSummaryModal] = useState(false);
  const t = intl.messages;
  const showDownloadButton = hasDownloadButton(type) && data.samples && data.samples.length > 0;
  const contentRef = useRef(null);

  const {
    extras = DEFAULTS.extras,
    updated_at = DEFAULTS.updated_at,
  } = data || {};

  const showEditKpiButton = extras && extras.kpis && extras.kpis.length > 0;
  const cardKpis = showEditKpiButton ? extras.kpis : [];

  const handleDownload = useCallback(() => {
    const anchor = document.createElement('a');
    anchor.setAttribute(
      'href',
      contentRef.current.toBase64Image()
    );
    anchor.setAttribute('download', title);
    anchor.click();
  }, [ title ]);

  const onSubmitDeleteModal = useCallback(() => {
    handleDeleteCard(id);
    setShowWarningModal(false);
  }, [
    handleDeleteCard,
    id
  ]);

  if(customChart){

    if (isEmpty(data)){
      return (
        <CardLoading />
      );
    }

    return (
      <CustomCardChart 
        type={ type } 
        data={ data } 
        parameters={ parameters }
        showDataTarget={showDataTarget} 
        dashboardTheme={dashboardTheme}
      />
    )
  }

  return (
    <>
      <div
        className={`DashboardComponent__card card_size_${size}`}
      >
        {
          !title ? null :
          <div className="DashboardComponent__card_title">
            <h3>
              { 
                icon && 
                <AplanetIcon
                  className='card_icon'
                  faStyle='fad'
                  name={icon}
                  style={{ color: theme['@primary-color'] }}
                /> 
              } 
              { title }
              {
                (cardKpis.length === 1) || !data.extras?.units || data.extras?.units.length === 0
                ? null
                : ` (${ data.extras?.units.join(', ')})`
              }
            </h3>
            {
              showDataTarget && 
              <AplanetIcon 
                titleClassName="DashboardComponent__card__icon_tooltip"
                name='Target' 
                style={{ color: theme['@primary-color'] }} 
                title={ t.dashboard_card_target_tooltip_message }
              />
            }
            {
              data.extras?.periodicity && 
              <div className="DashboardComponent__card_period">
                <CustomTag 
                    className="tag"
                    data-testid={`card_periodicity_${ data.extras.periodicity }`}
                    colorclass={ data.extras.periodicity }
                    name={ intl.formatMessage({ id: `select_periodicity_${data.extras.periodicity}` }) } 
                    selected={ false }
                />
              </div>
            }
            {
              canEditCard && 
              <CustomButton 
                className='DashboardComponent__card__btnEdit'
                tooltip={ t.dashboard_card_action_edit }
                icon={ <EditOutlined />} 
                size='small' 
                onClick={ () => handleEditCard(id) }
              />
            }
            {
              canDeleteCard && 
              <CustomButton 
                className='DashboardComponent__card__btnDelete'
                tooltip={ t.dashboard_card_action_delete }
                icon={ <DeleteOutlined />} 
                size='small' 
                onClick={ () => setShowWarningModal(true) }
              />
            }
          </div>
        }
        <div className="DashboardComponent__card_meta">
          {
            !updated_at
            ?
              null
            :
              <em>{ updated_at }</em>
          }
        </div>
        
        {
          !isEmpty(data) 
          ? (
            <CardChart 
              id={id}
              type={type}
              contentRef={contentRef}
              parameters={parameters}
              data={data}
              dashboardTheme={dashboardTheme}
              showDataLabels={showDataLabels}
              showDataTarget={showDataTarget}
              nodeLevel={nodeLevel}
            />
          )
          : (
            <CardLoading />
          )
        }
        
        {
          ((Object.keys(extras).length === 0 || Object.keys(extras).filter(key => extras[key].length > 0).length === 0 || !renderExtras) && actions.length === 0) ? null :
          <div
            className="DashboardComponent__card_footer"
          >
            { !renderExtras ? null : renderExtras(extras) }
            { actions }
            { 
              !showEditKpiButton ? null : 
              <CustomButton 
                onClick={ () => setShowCardKpiSummaryModal(true) }
                className="DashboardComponent__card_editkpi"
              >
                { intl.formatMessage({ id: `dashboard_see_kpi` }, { count: cardKpis.length }) }
              </CustomButton>
            }
            { !showDownloadButton ? null :
              <CustomButton
                onClick={handleDownload}
                className="DashboardComponent__card_download"
              >
                { t.dashboard_download }
              </CustomButton>
            }
          </div>
        }
      </div>
      <CustomWarningModal
        showModal={showWarningModal}
        title={intl.formatMessage({id: `dashboard_card_delete_warning_modal_title`})}
        onOk={() => onSubmitDeleteModal()}
        onCancel={() => setShowWarningModal(false) }
        onOkText={intl.formatMessage({id: `dashboard_card_delete_warning_modal_ok`})}
        onCancelText={intl.formatMessage({id: `dashboard_card_delete_warning_modal_cancel`})}
        icon='trash'
        iconType='fad'
        content={(
          <>{ t.dashboard_card_delete_warning_modal_content }</>
        )}
      />
      {
        showCardKpiSummaryModal && 
        <ModalDashboardCardKpiSummary 
            show={ true }
            onCancel={ () => setShowCardKpiSummaryModal(false) }
            kpis={ cardKpis.map( kpi => ({  
                slug: kpi.slug,
                name: kpi.name,
                link: `/dashboard/${activeTab}/${kpi.slug}/last/data`
            }))}
        />
      }
    </>
  );
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  showDataLabels: PropTypes.bool,
  showDataTarget: PropTypes.bool,
  parameters: PropTypes.object,
  data: PropTypes.object,
  title: PropTypes.node,
  description: PropTypes.string,
  dashboardTheme: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.element
  ),
  extras: PropTypes.object,
  renderExtras: PropTypes.func,
  onDownload: PropTypes.func,
  canDeleteCard: PropTypes.bool,
  canEditCard: PropTypes.bool,
  activeTab: PropTypes.string
};

function cardPropsAreEqual(prevCard, nextCard){
  return isEqual(prevCard.data, nextCard.data);
}

export default React.memo(injectIntl(Card), cardPropsAreEqual);
