import React, { useState, useCallback } from "react";
import { useSelector } from 'react-redux'
import { injectIntl } from "react-intl";

import { Loading } from 'tsComponents/emptyStates/Loading';
import Visor from "./Visor";
import Manager from "./Manager";
import Warning from "./Warning";
import { useKpiSearcherByTag } from "hooks/kpi";

const Tags = ({
  intl,
  loading,
  tags,
  selection,
  partialSelection,
  areThereChanges,
  onDeleteTag,
  onChange,
  onNewTag,
  onApply,
}) => {
  const [mode, setMode] = useState({ screen: "visor" });
  const searchKpis = useKpiSearcherByTag();
  const { data: categories } = useSelector((status) => status.data_categories);
  const showManager = useCallback(() => setMode({ screen: "manager" }), [
    setMode,
  ]);
  const showVisor = useCallback(() => setMode({ screen: "visor" }), [setMode]);
  const showWarning = useCallback(
    (tag) => {
      const affectedKpis = searchKpis(categories, tag);
      setMode({ screen: "warning", payload: { tag, affectedKpis } });
    },
    [setMode, searchKpis, categories]
  );

  if (loading || !tags) {
    return <Loading />;
  }

  if (mode.screen === "visor") {
    return (
      <Visor
        intl={intl}
        completeAndPartialSelection={[...selection, ...partialSelection]}
        partial={partialSelection}
        tags={tags}
        areThereChanges={areThereChanges}
        onNewTag={onNewTag}
        onChange={onChange}
        onApply={onApply}
        onGoToManager={showManager}
      />
    );
  } else if (mode.screen === "manager") {
    return (
      <Manager
        intl={intl}
        tags={tags}
        onNewTag={onNewTag}
        onDeleteTag={showWarning}
        onGoToVisor={showVisor}
      />
    );
  } else {
    return (
      <Warning
        intl={intl}
        tag={mode.payload.tag}
        kpis={mode.payload.affectedKpis.length}
        onConfirm={() => {onDeleteTag(mode.payload); showVisor();}}
        onCancel={showManager}
      />
    );
  }
};

export default injectIntl(Tags);
