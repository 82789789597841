export const fullValidation = (t) => ({
  name,
  type,
}) => {
  let errors = {};

  if (!name) {
    errors.name = t.form_error_required;
  }

  if (!type) {
    errors.type = t.form_error_required;
  }

  return errors;
};