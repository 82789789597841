import React from 'react';
  import { injectIntl } from 'react-intl';
  import { withRouter } from 'react-router';
  import Settings from './Settings';
  import SubOrg from './SubOrg';
  
  import {
    Row,
  } from 'antd';
  
  const DataNotApplies = ({
    kpi_slug,
    data = {},
    loading = false,
    permissions = {},
    period,
    handleOpenAnswer,
    onUseValue,
    onUseAttachment
  }) => {
  
    return (
      <React.Fragment>
        <Row className="KpiDetail__first-row">
            <Settings
                loading={loading}
                kpi_slug={kpi_slug}
                permissions={permissions}
                {...data}
            />
        </Row>
        <SubOrg
            schema={data.schema}
            loading={loading}
            kpi_slug={kpi_slug}
            period={period}
            cards={data.suborganizations}
            onOpenAnswer={handleOpenAnswer}
            onUseValue={onUseValue}
            onUseAttachment={onUseAttachment}
            permissions={permissions}
            canAggregate={false}
            defaultExpanded={true}
        />
      </React.Fragment>
    )
  }
  
  export default injectIntl(withRouter(DataNotApplies));
  
