import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import Avatar from 'components/Avatar';
import AplanetIcon from 'components/AplanetIcon';

import './style.less';

const OrganizationPath = ({
  slug,
  logo_small,
  name,
  sortedSuborganizations,
  className = '',
  hasEllipsis = false
}) => {
  return (
    <section className={`OrganizationPath ${className}`}>
      <Avatar
        className="OrganizationPath__logo"
        size="small"
        src={logo_small}
        name={name}
      />
      {sortedSuborganizations.map((suborganization, level) => (
        <div key={suborganization.slug} id={`OrganizationPath-id-${suborganization.slug}`}>
          <Tooltip
            title={suborganization.name}
            overlayClassName="OrganizationPath__tooltip"
            getPopupContainer={() => document.getElementById(`OrganizationPath-id-${suborganization.slug}`)}
          >
            <span className={`OrganizationPath__level${suborganization.slug === slug ? '-last' : ''}`}>
              {suborganization.slug === slug || (level === 0 && sortedSuborganizations.length === 2) || !hasEllipsis ? suborganization.name : '...'}
            </span>
          </Tooltip>
          {suborganization.slug !== slug ? (<AplanetIcon className="OrganizationPath__arrow-right" name='Chevron right'/>) : null}
        </div>
      ))}
    </section>
  );
};

OrganizationPath.propTypes = {
  slug: PropTypes.string.isRequired,
  logo_small: PropTypes.string,
  name: PropTypes.string.isRequired,
  sortedSuborganizations: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  className: PropTypes.string,
  hasEllipsis: PropTypes.bool,
};

export default OrganizationPath;
