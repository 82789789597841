import React from 'react'
import {Checkbox} from 'antd'
import './style.less'

const CustomCheckbox = ({
  size,
  checked,
  onChange,
  indeterminate,
  ...props
}) => (
  <Checkbox
    {...props}
    checked={checked}
    onChange={onChange}
    indeterminate={indeterminate}
    className={`CustomCheckbox ${size ? size : ''}`}
  />
)

export default CustomCheckbox
