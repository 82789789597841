import React from 'react';
import { Switch, Route, withRouter } from 'react-router';
import NotFound from 'containers/NotFound';
import ThirdPartyRequests from 'containers/ThirdPartyRequests';
import KpiRequestScreen from 'containers/ThirdPartyRequests/KpiRequestScreen';

const Routes = () => {
  return (
    <Switch>
      <Route path="/request/:token/data_request/:request_id" component={KpiRequestScreen} />
      <Route path="/request/:token" component={ThirdPartyRequests} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(Routes);
