import React, { Fragment, useMemo, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import CustomTable from 'components/CustomTable';
import ResultTable from './ResultTable';
import CustomButton from 'components/CustomButton';

import {
  Modal,
  Row,
} from 'antd';

import './style.less';


const RecipientTable = ({
  intl,
  recipients,
  subjects,
  surveySubjects,
  responses,
  isSurveyClosed,
  sendReminder,
  sendingReminder,
}) => {
  const t = intl.messages;
  const [showRecipientResult, setShowRecipientResult] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState();

  const onShowRecipientResult = useCallback(
    (record) => {
      setSelectedRecipient(record);
      setShowRecipientResult(true);
    },
    [setShowRecipientResult, setSelectedRecipient]
  );

  const selectedRecipientResult = useMemo(
    () => {
      if (!selectedRecipient) {
        return [];
      }
      return surveySubjects.map((surveySubject) => {
        const response = _.find(
          responses,
          (response) => (
            response.survey_subject_id === surveySubject.id &&
            response.survey_invite_id === selectedRecipient.invite_id
          )
        );
        return {
          ...surveySubject,
          ..._.find(
            subjects,
            subject => subject.id === surveySubject.kpi_category_id
          ),
          weight: response && response.weight,
          comment: response && response.comment
        };
      });
    },
    [selectedRecipient, surveySubjects, responses, subjects]
  );

  const columns = useMemo(
    () => (
      [{
        title: t.group,
        dataIndex: 'goi_name',
        render: (text, record) => `${t['goi_' + record.goi_type]} : ${record.goi_name}`,
      }, {
        title: t.member,
        dataIndex: 'name',
        render: (text, record) => `${text} - ${record.email}`,
      }, {
        title: t.status,
        dataIndex: 'status',
        render: (text) => t[text],
      }, {
        title: t.action,
        key: 'action',
        render: (text, record) => {
          if (record.status === 'waiting' && !isSurveyClosed) {
            return (
              <CustomButton
                type="primary"
                onClick={() => sendReminder(record.invite_id)}
                disabled={sendingReminder}
              >
                {t.send_reminder}
              </CustomButton>
            );
          }
          if (record.status === 'replied') {
            return (
              <CustomButton
                type="primary"
                onClick={() => onShowRecipientResult(record)}
              >
                {t.view_result}
              </CustomButton>
            );
          }
          return '';
        },
      },]
    ),
    [t, onShowRecipientResult, sendReminder, sendingReminder, isSurveyClosed]
  );

  return (
    <Fragment>
      <CustomTable
        columns={columns}
        dataSource={recipients}
        rowKey='id'
      />
      <Modal
        width="70%"
        title={selectedRecipient && selectedRecipient.name}
        visible={showRecipientResult}
        onCancel={() => setShowRecipientResult(false)}
        footer={null}
      >
        <ResultTable
          subjects={selectedRecipientResult}
          showComments={true} />
        <Row type="flex" justify="end">
          <CustomButton
            type="primary" shape="round"
            onClick={() => setShowRecipientResult(false)}
          >
            { t.ok }
          </CustomButton>
        </Row>
      </Modal>
    </Fragment>
  )
}

export default injectIntl(RecipientTable);
