import { useEffect, useState, useCallback } from 'react';
// To use in antd's <Upload>
// TODO: progress
// TODO: abort

const useCustomRequest = ({
  api_requests,
  refreshAccessToken,
  onSuccess = () => {},
  onError = () => {},
  onProgress = () => {},
}, deps = []) => {
  const { access_token, refreshing_tokens } = api_requests;
  const [authHeader, setAuthHeader] = useState(`Bearer ${access_token}`);
  const [started, setStarted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [file, setFile] = useState(null);
  const [params, setParams] = useState({ url: '/', method: 'GET', body: null });
  const [callbacks, setCallbacks] = useState(() => ({
    onSuccess: () => {},
    onError: () => {},
    onProgress: () => {},
  }));

  useEffect(() => {
    setAuthHeader(`Bearer ${access_token}`);
    if(started && !success) {
      launchRequest(params, file, callbacks);
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    access_token
  ]);

  const launchRequest = useCallback(({ url, method, body }, file, { onSuccess, onError, onProgress }) => {
    return fetch(url, {
      method,
      headers: {
        'Authorization': authHeader,
      },
      body,
    }).then(response => {
      if(response.ok) {
        return response.text();
      }
      if(response.status === 401) {
        if(!refreshing_tokens) {
          refreshAccessToken();
        }
        // if no error => we are retrying
        return Promise.reject(false);
      }
      return Promise.reject(response.statusText);
    })
    .then(data => {
      setSuccess(true);
      return onSuccess(data, file);
    })
    .catch(error => {
      // if no error => we are retrying
      if(error) {
        onError(error, file);
      }
    });
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    authHeader
  ]);

  const customRequest = useCallback(({
    action,
    data,
    file,
    filename,
    //headers,
    onError: onFormError = () => {},
    onProgress: onFormProgress = () => {},
    onSuccess: onFormSuccess = () => {},
    //withCredentials,
  }) => {
    // EXAMPLE: post form-data with 'axios'
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });
    }
    formData.append(filename, file);

    const params = {
      url: `${action}${ action.includes('?') ? '&' : '?' }filename=${encodeURIComponent((file || {}).name || filename)}`,
      method: 'POST',
      body: formData,
    };
    const callbacks = {
      onSuccess: (body, file) => {
        onFormSuccess(body, file);
        onSuccess(body, file);
      },
      onError: (error, file) => {
        onFormError(error, file);
        onError(error, file);
      },
      onProgress: (e) => {
        onFormProgress(e);
        onProgress(e);
      },
    };

    setFile(file);
    setParams(params);
    setCallbacks(callbacks);

    setStarted(true);
    launchRequest(params, file, callbacks);

    return {
      abort: (file) => { console.log('Canceled', file); },
    };
  }, [
    launchRequest,
    onError,
    onProgress,
    onSuccess,
    ...deps // eslint-disable-line react-hooks/exhaustive-deps
  ]);

  return customRequest;
};
export default useCustomRequest;

