import { loop, Cmd } from 'redux-loop';

import {
  requestReportHistory,
} from 'actions/api';

const TARGET = 'report_history';

const initialState = {
  data: null,
  loading: false,
  pushing: false,
  error: null,
  report: {
    processing: false,
    done: false,
    ready: false,
    error: null,
    endpoint: '',
    ticket: '',
    url: '',
  },
};

const waitForReport = (endpoint) => ({
  type: 'ACTIVE_WAIT_REQUEST',
  endpoint,
});

const ticketResponse = (
  ticket,
  url,
  responseEndpoint,
  nextState,
) => {
  // NOTICE: we need to compose the URL respecting query parameters
  const questIndex = responseEndpoint.indexOf('?')
  const search = questIndex >= 0
    ? responseEndpoint.slice( questIndex )
    : ''

 const base = questIndex >= 0
  ? responseEndpoint.slice( 0, questIndex )
  : responseEndpoint

  const endpoint = `${base}/status/${ticket}${search}`;
  return loop({
    ...nextState,
    report: {
      ...initialState.template,
      processing: true,
      ready: false,
      error: null,
      endpoint,
      ticket,
      url,
    },
  }, Cmd.action(waitForReport(endpoint)));
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API_CALL_REQUEST':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        data: action.method === 'GET' ? null : state.data,
        loading: true,
        error: null,
        report: { ...initialState.report },
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if (!response || response.target !== TARGET) return state;
      if (response.method === 'POST') {
        const { ticket, url, ...report } = action.response.result;
        let nextState = {
          ...state,
          data: [
            report,
            ...(state.data || []),
          ],
          loading: false,
          error: null,
          pushing: false,
        };

        if (ticket) {
          return ticketResponse(
            ticket, url, action.response.endpoint, nextState,
          );
        }
        return nextState;
      } else if (response.method === 'PUT') {
        const { ticket, url, ...report } = action.response.result;
        const index = state.data.map(
          item => item.slug
        ).indexOf(report.slug);
        let nextState = {
          ...state,
          data: [
            ...state.data.slice(0, index),
            report,
            ...state.data.slice(index + 1)
          ],
          loading: false,
          error: null,
          pushing: false,
        };

        if (ticket) {
          return ticketResponse(
            ticket, url, action.response.endpoint, nextState,
          );
        }
        return nextState;
      }
      else if (response.method === 'DELETE') {
        const {organization_slug, suborganization_slug} = action.response.result;

        return loop(state, Cmd.action(requestReportHistory(organization_slug, suborganization_slug)));
      }
      return {
        ...state,
        data: action.response.result,
        loading: false,
        error: null,
      };
    case 'API_CALL_FAILED':
      if (!action.request || action.request.target !== TARGET) return state;
      const { code } = action;
      return {
        ...state,
        data: action.request.method === 'GET' ? initialState.data : state.data,
        loading: false,
        error: code,
      };
      case 'ACTIVE_WAIT_COMPLETE':
        if (
          !state.report.endpoint
          || state.report.endpoint !== action.endpoint
        ) {
          return state;
        }
        return {
          ...state,
          report: {
            ...state.report,
            done: true,
            processing: false,
            error: null,
          }
        };
      case 'ACTIVE_WAIT_FAILED':
        if (
          !state.report.endpoint
          || state.report.endpoint !== action.endpoint
        ) {
          return state;
        }
        return {
          ...state,
          report: {
            ...state.report,
            done: false,
            processing: false,
            error: 500,
          }
        };
      case 'REPORT_DOWNLOADED':
        return {
          ...state,
          report: {
            ...state.report,
            done: false,
          }
        }
    default:
      return state;
  }
};

export { reducer as report_history };
