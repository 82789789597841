import config from 'config';

const REFRESH_TOKEN_FRAME = 'aplanet_sso_refresh_frame';
const SET_URL_TIMEOUT = 10000; // Authentication fails after ten seconds

const VALID_ORIGINS_FOR_POSTMESSAGE = [
  'https://localhost:3005',
  config.AUTH_URL,
];

let refreshTokenCallback = () => {
  console.log('WARNING: Received refresh token message, but no callback set');
};

window.addEventListener('message', (e) => {
  const {
    type,
    access_token,
    user,
  } = e.data || {};

  if(type === 'set_token') {
    if(!VALID_ORIGINS_FOR_POSTMESSAGE.includes(e.origin)) {
      console.log('WARNING: Invalid origin', e.origin);
      return;
    }

    refreshTokenCallback({
      access_token,
      user,
    });
  }
});

const getFrame = (frameId) => {
  let frame = document.getElementById(REFRESH_TOKEN_FRAME);
  if(!frame) {
    let _frame = document.createElement('iframe');
    _frame.setAttribute('id', frameId);
    _frame.setAttribute('aria-hidden', 'true');
    _frame.style.visibility = 'hidden';
    _frame.style.position = 'absolute';
    _frame.style.width = _frame.style.height = _frame.style.borderWidth = '0px';
    _frame.style.display = 'none';
    frame = document.getElementsByTagName('body')[0].appendChild(_frame);
  }

  return frame;
};

const setFrameUrl = (frame, url) => {
  return new Promise((resolve, reject) => {
    let success = false;

    // WARNING: Terrible mutability here!
    refreshTokenCallback = (payload) => {
      success = true;
      resolve(payload);
    };

    frame.src = 'about:blank'; // Reset the frame before
    frame.src = url;
    setTimeout(() => {
      if(success) {
        return;
      }
      console.log('WARNING: Refresh token timed out');
      reject();
    }, SET_URL_TIMEOUT);
  });
};

const tryRefreshFollowUrl = (response) => {
  if(response.ok && response.status === 202) {
    return response.json()
      .catch(() => ({}))
      .then(payload => {
        if(payload.action === 'follow_url' && payload.url) {
          const frame = getFrame(REFRESH_TOKEN_FRAME);
          return setFrameUrl(frame, payload.url)
            .then(response => {
              return new Response(
                JSON.stringify(response),
                {
                  status: 200,
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              );
            }).catch(error => {
              return new Response(new Blob(), {
                status: 401,
                statusText: 'Unauthorized',
              });
            });
        }
        return response;
      });
  }
  return response;
};

export {
  tryRefreshFollowUrl,
}
