import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { getPeriodsFormat, toBetweenDotsDateFormat, formatLong } from 'utils/date';
import { useSDGsClustering } from 'hooks/sdg';
import AplanetIcon from 'components/AplanetIcon';
import ReportingStandardTag from 'components/ReportingStandardTag';
import EsgLogo from 'components/EsgLogo';
import SdgMiniLogo from 'components/SdgMiniLogo';
import { DATA_REQUEST_STATUS, DATA_REQUEST_PERIODICITIES } from 'utils/dataRequests';

import './style.less';

const FILLED_STATUS = 'filled';
const KPI_VALUE_SOURCE_MANUAL = 'manual';

const KpiRequestsCard = ({
  name,
  standardInfo,
  sdgs,
  esgType,
  requests,
  onClickOpenRequest,
  topLevelOrg,
  isCustomKpi = false,
  hideKpiNoManual = false
}) => {
  const intl = useIntl();

  const clusterSDGs = useSDGsClustering();

  const clusteredSDGs = useCallback((sdgsToCluster) => {
    return clusterSDGs(sdgsToCluster).sort((a, b) => a.code - b.code);
  }, [clusterSDGs]);

  if (requests.every(({value_source}) => value_source !== KPI_VALUE_SOURCE_MANUAL && hideKpiNoManual)) {
    return null;
  }

  return (
    <section className="KpiRequestsCard">
      <div className="KpiRequestsCard__kpi-info">
        <div className="KpiRequestsCard__standard-info-name">
          <AplanetIcon className="KpiRequestsCard__kpi-icon" name="Globe" faStyle="fad" size="16px" />
          {(standardInfo || []).filter(({ standard }) => isCustomKpi || standard !== topLevelOrg).map(({ standard, code }) => (
            code ? (
              <React.Fragment key={`${standard}_${code}`}>
                <ReportingStandardTag
                  className="KpiRequestsCard__standard-info"
                  standard={standard}
                  name={code}
                  code={code}
                />
              </React.Fragment>
            ) : null
          ))}
          <Tooltip title={name} overlayClassName="KpiRequestsCard__name-tooltip">
            <span className="KpiRequestsCard__name">{name}</span>
          </Tooltip>
        </div>
        <div className="KpiRequestsCard__esg-sdgs">
          <EsgLogo type={esgType} faStyle="fad" size="18px" />
          {clusteredSDGs(sdgs).length > 0 && clusteredSDGs(sdgs).map(sdg => (
            <SdgMiniLogo key={sdg.slug} sdg={sdg} />
          ))}
        </div>
      </div>
      <div className="KpiRequestsCard__requests-grid">
        <div className="KpiRequestsCard__requests-header-wrapper">
          <AplanetIcon className="KpiRequestsCard__requests-header-icon" name="Calendar" />
          <span className="KpiRequestsCard__requests-header-label">{intl.formatMessage({id: "third_party_requests_period"})}</span>
        </div>
        <div className="KpiRequestsCard__requests-header-wrapper">
          <AplanetIcon className="KpiRequestsCard__requests-header-icon" name="Send" />
          <span className="KpiRequestsCard__requests-header-label">{intl.formatMessage({id: "third_party_requests_requested_on"})}</span>
        </div>
        <div className="KpiRequestsCard__requests-header-wrapper">
          <AplanetIcon className="KpiRequestsCard__requests-header-icon" name="File text empty" />
          <span className="KpiRequestsCard__requests-header-label">{intl.formatMessage({id: "third_party_requests_status"})}</span>
        </div>
        <div className="KpiRequestsCard__requests-header-wrapper"></div>
        {requests.map(({request_id, periodicity, status, value_source, params}) => value_source !== KPI_VALUE_SOURCE_MANUAL && hideKpiNoManual ? null : (
          <React.Fragment key={`request_${request_id}`}>
            <div className="KpiRequestsCard__request-period-column">
              <span className={`KpiRequestsCard__request-periodicity ${periodicity}`}>
                {intl.formatMessage({id: `third_party_requests_periodicity_${periodicity}`})}
              </span>
              <span className="KpiRequestsCard__request-period-date">
                {getPeriodsFormat(params.request.start_period_date, params.request.end_period_date, intl)}
              </span>
            </div>
            <div className="KpiRequestsCard__request-on-column">
              <span className="KpiRequestsCard__request-date">{toBetweenDotsDateFormat(params.request.first_notif_date, intl)}</span>
            </div>
            <div className="KpiRequestsCard__request-status-column">
              {value_source !== KPI_VALUE_SOURCE_MANUAL ? (
                <Tooltip title={intl.formatMessage({id: `third_party_requests_status_disable_tooltip`})} overlayClassName="KpiRequestsCard__no-manual-tooltip">
                  <span className={`KpiRequestsCard__request-status disable`}>
                    {intl.formatMessage({id: `third_party_requests_status_disable`})}
                  </span>
                </Tooltip>
              ) : (
                <div className="KpiRequestsCard__request-status-wrapper">
                  <span className={`KpiRequestsCard__request-status ${status !== DATA_REQUEST_STATUS.pending ? FILLED_STATUS : status}`}>
                    {intl.formatMessage({id: `third_party_requests_status_${status !== DATA_REQUEST_STATUS.pending ? FILLED_STATUS : status}`})}
                  </span>
                  <span className="KpiRequestsCard__response-date">{status !== DATA_REQUEST_STATUS.pending ? formatLong(params.response?.date, intl) : null}</span>
                </div>
              )}
            </div>
            <div className={`KpiRequestsCard__request-action-column ${value_source !== KPI_VALUE_SOURCE_MANUAL ? 'is-disabled' : ''}`} onClick={() => onClickOpenRequest(request_id, value_source)}>
              <AplanetIcon className="KpiRequestsCard__request-action-icon" name={status !== DATA_REQUEST_STATUS.pending ? 'Undo' : 'Edit'} size='18px'/>
              <span className="KpiRequestsCard__request-action-label">
                {intl.formatMessage({id: `third_party_requests_action_${status !== DATA_REQUEST_STATUS.pending ? 'review' : 'fill'}`})}
              </span>
            </div>
          </React.Fragment>
        ))}
      </div>
    </section>
  );
};

KpiRequestsCard.propTypes = {
  name: PropTypes.string.isRequired,
  standardInfo: PropTypes.arrayOf(PropTypes.shape({
    standard: PropTypes.string.isRequired,
    code:PropTypes.string,
  })),
  sdgs: PropTypes.arrayOf(PropTypes.string).isRequired,
  esgType: PropTypes.string.isRequired,
  requests: PropTypes.arrayOf(PropTypes.shape({
    request_id: PropTypes.number.isRequired,
    status: PropTypes.oneOf([
      DATA_REQUEST_STATUS.done,
      DATA_REQUEST_STATUS.pending,
      DATA_REQUEST_STATUS.not_notified,
      DATA_REQUEST_STATUS.partially_in_use,
      DATA_REQUEST_STATUS.in_use,
    ]).isRequired,
    value_source: PropTypes.oneOf([KPI_VALUE_SOURCE_MANUAL, 'calculated', 'aggregated', 'parent', 'children']).isRequired,
    periodicity: PropTypes.oneOf([
      DATA_REQUEST_PERIODICITIES.month,
      DATA_REQUEST_PERIODICITIES.quarter,
      DATA_REQUEST_PERIODICITIES.semester,
      DATA_REQUEST_PERIODICITIES.year,
    ]).isRequired,
    params: PropTypes.shape({
      kpi_value_id: PropTypes.number,
      request: PropTypes.shape({
        start_period_date: PropTypes.string.isRequired,
        end_period_date: PropTypes.string.isRequired,
        first_notif_date: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  })).isRequired,
  onClickOpenRequest: PropTypes.func.isRequired,
  topLevelOrg: PropTypes.string.isRequired,
  isCustomKpi: PropTypes.bool,
  hideKpiNoManual: PropTypes.bool,
};

export default KpiRequestsCard;
