import React from 'react';
import { injectIntl } from 'react-intl';
import A from 'components/A';

import { Layout } from 'antd';

import './style.css';

const {
  Footer,
} = Layout;

const SignedInFooter = ({ intl }) => {
  const t = intl.messages;
  return (
    <Footer className="Footer">
      <div>{ t.footer_contact_us_info }</div>
      <div>
        <A href={`mailto:${t.footer_contact_email_address}@aplanet.org`}>{ t.footer_contact_email_address }@aplanet.org</A>
      </div>
    </Footer>
  );
}

export default injectIntl(SignedInFooter);
