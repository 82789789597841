import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {formatMessage} from '../../../intl'
import CustomTable from 'components/CustomTable';
import CustomTag from 'components/CustomTag';

const BooleanTableCard = ({ data, parameters }) => {
  
    const columns = useMemo(() => {

        let arrColumns;

        if (data.values.length === 1) {
            
            // Only 1 node
            arrColumns = [{ 
                title: '', 
                dataIndex: 'rowName', 
                key: 'rowName',
                render: (text, record) => { 
                    console.log({ text, record });
                    return (
                    <CustomTag 
                        colorclass="yellow" 
                        className="booleanCardPeriodTitle"
                        name={ text }
                    />
                ) }
            }]

            return arrColumns.concat([{
                title: formatMessage('answer'),
                dataIndex: 'value0',
                key: 'value0',
                render: (text, record) => {
                    if (typeof record[`value0`] === 'boolean') return (
                        <CustomTag 
                            className={ record[`value0`] ? 'booleanCardValueYes' : 'booleanCardValueNo' }
                            name={ record[`value0`] ? formatMessage('yes') : formatMessage('no') }
                        />
                    )
                    return <>-</>
                }
            }]);

        }

        arrColumns = [{ title: '', dataIndex: 'rowName', key: 'rowName' }];

        return arrColumns.concat(data.labels.map((period, idx) => ({
            title: (
                <CustomTag 
                    colorclass="yellow" 
                    className="booleanCardPeriodTitle"
                    name={ period }
                />
            ),
            dataIndex: `value${idx}`,
            key: `value${idx}`,
            render: (text, record) => {
                if (typeof record[`value${idx}`] === 'boolean') return (
                    <CustomTag 
                        className={ record[`value${idx}`] ? 'booleanCardValueYes' : 'booleanCardValueNo' }
                        name={ record[`value${idx}`] ? formatMessage('yes') : formatMessage('no') }
                    />
                )
                return <>-</>
            }
        })));
        
    }, [data.labels, data.values.length]);

    const dataSource = useMemo(() => {

        if (data.values.length === 1){

            return data.labels.map((period, idx) => ({
                key: idx,
                rowName: period,
                value0: data.values[0].data[idx].value
            }));

        }

        return data.values.map((nodeData, idx) => {
            
            const periodValues = nodeData.data.reduce((obj, data, idxPeriod) => {
                obj[`value${idxPeriod}`] = data.value;
                return obj;
            }, {});

            return {
                key: idx,
                rowName: nodeData.label,
                ...periodValues
            }
            
        });

    }, [data.values, data.labels]);

    const summary = useMemo(() => {

        let summaryValues = data.values.reduce((obj, nodeData, idx) => {
            
            nodeData.data.forEach( data => {
            const keyValue = typeof data.value !== 'boolean' ? 'empty' : (data.value ? 'yes' : 'no');
            obj[keyValue]++;
            })
            return obj;
            
        }, {
            empty: 0,
            yes: 0,
            no: 0
        });

        const totalCount = data.values.length * data.labels.length;

        return {
            ...summaryValues,
            percent_empty: (summaryValues.empty / totalCount * 100).toFixed(2),
            percent_yes: (summaryValues.yes / totalCount * 100).toFixed(2),
            percent_no: (summaryValues.no / totalCount * 100).toFixed(2),
            totalCount: totalCount
        }

    }, [data.values, data.labels.length]);

    return (
        <>
            {
                data.labels.length < 2 && 
                <ul className='DashboardComponent__booleancard__tableSummary'>
                    <li>
                        <span className='tableSummaryCount tableSummaryCount_empty'></span>
                        <strong>{ summary.percent_empty }%</strong> 
                        {formatMessage('dashboard_card_boolean_table_summary_empty')} ({ summary.empty })
                    </li>
                    <li>
                        <span className='tableSummaryCount tableSummaryCount_yes'></span>
                        <strong>{ summary.percent_yes }%</strong> 
                        {formatMessage('yes')} ({ summary.yes })
                    </li>
                    <li>
                        <span className='tableSummaryCount tableSummaryCount_no'></span>
                        <strong>{ summary.percent_no }%</strong> 
                        {formatMessage('no')} ({ summary.no })
                    </li>
                </ul>
            }
            <CustomTable 
                columns={ columns }
                dataSource={ dataSource }
                pagination={ false }
                scroll={{ x: 'max-content' }}
            />
        </>
    )

}

BooleanTableCard.propTypes = {
    data: PropTypes.object,
    parameters: PropTypes.object,
};
  
export default BooleanTableCard;