const initialState = {
    data: [],
    no_reads: 0
  };
  
  const reducer = (state = initialState, action) => {
    switch(action.type) {
      case 'NOTIFICATION:GET':
        return {
          ...state,
          data: action.data,
          no_reads: action.data.reduce((prev,el) => {
            if (!el.read){
              return prev + 1;
            }else{
              return prev;
            }
          },0)
        }
      case 'NOTIFICATION:CREATE':
        return {
            ...state,
            data: action.data.temporary ? state.data : [action.data,...state.data],
            no_reads: action.data.temporary ? state.no_reads : state.no_reads + 1
        }
      case  'NOTIFICATION:DELETE':
          return {
              ...state,
              data: state.data.filter(el => el.id !== action.data.id ),
              no_reads: state.no_reads - Number(! (state.data.find(el => el.id === action.data.id)).read)

          }
      case 'NOTIFICATION:READ':
          return {
              ...state,
              data:state.data.map(el => {
                  if (action.data.id && el.id === action.data.id){
                      el.read = true;
                  } else if(action.data.all){
                      el.read = true;
                  }
                  return el;
              }),
              no_reads: state.no_reads - (action.data.id ? 1 : (action.data.all ? state.no_reads : 0))
          }
      case 'NOTIFICATION:UNREAD':
        return {
            ...state,
            data:state.data.map(el => {
                if (action.data.id && el.id === action.data.id){
                    el.read = false;
                } else if(action.data.all){
                    el.read = false;
                }
                return el;
            }),
            no_reads: state.no_reads + (action.data.id ? 1 : (action.data.all ? (state.data.length - state.no_reads) : 0))
        }    
      default:
        return state;
    }
  };
  
  export {
    reducer as notification,
  };
