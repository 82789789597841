import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Bar } from 'react-chartjs-2';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import CustomChart from 'components/CustomChart';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';
import {
  getPointsRangeForPositions,
  getPositionPoints,
  getSalaryForPoints,
} from 'utils/equality';

import {
  requestEqualityPlanFactors,
  requestEqualityPlanPositions,
  requestEqualityPlanSalaries,
} from 'actions/api';

import CustomButton from 'components/CustomButton';
import {
  Row,
  Col,
} from 'antd';
import { mean } from 'lodash';
import { defaultColors } from 'components/Dashboard/useColors';


const SalaryComparison = ({
  intl,
  history,
  plan,
  updatePlanProgress,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanFactors(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanPositions(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanSalaries(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetching: fetchingFactors,
    data: factors,
  } = useSelector(state => state.equality_plan_factor);
  const {
    fetching: fetchingPositions,
    data: positions,
  } = useSelector(state => state.equality_plan_position);
  const {
    fetching: fetchingSalaries,
    data: salaries,
  } = useSelector(state => state.equality_plan_salary);

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  const handleOnClickReviewed = useCallback(
    () => {
      updatePlanProgress('salary_comparison');
      handleBack();
    },
    [updatePlanProgress, handleBack]
  );

  const pointsRange = useMemo(
    () => {
      if (!positions || !factors) {
        return [0, 100];
      }

      return getPointsRangeForPositions(
        positions, factors, plan.job_evaluation?.type_points
      );
    },
    [positions, factors, plan]
  );

  const positionsData = useMemo(
    () => {
      if (!positions || !factors || !salaries) {
        return [];
      }

      return positions.map(position => {
        const points = getPositionPoints(
          position, factors, plan.job_evaluation?.type_points
        );
        const positionSalaries = salaries
          .filter(salary => salary.position_id === position.id);

        return {
          ...position,
          salary_f: getSalaryForPoints(
            pointsRange[0], pointsRange[1], plan.job_evaluation?.salary_min,
            plan.job_evaluation?.salary_max,
          )(
            (plan.job_evaluation?.salary_function || 1) - 1
          )(
            points
          ).toFixed(3),
          avg_salary_male: mean(
            positionSalaries
              .filter(salary => salary.gender === 'male')
              .map(salary => salary.salary)
          ) || 0,
          avg_salary_female: mean(
            positionSalaries
              .filter(salary => salary.gender === 'female')
              .map(salary => salary.salary)
          ) || 0,
          avg_salary_neutral: mean(
            positionSalaries
              .filter(salary => salary.gender === 'neutral')
              .map(salary => salary.salary)
          ) || 0,
        }
      });
    },
    [factors, plan, positions, pointsRange, salaries]
  );

  const getGraphData = useCallback(
    (graphType) => {
      let graphData = {
        labels: [],
        datasets: [
          graphType === 'suggested' ? 'f' : 'base', 'avg_m', 'avg_f', 'avg_n'
        ].map((option, index) => ({
          label: t[`equality_job_evaluation_salary_comparison_by_position_salary_${option}`],
          data: [],
          backgroundColor: defaultColors[index],
        })),
      };

      positionsData.forEach(position => {
        graphData.labels.push(`${position.code}: ${position.name}`);

        graphData.datasets[0].data.push(
          graphType === 'suggested'
            ? position.salary_f
            : position.salary
        );
        graphData.datasets[1].data.push(position.avg_salary_male);
        graphData.datasets[2].data.push(position.avg_salary_female);
        graphData.datasets[3].data.push(position.avg_salary_neutral);
      });
      return graphData;
    },
    [t, positionsData]
  );

  if (
    fetchingFactors
    || !factors
    || fetchingPositions
    || !positions
    || fetchingSalaries
    || !salaries
  ) {
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_salary_comparison}
        handleBackOrSkip={handleBack}
      />
      <Row type="flex" justify="end">
        <CustomButton
          type="primary"
          onClick={handleOnClickReviewed}
        >
          {t.equality_plan_reviewed}
        </CustomButton>
      </Row>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_salary_comparison_by_position_first}</h2>
        </Col>
        <Col span={24}>
          <CustomChart
            title={t.equality_job_evaluation_salary_comparison_by_position_first}
            Chart={Bar}
            data={getGraphData()}
            height='300px'
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: false,
                },
                y: {
                  stacked: false,
                },
              },
              plugins: {
                legend: { 
                  align: 'start',
                  position: 'bottom',
                  labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded'
                  }
                }
              }
            }}
          />
        </Col>
        <Col span={24}>
          <h2>{t.equality_job_evaluation_salary_comparison_by_position_second}</h2>
        </Col>
        <Col span={24}>
          <CustomChart
            title={t.equality_job_evaluation_salary_comparison_by_position_second}
            Chart={Bar}
            data={getGraphData('suggested')}
            height='300px'
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: false,
                },
                y: {
                  stacked: false,
                },
              },
              plugins: {
                legend: { 
                  align: 'start',
                  position: 'bottom',
                  labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded'
                  }
                }
              }
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default withRouter(injectIntl(SalaryComparison));
