import {
    useCallback,
    useMemo,
} from 'react';

import {
    toggle as toggleSetState,
    replaceAll as replaceAllSetState,
    resetAll as resetAllState,
    setAll as setAllState
} from 'actions/setState';

import {
  useSelector,
  useDispatch
} from 'react-redux';
import _ from 'lodash';

import {DEFAULT_STATUS_FILTER_STATE, FILTER_TYPES, getFilterSet} from './constants';

const useScreenFilters = ({
    screen,
    defaultFiltersSet
}) => {
    const dispatch = useDispatch();
    const maybeScreenFilters = useSelector(state => state.screen_filters)[screen];
    const screenFilters = useMemo(() => maybeScreenFilters || {}, [maybeScreenFilters]);

    const filterSet = getFilterSet(screen, defaultFiltersSet);

    const values = useMemo(() => {
        return filterSet.map(fs => {
            const value = (screenFilters[fs.type] && fs?.condition && fs.condition(screenFilters[fs.type])) ? screenFilters[fs.type] : fs.defaultValue;
            const result = {
                type: fs.type,
                value
            };
            return fs?.condition ? {...result, set: fs.condition(value)} : result;
        });
    }, [filterSet, screenFilters]);

    const isFilterSet = useMemo(() => {
        return values.some(({set}) => _.isBoolean(set) ? set : !_.isEmpty(set));
    }, [values]);

    const defaultFiltersValues = useMemo(() => {
        const filters = {};
        filterSet.forEach(({defaultValue, type}) => {
            filters[type] = defaultValue;
        });
        return filters;
    }, [filterSet]);

    const handleFilter = useCallback((filter) => (value) => {
        switch(filter) {
            case FILTER_TYPES.selected_category:
            case FILTER_TYPES.assignee_filter:
            case FILTER_TYPES.standard_filter:
            case FILTER_TYPES.date_filter:
            case FILTER_TYPES.text_filter:
            case FILTER_TYPES.period_date_filter:
            case FILTER_TYPES.requested_by_filter:
            case FILTER_TYPES.requested_to_filter:
            case FILTER_TYPES.request_date_filter:
            case FILTER_TYPES.response_date_filter:
            case FILTER_TYPES.upload_date_filter:
            case FILTER_TYPES.user_filter:
            case FILTER_TYPES.archive_status_filter:
                dispatch(
                    replaceAllSetState({
                        target: 'screen_filters',
                        param: value,
                        filter,
                        screen
                    })
                );
                break;
            case FILTER_TYPES.standard_array_filter:
            case FILTER_TYPES.validation_status_filter:
            case FILTER_TYPES.sdg_filter:
            case FILTER_TYPES.type_filter:
            case FILTER_TYPES.tag_filter:
            case FILTER_TYPES.periodicity_filter:
            case FILTER_TYPES.request_type_filter:
            case FILTER_TYPES.request_status_filter:
            case FILTER_TYPES.kpi_value_type_filter:
            case FILTER_TYPES.request_comment_attachment_filter:
            case FILTER_TYPES.request_action_filter:
            case FILTER_TYPES.attachment_filter:
            case FILTER_TYPES.data_manager_filter:
            case FILTER_TYPES.data_editor_filter:
            case FILTER_TYPES.data_validator_filter:
            case FILTER_TYPES.suborganizations_filter:
            case FILTER_TYPES.associated_kpis_filter:
            case FILTER_TYPES.schema_type_filter:
            case FILTER_TYPES.source_filter:
            case FILTER_TYPES.kpis:
            case FILTER_TYPES.value_status_filter:
                if(Array.isArray(value)) {
                    dispatch(
                        replaceAllSetState({
                            target: 'screen_filters',
                            param: value,
                            filter,
                            screen
                        })
                    );
                } else {
                    dispatch(
                        toggleSetState({
                            target: 'screen_filters',
                            param: value,
                            filter,
                            defaultValue: [],
                            screen
                        })
                    )
                }
                break;
            case FILTER_TYPES.status_filter:
                if(Array.isArray(value)) {
                    dispatch(
                        replaceAllSetState({
                            target: 'screen_filters',
                            param: value.length === 0 ? DEFAULT_STATUS_FILTER_STATE : value,
                            filter,
                            screen
                        })
                    );
                } else {
                    const filteredStatuses = (values || []).find(v => v.type === FILTER_TYPES.status_filter)?.value;
                    if(filteredStatuses.length === 1 && filteredStatuses[0] === value) {
                        dispatch(
                            replaceAllSetState({
                                target: 'screen_filters',
                                param: DEFAULT_STATUS_FILTER_STATE,
                                filter,
                                screen
                            })
                        )
                    } else {
                        dispatch(
                            toggleSetState({
                                target: 'screen_filters',
                                param: value,
                                filter,
                                defaultValue: DEFAULT_STATUS_FILTER_STATE,
                                screen
                            })
                        )
                    }
                }
                break;
            default:
        }
    }, [dispatch, screen, values]);

    const setAllFilters = useCallback((allFilters) => {
        dispatch(
            setAllState({
                target: 'screen_filters',
                screen,
                param: allFilters
            })
        );
    }, [dispatch, screen]);

    const resetAllFilters = useCallback((useDefaultValues = true) => {
        dispatch(
            resetAllState({
                target: 'screen_filters',
                screen,
                param: useDefaultValues ? defaultFiltersValues : null
            })
        );
    }, [dispatch, screen, defaultFiltersValues]);

    const filterState = useMemo(() => {
        return (values || []).reduce((acc, curr) => {
            acc[curr.type] = curr.value;
            return acc;
        }, {});
    }, [values]);

    return {
        values: values.map(v => v?.set || typeof v?.set == "boolean" ? [v.value, v.set] : [v.value]),
        handleFilter,
        filterState,
        resetAllFilters,
        isFilterSet,
        setAllFilters
    };
};

export default useScreenFilters;
