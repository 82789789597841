import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import CustomToggle from 'components/CustomToggle';
import CustomRadio from 'components/CustomRadio';
import ModalApplyNotApply from 'components/ModalApplyNotApply'
import ModalKpiSettings from 'components/ModalKpiSettings'
import ModalCalculatedKpi from 'components/ModalCalculatedKpi'
import CycleDateString from 'components/CycleDateString'
import A from 'components/A'
import { useFeatureList } from 'components/FeatureSwitch';

import {
  Modal, // Shameful Modal.info
  Button,
} from 'antd';

import { SettingOutlined } from '@ant-design/icons';

// NOTICE: Set to 'false' to show more info about this kpi_value's collection_frequency and cycle_date
const SKIP_THIS_DATA = true;

// NOTICE: Set to false to enable editing colelction_frequency and cycle_date
const DISABLE_EDIT_SETTINGS = false;

const MANUAL_SOURCE = 'manual';

const Settings = ({
  intl,
  kpi_slug,
  applies = true,
  source = MANUAL_SOURCE,
  value_source = MANUAL_SOURCE,
  canBeChildren = false, // This is kind of a feature switch
  canBeAggregated = false, // This is kind of a feature switch
  canBeParent = false, // This is kind of a feature switch
  canBeCalculated = false, // This is kind of a feature switch
  schema,
  formulas: allFormulas = [],
  aggregations: allAggregations = [],
  aggregation,
  aggregation_unit,
  formula_unit,
  formula,
  this_formula,
  not_apply_comment = '',
  collection_frequency = 'year',
  cycle_date = '01-01',
  this_collection_frequency = 'year',
  this_cycle_date = '01-01',
  first_reported_at,
  loading,
  error,
  permissions = {},
  ready_to_validate_level, // Integer with flags
  valid_collection_frequencies = [],
  config = {},
  org_mandatory_data
}) => {
  const {
    features: featureList,
  } = useFeatureList();

  const isRestrictedAdmin = useMemo(() => (
    featureList.has('restricted_admins') && !permissions?.can_affect_all_kpis
  ), [
    featureList,
    permissions,
  ]);

  const handleAppliesChange = useCallback(() => {
    setApplyModalParams({ applying: !applies });
  }, [
    applies,
  ]);

  const [applyModalParams, setApplyModalParams] = useState(null);
  const [showCalculatedModal, setShowCalculatedModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  return (
    <React.Fragment>
      {
        applies && 
        <div className='KpiDetail__title'>
          <div>{ intl.formatMessage({ id: "settings"}) }</div>
          {
            isRestrictedAdmin || !permissions.can_configure_kpi || DISABLE_EDIT_SETTINGS ? null :
            <div className="KpiDetail__settings__button">
              <Button
                shape="circle"
                icon={<SettingOutlined />}
                title={intl.formatMessage({ id: "change_settings"})}
                onClick={() => setShowSettingsModal(true)}
              />
            </div>
          }
        </div>
      }
      <section className="KpiDetail__card KpiDetail__settings">
        <div className="KpiDetail__settings-section">
          <div className="KpiDetail__card-title">{ intl.formatMessage({ id: "applies"}) }</div>
          <CustomToggle
            disabled={isRestrictedAdmin || !permissions.can_configure_kpi || loading || ready_to_validate_level}
            options={[intl.formatMessage({ id: "yes"}), intl.formatMessage({ id: "no"})]}
            checked={applies}
            onChange={handleAppliesChange}
          />
          {
            applies || !not_apply_comment ? null :
              <span className="KpiDetail__status-reason">
                <A
                  onClick={() => Modal.info({
                    title: intl.formatMessage({ id: "reason"}),
                    content: (
                      <div>
                        { not_apply_comment }
                      </div>
                    ),
                  })}
                >{ intl.formatMessage({ id: "reason"}) }</A>
              </span>
          }
        </div>
        {
          !(canBeChildren || canBeCalculated || canBeAggregated || canBeParent)
          ? null
          : (
            <div className="KpiDetail__settings-section">
              <div className="KpiDetail__card-title">{ intl.formatMessage({ id: "kpidetail_settings_is_calculated"}) }</div>
              <CustomRadio.Group
                disabled={!permissions.can_configure_kpi || loading || ready_to_validate_level}
                value={source}
                onChange={() => setShowCalculatedModal(true)}
                buttonStyle="solid"
                className="KpiDetail__settings__radiobutton"
              >
                {
                  [
                    ...( canBeChildren ? [ ['children', intl.formatMessage({ id: "kpi_source_children"}) ] ] : []),
                    ...( canBeCalculated ? [ ['calculated', intl.formatMessage({ id: "kpi_source_calculated"}) ] ] : []),
                    ...( canBeAggregated ? [ ['aggregated', intl.formatMessage({ id: "kpi_source_aggregated"}) ] ] : []),
                    ...( canBeParent ? [ ['parent', intl.formatMessage({ id: "kpi_source_parent"}) ] ] : []),
                    ['manual', intl.formatMessage({ id: "kpi_source_manual"})],
                  ].map(([slug, name]) => (
                    <CustomRadio.Button
                      key={slug}
                      value={slug}
                      onClick={() => setShowCalculatedModal(true)}
                    >
                      { name }
                    </CustomRadio.Button>
                  ))
                }

              </CustomRadio.Group>
              {
                value_source === source
                ? null :
                  <div className="KpiDetail__settings-subsetting">
                    { intl.formatMessage({ id: "source_this_data"}) }
                    :
                    { ' ' }
                    {
                      intl.formatMessage({id: `kpi_source_${value_source}`})
                    }
                  </div>
              }
            </div>
          )
        }

        <div className="KpiDetail__settings-section">
          <div className="KpiDetail__card-title KpiDetail__card-title-margin">{ intl.formatMessage({ id: "collection_frequency"}) }</div>
          <div className="KpiDetail__settings-setting">
            {
              intl.formatMessage({ id: `collection_frequency_${collection_frequency}`})
            }
            {
              SKIP_THIS_DATA || (
                this_collection_frequency === collection_frequency
              ) ? null :
              <div className="KpiDetail__settings-subsetting">
                {
                  intl.formatMessage({ id: "collection_frequency_this_data"})
                }
                :
                { ' ' }
                {
                  intl.formatMessage({ id: `collection_frequency_${this_collection_frequency}`})
                }
              </div>
            }
          </div>
        </div>

        <div className="KpiDetail__settings-section">
          <div className="KpiDetail__card-title KpiDetail__card-title-margin">{ intl.formatMessage({ id: "date"}) }</div>
          <div className="KpiDetail__settings-setting">
            <CycleDateString
              collection_frequency={collection_frequency}
              cycle_date={cycle_date}
            />
            {
              SKIP_THIS_DATA || (
                this_cycle_date === cycle_date && this_collection_frequency === collection_frequency
              ) ? null :
              <div className="KpiDetail__settings-subsetting">
                {
                  intl.formatMessage({ id: "cycle_date_this_data"})
                }
                :
                { ' ' }
                {
                  <CycleDateString
                    collection_frequency={this_collection_frequency}
                    cycle_date={this_cycle_date}
                  />
                }
              </div>
            }
          </div>
        </div>
      </section>
      <ModalApplyNotApply
        visible={!!applyModalParams}
        onClose={() => setApplyModalParams(null)}
        kpi_slugs={[kpi_slug]}
        hasNodesSelector
        { ...(applyModalParams || {}) }
      />
      <ModalKpiSettings
        visible={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        kpi_slugs={[kpi_slug]}
        collection_frequency={collection_frequency}
        cycle_date={cycle_date}
        first_reported_at={first_reported_at}
        loading={loading}
        error={error}
        valid_collection_frequencies={valid_collection_frequencies}
        can_override={permissions.can_override_limits}
        mandatory_data={config?.mandatory_data}
        org_mandatory_data={org_mandatory_data}
      />
      {
        !(canBeChildren || canBeCalculated || canBeAggregated || canBeParent)
        ? null
        : (
          <ModalCalculatedKpi
            visible={showCalculatedModal}
            onClose={() => setShowCalculatedModal(false)}
            canBeChildren={canBeChildren}
            canBeAggregated={canBeAggregated}
            canBeCalculated={canBeCalculated}
            canBeParent={canBeParent}
            schema={schema}
            kpi_slugs={[kpi_slug]}
            source={source}
            allFormulas={allFormulas}
            allAggregations={allAggregations}
            aggregation={aggregation}
            unit={
              source === 'aggregated'
              ? aggregation_unit
              : source === 'calculated'
                ? formula_unit
                : null
            }
            formula={formula}
            loading={loading}
            error={error}
            can_override={permissions.can_override_limits}
          />
        )
      }
    </React.Fragment>
  );
};

export default injectIntl(Settings);
