import React, { useMemo } from 'react';

import EsgLogo from 'components/EsgLogo';


const DataSourcesEsgTypes = ({
  kpi,
}) => {
  const esgs = useMemo(
    () => kpi.esgs,
    [kpi]
  );

  return (
    <>
      {
        (esgs || []).map(type => (
          <EsgLogo
            key={type}
            type={type}
          />
        ))
      }
    </>
  );
}

export default DataSourcesEsgTypes;
