import React from 'react';
import './style.less';
import AplanetIcon from 'components/AplanetIcon';
import {Collapse, Tooltip} from 'antd';

const CustomCollpase = (props) => {
  return (
    <div className={`CustomCollapse ${props.className || ""}`} id={props.header}>
      <Collapse {...props} defaultActiveKey={['1']}>
        <Collapse.Panel
          header={
            <>
              <div 
                className='CustomCollapse__header__title'
              >
                {props.header}
              </div>
              {props.tooltip 
              ? <Tooltip 
                  overlayClassName="CustomCollapse__tooltip" 
                  autoAdjustOverflow={false} 
                  placement={props.tooltip.placement || "bottomRight"} 
                  getPopupContainer={() => document.getElementById(props.header)}
                  title={props.tooltip.title}
                >
                    <div>
                      <AplanetIcon name="Information"/>
                    </div>
                </Tooltip> 
              : ''}
            </>
          }
          key="1"
        >
          {props.children}
        </Collapse.Panel>
      </Collapse>
    </div>
    
  );
}

export default CustomCollpase;
