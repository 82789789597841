import React, { useMemo, forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { getDatalabelOptions } from './types';
import { pluginBarTarget } from '../chartPlugins';
import { convertStringToArrayWordSegments } from 'utils/strings';

const DEFAULT_PARAMETERS = {
  layout: 'vertical',
  stacked: false,
  locale: undefined,
  type: 'vertical-bars',
  showDataLabels: false,
  showDataTarget: false
};

// TODO: Take out parameters and expose a way to get the params for a nivo-api call
const Bars = forwardRef(({
  slug,
  parameters = DEFAULT_PARAMETERS,
  data,
  onDownload,
}, ref) => {
  const intl = useIntl();
  const {
    labels = [],
    values: datasets = [],
  } = data;

  const {
    nodata = intl.messages.no_data,
    layout = DEFAULT_PARAMETERS.layout,
    stacked = DEFAULT_PARAMETERS.stacked,
    showDataLabels = DEFAULT_PARAMETERS.showDataLabels,
    showDataTarget = DEFAULT_PARAMETERS.showDataTarget
  } = parameters;

  const axesOptions = useCallback(() => {

    return {
      stacked,
      beginAtZero: true,
      ticks: {
        precision: 0
      }
    }

  }, [stacked]);

  const options = useMemo(() => ({
    borderRadius: (context) => {
        let borderRadius = 4;
        if (context.raw.value){
          const model = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
          const modelProps = model.getProps(['width', 'height'], true);
          const barWidth = layout === 'vertical' ? modelProps.width : modelProps.height;
          if (barWidth < borderRadius) borderRadius = 0;
        }
        return borderRadius;
    },
    layout: {
      padding: {
        top: 20
      }
    },
    maintainAspectRatio: false,
    scales: {
      x: axesOptions(),
      y: axesOptions()
    },
    indexAxis: layout === 'horizontal' ? 'y' : 'x', 
    plugins: {
      datalabels: showDataLabels ? getDatalabelOptions(parameters) : false,
      legend: {
        align: 'start',
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded'
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => { 

            const label = context.dataset.label || '';
            const value = context.formattedValue === 'NaN' ? '-' : context.formattedValue;
            const tooltip = convertStringToArrayWordSegments(`${label}: ${value}`, 50);
            
            if (showDataTarget && context.raw.target){
              tooltip.push(`${ intl.formatMessage({ id: 'dashboard_card_form_target_chart_label' }) }: ${ context.raw.target }`)
            }

            return tooltip;
          },
        },
      }
    }
  }), [axesOptions, layout, parameters, showDataLabels, showDataTarget, intl]); 

  const plugins = showDataTarget ? [pluginBarTarget] : [];

  return (
    <div className="DashboardComponent__card_content">
      {
        !data.values || data.values.length === 0
        ?
          <div
            className="DashboardComponent__nodata"
          >
            { nodata }
          </div>
        :
          <Bar
            ref={ref}
            data={{ labels, datasets }}
            height='300px'
            options={options} 
            plugins={ plugins}
          />
      }
    </div>
  );
});

Bars.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.object,
  onDownload: PropTypes.func,
};

export default Bars;

