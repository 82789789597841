import {
  useCallback,
  useMemo,
} from 'react';

import {
  toggle as toggleSetState,
  resetAll as resetAllState,
  setAll as setAllState,
} from 'actions/setState';

import {
useSelector,
useDispatch
} from 'react-redux';

import {
  getDefaultTableCustomViewSet,
} from './constants';

const useTableCustomView = ({
  table,
}) => {
  const dispatch = useDispatch();
  const customTableView = useSelector(state => state.table_custom_view)[table];

  const defaultTableCustomViewSet = getDefaultTableCustomViewSet(table);

  const tableCustomViewState = useMemo(
      () => customTableView || defaultTableCustomViewSet,
      [customTableView, defaultTableCustomViewSet]
  );

  const isTableCustomViewSet = useMemo(() => {
      return defaultTableCustomViewSet.some(view => !tableCustomViewState.includes(view));
  }, [tableCustomViewState, defaultTableCustomViewSet]);

  const toggleTableCustomView = useCallback((value) => {
      dispatch(
          toggleSetState({
              target: 'table_custom_view',
              param: value,
              table
          })
      )
  }, [dispatch, table]);

  const handleTableCustomView = useCallback((value) => {
      dispatch(
          setAllState({
              target: 'table_custom_view',
              param: value,
              table
          })
      )
  }, [dispatch, table]);

  const resetAllTableCustomViews = useCallback(() => {
      dispatch(
          resetAllState({
              target: 'table_custom_view',
              param: defaultTableCustomViewSet,
              table
          })
      );
  }, [defaultTableCustomViewSet, dispatch, table]);

  return {
      tableCustomViewState,
      isTableCustomViewSet,
      handleTableCustomView,
      toggleTableCustomView,
      resetAllTableCustomViews,
  };
};

export default useTableCustomView;