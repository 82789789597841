import React, {
  useCallback,
  useRef,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
} from 'antd';

import CustomButton from 'components/CustomButton';

import './style.less';

const CustomChart = ({
  intl,
  Chart,
  title,
  ...props
}) => {
  const t = intl.messages;
  const chartRef = useRef(null);

  const handleDownload = useCallback(() => {
    const anchor = document.createElement('a');
    anchor.setAttribute(
      'href',
      chartRef.current.chartInstance.toBase64Image()
    );
    anchor.setAttribute('download', title + '.png');
    anchor.click();
  }, [ title ]);

  return (
    <Row
      gutter={[20, 20]}
      className="CustomChart"
    >
      <Col span={24}>
        <CustomButton onClick={handleDownload}>
          { t.equality_chart_download }
        </CustomButton>
      </Col>
      <Col span={24}>
        <Chart
          ref={chartRef}
          {...props}
        />
      </Col>
    </Row>
  );
}

export default injectIntl(CustomChart);
