import React, { useCallback, useState, useMemo } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import {
  Row,
  Col,
  Button,
  Input,
  Form,
  Card,
  DatePicker,
  Modal,
  Popover,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { fullValidation as validation } from './validation';
import useForm from 'utils/useForm';
import MeasuresForm from 'components/EqualityPlanDiagnosis/MeasuresForm';
import FormItemPreview from 'components/EqualityPlan/FormItemPreview';
import EqualityPlanHeader from 'components/EqualityPlan/Header';
import { formatDateMoment, dateToDb, DATE_ONLY } from 'utils/date';
import useOrganizations from 'utils/useOrganizations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.less';

const DiagnosisForm = ({
  intl,
  history,
  plan,
  updatePlan,
  actions,
  measures,
  createMeasure,
  updateMeasure,
  deleteMeasure,
}) => {
  const t = intl.messages;
  const {
    suborganization: organization,
  } = useOrganizations();
  const [selectedMeasure, setSelectedMeasure] = useState();
  const defaults = useMemo(() => ({
    start_date: organization.product_config.atlas.reporting_start_date,
    end_date: dateToDb(new Date()),
    ...plan.diagnosis_data,
  }), [organization, plan]);
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    updatePlan({diagnosis_data: values});
    history.push(`/equality-plan/${plan.id}/diagnosis/document-manager`);
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    dirtyFields,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  const handleChangeDate = useCallback((name, value) => {
    handleChange(name)(value && dateToDb(value));
  }, [ handleChange ]);

  const handleBackOrSkip = useCallback(
    (action) => {
      const actionUrl = action === 'skip'
        ? `/equality-plan/${plan.id}/diagnosis/document-manager`
        : `/equality-plan/${plan.id}/`;

      if (dirtyFields.length > 0) {
        Modal.confirm({
          title: t.equality_plan_exit_without_save,
          content: t.equality_plan_exit_without_save_description,
          okText: t.equality_plan_exit_without_save_ok,
          cancelText: t.equality_plan_exit_without_save_cancel,
          onOk() {
            history.push(actionUrl);
          },
        })
      } else {
        history.push(actionUrl);
      }
    },
    [dirtyFields, plan, history, t]
  );

  const handleOnDeleteMeasure = useCallback(
    (measure) => {
      Modal.confirm({
        title: t.equality_plan_delete_measure,
        content: t.equality_plan_delete_measure_description,
        okText: t.equality_plan_delete_measure_ok,
        cancelText: t.equality_plan_delete_measure_cancel,
        onOk() {
          deleteMeasure(measure.id);
        },
      })
    },
    [t, deleteMeasure]
  );

  const canDeleteMeasure = useCallback(
    (measure) => !actions.find(action => action.measure_id === measure.id),
    [actions]
  );

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_diagnosis}
        isDocumentManager={false}
        handleBackOrSkip={handleBackOrSkip}
      />
      <Form onFinish={handleSubmit} className="EqualityPlanDiagnosis-form">
        <Row>
          <Col span={20}>
            <Row type="flex" align="middle">
              <span>{t.equality_diagnosis_complete_data}</span>&nbsp;&nbsp;
              <Popover
                placement="bottom"
                content={
                  <p className='popover-text'>
                    <ReactMarkdown
                      source={t.equality_diagnosis_form_help_text}
                    />
                  </p>
                }
                trigger="click"
              >
                <FontAwesomeIcon
                  className='icon-large icon-yellow' 
                  icon={'lightbulb'}
                />
              </Popover>
            </Row>
          </Col>
          <Col span={2}>
            <Button
                type="primary"
                onClick={() => handleBackOrSkip('skip')}
              >
                { t.skip }
            </Button>
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              htmlType="submit"
            >
              { t.done }
            </Button>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Card className="form-input-card">
              <Row gutter={10}>
                <Col span={24}>
                  <h4>{t.equality_diagnosis_period}</h4>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    validateStatus={ showError('start_date') ? 'error' : '' }
                    help={ showError('start_date') }
                    label={ t.start_date }
                    colon={false}
                    required
                  >
                    <DatePicker
                      format={DATE_ONLY}
                      name="start_date"
                      placeholder={t.start_date}
                      value={formatDateMoment(getValue('start_date'))}
                      onChange={(value) => handleChangeDate('start_date', value)}
                      className="EqualityPlanDiagnosis-form-datepicker"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    validateStatus={ showError('end_date') ? 'error' : '' }
                    help={ showError('end_date') }
                    label={ t.end_date }
                    colon={false}
                    required
                  >
                    <DatePicker
                      format={DATE_ONLY}
                      name="end_date"
                      placeholder={t.end_date}
                      value={formatDateMoment(getValue('end_date'))}
                      onChange={(value) => handleChangeDate('end_date', value)}
                      className="EqualityPlanDiagnosis-form-datepicker"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Form.Item
              hasFeedback
              validateStatus={ showError('size_of_company') ? 'error' : '' }
              help={ showError('size_of_company') }
              label={ t.equality_diagnosis_size_of_company }
              colon={false}
            >
              <Input.TextArea
                placeholder={ t.equality_diagnosis_size_of_company }
                autoSize={{ minRows: 2 }}
                name='size_of_company'
                value={ getValue('size_of_company') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Form.Item
              hasFeedback
              validateStatus={ showError('composition_of_template') ? 'error' : '' }
              help={ showError('composition_of_template') }
              label={ t.equality_diagnosis_composition }
              colon={false}
            >
              <Input.TextArea
                placeholder={ t.equality_diagnosis_composition }
                autoSize={{ minRows: 2 }}
                name='composition_of_template'
                value={ getValue('composition_of_template') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Form.Item
              hasFeedback
              validateStatus={ showError('activity_of_company') ? 'error' : '' }
              help={ showError('activity_of_company') }
              label={ t.equality_diagnosis_activity_of_company }
              colon={false}
            >
              <Input.TextArea
                placeholder={ t.equality_diagnosis_activity_of_company }
                autoSize={{ minRows: 2 }}
                name='activity_of_company'
                value={ getValue('activity_of_company') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Form.Item
              hasFeedback
              validateStatus={ showError('positioning_of_company') ? 'error' : '' }
              help={ showError('positioning_of_company') }
              label={ t.equality_diagnosis_positioning_of_company }
              colon={false}
            >
              <Input.TextArea
                placeholder={ t.equality_diagnosis_positioning_of_company }
                autoSize={{ minRows: 2 }}
                name='positioning_of_company'
                value={ getValue('positioning_of_company') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Form.Item
              hasFeedback
              validateStatus={ showError('other') ? 'error' : '' }
              help={ showError('other') }
              label={ t.equality_diagnosis_other }
              colon={false}
            >
              <Input.TextArea
                placeholder={ t.equality_diagnosis_other }
                autoSize={{ minRows: 2 }}
                name='other'
                value={ getValue('other') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <div className="form-input-card">
              <h4>{t.equality_plan_measures}</h4>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => setSelectedMeasure({})}
              ></Button>
            </div>
          </Col>
          { !!selectedMeasure && (
            <Col span={24}>
              <MeasuresForm
                measure={selectedMeasure}
                createMeasure={createMeasure}
                updateMeasure={updateMeasure}
                onClose={() => setSelectedMeasure()} />
            </Col>
          )}
          {measures.length > 0 && (
            <Col span={24} className="form-input-wrapper">
              {measures.map((measure, index) => {
                return <FormItemPreview
                  key={index}
                  sequence={index + 1}
                  onEdit={() => setSelectedMeasure(measure)}
                  onDelete={
                    canDeleteMeasure(measure)
                    ? () => handleOnDeleteMeasure(measure)
                    : null
                  }
                >
                  <div>{`${t.equality_plan_area}: ${t['equality_plan_area_' + measure.area]}`}</div>
                  <div>{`${t.equality_plan_measure}: ${measure.name}`}</div>
                </FormItemPreview>;
              })}
            </Col>
          )}
        </Row>
      </Form>
    </>
  )
};

export default withRouter(injectIntl(DiagnosisForm));
