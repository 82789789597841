import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import KpiDetail from 'containers/KpiDetail';
import DashboardCardFormWrapper from 'containers/DashboardCardForm/wrapper';
import Dashboard from '.';


const DashboardRoutes = ({
  intl,
  history: mainHistory,
  match: mainMatch,
}) => {

  return (
      <Switch>
        <Route exact path={`/dashboard`}>
          <Dashboard history={mainHistory} match={mainMatch} />
        </Route>
        <Route 
          path={`/dashboard/:activeTab/:activeCard/edit`} 
          render={({ match }) => (
            <DashboardCardFormWrapper match={match} />
          )} 
        />
        <Route 
          path={`/dashboard/:dashboard_slug/:slug/:period/:action?`} 
          render={({ match, history }) => (
            <KpiDetail
              urlBase={`dashboard/${match.params.dashboard_slug}`}
              history={history}
              match={match}
            />
          )} 
        />
        <Route path={`/dashboard/:activeTab/add-card`}>
            <DashboardCardFormWrapper match={mainMatch} />
        </Route>
        <Route>
          <Dashboard history={mainHistory} match={mainMatch} />
        </Route>
      </Switch>
  )
}

export default injectIntl(
  withRouter(
    DashboardRoutes
  )
);
