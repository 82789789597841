import { loop, Cmd } from 'redux-loop';

import {
  parseResponse,
  parseErrorBody,
} from 'utils';
import config from 'config';

const refresh = () => ({
  type: 'REFRESH_TOKEN_REQUEST',
});

const requestApiCall = async (access_token, { target, endpoint, method = 'GET', body, useNewApiUrl = false, ...rest }) => {
  try {
    const response = await fetch(`${useNewApiUrl ? config.API_URL_NEW : config.API_URL}/${endpoint}`, {
      method,
      headers: {
        'Content-type': (body ? 'application/json' : undefined),
        'Authorization': `Bearer ${access_token}`,
      },
      body: body ? JSON.stringify(body) : undefined,
    })

    const result = await parseResponse(response);

    return {
      target,
      endpoint,
      method,
      body,
      result,
      ...rest,
    };
  } catch(err) {
    const errorBody = await parseErrorBody(err);
    // eslint-disable-next-line no-throw-literal
    throw { ...err, errorBody, request: { target, endpoint, method, body, useNewApiUrl, ...rest } };
  }
};

const apiCallSuccess = (response) => ({
  type: 'API_CALL_COMPLETE',
  response,
});

const apiCallFail = ({ code, text, request, errorBody }) => ({
  type: 'API_CALL_MAYBE_FAILED',
  code,
  text,
  request,
  errorBody,
});

const apiQueueProcessSuccess = (response) => ({
  type: 'API_QUEUE_PROCESS_COMPLETE',
  response,
});

const apiQueueProcessFail = ({ code, text, request, errorBody }) => ({
  type: 'API_QUEUE_PROCESS_FAILED',
  code,
  text,
  request,
  errorBody,
});

const initialState = {
  access_token: false,
  ongoing_requests: 0,
  refreshing_tokens: false,
  error: false,
  queue: [],
  last_response: '',
};

const reducer = (state = initialState, action) => {
  let nextState; // TODO: move each 'case' to a function

  switch(action.type) {
    case 'REFRESH_TOKEN_REQUEST':
      // We leave this here in case another part of the code starts a refresh
      return {
        ...state,
        refreshing_tokens: true,
      };
    case 'REFRESH_TOKEN_COMPLETE':
    case 'IDENTITY_COMPLETE':
      const { access_token } = action;
      nextState = {
        ...state,
        access_token,
        refreshing_tokens: false,
      };
      if(state.queue.length > 0) {
        // Something was queued while we were refreshing, process the queue
        return loop({
          ...nextState,
          queue: state.queue.slice(1),
        }, Cmd.run(requestApiCall, {
          successActionCreator: apiQueueProcessSuccess,
          failActionCreator: apiQueueProcessFail,
          args: [ action.access_token, state.queue[0] ],
        }));
      } else {
        return nextState;
      }
    case 'REFRESH_TOKEN_FAILED':
      return {
        ...state,
        access_token: null,
        refreshing_tokens: false,
        error: true,
      };
    case 'API_CALL_REQUEST':
      const {
        target,
        method,
        endpoint,
        body,
        ...rest
      } = action;

      const request = { target, method, endpoint, body, ...rest };

      nextState = {
        ...state,
        ongoing_requests: (state.ongoing_requests + 1),
      };

      if(state.refreshing_tokens) {
        // I cannot make the call now, so I'll queue it
        return {
          ...nextState,
          queue: state.queue.concat(request),
        }
      } else if(!state.access_token) {
        // We do not have an API token, so requests will not go through
        return loop({
          ...nextState,
          refreshing_tokens: true,
          queue: state.queue.concat(request),
        }, Cmd.action(refresh()));
      } else {
        return loop(nextState, Cmd.run(requestApiCall, {
          successActionCreator: apiCallSuccess,
          failActionCreator: apiCallFail,
          args: [ state.access_token, request ],
        }));
      }
    case 'API_CALL_COMPLETE':
      return {
        ...state,
        ongoing_requests: (state.ongoing_requests - 1),
        last_response: JSON.stringify(action.response),
      };
    case 'API_CALL_MAYBE_FAILED':
      if(action.code === 401) {
        // Let's try to refresh the token
        // TODO: Better check with action.text
        return loop({
          ...state,
          refreshing_tokens: true,
          queue: state.queue.concat(action.request),
        }, Cmd.action(refresh()));
      }
      return loop({ ...state, error: true }, Cmd.action({ ...action, type: 'API_CALL_FAILED' }));
    case 'API_QUEUE_PROCESS_COMPLETE':
      if(state.queue.length > 0) {
        // Something else was queued while we were refreshing, process the queue
        return loop({
          ...state,
          queue: state.queue.slice(1),
        }, Cmd.list([
          Cmd.action({ ...action, type: 'API_CALL_COMPLETE' }),
          Cmd.run(requestApiCall, {
            successActionCreator: apiQueueProcessSuccess,
            failActionCreator: apiQueueProcessFail,
            args: [ state.access_token, state.queue[0] ],
          })
        ]));
      } else {
        return loop(state, Cmd.action({ ...action, type: 'API_CALL_COMPLETE' }));
      }
    case 'API_QUEUE_PROCESS_FAILED':
      return loop(state, Cmd.action({ ...action, type: 'API_CALL_FAILED' }));
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as api_requests,
};
