const _validateSchema = require('./validate_schema');

const validateSchema = (_schema) => {
  let schema = _schema;
  if(typeof _schema === 'string') {
    try {
      schema = JSON.parse(_schema);
    } catch(err) {
      return false;
    }
  }

  return _validateSchema(schema);
}

module.exports = {
  validateSchema,
};
