import React, {
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import { formatNumberWithIntl } from 'utils/formatNumber';
import { cartesian } from 'utils/cartesian';
import { deepGet } from 'utils/functional';
import useOrganizations from 'utils/useOrganizations';
import { getNumberFormatOptions } from 'utils/kpi';
import { isEmpty, merge } from 'lodash';
import CustomTable from 'components/CustomTable';
import CustomTag from 'components/CustomTag';
import Description from 'components/Description';

import {
  Col,
  Row,
} from 'antd';
import { convertToUnit } from 'utils/units';
import { useSchemaUnits } from 'utils/useSchemaUnits';


const translateSlug = (labels = {}) => (slug) => (labels || {})[slug] || slug;

const TAG_COLORS = [
  {color: '#117065', background: '#DBEAE8'},
  {color: '#112C70', background: '#DBDFEA'},
  {color: '#1CA131', background: '#DDF1E0'},
  {color: '#178FA9', background: '#DCEEF2'},
  {color: '#D38106', background: '#F8ECDA'},
  {color: '#DAAA00', background: '#F9F2D9'},
  {color: '#C13838', background: '#F6E1E1'},
  {color: '#2F7011', background: '#E0EADB'},
  {color: '#705511', background: '#EAE6DB'},
  {color: '#701111', background: '#EADBDB'},
  {color: '#1E1170', background: '#DEDBEA'},
  {color: '#5A6462', background: '#EBEAEA'},
];

const getTagColorByIndex = (i=0) => {
  // Cycle through the colours
  let color = TAG_COLORS[i];
  while(!color) {
    color = getTagColorByIndex(i - TAG_COLORS.length);
  }
  return color;
}

const KpiValue = ({
  value,
  type,
  availableUnits,
  intl,
  schema,
  config,
}) => {
  switch(type) {
    case 'quantitative':
      const unitObject = (Array.isArray(availableUnits) ? availableUnits : []).find(
        ({ slug }) => slug === value.unit
      ) || {};
      // NOTICE: This should have used the Answer component
      const formattedValue = (!value || value.value === null)
        ? '-'
        : formatNumberWithIntl(intl)(value.value, getNumberFormatOptions(schema, config))
      return `${formattedValue || '-'}${unitObject.symbol ? ` ${unitObject.symbol}` : ''}`;
    case 'qualitative':
      return <Description
        description={value.text || ''}
      />;
    case 'boolean':
      return intl.formatMessage({
        id: (
          value.boolean === true ? 'yes'
          : (value.boolean === false ? 'no' : ' ')
        )
      });
    default:
      return '-';
  };
};


const SubOrgTable = ({
  intl,
  schema,
  schemaLabels: rootSchemaLabels,
  kpi_name,
  subOrgValues,
  source
}) => {

  const {
    suborganization,
  } = useOrganizations();

  const schemaLabels = useMemo(() => {
    const subOrgSchemaLabels = subOrgValues.map(
      subOrgValue => subOrgValue.schemaLabels
    );
    return merge(...subOrgSchemaLabels, rootSchemaLabels);
  }, [
    subOrgValues,
    rootSchemaLabels,
  ]);

  const { availableUnits } = useSchemaUnits(schema);
  const isHeterogeneousTable = schema && schema.innerSchema && schema.innerSchema.type === 'tuple';
  
  const tableDimensions = useMemo(() => {
    // Combine all table dimensions of all suborg's
    return subOrgValues.reduce((acc, val) => {
      let tableDimensions = {};
      Object.entries(val.tableDimensions).forEach(([variable, values]) => {
        tableDimensions[variable] = [
          ...new Set([
            ...(acc?.[variable] || []),
            ...(values || [])
          ])
        ]
      });
      return {...acc, ...tableDimensions};
    }, {});
  }, [
    subOrgValues,
  ]);

  const columnCellData = useMemo(() => {
    if (!['table', 'tuple'].includes(schema.type)) {
      return [];
    }
    const hasTuple = schema.innerSchema?.type === 'tuple';

    const dimensionRows = schema.dimensions?.filter(
      ({presentation}) => presentation === 'row'
    ) || [];
    const dimensionColumn = schema.dimensions?.find(
      ({presentation}) => presentation === 'column'
    ) || {};
    const isSingleton = dimensionColumn.source === 'singleton';
    const isExtensibleTable = ((schema.dimensions || [])[0] || {}).source === 'user';
    let standardItems = [];

    if (isExtensibleTable) {
      standardItems = subOrgValues.map(
        subOrgValue => Object.keys(subOrgValue.kpi_value || {})
      ).filter(item => item.length);
    } else {
      for (const dimensionRow of dimensionRows) {
        const item = dimensionRow.source === 'organization'
          ? tableDimensions[dimensionRow.by] || [dimensionColumn.by]
          : (dimensionRow.standardItems || []).map(item => item.slug);
        standardItems.push(item);
      }
    }
    const adaptedStandardItems = standardItems.length
      ? cartesian(...standardItems)
      : [];

    let columns = [];
    let _columnCellData = [];

    if(isSingleton) {
      columns = [ dimensionColumn.by ];
    } else if (hasTuple) {
      columns = schema.innerSchema.components.map(component => component.name);
    } else {
      columns = tableDimensions[dimensionColumn.by] || Object.keys(schemaLabels.dimensionValues[dimensionColumn.by]);
    }

    if (hasTuple) {
      for (const standardItem of adaptedStandardItems) {
        let componentInx = 0;
        for (const component of schema.innerSchema.components) {
          const rowLabels = standardItem.map((item, index) => {
            return translateSlug(((schemaLabels || {}).dimensionValues || {})[dimensionRows[index].by])(item);
          });
          const colLabel = ((schemaLabels?.innerSchema || {}).componentLabels || {})[component.name] || component.name;
          _columnCellData.push({
            schema: component,
            labels: [...rowLabels, colLabel],
            address: [...standardItem, component.name],
            type: component.type,
            availableUnits: availableUnits[componentInx]
          });
          componentInx++;
        }
      }
    } else {
      for (const standardItem of adaptedStandardItems) {
        for (const column of columns) {
          const rowLabels = standardItem.map((item, index) => {
            return translateSlug(((schemaLabels || {}).dimensionValues || {})[dimensionRows[index].by] )(item);
          });
          const colLabel = (schemaLabels?.dimensionNames || {})[column] || ((schemaLabels?.dimensionValues || {})[dimensionColumn.by] || {})[column] || column;
          _columnCellData.push({
            schema: schema.innerSchema,
            labels: [...rowLabels, colLabel],
            address: [...standardItem, column],
            type: schema.innerSchema.type,
            availableUnits
          });
        }
      }
    }

    return _columnCellData;
  }, [
    schema,
    schemaLabels,
    tableDimensions,
    subOrgValues,
    availableUnits
  ]);

  const tableColumns = useMemo(() => {
    let columns = [];
    if (schema.type === 'table') {
      columns = columnCellData.map(cellData => ({
        title: cellData.labels.map((label, index) => <CustomTag name={label} style={getTagColorByIndex(index)} />),
        dataIndex: cellData.address.join('__'),
        render: (value, record) => {
          return (
            record.applies
            ? <KpiValue
                value={value}
                type={value?.type}
                availableUnits={availableUnits}
                intl={intl}
                schema={value.schema}
                config={suborganization?.product_config?.atlas || {}}
              />
            : 'N/A'
        )}
      }));
    } else {
      columns = [{
        title: <CustomTag name={kpi_name } colorclass="green" />,
        dataIndex: 'value',
        render: (value, record) => {
          return (
            record.applies
            ? <KpiValue
                value={value || {}}
                type={schema.type}
                availableUnits={availableUnits}
                intl={intl}
                schema={schema}
                config={suborganization?.product_config?.atlas || {}}
              />
            : 'N/A'
        )}
      }];
    }

    return [
      {
        title: '',
        dataIndex: 'org',
        fixed: 'left',
      },
      ...columns
    ];
  }, [
    intl,
    schema,
    kpi_name,
    availableUnits,
    columnCellData,
    suborganization,
  ]);

  const dataSource = useMemo(() => (
    subOrgValues.map(suborgValue => {
      if(schema.type === 'table') {
        return {
          ...(suborgValue.kpi_value || {}),
          org: suborgValue.name,
          applies: suborgValue.applies,
          ...Object.fromEntries(
            columnCellData.map(cellData => {

              let cellValue = deepGet(suborgValue.kpi_value, [...cellData.address]);
              
              if (
                !isEmpty(cellValue) && 
                isHeterogeneousTable && 
                source === 'aggregated' && 
                cellData.schema.type === 'quantitative' && 
                cellData.schema.allowedUnitSlugs.length > 0 && 
                !cellData.schema.allowedUnitSlugs.includes(cellValue.unit)
              ){
                
                //Convert from base unit to selected unit
                const targetUnit = (cellData.availableUnits || []).find(unit => unit.slug === cellData.schema.allowedUnitSlugs[0]);
                if (targetUnit){
                  cellValue = convertToUnit(targetUnit.slug, cellData.availableUnits)(cellValue);
                }
              }

              return [
                cellData.address.join('__'),
                {
                  ...cellValue,
                  type: cellData.type,
                  schema: cellData.schema,
                }
              ];
            })
          )
        };
      }

      return {
        org: suborgValue.name,
        value: suborgValue.kpi_value,
        applies: suborgValue.applies,
      };
    })), [
      subOrgValues,
      schema,
      columnCellData,
      isHeterogeneousTable,
      source
  ]);


  return (
    <Row className="SubOrgTable">
      <Col span={24}>
        <CustomTable
          columns={tableColumns}
          dataSource={dataSource}
        />
      </Col>
      <Col span={24} className="SubOrgTable__reference">
        <h4>{intl.formatMessage({id: 'data_suborg_table_reference'})}</h4>
        <div>N/A: {intl.formatMessage({id: 'data_suborg_table_reference_does_no_apply'})}</div>
        <div>" - ": {intl.formatMessage({id: 'data_suborg_table_reference_no_value'})}</div>
      </Col>
    </Row>
  );
};

export default injectIntl(SubOrgTable);
