import { getUnits } from "utils/useAvailableUnits";

const TARGET = 'bulk_manage_kpis';
const KPI_TARGET = 'data_kpi';
const ATTACHMENT_TARGET = 'kpi_attachment';

const isTarget = (target) => [
  TARGET,
  KPI_TARGET,
  ATTACHMENT_TARGET,
].includes(target);

const initialState = {
  error: null,
  loading: false,
  pushing: false,
  currentPage: 1,
  limit: 100,
  nextPage: 2,
  previousPage: null,
  total: null,
  data: [],
  pasteErrors: [],
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        loading: action.method === 'GET',
        pushing: action.method === 'PUT' || action.method === 'POST',
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if (!response || !isTarget(response.target)) return state;

      if (response.target === KPI_TARGET) {
        if (response.method === 'PUT') {
          return {
            ...state,
            data: [
              ...state.data.map(kpi => {
                if (kpi.id === parseInt(response.result.kpi_value_id)) {
                  let updatedKpi = {
                    ...kpi,
                    kpi_value: response.result.kpi_value,
                    comment: response.result.comment,
                  };
                  switch(response.query?.fieldToUpdate) {
                    case 'value':
                      updatedKpi.pendingValueUpdate = false;
                      break;
                    case 'unit':
                      updatedKpi.pendingUnitUpdate = false;
                      break;
                    case 'comment':
                      updatedKpi.pendingCommentUpdate = false;
                      break;
                    default:
                      break;
                  };
                  return updatedKpi;
                }
                return kpi;
              }),
            ],
          };
        }
        return state;
      }

      if(
        response.target === ATTACHMENT_TARGET &&
        state.data &&
        action.response.method === 'DELETE'
      ) {
        const result = action.response.result;
        const index = state.data.findIndex(
          item => (
            (item.organization_id === result.organization_id) &&
            (item.kpi_id === result.kpi_id) &&
            (item.period === result.period)
          )
        );
        const dataItem = state.data[index];

        if(!dataItem) {
          return {
            ...state,
            loading: false,
            error: null,
          }
        }

        dataItem.attachments = dataItem.attachments.filter(item => item.id !== result.id);

        return {
          ...state,
          data: [
            ...state.data.slice(0, index),
            {...dataItem},
            ...state.data.slice(index + 1)
          ],
          loading: false,
          error: null,
        }
      }

      const result = response.result;
      switch(response.method) {
        case 'GET':
          return {
            ...state,
            loading: false,
            ...result,
          };
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        loading: false,
        pushing: false,
        error: action.code,
      };
    case 'ADD_KPI_ATTACHMENT':
      if(state.data) {
        const result = action.attachment;
        const index = state.data.findIndex(
          item => (
            (item.organization_id === result.organization_id) &&
            (item.kpi_id === result.kpi_id) &&
            (item.period === result.period)
          )
        );
        const dataItem = state.data[index];

        if(!dataItem) {
          return {
            ...state,
            loading: false,
            error: null,
          }
        }

        dataItem.attachments = [
          ...dataItem.attachments,
          result,
        ];

        return {
          ...state,
          data: [
            ...state.data.slice(0, index),
            {...dataItem},
            ...state.data.slice(index + 1)
          ],
          loading: false,
          error: null,
        }
      }
      return {
        ...state,
        loading: false,
        error: null,
      }
    case 'BULK_MANAGE_CLIPBOARD_RESET_ERRORS':
      return {
        ...state,
        pasteErrors: [],
      };
    case 'BULK_MANAGE_CLIPBOARD_UPDATE':
      const {
        position,
        clipboardData,
        cellType,
        preferredUnits,
        metrics,
        intl,
      } = action.data;

      let newData = [ ...state.data ];
      let pasteErrors = [];
      const booleans = {
        truthy: [
          1, '1', intl.formatMessage({id: 'yes'}).toLowerCase(),
        ],
        falsy: [
          0, '0', intl.formatMessage({id: 'no'}).toLowerCase(),
        ],
      };

      for(let i = position; i < (clipboardData.length + position); i++) {
        let kpi = newData[i];
        const currentData = clipboardData[i - position];

        if (
          !kpi
          || kpi.kpi_source !== 'manual'
          || kpi.ready_to_validate_level > 0
        ) {
          continue;
        }

        if(cellType === 'value') {
          switch(kpi.schema.type) {
            case 'quantitative':
              const value = currentData ? Number(currentData) : NaN;
              if(isNaN(value)) {
                pasteErrors.push({
                  kpiName: kpi.name,
                  period: kpi.period,
                  value: currentData,
                  type: 'kpi_value',
                });
                break;
              }

              const forceDecimal = kpi.config.hasOwnProperty(kpi.schema.metricSlug)
                ? kpi.config[kpi.schema.metricSlug]
                : kpi.config['*']

              const decimalPoints = typeof forceDecimal === 'number'
                ? forceDecimal
                : (kpi.schema.decimalPoints || 0);

              kpi.kpi_value = {
                ...(kpi.kpi_value || {}),
                value: parseFloat(value).toFixed(decimalPoints),
              };
              kpi.pendingValueUpdate = true;
              break;
            case 'qualitative':
              kpi.kpi_value = {
                ...(kpi.kpi_value || {}),
                text: currentData,
              };
              kpi.pendingValueUpdate = true;
              break;
            case 'boolean':
              kpi.kpi_value = {
                ...(kpi.kpi_value || {}),
                boolean: booleans.falsy.includes(currentData.toLowerCase()) ? false
                : booleans.truthy.includes(currentData.toLowerCase()) ? true : null,
              };
              kpi.pendingValueUpdate = true;
              break;
            default:
              break;
          };
        } else if(cellType === 'comment') {
          kpi.comment = currentData;
          kpi.pendingCommentUpdate = true;
        } else if(cellType === 'unit') {
          if(kpi.schema.type !== 'quantitative') {
            continue;
          }

          const units = getUnits(metrics, preferredUnits)(kpi.schema);
          const unit = units.find(
            ({symbol, name, slug}) => {
              const currentString = currentData.toLowerCase();
              return (
                symbol.toLowerCase() === currentString
              ) || (
                name.toLowerCase() === currentString
              ) || (
                slug.toLowerCase() === currentString
              ) || (
                `${name} (${symbol})`.toLowerCase() === currentString
              );
            }
          );

          if (unit) {
            kpi.kpi_value = {
              ...(kpi.kpi_value || {}),
              unit: unit.slug,
            };
            kpi.pendingUnitUpdate = true;
          } else {
            pasteErrors.push({
              kpiName: kpi.name,
              period: kpi.period,
              value: currentData,
              type: 'unit',
            });
          }
        }
      }

      return {
        ...state,
        data: newData,
        pasteErrors,
      };
    default:
      return state;
  }
};

export {
  reducer as bulk_manage_kpis,
};
