import {
  useReducer,
  useCallback,
  useMemo,
} from 'react';

import {
  deepUpdate,
  deepGet,
} from './functional';

import {
  recalculateAllColumns,
  recalculateColumn,
  getCalculatedColumns,
} from './kpi_formula/column';
import { useTableUnits } from './useTableUnits';

const NO_DEPENDEES = [];

export const adaptInitialState = (props, _initialState) => {
  const {
    schema,
    slug,
    tableDimensions,
    organization,
    organization_parent,
    period,
    dependees = NO_DEPENDEES,
    readOnly = false,
    availableUnits = [],
  } = props || {};

  // This takes an (optional) existing initialState and adapts it to the state used
  // by the TableAnswer components. It sets the schema and adds a '_address' prop
  // that comes in handy when updating the fields later. It is a surprisingly-fast
  // one-pass implementation that's only possible since we ABUSE JavaScript references
  // At the end we will also update calculated columns once...

  let result = {}; // The final result, only modified by references
  let targets = [
    [ [], result ] // What I will modify in the next pass. Initial target = the root {}
  ];
  let nextTargets; // Buffer to record targets for the next iteration

  const initialState = _initialState || {};

  const {
    dimensions = [],
    innerSchema,
  } = schema;

  const isHeterogeneous = innerSchema.type === 'tuple';
  const hasCalculatedColumns = (getCalculatedColumns(schema, slug) || []).length > 0;
  const maxDepth = dimensions.length - 1;

  dimensions.forEach(({
    source,
    by,
    presentation,
    calculations = [],
    standardItems = [],
  }, depth) => {
    nextTargets = [];
    targets.forEach(([address, target]) => {
      let items = [];

      switch(source) {
        case 'organization':
          items = (
            tableDimensions[by] || []
          );
          break;
        case 'singleton':
          items = [by];
          break;
        case 'standard':
          items = standardItems.map(({ slug }) => slug); // TODO: translate
          break;
        case 'user':
          items = standardItems.map(({ slug }) => slug); // TODO: translate
          items = (depth === 0)
            ? items
                .concat(
                  Object.keys(initialState)
                    .filter(slug => !items.includes(slug))
                    .sort(
                      (a, b) => (initialState[a].order || 1)
                        - (initialState[b].order || 1)
                    )
                )
                .filter(Boolean)
            : []
          break;
        default:
          items = (depth === 0)
            ? Object.keys(initialState)
            : []
      }

      items.forEach(entry => {
        const nextAddress = address.concat(entry);
        target[entry] = {};

        if(!isHeterogeneous && depth === maxDepth) {
          target[entry] = {
            ...(
              deepGet(initialState, nextAddress)
            ),
            _address: nextAddress,
          };
        }

        nextTargets.push([nextAddress, target[entry]]);
      });
    });
    targets = nextTargets;
  });

  if(isHeterogeneous) {
    // Final round to look inside the tuple
    const components = innerSchema.components || {};
    targets.forEach(([address, target]) => {
      components.forEach(({ name: entry }) => {
        const nextAddress = address.concat(entry);
        target[entry] = {
          ...(
            deepGet(initialState, nextAddress)
          ),
          _address: nextAddress,
        };
      });
    });
  }

  if(hasCalculatedColumns && !readOnly) {
    result = recalculateAllColumns({
      schema,
      slug,
      value: result,
      tableDimensions,
      organization,
      organization_parent,
      period,
      dependees,
      availableUnits,
    });
  }

  return result;
};

export const getReducer = ({
  schema,
  slug,
  tableDimensions,
  organization,
  organization_parent,
  period,
  dependees = [],
}) => {
  const calculatedColumns = getCalculatedColumns(schema, slug);

  const targettingLeafValue = (address) => {
    return address.length > (schema.dimensions || []).length;
  };

  return (state, action) => {
    let updatedState = state;
    const {
      address,
    } = action;

    switch(action.type) {
      case 'UPDATE':
        updatedState = deepUpdate(state, address, action.value)
        break;
      case 'DELETE':
        updatedState = deepUpdate(state, address, undefined)
        break;
      default:
        return state;
    }

    if(calculatedColumns.length > 0 && targettingLeafValue(address)) {
      calculatedColumns.forEach(column => {
        updatedState = recalculateColumn({
          schema,
          slug,
          tableDimensions,
          organization,
          organization_parent,
          period,
          column,
          address,
          dependees,
        })(updatedState); // TODO: Context or values to recalculate?
      });
    }

    return updatedState;
  }
};

export const useTableState = ({
  schema,
  tableDimensions,
  initialState: _initialState,

  // These are used for column formulas only
  slug,
  organization,
  organization_parent,
  period,
  dependees = NO_DEPENDEES,
  sourceData = {},
  readOnly = false,
}) => {
  const { availableUnits } = useTableUnits(schema);
  const initialState = useMemo(
    () => adaptInitialState({
      schema,
      tableDimensions,
      slug,
      organization,
      organization_parent,
      period,
      dependees,
      sourceData,
      readOnly,
      availableUnits,
    }, _initialState),
    [
      schema,
      tableDimensions,
      slug,
      organization,
      organization_parent,
      period,
      dependees,
      sourceData,
      _initialState,
      readOnly,
      availableUnits,
    ]
  );

  const reducer = getReducer({
    schema,
    tableDimensions,
    slug,
    organization,
    organization_parent,
    period,
    dependees,
  });
  const [state, dispatch] = useReducer(reducer, initialState);
  const update = useCallback(
    (address, value) => dispatch({ type: 'UPDATE', address, value }),
    [ dispatch ]
  );
  const _delete = useCallback(
    (address) => dispatch({ type: 'DELETE', address }),
    [ dispatch ]
  );

  return {
    update,
    delete: _delete,
    values: state,
  };
};

