import React, { useCallback } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import CustomButton from 'components/CustomButton';

import {
  Row,
} from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';

const EqualityPlanHeader = ({
  intl,
  history,
  planId,
  title,
  tabs,
  currentTab,
  changeTab,
  handleBackOrSkip,
}) => {
  const t = intl.messages;

  const handleGoBack = useCallback(
    () => {
      if (handleBackOrSkip) {
        handleBackOrSkip('back');
      } else {
        history.push(`/equality-plan/${planId}/`);
      }
    },
    [handleBackOrSkip, history, planId]
  );

  return (
    <Row className="EqualityPlanHeader" type="flex" align="middle">
      <CustomButton
        type="primary"
        icon={<ArrowLeftOutlined />}
        shape="round"
        onClick={handleGoBack}
      >
        { t.back }
      </CustomButton>
      <span className="EqualityPlanHeader-title">
        { title }
      </span>
      { tabs && tabs.length > 0
        ? <div className="EqualityPlanHeader-tabs">
            {tabs.map(
              tab => (
                <span
                  key={tab.key}
                  className={`EqualityPlanHeader-tab${currentTab === tab.key ? ' EqualityPlanHeader-tab-active' : ''}`}
                  onClick={() => changeTab(tab.key)}
                >
                  { tab.title }
                </span>
              )
            )}
          </div>
        : null
      }
    </Row>
  )
}

export default withRouter(injectIntl(EqualityPlanHeader));
