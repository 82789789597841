import React from 'react';
import { injectIntl } from 'react-intl';

import CustomIcon from 'components/CustomIcon';

const WarningModalContent = ({
  intl,
  warningType,
  kpiSources = [],
  isPartialValue = false,
}) => {
  return (
    <div className="WarningModalContent">
      <div className="WarningModalContent__message">
        {intl.formatMessage(
          {
            id: `data_validation_warning_${warningType}_message`
          },
          {
            source: (kpiSources || [])
              .map(kpiSource => (<b style={{color: '#D38106'}}>{intl.formatMessage({id: `kpi_source_${kpiSource}`})}</b>))
              .reduce((acc, x) => {
                // Poor person's JSX join: https://stackoverflow.com/a/44738250
                if(acc === null) {
                  return x
                }
                return <React.Fragment>{acc}, {x}</React.Fragment>
              }, null)
          }
        )}
      </div>
      <div className="WarningModalContent__message">
        {intl.formatMessage(
          { id: `data_validation_warning_${warningType}_message2` }
        )}
      </div>
      {
        !isPartialValue ? null : (
          <div className="WarningModalContent__message">
            <CustomIcon
              className='CustomWarningModal__bodyicon'
              icon={['fa', 'exclamation-triangle']}
            />
            <b style={{color: '#D38106'}}>
              { intl.formatMessage({ id: `data_validation_warning_incomplete` }) }
            </b>
          </div>
        )
      }
      <div className="WarningModalContent__question">
        {intl.formatMessage({id: `data_validation_warning_${warningType}_question`})}
      </div>
    </div>
  );
};

export default injectIntl(WarningModalContent);
