import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import useOrganizations from 'utils/useOrganizations';
import { useAvailableUnits } from 'utils/useAvailableUnits';

import {
  formulaToTex,
} from 'utils/kpi_formula/tex';

import validateFormula from 'utils/kpi_formula/validator';

import {
  updateKpiSettings,
} from 'actions/api';

import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import CustomRadio from 'components/CustomRadio';
import CustomSelect from 'components/CustomSelect';
import CustomModal from 'components/CustomModal';
import KpiFormula from 'components/KpiFormula';

import { Button, Modal } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import './style.less';
import OrganizationTargets from '../OrganizationTargets';
import { useHasChildren } from '../../hooks/organization';

const NO_ERRORS = {};

const AGGREGATIONS_REQUIRING_UNITS = [
  //'one',
  //'concat',
  //'sum',
  //'average',
  //'table_sum',
  //'table_average',
  'sum_unit',
  'average_unit',
  'table_sum_unit',
  'table_average_unit',
];

const schemaRequiresUnit = (schema) => {
  return schema &&
    schema.type === 'quantitative' &&
    (schema.allowedUnitSlugs || []).length > 0
};

const recalculateAggregation = (initialAggregation, schema) => {

  const isTable = schema.type === 'table';
  const isHeterogeneousTable = isTable && schema.innerSchema.type === 'tuple';

  if (isTable){
    
    if (isHeterogeneousTable){
      
      switch(initialAggregation){
        case 'table_sum_unit': return 'table_sum';
        case 'table_average_unit': return 'table_average';
        default: break;
      }

    } else {

      switch(initialAggregation){
        case 'table_sum': return 'table_sum_unit';
        case 'table_average': return 'table_average_unit';
        default: break;
      }

    }
    
  } 

  return initialAggregation;

}

const Formula = injectIntl(({
  intl,
  formula,
  isSystem = false,
}) => {
  const t = intl.messages;
  const tex = useMemo(() => {
    const {
      tex,
    } = formulaToTex(
      formula,
      true,
    );
    return tex;
  }, [
    formula,
  ]);

  return (
    <div
      className="ModalCalculatedKpi__formula"
    >
      <KpiFormula
        tex={tex}
      />
      {
        !isSystem
        ? null
        : (
          <Button
            style={{
              margin: '10px 0 0 5px',
            }}
            type="ghost"
            shape="circle"
            icon={<EllipsisOutlined />}
            onClick={() => {
              Modal.info({
                title: t.modalcalculatedkpi_submodal_title,
                content: (
                  <div>
                    { formula }
                  </div>
                ),
              });
            }}
          />
        )
      }
    </div>
  );
});

const ModalCalculatedKpi = ({
  intl,
  visible,
  onClose,
  loading = false,
  error,
  kpi_slugs = [],
  can_override = false,
  schema,
  source: initialSource,
  formula: initialFormula,
  aggregation: initialAggregation,
  unit: initialUnit,
  allFormulas = [],
  allAggregations = [],
  canBeChildren = true,
  canBeAggregated = true,
  canBeParent = true,
  canBeCalculated = true,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();

  const recalculatedAggregation = useMemo(() => recalculateAggregation(initialAggregation, schema), [initialAggregation, schema]);
  const [dirty, setDirty] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [source, setSource] = useState(initialSource);
  const [formula, setFormula] = useState(initialFormula);
  const [formulaErrors, setFormulaErrors] = useState(NO_ERRORS); // Default to no errors
  const [aggregation, setAggregation] = useState(recalculatedAggregation);
  const [unit, setUnit] = useState(initialUnit);
  const [target, setTarget] = useState('self');

  const {
    organization,
    suborganization,
    organizationTree,
  } = useOrganizations();

  const hasChildren = useHasChildren(suborganization, organizationTree);
  const maybeAvailableUnits = useAvailableUnits(schema);
  const availableUnits = useMemo(() => maybeAvailableUnits || [], [maybeAvailableUnits]);

  const handleSourceChange = useCallback((e) => {
    setDirty(true);
    setSource(e.target.value);
    switch(e.target.value) {
      case 'aggregated':
        setFormula(null);
        setFormulaErrors(NO_ERRORS);
        setAggregation(
          initialAggregation || allAggregations[0]
        );
        setUnit(
          initialUnit || availableUnits[0]?.slug
        );
        break;
      case 'calculated':
        setFormula(
          initialFormula || (allFormulas || [])[0]
        );
        setFormulaErrors(NO_ERRORS);
        setAggregation(null);
        setUnit(
          schemaRequiresUnit(schema)
          ? ( initialUnit || availableUnits[0]?.slug )
          : null
        );
        break;
      case 'parent':
      case 'children':
      case 'manual':
      default:
        setFormula(null);
        setFormulaErrors(NO_ERRORS);
        setAggregation(null);
        setUnit(null);
        break;
    }
  }, [
    allFormulas,
    initialFormula,
    allAggregations,
    initialAggregation,
    availableUnits,
    initialUnit,
    schema,
  ]);

  const handleAggregationChange = useCallback((e) => {
    const agg = e.target.value;
    setDirty(true);
    setAggregation(agg);
    if(AGGREGATIONS_REQUIRING_UNITS.includes(agg)) {
      setUnit(
        initialUnit || availableUnits[0]?.slug
      );
    } else {
      setUnit(null);
    }
  }, [
    availableUnits,
    initialUnit,
  ]);

  const handleFormulaChange = useCallback((e) => {
    const formula = e.target.value;

    setDirty(true);
    setFormula(formula);
    setFormulaErrors(validateFormula(intl)({ formula }));
  }, [
    intl,
  ]);

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    dispatch(
      updateKpiSettings()({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        kpi_slugs,
        settings: {
          source,
          ...(
            source === 'calculated'
            ? {
              formula,
              ...(
                schemaRequiresUnit(schema)
                ? { formula_unit: unit }
                : {}
              ),
            } : {}
          ),
          ...(
            source === 'aggregated'
            ? {
              aggregation,
              ...(
                AGGREGATIONS_REQUIRING_UNITS.includes(aggregation)
                ? { aggregation_unit: unit }
                : {}
              ),
            }
            : {}
          ),
        },
        affect_old: true,
        target,
      })
    )
  }, [
    dispatch,
    organization,
    suborganization,
    kpi_slugs,
    source,
    formula,
    aggregation,
    unit,
    target,
    schema,
  ]);

  useEffect(() => {
    // Close the modal
    if(submitting && !loading && !error) {
      setSubmitting(false);
      setDirty(false);
      onClose();
    }
  }, [
    onClose,
    submitting,
    loading,
    error,
  ]);

  useEffect(() => {
    if(!visible && (
      source !== initialSource ||
      formula !== initialFormula ||
      dirty
    )) {
      setSource(initialSource);
      setFormula(initialFormula);
      setFormulaErrors(NO_ERRORS);
      setUnit(initialUnit);
      setDirty(false);
    }
  }, [
    visible,
    source,
    formula,
    dirty,
    initialSource,
    initialFormula,
    initialUnit,
  ]);

  useEffect(() => {
    if (visible){
      setDirty(recalculatedAggregation !== initialAggregation);
    }
  }, [visible, recalculatedAggregation, initialAggregation]);

  const {
    data: userProfile,
  } = useSelector(state => state.profile);

  const isSystem = (userProfile || {}).role === 'system'; // NOTICE: For now only system users can change formulas by hand

  const sourceOptions = useMemo(() => ([
    ...(canBeChildren ? [ 'children' ] : []),
    ...(canBeCalculated && isSystem ? [ 'calculated' ] : []),
    ...(canBeAggregated ? [ 'aggregated' ] : []),
    ...(canBeParent ? [ 'parent' ] : []),
    'manual',
  ]), [canBeParent, canBeAggregated, canBeCalculated, canBeChildren, isSystem]);

  const isOther = useMemo(() => {
    return !(allFormulas || []).includes(formula);
  }, [
    allFormulas,
    formula,
  ]);

  const hasFormulaErrors = useMemo(() => Object.keys(formulaErrors || NO_ERRORS).length > 0, [ formulaErrors ]);

  return (
    <CustomModal
      className="ModalCalculatedKpi"
      shown={visible}
      title={ t.modalcalculatedkpi_title }
      onOk={onSubmit}
      onCancel={onClose}
      width="40%"
      footer={
        <div className="ModalCalculatedKpi__footer">
          <CustomButton
            className="ModalCalculatedKpi__btn-footer"
            key="back"
            onClick={onClose}
          >
            { t.modalcalculatedkpi_cancel }
          </CustomButton>
          <CustomButton
            className="ModalCalculatedKpi__btn-footer"
            key="submit"
            type="primary"
            onClick={onSubmit}
          >
            { t.modalcalculatedkpi_save }
          </CustomButton>
        </div>
      }
    >
      <section className="ModalCalculatedKpi__firstsection">
        <div className="ModalCalculatedKpi__title">{ t.modalcalculatedkpi_source }</div>
        <CustomRadio.Group
          value={source}
          onChange={handleSourceChange}
          buttonStyle="solid"
        >
          {
            sourceOptions.map(source => (
              <CustomRadio.Button
                key={source}
                value={source}
              >
                { t[`kpi_source_${source}`] }
              </CustomRadio.Button>
            ))
          }
        </CustomRadio.Group>
        <div style={{ margin: '20px 10px' }}>
          { t[`modalcalculatedkpi_explain_${source}`] }
        </div>
      </section>
      {
        source !== 'aggregated'
        ? null
        : (
          <section
            className="ModalCalculatedKpi__aggregationsection"
          >
            <CustomRadio.Group
              value={aggregation}
              onChange={handleAggregationChange}
            >
              {
                allAggregations.map(agg => (
                  <CustomRadio
                    key={agg}
                    value={agg}
                    className="ModalCalculatedKpi__radio"
                  >
                    { t[`aggregation_${agg}`] }
                  </CustomRadio>
                ))
              }
            </CustomRadio.Group>
            {
              !AGGREGATIONS_REQUIRING_UNITS.includes(aggregation)
              ? null
              : (
                <section
                  className="ModalCalculatedKpi__unitsection"
                >
                  <CustomSelect
                    title={t.unit}
                    options={availableUnits}
                    onSelect={(unit) => setUnit(unit)}
                    selected={unit}
                  />
                </section>
              )
            }
          </section>
        )
      }
      {
        source !== 'calculated'
        ? null
        : (
          <section
            className="ModalCalculatedKpi__formulasection"
          >
            <section
              className="ModalCalculatedKpi__formulasubsection"
            >
              <CustomRadio.Group
                value={formula}
                onChange={handleFormulaChange}
              >
                {
                  (allFormulas || []).map(f => (
                    <CustomRadio
                      key={f}
                      value={f}
                      className="ModalCalculatedKpi__radio"
                    >
                      <Formula
                        formula={f}
                        isSystem={isSystem}
                      />
                    </CustomRadio>
                  ))
                }
                {
                  !isSystem
                  ? null
                  : (
                      <CustomRadio
                        value={isOther ? formula : ''}
                        className="ModalCalculatedKpi__radio"
                      >
                        { t.modalcalculatedkpi_other }
                        {
                          !isOther
                          ? null
                          : (
                            <>
                              { ':' }
                              { ' ' }
                              <CustomInput
                                status={hasFormulaErrors && 'error'}
                                placeholder={t.modalcalculatedkpi_other_placeholder}
                                value={formula}
                                onChange={handleFormulaChange}
                              />
                            </>
                          )
                        }
                      </CustomRadio>
                    )
                }
              </CustomRadio.Group>
              {
                !hasFormulaErrors
                ? <div>{ t.formula_ok }</div>
                : (
                  <section
                    className="ModalCalculatedKpi__formulaerrorsection"
                  >
                    <ul>
                      {
                        Object.values(formulaErrors).map(error => (
                          <li key={error}>{error}</li>
                        ))
                      }
                    </ul>
                  </section>
                )
              }
            </section>
            {
              !schemaRequiresUnit(schema)
              ? null
              : (
                <section
                  className="ModalCalculatedKpi__formulaunitsection"
                >
                  <div>
                    { t.modalcalculatedkpi_formula_unit }
                  </div>
                  <CustomSelect
                    title={t.unit}
                    options={availableUnits}
                    onSelect={(unit) => setUnit(unit)}
                    selected={unit}
                  />
                </section>
              )
            }
          </section>
        )
      }
      {
        (target !== 'self' || dirty ) && hasChildren &&
          <section>
            <div>
              { t.modalcalculatedkpi_apply_changes_to_target }
            </div>
            <OrganizationTargets
              intl={intl}
              showUnsafeTargets={false}
              selected={target}
              onSelect={value => setTarget(value)}
              hasChildren={hasChildren}
              />
          </section>
      }
    </CustomModal>
  )
};

export default injectIntl(ModalCalculatedKpi);
