const changeCurrentGroup = (group) => ({
  type: 'CHANGE_CURRENT_GROUP',
  group,
});

const startSurvey = (organization_slug, suborganization_slug, slug) => ({
  type: 'START_SURVEY',
  organization_slug,
  suborganization_slug,
  slug
});

const submitSurvey = (organization_slug, suborganization_slug, slug, body) => ({
  type: 'SUBMIT_SURVEY',
  organization_slug,
  suborganization_slug,
  slug,
  body
});

export {
  changeCurrentGroup,
  startSurvey,
  submitSurvey,
}
