import React, {
  useRef,
  useCallback,
  useEffect,
  useState
} from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import CustomInputSearch from 'components/CustomInputSearch';

import AplanetIcon from 'components/AplanetIcon';
//import useOutsideClick from 'utils/useOutsideClick';

import './style.less';

const SearchFilter = ({
  intl,
  textFilter,
  onSetSearch,
  placeholder,
  setHasToClearKpiSearch,
  hasToClearSearch = false,
  defaultOpen = false,
  autoFocus = false,
  disabled = false,
}) => {
  const ref = useRef();

  const [showSearchInput, setShowSearchInput] = useState(defaultOpen);
  const [searchText, setSearchText] = useState(textFilter);
  const [showPressEnterText, setShowPressEnterText] = useState(false);

  useEffect(() => {
    if (hasToClearSearch && textFilter === '' && searchText === '') {
      showSearchInput && setShowSearchInput(false);
      setHasToClearKpiSearch(false);
    }
  }, [
    hasToClearSearch,
    searchText,
    setHasToClearKpiSearch,
    showSearchInput,
    textFilter
  ]);

  const onClearSearch = useCallback(() => {
    setSearchText('');
    onSetSearch('');
    setShowSearchInput(false);
    setShowPressEnterText(true);
  }, [onSetSearch]);

  // const handleClickOutside = useCallback(() => {
  //   if (!searchText) {
  //     onClearSearch();
  //   }
  // }, [
  //   onClearSearch,
  //   searchText
  // ]);

  //useOutsideClick(ref, handleClickOutside);

  const handleChangeSearch = useCallback((inputText) => {
    if (inputText === ''){
      onSetSearch('');
    }
    setSearchText(inputText);
    setShowPressEnterText(true);
  }, [onSetSearch]);

  const onPressEnterToSetSearch = useCallback((e) => {
    if (showPressEnterText && searchText) {
      setSearchText(e.target.value);
      onSetSearch(e.target.value);
      setShowPressEnterText(false);
    }
  }, [
    onSetSearch,
    searchText,
    showPressEnterText
  ]);

  return (
    <div className="SearchFilter" ref={ref}>
      { showSearchInput && !disabled ? (
        <CustomInputSearch
          value={textFilter}
          onChange={(inputText) => handleChangeSearch(inputText.trim())}
          placeholder={placeholder}
          hasToClearSearch={hasToClearSearch}
          onPressEnter={onPressEnterToSetSearch}
          onEscPress={onClearSearch}
          autoFocus={autoFocus}
          debounceMs={50}
          suffixIcon={searchText ? (
            <>
              {showPressEnterText ? (<span className="SearchFilter__suffix-text">{intl.formatMessage({id: `search_filter_suffix_text`})}</span>) : null}
              <AplanetIcon
                className="SearchFilter__close-search"
                name="Cancel"
                onClick={onClearSearch}
                size="16px"
              />
            </>
          ) : null}
        />
      ) : (
        <button
          className="SearchFilter__search-button"
          onClick={() => setShowSearchInput(!showSearchInput)}
          disabled={disabled}
        >
          <AplanetIcon name="Search" size="18px" />
          <span>{intl.formatMessage({ id: "search_filter_button_title"})}</span>
        </button>
      )}
    </div>
  );
}

SearchFilter.propTypes = {
  textFilter: PropTypes.string,
  onSetSearch: PropTypes.func.isRequired,
  setHasToClearKpiSearch: PropTypes.func.isRequired,
  hasToClearSearch: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  defaultOpen: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default injectIntl(SearchFilter);
