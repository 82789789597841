import {
  useMemo,
} from 'react';

import { fromDb } from 'utils/date';

const DEFAULT_USER_APPROVAL_LEVEL = 2047;

export const canValidateWithLevel = (
  kpiLevel,
  userLevel,
) => (
  !!(kpiLevel & userLevel)
);

export const backendLevelToLevel = (
  level = 0,
) => {
  // NOTICE: Crappy implementation, only gets the highest level
  let result = 0;
  while (level > 0) {
    result++
    level = level >> 1;
  }
  return result;
};

export const levelToBackendLevel = (
  level = 0,
) => (
  level <= 0
    ? 0
    : (
      1 << (level - 1)
    )
);

export const useKpiValidationLevels = (
  levels,
  validated_level, // integer with flags
  ready_to_validate_level, // integer with flags
  approvals = [],
  rejections = [],
  userApprovalLevel = DEFAULT_USER_APPROVAL_LEVEL,
) => {
  const lastApprovalPeriodStarted = useMemo(() => {
    let result = null;
    approvals
      .filter(({ level }) => level === '0' || level === 0)
      .forEach(
        approval => {
          if (
            !result ||
            result < fromDb(approval.created_at)
          ) {
            result = fromDb(approval.created_at);
          }
        }
      );

    return result;
  }, [
    approvals,
  ]);

  const lastRejection = useMemo(() => {
    let result = null;
    rejections
      .forEach(
        rejection => {
          if (
            !result ||
            fromDb(result.created_at) < fromDb(rejection.created_at)
          ) {
            result = rejection;
          }
        }
      );

    return result;
  }, [
    rejections,
  ]);

  const lastActionsPerLevel = useMemo(() => {
    let result = new Array(levels).fill(null);
    const allActions = approvals
      .filter(({ created_at }) => lastApprovalPeriodStarted && fromDb(created_at) >= lastApprovalPeriodStarted)
      .map(action => ({
        ...action,
        type: 'approval',
      }))
      .concat(
        rejections
          .filter(({ created_at }) => lastApprovalPeriodStarted && fromDb(created_at) >= lastApprovalPeriodStarted)
          .map(action => ({
            ...action,
            type: 'rejection',
          }))
      )

    allActions.forEach(
      action => {
        if (action.level <= 0) {
          return;
        }
        const resultIndex = backendLevelToLevel(action.level) - 1;
        if (
          !result[resultIndex] ||
          fromDb(result[resultIndex].created_at) < fromDb(action.created_at)
        ) {
          result[resultIndex] = action;
        }
      }
    );

    return result;
  }, [
    lastApprovalPeriodStarted,
    levels,
    approvals,
    rejections,
  ]);

  const result = useMemo(() => {
    let currentStep = 0;
    let steps = [];
    let showSteps = false;

    if (ready_to_validate_level) {
      showSteps = true;
      for (let i = 0; i < levels; i++) {
        const level = i + 1;
        const backendLevel = levelToBackendLevel(level);
        const action = lastActionsPerLevel[i];
        const isApproved = !!(validated_level & backendLevel);
        const isActive = !!(ready_to_validate_level & backendLevel);
        const canApprove = !!(userApprovalLevel & backendLevel);

        if (isApproved) {
          currentStep++;
        }

        steps.push({
          level,
          isApproved,
          isActive,
          canApprove,
          action,
        });
      }
    }

    return {
      showSteps,
      currentStep,
      steps,
      lastRejection,
    };
  }, [levels, validated_level, ready_to_validate_level, lastActionsPerLevel, lastRejection, userApprovalLevel]);

  return result;
};

