import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Bar } from 'react-chartjs-2';
import { groupBy } from 'lodash';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import CustomChart from 'components/CustomChart';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';
import { defaultColors } from 'components/Dashboard/useColors';

import {
  requestEqualityPlanFactors,
  requestEqualityPlanPositions,
} from 'actions/api';

import CustomButton from 'components/CustomButton';
import {
  Row,
  Col,
  Tabs,
} from 'antd';


const AnalysisByGenderForPosition = ({
  intl,
  history,
  plan,
  updatePlanProgress,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();
  const [currentPosition, setCurrentPosition] = useState();
  const [currentTab, setCurrentTab] = useState();

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanFactors(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanPositions(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetching: fetchingFactors,
    data: factors,
  } = useSelector(state => state.equality_plan_factor);
  const {
    fetching: fetchingPositions,
    data: positions,
  } = useSelector(state => state.equality_plan_position);

  useEffect(
    () => {
      if (positions && positions.length > 0 && !currentTab) {
        setCurrentPosition(positions[0]);
        setCurrentTab(positions[0].id.toString());
      }
    },
    [positions, currentTab]
  );

  const handleOnChangeTab = useCallback(
    tab => {
      setCurrentPosition(
        positions.find(position => position.id === parseInt(tab))
      );
      setCurrentTab(tab);
    },
    [positions]
  );

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  const handleOnClickReviewed = useCallback(
    () => {
      updatePlanProgress('analysis_by_gender_for_position');
      handleBack();
    },
    [updatePlanProgress, handleBack]
  );

  const graphData = useMemo(
    () => {
      if (!currentPosition || !factors) {
        return {};
      }

      let data = {
        labels: [],
        datasets: ['male', 'female', 'neutral'].map((gender, index) => ({
          label: t[`equality_job_evaluation_factor_gender_option_${gender}`],
          data: [],
          backgroundColor: defaultColors[index],
        })),
      };

      const factorsWithPositionPoints = currentPosition.factors
        .map(positionFactor => {
          const factor = factors
            .find(factor => factor.id === positionFactor.factor_id);
          const levelPoints = factor.levels
            .find(level => level.id === positionFactor.factor_level_id)
            .points;
          return {
            ...factor,
            points: (
              levelPoints
              * factor.percentage
              * (plan.job_evaluation?.type_points?.[factor.type] || 0)
            ),
          };
        });

      Object.entries(
        groupBy(factorsWithPositionPoints, 'type')
      )
      .forEach(([type, factors]) => {
        factors.forEach(factor => {
          data.labels.push(`${type} - ${factor.code}: ${factor.name}`);
          ['male', 'female', 'neutral'].forEach((gender, index) => {
            if (factor.gender === gender) {
              data.datasets[index].data.push(factor.points);
            } else {
              data.datasets[index].data.push(0);
            }
          });
        });
      });

      return data;
    },
    [currentPosition, factors, plan, t]
  );

  if (
    fetchingFactors
    || !factors
    || fetchingPositions
    || !positions
  ) {
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_analysis_by_gender_for_position}
        handleBackOrSkip={handleBack}
      />
      <Row type="flex" justify="end">
        <CustomButton
          type="primary"
          onClick={handleOnClickReviewed}
        >
          {t.equality_plan_reviewed}
        </CustomButton>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs
            activeKey={currentTab}
            onChange={handleOnChangeTab}
            tabPosition="left"
          >
            {positions.map(position =>
              <Tabs.TabPane
                tab={position.name}
                key={position.id}
              >
                {currentTab === position.id.toString() &&
                  <CustomChart
                    title={t.equality_job_evaluation_analysis_by_gender_for_position}
                    Chart={Bar}
                    data={graphData}
                    height='300px'
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        x: {
                          stacked: true,
                        },
                        y: {
                          stacked: true,
                        },
                      },
                      plugins: {
                        legend: {
                          align: 'start',
                          position: 'bottom',
                          labels: {
                            usePointStyle: true,
                            pointStyle: 'rectRounded'
                          }
                        },
                      },
                    }}
                  />
                }
              </Tabs.TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
    </>
  );
}

export default withRouter(injectIntl(AnalysisByGenderForPosition));
