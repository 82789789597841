import { loop, Cmd } from 'redux-loop';
import { push } from 'connected-react-router'

const TARGET = 'dashboard_list';

const isTarget = (target) => [TARGET].includes(target);
const isGeneralTarget = (target) => target === TARGET;

const initialState = {
  data: [],
  loading: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        ...state,
        loading: action.method === 'GET',
        error: false,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;
      const response = action.response;
      if(response.method === 'POST' || response.method === 'PUT') {
        return loop({
          ...state,
          data: response.result.data,
          loading: false,
          error: false,
        }, Cmd.action(push(`/dashboard/${response.result.slug}`)));
      }
      return {
        ...state,
        data: response.result,
        loading: false,
        error: false,
      };
      
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      if(isGeneralTarget(action.request.target)) {
        return {
          ...state,
          loading: false,
          error: true,
        };
      }
      break
    default:
      return state;
  }
};

export {
  reducer as dashboard_list,
};
