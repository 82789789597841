import { useMemo } from "react";
import { useAvailableUnits } from "./useAvailableUnits";
import { useMetricUnits } from "./useMetricUnits";

export const useSchemaUnits = (schema) => {

    const schemaAvailableUnits = useAvailableUnits(schema);
    const { baseUnitsObj } = useMetricUnits(schema);
    const isHeterogeneousTable = schema && schema.type === 'table' && schema.innerSchema.type === 'tuple';
    
    const availableUnits = useMemo(() => {

        if (!isHeterogeneousTable){
          if (schemaAvailableUnits.find(_unit => _unit.is_base) || !baseUnitsObj) return schemaAvailableUnits;
          return [...schemaAvailableUnits, baseUnitsObj ];
        }
    
        // In heterogeneous table, data comes in base unit, which maybe does not exist into the availableUnits
        // In order to do the conversion without errors, we add the base unit to the availableUnits
        return Object.keys(schemaAvailableUnits).reduce((obj, key) => {
          const columnUnits = schemaAvailableUnits[key];
          const oldUnits = [...columnUnits];
          if (columnUnits.find( _unit => _unit.is_base )) {
            obj[key] = oldUnits;
          } else {
            const columnBaseUnit = baseUnitsObj[key];
            obj[key] = [...oldUnits, columnBaseUnit];
          }
          return obj;
        }, {});
    
    }, [ schemaAvailableUnits, baseUnitsObj, isHeterogeneousTable ]);

    return {
        availableUnits
    }
}