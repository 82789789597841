import React from "react";
import { FormattedMessage } from "react-intl";

import AppFramework from "components/MainLayout";
import AplanetIcon from "components/AplanetIcon";
import { Error } from "tsComponents/emptyStates/Error";
import { getErrorProps } from "tsComponents/emptyStates/errorProps";
import { goBack } from "tsComponents/emptyStates/helpers";
import { ButtonGroup } from "tsComponents/button/ButtonGroup";

const NotFound = () => {
  return (
    <AppFramework>
      <Error {...getErrorProps("notFound")}>
        <ButtonGroup>
          <button className="button--tertiary" onClick={goBack}>
            <AplanetIcon name="Arrow to left" />
            <FormattedMessage id="back" />
          </button>
        </ButtonGroup>
      </Error>
    </AppFramework>
  );
};

export default NotFound;
