import { loop, Cmd } from 'redux-loop';

import {
  downloadKpiExcelData,
} from 'actions/api';

const TARGET = 'kpi_excel_download';

const initialState = {
  data: null,
  loading: false,
  ready: false,
  processing: false,
  error: null,
  ticket: '',
  endpoint: '',
  resultEndpoint: '',
};

/*
const waitForData = (endpoint) => ({
  type: 'ACTIVE_WAIT_REQUEST',
  endpoint,
});

const ticketResponse = (
  ticket,
  responseEndpoint,
  nextState,
) => {
  // NOTICE: we need to compose the URL respecting query parameters
  const questIndex = responseEndpoint.indexOf('?')
  const search = questIndex >= 0
    ? responseEndpoint.slice( questIndex )
    : ''

 const base = questIndex >= 0
  ? responseEndpoint.slice( 0, questIndex )
  : responseEndpoint

  const endpoint = `${base}/status/${ticket}${search}`;
  const resultEndpoint = `${base}/result/${ticket}${search}`;
  return loop({
    ...nextState,
    processing: true,
    ready: false,
    error: null,
    ticket,
    endpoint,
    resultEndpoint,
  }, Cmd.action(waitForData(endpoint)));
};
*/

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API_CALL_REQUEST':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        data: action.method === 'GET' ? null : state.data,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if (!response || response.target !== TARGET) return state;
      if (response.method === 'PUT') {
        const { ticket } = action.response.result;
        let nextState = {
          ...state,
          data: null,
          loading: false,
          error: null,
          processing: true,
        };

        if (ticket) {
          /*
          return ticketResponse(
            ticket, action.response.endpoint, nextState,
          );
          */
        }
        return nextState;
      } else if(response.method === 'GET') {
        return {
          ...state,
          data: action.response.result,
          ready: true,
          error: false,
          loading: false,
          processing: false,
        };
      }
      return state;
    case 'API_CALL_FAILED':
      if (!action.request || action.request.target !== TARGET) return state;
      const { code } = action;
      return {
        ...initialState,
        loading: false,
        error: code,
      };
    case 'ACTIVE_WAIT_COMPLETE':
      if (
        !state.endpoint
        || state.endpoint !== action.endpoint
      ) {
        return state;
      }
      return loop({
        ...state,
        done: true,
        processing: false,
        error: null,
      }, Cmd.action(downloadKpiExcelData(state.resultEndpoint)));
    case 'ACTIVE_WAIT_FAILED':
      if (
        !state.endpoint
        || state.endpoint !== action.endpoint
      ) {
        return state;
      }
      return {
        ...state,
        done: false,
        processing: false,
        error: 500,
      };
    case 'RESET_DATA':
      if (action.target !== TARGET) return state;
      return initialState;
    default:
      return state;
  }
};

export { reducer as kpi_excel_download };
