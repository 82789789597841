import { Fragment } from "react";
import { orderBy, reverse } from "lodash";
import { searchTreeWithFullPath } from 'utils/tree';


export const MAX_ORG_LABEL_LENGTH = 40;

const ID_FIELD = 'id';
const CHILDREN_FIELD = 'children';
const TREE_LEVEL_FIELD = 'treeLevel';

const mergeObject = (obj, el) => ({ ...obj, ...el });

export const getOrderedSuborganizationPath = (suborganizationsTree, suborganization, path = []) => {
  const suborganizationTreeInfo = suborganizationsTree.find(({slug}) => suborganization.slug === slug);
  const suborganizationParent = suborganizationsTree.find(({slug}) => suborganizationTreeInfo.parent_slug === slug);
  path = [
    ...path,
    {
      ...suborganization,
      isTargetOrg: path.length === 0
    }
  ];
  return suborganizationParent
    ? getOrderedSuborganizationPath(suborganizationsTree, suborganizationParent, path)
    : reverse(path);
};


const getOrganizationTreeWithParents = (organizationTree, organization) => {
  return organizationTree
    ? searchTreeWithFullPath(
        [organizationTree], (node) => node.slug === organization?.slug
      )?.[0]
    : organization;
};

const flattenTreeSortedByTreeLevel = (tree, key = ID_FIELD, treeLevel = 0) => {
  if(!tree) return {};
  
  if(!tree[CHILDREN_FIELD] || tree[CHILDREN_FIELD].length === 0) {
    return {
      [tree[key]]: {...tree, [TREE_LEVEL_FIELD]: treeLevel},
    };
  }

  const nextTreeLevel = treeLevel + 1;
  return orderBy({
    [tree[key]]: { ...tree, [TREE_LEVEL_FIELD]: treeLevel, [CHILDREN_FIELD]: tree[CHILDREN_FIELD].map(child => child[key]) },
    ...(tree[CHILDREN_FIELD].map(child => flattenTreeSortedByTreeLevel(child, key, nextTreeLevel)).reduce(mergeObject, {})),
  }, TREE_LEVEL_FIELD, 'asc');
};

export const getOrganizationsRootToLeaf = (organizationTree, organization) => {
  const organizationTreeWithParents = getOrganizationTreeWithParents(organizationTree, organization);
  return Object.values(
    flattenTreeSortedByTreeLevel(organizationTreeWithParents)
  ).map(node => node.name);
};

export const organizationHierarchyText = (organizationTree, organization) => {
  const organizationsRootToLeaf = getOrganizationsRootToLeaf(organizationTree, organization);

  if (organizationsRootToLeaf.length === 0) {
    return organization.name;
  }
  if (organizationsRootToLeaf.length < 3) {
    return organizationsRootToLeaf.map(((string, i, array) => 
      <Fragment key={ string }>
        <span style={{color: i < array.length - 1 ? "#919897" : null }}>
          {string}
        </span>
        { i < array.length - 1 ? <span style={{color: "#919897"}}> / </span> : null }
      </Fragment>
    ))
  }

  const parts = [
    organizationsRootToLeaf[0],
    organizationsRootToLeaf.slice(1, -1).join(' / '),
    organizationsRootToLeaf.at(-1),
  ];

  const lengthOfRootAndLeaf = parts[0].length + parts[2].length;
  if (lengthOfRootAndLeaf >= MAX_ORG_LABEL_LENGTH) {
    parts[1] = '...';
  } else {
    const left = MAX_ORG_LABEL_LENGTH - lengthOfRootAndLeaf;

    if (left < parts[1].length) {
      parts[1] = '...';
    }
  }
  return parts.map(((string, i, array) => <><span style={{color: i < array.length - 1 ? "#919897" : null }}>{string}</span>{i < array.length - 1 ? <span style={{color: "#919897"}}> / </span> : null}</>));
};
