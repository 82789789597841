const TARGET = 'analysis_subjects';

const initialState = {
  error: null,
  data: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || response.target !== TARGET) return state;
      return {
        ...state,
        data: response.result,
      };
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        error: action.code,
      };
    default:
      return state;
  }
};

export {
  reducer as analysis_subjects,
};
