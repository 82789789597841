import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext
} from 'react'
import { withRouter } from "react-router";
import { injectIntl, FormattedHTMLMessage } from 'react-intl';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { groupBy } from 'lodash';

import useOrganizations from 'utils/useOrganizations';
import {
  requestFilesForOrganization,
} from 'actions/api';

import FilesFilters from 'components/FilesFilters';
import FilesCard from 'components/FilesCard'
import { useFeatureList } from 'components/FeatureSwitch';

import {
  makeSearchString,
  makeSearchStrings,
} from 'utils/strings';
import useScreenFilters from 'hooks/useScreenFilters';
import { SCREENS } from 'hooks/useScreenFilters/constants';


import {
  formatDate,
  DATE_ONLY,
  SHORT_FORMAT_WITH_YEAR,
  fromDb,
  isBetween,
  totalCalendarDays
} from 'utils/date';


import {
  Timeline,
} from 'antd';
import CustomModalType from 'components/CustomModalType';
import CustomInput from 'components/CustomInput';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { getEmptyResultsProps, emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';

import './style.less';
import AplanetIcon from 'components/AplanetIcon';
import { Loading } from 'tsComponents/emptyStates/Loading';
import { useEventTracking } from 'hooks/useEventTracking';
import { WebSocketContext } from 'components/WebsocketContext';
import { checkByFileTypes } from 'utils/validation';

const FilesTab = ({
  intl,
  onDownload
}) => {
  const t = intl.messages;

  const dispatch = useDispatch();
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const [downloadzipName, setDownloadZipName] = useState('');
  const [onInputError, setOnInputError] = useState(false);
  const eventTracking = useEventTracking();
  const { sendMessage } = useContext(WebSocketContext);

  //Same order as defined in useScreenFilters/constants.js
  const {
    values: [
      [filteredPeriodicity, periodicityFilterSet],
      [filteredAttachment, attachmentFilterSet],
      [filteredStandards, standardFilterSet],
      [filteredSdgs, sdgFilterSet],
      [filteredTypes, typeFilterSet],
      [filteredUser, userFilterSet],
      [filteredDate, dateFilterSet],
      [filteredStatuses], // eslint-disable-line no-unused-vars
      [textFilter, textFilterSet], // eslint-disable-line no-unused-vars
    ],
    handleFilter,
    filterState,
    isFilterSet, // eslint-disable-line no-unused-vars
    resetAllFilters
  } = useScreenFilters({
    screen: SCREENS.files
  });

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const {
    features: featureList,
  } = useFeatureList();

  const canExportKpis = useMemo(() => (
    (!featureList.has('restricted_admins') || suborganization?.permissions?.can_affect_all_kpis) && suborganization?.permissions?.can_export_kpis
  ), [
    featureList,
    suborganization,
  ]);

  const areFiltersSet = useMemo(
    () => sdgFilterSet || typeFilterSet || standardFilterSet
      || attachmentFilterSet
      || periodicityFilterSet || userFilterSet || dateFilterSet
    ,
    [
      sdgFilterSet,
      standardFilterSet,
      typeFilterSet,
      attachmentFilterSet,
      periodicityFilterSet,
      userFilterSet,
      dateFilterSet
    ]
  );


  const {
    data,
    loading,
    //error,
  } = useSelector(state => state.data_files);

  const {
    data: OrgArchives
  } = useSelector(state => state.organization_archive);

  useEffect(() => {
    dispatch(
      requestFilesForOrganization(
        organization.slug,
        suborganization.slug,
      )
    );
    sendMessage('org_archive:get');
  }, [
    dispatch,
    organization,
    suborganization,
    sendMessage
  ]);


  const { processedFiles, totalFiles, totalKpis } = useMemo(() => {

    const filteredData = (data || [])
      .filter(item => {
        if (!areFiltersSet && !textFilterSet) {
          return true;
        }
        let resultTextFilter = true,
          resultStandardFilter = true,
          resultSdgFilter = true,
          resultTypeFilter = true,
          resultAttachmentFilter = true,
          resultUserFilter = true,
          resultPeriodicityFilter = true,
          resultDateFilter = true;

        if (textFilterSet) {
          const searchStrings = makeSearchStrings(textFilter);
          const searchableKpi = makeSearchString(item.kpi_name);
          const searchableMember = makeSearchString(item.member_name);
          const searchableFile = makeSearchString(item.filename || '');
          resultTextFilter = searchStrings.every((searchString) => searchableKpi.includes(searchString) || searchableMember.includes(searchString) || searchableFile.includes(searchString));
        }

        if (standardFilterSet) {
          resultStandardFilter = (item.standards.filter(standard => filteredStandards.includes(standard))).length > 0;
        }
        if (sdgFilterSet) {
          resultSdgFilter = (item.sdgs.filter(sdg => filteredSdgs.includes(sdg))).length > 0;
        }
        if (typeFilterSet) {
          resultTypeFilter = filteredTypes.includes(item.esg_type);
        }
        if (attachmentFilterSet) {
          let fileExtension = item.filename.split('.').pop();
          resultAttachmentFilter = checkByFileTypes(fileExtension, filteredAttachment);
        }

        if (periodicityFilterSet) {
          resultPeriodicityFilter = filteredPeriodicity.includes(item.collection_frequency);
        }

        if (userFilterSet) {
          resultUserFilter = filteredUser.includes(item.member_slug);
        }

        if (dateFilterSet) {
          resultDateFilter = isBetween(item.created_at, filteredDate[0], filteredDate[1]);
        }

        return resultTextFilter && resultStandardFilter && resultSdgFilter && resultTypeFilter && resultTypeFilter && resultAttachmentFilter && resultPeriodicityFilter && resultUserFilter && resultDateFilter
      })
      .map(datum => ({
        ...datum,
        date: formatDate(
          datum.created_at,
          DATE_ONLY,
          intl,
        ),
        entity: 'kpi_value_attachment' // file's entity. Used by backend
      }))
      .sort((a, b) => {
        const dateA = fromDb(a.created_at);
        const dateB = fromDb(b.created_at);
        return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
      });

    const kpiMap = new Map();
    filteredData.forEach(datum => {
      if (!kpiMap.has(datum.kpi_slug)) {
        kpiMap.set(datum.kpi_slug);
      }
    });
    const totalFiles = filteredData.length;
    const totalKpis = kpiMap.size;

    const dateMap = new Map();
    filteredData.forEach(datum => {
      const {
        date,
        member_slug,
        kpi_slug,
      } = datum;

      if (!dateMap.has(date)) {
        dateMap.set(date, new Map());
      }

      const thisDateMap = dateMap.get(date);

      if (!thisDateMap.has(member_slug)) {
        thisDateMap.set(member_slug, new Map());
      }

      const thisMemberMap = thisDateMap.get(member_slug);

      if (!thisMemberMap.has(kpi_slug)) {
        thisMemberMap.set(kpi_slug, []);
      }
      thisMemberMap.get(kpi_slug).push(datum);
    });

    // NOTICE: weird format we got there...
    return {
      processedFiles: Array
        .from(dateMap.entries())
        .map(([date, dateEntry]) => [
          date,
          Array.from(dateEntry.entries())
            .map(([member_slug, memberEntry]) => [
              member_slug,
              Array.from(memberEntry.entries())
            ])
        ]),
      totalFiles,
      totalKpis
    };

  }, [
    data,
    intl,
    areFiltersSet,
    textFilterSet,
    textFilter,
    standardFilterSet,
    filteredStandards,
    sdgFilterSet,
    filteredSdgs,
    typeFilterSet,
    filteredTypes,
    attachmentFilterSet,
    filteredAttachment,
    dateFilterSet,
    filteredDate,
    filteredPeriodicity,
    filteredUser,
    periodicityFilterSet,
    userFilterSet

  ]
  );

  const openModal = useCallback(() => {
    setOpenDownloadModal(true);
  }, []);

  const handleUserInput = useCallback((input) => {
    setDownloadZipName(input);
    const isDuplicatedName = OrgArchives.some(el => {
      // const regex = new RegExp(`^${input}\\.`,'g');
      // return regex.test(el.filename);
      let filename_arr = el.filename.split('.');
      return filename_arr[0] === input;
    });
    const hasProhibitedChars = /[^A-Za-z0-9_\s]/.test(input);
    if (isDuplicatedName || hasProhibitedChars) {
      // set Error
      setOnInputError(true);
    } else {
      setOnInputError(false);
    }
  }, [
    OrgArchives,
    setDownloadZipName,
    setOnInputError
  ]);

  const handleDownload = useCallback(() => {

    const filesList = [];

    processedFiles.forEach(([date, dateEntries]) => {
      dateEntries.forEach(([member_slug, kpiEntries]) => {
        kpiEntries.forEach(([kpi, entries]) => {
          filesList.push(...entries);
        });
      });
    });

    const payload = {
      templateName: 'withKpi',
      format: 'zip',
      type: 'archive',
      organization_id: suborganization.id,
      fileName: downloadzipName,
      files: filesList,
    };
    // dispatch(
    //   (createArchive)(
    //     organization.slug,
    //     suborganization.slug,
    //     'zip',
    //     payload
    //   )
    // );

    sendMessage('org_archive:create', {
      format: 'zip',
      payload
    });

    setOpenDownloadModal(false);
    onDownload();
    eventTracking.capture('attachments.downloadAll', {
      indicators_amount: totalKpis,
      files_amount: filesList.length
    });
  }, [
    suborganization,
    processedFiles,
    downloadzipName,
    onDownload,
    eventTracking,
    totalKpis,
    sendMessage
  ]);

  const cancelDownload = useCallback(() => {
    setOpenDownloadModal(false);
    setDownloadZipName('');
  }, []);

  const isLoading = loading && !processedFiles.length
  const hasNoResult = data && !Boolean(processedFiles.length);

  const emptyState = textFilterSet || areFiltersSet
    ? <Empty {...getEmptyResultsProps(textFilterSet, areFiltersSet)} />
    : <Empty {...emptyPropsMap.get("noAttachments")} />

  return (
    <div className="DataManagement__files">
      <FilesFilters
        setFilterState={handleFilter}
        filterState={filterState}
        resetAllFilters={resetAllFilters}
        areFiltersSet={areFiltersSet}
        isTextFilterSet={textFilterSet}
        showDownloadButton={(canExportKpis && processedFiles.length) ? true : false}
        onDownload={openModal}
      />


      <div className='DataManagement__files__timeline__container'>
        {(isLoading)
          ? <Loading />
          : <Timeline className='ant-timeline-label' >
            {
              processedFiles.map(([date, dateEntries], outerIndex) => {
                const totalDays = totalCalendarDays(date, Date.now());
                return (
                  <React.Fragment key={date}>
                    <span className='DataManagement__files__timeline-day'>{totalDays === 0 ? t.timeline_day_today : totalDays === 1 ? t.timeline_day_yesterday : date}</span>
                    {
                      (dateEntries || []).map(([member_slug, kpiEntries], innerIndex) => {
                        return (kpiEntries || []).map(([kpi, entries]) => {
                          const formattedDate = formatDate(entries[0].created_at, SHORT_FORMAT_WITH_YEAR, intl);
                          let additionalClasses = (innerIndex === dateEntries.length - 1 && outerIndex < processedFiles.length - 1) ? 'DataManagement__files__timeline-item-last' : '';
                          additionalClasses += innerIndex === 0 ? ' DataManagement__files__timeline-item-first' : '';

                          return (
                            <Timeline.Item className={`ant-timeline-item-left DataManagement__files__timeline-item ${additionalClasses}`} label={<span>{formattedDate}</span>}>
                              {Object.values(groupBy(entries, 'period')).map(entries => {
                                const period = {
                                  label: entries[0].period,
                                  start_date: entries[0].period_start,
                                  end_date: entries[0].period_end
                                };
                                return (
                                  <FilesCard
                                    key={member_slug}
                                    name={entries[0].member_name}
                                    avatar={entries[0].member_avatar}
                                    email={entries[0].member_email}
                                    kpi_name={entries[0].kpi_name}
                                    kpi_slug={entries[0].kpi_slug}
                                    esg_type={entries[0].esg_type}
                                    period={period}
                                    attachments={entries}
                                    standard_info={entries[0].standard_info}
                                    showKpi
                                    collection_frequency={entries[0].collection_frequency}
                                    urlBase="data/files"
                                  />
                                )
                              })}
                            </Timeline.Item>
                          )
                        })
                      })
                    }
                  </React.Fragment>
                )
              })
            }
          </Timeline>
        }
        {hasNoResult && emptyState}


      </div>
      <CustomModalType
        className='DataManagement__files__DownloadModal'
        showModal={openDownloadModal}
        title={t.datamanagement_files_modal_download_title}
        icon="File zip"
        onOk={handleDownload}
        onCancel={cancelDownload}
        disabledOk={onInputError}
        onOkText={t.datamanagement_files_modal_download_button_ok}
        onCancelText={t.datamanagement_files_modal_download_button_cancel}
        content={
          <>
            <section className='DataManagement__files__DownloadModal__text'>
              <span>{t.datamanagement_files_modal_download_action_text}</span>
              <span>
                <AplanetIcon name="File text empty" />
                {intl.formatMessage({ id: 'datamanagement_files_modal_download_totalfiles' }, { total: totalFiles })}
              </span>
              <span>
                <AplanetIcon name="File text empty" faStyle="fas" />
                {intl.formatMessage({ id: 'datamanagement_files_modal_download_totalkpis' }, { total: totalKpis })}
              </span>
            </section>



            <section className="DataManagement__files__DownloadModal__form">
              <p>
                {t.datamanagement_files_modal_download_forminput_title}
              </p>
              <div>
                <CustomInput
                  value={downloadzipName}
                  onChange={(e) => handleUserInput(e.target.value)}
                  className={onInputError ? 'input__error' : ''}
                />
                {
                  onInputError &&
                  <p className="input__error">{t.datamanagement_files_modal_download_form_error}</p>
                }
              </div>
              <div className="DataManagement__files__DownloadModal__form__notice">
                <FormattedHTMLMessage
                  id="datamanagement_files_modal_download_forminput_notice"
                  defaultMessage={t.datamanagement_files_modal_download_forminput_notice}
                  tagName="p"
                  values={
                    {
                      downloads_tab: t.datamanagement_files_downloads
                    }
                  }
                />
              </div>
            </section>

          </>

        }
      >

      </CustomModalType>

    </div>
  );
}

export default injectIntl(
  withRouter(
    FilesTab
  )
)

