import React, {
  useEffect,
  useState,
  useMemo
} from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { injectIntl } from 'react-intl';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from '@emotion/react'

import config from 'config';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';
import { ExternalRoutes } from 'containers/Routes';
import { FeatureProvider } from 'components/FeatureSwitch';
import { useEventTracking } from 'hooks/useEventTracking';
import updateTheme from 'utils/updateTheme';

const ExternalRoot = ({
  intl
}) => {

  const {
    topLevelOrg,
    selector_orgs,
    profile,
  } = useSelector(state => state.third_party);

  const eventTracking = useEventTracking();
  const [theme, setTheme] = useState({}); // TODO - configure default theme

  const currentTopOrganization = useMemo(
    () => selector_orgs?.find(({slug}) => slug === topLevelOrg),
    [
      selector_orgs,
      topLevelOrg
    ]
  );

  useEffect(() => {
    const theme = currentTopOrganization?.config?.theme;
    updateTheme(theme);
    if(theme) {
      setTheme(theme);
    }
  }, [
    currentTopOrganization?.config?.theme
  ]);
  
  useEffect(() => {
    eventTracking.init();
  }, [eventTracking]);
  
  // WORKAROUND!: to avoid reloading twice when the language is different from the browser and it's being changed in intl object
  if (intl.locale !== profile?.language) {
    return null;
  }

  return (
    <Sentry.ErrorBoundary
      fallback={
      <ErrorBoundaryFallback
        titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
        buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
        descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_default_message' })}
        customErrorImage="/images/error_image.png"
      />
    }>
      <ThemeProvider theme={theme}>
        <FeatureProvider
          value={{
            features: new Set(config.DEFAULT_FEATURES),
            upselling: new Set(config.DEFAULT_FEATURES_UPSELLING),
          }}
        >
          <ConfigProvider locale={intl.formats.antd}>
            <ExternalRoutes />
          </ConfigProvider>
        </FeatureProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}

export default injectIntl(ExternalRoot);
