import React from 'react';
import { injectIntl } from 'react-intl';
import './style.less';
import Show from './Show';

// const NO_OP = () => {};
const DataTargets = ({
  schema,
  schemaLabels,
  config,
  kpi_value,
  target,
  justify = "end"
}) => {

  return (
        <Show
            schema={schema}
            schemaLabels={schemaLabels}
            target={target? target.value: {}}
            kpi_value={kpi_value}
            config = {config}
            justify={justify}
        />
  );
}

DataTargets.Show = Show.Component;
DataTargets.TableShow = Show.TableComponent;
DataTargets.QuantitativeTableShow = Show.QuantitativeTableComponent;
DataTargets.BooleanTableShow = Show.BooleanTableComponent;

DataTargets.Summary = Show.SummaryComponent;

export default injectIntl(DataTargets);
