import React, {
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react'
import { injectIntl } from 'react-intl'
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  generateReport,
} from 'actions/api'

import useForm from 'utils/useForm';
import { formatDateOnly } from 'utils/date';

import useOrganizations from 'utils/useOrganizations';

import CustomButton from 'components/CustomButton'
import CustomInput from 'components/CustomInput';
import CustomModal from 'components/CustomModal';
import T from 'components/T'
import moment from 'moment';
import { useEventTracking } from 'hooks/useEventTracking';

import './style.less'

import {
  Form,
  Checkbox,
} from 'antd';

const validation = (t) => ({
  title,
}) => {
  let errors = {};
  if(!title) {
    errors.title = t.form_error_required;
  }

  return errors;
};

const REPORT_FORMAT_DATE = 'YYYY-MM-DD';

const NewReportModal = ({
  intl,
  shown,
  kpi_slugs = [],
  dates = [],
  onClose,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const {
    organization,
    suborganization,
  } = useOrganizations();

  const [ submitting, setSubmitting ] = useState(false);

  const {
    loading,
    error,
  } = useSelector(state => state.report_history);

  const eventTracking = useEventTracking();

  const submitForm = () => {
    dispatch(
      generateReport({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        title: values.title,
        show_comments: values.show_comments,
        kpi_slugs,
        dates: dates.length ?
          [moment(dates[0]).startOf('month').format(REPORT_FORMAT_DATE), moment(dates[1]).endOf('month').format(REPORT_FORMAT_DATE)]
          : [],
      })
    );
    setSubmitting(true);
    eventTracking.capture('fillReport.generateReport', { comments_shown: values.show_comments ? 1 : 0 });
  };

  const validateForm = useMemo(() => validation(t), [ t ]);

  const {
    values,
    handleChangeEvent,
    handleChange,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
  });

  const closeModal = useCallback(() => {
    setSubmitting(false);
    resetForm();
    onClose();
  }, [resetForm, onClose]);

  // Effect: close form when success
  useEffect(() => {
    if(submitting && !loading && !error) {
      onClose();
    }
  }, [
    onClose,
    submitting,
    loading,
    error,
  ]);

  const titleError = useMemo(() => {
    // Validation error
    if(!isDirty('title') && errors.title) {
      return errors.title;
    }
    return '';
  }, [
    errors,
    isDirty,
  ]);

  const validateStatus = titleError ? 'error' : '';

  return (
    <CustomModal
      shown={shown}
      onCancel={closeModal}
      afterClose = {closeModal}
      title={
        <div>
          <h3>{ t.reports_modal_new_title }</h3>
          <div
            className='NewReportModal__subtitle'
          >
            {
              !dates || dates.length === 0
              ? t.reports_modal_new_subtitle_last
              : (
                <T
                  reports_modal_new_subtitle_dates={{
                    start: formatDateOnly(dates[0], t.reports_modal_new_subtitle_date_format, intl),
                    end: formatDateOnly(dates[1], t.reports_modal_new_subtitle_date_format, intl),
                  }}
                />
              )
            }
          </div>
        </div>
      }
      footer={
        <CustomButton
          type="primary"
          className='NewReportModal__createbutton'
          disabled={!values.title}
          loading={loading}
          onClick={handleSubmit}
        >
          { t.reports_modal_new_button }
        </CustomButton>
      }
      width={600}
    >
      <Form onFinish={handleSubmit}>
        <section className='NewReportModal__body'>
          <span className='NewReportModal__title'>{t.reports_modal_new_field}</span>
          <Form.Item
            hasFeedback
            validateStatus={validateStatus}
            help={titleError}
          >
            <CustomInput
              maxLength={75}
              placeholder={t.reports_modal_new_field_placeholder}
              name="title"
              value={values.title}
              onChange={handleChangeEvent}
            />
          </Form.Item>
          <Checkbox
            checked={values.show_comments}
            onChange={e => handleChange('show_comments')(e.target.checked)}
          >
            { t.reports_modal_show_comments }
          </Checkbox>
        </section>
      </Form>
    </CustomModal>
  );
};

export default injectIntl(
  NewReportModal
);

