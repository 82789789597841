import React, {
  useState,
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  useSelector,
  useDispatch,
} from 'react-redux';

import useCustomRequest from 'utils/useCustomRequest';
import {
  refreshAccessToken as _refreshAccessToken,
} from 'actions/auth';

import { formatMessage } from '../../intl';

import './style.less';

import { MinusCircleOutlined } from '@ant-design/icons';
import {
  Upload,
  Spin,
  notification,
} from 'antd';

import config from 'config';

import { ReactComponent as AddAttachmentIcon } from 'assets/icon-add-attachment.svg';

//const NO_OP = () => {};
const NO_OP = (...a) => { console.log('UoloadArea', a); };

const defaultBeforeUpload = (file) => {
  //const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  const isLt40M = file.size / 1024 / 1024 < 40;
  if (!isLt40M) {
    notification.error({
      message: formatMessage('datamanagement_datafiles_failed'),
      description: formatMessage('file_size_limit'),
      icon: <MinusCircleOutlined style={{ color: 'red' }} />,
      className: 'custom-class',
      style: {
        color: 'red',
      }
    })
  }
  return isLt40M;
}

// NOTICE: This component hides the (ugly) logic of uploading via a multipart
//         form in our app
const UploadArea = ({
  intl,
  children = null,
  actionUrl,
  onSuccess = NO_OP,
  onError = NO_OP,
  authenticated = false,
  accept,
  className = '',
  disabled = false,
  showUploadList = false,
  beforeUpload = defaultBeforeUpload,
  fileList = undefined,
}) => {
  const t = intl.messages;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const api_requests = useSelector(state => state.api_requests);
  const refreshAccessToken = useCallback(() => dispatch(_refreshAccessToken()), [dispatch]);

  const customRequest = useCustomRequest({
    api_requests,
    refreshAccessToken,
    onSuccess,
    onError,
  }, [
    api_requests,
    refreshAccessToken,
    onSuccess,
    onError,
  ]);



  const badExtension = (type) => {
    notification[type]({
      message: 'Upload Failed',
      description: 'The type of file you are trying to upload is not supported.',
      icon: <MinusCircleOutlined style={{ color: 'red' }} />,
      className: 'custom-class',
      style: {
        color: 'red',
      }
    });
  };

  const uploadSuccessful = (type) => {
    notification[type]({
      message: 'File uploaded',
      description: 'File uploaded correctly.',
    });
  };

  const handleChange = useCallback(({ file }) => {
    switch ((file || {}).status) {
      case 'error':
        badExtension('error');
        setError(false);
        setLoading(false);
        break;
      case 'done':
        uploadSuccessful('success')
        setError(false);
        setLoading(false);
        break;
      case 'uploading':
        setError(false);
        setLoading(true);
        break;
      default:
    }
  }, []);

  return (
    <Upload.Dragger
      showUploadList={showUploadList}
      action={actionUrl}
      beforeUpload={beforeUpload}
      fileList={fileList}
      disabled={loading || disabled}
      multiple
      className={`UploadArea__dashed ${className}`}
      {...(accept && { accept })}
      {
      ...(
        !authenticated ? {} :
          {
            customRequest,
            headers: {
              'Authorization': `Bearer ${api_requests.access_token}`,
            },
          }
      )
      }
      customRequest={customRequest}
      onChange={handleChange}
    >
      {
        loading
          ? <Spin size="large" />
          : <AddAttachmentIcon />
      }
      <p className="UploadArea__upload-text">
        {
          loading
            ? t.upload_area_loading
            : error
              ? t.upload_area_error
              : (
                <>
                  {children}
                  <small>
                    {
                      intl.formatMessage(
                        { id: 'upload_area_max_file_size' },
                        { value: config.UPLOAD_FILE_MAXSIZE_MB }
                      )
                    }
                  </small>
                </>
              )
        }
      </p>
    </Upload.Dragger>
  );

};

export default injectIntl(UploadArea);
