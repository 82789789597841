import _ from 'lodash';
import qs from 'query-string';

const queryStringToObject = (str = '') => {
  return qs.parse(str);
};

const objectToQueryParameters = obj => {
  return _.keys(obj).map(key => `${key}=${encodeURIComponent(obj[key])}`).join('&');
};

const updateQueryParam = (key, value) => (params) => {
  params.get(key) ? params.set(key, value) : params.append(key, value);
  return params;
};

export {
  queryStringToObject,
  objectToQueryParameters,
  updateQueryParam
};
