import { isEmpty, uniqBy } from "lodash";

const validate = (intl) => (values, currentStep) => {
  
  let errors = {};

  if (isEmpty(values)) return [];

  switch(currentStep) {
    case 0:
      return validateStep1(intl, values);
    case 1:
      return validateStep2(intl, values);
    case 2:
      return validateStep3(intl, values);
    case 3:
      return validateStep4(intl, values);
    case 4:
      return validateStep5(intl, values);
    default:
      return errors;
  }
}; 

const validateStep1 = (intl, values) => {
  
  let errors = {};

  if (!values.selectedRows.length > 0){
    errors.step1 = false;
  }

  const uniqsByKpi = uniqBy(values.selectedRows, kpi => kpi.slug);
  if (values.selectedRows.length > uniqsByKpi.length){
    errors.step1 = false;
  }

  if (values.selectedRows.some(kpi => kpi.source === 'children')){
    errors.step1 = false;
  }

  return errors;

};

const validateStep2 = (intl, values) => {
  
  let errors = {};

  if (!values.selectedNodeLevel){
    errors.step2 = false;
  }

  return errors;

};

const validateStep3 = (intl, values) => {
  
  let errors = {};

  const kpi = values.selectedRows[0];
  const components = kpi?.schema?.innerSchema?.components || [];
  const dimensions = Object.keys(kpi?.schemaLabels?.dimensionNames || []);
  const selectedColumn = values.graphAddress.length === (dimensions.length + 1) ? values.graphAddress[dimensions.length] : null;

  if (components.length > 0 && selectedColumn){
    const selectedColumnComponent = components.find( component => component.name ===  selectedColumn);
    if (selectedColumnComponent && selectedColumnComponent.type !== 'quantitative'){
      errors.step3 = false;
    }
  }

  const hasMetricSelection = (kpi.schema.type === 'quantitative' && kpi.schema.metricSlug) 
                          || (kpi.schema.type === 'table' && kpi.schema.innerSchema.type === 'quantitative' && kpi.schema.innerSchema.metricSlug) 
                          || (kpi.schema.type === 'table' && kpi.schema.innerSchema.type === 'tuple' 
                                  && kpi.schema.innerSchema.components.some( component => component.type === 'quantitative' && component.metricSlug ));
  if (hasMetricSelection && isEmpty(values.selectedUnit)){
    errors.step3 = false;
  }

  return errors;

};

const validateStep4 = (intl, values) => {
  
  let errors = {};

  if (
    (!values.periodicity) 
    || ( (!values.periodsNumber) || values.periodsNumber < 1)
  ){
    errors.step4 = false;
  }

  return errors;

};

const validateStep5 = (intl, values) => {
  
  let errors = {};
  
  if (!values.cardName || !values.selectedGraph){
    errors.step5 = false;
  }

  return errors;

};

export {
  validateStep1,
  validateStep2,
  validateStep3,
  validateStep4,
  validateStep5,
  validate,
}