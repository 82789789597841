import React from 'react';
import { Row } from 'antd';
//import useOrganizations from 'utils/useOrganizations';
import OrganizationArchiveType from 'components/NotificationType/OrganizationArchive';
import BulkManageExport from 'components/NotificationType/BulkManageExport';
import DefaultType from 'components/NotificationType/Default';

const COMPONENT_PER_TYPE = {
    org_archive_completed: OrganizationArchiveType.Popup,
    org_archive_pending: OrganizationArchiveType.Popup,
    org_archive_deleted: OrganizationArchiveType.Popup,
    bulk_manage_kpis: BulkManageExport.Popup, // Legacy: Field used to be called bulk_manage_kpis earlier
    kpi_value_bulk_manage: BulkManageExport.Popup,
    kpi_value_single_node: BulkManageExport.Popup,
    kpi_value: BulkManageExport.Popup,
}

const COMPONENT_DEFAULT = DefaultType

const NO_OP = () => {};

const PopupBody = ({
    intl,
    title,
    content,
    onNavigation = NO_OP
}) => {
    
    const Component = COMPONENT_PER_TYPE[title] || COMPONENT_DEFAULT;
return(
    <React.Fragment>
        <Row>
            <Component intl={intl} content={content} onNavigation={onNavigation} />
        </Row>

    </React.Fragment>
)

};


export default PopupBody;
