import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';

import useOrganizations from 'utils/useOrganizations';

import {
  Col,
  List,
  Row,
} from 'antd';

import AplanetIcon from 'components/AplanetIcon';
import Avatar from 'components/Avatar';
import CustomButton from 'components/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomInput from 'components/CustomInput';

import { useDispatch, useSelector } from 'react-redux';
import { requestAllOrganizationKpiMembers, syncOrganizationKpiMembers } from 'actions/api';
import CustomWarningModal from 'components/CustomWarningModal';
import { backendLevelToLevel } from 'utils/validation';
import { useEventTracking } from 'hooks/useEventTracking';


const TYPE_ICONS = {
  manager: 'eye',
  editor: 'pencil',
  validator: 'check-double',
};

const ROLE_PERMISSIONS = {
  manager: [
    'can_read_kpi',
  ],
  editor: [
    'can_read_kpi',
    'can_write_kpi',
  ],
  validator: [
    'can_read_kpi',
    'can_validate_kpi',
  ],
};
const DEFAULT_APPROVAL_LEVELS = 1;

const WarningMessage = injectIntl(({
  intl,
  type,
  hasNoActiveAssignees,
  validationApprovalLevels,
  assignees,
}) => {
  if (hasNoActiveAssignees) {
    return (
      <Col className="Assignees_card_header_role_information">
        <AplanetIcon
          className="Assignees_card_content_warning_icon"
          size="18px"
          name="exclamation-triangle"
          title={
            <span className="Assignees_card_header_role_information">
              {intl.formatMessage({id: `assignees_role_${type}_missing`})}
            </span>
          }
          titlePosition="bottom"
          titleColor="#D38106"
        />
      </Col>
    );
  } else if (type === 'validator') {
    let missingLevels = [];
    Array.from(Array(validationApprovalLevels + 1).keys()).slice(1).forEach(
      level => {
        if (
          !assignees
            .filter(({hasPermission}) => hasPermission)
            .find(assignee => assignee.validationApprovalLevels.includes(level))
        ) {
          missingLevels.push(level);
        }
      }
    );

    if (missingLevels.length) {
      return (
        <Col>
          <AplanetIcon
            className="Assignees_card_content_warning_icon"
            size="18px"
            name="exclamation-triangle"
            title={
              <span className="Assignees_card_header_role_information">
                {intl.formatMessage({id: 'assignees_role_validator_missing_levels'})}
                <ul>
                  {missingLevels.map(level =>
                    <li key={level}>
                      {intl.formatMessage({id: 'assignees_validation_level'})} {level}
                    </li>
                  )}
                </ul>
              </span>
            }
            titlePosition="bottom"
            titleColor="#D38106"
          />
        </Col>
      );
    }
  }

  return '';
});

const RoleCard = ({
  intl,
  type,
  kpiSlugs,
  assignees: allAssignees,
  isBulk = false,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [removingAssignees, setRemovingAssignees] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState(
    allAssignees?.filter(({roles}) => roles.includes(type)).map(({member_id}) => member_id) || []
  );
  const [assigneesToRemove, setAssigneesToRemove] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const dispatch = useDispatch();
  const eventTracking = useEventTracking();
  const {
    organization,
    suborganization,
  } = useOrganizations();

  const {
    pushing,
  } = useSelector(state => state.organization_kpi_member);

  useEffect(
    () => {
      if (updating && !pushing) {
        setUpdating(false);
        setEditMode(false);
        setSearchTerm('');

        if (isBulk) {
          // Refetch all the recent assignees for all KPIs of the org
          // This is used by filters in the F&R screen
          dispatch(
            requestAllOrganizationKpiMembers(
              organization.slug,
              suborganization.slug,
            )
          );
        }
      }
    },
    [
      dispatch,
      pushing,
      updating,
      isBulk,
      organization,
      suborganization,
    ]
  );

  useEffect(
    () => {
      if (removingAssignees && !pushing) {
        setRemovingAssignees(false);
        setAssigneesToRemove();
      }
    },
    [
      pushing,
      removingAssignees,
    ]
  );

  useEffect(
    () => {
      if (allAssignees) {
        setSelectedMembers(
          allAssignees.filter(({roles}) => roles.includes(type)).map(({member_id}) => member_id)
        );
      }
    },
    [
      type,
      allAssignees,
    ]
  );

  const validationApprovalLevels = useMemo(() => {
    return suborganization.product_config?.atlas?.approval_levels
      || DEFAULT_APPROVAL_LEVELS;
  }, [
    suborganization,
  ]);

  const hasRolePermissions = useCallback(member => {
    return ROLE_PERMISSIONS[type].every(permission => {
      if(Array.isArray(permission)) {
        // Nested Array will be treated as OR case ie. user must have at least one of the permissions.
        return permission.some(p => member[p]);
      }
      return member[permission];
    });
  }, [
    type,
  ]);

  const assignees = useMemo(
    () => {
      return allAssignees?.filter(
        ({roles}) => roles.includes(type)
      ).map(assignee => {
        const user = suborganization.users.find(
          member => member.member_id === assignee.member_id
        );
        return {
          ...assignee,
          hasPermission: user ? hasRolePermissions(user) : false,
          validationApprovalLevels: backendLevelToLevel(user?.validate_kpi_level),
        };
      }) || [];
    },
    [
      type,
      allAssignees,
      hasRolePermissions,
      suborganization.users,
    ]
  );

  const handleOnEditRoles = useCallback(
    () => {
      setEditMode(true);
    },
    []
  );

  const handleOnClickCancel = useCallback(
    () => {
      setSelectedMembers(assignees.map(({member_id}) => member_id));
      setSearchTerm('');
      setEditMode(false);
    },
    [assignees]
  );

  const handleOnClickSave = useCallback(
    () => {
      const currentAssigneeIds = assignees.map(({member_id}) => member_id);

      const add = selectedMembers.filter(memberId => !currentAssigneeIds.includes(memberId));
      const remove = currentAssigneeIds.filter(memberId => !selectedMembers.includes(memberId));

      if (add.length) {
        if (isBulk) {
          eventTracking.capture('dataOwners.add', {
            managers_added_amount: type === 'manager' ? add.length : 0,
            editors_added_amount: type === 'editor' ? add.length : 0,
            validators_added_amount: type === 'validator' ? add.length : 0,
            indicators_selected_amount: kpiSlugs.length,
          });
        } else {
          eventTracking.capture('dataOwners.assign', {
            assigned_managers_amount: type === 'manager' ? add.length : 0,
            assigned_editors_amount: type === 'editor' ? add.length : 0,
            assigned_validators_amount: type === 'validator' ? add.length : 0,
          });
        }
      }

      if (remove.length) {
        eventTracking.capture('dataOwners.remove', {
          removal_type: remove.length > 1 ? 'bulk' : 'individual',
        });
      }

      if (add.length || remove.length) {
        dispatch(
          syncOrganizationKpiMembers(
            organization.slug,
            suborganization.slug,
            type,
            {
              kpiSlugs,
              add,
              remove,
            },
            isBulk,
          )
        );
        setUpdating(true);
      } else {
        setEditMode(false);
      }
    },
    [
      dispatch,
      organization.slug,
      suborganization.slug,
      kpiSlugs,
      type,
      assignees,
      selectedMembers,
      isBulk,
      eventTracking,
    ]
  );

  const availableMembers = useMemo(() => {
    return (suborganization?.users || [])
      .filter((member) =>
        ['invited', 'active'].includes(member.member_status) &&
        hasRolePermissions(member) &&
        (
          member.member_email.toLowerCase().includes(
            searchTerm.trim().toLowerCase()
          ) ||
          member.member_name.toLowerCase().includes(
            searchTerm.trim().toLowerCase()
          )
        )
      )
      .map((member) => ({
        ...member,
        validationApprovalLevels: backendLevelToLevel(member.validate_kpi_level),
      }));
  }, [
    hasRolePermissions,
    suborganization,
    searchTerm,
  ]);

  const handleOnChange = useCallback(
    (checked, memberId) => {
      if (checked) {
        setSelectedMembers([
          ...selectedMembers,
          memberId,
        ]);
      } else {
        setSelectedMembers(selectedMembers.filter(id => id !== memberId));
      }
    },
    [selectedMembers]
  );

  const handleOnRemoveAssignees = useCallback(
    () => {
      eventTracking.capture('dataOwners.remove', {
        removal_type: assigneesToRemove.length > 1 ? 'bulk' : 'individual',
      });
      dispatch(
        syncOrganizationKpiMembers(
          organization.slug,
          suborganization.slug,
          type,
          {
            kpiSlugs,
            add: [],
            remove: assigneesToRemove,
          },
          isBulk,
        )
      )
      setRemovingAssignees(true);
    },
    [
      dispatch,
      organization.slug,
      suborganization.slug,
      kpiSlugs,
      type,
      assigneesToRemove,
      isBulk,
      eventTracking,
    ]
  );

  const getAssigneeNameFromId = useCallback(
    (memberId) => assignees.find(({member_id}) => member_id === memberId)?.name,
    [assignees]
  );

  const hasNoActiveAssignees = useMemo(
    () => (
      !assignees.length
      || assignees.every(({hasPermission}) => !hasPermission)
    ),
    [
      assignees,
    ]
  );

  return (
    <div className="Assignees_card">
      <div className="Assignees_card_header">
        <div>
          <Row justify="space-between" className="Assignees_card_header_title">
            <Col>
              <Row
                type="flex"
                align="middle"
                gutter={5}
              >
                <Col>
                  <AplanetIcon size="18px" name={TYPE_ICONS[type]} />
                </Col>
                <Col>
                  {intl.formatMessage({id: `assignees_role_${type}`})}
                </Col>
              </Row>
            </Col>
            <Col>
              { isBulk
                ? null
                : `${assignees.length} ${intl.formatMessage({id: `assignees_role_users`})}`
              }
            </Col>
          </Row>
          <Row justify="space-between" className="Assignees_card_header_subtitle">
            <Col>
              {intl.formatMessage({id: `assignees_role_${type}_description`})}
            </Col>
            <Col>
              <Row
                gutter={5}
              >
                { isBulk
                  ? null
                  : <WarningMessage
                      type={type}
                      hasNoActiveAssignees={hasNoActiveAssignees}
                      validationApprovalLevels={validationApprovalLevels}
                      assignees={assignees}
                    />
                }
                <Col>
                  <AplanetIcon
                    size="18px"
                    name="info-circle"
                    title={
                      <span className="Assignees_card_header_role_information">
                        <FormattedHTMLMessage
                          id={`assignees_role_${type}_information`}
                        />
                      </span>
                    }
                    titlePosition="bottom"
                    titleColor="#117065"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <Row className="Assignees_card_content" style={editMode ? {height: '36vh'} : {}}>
        { editMode
          ? <>
              <Col span={24}>
                <CustomInput
                  placeholder={intl.formatMessage({ id: "assignees_search" })}
                  suffix={<AplanetIcon size="18px" name="search" />}
                  onChange={e => setSearchTerm(e.target.value)}
                />
              </Col>

              <Col span={24} className="Assignees_card_content_list_container">
                <List
                  className="Assignees_card_content_list"
                  itemLayout="horizontal"
                  dataSource={availableMembers}
                  renderItem={member => (
                    <>
                      <List.Item
                        actions={
                          type === 'validator' && member.validationApprovalLevels.length
                          ? [<span>{intl.formatMessage({id: 'assignees_validation_levels'})}: {member.validationApprovalLevels.join(', ')}</span>]
                          : []
                        }
                      >
                        <CustomCheckbox
                          checked={selectedMembers.includes(member.member_id)}
                          onChange={e => handleOnChange(e.target.checked, member.member_id)}
                        ></CustomCheckbox>
                        <List.Item.Meta
                          avatar={<Avatar src={member.member_avatar} />}
                          title={member.member_name}
                          description={member.member_email}
                        />
                      </List.Item>
                    </>
                  )}
                />

                <Row justify="end" gutter={[10]} className="Assignees_card_footer">
                  <Col>
                    <CustomButton
                      onClick={handleOnClickCancel}
                      disabled={pushing}
                    >
                      {intl.formatMessage({id: `assignees_action_cancel`})}
                    </CustomButton>
                  </Col>
                  <Col>
                    <CustomButton
                      type="primary"
                      onClick={handleOnClickSave}
                      disabled={pushing}
                      loading={pushing}
                    >
                      {intl.formatMessage({id: `assignees_action_save`})}
                    </CustomButton>
                  </Col>
                </Row>
              </Col>
            </>
          : <>
              <Col span={24}>
                { !!assignees?.length
                  ? <List
                    itemLayout="horizontal"
                    dataSource={assignees}
                    renderItem={assignee => (
                      <>
                        <List.Item
                          actions={[
                            ...(
                              assignee.hasPermission ? [] :
                              [
                                <AplanetIcon
                                  className="Assignees_card_content_warning_icon"
                                  size="18px"
                                  name="exclamation-triangle"
                                  title={intl.formatMessage({id: `assignees_role_${type}_permission_mismatch`})}
                                  titleColor="#D38106"
                                />
                              ]
                            ),
                            <AplanetIcon
                              className="Assignees_card_content_delete_icon"
                              size="18px"
                              name="trash"
                              onClick={() => setAssigneesToRemove([assignee.member_id])}
                            />,
                          ]}
                        >
                          <List.Item.Meta
                            className={assignee.hasPermission ? '' : 'Assignees_card_content_inactive'}
                            avatar={<Avatar src={assignee.avatar} />}
                            title={assignee.name}
                            description={assignee.email}
                          />
                        </List.Item>
                      </>
                    )}
                    footer={
                      <Row
                        type="flex"
                        justify="space-between"
                      >
                        <Col>
                          <Row
                            className="empty"
                            onClick={handleOnEditRoles}
                            type="flex"
                            align="middle"
                            gutter={5}
                          >
                            <Col>
                              <AplanetIcon size="18px" name="pencil" />
                            </Col>
                            <Col>
                              {intl.formatMessage({id: `assignees_role_${type}_manage`})}
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <AplanetIcon
                            className="Assignees_card_content_delete_icon"
                            size="18px"
                            name="trash"
                            onClick={() => setAssigneesToRemove(assignees.map(({member_id}) => member_id))}
                          />
                        </Col>
                      </Row>
                    }
                  />
                  : <Row
                      className="empty"
                      onClick={handleOnEditRoles}
                      type="flex"
                      align="middle"
                      gutter={5}
                    >
                      <Col>
                        <AplanetIcon size="18px" name="plus" />
                      </Col>
                      <Col>
                        {intl.formatMessage({id: `assignees_role_${type}_add`})}
                      </Col>
                    </Row>
                }
              </Col>
            </>
        }
      </Row>
      <CustomWarningModal
        className="Assignees_remove_modal"
        showModal={!!assigneesToRemove?.length}
        onOk={handleOnRemoveAssignees}
        onCancel={setAssigneesToRemove}
        onOkText={intl.formatMessage({id: assigneesToRemove?.length > 1 ? `assignees_role_remove_users_ok` : `assignees_role_remove_user_ok`})}
        onCancelText={intl.formatMessage({id: assigneesToRemove?.length > 1 ? `assignees_role_remove_users_cancel` : `assignees_role_remove_user_cancel`})}
        title={intl.formatMessage({id: assigneesToRemove?.length > 1 ? `assignees_role_remove_users_title` : `assignees_role_remove_user_title`})}
        content={
          <Row gutter={[0, 10]}>
            <Col span={24}>
              { assigneesToRemove?.length > 1
                ? <>
                    {intl.formatMessage({id: `assignees_role_remove_users_warning_content`})} <span className="Assignees_remove_modal_highlight">{intl.formatMessage({id: `assignees_role_${type}`})}</span>.
                  </>
                : <>
                    {intl.formatMessage({id: `assignees_role_remove_user_warning_content`}, {name: getAssigneeNameFromId(assigneesToRemove?.[0])})} <span className="Assignees_remove_modal_highlight">{intl.formatMessage({id: `assignees_role_${type}`})}</span>.
                  </>
              }
            </Col>
            <Col span={24}>
              <b>
                { assigneesToRemove?.length > 1
                  ? intl.formatMessage({id: `assignees_role_remove_users_warning_confirmation`}, {role: intl.formatMessage({id: `assignees_role_${type}`})})
                  : intl.formatMessage({id: `assignees_role_remove_user_warning_confirmation`}, {role: intl.formatMessage({id: `assignees_role_${type}`}), name: getAssigneeNameFromId(assigneesToRemove?.[0])})
                }
              </b>
            </Col>
          </Row>
        }
        icon="trash"
        loading={pushing || removingAssignees}
      />
    </div>
  )
};

export default injectIntl(RoleCard);
