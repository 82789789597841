/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import useOrganizations from 'utils/useOrganizations';
import CustomTable from 'components/CustomTable';
import EqualityPlanHeader from 'components/EqualityPlan/Header';
import UploadButton from 'containers/UploadButton';
import AuthorizedLink from 'containers/AuthorizedLink';
import Avatar from 'components/Avatar';
import { getEqualityPlanUploadUrl } from 'actions/api';
import { uploadEqualityPlanDocumentSuccess } from 'actions/attachments';
import {
  formatDate,
  getYear,
  fromDbDate,
} from 'utils/date';

import ReactMarkdown from 'react-markdown';

import {
  Row,
  Col,
  Divider,
  Popover,
  Radio,
} from 'antd';

import { UserOutlined } from '@ant-design/icons';
import CustomSelect from 'components/CustomSelect';

const planStages = ['other_documents', 'other_documents_followup'];

const EqualityPlanDocumentManagerOtherDocuments = ({
  intl,
  planId,
  stage,
  documents,
  onFetchDocuments,
  guidelines,
  accept,
  onUploadSuccess,
  updatePlanProgress,
  reportingStartDate,
}) => {
  const t = intl.messages;
  const [period, setPeriod] = useState();
  const [normalizeSalary, setNormalizeSalary] = useState(true);
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();

  const documentUploadUrl = useMemo(
    () => getEqualityPlanUploadUrl(
      organization.slug,
      suborganization.slug,
      planId,
      stage,
      'attachment'
    ),
    [organization, suborganization, planId, stage],
  );

  const onDocumentUploadSuccess = useCallback(
    (response) => {
      if (onUploadSuccess) {
        onUploadSuccess();
      }
      if (['other_documents_followup', 'other_documents'].includes(stage)) {
        updatePlanProgress(stage);
      }
      dispatch(
        uploadEqualityPlanDocumentSuccess(JSON.parse(response))
      )
    },
    [dispatch, onUploadSuccess, updatePlanProgress, stage]
  );

  useEffect(
    () => {
      if (stage === 'other_documents_followup') {
        // Fetch follow up documents
        onFetchDocuments(
          'committee_followup,other_documents_followup', 'attachment'
        );
      } else if (stage === 'other_documents') {
        // Fetch all other documents of equality plan
        onFetchDocuments(
          'org_commitment,committee,diagnosis,plan_documents,other_documents',
          'attachment',
        );
      } else if (stage === 'data_collection') {
        // Fetch all other documents of equality plan
        onFetchDocuments('data_collection', 'attachment');
      }
    },
    [stage, onFetchDocuments]
  );

  const columns = useMemo(() => {
    const headers = [{
      title: t.name,
      dataIndex: 'member_name',
      key: 'name',
      render: (name, record) => (
        <React.Fragment>
          <Row type="flex" gutter={10} justify="start" align="middle" style={{ flexWrap: 'nowrap' }}>
            <Col>
              <Avatar
                src={record.member_avatar}
                name={record.member_name}
                shape="circle"
                size={38}
                icon={<UserOutlined />}
              />
            </Col>

            <Col>
              <Row type="flex" gutter={2}>
                <Col className="DataManagement__nobreak">
                  { name }
                </Col>
              </Row>
              <div className="DataManagement__nobreak">
                { record.member_email }
              </div>
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t.file,
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: t.date,
      dataIndex: 'created_at',
      key: 'date',
      render: (created_at) => (
        <span>
          {`${formatDate(created_at, 'D MMM YYYY HH:mm', intl)}`}
        </span>
      ),
    }, {
      title: t.action,
      key: 'action',
      render: (_, record) => (
        <AuthorizedLink
          href={record.download}
          download={record.filename}
          mimeType={record.mimetype}
        >
          { t.download }
        </AuthorizedLink>
      ),
    }];

    if (planStages.includes(stage)) {
      return [
        ...headers.slice(0, 1),
        {
          title: t.equality_plan_step,
          dataIndex: 'step',
          key: 'step',
          render: text => t[`equality_${text}`]
        },
        ...headers.slice(1, headers.length)
      ]
    }
    return headers;
  }, [
    intl,
    t,
    stage,
  ]);

  const periods = useMemo(
    () => {
      if (stage === 'data_collection') {
        const start_year = getYear(fromDbDate(reportingStartDate));
        const end_year = getYear(new Date());

        if(end_year < start_year) {
          return [];
        }

        const years = new Array(end_year - start_year + 1)
          .fill()
          .map((_, offset) => start_year + offset);
        return years.map(year => ({name: year, slug: year}));
      }
      return [];
    },
    [reportingStartDate, stage]
  );

  return (
    <div className="EqualityPlanDocumentManagerOtherDocuments">
      { planStages.includes(stage) 
        ? <EqualityPlanHeader
            planId={planId}
            title={stage === 'other_documents_followup'
              ? t.equality_other_documents_followup
              : t.equality_plan_other_documents
            }
          />
        : null
      }
      { stage === 'data_collection' &&
        <>
          <Row
            type='flex'
            justify='center'
            className="EqualityPlanDocumentManagerOtherDocuments-periods"
          >
            <Col span={5}>
              <CustomSelect
                title={t.equality_data_collection_select_period}
                selected={period}
                options={periods}
                onSelect={setPeriod}
              />
            </Col>
          </Row>
          {period &&
            <Row
              type='flex'
              justify='center'
            >
              <Radio.Group
                onChange={e => setNormalizeSalary(e.target.value)}
                value={normalizeSalary}
              >
                <Radio value={true}>
                  {t.equality_data_collection_normalize_salary}
                </Radio>
                <Radio value={false}>
                  {t.equality_data_collection_do_not_normalize_salary}
                </Radio>
              </Radio.Group>
            </Row>
          }
        </>
      }
      { stage === 'data_collection' && !period
        ? null
        : <Row
            type="flex"
            justify="center"
          >
            <Col>
              <UploadButton
                actionUrl={documentUploadUrl}
                onSuccess={onDocumentUploadSuccess}
                section={`equality_plan${stage === 'data_collection' ? '_data_collection' : ''}`}
                { ...( accept && { accept } ) }
                data={
                  (stage === 'data_collection' && period)
                    ? { period, normalizeSalary }
                    : {}
                }
              >
              </UploadButton>
            </Col>
          </Row>
      }
      { guidelines
        ? <Row type='flex' justify='center'>
            <Popover
              placement="bottom"
              content={
                <p className='popover-text'>
                  <ReactMarkdown
                    source={guidelines}
                  />
                </p>
              }
              trigger="click"
            >
              <a>{t.equality_plan_other_documents_guidelines}</a>
            </Popover>
          </Row>
        : null
      }
      { stage === 'data_collection' &&
        <Row
          type='flex'
          justify='center'
          className="EqualityPlanDocumentManagerOtherDocuments-download-template"
        >
          <a
            href={process.env.PUBLIC_URL + `/documents/equality/template_data_collection_${intl.locale}.xlsx`}
            download
          >{t.equality_plan_other_documents_download_template}</a>
        </Row>
      }
      <Divider />
      <Row
        type="flex"
        justify="center"
      >
        <Col>
          <CustomTable
            columns={columns}
            dataSource={documents}
            pagination={false}
            rowKey='id'
          />
        </Col>
      </Row>
    </div>
  );
}

export default injectIntl(EqualityPlanDocumentManagerOtherDocuments);
