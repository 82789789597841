const TARGET = 'equality_plan_professional_group';

const initialState = {
  error: null,
  fetching: false,
  pushing: false,
  data: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        fetching: action.method === 'GET',
        pushing: ['PUT', 'POST', 'DELETE'].includes(action.method),
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || response.target !== TARGET) return state;
      const result = response.result;
      switch(response.method) {
        case 'GET':
          return {
            ...state,
            fetching: false,
            data: result,
          };
        case 'POST':
          return {
            ...state,
            pushing: false,
            data: [...state.data, result],
          };
        case 'PUT':
          const index = state.data.map(
            item => item.id
          ).indexOf(parseInt(result.id));
          return {
            ...state,
            data: [
              ...state.data.slice(0, index),
              result,
              ...state.data.slice(index + 1)
            ],
            pushing: false,
          };
        case 'DELETE':
          const data = state.data.filter(d => d.id !== parseInt(result.id));
          return {...state, data};
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        fetching: false,
        pushing: false,
        error: action.code,
      };
    default:
      return state;
  }
};

export {
  reducer as equality_plan_professional_group,
};
