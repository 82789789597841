import React from 'react';
import { injectIntl } from 'react-intl';
import CustomButton from 'components/CustomButton';
import CustomTextArea from 'components/CustomTextArea';
import CustomModal from 'components/CustomModal';
import './style.less';

const ModalEditTableText = ({
  intl,
  value,
  visible,
  onClose,
  onChange
}) => {
  const t = intl.messages;
  return (
    <CustomModal
        className="ModalEditTableText"
        shown={visible}
        onOk={onClose}
        onCancel={onClose}
        title={t.modaledittabletext_title}
        destroyOnClose
        footer={
          <CustomButton
              className="ModalEditKPI__btn-footer"
              key="back"
              onClick={onClose}
            >
              {t.modaledittabletext_close}
            </CustomButton>
        }
      >
        <CustomTextArea
          value={value}
          onChange={onChange}
        />
      </CustomModal>
  );
}

export default injectIntl(ModalEditTableText);
