export const fullValidation = (t) => () => {
  let errors = {};

  return errors;
};

export const actionByAreaValidation = (t) => ({
  area,
  actions_old,
  actions_new,
}) => {
  let errors = {};

  if (!area) {
    errors.area = t.form_error_required;
  }

  if (actions_old === undefined || actions_old === null) {
    errors.actions_old = t.form_error_required;
  }

  if (actions_new === undefined || actions_new === null) {
    errors.actions_new = t.form_error_required;
  }
  return errors;
};

export const beneficiaryByAreaValidation = (t) => ({
  area,
  male,
  female,
}) => {
  let errors = {};

  if (!area) {
    errors.area = t.form_error_required;
  }

  if (male === undefined || male === null) {
    errors.male = t.form_error_required;
  }

  if (female === undefined || female === null) {
    errors.female = t.form_error_required;
  }
  return errors;
};

export const beneficiaryByCategoryValidation = (t) => ({
  category,
  male,
  female,
}) => {
  let errors = {};

  if (!category) {
    errors.category = t.form_error_required;
  }

  if (male === undefined || male === null) {
    errors.male = t.form_error_required;
  }

  if (female === undefined || female === null) {
    errors.female = t.form_error_required;
  }
  return errors;
};
