import React, { useState, useMemo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Rows from 'components/CreateEditCustomKPI/forms/Table/Rows';
import Columns from 'components/CreateEditCustomKPI/forms/Table/Columns';
import Overview from 'components/CreateEditCustomKPI/forms/Table/Overview';

import { validateSchema } from 'utils/kpi_schema';
import useOrganizations from 'utils/useOrganizations';

import { getNormalizedKpiVariables } from 'actions/api';

import {
  Row,
  Col,
  Tabs,
} from 'antd';


const Table = ({
  intl,
  mode = 'create',
  values,
  onChange,
  dirty,
  setDirty,
  error,
  required = false
}) => {
  const dispatch = useDispatch();
  const t = intl.messages;
  const [activeTabKey, setActiveTabKey] = useState('rows');
  const [affectsKpiValue, setAffectsKpiValue] = useState([]);

  const {
    organization,
    suborganization,
  } = useOrganizations();

  useEffect(
    () => {
      if (dirty && affectsKpiValue.length === 0) {
        setDirty(false);
      } else if (!dirty && affectsKpiValue.length) {
        setDirty(true);
      }
    },
    [
      dirty,
      setDirty,
      affectsKpiValue,
    ],
  );

  useEffect(
    () => {
      dispatch(
        getNormalizedKpiVariables(organization.slug, suborganization.slug)
      );
    },
    [
      dispatch,
      organization.slug,
      suborganization.slug,
    ]
  );

  const schema = useMemo(
    () => ({
      dimensions: values.dimensions,
      innerSchema: values.innerSchema,
      type: "table",
    }),
    [values]
  );

  return (
    <section className="CreateCustomKpi-table ModalEditKPI-table">
      <div className="CreateEditCustomKPI-title">
        {required
          ? (
            <div className='CreateEditCustomKPI-title__required'>
              *
            </div>
          )
          : ""
        }
        {t.createcustomkpi_table_rows_and_columns}
      </div>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Tabs
            className="CreateCustomKpi-table-tabs ModalEditKPI-table-tabs"
            activeKey={activeTabKey}
            onChange={setActiveTabKey}
            tabBarStyle={{ textAlign: 'center' }}
          >
            <Tabs.TabPane tab={t.createcustomkpi_table_tab_rows} key={'rows'}>
              <Rows
                mode={mode}
                values={values}
                onChange={onChange}
                affectsKpiValue={affectsKpiValue}
                setAffectsKpiValue={setAffectsKpiValue}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t.createcustomkpi_table_tab_columns} key={'columns'}>
              <Columns
                mode={mode}
                values={values}
                onChange={onChange}
                affectsKpiValue={affectsKpiValue}
                setAffectsKpiValue={setAffectsKpiValue}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t.createcustomkpi_table_tab_overview} key={'overview'}>
              {validateSchema(schema) &&
                <Overview
                  mode={mode}
                  values={values}
                  onChange={onChange}
                />
              }
            </Tabs.TabPane>
          </Tabs>
        </Col>
        {error &&
          <Col span={24}>
            <div className="CreateCustomKpi__error ModalEditKPI__error">{error}</div>
          </Col>
        }
      </Row>
    </section>
  );
};

export default injectIntl(Table);
