import React, {useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {isNull, isUndefined} from 'lodash';

import {
  Select,
} from 'antd';
import CustomInput from 'components/CustomInput';

const { Option } = Select;

const NOT_NUMBER_RE = new RegExp('\\D', 'g');

const RECURRENCY_TIME = ['days', 'weeks', 'months'];
const RECURRENCY_RELATIVE_TO_PERIOD = ['after_start', 'before_end', 'after_end'];
const FORM_KEYS_NOTIFICATION = {
    notif_number: 'notif_number',
    notif_relative: 'notif_relative',
    notif_time: 'notif_time',
};

const FORM_KEY_RECURRENCY = 'recurrency';
const FORM_KEY_RECURRENCY_RECURRENT = 'recurrent';

const RecurrentNotificationDate = ({
    intl,
    disabled,
    handleChange,
    notificationNumber,
    notificationTime,
    notificationRelative,
    notificationNumberError,
    recurrency,
}) => {

    const INTEGER_FORMATTER = useCallback(intl => (value) => `${value}`.replace(NOT_NUMBER_RE, ''), []);
    const INTEGER_PARSER = useCallback(intl => value => value.replace(NOT_NUMBER_RE, ''), []);

    const schemaProps = useMemo(() => {
        return {
            precision: 0,
            step: 1,
            formatter: (INTEGER_FORMATTER)(intl),
            parser: (INTEGER_PARSER)(intl),
        };
    }, [
        INTEGER_FORMATTER,
        INTEGER_PARSER,
        intl,
    ]);

    const handleChangeValues = useCallback((type, value) => {
        if (!recurrency) {
            handleChange(FORM_KEY_RECURRENCY)(FORM_KEY_RECURRENCY_RECURRENT);
        }
        handleChange(type)(value);
    }, [
        handleChange,
        recurrency,
    ]);

    return (
        <div className="RecurrentNotificationDate">
            <div className={`RecurrentNotificationDate__label is-required ${disabled ? 'is-disabled' : ''}`}>{intl.formatMessage({id: 'step_2_select_recurrent_notification'})}</div>
            <div className="RecurrentNotificationDate__labels">
                <div className="RecurrentNotificationDate__numberLabel">{intl.formatMessage({id: 'step_2_number'})}</div>
                <div className="RecurrentNotificationDate__timeLabel">{intl.formatMessage({id: 'step_2_time'})}</div>
                <div className="RecurrentNotificationDate__periodLabel">{intl.formatMessage({id: 'step_2_relative_to_period'})}</div>
            </div>
            <div className="RecurrentNotificationDate__notificationData">
                <CustomInput.Number
                    {...schemaProps}
                    className={`
                        RecurrentNotificationDate__number 
                        ${disabled ? 'is-disabled' : ''}
                        ${(!isNull(notificationNumber) && !isUndefined(notificationNumber)) && notificationNumberError ? 'has-error' : ''}
                    `}
                    disabled={disabled}
                    onChange={value => handleChangeValues(FORM_KEYS_NOTIFICATION.notif_number, value)}
                    value={notificationNumber}
                />
                <Select
                    className="RecurrentNotificationDate__time"
                    showArrow
                    disabled={disabled}
                    onSelect={value => handleChangeValues(FORM_KEYS_NOTIFICATION.notif_time, value)}
                    value={notificationTime}
                >
                    {RECURRENCY_TIME.map(time => 
                        (<Option key={time}>{intl.formatMessage({ id: `step_2_time_${time}` })}</Option>
                    ))}
                </Select>
                <Select
                    className="RecurrentNotificationDate__period"
                    showArrow
                    disabled={disabled}
                    onSelect={value => handleChangeValues(FORM_KEYS_NOTIFICATION.notif_relative, value)}
                    value={notificationRelative}
                >
                    {RECURRENCY_RELATIVE_TO_PERIOD.map(relative => (
                        <Option key={relative}>{intl.formatMessage({ id: `step_2_relative_to_period_${relative}` })}</Option>
                    ))}
                </Select>
            </div>
            {(!isNull(notificationNumber) && !isUndefined(notificationNumber)) && notificationNumberError ? (
                <span className="RecurrentNotificationDate__numberError">{notificationNumberError}</span>
            ) : null}
        </div>
    )
};

RecurrentNotificationDate.propTypes = {
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    notificationNumber: PropTypes.number,
    notificationTime: PropTypes.string,
    notificationRelative: PropTypes.string,
    notificationNumberError: PropTypes.string,
    recurrency: PropTypes.string,
};

export default RecurrentNotificationDate;
