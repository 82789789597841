import { EMAIL_REGEXP } from 'utils/validation';

export const fullValidation = (t, stage) => ({
  name,
  start_date,
  end_date,
  indicators,
  measure_id,
}) => {
  let errors = {};

  if (!name) {
    errors.name = t.form_error_required;
  }

  if (!start_date) {
    errors.start_date = t.form_error_required;
  }

  if (!end_date) {
    errors.end_date = t.form_error_required;
  }

  if (!indicators || indicators.length <= 0) {
    errors.indicators = t.form_error_required;
  } 

  if (!measure_id && stage === 'actions') {
    errors.measure_id = t.form_error_required;
  }

  return {
    ...errors,
  };
};

export const addObjectiveValidation = (t) => ({
  objective_id,
}) => {
  let errors = {};

  if (!objective_id) {
    errors.objective_id = t.form_error_required;
  }

  return errors;
};

export const peopleValidation = (t) => ({
  name,
  email,
}) => {
  let errors = {};

  if (!name) {
    errors.name = t.form_error_required;
  }

  if (email && !EMAIL_REGEXP.test(email)) {
    errors.email = t.form_error_invalid_email;
  }

  return errors;
};

export const targetGroupValidation = (t) => ({
  name,
}) => {
  let errors = {};

  if (!name) {
    errors.name = t.form_error_required;
  }

  return errors;
};

export const indicatorValidation = (t) => ({
  name,
  start_date,
  end_date,
  current_value,
  target_value,
  periodicity,
  information_tool,
}) => {
  let errors = {};

  if (!name) {
    errors.name = t.form_error_required;
  }

  if (!start_date) {
    errors.start_date = t.form_error_required;
  }

  if (!end_date) {
    errors.end_date = t.form_error_required;
  }

  if (!current_value && current_value !== 0) {
    errors.current_value = t.form_error_required;
  }

  if (!target_value) {
    errors.target_value = t.form_error_required;
  }

  if (target_value <= current_value) {
    errors.target_value = t.form_error_min_target_value;
  }

  if (!periodicity) {
    errors.periodicity = t.form_error_required;
  }

  if (!information_tool) {
    errors.information_tool = t.form_error_required;
  }

  return errors;
};