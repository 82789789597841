import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { injectIntl } from 'react-intl';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from '@emotion/react'
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';

import config from 'config';
import { Routes } from 'containers/Routes';
import AcceptTermsModal from 'components/AcceptTermsModal'
import { FeatureProvider } from 'components/FeatureSwitch';
import { useEventTracking } from 'hooks/useEventTracking';
import { updateProfile } from 'actions/api';
import updateTheme from 'utils/updateTheme';

const Root = ({
  intl
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, profile } = useSelector(state => state);

  const eventTracking = useEventTracking();
  const [theme, setTheme] = useState({}); // TODO - configure default theme
  const [features, setFeatures] = useState({
    features: new Set(
      (organization.data || {}).features ||
      config.DEFAULT_FEATURES
    ),
    upselling: new Set(
      (organization.data || {}).features_upselling ||
      config.DEFAULT_FEATURES_UPSELLING
    ),
  });

  // Request the profile upon login / org changes
  useEffect(() => {
    const theme = organization.data?.config?.theme;
    updateTheme(theme);
    if(theme) {
      setTheme(theme);
    }
  }, [
    organization.data,
  ]);

  useEffect(() => {
    if(!config.INTERCOM_APP_ID) {
      return;
    }
    if (organization.data?.features.includes("chatbot")){
      window.Intercom('boot', {
         app_id: config.INTERCOM_APP_ID,
         user_id: organization.data.name
      });
    } else {
      window.Intercom('shutdown', {
         app_id: config.INTERCOM_APP_ID,
      });
    }
  }, [ organization ]);

  useEffect(() => {
    eventTracking.init();
  }, [eventTracking]);

  const acceptTerms = useCallback(
    (termType) => dispatch(updateProfile(organization.data.slug, {[termType]: true})),
    [organization.data?.slug, dispatch]
  );

  return (
    <Sentry.ErrorBoundary
      fallback={
      <ErrorBoundaryFallback
        titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
        buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
        descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_default_message' })}
        customErrorImage="/images/error_image.png"
      />
    }>
      <ThemeProvider theme={theme}>
        <FeatureProvider
          value={{
            ...features,
            setFeatures,
          }}
        >
          <ConfigProvider locale={intl.formats.antd}>
            <Routes />
            <AcceptTermsModal
              title={t.terms_and_conditions_title}
              body={t.terms_and_conditions_body}
              lastTermsVersion={profile?.data?.last_terms_and_conditions_version}
              lastTermsAccepted={profile?.data?.last_terms_accepted}
              onAccept={() => acceptTerms('last_terms_accepted')}
              closable={false}
            />
            <AcceptTermsModal
              title={t.organization_policy}
              body={profile?.data?.organization?.terms}
              lastTermsVersion={profile?.data?.organization?.org_last_terms_version}
              lastTermsAccepted={profile?.data?.org_last_terms_accepted}
              onAccept={() => acceptTerms('org_last_terms_accepted')}
              privacyEmail={organization?.data?.general_config?.contact_email}
              closable={false}
            />
          </ConfigProvider>
        </FeatureProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}

if(config.INTERCOM_APP_ID) {
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/'+config.INTERCOM_APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

  window.Intercom('boot', {
     app_id: config.INTERCOM_APP_ID,
  });

  window.Intercom('shutdown', {
     app_id: config.INTERCOM_APP_ID,
  });
}

export default injectIntl(Root);
