import React from "react";


const Badge = ({
  background = '#F0F0F0',
  color = '#23302E',
  children,
  ...rest
}) => {
  return (
    <span className="BulkManage__badge" style={{ background, color }} {...rest}>
      { children }
    </span>
  );
}

export default Badge;
