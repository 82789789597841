import {
  validateSchema,
} from 'utils/kpi_schema';

const ANNUAL_START_DATE = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');
const CYCLE_DATE_PART_PATRON = /-(.*)/;

const fullValidation = (intl, cycleDateOptions) => ({
  name_translations,
  locale,
  sdgs = [],
  type,
  dimensions,
  innerSchema,
  code,
  gri_enabled = false,
  equality_enabled = false,
  gri = [],
  equality = [],
  annual_start_date,
}) => {
  let errors = {};
  if(!(name_translations.find(nt => nt.locale === locale)?.name !== '')) {
    errors.name_translations = intl.formatMessage({ id: "form_error_required"});
  }

  if (type === 'table' && !validateSchema({type, dimensions, innerSchema})) {
    errors.schema = intl.formatMessage({ id: "form_error_invalid_schema"});
  }

  if(code && code.length > 20) {
    errors.code = intl.formatMessage({id: "form_error_max_length"}, {max_length: 20});
  }

  if((gri_enabled && gri.find(g => !g.category_slug || !g.code))
    || (equality_enabled && equality.find(g => !g.category_slug || !g.code))) {
    errors.reporting_standards = intl.formatMessage({id: "form_error_invalid_schema"});
  }

  const isValidAnnualStartDate = annual_start_date && ANNUAL_START_DATE.test(annual_start_date);
  const cycleDate = isValidAnnualStartDate ? annual_start_date.match(CYCLE_DATE_PART_PATRON)[1] : null;
  if(isValidAnnualStartDate && cycleDate && !cycleDateOptions.includes(cycleDate)) {
    errors.annual_start_date = intl.formatMessage({id: "form_error_annual_start_date"});
  }

  return errors;
};

export default fullValidation;
