import React, {
  useMemo,
} from 'react'
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import chroma from "chroma-js";

import { isEmpty, isEqual } from 'lodash';
import { defaultColors } from '../useColors.js';
import { cardMap, convertData } from './types';
import { propTypes } from '../propTypes';

const DEFAULTS = {
  parameters: {},
  data: {},
  dashboardTheme: defaultColors,
  showDataTarget: false
};
  
const CustomCardChart = ({
  intl,
  type,
  data = DEFAULTS.data,
  parameters = DEFAULTS.parameters,
  dashboardTheme = DEFAULTS.dashboardTheme,
  showDataTarget = DEFAULTS.showDataTarget,
}) => {

  const filledDashboardTheme = useMemo(() => {
    return !isEmpty(data) ? data.series.map((_, i) => dashboardTheme[i] || chroma.random().hex()) : []
  }, [data, dashboardTheme])
  
  const adaptedData = useMemo(() => {
    return convertData(intl, type, data, parameters, filledDashboardTheme, showDataTarget);
  }, [intl, type, data, parameters, filledDashboardTheme, showDataTarget]);

  const Content = cardMap[type] || cardMap.default;

  return (
    <Content data={adaptedData} />
  );
};
  
CustomCardChart.propTypes = {
  type: PropTypes.string.isRequired,
  data: propTypes.data,
  parameters: PropTypes.object,
  dashboardTheme: PropTypes.arrayOf(PropTypes.string),
  showDataTarget: PropTypes.bool,
};

function cardPropsAreEqual(prevCard, nextCard){
  return isEqual(prevCard.data, nextCard.data);
}

export default React.memo(injectIntl(CustomCardChart), cardPropsAreEqual);