import {
  validateSchema,
} from 'utils/kpi_schema';

const fullValidation = (intl) => ({
  name_translations,
  locale,
  sdgs = [],
  type,
  dimensions,
  innerSchema,
  code,
  gri_enabled = false,
  equality_enabled = false,
  gri = [],
  equality = [],
}) => {
  let errors = {};
  if(!(name_translations.find(nt => nt.locale === locale)?.name !== '')) {
    errors.name_translations = intl.formatMessage({ id: "form_error_required"});
  }

  if (type === 'table' && !validateSchema({type, dimensions, innerSchema})) {
    errors.schema = intl.formatMessage({ id: "form_error_invalid_schema"});
  }

  if(code && code.length > 20) {
    errors.code = intl.formatMessage({id: "form_error_max_length"}, {max_length: 20});
  }

  if((gri_enabled && gri.find(g => !g.category_slug || !g.code))
    || (equality_enabled && equality.find(g => !g.category_slug || !g.code))) {
    errors.reporting_standards = intl.formatMessage({id: "form_error_invalid_schema"});
  }
  return errors;
};

export default fullValidation;
