const resetKpiHistory = () => ({
  type: 'RESET_DATA',
  target: 'kpi_history'
});

const resetDataHistory = () => ({
  type: 'RESET_DATA',
  target: 'data_history'
});

const resetDashboardChart = () => ({
  type: 'RESET_DATA',
  target: 'dashboard_chart'
});

const resetReportHistory = () => ({
  type: 'RESET_DATA',
  target: 'report_history'
});

const resetKpiExcel = () => ({
  type: 'RESET_DATA',
  target: 'kpi_excel_download'
});

export {
  resetKpiHistory,
  resetDataHistory,
  resetDashboardChart,
  resetReportHistory,
  resetKpiExcel,
};
