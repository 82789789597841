import React, { Fragment, useEffect, useState, useCallback, useMemo } from 'react';
import { injectIntl, FormattedHTMLMessage} from 'react-intl';

import CustomButton from 'components/CustomButton';
import useOrganizations from 'utils/useOrganizations';
import Portal from 'components/Portal';
import GroupOfInterestForm from 'components/GroupOfInterestForm';
import UploadButton from 'containers/UploadButton';

import { createExcel } from 'utils/excel';

import {
  Button,
  Col,
  Layout,
  List,
  Menu,
  Modal,
  Row,
  notification,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  UploadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';

import {
  getGroupOfInterestBulkUploadUrl,
} from 'actions/api';

import './style.less';

const { Sider } = Layout;

const GroupsOfInterest = ({
  intl,
  requestGroupsOfInterest,
  createGroupOfInterest,
  deleteGroupOfInterest,
  editGroupOfInterest,
  createGoiMember,
  editGoiMember,
  deleteGoiMember,
  changeCurrentGroup,
  groups,
  group,
}) => {
  const t = intl.messages;
  const { organization, suborganization } = useOrganizations();

  const {
    slug: mainOrgSlug,
  } = organization;

  const {
    slug: subOrgSlug,
  } = suborganization;

  const [itemFormDefaultValues, setItemFormDefaultValues] = useState({});
  const [itemFormShown, setItemFormShown] = useState(false);
  const [itemFormPlaceholderId, setItemFormPlaceholderId] = useState(null);
  const [itemFormAction, setItemFormAction] = useState('edit');
  const [itemFormType, setItemFormType] = useState('group');

  useEffect(() => {
    requestGroupsOfInterest(mainOrgSlug, subOrgSlug);
  }, [mainOrgSlug, requestGroupsOfInterest, subOrgSlug]);

  useEffect(() => {
    const targetSubheader =  document.getElementsByClassName(`GroupsOfInterest__subheader-${itemFormType}`)[0];
    const subheaderOpenClassName = 'GroupsOfInterest__subheader--open';

    if (itemFormShown && itemFormAction === 'create') {
      targetSubheader.classList.add(subheaderOpenClassName);
    } else if (targetSubheader.classList.length > 2) {
      targetSubheader.classList.remove(subheaderOpenClassName);
    }
  }, [ itemFormAction, itemFormShown, itemFormType ]);

  const isMember = useCallback(
    (item) => !!item.goi_id,
    []
  );

  const onDownloadXlsx = useCallback(() => {
    const templateHeader = [
      t.analysis_survey_goi_name,
      t.analysis_survey_goi_type,
      t.analysis_survey_goi_member_name,
      t.analysis_survey_goi_member_email,
      t.analysis_survey_goi_member_description,
    ];

    createExcel(
      `stakeholders_template.xlsx`,
      null,
      [templateHeader],
      true,
      [
        {col: 'B', values: [t.goi_internal, t.goi_external]},
      ]
    );
  }, [
    t,
  ]);

  const onBulkUploadSuccess = useCallback(() => {
    notification.open({
      message: t.goi_data_uploaded,
      description: t.goi_data_uploaded_desc,
      onClick: () => requestGroupsOfInterest(mainOrgSlug, subOrgSlug),
      onClose: () => requestGroupsOfInterest(mainOrgSlug, subOrgSlug),
      icon: <LoadingOutlined />,
    });
  }, [
    t,
    requestGroupsOfInterest,
    mainOrgSlug,
    subOrgSlug,
  ]);

  const getGroupById = useCallback(
    (targetGroupId, groupsObj=groups) => {
      return Object.values(groupsObj)
        .filter(group => group.id === parseInt(targetGroupId, 10))
        .shift();
    },
    [groups]
  );

  const showRemoveItemConfirm = useCallback(
    (targetItem) => {
      Modal.confirm({
        title: t.groups_of_interest_confirm_title.replace('{item}', targetItem.name),
        content: t.remove_action_confirm_warning,
        onOk() {
          if (isMember(targetItem)) {
            deleteGoiMember(mainOrgSlug, targetItem.goi_id, targetItem.id);
          } else {
            deleteGroupOfInterest(mainOrgSlug, subOrgSlug, targetItem.id);
          }
        }
      });
    },
    [isMember, deleteGroupOfInterest, deleteGoiMember, mainOrgSlug, subOrgSlug, t]
  );

  const restoreItemForm = useCallback(
    () => {
      setItemFormAction('edit');
      setItemFormPlaceholderId(null);
      setItemFormDefaultValues({});
    },
    []
  );

  const onMenuItemClick = useCallback(
    (menuItem) => {
      const currentGroup = getGroupById(menuItem.key);
      changeCurrentGroup(currentGroup);
      setItemFormShown(false);
      setItemFormDefaultValues(currentGroup);
    },
    [changeCurrentGroup, getGroupById]
  );

  const onRemoveClick = useCallback(
    (targetItem) => {
      showRemoveItemConfirm(targetItem);
    },
    [showRemoveItemConfirm]
  );

  const onCancelClick = useCallback(
    () => {
      setItemFormShown(false);
      restoreItemForm();
    },
    [setItemFormShown, restoreItemForm]
  );

  const showItemForm = useCallback(
    (values) => {
      setItemFormShown(false);
      setItemFormAction(values.itemFormAction);
      setItemFormType(values.itemFormType);
      setItemFormPlaceholderId(values.itemFormPlaceholderId);
      setItemFormDefaultValues(values.itemFormDefaultValues);
      setTimeout(() => setItemFormShown(true)); // This needs to render as false and THEN render as true
    },
    []
  );

  const onSubmit = useCallback(
    (mainOrgSlug, payload) => {
      const { id, name, email, type, description } = payload;
      if (itemFormType === 'member') {

        if (itemFormAction === 'create') {
          createGoiMember(mainOrgSlug, group.id, name, email, description);
        } else {
          editGoiMember(mainOrgSlug, group.id, id, name, email, description)
        }
        setItemFormShown(false);
      } else {
        if (itemFormAction === 'create') {
          createGroupOfInterest(mainOrgSlug, subOrgSlug, name, type);
          showItemForm({
            itemFormAction: 'create',
            itemFormType: 'member',
            itemFormPlaceholderId: 'item-form-member-placeholder',
            itemFormDefaultValues: {
              id: payload.id
            },
          });
        } else {
          editGroupOfInterest(mainOrgSlug, subOrgSlug, id, name, type);
          setItemFormShown(false);
        }
      }
    },
    [
      itemFormType,
      itemFormAction,
      createGoiMember,
      group.id,
      editGoiMember,
      createGroupOfInterest,
      subOrgSlug,
      showItemForm,
      editGroupOfInterest
    ]
  );

  const itemActionButtons = useCallback(
    (item) => {
      let itemFormTypeValue = 'group';
      let itemFormPlaceholderIdValue = `item-form-${itemFormTypeValue}-placeholder`;

      if (isMember(item)) {
        itemFormTypeValue = 'member';
        itemFormPlaceholderIdValue = `item-form-${itemFormTypeValue}-placeholder-${item.id}`;
      }

      return (
        <div>
          <Button
            icon={<EditOutlined />}
            shape="circle"
            className="GroupsOfInterest__item-actions-button"
            onClick={() => {
              restoreItemForm();
              showItemForm({
                itemFormAction: 'edit',
                itemFormType: itemFormTypeValue,
                itemFormPlaceholderId: itemFormPlaceholderIdValue,
                itemFormDefaultValues: item,
              });
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            shape="circle"
            className="GroupsOfInterest__item-actions-button"
            onClick={ () => {
              onRemoveClick(item);
            }}
          />
        </div>
      );
    },
    [isMember, onRemoveClick, restoreItemForm, showItemForm]
  );


  const groupsItems = useMemo(
    () => groups.map(group => {
      const numMembers = group.members.length;
      const groupType = t[`goi_${group.type}`];

      return (
        <Menu.Item
          key={ group.id }
          className="GroupsOfInterest__groups-item"
        >
          <span>{ `${groupType} : ${group.name} (${numMembers})` }</span>
          { itemActionButtons(group) }
        </Menu.Item>
      );
    }),
    [t, groups, itemActionButtons]
  );

  const memberItem = useCallback(
    (member) => {
      return (
        <List.Item
          key={ member.id }
          className="GroupsOfInterest__member-item"
        >
          <div className="GroupsOfInterest__member-item-inner-wrapper">
            <span>{member.name}</span>
            <span>{member.email}</span>
            <span>{member.description}</span>
            { itemActionButtons(member) }
          </div>
          <div id={ `item-form-member-placeholder-${member.id}` }></div>
        </List.Item>
      );
    },
    [itemActionButtons]
  );

  return (
    <div className="GroupsOfInterest">
      <div className="GroupsOfInterest__header">
        <h1 className="GroupsOfInterest__title">{t.groups_of_interest}</h1>
      </div>

      <div className="GroupsOfInterest__description">
        <FormattedHTMLMessage
          id="groups_of_interest_description"
          defaultMessage={ t.groups_of_interest_description }
          tagName="p"
        />
      </div>

      <Row gutter={[16, 16]}>
        <Col span={10}>
          <div className="GroupsOfInterest__subheader GroupsOfInterest__subheader-group">
            <div className="GroupsOfInterest__subheader-inner-wrapper">
              <h2 className="GroupsOfInterest__subheader-title">{ t.groups }</h2>
              <CustomButton
                type="primary"
                hidden={ itemFormShown && itemFormType === 'group' }
                onClick={() => {
                  showItemForm({
                    itemFormAction: 'create',
                    itemFormType: 'group',
                    itemFormPlaceholderId: 'item-form-group-placeholder',
                    itemFormDefaultValues: {}
                  });
                }}
              >
                { t.new }
              </CustomButton>
            </div>
            <div id="item-form-group-placeholder"></div>
          </div>

          <Sider
            width="100%"
            className="GroupsOfInterest__list"
          >
            { !groups.length ? null :
              <Fragment>
                <Menu
                  mode="inline"
                  selectedKeys={ group.id ? [group.id.toString()] : [] }
                  onClick={ (itemObj) => {
                    onMenuItemClick(itemObj);
                  }}
                >
                  { groupsItems }
                </Menu>

              </Fragment>
            }
          </Sider>
        </Col>
        <Col span={14}>
          <div className="GroupsOfInterest__subheader GroupsOfInterest__subheader-member">
            <div className="GroupsOfInterest__subheader-inner-wrapper">
              <h2 className="GroupsOfInterest__subheader-title">{ t.groups_of_interest_members }</h2>
              <CustomButton
                type="primary"
                hidden={ itemFormShown && itemFormType === 'member' && itemFormAction === 'create' }
                onClick={() => {
                  showItemForm({
                    itemFormAction: 'create',
                    itemFormType:  'member',
                    itemFormPlaceholderId: 'item-form-member-placeholder',
                    itemFormDefaultValues: {},
                  });
                }}
              >
                { t.new }
              </CustomButton>
            </div>
            <div id="item-form-member-placeholder"></div>
          </div>

          <List
            size="large"
            dataSource={ group.members }
            renderItem={ item => memberItem(item) }
          />
        </Col>
      </Row>
      {
        !itemFormShown ?
        null :
        <Portal id={ itemFormPlaceholderId }>
          <GroupOfInterestForm
            actionLabel={ Object.entries(itemFormDefaultValues).length ? t.save : t.add }
            defaultValues={ itemFormDefaultValues }
            formType={ itemFormType }
            targetItemType={ itemFormType }
            onCancel={ onCancelClick }
            onSubmit={ (body) => {
              onSubmit(mainOrgSlug, body);
            }}
          />
        </Portal>
      }
      <Row type="flex" gutter={[20, 20]} className="GroupOfInterest-actions">
        <Col>
          <CustomButton
            type="primary"
            onClick={onDownloadXlsx}
            icon={<DownloadOutlined />}
          >
            {t.analysis_goi_download_template_xlsx}
          </CustomButton>
        </Col>
        <Col>
          <UploadButton
            actionUrl={
              getGroupOfInterestBulkUploadUrl(
                organization.slug,
                suborganization.slug,
              )
            }
            onSuccess={onBulkUploadSuccess}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            component={(
            <CustomButton
              type="primary"
              icon={<UploadOutlined />}
            >
              { t.analysis_survey_goi_template_upload }
            </CustomButton>
            )}
          >
          </UploadButton>
        </Col>
      </Row>
    </div>
  );
};

export default injectIntl(GroupsOfInterest);
