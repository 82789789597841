import { backendLevelToLevel } from 'utils/kpi_validation';


export const KPI_STATUS_OPTIONS = [
  'notapplies',
  'pending',
  'uptodate',
  'restricted',
];

const VALUE_PROP = {
  qualitative: 'text',
  quantitative: 'value',
  choice: 'choice',
  boolean: 'boolean',
};

const isValueSet = (type, obj) => {
  const value = obj[VALUE_PROP[type]];
  const done = typeof value !== 'undefined' && value !== null && value !== '';
  return done;
}

const getLeafValueType = (obj) => {
  const keys = Object.keys(obj);
  return (
    (
      [1, 2].includes(keys.length) &&
      keys.includes('choice') &&
      'choice'
    ) ||
    (
      [1, 2].includes(keys.length) &&
      keys.includes('text') &&
      'qualitative'
    ) ||
    (
      [1, 2].includes(keys.length) &&
      keys.includes('boolean') &&
      'boolean'
    ) ||
    (
      [1, 2, 3].includes(keys.length) &&
      keys.includes('value') &&
      'quantitative'
    )
  );
}

const countResults = (obj) => {
  return Object
    .keys(obj)
    .map(key => {
      if(key && (
        key.startsWith('_')
        || key === 'order' // TODO: REMOVE AFTER MIGRATION
      )) {
        return [0, 0];
      }
      const type = getLeafValueType(obj);
      if(type) {
        return isValueSet(type, obj)
          ? [1, 1]
          : [0, 1]
      }
      if(typeof obj[key] === 'object' && obj[key] !== null) {
        const results = countResults(obj[key]);
        return results;
      }
      return [0, 1];
    })
    .reduce((
      [accVal, accTotal],
      [val, total]
    ) => {
      return [
        accVal + val,
        accTotal + total
      ];
    }, [0, 0]);
};

const getKpiValidationStatus = (kpi, kpiStatus, approvalLevels) => {
  if (
    kpiStatus !== 'uptodate'
    && (
      kpiStatus === 'pending'
      && !['aggregated', 'calculated', 'parent'].includes(kpi.source)
      && !kpi.value
    )
  ) {
    return;
  }

  if (!kpi.ready_to_validate_level) {
    if (kpi.rejection_count) {
      return 'rejected';
    }

    const mandatoryData = kpi.config?.mandatory_data || {};
    if (!(
      (mandatoryData.value && kpiStatus !== 'uptodate') ||
      (mandatoryData.attachment && !kpi.has_attachments) ||
      (mandatoryData.comment && !kpi.comment) ||
      (kpi.ready_to_validate_level !== 0) ||
      (kpi.validated_level !== 0)
    )) {
      return 'ready_to_validate';
    }

    return;
  }

  return approvalLevels < backendLevelToLevel(kpi.ready_to_validate_level)
    ? 'validated'
    : 'pending';
};

export const getKpiStatus = (kpi, validationApprovalLevels) => {
  let status = !kpi.applies
    ? 'notapplies'
    : (
      kpi.restricted
      ? 'restricted'
      : 'pending'
    );

  if(
    !kpi.restricted &&
    kpi.applies
  ) {
    if (
      (
        ['aggregated', 'calculated', 'parent'].includes(kpi.source)
        && (
          kpi.dependee_kpi_values_uptodate
          || (
            kpi.dependee_kpis?.length
            && kpi.dependee_kpis.filter(({applies}) => !!applies).every(({empty_value}) => !empty_value)
          )
        )
      ) || (
        kpi.source === 'children'
        && (
          kpi.suborganization_values_uptodate
          || (
            kpi.suborganizations?.length
            && kpi.suborganizations.filter(({applies}) => !!applies).every(({kpi_value}) => !!kpi_value)
          )
        )
      )
    ) {
      status = 'uptodate';
    } else if (kpi.source === 'manual' && kpi.kpi_value) {
      const {
        type,
      } = kpi.schema || {};
      let done;
      switch(type) {
        case 'boolean':
        case 'choice':
        case 'qualitative':
        case 'quantitative':
          done = isValueSet(type, kpi.kpi_value)
          status = done ? 'uptodate' : status;
          break;
        case 'table':
        case 'tuple':
          [done] = countResults(kpi.kpi_value);
          status = 'uptodate';
          break;
        default:
          return kpi;
      }
    }
  }

  return {
    status,
    validationStatus: validationApprovalLevels
      ? getKpiValidationStatus(kpi, status, validationApprovalLevels)
      : null,
  };
};

export const patchKpiStatus = (kpi) => {
  const {
    status,
  } = getKpiStatus(kpi);

  return {
    ...kpi,
    status,
  };
};

const codeSplitRegExp = new RegExp('[-.]');

// Out of https://stackoverflow.com/a/175787
const isNumeric = (str) => {
  if (typeof str != "string") return false
  return !isNaN(str) &&
         !isNaN(parseFloat(str))
}

export const sortKpiStructure = (locale) => (a, b) => {

  // Order 1 => first kpis, then categories
  if (('isCategory' in a) || ('isCategory' in b)){
    if (a.isCategory !== b.isCategory){
      return !!a.isCategory ? 1 : -1;
    }
  }

  //Order 2 => first without code, then with code
  const aTokens = (a.code || '').split(codeSplitRegExp).filter(Boolean);
  const bTokens = (b.code || '').split(codeSplitRegExp).filter(Boolean);
  
  if (aTokens.length === 0 && bTokens.length !== 0){
    return -1;
  }
  
  if (aTokens.length !== 0 && bTokens.length !== 0){

    if (a.code !== b.code){
      return byCode(locale)(a, b);
    }

  }

  return byName(locale)(a, b);

}

export const byCode = (locale) => (a, b) => {

  const aTokens = (a.code || '').split(codeSplitRegExp).filter(Boolean);
  const bTokens = (b.code || '').split(codeSplitRegExp).filter(Boolean);

  if(bTokens.length === 0) {
    return aTokens.length === 0 ? 0 : 1;
  }

  if(aTokens.length === 0) {
    return -1;
  }

  const aNumeric = isNumeric(aTokens[0]);
  const bNumeric = isNumeric(bTokens[0]);

  if(aNumeric !== bNumeric) {
    // NOTICE: All non-numeric tokens go before all the numeric ones
    return aNumeric ? 1 : -1;
  }

  const result = aTokens[0].localeCompare(
    bTokens[0],
    locale,
    { numeric: aNumeric },
  );

  if(result === 0) {
    return byCode(locale)(
      {code: aTokens.slice(1).join('-')},
      {code: bTokens.slice(1).join('-')},
    )
  }

  return result;
};

export const byName = (locale) => (a, b) => {
  if(!b) {
    return !a ? 0 : -1;
  }

  if(!a) {
    return 1;
  }

  const result = (a.name || a.category?.name || '').localeCompare(
    b.name || b.category?.name || '',
    locale,
    { numeric: false },
  );

  return result;
};

export const ESG_TYPES = [
    'environmental',
    'social',
    'governance',
    'economic',
    'cultural',
  ];
  
export const searchKpisByTag = (categories, tag) => {
  return categories.reduce((acc, o) => {
      return [
        ...acc,
        ...o.kpis
          .filter((kpi) => kpi.tags.includes(tag))
          .map((kpi) => kpi.slug),
        ...searchKpisByTag(o.children, tag),
      ];
  }, [])
};

export const getCommonSelectedTags = (tags, kpisAndCategories) =>
  tags.filter((t) =>
    kpisAndCategories.every((el) => el.tags.includes(t))
  );

export const getPartiallySelectedTags = (tags, kpisAndCategories) =>
  kpisAndCategories
    .reduce((acc, el) => [...acc, ...el.tags], [])
    .filter((t) => !tags.includes(t));

export const diff  = (initial, final) =>
  initial.filter(
    (tag) => !final.includes(tag)
  );

export const getOrderedCategoriesStructure = (categories) => {
  const categoryRoot = categories.find(({parent_id}) => !parent_id);
  return categories.reduce((acc, category) => {
    if (acc.some(element => element.id === category.parent_id)) {
      return [
        ...acc,
        category,
      ]
    }
    return acc;
  }, [categoryRoot]);
};

export const getNumberFormatOptions = (schema, _config) => {
  const showTrailingZeros = _config?.show_trailing_zeros || false;
  const config = _config?.force_decimal_points || {};

  const forceDecimal = config.hasOwnProperty(schema.metricSlug)
    ? config[schema.metricSlug]
    : config['*']

  const doForce = typeof forceDecimal === 'number';

  const digits = doForce
    ? forceDecimal
    : (schema.decimalPoints || 0);

  return {
    minimumFractionDigits: showTrailingZeros ? digits : 0,
    maximumFractionDigits: digits,
  }
};

export const getAdjacentPeriods = (period) => {
  // Helper function to format month
  const formatMonth = (year, month) => `${year}-${String(month + 1).padStart(2, '0')}`;

  // Determine the type of period and calculate previous and next
  const monthRegex = /^(\d{4})-(0[1-9]|1[0-2])$/; // Matches YYYY-MM format
  const quarterRegex = /^(\d{4})-Q([1-4])$/; // Matches YYYY-Q1 to YYYY-Q4
  const semesterRegex = /^(\d{4})-S([1-2])$/; // Matches YYYY-S1 or YYYY-S2
  const yearRegex = /^(\d{4})$/; // Matches YYYY format

  const monthMatch = period.match(monthRegex);
  const quarterMatch = period.match(quarterRegex);
  const semesterMatch = period.match(semesterRegex);
  const yearMatch = period.match(yearRegex);

  if (monthMatch) {
    const year = parseInt(monthMatch[1], 10);
    let month = parseInt(monthMatch[2], 10) - 1; // Convert to 0-indexed

    const prevMonth = month === 0 ? 11 : month - 1;
    const nextMonth = month === 11 ? 0 : month + 1;
    const prevYear = month === 0 ? year - 1 : year;
    const nextYear = month === 11 ? year + 1 : year;

    return {
      previous: formatMonth(prevYear, prevMonth),
      next: formatMonth(nextYear, nextMonth),
    };
  }

  if (quarterMatch) {
    const year = parseInt(quarterMatch[1], 10);
    const quarter = parseInt(quarterMatch[2], 10);

    const prevQuarter = quarter === 1 ? 4 : quarter - 1;
    const nextQuarter = quarter === 4 ? 1 : quarter + 1;
    const prevYear = quarter === 1 ? year - 1 : year;
    const nextYear = quarter === 4 ? year + 1 : year;

    return {
      previous: `${prevYear}-Q${prevQuarter}`,
      next: `${nextYear}-Q${nextQuarter}`,
    };
  }

  if (semesterMatch) {
    const year = parseInt(semesterMatch[1], 10);
    const semester = parseInt(semesterMatch[2], 10);

    const prevSemester = semester === 1 ? 2 : 1;
    const nextSemester = semester === 2 ? 1 : 2;
    const prevYear = semester === 1 ? year - 1 : year;
    const nextYear = semester === 2 ? year + 1 : year;

    return {
      previous: `${prevYear}-S${prevSemester}`,
      next: `${nextYear}-S${nextSemester}`,
    };
  }

  if (yearMatch) {
    const year = parseInt(yearMatch[1], 10);
    return {
      previous: `${year - 1}`,
      next: `${year + 1}`,
    };
  }

  // If the input doesn't match any valid format
  return {
    previous: null,
    next: null,
  };
}
