import React from 'react';
import ReportingStandardTag from 'components/ReportingStandardTag';

const DataReportingStandards = ({
  standard_info = [],
}) => {
  return (
    <div className="KpiSummaryCard__card-reporting-standards">
      {
        (standard_info || [])
          .filter(({ code }) => !!code)
          .map(({ standard, code }) => (
            <ReportingStandardTag
              key={standard}
              standard={standard}
              name={code}
              code={code}
              showTooltip
            />
          ))
      }
    </div>
  );
};

export default DataReportingStandards;

