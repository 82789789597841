import React, {
  useCallback,
} from 'react';

import QualitativeAnswer from 'components/QualitativeAnswer';
import QuantitativeAnswer from 'components/QuantitativeAnswer';
import TableAnswer from 'components/TableAnswer';
import ChoiceAnswer from 'components/ChoiceAnswer';
import BooleanAnswer from 'components/BooleanAnswer';
import TupleAnswer from 'components/TupleAnswer';

const DefaultAnswer =  ({
  schema = {},
}) => {
  console.log(`WARNING: we do not know how to render ${schema.type} answers`);
  return null;
}

DefaultAnswer.Summary = DefaultAnswer;
DefaultAnswer.Show = DefaultAnswer;
DefaultAnswer.Edit = DefaultAnswer;

const COMPONENT_PER_SCHEMA = {
  qualitative: QualitativeAnswer,
  quantitative: QuantitativeAnswer,
  choice: ChoiceAnswer,
  boolean: BooleanAnswer,
  table: TableAnswer,
  tuple: TupleAnswer,
  default: DefaultAnswer,
};

const Summary = ({
  schema = {},
  ...props
}) => {
  const Component = COMPONENT_PER_SCHEMA[schema.type] || COMPONENT_PER_SCHEMA.default;
  return (
    <Component.Summary
      schema={schema}
      {...props}
    />
  )
}

const Show = ({
  schema = {},
  ...props
}) => {
  const Component = COMPONENT_PER_SCHEMA[schema.type] || COMPONENT_PER_SCHEMA.default;
  return (
    <Component.Show
      schema={schema}
      {...props}
    />
  )
}

const Edit = ({
  schema = {},
  ...props
}) => {
  const Component = COMPONENT_PER_SCHEMA[schema.type] || COMPONENT_PER_SCHEMA.default;
  return (
    <Component.Edit
      schema={schema}
      {...props}
    />
  )
}

const Answer = (props) => {
  const {
    schema,
    schemaLabels,
    previous_value,
    previous_comment,
    previous_attachments,
    previous_period,
    attachments,
    onOpenAnswer,
    onUseComment,
    onUseAttachments,
    onUsePeriod,
    onUseValue,
    //onUseAttachment,
  } = props || {};
  const handleShowLastPeriod = useCallback(() => {
    onOpenAnswer({
      showUser: false,
      showUseButtons: false,
      schema,
      schemaLabels,
      value: previous_value,
      period: previous_period,
    });
  }, [
    onOpenAnswer,
    schema,
    schemaLabels,
    previous_value,
    previous_period,
  ]);

  const nonRepeatedPreviousAttachments = useCallback(() => {
    return previous_attachments.filter(({folder, filename}) =>
      !(attachments.some((attachment) => attachment.filename === filename && attachment.folder === folder))
    );
  }, [attachments, previous_attachments]);

  const handleUseLastWholeData = useCallback(() => {
    onUseComment({value: previous_value, comment: previous_comment})
  }, [
    onUseComment,
    previous_comment,
    previous_value
  ]);

  const handleUseLastValue = useCallback(() => {
    onUseValue({ value: previous_value });
  }, [
    onUseValue,
    previous_value,
  ]);

  const handleUseLastAttachments = useCallback(() => {
    const newAttachments = nonRepeatedPreviousAttachments();
    onUseAttachments({attachments: newAttachments, value: previous_value});
  }, [
    nonRepeatedPreviousAttachments,
    onUseAttachments,
    previous_value
  ]);

  const handleUseLastPeriod = useCallback(() => {
    const newAttachments = nonRepeatedPreviousAttachments();
    onUsePeriod({value: previous_value, attachments: newAttachments, comment: previous_comment});
  }, [
    nonRepeatedPreviousAttachments,
    onUsePeriod,
    previous_value,
    previous_comment
  ]);

  const Component = COMPONENT_PER_SCHEMA[schema.type] || COMPONENT_PER_SCHEMA.default;
  return (
    <Component
      onShowLastPeriod={handleShowLastPeriod}
      onUseLastValue={handleUseLastValue}
      onUseLastWholeData={handleUseLastWholeData}
      onUseLastAttachments={handleUseLastAttachments}
      onUseLastPeriod={handleUseLastPeriod}
      {...props}
    />
  )
}

Answer.Summary = Summary;
Answer.Show = Show;
Answer.Edit = Edit;

export default Answer;
