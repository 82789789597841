import React, { useState, useCallback, useEffect } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import Loading from 'containers/Loading';
import useAuthorizedRequest from 'utils/useAuthorizedRequest';

import {
  Row,
  Button,
} from 'antd';

import {
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';

import './style.less';


const PDFViewer = ({
  href,
}) => {
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [blobUrl, setBlobUrl] = useState();

  const handleDownload = useCallback(
    (content) => {
      const windowUrl = window.URL || window.webkitURL;
      setBlobUrl(windowUrl.createObjectURL(content));
    },
    []
  );
  const handleError = useCallback((error) => {
    console.log('Error fetching', error);
  }, []);

  const {
    onClick: downloadFile,
  } = useAuthorizedRequest({
    url: href,
    onSuccess: handleDownload,
    onError: handleError,
  });

  useEffect(
    () => {
      if (href && !blobUrl) {
        downloadFile();
      }
    },
    [href, blobUrl, downloadFile]
  );

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }) => {
      setNumberOfPages(numPages);
    },
    []
  );

  return (
    <div className="PDFViewer">
      <Row type="flex" justify="space-between" className="header">
        <Button
          type="primary"
          icon={<LeftOutlined />}
          disabled={pageNumber === 1}
          onClick={() => setPageNumber(pageNumber - 1)}
        />
        <span>{pageNumber} / {numberOfPages}</span>
        <Button
          type="primary"
          icon={<RightOutlined />}
          disabled={pageNumber === numberOfPages}
          onClick={() => setPageNumber(pageNumber + 1)}
        />
      </Row>
      <Document
        file={ blobUrl }
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Loading.Block />}
      >
        <Page
          pageNumber={pageNumber}
          loading={<Loading.Block />}
        />
      </Document>
    </div>
  )
}

export default PDFViewer;
