import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Row,
  Col
} from 'antd';

import './style.less';

const ErrorBoundaryFallback = ({
  buttonLabel,
  customErrorImage,
  descriptionErrorMessage,
  hideButton = false,
  onClickButton,
  titleErrorMessage,
}) => {
  
  const onClick = useCallback(()=>{
    onClickButton ? onClickButton() : window.location.reload();
  }, [onClickButton]);

  return (
    <Col justify="center" align="middle" className="errorBoundaryFallback">
      <Row justify="center" align="middle" className="errorBoundaryFallback__image">
        <img
          src={customErrorImage}
          alt=""
        />
      </Row>
      <Row justify="center" align="middle" className="errorBoundaryFallback__title">
        <h1>{titleErrorMessage}</h1>
      </Row>
      <Row justify="center" align="middle" className="errorBoundaryFallback__description">
        <h2>{descriptionErrorMessage}</h2>
      </Row>
      {!hideButton && (
        <Row justify="center" align="middle" className="errorBoundaryFallback__button">
          <Button type="primary" onClick={onClick}>
            {buttonLabel}
          </Button>
        </Row>
      )}
    </Col>
  );
}

ErrorBoundaryFallback.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  customErrorImage: PropTypes.string.isRequired,
  descriptionErrorMessage: PropTypes.string.isRequired,
  hideButton: PropTypes.bool,
  onClickButton: PropTypes.func,
  titleErrorMessage: PropTypes.string.isRequired
};

export default ErrorBoundaryFallback;
