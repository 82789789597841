import React from "react";
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import { toBetweenDotsDateFormat } from "utils/date";

import './style.less';

const WarningModalContentStep3 = ({
  intl,
  notificationDate,
}) => {

  return (
    <div className="WarningModalContentStep3">
      <div className="WarningModalContentStep3__message">
        {intl.formatMessage(
          {id: `data_requests_warning_modal_message_step3_finish`},
          {
            notificationDate: <b style={{color: '#D38106'}}>{toBetweenDotsDateFormat(notificationDate, intl)}</b>,
          }
        )}
      </div>
      <div className="WarningModalContentStep3__question">
        {intl.formatMessage({id: `data_requests_warning_modal_question_step3_finish`})}
      </div>
    </div>
  );
};

WarningModalContentStep3.propTypes = {
  notificationDate: PropTypes.string,
};

export default injectIntl(WarningModalContentStep3);
