import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { uniq } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import A from 'components/A';

import {
  getDataRequests,
  getDataRequestStatusProgress,
  deleteDataRequests,
  dataRequestsUseResponse,
  requestOrganizationDetail,
  sendPuntualReminderDataRequests
} from 'actions/api';
import useOrganizations from 'utils/useOrganizations';
import {
  DATA_REQUEST_STATUS,
  getDataRequestByKpi,
  getDataRequestQueryFilters,
  getPeriodsAndKpisOfDataRequests
} from 'utils/dataRequests';
import { objectToQueryParameters, queryStringToObject } from 'utils/queryParameters';
import useScreenFilters from 'hooks/useScreenFilters';
import {
  FILTER_TYPES,
  SCREENS,
} from 'hooks/useScreenFilters/constants';
import useTableCustomView from 'hooks/useTableCustomView';
import { TABLES, TABLE_CUSTOM_VIEW_OPTIONS } from 'hooks/useTableCustomView/constants';

import { Loading } from 'tsComponents/emptyStates/Loading';
import AplanetIcon from 'components/AplanetIcon';
import InfoTooltip from 'components/InfoTooltip';
import DataRequestsTableFilters from './DataRequestsTableFilters';
import DataRequestsBulkActions from './DataRequestsBulkActions';
import DataRequestsTableHeader from './DataRequestsTableHeader';
import DataRequestsTableBody from './DataRequestsTableBody';
import BackToTopButton from 'components/BackToTopButton';
import ProgressBars from 'components/ProgressBars';
import { useEventTracking } from 'hooks/useEventTracking';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { getEmptyResultsProps, emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';

import './style.less';

const DataRequests = ({
  intl,
  location,
  history,
}) => {
  const dispatch = useDispatch();
  const refScrollUp = useRef();
  const eventTracking = useEventTracking();

  const [checkedDataRequests, setCheckedDataRequests] = useState([]);
  const [lastLoadedPage, setLastLoadedPage] = useState(0);

  const {
    organization,
    suborganization,
    permissions: suborganizationPermissions,
  } = useOrganizations();

  const {
    tableCustomViewState,
    isTableCustomViewSet,
    handleTableCustomView,
    resetAllTableCustomViews,
  } = useTableCustomView({
    table: TABLES.admin_data_requests
  });

  const {
    values: [
      [periodDateFilter, isPeriodDateFilterSet],
      [filteredPeriodicities, isPeriodicityFilterSet],
      [filteredRequestTypes, isRequestTypesFilterSet],
      [requestedByFilter, isRequestedByFilterSet],
      [requestedToFilter, isRequestedToFilterSet],
      [requestDateFilter, isRequestDateFilterSet],
      [filteredRequestStatus, isRequestStatusFilterSet],
      [responseDateFilter, isResponseDateFilterSet],
      [filteredKpiValueTypes, isKpiValueTypesFilterSet],
      [filteredHasCommentAttachment, isCommentAttachmentFilterSet],
      [filteredRequestActions, isRequestActionsFilterSet],
      [kpiNameSearch, isKpiNameSearchSet]
    ],
    handleFilter,
    filterState,
    resetAllFilters,
    setAllFilters,
  } = useScreenFilters({
    screen: SCREENS.admin_data_requests
  });

  const areFiltersSet = useMemo(() => {
    return isPeriodDateFilterSet || isPeriodicityFilterSet || isRequestTypesFilterSet
      || isRequestedByFilterSet || isRequestedToFilterSet || isRequestDateFilterSet || isRequestStatusFilterSet || isResponseDateFilterSet
      || isKpiValueTypesFilterSet || isCommentAttachmentFilterSet || isRequestActionsFilterSet;
  }, [
    isCommentAttachmentFilterSet,
    isKpiValueTypesFilterSet,
    isPeriodDateFilterSet,
    isPeriodicityFilterSet,
    isRequestActionsFilterSet,
    isRequestDateFilterSet,
    isRequestStatusFilterSet,
    isResponseDateFilterSet,
    isRequestTypesFilterSet,
    isRequestedByFilterSet,
    isRequestedToFilterSet,
  ]);

  const {
    data: dataRequests,
    status_progress,
    currentPage,
    nextPage,
    loading,
  } = useSelector(state => state.data_request);

  const hasToFilterByRequestTo = useMemo(
    () => {
      const queryStringObject = queryStringToObject(location?.search);
      return queryStringObject?.requested_to;
    },
    [location?.search]
  );

  useEffect(() => {
    if (hasToFilterByRequestTo) {
      handleFilter(FILTER_TYPES.requested_to_filter)([hasToFilterByRequestTo]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryFilters = useMemo(() => {
    // NOTICE: Now the request_action_filter only takes into account whether the data request has been used (or partially used), so the status filter will be used for the moment.
    const filterValues = {
      [FILTER_TYPES.period_date_filter]: periodDateFilter,
      [FILTER_TYPES.periodicity_filter]: filteredPeriodicities,
      [FILTER_TYPES.request_type_filter]: filteredRequestTypes,
      [FILTER_TYPES.requested_by_filter]: requestedByFilter,
      [FILTER_TYPES.requested_to_filter]: requestedToFilter,
      [FILTER_TYPES.request_date_filter]: requestDateFilter,
      [FILTER_TYPES.request_status_filter]: filteredRequestActions.includes('response_in_use') ? uniq([...filteredRequestStatus, DATA_REQUEST_STATUS.in_use, DATA_REQUEST_STATUS.partially_in_use]) : filteredRequestStatus,
      [FILTER_TYPES.response_date_filter]: responseDateFilter,
      [FILTER_TYPES.kpi_value_type_filter]: filteredKpiValueTypes,
      [FILTER_TYPES.request_comment_attachment_filter]: filteredHasCommentAttachment,
      [FILTER_TYPES.request_action_filter]: filteredRequestActions,
      [FILTER_TYPES.text_filter]: kpiNameSearch,
    };
    return getDataRequestQueryFilters(filterValues);
  }, [
    filteredHasCommentAttachment,
    filteredKpiValueTypes,
    filteredPeriodicities,
    filteredRequestActions,
    filteredRequestStatus,
    filteredRequestTypes,
    kpiNameSearch,
    periodDateFilter,
    requestDateFilter,
    requestedByFilter,
    requestedToFilter,
    responseDateFilter
  ]);

  useEffect(() => {
    if (
      !organization?.slug ||
      !suborganization?.slug
    ) return;

    dispatch(
      getDataRequests({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        page: 1,
        filters: queryFilters
      })
    );

    dispatch(
      getDataRequestStatusProgress({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        filters: queryFilters
      })
    );

    dispatch(
      requestOrganizationDetail(
        organization.slug,
        suborganization.slug
      )
    );

    setLastLoadedPage(1);

  }, [
    dispatch,
    organization.slug,
    suborganization.slug,
    queryFilters
  ]);

  const scrollUp = useCallback(() => {
    refScrollUp?.current?.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleLoadMore = useCallback(() => {
    if(currentPage === lastLoadedPage && nextPage) {
      // This is a dirty workaround because react-infinite-scroller sucks...
      dispatch(
        getDataRequests({
          organization_slug: organization.slug,
          suborganization_slug: suborganization.slug,
          page: nextPage,
          filters: queryFilters
        })
      );

      setLastLoadedPage(nextPage);
    }
  }, [
    currentPage,
    dispatch,
    lastLoadedPage,
    nextPage,
    organization.slug,
    suborganization.slug,
    queryFilters
  ]);

  const onChangeKpiSearch = useCallback((term) => {
    handleFilter(FILTER_TYPES.text_filter)(term)
  }, [
    handleFilter,
  ]);

  const progressBarsConfig = useMemo(() => {
    const done = Number(status_progress[DATA_REQUEST_STATUS.done] || 0);
    const pending = Number(status_progress[DATA_REQUEST_STATUS.pending] || 0);
    const partially_in_use = Number(status_progress[DATA_REQUEST_STATUS.partially_in_use] || 0);
    const in_use = Number(status_progress[DATA_REQUEST_STATUS.in_use] || 0);
    const total_usage_status = done + partially_in_use + in_use;
    const total_response_status = total_usage_status + pending;

    return [
      {
        title: intl.formatMessage({id: 'admin_data_requests_table_progress_title_response_status'}),
        data: [
          {
            id: DATA_REQUEST_STATUS.done,
            count: done + partially_in_use + in_use,
            total: total_response_status,
            label: intl.formatMessage({id: "admin_data_requests_table_request_status_done"}),
            iconProps: {
              name: 'Data management',
              faStyle: 'fad'
            },
          },
          {
            id: DATA_REQUEST_STATUS.pending,
            count: pending,
            total: total_response_status,
            label: intl.formatMessage({id: "admin_data_requests_table_request_status_pending"}),
            iconProps: {
              name: 'Send',
              faStyle: 'fad'
            },
          },
        ],
        numberFormat: 'count',
      },
      {
        title: intl.formatMessage({id: 'admin_data_requests_table_progress_title_usage_status'}),
        data: [
          {
            id: 'not-used',
            count: done,
            total: total_usage_status,
            label: intl.formatMessage({id: "admin_data_requests_table_request_status_not_used"}),
            iconProps: {
              name: 'Edit',
              faStyle: 'fad'
            },
          },
          {
            id: DATA_REQUEST_STATUS.partially_in_use,
            count: partially_in_use,
            total: total_usage_status,
            label: intl.formatMessage({id: "admin_data_requests_table_request_status_partially-in-use"}),
            iconProps: {
              name: 'Copy',
            },
          },
          {
            id: DATA_REQUEST_STATUS.in_use,
            count: in_use,
            total: total_usage_status,
            label: intl.formatMessage({id: "admin_data_requests_table_request_status_in-use"}),
            iconProps: {
              name: 'Copy',
              faStyle: 'fad'
            },
          },
        ],
        numberFormat: 'count',
      },
    ];
  }, [
    intl,
    status_progress
  ]);

  const headerData = useMemo(() => [
    {
      id: 'selectionCheckBox',
      className: 'DataRequests__checkbox-header',
    },
    {
      id: 'periods',
      className: 'DataRequests__periods-header',
      title: (
        <span>{intl.formatMessage({ id: "admin_data_requests_table_periods_header"})}</span>
      ),
    },
    {
      id: 'requestedToRequestedBy',
      title: (
        <div className='DataRequests__requested-header-wrapper'>
          <span>{intl.formatMessage({ id: "admin_data_requests_table_requested_by_header"})}</span>
          <AplanetIcon name="Chevron right" size="18px" />
          <span>{intl.formatMessage({ id: "admin_data_requests_table_requested_to_header"})}</span>
        </div>
      ),
      hide: !tableCustomViewState.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.requested_by_to_column),
    },
    {
      id: 'value',
      title: (
        <div className='DataRequests__value-header-wrapper'>
          <span>{intl.formatMessage({ id: "admin_data_requests_table_value_header"})}</span>
          <InfoTooltip
            placement='bottomLeft'
            content={(
              <div className='DataRequests__value-tooltip-wrapper'>
                <span>{intl.formatMessage({ id: "admin_data_requests_table_value_tooltip_message_1"})}</span>
                <span>{intl.formatMessage({ id: "admin_data_requests_table_value_tooltip_message_2"})}</span>
              </div>
            )}
          />
        </div>
      ),
      hide: !tableCustomViewState.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.value_column),
    },
    {
      id: 'comments',
      className: 'DataRequests__comments-header',
      title: (<AplanetIcon title={intl.formatMessage({ id: "admin_data_requests_table_comment_header"})} name="Comment" size="18px" />),
      hide: !tableCustomViewState.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.comment_column),
    },
    {
      id: 'attachments',
      className: 'DataRequests__attachments-header',
      title: (
        <span>{intl.formatMessage({ id: "admin_data_requests_table_attachments_header"})}</span>
      ),
      hide: !tableCustomViewState.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.attachment_column),
    },
    {
      id: 'actions',
      className: 'DataRequests__actions-header',
      title: (
        <span>{intl.formatMessage({ id: "admin_data_requests_table_actions_header"})}</span>
      ),
      hide: !tableCustomViewState.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.actions_column),
    },
  ], [
    tableCustomViewState,
    intl,
  ]);

  const handleOpenKpi = useCallback((kpiSlug, period = 'last') => {
      window.open(`/data/requests/${kpiSlug}/${period}?toplevelorg=${organization.slug}&org=${suborganization.slug}`, "_blank");
    },
    [
      organization.slug,
      suborganization.slug
    ]
  );

  const allCheckedIds = useMemo(
    () => checkedDataRequests.map(({id}) => id),
    [checkedDataRequests]
  );

  const { totalKpis, totalPeriods } = useMemo(
    () => getPeriodsAndKpisOfDataRequests(checkedDataRequests),
    [checkedDataRequests]
  );

  const handleCheckKpi = useCallback((checkedKpiSlug) => {
    let newCheckedRequests = [...checkedDataRequests];
    const kpiRequests = getDataRequestByKpi(checkedKpiSlug, dataRequests || []);
    if (kpiRequests.every(({id}) => allCheckedIds.includes(id))) {
      newCheckedRequests = newCheckedRequests.filter(({id}) => !kpiRequests.map(r => r.id).includes(id));
    } else {
      const missingRequests = kpiRequests.filter(({id}) => !allCheckedIds.includes(id));
      newCheckedRequests = [...newCheckedRequests, ...missingRequests];
    }
    setCheckedDataRequests(newCheckedRequests);
  }, [
    checkedDataRequests,
    dataRequests,
    allCheckedIds
  ]);

  const handleCheckRecurrentRequests = useCallback((allRecurrentRequests) => {
    let newCheckedRequests = [...checkedDataRequests];
    if (allRecurrentRequests.every(({id}) => allCheckedIds.includes(id))) {
      newCheckedRequests = newCheckedRequests.filter(({id}) => !allRecurrentRequests.map(dr => dr.id).includes(id));
    } else {
      const missingRequests = allRecurrentRequests.filter(({id}) => !allCheckedIds.includes(id));
      newCheckedRequests = [...newCheckedRequests, ...missingRequests];
    }
    setCheckedDataRequests(newCheckedRequests);
    }, [
      checkedDataRequests,
      allCheckedIds,
    ]
  );

  const handleCheckDataRequests = useCallback((checkedRequests) => {
    let newCheckedRequests = [...checkedDataRequests];
    checkedRequests.forEach((checkedRequest) => {
      if (allCheckedIds.includes(checkedRequest.id)) {
        newCheckedRequests = newCheckedRequests.filter(({id}) => id !== checkedRequest.id);
      } else {
        newCheckedRequests = [...newCheckedRequests, checkedRequest];
      }
    })
    setCheckedDataRequests(newCheckedRequests);
    }, [
      allCheckedIds,
      checkedDataRequests,
    ]
  );

  const handleSendReminderDataRequests = useCallback((requestIds) => {
    dispatch(sendPuntualReminderDataRequests(organization.slug, suborganization.slug, requestIds));
  }, [
    dispatch,
    organization.slug,
    suborganization.slug
  ]);

  const handleDeleteDataRequests = useCallback((requestIds) => {
    dispatch(deleteDataRequests(organization.slug, suborganization.slug, requestIds.join()));
    scrollUp();
    setLastLoadedPage(1);
  }, [
    dispatch,
    scrollUp,
    organization.slug,
    suborganization.slug
  ]);

  const handleCopyDataRequests = useCallback((
      requestIds,
      useAnswerData,
      useAnswerAttachments,
    ) => {
    dispatch(
      dataRequestsUseResponse(
        organization.slug,
        suborganization.slug,
        requestIds,
        useAnswerData,
        useAnswerAttachments,
      )
    );
    scrollUp();
    setLastLoadedPage(1);
  }, [
    dispatch,
    scrollUp,
    organization.slug,
    suborganization.slug
  ]);

  const onToggleShowProgress = useCallback((showProgress) => {
    eventTracking.capture('dataRequestTab.progressBarsToggleShow', { visibility: showProgress });
  }, [eventTracking]);

  const onClickBackToTop = useCallback(() => {
    eventTracking.capture('dataRequestTab.scrollToTop');
  }, [eventTracking]);

  const removeHasToFilterByRequestTo = useCallback(() => {
    if (hasToFilterByRequestTo) {
      const queryStringObject = queryStringToObject(location?.search);
      const { requested_to, ...restQueryStringObject} = queryStringObject;
      const restQueryParams = objectToQueryParameters(restQueryStringObject);
      history.replace({
        search: restQueryParams,
      })
    }
  }, [
    hasToFilterByRequestTo,
    history,
    location?.search,
  ])

  if (loading && !currentPage) {
    return <Loading />
  }

  const hasResult = Boolean(dataRequests.length);
  const emptyState = isKpiNameSearchSet || areFiltersSet 
  ? <Empty {...getEmptyResultsProps(isKpiNameSearchSet, areFiltersSet)} />
  : <Empty {...emptyPropsMap.get("noRequest")} />

  return (
    <div className="DataRequests">
      <div className="DataRequests__top-actions-wrapper">
        <DataRequestsTableFilters
          setAllFilterState={setAllFilters}
          filterState={filterState}
          resetAllFilters={resetAllFilters}
          areFiltersSet={areFiltersSet}
          isKpiNameSearchSet={isKpiNameSearchSet}
          kpiNameSearch={kpiNameSearch}
          onChangeKpiSearch={onChangeKpiSearch}
          isCustomViewSet={isTableCustomViewSet}
          selectedCustomView={tableCustomViewState}
          onChangeCustomView={handleTableCustomView}
          resetAllTableCustomViews={resetAllTableCustomViews}
          permissions={suborganizationPermissions}
          removeHasToFilterByRequestTo={removeHasToFilterByRequestTo}
        />
        {!suborganizationPermissions.can_request_kpi && suborganizationPermissions.can_write_kpi ? null : (
          <DataRequestsBulkActions
            onDeleteDataRequests={handleDeleteDataRequests}
            onCopyDataRequests={handleCopyDataRequests}
            onSendReminderDataRequests = {handleSendReminderDataRequests}
            checkedDataRequests={checkedDataRequests}
            checkedDataRequestIds={allCheckedIds}
            canCopyData={suborganizationPermissions.can_write_kpi}
          />
        )}
      </div>
      <div className="DataRequests__progress-bars">
        <ProgressBars
          isPercentFormat={false}
          config={progressBarsConfig}
          onToggleShowProgress={onToggleShowProgress}
          defaultShowProgressBars
          hasHideShowButton
        />
      </div>
      <div className="DataRequests__selection-info-wrapper">
        <span className="DataRequests__selection-info">
          {intl.formatMessage({ id: "admin_data_requests_table_selected_periods"}, {totalPeriods})}
          {' '}
          {intl.formatMessage({ id: "admin_data_requests_table_selected_in_kpis"}, {totalKpis})}
        </span>
        <A
          className='DataRequests__remove-selection'
          onClick={() => setCheckedDataRequests([])}
          disabled={!checkedDataRequests.length}
        >
          {intl.formatMessage({id: 'admin_data_requests_table_remove_selection'})}
        </A>
      </div>
      <div className="DataRequests__table-wrapper">
        <div className="DataRequests__table-container" ref={refScrollUp}>
          <BackToTopButton scrollableComponentRef={refScrollUp} onClick={onClickBackToTop} />
          <InfiniteScroll
            pageStart={1}
            loadMore={handleLoadMore}
            hasMore={!loading}
            useWindow={false}
          >

          <table>
            <DataRequestsTableHeader
              headerData={headerData}
            />
            {hasResult && <DataRequestsTableBody
              suborganization={suborganization}
              dataRequests={dataRequests}
              handleCheckKpi={handleCheckKpi}
              handleCheckDataRequests={handleCheckDataRequests}
              handleCheckRecurrentRequests={handleCheckRecurrentRequests}
              handleOpenKpi={handleOpenKpi}
              checkedDataRequestIds={allCheckedIds}
              handleDeleteDataRequests={handleDeleteDataRequests}
              handleSendReminderDataRequests={handleSendReminderDataRequests}
              handleCopyDataRequests={handleCopyDataRequests}
              selectedTableCustomView={tableCustomViewState}
              canCopyData={suborganizationPermissions.can_write_kpi}
              canSendRequest={suborganizationPermissions.can_request_kpi}
            />}
          </table>

            {!hasResult && emptyState}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}

export default withRouter(injectIntl(DataRequests));
