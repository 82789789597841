import React, {
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import UploadArea from 'containers/UploadArea'
import FileItem from 'components/FileItem'
import Required from 'components/Required';
import { Tooltip } from 'antd';

import {
  getAttachFileUrl,
  deleteKpiAttachment,
} from 'actions/api';

import {
  uploadKpiAttachmentSuccess,
} from 'actions/attachments';

import useOrganizations from 'utils/useOrganizations';

import {
  Row,
  Col,
  Modal,
} from 'antd';

const DeleteLink = ({
  intl,
  attachment,
  isValidating,
  onDelete,
}) => {
  const t = intl.messages;

  if(isValidating) {
    return (
      <Tooltip
        title={t.kpi_detail_attachment_validating}
        placement="top"
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href=""
          className="KpiDetail__files__delete"
          disabled
        >
          { t.delete }
        </a>
      </Tooltip>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href=""
      className="KpiDetail__files__delete"
      onClick={e => onDelete(e, attachment)}
    >
      { t.delete }
    </a>
  );
};

const Attachments = ({
  intl,
  attachments = [],
  loading,
  kpi_slug,
  period,
  permissions = {},
  isValidating,
  config
}) => {
  const t = intl.messages;
  const {
    organization,
    suborganization,
  } = useOrganizations();
  const dispatch = useDispatch();

  const onSuccess = useCallback((response) => {
    try {
      const attachment = JSON.parse(response);
      dispatch(
        uploadKpiAttachmentSuccess(
          suborganization.slug,
          kpi_slug,
          period,
          attachment,
        )
      )
    } catch(err) {
      console.error(err);
    }
  }, [
    dispatch,
    suborganization.slug,
    kpi_slug,
    period,
  ]);

  const handleOnDelete = useCallback(
    (e, attachment) => {
      e.preventDefault();
      if(isValidating) {
        return;
      }
      Modal.confirm({
        title: t.datamanagement_delete_attachment_modal_title,
        content: t.datamanagement_delete_attachment_modal_description,
        okText: t.datamanagement_delete_attachment_modal_ok,
        cancelText: t.datamanagement_delete_attachment_modal_cancel,
        onOk() {
          dispatch(
            deleteKpiAttachment(
              organization.slug,
              suborganization.slug,
              kpi_slug,
              period,
              attachment.filename,
            )
          );
        },
      })
    },
    [
      t,
      dispatch,
      kpi_slug,
      period,
      organization,
      suborganization,
      isValidating,
    ]
  );

  return (
    <Col span={24}>
      <Row className="KpiDetail__title">
        { t.kpi_detail_attachments }
        { attachments.length > 0 ? `(${attachments.length})` : (config?.mandatory_data?.attachment ? <Required/> : '') }
      </Row>
      <Row
        type="flex"
        gutter={[15, 15]}
        className="KpiDetail__files"
      >
        {
          attachments.map(
            attachment => (
              <Col
                span={24}
                key={attachment.download}
              >
                <FileItem {...attachment}>
                  { !permissions.can_write_kpi
                    ? null
                    : <DeleteLink
                        intl={intl}
                        isValidating={isValidating}
                        attachment={attachment}
                        onDelete={handleOnDelete}
                      />
                  }
                </FileItem>
              </Col>
            )
          )
        }
        {
          !permissions.can_write_kpi
          ? null
          : (
            <Col>
              <UploadArea
                className="KpiDetail__files_upload"
                actionUrl={
                  getAttachFileUrl(
                    organization.slug,
                    suborganization.slug,
                    kpi_slug,
                    period,
                  )
                }
                onSuccess={onSuccess}
                disabled={isValidating}
              >
                { t.kpi_detail_attachment_add }
              </UploadArea>
            </Col>
          )
        }
      </Row>
    </Col>
  );

};

export default injectIntl(Attachments);
