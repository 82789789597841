import React from 'react';
import { injectIntl } from 'react-intl';
import './style.less';

import FileItem from 'components/FileItem';
import AplanetIcon from 'components/AplanetIcon';
import CustomButton from 'components/CustomButton';

import { 
  Row,
  Col,
  Avatar,
  Divider,
  Tooltip
 } from 'antd';

 import {
  formatDate,
  // DATE_ONLY,
  SHORT_FORMAT_WITH_YEAR,
  // fromDb,

} from 'utils/date';

const DEFAULT_AVATAR_PROPS = {
  icon: 'user',
};

const NO_OP = ()=>{};

const getAvatarProps = (avatar) => {
  if(avatar) {
    return { src: avatar };
  }
  return DEFAULT_AVATAR_PROPS;
};

const FilesDownloadCard = ({
  intl,
  name,
  avatar,
  email,
  file = {},
  urlBase = 'kpi',
  onUpdate = NO_OP,
  onDelete = NO_OP,
  onDownload = NO_OP
}) => {
    const t = intl.messages;
    const {
        id: file_id,
        filename,
        expired_at,
        downloaded_at,
        download,
        bookmark,
        status,
        metadata,
    } = file;

    const { mimetype, size, count } = metadata;

  return (
    <section className="FilesDownloadCard__card">
      <Row
        className="FilesDownloadCard__header"
        type="flex"
      >
        <Col
          flex={1}
          className="FilesDownloadCard__header__file"
        >
        <Row
        type="flex"
        gutter={20}
        >
          <Col span={24} className="FilesDownloadCard__header__file__name">
          <AplanetIcon name="Folder close" size="25px" />
          <span className='FilesDownloadCard__header__file__name_title'>
          {filename} - {t[`downloadcard_status_${status}`]}
          </span>


          </Col>
          {/* <Col span={24} className="FilesDownloadCard__header__kpi__categories">
                Category 1
          </Col> */}
          <Col span={24} className="FilesDownloadCard__header__kpi__info">
            <Row>
                {
                    count &&
                    <Col>{count} files</Col>
                }
                {/* {
                    size &&
                    <Col>{size} bytes</Col>
                } */}
            </Row>
          </Col>
        </Row>
        </Col>
        <Col
          className="FilesDownloadCard__header__avatar"
        >
          <Avatar
            className='FilesDownloadCard__avatar'
            size={32}
            {...getAvatarProps(avatar)}
          />
          <div className='FilesDownloadCard__member__info'>
            <span className="FilesDownloadCard__name">{name}</span>
            <span className="FilesDownloadCard__name">{email}</span>
          </div>
        </Col>
      </Row>
      <Divider className="FilesDownloadCard__divider" />
      <Row
        className="FilesDownloadCard__body"
      >
      {
        (status === 'completed' || status === 'downloaded' || status === 'failed' ) &&
        <>
          <Col span={23}>
            { status !== 'failed' &&         
              <FileItem
                  key={filename}
                  filename = {filename}
                  mimetype = {mimetype}
                  size = {size}
                  download = {download}
                  onDownload = {() => onDownload(file_id)}
              />
            }
          </Col>
          <Col span={1} className="FilesDownloadCard__Buttons">
            { status !== 'failed' &&
              <CustomButton 
                className="FilesDownloadCard__Buttons__bookmark" 
                icon={<AplanetIcon name="Bookmark" faStyle={ bookmark ? 'fas': 'fal' }/>} 
                onClick= { () => onUpdate(file_id,{action:'bookmark', bookmark : !bookmark})}  
                />
              }
            <CustomButton 
              className="FilesDownloadCard__Buttons__delete" 
              icon={<AplanetIcon name="Bin"/>} 
              onClick = { () => onDelete(file_id, filename)}
              />
          </Col>

        
            <Col className='subtitle'>
              <Row>
                { status === 'downloaded' && downloaded_at &&
                  <Col span={24}>
                  {`${t.downloadcard_downloaded_text} ${formatDate(
                      downloaded_at,
                      SHORT_FORMAT_WITH_YEAR,
                      intl,
                    )}`}
                  </Col>
                }
                { status !== 'failed' &&
                <Col>
                  { bookmark ? (
                      `${t.downloadcard_bookmarked_text}`
                    ) : (
                      `${t.downloadcard_download_available_text} ${formatDate(
                        expired_at,
                        SHORT_FORMAT_WITH_YEAR,
                        intl,
                      )}`
                    )
                  }
          
                    <Tooltip className='FilesDownloadCard__Tooltip' placement="right"  title={bookmark ? t.downloadcard_bookmarked_tooltip : t.downloadcard_download_tooltip}>
                        <i>
                          <AplanetIcon name="Information" size="14px" />
                        </i>
                    </Tooltip>
                </Col>
                }
              </Row>
            </Col>



        </>
      }
      {
        status === 'pending' &&
        <Col span={24}>
          <section className='FilePendingItem__item'>
            <div className='FilePendingItem__icon'>
              <AplanetIcon name="Load" size="25px" iconProps={{spin: true}} />
            </div>
            <div className='FilePendingItem__detail'>
              <div className='FilePendingItem__name subtitle'>
                {t.downloadcard_pending_text}
              </div>
            </div>
          </section>
         
        </Col>
      }

      </Row>
      </section> 
  )
};

export default injectIntl(FilesDownloadCard);
