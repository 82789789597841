import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { uniqBy, flattenDeep } from 'lodash';
import { Tooltip, Modal } from 'antd';
import EsgLogo from 'components/EsgLogo';
import HeaderStatusLabel from 'components/HeaderStatusLabel';
import ReportingStandardTag from 'components/ReportingStandardTag';
import CustomButton from 'components/CustomButton';
import ModalEditKPI from 'components/ModalEditKPI';
import ModalUfinetAddKpiData from 'components/ModalUfinetAddKpiData';
import { formatDate } from 'utils/date';
import {
  DeleteOutlined,
  EditOutlined,
  FormOutlined
} from '@ant-design/icons';
import {
  useDispatch,
} from 'react-redux';

import { deleteKpi } from 'actions/api';
import CustomTagSimple from 'components/CustomTagSimple';
import AplanetIcon from 'components/AplanetIcon';
import { TooltipTagList } from 'tsComponents/TooltipTagList';
import { sortKpiStructure } from 'utils/kpi';

const Header = ({
  intl,
  id,
  name,
  name_translations,
  slug,
  type,
  sdgs = [],
  standard_info = {},
  last_updated_at,
  last_collection_at,
  is_custom,
  schema,
  schemaLabels,
  guidelines_std,
  periodicities = [],
  first_reported_at,
  value_source,
  kpi_value_status_for_whole_tree,
  kpi_associations,
  all_periods,
  tags,
  loading,
  error,
  permissions = {},
  organization_slug,
  suborganization_slug,
  enabled_reports = [],
  isMainOrganization
}) => {
  const dispatch = useDispatch();
  const [showKpiAssociationsModal, setShowKpiAssociationsModal] = useState(false);
  const [showEditKPIModal, setShowEditKPIModal] = useState(false);
  const [showUfinetAddKPIDataModal, setShowUfinetAddKPIDataModal] = useState(false);

  const UfinetSpecialCaseVisible = organization_slug === 'ufinet' && value_source === 'manual' && schema.type === 'quantitative';

  const handleOnDelete = useCallback(
    () => {
      Modal.confirm({
        title: intl.formatMessage({ id: "modaleditkpi_delete_modal_title" }),
        content: intl.formatMessage({ id: "modaleditkpi_delete_modal_description" }),
        okText: intl.formatMessage({ id: "modaleditkpi_delete_modal_ok" }),
        cancelText: intl.formatMessage({ id: "modaleditkpi_delete_modal_cancel" }),
        onOk() {
          dispatch(
            deleteKpi(
              organization_slug,
              suborganization_slug,
              slug,
            )
          );
        },
      })
    },
    [intl, dispatch, organization_slug, suborganization_slug, slug]
  );

  const enabledReportSet = useMemo(() => {
    return new Set(is_custom ? (enabled_reports || []).concat(organization_slug) : (enabled_reports || []));
  }, [enabled_reports, is_custom, organization_slug]);

  const kpiCodes = useMemo(() => {

    return uniqBy(
            flattenDeep(
              Object.keys(standard_info)
              .filter(standard => enabledReportSet.has(standard))
              .sort((a,b) => a < b)
              .map(standard => {
                const values = (standard_info[standard] || []).filter(({code}) => !!code);
                return uniqBy(values, 'code').map(data => ({ ...data, standard }) ); 
              })
            ), 'code');

  }, [enabledReportSet, standard_info]);

  const associatedKpis = useMemo(() => {
    
    return flattenDeep(Object.keys(kpi_associations || {}).map( standard => {
      
      return kpi_associations[standard].map( data => {
        return { ...data, standard };
      });

    })).sort(sortKpiStructure(intl.locale));

  }, [kpi_associations, intl.locale]);

  return (
    <React.Fragment>
      <div className='KpiDetail__header'>

        <section className="KpiDetail__hero">
          
          <HeaderStatusLabel
            last_updated_at={last_updated_at}
            last_collection_at={last_collection_at}
          />

          <h5 className="KpiDetail__name">
            { name }
          </h5>

          <div className='KpiDetail__hero__data_wrapper'>

            <dl>
              <dt>{ intl.formatMessage({ id: 'kpidetail_header_label_code' }) }</dt>
              <dd className='KpiDetail__hero__data__standards'>
              {
                  // NOTICE: We only show standards that have a code, and only if you org has it
                  kpiCodes.length > 0 
                  ?  
                    kpiCodes.map( value => (
                      <ReportingStandardTag 
                          key={value}
                          showTooltip
                          {...value}
                        />
                    ))
                  : (<>-</>)
              }
              </dd>
              
              <dt>{ intl.formatMessage({ id: 'kpidetail_header_label_association' }) }</dt>
              <dd className='KpiDetail__hero__data__associations'>
                {
                  associatedKpis.length > 0 
                  ? (
                    <button type='button' onClick={ () => setShowKpiAssociationsModal(true) }>
                      <AplanetIcon name="Link" size="15px" />
                      { intl.formatMessage({ id: 'kpidetail_header_value_associations' }, { value: associatedKpis.length }) }
                    </button>
                  )
                  : (
                    <>-</>
                  )
                }
              </dd>
              
              <dt>{ intl.formatMessage({ id: "collection_frequency"}) }</dt>
              <dd>
                {
                  Object.keys(periodicities).map(periodicity => (
                    <CustomTagSimple 
                      key={ periodicity }
                      colorclass={periodicity} 
                      name={ intl.formatMessage({id: `collection_frequency_${periodicity}` }) } 
                    />
                  ))
                }
              </dd>
              
              <dt>{ intl.formatMessage({ id: 'kpidetail_header_label_esg' }) }</dt>
              <dd><EsgLogo type={type} /></dd>
              
              <dt>{ intl.formatMessage({ id: 'kpidetail_header_label_tags' }) }</dt>
              <dd className={`KpiDetail__hero__data__tags`}>
                {
                  (tags || []).length > 0
                  ? (
                    <Tooltip 
                      overlayClassName="KpiDetail__hero__data__tags__tooltip" 
                      title={ <TooltipTagList tags={ tags || [] } /> }>
                        <span>
                          <AplanetIcon name="tag" faStyle='fas'/>
                        </span>
                    </Tooltip>
                  )
                  : (
                    <>-</>
                  )
                }
              </dd>
              
              <dt>{ intl.formatMessage({ id: 'kpidetail_header_label_start_date' }) }</dt>
              <dd>
                <CustomTagSimple 
                  colorclass={'green'}
                  name={ formatDate(first_reported_at, 'D MMMM YYYY', intl) } 
                />
              </dd>
              
            </dl>

            { 
              !UfinetSpecialCaseVisible
                ? null 
                : <section className='KpiDetail__hero__actions'>
                    <CustomButton 
                      className='CustomButton-primary-outlined'
                      tooltip={ intl.formatMessage({ id: "kpidetail_ufinet_edit_button"}) }
                      onClick={() => setShowUfinetAddKPIDataModal(true)}  
                      icon={<FormOutlined />}
                    />
                  </section>
            }

            {
                !is_custom || !permissions.can_configure_kpi || !isMainOrganization ? null :
                <section className='KpiDetail__hero__actions'>
                  <CustomButton 
                    className='CustomButton-primary-outlined'
                    tooltip={ intl.formatMessage({ id: "kpidetail_edit_button"}) }
                    onClick={() => setShowEditKPIModal(true)}  
                    icon={<EditOutlined />}
                  />
                  
                  <CustomButton 
                    className='CustomButton-primary-outlined'
                    tooltip={ intl.formatMessage({ id: "modaleditkpi_delete"}) }
                    icon={<DeleteOutlined />}
                    onClick={handleOnDelete}
                  />
                  
                </section>
            }

          </div>

        </section>

        {
          associatedKpis.length > 0 && 
          showKpiAssociationsModal && 
          <section className='KpiDetail__associations_modal'>
            <h5>{ intl.formatMessage({ id: 'kpidetail_header_value_associations' }, { value: associatedKpis.length }) }</h5>
            <button  
              className='KpiDetail__associations_modal__closeBtn'
              onClick={() => setShowKpiAssociationsModal(false)}  
            >
              <AplanetIcon name="xmark" />
            </button>

            <ul>
              {
                associatedKpis.map(kpi => {
                  return (
                    <li key={`${kpi.code}`}>
                        <ReportingStandardTag standard={kpi.standard} code={kpi.code} /> 
                        <AplanetIcon name={'file-lines'} faStyle="fas" />
                        {kpi.name}
                    </li>
                  )
                })}
            </ul>
          </section>

        }

      </div>

      {is_custom && showEditKPIModal &&
        <ModalEditKPI
          key={id}
          onClose={() => setShowEditKPIModal(false)}
          name={name}
          name_translations={name_translations}
          kpi_slug={slug}
          sdgs={sdgs}
          standard_info={standard_info}
          schema={schema}
          schemaLabels={schemaLabels}
          loading={loading}
          error={error}
          esg_type={type}
          guidelines_std={guidelines_std}
          periodicities={periodicities}
          first_reported_at={first_reported_at}
          kpi_value_status_for_whole_tree={kpi_value_status_for_whole_tree}
          kpi_associations={kpi_associations}
        />
      }

      {
        showUfinetAddKPIDataModal && 
        <ModalUfinetAddKpiData
          key={id}
          error={error}
          kpi_slug={slug}
          name={name}
          name_translations={name_translations}
          schema={schema}
          all_periods={all_periods}
          onClose={() => setShowUfinetAddKPIDataModal(false)}
        />
      }

    </React.Fragment>
  );
};

export default injectIntl(Header);
