import { sum } from "lodash";

const getPositionPoints = (position, factors, type_points) => {
  return sum(
    position.factors.map(positionFactor => {
      const factor = factors
        .find(factor => factor.id === positionFactor.factor_id);
      const levelPoints = factor.levels
        .find(level => level.id === positionFactor.factor_level_id)
        .points;
      return (
        levelPoints
        * factor.percentage
        * (type_points?.[factor.type] || 0)
      );
    })
  );
};

const getPointsRangeForPositions = (positions, factors, type_points) => {
  let points = positions
    .map(position => getPositionPoints(position, factors, type_points));

  return [Math.min(...points), Math.max(...points)];
};

const E = Math.E;
const E2 = Math.E * Math.E;
const SALARY_CALCULATION_FUNCS = [
  // Upper
  (x) => 1 - (((E2 ** -(x - 1)) - 1) / (E2 - 1)),
  (x) => 1 - (((E ** -(x - 1)) - 1) / (E - 1)),
  // Straight
  (x) => x,
  // Lower
  (x) => ((E ** x) - 1) / (E - 1),
  (x) => ((E2 ** x) - 1) / (E2 - 1),
];

const getSalaryForPoints = (xmin, xmax, ymin, ymax) => (func) => (x) => {
  const normX = (x - xmin) / (xmax - xmin);
  const normY = SALARY_CALCULATION_FUNCS[func](normX);
  return ymin + (normY * (ymax - ymin));
};

export {
  getPointsRangeForPositions,
  getSalaryForPoints,
  getPositionPoints,
};