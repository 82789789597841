import React from 'react';
import Avatar from 'components/Avatar';

import {
  Col,
  Row,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';


const DataSourcesPermissionMember = ({
  member,
}) => {
  return (
    <Row gutter={[10, 10]} className="DataSourcesPermissionMember">
      <Col span={4}>
        <Avatar
          className="DataSourcesPermissionMember__avatar"
          src={member.member_avatar}
          name={member.member_name}
          shape="circle"
          size={38}
          icon={<UserOutlined />}
        />
      </Col>
      <Col span={20}>
        <Row>
          <Col span={24}>
            {member.member_name}
          </Col>
          <Col span={24}>
            {member.member_email}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default DataSourcesPermissionMember;
