import React from 'react';
import { withRouter } from "react-router";

import List from './FillAndReportTabs/List';
import BulkManage from './FillAndReportTabs/BulkManage';

const DEFAULT_TAB = 'aplanet';


const FillAndReport = ({
  history,
  match,
  ...rest
}) => {
  const activeTab = match.params.category || DEFAULT_TAB;

  return (
    <div>
    {
      activeTab === 'aplanet' &&
      <List {...rest} />
    }
    {
      activeTab === 'global' &&
      <BulkManage />
    }
    </div>
  );
}

export default withRouter(FillAndReport)
