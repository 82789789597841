import React, { useMemo, useCallback, useState, useEffect } from "react";
import { omit } from "lodash";
import useForm from 'utils/useForm';
import useOrganizations from "utils/useOrganizations";
import useSteps from "./useSteps";
import { injectIntl } from "react-intl";
import ModalWizard from "../ModalWizard";
import validation from './validation';
import { useDispatch } from "react-redux";
import { addKpis } from "actions/api";
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_CYCLE_DATE_OPTIONS = ['01-01'];

const CreateOrAddKpi = ({
    intl,
    visible = false,
    reportingStructure,
    reporting_structure_kpis,
    onCancel,
    onCreate,
    standardsKpis,
    loading,
    errorReportingStructure
}) => {

    const {
        organization,
        suborganization
    } = useOrganizations();
    const dispatch = useDispatch();

    const [isCreatingNewKpi, setIsCreatingNewKpi] = useState(false);
    const [isSavingKpis, setIsSavingKpis] = useState(false);

    const kpis = useMemo(() => (reportingStructure || []).map(({ kpis }) => kpis).reduce((acc, val) => acc.concat(val), []), [reportingStructure]);

    const newKpiDefaultValues = useMemo(() => ({
        name_translations: (organization?.config?.preferred_languages || []).map(locale => ({ locale, name: '' })),
        guidelines: (organization?.config?.preferred_languages || []).map(locale => ({ locale, guidelines: '' })),
        annual_start_date: '2018-01-01',
        periodicities: 'year',
        is_custom: true,
        kpi_associations: {}
    }), [organization]);

    const defaultValues = useMemo(() => ({
        selected_kpis: [],
        new_kpis: [],
        parents: [],
    }), []);

    const saveKpi = (newKpiValues) => {
        const name = kpiValues?.name_translations.find(({ locale }) => locale === suborganization.language).name;
        const {
            name_translations = [],
            esg_type,
            periodicities,
            annual_start_date,
            code,
            guidelines,
            is_custom,
            sdgs,
            kpi_associations,
            ...schema
        } = newKpiValues;
        const newKpi = {
            name,
            uuid: uuidv4(),
            isNew: true,
            schema: {...schema},
            name_translations: name_translations.filter(({ name }) => name !== ''),
            type: esg_type,
            periodicities: [periodicities],
            annual_start_date,
            code,
            guidelines,
            is_custom,
            sdgs,
            kpi_associations
        };
        handleChange('selected_kpis')([...values?.selected_kpis, newKpi])
        handleChange('new_kpis')([newKpi, ...values?.new_kpis])
        kpiResetForm();
        setIsCreatingNewKpi(false);
    };

    const cycleDateOptions = useMemo(() => {
        if (suborganization.slug === organization.slug) {
          // Top level org, all options are possible
          return organization?.config?.cycle_date_options || DEFAULT_CYCLE_DATE_OPTIONS;
        }
    
        // Suborgs only get the one possible cycle date
        // But notice this will never be used because for now you can only create KPIs in a top-level org
        return (
          suborganization?.config?.start_fiscal_year && [suborganization?.config?.start_fiscal_year]
        ) || (
            organization?.config?.cycle_date_options?.length > 0
            && [organization?.config?.cycle_date_options[0]]
          ) || DEFAULT_CYCLE_DATE_OPTIONS;
    }, [
        organization,
        suborganization,
    ]);

    const validateForm = useMemo(() => validation(intl), [intl]);
    const setNames = useMemo(() => ['sdgs'], [])
    const {
        values: kpiValues,
        handleChange: kpiHandleChange,
        handleSubmit: kpiHandleSubmit,
        errors: kpiErrors,
        resetForm: kpiResetForm,
    } = useForm({
        callback: saveKpi,
        validate: validateForm,
        setNames: setNames,
        defaultValues: newKpiDefaultValues,
        validationDefaults: { locale: suborganization.language, isNew: true },
        continousValidation: true
    });

    const allKPIs = useMemo(() => {
        const customKpis = (kpis || [])
            .filter((kpi) => !(standardsKpis || []).some(({ slug }) => kpi.slug === slug))
        return [...customKpis, ...(standardsKpis || [])]
    }, [kpis, standardsKpis]);

    const createOrAddKpis = ((kpiValues) => {

        dispatch(addKpis({
            organization_slug: organization.slug,
            suborganization_slug: suborganization.slug,
            parents: kpiValues.parents,
            kpis: kpiValues.selected_kpis.map(kpi => omit(kpi, ['standard']))
        }))
        setIsSavingKpis(true)
    });

    const {
        values,
        handleChange,
        handleSubmit,
        isDirty,
        errors,
        resetForm
    } = useForm({
        callback: createOrAddKpis,
        validate: useCallback(() => ({}), []),
        defaultValues
    });

    const onClose = useCallback(() => {
        resetForm()
        setIsCreatingNewKpi(false)
        onCancel()
    }, [resetForm, onCancel, setIsCreatingNewKpi]);

    useEffect(() => {
        if (visible && !loading && !errorReportingStructure && reportingStructure?.length > 0 && isSavingKpis) {
            setIsCreatingNewKpi(false)
            setIsSavingKpis(false)
            resetForm()
            onCreate()
        }
    }, [
        reportingStructure,
        errorReportingStructure,
        intl,
        loading,
        onCreate,
        onClose,
        resetForm,
        setIsSavingKpis,
        visible,
        isSavingKpis
    ]);

    useEffect(() => {
        if (visible && errorReportingStructure && isSavingKpis) {
            message.error(intl.formatMessage({ id: "create_or_edit_category_error_message" }))
            setIsSavingKpis(false)
        }
    }, [
        errorReportingStructure,
        intl,
        isSavingKpis,
        visible
    ])

    const onFinish = useCallback(() => {
        handleSubmit()
    }, [handleSubmit]);

    const handleNewKpi = useCallback((name, type) => {
        kpiHandleChange('type')(type);
        const orgLocale = suborganization.language;
        const nt = kpiValues?.name_translations.map(value => value?.locale === orgLocale ? ({ locale: orgLocale, name }) : value);
        kpiHandleChange('name_translations')(nt)
        setIsCreatingNewKpi(true);
    }, [suborganization, kpiValues, kpiHandleChange]);

    const submitNewKpi = useCallback((save = false) => {
        if (save) {
            kpiHandleSubmit();
        } else {
            setIsCreatingNewKpi(false);
        }
    }, [kpiHandleSubmit]);

    const flatKpiTree = useMemo(() => ([...values?.new_kpis, ...allKPIs]), [values?.new_kpis, allKPIs]);

    const steps = useSteps({
        intl,
        values,
        reportingStructure,
        errors,
        isDirty,
        isCreatingNewKpi,
        handleChange,
        handleNewKpi,
        flatTree: flatKpiTree,
        kpiValues,
        kpiErrors,
        kpiHandleChange,
        organizationName: organization.name,
        organization,
        suborganization,
        reporting_structure_kpis,
        cycleDateOptions
    });

    return (<ModalWizard
        className="CreateOrEditCategory-modal"
        title={intl.formatMessage({ id: 'create_or_edit_kpi_title' })}
        visible={visible}
        steps={steps}
        onCancel={onClose}
        onFinish={onFinish}
        afterClose={onClose}
        isCreating={isCreatingNewKpi}
        saveAction={(value) => submitNewKpi(value)}
        loading={loading}
        errors={kpiErrors}
    />);
};

export default injectIntl(CreateOrAddKpi);
