import React, { useMemo, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import {
  Row,
  Col,
  Badge,
  Progress,
  Collapse,
  Space,
} from 'antd';

import CustomTable from 'components/CustomTable';
import IndicatorDetailModal from './IndicatorDetailModal';
import { fromDb, formatDate, DATE_TIME } from 'utils/date';
import CustomButton from 'components/CustomButton';

import './style.less';


const IndicatorProgress = ({
  intl,
  action,
  indicator,
  sequence,
  updateActionIndicator,
}) => {
  const t = intl.messages;
  const [showDetailModal, setShowDetailModal] = useState(false);

  const progress = useMemo(
    () => {
      return Math.round(
        indicator.current_value / indicator.target_value * 100
      );
    },
    [indicator]
  );

  const actionStatus = useMemo(
    () => {
      if (new Date() > fromDb(action.end_date)) {
        return 'expired';
      } else if (action.cancel_reason) {
        return 'canceled';
      } else if (action.completed) {
        return 'completed';
      } else {
        return;
      }
    },
    [action]
  );

  const indicatorStatus = useMemo(
    () => {
      let status = 'in_progress';

      if (actionStatus === 'canceled' || indicator.cancel_reason) {
        status = 'canceled';
      } else if (indicator.current_value >= indicator.target_value) {
        status = 'completed';
      } else if (
        actionStatus === 'expired' ||
        new Date() > fromDb(indicator.end_date)
      ) {
        status = 'expired';
      }

      return status;
    },
    [indicator, actionStatus]
  );

  const progressBarAndStatus = useMemo(
    () => {
      let status = null;
      let type = null;
      let strokeColor = '#309309';

      if (indicatorStatus === 'canceled' || actionStatus === 'canceled') {
        status = `${t.canceled.toUpperCase()}`;

        if (indicator.cancel_reason) {
          status += `- ${indicator.cancel_reason}`;
        }
        type = 'danger';
        strokeColor = '#df1f1e';
      } else if (indicatorStatus === 'completed') {
        status = t.completed.toUpperCase();
        type = 'success';
      } else if (indicatorStatus === 'expired' || actionStatus === 'expired') {
        if (actionStatus === 'expired') {
          status = t.expired.toUpperCase();
        } else {
          const daysExpiredSince = Math.round(
            (new Date() - fromDb(indicator.end_date)) / (1000 * 3600 * 24)
          );
          status = <FormattedMessage
            id="expired_days_ago"
            values={{ days: daysExpiredSince }} />;
        }
        type = 'warning';
        strokeColor = '#ed9150';
      } else {
        const daysLeft = Math.round(
          (fromDb(indicator.end_date) - new Date()) / (1000 * 3600 * 24)
        );
        status = <FormattedMessage
          id="days_left"
          values={{ days: daysLeft }} />;
      }
      return (
        <>
          <Col span={24}>
            <Progress
              className='action-progress-bar'
              percent={progress}
              strokeColor={strokeColor} />
          </Col>
          <Col span={24}>
            <span className={`action-status ${type}`}>{status}</span>
          </Col>
        </>
      );
    },
    [t, indicator, progress, indicatorStatus, actionStatus]
  );

  const activityColumns = useMemo(
    () => (
      [{
        title: t.date,
        dataIndex: 'created_at',
        render: (text) => formatDate(text, DATE_TIME, intl),
      }, {
        title: t.value,
        dataIndex: 'value',
      }, {
        title: t.comment,
        dataIndex: 'comment',
      }, {
        title: t.user,
        dataIndex: 'member_name',
      }]
    ),
    [t, intl]
  );

  return (
    <Row>
      <Col span={23}>
        <Row className='action-progress-header'>
          <Col span={24}>
            <Space size="small">
              <span className="action-progress-header-title">
                {indicator.name}
              </span>
              {(!['expired', 'completed'].includes(indicatorStatus) && actionStatus !== 'completed') &&
                <CustomButton
                  type="primary"
                  size="small"
                  onClick={() => setShowDetailModal(true)}
                >
                  {t.update}
                </CustomButton>
              }
            </Space>
          </Col>
          {progressBarAndStatus}
        </Row>
      </Col>
      <Col span={1}>
        <Badge className='form-item-preview-badge' count={sequence} />
      </Col>
      <Col span={24}>
      <Collapse ghost>
        <Collapse.Panel header={t.updates} key="1">
          <CustomTable
            columns={activityColumns}
            dataSource={indicator.activity}
            rowKey="id"
          />
        </Collapse.Panel>
      </Collapse>
      </Col>
      <IndicatorDetailModal
        visible={showDetailModal}
        indicator={indicator}
        indicatorStatus={indicatorStatus}
        status={indicatorStatus}
        onClose={() => setShowDetailModal(false)}
        updateActionIndicator={updateActionIndicator} />
    </Row>
  )
};

export default injectIntl(IndicatorProgress);
