import React, { useCallback, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import T from 'components/T';
import {
  updateDimensionName,
} from 'components/CreateEditCustomKPI/forms/Table/utils';

import CustomButton from 'components/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import {
  Col,
  Divider,
  Row,
  Space,
  Form,
} from 'antd';
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';


const Rows = ({
  intl,
  mode,
  values,
  onChange,
  affectsKpiValue,
  setAffectsKpiValue,
}) => {
  const t = intl.messages;

  const {
    kpi_variables = [],
  } = useSelector(state => state.taxonomies);

  const rowDimensions = useMemo(
    () => values.dimensions &&
      values.dimensions.filter(dimension => dimension.presentation === 'row'),
    [values.dimensions]
  );

  const [selectedMethod, setSelectedMethod] = useState(
    mode === 'edit' && rowDimensions?.[0]
    ? rowDimensions[0].source === 'user'
      ? 'auto_extend'
      : 'fixed_rows'
    : null
  );
  const [newVariableValue, setNewVariableValue] = useState();

  const handleSelectAutoExtend = useCallback(
    e => {
      setAffectsKpiValue([...affectsKpiValue, 'row_switch_dimension_type']);
      setSelectedMethod(e.target.checked ? 'auto_extend' : null);

      if (e.target.checked) {
        onChange('dimensions')([
          {
            calculations: [],
            presentation: "row",
            source: "user",
            standardItems: [],
          },
          ...(values.dimensions || [])
            .filter(dimension => dimension.presentation !== 'row'),
        ]);
      } else {
        onChange('dimensions')(
          (values.dimensions || [])
            .filter(dimension => dimension.presentation !== 'row')
        );
      }
    },
    [
      onChange,
      values,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleOnChangeAutoExtendDimensionName = useCallback(
    (e) => {
      let index = values.dimensions.findIndex(
        dimension => dimension.presentation === 'row'
      );
      let dimensions = [...values.dimensions];
      dimensions[index] = updateDimensionName(dimensions[index], e.target.value);
      onChange('dimensions')(dimensions);
    },
    [onChange, values]
  );

  const handleSelectFixedRows = useCallback(
    e => {
      setAffectsKpiValue([...affectsKpiValue, 'row_switch_dimension_type']);
      onChange('dimensions')([
        ...(e.target.checked ? [{
          presentation: 'row',
        }] : []), // Render first level by default
        ...(values.dimensions || [])
          .filter(dimension => dimension.presentation !== 'row')
      ]);
      setSelectedMethod(e.target.checked ? 'fixed_rows' : null);
    },
    [
      onChange,
      values,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleSelectExistingVariable = useCallback(
    (e, index) => {
      setAffectsKpiValue([...affectsKpiValue, 'row_fixed_select_existing_variable']);
      let updatedRowDimensions = [...rowDimensions];
      updatedRowDimensions[index] = {
        calculations: [],
        presentation: "row",
        source: "organization",
        standardItems: [],
      };
      onChange('dimensions')([
        ...updatedRowDimensions,
        ...(values.dimensions || [])
          .filter(dimension => dimension.presentation !== 'row'),
      ]);
    },
    [
      onChange,
      values,
      rowDimensions,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleSelectExistingVariableOption = useCallback(
    (by, index) => {
      setAffectsKpiValue([...affectsKpiValue, 'row_fixed_select_existing_variable_option']);
      let updatedRowDimensions = [...rowDimensions];
      updatedRowDimensions[index] = {
        ...updatedRowDimensions[index],
        by,
      };
      onChange('dimensions')([
        ...updatedRowDimensions,
        ...(values.dimensions || [])
          .filter(dimension => dimension.presentation !== 'row'),
      ]);
    },
    [
      values,
      onChange,
      rowDimensions,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleSelectNewVariable = useCallback(
    (e, index) => {
      setAffectsKpiValue([...affectsKpiValue, 'row_fixed_select_new_variable']);
      let updatedRowDimensions = [...rowDimensions];
      updatedRowDimensions[index] = {
        calculations: [],
        presentation: "row",
        source: "standard",
        standardItems: [],
      };
      onChange('dimensions')([
        ...updatedRowDimensions,
        ...(values.dimensions || [])
          .filter(dimension => dimension.presentation !== 'row'),
      ]);
    },
    [
      onChange,
      values,
      rowDimensions,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleChangeNewVariableName = useCallback(
    (e, index) => {
      let updatedRowDimensions = [...rowDimensions];
      updatedRowDimensions[index] = updateDimensionName({
        ...updatedRowDimensions[index],
      }, e.target.value);

      onChange('dimensions')([
        ...updatedRowDimensions,
        ...(values.dimensions || [])
          .filter(dimension => dimension.presentation !== 'row'),
      ]);
    },
    [rowDimensions, onChange, values]
  );

  const handleAddNewVariableValue = useCallback(
    (index) => {
      if (!newVariableValue) {
        return;
      }

      let updatedRowDimensions = [...rowDimensions];
      updatedRowDimensions[index] = {
        ...updatedRowDimensions[index],
        standardItems: [
          ...updatedRowDimensions[index].standardItems,
          {name: newVariableValue},
        ],
      };
      onChange('dimensions')([
        ...updatedRowDimensions,
        ...(values.dimensions || [])
          .filter(dimension => dimension.presentation !== 'row'),
      ]);
      setNewVariableValue();
    },
    [rowDimensions, onChange, values, newVariableValue]
  );

  const handleRemoveNewVariableValue = useCallback(
    (name, index) => {
      let updatedRowDimensions = [...rowDimensions];
      updatedRowDimensions[index] = {
        ...updatedRowDimensions[index],
        standardItems: updatedRowDimensions[index].standardItems
          .filter(item => item.name !== name),
      };

      onChange('dimensions')([
        ...updatedRowDimensions,
        ...(values.dimensions || [])
          .filter(dimension => dimension.presentation !== 'row'),
      ]);
    },
    [rowDimensions, onChange, values]
  );

  const tableVariableOptions = useMemo(
    () => Object.entries(kpi_variables)
      .filter(([_, {deprecated}]) => !deprecated)
      .map(([slug, {name}]) => ({ name, slug })),
    [kpi_variables]
  );

  const handleAddNewLevel = useCallback(
    () => {
      setAffectsKpiValue([...affectsKpiValue, `row_fixed_add_level`]);
      onChange('dimensions')([
        ...(values.dimensions || [])
          .filter(dimension => dimension.presentation === 'row'),
        {
          presentation: 'row'
        }, // Render new empty level
        ...(values.dimensions || [])
          .filter(dimension => dimension.presentation !== 'row')
      ]);
    },
    [
      onChange,
      values,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleOnRemoveLevel = useCallback(
    (index) => {
      setAffectsKpiValue([...affectsKpiValue, `row_fixed_remove_level`]);
      onChange('dimensions')([
        ...(values.dimensions || [])
          .filter(dimension => dimension.presentation === 'row')
          .slice(0, index),
        ...(values.dimensions || [])
          .filter(dimension => dimension.presentation === 'row')
          .slice(index + 1),
        ...(values.dimensions || [])
          .filter(dimension => dimension.presentation !== 'row'),
      ]);
    },
    [
      onChange,
      values,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  return (
    <div className="CreateCustomKpi-table-rows ModalEditKPI-table-rows">
      <Row className="CreateCustomKpi-table-rows-auto ModalEditKPI-table-rows-auto">
        <Col span={24} className="CreateCustomKpi-table-rows-auto-checkbox ModalEditKPI-table-rows-auto-checkbox">
          <CustomCheckbox
            checked={selectedMethod === 'auto_extend'}
            onChange={handleSelectAutoExtend}
          >
            {t.createcustomkpi_table_tab_rows_auto_extend}
          </CustomCheckbox>
        </Col>
        <Col span={24} className="CreateCustomKpi-table-rows-auto-desc ModalEditKPI-table-rows-auto-desc">
          <span>{t.createcustomkpi_table_tab_rows_auto_extend_desc}</span>
        </Col>
        { selectedMethod === 'auto_extend' &&
          <Col span={24} className="CreateCustomKpi-table-rows-auto-form ModalEditKPI-table-rows-auto-form">
            <Form.Item
              label={ t.createcustomkpi_table_tab_rows_auto_extend_name }
              hasFeedback
              colon={false}
              required
            >
              <CustomInput
                value={rowDimensions?.[0]?.byName}
                onChange={handleOnChangeAutoExtendDimensionName}
              />
            </Form.Item>
          </Col>
        }
      </Row>
      <Row className="CreateCustomKpi-table-rows-fixed ModalEditKPI-table-rows-fixed">
        <Col span={24}>
          <CustomCheckbox
            checked={selectedMethod === 'fixed_rows'}
            onChange={handleSelectFixedRows}
          >
            {t.createcustomkpi_table_tab_rows_fixed_rows}
          </CustomCheckbox>
        </Col>
        { selectedMethod === 'fixed_rows' &&
          <>
            {rowDimensions.map((rowDimension, index) =>
              <Col
                span={24}
                className="CreateCustomKpi-table-rows-fixed-form ModalEditKPI-table-rows-fixed-form"
                key={index}
              >
                <Row gutter={[15, 15]}>
                  <Col span={24}>
                    <Space>
                      <span>
                        <b>
                          <T
                            createcustomkpi_table_tab_rows_fixed_rows_level={{
                              level: index + 1
                            }}
                          />
                        </b>
                      </span>
                      <MinusCircleOutlined
                        onClick={() => handleOnRemoveLevel(index)}
                      />
                    </Space>
                  </Col>
                  <Col span={24}>
                    <CustomCheckbox
                      checked={rowDimension.source === 'organization'}
                      onChange={e => handleSelectExistingVariable(e, index)}
                    >
                      {t.createcustomkpi_table_tab_rows_fixed_rows_existing_variable}
                    </CustomCheckbox>
                  </Col>
                  { rowDimension.source === 'organization' &&
                    <>
                      <Col span={24}>
                        <CustomSelect
                          options={tableVariableOptions}
                          selected={rowDimension.by}
                          onSelect={value => handleSelectExistingVariableOption(value, index)}
                        />
                      </Col>
                      {rowDimension.by && kpi_variables[rowDimension.by] &&
                        <Col span={24}>
                          {t.createcustomkpi_table_values}: {
                            Object.values(kpi_variables[rowDimension.by].options || {})
                              .join(', ')
                          }
                        </Col>
                      }
                    </>
                  }
                  <Col span={24}>
                    <CustomCheckbox
                      checked={rowDimension.source === 'standard'}
                      onChange={e => handleSelectNewVariable(e, index)}
                    >
                      {t.createcustomkpi_table_tab_rows_fixed_rows_new_variable}
                    </CustomCheckbox>
                  </Col>
                  { rowDimension.source === 'standard' &&
                    <>
                      <Col span={24}>
                        <Form.Item
                          label={ t.createcustomkpi_table_tab_rows_fixed_rows_new_variable_name }
                          hasFeedback
                          colon={false}
                          required
                        >
                          <CustomInput
                            value={rowDimension.byName}
                            onChange={e => handleChangeNewVariableName(e, index)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={ t.createcustomkpi_table_tab_rows_fixed_rows_new_variable_values }
                          hasFeedback
                          colon={false}
                          required
                        >
                          <Space direction="vertical">
                            <Space>
                              <CustomInput
                                value={newVariableValue}
                                onChange={e => setNewVariableValue(e.target.value)}
                              />
                              <PlusCircleOutlined
                                onClick={() => handleAddNewVariableValue(index)}
                              />
                            </Space>
                            <Space direction="vertical">
                              {rowDimension.standardItems.map(item => {
                                return (
                                  <Space key={item.name}>
                                    <span>{item.name}</span>
                                    <MinusCircleOutlined
                                      onClick={() => handleRemoveNewVariableValue(item.name, index)}
                                    />
                                  </Space>
                                )
                              })}
                            </Space>
                          </Space>
                        </Form.Item>
                      </Col>
                    </>
                  }
                </Row>
                <Divider />
              </Col>
            )}
            <Col
              className="CreateCustomKpi-table-rows-fixed-form ModalEditKPI-table-rows-fixed-form"
              span={24}
            >
              <CustomButton
                type="primary"
                size="small"
                onClick={handleAddNewLevel}
              >
                {t.createcustomkpi_table_tab_rows_fixed_rows_level_add}
              </CustomButton>
            </Col>
          </>
        }
      </Row>
    </div>
  );
};

export default injectIntl(Rows);
