// To store local app state

const initialState = {
  isSidebarCollapsed: true,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'TOGGLE_SIDEBAR':
      return {
        ...state,
        isSidebarCollapsed: (
          action.isCollapsed !== undefined
          ? action.isCollapsed
          : !state.isSidebarCollapsed
        ),
      }
    default:
      return state;
  }
};

export {
  reducer as app,
};
