import React, { useCallback, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useOrganizations from 'utils/useOrganizations';
import validateFormula from 'utils/kpi_formula/validator';
import {
  updateDimensionName,
} from 'components/CreateEditCustomKPI/forms/Table/utils';

import CustomButton from 'components/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';
import CustomTable from 'components/CustomTable';
import {
  Col,
  Row,
  Space,
  Form,
  TreeSelect,
} from 'antd';
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';


const { SHOW_PARENT, SHOW_CHILD } = TreeSelect;
const NOT_NUMBER_RE = new RegExp('\\D', 'g');
const DECIMALS_FIELD = 'decimalPoints';

const NO_ERRORS = {};
const DEFAULT_KPI_SLUG = 'default-kpi-slug-for-validation';

const schemaTypeToColumnType = {
  qualitative: 'text',
  quantitative: 'number',
  quantitative_calculated: 'number',
  choice: 'choice',
  choice_variable: 'choice',
  boolean: 'boolean',
};

const Columns = ({
  intl,
  mode,
  values,
  onChange,
  affectsKpiValue,
  setAffectsKpiValue,
}) => {
  const t = intl.messages;
  const [currentColumnIndex, setCurrentColumnIndex] = useState(null);
  const [currentColumn, setCurrentColumn] = useState();
  const [currentColumnErrors, setCurrentColumnErrors] = useState(NO_ERRORS);
  const [newChoiceOption, setNewChoiceOption] = useState();

  const columnDimension = useMemo(
    () => values.dimensions
      && values.dimensions.find(dimension => dimension.presentation === 'column'),
    [values.dimensions]
  );

  const [selectedMethod, setSelectedMethod] = useState(
    mode === 'edit'
    ? columnDimension && columnDimension.source !== 'singleton'
      ? 'variable'
      : 'custom'
    : null
  );

  const [variableMethod, setVariableMethod] = useState(
    mode === 'edit' && selectedMethod === 'variable' && columnDimension
    ? columnDimension.source === 'organization'
      ? 'existing_variable'
      : 'new_variable'
    : null
  );

  const [newVariableValue, setNewVariableValue] = useState();

  const {
    metrics = [], // This is really aggregated metrics
  } = useSelector(state => state.taxonomies);

  const {
    data: userProfile,
  } = useSelector(state => state.profile);

  const isSystem = (userProfile || {}).role === 'system'; // NOTICE: For now only system users can add calculated columns

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const {
    kpi_variables = {},
  } = useSelector(state => state.taxonomies);

  const metricsAndUnits = useMemo(() => {
    const preferredUnits = (suborganization?.config || organization?.config || {}).preferred_units || {};
    return metrics.map((metric, metricIndex) => {
      return metric.metrics.map((submetric, submetricIndex) => {
        const updatedColumn = {...currentColumn};
        const disabled = updatedColumn['metricSlug'] ? !(updatedColumn['metricSlug'] === submetric.slug) : false;
        let units = submetric.units || [];

        if(preferredUnits[submetric.slug] && preferredUnits[submetric.slug].length > 0) {
          const preferredSet = new Set(preferredUnits[submetric.slug]);
          units = units.filter(({ slug }) => preferredSet.has(slug));
          if(units.length === 0) {
            units = submetric.units;
          }
        }
        return {
          title: metric.name === submetric.name || metric.name === '...'
          ? submetric.name
          : `${metric.name} - ${submetric.name}`,
          value: `${metricIndex}-${submetricIndex}-${submetric.name}-${submetric.symbol ?? ''}`,
          key: `${metricIndex}-${submetricIndex}-${submetric.name}-${submetric.symbol ?? ''}`,
          slug: submetric.slug,
          disabled,
          children: units.map((unit, unitIndex) => ({
            title: unit.symbol ? `${unit.name} (${unit.symbol})` : unit.name,
            value: `${metricIndex}-${submetricIndex}-${unitIndex}-${unit.name}-${unit.symbol ?? ''}`,
            key: `${metricIndex}-${submetricIndex}-${unitIndex}-${unit.name}-${unit.symbol ?? ''}`,
            slug: unit.slug,
            disabled
          })),
        };
      });
    }).reduce((acc, val) => acc.concat(val), []);
  }, [currentColumn, metrics, organization.config, suborganization.config]);

  const getCheckedValues = useCallback((type) => {
    const schema = type === 'custom' ? currentColumn : values.innerSchema;
    const metricSlug = schema['metricSlug'];
    const currentMetric = metricsAndUnits.filter(mu => mu.slug === metricSlug);
    const allowedUnitSlugs = schema['allowedUnitSlugs'];
    const areAllUnits = metricsAndUnits[0].children.every(c => allowedUnitSlugs?.includes(c.slug));

    return areAllUnits ? currentMetric.map(cm => cm.value) : currentMetric
    .map(mu => mu.children.filter(c => allowedUnitSlugs?.includes(c.slug)))
    .reduce((acc, val) => acc.concat(val), [])
    .map(c => c.value);
  }, [currentColumn, metricsAndUnits, values.innerSchema]);

  const handleChangeUnit = useCallback((unitSlugs) => {
    const metric = metricsAndUnits.find(({ children = [], key }) => unitSlugs.includes(key) || children.find(({ key }) => unitSlugs.includes(key)));
    const units = metric?.children.filter(child => unitSlugs.includes(child.key)) || [];

    const updatedColumn = {...currentColumn};

    updatedColumn['metricSlug'] = metric?.slug;
    updatedColumn['allowedUnitSlugs'] = units.length ? units.map(u => u.slug) : (metric?.children || []).map(c => c.slug);
    setCurrentColumn(updatedColumn);
  }, [currentColumn, metricsAndUnits]);

  const handleChangeVariableUnit = useCallback((unitSlugs) => {
    const metric = metricsAndUnits.find(({ children = [], key }) => unitSlugs.includes(key) || children.find(({ key }) => unitSlugs.includes(key)));
    const units = metric?.children.filter(child => unitSlugs.includes(child.key)) || [];

    onChange('innerSchema')({
      ...values.innerSchema,
      metricSlug: metric?.slug,
      allowedUnitSlugs: units.length ? units.map(u => u.slug) : (metric?.children || []).map(c => c.slug),
    });
  }, [
    values,
    metricsAndUnits,
    onChange,
  ]);

  const getTreeSelectProps = useCallback((type = 'variable', allowChoosingMetrics = true) => ({
    treeData: metricsAndUnits,
    onChange: type === 'custom' ? handleChangeUnit : handleChangeVariableUnit,
    value: getCheckedValues(type),
    treeCheckable: allowChoosingMetrics,
    showCheckedStrategy: allowChoosingMetrics ? SHOW_PARENT : SHOW_CHILD,
    placeholder: t.createcustomkpi_select_unit,
    style: {
      width: '100%',
    },
    size: 'large'
  }), [
    getCheckedValues,
    handleChangeUnit,
    metricsAndUnits,
    t.createcustomkpi_select_unit,
    handleChangeVariableUnit,
  ]);

  const INTEGER_FORMATTER = useCallback(intl => (value) => `${value}`.replace(NOT_NUMBER_RE, ''), []);
  const INTEGER_PARSER = useCallback(intl => value => value.replace(NOT_NUMBER_RE, ''), []);

  const schemaProps = useMemo(() => {
    return {
      precision: 0,
      step: 1,
      min: 0,
      max: 6,
      formatter: (INTEGER_FORMATTER)(intl),
      parser: (INTEGER_PARSER)(intl),
    };
  }, [INTEGER_FORMATTER, INTEGER_PARSER, intl]);

  const handleChangeDecimals = useCallback((decimals) => {
    const finalValue = decimals < schemaProps.min ? schemaProps.min : decimals > schemaProps.max ? schemaProps.max : decimals;
    const updatedColumn = {...currentColumn};
    updatedColumn[DECIMALS_FIELD] = finalValue;
    setCurrentColumn(updatedColumn);
  }, [currentColumn, schemaProps.max, schemaProps.min]);

  const handleChangeFormula = useCallback((e) => {
    const formula = e.target.value;
    const updatedColumn = {
      ...currentColumn,
      formula,
    };
    setCurrentColumn(updatedColumn);
    setCurrentColumnErrors(
      validateFormula(intl)({
        formula,
        context: { default_kpi_slug: DEFAULT_KPI_SLUG }, // NOTICE: this does not matter because it's only used to validate
      })
    );
  }, [
    currentColumn,
    intl,
  ]);

  const decimalPoints = useMemo(() => {
    return currentColumn?.decimalPoints ?? schemaProps.min;
  }, [currentColumn, schemaProps.min])

  const formula = useMemo(() => {
    return currentColumn?.formula ?? '';
  }, [currentColumn])

  const getColumnTypeForSchema = useCallback(
    schema => {
      if (!schema?.type) {
        return;
      }
      if (schema.type === 'quantitative' && schema.source === 'calculated'){
        return 'number_calculated';
      }
      if (schema.type === 'quantitative' && schema.metricSlug === 'percentage') {
        return 'percentage';
      }
      if (schema.type === 'choice' && schema.options.source === 'organization'){
        return 'choice_variable';
      }
      return schemaTypeToColumnType[schema.type];
    },
    []
  );

  const columnTypeOptions = useMemo(
    () => {
      const allowCalculated = isSystem || (
        getColumnTypeForSchema(values?.innerSchema) === 'number_calculated'
      );
      const options = allowCalculated
        ? ['text', 'number', 'number_calculated', 'percentage', 'choice', 'choice_variable', 'boolean']
        : ['text', 'number', 'percentage', 'choice', 'choice_variable', 'boolean']

      return options.map(option => ({
        slug: option,
        name: t[`createcustomkpi_table_tab_columns_type_${option}`]
      }))
    },
    [
      t,
      isSystem,
      getColumnTypeForSchema,
      values,
    ]
  );

  const handleAddNewColumn = useCallback(
    () => {
      setCurrentColumnIndex(
        columnDimension
          ? 1
          : (values.innerSchema?.components || []).length
      )
      setCurrentColumn({});
      setCurrentColumnErrors(NO_ERRORS);
    },
    [values, columnDimension]
  );

  const handleOnSaveColumn = useCallback(
    () => {
      if (
        !values.innerSchema || (
          values.innerSchema.type !== 'tuple' && currentColumnIndex === 0
        )
      ) {
        const {
          name: by,
          componentName: byName,
          ...schema
        } = currentColumn;
        onChange('dimensions')([
          ...values.dimensions.filter(dimension => dimension.presentation !== 'column'),
          {
            by: by || "",
            byName,
            calculations: [],
            presentation: "column",
            source: "singleton",
            standardItems: [],
          },
        ]);
        onChange('innerSchema')(schema);
      } else if (values.innerSchema.type === 'tuple') {
        onChange('innerSchema')({
          ...values.innerSchema,
          components: [
            ...values.innerSchema.components.slice(0, currentColumnIndex),
            currentColumn,
            ...values.innerSchema.components.slice(currentColumnIndex + 1)
          ]
        });
      } else {
        onChange('innerSchema')({
          type: 'tuple',
          components: [
            {
              ...values.innerSchema,
              name: columnDimension.by,
              componentName: columnDimension.byName,
            },
            currentColumn,
          ]
        });
        onChange('dimensions')([
          ...values.dimensions.filter(dimension => dimension.presentation !== 'column'),
        ]);
      }
      setCurrentColumnIndex();
      setCurrentColumn();
      setCurrentColumnErrors(NO_ERRORS);
    },
    [
      currentColumn,
      currentColumnIndex,
      onChange,
      values,
      columnDimension
    ]
  );

  const handleOnCancelSaveColumn = useCallback(
    () => {
      setCurrentColumnIndex();
      setCurrentColumn();
      setCurrentColumnErrors(NO_ERRORS);
    },
    []
  );

  const getSchemaForColumnType = useCallback(
    (type) => {
      switch(type) {
        case 'percentage':
          return {
            aggregationTime: "none",
            allowNegative: true,
            allowedUnitSlugs: ["percentage"],
            decimalPoints: 2,
            metricSlug: "percentage",
            type: "quantitative",
          };
        case 'number':
          return {
            aggregationTime: "none",
            allowNegative: true,
            allowedUnitSlugs: ["number"],
            decimalPoints: 0,
            metricSlug: "number",
            type: "quantitative",
          };
        case 'number_calculated':
          return {
            aggregationTime: "none",
            allowNegative: true,
            allowedUnitSlugs: [],
            decimalPoints: 0,
            metricSlug: null,
            source: "calculated",
            formula: "",
            type: "quantitative",
          };
        case 'choice':
          return {
            type: 'choice',
            options: {
              source: 'standard',
              standardItems: [],
            },
          };
        case 'choice_variable':
          return {
            type: 'choice',
            options: {
              source: 'organization',
              standardItems: []
            },
          };
        case 'boolean':
          return {
            type: 'boolean',
          };
        default:
          return {
            type: "qualitative",
          };
      }
    },
    []
  );

  const handleSelectColumnType = useCallback(
    type => {
      if (currentColumn.name && (currentColumn.type !== type)) {
        setAffectsKpiValue([...affectsKpiValue, 'column_change_type']);
      }
      setCurrentColumn(getSchemaForColumnType(type));
      setCurrentColumnErrors(NO_ERRORS);
    },
    [
      getSchemaForColumnType,
      currentColumn,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleChangeColumnName = useCallback(
    e => {
      let updatedColumn = {
        ...currentColumn,
        componentName: e.target.value,
      };

      if (currentColumn.type === 'choice') {

        if (currentColumn.options.source === 'standard'){
          updatedColumn.options.by = "";
        }
        
        updatedColumn.options.byName = e.target.value;
      }

      setCurrentColumn(updatedColumn);
    },
    [currentColumn]
  );
  
  const handleOnEditColumn = useCallback(
    (column, index) => {
      setCurrentColumn(column);
      setCurrentColumnIndex(index);

      // TODO: Make this generic
      if(column && column.formula) {
        setCurrentColumnErrors(
          validateFormula(intl)({
            formula: column.formula,
            context: { default_kpi_slug: DEFAULT_KPI_SLUG }, // NOTICE: this does not matter because it's only used to validate
          })
        );
      } else {
        setCurrentColumnErrors(NO_ERRORS);
      }
    },
    [
      intl,
    ]
  );

  const handleOnDeleteColumn = useCallback(
    (index) => {
      if (values.innerSchema.type !== 'tuple') {
        onChange('dimensions')([
          ...values.dimensions.filter(dimension => dimension.presentation !== 'column')
        ]);
        onChange('innerSchema')();
      } else {
        const updatedComponents = [
          ...values.innerSchema.components.slice(0, index),
          ...values.innerSchema.components.slice(index + 1)
        ];

        if (updatedComponents.length === 1) {
          const {
            name: by,
            componentName: byName,
            ...schema
          } = updatedComponents[0];

          onChange('dimensions')([
            ...values.dimensions.filter(dimension => dimension.presentation !== 'column'),
            {
              by: by || "",
              byName,
              calculations: [],
              presentation: "column",
              source: "singleton",
              standardItems: [],
            },
          ]);
          onChange('innerSchema')(schema);
        } else {
          onChange('innerSchema')({
            ...values.innerSchema,
            components: updatedComponents,
          });
        }
      }
    },
    [onChange, values]
  );

  const getUnitName = useCallback((metricSlug, unitSlugs) => {
    const metric = metricsAndUnits.find(metric => metric.slug === metricSlug);
    return metric?.children.filter(unit => unitSlugs.includes(unit.slug)).map(unit => unit.title).join(', ');
  }, [
    metricsAndUnits,
  ]);

  const tableVariableOptions = useMemo(
    () => Object.entries(kpi_variables)
      .filter(([_, {deprecated}]) => !deprecated)
      .map(([slug, {name}]) => ({ name, slug })),
    [kpi_variables]
  );

  const columnTableColumns = useMemo(
    () => {
      return [{
        title: t.createcustomkpi_table_tab_columns_name,
        dataIndex: 'componentName',
      }, {
        title: t.createcustomkpi_table_tab_columns_type,
        dataIndex: 'type',
        render: (_, record) => {
          const columnType = getColumnTypeForSchema(record);
          const value = t[`createcustomkpi_table_tab_columns_type_${columnType}`];

          if (['number', 'number_calculated'].includes(columnType)) {
            return (
              <Space  direction="vertical">
                <span>{value}</span>
                <span>{t.createcustomkpi_table_tab_columns_unit}: {getUnitName(record.metricSlug, record.allowedUnitSlugs)}</span>
              </Space>
            )
          }
          return value;
        }
      }, {
        title: t.createcustomkpi_table_tab_columns_actions,
        render: (_, record, index) => {
          return <Space size="small">
            <CustomButton
              type="primary"
              onClick={() => handleOnEditColumn(record, index)}
            >
              {t.createcustomkpi_table_tab_columns_actions_edit}
            </CustomButton>
            <CustomButton
              danger={true}
              onClick={() => handleOnDeleteColumn(index)}
            >
              {t.createcustomkpi_table_tab_columns_actions_delete}
            </CustomButton>
          </Space>
        }
      }]
    },
    [
      t,
      getColumnTypeForSchema,
      handleOnEditColumn,
      handleOnDeleteColumn,
      getUnitName,
    ]
  );

  const getColumnComponentName = useCallback(
    (name, componentName, schema) => {
      if (componentName) {
        return componentName;
      }

      let value = name;

      if (schema.type === 'choice' && schema.options.source === 'organization') {
        value = kpi_variables[schema.options.by]?.name || value;
      }
      return value;
    },
    [kpi_variables]
  );

  const columnTableData = useMemo(
    () => {
      if (values.innerSchema && values.innerSchema.type !== 'tuple') {
        return [{
          ...values.innerSchema,
          name: columnDimension.by,
          componentName: getColumnComponentName(columnDimension.by, columnDimension.byName, values.innerSchema),
        }];
      }
      return (values.innerSchema?.components || []).map(component => ({
        ...component,
        componentName: getColumnComponentName(
          component.name, component.componentName, component
        ),
      }));
    },
    [
      values,
      columnDimension,
      getColumnComponentName,
    ]
  );

  const handleAddNewChoiceOption = useCallback(
    () => {
      if (!newChoiceOption) {
        return;
      }

      setCurrentColumn({
        ...currentColumn,
        options: {
          ...currentColumn.options,
          standardItems: [
            ...currentColumn.options.standardItems,
            {name: newChoiceOption}
          ],
        },
      });
      setNewChoiceOption();
    },
    [currentColumn, newChoiceOption]
  );

  const handleRemoveNewChoiceOption = useCallback(
    (name) => {
      setCurrentColumn({
        ...currentColumn,
        options: {
          ...currentColumn.options,
          standardItems: currentColumn.options.standardItems.filter(
            item => item.name !== name
          ),
        },
      });
    },
    [currentColumn]
  );

  const handleSelectCustomMethod = useCallback(
    e => {
      setAffectsKpiValue([...affectsKpiValue, 'column_select_custom_method']);
      setSelectedMethod(e.target.checked ? 'custom' : null);
      onChange('dimensions')([
        ...(values.dimensions || []).filter(dimension => dimension.presentation !== 'column'),
      ]);
      onChange('innerSchema')();
    },
    [
      onChange,
      values,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleSelectVariableMethod = useCallback(
    e => {
      setAffectsKpiValue([...affectsKpiValue, 'column_select_variable_method']);
      setSelectedMethod(e.target.checked ? 'variable' : null);
      onChange('dimensions')(
        (values.dimensions || []).filter(dimension => dimension.presentation !== 'column')
      );
      onChange('innerSchema')();
    },
    [
      onChange,
      values,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleSelectExistingVariable = useCallback(
    e => {
      setAffectsKpiValue([...affectsKpiValue, 'column_select_variable_method_existing']);
      onChange('dimensions')(
        (values.dimensions || []).filter(dimension => dimension.presentation !== 'column')
      );
      setVariableMethod(e.target.checked ? 'existing_variable' : null);
      onChange('innerSchema')();
    },
    [
      onChange,
      values,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleSelectNewVariable = useCallback(
    e => {
      setAffectsKpiValue([...affectsKpiValue, 'column_select_variable_method_new']);
      onChange('dimensions')(
        (values.dimensions || []).filter(dimension => dimension.presentation !== 'column')
      );
      setVariableMethod(e.target.checked ? 'new_variable' : null);
      onChange('innerSchema')();
    },
    [
      onChange,
      values,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleChangeNewVariableName = useCallback(
    e => {
      if (columnDimension) {
        onChange('dimensions')([
          ...(values.dimensions || []).filter(dimension => dimension.presentation !== 'column'),
          updateDimensionName(columnDimension, e.target.value),
        ]);
      } else {
        onChange('dimensions')([
          ...(values.dimensions || []).filter(dimension => dimension.presentation !== 'column'),
          updateDimensionName({
            calculations: [],
            presentation: "column",
            source: "standard",
            standardItems: [],
          }, e.target.value),
        ]);
      }
    },
    [columnDimension, onChange, values]
  );
  const handleAddNewVariableValue = useCallback(
    () => {
      if (!newVariableValue) {
        return;
      }

      if (columnDimension) {
        onChange('dimensions')([
          ...(values.dimensions || []).filter(dimension => dimension.presentation !== 'column'),
          {
            ...columnDimension,
            standardItems: [
              ...columnDimension.standardItems,
              {name: newVariableValue},
            ]
          },
        ]);
      } else {
        onChange('dimensions')([
          ...(values.dimensions || []).filter(dimension => dimension.presentation !== 'column'),
          {
            calculations: [],
            presentation: "column",
            source: "standard",
            standardItems: [{name: newVariableValue}],
          }
        ]);
      }
      setNewVariableValue();
    },
    [columnDimension, onChange, values, newVariableValue]
  );
  const handleRemoveNewVariableValue = useCallback(
    (name) => {
      onChange('dimensions')([
        ...(values.dimensions || []).filter(dimension => dimension.presentation !== 'column'),
        {
          ...columnDimension,
          standardItems: columnDimension.standardItems.filter(item => item.name !== name),
        },
      ]);
    },
    [columnDimension, onChange, values]
  );

  const handleSelectVariableOption = useCallback(
    (value) => {
      setAffectsKpiValue([...affectsKpiValue, 'column_select_variable_method_existing_option']);
      onChange('dimensions')([
        ...(values.dimensions || []).filter(dimension => dimension.presentation !== 'column'),
        {
          by: value,
          calculations: [],
          presentation: "column",
          source: "organization",
          standardItems: [],
        },
      ]);
    },
    [
      onChange,
      values,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleSelectColumnVariable = useCallback(
    (value) => {
      setAffectsKpiValue([...affectsKpiValue, 'column_select_type_from_variable']);

      setCurrentColumn({
        ...currentColumn,
        name: value,
        options: {
          ...currentColumn.options,
          by: value
        },
      });
      
    },
    [
      currentColumn,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );
  
  const handleSelectVariableColumnType = useCallback(
    type => {
      setAffectsKpiValue([...affectsKpiValue, 'column_select_variable_column_type']);
      onChange('innerSchema')(getSchemaForColumnType(type))
    },
    [
      getSchemaForColumnType,
      onChange,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleAddNewVariableChoiceOption = useCallback(
    () => {
      if (!newChoiceOption) {
        return;
      }

      onChange('innerSchema')({
        ...values.innerSchema,
        options: {
          ...values.innerSchema.options,
          standardItems: [
            ...values.innerSchema.options.standardItems,
            {name: newChoiceOption}
          ],
        },
      });
      setNewChoiceOption();
    },
    [newChoiceOption, onChange, values]
  );
  const handleRemoveNewVariableChoiceOption = useCallback(
    (name) => {
      setAffectsKpiValue([...affectsKpiValue, 'column_remove_new_variable_choice_option']);
      onChange('innerSchema')({
        ...values.innerSchema,
        options: {
          ...values.innerSchema.options,
          standardItems: values.innerSchema.options.standardItems.filter(
            item => item.name !== name
          ),
        },
      });
    },
    [
      onChange,
      values,
      affectsKpiValue,
      setAffectsKpiValue,
    ]
  );

  const handleChangeVariableColumnDecimals = useCallback(
    (decimals) => {
      let finalValue = decimals;
      if (decimals < schemaProps.min) {
        finalValue = schemaProps.min;
      } else if (decimals > schemaProps.max) {
        finalValue = schemaProps.max;
      }
      onChange('innerSchema')({
        ...values.innerSchema,
        [DECIMALS_FIELD]: finalValue,
      });
    },
    [
      values,
      onChange,
      schemaProps.max,
      schemaProps.min,
    ]
  );

  const currentColumnHasErrors = Object.keys(currentColumnErrors).length > 0

  return (
    <div className="CreateCustomKpi-table-columns ModalEditKPI-table-columns">
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <CustomCheckbox
            checked={selectedMethod === 'variable'}
            onChange={handleSelectVariableMethod}
          >
            {t.createcustomkpi_table_tab_columns_method_variable}
          </CustomCheckbox>
        </Col>
      </Row>
      {selectedMethod === 'variable' &&
        <Row
          className="CreateCustomKpi-table-columns-form ModalEditKPI-table-columns-form"
          gutter={[15, 15]}
        >
          <Col span={24}>
            <CustomCheckbox
              checked={variableMethod === 'existing_variable'}
              onChange={handleSelectExistingVariable}
            >
              {t.createcustomkpi_table_tab_rows_fixed_rows_existing_variable}
            </CustomCheckbox>
          </Col>
          {variableMethod === 'existing_variable' &&
            <>
              <Col span={24}>
                <CustomSelect
                  options={tableVariableOptions}
                  selected={columnDimension?.by}
                  onSelect={handleSelectVariableOption}
                />
              </Col>
              {columnDimension?.by && kpi_variables[columnDimension.by] &&
                <Col span={24}>
                  {t.createcustomkpi_table_values}: {
                    Object.values(kpi_variables[columnDimension.by].options || {})
                    .join(', ')
                  }
                </Col>
              }
              {columnDimension?.source &&
                <Col span={24}>
                  <Form.Item
                    label={ t.createcustomkpi_table_tab_columns_type }
                    hasFeedback
                    colon={false}
                    required
                  >
                    <CustomSelect
                      options={columnTypeOptions}
                      selected={getColumnTypeForSchema(values.innerSchema)}
                      onSelect={handleSelectVariableColumnType}
                    />
                  </Form.Item>
                </Col>
              }
              {getColumnTypeForSchema(values.innerSchema) === 'number' &&
                <>
                  <Col span={24}>
                    <Form.Item
                      label={ t.createcustomkpi_table_tab_columns_unit }
                      hasFeedback
                      colon={false}
                      required
                    >
                      <TreeSelect {...getTreeSelectProps('variable')} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={ t.createcustomkpi_default_decimals }
                      hasFeedback
                      colon={false}
                    >
                      <CustomInput.Number
                        value={values?.innerSchema?.decimalPoints || schemaProps.min}
                        onChange={handleChangeVariableColumnDecimals}
                        {...schemaProps}
                        className="KpiDetail__settings-quantitative-input-medium"
                      />
                      <div>{`${t.createcustomkpi_example}: ${parseFloat(`1e-${values?.innerSchema?.decimalPoints || schemaProps.min}`)}`}</div>
                    </Form.Item>
                  </Col>
                </>
              }
              {getColumnTypeForSchema(values.innerSchema) === 'choice' &&
                <Col span={24}>
                  <Form.Item
                    label={ t.createcustomkpi_table_tab_columns_values }
                    hasFeedback
                    colon={false}
                    required
                  >
                    <Space direction="vertical">
                      <Space>
                        <CustomInput
                          value={newChoiceOption}
                          onChange={e => setNewChoiceOption(e.target.value)}
                        />
                        <PlusCircleOutlined
                          onClick={handleAddNewVariableChoiceOption}
                        />
                      </Space>
                      <Space direction="vertical">
                        {values.innerSchema.options.standardItems.map(item => {
                          return (
                            <Space key={item.name}>
                              <span>{item.name}</span>
                              <MinusCircleOutlined
                                onClick={() => handleRemoveNewVariableChoiceOption(item.name)}
                              />
                            </Space>
                          )
                        })}
                      </Space>
                    </Space>
                  </Form.Item>
                </Col>
              }
            </>
          }
          <Col span={24}>
            <CustomCheckbox
              checked={variableMethod === 'new_variable'}
              onChange={handleSelectNewVariable}
            >
              {t.createcustomkpi_table_tab_rows_fixed_rows_new_variable}
            </CustomCheckbox>
          </Col>
          { variableMethod === 'new_variable' &&
            <>
              <Col span={24}>
                <Form.Item
                  label={ t.createcustomkpi_table_tab_rows_fixed_rows_new_variable_name }
                  hasFeedback
                  colon={false}
                  required
                >
                  <CustomInput
                    value={columnDimension?.byName}
                    onChange={handleChangeNewVariableName}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={ t.createcustomkpi_table_tab_rows_fixed_rows_new_variable_values }
                  hasFeedback
                  colon={false}
                  required
                >
                  <Space direction="vertical">
                    <Space>
                      <CustomInput
                        value={newVariableValue}
                        onChange={e => setNewVariableValue(e.target.value)}
                      />
                      <PlusCircleOutlined
                        onClick={handleAddNewVariableValue}
                      />
                    </Space>
                    <Space direction="vertical">
                      {columnDimension?.standardItems.map(item => {
                        return (
                          <Space key={item.name}>
                            <span>{item.name}</span>
                            <MinusCircleOutlined
                              onClick={() => handleRemoveNewVariableValue(item.name)}
                            />
                          </Space>
                        )
                      })}
                    </Space>
                  </Space>
                </Form.Item>
              </Col>
              {columnDimension?.source === "standard" &&
                <Col span={24}>
                  <Form.Item
                    label={ t.createcustomkpi_table_tab_columns_type }
                    hasFeedback
                    colon={false}
                    required
                  >
                    <CustomSelect
                      options={columnTypeOptions}
                      selected={getColumnTypeForSchema(values.innerSchema)}
                      onSelect={handleSelectVariableColumnType}
                    />
                  </Form.Item>
                </Col>
              }
              {getColumnTypeForSchema(values.innerSchema) === 'number' &&
                <>
                  <Col span={24}>
                    <Form.Item
                      label={ t.createcustomkpi_table_tab_columns_unit }
                      hasFeedback
                      colon={false}
                      required
                    >
                      <TreeSelect {...getTreeSelectProps('variable')} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={ t.createcustomkpi_default_decimals }
                      hasFeedback
                      colon={false}
                    >
                      <CustomInput.Number
                        value={values?.innerSchema?.decimalPoints || schemaProps.min}
                        onChange={handleChangeVariableColumnDecimals}
                        {...schemaProps}
                        className="KpiDetail__settings-quantitative-input-medium"
                      />
                      <div>{`${t.createcustomkpi_example}: ${parseFloat(`1e-${values?.innerSchema?.decimalPoints || schemaProps.min}`)}`}</div>
                    </Form.Item>
                  </Col>
                </>
              }
              {getColumnTypeForSchema(values.innerSchema) === 'choice' &&
                <Col span={24}>
                  <Form.Item
                    label={ t.createcustomkpi_table_tab_columns_values }
                    hasFeedback
                    colon={false}
                    required
                  >
                    <Space direction="vertical">
                      <Space>
                        <CustomInput
                          value={newChoiceOption}
                          onChange={e => setNewChoiceOption(e.target.value)}
                        />
                        <PlusCircleOutlined
                          onClick={handleAddNewVariableChoiceOption}
                        />
                      </Space>
                      <Space direction="vertical">
                        {values.innerSchema.options.standardItems.map(item => {
                          return (
                            <Space key={item.name}>
                              <span>{item.name}</span>
                              <MinusCircleOutlined
                                onClick={() => handleRemoveNewVariableChoiceOption(item.name)}
                              />
                            </Space>
                          )
                        })}
                      </Space>
                    </Space>
                  </Form.Item>
                </Col>
              }
            </>
          }
        </Row>
      }
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <CustomCheckbox
            checked={selectedMethod === 'custom'}
            onChange={handleSelectCustomMethod}
          >
            {t.createcustomkpi_table_tab_columns_method_custom}
          </CustomCheckbox>
        </Col>
      </Row>
      {selectedMethod === 'custom' &&
        <Row
          className="CreateCustomKpi-table-columns-form ModalEditKPI-table-columns-form"
          gutter={[15, 15]}
        >
          <Col span={24}>
            <CustomTable
              columns={columnTableColumns}
              dataSource={columnTableData}
              rowKey="componentName"
              pagination={false}
            />
          </Col>
          {!currentColumn &&
            <Col span={24}>
              <CustomButton
                type="primary"
                onClick={handleAddNewColumn}
              >
                {t.createcustomkpi_table_tab_columns_add}
              </CustomButton>
            </Col>
          }
          {currentColumn &&
            <>
              <Col span={24}>
                <Form.Item
                  label={ t.createcustomkpi_table_tab_columns_type }
                  hasFeedback
                  colon={false}
                  required
                >
                  <CustomSelect
                    options={columnTypeOptions}
                    selected={getColumnTypeForSchema(currentColumn)}
                    onSelect={handleSelectColumnType}
                  />
                </Form.Item>
              </Col>
              {getColumnTypeForSchema(currentColumn) === 'number' &&
                <>
                  <Col span={24}>
                    <Form.Item
                      label={ t.createcustomkpi_table_tab_columns_unit }
                      hasFeedback
                      colon={false}
                      required
                    >
                      <TreeSelect {...getTreeSelectProps('custom')} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={ t.createcustomkpi_default_decimals }
                      hasFeedback
                      colon={false}
                    >
                      <CustomInput.Number
                        value={decimalPoints}
                        onChange={handleChangeDecimals}
                        {...schemaProps}
                        className="KpiDetail__settings-quantitative-input-medium"
                      />
                      <div>{`${t.createcustomkpi_example}: ${parseFloat(`1e-${decimalPoints}`)}`}</div>
                    </Form.Item>
                  </Col>
                </>
              }
              {getColumnTypeForSchema(currentColumn) === 'number_calculated' &&
                <>
                  <Col span={24}>
                    <Form.Item
                      label={ t.createcustomkpi_default_formula }
                      hasFeedback
                      colon={false}
                    >
                      <CustomInput
                        value={formula}
                        onChange={handleChangeFormula}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={ t.createcustomkpi_table_tab_columns_unit }
                      hasFeedback
                      colon={false}
                      required
                    >
                      <TreeSelect {...getTreeSelectProps('custom', false)} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={ t.createcustomkpi_default_decimals }
                      hasFeedback
                      colon={false}
                    >
                      <CustomInput.Number
                        value={decimalPoints}
                        onChange={handleChangeDecimals}
                        {...schemaProps}
                        className="KpiDetail__settings-quantitative-input-medium"
                      />
                      <div>{`${t.createcustomkpi_example}: ${parseFloat(`1e-${decimalPoints}`)}`}</div>
                    </Form.Item>
                  </Col>
                </>
              }
              {getColumnTypeForSchema(currentColumn) === 'choice' &&
                <Col span={24}>
                  <Form.Item
                    label={ t.createcustomkpi_table_tab_columns_values }
                    hasFeedback
                    colon={false}
                    required
                  >
                    <Space direction="vertical">
                      <Space>
                        <CustomInput
                          value={newChoiceOption}
                          onChange={e => setNewChoiceOption(e.target.value)}
                        />
                        <PlusCircleOutlined
                          onClick={handleAddNewChoiceOption}
                        />
                      </Space>
                      <Space direction="vertical">
                        {currentColumn.options.standardItems.map(item => {
                          return (
                            <Space key={item.name}>
                              <span>{item.name}</span>
                              <MinusCircleOutlined
                                onClick={() => handleRemoveNewChoiceOption(item.name)}
                              />
                            </Space>
                          )
                        })}
                      </Space>
                    </Space>
                  </Form.Item>
                </Col>
              }
              {
                getColumnTypeForSchema(currentColumn) !== 'choice_variable' && 

                <Col span={24}>
                  <Form.Item
                    label={ t.createcustomkpi_table_tab_columns_name }
                    hasFeedback
                    colon={false}
                    required
                  >
                    <CustomInput
                      value={currentColumn.componentName}
                      onChange={handleChangeColumnName}
                    />
                  </Form.Item>
                </Col>
              }
              {
                getColumnTypeForSchema(currentColumn) === 'choice_variable' && 
                <>
                  <Col span={24}>
                    <Form.Item
                      label={ t.createcustomkpi_table_tab_columns_actions_select_variable }
                      hasFeedback
                      colon={false}
                      required
                    >
                      <CustomSelect
                        options={tableVariableOptions}
                        selected={currentColumn.options?.by}
                        onSelect={handleSelectColumnVariable}
                      />
                      {currentColumn.options?.by && kpi_variables[currentColumn.options?.by] &&
                        <div>
                          {t.createcustomkpi_table_values}: {
                            Object.values(kpi_variables[currentColumn.options?.by].options || {})
                            .join(', ')
                          }
                        </div>
                      }
                    </Form.Item>
                  </Col>
                </>
              }
              {
                !currentColumnHasErrors
                ? null
                : (
                  <>
                    <Col span={24}>
                      <ul
                        className="CreateEditCustomKPI-form-errors"
                      >
                        {
                          Object.values(currentColumnErrors).map(error => (
                            <li key={error}>{error}</li>
                          ))
                        }
                      </ul>
                    </Col>
                  </>
                )
              }
              <Col span={24}>
                <Space>
                  <CustomButton
                    type="primary"
                    onClick={handleOnSaveColumn}
                    disabled={currentColumnHasErrors}
                  >
                    {t.createcustomkpi_table_tab_columns_save}
                  </CustomButton>
                  <CustomButton
                    onClick={handleOnCancelSaveColumn}
                  >
                    {t.createcustomkpi_table_tab_columns_cancel}
                  </CustomButton>
                </Space>
              </Col>
            </>
          }
        </Row>
      }
    </div>
  )
};

export default injectIntl(Columns);
