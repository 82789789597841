import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
} from 'react-redux';

import AplanetIcon from 'components/AplanetIcon';
import FileItem from 'components/FileItem';
import UploadButton from 'containers/UploadButton';
import Badge from './Badge';
import DeleteAttachmentLink from './DeleteAttachmentLink';

import useOrganizations from 'utils/useOrganizations';

import {
  Row,
  Col,
  Popover,
} from 'antd';

import {
  getAttachFileUrl,
} from 'actions/api';

import {
  uploadKpiAttachmentSuccess,
} from 'actions/attachments';


const PopoverAttachments = ({
  intl,
  kpi,
  canEdit = true,
}) => {
  return (
    <Row className="BulkManage_Attachments__popover">
      {kpi.attachments.map(attachment => (
        <Col>
          <FileItem {...attachment}>
            <DeleteAttachmentLink
              intl={intl}
              kpi={kpi}
              canEdit={canEdit}
              attachment={attachment}
            />
          </FileItem>
        </Col>
      ))}
    </Row>
  );
}


const Attachments = ({
  intl,
  kpi,
  canEdit = true,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const {
    organization,
  } = useOrganizations();
  const dispatch = useDispatch();

  const onSuccess = useCallback((response) => {
    try {
      const attachment = JSON.parse(response);
      dispatch(
        uploadKpiAttachmentSuccess(
          kpi.organization_slug,
          kpi.slug,
          kpi.period,
          attachment,
        )
      )
    } catch(err) {
      console.error(err);
    }
  }, [
    dispatch,
    kpi,
  ]);

  const uploadProps = useMemo(() => ({
    intl: intl,
    actionUrl: getAttachFileUrl(
      organization.slug,
      kpi.organization_slug,
      kpi.slug,
      kpi.period,
    ),
    onSuccess: onSuccess,
    component: (
      <AplanetIcon
        className="BulkManage_Attachments__icon_button"
        name="plus"
        size="10px"
      />
    )
  }), [
    kpi,
    intl,
    onSuccess,
    organization,
  ]);

  return (
    <Row type="flex"
      justify="space-between"
      align="middle"
      gutter={5}
      className={`BulkManage_Attachments ${canEdit ? '' : 'DataManagement__bulk-manage__data__column_readonly'}`}
    >
      <Col>
        <Row type="flex" align="middle">
          <Col className="BulkManage_Attachments__upload_button_container">
            <UploadButton {...uploadProps} disabled={!canEdit}/>
          </Col>
          { !!kpi.attachments.length
            ? <AplanetIcon
                className="BulkManage_Attachments__icon clickable"
                size="15px"
                name="file"
                onClick={() => setPopoverOpen(true)}
              />
            : <Col
                className="BulkManage_Attachments__placeholder"
              >
                <AplanetIcon name="paperclip" size="15px" />
                <span>{intl.formatMessage({id: 'bulk_manage_table_attachments_placeholder'})}</span>
              </Col>
          }
          { kpi.attachments.length > 1 &&
          <Col className="BulkManage_Attachments__badge clickable">
            <Badge
              background="#112C70"
              color="#FFFF"
              onClick={() => setPopoverOpen(true)}
            >
              +{kpi.attachments.length - 1}
            </Badge>
          </Col>
          }
        </Row>
      </Col>
      <Col className="BulkManage_Attachments__expand">
        <Popover
          placement="bottom"
          trigger="click"
          title={(
            <Row type="flex" align="middle" justify="space-between">
              <Row type="flex" align="middle">
                <span>{intl.formatMessage({id: 'bulk_manage_table_attachments'})}</span>&nbsp;
                <Badge>{kpi.attachments.length}</Badge>
              </Row>
              <AplanetIcon className="clickable" name="close" onClick={() => setPopoverOpen(false)} />
            </Row>
          )}
          content={
            <PopoverAttachments
              intl={intl}
              kpi={kpi}
              canEdit={canEdit}
            />
          }
          open={popoverOpen}
          onOpenChange={setPopoverOpen}
        >
          {!!kpi.attachments.length &&
          <AplanetIcon
            className="BulkManage_Attachments__expand_icon"
            name="arrow-up-right-and-arrow-down-left-from-center"
            size="15px"
          />
          }
        </Popover>
      </Col>
    </Row>
  )
}

export default Attachments;
