import React, { useCallback, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import useOrganizations from 'utils/useOrganizations';

import MainLayout from 'components/MainLayout';
import { Loading } from 'tsComponents/emptyStates/Loading';
import EqualityPlanComponent from 'components/EqualityPlan';
import useAuthorizedRequest from 'utils/useAuthorizedRequest';

// Equality plan steps
import OrganizationCommitment from 'components/EqualityPlanOrganizationCommitment';
import EqualityPlanCommittee from 'components/EqualityPlanCommittee';
import EqualityPlanDataCollection from 'components/EqualityPlanDataCollection';
import EqualityPlanDiagnosis from 'components/EqualityPlanDiagnosis';
import EqualityPlanObjectives from 'components/EqualityPlanObjectives';
import EqualityPlanActions from 'components/EqualityPlanActions';
import EqualityPlanDocument from 'components/EqualityPlanDocument';
import EqualityPlanObjectiveTracking from 'components/EqualityPlanObjectiveTracking';
import EqualityPlanCreation from 'components/EqualityPlanCreation';
import EqualityPlanDocumentManagerOtherDocuments from 'components/EqualityPlanDocumentManager/Other';
import EqualityPlanCalendar from 'components/EqualityPlanCalendar';
import EqualityPlanEvaluationOfResults from 'components/EqualityPlanEvaluationOfResults';
import EqualityPlanDiagnosisSalaryAudit from 'components/EqualityPlanDiagnosisSalaryAudit';
import EqualityPlanSalaryAuditDocument from 'components/EqualityPlanSalaryAuditDocument';

import EqualityPlanFactorDefinition from 'components/EqualityPlanJobEvaluation/FactorDefinition';
import EqualityPlanFactorWeight from 'components/EqualityPlanJobEvaluation/FactorWeight';
import EqualityPlanDetermination from 'components/EqualityPlanJobEvaluation/Determination';
import EqualityPlanJobAssessment from 'components/EqualityPlanJobEvaluation/JobAssessment';
import EqualityPlanWageData from 'components/EqualityPlanJobEvaluation/WageData';
import EqualityPlanSalaryData from 'components/EqualityPlanJobEvaluation/SalaryData';
import EqualityPlanFactorPointGraphs from 'components/EqualityPlanJobEvaluation/FactorPointGraphs';
import EqualityPlanPointsByPosition from 'components/EqualityPlanJobEvaluation/PointsByPosition';
import EqualityPlanGenderAnalysisByPosition from 'components/EqualityPlanJobEvaluation/GenderAnalysisByPosition';
import EqualityPlanAnalysisByGenderForPosition from 'components/EqualityPlanJobEvaluation/AnalysisByGenderForPosition';
import EqualityPlanPointsSalarySuggestion from 'components/EqualityPlanJobEvaluation/PointsSalarySuggestion';
import EqualityPlanPointsConsistencyCheck from 'components/EqualityPlanJobEvaluation/ConsistencyCheck';
import EqualityPlanPointsSalaryComparison from 'components/EqualityPlanJobEvaluation/SalaryComparison';

import {
  requestEqualityPlan,
  requestEqualityPlanDocuments,
  downloadEqualityPlanDocument,
  updateEqualityPlan,
  requestEqualityPlanObjectives,
  createEqualityPlanObjective,
  updateEqualityPlanObjective,
  requestEqualityPlanActions,
  createEqualityPlanAction,
  updateEqualityPlanAction,
  updateEqualityPlanActionIndicator,
  createEqualityPlanMeasure,
  updateEqualityPlanMeasure,
  deleteEqualityPlanMeasure,
} from '../../actions/api';

import './style.less';

const MIME_TYPES = {
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  odt: 'application/vnd.oasis.opendocument.text',
  pdf: 'application/pdf',
};
const planStages = {
  'org_commitment': 0,
  'committee': 1,
  'data_collection': 2,
  'diagnosis': 3,
  'objectives': 4,
  'actions': 5,
  'plan_creation': 6,
};


const EqualityPlan = ({
  match,
  userProfile,
  history,
  plan,
  fetchingPlan,
  documents,
  fetchingDocuments,
  documentTemplate,
  requestEqualityPlan,
  requestEqualityPlanDocuments,
  downloadEqualityPlanDocument,
  updateEqualityPlan,
  requestEqualityPlanObjectives,
  createEqualityPlanObjective,
  updateEqualityPlanObjective,
  objectives,
  fetchingObjectives,
  requestEqualityPlanActions,
  createEqualityPlanAction,
  updateEqualityPlanAction,
  actions,
  fetchingActions,
  updateEqualityPlanActionIndicator,
  measures,
  createEqualityPlanMeasure,
  updateEqualityPlanMeasure,
  deleteEqualityPlanMeasure,
}) => {
  const { organization, suborganization } = useOrganizations();
  const { id } = match.params;
  const [requestedDownload, setRequestedDownload] = useState(false);

  const {
    slug: mainOrgSlug,
  } = organization;
  const {
    slug: subOrgSlug,
  } = suborganization;

  useEffect(() => {
    requestEqualityPlan(mainOrgSlug, subOrgSlug, id);
  }, [
    mainOrgSlug, subOrgSlug, requestEqualityPlan, id
  ]);

  const handleDownload = useCallback((content) => {
    let anchor = linkRef.current;

    if(!anchor) {
      console.log('ERROR: cannot handle download without an anchor');
      return;
    }

    const windowUrl = window.URL || window.webkitURL;
    const blob = content instanceof Blob
      ? content
      : new Blob(
        [content],
        { type: MIME_TYPES[documentTemplate.url?.split('.').pop()] }
      );

    const url = windowUrl.createObjectURL(blob);
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', documentTemplate.url?.split('/').pop());
    anchor.click();
    windowUrl.revokeObjectURL(url);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTemplate]);

  const handleError = useCallback((error) => {
    console.log('Error fetching', error);
  }, []);

  const {
    linkRef,
    onClick: downloadDocument,
  } = useAuthorizedRequest({
    url: documentTemplate.url,
    onSuccess: handleDownload,
    onError: handleError,
  });

  useEffect(
    () => {
      if (
        !documentTemplate.processing
        && documentTemplate.done
        && requestedDownload
      ) {
        downloadDocument();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentTemplate, requestedDownload]
  );

  const onOpenEqualityPlanStep = useCallback(
    (route) => {
      return history.push(`/equality-plan/${plan.id}/${route}`);
    },
    [history, plan]
  );

  const onUpdatePlanProgress = useCallback(
    (step) => {
      if (!plan.progress.includes(step)) {
        updateEqualityPlan(
          mainOrgSlug, subOrgSlug, plan.id,
          { progress: [...plan.progress, step] }
        );
      }
    },
    [mainOrgSlug, subOrgSlug, plan, updateEqualityPlan]
  );

  const onFetchDocuments = useCallback(
    (step, type) => {
      return requestEqualityPlanDocuments(
        mainOrgSlug, subOrgSlug, plan.id, step, type
      );
    },
    [mainOrgSlug, subOrgSlug, plan, requestEqualityPlanDocuments]
  );

  const onDownloadDocument = useCallback(
    (type, format) => {
      setRequestedDownload(true);
      return downloadEqualityPlanDocument(mainOrgSlug, subOrgSlug, plan.id, type, format);
    },
    [mainOrgSlug, subOrgSlug, plan, downloadEqualityPlanDocument, setRequestedDownload]
  );

  const onUpdateEqualityPlan = useCallback(
    (data) => {
      updateEqualityPlan(mainOrgSlug, subOrgSlug, plan.id, data);
    },
    [updateEqualityPlan, mainOrgSlug, subOrgSlug, plan]
  );

  const onFetchObjectives = useCallback(
    (type) => {
      return requestEqualityPlanObjectives(mainOrgSlug, subOrgSlug, plan.id);
    },
    [mainOrgSlug, subOrgSlug, plan, requestEqualityPlanObjectives]
  );

  const onCreateObjective = useCallback(
    (data) => {
      createEqualityPlanObjective(mainOrgSlug, subOrgSlug, plan.id, data);
    },
    [createEqualityPlanObjective, mainOrgSlug, subOrgSlug, plan]
  );

  const onUpdateObjective = useCallback(
    (id, data) => {
      updateEqualityPlanObjective(mainOrgSlug, subOrgSlug, plan.id, id, data);
    },
    [updateEqualityPlanObjective, mainOrgSlug, subOrgSlug, plan]
  );

  const onFetchActions = useCallback(
    (type) => {
      return requestEqualityPlanActions(mainOrgSlug, subOrgSlug, plan.id, type);
    },
    [mainOrgSlug, subOrgSlug, plan, requestEqualityPlanActions]
  );

  const onCreateAction = useCallback(
    (data) => {
      createEqualityPlanAction(mainOrgSlug, subOrgSlug, plan.id, data);
    },
    [createEqualityPlanAction, mainOrgSlug, subOrgSlug, plan]
  );

  const onUpdateAction = useCallback(
    (id, data) => {
      updateEqualityPlanAction(mainOrgSlug, subOrgSlug, plan.id, id, data);
    },
    [updateEqualityPlanAction, mainOrgSlug, subOrgSlug, plan]
  );

  const onUpdateActionIndicator = useCallback(
    (actionID, id, data) => {
      updateEqualityPlanActionIndicator(mainOrgSlug, subOrgSlug, plan.id, actionID, id, data);
    },
    [updateEqualityPlanActionIndicator, mainOrgSlug, subOrgSlug, plan]
  );

  const onCreateMeasure = useCallback(
    (data) => {
      createEqualityPlanMeasure(mainOrgSlug, subOrgSlug, plan.id, data);
    },
    [createEqualityPlanMeasure, mainOrgSlug, subOrgSlug, plan]
  );

  const onUpdateMeasure = useCallback(
    (id, data) => {
      updateEqualityPlanMeasure(mainOrgSlug, subOrgSlug, plan.id, id, data);
    },
    [updateEqualityPlanMeasure, mainOrgSlug, subOrgSlug, plan]
  );

  const onDeleteMeasure = useCallback(
    (id) => {
      deleteEqualityPlanMeasure(mainOrgSlug, subOrgSlug, plan.id, id);
    },
    [deleteEqualityPlanMeasure, mainOrgSlug, subOrgSlug, plan]
  );

  return (
    <>
      <MainLayout>
        { fetchingPlan 
          ? <Loading />
          : (
          <Switch>
            <Route path={`/equality-plan/${plan.id}/organization-commitment`}>
              <OrganizationCommitment
                plan={plan}
                documents={documents}
                fetchingDocuments={fetchingDocuments}
                onFetchDocuments={onFetchDocuments}
                onDownloadDocument={onDownloadDocument}
                downloadingDocument={documentTemplate.processing}
                updatePlanProgress={onUpdatePlanProgress} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/committee`}>
              <EqualityPlanCommittee
                plan={plan}
                updatePlan={onUpdateEqualityPlan}
                documents={documents}
                fetchingDocuments={fetchingDocuments}
                onFetchDocuments={onFetchDocuments}
                onDownloadDocument={onDownloadDocument}
                downloadingDocument={documentTemplate.processing}
                updatePlanProgress={onUpdatePlanProgress} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/committee-followup`}>
              <EqualityPlanCommittee
                stage="committee_followup"
                plan={plan}
                updatePlan={onUpdateEqualityPlan}
                documents={documents}
                fetchingDocuments={fetchingDocuments}
                onFetchDocuments={onFetchDocuments}
                onDownloadDocument={onDownloadDocument}
                downloadingDocument={documentTemplate.processing}
                updatePlanProgress={onUpdatePlanProgress} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/data-collection`}>
              <EqualityPlanDataCollection
                plan={plan}
                documents={documents}
                fetchingDocuments={fetchingDocuments}
                onFetchDocuments={onFetchDocuments}
                onDownloadDocument={onDownloadDocument}
                updatePlanProgress={onUpdatePlanProgress}
                reportingStartDate={suborganization?.product_config?.atlas?.reporting_start_date}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/diagnosis`}>
              <EqualityPlanDiagnosis
                plan={plan}
                updatePlan={onUpdateEqualityPlan}
                documents={documents}
                fetchingDocuments={fetchingDocuments}
                onFetchDocuments={onFetchDocuments}
                onDownloadDocument={onDownloadDocument}
                downloadingDocument={documentTemplate.processing}
                updatePlanProgress={onUpdatePlanProgress}
                measures={measures}
                createMeasure={onCreateMeasure}
                updateMeasure={onUpdateMeasure}
                deleteMeasure={onDeleteMeasure}
                actions={actions}
                fetchActions={onFetchActions}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/objectives`}>
              <EqualityPlanObjectives
                plan={plan}
                updatePlan={onUpdateEqualityPlan}
                objectives={objectives}
                fetchingObjectives={fetchingObjectives}
                fetchObjectives={onFetchObjectives}
                createObjective={onCreateObjective}
                updateObjective={onUpdateObjective}
                updatePlanProgress={onUpdatePlanProgress}
                actions={actions}
                fetchingActions={fetchingActions}
                fetchActions={onFetchActions}
                measures={measures}
                createMeasure={onCreateMeasure} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/actions`}>
              <EqualityPlanActions
                plan={plan}
                updatePlan={onUpdateEqualityPlan}
                objectives={objectives}
                fetchingObjectives={fetchingObjectives}
                fetchObjectives={onFetchObjectives}
                actions={actions}
                fetchingActions={fetchingActions}
                fetchActions={onFetchActions}
                createAction={onCreateAction}
                updateAction={onUpdateAction}
                updatePlanProgress={onUpdatePlanProgress}
                measures={measures}
                createMeasure={onCreateMeasure} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/actions-followup`}>
              <EqualityPlanActions
                stage="actions_followup"
                plan={plan}
                updatePlan={onUpdateEqualityPlan}
                objectives={objectives}
                fetchingObjectives={fetchingObjectives}
                fetchObjectives={onFetchObjectives}
                actions={actions}
                fetchingActions={fetchingActions}
                fetchActions={onFetchActions}
                createAction={onCreateAction}
                updateAction={onUpdateAction}
                updatePlanProgress={onUpdatePlanProgress} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/plan-creation`}>
              <EqualityPlanCreation
                plan={plan}
                updatePlan={onUpdateEqualityPlan} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/plan-document`}>
              <EqualityPlanDocument
                plan={plan}
                documents={documents}
                fetchingDocuments={fetchingDocuments}
                onFetchDocuments={onFetchDocuments}
                onDownloadDocument={onDownloadDocument}
                downloadingDocument={documentTemplate.processing}
                updatePlanProgress={onUpdatePlanProgress} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/tracking`}>
              <EqualityPlanObjectiveTracking
                plan={plan}
                objectives={objectives}
                fetchingObjectives={fetchingObjectives}
                fetchObjectives={onFetchObjectives}
                actions={actions}
                fetchingActions={fetchingActions}
                fetchActions={onFetchActions}
                onUpdateActionIndicator={onUpdateActionIndicator}
                updateAction={onUpdateAction}
                updatePlanProgress={onUpdatePlanProgress} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/tracking-followup`}>
              <EqualityPlanObjectiveTracking
                stage='tracking_followup'
                plan={plan}
                objectives={objectives}
                fetchingObjectives={fetchingObjectives}
                fetchObjectives={onFetchObjectives}
                actions={actions}
                fetchingActions={fetchingActions}
                fetchActions={onFetchActions}
                onUpdateActionIndicator={onUpdateActionIndicator}
                updateAction={onUpdateAction}
                updatePlanProgress={onUpdatePlanProgress} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/other-documents`}>
              <EqualityPlanDocumentManagerOtherDocuments
                planId={plan.id}
                stage='other_documents'
                documents={documents}
                onFetchDocuments={onFetchDocuments}
                updatePlanProgress={onUpdatePlanProgress} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/other-documents-followup`}>
              <EqualityPlanDocumentManagerOtherDocuments
                planId={plan.id}
                stage='other_documents_followup'
                documents={documents}
                onFetchDocuments={onFetchDocuments}
                updatePlanProgress={onUpdatePlanProgress} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/calendar-followup`}>
              <EqualityPlanCalendar
                planId={plan.id}
                actions={actions}
                fetchingActions={fetchingActions}
                fetchActions={onFetchActions}
                updatePlanProgress={onUpdatePlanProgress} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/evaluation-of-results`}>
              <EqualityPlanEvaluationOfResults
                plan={plan}
                updatePlan={onUpdateEqualityPlan} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/diagnosis-salary-audit`}>
              <EqualityPlanDiagnosisSalaryAudit
                plan={plan}
                updatePlan={onUpdateEqualityPlan}
                documents={documents}
                onFetchDocuments={onFetchDocuments}
                currentUserRole={userProfile?.role}
                updatePlanProgress={onUpdatePlanProgress}
                reportingStartDate={suborganization?.product_config?.atlas?.reporting_start_date} />
            </Route>
            <Route path={`/equality-plan/${plan.id}/salary-audit-document`}>
              <EqualityPlanSalaryAuditDocument
                plan={plan}
                updatePlan={onUpdateEqualityPlan}
                documents={documents}
                fetchingDocuments={fetchingDocuments}
                onFetchDocuments={onFetchDocuments}
                onDownloadDocument={onDownloadDocument}
                downloadingDocument={documentTemplate.processing}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/factor-definition`}>
              <EqualityPlanFactorDefinition
                plan={plan}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/factor-weight`}>
              <EqualityPlanFactorWeight
                plan={plan}
                updatePlan={onUpdateEqualityPlan}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/job-determination`}>
              <EqualityPlanDetermination
                plan={plan}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/job-assessment`}>
              <EqualityPlanJobAssessment
                plan={plan}
                updatePlan={onUpdateEqualityPlan}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/wages-by-agreements`}>
              <EqualityPlanWageData
                plan={plan}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/salary-data`}>
              <EqualityPlanSalaryData
                plan={plan}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/factor-point-graphs`}>
              <EqualityPlanFactorPointGraphs
                plan={plan}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/points-by-position`}>
              <EqualityPlanPointsByPosition
                plan={plan}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/gender-analysis-by-position`}>
              <EqualityPlanGenderAnalysisByPosition
                plan={plan}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/analysis-by-gender-for-position`}>
              <EqualityPlanAnalysisByGenderForPosition
                plan={plan}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/consistency-check`}>
              <EqualityPlanPointsConsistencyCheck
                plan={plan}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/points-salary-suggestion`}>
              <EqualityPlanPointsSalarySuggestion
                plan={plan}
                updatePlan={onUpdateEqualityPlan}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route path={`/equality-plan/${plan.id}/salary-comparison`}>
              <EqualityPlanPointsSalaryComparison
                plan={plan}
                updatePlanProgress={onUpdatePlanProgress}
              />
            </Route>
            <Route>
              <EqualityPlanComponent
                plan={plan}
                userProfile={userProfile}
                onOpenEqualityPlanStep={onOpenEqualityPlanStep}
                planStages={planStages} />
            </Route>
          </Switch>
        )}
        { /* eslint-disable-next-line */ }
        <a ref={linkRef}></a>
      </MainLayout>
    </>
  )
};

const mapStateToProps = ({
  profile, equality_plan, equality_plan_document, equality_plan_objective,
  equality_plan_action, equality_plan_measure,
}) => ({
  userProfile: profile.data,
  plan: equality_plan.item,
  fetchingPlan: equality_plan.fetching,
  documents: equality_plan_document.data,
  fetchingDocuments: equality_plan_document.fetching,
  documentTemplate: equality_plan_document.template,
  objectives: equality_plan_objective.data,
  fetchingObjectives: equality_plan_objective.fetching,
  actions: equality_plan_action.data,
  fetchingActions: equality_plan_action.fetching,
  measures: equality_plan_measure.data,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      requestEqualityPlan,
      requestEqualityPlanDocuments,
      downloadEqualityPlanDocument,
      updateEqualityPlan,
      requestEqualityPlanObjectives,
      createEqualityPlanObjective,
      updateEqualityPlanObjective,
      requestEqualityPlanActions,
      createEqualityPlanAction,
      updateEqualityPlanAction,
      updateEqualityPlanActionIndicator,
      createEqualityPlanMeasure,
      updateEqualityPlanMeasure,
      deleteEqualityPlanMeasure,
    }
  )(EqualityPlan)
);
