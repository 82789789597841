import React, {
  useCallback,
  forwardRef,
} from 'react';

import useAuthorizedRequest from 'utils/useAuthorizedRequest';

import './style.less';

import {
  Spin,
} from 'antd';

const AuthorizedLink = forwardRef(({
  href,
  children,
  className="",
  style={},
  download=true,
  mimeType='application/octet-stream',
  onClick,
  onSuccess,
}, externalLinkRef) => {
  const handleDownload = useCallback((content) => {
    let anchor = linkRef.current;

    if(!anchor) {
      console.log('ERROR: cannot handle download without an anchor');
      return;
    }

    const windowUrl = window.URL || window.webkitURL;
    const blob = content instanceof Blob
      ? content
      : new Blob(
        [content],
        { type: mimeType }
      );

    const url = windowUrl.createObjectURL(blob);
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', download);
    if(download) {
      anchor.click();
    }
    windowUrl.revokeObjectURL(url);
    if (onSuccess) {
      onSuccess(content);
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    download,
    mimeType,
  ]);

  const handleError = useCallback((error) => {
    console.log('Error fetching', error);
  }, []);

  const {
    linkRef,
    onClick: onClickAuthorizedLink,
    started,
    success,
    error,
  } = useAuthorizedRequest({
    url: href,
    onSuccess: handleDownload,
    onError: handleError,
  });

  const statusClassname = success
    ? 'AuthorizedLink-success'
    : error
      ? 'AuthorizedLink-error'
      : '';

  const handleOnClick = useCallback(
    () => {
      if (onClick) { onClick(); }
      onClickAuthorizedLink()
    },
    [
      onClick,
      onClickAuthorizedLink,
    ]
  );

  return (
    <React.Fragment>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
      <a
        className={`AuthorizedLink-link ${statusClassname} ${className}`}
        style={style}
        download={download}
        onClick={handleOnClick}
        ref={externalLinkRef}
      >
        { children }
        {
          !started ? null
          : (
              <React.Fragment>
                { ' ' }
                <Spin size="small" />
              </React.Fragment>
            )
        }
      </a>
      { /* eslint-disable-next-line */ }
      <a ref={linkRef}></a>
    </React.Fragment>
  );
})

export default AuthorizedLink;
