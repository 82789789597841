import React, {useEffect, useMemo, useState} from 'react';
import {injectIntl} from 'react-intl';
import { useSelector } from 'react-redux';

import Description from 'components/Description';
import CustomButton from 'components/CustomButton';
import ModalDataAnswer from 'components/ModalDataAnswer';
import AnswerFooter from 'components/AnswerFooter';

import useOrganizations from 'utils/useOrganizations';
import {adaptInitialState} from 'utils/useTableState';
import { useFeatureList } from 'components/FeatureSwitch';

import {Divider} from 'antd';
import ShowTable from './ShowTable';
import SummaryComponent from "./SummaryComponent";

const QUALITATIVE_SCHEMA_TYPE= 'qualitative';

// eslint-disable-next-line no-unused-vars
const ScrollTableButton = () => {
  return (
    <section className='KpiDetail__answer-table-more'>
      <img src='/images/assets/chevron-big.svg' alt='' style={{transform: 'rotate(180deg)'}}/>
    </section>
  )
};

const NO_DEPENDEES = [];

const Show = ({
  intl,
  source,
  source_params,
  kpi_slug,
  schema,
  schemaLabels,
  value: initialValue,
  previous_value,
  previous_comment,
  previous_attachments,
  comment,
  onShowLastPeriod,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
  onEdit,
  loading,
  kpi_name,
  org_name,
  period,
  canWrite = true,
  target,
  isTarget,
  onTarget,
  suborganization_data,
  dependees = NO_DEPENDEES,
}) => {
  const t = intl.messages;
  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);

  const {
    suborganization,
    permissions,
  } = useOrganizations();

  const { config = {} } = suborganization;

  const [showFullscreen, setShowFullscreen] = useState(false);
  const [hasTarget, setHasTarget] = useState(false);

  const value = useMemo(
    () => adaptInitialState({
      schema,
      tableDimensions,
      slug: kpi_slug,
      organization: suborganization.slug,
      organization_parent: suborganization.parent_slug,
      period,
      dependees,
      readOnly: true,
    }, initialValue),
    [
      schema,
      tableDimensions,
      initialValue,
      suborganization,
      kpi_slug,
      period,
      dependees,
    ]
  );

  const {
    suborganizationFeatures: featureList,
  } = useFeatureList();

useEffect(()=>{
  if( featureList.has('targets') && target){
    setHasTarget(true);
  }
},[target, featureList]);

  const hasTargetPermissions = useMemo(() => {
    return featureList.has("targets")
      && permissions.can_configure_kpi
      && schema.type !== QUALITATIVE_SCHEMA_TYPE;
  }, [
    featureList,
    permissions.can_configure_kpi,
    schema.type
  ]);

  // TODO: table with class KpiDetail__answer-table-scrollable
  return (
    <section className='KpiDetail__answer-table KpiDetail__answer-table-unscrollable'>
      <ShowTable
        schema={schema}
        schemaLabels={schemaLabels}
        config={config}
        value={value}
        previous_value={previous_value}
        target = {target}
        isTarget = {isTarget}
        sourceData={{ source, source_params }}
      />
      <ScrollTableButton />
      <section className='KpiDetail__answer-table-buttons'>
        <CustomButton
          type='default'
          className='KpiDetail__answer-table-buttons-fullscreen'
          onClick={() => setShowFullscreen(true)}
        >
          <img
            src='/images/assets/full-size.svg'
            alt='fullscreen'
          />
          { t.kpi_detail_fullscreen }
        </CustomButton>
      </section>
      <section>
        {
          !comment
          ? null
          : (
            <section>
              <Divider/>
              <div className="KpiDetail__title">
                { t.privateComment }
              </div>
              <Description
                description={comment}
              />
            </section>
          )
        }
      </section>
      <Divider />
      <AnswerFooter
        source={source}
        source_params={source_params}
        canWrite={canWrite}
        hasLastAttachments={!!previous_attachments?.length}
        hasLastComment={!!previous_comment}
        hasLastPeriod={!!previous_value}
        onShowLastPeriod={onShowLastPeriod}
        onUseLastValue={onUseLastValue}
        onUseLastWholeData={onUseLastWholeData}
        onUseLastAttachments={onUseLastAttachments}
        onUseLastPeriod={onUseLastPeriod}
        onEdit={onEdit}
        onTarget={onTarget}
        hasTarget={hasTarget}
        isTargetElegible={hasTargetPermissions}
        suborganization_data={suborganization_data}
        dependees={dependees}
      />
      <ModalDataAnswer
        shown={showFullscreen}
        onClose={() => setShowFullscreen(false)}
        showUser={false}
        showUseButtons={false}
        loading={loading}
        schema={schema}
        schemaLabels={schemaLabels}
        value={value}
        comment={comment}
        config={config}
        kpi_name={kpi_name}
        org_name={org_name}
        period={period}
        sourceData={{ source, source_params }}
      />
    </section>
  );
};

Show.Component = ShowTable;
Show.SummaryComponent = SummaryComponent;

export default injectIntl(Show);

