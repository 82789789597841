import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';

import MainLayout from 'components/MainLayout';
import GOI from 'components/GroupsOfInterest';


import {
  requestGroupsOfInterest,
  createGroupOfInterest,
  deleteGroupOfInterest,
  editGroupOfInterest,
  createGoiMember,
  editGoiMember,
  deleteGoiMember,
} from 'actions/api';
import { changeCurrentGroup } from 'actions/goi';

import './style.less';

const GroupsOfInterest = ({
  intl,
  requestGroupsOfInterest,
  createGroupOfInterest,
  deleteGroupOfInterest,
  editGroupOfInterest,
  createGoiMember,
  editGoiMember,
  deleteGoiMember,
  changeCurrentGroup,
  groups,
  group,
}) => {
  return (
    <MainLayout>
      <Sentry.ErrorBoundary
        fallback={
          <ErrorBoundaryFallback
            titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
            buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
            descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_groups_of_interest_message' })}
            customErrorImage="/images/error_image.png"
          />
      }>
        <GOI
          requestGroupsOfInterest={requestGroupsOfInterest}
          createGroupOfInterest={createGroupOfInterest}
          deleteGroupOfInterest={deleteGroupOfInterest}
          editGroupOfInterest={editGroupOfInterest}
          createGoiMember={createGoiMember}
          editGoiMember={editGoiMember}
          deleteGoiMember={deleteGoiMember}
          changeCurrentGroup={changeCurrentGroup}
          groups={groups}
          group={group}
        />
      </Sentry.ErrorBoundary>
    </MainLayout>
  );
};

const mapStateToProps = ({
  groups_of_interest
}) => ({
  groups: groups_of_interest.data,
  group: groups_of_interest.currentGroup,
  error: groups_of_interest.error,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      changeCurrentGroup,
      requestGroupsOfInterest,
      createGroupOfInterest,
      deleteGroupOfInterest,
      editGroupOfInterest,
      createGoiMember,
      editGoiMember,
      deleteGoiMember,
    }
  )(GroupsOfInterest)
);
