import React, { useMemo } from 'react';
import { isEmpty } from "lodash";
import { useSelector } from 'react-redux';
import SelectKpi from './steps/SelectKpi'
import SelectGraph from './steps/SelectGraph'
import SelectTableData from './steps/SelectTableData'
import SelectNodes from './steps/SelectNodes';

import { validate } from "./validators";
import SelectPeriodicity from './steps/SelectPeriodicity';

export default ({
    intl,
    values
  }) => {

    const organization = useSelector(state => state.organization);

    const Step1Content = useMemo(() => ( 
                              <SelectKpi 
                                organization={organization} 
                              />
                          ), [organization]);

    const Step2Content = useMemo(() => ( <SelectNodes /> ), []);
    const Step3Content = useMemo(() => ( <SelectTableData /> ), []);
    const Step4Content = useMemo(() => ( <SelectPeriodicity /> ), []);
    const Step5Content = useMemo(() => ( <SelectGraph /> ), []);

    return useMemo(() => [
      {
        title: intl.formatMessage({id: 'modal_add_kpi_first_step'}),
        content: Step1Content,
        isValid: () => isEmpty(validate(intl)(values, 0)),
        warningTooltipMessage: '', //TODO
      },
      {
        title: intl.formatMessage({id: 'modal_add_kpi_second_step'}),
        content: Step2Content,
        isValid: () => isEmpty(validate(intl)(values, 1)),
        warningTooltipMessage: '', //TODO
        hasWarningModal: false, //TODO
      },
      {
        title: intl.formatMessage({id: 'modal_add_kpi_third_step'}),
        content: Step3Content,
        isValid: () => isEmpty(validate(intl)(values, 2)),
        warningTooltipMessage: '', //TODO
        hasWarningModal: false, //TODO
      },
      {
        title: intl.formatMessage({id: 'modal_add_kpi_fourth_step'}),
        content: Step4Content,
        isValid: () => isEmpty(validate(intl)(values, 3)),
        warningTooltipMessage: '', //TODO
        hasWarningModal: false, //TODO
      },
      {
        title: intl.formatMessage({id: 'modal_add_kpi_fifth_step'}),
        content: Step5Content,
        isValid: () => isEmpty(validate(intl)(values, 4)),
        warningTooltipMessage: '', //TODO
        hasWarningModal: false, //TODO
      }
    ], [
      intl,
      values,
      Step1Content,
      Step2Content,
      Step3Content,
      Step4Content,
      Step5Content
    ])
  }
  