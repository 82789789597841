import React from 'react';

import { replaceTextURLByHTMLLink } from 'utils/strings';
import { Modal } from 'antd';


const InformationModal = ({
  visible = false,
  title,
  message,
  onClose,
}) => {
  return (
    <Modal
      visible={visible}
      title={title}
      footer={null}
      maskClosable
      onCancel={onClose}
    >
      { message && (
        <p
          dangerouslySetInnerHTML={{
            __html: replaceTextURLByHTMLLink(
              message.replace(/\r\n?|\n/g, '<br>')
            )
          }}
        ></p>
      )}
    </Modal>
  );
}

export default InformationModal;