import React, { useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import A from 'components/A';
import { Col, Progress, Row } from 'antd';

import AplanetIcon from '../AplanetIcon';

import './style.less';


const HideShowButton = injectIntl(({
  intl,
  status = 'show',
  handleOnToggleStatus,
  placement = 'bottomLeft',
}) => (
  <Row
    className={`ProgressBars__visibility_toggle ProgressBars__visibility_toggle_${placement}`}
    type="flex"
    justify={placement === "topRight" ? "end" : "start"}
  >
    <Col>
      <A
        className="ProgressBars__button"
        onClick={handleOnToggleStatus}
      >
        <AplanetIcon name={`${status === 'show' ? 'Eye close' : 'Eye open'}`} size="18px" />
        {intl.formatMessage({ id: `progress_bar_${status === 'show' ? 'hide' : 'show'}_button`})}
      </A>
    </Col>
  </Row>
));

const ProgressBars = ({
  intl,
  defaultShowProgressBars = false,
  isPercentFormat = true,
  config,
  onSelectBar,
  selectBarLabel,
  hideShowButtonPlacement = "bottomLeft",
  hasHideShowButton,
  onToggleShowProgress,
}) => {
  const [showProgress, setShowProgress] = useState(defaultShowProgressBars);

  const handleToggleShowProgress = useCallback(() => {
    setShowProgress(!showProgress);
    if (onToggleShowProgress) {
      onToggleShowProgress(!showProgress);
    }
  }, [onToggleShowProgress, showProgress]);

  const formatLabelNumber = useCallback(
    (count, total, format) => {
      switch (format) {
        case 'count':
          return `${count}`;
        case 'countOfTotal':
          return intl.formatMessage({ id: `progress_bar_count_of_total_label`}, {count, total});
        case 'percentage':
        default:
          const labelNumber = isPercentFormat
            ? count
            : Number((count / total * 100).toFixed(1));
          return `${labelNumber}%`;
      };
    },
    [
      intl,
      isPercentFormat
    ]
  );

  return (
    <div className="ProgressBars">
      { (hasHideShowButton && hideShowButtonPlacement === "topRight")
        ? <HideShowButton
            status={showProgress ? 'show' : 'hide'}
            handleOnToggleStatus={handleToggleShowProgress}
            placement={hideShowButtonPlacement}
          />
        : null
      }
      {showProgress ? (
        <Row gutter={[30, 10]}>
          { config.map(({title, data, numberFormat = 'percentage', span = 12}, index) =>
            <Col xs={24} sm={24} md={24} lg={span} xl={span} key={index}>
              <Row gutter={[10, 3]}>
                { title
                  ? <Col span={24} className="ProgressBars__title">
                      {title}
                    </Col>
                  : null
                }
                <Col span={24} className={`ProgressBars__bars-container ${data.length === 1 ? 'is-one-bar' : ''}`}>
                  {data.map(({
                    id,
                    count,
                    total,
                    height,
                    label,
                    iconProps,
                  }) => (
                    <React.Fragment key={id}>
                      <Progress
                        className={`ProgressBars__progress-bar ${id}`}
                        percent={isPercentFormat ? count : Number((count / total * 100).toFixed(1))}
                        strokeWidth={height || 16}
                        showInfo={false}
                      />
                      <div className="ProgressBars__bar-info">
                        {iconProps ? (
                          <AplanetIcon
                            name={iconProps.name}
                            faStyle={iconProps.faStyle}
                            className={id}
                            size="18px"
                          />
                        ) : null}
                        <span className={`ProgressBars__value ${id}`}>{formatLabelNumber(count, total, numberFormat)}</span>
                        {label ? (<span className={`ProgressBars__label ${id}`}>{label}</span>) : null}
                        {onSelectBar && selectBarLabel ? (
                          <A
                            className={`ProgressBars__select ${id}`}
                            onClick={() => onSelectBar(id)}
                          >
                            {selectBarLabel}
                          </A>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ))}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      ) : null}
      { (hasHideShowButton && hideShowButtonPlacement === "bottomLeft")
        ? <HideShowButton
            status={showProgress ? 'show' : 'hide'}
            handleOnToggleStatus={handleToggleShowProgress}
            placement={hideShowButtonPlacement}
          />
        : null
      }
    </div>
  );
}

ProgressBars.propTypes = {
  config: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      total: PropTypes.number,
      height: PropTypes.number,
      label: PropTypes.string,
      iconProps: PropTypes.shape({
        name: PropTypes.string.isRequired,
        faStyle: PropTypes.string,
        className: PropTypes.string,
      }),
    })).isRequired,
    numberFormat: PropTypes.oneOf(['percentage', 'count', 'countOfTotal']),
    span: PropTypes.number,
  })).isRequired,
  hasHideShowButton: PropTypes.bool,
  onSelectBar: PropTypes.func,
  onToggleShowProgress: PropTypes.func,
  defaultShowProgressBars: PropTypes.bool,
  isPercentFormat: PropTypes.bool,
  selectBarLabel: PropTypes.string,
  hideShowButtonPlacement: PropTypes.oneOf(['bottomLeft', 'topRight']),
};

export default injectIntl(ProgressBars);
