import { loop, Cmd } from 'redux-loop';
import { parseResponse } from 'utils';
import config from 'config';

const startSurvey = async (organization_slug, suborganization_slug, uuid) => {
  try {
    const response = await fetch(
      `${config.API_URL}/external/${organization_slug}/survey/${suborganization_slug}/${uuid}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
      }
    );

    const result = await parseResponse(response);
    return result;
  } catch(err) {
    // eslint-disable-next-line no-throw-literal
    throw { ...err };
  }
};

const startSurveySuccess = (response) => ({
  type: 'START_SURVEY_SUCCESS',
  response
});

const startSurveyFail = ({ code }) => ({
  type: 'START_SURVEY_FAILED',
  code
});

const submitSurvey = async (organization_slug, suborganization_slug, uuid, body) => {
  const response = await fetch(
    `${config.API_URL}/external/${organization_slug}/survey/${suborganization_slug}/${uuid}`,
    {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
    }
  );

  const result = await parseResponse(response);
  return result;
};

const submitSurveySuccess = (response) => ({
  response,
  type: 'SUBMIT_SURVEY_SUCCESS',
});

const submitSurveyFail = ({ code }) => ({
  type: 'SUBMIT_SURVEY_FAILED',
  code,
});

const initialState = {
  requested: false,
  loading: true,
  error: false,
  data: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'START_SURVEY':
      return loop(
        { requested: false, loading: true, error: false, },
        Cmd.run(startSurvey, {
          successActionCreator: startSurveySuccess,
          failActionCreator: startSurveyFail,
          args: [ action.organization_slug, action.suborganization_slug, action.slug ],
        })
      );
    case 'START_SURVEY_SUCCESS':
      return {
        requested: true, loading: false, error: false,
        data: action.response
      };
    case 'START_SURVEY_FAILED':
    case 'SUBMIT_SURVEY_FAILED':
      return { requested: false, loading: false, error: true };
    case 'SUBMIT_SURVEY':
      return loop(
        {
          ...state,
          requested: false,
          loading: true,
          error: false,
        },
        Cmd.run(submitSurvey, {
          successActionCreator: submitSurveySuccess,
          failActionCreator: submitSurveyFail,
          args: [ action.organization_slug, action.suborganization_slug, action.slug, action.body ],
        })
      );
    case 'SUBMIT_SURVEY_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          status: 'replied'
        },
        requested: false,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export {
  reducer as goi_survey,
};
