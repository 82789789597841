import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

const areAllTargetsSelected = (slugsSelection, sdg) =>
  sdg.targets.every((t) => slugsSelection.includes(t.slug));

const isIncludedInTargets = (topSelection, sdg) =>
  topSelection
    .reduce((acc, p) => [...acc, ...p.targets], [])
    .map((c) => c.slug)
    .includes(sdg);

const createHashMapForSDGsTree = (tree) => {
  return (tree || []).reduce(
    (acc, sdg) => ({
      ...acc,
      ...sdg.targets.reduce(
        (innerAcc, t) => ({
          ...innerAcc,
          [t.slug]: { parent: sdg, value: t },
        }),
        {}
      ),
    }),
    {}
  );
}

export const useSDGsClustering = () => {
  const { sdgs: sdgsTaxonomy } = useSelector((state) => state.taxonomies);
  const taxonomyMap = useMemo(
    () => createHashMapForSDGsTree(sdgsTaxonomy),
    [sdgsTaxonomy]
  );

  return useCallback(
    (slugs = []) => {
      if (Object.keys(taxonomyMap).length === 0) return [];

      const sdgSelection = sdgsTaxonomy.filter((sdg) =>
        areAllTargetsSelected(slugs, sdg)
      );
      const targetsSelection = slugs
        .filter((sdg) => !isIncludedInTargets(sdgSelection, sdg))
        .map((slug) => ({
          slug,
          code: taxonomyMap[slug]?.value.code,
          parent_slug: taxonomyMap[slug]?.parent.slug,
        }));
      
      return sdgSelection
        .concat(targetsSelection)
        .map((s) => ({
          slug: s.slug,
          code: s.code || sdgsTaxonomy.find((st) => st.slug === s.slug).code,
          parent_slug: s.parent_slug || s.slug,
        }));
    },
    [sdgsTaxonomy, taxonomyMap]
  );
};
