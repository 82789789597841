const localeToVariableLocale = {
  'en': 'en-US',
  'en-US': 'en-US',
  'es': 'es-ES',
  'es-ES': 'es-ES',
  'es-MX': 'es-ES',
  'pt': 'pt-PT',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt-PT',
  'fr': 'fr-FR',
  'fr-FR': 'fr-FR',
  'ca': 'ca-ES',
  'ca-ES': 'ca-ES',
};

const updateDimensionName = (dimension, name) => {
  let updated = {
    ...dimension,
    byName: name,
  };

  if (name) {
    updated.by = "";
  } else {
    delete updated.by;
  }
  return updated;
};

const getDimensionsEligibleForCalculation = (
  dimensions, calculation, target = 'row'
) => {
  return dimensions.filter((dimension, index) => {
    let eligibleForCalculation = false;

    switch (`${calculation}_${target}`) {
      case 'total_row':
        eligibleForCalculation = (
          (index + 1) === dimensions.length
          && ['organization', 'standard'].includes(dimension.source)
          && dimension.presentation === 'column'
        );
        break;
      case 'total_column':
        eligibleForCalculation = dimension.presentation === 'row' && (
          // The calculations refer to this dimension if it's the last one
          // with many options
          (
            (index + 1) === dimensions.length
            && ['organization', 'standard'].includes(dimension.source)
          )
          ||
          // Or if it's the second-last one and the last one is a singleton
          (
            (index + 2) === dimensions.length
            && dimensions[index + 1].source === 'singleton'
          )
          ||
          // Also calculate total for the second-last one if the last one
          // gets the calculations
          (
            (index + 2) === dimensions.length
            && ['organization', 'standard'].includes(
              dimensions[index + 1].source
            )
          )
        );
        break;
      case 'percentage_row':
        eligibleForCalculation = (
          (index + 1) === dimensions.length
          && ['organization', 'standard'].includes(dimension.source)
          && dimension.presentation === 'column'
        );
        break;
      case 'percentage_column':
        eligibleForCalculation = dimension.presentation === 'row' && (
          // The calculations refer to this dimension if it's the last one
          // with many options
          (
            (index + 1) === dimensions.length
            && ['organization', 'standard'].includes(dimension.source)
          )
          ||
          // Or if it's the second-last one and the last one is a singleton
          (
            (index + 2) === dimensions.length
            && dimensions[index + 1].source === 'singleton'
          )
          ||
          // Also calculate percentage for the second-last one if the last one
          // gets the calculations
          (
            (index + 2) === dimensions.length
            && ['organization', 'standard'].includes(
              dimensions[index + 1].source
            )
          )
        );
        break;
      case 'count_row':
        eligibleForCalculation = index === 0
          && dimension.presentation === 'row';
        break;
      default:
        break;
    }

    return eligibleForCalculation;
  });
};

const canAddCalculationToDimensions = (
  dimensions, calculation, target = 'row'
) => {
  return getDimensionsEligibleForCalculation(
    dimensions, calculation, target,
  ).length > 0;
};

const dimensionsHaveCalculation = (dimensions, calculation, target = 'row') => {
  const eligibleDimensions = getDimensionsEligibleForCalculation(
    dimensions, calculation, target
  );
  const result = eligibleDimensions.map(
    dimension => dimension.calculations.includes(calculation)
  );
  return result.some(_result => !!_result);
};

const changeCalculationOnDimensions = (
  dimensions, calculation, target = 'row', action = 'add',
) => {
  return dimensions.map((dimension, index) => {
    let hasCalculations = false;

    switch (`${calculation}_${target}`) {
      case 'total_row':
        hasCalculations = (
          (index + 1) === dimensions.length
          && ['organization', 'standard'].includes(dimension.source)
          && dimension.presentation === 'column'
        );
        break;
      case 'total_column':
        hasCalculations = dimension.presentation === 'row' && (
          // The calculations refer to this dimension if it's the last one
          // with many options
          (
            (index + 1) === dimensions.length
            && ['organization', 'standard'].includes(dimension.source)
          )
          ||
          // Or if it's the second-last one and the last one is a singleton
          (
            (index + 2) === dimensions.length
            && dimensions[index + 1].source === 'singleton'
          )
          ||
          // Also calculate total for the second-last one if the last one
          // gets the calculations
          (
            (index + 2) === dimensions.length
            && ['organization', 'standard'].includes(
              dimensions[index + 1].source
            )
          )
        );
        break;
      case 'percentage_row':
        hasCalculations = (
          (index + 1) === dimensions.length
          && ['organization', 'standard'].includes(dimension.source)
          && dimension.presentation === 'column'
        );
        break;
      case 'percentage_column':
        hasCalculations = dimension.presentation === 'row' && (
          // The calculations refer to this dimension if it's the last one
          // with many options
          (
            (index + 1) === dimensions.length
            && ['organization', 'standard'].includes(dimension.source)
          )
          ||
          // Or if it's the second-last one and the last one is a singleton
          (
            (index + 2) === dimensions.length
            && dimensions[index + 1].source === 'singleton'
          )
          ||
          // Also calculate percentage for the second-last one if the last one
          // gets the calculations
          (
            (index + 2) === dimensions.length
            && ['organization', 'standard'].includes(
              dimensions[index + 1].source
            )
          )
        );
        break;
      case 'count_row':
        hasCalculations = index === 0
          && dimension.presentation === 'row';
        break;
      default:
        break;
    }

    return {
      ...dimension,
      calculations: hasCalculations
        ? action === 'add'
          ? [...new Set([...dimension.calculations, calculation])]
          : dimension.calculations.filter(calc => calc !== calculation)
        : dimension.calculations,
    };
  });
}

export {
  localeToVariableLocale,
  updateDimensionName,
  dimensionsHaveCalculation,
  changeCalculationOnDimensions,
  canAddCalculationToDimensions,
}