import React, { FunctionComponent, ReactElement, SVGProps } from "react";
import styled from "@emotion/styled";

import type { FormattedMessage } from "react-intl";

import { ReactComponent as ErrorDefaultAnimation } from "assets/animations/internalServerError.svg";

export interface ErrorProps {
  /**
   * Animation component
   */
  svgAnimation?: FunctionComponent<SVGProps<SVGSVGElement>>;
  /**
   * Intro message
   */
  message: string | ReactElement<FormattedMessage>;
  /**
   * Explanation/reason message
   */
  explanation?: string | ReactElement<FormattedMessage>;
  /**
   * Child components
   */
  children?: React.ReactNode;
}

const defaultPrimaryColor = "#147F74";

const StyledError = styled.div<ErrorProps>`
  color: #23302e;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.15;
  padding: 80px 0;
  text-align: center;
  .animated {
    margin: 0 auto;
    display: block;
    max-width: 300px;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .link,
  &:visited {
    color: ${(props) => props.theme["@primary-color"] || defaultPrimaryColor};
    text-decoration: none;
    font-weight: 500;
    &:hover {
      color: ${(props) =>
        `${props.theme["@primary-color"] || defaultPrimaryColor}e3`};
    }
  }
  .text--bold {
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .text--regular {
    line-height: 1.5;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 600px) {
    max-width: 200px;
  }
`;

/**
 * Error component with a message and children components for actions
 */
export const Error = ({
  svgAnimation = ErrorDefaultAnimation,
  message,
  explanation,
  children,
  ...props
}: ErrorProps) => {
  const AnimationComponent = svgAnimation;

  return (
    <StyledError message={message} {...props}>
      <>
        <AnimationComponent />
        <p className="text--bold">{message}</p>
        <p className="text--regular">{explanation}</p>
        {children}
      </>
    </StyledError>
  );
};
