export const fullValidation = (t, jobFactors) => ({
  factors,
}) => {
  let errors = {};

  if (factors.length !== jobFactors.length) {
    errors.factors = t.form_error_required;
  } else {
    for (const factor of factors) {
      if (!factor.factor_level_id) {
        errors.factors = t.form_error_required;
        break;
      }
    }
  }

  return {
    ...errors,
  };
};