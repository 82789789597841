import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import {
  DeleteOutlined,
} from '@ant-design/icons';

import {
  requestKpiUpdate,
  deleteKpiValue
} from 'actions/api';

import {
  switchCurrentOrganization,
} from 'actions/organizationTree';

import { formatLong } from 'utils/date';
import useOrganizations from 'utils/useOrganizations';
import { adaptDependeeKpiForColumns } from 'utils/kpi_formula/column';

import Avatar from 'components/Avatar';
import RecursiveAnswers from 'components/RecursiveAnswers';
import Answer from 'components/Answer';
import ModalTarget from 'components/ModalTarget';
import DataTargets from 'components/DataTargets';
import { formatMessage } from '../../../../intl';
import CustomTag from 'components/CustomTag';
import CustomModal from 'components/CustomModal'
import { Empty } from 'tsComponents/emptyStates/Empty';
import { ButtonGroup } from 'tsComponents/button/ButtonGroup';
import { emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';
import Required from 'components/Required';

import {
  Row,
  Col,
  Modal,
} from 'antd';

import { useFeatureList } from 'components/FeatureSwitch';
import AplanetIcon from 'components/AplanetIcon';

const CHILDREN_SOURCE = 'children';
const MANUAL_SOURCE = 'manual';

const KpiDetailAnswer = ({
  intl,
  value,
  childrenValues,
  comment,
  attachments,
  source,
  source_params,
  schema,
  schemaLabels,
  lastUpdate,
  kpi_name,
  org_name,
  kpi_slug,
  period,
  loading,
  error,
  previous_value,
  previous_comment,
  previous_attachments,
  previous_period,
  onOpenAnswer,
  onUseValue,
  onUseComment,
  onUseAttachments,
  onUsePeriod,
  onUseAttachment,
  permissions = {},
  isValidating,
  target,
  has_target,
  type,
  sdgs,
  dependee_kpis,
  standard_info,
  config,
  suborganization_data,
  history,
  updateStatus,
}) => {

  const t = intl.messages;
  const {
    organization,
    suborganization,
  } = useOrganizations();

  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [targetModalParams, setTargetModalParams] = useState(null);

  const orgDimensions = useMemo(() => {
    const dimensions = (schema.dimensions || [])
      .filter(({ source }) => source === 'organization');

    return dimensions.map(({ by, byName }) => ({
      slug: by,
      name: (schemaLabels?.dimensionNames || {})[by] || byName || by,
    }));

  }, [
    schema,
    schemaLabels,
  ]);

  const orgColumns = useMemo(() => {

    //Check choice singleton dimension
    if (schema?.innerSchema?.type === 'choice' &&
      schema?.innerSchema?.options.source === 'organization') {

      return [{
        slug: schema?.innerSchema?.options.by,
        name: schemaLabels.innerSchema?.optionsName || schema?.innerSchema?.options.by,
      }];
    }

    //Check choice components
    const columns = (schema?.innerSchema?.components || [])
      .filter(({ type, options }) => type === 'choice' && options.source === 'organization');

    return columns.map(({ options, name }) => ({
      slug: options.by,
      name: (schemaLabels.innerSchema?.componentLabels || {})[options.by] || name || options.by,
    }));

  }, [
    schema,
    schemaLabels,
  ]);

  const {
    features: featureList,
  } = useFeatureList();

  const handleCancel = useCallback(() => {
    setEditMode(false);
  }, []);

  const handleSave = useCallback((value, comment) => {
    dispatch(
      requestKpiUpdate(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
        {
          value,
          comment,
        },
      )
    )
    setSubmitting(true);
  }, [
    dispatch,
    organization,
    suborganization,
    kpi_slug,
    period,
  ]);

  const handleClear = useCallback((value, comment) => {
    dispatch(
      deleteKpiValue(
        organization.slug,
        suborganization.slug,
        kpi_slug,
        period,
      )
    )
    setSubmitting(true);
  }, [
    dispatch,
    organization,
    suborganization,
    kpi_slug,
    period,
  ]);

  useEffect(() => {
    if (submitting && !loading && !error) {
      setEditMode(false);
      setSubmitting(false);
    }
  }, [
    submitting,
    loading,
    error,
  ]);

  useEffect(() => {
    // TODO: Maybe disable period buttons while editting...
    setEditMode(false);
  }, [
    period,
  ]);

  const handleChangeOrganization = useCallback(
    (org) => dispatch(switchCurrentOrganization(org)),
    [dispatch]
  );

  const dependees = useMemo(() => {
    // NOTICE: Here we adapt the 'dependee_kpis' field to the formula engine format
    return (dependee_kpis || []).map(adaptDependeeKpiForColumns)
  }, [
    dependee_kpis,
  ]);

  // TARGET METHODS
  const handleTarget = useCallback(() => {
    setTargetModalParams({
      name: kpi_name,
      slug: kpi_slug,
      // organization_slug: organization.slug,
      // suborganization_slug: suborganization.slug,
      type,
      sdgs,
      period,
      schema,
      schemaLabels,
      standard_info,
      target: target ? target.value : null,
      kpi_value: value,
      dependees,
      has_target
    });
  }, [
    kpi_name,
    kpi_slug,
    type,
    sdgs,
    period,
    schema,
    schemaLabels,
    standard_info,
    target,
    value,
    dependees,
    has_target
    // organization,
    // suborganization
  ]);
  const hasValue = source === CHILDREN_SOURCE
    ? (childrenValues && childrenValues.filter(Boolean).length > 0)
    : !!value

  const [modal, contextHolder] = Modal.useModal();
  const modalConfig = (kpi) => ({
    className: 'KpiDetail__answer__modal',
    title: kpi.name,
    icon: null,
    okText: t.kpi_title_go_to_indicator,
    cancelText: t.close,
    onOk: () => history.push(`/kpi/${kpi.slug}/${period}/data`),
    maskClosable: true,
    content: (
      <Answer.Show
        compact
        schema={kpi.schema}
        schemaLabels={kpi.schemaLabels}
        kpi_slug={kpi.slug}
        config={config}
        value={kpi.value}
      />
    )
  })

  const isEmpty = !hasValue;
  const hasWritePermissions =
    permissions.can_write_kpi && source === MANUAL_SOURCE;
  const hasTargetPermissions =
    featureList.has("targets") &&
    permissions.can_configure_kpi &&
    schema.type !== "qualitative";

  const hasCalculatedColumn = useMemo(() =>
    schema.type === 'table'
    && schema.innerSchema?.type === 'tuple'
    && dependee_kpis.length > 0
    && schema.innerSchema?.components.some(col => col.source === 'calculated')
    ,[dependee_kpis.length, schema.innerSchema?.components, schema.innerSchema?.type, schema.type]
  )

  const noEmptyStateSources = ['aggregated', 'calculated']

  return (
    <>
      { isEmpty && !editMode && !noEmptyStateSources.includes(source) ? (
        <Empty {...emptyPropsMap.get("noDataAdded")}>
          {hasWritePermissions && (
            <ButtonGroup>
              <button className="button--main" onClick={() => setEditMode(true)}>
                <AplanetIcon name="Add" />
                <FormattedMessage id="add_data" />
              </button>
              {hasTargetPermissions && (
                <button className="button--main-secondary" onClick={handleTarget}>
                  <AplanetIcon name="Target" />
                  <FormattedMessage
                    id={
                      target && target.value
                        ? "kpi_detail_target_edit"
                        : "kpi_detail_target_create"
                    }
                  />
                </button>
              )}
            </ButtonGroup>
          )}
        </Empty>
        ) : ( 
          <Row className="KpiDetail__row KpiDetail__fullrow">
          {editMode || !value || !lastUpdate || source !== MANUAL_SOURCE ? null : (
            <Col span={24} className="KpiDetail__answer-header">
              <Row type='flex' gutter={13}>
                <Col>
                  {lastUpdate.status === 'deleted'
                    ?
                    <Avatar className='KpiDetail__answer-avatar' icon={<DeleteOutlined />} />
                    :
                    <Avatar
                      shape="circle"
                      src={lastUpdate.avatar || undefined}
                      name={lastUpdate.name || undefined}
                      className='KpiDetail__answer-avatar'
                    />

                }
              </Col>
              <Col>
                <span className='KpiDetail__answer-user'>
                  <div>
                    <b>{formatMessage(lastUpdate.name)}</b>{` ${t.kpi_detail_has_added}`}
                  </div>
                  <div>
                    {formatLong(lastUpdate.updated_at, intl)}
                  </div>
                </span>
              </Col>
            </Row>
          </Col>
        )}
        {
          !editMode && !hasValue && !isValidating && !noEmptyStateSources.includes(source)
            ? (
              <div
                className="KpiDetail__answer-empty"
              >
                <Row>
                  <Col xs={24} sm={24} md={24} lg={target ? "24" : "8"} xl={target ? "24" : "8"}>
                    <h2
                      className="KpiDetail__answer-empty-title"
                    >
                      {t.kpi_detail_empty}{config?.mandatory_data?.value && <Required/>}
                    </h2>
                  </Col>
                  {target &&
                    <Col xs={24} sm={24} md={24} lg={7} xl={10} className='KpiDetail__answer-empty-target'>
                      <DataTargets
                        schema={schema}
                        schemaLabels={schemaLabels}
                        target={target}
                        kpi_value={value}
                        config={config}
                        justify="start"
                      />
                    </Col>
                  }

                </Row>
              </div>
            ) : source === CHILDREN_SOURCE
              ? (
                <Row className="KpiDetail__row KpiDetail__fullrow">
                  <RecursiveAnswers
                    onChangeOrganization={handleChangeOrganization}
                    schema={schema}
                    schemaLabels={schemaLabels}
                    values={childrenValues}
                    columnsNoScroll={3}
                  />
                </Row>
              ) : (
                <Row className={`KpiDetail__row ${(source !== MANUAL_SOURCE && updateStatus === 'pending') ? 'KpiDetail__row__disabled' : ''}`}>
                  {source !== MANUAL_SOURCE && updateStatus === 'pending' &&
                  <div className="KpiDetail__answer__watermark">
                    <FormattedMessage id="kpi_detail_update_status_pending" />
                  </div>
                  }
                  <Answer
                    kpi_name={kpi_name}
                    kpi_slug={kpi_slug}
                    org_name={org_name}
                    org_slug={suborganization.slug}
                    period={period}
                    source={source}
                    source_params={source_params}
                    schema={schema}
                    schemaLabels={schemaLabels}
                    value={value}
                    comment={comment}
                    attachments={attachments}
                    edit={editMode}
                    onCancel={handleCancel}
                    onSave={handleSave}
                    onClear={handleClear}
                    previous_value={previous_value}
                    previous_comment={previous_comment}
                    previous_attachments={previous_attachments}
                    previous_period={previous_period}
                    onEdit={() => setEditMode(true)}
                    onOpenAnswer={onOpenAnswer}
                    onUseValue={onUseValue}
                    onUseComment={onUseComment}
                    onUseAttachments={onUseAttachments}
                    onUsePeriod={onUsePeriod}
                    onUseAttachment={onUseAttachment}
                    canWrite={
                      permissions.can_write_kpi &&
                      !isValidating &&
                      source === MANUAL_SOURCE
                    }
                    target={target}
                    onTarget={handleTarget}
                    dependees={dependees}
                    suborganization_data={suborganization_data}
                    config={config}
                  />
                </Row>
              )
            }
          </Row> 
        )
      }
      {
        (!orgDimensions || orgDimensions.length === 0) &&
          (!orgColumns || orgColumns.length === 0)
          ? null
          : (
            <>
              <Row className="KpiDetail__row KpiDetail__fullrow">
                <label>{t.kpidetail_variables_used}:</label>
                <div className="KpiDetail__fullrow__list">
                  {orgDimensions.map(({ name }) => <CustomTag key={name} name={name} colorclass="grey" />)}
                  {orgColumns.map(({ name }) => <CustomTag key={name} name={name} colorclass="grey" />)}
                </div>
              </Row>

            </>
          )
      }
      {hasCalculatedColumn ? (
        <Row className="KpiDetail__row KpiDetail__fullrow">
          <label>{t.kpidetail_dependees_used}:</label>
          <div className="KpiDetail__fullrow__list">
            {dependee_kpis.filter(({applies}) => !!applies).map((kpi) => (
              <CustomTag
                className="KpiDetail__fullrow__list__tag"
                key={`${kpi.organization_id}-${kpi.slug}`}
                name={<>{kpi.name} <AplanetIcon name="Information" size='16px' style={{ marginLeft: '5px' }} /></>}
                colorclass="org"
                onClick={() => modal.confirm(modalConfig(kpi))}
              />
            ))}
          </div>
        </Row>
      )
        : null
      }
      {
        !targetModalParams ? null : (
          <ModalTarget
            visible={!!targetModalParams}
            onClose={() => setTargetModalParams(null)}
            {...targetModalParams}
          />
        )
      }
      <CustomModal></CustomModal>
      {contextHolder}
    </>
  );
};

export default injectIntl(withRouter(KpiDetailAnswer));
