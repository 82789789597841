import React from 'react';
import { injectIntl } from 'react-intl';
import { Radio } from 'antd';
import {
  useCollectionFrequencyOptions,
} from 'utils/collectionFrequency';
import CustomTag from 'components/CustomTag';
import './style.less';

const PeriodicitiesBulkSelector = (props) => {


  const {
    intl,
    onChange,
    error = '',
    disabled = false
  } = props

  const collectionFrequencyOptions = useCollectionFrequencyOptions(intl);

  return (
    <>
      <div className='PeriodicitiesSelector'>
        <Radio.Group {...props} onChange={e => onChange(e.target.value)} disabled={disabled} >
          {
            collectionFrequencyOptions.map(({ slug, name }) =>
              <Radio key={slug} value={slug} >
                <CustomTag
                  key={slug}
                  name={name}
                  colorclass={slug}
                />
              </Radio>
            )
          }
        </Radio.Group>

      </div>
      <p className='PeriodicitiesSelector__error-message'>{error}</p>
    </>
  )
}
export default injectIntl(PeriodicitiesBulkSelector);
