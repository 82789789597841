import React, { createContext } from 'react';

export const TableDataRequestsCellsContext = createContext();

export const TableDataRequestsCellsProvider = ({ value, children }) => {
  return (
    <TableDataRequestsCellsContext.Provider value={value} >
      {children}
    </TableDataRequestsCellsContext.Provider>
  );
}