import React, {
  useMemo,
} from 'react';
import { withRouter } from 'react-router';
import { Typography } from 'antd';

import config from 'config';

import A from 'components/A';
import { formatMessage } from '../../intl';

import './style.less';


const { Text } = Typography;

const ProductSelector = ({
  organization,
  history
}) => {
  const adminBaseUrl = useMemo(() => {
    return organization?.config?.community?.custom_redirect_urls?.admin || config.ADMIN_APP_URL;
  }, [
    organization,
  ]);

  return (
    <div className="ProductSelector">
        <Text className='ProductSelector-button ProductSelector-active'>
          {formatMessage('product_atlas')}
        </Text>
      {organization?.products?.includes('community') &&
        <A href={adminBaseUrl} className='ProductSelector-button ProductSelector-disabled'>
          {formatMessage('product_community')}
        </A>
      }
    </div>
  );
};

export default withRouter(ProductSelector);
