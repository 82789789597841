import React, {
  useCallback,
  useMemo,
} from 'react';

import { injectIntl } from 'react-intl';

import { professionalGroupValidation as validation } from './validation';
import useForm from 'utils/useForm';

import {
  Row,
  Col,
  Form,
} from 'antd';

import CustomTextArea from 'components/CustomTextArea';
import CustomInput from 'components/CustomInput';
import CustomModal from 'components/CustomModal';


const ProfessionalGroupForm = ({
  intl,
  professionalGroup,
  closeForm,
  handleSave,
}) => {
  const t = intl.messages;
  const defaults = professionalGroup;
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    handleSave(values);
    resetForm();
    closeForm();
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  const handleOnCloseForm = useCallback(
    () => {
      resetForm();
      closeForm();
    },
    [resetForm, closeForm]
  );

  return (
    <CustomModal
      title={getValue('id')
        ? t.equality_job_evaluation_determination_professional_group_edit
        : t.equality_job_evaluation_determination_professional_group_add
      }
      shown={true}
      onOk={handleSubmit}
      onCancel={handleOnCloseForm}
      okText={t.save}
      cancelText={t.cancel}
      width={800}
    >
      <Form
        className="form-input-wrapper"
        layout="vertical"
      >
        <Row>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('name') ? 'error' : '' }
              help={ showError('name') }
              label={ t.equality_job_evaluation_determination_professional_group_name }
              colon={false}
              required
            >
              <CustomInput
                placeholder={ t.equality_job_evaluation_determination_professional_group_name }
                name="name"
                value={ getValue('name') }
                onChange={handleChangeEvent}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('description') ? 'error' : '' }
              help={ showError('description') }
              label={ t.equality_job_evaluation_determination_professional_group_description }
              colon={false}
            >
              <CustomTextArea
                value={getValue('description')}
                onChange={handleChange('description')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  );
}

export default injectIntl(ProfessionalGroupForm);
