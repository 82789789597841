import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { useIntl } from 'react-intl';
import { getDatalabelOptions } from './types';
import { pluginLineTarget } from '../chartPlugins';
import { convertStringToArrayWordSegments } from 'utils/strings';

const DEFAULT_PARAMETERS = {
  smooth: false,
  showDataLabels: false,
  showDataTarget: false
};

// TODO: Take out parameters and expose a way to get the params for a nivo-api call
const Lines = forwardRef(({
  slug,
  parameters = DEFAULT_PARAMETERS,
  data,
  onDownload,
  dashboardTheme
}, ref) => {
  const intl = useIntl();
  const {
    nodata = intl.messages.no_data,
    smooth = DEFAULT_PARAMETERS.smooth,
    showDataLabels = DEFAULT_PARAMETERS.showDataLabels,
    showDataTarget = DEFAULT_PARAMETERS.showDataTarget
  } = parameters;

  const {
    labels = [],
    values: datasets = [],
  } = data;

  const commonAxesOptions = useMemo(() => ({
    beginAtZero: true,
    ticks: {
      precision: 0,
    },
  }), []);

  const options = useMemo(() => ({
    layout: {
      padding: {
        top: 20
      }
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        ...commonAxesOptions,
      },
      y: {
        ...commonAxesOptions,
      }
    },
    plugins: {
      datalabels: showDataLabels ? getDatalabelOptions(parameters) : false,
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded',
          filter: (legendItem, chartData) => {

            if (showDataTarget){
              const numDatasets = chartData.datasets.length / 2;
              return (legendItem.datasetIndex + 1) > numDatasets;
            }

            return true;
            
          }
        },
      },
      tooltip: {
        filter: function (tooltipItem) {

          if (showDataTarget){
            const numDatasets = data.values ? data.values.length / 2 : 0;
            return (tooltipItem.datasetIndex + 1) > numDatasets;
          }

          return true;
          
        },
        callbacks: {
          label: (context) => { 

            const label = context.dataset.label || '';
            const value = context.formattedValue === 'NaN' ? '-' : context.formattedValue;
            const tooltip = convertStringToArrayWordSegments(`${label}: ${value}`, 50);
            
            if (showDataTarget && context.raw.target){
              tooltip.push(`${ intl.formatMessage({ id: 'dashboard_card_form_target_chart_label' }) }: ${ context.raw.target }`)
            }

            return tooltip;
          },
        },
      }
    },
    ...(smooth && { lineTension: 0.4 }),
  }), [
    smooth,
    commonAxesOptions,
    parameters,
    showDataLabels,
    showDataTarget,
    data.values,
    intl
  ]);

  const plugins = showDataTarget ? [pluginLineTarget] : [];

  return (
    <div className="DashboardComponent__card_content">
      {
        !data.values || data.values.length === 0
        ?
          <div
            className="DashboardComponent__nodata"
          >
            { nodata }
          </div>
        :
          <Line
            ref={ref}
            data={{ labels, datasets }}
            height='300px'
            options={options} 
            plugins={plugins}
          />
      }
    </div>
  );
});

Lines.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.object,
};

export default Lines;

