import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const A = ({
  href,
  onClick,
  children,
  newWindow,
  className="",
  style={},
  download=false,
  disabled
}) => {
  // NOTE: provide either 'href' or 'onClick' here, but not both
  if(href) {
    const openNewWindow = newWindow || href.startsWith('http');
    return (
      // eslint-disable-next-line
      <a
        className={`A-a ${className}`}
        style={style}
        href={href}
        target={openNewWindow ? '_blank' : undefined}
        rel={openNewWindow ? 'nofollow noopener noreferrer' : 'nofollow'}
        download={download}
      >{ children }</a>
    );
  }

  return (
    <button
      type="button"
      className={`A-button ${className}`}
      onClick={onClick}
      disabled={disabled}
    >{ children }</button>
  );
}

A.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  download: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default A;
