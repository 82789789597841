import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import CustomButton from 'components/CustomButton';
import AplanetIcon from 'components/AplanetIcon';
import CustomWarningModal from 'components/CustomWarningModal';
import useOrganizations from 'utils/useOrganizations';
import { Loading } from 'tsComponents/emptyStates/Loading';
import RoleCard from './RoleCard';
import { useEventTracking } from 'hooks/useEventTracking';

import {
  requestOrganizationKpiMember,
  syncOrganizationKpiMembersToChildNodes,
} from 'actions/api';

import {
  Row,
  Col,
} from 'antd';


const Assignees = ({
  intl,
  kpiSlug,
  owners_uptodate,
}) => {
  const dispatch = useDispatch();
  const eventTracking = useEventTracking();
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [syncingAssignees, setSyncingAssignees] = useState(false);
  const { organization, suborganization } = useOrganizations();

  const {
    data: assignees,
    loading,
    pushing,
    error,
  } = useSelector(state => state.organization_kpi_member);

  const filteredAssignees = useMemo(() => {
    return assignees.filter(({is_external}) => !is_external);
  }, [assignees]);

  useEffect(() => {
    dispatch(
      requestOrganizationKpiMember(
        organization.slug, suborganization.slug, kpiSlug
      )
    );
  }, [
    dispatch,
    kpiSlug,
    organization.slug,
    suborganization.slug,
  ]);

  useEffect(
    () => {
      if (syncingAssignees && !pushing) {
        setSyncingAssignees(false);
        if (!error) {
          setShowSyncModal(false);
        }
      }
    },
    [
      error,
      pushing,
      syncingAssignees,
    ]
  );

  const handleOnSyncToChildNodes = useCallback(
    () => {
      eventTracking.capture('dataOwners.sync', {
        tab_name: 'KPI_detail',
      });
      dispatch(
        syncOrganizationKpiMembersToChildNodes(
          organization.slug,
          suborganization.slug,
          [kpiSlug],
        )
      );
      setSyncingAssignees(true);
    },
    [
      dispatch,
      organization,
      suborganization,
      kpiSlug,
      eventTracking,
    ]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Row
      className="Assignees"
      gutter={[2, 10]}
    >
      <Col span={24}>
        { suborganization?.children?.length
          ? <Row
              type="flex"
              justify="end"
            >
              <Col>
                <CustomButton
                  type="primary"
                  icon={<AplanetIcon name="refresh" className="Assignees__sync_button" />}
                  onClick={() => setShowSyncModal(true)}
                  disabled={pushing || syncingAssignees || owners_uptodate}
                >
                  {intl.formatMessage({id: `assignees_sync_to_tree`})}
                </CustomButton>
                <CustomWarningModal
                  className="Assignees_sync_modal"
                  showModal={showSyncModal}
                  onOk={handleOnSyncToChildNodes}
                  onCancel={() => setShowSyncModal(false)}
                  onOkText={intl.formatMessage({id: `assignees_sync_modal_action_ok`})}
                  onCancelText={intl.formatMessage({id: `assignees_sync_modal_action_cancel`})}
                  title={intl.formatMessage({id: `assignees_sync_modal_title`})}
                  content={
                    <Row gutter={[0, 10]}>
                      <Col span={24}>
                        {intl.formatMessage({id: `assignees_sync_modal_content`})}
                      </Col>
                      <Col span={24}>
                        <b>
                          {intl.formatMessage({id: `assignees_sync_modal_confirmation`})}
                        </b>
                      </Col>
                    </Row>
                  }
                  loading={pushing || syncingAssignees}
                />
              </Col>
            </Row>
          : null
        }
      </Col>
      <Col span={8}>
        <RoleCard
          type="manager"
          kpiSlugs={[kpiSlug]}
          assignees={filteredAssignees}
        />
      </Col>
      <Col span={8}>
        <RoleCard
          type="editor"
          kpiSlugs={[kpiSlug]}
          assignees={filteredAssignees}
        />
      </Col>
      <Col span={8}>
        <RoleCard
          type="validator"
          kpiSlugs={[kpiSlug]}
          assignees={filteredAssignees}
        />
      </Col>
    </Row>
  );
}

export default withRouter(injectIntl(Assignees));
