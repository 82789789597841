import React from 'react';
import { injectIntl } from 'react-intl';
import AplanetIcon from 'components/AplanetIcon';
import { Tooltip } from 'antd';
import './style.less';

const Icon = {
  cultural: "ESG Cultural",
  economic: "ESG Economic",
  environmental: "ESG Environmental",
  governance: "ESG Governance",
  social: "ESG Social",
}

const EsgLogo = ({
  type,
  faStyle = "fal",
  intl,
  uncolored,
  size
}) => {
  if(!type || !Icon[type]) {
    return null;
  }
  return (
    <Tooltip
      title={intl.formatMessage({id: `esg_type_${type}`})}
      overlayClassName="EsgLogo__tooltip"
    >
      <div className={`EsgLogo`}>
        <AplanetIcon
          className={`EsgLogo_${type}`}
          size={size}
          name={Icon[type]}
          faStyle={faStyle}
          style={{color: uncolored ? '#919897' : ''}}
        />
      </div>
    </Tooltip>
  );
}

export default injectIntl(EsgLogo);
