const TARGET = 'data_bulk_upload';

const isTarget = (target) => [TARGET].includes(target);
//const isGeneralTarget = (target) => target === TARGET;

const initialState = {
  data: null,
  loading: false,
  error: null,
  current_organization_slug: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(!isTarget(action.target)) return state;
      return {
        data: action.method === 'GET' && (state.current_organization_slug !== action.suborganization_slug)
          ? null
          : state.data,
        loading: true,
        error: null,
        current_organization_slug: action.suborganization_slug,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || !isTarget(action.response.target)) return state;
      return {
        ...state,
        data: action.response.result,
        loading: false,
        error: null,
      };
    case 'ADD_BULK_UPLOAD':
      if(
        !state.data
      ) {
        return state;
      }

      return {
        ...state,
        data: [
          {
            params: {
              filename: action.filename,
              suborganization_slug: action.suborganization_slug,
            },
            status: 'pending',
            member_name: action.member_name,
            member_email: action.member_email,
            member_avatar: action.member_avatar,
            updated_at: action.updated_at,
          },
          ...state.data,
        ]
      };
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state,
        data: null,
        loading: false,
        error: action.code,
      };
    case 'RESET_AUTH':
    case 'LOGOUT_REQUEST':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as data_bulk_upload,
};
