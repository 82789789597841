import React, { useCallback, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';

import { backendLevelToLevel } from 'utils/validation';

import CustomCheckbox from 'components/CustomCheckbox';
import {
  Col,
  Row,
} from 'antd';


const UpdateValidationLevels = ({
  intl,
  approvalLevelOptions,
  permissions,
  updateValidationLevel,
}) => {
  const initialAppovalLevel = useMemo(
    () => backendLevelToLevel(permissions.validate_kpi_level),
    [ permissions,]
  );

  const [approvalLevel, setApprovalLevel] = useState(
    initialAppovalLevel,
  );

  const handleChangeApprovalLevel = useCallback(
    (level) => {
      let newValue = [...approvalLevel];

      if (newValue.includes(level)) {
        newValue = newValue.filter(_level => _level !== level);
      } else {
        newValue.push(level);
      }
      setApprovalLevel(newValue);
    },
    [approvalLevel]
  );

  return (
    <>
      { approvalLevelOptions.map(option =>
        <Row
          key={option.value}
          type="flex"
          align="middle"
          gutter={10}
        >
          <Col>
            <CustomCheckbox
              checked={approvalLevel.includes(option.value)}
              onChange={() => handleChangeApprovalLevel(option.value)}
            />
          </Col>
          <Col>
            {option.label}
          </Col>
        </Row>
      )}
      <Row
        className="DataSourcesPermissionValidationLevels__save"
        gutter={[10, 10]}
      >
        <Col>
          { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
          <a onClick={() => updateValidationLevel(approvalLevel)}>
            { intl.formatMessage({ id: "data_management_sources_permissions_save" }) }
          </a>
        </Col>
      </Row>
    </>
  );
}

export default injectIntl(UpdateValidationLevels);
