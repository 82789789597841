import React, { useMemo, useCallback } from 'react';
import { injectIntl} from 'react-intl';
import { withRouter } from 'react-router';

import { formatDate, DATE_ONLY } from 'utils/date';
import Navigator from 'components/Navigator';

import {
  Row,
  Col,
  Tabs,
} from 'antd';
import {
  CheckOutlined,
} from "@ant-design/icons";

import './style.less';


const { TabPane } = Tabs;

const EqualityPlanCard = ({
  t,
  step,
  index,
  plan,
  onOpenEqualityPlanStep,
}) => {
  const onStepClick = useCallback(
    (route) => {
      return onOpenEqualityPlanStep(route);
    },
    [onOpenEqualityPlanStep]
  );

  return (
    <Col span={8}>
      <Row
        onClick={() => onStepClick(step.route)}
        className={`EqualityPlan-card${plan.progress && plan.progress.includes(step.key) ? ' EqualityPlan-card-complete' : ''}`}
      >
        <Col span={5} className="EqualityPlan-card-number">
          {index + 1}
        </Col>
        <Col span={19}>
          <Row>
            <Col span={24} className="EqualityPlan-card-title">
              {t[step.title]}
            </Col>
            <Col span={24} className="EqualityPlan-card-description">
              {t[step.description]}
            </Col>
          </Row>
        </Col>
        { plan.progress && plan.progress.includes(step.key)
          ? <Col span={24} className="EqualityPlan-card-complete-icon">
              <CheckOutlined />
            </Col>
          : null
        }
      </Row>
    </Col>
  );
}

const EqualityPlan = ({
  intl,
  match,
  history,
  plan,
  onOpenEqualityPlanStep,
}) => {
  const t = intl.messages;

  const creationSteps = useMemo(
    () => [{
      title: 'equality_org_commitment_card_title',
      description: 'equality_org_commitment_card_desc',
      icon: 'signature',
      route: 'organization-commitment',
      key: 'org_commitment',
    }, {
      title: 'equality_committee_card_title',
      description: 'equality_committee_card_desc',
      icon: 'users',
      route: 'committee',
      key: 'committee',
    }, {
      title: 'equality_data_collection_card_title',
      description: 'equality_data_collection_card_desc',
      icon: 'table',
      route: 'data-collection',
      key: 'data_collection',
    }, {
      title: 'equality_diagnosis_salary_audit_card_title',
      description: 'equality_diagnosis_salary_audit_card_desc',
      icon: 'money-check-alt',
      route: 'diagnosis-salary-audit',
      key: 'diagnosis_salary_audit',
    }, {
      title: 'equality_salary_audit_document_card_title',
      description: 'equality_salary_audit_document_card_desc',
      icon: 'money-check-alt',
      route: 'salary-audit-document',
      key: 'salary_audit_document',
    }, {
      title: 'equality_diagnosis_card_title',
      description: 'equality_diagnosis_card_desc',
      icon: 'balance-scale-right',
      route: 'diagnosis',
      key: 'diagnosis',
    }, {
      title: 'equality_objectives_card_title',
      description: 'equality_objectives_card_desc',
      icon: 'map-signs',
      route: 'objectives',
      key: 'objectives',
    }, {
      title: 'equality_actions_card_title',
      description: 'equality_actions_card_desc',
      icon: 'wrench',
      route: 'actions',
      key: 'actions',
    }, {
      title: 'equality_plan_creation_card_title',
      description: 'equality_plan_creation_card_desc',
      icon: 'edit',
      route: 'plan-creation',
      key: 'plan_creation',
    }, {
      title: 'equality_plan_document',
      description: 'doc_ready_to_share',
      icon: 'scroll',
      route: 'plan-document',
      key: 'plan_documents',
    }, {
      title: 'equality_plan_other_documents',
      description: 'equality_plan_other_documents_desc',
      icon: 'file-alt',
      route: 'other-documents',
      key: 'other_documents',
    }],
    []
  );

  const evaluationSteps = useMemo(
    () => [{
      title: 'equality_followup_committee_title',
      description: 'equality_followup_committee_desc',
      icon: 'users',
      route: 'committee-followup',
      key: 'committee_followup',
    }, {
      title: 'equality_followup_calendar_title',
      description: 'equality_followup_calendar_desc',
      icon: 'calendar',
      route: 'calendar-followup',
      key: 'calendar_followup',
    }, {
      title: 'equality_objective_tracking',
      description: 'equality_objective_tracking_desc',
      icon: 'binoculars',
      route: 'tracking',
      key: 'tracking',
    }, {
      title: 'equality_followup_evaluation_of_results_title',
      description: 'equality_followup_evaluation_of_results_desc',
      icon: 'file',
      route: 'evaluation-of-results',
      key: 'evaluation_of_results',
    }, {
      title: 'equality_followup_actions_title',
      description: 'equality_followup_actions_desc',
      icon: 'wrench',
      route: 'actions-followup',
      key: 'actions_followup',
    }, {
      title: 'equality_followup_objective_tracking_title',
      description: 'equality_followup_objective_tracking_desc',
      icon: 'binoculars',
      route: 'tracking-followup',
      key: 'tracking_followup',
    }, {
      title: 'equality_followup_plan_other_documents_title',
      description: 'equality_followup_plan_other_documents_desc',
      icon: 'file-alt',
      route: 'other-documents-followup',
      key: 'other_documents_followup',
    }],
    []
  );

  const jobEvaluationSteps = useMemo(
    () => [{
      title: 'equality_job_evaluation_definition_of_factors_title',
      description: 'equality_job_evaluation_definition_of_factors_desc',
      route: 'factor-definition',
      key: 'factor_definition',
    }, {
      title: 'equality_job_evaluation_factor_weight_title',
      description: 'equality_job_evaluation_factor_weight_desc',
      route: 'factor-weight',
      key: 'factor_weight',
    }, {
      title: 'equality_job_evaluation_factor_point_graph_title',
      description: 'equality_job_evaluation_factor_point_graph_desc',
      route: 'factor-point-graphs',
      key: 'factor_point_graphs',
    }, {
      title: 'equality_job_evaluation_determination_title',
      description: 'equality_job_evaluation_determination_desc',
      route: 'job-determination',
      key: 'job_determination',
    }, {
      title: 'equality_job_evaluation_assessment_title',
      description: 'equality_job_evaluation_assessment_desc',
      route: 'job-assessment',
      key: 'job_assessment',
    }, {
      title: 'equality_job_evaluation_points_by_position_title',
      description: 'equality_job_evaluation_points_by_position_desc',
      route: 'points-by-position',
      key: 'points_by_position',
    }, {
      title: 'equality_job_evaluation_gender_analysis_by_position_title',
      description: 'equality_job_evaluation_gender_analysis_by_position_desc',
      route: 'gender-analysis-by-position',
      key: 'gender_analysis_by_position',
    }, {
      title: 'equality_job_evaluation_analysis_by_gender_for_position_title',
      description: 'equality_job_evaluation_analysis_by_gender_for_position_desc',
      route: 'analysis-by-gender-for-position',
      key: 'analysis_by_gender_for_position',
    }, {
      title: 'equality_job_evaluation_consistency_check_title',
      description: 'equality_job_evaluation_consistency_check_desc',
      route: 'consistency-check',
      key: 'consistency_check',
    }, {
      title: 'equality_job_evaluation_points_salary_suggestion_title',
      description: 'equality_job_evaluation_points_salary_suggestion_desc',
      route: 'points-salary-suggestion',
      key: 'points_salary_suggestion',
    }, {
      title: 'equality_job_evaluation_wages_by_agreements_title',
      description: 'equality_job_evaluation_wages_by_agreements_desc',
      route: 'wages-by-agreements',
      key: 'wages_by_agreements',
    }, {
      title: 'equality_job_evaluation_salary_data_title',
      description: 'equality_job_evaluation_salary_data_desc',
      route: 'salary-data',
      key: 'salary_data',
    }, {
      title: 'equality_job_evaluation_salary_comparison_title',
      description: 'equality_job_evaluation_salary_comparison_desc',
      route: 'salary-comparison',
      key: 'salary_comparison',
    }],
    []
  );

  const handleOnChangeTab = useCallback(
    (tabKey) => history.push(`/equality-plan/${plan.id}/${tabKey}`),
    [history, plan]
  );

  return (
    <div className="EqualityPlan">
      <Row>
        <Col>
          <Navigator
            to={'/equality-plan'}
            current={plan.name}
          />
        </Col>
      </Row>
      { plan.start_date && plan.end_date
        ? <Row>
            <Col>
              <h4 className="EqualityPlan-dates">{`${t.from} ${formatDate(plan.start_date, DATE_ONLY, intl)} ${t.to} ${formatDate(plan.end_date, DATE_ONLY, intl)}`}</h4>
            </Col>
          </Row>
        : null
      }
      <Tabs
        activeKey={match.params.stage || 'plan'}
        onChange={handleOnChangeTab}
      >
        <TabPane tab={t.equality_plan_tab_salary_audit} key={'plan'}>
          <Row gutter={5}>
            <Col span={24}>
              <div className="EqualityPlan-card EqualityPlan-card-info">
                <div className="EqualityPlan-card-title">
                  {t.equality_plan_section_setup_and_execution}
                </div>
              </div>
            </Col>
            {creationSteps.map((step, index) => (
              <EqualityPlanCard
                key={index}
                t={t}
                step={step}
                index={index}
                plan={plan}
                onOpenEqualityPlanStep={onOpenEqualityPlanStep}
              />
            ))}
          </Row>
          <Row gutter={5}>
            <Col span={24}>
              <div className="EqualityPlan-card EqualityPlan-card-info">
                <div className="EqualityPlan-card-title">
                  {t.equality_plan_section_followup}
                </div>
              </div>
            </Col>
            {evaluationSteps.map((step, index) => (
              <EqualityPlanCard
                key={index}
                t={t}
                step={step}
                index={index}
                plan={plan}
                onOpenEqualityPlanStep={onOpenEqualityPlanStep}
              />
            ))}
          </Row>
        </TabPane>
        <TabPane
          tab={t.equality_plan_tab_job_evaluation_system}
          key={'job-evaluation'}
        >
          <Row gutter={5}>
            {jobEvaluationSteps.map((step, index) => (
              <EqualityPlanCard
                key={index}
                t={t}
                step={step}
                index={index}
                plan={plan}
                onOpenEqualityPlanStep={onOpenEqualityPlanStep}
              />
            ))}
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default withRouter(injectIntl(EqualityPlan));
