import React, {
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import './style.less';

import {
  resetOrganization,
} from 'actions/organizationTree';

import {
  Row,
  Col,
} from 'antd';

import FullScreenNoticeLayout from 'components/FullScreenNoticeLayout';
import CustomButton from 'components/CustomButton';

import T from 'components/T';
import config from 'config';

const NotSubscribed = ({
  organization,
  intl,
  history,
  auth,
  resetOrganization,
}) => {
  const t = intl.messages;
  const replace = history.replace;
  const push = history.push;

  const {
    logged_in,
  } = auth;

  useEffect(() => {
    if(!logged_in) {
      return replace('/signin');
    }
  }, [logged_in, replace]);

  useEffect(() => {
    if(!organization.data) {
      return replace('/organizations');
    }
  }, [organization.data, replace]);

  const adminBaseUrl = useMemo(() => {
    return organization?.data?.config?.community?.custom_redirect_urls?.admin || config.ADMIN_APP_URL;
  }, [
    organization,
  ]);

  const handleChange = useCallback(() => window.location.href = adminBaseUrl, [ adminBaseUrl ]);
  const handleLogout = useCallback(() => push('/logout'), [push]);

  return (
    <FullScreenNoticeLayout>
      <h2 className="NotSubscribed__title">{ t.notsubscribed_title }</h2>
      <div className="NotSubscribed__desc">
        <T notsubscribed_desc={{ organization_name: (organization.data || {}).name }} />
      </div>
      <Row
        type="flex"
        gutter={8}
        className="NotSubscribed__buttons"
      >
        <Col>
          <CustomButton
            key="submit"
            type="primary"
            onClick={handleChange}
          >
            { t.notsubscribed_go_to_community }
          </CustomButton>
        </Col>
        <Col>
          <CustomButton
            key="submit"
            type="default"
            onClick={handleLogout}
           >
            { t.notsubscribed_change_account }
          </CustomButton>
        </Col>
      </Row>
    </FullScreenNoticeLayout>
  )
}

const mapStateToProps = ({
  organization,
  auth,
}) => ({
  organization,
  auth,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      resetOrganization,
    })(withRouter(NotSubscribed))
);
