import { useMemo } from "react";
import { useAvailableUnits } from "./useAvailableUnits";
import { useMetricUnits } from "./useMetricUnits";

export const useTableUnits = (schema) => {

    const schemaAvailableUnits = useAvailableUnits((schema || {}).innerSchema);
    const { baseUnitsObj } = useMetricUnits((schema || {}).innerSchema);
    const isHeterogeneous = schema && schema.innerSchema.type === 'tuple';

    const availableUnits = useMemo(() => {

        if (isHeterogeneous){
          // In heterogeneous table, data comes in base unit, which maybe does not exist into the availableUnits
          // In order to do the conversion without errors, we add the base unit to the availableUnits
          return Object.keys(schemaAvailableUnits).reduce((obj, key) => {
            const columnUnits = schemaAvailableUnits[key];
            const oldUnits = [...columnUnits];
            if (columnUnits.find( _unit => _unit.is_base )) {
              obj[key] = oldUnits;
            } else {
              const columnBaseUnit = baseUnitsObj[key];
              obj[key] = [...oldUnits, columnBaseUnit];
            }
            return obj;
          }, {});
        } else {
          const singletonColumnDimension = schema.dimensions.find(
            ({presentation, source}) => presentation === 'column'
              && source === 'singleton'
              && schema.innerSchema.source === 'calculated'
          );

          if (singletonColumnDimension && !schemaAvailableUnits.find( _unit => _unit.is_base )) {
            return [
              ...schemaAvailableUnits,
              baseUnitsObj,
            ];
          }
          return schemaAvailableUnits;
        }
    
    }, [
      schemaAvailableUnits,
      baseUnitsObj,
      isHeterogeneous,
      schema,
    ]);

    return {
        availableUnits
    }
}