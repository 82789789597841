import React, { useCallback } from "react";
import { useIntl } from 'react-intl';
import { Pagination } from 'antd';

import './style.less';

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_TOTAL_ITEMS = 0;

const CustomPagination = ({
  totalItems = DEFAULT_TOTAL_ITEMS,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  pageSize,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  onChangePageSize,
  defaultCurrentPage = DEFAULT_CURRENT_PAGE,
  currentPage,
  onChangePage,
  showTotalItems = false,
  showPageSizeChanger = false,
  ...props
}) => {
  const intl = useIntl();

  const onShowTotalItems = useCallback((total) =>
    (intl.formatMessage({id: `custom_pagination_total_items`}, {totalItems: total})),
    [intl]
  );

  return (
    <div className="CustomPagination">
      <Pagination
        total={totalItems}
        showTotal={showTotalItems ? onShowTotalItems : null}
        defaultPageSize={defaultPageSize}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        onShowSizeChange={onChangePageSize}
        defaultCurrent={defaultCurrentPage}
        current={currentPage}
        onChange={onChangePage}
        showSizeChanger={showPageSizeChanger}
        {...props}
      />
    </div>
  );
};

export default CustomPagination;
