import React, { useCallback, useEffect, useState, useMemo, Fragment } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Button,
  Input,
  Form,
  Card,
  Select,
  Typography,
  Divider,
  Modal,
} from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { fullValidation as validation } from './validation';
import CancelObjectiveForm from 'components/EqualityPlanObjectives/CancelObjectiveForm';
import AddMeasureForm from 'components/EqualityPlanObjectives/AddMeasureForm';
import useForm from 'utils/useForm';
import { Loading } from 'tsComponents/emptyStates/Loading';
import FormItemPreview from 'components/EqualityPlan/FormItemPreview';
import EqualityPlanHeader from 'components/EqualityPlan/Header';
import T from 'components/T';

import './style.less';

const planStage = 'objectives';

const ObjectivesForm = ({
  intl,
  history,
  plan,
  objectives,
  fetchObjectives,
  createObjective,
  updateObjective,
  fetchingObjectives,
  updatePlanProgress,
  actions,
  measures,
  createMeasure,
}) => {
  const t = intl.messages;
  const [activeObjective, setActiveObjective] = useState({});
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [objectiveToCancel, setObjectiveToCancel] = useState({});
  const [showAddMeasureForm, setShowAddMeasureForm] = useState(false);
  const defaults = useMemo(() => ({
    ...activeObjective,
    measures: activeObjective.measures
      ? activeObjective.measures.map(measure => measure.id)
      : [],
  }), [activeObjective]);
  const validateForm = useMemo(() => validation(t), [ t ]);

  useEffect(
    () => {
      fetchObjectives()
    },
    [plan.id, fetchObjectives]
  );

  const submitForm = () => {
    createObjective(values);
    updatePlanProgress(planStage);
    resetForm();
    setActiveObjective({});
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  const objectivesByCategory = useMemo(
    () => ({
      short_term: objectives.filter(objective => objective.type === 'short_term'),
      long_term: objectives.filter(objective => objective.type === 'long_term'),
    }),
    [objectives]
  )

  const handleAddNewObjective = useCallback(
    () => {
      setActiveObjective({new: true});
    },
    [setActiveObjective]
  );

  const openCancelObjectiveModal = useCallback(
    (objective) => {
      setObjectiveToCancel(objective);
      setShowCancelForm(true);
    },
    [setObjectiveToCancel, setShowCancelForm]
  );

  const onCancelObjective = useCallback(
    (cancel_reason) => {
      updateObjective(objectiveToCancel.id, { cancel_reason });
      setShowCancelForm(false);
      setObjectiveToCancel({});
    },
    [objectiveToCancel, updateObjective, setObjectiveToCancel, setShowCancelForm]
  );

  const onAddMeasure = useCallback(
    (data) => {
      createMeasure(data);
      setShowAddMeasureForm(false);
    },
    [createMeasure]
  );

  const handleBackOrSkip = useCallback(
    () => {
      const actionUrl = `/equality-plan/${plan.id}/`;

      if (Object.keys(activeObjective).length > 0) {
        Modal.confirm({
          title: t.equality_plan_exit_without_save,
          content: t.equality_plan_exit_without_save_description,
          okText: t.equality_plan_exit_without_save_ok,
          cancelText: t.equality_plan_exit_without_save_cancel,
          onOk() {
            history.push(actionUrl);
          },
        })
      } else {
        history.push(actionUrl);
      }
    },
    [plan, history, t, activeObjective]
  );

  const getActionProgress = useCallback(
    (action) => {
      const indicatorsProgress = action.indicators.map(indicator => {
        return Math.min(
          Math.round(indicator.current_value / indicator.target_value * 100), 100
        );
      });
      return Math.round(
        indicatorsProgress.reduce( ( p, c ) => p + c, 0 ) / indicatorsProgress.length
      );
    },
    []
  );

  const getObjectiveProgress = useCallback(
    (objective) => {
      const objectivesActions = actions
        .filter(action => action.objectives.includes(objective.id));

      if (objectivesActions.length === 0) {
        return null;
      }
      return Math.round(
        objectivesActions
          .map(action => getActionProgress(action))
          .reduce( ( p, c ) => p + c, 0 )
        / objectivesActions.length
      );
    },
    [actions, getActionProgress]
  );

  if (fetchingObjectives) {
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_objectives}
        isDocumentManager={false}
        handleBackOrSkip={handleBackOrSkip}
      />
      <Row gutter={10}>
        <Col span={24}>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => handleAddNewObjective()}
          >{t.equality_objectives_new}</Button>
        </Col>
        {Object.keys(activeObjective).length > 0 && (
          <Col span={24} className="form-input-wrapper">
            <Card className="form-input-card">
              <Form onFinish={handleSubmit} layout="vertical">
                <Row gutter={10}>
                  <Col span={18}>
                    <Form.Item
                      hasFeedback
                      validateStatus={ showError('measures') ? 'error' : '' }
                      help={ showError('measures') }
                      label={ t.equality_plan_measure }
                      colon={false}
                    >
                      <Select
                        name="measures"
                        mode="multiple"
                        value={ getValue('measures') }
                        placeholder={ t.equality_plan_measure }
                        onChange={(value) => handleChange('measures')(value)}
                        dropdownRender={menu => (
                          <div className='add-selectoption-container'>
                            {menu}
                            <Divider />
                            <div
                              className='add-selectoption'
                              onMouseDown={e => e.preventDefault()}
                              onClick={() => setShowAddMeasureForm(true)}
                            >
                              <PlusOutlined /> {`${t.add} ${t.equality_plan_measure}`}
                            </div>
                          </div>
                        )}
                      >
                        {measures && measures.map(
                          (measure) => {
                            return <Select.Option
                              key={measure.id}
                              value={measure.id}
                            >
                              {measure.name}
                            </Select.Option>
                          }
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      hasFeedback
                      validateStatus={ showError('type') ? 'error' : '' }
                      help={ showError('type') }
                      label={ t.type }
                      colon={false}
                      required
                    >
                      <Select
                        name="type"
                        value={ getValue('type') }
                        placeholder={ t.type }
                        onChange={(value) => handleChange('type')(value)}
                      >
                        <Select.Option value="short_term">{t.short_term}</Select.Option>
                        <Select.Option value="long_term">{t.long_term}</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      hasFeedback
                      validateStatus={ showError('name') ? 'error' : '' }
                      help={ showError('name') }
                      label={ t.equality_objectives_name }
                      colon={false}
                      required
                    >
                      <Input
                        placeholder={ t.equality_objectives_name }
                        name="name"
                        value={ getValue('name') }
                        onChange={handleChangeEvent}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Button
                        type="primary"
                        htmlType='submit'
                      >
                        { t.save }
                    </Button>
                  </Col>
                  <Col span={2}>
                    <Button onClick={() => setActiveObjective({})}>
                      { t.cancel }
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        )}
      </Row>
      <br/>
      <Row>
        {Object.keys(objectivesByCategory).map(key => {
          return <Fragment key={key}>
            <Col span={24}>
              <h3>{t[key]}</h3>
            </Col>
            <Col span={24} className="form-input-wrapper">
              {objectivesByCategory[key].map((objective, index) => {
                const progress = getObjectiveProgress(objective);
                return <FormItemPreview
                  key={index}
                  sequence={index + 1}
                  onDelete={objective.cancel_reason ? null : () => openCancelObjectiveModal(objective)}
                >
                  <div><b>{ t.equality_objective }: {objective.name} {progress !== null && <>(<T equality_actions_progress={{progress}} />)</>}</b></div>
                  { objective.measures.length > 0 &&
                    <div><b>{ t.equality_plan_measures_short }:</b></div>
                  }
                  { objective.measures.map(
                      measure => <div key={measure.id}>{measure.name}</div>
                    )
                  }
                  {objective.cancel_reason && (
                    <div>
                      <Typography.Text type="danger">
                        {`${t.canceled} - ${objective.cancel_reason}`}
                      </Typography.Text>
                    </div>
                  )}
                </FormItemPreview>;
              })}
            </Col>
          </Fragment>;
        })}
      </Row>
      <CancelObjectiveForm
        visible={showCancelForm}
        setVisibility={setShowCancelForm}
        cancelObjective={onCancelObjective} />
      <AddMeasureForm
        visible={showAddMeasureForm}
        setVisibility={setShowAddMeasureForm}
        onSubmit={onAddMeasure} />
    </>
  )
};

export default withRouter(injectIntl(ObjectivesForm));
