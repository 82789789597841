import React from 'react';
import EsgLogo from 'components/EsgLogo';

const DataEsgLogo = ({
  type,
}) => {
  return (
    <div className="KpiSummaryCard__card-type">
      <EsgLogo
        type={type}
      />
    </div>
  );
};

export default DataEsgLogo;

