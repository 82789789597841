import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Modal,
  Form,
  Row,
  Col,
} from 'antd';

import {fullValidation as validation} from 'containers/Materiality/validation';
import { DATE_ONLY, dateToDb, formatDateMoment } from 'utils/date';
import useForm from 'utils/useForm';

import CustomDatepicker from 'components/CustomDatepicker';
import CustomInput from 'components/CustomInput';
import CustomSelect from 'components/CustomSelect';

import './style.less';


const AddAnalysisModal = ({
  intl,
  visible,
  onClose,
  onCreate,
}) => {
  const t = intl.messages;
  const defaults = useMemo(() => ({}), []);
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    const {
      name,
      start_date,
      end_date,
      method,
    } = values;
    resetForm();
    onCreate({ name, start_date, end_date, method });
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );

  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  const handleChangeDate = useCallback((name, value) => {
    handleChange(name)(value && dateToDb(value));
  }, [ handleChange ]);

  const getDisabled = useCallback(
    (type, current) => {
      if (type === 'start_date') {
        const endDate = getValue('end_date');
        return endDate ? current > formatDateMoment(endDate).endOf('day') : false;
      } else {
        const startDate = getValue('start_date');
        return startDate ? current < formatDateMoment(startDate).startOf('day') : false;
      }
    },
    [getValue]
  );

  const methodOptions = useMemo(
    () => {
      return ['maturity_relevancy', 'internal_external'].map(method => ({
        name: t[`analysis_method_${method}`],
        slug: method,
      }));
    },
    [t]
  );

  return (
    <Modal
      className="AddAnalysisModal"
      title={t.analysis_add_new}
      visible={visible}
      okText={t.done}
      cancelText={t.cancel}
      onOk={handleSubmit}
      onCancel={onClose}
    >
      <Form onFinish={handleSubmit} layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('name') ? 'error' : '' }
              help={ showError('name') }
              label={ t.name }
              required
            >
              <CustomInput
                name='name'
                onChange={handleChangeEvent}
                value={getValue('name')}
                placeholder={ t.name }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('method') ? 'error' : '' }
              help={ showError('method') }
              label={ t.analysis_method }
              required
            >
              <CustomSelect
                title={t.analysis_method}
                options={methodOptions}
                onSelect={handleChange('method')}
                selected={getValue('method')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('start_date') ? 'error' : '' }
              label={t.start_date}
              help={ showError('start_date') }
              required={getValue('end_date') ? true : false}
            >
              <CustomDatepicker
                format={DATE_ONLY}
                name="start_date"
                placeholder={t.start_date}
                value={formatDateMoment(getValue('start_date'))}
                onChange={(value) => handleChangeDate('start_date', value)}
                disabledDate={(current) => getDisabled('start_date', current)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              hasFeedback
              validateStatus={ showError('end_date') ? 'error' : '' }
              label={t.end_date}
              help={ showError('end_date') }
              required={getValue('start_date') ? true : false}
            >
              <CustomDatepicker
                format={DATE_ONLY}
                name="end_date"
                placeholder={t.end_date}
                value={formatDateMoment(getValue('end_date'))}
                onChange={(value) => handleChangeDate('end_date', value)}
                disabledDate={(current) => getDisabled('end_date', current)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
};

export default injectIntl(AddAnalysisModal);
