import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Button,
  Card,
  Input,
  Form,
} from 'antd';

import PhoneInput from 'components/PhoneInput';

import {attendeesValidation as validation} from './validation';
import useForm from 'utils/useForm';
import {
} from '../../actions/api';

import './style.less';

const AttendeesForm = ({
  intl,
  attendee,
  index,
  onSave,
  onCancel,
}) => {
  const t = intl.messages;
  const defaults = attendee;
  const validateForm = useMemo(() => validation(intl), [ intl ]);

  const submitForm = () => {
    const {
      name, nationality, id, phone, address, post,
    } = values;
    onSave(
      'attendees', index, { name, nationality, id, phone, address, post, }
    );
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  return (
    <Row>
      <Col span={24} className="form-input-wrapper">
        <Card className="form-input-card">
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('name') ? 'error' : '' }
                help={ showError('name') }
                label={ t.first_and_last_name }
                colon={false}
                required
              >
                <Input
                  placeholder={ t.first_and_last_name }
                  name="name"
                  value={ getValue('name') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('nationality') ? 'error' : '' }
                help={ showError('nationality') }
                label={ t.nationality }
                colon={false}
                required
              >
                <Input
                  placeholder={ t.nationality }
                  name="nationality"
                  value={ getValue('nationality') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('id') ? 'error' : '' }
                help={ showError('id') }
                label={ t.id }
                colon={false}
              >
                <Input
                  placeholder={ t.id }
                  name="id"
                  value={ getValue('id') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('phone') ? 'error' : '' }
                help={ showError('phone') }
                label={ t.phone }
                colon={false}
                required
              >
                <PhoneInput
                  className="AttendeesForm-phone-input"
                  placeholder={ t.phone }
                  value={ getValue('phone') }
                  onChange={handleChange('phone')}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('post') ? 'error' : '' }
                help={ showError('post') }
                label={ t.equality_plan_member_post }
                colon={false}
              >
                <Input
                  placeholder={ t.equality_plan_member_post }
                  name="post"
                  value={ getValue('post') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('address') ? 'error' : '' }
                help={ showError('address') }
                label={ t.address }
                colon={false}
                required
              >
                <Input
                  placeholder={ t.address }
                  name="address"
                  value={ getValue('address') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button
                  type="primary"
                  onClick={handleSubmit}
                >
                  { t.save }
              </Button>
            </Col>
            <Col span={2}>
              <Button onClick={() => onCancel('attendees', index)}>
                { t.cancel }
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
};

export default injectIntl(AttendeesForm);
