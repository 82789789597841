import React from "react";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";

import { ReactComponent as LoadingAnimation } from "assets/animations/loading.svg";

const StyledLoading = styled.div`
  color: #23302e;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  padding: 80px 0;
  text-align: center;
  .animated {
    margin: 0 auto;
    display: block;
    max-width: 300px;
    margin-bottom: 24px;
  }
  .text--bold {
    margin: 0 0 16px;
    font-weight: 500;
  }
  .text--regular {
    margin: 0;
  }
`;
interface LoadingProps {
  /**
   * Text content
   */
  title?: string;
  message?: string;
}

/**
 * Loading animation component with a message
 */
export const Loading = ({ message, title, ...props }: LoadingProps) => {
  const renderedMessage = message ? (
    message
  ) : (
    <FormattedMessage id="emptystates_loading_default_message" />
  );

  return (
    <StyledLoading {...props}>
      <LoadingAnimation />

      <p className="text--bold">
        {title || <FormattedMessage id="emptystates_loading_text" />}
      </p>

      <p className="text--regular">{renderedMessage}</p>
    </StyledLoading>
  );
};
