import React, {
  useCallback,
  useMemo,
} from 'react';
import { Row, Col } from 'antd';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { find } from 'lodash'

import useOrganizations from 'utils/useOrganizations';
import {
  switchCurrentOrganization,
} from 'actions/organizationTree';
import OrganizationSelector from 'components/OrganizationSelector';

import LanguagesDropdown from './LanguagesDropdown';
import { formatMessage } from '../../intl';
import { updateQueryParam } from 'utils/queryParameters';
import './style.less';

const AppHeader = ({
  location,
  match,
  disabledOrganizationTreeSelector = false,
}) => {
  const { search, pathname } = location;
  const dispatch = useDispatch();

  const {
    suborganization,
    organizationTree,
    organization
  } = useOrganizations();

  const {
    data: profile
  } = useSelector(state => state.profile);

  const {
    data: dashboardTypes,
  } = useSelector(state => state.dashboard_list);

  const {
    user_language
  } = profile || {};

  const urlParams = useMemo(() => new URLSearchParams(search), [search]);
  const langQueryValue = useMemo(() => urlParams.get('lang'), [urlParams]);
  const defaultValue = useMemo(() => langQueryValue || suborganization.language, [langQueryValue, suborganization.language]);

  const orgHasUserLang = useMemo(() => organization.languages.includes(user_language), [
    organization.languages, user_language
  ]);

  const onOrganizationSelection = useCallback(
    (org) => dispatch(switchCurrentOrganization(org)),
    [dispatch]
  );

  const onLanguageChange = useCallback((lang) => {
    window.location.assign(`${pathname}?${updateQueryParam('lang', lang)(urlParams)}`);
  }, [pathname, urlParams]);

  const path = location.pathname;
  const product = path.split('/')[1]

  return (
    <section className='AppHeader'>
      <Row
        type='flex'
        justify='space-between'
        align='middle'
      >
        {product === 'data' && (
          <Col>
            <Row style={{ fontSize: "20px", fontWeight: 500, }}>
              {match.params.activeTab
                ? <span style={{ color: match.params.category ? "#919897" : null }}>{formatMessage(`datamanagement_${match.params.activeTab}`)}</span>
                : <span>{formatMessage(`datamanagement_fill`)}</span>
              }
              {match.params.category ? <><span style={{ color: "#919897" }}>&nbsp;/&nbsp;</span><span>{formatMessage(`datamanagement_${match.params.activeTab}_${match.params.category}`)}</span></> : null}
            </Row>
          </Col>
        )}
        {product === 'dashboard' && (
          <Col>
            <Row style={{ fontSize: "20px", fontWeight: 500, }}>
              <span>{find(dashboardTypes, ['slug', match.params.activeTab])?.name}</span>
            </Row>
          </Col>
        )}
        <Col
          className="AppHeader__right"
        >
          <Row
            type='flex'
            justify='end'
            align='middle'
            gutter={8}
          >
            <Col style={{ position: 'initial' }}>
              <OrganizationSelector
                tree={organizationTree}
                current={suborganization}
                onSelect={onOrganizationSelection}
                disabled={disabledOrganizationTreeSelector}
              />
            </Col>
            {
              !orgHasUserLang &&
              <Col span={4}>
                <LanguagesDropdown
                  organization={organization}
                  onChange={onLanguageChange}
                  defaultValue={defaultValue}
                />
              </Col>
            }

          </Row>
        </Col>
      </Row>

    </section>
  );
}

export default injectIntl(
  withRouter(
    AppHeader
  )
);
