import React, { useMemo } from 'react';

import {
  getOrganizationsRootToLeaf,
} from 'utils/organization';
import useOrganizations from 'utils/useOrganizations';

import { Tooltip } from 'antd';


const OrganizationHierarchyLabel = ({
  organization,
}) => {
  const {
    organizationTree,
  } = useOrganizations();
  const orgsRootToLeaf = getOrganizationsRootToLeaf(
    organizationTree,
    organization,
  );

  const orgLabel = useMemo(
    () => {
      if (!orgsRootToLeaf?.length) {
        return '';
      }

      switch (orgsRootToLeaf?.length) {
        case 1:
          return <b>{orgsRootToLeaf[0]}</b>;
        case 2:
          return <span>{orgsRootToLeaf[0]}/<b>{orgsRootToLeaf[1]}</b></span>;
        default:
          return <span>{orgsRootToLeaf[0]}/.../<b>{orgsRootToLeaf[orgsRootToLeaf.length - 1]}</b></span>;
      }
    },
    [
      orgsRootToLeaf,
    ],
  );

  return (
    <Tooltip
      title={orgsRootToLeaf?.join(' >> ')}
      mouseEnterDelay={0.5}
    >
      { orgLabel }
    </Tooltip>
  );
};

export default OrganizationHierarchyLabel;