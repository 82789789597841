import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const TextCard = React.forwardRef(({ parameters, data }, ref) => {
  const intl = useIntl();
  const {
    nodata = intl.messages.no_data,
  } = parameters || {};
  const hasData = typeof data === 'string';

  return (
    <div className="DashboardComponent__card_content">
      {
        !hasData
        ?
          <div
            className="DashboardComponent__nodata"
          >
            { nodata }
          </div>
        :
          <div className="DashboardComponent__textcard">
            <output>{ data }</output>
          </div>
      }
    </div>
  );
});

TextCard.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.string,
};

export default TextCard;

