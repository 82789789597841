import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import A from 'components/A';

import { FILTERS_COMPONENT_TYPES_CATALOG, FILTER_TYPES } from 'hooks/useScreenFilters/constants';
import FiltersPanel from 'components/FiltersPanel';
import SearchFilter from 'components/SearchFilter';
import TableCustomViewPanel from 'components/TableCustomViewPanel';
import AplanetIcon from 'components/AplanetIcon';
import { DEFAULT_TABLE_CUSTOM_VIEWS_SET, TABLE_CUSTOM_VIEW_OPTIONS } from 'hooks/useTableCustomView/constants';
import { DATA_REQUEST_STATUS, DATA_REQUEST_RECURRENCE } from 'utils/dataRequests';
import { useEventTracking } from 'hooks/useEventTracking';

import './style.less';

const REQUEST_PERIODICITY_OPTIONS = ['month', 'quarter', 'semester', 'year'];
const REQUEST_TYPE_OPTIONS = Object.values(DATA_REQUEST_RECURRENCE);
const REQUEST_STATUS_OPTIONS = Object.values(DATA_REQUEST_STATUS);
const VALUE_TYPE_OPTIONS = ['qualitative', 'table', 'quantitative', 'boolean', 'tuple'];
const ACTION_OPTIONS = ['response_in_use'];
const COMMENT_ATTACHMENT_OPTIONS = ['comment', 'attachment'];

const TABLE_CUSTOM_VIEW_PERIODS = [TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.periods_column];
const TABLE_CUSTOM_VIEW_REQUESTED = [TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.requested_by_to_column];
const TABLE_CUSTOM_VIEW_ANSWER = [
  TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.value_column,
  TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.comment_column,
  TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.attachment_column,
];
const TABLE_CUSTOM_VIEW_ACTIONS = [TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.actions_column];

const DataRequestsTableFilters = ({
  intl,
  setAllFilterState,
  filterState,
  resetAllFilters,
  areFiltersSet,
  isKpiNameSearchSet,
  kpiNameSearch,
  onChangeKpiSearch,
  isCustomViewSet,
  selectedCustomView,
  onChangeCustomView,
  resetAllTableCustomViews,
  permissions,
  removeHasToFilterByRequestTo
}) => {
  const eventTracking = useEventTracking();

  const {
    data: organizationDetail,
  } = useSelector(state => state.organization_detail);

  const {
    data: profile
  } = useSelector(state => state.profile);

  // Search
  const [hasToClearKpiSearch, setHasToClearKpiSearch] = useState(false);

  // Filters
  const [periodDateFilterValues, setPeriodDateFilterValues] = useState(filterState.period_date_filter);
  const [periodicityFilterValues, setPeriodicityFilterValues] = useState(filterState.periodicity_filter);
  const [requestTypeFilterValues, setRequestTypeFilterValues] = useState(filterState.request_type_filter);
  const [requestedByFilterValue, setRequestedByFilterValue] = useState(filterState.requested_by_filter);
  const [requestedToFilterValue, setRequestedToFilterValue] = useState(filterState.requested_to_filter);
  const [requestDateFilterValues, setRequestDateFilterValues] = useState(filterState.request_date_filter);
  const [requestStatusFilterValues, setRequestStatusFilterValues] = useState(filterState.request_status_filter);
  const [responseDateFilterValues, setResponseDateFilterValues] = useState(filterState.response_date_filter);
  const [kpiValueTypeFilterValues, setKpiValueTypeFilterValues] = useState(filterState.kpi_value_type_filter);
  const [commentAttachmentFilterValues, setCommentAttachmentFilterValues] = useState(filterState.request_comment_attachment_filter);
  const [requestActionFilterValues, setRequestActionFilterValues] = useState(filterState.request_action_filter);

  // Table custom view
  const [customView, setCustomView] = useState(selectedCustomView);

  const adminMembers = useMemo(() => {
    const patchedProfile = {
      member_name: profile.name,
      member_email: profile.email,
      member_status: profile.status
    };
    return [...(organizationDetail?.users || []), patchedProfile].filter(({member_status}) => member_status !== 'deleted').map(user => ({
      slug: user.member_email,
      name: `${user.member_name} (${user.member_email})`,
    }));
  }, [profile, organizationDetail?.users]);

  const requestedToMembers = useMemo(() => {
    return (organizationDetail?.requested_to_users || []).filter(({member_status}) => member_status !== 'deleted').map(user => ({
      slug: user.member_email,
      name: `${user.member_name} (${user.member_email})`,
    }));
  }, [organizationDetail?.requested_to_users]);

  const filtersConfig = useMemo(() => (
    [
      {
        columnTitle: intl.formatMessage({id: `admin_data_requests_table_filter_column_periods`}),
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.date,
            filterTitle: intl.formatMessage({id: `admin_data_requests_table_filter_title_date`}),
            picker: 'date',
            format: 'YYYY-MM-DD',
            suffixIcon: (<AplanetIcon name="Calendar" />),
            value: periodDateFilterValues,
            onChange: setPeriodDateFilterValues,
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.list,
            filterTitle: intl.formatMessage({id: `admin_data_requests_table_filter_title_periodicity`}),
            listOptions: REQUEST_PERIODICITY_OPTIONS.map(option => ({
              value: option,
              title: intl.formatMessage({id: `admin_data_requests_table_filter_${option}`}),
              label: (<span className={`DataRequests__periodicity ${option}`}>{intl.formatMessage({id: `admin_data_requests_table_filter_${option}`})}</span>)
            })),
            setSelectedOptions: setPeriodicityFilterValues,
            selectedOptions: periodicityFilterValues,
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.list,
            filterTitle: intl.formatMessage({id: `admin_data_requests_table_filter_title_request_type`}),
            listOptions: REQUEST_TYPE_OPTIONS.map(option => ({
              value: option,
              title: intl.formatMessage({id: `admin_data_requests_table_filter_${option}`}),
              label: (<span className={`DataRequests__type ${option}`}>{intl.formatMessage({id: `admin_data_requests_table_filter_${option}`})}</span>)
            })),
            setSelectedOptions: setRequestTypeFilterValues,
            selectedOptions: requestTypeFilterValues,
          },
        ]
      },
      {
        columnTitle: intl.formatMessage({id: `admin_data_requests_table_filter_column_requested_by_to`}),
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.multiSelect,
            filterTitle: intl.formatMessage({id: `admin_data_requests_table_filter_title_requested_by`}),
            title: intl.formatMessage({id: `admin_data_requests_table_filter_title_requested_by`}),
            placeholder: intl.formatMessage({id: `admin_data_requests_table_filter_search_user_placeholder`}),
            selected: requestedByFilterValue,
            onSelect: setRequestedByFilterValue,
            options : adminMembers.sort((a,b) => {
              const A = a.name.toUpperCase();
              const B = b.name.toUpperCase();
              return (A < B) ? -1 : (A > B) ? 1 : 0;
            })
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.multiSelect,
            filterTitle: intl.formatMessage({id: `admin_data_requests_table_filter_title_requested_to`}),
            title: intl.formatMessage({id: `admin_data_requests_table_filter_title_requested_to`}),
            placeholder: intl.formatMessage({id: `admin_data_requests_table_filter_search_user_placeholder`}),
            selected: requestedToFilterValue,
            onSelect: setRequestedToFilterValue,
            disabled: !permissions.can_request_kpi && permissions.can_write_kpi,
            options: requestedToMembers.sort((a,b) => {
              const A = a.name.toUpperCase();
              const B = b.name.toUpperCase();
              return (A < B) ? -1 : (A > B) ? 1 : 0;
            })
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.date,
            filterTitle: intl.formatMessage({id: `admin_data_requests_table_filter_title_requested_date`}),
            picker: 'date',
            format: 'YYYY-MM-DD',
            suffixIcon: (<AplanetIcon name="Calendar" />),
            value: requestDateFilterValues,
            onChange: setRequestDateFilterValues,
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.date,
            filterTitle: intl.formatMessage({id: `admin_data_requests_table_filter_title_response_date`}),
            picker: 'date',
            format: 'YYYY-MM-DD',
            suffixIcon: (<AplanetIcon name="Calendar" />),
            value: responseDateFilterValues,
            onChange: setResponseDateFilterValues,
          },
        ]
      },
      {
        columnTitle: intl.formatMessage({id: `admin_data_requests_table_filter_column_status`}),
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.list,
            filterTitle: intl.formatMessage({id: `admin_data_requests_table_filter_title_status`}),
            listOptions: REQUEST_STATUS_OPTIONS.map(option => ({
              value: option,
              title: intl.formatMessage({id: `admin_data_requests_table_filter_${option}`}),
              label: (<span className={`DataRequests__request-status ${option}`}>{intl.formatMessage({id: `admin_data_requests_table_filter_${option}`})}</span>)
            })),
            setSelectedOptions: setRequestStatusFilterValues,
            selectedOptions: requestStatusFilterValues,
          },
        ]
      },
      {
        columnTitle: intl.formatMessage({id: `admin_data_requests_table_filter_column_indicators_properties`}),
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.checkbox,
            filterTitle: intl.formatMessage({id: `admin_data_requests_table_filter_title_value_type`}),
            listOptions: VALUE_TYPE_OPTIONS.map(option => ({
              value: option,
              label: intl.formatMessage({id: `admin_data_requests_table_filter_${option}`})
            })),
            setSelectedOptions: setKpiValueTypeFilterValues,
            selectedOptions: kpiValueTypeFilterValues,
            hideDivider: true,
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.heading,
            columnTitle: intl.formatMessage({id: `admin_data_requests_table_filter_column_comment_and_attachments`}),
          },
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.checkbox,
            filterTitle: intl.formatMessage({id: `admin_data_requests_table_filter_title_comment_attachment`}),
            listOptions: COMMENT_ATTACHMENT_OPTIONS.map(option => ({
              value: option,
              label: intl.formatMessage({id: `admin_data_requests_table_filter_${option}`})
            })),
            setSelectedOptions: setCommentAttachmentFilterValues,
            selectedOptions: commentAttachmentFilterValues,
          },
        ]
      },
      {
        columnTitle: intl.formatMessage({id: `admin_data_requests_table_filter_column_action`}),
        filters: [
          {
            filterType: FILTERS_COMPONENT_TYPES_CATALOG.checkbox,
            filterTitle: intl.formatMessage({id: `admin_data_requests_table_filter_title_action`}),
            listOptions: ACTION_OPTIONS.map(option => ({
              value: option,
              label: intl.formatMessage({id: `admin_data_requests_table_filter_${option}`})
            })),
            setSelectedOptions: setRequestActionFilterValues,
            selectedOptions: requestActionFilterValues,
          },
        ]
      },
    ]
  ), [
    intl,
    adminMembers,
    requestedToMembers,
    commentAttachmentFilterValues,
    kpiValueTypeFilterValues,
    periodDateFilterValues,
    periodicityFilterValues,
    requestActionFilterValues,
    requestDateFilterValues,
    requestStatusFilterValues,
    requestTypeFilterValues,
    requestedByFilterValue,
    requestedToFilterValue,
    responseDateFilterValues,
    permissions,
  ]);

  const customViewConfig = useMemo(() => {
    return [
      {
        columnTitle: intl.formatMessage({id: `admin_data_requests_table_custom_view_column_periods`}),
        options: TABLE_CUSTOM_VIEW_PERIODS.map(option => ({
          value: option,
          label: intl.formatMessage({id: `admin_data_requests_table_custom_view_${option}`}),
          disabled: true,
        })),
      },
      {
        columnTitle: intl.formatMessage({id: `admin_data_requests_table_custom_view_column_requested_by_to`}),
        options: TABLE_CUSTOM_VIEW_REQUESTED.map(option => ({
          value: option,
          label: intl.formatMessage({id: `admin_data_requests_table_custom_view_${option}`}),
        })),
      },
      {
        columnTitle: intl.formatMessage({id: `admin_data_requests_table_custom_view_column_answer`}),
        options: TABLE_CUSTOM_VIEW_ANSWER.map(option => ({
          value: option,
          label: intl.formatMessage({id: `admin_data_requests_table_custom_view_${option}`}),
        })),
      },
      {
        columnTitle: intl.formatMessage({id: `admin_data_requests_table_custom_view_column_actions`}),
        options: TABLE_CUSTOM_VIEW_ACTIONS.map(option => ({
          value: option,
          label: intl.formatMessage({id: `admin_data_requests_table_custom_view_${option}`}),
        })),
      },
    ];
  }, [
    intl
  ]);

  const onSetFilters = useCallback(() => {
    removeHasToFilterByRequestTo();
    setAllFilterState({
      [FILTER_TYPES.period_date_filter]: periodDateFilterValues,
      [FILTER_TYPES.periodicity_filter]: periodicityFilterValues,
      [FILTER_TYPES.request_type_filter]: requestTypeFilterValues,
      [FILTER_TYPES.requested_by_filter]: requestedByFilterValue,
      [FILTER_TYPES.requested_to_filter]: requestedToFilterValue,
      [FILTER_TYPES.request_date_filter]: requestDateFilterValues,
      [FILTER_TYPES.request_status_filter]: requestStatusFilterValues,
      [FILTER_TYPES.response_date_filter]: responseDateFilterValues,
      [FILTER_TYPES.kpi_value_type_filter]: kpiValueTypeFilterValues,
      [FILTER_TYPES.request_comment_attachment_filter]: commentAttachmentFilterValues,
      [FILTER_TYPES.request_action_filter]: requestActionFilterValues,
      [FILTER_TYPES.text_filter]: kpiNameSearch,
    });
  }, [
    setAllFilterState,
    periodDateFilterValues,
    periodicityFilterValues, 
    requestTypeFilterValues,
    requestedByFilterValue,
    requestedToFilterValue,
    requestDateFilterValues,
    requestStatusFilterValues,
    responseDateFilterValues,
    kpiValueTypeFilterValues,
    commentAttachmentFilterValues,
    requestActionFilterValues,
    kpiNameSearch,
    removeHasToFilterByRequestTo,
  ]);

  const onCancelFilter = useCallback(() => {
    setPeriodDateFilterValues(filterState.period_date_filter);
    setPeriodicityFilterValues(filterState.periodicity_filter);
    setRequestTypeFilterValues(filterState.request_type_filter);
    setRequestedByFilterValue(filterState.requested_by_filter);
    setRequestedToFilterValue(filterState.requested_to_filter);
    setRequestDateFilterValues(filterState.request_date_filter);
    setRequestStatusFilterValues(filterState.request_status_filter);
    setResponseDateFilterValues(filterState.response_date_filter);
    setKpiValueTypeFilterValues(filterState.kpi_value_type_filter);
    setCommentAttachmentFilterValues(filterState.request_comment_attachment_filter);
    setRequestActionFilterValues(filterState.request_action_filter);
  }, [
    filterState.period_date_filter,
    filterState.periodicity_filter,
    filterState.request_type_filter,
    filterState.requested_by_filter,
    filterState.requested_to_filter,
    filterState.request_date_filter,
    filterState.request_status_filter,
    filterState.response_date_filter,
    filterState.kpi_value_type_filter,
    filterState.request_comment_attachment_filter,
    filterState.request_action_filter,
  ]);

  const onSetCustomView = useCallback(() => {
    onChangeCustomView(customView);
    setCustomView(customView);
    eventTracking.capture('dataRequestTab.customViewSelection', {
      periods: customView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.periods_column),
      requested_by_to: customView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.requested_by_to_column),
      answer_value: customView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.value_column),
      answer_comment: customView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.comment_column),
      answer_attachment: customView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.attachment_column),
      actions: customView.includes(TABLE_CUSTOM_VIEW_OPTIONS.admin_data_requests.actions_column),
    });
  }, [
    customView,
    onChangeCustomView,
    eventTracking
  ]);

  const onCancelCustomView = useCallback(() => {
    onChangeCustomView(selectedCustomView);
    setCustomView(selectedCustomView);
  }, [
    onChangeCustomView,
    selectedCustomView
  ]);

  const onClearSearchAndFilters = useCallback(() => {
    setPeriodDateFilterValues([]);
    setPeriodicityFilterValues([]);
    setRequestTypeFilterValues([]);
    setRequestedByFilterValue('');
    setRequestedToFilterValue('');
    setRequestDateFilterValues([]);
    setRequestStatusFilterValues([]);
    setResponseDateFilterValues([]);
    setKpiValueTypeFilterValues([]);
    setCommentAttachmentFilterValues([]);
    setRequestActionFilterValues([]);
    setHasToClearKpiSearch(true);
    removeHasToFilterByRequestTo();
    resetAllFilters();
  }, [
    resetAllFilters,
    removeHasToFilterByRequestTo,
  ]);

  const onResetCustomView = useCallback(() => {
    setCustomView(DEFAULT_TABLE_CUSTOM_VIEWS_SET.admin_data_requests);
    resetAllTableCustomViews();
  }, [resetAllTableCustomViews]);

  return (
    <div className="DataRequests__table-filters">
      <FiltersPanel
        areFiltersSet={areFiltersSet}
        onCancelFilter={onCancelFilter}
        onSetFilters={onSetFilters}
        filtersConfig={filtersConfig}
      />
      <SearchFilter
        textFilter={kpiNameSearch}
        onSetSearch={onChangeKpiSearch}
        hasToClearSearch={hasToClearKpiSearch}
        setHasToClearKpiSearch={setHasToClearKpiSearch}
        defaultOpen={isKpiNameSearchSet}
        placeholder={intl.formatMessage({id: `admin_data_requests_table_search_kpi_placeholder`})}
        autoFocus
      />
      <TableCustomViewPanel
        isCustomViewSet={isCustomViewSet}
        onCancelCustomView={onCancelCustomView}
        onSetCustomView={onSetCustomView}
        customViewConfig={customViewConfig}
        customView={customView}
        onChangeCustomView={setCustomView}
      />
      <div className='DataRequests__remove-filters-wrapper'>
        <A
          className='DataRequests__remove-filters'
          onClick={onClearSearchAndFilters}
          disabled={!areFiltersSet && !isKpiNameSearchSet}
        >
          {intl.formatMessage({id: 'admin_data_requests_table_filters_delete_all'})}
        </A>
      </div>
      <div className='DataRequests__reset-view-wrapper'>
        <A
          className='DataRequests__reset-view'
          onClick={onResetCustomView}
          disabled={!isCustomViewSet}
        >
          {intl.formatMessage({id: 'admin_data_requests_table_custom_view_reset'})}
        </A>
      </div>
    </div>
  );
}

DataRequestsTableFilters.propTypes = {
  setAllFilterState: PropTypes.func.isRequired,
  filterState: PropTypes.object.isRequired,
  resetAllFilters: PropTypes.func.isRequired,
  areFiltersSet: PropTypes.bool,
  isKpiNameSearchSet: PropTypes.bool,
  kpiNameSearch: PropTypes.string,
  onChangeKpiSearch: PropTypes.func.isRequired,
  isCustomViewSet: PropTypes.bool,
  selectedCustomView: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeCustomView: PropTypes.func.isRequired,
  resetAllTableCustomViews: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  removeHasToFilterByRequestTo: PropTypes.func.isRequired,
};

export default injectIntl(DataRequestsTableFilters);
