import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import { uniq } from 'lodash'

import {
  Row,
  Col,
  Button,
  Dropdown,
  Menu
} from 'antd';

import { formatDateOnly } from 'utils/date';
import useOrganizations from 'utils/useOrganizations';

const PeriodSwitcher = ({
  intl,
  period = {},
  all_periods: allPeriods = [],
  changePeriod,
  loading,
  showLabel = true,
}) => {
  const {
    suborganization,
    organization,
  } = useOrganizations();

  const { config = {} } = suborganization;
  const { hidden_periods: hiddenPeriods = [] } = organization?.config || {};

  const all_periods = useMemo(() => (
    allPeriods.filter(period => !hiddenPeriods.includes(period.label))
  ), [
    allPeriods,
    hiddenPeriods,
  ]);

  const periodIndex = useMemo(() => {
    return all_periods.findIndex(p => (
      p.label === period.label &&
      p.start === period.start &&
      p.end === period.end
    ));
  }, [period, all_periods]);

  const menu = useMemo(() => {
    const years = uniq(all_periods.map(period => period.start.split('-')[0])).reverse()
    return (
      <Menu onClick={({ key }) => changePeriod(key)} className='kpi-period-switch-dropdown'>
        {years.map(year => (
          <Menu.ItemGroup title={year} key={year}>
            {all_periods.filter(p => p.start.split('-')[0] === year).reverse().map(p => (
              <Menu.Item key={p.label} className={p.label === period.label ? 'selected' : ''}>
                {!config.expand_period_label && <b>{p.label}: </b>}
                <span>
                  {formatDateOnly(p.start, intl.formatMessage({id: 'datadetail_period_date_format'}), intl)}
                  {' - '}
                  {formatDateOnly(p.end, intl.formatMessage({id: 'datadetail_period_date_format'}), intl)}
                </span>
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        ))}
      </Menu>
    )
  }, [all_periods, changePeriod, config.expand_period_label, intl, period.label])

  return (
    <Col>
      <Row
        type="flex"
        justify="center"
        className="KpiDetail__periodrow"
      >
        { showLabel &&
          <Col className="KpiDetail__dropdownlabel">
            { intl.formatMessage({id: 'datadetail_label_period'}) }
          </Col>
        }
        <Col>
          <Button
            disabled={loading || periodIndex <= 0}
            loading={loading}
            onClick={() => changePeriod(all_periods[periodIndex - 1].label)}
            className="KpiDetail__periodbuttonback"
          >
            {!loading && <img src='/images/assets/chevron-big.svg' alt='' />}
          </Button>
        </Col>
        <Col
          className="KpiDetail__periodlabel"
        >
          <Dropdown trigger={['click']} overlay={menu} className={"KpiDetail__perioddropdown"} disabled={loading}>
            <Button>
              <div className="KpiDetail__periodlabels">
                {
                  config.expand_period_label ? null :
                    <span className='KpiDetail__periodtitle'>{period.label}</span>
                }
                <span className='KpiDetail__periodperiod'>
                  {formatDateOnly(period.start, intl.formatMessage({id: 'datadetail_period_date_format'}), intl)}
                  {' - '}
                  {formatDateOnly(period.end, intl.formatMessage({id: 'datadetail_period_date_format'}), intl)}
                </span>
              </div>
              <Button
                disabled={loading}
                loading={loading}
                className="KpiDetail__periodbutton"
              >
                {!loading && <img src='/images/assets/chevron-big.svg' alt='' style={{ transform: 'rotate(270deg)' }} />}
              </Button>
            </Button>
          </Dropdown>
        </Col>
        <Col>
          <Button
            disabled={loading || periodIndex < 0 || (periodIndex + 1) >= all_periods.length}
            loading={loading}
            onClick={() => changePeriod(all_periods[periodIndex + 1].label)}
            className="KpiDetail__periodbuttonfwd"
          >
            {!loading && <img src='/images/assets/chevron-big.svg' style={{ transform: 'rotate(180deg)' }} alt='' />}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default injectIntl(PeriodSwitcher);
