export const FORM_KEY_SELECTED_ROW_KEYS = 'selectedRowKeys';
export const FORM_KEY_SELECTED_ROWS = 'selectedRows';
export const FORM_KEY_PERIODSNUMBER = 'periodsNumber'; 
export const FORM_KEY_PERIODICITY = 'periodicity'; 
export const FORM_KEY_CARD_NAME = 'cardName';
export const FORM_KEY_SELECTED_GRAPH = 'selectedGraph';
export const FORM_KEY_SELECTED_UNIT = 'selectedUnit';
export const FORM_KEY_GRAPH_ADDRESS = 'graphAddress';
export const FORM_KEY_SELECTED_NODE_LEVEL = 'selectedNodeLevel';
export const FORM_KEY_SHOW_DATA_LABELS = 'showDataLabels';
export const FORM_KEY_SHOW_DATA_TARGET = 'showDataTarget';
export const FORM_KEY_CARD_ICON = 'cardIcon';
