import React, { useState } from "react";
import "./style.less";

import { Row, Col, Button } from "antd";
import { /*PlusOutlined,*/ DeleteOutlined, EditOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import CustomInput from 'components/CustomInput';
import CustomButton from "components/CustomButton";
import CustomTag from "components/CustomTag";
import AplanetIcon from "components/AplanetIcon";

const Visor = ({
  intl,
  completeAndPartialSelection,
  partial,
  tags,
  areThereChanges,
  onNewTag,
  onChange,
  onGoToManager,
  onApply,
}) => {
  const [newTag, setNewTag] = useState("");
  const [inputVisible, setInputVisible] = useState(false);

  const getColor = (tag) => {
    if (partial.includes(tag)) return "grey";
    if (completeAndPartialSelection.includes(tag)) return "green";
    return "white";
  };

  const getScore = (tag) => {
    if (partial.includes(tag)) return 0.5;
    if (completeAndPartialSelection.includes(tag)) return 0;

    return 1
  }

  const orderTags = (tag1, tag2) => {
    const tag1score = getScore(tag1);
    const tag2score = getScore(tag2);

    if(tag1score === tag2score) return tag1.localeCompare(tag2);
    return tag1.localeCompare(tag2);
    
  }

  return (
    <div className="CustomMultiTagSelector">
      <div className="CustomMultiTagSelector-values">
        {tags.sort(orderTags).map((tag) => (
          <CustomTag
            className="tag"
            onClick={() => onChange(tag, !completeAndPartialSelection.includes(tag))}
            key={tag}
            data-testid={`tag_${tag}`}
            colorclass={getColor(tag)}
            name={tag}
          />
        ))}
      </div>
      {!inputVisible && (
          <Button ghost type='primary' className="AddTag" onClick={() => setInputVisible(true)} icon={<AplanetIcon name='Add'/>}>
            <p>{intl.formatMessage({ id: "custom_multi_tag_selector_new_tag" })}</p>
          </Button>
        )}
      {inputVisible && (
        <div>
          <div className="tag-title">
            <span>{intl.formatMessage({ id: "custom_multi_tag_selector_new_tag" })}</span>
            <span> *</span>
          </div>
          <div className="InputContainer">
            <CustomInput
              type="text"
              size="large"
              className="tag-input"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
            />
            <Button type="link" icon={<CloseOutlined />} onClick={() => setInputVisible(false)}/>
            <Button 
              type="link" 
              icon={<SaveOutlined />} 
              onClick={() => {
                onNewTag(newTag);
                setNewTag("");
              }}/>
          </div>
          
        </div>
        
      )}
      <div className="CustomMultiTagSelector-buttons">
        <Row gutter={5} justify="end" style={{ width: "100%" }}>
          <Col>
            <CustomButton className="ManageTags" danger icon={<DeleteOutlined />} key="submit" onClick={onGoToManager} disabled={areThereChanges}>
              {intl.formatMessage({
                id: "custom_multi_tag_selector_manage_tags",
              })}
            </CustomButton>
          </Col>
          {onApply ? (
            <Col>
              <CustomButton className="ApplyTags" icon={<EditOutlined />} key="submit" onClick={onApply} disabled={!areThereChanges}>
                {intl.formatMessage({ id: "custom_multi_tag_selector_ok" })}
              </CustomButton>
            </Col>
          )
            : ''
          }
        </Row>
      </div>
    </div>
  );
};

export default Visor;
