import React, {
  useMemo,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { injectIntl } from 'react-intl';
import { Col, Form, Row, Select, Tooltip } from 'antd';
import CustomInput from 'components/CustomInput';
import CustomRadio from 'components/CustomRadio';


import { useFeatureList } from 'components/FeatureSwitch';
import { DashboardCardFormContext } from 'containers/DashboardCardForm';
import CardFormStepWrapper from '../CardFormStepWrapper';

import '../style.less'
import { 
      FORM_KEY_CARD_NAME, 
      FORM_KEY_SELECTED_GRAPH,
      FORM_KEY_SHOW_DATA_LABELS,
      FORM_KEY_CARD_ICON,
      FORM_KEY_SHOW_DATA_TARGET } from 'containers/DashboardCardForm/constants';
import IconSelector from 'components/IconSelector';

const { Option } = Select;

const SelectGraph = ({
  intl
}) => {

  const { suborganizationFeatures: featureList } = useFeatureList();
  const { values, handleChange } = useContext(DashboardCardFormContext);
  const countOccurrences = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

  const isOfType = useCallback((type, kpi) => {

    const selectedComponent = kpi.schema.type === 'table' && kpi.schema.innerSchema.type === 'tuple' ? (values.graphAddress || [])[values.graphAddress.length-1] : null;

    return kpi.schema.type === type 
    || (kpi.schema.type === 'table' && kpi.schema.innerSchema.type === type) 
    || (kpi.schema.type === 'table' && kpi.schema.innerSchema.type === 'tuple' && (kpi.schema.innerSchema?.components || []).some( component => component.name === selectedComponent && component.type === type))

  }, [values?.graphAddress]);

  const isOneOfType = useCallback(type => 
    values.selectedRows.length === 1 && isOfType(type, values.selectedRows[0])
  , [values.selectedRows, isOfType]);

  const oneQuantitativeKpis = useMemo(() => isOneOfType('quantitative'), [isOneOfType]);
  const moreThanOneQuantitativeKpis = useMemo(() => 
    values.selectedRows.length > 1 && values.selectedRows.every(kpi => isOfType('quantitative', kpi))
  , [values.selectedRows, isOfType]);

  const oneDimensionTable = useMemo(() => values.selectedRows[0]?.schema.type === 'table' && countOccurrences(values.graphAddress, '*') === 1, [values.graphAddress, values.selectedRows]);
  const twoDimensionTable = useMemo(() => values.selectedRows[0]?.schema.type === 'table' && countOccurrences(values.graphAddress, '*') === 2, [values.graphAddress, values.selectedRows]);

  const getIsDataTargetDisabled = ( selectedGraph ) => {
    return selectedGraph && !['vertical-bars', 'horizontal-bars', 'smooth-lines', 'sharp-lines', 'number'].includes(selectedGraph);
  }

  const graphs = useMemo(() => [
    {
      value: 'vertical-bars',
      label: intl.formatMessage({ id: 'select_graph_vertical_bars' }),
      rules: (oneQuantitativeKpis && (values.periodsNumber > 1 || values.selectedNodeLevel === 2) ) || moreThanOneQuantitativeKpis || oneDimensionTable || (twoDimensionTable && values.periodsNumber === 1),
      size: 'medium',
    },
    {
      value: 'horizontal-bars',
      label: intl.formatMessage({ id: 'select_graph_horizontal_bars' }),
      rules: (oneQuantitativeKpis && (values.periodsNumber > 1 || values.selectedNodeLevel === 2) ) || moreThanOneQuantitativeKpis || oneDimensionTable || (twoDimensionTable && values.periodsNumber === 1),
      size: 'medium',
    },
    {
      value: 'vertical-stacks',
      label: intl.formatMessage({ id: 'select_graph_vertical_stacks' }),
      rules: (oneQuantitativeKpis && values.periodsNumber > 1 ) || moreThanOneQuantitativeKpis || oneDimensionTable || (twoDimensionTable && values.periodsNumber === 1),
      size: 'medium',
    },
    {
      value: 'horizontal-stacks',
      label: intl.formatMessage({ id: 'select_graph_horizontal_stacks' }),
      rules: (oneQuantitativeKpis && values.periodsNumber > 1 ) || moreThanOneQuantitativeKpis || oneDimensionTable || (twoDimensionTable && values.periodsNumber === 1),
      size: 'medium',
    },
    {
      value: 'pie',
      label: intl.formatMessage({ id: 'select_graph_pie' }),
      rules: (moreThanOneQuantitativeKpis || oneDimensionTable) && values.periodsNumber === 1,
      size: 'medium',
    },
    {
      value: 'donut',
      label: intl.formatMessage({ id: 'select_graph_ring' }),
      rules: (moreThanOneQuantitativeKpis || oneDimensionTable) && values.periodsNumber === 1,
      size: 'medium',
    },
    {
      value: 'spider',
      label: intl.formatMessage({ id: 'select_graph_spider' }),
      rules: (moreThanOneQuantitativeKpis || oneDimensionTable) && values.periodsNumber === 1,
      size: 'medium',
    },
    {
      value: 'smooth-lines',
      label: intl.formatMessage({ id: 'select_graph_smooth_lines' }),
      rules: (oneQuantitativeKpis || moreThanOneQuantitativeKpis) && values.periodsNumber > 1,
      size: 'medium',
    },
    {
      value: 'sharp-lines',
      label: intl.formatMessage({ id: 'select_graph_sharp_lines' }),
      rules: (oneQuantitativeKpis || moreThanOneQuantitativeKpis) && values.periodsNumber > 1,
      size: 'medium',
    },
    {
      value: 'number',
      label: intl.formatMessage({ id: 'select_graph_number' }),
      rules: countOccurrences(values.graphAddress, '*') === 0 && (values.periodsNumber === 1 || values.periodsNumber === 2) && oneQuantitativeKpis && (values.selectedNodeLevel === 1),
      size: 'small',
    },
    {
      value: 'boolean',
      label: intl.formatMessage({ id: 'select_graph_boolean' }),
      rules: countOccurrences(values.graphAddress, '*') === 0 && isOneOfType('boolean'),
      size: 'small',
    },
    {
      value: 'text',
      label: intl.formatMessage({ id: 'select_graph_text' }),
      rules: countOccurrences(values.graphAddress, '*') === 0 && (values.periodsNumber === 1 || values.periodsNumber === 2) && isOneOfType('qualitative'),
      size: 'small',
    },
  ],
  [values.graphAddress, isOneOfType, moreThanOneQuantitativeKpis, oneDimensionTable, oneQuantitativeKpis, values.periodsNumber, values.selectedNodeLevel, twoDimensionTable, intl])

  const graphOptions = useMemo(() => graphs.filter(graph => graph.rules),
  [graphs]
  )

  const updateSelectedGraph = useCallback(( selectedGraph ) => {

    handleChange(FORM_KEY_SELECTED_GRAPH)( selectedGraph );

    if (['number', 'boolean', 'text'].includes(selectedGraph)) {
      handleChange(FORM_KEY_SHOW_DATA_LABELS)(0); 
    }

    if (getIsDataTargetDisabled(selectedGraph)) {
      handleChange(FORM_KEY_SHOW_DATA_TARGET)(0);
    }

  }, [handleChange]);

  const onSelectIcon = (icon) => {

    handleChange(FORM_KEY_CARD_ICON)( icon );

  }

  useEffect(() => {
    
    const options = graphOptions.map( option => option.value);
    if (values.selectedGraph && !options.includes(values.selectedGraph)){
      updateSelectedGraph('');
    }

  }, [graphOptions, updateSelectedGraph, values.selectedGraph])
  

  return (
    <CardFormStepWrapper className="DashboardCardForm__graphSelection">
      <Form>
        <div className='DashboardCardForm__stepTitle'>
            { intl.formatMessage({ id: 'dashboard_card_form_select_graph' }) }
        </div>

        <Row>
          <Col span={12}>
            <Form.Item label={intl.formatMessage({ id: 'dashboard_card_form_select_icon' })}>
              <IconSelector 
                label={null}
                defaultIcon={ null }
                selectedIcon={ values.cardIcon }
                onSelectIcon={ onSelectIcon }
              />
            </Form.Item>
          </Col> 
        </Row>

        <Form.Item label={intl.formatMessage({ id: 'select_graph_card_name' })}>
          <CustomInput 
            maxLength={200}
            value={values.cardName} 
            onChange={e => {
              handleChange(FORM_KEY_CARD_NAME)(e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item label={intl.formatMessage({ id: 'select_graph_label' })}>
          <Select 
            defaultValue={values.selectedGraph || ''} 
            value={values.selectedGraph} 
            onChange={value => { 
              updateSelectedGraph( value );
            }}>
            <Option value={''}>{intl.formatMessage({ id: 'select_graph_default_option' })}</Option>
            {graphOptions.map(option => <Option key={option.label} value={option.value}>{option.label}</Option>)}
          </Select>
        </Form.Item>

        {
          (!['number', 'boolean', 'text'].includes(values.selectedGraph)) && 
        <Form.Item label={intl.formatMessage({ id: 'dashboard_card_form_value_tags_label' })} >
          <div className="DashboardCardForm__labelHelp">
            {intl.formatMessage({ id: 'dashboard_card_form_value_tags_help' })}
          </div>
          <CustomRadio.Group 
              disabled={ values.selectedGraph && ['number', 'boolean', 'text'].includes(values.selectedGraph) }
              buttonStyle="solid"
              onChange={ (e) => handleChange(FORM_KEY_SHOW_DATA_LABELS)(e.target.value) }
              value={ values.showDataLabels }
            >
              <CustomRadio.Button key="1" value={ true }>{ intl.formatMessage({ id: 'dashboard_card_form_value_tags_yes' }) }</CustomRadio.Button>
              <CustomRadio.Button key="0" value={ false }>{ intl.formatMessage({ id: 'dashboard_card_form_value_tags_no' }) }</CustomRadio.Button>
            </CustomRadio.Group>
        </Form.Item>
        }

        {
          featureList.has('targets') && 
          <Form.Item label={intl.formatMessage({ id: 'dashboard_card_form_target_label' })} >
            <div className="DashboardCardForm__labelHelp">
              {intl.formatMessage({ id: 'dashboard_card_form_target_help' })}
            </div>
            <CustomRadio.Group 
                disabled={ getIsDataTargetDisabled(values.selectedGraph) }
                buttonStyle="solid"
                onChange={ (e) => handleChange(FORM_KEY_SHOW_DATA_TARGET)(e.target.value) }
                value={ values.showDataTarget }
              >
                <Tooltip 
                  title={ getIsDataTargetDisabled(values.selectedGraph) ? intl.formatMessage({ id: 'dashboard_card_form_target_disabled_tooltip' }) : null } 
                  overlayClassName="CustomButton__tooltip"
                >
                  <CustomRadio.Button key="1" value={ true }>{ intl.formatMessage({ id: 'dashboard_card_form_target_value_yes' }) }</CustomRadio.Button>
                  <CustomRadio.Button key="0" value={ false }>{ intl.formatMessage({ id: 'dashboard_card_form_target_value_no' }) }</CustomRadio.Button>
                </Tooltip>
              </CustomRadio.Group>
          </Form.Item>
        }
        
      </Form>
    </CardFormStepWrapper>
  )
};

export default injectIntl(SelectGraph);
