import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import Form from './Form';
import { Loading } from 'tsComponents/emptyStates/Loading';
import UploadButton from 'containers/UploadButton';
import useOrganizations from 'utils/useOrganizations';
import {
  getPointsRangeForPositions,
  getPositionPoints,
  getSalaryForPoints,
} from 'utils/equality';
import { createExcel } from 'utils/excel';

import {
  requestEqualityPlanSalaries,
  createEqualityPlanSalary,
  updateEqualityPlanSalary,
  deleteEqualityPlanSalary,
  requestEqualityPlanPositions,
  requestEqualityPlanFactors,
  requestEqualityPlanProfessionalGroups,
  getEqualityPlanSalaryUploadUrl,
} from 'actions/api';

import CustomTable from 'components/CustomTable';
import CustomButton from 'components/CustomButton';
import {
  Row,
  Col,
  Space,
  Alert,
  Tooltip,
  Popconfirm,
  notification,
} from 'antd';
import {
  PlusOutlined,
  UploadOutlined,
  DownloadOutlined,
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';


const FactorDefinition = ({
  intl,
  history,
  plan,
  updatePlanProgress,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();
  const [currentSalary, setCurrentSalary] = useState();

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanFactors(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanPositions(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanProfessionalGroups(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanSalaries(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetching: fetchingFactors,
    data: factors,
  } = useSelector(state => state.equality_plan_factor);
  const {
    fetching: fetchingPositions,
    data: positions,
  } = useSelector(state => state.equality_plan_position);
  const {
    fetching: fetchingProfessionalGroups,
    data: professionalGroups,
  } = useSelector(state => state.equality_plan_professional_group);
  const {
    fetching: fetchingSalaries,
    data: salaries,
  } = useSelector(state => state.equality_plan_salary);
  const {
    metrics,
  } = useSelector(state => state.taxonomies);

  const currency = useMemo(
    () => {
      const orgCurrency = suborganization?.product_config?.atlas?.preferred_units?.currency?.[0];

      if (!orgCurrency) {
        return;
      }

      return metrics.find(
        metric => metric.slug === 'currency'
      )?.metrics.find(
        metric => metric.slug === 'currency'
      )?.units.find(unit => unit.slug === orgCurrency);
    },
    [suborganization, metrics]
  );

  const handleAddNewSalary = useCallback(
    () => setCurrentSalary({}),
    []
  );

  const handleEditSalary = useCallback(
    (salary) => {
      setCurrentSalary({...salary});
    },
    []
  );

  const handleSave = useCallback(
    (data) => {
      if (data.id) {
        dispatch(
          updateEqualityPlanSalary(
            organization.slug,
            suborganization.slug,
            plan.id,
            data.id,
            data,
          )
        );
      } else {
        dispatch(
          createEqualityPlanSalary(
            organization.slug,
            suborganization.slug,
            plan.id,
            data,
          )
        );
      }
      updatePlanProgress('salary_data');
    },
    [dispatch, plan, organization, suborganization, updatePlanProgress]
  );

  const handleDeleteSalary = useCallback(
    (data) => {
      dispatch(
        deleteEqualityPlanSalary(
          organization.slug,
          suborganization.slug,
          plan.id,
          data.id,
        )
      );
    },
    [dispatch, plan, organization, suborganization]
  );

  const positionsWithPoints = useMemo(
    () => {
      if (!positions || !factors) {
        return [];
      }
      return positions.map(position => ({
        ...position,
        points: getPositionPoints(
          position, factors, plan.job_evaluation?.type_points
        ),
      }));
    },
    [factors, plan, positions]
  );

  const pointsRange = useMemo(
    () => {
      if (!positions || !factors) {
        return [0, 100];
      }

      return getPointsRangeForPositions(
        positions, factors, plan.job_evaluation?.type_points
      );
    },
    [positions, factors, plan]
  );

  const salaryData = useMemo(
    () => {
      if (!positions || !factors || !salaries || !professionalGroups) {
        return [];
      }

      return salaries.map(salary => {
        const position = positionsWithPoints
          .find(position => position.id === salary.position_id);
        const professionalGroup = professionalGroups
          .find(group => group.id === position.professional_group_id);

        return {
          ...salary,
          points: position.points,
          salary_cp: position.salary || 0,
          salary_cgp: professionalGroup.salary || 0,
          salary_f: getSalaryForPoints(
            pointsRange[0], pointsRange[1], plan.job_evaluation?.salary_min,
            plan.job_evaluation?.salary_max,
          )(
            (plan.job_evaluation?.salary_function || 1) - 1
          )(
            position.points
          ).toFixed(3),
        };
      });
    },
    [
      factors,
      plan,
      positions,
      salaries,
      positionsWithPoints,
      pointsRange,
      professionalGroups,
    ]
  );

  const salaryUploadUrl = useMemo(
    () => getEqualityPlanSalaryUploadUrl(
      organization.slug,
      suborganization.slug,
      plan.id,
    ),
    [organization, suborganization, plan],
  );

  const refreshSalaryData = useCallback(() => {
    dispatch(
      requestEqualityPlanSalaries(
        organization.slug, suborganization.slug, plan.id
      )
    );
  }, [
    dispatch,
    organization,
    suborganization,
    plan,
  ]);

  const onDocumentUploadSuccess = useCallback(
    (response) => {
      notification.open({
        message: t.equality_job_evaluation_salary_data_uploaded,
        description: t.equality_job_evaluation_salary_data_uploaded_desc,
        onClick: refreshSalaryData,
        onClose: refreshSalaryData,
        icon: <LoadingOutlined />,
      });
      updatePlanProgress('salary_data');
    },
    [
      t,
      updatePlanProgress,
      refreshSalaryData,
    ]
  );

  const onDownloadSalaryTemplate = useCallback(
    () => {
      const templateHeader = [
        t.equality_job_evaluation_salary_data_id,
        t.equality_job_evaluation_salary_data_gender,
        t.equality_job_evaluation_salary_data_position,
        t.equality_job_evaluation_salary_data_salary,
      ];
      const templateData = [...Array(1000)].map(_ => ['', '', '', ''])

      createExcel(
        `equality_plan_${plan.id}_salary_template.xlsx`,
        null,
        [templateHeader, ...templateData],
        true,
        [
          {
            col: 'B',
            values: ['male', 'female', 'neutral'].map(
              value => t[`gender_${value}`]
            ),
          },
          {
            col: 'C',
            values: positions?.map(position => position.name),
          }
        ]
      );
    },
    [
      t,
      plan,
      positions,
    ]
  );

  const columns = useMemo(
    () => (
      [{
        title: t.equality_job_evaluation_salary_data_id,
        dataIndex: 'employee_id',
      }, {
        title: t.equality_job_evaluation_salary_data_gender,
        dataIndex: 'gender',
        render: value => t[`equality_job_evaluation_salary_data_gender_option_${value}`]
      }, {
        title: t.equality_job_evaluation_salary_data_position,
        dataIndex: 'position_name',
      }, {
        title: t.equality_job_evaluation_salary_data_salary,
        dataIndex: 'salary',
        render: value => `${value} ${currency?.symbol || ''}`,
      }, {
        title: t.equality_job_evaluation_salary_data_points,
        dataIndex: 'points',
      }, {
        title: t.equality_job_evaluation_salary_data_salary_cp,
        dataIndex: 'salary_cp',
        render: value => `${value} ${currency?.symbol || ''}`,
      }, {
        title: t.equality_job_evaluation_salary_data_salary_cgp,
        dataIndex: 'salary_cgp',
        render: value => `${value} ${currency?.symbol || ''}`,
      }, {
        title: t.equality_job_evaluation_salary_data_salary_f,
        dataIndex: 'salary_f',
        render: value => `${value} ${currency?.symbol || ''}`,
      }, {
        title: t.equality_actions_table_actions,
        key: 'actions',
        render: (_, record) => (
          <Space size="middle">
            <Tooltip title={t.equality_actions_table_actions_edit}>
              <EditOutlined
                className='form-item-preview-icon'
                onClick={() => handleEditSalary(record)}
              />
            </Tooltip>
            <Tooltip title={t.equality_actions_table_actions_delete}>
              <Popconfirm
                title={t.equality_job_evaluation_salary_data_delete_confirm}
                onConfirm={() => handleDeleteSalary(record)}
                okText={t.yes}
                cancelText={t.no}
              >
                <DeleteOutlined
                  className='form-item-preview-icon'
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      }]
    ),
    [t, handleEditSalary, handleDeleteSalary, currency]
  );

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  if (
    fetchingPositions
    || !positions
    || fetchingSalaries
    || !salaries
    || fetchingFactors
    || !factors
    || fetchingProfessionalGroups
    || !professionalGroups
  ) {
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_salary_data}
        handleBackOrSkip={handleBack}
      />
      <Row gutter={10}>
        <Col>
          <CustomButton
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleAddNewSalary}
          >
            {t.equality_job_evaluation_salary_data_add_new}
          </CustomButton>
        </Col>

        <Col>
          <CustomButton
            type='primary'
            icon={<DownloadOutlined />}
            onClick={onDownloadSalaryTemplate}
          >
            {t.equality_job_evaluation_salary_data_upload_template}
          </CustomButton>
        </Col>

        <Col>
          <UploadButton
            actionUrl={salaryUploadUrl}
            onSuccess={onDocumentUploadSuccess}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            component={(
              <CustomButton
                type="primary"
                block
                icon={<UploadOutlined />}
              >
                { t.equality_job_evaluation_salary_data_upload }
              </CustomButton>
            )}
          >
          </UploadButton>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="form-input-wrapper">
          { currentSalary &&
            <Form
              visible={true}
              plan={plan}
              salary={currentSalary}
              positions={positions}
              closeForm={() => setCurrentSalary()}
              handleSave={handleSave}
              currency={currency}
            />
          }
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Alert message={t.equality_job_evaluation_salary_data_note} type="warning" />
        </Col>
        <Col span={24}>
          <CustomTable
            columns={columns}
            dataSource={salaryData}
            rowKey='id'
          />
        </Col>
      </Row>
    </>
  );
}

export default withRouter(injectIntl(FactorDefinition));
