import React from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import AppHeader from 'components/AppHeader'
import SignedInFooter from 'components/SignedInFooter';

import {
  Layout,
} from 'antd';

import './style.less';

const { Header } = Layout;
const PRODUCT = 'sustainability';

const FullScreenNoticeLayout = ({
  children,
  intl,
  organization = null,
  showLogin = false,
  history,
  location,
}) => {
  return (
    <section className="FullScreenNoticeLayout">
      <Header>
        <AppHeader/>
      </Header>
      <Layout hasSider={false} className="FullScreenNoticeLayout__container">
        <section className="FullScreenNoticeLayout__content">
          <section className="FullScreenNoticeLayout__message">
            { children }
          </section>
          <SignedInFooter className="FullScreenNoticeLayout__footer" />
        </section>
        <section className="FullScreenNoticeLayout__cover" >
          <img src={`/images/cover/${PRODUCT}.png`} alt="" />
        </section>
      </Layout>
    </section>
  );
};

export default injectIntl(
  withRouter(
    FullScreenNoticeLayout
  )
);
