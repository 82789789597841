import React, { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import { fullValidation as validation } from './validation';
import useForm from 'utils/useForm';
import { formatDateMoment, dateToDb, DATE_ONLY } from 'utils/date';
import MembersForm from 'components/EqualityPlanCommittee/MembersForm';
import FormItemPreview from 'components/EqualityPlan/FormItemPreview';
import EqualityPlanHeader from 'components/EqualityPlan/Header';

import {
  Row,
  Col,
  Button,
  Card,
  Input,
  Form,
  DatePicker,
  Modal,
  Popover,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.less';


const planStage = 'salary_audit_document';

const SalaryAuditDocumentForm = ({
  intl,
  history,
  plan,
  updatePlan,
}) => {
  const t = intl.messages;
  const defaults = useMemo(() => Object.keys(plan[`${planStage}_data`] || {}).length === 0
    ? {}
    : plan[`${planStage}_data`], [plan]);
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    updatePlan({[`${planStage}_data`]: values});
    history.push(`/equality-plan/${plan.id}/salary-audit-document/document-manager`);
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
    dirtyFields,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  // Members
  const members = getValue('members');
  const validMembers = useMemo(
    () => {
      if (members && members.length) {
        return members.filter(member => !member.active);
      } else {
        return [];
      }
    },
    [members]
  );
  const activeMember = useMemo(
    () => {
      if (members && members.length) {
        return members.find(member => member.active);
      }
    },
    [members]
  );
  const activeMemberIndex = useMemo(
    () => {
      if (members && members.length) {
        return members.findIndex(member => member.active);
      }
    },
    [members]
  );

  const handleAddToList = useCallback(
    (name) => {
      let value = [...getValue(name) || []];
      const alreadyActiveItem = value.find(item => item.active);
      if (alreadyActiveItem) {
        if (alreadyActiveItem.new) {
          value.splice(
            value.findIndex(item => item.active), 1
          )
        } else {
          delete alreadyActiveItem.active;
        }
      }
      value.push({new: true, active: true});
      handleChange(name)(value);
    },
    [handleChange, getValue]
  );

  const handleSaveToList = useCallback(
    (name, index, item) => {
      let value = [...getValue(name)];
      if (typeof index == 'number') {
        value[index] = item;
      } else {
        value.push(item);
      }
      handleChange(name)(value);
    },
    [handleChange, getValue]
  );

  const makeListItemInactive = useCallback(
    (list, index) => {
      let item = list[index];
      if (item.new) {
        list.splice(index, 1);
      }
      else {
        delete item.active;
      }
      return list;
    },
    []
  );

  const handleCancelSaveToList = useCallback(
    (name, index) => {
      let value = makeListItemInactive([...getValue(name)], index);
      handleChange(name)(value);
    },
    [getValue, handleChange, makeListItemInactive]
  );

  const handleEditListItem = useCallback(
    (name, index) => {
      let value = [...getValue(name)];
      const alreadyActiveItemIndex = value.findIndex(item => item.active);
      value[index].active = true;
      if (alreadyActiveItemIndex >= 0) {
        value = makeListItemInactive(value, alreadyActiveItemIndex);
      }
      handleChange(name)(value);
    },
    [handleChange, getValue, makeListItemInactive]
  );

  const handleDeleteListItem = useCallback(
    (name, index) => {
      let value = [...getValue(name)];
      value.splice(index, 1);
      handleChange(name)(value);
    },
    [handleChange, getValue]
  );

  const handleBackOrSkip = useCallback(
    (action) => {
      const actionUrl = action === 'skip'
        ? `/equality-plan/${plan.id}/salary-audit-document/document-manager`
        : `/equality-plan/${plan.id}/`;

      if (dirtyFields.length > 0) {
        Modal.confirm({
          title: t.equality_plan_exit_without_save,
          content: t.equality_plan_exit_without_save_description,
          okText: t.equality_plan_exit_without_save_ok,
          cancelText: t.equality_plan_exit_without_save_cancel,
          onOk() {
            history.push(actionUrl);
          },
        })
      } else {
        history.push(actionUrl);
      }
    },
    [dirtyFields, plan, history, t]
  );

  const handleChangeDate = useCallback((name, value) => {
    handleChange(name)(value && dateToDb(value));
  }, [ handleChange ]);

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t[`equality_${planStage}`]}
        isDocumentManager={false}
        handleBackOrSkip={handleBackOrSkip}
      />
      <Form
        onFinish={handleSubmit}
        layout="vertical"
        className="EqualityPlanSalaryAuditDocument-form"
      >
        <Row>
          <Col span={20}>
            <Row type="flex" align="middle">
              <span>{t[`equality_${planStage}_complete_data`]}</span>&nbsp;&nbsp;
              <Popover
                placement="bottom"
                content={
                  <p className='popover-text'>
                    <ReactMarkdown
                      source={t[`equality_${planStage}_form_help_text`]}
                    />
                  </p>
                }
                trigger="click"
              >
                <FontAwesomeIcon
                  className='icon-large icon-yellow' 
                  icon={'lightbulb'}
                />
              </Popover>
            </Row>
          </Col>
          <Col span={2}>
            <Button
                type="primary"
                onClick={() => handleBackOrSkip('skip')}
              >
                { t.skip }
            </Button>
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              htmlType="submit"
            >
              { t.done }
            </Button>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Card className="form-input-card">
              <Row gutter={10}>
                <Col span={24}>
                  <h4>{t.equality_salary_audit_document_period}</h4>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    validateStatus={ showError('start_date') ? 'error' : '' }
                    help={ showError('start_date') }
                    label={ t.start_date }
                    colon={false}
                    required
                  >
                    <DatePicker
                      format={DATE_ONLY}
                      name="start_date"
                      placeholder={t.start_date}
                      value={formatDateMoment(getValue('start_date'))}
                      onChange={(value) => handleChangeDate('start_date', value)}
                      className="EqualityPlanSalaryAuditDocument-form-datepicker"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    validateStatus={ showError('end_date') ? 'error' : '' }
                    help={ showError('end_date') }
                    label={ t.end_date }
                    colon={false}
                    required
                  >
                    <DatePicker
                      format={DATE_ONLY}
                      name="end_date"
                      placeholder={t.end_date}
                      value={formatDateMoment(getValue('end_date'))}
                      onChange={(value) => handleChangeDate('end_date', value)}
                      className="EqualityPlanSalaryAuditDocument-form-datepicker"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <Card className="form-input-card">
              <Form.Item
                hasFeedback
                validateStatus={ showError('organization_name') ? 'error' : '' }
                help={ showError('organization_name') }
                label={ t.organization_name }
                colon={false}
              >
                <Input
                  placeholder={ t.organization_name }
                  name="organization_name"
                  value={ getValue('organization_name') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Card>
          </Col>
          <Col span={24} className="form-input-wrapper">
            <div className="form-input-card">
              <h4>{t[`equality_${planStage}_members`]}</h4>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => handleAddToList('members')}
              ></Button>
            </div>
          </Col>
          { activeMember && (
            <Col span={24}>
              <MembersForm
                type="members"
                member={activeMember}
                index={activeMemberIndex}
                onSave={handleSaveToList}
                onCancel={handleCancelSaveToList} />
            </Col>
          )}
          {validMembers.length > 0 && (
            <Col span={24} className="form-input-wrapper">
              {validMembers.map((member, index) => {
                return <FormItemPreview
                  key={index}
                  sequence={index + 1}
                  onEdit={() => handleEditListItem('members', index)}
                  onDelete={() => handleDeleteListItem('members', index)}
                >
                  <div>{`${t.name}: ${member.name}`}</div>
                  { member.email
                    ? <div>{`${t.email}: ${member.email}`}</div>
                    : null
                  }
                  { member.post
                    ? <div>{`${t.equality_plan_member_post}: ${member.post}`}</div>
                    : null
                  }
                  <div>{`${t.is_legal_representative}: ${member.is_legal_representative}`}</div>
                </FormItemPreview>;
              })}
            </Col>
          )}
        </Row>
      </Form>
    </>
  )
};

export default withRouter(injectIntl(SalaryAuditDocumentForm));
