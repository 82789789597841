import React, { useMemo, useCallback } from 'react'
import { withRouter } from 'react-router';
import {
  Layout,
  Row,
  Col,
  Avatar,
  Popover,
  Tooltip
} from 'antd';
import {
  UserOutlined
} from '@ant-design/icons';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Link } from 'react-router-dom';
import config from 'config';

import Nav from 'components/Nav';
import AppHeader from 'components/AppHeader';
import OrganizationLogo from 'components/OrganizationLogo';
import AplanetIcon from 'components/AplanetIcon';
import NotificationCenter from 'components/NotificationCenter';
import { useFeatureList } from 'components/FeatureSwitch';
import LateralMenu from './components/LateralMenu';

import useElementSize from 'utils/useElementSize';
import useOrganizations from 'utils/useOrganizations';

import {
  resetOrganization,
} from 'actions/organizationTree';
import { formatMessage } from '../../intl';

import './style.less';
import { injectIntl } from 'react-intl';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';
import { isExternalPath } from 'utils/validation';

const { Header, Sider } = Layout;

const AppFramework = ({
  children,
  className = '',
  disabledOrganizationTreeSelector = false,
  nav = undefined,
  header = undefined,
  currentOrganization = undefined,
  disableOnClickInSiderLogo = false,
  hideOrgLogoinSider = false,
  hideHeader = false,
  hideActionButtons = false,
  history,
}) => {
  const {
    organization,
    suborganization,
    memberships,
  } = useOrganizations();

  const org = currentOrganization || suborganization || organization;

  const {
    clientWidth,
    scrollWidth
  } = useElementSize(document.body);

  const dispatch = useDispatch();

  const {
    isSidebarCollapsed,
  } = useSelector(state => state.app);

  const {
    data
  } = useSelector(state => state.profile);

  const {
    avatar,
    name,
  } = data || {};

  const hasHorizontalScroll = useMemo(() => scrollWidth > clientWidth, [
    clientWidth,
    scrollWidth,
  ]);

  const {
    features: featureList,
  } = useFeatureList();

  const isRestrictedAdmin = useMemo(() => (
    featureList.has('restricted_admins') && !suborganization?.permissions?.can_affect_all_kpis
  ), [
    featureList,
    suborganization,
  ]);

  const onNotificationNavigation = useCallback((route) => {
    history.push(route);
  },
    //eslint-disable-next-line
    []);

  const handleResetOrg = useCallback(() => dispatch(resetOrganization()), [dispatch]);

  return (
    <Layout
      hasSider={true}
      className={`
        wrapper-layout
        ${hasHorizontalScroll ? 'hasHorizontalScroll' : ''}
      `}
    >
      <Sider
        theme="light"
        className="sider"
        collapsedWidth={64}
        collapsed={isSidebarCollapsed}
        trigger={null}
        collapsible
      >
        <Row type="flex" justify="center" className="sider__logo-wrapper">
          {hideOrgLogoinSider ? null : (
            <Col>
              {memberships.length <= 1 ? (
                <OrganizationLogo
                  showPoweredBy={org?.config?.show_powered_by}
                  name={org.name}
                  logo={org.logo}
                  logo_small={org.logo_small}
                  showSmallLogo={isSidebarCollapsed}
                  className={isSidebarCollapsed ? "sider__logo-small" : "sider__logo-full"}
                />
              ) : (
                <Tooltip placement="right" title={formatMessage('avatardropdown_change_organization')}>
                  <div onClick={handleResetOrg} style={{ cursor: 'pointer' }}>
                    <OrganizationLogo
                      showPoweredBy={org?.config?.show_powered_by}
                      name={org.name}
                      logo={org.logo}
                      logo_small={org.logo_small}
                      showSmallLogo={isSidebarCollapsed}
                      className={isSidebarCollapsed ? "sider__logo-small" : "sider__logo-full"}
                    />
                  </div>
                </Tooltip>
              )}
            </Col>
          )}
        </Row>
        {nav ? nav : <Nav />}
        <div className='grow' style={{ flexGrow: 2 }}></div>
        {hideActionButtons ? null : (
          <div>
            {!isRestrictedAdmin &&
              <div className="icon">
                <NotificationCenter
                  onNavigation={(link) => onNotificationNavigation(link)}
                />
              </div>
            }
            <div className="icon">
              <a
                style={{ color: 'initial' }}
                href={`${config.ACCOUNTS_URL}/organization/${organization.slug}?org=${organization.slug}`}
              >
                <AplanetIcon name='Settings' faStyle='fad' />
              </a>
            </div>
            <div className="icon" id="avatar">
              <Popover
                overlayClassName="AvatarMenuPopOver"
                align={{
                  offset: [4, 40],            // the offset sourceNode by 10px in x and 20px in y,
                }}
                content={
                  <div style={{ display: 'flex', flexDirection: 'column', paddingRight: "20px", gap: "10px" }}>
                    <a
                      href={`${config.ACCOUNTS_URL}/profile/${organization.slug}?org=${organization.slug}`}>
                      {formatMessage('avatardropdown_profile')}
                    </a>
                    <Link to="/logout">
                      {formatMessage('avatardropdown_logout')}
                    </Link>
                  </div>
                }
                placement="right"
                trigger='click'
              >
                <section>
                  <Avatar
                    shape="circle"
                    src={avatar || undefined}
                    name={name || undefined}
                    icon={<UserOutlined />}
                    className='AppHeader-avatar'
                  />
                </section>
              </Popover>
            </div>
          </div>
        )}
      </Sider>
      <LateralMenu />
      <Layout
        className={`
          main-layout
          ${isSidebarCollapsed ? 'main-layout--collapsed' : ''}
          ${hasHorizontalScroll ? 'hasHorizontalScroll' : ''}
        `}
      >
        {hideHeader ? null : (
          <Header
            className={`
            main-header
            ${isSidebarCollapsed ? 'main-header--collapsed' : ''}
          `}
          >
            {header ? header : <AppHeader disabledOrganizationTreeSelector={disabledOrganizationTreeSelector} />}
          </Header>
        )}
        <Layout hasSider={false} className={`content-layout ${className}`}>
          {
            suborganization.permissions || isExternalPath(window?.location?.pathname)
              ? children 
              : <Empty {...emptyPropsMap.get("noNodePermissions")} />
          }
        </Layout>
      </Layout>
    </Layout>
  )
}

export default injectIntl(withRouter(AppFramework))