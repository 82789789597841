import React, {
  useCallback,
  useMemo,
} from 'react';

import { injectIntl } from 'react-intl';

import Answer from 'components/Answer';
import CustomButton from 'components/CustomButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Collapse,
  Popover,
  Row,
  Col,
} from 'antd';

import ReactMarkdown from 'react-markdown';

import './style.less';

const Extra = injectIntl(({
  intl,
  organization_id,
  slug,
  comment,
  onChangeOrganization,
}) => {
  const t = intl.messages;

  const handleOrg = useCallback((event) => {
    event.preventDefault();
    onChangeOrganization({
      id:organization_id,
      slug,
    });
  }, [
    onChangeOrganization,
    organization_id,
    slug,
  ]);

  return (
    <Row
      type="flex"
      align="middle"
      gutter={10}
    >
      {
        !comment
        ? null
        : (
          <Col>
            <Popover
             placement="bottom"
             content={
               <p className='popover-text'>
                 <ReactMarkdown
                   source={comment}
                 />
               </p>
             }
             trigger="click"
             onClick={(e) => { e.stopPropagation(); }}
           >
             <FontAwesomeIcon
               className='icon-large'
               icon={'comment-alt'}
             />
           </Popover>
         </Col>
        )
      }
      <Col>
        <CustomButton
          onClick={handleOrg}
        >
          { t.recursiveanswer_go }
        </CustomButton>
      </Col>
    </Row>
  );
});

const RecursiveAnswers = injectIntl(({
  intl,
  onChangeOrganization,
  values = [],
  indentLevel = 0,
  ...props
}) => {
  const t = intl.messages;
  const topLevel = indentLevel === 0;
  const defaultActiveKey = useMemo(() => {
    return !topLevel
    ? []
    : values.map(({ slug }) => slug);
  }, [
    topLevel,
    values,
  ]);

  const valuesThatApply = useMemo(() => {
    return values
      .filter(({ applies }) => applies)
      .map((value) => {
        return {
          ...value,
          ...(
            value.children
            ? {
              children: value.children.filter(({ applies }) => applies)
            }
            : {}
          ),
        };
      });
  }, [
    values,
  ]);

  return (
    <div className="RecursiveAnswers">
      <Collapse
        defaultActiveKey={defaultActiveKey}
        bordered={!topLevel}
        className={
          `RecursiveAnswers__collapse ${topLevel ? 'RecursiveAnswers__toplevel' : ''}`
        }
        {...( topLevel ? { activeKey: defaultActiveKey } : {} )}
      >
      {
        valuesThatApply.map(value => (
          <Collapse.Panel
            key={value.slug}
            header={
              <div className="RecursiveAnswers__header">
                <span>{ value.name }</span>
              </div>
            }
            showArrow={!topLevel}
            collapsible={!topLevel}
            extra={<Extra onChangeOrganization={onChangeOrganization} {...value} />}
          >
            {
              value.applies
              ? null
              : (
                <div
                  className="RecursiveAnswers__notapplies"
                >
                  { t.recursiveanswer_notapplies }
                </div>
              )
            }
            {
              !(value.applies && !value.kpi_value && (value.children || []).length === 0)
              ? null
              : (
                <div
                  className="RecursiveAnswers__novalue"
                >
                  { t.recursiveanswer_novalue }
                </div>
              )
            }
            {
              !value.applies || !value.kpi_value || value.value_source === 'children'
              ? null
              : (
                <Answer.Show
                  compact
                  expanded
                  value={value.kpi_value}
                  config={value.config}
                  {...props}
                  schemaLabels={value.schemaLabels}
                  tableDimensions={value.tableDimensions}
                />
              )
            }
            {
              !value.applies || value.value_source !== 'children' || !value.children || value.children.length === 0
              ? null
              : (
                <RecursiveAnswers
                  {...props}
                  onChangeOrganization={onChangeOrganization}
                  values={value.children}
                  indentLevel={indentLevel+1}
                />
              )
            }
          </Collapse.Panel>
        ))
      }
      </Collapse>
    </div>
  )
});

export default RecursiveAnswers;
