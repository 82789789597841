import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import EditableComment from 'components/EditableComment'

import BooleanAnswer from 'components/BooleanAnswer';
import ChoiceAnswer from 'components/ChoiceAnswer';
import QualitativeAnswer from 'components/QualitativeAnswer';
import QuantitativeAnswer from 'components/QuantitativeAnswer';
import TableAnswer from 'components/TableAnswer';
import AnswerFooter from 'components/AnswerFooter';

import useOrganizations from 'utils/useOrganizations';
import { useTupleState } from 'utils/useTupleState';

import {
  Divider,
  Modal,
} from 'antd';
import { useFeatureList } from 'components/FeatureSwitch';

const QUALITATIVE_SCHEMA_TYPE= 'qualitative';

const TableEdit = ({
  schema,
  schemaLabels,
  config,
  value,
  onChange,
  tableDimensions,
  dependees = [],
}) => {
  return (
    <TableAnswer.Edit
      schema={schema}
      schemaLabels={schemaLabels}
      config={config}
      value={value}
      onChange={onChange}
      tableDimensions={tableDimensions}
      dependees={dependees}
    />
  );
};

const COMPONENTS_BY_TYPE = {
  boolean: BooleanAnswer.Edit,
  choice: ChoiceAnswer.Edit,
  qualitative: QualitativeAnswer.Edit,
  quantitative: QuantitativeAnswer.Edit,
  table: TableEdit,
  default: () => <div>-</div>,
};

const TupleComponent = ({
  schema,
  schemaLabels,
  config,
  value,
  onChange,
  tableDimensions: overrideTableDimensions,
  dependees = [],
}) => {
  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);
  const {
    components = [],
  } = schema;

  const handleChange = useCallback((name) => (...params) => {
    onChange(name, ...params)
  }, [
    onChange,
  ]);

  return (
    <React.Fragment>
      {
        components.map(schema => {
          const name = schema.name
          const componentValue = value[name];
          const Component = COMPONENTS_BY_TYPE[schema.type] || COMPONENTS_BY_TYPE.default;
          const displayName = ((schemaLabels || {}).componentLabels || {})[name] || name;
          const componentSchemaLabels = ((schemaLabels || {}).components || {})[name] || {};

          return (
            <section
              key={name}
              className='KpiDetail__answer-tuple'
            >
              <span className='KpiDetail__answer-tuple-label' title={displayName}>
                {
                  schema.type === 'table' && displayName === 'table'
                  ? null
                  : displayName
                }
              </span>
              <section
                className={`KpiDetail__answer-tuple-value KpiDetail__answer-tuple-value-${schema.type}`}
              >
                <Component
                  schema={schema}
                  schemaLabels={componentSchemaLabels}
                  config={config}
                  value={componentValue}
                  onChange={handleChange(name)}
                  tableDimensions={overrideTableDimensions || tableDimensions}
                  dependees={dependees}
                />
              </section>
            </section>
          );
        })
      }
    </React.Fragment>
  );

}

const Edit = ({
  intl,
  schema,
  schemaLabels,
  kpi_slug,
  value: initialValue,
  previous_value,
  previous_comment,
  previous_attachments,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
  onShowLastPeriod,
  comment: initialComment,
  onCancel,
  onSave,
  onClear,
  tableDimensions: overrideTableDimensions,
  config: kpiConfig
}) => {
  const t = intl.messages;

  const {
    suborganization,
    permissions,
  } = useOrganizations();
  const {
    features: featureList,
  } = useFeatureList();

  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);

  const { config = {} } = suborganization;

  const {
    values: tupleValues,
    update: updateTuple,
  } = useTupleState({
    schema,
    tableDimensions: overrideTableDimensions || tableDimensions,
    initialValue,
    slug: kpi_slug,
  });

  const [ comment, setComment ] = useState(initialComment);
  const [ edittingComment, setEdittingComment ] = useState(false);
  const [ dirty, setDirty ] = useState(false);

  const handleChange = useCallback((name, ...params) => {
    setDirty(true);
    updateTuple(name, ...params)
  }, [
    updateTuple,
  ]);

  const handleUseLastValue = useCallback(() => {
    onCancel();
    onUseLastValue();
  }, [
    onUseLastValue,
    onCancel,
  ]);

  const handleUseLastWholeData = useCallback(() => {
    onCancel();
    onUseLastWholeData();
  }, [
    onUseLastWholeData,
    onCancel,
  ]);

  const handleUseLastAttachments = useCallback(() => {
    onCancel();
    onUseLastAttachments();
  }, [
    onUseLastAttachments,
    onCancel,
  ]);

  const handleUseLastPeriod = useCallback(() => {
    onCancel();
    onUseLastPeriod();
  }, [
    onUseLastPeriod,
    onCancel,
  ]);

  const handleCommentChange = useCallback((comment) => {
    setDirty(true);
    setComment(comment);
  }, []);

  const handleCancel = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    onCancel();
  }, [
    dirty,
    onCancel,
  ]);

  const handleClear = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    Modal.confirm({
      title: t.kpi_detail_clear_title,
      content: t.kpi_detail_clear_content,
      okText: t.kpi_detail_clear_ok,
      okType: 'danger',
      cancelText: t.kpi_detail_clear_cancel,
      onOk() {
        onClear ? onClear() : onSave(null, '');
      },
      onCancel() {},
    });
  }, [
    t,
    dirty,
    onSave,
    onClear
  ]);

  const isEmpty = useMemo(() => {
    // TODO: A tuple is empty if all of its components are empty
    return false;
  }, []);

  const handleSave = useCallback(() => {
    if(dirty) {
      console.log('TODO, field is dirty');
    }
    onSave(
      tupleValues,
      comment,
    );
  }, [
    dirty,
    tupleValues,
    comment,
    onSave,
  ]);

  const hasTargetPermissions = useMemo(() => {
    return featureList && featureList.has("targets")
      && permissions.can_configure_kpi
      && schema.type !== QUALITATIVE_SCHEMA_TYPE;
  }, [
    featureList,
    permissions.can_configure_kpi,
    schema.type
  ]);

  return (
    <React.Fragment>
      <section>
        <TupleComponent
          schema={schema}
          schemaLabels={schemaLabels}
          config={config}
          value={tupleValues}
          onChange={handleChange}
          tableDimensions={overrideTableDimensions || tableDimensions}
        />
      </section>
      <section>
        <EditableComment.Component
          title={t.privateComment}
          value={comment}
          onChange={handleCommentChange}
          editMode={edittingComment}
          setEditMode={setEdittingComment}
          editable
          titleClass='KpiDetail__title'
          config={kpiConfig}
        />
      </section>
      <Divider/>
      <AnswerFooter
        editting
        canWrite
        hasLastPeriod={!!previous_value}
        hasLastComment={!!previous_comment}
        hasLastAttachments={!!previous_attachments?.length}
        onShowLastPeriod={onShowLastPeriod}
        onUseLastValue={handleUseLastValue}
        onUseLastWholeData={handleUseLastWholeData}
        onUseLastAttachments={handleUseLastAttachments}
        onUseLastPeriod={handleUseLastPeriod}
        hasInitialValue={!!initialValue}
        onCancelEdit={handleCancel}
        onClearEdit={handleClear}
        onSaveEdit={handleSave}
        dirty={dirty}
        empty={isEmpty}
        isTargetElegible={hasTargetPermissions}
      />
    </React.Fragment>
  );
};

Edit.Component = TupleComponent;

export default injectIntl(Edit);

