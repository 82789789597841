import React from 'react'

import {
  Radio,
} from 'antd'

import './style.less'

const CustomRadio = (props) => (
  <Radio className='CustomRadio' {...props}/>
)

CustomRadio.Group = (props) => (
  <Radio.Group className='CustomRadio' {...props}/>
)

CustomRadio.Button = (props) => (
  <Radio.Button className='CustomRadio' {...props}/>
)

export default CustomRadio
