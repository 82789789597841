import React, { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import AplanetIcon from 'components/AplanetIcon';
import FilterList from 'components/FilterList/FilterList';
import DateRangePicker from 'components/DateRangePicker';
import SDGFilter from 'components/SDGFilter';
import { useComponentVisible } from 'hooks/useComponentVisible';
import { FILTERS_COMPONENT_TYPES_CATALOG } from 'hooks/useScreenFilters/constants';

import {
  Col,
  Row,
} from 'antd';
import A from 'components/A';
import CustomButton from 'components/CustomButton';
import CustomInputSearch from 'components/CustomInputSearch';
import CustomMultiSelect from 'components/CustomMultiSelect';
import CustomSelect from 'components/CustomSelect';
import OrganizationTreeSelector from 'components/OrganizationTreeSelector';
import KpiTreeSelector from 'containers/DataRequests/screens/Step1/KpiTreeSelector';

import './style.less';


const FiltersPanelColumnHeading = ({
  columnTitle,
}) => (
  <div className="FiltersPanelColumnHeading FiltersPanel__column__heading">
    <span className="FiltersPanel__column-title">{columnTitle}</span>
    <hr />
  </div>
);

const FILTERS_COMPONENT_CATALOG = {
  [FILTERS_COMPONENT_TYPES_CATALOG.text]: CustomInputSearch,
  [FILTERS_COMPONENT_TYPES_CATALOG.list]: FilterList,
  [FILTERS_COMPONENT_TYPES_CATALOG.checkbox]: FilterList,
  [FILTERS_COMPONENT_TYPES_CATALOG.sdg]: FilterList,
  [FILTERS_COMPONENT_TYPES_CATALOG.tags]: FilterList,
  [FILTERS_COMPONENT_TYPES_CATALOG.date]: DateRangePicker,
  [FILTERS_COMPONENT_TYPES_CATALOG.select]: CustomSelect,
  [FILTERS_COMPONENT_TYPES_CATALOG.multiSelect]: CustomMultiSelect,
  [FILTERS_COMPONENT_TYPES_CATALOG.sdgSelect]: SDGFilter,
  [FILTERS_COMPONENT_TYPES_CATALOG.heading]: FiltersPanelColumnHeading,
  [FILTERS_COMPONENT_TYPES_CATALOG.organizationSelect]: OrganizationTreeSelector,
  [FILTERS_COMPONENT_TYPES_CATALOG.kpiTreeSelect]: KpiTreeSelector,
};

const FiltersPanel = ({
  intl,
  areFiltersSet,
  onCancelFilter,
  onSetFilters,
  filtersConfig,
  menuPosition = 'bottom',
  columnSpan = { xs: 24, sm: 12, md: 8, lg: 6 },
  disabled = false
}) => {

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible({
    onClickOutside: onSetFilters,
    onEscPress: onCancelFilter
  });

  const handleCancel = useCallback(() => {
    setIsComponentVisible(false); 
    onCancelFilter();
  }, [onCancelFilter, setIsComponentVisible]);

  const handleSet = useCallback(() => {
    setIsComponentVisible(false);
    onSetFilters();
  }, [onSetFilters, setIsComponentVisible]);

  return (
    <div className="FiltersPanel" ref={ref} >
      <button
        className={`
          FiltersPanel__button
          ${isComponentVisible && !disabled? 'open-panel' : ''}
          ${areFiltersSet && !disabled? 'is-set' : ''}
        `}
        disabled={disabled}
        onClick={ !isComponentVisible ? () => setIsComponentVisible(true) : handleSet }
      >
        <AplanetIcon name='Filter' size="18px" />
        <span>{intl.formatMessage({ id: "filter_panel_button_title"})}</span>
      </button>
      { ( isComponentVisible) && !disabled ? (
        <div className={`FiltersPanel__panel ${menuPosition === 'top' ? 'menuTop' : ''}`} >
          <Row className="FiltersPanel__panel-wrapper" gutter={[16, 16]}>
            {filtersConfig.map(({ columnTitle, filters, colSpan }) => (
              <Col
                key={columnTitle}
                className="FiltersPanel__column"
                { ...columnSpan }
                { ...colSpan }
              >
                {columnTitle ? (
                  <>
                    <span className="FiltersPanel__column-title">{columnTitle}</span>
                    <hr/>
                  </>
                ) : null}
                {filters.map(({ filterType, filterTitle, hideDivider, ...filterProps }) => {
                  const FilterComponent = FILTERS_COMPONENT_CATALOG[filterType];
                  return (
                    <div key={`${columnTitle}_${filterTitle}`}  className="FiltersPanel__filter-container" id={`Filter-id-${filterTitle}`}>
                      {filterTitle ? (<span className="FiltersPanel__filter-title">{filterTitle}</span>) : null}
                      <FilterComponent
                        {...filterProps}
                        listType={filterType}
                        getPopupContainer={() => filterType === FILTERS_COMPONENT_TYPES_CATALOG.multiSelect ? document.getElementById(`CustomMultiSelect-${filterProps.title}`) : document.getElementById(`Filter-id-${filterTitle}`) }
                      />
                      { hideDivider || (filterType === FILTERS_COMPONENT_TYPES_CATALOG.heading) ? null : <hr/> }
                    </div>
                  );
                })}
              </Col>
            ))}
          </Row>
          <div className={`FiltersPanel__panel-buttons ${menuPosition === 'top' ? 'menuTop' : ''}`}>
            <A
              className="FiltersPanel__cancel-button"
              onClick={handleCancel}
            >
              {intl.formatMessage({ id: "filter_panel_cancel_button_title"})}
            </A>
            <CustomButton
              type="primary"
              className="FiltersPanel__set-button"
              onClick={handleSet}
              icon={<AplanetIcon name='Filter' size="18px" />}
            >
              {intl.formatMessage({ id: "filter_panel_filter_button_title"})}
            </CustomButton>
          </div>
        </div>
      ) : null}
    </div>
  );
}

FiltersPanel.propTypes = {
  areFiltersSet: PropTypes.bool,
  onCancelFilter: PropTypes.func.isRequired,
  onSetFilters: PropTypes.func.isRequired,
  filtersConfig: PropTypes.arrayOf(PropTypes.shape({
    columnTitle: PropTypes.string,
    colSpan: PropTypes.object,
    filters: PropTypes.arrayOf(PropTypes.shape({
      filterType: PropTypes.oneOf(Object.keys(FILTERS_COMPONENT_CATALOG)).isRequired,
      filterTitle: PropTypes.string,
    }))
  })).isRequired,
  menuPosition: PropTypes.string,
  columnSpan: PropTypes.object,
  disabled: PropTypes.bool,
};

export default injectIntl(FiltersPanel);
