import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import RoleCard from 'containers/KpiDetail/screens/Assignees/RoleCard';
import CustomButton from 'components/CustomButton';
import AplanetIcon from 'components/AplanetIcon';
import CustomWarningModal from 'components/CustomWarningModal';
import Avatar from 'components/Avatar';
import CustomModal from 'components/CustomModal';
import useOrganizations from 'utils/useOrganizations';
import { useEventTracking } from 'hooks/useEventTracking';

import { syncOrganizationKpiMembersToChildNodes } from 'actions/api';

import {
  Col,
  Row,
} from 'antd';

import './style.less';


const NewAssigneeModal = ({
  intl,
  visible,
  setVisible,
  kpis,
}) => {
  const dispatch = useDispatch();
  const eventTracking = useEventTracking();
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [syncingAssignees, setSyncingAssignees] = useState(false);

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const {
    pushing,
    error,
  } = useSelector(state => state.organization_kpi_member);

  useEffect(
    () => {
      if (syncingAssignees && !pushing) {
        setSyncingAssignees(false);
        if (!error) {
          setShowSyncModal(false);
        }
      }
    },
    [
      error,
      pushing,
      syncingAssignees,
    ]
  );

  const closeModal = useCallback(() => {
    setVisible(false);
  }, [
    setVisible,
  ]);

  const kpiSlugs = useMemo(
    () => kpis.map(({slug}) => slug),
    [kpis]
  );

  const handleOnSyncToChildNodes = useCallback(
    () => {
      eventTracking.capture('dataOwners.sync', {
        tab_name: 'Fill_and_report',
      });
      dispatch(
        syncOrganizationKpiMembersToChildNodes(
          organization.slug,
          suborganization.slug,
          kpiSlugs,
        )
      );
      setSyncingAssignees(true);
    },
    [
      dispatch,
      organization,
      suborganization,
      kpiSlugs,
      eventTracking,
    ]
  );

  return (
    <CustomModal
      destroyOnClose
      shown={visible}
      title={intl.formatMessage({id: 'new_assignee_modal_title'})}
      onCancel={closeModal}
      width='85%'
      className="NewAssigneeModal"
      footer={null}
    >
      <Row gutter={[2, 10]}>
        <Col span={24}>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
          >
            <Col>
              <Row
                type="flex"
                align="middle"
                gutter={10}
              >
                <Col>
                  <AplanetIcon name="file" faStyle="fas" />
                </Col>
                <Col className="NewAssigneeModal__indicator_count">
                  {`${kpiSlugs.length} ${intl.formatMessage({id: `new_assignee_modal_number_of_indicator${kpiSlugs.length === 1 ? '' : 's'}`})}`}
                </Col>
              </Row>
            </Col>
            <Col>
              <Row
                type="flex"
                align="middle"
                gutter={10}
              >
                <Col>
                  <Avatar
                    src={suborganization.logo_small}
                    name={suborganization.name}
                  />
                </Col>
                <Col className="NewAssigneeModal__organization">
                  {suborganization.name}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          { !suborganization?.parent_id
            ? <Row
                type="flex"
                justify="end"
              >
                <Col>
                  { false && // Disabling this for release until further discussion
                    <CustomButton
                      type="primary"
                      icon={<AplanetIcon name="refresh" className="Assignees__sync_button" />}
                      onClick={() => setShowSyncModal(true)}
                      disabled={pushing || syncingAssignees}
                    >
                      {intl.formatMessage({id: `assignees_sync_to_tree`})}
                    </CustomButton>
                  }
                  <CustomWarningModal
                    className="Assignees_sync_modal"
                    showModal={showSyncModal}
                    onOk={handleOnSyncToChildNodes}
                    onCancel={() => setShowSyncModal(false)}
                    onOkText={intl.formatMessage({id: `assignees_sync_modal_action_ok`})}
                    onCancelText={intl.formatMessage({id: `assignees_sync_modal_action_cancel`})}
                    title={intl.formatMessage({id: `assignees_sync_modal_title`})}
                    content={
                      <Row gutter={[0, 10]}>
                        <Col span={24}>
                          {intl.formatMessage({id: `assignees_sync_modal_content`})}
                        </Col>
                        <Col span={24}>
                          <b>
                            {intl.formatMessage({id: `assignees_sync_modal_confirmation`})}
                          </b>
                        </Col>
                      </Row>
                    }
                    loading={pushing || syncingAssignees}
                  />
                </Col>
              </Row>
            : null
          }
        </Col>
        <Col span={8}>
          <RoleCard
            type="manager"
            kpiSlugs={kpiSlugs}
            isBulk={true}
          />
        </Col>
        <Col span={8}>
          <RoleCard
            type="editor"
            kpiSlugs={kpiSlugs}
            isBulk={true}
          />
        </Col>
        <Col span={8}>
          <RoleCard
            type="validator"
            kpiSlugs={kpiSlugs}
            isBulk={true}
          />
        </Col>
        <Col span={24} className="NewAssigneeModal__info">
          {intl.formatMessage({id: 'new_assignee_modal_info'})}
        </Col>
      </Row>
    </CustomModal>
  )
}

export default injectIntl(NewAssigneeModal);
