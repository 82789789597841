import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AuthorizedLink from 'containers/AuthorizedLink';
import CustomTable from 'components/CustomTable';
import Avatar from 'components/Avatar';
import { formatDate } from 'utils/date';

import {
  Row,
  Col,
} from 'antd';

import { UserOutlined } from '@ant-design/icons';

const EqualityPlanDocumentManagerHistory = ({
  intl,
  documents
}) => {
  const t = intl.messages;
  const columns = useMemo(() => {
    return [{
      title: t.name,
      dataIndex: 'member_name',
      key: 'name',
      render: (name, record) => (
        <React.Fragment>
          <Row type="flex" gutter={10} justify="start" align="middle" style={{ flexWrap: 'nowrap' }}>
            <Col>
              <Avatar
                src={record.member_avatar}
                name={record.member_name}
                shape="circle"
                size={38}
                icon={<UserOutlined />}
              />
            </Col>

            <Col>
              <Row type="flex" gutter={2}>
                <Col className="DataManagement__nobreak">
                  { name }
                </Col>
              </Row>
              <div className="DataManagement__nobreak">
                { record.member_email }
              </div>
            </Col>
          </Row>
        </React.Fragment>
      ),
    },
    {
      title: t.file,
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: t.date,
      dataIndex: 'created_at',
      key: 'date',
      render: (created_at) => (
        <span>
          {`${formatDate(created_at, 'D MMM YYYY HH:mm', intl)}`}
        </span>
      ),
    }, {
      title: t.action,
      key: 'action',
      render: (_, record) => (
        <AuthorizedLink
          className="EqualityPlanDocumentHistory-link"
          href={record.download}
          download={record.filename}
          mimeType={record.mimetype}
        >
          <FontAwesomeIcon
            className='EqualityPlanDocumentHistory-icon icon-large'
            icon={'download'}
          />
        </AuthorizedLink>
      ),
    }];
  }, [
    intl,
    t,
  ]);

  return (
    <CustomTable
      className="EqualityPlanDocumentHistory"
      columns={columns}
      dataSource={documents}
      rowKey='id'
    />
  );
};

export default injectIntl(EqualityPlanDocumentManagerHistory);
