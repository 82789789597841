import React from 'react';

import {
  Button, Dropdown,
} from 'antd';

import './style.less';


const FloatingButton = ({
  className,
  onClick,
  icon,
  iconUrl,
  disabled = false,
  ...props
}) => {
  return (
    <Button
      onClick={onClick}
      shape='circle'
      type='primary'
      className={`FloatingButton${className ? ` ${className}` : ''}`}
      disabled={disabled}
      icon={icon}
      {...props}
    >
      { iconUrl && <img src={iconUrl} alt=''/> }
    </Button>
  );
}

const FloatingDropdownButton = ({
  placement,
  menu,
  buttonProps,
}) => {
  return (
    <Dropdown
      overlay={menu}
      placement={placement}
    >
      <FloatingButton
        {...buttonProps}
      />
    </Dropdown>
  );
}

FloatingButton.Dropdown = FloatingDropdownButton;
export default FloatingButton;
