import {
  parse,
  toTex,
} from 'math-parser';

import {
  kebabToPascal,
} from 'utils/strings';

import BracesReplacer from '../js/bracesReplacer';

import slugify from 'slugify';

const DEFAULT_OP_NAME = 'OP';
const REMOVE_REGEXP = /[^\w\s]/g;
const OPS_MAP = {
  'SUM': '\\sum',
};

const createShortVariableGenerator = () => {
  const cache = {};
  const start = 'A'.charCodeAt(0);
  const end = 'Z'.charCodeAt(0);
  const len = 1 + end - start;
  const getChars = index => {
    if(index >= len) {
      return getChars(Math.floor(index / len) - 1) + getChars(index%len);
    }
    return String.fromCharCode(index+start);
  };
  let i = 0;

  return (slug) => {
    if(cache[slug]) {
      return cache[slug];
    }
    const result = getChars(i++);
    cache[slug] = result;
    return result;
  };
};

const createNameVariableGenerator = (translations = {}) => {
  const cache = {};
  return (slug) => {
    if(cache[slug]) {
      return cache[slug];
    }
    const result = kebabToPascal(
      translations[slug]
      ? slugify(translations[slug], { lower: true, remove: REMOVE_REGEXP })
      : slug
    );
    cache[slug] = result;
    return result;
  };
};

const parseFormula = (
  formula,
  shortVariables = true,
  kpiNames = {},
) => {
  let kpis = {};
  let cache = {};
  const getName = shortVariables
    ? createShortVariableGenerator()
    : createNameVariableGenerator( kpiNames );

  const kpiFormula = formula.replace(
    new BracesReplacer(),
    function(match) {
      // NOTICE: here we abuse the "pure" replace function to emit a side effect
      //         this is ugly but pretty damn convenient
      try {
        const info = JSON.parse(match);
        const slug = info.kpi;
        const base = getName(slug);
        const subindex = Object.keys(kpis[base] || {}).length;
        const identifier = `${base}_${subindex}`;

        cache[base] = info.kpi;
        kpis[base] = kpis[base] || {};
        kpis[base][subindex] = info;
        return identifier;
      } catch(err) {
        console.log('ERROR parsing', match, err);
        return 'x';
      }
    }
  );

  let ast = null;

  try{
    ast = parse(kpiFormula);
  } catch(err) {
    console.log('ERROR parsing formula', kpiFormula);
    ast = null;
  }

  return {
    ast,
    kpis,
  };
};

const adaptTexOps = kpis => ast => {
  let kpi;
  if(ast && ast.type === 'Identifier') {
    kpi = ((kpis[ast.name] || {})[(ast.subscript || {}).value]);
    if(kpi && kpi.invisible) {
      return null;
    }
  }
  
  if(ast && ast.op && typeof ast.op === 'object') {
    return {
      ...ast,
      op: OPS_MAP[ast.op.name] || ast.op.name || DEFAULT_OP_NAME,
      args: ast.args ? (ast.args || []).map(adaptTexOps(kpis)).filter(Boolean) : undefined,
    }
  }
  return {
    ...ast,
    args: ast.args ? (ast.args || []).map(adaptTexOps(kpis)).filter(Boolean) : undefined,
  };
};
export const formulaToTex = (
  formula,
  shortVariables = true,
  kpiNames = {},
) => {
  const {
    ast,
    kpis,
  } = parseFormula(formula, shortVariables, kpiNames);

  const tex = ast && toTex(
    adaptTexOps(kpis)(ast)
  );

  return {
    tex,
    kpis,
  };
};

