import { useIntl } from "react-intl";

import useOrganizations from "utils/useOrganizations";

export const goBack = () => window.history.back();

export const reload = () => window.location.reload();

export const useContactUs = (): String => {
  const intl = useIntl();
  const { organization } = useOrganizations();
  return (
    organization?.general_config?.contact_email ||
    `${intl.formatMessage({ id: "contact_email_address" })}@aplanet.org`
  );
};
