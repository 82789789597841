import React, {
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Description from 'components/Description'
import CustomSelect from 'components/CustomSelect';
import AnswerFooter from 'components/AnswerFooter';

import { getOptions } from './utils';

import {
  Empty,
  Divider,
} from 'antd';
import useOrganizations from 'utils/useOrganizations';
import { useFeatureList } from 'components/FeatureSwitch';

const QUALITATIVE_SCHEMA_TYPE= 'qualitative';

const Component = injectIntl(({
  intl,
  schema,
  schemaLabels,
  value,
}) => {
  const t = intl.messages;
  const { choice } = value || {};
  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);
  const options = getOptions(schema, schemaLabels, tableDimensions);
  return (
    <CustomSelect
      title={t.choiceanswer_placeholder}
      disabled
      selected={choice}
      options={options}
      className="KpiDetail__answer-choice-dropdown"
    />
  );
});

const TableComponent = ({
  schema,
  schemaLabels,
  value,
}) => {
  const { choice } = value || {};
  const {
    table_dimensions: tableDimensions = {},
  } = useSelector(state => state.taxonomies);
  const options = getOptions(schema, schemaLabels, tableDimensions);

  const option = useMemo(() => {
    return (options.find(({ slug, name }) => slug === choice) || {}).name || choice;
  }, [
    options,
    choice,
  ]);

  return (
    <strong>{ option }</strong>
  );
};

const SummaryComponent = injectIntl(({
  intl,
  schema, // TODO: Consider schema?
  value,
}) => {
  const t = intl.messages;
  const { choice } = value || {};
  return (
    typeof choice !== 'string'
    ? (
      <Empty
        image={'/images/icon-empty-data.svg'}
        description={t.kpi_detail_empty}
      />
    )
    : (
      <div className="Answer__summary_value">
        choice ? t.yes : t.no
      </div>
    )
  );
});

const Show = ({
  intl,
  source,
  source_params,
  schema,
  schemaLabels,
  value,
  previous_value,
  previous_comment,
  previous_attachments,
  comment,
  onShowLastPeriod,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
  onEdit,
  canWrite = true,
}) => {
  const t = intl.messages;
  const {
    permissions,
  } = useOrganizations();

  const {
    features: featureList,
  } = useFeatureList();

  const hasTargetPermissions = useMemo(() => {
    return featureList.has("targets")
      && permissions.can_configure_kpi
      && schema.type !== QUALITATIVE_SCHEMA_TYPE;
  }, [
    featureList,
    permissions.can_configure_kpi,
    schema.type
  ]);

  return (
    <section>
      <section>
        <Component
          schema={schema}
          schemaLabels={schemaLabels}
          value={value}
        />
      </section>
      <section>
        {
          !comment
          ? null
          : (
            <section>
              <Divider />
              <div className="KpiDetail__title">
                { t.privateComment }
              </div>
              <Description
                description={comment}
              />
            </section>
          )
        }
      </section>
      <Divider/>
      <AnswerFooter
        source={source}
        source_params={source_params}
        canWrite={canWrite}
        hasLastAttachments={!!previous_attachments?.length}
        hasLastComment={!!previous_comment}
        hasLastPeriod={!!previous_value}
        onShowLastPeriod={onShowLastPeriod}
        onUseLastValue={onUseLastValue}
        onUseLastWholeData={onUseLastWholeData}
        onUseLastAttachments={onUseLastAttachments}
        onUseLastPeriod={onUseLastPeriod}
        onEdit={onEdit}
        isTargetElegible={hasTargetPermissions}
      />
    </section>
  );
};

Show.Component = Component;
Show.TableComponent = TableComponent;
Show.SummaryComponent = SummaryComponent;

export default injectIntl(Show);

