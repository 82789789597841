import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import _ from 'lodash';

import ResultTable from './ResultTable';
import RecepientTable from './RecipientTable';
import CustomButton from 'components/CustomButton';

import {
  Row,
  Col,
  Tabs,
} from 'antd';

import './style.less';

const { TabPane } = Tabs;

const SurveyResults = ({
  intl,
  subjects,
  surveySubjects,
  responses,
  recipients,
  requestSurveyResponses,
  closeSurvey,
  isSurveyClosed,
  sendSurveyRecipientEmail,
  sendingSurveyEmail,
}) => {
  const t = intl.messages;
  const tabs = {
    'results': '0',
    'status_of_recipient_answer': '1'
  };
  const [activeTabKey, setActiveTabKey] = useState(tabs['results']);

  useEffect(() => {
    requestSurveyResponses();
  }, [
    requestSurveyResponses
  ]);

  const subjectsWithWeights = useMemo(
    () => {
      return surveySubjects.map((surveySubject) => {
        const subjectResponses = (responses || [])
          .filter((response) => response.survey_subject_id === surveySubject.id);
        return {
          ...surveySubject,
          ..._.find(
            subjects,
            subject => subject.id === surveySubject.kpi_category_id
          ),
          weight: Number((
            subjectResponses
              .map(response => response.weight * response.survey_invite_weight)
              .reduce((a, b) => a + b, 0)
            /
            subjectResponses
              .map(response => response.survey_invite_weight)
              .reduce((a, b) => a + b, 0)
          ).toFixed(2)) || '-',
        };
      });
    },
    [surveySubjects, responses, subjects]
  );

  return (
    <Fragment>
      <Row type="flex" justify="center">
        <Col span={24}>
          <Tabs
            activeKey={activeTabKey}
            onChange={setActiveTabKey}
            tabBarStyle={{textAlign: 'center'}}
            tabPosition='left'
          >
            <TabPane tab={t.results} key={tabs.results}>
              <ResultTable subjects={subjectsWithWeights} />
            </TabPane>
            <TabPane
              tab={t.status_of_recipient_answer}
              key={tabs.status_of_recipient_answer}
            >
              <RecepientTable
                recipients={recipients}
                subjects={subjects}
                surveySubjects={surveySubjects}
                responses={responses}
                isSurveyClosed={isSurveyClosed}
                sendReminder={sendSurveyRecipientEmail}
                sendingReminder={sendingSurveyEmail} />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      {!isSurveyClosed && (
        <Row type="flex" justify="end">
          <CustomButton
            type='primary'
            onClick={() => closeSurvey()}
          >
            {t.close_survey}
          </CustomButton>
        </Row>
      )}
    </Fragment>
  )
}

export default injectIntl(SurveyResults);
