import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import CustomButton from 'components/CustomButton';

import { useDispatch } from 'react-redux';
import { updateKpiCategory } from 'actions/api';
import useOrganizations from 'utils/useOrganizations';
import useForm from 'utils/useForm';
import validation from './validation';

import CustomCollapse from 'components/CustomCollapse';
import AplanetIcon from 'components/AplanetIcon'
import { formatMessage } from '../../intl';
import DeleteModal from './DeleteModal'
import EditCategory from './EditCategory'
import './style.less';

import { Col, Row, Tooltip } from 'antd';

const CollapseEditCategory = injectIntl(({
  intl,
  name,
  name_translations,
  is_custom,
  slug,
  handleCategoryDeleteFromTree,
  handleCategoryDeleteFromPlatform,
  reporting_structure,
  loading,
  error,
}) => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const {
    organization,
    suborganization,
    isMainOrganization,
    permissions
  } = useOrganizations();

  const submitForm = () => {
    setSubmitting(true);
    const {
      name_translations,
    } = values;

    dispatch(
      updateKpiCategory({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        category_slug: slug,
        name_translations,
      })
    );
  };

  const defaultValues = useMemo(() => {
    // OH NO! name_translations is undefined!
    const result = {
      name,
      name_translations: (name_translations || []).filter(({ locale }) => (organization?.config?.preferred_languages || []).includes(locale)),
    };
    return result;
  }, [
    name,
    name_translations,
    organization,
  ]);

  const validateForm = useMemo(() => validation(intl), [intl]);
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    //isDirty,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues,
    validationDefaults: { locale: suborganization.language },
  });

  // NOTICE: silly wrapper, we could remove it
  const handleOnSubmit = useCallback(
    () => {
      handleSubmit();
    },
    [
      handleSubmit,
    ]
  );

  const handleClose = useCallback(
    () => {
      resetForm();
      setShowForm(false)
    },
    [
      resetForm,
    ]
  );

  useEffect(() => {
    // Close the modal
    if (submitting && !loading && !error) {
      setSubmitting(false);
      handleClose();
    }
  }, [
    handleClose,
    submitting,
    loading,
    error,
  ]);

  const PanelHeader = useCallback(() => (
    <div className="KpiTreeActionsSection__actions__title">
      <span>
        {formatMessage("kpi_tree_action_section_actions_category")}
      </span>
      <span>
        {name}
      </span>
    </div>
  ),
    [name]
  )

  return (
    <>
      <CustomCollapse
        className="KpiTreeActionsSection__actions__category"
        header={<PanelHeader />}
        tooltip={{ title: formatMessage("modaleditcategory_tooltip"), placement: 'topRight' }}
      >
        {
          showForm
            ? (
              <>
                <EditCategory
                  intl={intl}
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                />
                <Row type="flex" justify="end">
                  <Col className="ModalEditKPI__footer">
                    <CustomButton
                      className="ModalEditKPI__btn-footer"
                      key="back"
                      icon={<AplanetIcon name="Cancel" />}
                      danger
                      onClick={handleClose}
                    >
                      {intl.formatMessage({ id: "modaleditkpi_cancel" })}
                    </CustomButton>
                    <CustomButton
                      className="ModalEditKPI__btn-footer"
                      key="submit"
                      type="primary"
                      icon={<AplanetIcon name="Save" faStyle="fas" />}
                      onClick={handleOnSubmit}
                    >
                      {intl.formatMessage({ id: "modaleditkpi_save" })}
                    </CustomButton>
                  </Col>
                </Row>
              </>
            ) : (
              <div className="ButtonsContainer">
                <Tooltip title={!isMainOrganization ? intl.formatMessage({ id: 'only_available_at_parent' }) : ""}>
                  <div style={{ cursor: !isMainOrganization ? 'not-allowed' : 'pointer' }}>
                    <CustomButton className="DeleteButton" danger icon={<AplanetIcon name="Bin" />} disabled={!isMainOrganization || !permissions.can_configure_kpi} onClick={() => setShowModal(true)} style={{ pointerEvents: !isMainOrganization ? 'none' : 'auto'}}>
                      {formatMessage("delete")}
                    </CustomButton>
                  </div>
                </Tooltip>
                <Tooltip title={!isMainOrganization ? intl.formatMessage({ id: 'only_available_at_parent' }) : ""}>
                  <div style={{ cursor: !isMainOrganization ? 'not-allowed' : 'pointer' }}>
                    <CustomButton className="EditButton" icon={<AplanetIcon name="Edit" />} disabled={!isMainOrganization || !is_custom || !permissions.can_configure_kpi} onClick={() => setShowForm(true)} style={{ pointerEvents: !isMainOrganization ? 'none' : 'auto'}}>
                      {formatMessage("edit")}
                    </CustomButton>
                  </div>
                </Tooltip>
              </div>
            )
        }
      </CustomCollapse>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        slug={slug}
        name={name}
        handleCategoryDeleteFromTree={handleCategoryDeleteFromTree}
        handleCategoryDeleteFromPlatform={handleCategoryDeleteFromPlatform}
        reporting_structure={reporting_structure}
      />
    </>
  )
});

export default CollapseEditCategory
