const TARGET = 'equality_plan_action';
const TARGET_INDICATOR = 'equality_plan_action_indicator';

const initialState = {
  error: null,
  fetching: false,
  pushing: false,
  updatingIndicator: false,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if (action.target === TARGET_INDICATOR) {
        return {
          ...state,
          updatingIndicator: true
        }
      }
      if (action.target !== TARGET) return state;
      return {
        ...state,
        fetching: action.method === 'GET',
        pushing: action.method === 'PUT' || action.method === 'POST',
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || (response.target !== TARGET && response.target !== TARGET_INDICATOR)) return state;
      const result = response.result;

      if (response.target === TARGET_INDICATOR) {
        const index = state.data.map(
          action => action.id
        ).indexOf(parseInt(result.equality_plan_action_id));
        let indicators = state.data[index].indicators;
        const indicatorIndex = indicators.map(
          indicator => indicator.id
        ).indexOf(parseInt(result.id));
        let updatedAction = {
          ...state.data[index],
          indicators: [
            ...indicators.slice(0, indicatorIndex),
            result,
            ...indicators.slice(indicatorIndex + 1)
          ]
        };
        return {
          ...state,
          updatingIndicator: false,
          data: [
            ...state.data.slice(0, index),
            updatedAction,
            ...state.data.slice(index + 1)
          ]
        }
      }

      switch(response.method) {
        case 'GET':
          return {
            ...state,
            fetching: false,
            data: result,
          };
        case 'POST':
          return {
            ...state,
            pushing: false,
            data: [result, ...state.data],
          };
        case 'PUT':
          const index = state.data.map(
            action => action.id
          ).indexOf(parseInt(result.id));
          return {
            ...state,
            data: [
              ...state.data.slice(0, index),
              result,
              ...state.data.slice(index + 1)
            ],
            pushing: false,
          };
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || (action.request.target !== TARGET && action.request.target !== TARGET_INDICATOR)) return state;
      return {
        ...state,
        fetching: false,
        pushing: false,
        error: action.code,
        updatingIndicator: false,
      };
    default:
      return state;
  }
};

export {
  reducer as equality_plan_action,
};
