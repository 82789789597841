import React from 'react';

import {
  Row,
  Col,
  Button,
  Card,
  Badge,
} from 'antd';

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import './style.less';

const FormItemPreview = ({
  sequence,
  onEdit,
  onDelete,
  children
}) => {

  return (
    <Card className="form-item-preview">
      <Row>
        <Col span={20}>
          { children }
        </Col>
        <Col span={4}>
          <Row gutter={5} type="flex" justify="end">
            <Col>
              {onEdit ? (
                <Button
                  icon={<EditOutlined />}
                 onClick={() => onEdit()}
                ></Button>
              ) : ''}
            </Col>
            <Col>
              {onDelete && <Button
                icon={<DeleteOutlined />}
                onClick={() => onDelete()}
              ></Button>
              }
            </Col>
            <Col>
              <Badge className='form-item-preview-badge' count={sequence} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default FormItemPreview;
