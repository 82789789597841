import React from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

const FORM_KEY_RECURRENCY_TYPES = {
  recurrent: 'recurrent',
  one_time: 'one_time',
};

const InfoTooltipContent = ({
  intl,
  type,
}) => {
  return (
    <div className="Step2__infoTooltipContent">
      <span>{intl.formatMessage({id: `step_2_info_tooltip_${type}`})}</span>
      <ol>
        <li>
          {intl.formatMessage(
            {id: `step_2_info_tooltip_${type}_1`},
            {boldValue: <b>{intl.formatMessage({id: `step_2_info_tooltip_${type}_1_bold`})}</b>}
          )}
        </li>
        <li>
          {intl.formatMessage(
            {id: `step_2_info_tooltip_${type}_2`},
            {boldValue: <b>{intl.formatMessage({id: `step_2_info_tooltip_${type}_2_bold`})}</b>}
          )}
        </li>
        {type === FORM_KEY_RECURRENCY_TYPES.recurrent ? (
          <li>
            {intl.formatMessage(
                {id: `step_2_info_tooltip_recurrent_3`},
                {boldValue: <b>{intl.formatMessage({id: `step_2_info_tooltip_recurrent_3_bold`})}</b>}
            )}
          </li>
        ) : null}
      </ol>
    </div>
  )
};

InfoTooltipContent.propTypes = {
  type: PropTypes.oneOf(['recurrent', 'one_time']).isRequired,
};

export default injectIntl(InfoTooltipContent);
