// Validation for equality plan creation form
import { EMAIL_REGEXP } from 'utils/validation';

export const fullValidation = (t) => () => {
  let errors = {};

  return errors;
};

export const budgetValidation = (t) => ({
  concept,
  amount,
}) => {
  let errors = {};
  if (!concept) {
    errors.concept = t.form_error_required;
  }

  if (!amount) {
    errors.amount = t.form_error_required;
  }

  return errors;
};

export const managerValidation = (t) => ({
  name,
  email,
  position,
  department,
}) => {
  let errors = {};
  if (!name) {
    errors.name = t.form_error_required;
  }

  if (email && !EMAIL_REGEXP.test(email)) {
    errors.email = t.form_error_invalid_email;
  }

  if (!position) {
    errors.position = t.form_error_required;
  }

  if (!department) {
    errors.department = t.form_error_required;
  }

  return errors;
};
