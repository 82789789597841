import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Bar } from 'react-chartjs-2';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import CustomChart from 'components/CustomChart';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';
import { defaultColors } from 'components/Dashboard/useColors';

import {
  requestEqualityPlanFactors,
  requestEqualityPlanPositions,
} from 'actions/api';

import CustomButton from 'components/CustomButton';
import {
  Row,
  Col,
  Tabs,
} from 'antd';
import { mean } from 'lodash';


const ConsistencyCheck = ({
  intl,
  history,
  plan,
  updatePlanProgress,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();
  const [currentFactor, setCurrentFactor] = useState();
  const [currentTab, setCurrentTab] = useState();

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanFactors(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanPositions(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetching: fetchingFactors,
    data: factors,
  } = useSelector(state => state.equality_plan_factor);
  const {
    fetching: fetchingPositions,
    data: positions,
  } = useSelector(state => state.equality_plan_position);

  useEffect(
    () => {
      if (factors && factors.length > 0 && !currentTab) {
        setCurrentFactor(factors[0]);
        setCurrentTab(factors[0].id.toString());
      }
    },
    [factors, currentTab]
  );

  const handleOnChangeTab = useCallback(
    tab => {
      setCurrentFactor(
        factors.find(factor => factor.id === parseInt(tab))
      );
      setCurrentTab(tab);
    },
    [factors]
  );

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  const handleOnClickReviewed = useCallback(
    () => {
      updatePlanProgress('consistency_check');
      handleBack();
    },
    [updatePlanProgress, handleBack]
  );

  const { data: graphData, maxPoints, avgPoints } = useMemo(
    () => {
      if (!currentFactor || !positions) {
        return {};
      }

      let data = {
        labels: [],
        datasets: [{
          label: t.equality_job_evaluation_consistency_check_positions,
          data: [],
          backgroundColor: defaultColors[0],
        }],
      };
      let allPoints = [];

      positions.forEach(position => {
        data.labels.push(`${position.code}: ${position.name}`);
        const positionFactor = position.factors
          .find(positionFactor => currentFactor.id === positionFactor.factor_id);

        if (positionFactor) {
          const levelPoints = currentFactor.levels
            .find(level => level.id === positionFactor.factor_level_id)
            .points;
          const points = (
            levelPoints
            * currentFactor.percentage
            * (plan.job_evaluation?.type_points?.[currentFactor.type] || 0)
          );
          data.datasets[0].data.push(points);
          allPoints.push(points);
        } else {
          data.datasets[0].data.push(0);
        }
      });

      return {
        data,
        maxPoints: allPoints.length && Math.max(...allPoints),
        avgPoints: allPoints.length && mean(allPoints),
      }
    },
    [currentFactor, positions, plan, t]
  );

  if (
    fetchingFactors
    || !factors
    || fetchingPositions
    || !positions
  ) {
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_consistency_check}
        handleBackOrSkip={handleBack}
      />
      <Row type="flex" justify="end">
        <CustomButton
          type="primary"
          onClick={handleOnClickReviewed}
        >
          {t.equality_plan_reviewed}
        </CustomButton>
      </Row>
      <Row>
        <Col span={24}>
          <Tabs
            activeKey={currentTab}
            onChange={handleOnChangeTab}
            tabPosition="left"
          >
            {factors.map(factor =>
              <Tabs.TabPane
                tab={`${factor.code}: ${factor.name}`}
                key={factor.id}
              >
                {currentTab === factor.id.toString() &&
                  <Row gutter={[15, 15]}>
                    <Col span={24}>
                      <h2>{t.equality_job_evaluation_consistency_check_max_points}: {maxPoints}</h2>
                      <h2>{t.equality_job_evaluation_consistency_check_avg_points}: {avgPoints}</h2>
                    </Col>
                    <Col span={24}>
                      <CustomChart
                        title={t.equality_job_evaluation_consistency_check}
                        Chart={Bar}
                        data={graphData}
                        height='300px'
                        options={{
                          maintainAspectRatio: false,
                          scales: {
                            x: {
                              stacked: true,
                            },
                            y: {
                              stacked: true,
                            },
                          },
                          plugins: {
                            legend: {
                              align: 'start',
                              position: 'bottom',
                              labels: {
                                usePointStyle: true,
                                pointStyle: 'rectRounded'
                              }
                            },
                          },
                        }}
                      />
                    </Col>
                  </Row>
                }
              </Tabs.TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
    </>
  );
}

export default withRouter(injectIntl(ConsistencyCheck));
