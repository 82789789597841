const DEBUG = false;

export const convertToUnit = (
  targetUnit,
  availableUnits = []
) => (
  payload
) => {
  const {
    value,
    unit: sourceUnit,
  } = payload || {};

  if(typeof value === 'undefined' || value === null || isNaN(value)) {
    return {
      value,
      unit: sourceUnit,
    }
  }

  const numericValue = Number(value); // Just in case
  const targetUnitObj = availableUnits.find(({ slug }) => slug === targetUnit);
  const sourceUnitObj = availableUnits.find(({ slug }) => slug === sourceUnit);

  if(!targetUnitObj || !sourceUnitObj) {
    if(DEBUG) {
      console.log(`WARNING: I dont know how to convert ${sourceUnit} to ${targetUnit}`);
    }
    return {
      value: numericValue,
      unit: targetUnit && !sourceUnit
        ? targetUnit
        : sourceUnit,
    }
  }

  return {
    value: ((numericValue - sourceUnitObj.offset) * sourceUnitObj.ratio / targetUnitObj.ratio) + targetUnitObj.offset,
    unit: targetUnit,
  }
};

export const fixValueUnit = (
  schema
) => (
  value
) => {
  // TODO: Do this for tables
  if(
    !schema ||
    schema.type !== 'quantitative' ||
    (schema.allowedUnitSlugs || []).length !== 1 ||
    !value ||
    !!value.unit
  ) {
    return value;
  }

  return {
    ...value,
    unit: schema.allowedUnitSlugs[0],
  };
};

