import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Variable from 'components/VariablesConfiguration/Variable';
import {
  createKpiVariable,
} from 'actions/api';
import useOrganizations from 'utils/useOrganizations';

import CustomButton from 'components/CustomButton';
import CustomInput from 'components/CustomInput';
import CustomInputSearch from 'components/CustomInputSearch';

import {
  Col,
  Row,
  Tabs,
  Tooltip,
  Form,
} from 'antd';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { getEmptyResultsProps } from 'tsComponents/emptyStates/emptyProps';

const standardSlugTagTitles = {
  'gri': 'GRI',
  'gri-2021': 'GRI-2021',
  'equality': 'EQ',
  'aplanet': 'Aplanet',
  'sdg': 'SDG',
  'sdgc': 'SDG-C',
  'bcorp': 'B-Corp',
  'einf': 'EINF',
  'euss': 'EUSS',
  'ungc': 'UNGC',
  'tefce': 'TEFCE',
  'prme': 'PRME',
  'sasb-hc-dy': 'SASB-HC-DY',
  'sasb-hc-di': 'SASB-HC-DI',
  'sasb-hc-dr': 'SASB-HC-DR',
  'sasb-hc-mc': 'SASB-HC-MC',
  'sasb-hc-ms': 'SASB-HC-MS',
  'sasb-hc-bp': 'SASB-HC-BP',
  'sasb-fn-ac': 'SASB-FN-AC',
  'sasb-fn-cb': 'SASB-FN-CB',
  'sasb-fn-cf': 'SASB-FN-CF',
  'sasb-fn-in': 'SASB-FN-IN',
  'sasb-fn-ib': 'SASB-FN-IB',
  'sasb-fn-mf': 'SASB-FN-MF',
  'sasb-fn-ex': 'SASB-FN-EX',
  'sasb-cg-aa': 'SASB-CG-AA',
  'sasb-cg-mr': 'SASB-CG-MR',
  'tcfd': 'TCFD',
  'sasb-fb-fr': 'SASB-FB-FR',
  'sasb-if-re': 'SASB-IF-RE',
  'sfdr': 'SFDR',
  'shift': 'SHIFT',
  'scority': 'SCORITY',
  'ghg': 'GHG',
  'neutrality': 'Neutrality',
};

const VARIABLE_STANDARDS = {
  accident_severity: ['einf'],
  action_type: [],
  age_group: ['einf', 'gri', 'gri-2021', 'euss'],
  bank_category: ['sasb-fn-cb', 'sasb-fn-ib'],
  company: [],
  complain_type: [],
  country: ['einf', 'gri', 'gri-2021', 'euss'],
  currency: [
    'gri',
    'gri-2021',
    'bcorp',
    'sdgc',
    'equality',
    'sasb-hc-dy',
    'sasb-hc-di',
    'sasb-hc-dr',
    'sasb-hc-mc',
    'sasb-hc-ms',
    'sasb-hc-bp',
    'sasb-fn-ac',
    'sasb-fn-cb',
    'sasb-fn-cf',
    'sasb-fn-in',
    'sasb-fn-ib',
    'sasb-fn-mf',
    'sasb-fn-ex',
    'einf',
  ],
  demand_level: ['equality'],
  department: ['equality'],
  disposal_method: ['euss'],
  emission_type: ['euss'],
  employee_category: ['gri', 'gri-2021', 'equality', 'einf'],
  facilities_beds_types: ['sasb-hc-dy'],
  facility: ['gri', 'gri-2021'],
  food_waste: [],
  gender: [
    'gri',
    'gri-2021',
    'sdgc',
    'euss',
    'equality',
    'einf',
    'scority',
  ],
  geographic_segment: ['sasb-fn-in'],
  governance_body: ['gri', 'gri-2021'],
  hazard: ['gri', 'gri-2021'],
  hazardous_waste: [],
  industry_asset_class: ['sasb-fn-in'],
  job_group: ['equality'],
  job_position: ['equality', 'einf'],
  location_of_operation: ['gri', 'gri-2021'],
  market: ['gri', 'gri-2021'],
  non_renewable_material: ['gri', 'gri-2021'],
  nonhazardous_waste: [],
  nonrenewable_energy_source: [],
  occupational_diseases_severity: ['einf'],
  pollutant: [],
  product_category: ['gri', 'gri-2021', 'sasb-hc-di', 'sasb-hc-ms'],
  raw_material: [],
  recipient_beneficiary: ['gri', 'gri-2021'],
  region: ['gri', 'gri-2021', 'euss'],
  renewable_energy_source: [],
  renewable_material: ['gri', 'gri-2021'],
  responsibility_level: ['equality'],
  salary_range: ['equality'],
  sector: ['sasb-fn-ib'],
  source: ['euss'],
  tax_jurisdiction: ['gri', 'gri-2021'],
  trades_product_or_asset_class: ['sasb-fn-ex'],
  transportation_type: ['sasb-hc-di'],
  type_of_business_partner: ['gri', 'gri-2021'],
  type_of_fuels_non_renewable: ['gri', 'gri-2021'],
  type_of_fuels_renewable: ['gri', 'gri-2021'],
  type_of_project: ['euss'],
  type_of_waste: ['euss'],
  type_operation_supplier: ['gri', 'gri-2021'],
  utility: ['euss'],
  voltage_category: ['euss'],
  waste: [],
  waste_composition: ['gri', 'gri-2021'],
  water_destination: ['euss'],
  water_installation: ['gri', 'gri-2021'],
  water_quality: ['euss'],
  water_treatment: ['gri', 'gri-2021'],
  working_modalities: ['einf'],
};
const { TabPane } = Tabs;

const VariablesLabel = ({
  variable,
  locale,
}) => {
  const {
    organization,
  } = useOrganizations();

  const variableStandards = useMemo(() => {
    // Only show enabled standards
    const enabledStandards = new Set([
      'aplanet',
      ...((organization.config || {}).enabled_reports || []),
    ]);
    return Object.fromEntries(
      Object.entries(VARIABLE_STANDARDS).map(([variable, standards]) => (
        [variable, standards.filter(standard => enabledStandards.has(standard))]
      ))
    );
  }, [
    organization.config,
  ]);

  return (
    <Row
      type="flex"
      align="middle"
      className={`VariablesConfiguration__variables_label${variable.status === 'deprecated' ? ' VariablesConfiguration__variables_label_deprecated' : ''}`}
      gutter={10}
    >
      <Col>
        <span>{variable.translations[locale] || variable.slug}</span>
      </Col>
      { variableStandards[variable.slug] &&
        variableStandards[variable.slug].slice(0, 3).map(standard =>
          <Col key={standard}>
            <span
              className={`VariablesConfiguration__variables_label__tag`}
            >
              {standardSlugTagTitles[standard]}
            </span>
          </Col>
        )
      }
      { variableStandards[variable.slug]?.length > 3 &&
        <Col>
          <Tooltip
            title={
              <Row gutter={[5, 5]}>
                { variableStandards[variable.slug].slice(3).map(standard =>
                  <Col key={standard}>
                    <span
                      className={`VariablesConfiguration__variables_label__tag`}
                    >
                      {standardSlugTagTitles[standard]}
                    </span>
                  </Col>
                )}
              </Row>
            }
            color={'white'}
          >
            <span
              className={`VariablesConfiguration__variables_label__tag`}
            >...</span>
          </Tooltip>
        </Col>
      }
    </Row>
  )
};

const Variables = ({
  intl,
  allVariables,
  data,
  canAdd = false,
  canManage = false,
  editingVariables,
  setEditingVariables,
}) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(data[0]?.slug);
  const [newVariableValue, setNewVariableValue] = useState();
  const [creatingVariable, setCreatingVariable] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [isNewVariableDuplicated, setIsNewVariableDuplicated] = useState(false);

  const {
    organization,
    suborganization,
  } = useOrganizations();
  const {
    pushing,
    error,
    status,
    fetchingStatus,
  } = useSelector(state => state.kpi_variable);

  useEffect(
    () => {
      if (creatingVariable && !pushing) {
        setCreatingVariable(false);

        if (!error) {
          setNewVariableValue();
          // Activate the new variable tab
          setActiveTab(allVariables[allVariables.length - 1].slug);
        }
      }
    },
    [
      creatingVariable,
      pushing,
      error,
      allVariables,
    ]
  );

  const filteredData = useMemo(
    () => {
      if (!searchTerm) {
        return data;
      }

      return data.filter(
        item => item.translations[intl.locale]?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    },
    [
      intl,
      searchTerm,
      data,
    ]
  );

  const preferredLanguages = useMemo(
    () => suborganization?.product_config?.atlas?.preferred_languages
      || [suborganization.language],
    [suborganization]
  );

  const handleAddNewVariable = useCallback(
    () => {
      if (
        allVariables.find(
          ({translations}) => preferredLanguages.map(language => (
            translations[language] || ''
          ).trim().toLowerCase()).includes(
            (newVariableValue || '').trim().toLowerCase()
          )
        )
      ) {
        setIsNewVariableDuplicated(true);
      } else {
        setCreatingVariable(true);
        dispatch(
          createKpiVariable(
            organization.slug,
            suborganization.slug,
            {
              value: newVariableValue,
            },
          )
        );
      }
    },
    [
      dispatch,
      newVariableValue,
      organization,
      suborganization,
      allVariables,
      preferredLanguages,
    ]
  );

  const handleOnChangeNewVariableValue = useCallback(
    e => {
      setIsNewVariableDuplicated(false);
      setNewVariableValue(e.target.value);
    },
    []
  );

  const hasSearch = Boolean(searchTerm)

  return (
    <Row className="VariablesConfiguration__variables" gutter={[5, 5]}>
      <Col span={24}>
        <CustomInputSearch
          placeholder={intl.formatMessage({id: 'search'})}
          value={searchTerm}
          onChange={setSearchTerm}
          allowClear
        />
      </Col>
      <Col span={24}>
        {!filteredData.length
          ? <Empty {...getEmptyResultsProps(hasSearch, false)} />
          : (
          <Tabs
            className="VariablesConfiguration__variables__tabs"
            size="small"
            tabPosition="left"
            activeKey={activeTab}
            onChange={setActiveTab}
            destroyInactiveTabPane={true}
            tabBarStyle={{width: '30%'}}
          >
            { filteredData.map(item =>
              <TabPane
                tab={<VariablesLabel variable={item} locale={intl.locale} />}
                key={item.slug}
              >
                {activeTab === item.slug &&
                  <Variable
                    allVariables={allVariables}
                    data={item}
                    canManage={canManage}
                    canDelete={canAdd}
                    editingVariables={editingVariables}
                    setEditingVariables={setEditingVariables}
                    status={status[item.slug]}
                    fetchingStatus={fetchingStatus}
                  />
                }
              </TabPane>
            )}
          </Tabs>
        )}
      </Col>
        <Col span={8} className="VariablesConfiguration__variables_actions">
          { canAdd &&
          <Tooltip
            title={
              !suborganization.parent_id
              ? null
              : intl.formatMessage({id: 'variables_configuration_add_variable_from_suborg_tooltip'})
            }
            mouseEnterDelay={1}
          >
            <Row type="flex" align="top" gutter={10}>
              <Col span={17}>
                <Form.Item
                  validateStatus={isNewVariableDuplicated ? 'error' : null}
                  help={isNewVariableDuplicated ? intl.formatMessage({id: 'variables_configuration_duplicate_variable'}) : null}
                >
                  <CustomInput
                    placeholder={intl.formatMessage({id: 'variables_configuration_add_variable_placeholder'})}
                    value={newVariableValue}
                    onChange={handleOnChangeNewVariableValue}
                    disabled={!!suborganization.parent_id}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <CustomButton
                  className="VariablesConfiguration__variables--add"
                  type="primary"
                  onClick={handleAddNewVariable}
                  disabled={!newVariableValue || creatingVariable}
                  loading={creatingVariable}
                >
                  {intl.formatMessage({id: 'variables_configuration_add_variable'})}
                </CustomButton>
              </Col>
            </Row>
          </Tooltip>
          }
        </Col>
    </Row>
  );
}

export default injectIntl(Variables);
