import _ from 'lodash';
import { interpolateViridis } from "d3-scale-chromatic";
import { Chart as ChartJS, Tooltip, Legend } from 'chart.js'; 
import { color } from 'chart.js/helpers';

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';

import { Loading } from 'tsComponents/emptyStates/Loading';
import CustomTable from 'components/CustomTable';
import History from 'components/AnalysisResult/History';
import CustomButton from 'components/CustomButton';
import Scatter from 'components/AnalysisResult/Scatter';

import {
  Row,
  Col,
  InputNumber,
  Input,
  Tabs,
} from 'antd';

import './style.less';
import { interpolateColors } from 'utils/colorGenerator';

ChartJS.register(Tooltip, Legend);

const Edit = ({
  value,
  onChange,
}) => {
  return <InputNumber
    max={5}
    value={value}
    onChange={onChange}
  />;
};

const AnalysisResult = ({
  intl,
  analysis,
  requestAnalysisResult,
  updateAnalysisResult,
  result,
  activity,
  loading,
}) => {
  const t = intl.messages;
  const [analysisResult, setAnalysisResult] = useState(result);
  const [editable, setEditable] = useState(false);
  const [comment, setComment] = useState('');
  const [activeTabKey, setActiveTabKey] = useState('graph');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    requestAnalysisResult();
  }, [
    requestAnalysisResult,
    analysis,
  ]);

  useEffect(
    () => {
      if (result) {
        setAnalysisResult(result);
        setSelectedRowKeys((result || []).map(issue => issue.id));
      }
    },
    [result, analysis]
  );

  const issues = useMemo(
    () => {
      if (!result) {
        return [];
      }

      const COLORS = interpolateColors(
        result.length,
        interpolateViridis,
        {
          colorStart: 0,
          colorEnd: 1,
          useEndAsStart: false,
        },
      );
      return result.map((issue) => ({
        id: issue.id,
        name: `${issue.subject}${issue.issue ? ` - ${issue.issue}` : ''}`,
        y: issue.y,
        x: issue.x,
        weight: 1,
        background: COLORS.shift(),
      }));
    },
    [result]
  );

  const onChangeIssue = useCallback(
    (id, fieldName, value) => {
      const index = analysisResult.map(
        item => item.id
      ).indexOf(id);
      setAnalysisResult([
        ...analysisResult.slice(0, index),
        {
          ...analysisResult[index],
          [ fieldName ]: value,
        },
        ...analysisResult.slice(index + 1)
      ]);
    },
    [analysisResult]
  );

  const graphTableColumns = useMemo(
    () => (
      [{
        dataIndex: 'name',
      }, {
        dataIndex: 'name',
        render: (_, record) => <span
          className="AnalysisResult-color"
          style={{backgroundColor: color(record.background).alpha(0.3).rgbString()}}
        ></span>
      }, {
        title: t[`analysis_${analysis.method}_matrix_axis_y`],
        dataIndex: 'y',
      }, {
        title: t[`analysis_${analysis.method}_matrix_axis_x`],
        dataIndex: 'x',
      }]
    ),
    [t, analysis]
  );

  const addIssueRowSelection = useMemo(
    () => ({
      selectedRowKeys,
      onSelect: (record, selected) => {
        let rowKeys = [];
        if (selected) {
          rowKeys = [
            ...selectedRowKeys,
            record.id,
          ]
        } else {
          rowKeys = _.difference(
            selectedRowKeys,
            [record.id]
          );
        }
        setSelectedRowKeys(rowKeys);
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        let rowKeys = [];
        if (selected) {
          rowKeys = selectedRows.map(row => row.id);
        } else {
          rowKeys = _.difference(
            selectedRowKeys,
            changeRows.map(row => row.id)
          );
        }
        setSelectedRowKeys(rowKeys);
      }
    }),
    [selectedRowKeys]
  );

  const columns = useMemo(
    () => (
      [{
        dataIndex: 'issue',
        render: (_, record) => `${record.subject}${record.issue ? `: ${record.issue}` : ''}`
      }, {
        title: t[`analysis_${analysis.method}_matrix_axis_y`],
        dataIndex: 'y',
        render: (text, record) => editable
          ? <Edit
              value={record.y}
              onChange={
                value => onChangeIssue(record.id, 'y', value)
              }
            />
          : text
      }, {
        title: t[`analysis_${analysis.method}_matrix_axis_x`],
        dataIndex: 'x',
        render: (text, record) => editable
          ? <Edit
              value={record.x}
              onChange={
                value => onChangeIssue(record.id, 'x', value)
              }
            />
          : text
      }]
    ),
    [t, editable, onChangeIssue, analysis]
  );

  const handleOnCancelEdit = useCallback(
    () => {
      setComment('');
      setAnalysisResult(result);
      setEditable(false);
    },
    [setComment, setAnalysisResult, setEditable, result]
  );

  const handleOnSave = useCallback(
    () => {
      const updated = analysisResult.filter(
        item => {
          const oldResult = result.find(result => result.id === item.id);
          return (
            (oldResult.y !== item.y)
            || (oldResult.x !== item.x)
          );
        }
      );

      if (updated.length > 0) {
        updateAnalysisResult({
          comment, updated,
        });
      }
      setComment('');
      setEditable(false);
    },
    [
      analysisResult,
      result,
      updateAnalysisResult,
      comment,
      setComment,
      setEditable
    ]
  );

  const scatterData = useMemo(
    () => {
      if (selectedRowKeys.length === 0) {
        return [];
      }

      return issues.filter(issue => selectedRowKeys.includes(issue.id))
        .map(issue => ({
          label: issue.name,
          pointRadius: 6,
          pointHoverRadius: 6,
          data: [{
            x: issue.x,
            y: issue.y,
          }],
          backgroundColor: color(issue.background).alpha(0.2).rgbString(),
          borderColor: color(issue.background).alpha(0.4).rgbString(),
          fill: false,
        }));
    },
    [selectedRowKeys, issues]
  );

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="AnalysisResult">
      <Row>
        <Col span={24}>
          <Tabs
            activeKey={activeTabKey}
            onChange={setActiveTabKey}
            tabBarStyle={{textAlign: 'center'}}
          >
            <Tabs.TabPane tab={t.analysis_result_tab_graph} key={'graph'}>
              <Row>
                <Col span={24}>
                  <Scatter
                    analysisMethod={analysis.method}
                    data={{
                      values: scatterData
                    }}
                  />
                </Col>
                <Col span={24}>
                  <CustomTable
                    pagination={false}
                    columns={graphTableColumns}
                    dataSource={issues}
                    rowKey='id'
                    rowSelection={addIssueRowSelection}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t.analysis_result_tab_data}
              key={'data'}
            >
              <Row className="AnalysisResult--table-control">
                { editable
                  ? <Col span={24}>
                      <Row
                        type="flex"
                        justify="end"
                        gutter={5}
                        className="AnalysisResult--comment"
                      >
                        <Col span={12}>
                          <Input.TextArea
                            value={comment}
                            placeholder={t.comments}
                            onChange={e => setComment(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row type="flex" justify="end" gutter={5}>
                        <Col>
                          <CustomButton
                            type="primary"
                            onClick={handleOnSave}
                            disabled={!comment}
                          >
                            { t.save }
                          </CustomButton>
                        </Col>
                        <Col>
                          <CustomButton
                            type="primary"
                            onClick={handleOnCancelEdit}
                          >
                            { t.cancel }
                          </CustomButton>
                        </Col>
                      </Row>
                    </Col>
                  : <Col span={24}>
                      <Row type="flex" justify="end">
                        <CustomButton
                          type="primary"
                          onClick={() => setEditable(true)}
                        >
                          { t.edit }
                        </CustomButton>
                      </Row>
                    </Col>
                }
              </Row>
              <Row>
                <Col span={24}>
                  <CustomTable
                    columns={columns}
                    dataSource={analysisResult}
                    rowKey='id'
                  />
                </Col>
                <Col span={24}>
                  <History activity={activity} />
                </Col>
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default injectIntl(AnalysisResult);
