import React, {useMemo} from "react";
import { isEmpty } from "lodash";
import moment from 'moment';

import {
  Step1,
  Step2,
  Step3
} from './screens';

import WarningModalContentStep1 from "./screens/Step1/WarningModalContentStep1";
import WarningModalContentStep2 from "./screens/Step2/WarningModalContentStep2";
import WarningModalContentStep3 from "./screens/Step3/WarningModalContentStep3";

import {
  validateStep1,
  validateStep2,
  validateStep3
} from "./validators";

import { DB_DATE_FORMAT } from "utils/date";

export default ({
  intl,
  values,
  handleChange,
  onFinishStepsWizard,
  errors,
  kpisWithNotExistingPeriodicitiesAndNotApply,
  activeAdmins,
  setActiveAdmins,
}) => {

  return useMemo(() => [
    {
      title: intl.formatMessage({id: "data_requests_which_indicators"}),
      content: <Step1 values={values} handleChange={handleChange} setActiveAdmins={setActiveAdmins} />,
      isValid: () => isEmpty(validateStep1(intl, values)),
      warningTooltipMessage: intl.formatMessage({id: "data_requests_step_1_warning_tooltip_message"}),
      hasWarningModal: kpisWithNotExistingPeriodicitiesAndNotApply.some(({notApplyNodes}) => notApplyNodes.length > 0),
      warningModal: {
        onOkText: intl.formatMessage({id: `data_requests_warning_modal_ok_step_1_next`}),
        onCancelText: intl.formatMessage({id: `data_requests_warning_modal_cancel_step_1_next`}),
        title: intl.formatMessage({id: `data_requests_warning_modal_title_step_1_next`}),
        stepWhenOk: 1,
        content: <WarningModalContentStep1 kpisWithNotApply={kpisWithNotExistingPeriodicitiesAndNotApply.filter(({notApplyNodes}) => notApplyNodes.length > 0)} />,
      },
    },
    {
      title: intl.formatMessage({id: "data_requests_when_send"}),
      content: <Step2 values={values} handleChange={handleChange} notificationNumberError={errors?.notif_number_value}/>,
      isValid: () => isEmpty(validateStep2(intl, values)),
      warningTooltipMessage: intl.formatMessage({id: "data_requests_step_2_warning_tooltip_message"}),
      hasWarningModal: kpisWithNotExistingPeriodicitiesAndNotApply.some(({nodesWithNotExistingPeriodicities}) => nodesWithNotExistingPeriodicities.length > 0),
      warningModal: {
        onOkText: intl.formatMessage({id: `data_requests_warning_modal_ok_step_2_next`}),
        onCancelText: intl.formatMessage({id: `data_requests_warning_modal_cancel_step_2_next`}),
        title: intl.formatMessage({id: `data_requests_warning_modal_title_step_2_next`}),
        stepWhenOk: 2,
        content: <WarningModalContentStep2 kpisWithNewPeriodicities={kpisWithNotExistingPeriodicitiesAndNotApply.filter(({nodesWithNotExistingPeriodicities}) => nodesWithNotExistingPeriodicities.length > 0)} />,
      },
    },
    {
      title: intl.formatMessage({id: "data_requests_who_receives"}),
      content: <Step3 values={values} handleChange={handleChange} activeAdmins={activeAdmins} />,
      isValid: () => isEmpty(validateStep3(intl, values)),
      warningTooltipMessage: intl.formatMessage({id: "data_requests_step_3_warning_tooltip_message"}),
      hasWarningModal: !!(values?.notif_date && moment(values?.notif_date, DB_DATE_FORMAT) < moment().startOf('day')),
      warningModal: {
        onOkText: intl.formatMessage({id: `data_requests_warning_modal_ok_step3_finish`}),
        onCancelText: intl.formatMessage({id: `data_requests_warning_modal_cancel_step3_finish`}),
        title: intl.formatMessage({id: `data_requests_warning_modal_title_step3_finish`}),
        stepWhenOk: 1,
        onCancel: () => onFinishStepsWizard(),
        content: <WarningModalContentStep3 notificationDate={values?.notif_date} />,
      },
    }
  ], [
    intl,
    values,
    handleChange,
    errors,
    kpisWithNotExistingPeriodicitiesAndNotApply,
    onFinishStepsWizard,
    setActiveAdmins,
    activeAdmins,
  ])
}
