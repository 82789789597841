import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  reportingStructureToTree
} from 'utils/reporting_structure'

import KpiTree from 'components/KpiTree';

import './style.less';

const CategoryTreeParentSelector = ({
  intl,
  reportingStructure,
  onSelectParent,
  selectedParents = [],
  isForCategoryParentSelect = false,
  selectedCategories = [],
  subtreeCategories = []
}) => {
  return (
    <section className='CategoryTreeParentSelector__wrapper'>
      <div className='CategoryTreeParentSelector__title'>
        {intl.formatMessage({ id: `tree_parent_selector_title_${isForCategoryParentSelect ? 'category' : 'kpi'}` })}
      </div>
      <div className='CategoryTreeParentSelector__subtitle'>
        {intl.formatMessage({ id: `tree_parent_selector_subtitle_${isForCategoryParentSelect ? 'category' : 'kpi'}` })}
      </div>
      <div
        className="CategoryTreeParentSelector__treeWrapper"
        type="flex"
      >
          <KpiTree
            categories={reportingStructureToTree(reportingStructure)}
            checkedCategories={selectedParents}
            previousSelectedCategories={selectedCategories}
            setCheckedCategories={onSelectParent}
            subtreeCategories={subtreeCategories}
            showOnlyCategories
            clearSelection={() => onSelectParent([])}
            showNotApplyToggle={false}
            isCreateMode={true}
          />
      </div>
    </section>
  )

};

CategoryTreeParentSelector.propTypes = {
  reportingStructure: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectParent: PropTypes.func.isRequired,
  selectedParents: PropTypes.array,
  selectedCategories: PropTypes.array,
  subtreeCategories: PropTypes.array,
};

export default injectIntl(CategoryTreeParentSelector);
