import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowDown,
  faArrowUp,
  faCalculator,
  faChartBar,
  faChartLine,
  faChartPie,
  faDownload,
  faEquals,
  faQuestion,
  faSpider,
} from '@fortawesome/free-solid-svg-icons';

const setup = () => {
  library.add(
    faArrowDown,
    faArrowUp,
    faCalculator,
    faChartBar,
    faChartLine,
    faChartPie,
    faDownload,
    faEquals,
    faQuestion,
    faSpider,
  );
};

export default setup;

