import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';

import Description from 'components/Description';
import BooleanAnswer from 'components/BooleanAnswer';
import ChoiceAnswer from 'components/ChoiceAnswer';
import QualitativeAnswer from 'components/QualitativeAnswer';
import QuantitativeAnswer from 'components/QuantitativeAnswer';
import TableAnswer from 'components/TableAnswer';
import CustomButton from 'components/CustomButton';
import AnswerFooter from 'components/AnswerFooter';

import useOrganizations from 'utils/useOrganizations';

import {
  Divider,
} from 'antd';
import { useFeatureList } from 'components/FeatureSwitch';

const QUALITATIVE_SCHEMA_TYPE= 'qualitative';

const COMPONENTS_BY_TYPE = {
  boolean: BooleanAnswer.Show,
  choice: ChoiceAnswer.Show,
  qualitative: QualitativeAnswer.Show,
  quantitative: QuantitativeAnswer.Show,
  table: TableAnswer.Show,
  default: () => <div>-</div>,
};

const Component = injectIntl(({
  schema,
  schemaLabels,
  config,
  value,
  previous_value,
}) => {
  const {
    components = {},
  } = schema;

  const tupleValues = value || {};
  const previousTupleValues = previous_value || {};

  return (
    <React.Fragment>
      {
        components.map(schema => {
          const name = schema.name;
          const value = tupleValues[name];
          const previous_value = previousTupleValues[name];
          const Component = COMPONENTS_BY_TYPE[schema.type] || COMPONENTS_BY_TYPE.default;
          const displayName = ((schemaLabels || {}).componentLabels || {})[name] || name;
          const componentSchemaLabels = ((schemaLabels || {}).components || {})[name] || {};

          return (
            <section
              key={name}
              className='KpiDetail__answer-tuple'
            >
              <span className='KpiDetail__answer-tuple-label' title={displayName}>
                {
                  schema.type === 'table' && displayName === 'table'
                  ? null
                  : displayName
                }
              </span>
              <section
                className={`KpiDetail__answer-tuple-value KpiDetail__answer-tuple-value-${schema.type}`}
              >
                <Component
                  schema={schema}
                  schemaLabels={componentSchemaLabels}
                  config={config}
                  value={value}
                  previous_value={previous_value}
                />
              </section>
              <section className={`KpiDetail__answer-tuple-filler`} />
            </section>
          );
        })
      }
    </React.Fragment>
  );
});

const SummaryComponent = injectIntl(({
  intl,
  schema, // TODO: Consider schema?
  value,
  onClick,
}) => {
  const t = intl.messages;
  return (
    <CustomButton
      onClick={onClick}
    >
      { t.data_answer_card_show_more }
    </CustomButton>
  );
});

const Show = ({
  intl,
  source,
  source_params,
  schema,
  schemaLabels,
  value,
  previous_value,
  previous_comment,
  previous_attachments,
  comment,
  onShowLastPeriod,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
  onEdit,
  canWrite = true,
}) => {
  const t = intl.messages;

  const {
    suborganization,
    permissions,
  } = useOrganizations();

  const {
    features: featureList,
  } = useFeatureList();

  const { config = {} } = suborganization;

  const hasTargetPermissions = useMemo(() => {
    return featureList.has("targets")
      && permissions.can_configure_kpi
      && schema.type !== QUALITATIVE_SCHEMA_TYPE;
  }, [
    featureList,
    permissions.can_configure_kpi,
    schema.type
  ]);

  return (
    <React.Fragment>
      <section>
        <Component
          schema={schema}
          schemaLabels={schemaLabels}
          config={config}
          value={value}
          previous_value={previous_value}
        />
      </section>
      <section>
        {
          !comment
          ? null
          : (
            <section>
              <Divider/>
              <div className="KpiDetail__title">
                { t.privateComment }
              </div>
              <Description
                description={comment}
              />
            </section>
          )
        }
      </section>
      <Divider />
      <AnswerFooter
        source={source}
        source_params={source_params}
        canWrite={canWrite}
        hasLastAttachments={!!previous_attachments?.length}
        hasLastComment={!!previous_comment}
        hasLastPeriod={!!previous_value}
        onShowLastPeriod={onShowLastPeriod}
        onUseLastValue={onUseLastValue}
        onUseLastWholeData={onUseLastWholeData}
        onUseLastAttachments={onUseLastAttachments}
        onUseLastPeriod={onUseLastPeriod}
        onEdit={onEdit}
        isTargetElegible={hasTargetPermissions}
      />
    </React.Fragment>
  );
};

Show.Component = Component;
Show.SummaryComponent = SummaryComponent;

export default injectIntl(Show);

