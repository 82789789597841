import React, {useMemo, useCallback} from 'react';
import { uniqBy } from "lodash";
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import useOrganizations from 'utils/useOrganizations';

import UsersSearchSelector from './UsersSearchSelector';
import AddExternalUser from './AddExternalUser';
import UsersTable from './UsersTable';

import './style.less';

const FORM_KEY_USERS = 'users';
const MAX_USERS_SELECTED = 10;
const DEFAULT_COMMENT = '';

const Step3 = ({
    intl,
    handleChange,
    values,
    activeAdmins,
}) => {

    const {
        organization,
    } = useOrganizations();

    const activeAdminsToSelect = useMemo(() => {
        const usersAlreadySelected = values?.users.map(u => u.slug) || [];
        return (activeAdmins || []).filter(({member_slug}) => !usersAlreadySelected.includes(member_slug));
    }, [
        activeAdmins,
        values?.users,
    ]);

    const onSelectInternalUser = useCallback((userSelected) => {
        const userData = activeAdminsToSelect.find(u => u.member_slug === userSelected);
        const user = {
            slug: userData.member_slug,
            name: userData.member_name,
            email: userData.member_email,
            avatar: userData.member_avatar,
            language: userData.member_language,
            comment: DEFAULT_COMMENT,
            internal: true
        };
        handleChange(FORM_KEY_USERS)([...values?.users, user]);
    }, [
        handleChange,
        activeAdminsToSelect,
        values?.users,
    ]);

    const onAddExternalUser = useCallback((externalUser) => {
        const externalUsers = Array.isArray(externalUser) ? externalUser : [externalUser];
        const users = uniqBy([...values?.users, ...externalUsers], 'email');
        handleChange(FORM_KEY_USERS)(users);
    }, [
        handleChange,
        values?.users,
    ]);

    const onChangeUser = useCallback((key, newValue, user) => {
        const changedUsers = [...values?.users].map(u => u.slug === user.slug ? {...u, [key]: newValue} : u);
        handleChange(FORM_KEY_USERS)([ ...changedUsers]);
    }, [
        handleChange, 
        values?.users,
    ]);

    const onDeleteUser = useCallback((user) => {
        const restUsers = [...values?.users].filter(u => u.slug !== user.slug);
        handleChange(FORM_KEY_USERS)([ ...restUsers]);
    }, [
        handleChange,
        values?.users,
    ]);

    const allUserEmails = useMemo(() => {
        const currentUsers = values?.users.map(u => u.email) || [];
        const adminEmails = activeAdminsToSelect?.map(admin => admin.member_email) || [];
        return Array.from((new Set([
            ...currentUsers,
            ...adminEmails,
        ])));
    }, [
        activeAdminsToSelect,
        values?.users,
    ]);

    const cannotAddMoreUsers = useMemo(
        () => values?.users?.length >= MAX_USERS_SELECTED,
        [values?.users]
    );

    return (
        <div className="Step3">
            <div className="Step3__message">{intl.formatMessage({id: 'step_3_who_to_send'})}</div>
            <div className="Step3__totalkpis">
                {intl.formatMessage(
                    {id: 'step_3_total_kpis_selected'},
                    {value: values?.selected_kpis?.length || 0}
                )}
            </div>
            <div className="Step3__forms">
                <div className="Step3__UsersSearchSelector">
                    <UsersSearchSelector
                        usersData={activeAdminsToSelect}
                        onSelectUser={onSelectInternalUser}
                        disabled={cannotAddMoreUsers}
                    />
                </div>
                <div className="Step3__AddExternalUser">
                    <AddExternalUser
                        languages={organization?.config?.preferred_languages || []}
                        onAddExternalUser={onAddExternalUser}
                        activeAdmins={activeAdminsToSelect}
                        allUserEmails={allUserEmails}
                        disabled={cannotAddMoreUsers}
                    />
                </div>
                <div className="Step3__UsersTable">
                    <UsersTable
                        usersSelected={values?.users || []}
                        languages={organization?.config?.preferred_languages || []}
                        onDeleteUser={onDeleteUser}
                        onChangeUser={onChangeUser}
                    />
                </div>
            </div>
        </div>
    );
};

Step3.propTypes = {
    activeAdmins: PropTypes.arrayOf(PropTypes.object),
    handleChange: PropTypes.func.isRequired,
    values: PropTypes.object,
};

export default injectIntl(Step3);
