import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

import EsgTypes from 'components/DataSources/EsgTypes';
import Sdgs from 'components/DataSources/Sdgs';
import Tags from 'components/DataSources/Tags';
import DataSourcesPermissionMember from 'components/DataSources/PermissionMember';
import DataSourcesPermissionCheckbox from 'components/DataSources/PermissionCheckbox';
import { requestDataSourcesInformation } from 'actions/api';
import useOrganizations from 'utils/useOrganizations';

import {
  CaretRightOutlined,
  CaretDownOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import Avatar from 'components/Avatar';

import './style.less';
import DataSourcesMandatoryDataCheckbox from './MandatoryDataCheckbox';
import T from 'components/T';
import DataSourcesPermissionValidationLevels from './PermissionValidationLevels';


const DEFAULT_APPROVAL_LEVELS = 1;

const DataSourcesOrganizationKpiRow = ({
  intl,
  kpi,
  organization,
  level = 0,
}) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [fetchingChildren, setFetchingChildren] = useState(false);
  const [numberOfRows, setNumberOfRows] = useState(0);
  const [showAllMembers, setShowAllMembers] = useState(false);
  const cellStyle = useMemo(
    () => ({
      borderLeftWidth: `${(level * 6)}px`,
    }),
    [level],
  );
  const {
    organization: rootOrganization,
  } = useOrganizations();
  const {
    members,
    // restricted,
    fetching,
  } = useSelector(
    state => state.data_sources_kpi_permissions[kpi.slug]?.[organization.slug] || {}
  );
  const {
    mandatory_data,
  } = useSelector(
    state => state.data_sources_kpi_config[kpi.slug]?.[organization.slug] || {}
  );

  useEffect(
    () => {
      if (!fetching && members && members.length) {
        if (showAllMembers || (members.length < 5)) {
          setNumberOfRows(members.length - 1);
        } else if (members.length > 4) {
          setNumberOfRows(3);
        }
      }
    },
    [
      showAllMembers,
      fetching,
      members,
    ],
  );

  useEffect(() => {
    if (!organization.parent_id) {
      setTimeout(
        () => dispatch(
          requestDataSourcesInformation(
            rootOrganization.slug,
            organization.slug,
            kpi.slug,
          )
        ), 100
      );
    }
  }, [
    rootOrganization,
    organization,
    kpi,
    dispatch,
  ]);

  useEffect(
    () => {
      if (!expanded && !fetching && fetchingChildren) {
        setExpanded(true);
        setFetchingChildren(false);
      }
    },
    [
      expanded,
      fetching,
      fetchingChildren,
    ]
  );

  const handleOnExpand = useCallback(
    () => {
      if (!expanded) {
        setFetchingChildren(true);
        dispatch(
          requestDataSourcesInformation(
            rootOrganization.slug,
            organization.slug,
            kpi.slug,
            true,
          )
        );
      } else {
        setExpanded(!expanded);
      }
    },
    [
      dispatch,
      expanded,
      rootOrganization,
      organization,
      kpi,
    ]
  );

  const approvalLevels = useMemo(() => {
    return organization.product_config?.atlas?.approval_levels
      || DEFAULT_APPROVAL_LEVELS;
  }, [
    organization,
  ]);

  const approvalLevelOptions = useMemo(() => {
    return new Array(approvalLevels).fill(0).map((_, index) => {
      const level = index + 1;
      return {
        label: (
          <T
            level_of_approval_n={{
              level,
            }}
          />
        ),
        value: level,
      };
    });
  }, [
    approvalLevels,
  ]);

  return (
    <>
      <tr className="DataSourcesOrganizationKpiRow">
        <td></td>
        <td className="DataSourcesOrganizationKpiRow__esgs">
          {level === 0 && <EsgTypes kpi={kpi} />}
        </td>
        <td className="DataSourcesOrganizationKpiRow__sdgs">
          {level === 0 && <Sdgs kpi={kpi} />}
        </td>
        <td className="DataSourcesOrganizationKpiRow__tags">
          {level === 0 && <Tags kpi={kpi} /> }
        </td>
        <td
          className={`DataSourcesOrganizationKpiRow__organization${organization.parent_id ? ` DataSourcesOrganizationKpiRow__organization--child` : ''}`}
          style={cellStyle}
          onClick={handleOnExpand}
        >
          { organization.children.length > 0
            ? <>
                { fetchingChildren && <LoadingOutlined />}
                { expanded && <CaretDownOutlined /> }
                { (!fetchingChildren && !expanded) && <CaretRightOutlined /> } <Avatar
                    shape="circle"
                    src={organization.logo_small || undefined}
                    name={organization.name || undefined}
                    icon={<InfoCircleOutlined />}
                  /> {organization.name}
              </>
            : <>
                <Avatar
                  shape="circle"
                  src={organization.logo_small || undefined}
                  name={organization.name || undefined}
                  icon={<InfoCircleOutlined />}
                /> {organization.name}
              </>
          }
        </td>
        <td>
          { members?.[0] &&
            <DataSourcesPermissionMember member={members[0]} />
          }
        </td>
        <td>
          { members?.[0] &&
            <DataSourcesPermissionCheckbox
              organization_slug={rootOrganization.slug}
              suborganization_slug={organization.slug}
              kpi_slug={kpi.slug}
              member={members[0]}
              permission={'can_read_kpi'}
            />
          }
        </td>
        <td>
          { members?.[0] &&
            <DataSourcesPermissionCheckbox
              organization_slug={rootOrganization.slug}
              suborganization_slug={organization.slug}
              kpi_slug={kpi.slug}
              member={members[0]}
              permission={'can_write_kpi'}
            />
          }
        </td>
        <td>
          { members?.[0] &&
            <DataSourcesPermissionCheckbox
              organization_slug={rootOrganization.slug}
              suborganization_slug={organization.slug}
              kpi_slug={kpi.slug}
              member={members[0]}
              permission={'can_validate_kpi'}
            />
          }
        </td>
        <td>
          { members?.[0] &&
            <DataSourcesPermissionValidationLevels
              organization_slug={rootOrganization.slug}
              suborganization_slug={organization.slug}
              kpi_slug={kpi.slug}
              member={members[0]}
              approvalLevelOptions={approvalLevelOptions}
            />
          }
        </td>
        <td>
          { members?.[0] &&
            <DataSourcesPermissionCheckbox
              organization_slug={rootOrganization.slug}
              suborganization_slug={organization.slug}
              kpi_slug={kpi.slug}
              member={members[0]}
              permission={'can_undo_validate_kpi'}
            />
          }
        </td>
        <td>
          { members?.[0] &&
            <DataSourcesPermissionCheckbox
              organization_slug={rootOrganization.slug}
              suborganization_slug={organization.slug}
              kpi_slug={kpi.slug}
              member={members[0]}
              permission={'can_configure_kpi'}
            />
          }
        </td>
        <td>
          { members?.[0] &&
            <DataSourcesPermissionCheckbox
              organization_slug={rootOrganization.slug}
              suborganization_slug={organization.slug}
              kpi_slug={kpi.slug}
              member={members[0]}
              permission={'can_report_kpi'}
            />
          }
        </td>
        <td>
          { mandatory_data &&
            <DataSourcesMandatoryDataCheckbox
              organization_slug={rootOrganization.slug}
              suborganization_slug={organization.slug}
              kpi_slug={kpi.slug}
              mandatory_data={mandatory_data}
              type='value'
            />
          }
        </td>
        <td>
          { mandatory_data &&
            <DataSourcesMandatoryDataCheckbox
              organization_slug={rootOrganization.slug}
              suborganization_slug={organization.slug}
              kpi_slug={kpi.slug}
              mandatory_data={mandatory_data}
              type='attachment'
            />
          }
        </td>
        <td>
          { mandatory_data &&
            <DataSourcesMandatoryDataCheckbox
              organization_slug={rootOrganization.slug}
              suborganization_slug={organization.slug}
              kpi_slug={kpi.slug}
              mandatory_data={mandatory_data}
              type='comment'
            />
          }
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      { [...Array(numberOfRows).keys()].map(index =>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td
              className="DataSourcesOrganizationKpiRow__organization--empty-child"
              style={cellStyle}
            ></td>
            { members[index + 1]
              ? <>
                  <td>
                    { members[index + 1] &&
                      <DataSourcesPermissionMember member={members[index + 1]} />
                    }
                  </td>
                  <td>
                    <DataSourcesPermissionCheckbox
                      organization_slug={rootOrganization.slug}
                      suborganization_slug={organization.slug}
                      kpi_slug={kpi.slug}
                      member={members[index + 1]}
                      permission={'can_read_kpi'}
                    />
                  </td>
                  <td>
                    <DataSourcesPermissionCheckbox
                      organization_slug={rootOrganization.slug}
                      suborganization_slug={organization.slug}
                      kpi_slug={kpi.slug}
                      member={members[index + 1]}
                      permission={'can_write_kpi'}
                    />
                  </td>
                  <td>
                    <DataSourcesPermissionCheckbox
                      organization_slug={rootOrganization.slug}
                      suborganization_slug={organization.slug}
                      kpi_slug={kpi.slug}
                      member={members[index + 1]}
                      permission={'can_validate_kpi'}
                    />
                  </td>
                  <td>
                  <DataSourcesPermissionValidationLevels
                    organization_slug={rootOrganization.slug}
                    suborganization_slug={organization.slug}
                    kpi_slug={kpi.slug}
                    member={members[index + 1]}
                    approvalLevelOptions={approvalLevelOptions}
                  />
                  </td>
                  <td>
                    <DataSourcesPermissionCheckbox
                      organization_slug={rootOrganization.slug}
                      suborganization_slug={organization.slug}
                      kpi_slug={kpi.slug}
                      member={members[index + 1]}
                      permission={'can_undo_validate_kpi'}
                    />
                  </td>
                  <td>
                    <DataSourcesPermissionCheckbox
                      organization_slug={rootOrganization.slug}
                      suborganization_slug={organization.slug}
                      kpi_slug={kpi.slug}
                      member={members[index + 1]}
                      permission={'can_configure_kpi'}
                    />
                  </td>
                  <td>
                    <DataSourcesPermissionCheckbox
                      organization_slug={rootOrganization.slug}
                      suborganization_slug={organization.slug}
                      kpi_slug={kpi.slug}
                      member={members[index + 1]}
                      permission={'can_report_kpi'}
                    />
                  </td>
                </>
              : <>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </>
            }
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        )
      }
      { !showAllMembers && ((members?.length || 0) > 4) &&
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td
            className="DataSourcesOrganizationKpiRow__organization--empty-child"
            style={cellStyle}
          ></td>
          <td>
            { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
            <a
              onClick={(a) => {a.preventDefault();setShowAllMembers(true)}}
            >{ intl.formatMessage({ id: "data_management_sources_permissions_load_more" }) }</a>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      }
      { expanded &&
        organization.children.map(childOrganization =>
          <DataSourcesOrganizationKpiRow
            key={childOrganization.id}
            intl={intl}
            kpi={kpi}
            organization={childOrganization}
            level={level + 1}
          />
        )
      }
    </>
  );
};

export default injectIntl(DataSourcesOrganizationKpiRow);
