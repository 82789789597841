module.exports = {

  /* --- General --- */
  // Colors
  '@primary-color': '#147F74',
  '@secondary-color': '#169C8E',
  '@third-color': '#107066',
  '@hover-color': '#E5E8E8',
  '@sidebar-selected-color': '#D9EAE8',
  '@white': '#FFF',
  '@black': '#000',
  '@right': '#1CA131',
  '@wrong': '#C13838',
  '@alert': '#D38106',
  '@primary-text-color': '#273941',
  '@secondary-text-color': '#7B8989',
  '@text-selection-bg': '#D9EAE8',
  '@text-color-inverse': '#273941',
  '@border-color-base': '#E5E8E8',
  '@border-color-split': '#E5E8E8',
  '@equality-color': '#8362cf',

  // Background
  '@content-background': '#FCFCFC',
  '@login-background': '#FCFCFC',
  '@layout-header-background':' #FCFCFC',
  '@layout-sider-background-light': '#FCFCFC',
  '@layout-body-background': '#FCFCFC',
  '@menu-bg':'#FCFCFC',

  // Especific colors
  '@success-color': '#147F74',
  '@warning-color': '#FFC600',
  '@danger-color': '#E37C78',
  '@error-color': '#D65050',
  '@block-color': '#FB003F',


  // Box shadow
  '@primary-box-shadow': '1px 0 0px 0px rgba(123, 137, 137, 0.1), 0 1px 0px 0px rgba(123, 137, 137, 0.1), 0 -1px 0px 0px rgba(123, 137, 137, 0.1), -1px 0px 0px 0px rgba(123, 137, 137, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.05)',

  // Border
  '@primary-border-radius': '6px',
  '@primary-border-width': '2px',
  /* ------ */

  /* --- Menu --- */
  '@menu-item-vertical-margin': '0px',
  '@menu-item-boundary-margin': '2px',
  '@menu-inline-toplevel-item-height': '44px',
  '@menu-item-height': '44px',
  /* ------ */

  /* --- Header --- */
  '@header-padding': '28px 30px 28px',
  /* ------ */

  /* --- Table --- */
  '@table-header-bg': '#f2f4f5',
  '@table-header-color': '#273941',
  '@table-border-radius-base': '6px',
  '@table-row-hover-bg': '#F6F7F7',
  /* ------ */

  /* --- Card --- */
  '@card-radius': '6px',
  '@card-header-size': '16px',
  '@card-shadow': '1px 0 0px 0px rgba(123, 137, 137, 0.1), 0 1px 0px 0px rgba(123, 137, 137, 0.1), 0 -1px 0px 0px rgba(123, 137, 137, 0.1), -1px 0px 0px 0px rgba(123, 137, 137, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
  /* ------ */

  /* --- Dropwdown --- */
  '@dropdown-selected-color': '#E5E8E8',
  /* ------ */

  /* --- Inputs --- */
  '@input-radius': '6px',
  '@textarea-radius': '6px',
  '@input-border-color': '#E5E8E8',
  '@input-hover-border-color': '#CAD0D0',
  '@input-number-handler-border-color': '#E5E8E8',
  '@select-border-color': '#E5E8E8',
  /* ------ */

  /* -- Pagination --- */
  // General
  '@pagination-item-size': '28px',
  '@pag-item-shadow': '1px 0 0px 0px rgba(123, 137, 137, 0.1), 0 1px 0px 0px rgba(123, 137, 137, 0.1), 0 -1px 0px 0px rgba(123, 137, 137, 0.1), -1px 0px 0px 0px rgba(123, 137, 137, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
  '@pag-item-border-radius': '6px',
  // Items
  '@pag-item-bg-color': '#FFF',
  '@pag-item-hover-bg-color': '#E5E8E8',
  '@pag-item-color': '#273941',
  // Active
  '@pag-active-item-bg-color': '#147F74',
  '@pag-active-item-hover-bg-color': '#147F74',
  '@pag-active-item-color': '#FFF',
  /* ------ */

  /* -- Dashboards --- */
  '@primary-dashboards-color': '#1BA7E6',
  '@secondary-dashboards-color': '#5BC77B',
  /* ------ */

  /* --- Buttons --- */
  // Primary button
  '@primary-button-bg-color': '#147F74',
  '@primary-button-border-color': '#147F74',
  '@primary-button-color': '#FFF',
  '@primary-button-hover-bg-color': '#169C8E',
  '@primary-button-hover-border-color': '#169C8E',
  '@primary-button-hover-color': '#FFF',
  '@primary-button-focus-bg-color': '#107066',
  '@primary-button-focus-border-color': '#107066',
  '@primary-button-focus-color': '#FFF',
  // Default button
  '@default-button-bg-color': '#FFF',
  '@default-button-border-color': '#E5E8E8',
  '@default-button-color': '#273941',
  '@default-button-hover-bg-color': '#E5E8E8',
  '@default-button-hover-border-color': '#E5E8E8',
  '@default-button-hover-color': '#273941',
  '@default-button-focus-bg-color': '#147F74',
  '@default-button-focus-border-color': '#147F74',
  '@default-button-focus-color': '#FFF',
  // Disabled button
  '@disabled-button-color': 'rgba(0, 0, 0, 0.25)',
  '@disabled-button-bg-color': '#f5f5f5',
  '@disabled-border-color': '#d9d9d9',
  // General
  '@btn-font-weight': 'regular',
  '@btn-border-radius-base': '6px',
  '@btn-border-radius-sm': '6px',
  '@btn-border-width': '2px',
  '@btn-shadow': 'none',
  '@btn-primary-shadow': 'none',
  '@btn-text-shadow': 'none',
  '@btn-padding-base': '0 12px',
  '@btn-padding-sm': '0 10px',
  /* ------ */

  /* --- Links --- */
  '@link-color': '#147F74',
  /* ------ */

  /* --- Fonts --- */
  '@regular-font': 'Roboto',
  '@font-size-base': '15px',
  '@font-size-lg': '17px',
  '@font-size-sm': '12px',
  /* ------ */

  /* --- Headers --- */
  '@header-font-size': '17px',
  '@h1-size': '51px',
  '@h2-size': '21px',
  '@h3-size': '17px',
  '@h4-size': '17px',
  /* ------ */

  /* --- Progress --- */
  '@progress-default-color': 'rgba(19, 134, 122, 1)',
  '@progress-confirmed-color': 'rgba(19, 134, 122, 0.3)',
  '@progress-remaining-color': 'rgba(123, 137, 137, 0.2)',
  /* ------ */

  /* --- Tags --- */
  '@tag-default-bg': 'transparent',
  '@tag-default-color': 'rgba(68, 68, 68, 1)',
  '@tag-font-size': '17px',
  '@tag-border': '0px',
  'tag-palette': ['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'],
  /* ------ */

  /* --- Tabs --- */
  '@tabs-title-font-size': '16px',
  '@tabs-title-font-size-lg': '21px',
  '@tabs-title-font-size-sm': '15px',
  /* ------ */

  /* --- Checkbox --- */
  '@checkbox-size': '24px',
  '@checkbox-color':'#147F74',
  '@checkbox-check-color': '#FFF',
  '@checkbox-border-width': '2px',
  /* ------ */

  /* --- Time picker --- */
  '@time-picker-selected-bg': '#E5E8E8',
  /* ------ */

  /* --- SDG --- */
  'sdg-background-colors': {
    'no-poverty': '#FAD3D8',
    'zero-hunger': '#F8EDD7',
    'good-health-and-wellbeing': '#C9E2C7',
    'quality-education': '#F3D2D5',
    'gender-equality': 'FCD9D4',
    'clean-water-and-sanitation': '#D4F2F9',
    'affordable-and-clean-energy': '#FEF3CE',
    'decent-work-and-economic-growth': '#ECD1D9',
    'industry-innovation-and-infrastructure': '#FCE1D5',
    'reduced-inequalities': '#F8D0E1',
    'sustainable-cities-and-communities': '#FEEBD4',
    'responsible-consumption-and-production': '#F2E8D5',
    'climate-action': '#D9E5DA',
    'life-below-water': '#D2EAF6',
    'life-on-land': '#DEF1DA',
    'peace-justice-and-strong-institutions': '#CDE1EC',
    'partnership-for-the-goals': '#D1DAE1'
  },
  'sdg-colors': {
    'no-poverty': '#FF334C',
    'zero-hunger': '#E7B551',
    'good-health-and-wellbeing': '#52984D',
    'quality-education': '#DF3B4D',
    'gender-equality': '#FF513D',
    'clean-water-and-sanitation': '#61BBD1',
    'affordable-and-clean-energy': '#F5C119',
    'decent-work-and-economic-growth': '#CE4D74',
    'industry-innovation-and-infrastructure': '#FF7E43',
    'reduced-inequalities': '#E11484',
    'sustainable-cities-and-communities': '#FDA029',
    'responsible-consumption-and-production': '#CA9B46',
    'climate-action': '#326F36',
    'life-below-water': '#36A1D8',
    'life-on-land': '#6BC059',
    'peace-justice-and-strong-institutions': '#1E77A7',
    'partnership-for-the-goals': '#2E6084'
    },
  /* ------ */

  /* --- COLORS FOR GROUPS --- */
  'group-colors': ['#00B46E', '#FF544A', '#FFA424', '#CA9058', '#5AC8FA', '#FFD83E', '#7371E9', '#489FFD', '#C9F2E5', '#FF456F', '#9AD96C'],
  '@no-group-color': '#AFB8B8',
  /* ------ */
};
