import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { find, sortBy, reverse } from 'lodash'
import * as Sentry from '@sentry/react';

import CustomModal from 'components/CustomModal';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';

import {
  Table,
  Row,
  Col,
} from 'antd';

import {
  FileWordOutlined,
  FilePdfOutlined
} from '@ant-design/icons';

import AuthorizedLink from 'containers/AuthorizedLink';
import MainLayout from 'components/MainLayout'
import LoaderModal from 'components/LoaderModal';
import CustomButton from 'components/CustomButton';
import T from 'components/T';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import { formatDateOnly } from 'utils/date';
import useOrganizations from 'utils/useOrganizations';
import useAuthorizedRequest from 'utils/useAuthorizedRequest';

import {
  deleteReport,
  requestReportHistory,
  exportReportDocument,
} from 'actions/api';

import {
  reportDownloaded,
} from 'actions/app';

import { useEventTracking } from 'hooks/useEventTracking';

import './style.less';

const DEFAULT_REPORT_FORMATS = [
  'odt',
  'pdf',
  'docx',
];

const ICONS = {
  odt: FileWordOutlined,
  pdf: FilePdfOutlined,
  docx: FileWordOutlined,
  default: FileWordOutlined,
};

const MIME_TYPES = {
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  odt: 'application/vnd.oasis.opendocument.text',
  pdf: 'application/pdf',
};

const Reports = ({
  intl,
  history,
}) => {
  const t = intl.messages

  // Store and actions: Get organization and kpi_detail state
  const dispatch = useDispatch();

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const [currentReport, setCurrentReport] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [reportToDelete, setReportToDelete] = useState();
  const eventTracking = useEventTracking();

  const {
    loading: exportingDocument,
    data: reportHistory,
    report,
  } = useSelector(state => state.report_history);

  // Request new data from API
  useEffect(() => {
    // Get slug and period from URL
    if(
      !organization ||
      !suborganization
    ) return

    // Dispach action to request history
    dispatch(
      requestReportHistory(
        organization.slug,
        suborganization.slug,
      )
    )
  }, [
    organization,
    suborganization,
    dispatch,
  ])

  const documentEventTracking = useCallback((event_name, params) => {

    eventTracking.capture(event_name, {
      ...params,
      //report_tab_slug: activeTab
    });

  }, [/*activeTab,*/ eventTracking]);

  const deleteReportAction = useCallback((report) => {
    dispatch(deleteReport(organization.slug, suborganization.slug, report.slug))
    setShowDeleteModal(false);
    
    documentEventTracking('report.delete', {
      report_name: report.title
    });

  }, [dispatch, organization.slug, suborganization.slug, documentEventTracking])
  
  const handleDownload = useCallback((content) => {
    let anchor = linkRef.current;

    if(!anchor) {
      console.log('ERROR: cannot handle download without an anchor');
      return;
    }

    const windowUrl = window.URL || window.webkitURL;
    const blob = content instanceof Blob
      ? content
      : new Blob(
        [content],
        { type: MIME_TYPES[report.url?.split('.').pop()] }
      );

    const url = windowUrl.createObjectURL(blob);
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', report.url?.split('/').pop());
    anchor.click();
    windowUrl.revokeObjectURL(url);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const handleError = useCallback((error) => {
    console.log('Error fetching', error);
  }, []);

  const {
    linkRef,
    onClick: downloadFile,
  } = useAuthorizedRequest({
    url: report.url,
    onSuccess: handleDownload,
    onError: handleError,
  });

  useEffect(
    () => {
      if (!report.processing && report.done) {
        downloadFile();
        dispatch(
          reportDownloaded()
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report]
  );

  useEffect(
    () => {
      if (currentReport && !exportingDocument) {
        setCurrentReport();
      }
    },
    [exportingDocument, currentReport]
  );

  const exportDocument = useCallback((slug, format) => {
    dispatch(
      exportReportDocument(
        organization.slug,
        suborganization.slug,
        slug,
        format,
      )
    );
  }, [
    dispatch,
    organization,
    suborganization,
  ]);

  useEffect(
    () => {
      if (currentReport && !exportingDocument) {
        setCurrentReport();
      }
    },
    [exportingDocument, currentReport]
  );

  const onExportDocument = useCallback(
    (report, format) => {
      if (report.urls[format]) {
        window.open(report.urls[format], '_blank');
      } else {
        setCurrentReport(report.slug);
        exportDocument(report.slug, format);
      }
      documentEventTracking('report.download', {
        report_name: report.title, 
        file_extension: format 
      });
    },
    [exportDocument, documentEventTracking],
  );

  const reportFormats = useMemo(() => {
    return (organization.config || {}).report_formats || DEFAULT_REPORT_FORMATS;
  }, [
    organization,
  ]);

  const sortedReportHistory = useMemo(() => reverse(sortBy(reportHistory, ['created_at'])), [reportHistory])

  const columns = useMemo(() => {
    return [
      {
        title: t.reports_modal_history_name,
        dataIndex: 'title',
        render: (title, record) => (
          <div>
            <div
              className="Reports__historytable__title"
            >
              { title }
            </div>
            <div>
              <T
                reports_modal_history_date_range={{
                  start: formatDateOnly(record.start_date, t.reports_modal_history_date_format, intl),
                  end: formatDateOnly(record.end_date, t.reports_modal_history_date_format, intl),
                }}
              />
            </div>
          </div>
        ),
      },
      {
        title: t.reports_modal_history_creationdate,
        dataIndex: 'created_at',
        render: (created_at) => formatDateOnly(created_at, t.reports_modal_history_date_format, intl)
      },
      {
        title: '',
        dataIndex: 'slug',
        render: (slug, report) => (
          <div
          >
            {
              reportFormats.map(format => {
                const Icon = ICONS[format] || ICONS.default;
                return (
                  report.urls[format]
                    ? <AuthorizedLink
                        href={report.urls[format]}
                        download={report.urls[format].split('/').pop()}
                        mimeType={MIME_TYPES[report.urls[format].split('.').pop()]}
                        key={format}
                        style={{
                          display: 'inline-block',
                          marginRight: 5,
                        }}
                        onClick={() => documentEventTracking('report.download', {
                          report_name: report.title, 
                          file_extension: format 
                        })}
                      >
                        <CustomButton
                          type="secondary"
                          icon={<Icon />}
                        >{ (format || '').toUpperCase() }</CustomButton>
                      </AuthorizedLink>
                    : <CustomButton
                        type="secondary"
                        onClick={() => onExportDocument(report, format)}
                        icon={<Icon />}
                        key={format}
                        style={{
                          display: 'inline-block',
                          marginRight: 5,
                        }}
                      >{ (format || '').toUpperCase() }</CustomButton>
                );
              })
            }
          </div>
        ),
      },
      {
        title: '',
        dataIndex: 'slug',
        render: (slug, report) => (
          <CustomButton onClick={() => {setShowDeleteModal(true);setReportToDelete(report)}}>
            {t.delete}
          </CustomButton>
        ),
      }
    ];
  }, [
    t,
    intl,
    onExportDocument,
    reportFormats,
    documentEventTracking
  ]);
  return (
      <MainLayout className="ReportsLayout">
        <Sentry.ErrorBoundary
          fallback={
          <ErrorBoundaryFallback
            titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
            buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
            descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_reports_message' })}
            customErrorImage="/images/error_image.png"
          />
        }>
          <Row type="flex" justify="space-between" className="ReportsLayout__header">
            <Col>
              <span className="ReportsLayout__header__title">
                {intl.formatMessage({id: 'reports'})}
              </span>
            </Col>
            <Col>
              <CustomButton
                type="primary"
                onClick={() => history.push(`/data/fill`)}
                style={{
                  marginBottom: 10,
                }}
              >
                { intl.formatMessage({id: 'reports_generate_button'}) }
              </CustomButton>
            </Col>
          </Row>
            <Table
              className="Reports__historytable"
              columns={columns}
              dataSource={sortedReportHistory}
              rowClassName={(_, index) => index%2 ? 'Table-row-odd' : 'Table-row-even'}
              rowKey='slug'
              bordered={false}
              size="small"
              pagination={false}
              scroll={{ y: '75vh' }}
            />
            <LoaderModal
              visible={report.processing}
              message={t.downloading_report}
            />
            { /* eslint-disable-next-line */ }
            <a ref={linkRef}></a>
            <CustomModal 
              shown={showDeleteModal}
              setShown={setShowDeleteModal}
              onOk={() => deleteReportAction(reportToDelete)} 
              title={t.report_delete_title}
              okText={t.delete}
            >
              {
                reportToDelete && 
                <T
                  report_delete={{
                    value: find(reportHistory, ['slug', reportToDelete.slug])?.title,
                  }}
                />
              }
            </CustomModal>
        </Sentry.ErrorBoundary>
      </MainLayout>
)}

export default injectIntl(
  withRouter(
    Reports
  )
);
