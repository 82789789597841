import React from 'react';
import { injectIntl } from 'react-intl';
import './style.less';

import FileItem from 'components/FileItem';
import KpiLabel from 'components/KpiLabel';
import ReportingStandardTag from 'components/ReportingStandardTag';
import PeriodLabel from 'components/PeriodLabel';
import EsgLogo from 'components/EsgLogo';
import T from 'components/T';
import { 
  Row,
  Col,
  Avatar,
Divider
 } from 'antd';

const DEFAULT_AVATAR_PROPS = {
  icon: 'user',
};

const getAvatarProps = (avatar) => {
  if(avatar) {
    return { src: avatar };
  }
  return DEFAULT_AVATAR_PROPS;
};

const FilesCard = ({
  intl,
  onClick,
  name,
  avatar,
  status,
  email,
  kpi_name,
  kpi_slug,
  esg_type,
  attachments = [],
  selected,
  select,
  id,
  date,
  type,
  loading,
  showKpi,
  period,
  urlBase = 'kpi',
  standard_info = [],
  collection_frequency
}) => {

  return (
    <section className="FilesCard__card">
      <Row
        className="FilesCard__header"
        type="flex"
      >
        <Col
          flex={1}
          className="FilesCard__header__kpi"
        >
        <Row
        type="flex"
        gutter={20}
        >
          <Col span={24} className="FilesCard__header__kpi__attachments_count">
            <T
                filescard_uploaded={{
                count: attachments.length,
                }}
            />
          </Col>
          {/* <Col span={24} className="FilesCard__header__kpi__categories">
                Category 1
          </Col> */}
          <Col span={24} className="FilesCard__header__kpi__info">
            <Row>
            {
              esg_type ? (
                <Col>
                  <EsgLogo type ={esg_type} />
                </Col>
              ):null
            }
            {
              standard_info ? (
            
              <Col>
              <div className="FilesCard__header__standard_container">
              {
                standard_info.filter(info => info.code && info.standard !== 'aplanet').map(info => {
                  const { standard } = info;
                  return (
                    <section className="FilesCard__header__standard_item" key={standard}>
                        {/* <span>
                        { t[`reporting_standard_${standard}`] }
                      </span> */}
                        <ReportingStandardTag
                          standard={standard}
                          showTooltip
                          {...info}
                        />
                    </section>
                  )
                })
              }
              </div>
              </Col>
              ):null
              }
              {
                kpi_name && kpi_slug ? (
                  <Col>
                      <KpiLabel
                        name={kpi_name}
                        slug={kpi_slug}
                        esg_type={esg_type}
                        urlBase={urlBase}
                        period={period?.label}
                        truncateLength={60}
                      />
                  </Col>
                ): null
              }
              
              { collection_frequency ? (
                <Col offset={1}>
                  <span className={`Files__periodicity ${collection_frequency}`}>{intl.formatMessage({id: `datamanagement_files_filter_periodicity_${collection_frequency}`})}</span>
                </Col>

              ): null}
              {
                period ? (
                  <Col>
                    <PeriodLabel
                      period={period} />
                  </Col>
                ): null}
            </Row>
          </Col>
        </Row>
        </Col>
        <Col
          className="FilesCard__header__avatar"
        >
          <Avatar
            className='FilesCard__avatar'
            size={32}
            {...getAvatarProps(avatar)}
          />
          <div className='FilesCard__member__info'>
            <span className="FilesCard__name">{name}</span>
            <span className="FilesCard__name">{email}</span>
          </div>
        </Col>
      </Row>
      <Divider className="FilesCard__divider" />
      <Row
        className="FilesCard__body"
      >
        {
          attachments.map(
            (attachment) => (
              <>
              <Col span={24}>
              <FileItem
                key={attachment.url}
                {...attachment}
              />
              </Col>
              </>
            )
          )
        }
      </Row>
      </section> 
  )
};

export default injectIntl(FilesCard);
