import React, {useEffect, useState, useMemo} from 'react';
import { injectIntl } from 'react-intl';

import Description from 'components/Description'
import CustomToggle from 'components/CustomToggle';
import AnswerFooter from 'components/AnswerFooter';

import { useFeatureList } from 'components/FeatureSwitch';

import DataTargets from 'components/DataTargets';

import {
  Empty,
  Checkbox,
  Divider,
  Row,
  Col
} from 'antd';
import useOrganizations from 'utils/useOrganizations';

const QUALITATIVE_SCHEMA_TYPE= 'qualitative';

const Component = injectIntl(({
  intl,
  schema, // TODO: Consider schema?
  value,
}) => {
  const t = intl.messages;
  const { boolean } = value || {};
  return (
    <CustomToggle
      disabled
      options={[t.yes, t.no]}
      checked={boolean}
    />
  );
});

const TableComponent = ({
  schema, // TODO: Consider schema?
  value,
}) => {
  const { boolean, target_value = null } = value || {};
  const {
    suborganizationFeatures: featureList,
  } = useFeatureList();

  if ( featureList.has('targets') && target_value ){
    return (

      <Row justify="space-between">
          <Col>
            { typeof boolean !== 'boolean' ? null :
                  <Checkbox
                    disabled
                    checked={boolean}
                  />
            }
          </Col>
          <Col>
            <DataTargets.BooleanTableShow
              target={target_value}
            />
          </Col>

      </Row>
    );

  }else{
    return typeof boolean !== 'boolean' ? null : (
      <Checkbox
        disabled
        checked={boolean}
      />
    );
  }
};

const SummaryComponent = injectIntl(({
  intl,
  schema, // TODO: Consider schema?
  value,
}) => {
  const t = intl.messages;
  const { boolean } = value || {};
  return (
    typeof boolean !== 'boolean'
    ? (
      <Empty
        image={'/images/icon-empty-data.svg'}
        description={t.kpi_detail_empty}
      />
    )
    : (
      <div className="Answer__summary_value">
        { boolean ? t.yes : t.no }
      </div>
    )
  );
});

const Show = ({
  intl,
  source,
  source_params,
  schema,
  value,
  previous_value,
  previous_comment,
  previous_attachments,
  comment,
  onShowLastPeriod,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
  onEdit,
  canWrite = true,
  target,
  onTarget
}) => {
  const t = intl.messages;
  const {
    suborganizationFeatures: featureList,
  } = useFeatureList();
  const {
    permissions,
  } = useOrganizations();

  const [hasTarget, setHasTarget] = useState(false);

  useEffect(()=>{
    if(featureList.has('targets') && target){
      setHasTarget(true);
    }
  },[target,featureList]);

  const hasTargetPermissions = useMemo(() => {
    return featureList.has("targets")
      && permissions.can_configure_kpi
      && schema.type !== QUALITATIVE_SCHEMA_TYPE
  }, [
    featureList,
    permissions.can_configure_kpi,
    schema.type
  ]);

  return (
    <section>
      <section>
        <Row>
          <Col span={6}>
          <Component
          schema={schema}
          value={value}
        />
          </Col>
          <Col span={6} offset={2}>
            <DataTargets
              schema={schema}
              kpi_value={value}
              target={target}
              justify="start"
            />
          </Col>
        </Row>

      </section>
      <section>
        {
          !comment
          ? null
          : (
            <section>
              <Divider />
              <div className="KpiDetail__title">
                { t.privateComment }
              </div>
              <Description
                description={comment}
              />
            </section>
          )
        }
      </section>
      <Divider/>
      <AnswerFooter
        source={source}
        source_params={source_params}
        canWrite={canWrite}
        hasLastAttachments={!!previous_attachments?.length}
        hasLastComment={!!previous_comment}
        hasLastPeriod={!!previous_value}
        onShowLastPeriod={onShowLastPeriod}
        onUseLastValue={onUseLastValue}
        onUseLastWholeData={onUseLastWholeData}
        onUseLastAttachments={onUseLastAttachments}
        onUseLastPeriod={onUseLastPeriod}
        onEdit={onEdit}
        onTarget={onTarget}
        hasTarget={hasTarget}
        isTargetElegible={hasTargetPermissions}
      />
    </section>
  );
};

Show.Component = Component;
Show.TableComponent = TableComponent;
Show.SummaryComponent = SummaryComponent;

export default injectIntl(Show);

