import React, { useMemo } from 'react';
import { flattenDeep } from 'lodash';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import CustomTagSimple from 'components/CustomTagSimple';
import { TooltipTagList } from 'tsComponents/TooltipTagList';

const KpiAssociationsTags = ({
    kpi_associations = {},
    max = -1
}) => {
    
    const associatedKpis = useMemo(() => {
    
        return flattenDeep(
            Object.keys(kpi_associations || {}).map( standard => {
                return kpi_associations[standard].filter(({ code }) => !!code);
            })
        );
    
    }, [kpi_associations]); 

    return (
        <div className="KpiAssociationsTags">
        {
            (max > 0 ? associatedKpis.slice(0, max+1) : associatedKpis)
            .map(({ name, code }, index) => (
                <Tooltip 
                    key={code}
                    title={ index > (max-1) && associatedKpis.length > max 
                                    ? <TooltipTagList tags={ associatedKpis.slice(max).map( kpi => kpi.code ) } /> 
                                    : name 
                    }
                    placement="bottom" 
                    overlayClassName='KpiAssociationsTags__tooltip'
                >
                    <CustomTagSimple 
                      colorclass={'dark-grey'} 
                      name={ index > (max-1) && associatedKpis.length > max ? '...' : code } 
                    />
                </Tooltip>
            ))
        }
        </div>
    );
};

KpiAssociationsTags.propTypes = {
    kpi_associations: PropTypes.object.isRequired,
    max: PropTypes.number
}

export default KpiAssociationsTags;