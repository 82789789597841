export const fullValidation = (t) => ({
  start_date,
  end_date,
}) => {
  let errors = {};

  if (!start_date) {
    errors.start_date = t.form_error_required;
  }

  if (!end_date) {
    errors.end_date = t.form_error_required;
  }

  return errors;
};
