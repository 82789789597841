import React, {useMemo} from 'react';

import CustomTag from 'components/CustomTag';


const DataSourcesTags = ({
  kpi,
}) => {
  const tags = useMemo(
    () => kpi.tags,
    [kpi]
  );

  return (
    <>
      { tags.map(tag => <CustomTag key={tag} name={tag} /> )}
    </>
  );
}

export default DataSourcesTags;