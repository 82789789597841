import React, {
  useCallback,
  useMemo
} from 'react';
import { injectIntl } from 'react-intl';
import './style.less';

import {
  formatLong,
  formatDateShort,
} from 'utils/date';

import FileItem from 'components/FileItem';
import KpiLabel from 'components/KpiLabel';
import PeriodLabel from 'components/PeriodLabel';
import Avatar from 'components/Avatar';
import Answer from 'components/Answer';
import CustomTag from 'components/CustomTag';
import T from 'components/T';
import SdgMiniLogo from 'components/SdgMiniLogo'
import ReportingStandardTag from 'components/ReportingStandardTag';
import Description from 'components/Description'

import {
  Divider,
} from 'antd';

import {
  DeleteOutlined,
} from '@ant-design/icons';

import {
  Row,
  Col,
} from 'antd';
import {useSDGsClustering} from "../../hooks/sdg";
import useOrganizations from 'utils/useOrganizations';
import { DELETED_USER_SLUG, getUserName } from 'utils/user';

const COMPONENT_PER_SCHEMA = {
  qualitative: Answer.Show,
  default: Answer.Summary,
};

const DEFAULT_AVATAR_PROPS = {
  icon: 'user',
};

const getAvatarProps = (avatar) => {
  if(avatar) {
    return { src: avatar };
  }
  return DEFAULT_AVATAR_PROPS;
};

const Content = injectIntl(({
  intl,
  type,
  schema,
  schemaLabels,
  activity_params = {},
  onOpenAnswer,
  ...rest
}) => {
  const t = intl.messages;

  const {
    name,
    email,
    value,
    status,
    add_tags,
    remove_tags,
    added_members,
    removed_members,
    role,
    guidelines_org,
    guidelines_std,
    level,
    comment,
    applies,
    collection_frequency,
    cycle_date,
    sdgs,
    standard_info,
    type : type_monitoring //for target
  } = activity_params;
  const clusterSDGs = useSDGsClustering();
  const clusteredSDGs = useMemo(() => clusterSDGs(sdgs), [sdgs, clusterSDGs]);
  const {
    suborganization,
  } = useOrganizations();

  const assignees = useMemo(() => {
    if(type !== 'assignee') {
      return;
    }

    return {
      added_members: added_members.map(id => suborganization.users.find(user => user.member_id === id)),
      removed_members: removed_members.map(id => suborganization.users.find(user => user.member_id === id)),
    }
  }, [
    suborganization,
    removed_members,
    added_members,
    type,
  ]);

  const handleOpenAnswer = useCallback(() => {
    onOpenAnswer({
      value,
      attachments: [],
      mode: type,
    });
  }, [
    onOpenAnswer,
    value,
    type,
  ])
  const requestToName = name ? name : rest?.name;
  const requestToEmail = email ? email : rest?.email;
  switch(type) {
    case 'request':
    case 'delete_request':
      return (
        <div className="HistoryCard__body">
          <div>
            { t[`datamanagement_history_${type}_title`] }
            {requestToEmail && requestToName ? (
              <>
                { ' ' }
                <strong>{ requestToName  === DELETED_USER_SLUG ? intl.formatMessage({ id: requestToName }) : requestToName }</strong>
                {requestToEmail  === DELETED_USER_SLUG ? null : (<>{ ' ' }<span>({requestToEmail})</span> </>)}
              </>
            ) : null}
          </div>
        </div>
      );
    case 'change':
    case 'suggestion':
      if(!value) {
        return (
          null
        );
      }

      const Component = COMPONENT_PER_SCHEMA[schema.type] || COMPONENT_PER_SCHEMA.default;

      return (
        <div className="HistoryCard__body">
          <div
            className='HistoryCard__answer-title'
          >
            { t[`datamanagement_history_${type}_title`] }
          </div>
          <Component
            schema={schema}
            schemaLabels={schemaLabels}
            value={value}
            comment={comment}
            onClick={handleOpenAnswer}
          />
          {
            !comment
            ? null
            : (
              <>
                <Divider/>
                <div className="KpiDetail__title KpiDetail__title__comment">
                  { t.datamanagement_history_comment }
                </div>
                <Description
                  className='text'
                  description={comment}
                />
              </>
            )
          }
        </div>
      );
    case 'attachment':
      return (
        <div className="HistoryCard__body">
          <div
            className='HistoryCard__answer-title'
          >
            { t[`datamanagement_history_${type}_${status}_title`] }
          </div>
          <FileItem
            {...activity_params}
          />
        </div>
      );
    case 'delete_attachment':
      return (
        <div className="HistoryCard__body">
          <div
            className='HistoryCard__answer-title'
          >
            { t[`datamanagement_history_${type}_${status}_title`] }
          </div>
          <FileItem
            {...activity_params}
          />
        </div>
      );
    case 'assignee':
      return (
        <div className="HistoryCard__body">
          <Row gutter={[0, 20]}>
            { !!assignees.added_members.length &&
              <Col span={24}>
                <div className='HistoryCard__answer-title'>
                  { intl.formatMessage({ id: `datamanagement_history_assignee_added_members_${role}` }) }
                </div>
                <div>
                  {
                    assignees.added_members.map(
                      member => <CustomTag key={member.member_id} name={member.member_name} />
                    )
                  }
                </div>
              </Col>
            }
            { !!assignees.removed_members.length &&
              <Col span={24}>
                <div className='HistoryCard__answer-title'>
                  { intl.formatMessage({ id: `datamanagement_history_assignee_removed_members_${role}` }) }
                </div>
                <div>
                  {
                    assignees.removed_members.map(
                      member => <CustomTag key={member.member_id} name={member.member_name} />
                    )
                  }
                </div>
              </Col>
            }
          </Row>
        </div>
      )
    case 'meta':
      if(add_tags) {
        return (
          <div className="HistoryCard__body">
            <div className='HistoryCard__answer-title'>
              { t[`datamanagement_history_${type}_addtags_title`] }
            </div>
            <div>
              {
                add_tags.map(
                  tag => <CustomTag key={tag} name={tag} />
                )
              }
            </div>
          </div>
        );
      } else if(remove_tags) {
        return (
          <div className="HistoryCard__body">
            <div className='HistoryCard__answer-title'>
              { t[`datamanagement_history_${type}_removetags_title`] }
            </div>
            <div>
              {
                remove_tags.map(
                  tag => <CustomTag key={tag} name={tag} />
                )
              }
            </div>
          </div>
        );
      } else if(guidelines_std || guidelines_org) {
        return(
          <div className="HistoryCard__body">
            <div className='HistoryCard__answer-title'>
              { t[`datamanagement_history_${type}_guidelines_title`] }
            </div>
            <p className="HistoryCard__guidelines">
              <Description
                className='text'
                description={guidelines_std || guidelines_org}
              />
            </p>
          </div>
        );
      } else if(typeof applies !== 'undefined') {
        return(
          <div className="HistoryCard__body">
            <div className='HistoryCard__answer-title'>
              { t[`datamanagement_history_${type}_applies_title`] }
            </div>
            <p className="HistoryCard__guidelines">
              {
                applies
                ? t[`datamanagement_history_${type}_applies_true`]
                : t[`datamanagement_history_${type}_applies_false`]
              }
            </p>
          </div>
        );
      } else if(collection_frequency || cycle_date) {
        return(
          <div className="HistoryCard__body">
            <div className='HistoryCard__answer-title'>
              { t[`datamanagement_history_${type}_settings_title`] }
            </div>
            <p className="HistoryCard__guidelines">
              <T
                datamanagement_history_meta_settings={{
                  frequency: t[`collection_frequency_${collection_frequency}`].toLowerCase(),
                  cycle_date: formatDateShort(`1970-${cycle_date} 12:00:00.000Z`, intl),
                }}
              />
            </p>
          </div>
        );
      } else if(sdgs || standard_info) {
        return(
          <div className="HistoryCard__body">
            <div className='HistoryCard__answer-title'>
              { t[`datamanagement_history_${type}_standards_title`] }
            </div>
            <p className="HistoryCard__guidelines_standards">
              <section>
                <span>
                  { t.sdg }
                </span>
                {
                  (clusteredSDGs).map(sdg => (
                    <SdgMiniLogo
                      key={sdg.slug}
                      sdg={sdg}
                    />
                  ))
                }
              </section>
              {
                (standard_info || []).filter(info => info.values && info.values.some(v => v.code)).map(info => {
                  const {
                    standard,
                    values = null
                  } = info || {};
                  return (
                    <section
                      key={standard}
                    >
                      <span>
                        { t[`reporting_standard_${standard}`] }
                      </span>
                      {
                        values ? 
                        values.map(val => (
                          <ReportingStandardTag
                            standard={standard}
                            showTooltip
                            {...val}
                          />
                        )) :
                        <ReportingStandardTag
                          standard={standard}
                          showTooltip
                          {...info}
                        />
                      }
                    </section>
                  );
                })
              }
            </p>
          </div>
        );
      } else if (type_monitoring === 'target' && (typeof value === "object" && Object.keys(value).length > 0) ) {
        const Component = COMPONENT_PER_SCHEMA[schema.type] || COMPONENT_PER_SCHEMA.default;
        return (
          <div className="HistoryCard__body">
            <div
              className='HistoryCard__answer-title'
            >
              { t[`datamanagement_history_target_title`] }
            </div>
            <Component
              schema={schema}
              schemaLabels={schemaLabels}
              value={value}
              comment={comment}
              onClick={handleOpenAnswer}
            />
          </div>
        );
      }
      // TODO: More types of meta changes
      return null;
    case 'approval':
    case 'rejection':
      return (
        <div className="HistoryCard__body">
          <div
            className='HistoryCard__answer-title'
          >
            {
              type === 'approval' && level === 0
                ? (
                  null
                ) : (
                  <span>
                    { t[`datamanagement_history_${type}_title`] } { level }
                  </span>
                )
            }
          </div>
          {
            !comment ? null : (
              <p>
                { comment }
              </p>
            )
          }
        </div>
      );
    case 'rename':
      return (
        <div className="HistoryCard__body">
          <div>
            { t.datamanagement_history_rename_from } <strike>{ activity_params.from }</strike> { t.datamanagement_history_rename_to } <strong>{ activity_params.to }</strong>
          </div>
        </div>
      );
    case 'bulk':
    default:
      return null;
  }
});

const HistoryCard = ({
  intl,
  onClick,
  type,
  created_at,
  member_name,
  member_avatar,
  member_email,
  member_status,
  kpi_name,
  kpi_slug,
  kpi_status,
  esg_type,
  schema,
  schemaLabels,
  period,
  period_start,
  period_end,
  showKpi: preferShowKpi,
  onOpenAnswer,
  urlBase = 'kpi', // NOTICE: For KpiLabel link
  ...rest
}) => {
  const t = intl.messages;

  const handleOpenAnswer = useCallback((props = {}) => {
    onOpenAnswer({
      ...props,
      name: member_name,
      avatar: member_avatar,
      email: member_email,
      updated_at: created_at,
      kpi_name,
      schema,
      schemaLabels,
      period,
    });
  }, [
    onOpenAnswer,
    member_name,
    member_avatar,
    member_email,
    created_at,
    kpi_name,
    schema,
    schemaLabels,
    period,
  ]);

  const showKpi = preferShowKpi && kpi_name && kpi_slug;

  const memberData = useMemo(
    () => ({ 
      name: member_name, 
      status: member_status 
    }),
    [member_name, member_status]
  );

  return (
    <section className="HistoryCard__card">
      <Row
        className="HistoryCard__header"
        gutter={20}
        type="flex"
      >
        <Col>
          {member_status === 'deleted' 
          ?
            <Avatar className='HistoryCard__avatar' size={32} icon={<DeleteOutlined />} />
          :
            <Avatar
              className='HistoryCard__avatar'
              size={32}
              {...getAvatarProps(member_avatar)}
            />
          }
        </Col>
        <Col
          className="HistoryCard__header_maincol"
        >
          <div>
            <span>
              <span className="HistoryCard__name">{ getUserName(memberData, intl) }</span>
              { ' ' }
              {
                (() => {
                  switch(type) {
                    case 'approval':
                      if(!rest.activity_params || !rest.activity_params.level) {
                        return t[`datamanagement_history_approval_nolevel`];
                      } else {
                        return t[`datamanagement_history_approval`];
                      }
                    case 'meta':
                      if( (showKpi || !period ) &&  !(rest.activity_params.type && rest.activity_params.type === 'target') ) {
                        return t[`datamanagement_history_meta_noperiod`];
                      } else if( rest.activity_params.type && rest.activity_params.type === 'target' && ['add','edit','delete'].indexOf(rest.activity_params.action) !== -1 ) {
                              return t[`datamanagement_history_target_${rest.activity_params.action}${!period? '_noperiod':''}`];
                      } else {
                        return t[`datamanagement_history_meta`];
                      }
                    case 'change':
                      if(!rest.activity_params || !rest.activity_params.value) {
                        return t[`datamanagement_history_change_novalue`];
                      } else if(showKpi || !period) {
                        return t[`datamanagement_history_change_noperiod`];
                      } else {
                        return t[`datamanagement_history_change`];
                      }
                    case 'assignee':
                      return intl.formatMessage({ id: 'datamanagement_history_assignee' })
                    default:
                      return t[`datamanagement_history_${type}`];
                  }
                })()
              }
              {
                showKpi || !period
                ? null
                : (
                  <PeriodLabel
                    period={{
                      label: period,
                      start_date: period_start,
                      end_date: period_end,
                    }}
                  />
                )
              }
            </span>
          </div>
          {
            !showKpi ? null :
            <div className="HistoryCard__labelrow">
              <KpiLabel
                name={kpi_name}
                slug={kpi_slug}
                esg_type={esg_type}
                urlBase={urlBase}
                period={period}
                status={kpi_status}
              />
              { !period ? null : ' › ' }
              {
                !period ? null :
                <PeriodLabel
                  period={{
                    label: period,
                    start_date: period_start,
                    end_date: period_end,
                  }}
                />
              }
            </div>
          }
        </Col>
        <Col
          className="HistoryCard__header_lastcol"
        >
          { formatLong(created_at, intl) }
        </Col>
      </Row>
      <div>
        <Content
          type={type}
          schema={schema}
          schemaLabels={schemaLabels}
          onOpenAnswer={handleOpenAnswer}
          {...rest}
        />
      </div>
    </section>
  );
};

export default injectIntl(HistoryCard);

