import React, { useCallback, useMemo, useRef } from "react";
import { injectIntl } from 'react-intl';

import {
  useDispatch, useSelector,
} from 'react-redux';

import useOrganizations from "utils/useOrganizations";

import { useEventTracking } from 'hooks/useEventTracking';

import {
  updateBulkManageFromClipboard,
} from 'actions/bulk';


const NO_OP = () => {};


const ReadOnlyValue = ({
  kpi,
  value,
  mode,
  changeMode = NO_OP,
  position,
  cellType,
  canEdit = true,
  intl,
}) => {
  const timer = useRef()
  const dispatch = useDispatch();

  const taxonomies = useSelector(state => state.taxonomies);
  const eventTracking = useEventTracking();

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const handleOnClick = useCallback(() => {
    if (mode !== 'selected') {
      setTimeout(() => changeMode('selected'), 0);
    }
  }, [
    mode,
    changeMode,
  ]);

  const handleOnDoubleClick = useCallback(
    () => {
      if ((mode !== 'edit') && canEdit) {
        changeMode('edit');
      }
    },
    [
      mode,
      canEdit,
      changeMode,
    ]
  );

  const onClickHandler = useCallback(event => {
    // Avoid single click event when user double clicks
    clearTimeout(timer.current);

    if (event.detail === 1) {
      timer.current = setTimeout(handleOnClick, 200);
    } else if (event.detail === 2) {
      handleOnDoubleClick();
    }
  }, [
    timer,
    handleOnClick,
    handleOnDoubleClick,
  ]);

  const preferredUnits = useMemo(() => {
    const config = (suborganization && suborganization.config) ||
      (organization && organization.config) ||
      {}
    return config.preferred_units || {};
  }, [
    organization,
    suborganization,
  ]);

  const metrics = useMemo(() => {
    return (
      taxonomies.metrics && taxonomies.metrics.length > 0
      ? taxonomies.metrics
      : []
    ).map(({ metrics }) => metrics || []).reduce((acc, arr) => acc.concat(arr), []);
  }, [
    taxonomies,
  ]);

  const handleOnPaste = useCallback(e => {
    if (mode !== 'selected') {
      return;
    }

    eventTracking.capture('bulkManage.paste', {
      organization_name: organization.name,
      suborganization_name: suborganization.name,
      indicator_name: kpi.name,
      period: kpi.period,
      destination: 'main table',
    });

    const clipboardData = e.clipboardData.getData('text');
    let data = clipboardData.split(/\r\n?|\n/g);

    if (data[data.length - 1] === '') {
      // Remove the last empty element inserted by browser
      data.pop();
    }

    dispatch(
      updateBulkManageFromClipboard({
        cellType,
        position,
        clipboardData: data,
        preferredUnits,
        metrics,
        intl,
      })
    );
  }, [
    mode,
    dispatch,
    cellType,
    position,
    preferredUnits,
    metrics,
    kpi,
    intl,
    eventTracking,
    organization,
    suborganization,
  ]);

  return (
    <div
      className={`ReadOnlyValue${canEdit ? '' : ' ReadOnlyValue--disabled'}`}
      onClick={onClickHandler}
      onPaste={handleOnPaste}
    >
      {value}
    </div>
  );
}

export default injectIntl(ReadOnlyValue);
