import React, { useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';

import CustomButton from 'components/CustomButton';
import AplanetIcon from 'components/AplanetIcon';

import DataTargetIcon from 'components/DataTargets/DataTargetIcon';

import { formatMessage } from '../../intl';

import {
  Dropdown,
  Menu,
} from 'antd';

const NO_OP = () => { };
const EMPTY_OBJ = {};
const MANUAL_SOURCE = 'manual';

const MenuOptions = injectIntl(({
  intl,
  hasLastComment,
  hasLastAttachments,
  onUseLastValue,
  onUseLastWholeData,
  onUseLastAttachments,
  onUseLastPeriod,
}) => {
  const t = intl.messages;

  return (
    <Menu style={{ boxShadow: '0 5px 10px 0' }} selectedKeys={[]}>
      <Menu.Item onClick={onUseLastValue} key="data">
        {t.kpi_detail_use_only_data}
      </Menu.Item>
      <Menu.Item disabled={!hasLastComment} onClick={onUseLastWholeData} key="data-comments">
        {t.kpi_detail_use_data_and_comments}
      </Menu.Item>
      <Menu.Item disabled={!hasLastAttachments} onClick={onUseLastAttachments} key="data-attachments">
        {t.kpi_detail_use_data_and_attachments}
      </Menu.Item>
      <Menu.Item disabled={!hasLastAttachments || !hasLastComment} onClick={onUseLastPeriod} key="data-comments-attachments">
        {t.kpi_detail_use_data_comments_and_attachments}
      </Menu.Item>
    </Menu>
  )
});

const AnswerFooter = ({
  intl,
  source = MANUAL_SOURCE,
  source_params = EMPTY_OBJ,
  editting = false,
  canWrite = false,
  hasLastPeriod = false,
  hasLastComment = false,
  hasLastAttachments = false,
  onShowLastPeriod = NO_OP,
  onUseLastValue = NO_OP,
  onUseLastWholeData = NO_OP,
  onUseLastAttachments = NO_OP,
  onUseLastPeriod = NO_OP,
  onEdit = NO_OP,
  hasInitialValue = false,
  onCancelEdit = NO_OP,
  onClearEdit = NO_OP,
  onSaveEdit = NO_OP,
  dirty = true,
  empty = true,
  hasTarget = false,
  isTargetElegible = false,
  onTarget = NO_OP,
  suborganization_data = [],
  dependees = [],
}) => {
  const t = intl.messages;

  const data_filled_number = useMemo(() =>
    dependees.filter(dependee => dependee.value && dependee.applies).length,
    [dependees]
  );
  const data_total_number = useMemo(() =>
    dependees.filter(dependee => dependee.applies).length,
    [dependees]
  );

  const DataCompletionIndicator = useCallback(() => {
    if (data_filled_number === dependees.length) return (
      <section className='KpiDetail__answer-completed'>
        <AplanetIcon name='Check fill' size='15px' faStyle='fas'/>
        <span className=' KpiDetail__answer-completed-data'>{formatMessage('kpi_detail_footer_completion', {a: data_filled_number, b: data_total_number})}</span>
      </section>
    )
    else return (
      <section className='KpiDetail__answer-warning'>
        <AplanetIcon name='Alert' size='15px' />
        <span className=' KpiDetail__answer-warning-data'>{formatMessage('kpi_detail_footer_completion', {a: data_filled_number, b: data_total_number})}</span>
      </section>
    )
  }, [
    data_filled_number,
    data_total_number,
    dependees.length
  ])

  if (source !== MANUAL_SOURCE) {
    return (
      <div>
        {
          source === 'aggregated' && <DataCompletionIndicator />
        }
        <section className='KpiDetail__answer-warning'>
          <AplanetIcon name='Alert' size='15px' />
          {t.kpi_detail_not_manual_source}
        </section>
        {isTargetElegible &&
          <div className='KpiDetail__answer-editButtonsGroup'>
            <CustomButton
              className='KpiDetail__answer-edit-target'
              onClick={onTarget}
              icon={
                <DataTargetIcon />
              }
              type='default'
            >
              {hasTarget ? t.kpi_detail_target_edit : t.kpi_detail_target_create}
            </CustomButton>
          </div>
        }
      </div>
    );
  }

  return (
    <section className='KpiDetail__answer-editButtons'>
      <div className='KpiDetail__answer-editButtonsGroup'>
        <CustomButton
          className='KpiDetail__answer-showPeriod'
          type='default'
          onClick={onShowLastPeriod}
          disabled={!hasLastPeriod}
        >
          {t.kpi_detail_show_last_period}
        </CustomButton>
        {
          canWrite && (
            <Dropdown overlay={
              <MenuOptions
                hasLastComment={hasLastComment}
                hasLastAttachments={hasLastAttachments}
                onUseLastValue={onUseLastValue}
                onUseLastWholeData={onUseLastWholeData}
                onUseLastAttachments={onUseLastAttachments}
                onUseLastPeriod={onUseLastPeriod}
              />
            } placement="bottomCenter" arrow disabled={!hasLastPeriod}>
              <CustomButton
                className='KpiDetail__answer-usePeriod'
                type='default'
              >
                {t.kpi_detail_use_last_period}
              </CustomButton>
            </Dropdown>
          )
        }
      </div>
      {
        !editting
          ? (
            <div className='KpiDetail__answer-editButtonsGroup'>
              {
                !canWrite
                  ? null
                  : (
                    <CustomButton
                      onClick={onEdit}
                      className='KpiDetail__answer-edit'
                      type='default'
                    >
                      {t.edit_data}
                    </CustomButton>
                  )
              }
            </div>
          ) : (
            <div className='KpiDetail__answer-editButtonsGroup'>
              <CustomButton
                className='KpiDetail__answer-cancel'
                onClick={onCancelEdit}
                type='default'
              >
                {t.cancel}
              </CustomButton>
              {
                !hasInitialValue ? null :
                  <CustomButton
                    className='KpiDetail__answer-clear'
                    onClick={onClearEdit}
                    type="default"
                  >
                    {t.kpi_detail_clear_value}
                  </CustomButton>
              }
              <CustomButton
                className='KpiDetail__answer-save'
                onClick={onSaveEdit}
                disabled={!dirty}
                type='primary'
              >
                {t.save}
              </CustomButton>
            </div>
          )
      }
      {isTargetElegible && !editting &&
        <div className='KpiDetail__answer-editButtonsGroup'>
          <CustomButton
            className='KpiDetail__answer-edit-target'
            onClick={onTarget}
            icon={
              <DataTargetIcon />
            }
            type='default'
          >
            {hasTarget ? t.kpi_detail_target_edit : t.kpi_detail_target_create}
          </CustomButton>
        </div>
      }
    </section>
  )
}

export default injectIntl(AnswerFooter);
