import React from "react";
import { FormattedMessage } from "react-intl";

import type { ErrorProps } from "./Error";

/**
 * Animation component imports
 */
import { ReactComponent as forbiddenAnimation } from "assets/animations/noAccess.svg";
import { ReactComponent as paymentRequiredAnimation } from "assets/animations/paymentRequired.svg";
import { ReactComponent as notFoundAnimation } from "assets/animations/notFound.svg";
import { ReactComponent as unprocessableEntityAnimation } from "assets/animations/unprocessableEntity.svg";
import { ReactComponent as tooManyRequestsAnimation } from "assets/animations/tooManyRequests.svg";
import { ReactComponent as internalServerErrorAnimation } from "assets/animations/internalServerError.svg";
import { ReactComponent as badGatewayAnimation } from "assets/animations/badGateway.svg";
import { ReactComponent as serviceUnavailableAnimation } from "assets/animations/serviceUnavailable.svg";
import { ReactComponent as gatewayTimeoutAnimation } from "assets/animations/gatewayTimeout.svg";
import { ReactComponent as notUpdatedAnimation } from "assets/animations/notUpdated.svg";

/**
 * Error States
 */
const paymentRequiredProps = {
  svgAnimation: paymentRequiredAnimation,
  message: <FormattedMessage id="payment_required_message" />,
  explanation: <FormattedMessage id="payment_required_explanation" />,
};

const forbiddenProps = {
  svgAnimation: forbiddenAnimation,
  message: <FormattedMessage id="forbidden_message" />,
};

const notFoundProps = {
  svgAnimation: notFoundAnimation,
  message: <FormattedMessage id="not_found_message" />,
};

const unprocessableEntityProps = {
  svgAnimation: unprocessableEntityAnimation,
  message: <FormattedMessage id="unprocessable_entity_message" />,
};

const tooManyRequestsProps = {
  svgAnimation: tooManyRequestsAnimation,
  message: <FormattedMessage id="too_many_requests_message" />,
};

const internalServerErrorProps = {
  svgAnimation: internalServerErrorAnimation,
  message: <FormattedMessage id="internal_server_error_message" />,
  explanation: <FormattedMessage id="internal_server_error_explanation" />,
};

const badGatewayProps = {
  svgAnimation: badGatewayAnimation,
  message: <FormattedMessage id="bad_gateway_message" />,
  explanation: <FormattedMessage id="bad_gateway_explanation" />,
};

const serviceUnavailableProps = {
  svgAnimation: serviceUnavailableAnimation,
  message: <FormattedMessage id="service_unavailable_message" />,
  explanation: <FormattedMessage id="service_unavailable_explanation" />,
};

const gatewayTimeoutProps = {
  svgAnimation: gatewayTimeoutAnimation,
  message: <FormattedMessage id="gateway_timeout_message" />,
  explanation: <FormattedMessage id="gateway_timeout_explanation" />,
};

const notUpdatedProps = {
  svgAnimation: notUpdatedAnimation,
  message: <FormattedMessage id="not_updated_message" />,
  explanation: <FormattedMessage id="not_updated_explanation" />,
};

export const errorPropsMap = new Map<string, ErrorProps>([
  ["paymentRequired", paymentRequiredProps], //402
  ["forbidden", forbiddenProps], //403
  ["notFound", notFoundProps], //404
  ["unprocessableEntity", unprocessableEntityProps], //422
  ["tooManyRequests", tooManyRequestsProps], //429
  ["internalServerError", internalServerErrorProps], //500
  ["badGateway", badGatewayProps], //502
  ["serviceUnavailable", serviceUnavailableProps], //503
  ["gatewayTimeout", gatewayTimeoutProps], //504
  ["notUpdated", notUpdatedProps], //512
]);

export const getErrorProps = (errorName: string): ErrorProps => {
  const errorProps = errorPropsMap.get(errorName);
  if (errorProps === undefined) {
    // TODO - return default ErrorProps
    throw Error("errorName doesn't exist");
  }
  return errorProps;
};
