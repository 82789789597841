import React from "react";


const CellState = ({
  mode,
  children,
}) => {
  return (
    <div className={`CellState${mode ? ` CellState--${mode}` : ''}`}>
      { children }
    </div>
  );
}

export default CellState;