import React, {
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import useOrganizations from 'utils/useOrganizations';

import {
  addRemoveTags
} from 'actions/api';

import CustomMultiTagSelector from 'components/CustomMultiTagSelector'

const Tags = ({
  intl,
  kpi,
  tags = [],
  loading,
  permissions = {},
}) => {
  const {
    organization,
    suborganization,
  } = useOrganizations();
  const dispatch = useDispatch();

   const onAddTag = useCallback((kpis, addTags, removeTags) => {
    dispatch(
      addRemoveTags(
        organization.slug,
        suborganization.slug,
        kpis,
        addTags,
        removeTags,
      )
    );
  }, [
    dispatch,
    organization,
    suborganization,
  ]);

  return (
    <React.Fragment>
      <div className='KpiDetail__title'>{ intl.formatMessage({ id: 'tags'}) }</div>
      <section className="KpiDetail__card KpiDetail__tags">
        <CustomMultiTagSelector
          onSubmit={onAddTag}
          selectedKpisAndCategories={[kpi]}
          loading={loading}
        />
      </section>
    </React.Fragment>
  );
};

export default injectIntl(Tags);
