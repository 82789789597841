import { loop, Cmd } from 'redux-loop';

import { parseResponse } from 'utils';
import { tryRefreshFollowUrl } from 'utils/sso';
import config from 'config';

// IDENTITY ACTIONS
const requestIdentity = async () => {
  const response = await fetch(`${config.AUTH_URL}/identity`, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'include',
  }).then(tryRefreshFollowUrl);

  const result = await parseResponse(response);
  return result;
};

const identitySuccess = ({ access_token, user }) => ({
  type: 'IDENTITY_COMPLETE',
  access_token,
  user,
});

const identityFail = ({ code, text }) => ({
  type: 'IDENTITY_FAILED',
  code,
  text,
});

// REFRESH ACTIONS
const requestRefreshToken = async () => {
  const response = await fetch(`${config.AUTH_URL}/refresh`, {
    method: 'PUT',
    mode: 'cors',
    credentials: 'include',
  }).then(tryRefreshFollowUrl);

  const result = await parseResponse(response);
  return result;
};

const refreshTokenSuccess = ({ access_token }) => ({
  type: 'REFRESH_TOKEN_COMPLETE',
  access_token,
});

const refreshTokenFail = ({ code, text }) => ({
  type: 'REFRESH_TOKEN_FAILED',
  code,
  text,
});

const initialState = {
  refreshing_token: false,
  logged_in: false,
  missing: null,
  defaults: {},
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'IDENTITY_REQUEST':
      if(state.refreshing_token) {
        // Already refreshing, move along...
        return state;
      }
      return loop({ ...state, refreshing_token: true }, Cmd.run(requestIdentity, {
        successActionCreator: identitySuccess,
        failActionCreator: identityFail,
        args: [],
      }));
    case 'REFRESH_TOKEN_REQUEST':
      if(state.refreshing_token) {
        // Already refreshing, move along...
        return state;
      }
      return loop({ ...state, refreshing_token: true }, Cmd.run(requestRefreshToken, {
        successActionCreator: refreshTokenSuccess,
        failActionCreator: refreshTokenFail,
        args: [],
      }));
    case 'REFRESH_TOKEN_COMPLETE':
    case 'IDENTITY_COMPLETE':
      return {
        ...initialState,
        refreshing_token: false,
        logged_in: true,
      };
    case 'REFRESH_TOKEN_FAILED':
    case 'IDENTITY_FAILED':
      return initialState;
    default:
      return state;
  }
};

export {
  reducer as auth,
};
