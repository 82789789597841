import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import AplanetIcon from 'components/AplanetIcon';
import KpiTreeExtraColumn from './KpiTreeExtraColumn';
import ReportingStandardTag from 'components/ReportingStandardTag';

// NOTICE: adapt KpiTreeTitle when reporting_structure is ready for use, now is done for data_categories
const KpiTreeTitle = ({
  isExpanded,
  nodeData,
  searchText = '',
  extraColumns,
  enabledReports = []
}) => {

  if (nodeData.isCategory) {
    
    return (
      <span className="KpiTreeSelector__wrapper">
        <span className="KpiTreeSelector__column__title">

          {
            isExpanded
            ? (
              <>
                <AplanetIcon size="15px" name="folder-open" faStyle="fal" />
              </>
            )
            : (
              <>
                <AplanetIcon size="15px" name="folder" faStyle="fal" />
              </>
            )
          }
          {' '}

          { nodeData.code && 
              <ReportingStandardTag
                className="KpiTreeSelector__standard_tag"
                standard={nodeData.standard}
                name={nodeData.code}
                code={nodeData.code}
                key={nodeData.standard + nodeData.code}
              />
          }

          { !searchText
            ? nodeData.title
            : <Highlighter
                highlightClassName="KpiTreeSelector__highlighter"
                searchWords={[searchText]}
                textToHighlight={nodeData.title}
                autoEscape
              />
          }
        </span>
        {
          extraColumns.map( column => 
            column === 'link' 
            ? null 
            : <span key={ column } className={`KpiTreeSelector__column__${ column }`}>
                <KpiTreeExtraColumn nodeData={ nodeData } column={ column } /> 
              </span>
          ) 
        }
      </span>
    );
  }

  const iconBySchema = {
    table: 'file-spreadsheet',
    quantitative: 'file-binary',
    qualitative: 'file-lines',
    boolean: 'file-check',
  }

  return (
    <div className="KpiTreeSelector__wrapper">
      <span className="KpiTreeSelector__column__title">
        <AplanetIcon size="15px" name={ nodeData.schema.type in iconBySchema ? iconBySchema[nodeData.schema.type] : 'file' } faStyle="fal" />
        {(nodeData.standard_info || []).filter(({ standard }) => nodeData.is_custom ? true : enabledReports.includes(standard)).map(({ standard, code }) => (
          code
            ? (
              <ReportingStandardTag
                className="KpiTreeSelector__standard_tag"
                standard={standard}
                name={code}
                code={code}
                key={standard + code}
              />
            )
            : null
        ))}
        {' '}
        { !searchText
          ? nodeData.title
          : <Highlighter
              highlightClassName="KpiTreeSelector__highlighter"
              searchWords={[searchText]}
              textToHighlight={nodeData.title}
              autoEscape
            />
        }
      </span>
      {
        extraColumns.map( column => 
          <span key={ column } className={`KpiTreeSelector__column__${ column }`}>
            <KpiTreeExtraColumn nodeData={ nodeData } column={ column } /> 
          </span>
        ) 
      }
    </div>
  );
}

KpiTreeTitle.propTypes = {
  nodeData: PropTypes.object.isRequired,
  searchText: PropTypes.string,
  extraColumns: PropTypes.arrayOf(PropTypes.string),
  isExpanded: PropTypes.bool,
  enabledReports: PropTypes.arrayOf(PropTypes.string),
};

export default React.memo(KpiTreeTitle);
