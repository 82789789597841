//TODO: maybe this repo and reporting_structure and reporting_structure_kpi
//should be unified and handle the whole state of component using them,
//we decide separate them to keep them consistent with the rest.
const TARGET = 'data_meta';
const isTarget = (target) => [
  TARGET,
].includes(target);
const initialState = {
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  const data = action.response || action.request || action
  if(!isTarget(data.target) || !data.method === 'PUT' || data.body.not_apply_comment !== '') return state;

  switch(action.type) {
    case 'API_CALL_REQUEST':
      return  {
        loading: true,
        error: null,
      }
    case 'API_CALL_COMPLETE':
      return {
        loading: false,
        error: !data.result.error ? null : data.result.error,
      };
    case 'API_CALL_FAILED':
      return {
        ...state, // We leave the old state just in case
        loading: false,
        error: action.code,
      };
    default:
      return state;
  }
};
export {
  reducer as kpi_organizations,
};
