import CustomSelect from 'components/CustomSelect';
import { ORGANIZATION_TARGET_OPTIONS } from 'components/CustomSelect/targets';
import React from 'react';

export default function OrganizationTargets({ intl, selected, hasChildren, showUnsafeTargets = true, onSelect }) {
  const unsafeTargets = ['descendants'];

  if (!hasChildren) return null;

  return <CustomSelect
    title={intl.formatMessage({ id: 'guidelines_not_guidelines_direction' })}
    selected={selected}
    options={ORGANIZATION_TARGET_OPTIONS
      .filter(target => showUnsafeTargets || !unsafeTargets.includes(target))
      .map(slug => ({
        name: intl.formatMessage({ id: `guidelines_not_guidelines_direction_${slug}` }),
        slug
      }))}
    onSelect={onSelect}
  />;
}
