import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Radar } from 'react-chartjs-2';
import { useIntl } from 'react-intl';

import { defaultColors } from '../useColors.js';
import { formatNumberAbbr } from '../../../utils/formatNumber';
import { getDatalabelOptions } from './types.js';
import { convertStringToArrayWordSegments } from 'utils/strings.js';

// Hex transparency: https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
const makeHexColorTransparent = (hexColor) => hexColor + '4D';

const DEFAULT_PARAMETERS = {
  locale: undefined,
  showDataLabels: false
};

// TODO: Take out parameters and expose a way to get the params for a nivo-api call
const Spider = forwardRef(({
  slug,
  parameters = DEFAULT_PARAMETERS,
  data,
  onDownload,
}, ref) => {
  const intl = useIntl();
  const {
    nodata = intl.messages.no_data,
    locale = DEFAULT_PARAMETERS.locale,
    showDataLabels = DEFAULT_PARAMETERS.showDataLabels
  } = parameters;

  const chartData = useMemo(() => ({
    labels: data.labels,
    datasets: (data.values || []).map(value => ({
      ...value,
      backgroundColor: makeHexColorTransparent(defaultColors[0]),
    })),
  }), [
    data
  ]);

  const chartOptions = useMemo(() => ({
    maintainAspectRatio: false,
    scale: {
      ticks: {
        callback: value => {
          if(isNaN(value)) {
            return value;
          }
          return formatNumberAbbr(value, { locale });
        }
      },
    },
    plugins: {
      legend: {
        align: 'start',
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded'
        }
      },
      datalabels: showDataLabels ? getDatalabelOptions(parameters) : false,
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.formattedValue === 'NaN' ? '-' : formatNumberAbbr(Number(context.value), { locale });
            const tooltip = convertStringToArrayWordSegments(`${label}: ${value}`, 50);
            return tooltip;
          },
        },
      },

    }
  }), [
    locale, parameters, showDataLabels
  ]);

  return (
    <div className="DashboardComponent__card_content">
      {
        !data.values || data.values.length === 0
        ?
          <div
            className="DashboardComponent__nodata"
          >
            { nodata }
          </div>
        :
          <Radar
            ref={ref}
            height='500px'
            data={chartData}
            options={chartOptions}
          />
      }
    </div>
  );
});

Spider.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.object,
  onDownload: PropTypes.func,
};

export default Spider;

