import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from "react-router";

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  requestKpiValues,
  requestReportingStructure,
  generateBulkManageDownload,
} from 'actions/api';
import { resetBulkManagePasteErrors } from 'actions/bulk';

import CustomTag from 'components/CustomTag';
import CustomTable from 'components/CustomTable';
import KpiValue from 'components/BulkManage/KpiValue';
import Unit from 'components/BulkManage/Unit';
import Comment from 'components/BulkManage/Comment';
import Attachments from 'components/BulkManage/Attachments';
import Filters from 'components/BulkManage/Filters';
import OrganizationHierarchyLabel from 'components/BulkManage/OrganizationHierarchyLabel';
import KpiNameLabel from 'components/BulkManage/KpiNameLabel';
import CellState from 'components/BulkManage/CellState';
import ReadOnlyValue from 'components/BulkManage/ReadOnlyValue';
import Badge from 'components/BulkManage/Badge';

import { useEventTracking } from 'hooks/useEventTracking';
import useOrganizations from 'utils/useOrganizations';

import useScreenFilters from 'hooks/useScreenFilters';
import {
  DEFAULT_FILTER_SET,
  FILTER_TYPES,
  getQueryFiltersNames,
  SCREENS,
} from 'hooks/useScreenFilters/constants';

import {
  Col,
  Tooltip,
  Row,
  notification,
} from 'antd';


const PAGINATION_DEFAULT_LIMIT = 100;


const getQueryFilters = (filterValues = {}, organizationSlug) => {
  const queryFiltersNames = getQueryFiltersNames(SCREENS.bulk_manage);
  return Object.entries(queryFiltersNames).reduce((obj, [filterName, queryFilterName]) => {
    if (
      filterValues[filterName]
      && DEFAULT_FILTER_SET[filterName].condition(filterValues[filterName])
    ) {
      if (
        filterName === 'standard_array_filter'
        && filterValues[filterName]?.length === 1
        && filterValues[filterName][0] === organizationSlug
      ) {
        obj['is_custom'] = true;
      } else {
        obj[queryFilterName] = filterValues[filterName];
      }
    }
    return obj;
  }, {});
};


const BulkManage = ({
  intl,
}) => {
  const dispatch = useDispatch();
  const [selectedCell, setSelectedCell] = useState({});

  const {
    organization,
    suborganization,
    permissions,
  } = useOrganizations();
  const eventTracking = useEventTracking();

  const {
    values: [
      [filteredStandards, standardFilterSet],
      [filteredKpis, kpiFilterSet],
      [filteredOrganizations, organizationFilterSet],
      [filteredStatuses, statusFilterSet],
      [filteredSchemaTypes, schemaTypeFilterSet],
      [filteredSources, sourceFilterSet],
      [periodDateFilter, periodDateFilterSet],
      [filteredPeriodicities, periodicityFilterSet],
      [filteredTags, tagFilterSet],
      [textFilter, textFilterSet],
      [filteredValidationStatuses, validationStatusFilterSet],
    ],
    handleFilter,
    filterState,
    resetAllFilters,
    setAllFilters,
  } = useScreenFilters({
    screen: SCREENS.bulk_manage,
  });

  const areFiltersSet = useMemo(() => {
    return standardFilterSet
      || kpiFilterSet
      || organizationFilterSet
      || statusFilterSet
      || schemaTypeFilterSet
      || sourceFilterSet
      || periodDateFilterSet
      || periodicityFilterSet
      || tagFilterSet
      || textFilterSet
      || validationStatusFilterSet;
  }, [
    standardFilterSet,
    kpiFilterSet,
    organizationFilterSet,
    statusFilterSet,
    schemaTypeFilterSet,
    sourceFilterSet,
    periodDateFilterSet,
    periodicityFilterSet,
    tagFilterSet,
    textFilterSet,
    validationStatusFilterSet,
  ]);

  const queryFilters = useMemo(() => {
    const filterValues = {
      [FILTER_TYPES.standard_array_filter]: filteredStandards,
      [FILTER_TYPES.kpis]: filteredKpis,
      [FILTER_TYPES.suborganizations_filter]: filteredOrganizations,
      [FILTER_TYPES.value_status_filter]: filteredStatuses,
      [FILTER_TYPES.schema_type_filter]: filteredSchemaTypes,
      [FILTER_TYPES.source_filter]: filteredSources,
      [FILTER_TYPES.period_date_filter]: periodDateFilter,
      [FILTER_TYPES.periodicity_filter]: filteredPeriodicities,
      [FILTER_TYPES.tag_filter]: filteredTags,
      [FILTER_TYPES.text_filter]: textFilter,
      [FILTER_TYPES.validation_status_filter]: filteredValidationStatuses,
    };

    return getQueryFilters(filterValues, organization.slug);
  }, [
    filteredStandards,
    filteredKpis,
    filteredOrganizations,
    filteredStatuses,
    filteredSchemaTypes,
    filteredSources,
    periodDateFilter,
    filteredPeriodicities,
    filteredTags,
    textFilter,
    filteredValidationStatuses,
    organization,
  ]);

  useEffect(() => {
    if (
      !organization?.slug ||
      !suborganization?.slug
    ) return;

    dispatch(
      requestKpiValues({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        page: 1,
        limit: PAGINATION_DEFAULT_LIMIT,
        filters: queryFilters,
      })
    );
  }, [
    organization,
    suborganization,
    dispatch,
    queryFilters,
  ]);

  useEffect(() => {
    if (
      !organization
      || !suborganization
    ) return;

    dispatch(
      requestReportingStructure({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
      }),
    );
  }, [
    organization,
    suborganization,
    dispatch,
  ]);

  const onClickDocument = useCallback(() => {
    if(selectedCell.mode === 'selected') {
      setSelectedCell({});
    }
  }, [
    selectedCell,
  ]);

  useEffect(() => {
    document.addEventListener('click', onClickDocument);
    return () => document.removeEventListener('click', onClickDocument);
  }, [
    onClickDocument,
  ]);

  const {
    data,
    limit,
    currentPage,
    total,
    loading,
    pasteErrors,
  } = useSelector(state => state.bulk_manage_kpis);

  useEffect(() => {
    pasteErrors.forEach(error => notification.error({
      message: intl.formatMessage({id: 'bulk_manage_table_invalid_input'}),
      description: intl.formatMessage(
        {id: 'bulk_manage_table_invalid_input_description'},
        {...error}
      ),
      duration: null,
    }));
    if (pasteErrors.length) {
      dispatch(resetBulkManagePasteErrors());
    }
  }, [
    intl,
    dispatch,
    pasteErrors,
  ]);

  const enabledReports = useMemo(() => [
    ...organization?.config?.enabled_reports || [],
    organization.slug,
  ], [ organization ]);

  const canEditKpi = useCallback((kpi) => {
    return (kpi.kpi_source === 'manual') &&
      (kpi.ready_to_validate_level === 0) &&
      permissions.can_write_kpi;
  }, [
    permissions,
  ]);

  const handleDownload = useCallback(() => {
    eventTracking.capture('bulkManage.download', {
      organization_name: suborganization.name,
      filtered: !!Object.keys((queryFilters || {})).length ? 1 : 0,
    });
    dispatch(
      generateBulkManageDownload({
        organization_slug: organization.slug,
        suborganization_slug: suborganization.slug,
        filters: queryFilters,
      })
    );
    notification.info({
      message: intl.formatMessage({id: 'bulk_manage_download_start_message'}),
      description: intl.formatMessage({id: 'bulk_manage_download_start_description'}),
      placement: 'topRight',
    });
  }, [
    intl,
    dispatch,
    queryFilters,
    eventTracking,
    organization,
    suborganization,
  ]);

  const columns = useMemo(() => ([{
    title: intl.formatMessage({id: 'bulk_manage_table_category'}),
    dataIndex: 'category_name',
    className: 'DataManagement__bulk-manage__data__column_readonly',
    render: (value, record) => <KpiNameLabel
      name={(value || []).join(' / ')}
      standardInfo={[{
        code: record.category_code,
        standard: record.category_standard,
      }]}
    />,
    width: '11%',
    ellipsis: true,
  }, {
    title: intl.formatMessage({id: 'bulk_manage_table_indicator'}),
    dataIndex: 'name',
    className: 'DataManagement__bulk-manage__data__column_readonly',
    render: (value, record) => {
      return <KpiNameLabel
        isKpi
        name={value}
        searchText={textFilter}
        kpi={record}
        standardInfo={
          (record.standard_info || [])
            .filter(({ standard }) => record.is_custom
              ? true
              : (standard !== organization.slug && enabledReports.includes(standard))
            )
        }
      />;
    },
    width: '25%',
    ellipsis: true,
  }, {
    title: intl.formatMessage({id: 'bulk_manage_table_organization'}),
    dataIndex: 'organization_name',
    className: 'DataManagement__bulk-manage__data__column_readonly',
    render: (_, record) => <OrganizationHierarchyLabel
      organization={{
        slug: record.organization_slug,
        name: record.organization_name,
      }}
    />,
    width: '11%',
    ellipsis: true,
  }, {
    title: intl.formatMessage({id: 'bulk_manage_table_period'}),
    dataIndex: 'period',
    className: 'DataManagement__bulk-manage__data__column_readonly DataManagement__bulk-manage__data__column_period',
    width: '6%',
    ellipsis: true,
  }, {
    title: intl.formatMessage({id: 'bulk_manage_table_data'}),
    dataIndex: 'kpi_value',
    className: 'DataManagement__bulk-manage__data__column__no_padding',
    render: (_, record, index) => {
      return (
        <CellState
          key={`${index}-${record.id}-kpi_value`}
          mode={((selectedCell.column === 'kpi_value') && (selectedCell.key === index)) ? selectedCell.mode : 'show'}
        >
          <KpiValue
            key={`${index}-${record.id}`}
            position={index}
            kpi={record}
            mode={((selectedCell.column === 'kpi_value') && (selectedCell.key === index)) ? selectedCell.mode : 'show'}
            onChangeMode={setSelectedCell}
            canEdit={canEditKpi(record)}
          />
        </CellState>
      );
    },
    width: '14%',
  }, {
    title: intl.formatMessage({id: 'bulk_manage_table_unit'}),
    dataIndex: 'kpi_value',
    className: 'DataManagement__bulk-manage__data__column__no_padding',
    render: (_, record, index) => {
      if (record.schema.type !== 'quantitative') {
        return <ReadOnlyValue
          canEdit={false}
        />;
      }
      return (
        <CellState
          key={`${index}-${record.id}-unit`}
          mode={((selectedCell.column === 'unit') && (selectedCell.key === index)) ? selectedCell.mode : 'show'}
        >
          <Unit
            key={`${index}-${record.id}`}
            position={index}
            kpi={record}
            mode={((selectedCell.column === 'unit') && (selectedCell.key === index)) ? selectedCell.mode : 'show'}
            onChangeMode={setSelectedCell}
            canEdit={canEditKpi(record)}
          />
        </CellState>
      );
    },
    width: '6%',
  }, {
    title: intl.formatMessage({id: 'bulk_manage_table_comment'}),
    dataIndex: 'comment',
    className: 'DataManagement__bulk-manage__data__column__no_padding',
    render: (_, record, index) => {
      return (
        <CellState
          key={`${index}-${record.id}-comment`}
          mode={((selectedCell.column === 'comment') && (selectedCell.key === index)) ? selectedCell.mode : 'show'}
        >
          <Comment
            key={`${index}-${record.id}`}
            position={index}
            kpi={record}
            mode={((selectedCell.column === 'comment') && (selectedCell.key === index)) ? selectedCell.mode : 'show'}
            onChangeMode={setSelectedCell}
            canEdit={canEditKpi(record)}
          />
        </CellState>
      );
    },
    width: '11%',
  }, {
    title: intl.formatMessage({id: 'bulk_manage_table_attachments'}),
    dataIndex: 'attachments',
    className: 'DataManagement__bulk-manage__data__column__no_padding',
    render: (_, record, index) => {
      return (
        <Attachments
          intl={intl}
          kpi={record}
          canEdit={canEditKpi(record)}
        />
      );
    },
    width: '9%',
  }, {
    title: intl.formatMessage({id: 'bulk_manage_table_tags'}),
    dataIndex: 'tags',
    className: 'DataManagement__bulk-manage__data__column_tags DataManagement__bulk-manage__data__column_readonly',
    render: (tags) => (
      <Tooltip
        placement="left"
        title={
          <div className="DataManagement__bulk-manage_tooltip">
            {tags.map(tag => <CustomTag name={tag} />)}
          </div>
        }
        mouseEnterDelay={0.5}
      >
        <Row type="flex" align="middle">
          <Col span={tags.length > 1 ? 16 : 24}>
            {!!tags.length && <CustomTag name={tags[0]} />}
          </Col>
          <Col>
            {(tags.length > 1) &&
              <Badge background="#112C70" color="#FFFF">+{tags.length - 1}</Badge>
            }
          </Col>
        </Row>
      </Tooltip>
    ),
    width: '7%',
    ellipsis: true,
  }]), [
    intl,
    textFilter,
    enabledReports,
    organization,
    selectedCell,
    canEditKpi,
  ]);

  const onPaginationChange = useCallback(
    (page, limit) => {
      setSelectedCell({});
      dispatch(
        requestKpiValues({
          organization_slug: organization.slug,
          suborganization_slug: suborganization.slug,
          page,
          limit,
          filters: queryFilters,
        })
      );
    },
    [
      dispatch,
      organization,
      suborganization,
      queryFilters,
    ]
  );

  const onChangeSearch = useCallback((term) => {
    setSelectedCell({});
    handleFilter(FILTER_TYPES.text_filter)(term);
  }, [handleFilter]);

  const handleOnSetAllFilters = useCallback(
    (filters) => {
      setSelectedCell({});
      setAllFilters(filters);
    },
    [setAllFilters]
  );

  return (
    <div className="DataManagement__bulk-manage">
      <Row gutter={10}>
        <Col span={24}>
          <Filters
            filterState={filterState}
            resetAllFilters={resetAllFilters}
            setAllFilters={handleOnSetAllFilters}
            areFiltersSet={areFiltersSet}
            isKpiNameSearchSet={textFilterSet}
            kpiNameSearch={textFilter}
            onChangeKpiSearch={onChangeSearch}
            handleDownload={handleDownload}
          />
        </Col>
        <Col span={24}>
          <div className="DataManagement__bulk-manage__disclaimer">
            <span className="DataManagement__bulk-manage__disclaimer__dot"></span>
            <span>
              {intl.formatMessage({id: 'bulk_manage_disclaimer'})}
            </span>
          </div>
          <CustomTable
            className="DataManagement__bulk-manage__data"
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={{
              pageSize: limit,
              current: currentPage,
              total: total,
              onChange: onPaginationChange,
              position: ['bottomCenter'],
              showTotal: (total, range) => intl.formatMessage(
                {id: 'bulk_manage_table_total_of'},
                {from: range[0], to: range[1], total}
              )
            }}
            scroll={{ y: '65vh' }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default injectIntl(
  withRouter(BulkManage)
);

