const TARGET = 'survey_recipient';

const initialState = {
  data: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || response.target !== TARGET) return state;
      const result = response.result;
      switch(response.method) {
        case 'DELETE':
          const data = state.data.filter(d => d.id !== parseInt(result.id));
          return {...state, data};
        case 'POST':
          return {
            ...state,
            data: [...state.data, result],
          };
        case 'PUT':
          const index = state.data.map(
            recipient => recipient.id
          ).indexOf(result.id);
          const newState = {
            ...state,
            data: [
              ...state.data.slice(0, index),
            result,
            ...state.data.slice(index + 1),
            ],
          };
          return newState;
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        error: action.code,
      };
    case 'LOAD_RECIPIENTS':
      return {
        ...state,
        data: action.data,
      };
    default:
      return state;
  }
};

export {
  reducer as survey_recipient,
};
