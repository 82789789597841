import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import OtherDocuments from 'components/EqualityPlanDocumentManager/Other';
import DataCollection from './DataCollection';

import {
  Row,
  Col,
} from 'antd';

import './style.less';


const planStage = 'data_collection';

const EqualityPlanDataCollection = ({
  intl,
  history,
  plan,
  updatePlanProgress,
  documents,
  onFetchDocuments,
  reportingStartDate,
}) => {
  const t = intl.messages;
  const [currentTab, setCurrentTab] = useState('indicators');

  const onUpdatePlanProgress = useCallback(
    (stage) => {
      updatePlanProgress(stage);

      if (stage === planStage) {
        history.push(`/equality-plan/${plan.id}`);
      }
    },
    [updatePlanProgress, history, plan]
  );

  const headerTabs = useMemo(
    () => [{
      key: 'indicators',
      title: t.equality_data_collection_tab_indicators,
    }, {
      key: 'load-data',
      title: t.equality_data_collection_tab_load_data,
    }],
    [t]
  );

  // </FilterLogic>

  return (
    <section>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_data_collection}
        tabs={headerTabs}
        currentTab={currentTab}
        changeTab={setCurrentTab}
      />
      { currentTab === 'indicators' &&
        <DataCollection
          stage={planStage}
          plan={plan}
          updatePlanProgress={() => onUpdatePlanProgress(planStage)}
        />
      }
      { currentTab === 'load-data' &&
        <Row>
          <Col span={24}>
            <span>{t.equality_data_collection_tab_load_data_desc}</span>
          </Col>
          <Col span={24}>
            <OtherDocuments
              planId={plan.id}
              stage='data_collection'
              documents={documents}
              onFetchDocuments={onFetchDocuments}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onUploadSuccess={() => onUpdatePlanProgress(`${planStage}_attachments`)}
              reportingStartDate={reportingStartDate}
            />
          </Col>
        </Row>
      }
    </section>
  )
};

export default withRouter(
  injectIntl(
    EqualityPlanDataCollection
  )
);
