import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import EqualityPlanHeader from 'components/EqualityPlan/Header';
import { Loading } from 'tsComponents/emptyStates/Loading';
import useOrganizations from 'utils/useOrganizations';
import Form from './Form';

import {
  requestEqualityPlanPositions,
  updateEqualityPlanPosition,
  requestEqualityPlanFactors,
} from 'actions/api';

import {
  Row,
  Col,
  Tabs,
} from 'antd';


const JobAssessment = ({
  intl,
  history,
  plan,
  updatePlan,
}) => {
  const t = intl.messages;
  const dispatch = useDispatch();
  const { organization, suborganization } = useOrganizations();
  const [currentPosition, setCurrentPosition] = useState();
  const [currentTab, setCurrentTab] = useState();

  useEffect(
    () => {
      if (plan?.id) {
        dispatch(
          requestEqualityPlanFactors(
            organization.slug, suborganization.slug, plan.id
          )
        );
        dispatch(
          requestEqualityPlanPositions(
            organization.slug, suborganization.slug, plan.id
          )
        );
      }
    },
    [dispatch, plan, organization, suborganization]
  );

  const {
    fetching: fetchingFactors,
    data: factors,
  } = useSelector(state => state.equality_plan_factor);
  const {
    fetchingPositions,
    data: positions,
  } = useSelector(state => state.equality_plan_position);

  useEffect(
    () => {
      if (positions && positions.length > 0 && !currentTab) {
        setCurrentPosition(positions[0]);
        setCurrentTab(positions[0].id.toString());
      }
    },
    [positions, currentTab]
  );

  const handleOnChangeTab = useCallback(
    tab => {
      setCurrentPosition(
        positions.find(position => position.id === parseInt(tab))
      );
      setCurrentTab(tab);
    },
    [positions]
  );

  const handleSave = useCallback(
    (data) => {
      dispatch(
        updateEqualityPlanPosition(
          organization.slug,
          suborganization.slug,
          plan.id,
          data.id,
          data,
        )
      );
      if (!plan.progress.includes('job_assessment')) {
        updatePlan(
          {
            progress: [...new Set([
              ...plan.progress,
              'job_assessment',
              'points_by_position',
              'gender_analysis_by_position',
              'analysis_by_gender_for_position',
              'consistency_check',
            ])],
          }
        );
      }
    },
    [
      dispatch,
      plan,
      organization,
      suborganization,
      updatePlan,
    ]
  );

  const handleBack = useCallback(
    () => history.push(`/equality-plan/${plan.id}/job-evaluation`),
    [plan, history]
  );

  if (
    fetchingPositions
    || !positions
    || fetchingFactors
    || !factors  
  ) {
    return <Loading />;
  }

  return (
    <>
      <EqualityPlanHeader
        planId={plan.id}
        title={t.equality_job_evaluation_assessment}
        handleBackOrSkip={handleBack}
      />
      <Row>
        <Col span={24}>
          <Tabs
            activeKey={currentTab}
            onChange={handleOnChangeTab}
            tabPosition="left"
          >
            {positions.map(position =>
              <Tabs.TabPane
                tab={position.name}
                key={position.id}
              >
                {currentTab === position.id.toString() &&
                  <Form
                    key={position.id}
                    position={currentPosition}
                    positions={positions}
                    factors={factors}
                    handleSave={handleSave}
                  />
                }
              </Tabs.TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
    </>
  );
}

export default withRouter(injectIntl(JobAssessment));
