import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {formatMessage} from '../../intl'

import moment from 'moment';

import CustomDatepicker from 'components/CustomDatepicker';
import CustomSelect from 'components/CustomSelect';
import PeriodicitiesSelector from 'components/PeriodicitiesSelector';

import {
  formatDateShort,
} from 'utils/date';
import useOrganizations from 'utils/useOrganizations';

import { requestKpiValueStatus } from 'actions/api';


const DEFAULT_CYCLE_DATE_OPTIONS = [ '01-01' ];

const Required = ({ required }) => (
  required
    ? (
      <div className='CreateEditCustomKPI-required'>
        *
      </div>
    )
    : null
);

const disabledDate = (date) => date && moment(date).isAfter( moment() );

const Periodicities = ({
  intl,
  kpiSlug,
  value,
  onChange,
  disabled,
  error,
  required = false,
  cycleDateOptions = DEFAULT_CYCLE_DATE_OPTIONS,
  kpiValueStatus,
}) => {
  const dispatch = useDispatch();
  const {
    organization,
    suborganization,
  } = useOrganizations();

  const {
    loadingKpiValueStatus,
  } = useSelector(state => state.reporting_structure);

  useEffect(
    () => {
      if (kpiSlug) {
        dispatch(
          requestKpiValueStatus(
            organization.slug,
            suborganization.slug,
            kpiSlug,
          )
        );
      }
    },
    [
      dispatch,
      kpiSlug,
      organization.slug,
      suborganization.slug,
    ]
  );

  const {
    periodicities = [],
    annual_start_date = null, // Format YEAR-MONTH-DATE as '2018-01-01'
  } = value || {};

  const [ year, cycle_date ] = useMemo(() => {
    if(!annual_start_date || !annual_start_date.slice) {
      return [ null, null ];
    }

    const year = annual_start_date.slice(0,4);
    const cycle_date = annual_start_date.slice(5,10);

    return [ year, cycle_date ];
  }, [
    annual_start_date,
  ]);

  const handleCycleDateChange = useCallback((cycle_date) => {
    const newValue = !cycle_date
      ? null
      : !year
        ? `${(new Date()).getFullYear()}-${cycle_date}`
        : `${year}-${cycle_date}`

    onChange('annual_start_date')(newValue);
  }, [
    year,
    onChange,
  ]);

  const handleYearChange = useCallback((_year) => {
    const year = _year && moment(_year).year();
    const newValue = !year|| (!cycle_date && !cycleDateOptions[0])
      ? null
      : !cycle_date
        ? `${year}-${cycleDateOptions[0]}`
        : `${year}-${cycle_date}`

    onChange('annual_start_date')(newValue);
  }, [
    cycleDateOptions,
    cycle_date,
    onChange,
  ]);

  const handlePeriodicitiesChange = useCallback(
    (slug, checked) => {
      if (checked) {
        onChange('periodicities')(slug);
      } else {
        if (periodicities.includes(slug)) onChange('periodicities')(slug);
      }
    },
    [onChange, periodicities],
  );

  const yearValue= useMemo(() => {
    return year && moment(`${year}-01-01 12:00:00.000000Z`);
  }, [
    year,
  ]);

  const cycleDateOptionsTranslated = useMemo(() => {
    return (cycleDateOptions || []).map(slug => ({
      slug,
      name: formatDateShort(`1970-${slug} 12:00:00.000000Z`, intl),
    }));
  }, [
    cycleDateOptions,
    intl,
  ]);

  return (
    <React.Fragment>
      <div className='CreateEditCustomKPI-title'>
        <Required required={required} />
        {formatMessage("createcustomkpi_collection_frequency")}
      </div>
      <div>
        <PeriodicitiesSelector
          disabled={disabled}
          value={{ checked: periodicities }}
          onChange={handlePeriodicitiesChange}
          error={error.periodicities}
        />
        <div>
          <div className='CreateEditCustomKPI-miniTitle'>
            <Required required={required} />
            {formatMessage("createcustomkpi_date")}
          </div>
          <div className='CreateEditCustomKPI-additionalInfo'>
            {formatMessage("createcustomkpi_date_additional_info")}
          </div>
          <div className='CreateEditCustomKPI-dateSelector'>
            <CustomSelect
              loading={loadingKpiValueStatus}
              disabled={kpiSlug && (loadingKpiValueStatus || !kpiValueStatus || (kpiValueStatus === 'set'))}
              options={cycleDateOptionsTranslated}
              onSelect={handleCycleDateChange}
              selected={cycle_date}
            />
            <CustomDatepicker
              picker="year"
              disabledDate={disabledDate}
              value={yearValue}
              onChange={handleYearChange}
              allowClear={false}
              disabled={disabled}
            />
          </div>
          {error.annual_start_date ? (<span className='CreateEditCustomKPI__error'>{error.annual_start_date}</span>) : null}
        </div>
      </div>
    </React.Fragment>
  )
}

export default injectIntl(Periodicities);
