import React, {
  useCallback,
} from 'react';
import CustomLanguageInput from '../CustomLanguageInput';
import Code from './Code';
import TableKpi from 'components/CreateEditCustomKPI/forms/Table';
import QuantitativeKpi from './forms/Quantitative';
import Instructions from './Instructions';
import Periodicities from './Periodicities';
import Sdgs from '../SDGsSelector';
import EsgSelector from 'components/EsgSelector';
import ReportingStandard from './ReportingStandard';
import './style.less';

const DEFAULT_CYCLE_DATE_OPTIONS = ['01-01'];

const CreateEditCustomKPI = ({
  intl,
  kpiSlug,
  values,
  handleChange,
  errors,
  showFrequency = true,
  schemaDirty,
  setSchemaDirty,
  showLanguageInput = true,
  showCode = true,
  showEsg = true,
  showValue = true,
  showInstructions = true,
  showSdgs = true,
  showKpiAssociations = true,
  organization,
  suborganization,
  isCreate,
  kpiValueStatus,
  cycleDateOptions = DEFAULT_CYCLE_DATE_OPTIONS
}) => {
  const handleEsgTypeChange = useCallback((t) => {
    return handleChange('esg_type')((t || [])[0])
  }, [
    handleChange,
  ]);

  return (
    <section className='CreateEditCustomKPI-form'>
      <div className='CreateEditCustomKPI-title'>
        {intl.formatMessage({ id: 'createcustomkpi_name' })}
      </div>
      {showLanguageInput &&
        <>
          <CustomLanguageInput
            values={values}
            errors={errors.name_translations}
            handleChange={handleChange}
            placeholder={intl.formatMessage({ id: 'createcustomkpi_name_placeholder' })}
          />
        </>
      }
      {showCode &&
        <>
          <div className='CreateEditCustomKPI-divider' />
          <div className='CreateEditCustomKPI-title'>
            {intl.formatMessage({ id: 'createcustomkpi_code' })}
          </div>
          <Code
            value={values.code}
            onChange={handleChange('code')}
            error={errors.code}
          />
        </>
      }
      {showEsg &&
        <>
          <div className='CreateEditCustomKPI-divider' />
          <EsgSelector
            value={[values.esg_type]}
            onChange={handleEsgTypeChange}
            error={errors.esg_type}
            required
          />
        </>
      }
      {showSdgs &&
        <>
          <div className='CreateEditCustomKPI-divider' />
          <Sdgs
            value={values.sdgs}
            onChange={handleChange('sdgs')}
            error={errors.sdgs}
          />
        </>
      }
      {showValue && values.type === 'table' &&
        <>
          <div className='CreateEditCustomKPI-divider' />
          <TableKpi
            mode={setSchemaDirty ? 'edit' : undefined}
            values={values}
            onChange={handleChange}
            dirty={schemaDirty}
            setDirty={setSchemaDirty}
            error={errors.schema}
            required
          />
        </>
      }
      {showValue && values.type === 'quantitative' &&
        <>
          <div className='CreateEditCustomKPI-divider' />
          <QuantitativeKpi
            values={values}
            onChange={handleChange}
            setDirty={setSchemaDirty}
            error={errors.schema}
          />
        </>
      }
      {showInstructions &&
        <>
          <div className='CreateEditCustomKPI-divider' />
          <Instructions
            value={values}
            onChange={handleChange}
            error={errors.instructions}
          />
        </>
      }
      {showFrequency &&
        <>
          <div className='CreateEditCustomKPI-divider' />
          <Periodicities
            kpiSlug={kpiSlug}
            disabled={!isCreate}
            value={values}
            onChange={handleChange}
            error={errors}
            cycleDateOptions={cycleDateOptions}
            required
            kpiValueStatus={kpiValueStatus}
          />
        </>
      }
      {
        showKpiAssociations && 
        <>
          <div className='CreateEditCustomKPI-divider' />
          <ReportingStandard
            value={values.kpi_associations}
            onChange={handleChange('kpi_associations')}
            error={errors.reporting_standards}
          />
        </>
      }
    </section>
  );
};

export default CreateEditCustomKPI;
