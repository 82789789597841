import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';

import { useDispatch } from 'react-redux';
import useOrganizations from 'utils/useOrganizations';

import { updateKpi } from 'actions/api';

import useForm from 'utils/useForm';
import useKpiSchemaValidation from 'hooks/useKpiSchemaValidation';
import validation from './validation';

import CustomButton from 'components/CustomButton';
import CreateEditCustomKPI from 'components/CreateEditCustomKPI';
import CustomModal from 'components/CustomModal';
import AplanetIcon from 'components/AplanetIcon';
import LoaderModal from 'components/LoaderModal';

import {
  Col,
  notification,
  Row,
} from 'antd';

import './style.less';


// NOTICE: Order is important here (see below!)
const REPORTING_STANDARDS = [
  'gri',
  'gri-2021',
  'equality',
  'sdg',
  'sdgc',
  'bcorp',
  'einf',
  'euss',
  'ungc',
  'tefce',
  'prme',
  'sasb-hc-dy',
  'sasb-hc-di',
  'sasb-hc-dr',
  'sasb-hc-mc',
  'sasb-hc-ms',
  'sasb-hc-bp',
  'sasb-fn-ac',
  'sasb-fn-cb',
  'sasb-fn-cf',
  'sasb-fn-in',
  'sasb-fn-ib',
  'sasb-fn-mf',
  'sasb-fn-ex',
  'sasb-cg-aa',
  'sasb-cg-mr',
  'tcfd',
  'sasb-fb-fr',
  'sasb-if-re',
  'sfdr',
  'shift',
  'scority',
  'ghg',
  'neutrality',
];

const DEFAULT_CYCLE_DATE_OPTIONS = ['01-01'];

const ModalEditKPI = ({
  intl,
  onClose,
  loading = false,
  error,
  name,
  name_translations,
  esg_type,
  schema,
  schemaLabels,
  kpi_slug,
  sdgs: defaultSdgs = [],
  standard_info = {},
  guidelines_std,
  periodicities,
  first_reported_at,
  kpi_value_status_for_whole_tree,
  kpi_associations
}) => {
  const dispatch = useDispatch();
  const [showUpdateSchemaModal, setShowUpdateSchemaModal] = useState(false);

  const componentValues = useMemo(() => {
    const VALUES_PER_SCHEMA = {
      quantitative: schema,
      default: {},
    };
    return VALUES_PER_SCHEMA[schema.type] || VALUES_PER_SCHEMA.default;
  }, [schema]);

  const {
    organization,
    suborganization,
  } = useOrganizations();

  const enabledReports = useMemo(() => {
    return (organization.config || {}).enabled_reports || [];
  }, [
    organization,
  ]);

  const standardInfo = useMemo(() => {
    return enabledReports.reduce((acc, curr) => {
      acc[curr] = standard_info[curr] ?? [{}];
      return acc;
    }, {});
  }, [enabledReports, standard_info]);

  const [submitting, setSubmitting] = useState(false);
  const [schemaDirty, setSchemaDirty] = useState(false);

  const submitForm = () => {
    setSubmitting(true);
    setShowUpdateSchemaModal(false);
    const {
      name_translations: newNames,
      sdgs = [],
      esg_type,
      guidelines,
      enabled_standards,
      schemaLabels,
      schema_empty_existing_values = false,
      code,
      periodicities,
      annual_start_date,
      kpi_associations,
      ...rest
    } = values;

    const reporting_standards = [
      ...(
        REPORTING_STANDARDS
          .map(standard => ([
            rest[standard],
            rest[`${standard}_enabled`],
          ]))
          .map(([rs, enabled], index) => enabled && ({ values: rs, standard: REPORTING_STANDARDS[index] })).filter(rs => rs && !!rs.values.length)
      ),
      { values: [{ code }], name_translations, standard: organization.slug }
    ]

    const schema = Object.fromEntries(
      Object.keys(rest)
        .filter(key => !REPORTING_STANDARDS.includes(key) && !(REPORTING_STANDARDS.map(std => `${std}_enabled`)).includes(key))
        .map(key => [key, rest[key]])
    );

    const newKpiNames = newNames.length === name_translations.length &&
      name_translations.every(({ locale, name }) => {
        const newName = newNames.find((nn => nn.locale === locale));
        return newName?.name === name;
      }) ? undefined : newNames.filter(({ name }) => name !== '');

    dispatch(
      updateKpi({
        organization_slug: organization.slug,
        suborganization,
        kpi_slug,
        sdgs,
        standard_info: !!reporting_standards.length ? reporting_standards : undefined,
        name_translations: newKpiNames,
        esg_type,
        guidelines_std: guidelines,
        schema: schema.type ? schema : undefined, // type will be undefined if schema hasn't changed,
        schema_empty_existing_values,
        code,
        annual_start_date,
        periodicities,
        kpi_associations
      })
    );
  };

  const labeledSchema = useMemo(
    () => {
      if (schema.type !== 'table') {
        return schema;
      }

      let innerSchema = {
        ...schema.innerSchema,
      };

      const dimensions = schema.dimensions.map(
        dimension => {
          const updatedDimension = { ...dimension };
          if (['user', 'singleton'].includes(updatedDimension.source)) {
            updatedDimension.byName = schemaLabels.dimensionNames[updatedDimension.by];
          } else if (updatedDimension.source === 'standard') {
            updatedDimension.byName = schemaLabels.dimensionNames[updatedDimension.by];
            updatedDimension.standardItems = (updatedDimension?.standardItems || []).map(
              option => ({
                slug: option.slug,
                name: schemaLabels.dimensionValues[updatedDimension.by][option.slug]
              })
            );
          }
          return updatedDimension;
        }
      );

      if (innerSchema.type === 'tuple') {
        innerSchema.components = innerSchema.components.map(
          component => {
            let updatedColumn = {
              ...component,
            };

            updatedColumn.componentName = schemaLabels.innerSchema.componentLabels[updatedColumn.name];

            if (updatedColumn.type === 'choice' && updatedColumn?.options?.standardItems) {
              updatedColumn.options.standardItems = (updatedColumn?.options.standardItems || []).map(
                option => ({
                  slug: option.slug,
                  name: schemaLabels.innerSchema.components[updatedColumn.name].options[option.slug],
                })
              )
            }
            return updatedColumn;
          }
        );
      }

      return {
        ...schema,
        dimensions,
        innerSchema,
      };
    },
    [schema, schemaLabels]
  );

  const defaultValues = useMemo(() => {
    let result = {
      name,
      name_translations: name_translations.filter(({ locale }) => (organization?.config?.preferred_languages || []).includes(locale)),
      esg_type,
      periodicities: Object.keys(periodicities || {}),
      guidelines: guidelines_std,
      code: (standard_info[organization.slug] || [{}])[0].code,
      ...labeledSchema,
      schemaLabels,
      ...componentValues,
      annual_start_date: first_reported_at,
      kpi_associations: Object.keys(kpi_associations || {}).reduce((obj, standard) => {
        obj[standard] = kpi_associations[standard].map(kpi => kpi.slug);
        return obj;
      }, {}),
    };

    enabledReports.forEach(er => result[`${er}_enabled`] = !!standardInfo[er].length && standardInfo[er].every(si => !!Object.keys(si).length));

    return result;
  }, [
    periodicities,
    first_reported_at,
    name,
    name_translations,
    esg_type,
    guidelines_std,
    labeledSchema,
    schemaLabels,
    componentValues,
    enabledReports,
    standardInfo,
    standard_info,
    organization,
    kpi_associations
  ]);

  const defaultSetValues = useMemo(() => {
    return {
      sdgs: defaultSdgs,
      enabled_standards: Object.keys(standard_info || {}),
      ...standardInfo
    };
  }, [defaultSdgs, standardInfo, standard_info]);

  const cycleDateOptions = useMemo(() => {
    if (suborganization.slug === organization.slug) {
      // Top level org, all options are possible
      return organization?.config?.cycle_date_options || DEFAULT_CYCLE_DATE_OPTIONS;
    }

    // Suborgs only get the one possible cycle date
    // But notice this will never be used because for now you can only create KPIs in a top-level org
    return (
      suborganization?.config?.start_fiscal_year && [suborganization?.config?.start_fiscal_year]
    ) || (
        organization?.config?.cycle_date_options?.length > 0
        && [organization?.config?.cycle_date_options[0]]
      ) || DEFAULT_CYCLE_DATE_OPTIONS;
  }, [
    organization,
    suborganization,
  ]);

  const validateForm = useMemo(() => validation(intl, cycleDateOptions), [intl, cycleDateOptions]);

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues,
    defaultSetValues,
    validationDefaults: { locale: suborganization.language },
    setNames: ['sdgs', 'enabled_standards', ...enabledReports],
    continousValidation: true,
  });
  
  const handleOnSubmit = useCallback(
    (callbackData) => {
      if (
        (schema.type === 'table' || schema.type === 'quantitative') 
        && !callbackData?.isSecureUpdate 
      ) {
        setShowUpdateSchemaModal(true);
      } else {
        // TODO: Do we need to reset 'decimalPoints', 'metricSlug' and 'allowedUnitSlugs'?
        handleChange('schema_empty_existing_values')(false);
        handleSubmit();
      }
    },
    [
      schema,
      handleSubmit,
      handleChange
    ]
  );

  const { 
    validateSchemaForm, 
    isFetching: isValidating,
  } = useKpiSchemaValidation(organization, suborganization, kpi_slug, defaultValues, handleOnSubmit);

  const handleClose = useCallback(
    () => {
      setSchemaDirty(false);
      resetForm();
      onClose();
    },
    [
      onClose,
      resetForm,
      setSchemaDirty,
    ]
  );

  useEffect(() => {
    // Close the modal
    if (submitting && !loading) {
      
      setSubmitting(false);
      if (error){
        notification.error({
          message: intl.formatMessage({ id: 'modaleditkpi_update_error_title' }),
          description: intl.formatMessage({ id: 'modaleditkpi_update_error_message' })
        })
      } else {
        notification.success({
          message: intl.formatMessage({ id: 'modaleditkpi_update_saved_title' }),
          description: intl.formatMessage({ id: 'modaleditkpi_update_saved_message' })
        })
      }
      handleClose();
    }
  }, [
    handleClose,
    submitting,
    loading,
    error,
    intl
  ]);

  const handleOnCancelChange = useCallback(
    () => {
      setShowUpdateSchemaModal(false);
      handleClose();
    },
    [
      handleClose,
    ]
  );

  return (
    <>
    <CustomModal
      className="ModalEditKPI"
      shown={!submitting}
      title={intl.formatMessage({ id: "modaleditkpi_title" })}
      onOk={ () => validateSchemaForm(values) }
      onCancel={handleClose}
      width={800}
      destroyOnClose={true}
      footer={
        <Row type="flex" justify="end">
          <Col className="ModalEditKPI__footer">
            <CustomButton
              className="ModalEditKPI__btn-footer"
              key="back"
              onClick={handleClose}
            >
              {intl.formatMessage({ id: "modaleditkpi_cancel" })}
            </CustomButton>
            <CustomButton
              className="ModalEditKPI__btn-footer"
              key="submit"
              type="primary"
              icon={
                isValidating 
                ? (
                  <AplanetIcon
                    name='spinner'
                    faStyle='fad'
                    className='ModalEditKPI__spinner'
                    size='20px'
                  />
                ) : null
              }
              onClick={ () => validateSchemaForm(values) }
            >
              {intl.formatMessage({ id: "modaleditkpi_save" })}
            </CustomButton>
          </Col>
        </Row>
      }
    >
      <CreateEditCustomKPI
        intl={intl}
        kpiSlug={kpi_slug}
        values={values}
        errors={errors}
        handleChange={handleChange}
        schemaDirty={schemaDirty}
        setSchemaDirty={setSchemaDirty}
        organization={organization}
        suborganization={suborganization}
        kpiValueStatus={kpi_value_status_for_whole_tree}
        cycleDateOptions={cycleDateOptions}
      />
      <CustomModal
        shown={showUpdateSchemaModal && !isValidating}
        title={intl.formatMessage({ id: "modaleditkpi_update_schema_title" })}
        destroyOnClose={true}
        onCancel={() => setShowUpdateSchemaModal(false)}
        width={700}
        footer={
          <Row type="flex" justify="end" gutter={5}>
            <Col>
              <CustomButton
                key="back"
                onClick={handleOnCancelChange}
              >
                {intl.formatMessage({ id: "modaleditkpi_update_schema_cancel" })}
              </CustomButton>
            </Col>
            <Col>
              <CustomButton
                key="submit"
                type="primary"
                onClick={() => {
                  handleChange('schema_empty_existing_values')(true);
                  handleSubmit();
                }}
              >
                {intl.formatMessage({ id: "modaleditkpi_update_schema_ok" })}
              </CustomButton>
            </Col>
          </Row>
        }
      >
        {intl.formatMessage({ id: "modaleditkpi_update_schema_description" })}
      </CustomModal>
    </CustomModal>
    <LoaderModal
      visible={submitting}
      type='spinner'
      title={intl.formatMessage({ id: 'modaleditkpi_update_saving_title' })}
      message={intl.formatMessage({ id: 'modaleditkpi_update_saving_message' })}
    />
    </>
  )
};

export default injectIntl(ModalEditKPI);
