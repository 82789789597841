import React from "react";

import Highlighter from 'react-highlight-words';

import ReportingStandardTag from "components/ReportingStandardTag";

import {
  Tooltip,
} from "antd";


const KpiNameLabel = ({
  name,
  standardInfo,
  searchText,
  kpi,
  isKpi,
}) => {

  return (
    <Tooltip
      title={name}
      mouseEnterDelay={0.5}
    >
      <span className="KpiNameLabel">
        {(standardInfo || [])
          .filter(({ code }) => !!code)
          .map(({ standard, code }) => (
            <ReportingStandardTag
              className="KpiNameLabel__standard_tag"
              standard={standard}
              name={code}
              code={code}
          />
          ))
        }
        {isKpi ? (
          <a href={`/kpi/${kpi.slug}/${kpi.period}?org=${kpi.organization_slug}`} target="_blank" rel="noreferrer">
            <Highlighter
              searchWords={[searchText]}
              textToHighlight={name}
              autoEscape
            />
          </a>
        ) :
        <span>{name}</span>
        }
      </span>
    </Tooltip>
  )
}

export default KpiNameLabel;
