import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import CustomInputSearch from 'components/CustomInputSearch';
import { FixedSizeGrid as Grid } from "react-window";
import { library } from '@fortawesome/fontawesome-svg-core';
import styled from "@emotion/styled";

import { useComponentVisible } from 'hooks/useComponentVisible';
import useOrganizations from 'utils/useOrganizations';
import AplanetIcon from 'components/AplanetIcon';

import './style.less';

const IconWrapperStyled = styled.div`
  &:hover {
    border-color: ${props => props.color};
  }
  &.is-selected {
    background-color: ${props => props.color};
  }
  .IconSelector__icon-item {
    color: ${props => props.color};
    &.is-selected {
      background-color: ${props => props.color};
    }
  }
`;

const ICONS_PER_ROW = 15;
const DEFAULT_COLOR = '#147F74';

const IconSelector = ({
  label,
  defaultIcon = '',
  selectedIcon,
  onSelectIcon,
  color,
}) => {
  const intl = useIntl();
  const scrollableComponentRef = useRef(null);

  const {
    suborganization
  } = useOrganizations();

  const [searchText, setSearchText] = useState('');
  
  const filteredIcons = useMemo(() => {
    const iconsFadLibrary = Object.keys(library?.definitions?.fad) || [];
    const icons = iconsFadLibrary.map(iconKey => {
      const splitIconKey = iconKey.replaceAll('-', ' ');
      const iconName = iconKey.length === 1 ? splitIconKey.toUpperCase() : splitIconKey.charAt(0).toUpperCase() + splitIconKey.slice(1);
      return {
        iconKey,
        iconName,
      }
    });
    return searchText ? icons.filter(({iconName}) => iconName.toLowerCase().includes(searchText.toLowerCase())) : icons;
  }, [searchText]);

  useEffect(() => {
    scrollableComponentRef?.current?._outerRef?.scrollTo({ top: 0, behavior: "smooth" });
  }, [searchText])

  const onResetSearch = useCallback(() => {
    setSearchText('');
  }, []);

  const onHandleSetSearch = useCallback((term) => {
    setSearchText(term.trim());
  }, []);

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
    toggleIsComponentVisible,
  } = useComponentVisible({
    onClickOutside: onResetSearch,
    onEscPress: onResetSearch
  });

  const handleSelection = useCallback((icon) => {
    onSelectIcon(icon);
    setIsComponentVisible(false); 
    onResetSearch();
  }, [onSelectIcon, setIsComponentVisible, onResetSearch]);

  const fieldLabel = label !== undefined ? label : intl.formatMessage({ id: 'icon_selector_title' });

  return  (
    <section className='IconSelector' ref={ref}>
      {
         fieldLabel && 
         <span className='IconSelector__title'>{ fieldLabel }</span>
      }
      <div className='IconSelector__wrapper'>
        <button
          className='IconSelector__selection-button'
          onClick={toggleIsComponentVisible}
        >
          {selectedIcon || defaultIcon ? (
            <AplanetIcon
              className='IconSelector__current-icon'
              faStyle='fad'
              name={selectedIcon || defaultIcon}
              style={{ color: color || (suborganization?.config?.theme || {})['@primary-color'] || DEFAULT_COLOR }}
            />
          ) : null}
        </button>
        {isComponentVisible ? (
          <div className='IconSelector__icons-panel'>
            <CustomInputSearch
              value={searchText}
              placeholder={intl.formatMessage({ id: 'icon_selector_search_placeholder' })}
              onChange={onHandleSetSearch}
              autoFocus
              allowClear
            />
            <Grid
              className='IconSelector__icons-list'
              ref={scrollableComponentRef}
              columnCount={ICONS_PER_ROW}
              rowCount={Math.ceil(filteredIcons.length / ICONS_PER_ROW)}
              columnWidth={40}
              width={40 * ICONS_PER_ROW}
              rowHeight={50}
              height={400}
            >
              {({ columnIndex, rowIndex, style }) => {
                const iconIndex = ICONS_PER_ROW * rowIndex + columnIndex;
                return iconIndex >= filteredIcons.length ? null : (
                  <div style={style}>
                    <IconWrapperStyled
                      key={filteredIcons[iconIndex].iconKey}
                      color={color || (suborganization?.config?.theme || {})['@primary-color'] || DEFAULT_COLOR}
                      className={`IconSelector__icon-wrapper ${(selectedIcon || defaultIcon) === filteredIcons[iconIndex].iconKey ? 'is-selected' : ''}`}
                      onClick={() => handleSelection(filteredIcons[iconIndex].iconKey)}
                    >
                      <AplanetIcon
                        className={`IconSelector__icon-item ${(selectedIcon || defaultIcon) === filteredIcons[iconIndex].iconKey ? 'is-selected' : ''}`}
                        faStyle='fad'
                        name={filteredIcons[iconIndex].iconKey}
                        title={filteredIcons[iconIndex].iconName}
                      />
                    </IconWrapperStyled>
                  </div>
                );
              }}
            </Grid>
          </div>
        ) : null}
      </div>
    </section>
  );
};

IconSelector.propTypes = {
  label: PropTypes.string,
  defaultIcon: PropTypes.string,
  selectedIcon: PropTypes.string,
  onSelectIcon: PropTypes.func.isRequired,
  color: PropTypes.string,
};

export default IconSelector;
