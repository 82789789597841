import React, {useState,useCallback} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import CustomInput from 'components/CustomInput';

import {
  EditOutlined,
  CheckOutlined,
  PlusOutlined,
} from '@ant-design/icons';

const CommentColumn = ({
  intl,
  comment = '',
  onChangeComment
}) => {
  const [showEditComment, setShowEditComment] = useState(false);
  const [commentInput, setCommentInput] = useState(comment);
  
  const handleSaveComment = useCallback(() => {
    onChangeComment(commentInput);
    setShowEditComment(false);
  }, [
    commentInput,
    onChangeComment,
  ]); 

  return (
    <div className="CommentColumn">
      {showEditComment ? (
        <div className="CommentColumn__wrapper">
          <CustomInput.TextArea
            id="Comment"
            className="CommentColumn__commentInput"
            placeholder={intl.formatMessage({id: 'step_3_add_comment_placeholder'})}
            rows={1}
            value={commentInput}
            onChange={e => setCommentInput(e.target.value)}
            onPressEnter={handleSaveComment}
          />
          <CheckOutlined onClick={handleSaveComment} />
        </div>
      ) : (
        <div className="CommentColumn__wrapper">
          {comment ? (
            <>
              <span className="CommentColumn__comment">
                {comment}
              </span>
              <EditOutlined onClick={() => setShowEditComment(true)} />
            </>
          ) : (
            <button
              className="CommentColumn__addComment"
              onClick={() => setShowEditComment(true)}
            >
              <PlusOutlined /> {intl.formatMessage({id: 'step_3_add_comment'})}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

CommentColumn.propTypes = {
  comment: PropTypes.string,
  onChangeComment: PropTypes.func.isRequired,
};

export default injectIntl(CommentColumn);
