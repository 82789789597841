import React, { useMemo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { flatten, chain, uniqBy } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSDGsClustering } from 'hooks/sdg';
import EsgLogo from 'components/EsgLogo';
import SdgMiniLogo from 'components/SdgMiniLogo';
import CustomTagSimple from 'components/CustomTagSimple';
import CustomButton from 'components/CustomButton';

const shortStandard = {
    'gri': 'GRI',
    'gri-2021': 'GRI-2021',
    'equality': 'EQ',
    'aplanet': 'Aplanet',
    'sdg': 'SDG',
    'sdgc': 'SDG-C',
    'bcorp': 'B-Corp',
    'einf': 'EINF',
    'euss': 'Electric Utilities Sector Supplement',
    'ungc': 'UNGC',
    'tefce': 'TEFCE',
    'prme': 'PRME',
    'sasb-hc-dy': 'SASB-HC-DY',
    'sasb-hc-di': 'SASB-HC-DI',
    'sasb-hc-dr': 'SASB-HC-DR',
    'sasb-hc-mc': 'SASB-HC-MC',
    'sasb-hc-ms': 'SASB-HC-MS',
    'sasb-hc-bp': 'SASB-HC-BP',
    'sasb-fn-ac': 'SASB-FN-AC',
    'sasb-fn-cb': 'SASB-FN-CB',
    'sasb-fn-cf': 'SASB-FN-CF',
    'sasb-fn-in': 'SASB-FN-IN',
    'sasb-fn-ib': 'SASB-FN-IB',
    'sasb-fn-mf': 'SASB-FN-MF',
    'sasb-fn-ex': 'SASB-FN-EX',
    'sasb-cg-aa': 'SASB-CG-AA',
    'sasb-cg-mr': 'SASB-CG-MR',
    'tcfd': 'TCFD',
    'sasb-fb-fr': 'SASB-FB-FR',
    'sasb-if-re': 'SASB-IF-RE',
    'sfdr': 'SFDR',
    'shift': 'SHIFT',
    'scority': 'SCORITY',
    'ghg': 'GHG',
    'neutrality': 'Neutrality',
};

// NOTICE: Some memoized components to avoid repeating the same over and over again...
const MemoEsg = React.memo(({
    esg,
    uncolored = false
  }) => {

    return (
      <EsgLogo
        type={esg}
        faStyle={uncolored ? "fal" : "fad"}
        uncolored={uncolored}
      />
    );
  });
  
  // NOTICE: String paramters (and not Object) to avoid memoizantion failures
const MemoSdg = React.memo(({
    slug,
    parent_slug,
    code,
    uncolored = false,
    text = null
    }) => {
    const sdg = {
        slug,
        parent_slug,
        code, 
        text
    };
    return (
        <SdgMiniLogo
            sdg={sdg}
            unColored={uncolored}
            text={text}
        />
    );
});

const MemoTag = React.memo(({
    tag,
    colorclass,
  }) => {
    return (
      <CustomTagSimple
        name={tag}
        colorclass={ colorclass }
      />
    );
  });

const KpiTreeExtraColumn = ({ intl, nodeData, column }) => {

    const clusterSDGs = useSDGsClustering();
    const clusteredSDGs = useCallback((sdgs) => {
        return clusterSDGs(sdgs).sort((a, b) => a.code - b.code);
    }, [clusterSDGs]);

    const getRecursiveChildren = useCallback((category) => flatten(category?.children?.map(child => {
        if (child.isCategory) return getRecursiveChildren(child)
        else return child
    })), []);

    const checkRecursiveChildren = useCallback(( checkFunc, childs = [] ) => { 

        return childs.every(child => 
                (!child?.isCategory && checkFunc(child) ) || 
                (!!child?.isCategory && checkRecursiveChildren( checkFunc, child.children  || [] ))
        );

    }, []);

    const arrEsgs = useMemo(() => {
    
        const esgs = nodeData.isCategory 
                            ? chain(getRecursiveChildren(nodeData)?.map(child => child.esgs)).flatten().uniq().compact().value()
                            : nodeData.esgs;

        return esgs.map((esg) => {
            const isSharedByAllChilds = nodeData.isCategory && checkRecursiveChildren((child) => {
                                            return child.esgs.some(e => e === esg)
                                        }, nodeData.children);

            return {
                esg,
                uncolored: nodeData.isCategory && (!isSharedByAllChilds)
            };
        });

    },[getRecursiveChildren, checkRecursiveChildren, nodeData]);

    const arrSdgs = useMemo(() => {

        const sdgs = nodeData.isCategory 
                            ? chain(getRecursiveChildren(nodeData)?.map(child => clusteredSDGs(child.sdgs))).flatten().uniqBy('code').value()
                            : clusteredSDGs(nodeData.sdgs);

        return sdgs.map((sdg) => {
            
            const isSharedByAllChilds = nodeData.isCategory && checkRecursiveChildren((child) => {
                                            return clusteredSDGs(child.sdgs).some(e => e.code === sdg.code) 
                                        }, nodeData.children);

            return {
                ...sdg,
                uncolored: nodeData.isCategory && (!isSharedByAllChilds)
            };
        });

    },[clusteredSDGs, getRecursiveChildren, checkRecursiveChildren, nodeData])

    const arrStandards = useMemo(() => {
        
        const standards = nodeData.isCategory 
                            ? chain(getRecursiveChildren(nodeData)?.map(child => child.standard_info)).flatten().uniqBy('standard').compact().value() 
                            : nodeData.standard_info;

        return standards.map(({ standard }) => {
            const isSharedByAllChilds = nodeData.isCategory && checkRecursiveChildren((child) => {
                                            return child.standard_info?.some(e => e.standard === standard) 
                                        }, nodeData.children);

            return {
                standard,
                colorclass: (!nodeData.isCategory) || isSharedByAllChilds ? "standard" : "grey"
            };
        });

    },[getRecursiveChildren, checkRecursiveChildren, nodeData])

    const arrPeriodicities = useMemo(() => {
        
        const periodicities = nodeData.isCategory 
                                ? chain(getRecursiveChildren(nodeData)?.map(child => child.periodicities)).flatten().uniq().compact().value() 
                                : nodeData.periodicities;

        if (nodeData.isCategory && periodicities.length > 1){
            return [{
                periodicity: 'mix',
                colorclass: 'grey'
            }]
        }

        return periodicities.map(periodicity => { 
            return {
                periodicity,
                colorclass: periodicity,
            };
        });

    },[getRecursiveChildren, nodeData]);

    const openKpi = ({
        slug
      }) => {
        const url = `/kpi/${slug}/last/data`;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    }

    switch (column){

        case 'sdg':
            return uniqBy(arrSdgs.filter(item => !!item.code), 'code')
                    .slice(0, 3)
                    .map((sdg, index) => {

                return (
                    <MemoSdg 
                        key={ sdg.code } 
                        slug={ sdg.slug }
                        parent_slug={ sdg.parent_slug }
                        code={ sdg.code } 
                        uncolored={ sdg.uncolored } 
                        text={ index > 1 && arrSdgs.length > 3 ? '...' : sdg.code }
                    />
                )
            });
        case 'esg': 
            return uniqBy(arrEsgs.filter(item => !!item.esg), 'esg')
                    .map(({ esg, uncolored }) => 
                        <MemoEsg 
                            key={ esg } 
                            uncolored={ uncolored }
                            esg={ esg } 
                        />)
        case 'periodicity':
            return uniqBy(arrPeriodicities.filter(item => !!item), 'periodicity')
                    .slice(0, 3)
                    .map(({ periodicity, colorclass }, index) => { 
                
                return (
                        <MemoTag 
                            key={ periodicity }
                            colorclass={ colorclass }
                            tag={ index > 1 && arrPeriodicities.length > 2 ? '...' : intl.formatMessage({ id: `kpi_periodicity_tags_${periodicity}` }) } 
                        />
            ) })
        case 'standard':
            return uniqBy(arrStandards.filter(item => !!item), 'standard')
                    .slice(0, 3)
                    .map(({ standard, colorclass }, index) => (
                        <MemoTag  
                            key={ standard }
                            colorclass={ colorclass }
                            tag={ index > 1 && arrStandards.length > 2 ? '...' : shortStandard[standard] } 
                        />
            ))
        case 'link':
            return <CustomButton 
                        tooltip={ intl.formatMessage({ id: 'kpi_tree_selector_column_detail'}) }
                        icon={<InfoCircleOutlined />}
                        size="small"
                        onClick={(e) => {e.stopPropagation(); openKpi(nodeData)}}
                    />
        default: 
            return <></>

    }

}

export default injectIntl(KpiTreeExtraColumn);
