import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types'
import GridLayout from 'react-grid-layout';
import { isEqual, isEmpty, pick } from 'lodash';
import { formatDate } from 'utils/date';
import useOrganizations from 'utils/useOrganizations';
import { useFeatureList } from 'components/FeatureSwitch';

import setupIcons from './icons';
import Card from './Card';
import { Empty } from 'tsComponents/emptyStates/Empty';
import { getEmptyResultsProps } from 'tsComponents/emptyStates/emptyProps';

import 'react-grid-layout/css/styles.css' 
import 'react-resizable/css/styles.css' 
import './style.less';

import { 
  calculateGridLayoutFromTemplate, 
  DASHBOARD_DEFAULT_SIZE_FOR_CARD, 
  DASHBOARD_GRID_LAYOUT_COLS, 
  DASHBOARD_GRID_LAYOUT_ROW_HEIGHT, 
  sortGridLayoutByLoadLinearOrder
} from 'utils/dashboard';

setupIcons();

function getWindowSize() {

  const gridObj = document.querySelector('#gridWrapper');

  if (!gridObj) return undefined;

  return {
    width: (gridObj || {}).offsetWidth,
    height: (gridObj || {}).offsetHeight,
  }
}

const getTemplateByOrderLoading = (template, gridLayout) => {

  const orderedGridLayout = sortGridLayoutByLoadLinearOrder(gridLayout);
  const orderedTemplate = orderedGridLayout.map(item => template.find(({ id }) => id === item.i));
  return orderedTemplate.filter( item => !!item );

}

const getInitialLayout = (template, gridLayout) => {
  
  if (!template.length) return [];
  
  if (gridLayout?.length && gridLayout?.length === template.length){
    return JSON.parse(JSON.stringify(gridLayout));
  }
  
  // Check react-grid-layout issue with layout's reference that is not changing 
  return JSON.parse(JSON.stringify(calculateGridLayoutFromTemplate(template)));
 
};

const DEFAULT_TEMPLATE = [];
const DEFAULT_PARAMETERS_FOR_CARD = {};
const DEFAULT_DATA_FOR_CARD = {};
const DEFAULT_ICON_FOR_CARD = undefined;
const DEFAULT_TITLE_FOR_CARD = '';
const DEFAULT_DESCRIPTION_FOR_CARD = '';
const DEFAULT_ACTIONS_FOR_CARD = [];
const DEFAULT_SHOW_DATA_LABELS_FOR_CARD = false;
const DEFAULT_SHOW_DATA_TARGET_FOR_CARD = false;
const DEFAULT_NODE_LEVEL_FOR_CARD = 1;
const DEFAULT_DEFAULT_PARAMETERS = {};
const DEFAULT_RENDER_EXTRAS = undefined;
const DEFAULT_ON_DELETE_CARD = undefined;
const DEFAULT_ON_EDIT_CARD = undefined;
const DEFAULT_ON_EDITKPI = undefined;
const DEFAULT_PERMISSIONS = {};

const getInitialDataByCard = ( template ) => {
    
  const initialData = template.reduce((obj, curr) => {
    return {
      ...obj,
      [curr.id]: null
    }
  }, {});

  return initialData;

}

const Dashboard = ({ 
  intl,
  template: originalTemplate = DEFAULT_TEMPLATE,
  hasFilter,
  defaultParameters = DEFAULT_DEFAULT_PARAMETERS,
  renderExtras = DEFAULT_RENDER_EXTRAS,
  onEditKpi = DEFAULT_ON_EDITKPI,
  permissions = DEFAULT_PERMISSIONS,  
  handleEditCard = DEFAULT_ON_EDIT_CARD,
  handleDeleteCard = DEFAULT_ON_DELETE_CARD,
  gridLayout,
  gridLayoutUpdate,
  isSharedDashboard = false,
  activeTab
}) => {

  const t = intl.messages;
  const { data } = useSelector(state => state.dashboard);

  const {
    suborganization,
  } = useOrganizations();

  const { suborganizationFeatures: featureList } = useFeatureList();
  const { dashboard_theme, theme } = suborganization.config;
  const [ windowSize, setWindowSize ] = useState();
  const [ dataByCard, setDataByCard ] = useState( getInitialDataByCard(originalTemplate) );
  const grid_layout = useMemo(() => getInitialLayout(originalTemplate, gridLayout), [originalTemplate, gridLayout]);
  const template = useMemo(() => getTemplateByOrderLoading(originalTemplate, grid_layout), [originalTemplate, grid_layout]);

  useEffect(() => {
    
    function handleWindowResize(){
      setWindowSize(getWindowSize());
    }

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);
    
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

  }, []);

  const handleLayoutChange = (newLayout) => {
    const newFormattedGridLayout = newLayout.map( item => pick(item, ['h','w','x','y','i','moved','static'] ));
    if (isEqual(grid_layout, newFormattedGridLayout)) return;
    gridLayoutUpdate({ grid_layout: JSON.stringify(newLayout) })
  }

  const getTranslatedData = useCallback(( data ) => {
    
      if(!data) return {};

      const { id, ...datum } = data;

      return {
        ...datum,
        updated_at: !datum.updated_at
          ? datum.updated_at
          : `${t.dashboard_updated_at} ${ formatDate(datum.updated_at, t.dashboard_updated_at_format, intl) }`,
        series: datum.series,
        keys: datum.keys,
        samples: datum.samples,
      };
    
  }, [
    t,
    intl,
  ]);

  useEffect(() => {

    if (isEmpty(data)) return; 

    setDataByCard( prevData => {
      
      const updatedCardKeys = Object.keys(data || {}).filter( cardKey => !isEqual(data[cardKey], prevData[cardKey]));
      const updatedData = updatedCardKeys.map( cardKey => data[cardKey] ).reduce( (obj, curr) => {
        return { 
          ...obj,
          [curr.id]: getTranslatedData(curr) 
        }
      }, {});
      
      return {
        ...prevData,
        ...updatedData
      };

    })

  }, [data, getTranslatedData]);

  const children = useMemo(() => {
    
    return template.map(({
      id,
      type,
      custom_chart: customChart,
      size = DASHBOARD_DEFAULT_SIZE_FOR_CARD,
      parameters = DEFAULT_PARAMETERS_FOR_CARD,
      icon = DEFAULT_ICON_FOR_CARD,
      title = DEFAULT_TITLE_FOR_CARD,
      description = DEFAULT_DESCRIPTION_FOR_CARD,
      actions = DEFAULT_ACTIONS_FOR_CARD,
      show_data_labels = DEFAULT_SHOW_DATA_LABELS_FOR_CARD,
      show_data_target = DEFAULT_SHOW_DATA_TARGET_FOR_CARD,
      node_level = DEFAULT_NODE_LEVEL_FOR_CARD
    }) => (
      <div key={id}>
        <Card
          key={id}
          id={id}
          type={type}
          icon={icon}
          title={title}
          description={description}
          actions={actions}
          customChart={customChart}
          size={size}
          showDataLabels={show_data_labels}
          showDataTarget={ featureList.has('targets') && show_data_target }
          nodeLevel={node_level}
          parameters={{ ...defaultParameters, ...parameters, }} 
          data={ dataByCard[id] || DEFAULT_DATA_FOR_CARD }
          renderExtras={renderExtras}
          onEditKpi={onEditKpi}
          permissions={permissions}
          handleEditCard={handleEditCard}
          handleDeleteCard={handleDeleteCard} 
          theme={theme}
          dashboardTheme={dashboard_theme}
          canEditCard={!isSharedDashboard}
          canDeleteCard={!isSharedDashboard}
          activeTab={activeTab}
        />
      </div>
    ));
  }, [
    dashboard_theme,
    theme,
    // data,
    defaultParameters,
    handleDeleteCard,
    handleEditCard,
    onEditKpi,
    permissions,
    renderExtras,
    template,
    isSharedDashboard,
    featureList,
    dataByCard,
    activeTab
  ]);
  
  const hasChildren = Boolean(children?.length);
  if (!hasChildren && hasFilter) {
    return <Empty {...getEmptyResultsProps(false, hasFilter)} />
  }

  return (
      <div id="gridWrapper">
        {
          windowSize && 
          <GridLayout 
            className="layout" 
            cols={ DASHBOARD_GRID_LAYOUT_COLS }
            rowHeight={ DASHBOARD_GRID_LAYOUT_ROW_HEIGHT }
            width={ windowSize.width }
            compactType={'vertical'}
            onDragStop={ handleLayoutChange }
            onResizeStop={ handleLayoutChange }
            layout={grid_layout} 
            isDraggable={true}
          >
          {
            children
          }
          </GridLayout>
        }
      </div>
  );
};

Dashboard.propTypes = {
  template: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      size: PropTypes.oneOf(['small', 'medium', 'large']),
      parameters: PropTypes.object,
      icon: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
      title: PropTypes.node,
      description: PropTypes.string,
      tags: PropTypes.arrayOf( PropTypes.string ),
      actions: PropTypes.arrayOf(
        PropTypes.element
      ),
      show_data_labels: PropTypes.bool,
      show_data_target: PropTypes.bool,
      periodicity: PropTypes.string,
      node_level: PropTypes.number
    })
  ),
  layout: PropTypes.array,
  // data: PropTypes.objectOf( propTypes.data ),
  hasFilter: PropTypes.bool,
  defaultParameters: PropTypes.object,
  renderExtras: PropTypes.func,
  onDownload: PropTypes.func,
  isSharedDashboard: PropTypes.bool,
  gridLayoutUpdate: PropTypes.func,
  activeTab: PropTypes.string
};

export default injectIntl(Dashboard);

