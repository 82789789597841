import React from "react";
import styled from "@emotion/styled";
import { Tooltip } from "antd";

import CustomTagSimple from "components/CustomTagSimple";
import { TooltipTagList } from "./TooltipTagList";

export interface TagListProps {
  /**
   * Array of tag string
   */
  tags: string[];
  /**
   * Number of tags to be displayed directly
   */
  showNTags?: number;
}

export const StyledTagList = styled.div`
  .CustomTagSimple:last-child {
    margin-right: 0;
  }
`;

/**
 * Empty component with a message and children components for actions
 */
export const TagList = ({ tags, showNTags = 3 }: TagListProps) => {
  const tagNodes = tags
    .slice(0, showNTags)
    .map((tag, i) => <CustomTagSimple key={`${tag}-${i}`} name={tag} />);
  return (
    <StyledTagList>
      {tagNodes.slice(0, showNTags)}
      {tags.length > showNTags && (
        <Tooltip title={<TooltipTagList tags={tags.slice(showNTags)} />}>
          <CustomTagSimple name="..." />
        </Tooltip>
      )}
    </StyledTagList>
  );
};
