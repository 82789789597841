import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { toBetweenDotsDateFormat } from 'utils/date';
import AplanetIcon from 'components/AplanetIcon';

const DataRequestsActionsCell = ({
  intl,
  showCopyData,
  showEdit,
  showSendReminder,
  showReStart,
  showStop,
  showDelete,
  showOpenKPI,
  disabledCopyData = false,
  disabledSendReminder = false,
  onEdit,
  onDelete,
  onCopyData,
  onOpenKPI,
  onSendReminder,
  onReStart,
  onStop,
  lastActionDate,
  lastReminderSentDate,
  valueSource,
  isReadyToValidate
}) => (
  <td className='DataRequests__actions-cell'>
    {showCopyData ? (
      <AplanetIcon
        className={`
          DataRequests__copy-action
          ${disabledCopyData ? 'is-disabled' : ''}
        `}
        name="Copy"
        title={
          isReadyToValidate ?
          intl.formatMessage({ id: "admin_data_requests_table_actions_disabled_copy_ready_to_validate"})
          :
          valueSource === "manual"
            ? intl.formatMessage({ id: "admin_data_requests_table_actions_copy_title"})
            : intl.formatMessage({ id: "admin_data_requests_table_actions_disabled_copy_not_manual"})}
        onClick={disabledCopyData ? null : onCopyData}
        size="18px"
      />
    ) : null}
    {showEdit ? (
      <AplanetIcon
        className='DataRequests__edit-action'
        name="Edit"
        title={intl.formatMessage({ id: "admin_data_requests_table_actions_edit_title"})}
        onClick={onEdit}
        size="18px"
      />
    ) : null}
    {showOpenKPI ? (
      <AplanetIcon
        className='DataRequests__open-action'
        name="Data management"
        title={intl.formatMessage({ id: "admin_data_requests_table_actions_open_title"})}
        onClick={onOpenKPI}
        size="18px"
      />
    ) : null}
    {showSendReminder ? (
      <AplanetIcon
        className={`
          DataRequests__reminder-action
          ${disabledSendReminder ? 'is-disabled' : ''}
        `}
        name="Alarm"
        title={intl.formatMessage({ id: "admin_data_requests_table_actions_reminder_title"})}
        onClick={disabledSendReminder ? null : onSendReminder}
        size="18px"
      />
    ) : null}
    {showReStart ? (
      <AplanetIcon
        className='DataRequests__restart-action'
        name="Refresh"
        title={intl.formatMessage({ id: "admin_data_requests_table_actions_restart_title"})}
        onClick={onReStart}
        size="18px"
      />
    ) : null}
    {showStop ? (
      <AplanetIcon
        className='DataRequests__stop-action'
        name="Stop"
        title={intl.formatMessage({ id: "admin_data_requests_table_actions_stop_title"})}
        onClick={onStop}
        size="18px"
      />
    ) : null}
    {showDelete ? (
      <AplanetIcon
        className='DataRequests__delete-action'
        name="Bin"
        title={intl.formatMessage({ id: "admin_data_requests_table_actions_delete_title"})}
        onClick={onDelete}
        size="18px"
      />
    ) : null}
    {lastActionDate ? (
      <span className='DataRequests__last-action'>
        {intl.formatMessage({id: `admin_data_requests_table_last_action_response_in_use`}, {date: toBetweenDotsDateFormat(lastActionDate, intl)})}
      </span>
    ) : null}
    {lastReminderSentDate ? (
      <span className='DataRequests__last-action'>
        {intl.formatMessage({id: `admin_data_requests_table_last_reminder_sent_date`}, {date: toBetweenDotsDateFormat(lastReminderSentDate, intl)})}
      </span>
    ) : null}
  </td>
)

DataRequestsActionsCell.propTypes = {
  showCopyData: PropTypes.bool,
  showEdit: PropTypes.bool,
  showSendReminder: PropTypes.bool,
  showReStart: PropTypes.bool,
  showStop: PropTypes.bool,
  showDelete: PropTypes.bool,
  showOpenKPI: PropTypes.bool,
  disabledCopyData: PropTypes.bool,
  disabledSendReminder: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCopyData: PropTypes.func,
  onOpenKPI: PropTypes.func,
  onSendReminder: PropTypes.func,
  onReStart: PropTypes.func,
  onStop: PropTypes.func,
  lastActionDate: PropTypes.string,
  lastReminderSentDate: PropTypes.string || null,
  valueSource: PropTypes.oneOf(['manual', 'calculated', 'aggregated', 'parent', 'children']),
};

export default injectIntl(DataRequestsActionsCell);
