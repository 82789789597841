import React from "react";
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';

import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import './style.less';

const { Panel } = Collapse;

const KPIS_NOT_APPLY_DEFAULT = [];

const WarningModalContentStep1 = ({
  intl,
  kpisWithNotApply = KPIS_NOT_APPLY_DEFAULT,
}) => {

  return (
    <div className="WarningModalContentStep1">
      <div className="WarningModalContentStep1__message">
        <span>
          {intl.formatMessage(
            {id: `data_requests_warning_modal_message_1_step_1_next`},
            {
              indicators: <b style={{color: '#D38106'}}>{intl.formatMessage({id: `data_requests_warning_modal_step_1_indicators`})}</b>,
              doNotApply: <b style={{color: '#D38106'}}>{intl.formatMessage({id: `data_requests_warning_modal_step_1_not_apply`})}</b>,
              organizations: <b style={{color: '#D38106'}}>{intl.formatMessage({id: `data_requests_warning_modal_step_1_organizations`})}</b>,
            }
          )}
        </span>
        <span>{intl.formatMessage({id: `data_requests_warning_modal_message_2_step_1_next`})}</span>        
      </div>
      <div>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="WarningModalContentStep1__collapse"
        >
          <Panel header={intl.formatMessage({id: `data_requests_warning_modal_see_indicators_list_not_apply`})} key="1" className="WarningModalContentStep1__panel">
            {kpisWithNotApply.map(kpiInfo => (
              <div key={kpiInfo.slug} className="WarningModalContentStep1__wrapper">
                <div className="WarningModalContentStep1__notApplyNodes">{kpiInfo.notApplyNodes.map(node => node.name).join(", ")}</div>
                <div title={kpiInfo.name} className="WarningModalContentStep1__kpiName">{kpiInfo.name}</div>
              </div>
            ))}
          </Panel>
        </Collapse>
      </div>
      <div className="WarningModalContentStep1__question">
        {intl.formatMessage({id: `data_requests_warning_modal_question_step_1_next`})}
      </div>
    </div>
  );
};

WarningModalContentStep1.propTypes = {
  kpisWithNotApply: PropTypes.arrayOf(PropTypes.object),
};

export default injectIntl(WarningModalContentStep1);
