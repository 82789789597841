import intl from 'intl';
import { createIntl, createIntlCache } from 'react-intl';
import { updateIntl } from 'react-intl-redux';

// Locale Data
/*import locale_en from 'react-intl/locale-data/en';
import locale_es from 'react-intl/locale-data/es';
import locale_pt from 'react-intl/locale-data/pt';
import locale_fr from 'react-intl/locale-data/fr';
import locale_it from 'react-intl/locale-data/it';
import locale_ca from 'react-intl/locale-data/ca';*/

// This is a polyfill
import 'intl/locale-data/jsonp/en.js';
import 'intl/locale-data/jsonp/es.js';
import 'intl/locale-data/jsonp/pt.js';
import 'intl/locale-data/jsonp/fr.js';
import 'intl/locale-data/jsonp/ca.js';
import 'intl/locale-data/jsonp/it.js';

// Date-fns locale
import dateFns_en from 'date-fns/locale/en';
import dateFns_es from 'date-fns/locale/es';
import dateFns_pt from 'date-fns/locale/pt';
import dateFns_fr from 'date-fns/locale/fr';
import dateFns_ca from 'date-fns/locale/ca';
import dateFns_it from 'date-fns/locale/it';

// Messages
import messages_es_ES from './es-ES.json';
import messages_en_US from './en-US.json';
import messages_pt_BR from './pt-BR.json';
import messages_pt_PT from './pt-PT.json';
import messages_fr_FR from './fr-FR.json';
import messages_ca_ES from './ca-ES.json';
import messages_it_IT from './it-IT.json';

import messages_dashboard_es_ES from './dashboard/es-ES.json';
import messages_dashboard_en_US from './dashboard/en-US.json';
import messages_dashboard_pt_BR from './dashboard/pt-BR.json';
import messages_dashboard_pt_PT from './dashboard/pt-PT.json';
import messages_dashboard_fr_FR from './dashboard/fr-FR.json';
import messages_dashboard_ca_ES from './dashboard/ca-ES.json';
import messages_dashboard_it_IT from './dashboard/it-IT.json';

// Ant Design
import antd_es from 'antd/lib/locale-provider/es_ES';
import antd_en from 'antd/lib/locale-provider/en_US';
import antd_ptbr from 'antd/lib/locale-provider/pt_BR';
import antd_ptpt from 'antd/lib/locale-provider/pt_PT';
import antd_fr from 'antd/lib/locale-provider/fr_FR';
import antd_ca from 'antd/lib/locale-provider/ca_ES';
import antd_it from 'antd/lib/locale-provider/it_IT';

// DatePicker
import datePicker_es from 'antd/lib/date-picker/locale/es_ES';
import datePicker_en from 'antd/lib/date-picker/locale/en_US';
import datePicker_ptbr from 'antd/lib/date-picker/locale/pt_BR';
import datePicker_ptpt from 'antd/lib/date-picker/locale/pt_PT';
import datePicker_fr from 'antd/lib/date-picker/locale/fr_FR';
import datePicker_ca from 'antd/lib/date-picker/locale/ca_ES';
import datePicker_it from 'antd/lib/date-picker/locale/it_IT';

// Moment
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/pt-br';
import 'moment/locale/fr';
import 'moment/locale/ca';
import 'moment/locale/it';

// Numbers
const DECIMAL_SEPARATOR_COMMA = ',';
const DECIMAL_SEPARATOR_POINT = '.';

//const locales = [...locale_en, ...locale_es, ...locale_pt, ...locale_fr, ...locale_ca, ...locale_it];

const en_US = {
  locale: 'en-US',
  messages: {
    ...messages_en_US,
    dashboard: messages_dashboard_en_US,
  },
  formats: {
    dateFns: dateFns_en,
    decimalSeparator: DECIMAL_SEPARATOR_POINT,
    antd: antd_en,
    datePicker: datePicker_en
  }
};

const es_ES = {
  locale: 'es-ES',
  messages: {
    ...messages_es_ES,
    dashboard: messages_dashboard_es_ES,
  },
  formats: {
    dateFns: dateFns_es,
    antd: antd_es,
    decimalSeparator: DECIMAL_SEPARATOR_COMMA,
    datePicker: datePicker_es
  }
};

const pt_BR = {
  locale: 'pt-BR',
  messages: {
    ...messages_pt_BR,
    dashboard: messages_dashboard_pt_BR,
  },
  formats: {
    dateFns: dateFns_pt,
    antd: antd_ptbr,
    decimalSeparator: DECIMAL_SEPARATOR_COMMA,
    datePicker: datePicker_ptbr
  }
};

const pt_PT = {
  locale: 'pt-PT',
  messages: {
    ...messages_pt_PT,
    dashboard: messages_dashboard_pt_PT,
  },
  formats: {
    dateFns: dateFns_pt,
    antd: antd_ptpt,
    decimalSeparator: DECIMAL_SEPARATOR_COMMA,
    datePicker: datePicker_ptpt
  }
};

const fr_FR = {
  locale: 'fr-FR',
  messages: {
    ...messages_fr_FR,
    dashboard: messages_dashboard_fr_FR,
  },
  formats: {
    dateFns: dateFns_fr,
    antd: antd_fr,
    decimalSeparator: DECIMAL_SEPARATOR_COMMA,
    datePicker: datePicker_fr
  }
};

const it_IT = {
  locale: 'it-IT',
  messages: {
    ...messages_it_IT,
    dashboard: messages_dashboard_it_IT,
  },
  formats: {
    dateFns: dateFns_it,
    antd: antd_it,
    decimalSeparator: DECIMAL_SEPARATOR_COMMA,
    datePicker: datePicker_it
  }
};

const ca_ES = {
  locale: 'ca-ES',
  messages: {
    ...messages_ca_ES,
    dashboard: messages_dashboard_ca_ES,
  },
  formats: {
    dateFns: dateFns_ca,
    antd: antd_ca,
    decimalSeparator: DECIMAL_SEPARATOR_COMMA,
    datePicker: datePicker_ca
  }
};

const config = {
  en: {
    default: en_US,
    'en-US': en_US
  },
  es: {
    default: es_ES,
    'es-ES': es_ES
  },
  pt: {
    default: pt_BR,
    'pt-BR': pt_BR,
    'pt-PT': pt_PT
  },
  fr: {
    default: fr_FR,
    'fr-FR': fr_FR
  },
  it: {
    default: it_IT,
    'it-IT': it_IT
  },
  ca: {
    default: ca_ES,
    'es-ES': ca_ES
  },
  default: {
    default: en_US
  }
};

const defaultLocale = 'en-US';

const getConfig = (locale = defaultLocale) => {
  const langConfig = config[locale.slice(0, 2)] || config.default;
  return langConfig[locale] || langConfig.default;
};

const setupIntl = () => {
  if (typeof window.Intl === 'undefined') window.Intl = intl;
  //addLocaleData(locales);
  const config = getConfig(navigator?.language || navigator?.userLanguage || defaultLocale);
  moment.locale(config.locale);

  return config;
};

const cache = createIntlCache()
let intlObj = createIntl(getConfig(navigator?.language || navigator?.userLanguage || defaultLocale), cache)

const changeLocale = locale => {
  const config = getConfig(locale);
  moment.locale(config.locale);
  intlObj = createIntl(config, cache)
  return updateIntl(config);
};

const formatMessage = (key, values = {}) => intlObj.formatMessage({id: key}, values)

export { setupIntl, changeLocale, formatMessage, intlObj, defaultLocale, getConfig, config };
