import React, {
  useMemo,
  useCallback
} from 'react'
import { injectIntl } from 'react-intl'

import CustomInput from 'components/CustomInput';
import LocaleFlag from 'components/LocaleFlag';
import useOrganizations from 'utils/useOrganizations';

import {
  Form,
  Collapse,
  Row,
  Col
} from 'antd';

import './style.less';

const { Panel } = Collapse;

const CustomLanguageInput = ({
  intl,
  values,
  errors,
  subtitle = '',
  handleChange,
  placeholder,
  maxLength = 600,
  collapsed = true
}) => {

  const maybeNameTranslations = useMemo(() => values?.name_translations, [values]);
  const nameTranslations = useMemo(() => maybeNameTranslations || [], [maybeNameTranslations]);
  const {
    organization,
    suborganization
  } = useOrganizations();

  const orgLocale = useMemo(
    () => suborganization.language,
    [suborganization.language]
  );

  const preferredLanguages = useMemo(
    () => organization.languages,
    [organization.languages]
  )

  const leftLangs = useMemo(
    () => preferredLanguages.filter(l => !nameTranslations.map(({locale}) => locale).includes(l)),
    [preferredLanguages, nameTranslations]
  )

  const addTranslation = useCallback(({locale, name}) => {
    let lang = []
    if (leftLangs.length && leftLangs.includes(locale)) {
      lang = [...nameTranslations, {locale, name}]
    } else {
      lang = nameTranslations.map(value => value?.locale === locale ? ({locale, name}) : value);
    }
    handleChange('name_translations')(lang);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange, nameTranslations, leftLangs]);

  return (
      <React.Fragment>
        <section className='CustomLanguageInput-section'>
          {
            preferredLanguages.filter(l => l === orgLocale).map(l => {
              return (
              <Form.Item
                colon={false}
                hasFeedback
                validateStatus={!!errors ? 'error' : ''}
                label={<div><LocaleFlag intl={intl} locale={l}/>{intl.formatMessage({id: l})}</div>}
                required
                key={l}
              >
                <CustomInput
                  maxLength={maxLength}
                  placeholder={placeholder}
                  name="name"
                  aria-label="name"
                  value={nameTranslations.find(({locale}) => locale === l)?.name || ''}
                  key={l}
                  onChange={(e) => {
                    addTranslation({locale: l, [e.target.name]: e.target.value});
                  }}
                />
              </Form.Item>)
            })
          }
          {
            errors && <div className="CustomLanguageInput__error">{ errors }</div>
          }
          <Collapse defaultActiveKey={collapsed ? ['1'] : null}>
            <Panel header={intl.formatMessage({id: "custom_language_add"})} key="1">
              <>
                {
                  preferredLanguages.filter(l => l !== orgLocale)
                  .map(l => {
                    return (
                      <Form.Item
                        colon={false}
                        hasFeedback
                        label={<div><LocaleFlag intl={intl} locale={l}/>{intl.formatMessage({id: l})}</div>}
                        key={l}
                      >
                        <Row style={{width: '100%'}} type="flex" justify="space-between" align="middle">
                          <Col style={{width:'100%'}}>
                            <CustomInput
                              maxLength={maxLength}
                              placeholder={placeholder}
                              name="name"
                              value={nameTranslations.find(({locale}) => locale === l)?.name || ''}
                              key={l}
                              onChange={(e) => {
                                addTranslation({locale: l, [e.target.name]: e.target.value});
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                    )
                  })
                }
              </>
            </Panel>
          </Collapse>
        </section>
        <div style={{marginTop: '10px'}}>{ subtitle }</div>
      </React.Fragment>
  );
};

export default injectIntl(
  CustomLanguageInput
);
