const config = {
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN || '',
  SENTRY_SAMPLE_RATE: +process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1.0,
  ATLAS_APP_URL: process.env.REACT_APP_ATLAS_APP_URL || 'https://localhost:3003',
  API_URL: process.env.REACT_APP_API_URL || 'https://localhost:7500/atlas',
  API_URL_NEW: process.env.REACT_APP_API_URL_NEW || 'https://localhost:7500/atlas_4.0',
  ASSETS_URL: 'https://aplanet-static.ams3.digitaloceanspaces.com/assets',
  ACCOUNTS_URL: process.env.REACT_APP_ACCOUNTS_URL || 'https://localhost:3004',
  AUTH_URL: process.env.REACT_APP_AUTH_URL || 'https://localhost:7501',
  ADMIN_APP_URL: process.env.REACT_APP_ADMIN_APP_URL || 'https://localhost:3001',
  WS_APP_URL: process.env.REACT_APP_WS_URL || 'https://localhost:9500',
  ORGANIZATION_COOKIE_DOMAIN: process.env.REACT_APP_ORGANIZATION_COOKIE_DOMAIN || 'localhost',
  ORGANIZATION_COOKIE_NAME: process.env.REACT_APP_ORGANIZATION_COOKIE_NAME || 'aplanet_organization_development',
  INSPECTLET_KEY: process.env.REACT_APP_INSPECTLET_KEY || '',
  USETIFUL_TOKEN: process.env.REACT_APP_USETIFUL_TOKEN || '',
  DEFAULT_FEATURES: [
    'approvals',
    'groups_of_interest',
    'materiality',
    'equality',
    'organization_tree',
    'dashboard',
  ],
  DEFAULT_FEATURES_UPSELLING: [
    'approvals',
    'groups_of_interest',
    'materiality',
    'equality',
    'organization_tree',
    'dashboard',
  ],
  DEFAULT_PRODUCTS: [
    'community',
    'atlas',
    'materiality',
    'equality',
  ],
  UPLOAD_FILE_MAXSIZE_MB: 40,
  POSTHOG_URL: process.env.REACT_APP_POSTHOG_URL || '',
  POSTHOG_KEY: process.env.REACT_APP_POSTHOG_KEY || '',
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
  SUPPORTED_BROWSERS: {
    Chrome: 84,
    Safari: 11,
    Firefox: 82,
    Edge: 84,
    Opera: 69,
    IE: 11,
  },
  DASHBOARD_MAX_CARDS_PER_TAB: process.env.REACT_APP_DASHBOARD_MAX_CARDS_PER_TAB || 12,
  INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID || null,
}

export default config;
