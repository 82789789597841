import React, {
  useMemo,
  useCallback,
  useState
} from 'react';
import { injectIntl } from 'react-intl';

import { useDispatch } from 'react-redux';
import useOrganizations from 'utils/useOrganizations';
import { SettingOutlined } from '@ant-design/icons';

import {
  Button,
} from 'antd';
import ModalKpiInstructions from 'components/ModalKpiInstructions';

import {
  updateGuidelines,
} from 'actions/api';

import {
  findTree,
} from 'utils/tree';

import CollapsableCard from 'components/CollapsableCard';
import { ReactComponent as GuidelinesIcon } from 'assets/icon-guidelines.svg';
import Description from 'components/Description'
import EditableComment from 'components/EditableComment'
import A from 'components/A'

const Guidelines = ({
  intl,
  kpi_slug,
  guidelines_std = '',
  guidelines_org = '',
  guidelines_member = '',
  type,
  loading,
  standard_info = {},
  permissions = {},
  config = {}
}) => {
  const t = intl.messages;
  const {show_guidelines_std} = config;
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  
  const {
    organization,
    suborganization,
    organizationTree
  } = useOrganizations();

  const dispatch = useDispatch();

  const hasChildren = useMemo(() => {
    const treeNode = findTree(
      [ organizationTree ],
      ({ slug }) => slug === suborganization.slug,
    );

    // TODO: MAYBE consider 'restricted' children as non-existing??
    return treeNode && treeNode.children && treeNode.children.length > 0;
  }, [
    suborganization,
    organizationTree,
  ]);

  const handleGuidelinesChange = useCallback((fieldName) => ({comment, target}) => {
    dispatch(
      updateGuidelines(
        organization.slug,
        suborganization.slug,
        [ kpi_slug ],
        {
          [fieldName]: comment,
        },
        target
      )
    )
  }, [
    dispatch,
    organization,
    suborganization,
    kpi_slug,
  ]);

  const documentationUrls = useMemo(() => {
    return Object.keys(standard_info)
      .map(standard => {
        let urls = standard_info[standard].map(({documentation_url}) => documentation_url);
        return urls.length ? [standard, urls] : null;
      })
      .filter(Boolean)
      .reduce((obj, [key, vals]) => {
        obj[key] = (vals || []).filter(Boolean);
        return obj;
      }, {});
  }, [
    standard_info,
  ]);

  return (
    <>
      <CollapsableCard
        title={ t.guidelines }
        icon={<GuidelinesIcon />}
        className='KpiDetail__guidelines'
        action={
          permissions.can_configure_kpi
          && (
            <Button
              shape="circle"
              icon={<SettingOutlined />}
              title={t.change_settings}
              onClick={() => setShowSettingsModal(true)}
              style={{marginRight: 10}}
            />
          )
        }
      >
        {
          show_guidelines_std &&
          <div>
            <div
              className="KpiDetail__guidelines-title"
            >
              { t.guidelines_std }
            </div>
            {
              Object.keys(documentationUrls).length !== 0 && (
                Object.keys(documentationUrls)
                  .filter(standard => documentationUrls[standard] && documentationUrls[standard].length > 0)
                  .map(standard => (
                    <ul
                      key={standard}
                    >
                      {
                        standard === 'aplanet'
                        ? null
                        : (
                          <>
                            { t[`reporting_standard_${standard}`] }
                            { ': ' }
                          </>
                        )
                      }
                      {
                        documentationUrls[standard].map(durl => (
                          <li style={{'listStyle': 'none'}}>
                            <A
                              href={durl}
                              newWindow
                            >
                              { durl }
                            </A>
                          </li>
                        ))
                      }
                    </ul>
                  ))
              )
            }
            <Description description={guidelines_std?.find(gl => gl.locale === intl.locale)?.guidelines || ''} />
          </div>
        }
        {
          !guidelines_org && !permissions.can_configure_kpi
          ? null
          : (
            <EditableComment
              title={t.guidelines_org}
              value={guidelines_org}
              onChange={handleGuidelinesChange('guidelines_org')}
              editable={permissions.can_configure_kpi}
              loading={loading}
              titleClass='KpiDetail__guidelines-title'
              hasChildren= {hasChildren}
            />
          )
        }
        <EditableComment
          title={t.guidelines_member}
          value={guidelines_member}
          onChange={handleGuidelinesChange('guidelines_member')}
          editable
          loading={loading}
          titleClass='KpiDetail__guidelines-title'
        />
      </CollapsableCard>
      <ModalKpiInstructions
        visible={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        show_guidelines_std={show_guidelines_std}
        kpi_slug={kpi_slug}
      />
    </>
  );
};

export default injectIntl(Guidelines);
