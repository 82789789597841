import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import CustomModalType from 'components/CustomModalType';
import DataRequestsInfoModalHeader from './DataRequestsInfoModalHeader';
import AplanetIcon from 'components/AplanetIcon';
import { DELETED_USER_SLUG } from 'utils/user';

const DataRequestsCommentCell = ({
  intl,
  requestedToName,
  responseComment,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <td className='DataRequests__response-comment'>
      {responseComment ? (
        <>
          <AplanetIcon
            name="Comment"
            faStyle="fas"
            title={intl.formatMessage({ id: "admin_data_requests_table_see_comment"})}
            onClick={() => setShowModal(true)}
            size="18px"
          />
          <CustomModalType
            showModal={showModal}
            width={1000}
            onCancel={() => setShowModal(false)}
            title={intl.formatMessage({ id: "admin_data_requests_table_modal_comment_title"}, { value: requestedToName === DELETED_USER_SLUG ? intl.formatMessage({ id: requestedToName }) : requestedToName  })}
            content={showModal ? (
              <>
                <DataRequestsInfoModalHeader />
                <ReactMarkdown
                  className='DataRequests__comment'
                  source={responseComment}
                />
              </>
            ) : null}
            hasCloseButton
          />
        </>
      ) : null}
    </td>
  );
}

DataRequestsCommentCell.propTypes = {
  requestedToName: PropTypes.string.isRequired,
  responseComment: PropTypes.string,
};

export default injectIntl(DataRequestsCommentCell);