import {reverse, compact} from 'lodash'
/* eslint-disable no-useless-escape */
// eslint-disable-next-line no-control-regex
const EMAIL_REGEXP = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;
const URL_REGEXP = /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

const isValidDNI = (text) => {
  const dniLetters = "TRWAGMYFPDXBNJZSQVHLCKE";
  const letter = dniLetters.charAt(parseInt(text, 10) % 23);

  return letter === text.charAt(8);
}

const backendLevelToLevel = (
  level = 0,
) => {
  const binaryArray = reverse(level.toString(2).split(''))
  const levelArray = binaryArray.map((v, i) => v !== '0' ? i+1 : '')
  let result = compact(levelArray);
  return result;
};

const EXTERNAL_REQUEST_START_PATH_REGEXP = /^\/request\//;

const isExternalPath = (pathname) => {
  return EXTERNAL_REQUEST_START_PATH_REGEXP.test(pathname);
};

const ATTACHMENT_FILE_OPTIONS = ['doc', 'pdf', 'xls'];
const ATTACHMENT_IMAGE_OPTIONS = ['png', 'jpeg', 'jpg'];

const checkByFileTypes = (fileExtension, typesToCheck = []) => {
  const imageOptions = [
    ...ATTACHMENT_IMAGE_OPTIONS,
    ...ATTACHMENT_IMAGE_OPTIONS.map(im => im.toUpperCase()),
  ];
  const fileAndImageOptions = ([
    ...ATTACHMENT_FILE_OPTIONS,
    ...ATTACHMENT_FILE_OPTIONS.map(f => f.toUpperCase()),
    ...imageOptions,
  ]);

  let regex, fileType;
  let isFileType = false;
  
  for (let i = 0, l = typesToCheck.length; i < l; i++) {
    fileType = typesToCheck[i];
    switch (fileType) {
      case 'other':
        regex = new RegExp(fileAndImageOptions.join('|'));
        isFileType = !regex.test(fileExtension);
        break;
      case 'image':
        regex = new RegExp(imageOptions.join('|'));
        isFileType = regex.test(fileExtension);
        break;
      default:
        regex = new RegExp([fileType, fileType.toUpperCase()].join('|'));
        isFileType = regex.test(fileExtension);
        break;
    }
    if (isFileType) {
      break;
    }
  }
  return isFileType;
};

export {
  EMAIL_REGEXP,
  URL_REGEXP,
  EXTERNAL_REQUEST_START_PATH_REGEXP,
  isExternalPath,
  isValidDNI,
  backendLevelToLevel,
  checkByFileTypes,
};
