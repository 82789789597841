import React, { Fragment, useMemo, useEffect, useState, useCallback } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { withRouter } from 'react-router';
import { injectIntl, FormattedMessage } from 'react-intl';
import { sortBy } from 'lodash'
import { PlusOutlined, EditOutlined, DeleteOutlined, ShareAltOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';

import CustomButton from 'components/CustomButton';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';

import useOrganizations from 'utils/useOrganizations';
import { findTree } from 'utils/tree';

import MainLayout from 'components/MainLayout';
import { Loading } from 'tsComponents/emptyStates/Loading';
import SdgMiniLogo from 'components/SdgMiniLogo';
import EsgLogo from 'components/EsgLogo'
import DashboardComponent from 'components/Dashboard';
import DashboardFilters from 'components/DashboardFilters';
import useSetState from 'utils/useSetState';
import { Empty, EmptyCard } from 'tsComponents/emptyStates/Empty';
import { ButtonGroup } from 'tsComponents/button/ButtonGroup';
import { emptyPropsMap } from 'tsComponents/emptyStates/emptyProps';

import {
  createDashboard,
  deleteDashboard,
  updateDashboard,
  requestDashboard,
  deleteDashboardCard,
  requestDashboards,
  requestDashboardCardKpiValues
} from 'actions/api';


import ModalCUD from './screens/ModalCUD';
import ModalShareDashboard from './screens/ModalShareDashboard';
import AplanetIcon from 'components/AplanetIcon';

import { formatMessage } from '../../intl'

import { useSDGsClustering } from "../../hooks/sdg";
import { useEventTracking } from 'hooks/useEventTracking';
import config from 'config';
import './style.less';

const Dashboard = ({
  intl,
  history,
  match,
}) => {
  const t = intl.messages;

  const eventTracking = useEventTracking();
  const {
    organization,
    suborganization,
    permissions,
    organizationTree
  } = useOrganizations();

  const dispatch = useDispatch();

  const {
    data: dashboardTypes,
    loading: isDashboardTypesLoading
  } = useSelector(state => state.dashboard_list);

  const {
    data: dashboardValues,
    template,
    loading,
    loadingDashboardValues,
    grid_layout,
    shared_dashboard_ids,
    organization_id: dashboard_organization_id
  } = useSelector(state => state.dashboard);

  const [dashboardModalType, setDashboardModalType] = useState("");
  const [dateFilterValue, setDateFilterValue] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);

  const handleAddNewCard = () => {
    history.push(`/dashboard/${match.params.activeTab}/add-card`);
  }

  const handleEditCard = (card_slug) => {
    history.push(`/dashboard/${match.params.activeTab}/${card_slug}/edit`);
  }

  const enabledDashboards = useMemo(() => {

    if (isDashboardTypesLoading) {
      return [];
    }

    const sortedDashboardTypes = sortBy(dashboardTypes, (dashboard) => dashboard.name.toLowerCase());
    return sortedDashboardTypes.map(({ organization_id, slug, name, grid_layout }) => {

      const dashboard_org = findTree([organizationTree], (node) => node.id === organization_id)
      return {
        slug,
        name: (
          <>
            <span className="enabledDashboardsTab" >{name || t[`reporting_standard_${slug}`] || t.reporting_standard_custom}</span>
            {organization_id === suborganization.id ? null : (
              <AplanetIcon
                className="enabledDashboardsTab__icon"
                name="Refresh"
                size="15px"
                title={formatMessage('dashboard_shared_by', { id: dashboard_org.name })}
              />
            )}
          </>
        ),
        grid_layout,
      };
    });
  }, [dashboardTypes, organizationTree, suborganization.id, t, isDashboardTypesLoading]);

  // <FilterLogic>
  const {
    set: filteredSdgs,
    has: isSdgFilterSet,
    toggle: toggleSdgFilter,
    replaceAll: setFilteredSdgs,
  } = useSetState([]);
  const sdgFilterSet = useMemo(() => filteredSdgs.length > 0, [filteredSdgs]);

  const {
    set: filteredTypes,
    has: isTypeFilterSet,
    toggle: toggleTypeFilter,
    replaceAll: setFilteredTypes,
  } = useSetState([]);
  const typeFilterSet = useMemo(() => filteredTypes.length > 0, [filteredTypes]);

  const isFilterSet = useMemo(() => {
    return (
      sdgFilterSet ||
      typeFilterSet
    );
  }, [
    sdgFilterSet,
    typeFilterSet,
  ]);

  const filterState = useMemo(() => {
    return {
      sdg: filteredSdgs,
      type: filteredTypes,
    };
  }, [
    filteredSdgs,
    filteredTypes,
  ]);

  const hideCUDModal = () => setDashboardModalType("")

  const handleNewDashboard = (name) => {
    dispatch(
      createDashboard(
        organization.slug,
        suborganization.slug,
        name
      )
    );
    hideCUDModal()
  }

  const handleDashboardDelete = () => {
    dispatch(
      deleteDashboard(
        organization.slug,
        suborganization.slug,
        match.params.activeTab
      )
    );
    hideCUDModal();
    history.push(`/dashboard`);
  }

  const handleDashboardUpdate = (body) => {
    dispatch(
      updateDashboard(
        organization.slug,
        suborganization.slug,
        match.params.activeTab,
        body
      )
    );
    hideCUDModal()
  }

  const handleDeleteCard = (card_slug) => {
    dispatch(
      deleteDashboardCard(
        organization.slug,
        suborganization.slug,
        match.params.activeTab,
        card_slug
      )
    )
  }


  const handleFilter = useCallback((type) => (value) => {
    switch (type) {
      case 'sdg':
        if (Array.isArray(value)) {
          setFilteredSdgs(value);
        } else {
          toggleSdgFilter(value);
        }
        break;
      case 'type':
        if (Array.isArray(value)) {
          setFilteredTypes(value);
        } else {
          toggleTypeFilter(value);
        }
        break;
      default:
    }
  }, [
    setFilteredSdgs,
    setFilteredTypes,
    toggleSdgFilter,
    toggleTypeFilter,
  ]);

  const selectedTab = useMemo(() => dashboardTypes.find(item => item.slug === match.params.activeTab), [dashboardTypes, match.params.activeTab]);

  const onTabLoaded = useCallback(() => {

    eventTracking.capture('dashboards.tabAccess', {
      dashboard_tab_name: selectedTab?.name,
      dashboard_is_shared: Array.isArray(shared_dashboard_ids) && shared_dashboard_ids.some(orgId => orgId !== suborganization.id)
    });

  }, [selectedTab?.name, eventTracking, shared_dashboard_ids, suborganization.id]);

  useEffect(() => {

    if (
      !match.params.activeTab
      || !enabledDashboards.find(({ slug }) => slug === match.params.activeTab)
    ) {
      if (enabledDashboards.length < 1 || loading) return;
      const tab = (enabledDashboards[0] || {}).slug;
      history.push(`/dashboard/${tab}`);
    }

  }, [enabledDashboards, match.params.activeTab, loading, history])

  useEffect(() => {
    if (
      !organization ||
      !suborganization ||
      !match.params.activeTab ||
      !enabledDashboards.find(({ slug }) => slug === match.params.activeTab)
    ) return;

    dispatch(
      requestDashboard(
        match.params.activeTab,
        organization.slug,
        suborganization.slug,
      )
    );
  }, [
    organization,
    suborganization,
    dispatch,
    match.params.activeTab,
    enabledDashboards
  ]);

  useEffect(() => {
    if (
      !organization ||
      !suborganization
    ) return

    dispatch(
      requestDashboards(
        organization.slug,
        suborganization.slug,
      )
    );
  }, [
    organization,
    suborganization,
    dispatch,
  ]);

  const filteredTemplate = useMemo(() => {
    const templatesThatApply = (template || []).filter(({ applies }) => applies);

    if (!isFilterSet) {
      return templatesThatApply;
    }

    return templatesThatApply
      .filter(({
        sdgs = [],
        esgTypes = [],
      }) => {
        const sdgMatch = (
          !sdgFilterSet ||
          (sdgs || []).find(isSdgFilterSet)
        );
        const typeMatch = (
          !typeFilterSet ||
          (esgTypes || []).find(isTypeFilterSet)
        );

        return (
          (!sdgFilterSet || sdgMatch) &&
          (!typeFilterSet || typeMatch)
        );
      });
  }, [
    template,
    isFilterSet,
    sdgFilterSet,
    typeFilterSet,
    isSdgFilterSet,
    isTypeFilterSet,
  ]);

  const translatedTemplate = useMemo(() => {

    return filteredTemplate.map(template => {

      const { sdgs, esgTypes, standards, applies, units, actions, ...rest } = template;

      return {
        ...rest,
        title: t.dashboard[template.title] || (template.title || '').replace(/[-]/g, ' '),
        // actions: [''], // NOTICE: Workaround for this old version of aplanet-dashboard
        actions: []
      }
    });

  }, [filteredTemplate, t]);

  const requestDashboardValues = useCallback((arrDateFilter) => {

    if (!translatedTemplate || !selectedTab?.slug) return;

    translatedTemplate.forEach(( card ) => {
      dispatch( requestDashboardCardKpiValues(
        organization.slug,
        suborganization.slug,
        selectedTab?.slug,
        card.id,
        arrDateFilter
      ));
    })

  }, [dispatch, translatedTemplate, organization, suborganization, selectedTab?.slug]);
  
  useEffect(() => {
    
    if (
      !loading
      && !loadingDashboardValues
      && !dashboardValues
      && selectedTab?.name
    ) {
      onTabLoaded();
      requestDashboardValues(dateFilterValue);
    }

  }, [
    loading,
    loadingDashboardValues,
    selectedTab?.name,
    onTabLoaded,
    requestDashboardValues,
    dateFilterValue,
    dashboardValues,
  ]);
  
  const hasDashboards = Boolean(enabledDashboards.length);
  const hasNotDashboardGraph = !Boolean(translatedTemplate.length);
  const showAddCardButton = hasDashboards && dashboard_organization_id === suborganization.id;
  const emptyState = hasDashboards && selectedTab 
  ? (
      showAddCardButton 
      ? (
        <Empty {...emptyPropsMap.get("noGraphInDashboardCta")}>
          <ButtonGroup>
            <button className="button--primary" onClick={handleAddNewCard}>
              <AplanetIcon name="Chart vertical bars" />
              <FormattedMessage id="dashboard_new_chart" />
            </button>
          </ButtonGroup>
        </Empty>
      )
      : (
        <Empty {...emptyPropsMap.get("noGraphInDashboard")}></Empty>
      )
  ) : (
    <Empty {...emptyPropsMap.get("noDashboard")}>
      <ButtonGroup>
        <button
          className="button--primary"
          onClick={() => setDashboardModalType("create")}
        >
          <AplanetIcon name="Add" />
          <FormattedMessage id="dashboard_new_dashboard" />
        </button>
      </ButtonGroup>
    </Empty>
  );

  let content = (
    <DashboardComponent
      template={translatedTemplate}
      hasFilter={isFilterSet}
      defaultParameters={{
        nodata: <EmptyCard {...emptyPropsMap.get("noDataOnPeriods")} />,
        locale: intl.locale,
        sameValueMsg: t.dashboard_number_same_value,
        increaseMsg: t.dashboard_number_increased,
        decreaseMsg: t.dashboard_number_decreased,
        increaseByMsg: t.dashboard_number_increased_by,
        decreaseByMsg: t.dashboard_number_decreased_by,
      }}
      renderExtras={({ sdgs = [], esgTypes = [] }) => (
        <div className="Dashboard__card_extras">
          {esgTypes.map((type) => (
            <EsgLogo key={type} type={type} />
          ))}
          {clusterSDGs(sdgs).map((sdg) => (
            <SdgMiniLogo key={sdg.slug} sdg={sdg} />
          ))}
        </div>
      )}
      permissions={permissions}
      handleDeleteCard={handleDeleteCard}
      handleEditCard={handleEditCard}
      gridLayout={grid_layout}
      gridLayoutUpdate={handleDashboardUpdate}
      isSharedDashboard={dashboard_organization_id !== suborganization.id}
      activeTab={match.params.activeTab}
    />
  );

  if (loading) {
    content = <Loading />;
  } else if (hasNotDashboardGraph) {
    content = emptyState;
  }

  const clusterSDGs = useSDGsClustering();

  return (
    <Fragment>
      <MainLayout className="DashboardLayout">
        <Sentry.ErrorBoundary
          fallback={
            <ErrorBoundaryFallback
              titleErrorMessage={intl.formatMessage({ id: 'error_boundary_title_message' })}
              buttonLabel={intl.formatMessage({ id: 'error_boundary_reload_button' })}
              descriptionErrorMessage={intl.formatMessage({ id: 'error_boundary_dashboard_message' })}
              customErrorImage="/images/error_image.png"
            />
          }>
          <div className="DashboardLayout__content">
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '6px', justifyContent: 'space-between' }}>
              <div className="Dashboard__filters">
                <DashboardFilters
                  dateFilter={dateFilterValue}
                  onChangeDateFilter={(data) => {
                    setDateFilterValue(data);
                    requestDashboardValues(data);
                  }}
                  taxonomyFilter={filterState}
                  onChangeTaxonomyFilter={handleFilter}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                {showAddCardButton &&
                  <CustomButton
                    icon={<PlusOutlined />}
                    onClick={handleAddNewCard}
                    tooltip={translatedTemplate.length >= parseInt(config.DASHBOARD_MAX_CARDS_PER_TAB) ? formatMessage('dashboard_card_limit_exceeded_tooltip', { limit: config.DASHBOARD_MAX_CARDS_PER_TAB }) : null}
                    disabled={translatedTemplate.length >= parseInt(config.DASHBOARD_MAX_CARDS_PER_TAB)}
                  >
                    {formatMessage('dashboard_new_chart')}
                  </CustomButton>
                }
                <CustomButton
                  icon={<PlusOutlined />}
                  onClick={() => setDashboardModalType('create')}
                >
                  {formatMessage('dashboard_new_dashboard')}
                </CustomButton>
                {
                  hasDashboards && selectedTab &&
                  <CustomButton
                    icon={<ShareAltOutlined />}
                    onClick={() => setShowShareModal(true)}
                  >
                    {formatMessage('dashboard_share_dashboard')}
                  </CustomButton>
                }
                {dashboard_organization_id === suborganization.id ? (
                  <>
                    <CustomButton
                      icon={<EditOutlined />}
                      onClick={() => setDashboardModalType('update')}
                    >
                      {formatMessage('dashboard_edit_dashboard')}
                    </CustomButton>
                    <CustomButton
                      icon={<DeleteOutlined />}
                      onClick={() => setDashboardModalType('delete')}
                    >
                      {formatMessage('dashboard_delete_dashboard')}
                    </CustomButton>
                  </>
                ) : null}
              </div>
            </div>
            {content}
          </div>
        </Sentry.ErrorBoundary>
      </MainLayout>
      <ModalCUD
        type={dashboardModalType}
        handleNewDashboard={handleNewDashboard}
        handleDashboardUpdate={handleDashboardUpdate}
        handleDashboardDelete={handleDashboardDelete}
        onCancel={hideCUDModal}
        defaultParameters={dashboardModalType === 'update' ? { dashboardName: selectedTab.name } : {}}
      />
      {
        hasDashboards && selectedTab &&
        <ModalShareDashboard
          visible={showShareModal}
          shared_dashboard_ids={shared_dashboard_ids}
          dashboard_organization_id={dashboard_organization_id}
          handleDashboardUpdate={handleDashboardUpdate}
          onCancel={() => setShowShareModal(false)}
        />
      }
    </Fragment>
  )
}

export default injectIntl(
  withRouter(
    Dashboard
  )
);
