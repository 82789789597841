const ALWAYS_FALSE = () => false;

export const nestedObjectFilter = (
  obj,
  fLeaf,
  isLeaf = ALWAYS_FALSE,
  _addr = [],
) => {
  return Object.keys(obj)
    .map(key => [
      key,
      (isLeaf(obj[key]) || typeof obj[key] !== 'object'),
    ])
    .filter(([key, leaf]) => !leaf || fLeaf(obj[key], [..._addr, key]))
    .map(([key, leaf]) => [
      key,
      (
        leaf
        ? obj[key]
        : nestedObjectFilter(
          obj[key],
          fLeaf,
          isLeaf,
          [..._addr, key],
        )
      ),
    ])
    .reduce((acc, [key, val]) => {
      acc[key] = val;
      return acc;
    }, {})
};

export const deepGet = (obj, address = []) => {
  if(address.length === 0) {
    return obj;
  }
  const next = address[0];
  return deepGet(
    obj?.[next] || {},
    address.slice(1)
  );
};

// GO RECURSIVE GO!
export const deepUpdate = (obj, address = [], value) => {
  if(address.length === 0) {
    return value;
  }
  const next = address[0];
  return {
    ...obj,
    [next]: deepUpdate(obj[next] || {}, address.slice(1), value),
  };
};

export const deepGetStar = (obj, address = []) => {
  if(address.length === 0) {
    return obj;
  }
  const next = address[0];

  if(next === '*') {
    return Object.keys(obj || {})
      .map(key => [
        key,
        deepGetStar(
          obj[key],
          address.slice(1)
        )
      ])
      .reduce((obj, [key, val]) => {
        obj[key] = val;
        return obj;
      }, {})
  }

  return deepGetStar(
    (obj || {})[next],
    address.slice(1)
  );
};

