import React from 'react';
import { withRouter } from 'react-router';

import A from 'components/A';
import { truncateString } from 'utils/strings';
import './style.less';

const KpiLabel = ({
  name,
  slug,
  esg_type,
  history,
  urlBase = 'kpi',
  period: maybePeriod,
  status,
  truncateLength = 1000
}) => {
  const period = maybePeriod || 'last';
  return(
    <section className={`KpiLabel KpiLabel__type_${esg_type}`}>
      { status === 'deleted'
        ? <span title={name}>{ name }</span>
        : <A onClick={() => history.push(`/${urlBase}/${slug}/${period}/data`)}>
            <span title={name}>{ truncateString(name,truncateLength) }</span>
          </A>
      }
    </section>
  )
};

export default withRouter(KpiLabel);
