const calculatePoint = (index, intervalSize, colorRangeInfo) => {
  const { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
  return (useEndAsStart
    ? (colorEnd - (index * intervalSize))
    : (colorStart + (index * intervalSize)));
}

/* Must use an interpolated color scale, which has a range of [0, 1] */
export const interpolateColors = (dataLength, colorScale, colorRangeInfo) => {
  const { colorStart, colorEnd } = colorRangeInfo;
  const colorRange = colorEnd - colorStart;
  const intervalSize = colorRange / dataLength;
  let colors = [];

  for (let index = 0; index < dataLength; index++) {
    colors.push(
      colorScale(calculatePoint(index, intervalSize, colorRangeInfo))
    );
  }

  return colors;
}

// Function to convert hexadecimal color to Rgb
export const convertHex2RGB = (hex) => {

  var r,g,b,a="";
  if( hex==="" ) hex="000000";
  if( hex.charAt(0)==="#" ) hex=hex.substring(1,hex.length);

  if( hex.length!==6 && hex.length!==8 && hex.length!==3 ){
    return false;
  }

  if( hex.length===3 ){
    r = hex.substring(0,1);
    g = hex.substring(1,2);
    b = hex.substring(2,3);
    r=r+r;
    g=g+g;
    b=b+b;
  } else {
    r = hex.substring(0,2);
    g = hex.substring(2,4);
    b = hex.substring(4,6);
  }

  if( hex.length===8 ){
    a = hex.substring(6,8);
    a = (parseInt(a, 16)/255.0).toFixed(2);
  }

  r = parseInt(r, 16);
  g = parseInt(g, 16);
  b = parseInt(b, 16);
  
  if( hex.length===8 ) return [r, g, b, a];

  return [r, g, b];
	
}

// Function to get lighter / darker color
// Arguments: 
// c => rgb number array,
// n => number positive / negative. Positive => lighter, Negative => darker
export const lightenDarkenRgbColor = (c, n) => c.map(d=>(d+=n)<0?0:d>255?255:d|0);