import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import { uniq } from 'lodash';

import DataAnswerCard from 'components/DataAnswerCard';
import CollapsableCard from 'components/CollapsableCard';
import { ReactComponent as ExternalRequestIcon } from 'assets/icon-external-request.svg';
import useSetState from 'utils/useSetState'
import useAggregateValues from 'utils/useAggregateValues';
import CustomButton from 'components/CustomButton'
import T from 'components/T';
import {
  formatLong,
} from 'utils/date';
import useOrganizations from 'utils/useOrganizations';

import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import CustomModalType from 'components/CustomModalType';
import { DATA_REQUEST_STATUS } from 'utils/dataRequests';

const getCardId = ({
  email,
  uuid,
  responseDate,
}) => `${email}|${uuid}|${responseDate}`;

const Footer = injectIntl(({
  intl,
  selected,
  schema,
  loading,
  handleUseDataRequests,
  handleUseAggregatedDataRequests,
  setShowModalType,
  value_source,
}) => {
  const t = intl.messages;
  const selectedAttachments = useMemo(() => {
    return (selected || [])
      .map(card => card.attachments || [])
      .reduce((arr, el) => arr.concat(el), []);
  }, [
    selected,
  ]);

  const hasAttachments = selectedAttachments.length > 0;

  const selectedWithValue = useMemo(() => {
    return (selected || []).filter(card => !!card.params?.response?.value)
  }, [
    selected,
  ]);

  const handleUseAggregateValues = useCallback(({ value, comment }, aggregationType) => {
    const dataRequestIds = selectedWithValue.map(({ request_id }) => request_id);
    if (dataRequestIds.length > 1) {
      handleUseAggregatedDataRequests(dataRequestIds, aggregationType, value, comment, null);
    } else {
      handleUseDataRequests(dataRequestIds, true, false, value);
    }
  }, [
    handleUseDataRequests,
    handleUseAggregatedDataRequests,
    selectedWithValue,
  ]);

  const valueHandlers = useAggregateValues(
    handleUseAggregateValues,
    schema,
    selectedWithValue.map(({ name, params }) => ({
      name,
      kpi_value: params?.response?.value,
      comment: params?.response?.comment,
    })),
  );

  const handleUseAttachments = useCallback(() => {
    const dataRequestIds = uniq(selectedAttachments.map(({ request_id }) => request_id));
    if (dataRequestIds.length > 1) {
      handleUseAggregatedDataRequests(dataRequestIds, null, null, null, selectedAttachments);
    } else {
      handleUseDataRequests(dataRequestIds, false, true);
    }
  }, [
    handleUseDataRequests,
    handleUseAggregatedDataRequests,
    selectedAttachments,
  ]);

  const handleMenuClick = useCallback((key) => (e) => {
    const handlers = valueHandlers[key] || [];
    const handler = handlers.find(({ key }) => key === (e || {}).key);
    if(handler && handler.onClick) {
      handler.onClick();
    }
  }, [
    valueHandlers,
  ]);

  const cannotUseData = useCallback((dataInUseKey) => {
    return value_source !== "manual"
      || (selected.some(({request_status, params}) => {
            return request_status === DATA_REQUEST_STATUS.in_use
              || (request_status === DATA_REQUEST_STATUS.partially_in_use && params?.response?.data_in_use && params?.response?.data_in_use[dataInUseKey]);
          }))
  }, [
    selected,
    value_source,
  ]);

  return (
    <React.Fragment>
      {
        !hasAttachments ? null :
        <CustomButton
          disabled={loading}
          onClick={cannotUseData('attachments') ? () => setShowModalType(true) : handleUseAttachments}
        >
          { t.data_answer_card_use_attachment }
        </CustomButton>
      }
      {
        Object.keys(valueHandlers).map(
          key => {
            const handler = valueHandlers[key];

            if(Array.isArray(handler)) {
              return (
                <Dropdown
                  key={key}
                  overlay={
                    <Menu onClick={cannotUseData('value') ? () => setShowModalType(true) : handleMenuClick(key)} >
                      {
                        handler.map(({
                          key,
                          label,
                        }) => (
                          <Menu.Item key={key}>
                            <T
                              convert_to_unit={{ unit: label }}
                            />
                          </Menu.Item>
                        ))
                      }
                    </Menu>
                  }
                >
                  <CustomButton>
                    { t[`data_answer_card_use_data_${key}`] } <DownOutlined />
                  </CustomButton>
                </Dropdown>
              );
            }

            return (
              <CustomButton
                key={key}
                onClick={cannotUseData('value') ? () => setShowModalType(true) : valueHandlers[key]}
                disabled={schema?.type === 'boolean' && selectedWithValue.length > 1}
              >  
                { t[`data_answer_card_use_data_${key}`] }
              </CustomButton>
            )
          }
        )
      }
    </React.Fragment>
  );
});

const ExternalRequests = ({
  intl,
  cards = [],
  schema,
  loading,
  kpi_slug,
  value_source,
  period,
  onOpenAnswer,
  onRemind,
  permissions = {},
  canAggregate,
  onShowDataRequests,
  handleUseDataRequests,
  handleUseAggregatedDataRequests,
}) => {
  const [showModalType, setShowModalType] = useState(false);

  const t = intl.messages;

  const { suborganization } = useOrganizations();

  const { new_atlas_enabled = {} } = suborganization?.product_config?.atlas || {};
  const {
    data_request = false,
  } = new_atlas_enabled;

  // Control suborgs selected
  const {
    set: selected,
    toggle: toggleSelected,
    //replaceAll: setSelected,
  } = useSetState([]);

  const selectedCards = useMemo(() => {
    return cards.filter(card => selected.includes(getCardId({
      email: card.email,
      uuid: card.uuid,
      responseDate: card.params?.response?.date,
    })));
  }, [
    cards,
    selected,
  ]);

  const handleOpenAnswer = useCallback((answerProps) => {
    return () => {
      onOpenAnswer({
        ...answerProps,
        mode: 'external',
      });
    }
  }, [
    onOpenAnswer,
  ]);

  return (
    <>
      <CollapsableCard
        title={ t.external_requests }
        icon={<ExternalRequestIcon />}
        footer={true}
        className='CollapsableCard__grid'
        action={
          !permissions.can_request_kpi || !data_request
          ? null
          : (
            <CustomButton
              onClick={onShowDataRequests}
              disabled={loading}
            >
              <img
                src='/images/assets/icon-request.svg'
                alt=''
                style={{marginRight: 10}}
              />
              { t.data_answer_card_request }
            </CustomButton>
          )
        }
      >
          {cards.map(card => {
            const {
              name,
              slug,
              avatar,
              email,
              params = {},
              status,
              attachments = [],
              uuid,
              reminded = false,
              request_id,
              request_status,
            } = card || {};
            const {
              response = {}
            } = params;

            const id = getCardId({
              email,
              uuid,
              responseDate: response?.date,
            });
            return (
              <DataAnswerCard
                key={id}
                className=""
                selectable={permissions.can_write_kpi && canAggregate}
                name={name}
                avatar={avatar}
                email={email}
                value={response?.value}
                status={status}
                attachments={attachments}
                date={
                  formatLong(
                    (
                      (
                        (request_status === DATA_REQUEST_STATUS.in_use || request_status === DATA_REQUEST_STATUS.partially_in_use)
                        && response?.response_used_at
                      ) || response?.date // NOTICE: defensive programming use date if response_used_at does not exist
                    ), intl
                  )
                }
                selected={selected.includes(id)}
                select={toggleSelected}
                id={id}
                schema={schema}
                loading={loading}
                showRemind={slug !== 'deleted_user'}
                onRemind={() => onRemind(email)}
                reminded={reminded}
                requestStatus={request_status}
                requestParams={params}
                onClick={handleOpenAnswer({
                  name,
                  avatar,
                  email,
                  value: response?.value,
                  attachments,
                  updated_at: response?.date,
                  comment: response?.comment,
                  request_id,
                  request_data_in_use: response?.data_in_use || {},
                  request_status,
                  value_source
                })}
                newDataRequest={data_request}
              />
            );
          })}
          {
            !permissions.can_write_kpi || !canAggregate
            ? null
            : (
              <section className='CollapsableCard__footer'>
                <Footer
                  schema={schema}
                  selected={selectedCards}
                  loading={loading}
                  handleUseDataRequests={handleUseDataRequests}
                  handleUseAggregatedDataRequests={handleUseAggregatedDataRequests}
                  setShowModalType={setShowModalType}
                  value_source={value_source}
                />
              </section>
            )
          }
      </CollapsableCard>
      <CustomModalType
          className="dataTabs__ExternalRequestsModal"
          showModal={showModalType}
          type='error'
          title={intl.formatMessage({id: `admin_data_requests_table_modal_title_forbidden`})}
          icon='Alert'
          iconType='fad'
          onCancel={() => setShowModalType(false)}
          onCancelText={intl.formatMessage({id: 'admin_data_requests_table_modal_close'})}
          content={showModalType ? (
            <div className="dataTabs__ExternalRequestsModal-content">
              <span className="dataTabs__ExternalRequestsModal-content-message">{intl.formatMessage({id: 'external_requests_modal_message'})}</span>
              <span className="dataTabs__ExternalRequestsModal-content-message-bold">{intl.formatMessage({id: 'external_requests_modal_message_bold'})}</span>
            </div>
          ) : null}
      />
    </>
  );
};

export default injectIntl(
  ExternalRequests
);
