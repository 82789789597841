const SINGLE_COLUMN_VALUE = '__single-column-value';
const SUM_SLUG = '__sum';
const PRODUCT_SLUG = '__product';
const PERCENTAGE_SLUG = '__percentage';
const META_SLUG = '__meta';
const FULL_VALUE_SLUG = '__full_value';
const HETEROGENEOUS_META_TYPE = 'quantitative';
const HETEROGENEOUS_META_SCHEMA = {
  type: HETEROGENEOUS_META_TYPE,
  metricSlug: 'number',
  allowNegative: true,
  decimalPoints: 2,
  aggregationTime: 'none',
  allowedUnitSlugs: ['number'],
};

const translateSlug = (labels = {}) => (_slug) => {
  const slug = (_slug || '').replace('+', '');
  return (labels || {})[slug] || (labels || {})[`${slug}+`] || slug;
};

export {
  SINGLE_COLUMN_VALUE,
  SUM_SLUG,
  PRODUCT_SLUG,
  PERCENTAGE_SLUG,
  META_SLUG,
  FULL_VALUE_SLUG,
  HETEROGENEOUS_META_TYPE,
  HETEROGENEOUS_META_SCHEMA,
  translateSlug,
};