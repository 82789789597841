import React, { useState, useContext, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Form, Radio, Select } from 'antd';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import {formatMessage} from '../../../../intl'
import { DashboardCardFormContext } from '../..';
import CardFormStepWrapper from '../CardFormStepWrapper';
import { FORM_KEY_GRAPH_ADDRESS, FORM_KEY_SELECTED_UNIT } from 'containers/DashboardCardForm/constants';
import '../style.less'

const { Option } = Select;

const isTableDataSelectable = (selectedKpi) => {

  if (selectedKpi.schema.type !== 'table') return false;

  const {dimensionNames, dimensionValues} = selectedKpi.schemaLabels;
  const {componentLabels = {}} = selectedKpi.schemaLabels?.innerSchema;

  return [
    ...Object.keys(dimensionNames || []).map((dimension) => Object.keys(dimensionValues[dimension]).length > 1), 
    Object.values(componentLabels).length > 1
  ].some(e => e)
}

const isUnitDataSelectable = (kpi) => {

  const hasMetricSelection = (kpi.schema.type === 'quantitative' && kpi.schema.metricSlug) 
                          || (kpi.schema.type === 'table' && kpi.schema.innerSchema.type === 'quantitative' && kpi.schema.innerSchema.metricSlug) 
                          || (kpi.schema.type === 'table' && kpi.schema.innerSchema.type === 'tuple' 
                                  && kpi.schema.innerSchema.components.some( component => component.type === 'quantitative' && component.metricSlug ));

  
  return hasMetricSelection;

}

const getMetricData = (kpi, componentName = null) => {

  let finalSchema = null;

  switch(kpi.schema.type){
    case 'quantitative':
      finalSchema = kpi.schema;
      break;
    case 'table':
      if (kpi.schema.innerSchema.type === 'quantitative')
        finalSchema = kpi.schema.innerSchema;
      else if (kpi.schema.innerSchema.type === 'tuple'){
        finalSchema = kpi.schema.innerSchema.components.find( component => component.type === 'quantitative' && component.name === componentName);
      }
      break;
    default:
      break;
  }

  return finalSchema ? _.pick(finalSchema, ['metricSlug', 'allowedUnitSlugs']) : null;
}

const convertGraphAddressToForm = (kpi, graphAddress) => {

    const schemaLabels = kpi?.schemaLabels;
    const componentLabels = schemaLabels?.innerSchema?.componentLabels || {};
    const firstQuantitativeComponent = kpi?.schema?.innerSchema?.components?.find( component => component.type === 'quantitative');

    const dimensions = Object.keys(schemaLabels?.dimensionNames || [])
        .map((dimension, i) => { 
            return graphAddress[i];
        });

    return {
        dimensions: dimensions,
        columns: componentLabels && Object.values(componentLabels).length > 1 
                    ? [graphAddress[dimensions.length] || (firstQuantitativeComponent ? firstQuantitativeComponent.name : Object.keys(componentLabels)[0])] 
                    : []
    }

}

const SelectTableData = ({
  intl
}) => {

  const { values, handleChange } = useContext(DashboardCardFormContext);
  const { metrics } = useSelector(state => state.taxonomies);

  const arrMetrics = useMemo(() => Array.isArray(metrics) && metrics.length > 0 ? metrics[0].metrics : [], [metrics]);
  const kpi = values.selectedRows[0];
  const componentLabels = kpi?.schemaLabels?.innerSchema?.componentLabels || {}
  
  const theValue = convertGraphAddressToForm(kpi, values.graphAddress);

  const [homogeneousData, setHomogeneousData] = useState(theValue.dimensions);
  const [heterogeneousData, setHeterogeneousData] = useState(theValue.columns);

  const updateHomogeneousData = ( value ) => {
    
    setHomogeneousData(value);
    handleChange(FORM_KEY_GRAPH_ADDRESS)( [...value, ...heterogeneousData] );

  }

  const updateHeterogeneousData = ( value ) => {

    setHeterogeneousData(value);
    handleChange(FORM_KEY_GRAPH_ADDRESS)( [...homogeneousData, ...value] );
    handleChange(FORM_KEY_SELECTED_UNIT)( '' );

  }

  

  const kpiMetric = useMemo(() => {
    
    if (!isUnitDataSelectable(kpi)) return null;

    const metricData = getMetricData(kpi, heterogeneousData ? heterogeneousData[0] : null);
    
    if (!metricData) return null;

    const metric = arrMetrics.find( metric => metric.slug === metricData.metricSlug );

    return {
      ...metric,
      // units: metric.units.filter( unit => metricData.allowedUnitSlugs.length === 0 || metricData.allowedUnitSlugs.includes(unit.slug) )
    }

  }, [arrMetrics, kpi, heterogeneousData]);

  const updateSelectedUnit = useCallback(( selectedUnit ) => {
    handleChange(FORM_KEY_SELECTED_UNIT)( selectedUnit );
  }, [handleChange]);

  return (
    <CardFormStepWrapper>
    {
      isTableDataSelectable(kpi) || (isUnitDataSelectable(kpi) && kpiMetric.units.length > 1) ? (
        <Form layout='vertical'>
          {
            isTableDataSelectable(kpi) && 
            <>
              {
                Object.keys(kpi?.schemaLabels?.dimensionNames || []).map((dimension, index) =>
                  Object.keys(kpi?.schemaLabels?.dimensionValues[dimension]).length > 1 && (
                    <Form.Item 
                        key={kpi?.schemaLabels?.dimensionNames[dimension]} 
                        label={kpi?.schemaLabels?.dimensionNames[dimension]}
                    >
                      <Radio.Group 
                        value={homogeneousData[index]} 
                        onChange={e => updateHomogeneousData(Object.assign([], homogeneousData, {[index]: e.target.value}))}
                        options={[
                            {
                              value: '*', 
                              label: formatMessage('select_table_data_label_all')
                            },
                            ...Object.keys(kpi?.schemaLabels?.dimensionValues[dimension] || []).map(value => (
                              {
                                value, 
                                label: kpi?.schemaLabels?.dimensionValues[dimension][value]
                              }
                            ))
                        ]}
                      >
                      </Radio.Group>
                    </Form.Item>
                  )
                )
              }
              {
                componentLabels 
                && Object.values(componentLabels).length > 1 
                && (
                  <Form.Item label={intl.formatMessage({ id: 'select_table_data_column' })}>
                    <Radio.Group 
                      value={heterogeneousData[0]} 
                      onChange={e => updateHeterogeneousData(Object.assign([], heterogeneousData, {0: e.target.value}))}
                      options={[
                        ...Object.keys(componentLabels || []).map((value, inx) => (
                          {
                            value, 
                            label: componentLabels[value],
                            disabled: kpi?.schema?.innerSchema?.components[inx].type !== 'quantitative'
                          }
                        ))
                      ]}
                    >
                    </Radio.Group>
                  </Form.Item>
                )
              }
            </>
          }
          {
            isUnitDataSelectable(kpi) && kpiMetric && 
            <>
              <Form.Item label={ `${ intl.formatMessage({ id: 'dashboard_card_form_unit_label' }) } (${ kpiMetric.name })` }>
                <Select 
                  showSearch 
                  defaultValue={ values.selectedUnit || ''} 
                  value={ values.selectedUnit} 
                  onChange={ value => updateSelectedUnit( value ) }>
                  <Option value={''}>{ intl.formatMessage({ id: 'dashboard_card_form_unit_default_option' }) }</Option>
                  { kpiMetric.units.map( option => <Option key={ option.slug } value={ option.slug }>{ option.name } ({ option.symbol })</Option> ) }
                </Select>
              </Form.Item>
            </>
          }
        </Form>
      )
      : formatMessage('select_table_data_no_need')
    }
    </CardFormStepWrapper>
    
  )
};

export default injectIntl(SelectTableData);
