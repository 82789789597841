import React, { useCallback, useMemo } from "react";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";

import SdgMiniLogo from "components/SdgMiniLogo";

import { Select, Tree } from "antd";
import "./style.less";

const SdgTitleRender = injectIntl(({
  intl,
  sdg,
  parent_slug,
}) => (
  <div className="SDGsSelector-sdg-title"><SdgMiniLogo sdg={{ ...sdg, parent_slug }} />{ intl.formatMessage({ id: `sdg_${sdg.slug}` }) }</div>
));

const Sdgs = ({ intl, value, onChange, error, showTitle = true }) => {

  const { sdgs = [] } = useSelector((state) => state.taxonomies);

  const sdgsTreeData = useMemo(() => sdgs.map(sdg =>
    ({title: <SdgTitleRender sdg={sdg} parent_slug={sdg.slug} />, 
      key: sdg.slug, 
      targets: sdg.targets,
      children: sdg.targets.map(t => 
        ({title: <SdgTitleRender sdg={t} parent_slug={sdg.slug} />, 
          key: t.slug,
        })
      )
    })
  ),
    [sdgs]
  )

  const checkedParents = useMemo(() => sdgsTreeData.filter(({children}) => children.every(sdg => (value.checked || value).includes(sdg.key))).map(({key}) => key), [sdgsTreeData, value])
  const halfCheckedParents = useMemo(() => sdgsTreeData.filter(({children}) => children.some(sdg => (value.checked || value).includes(sdg.key) || (value.halfChecked || []).includes(sdg.key))).map(({key}) => key), [sdgsTreeData, value])

  const toggleAll = useCallback((checked, slug, targets, value) => {
    if (targets.length === 0) onChange(slug);

    targets
      .filter((t) => checked ? !value.includes(t.slug) : value.includes(t.slug))
      .forEach((t) => onChange(t.slug, checked));
  }, [onChange]);

  const areAllSelected = useCallback((sdg, value) =>
    sdg.targets.every((t) => value.includes(t.slug))
  , []);

  const onCheck = (_checkedKeys, {checked, node}) => {
    if(node.children) {
      toggleAll(checked, node.key, node.targets, (value.checked || value));
    }
    else onChange(node.key, checked)
  };

  
  return (
    <div className="SDGsSelector" id="SDGsSelector">
      {
        showTitle
          ? (
            <>
              <div className="SDGsSelector-title">
                {intl.formatMessage({ id: "createcustomkpi_goals" })}
              </div>
              <div className="SDGsSelector-title-optional">
                {intl.formatMessage({ id: "createcustomkpi_goals_subtitle" })}
              </div>
            </>
          )
          : null
      }
      {!error ? null : <div className="SDGsSelector__error">{error}</div>}
      <div className="SDGsSelector-select">
        <Select
          getPopupContainer={() => document.getElementById('SDGsSelector')}
          placeholder={sdgs.map(sdg => {
            if(areAllSelected(sdg, (value.checked || value))) return <SdgMiniLogo sdg={{ ...sdg, parent_slug: sdg.slug }} />
            else if(areAllSelected(sdg, (value.halfChecked || []))) return <SdgMiniLogo sdg={{ ...sdg, parent_slug: sdg.slug }} unColored/>
            else {
              return sdg.targets.map((target) => {
                if((value.checked || value).includes(target.slug)) return <SdgMiniLogo sdg={{ ...target, parent_slug: sdg.slug }}/>
                else if((value.halfChecked || []).includes(target.slug)) return <SdgMiniLogo sdg={{ ...target, parent_slug: sdg.slug }} unColored/>
                return ""
              })
            }
          })}
          dropdownRender={() => (
            <div className="SDGsSelector-dropdown-content">
              <Tree
                checkable
                checkStrictly
                selectable={false}
                checkedKeys={{checked: [...(value.checked || value), ...checkedParents], halfChecked: [...(value.halfChecked || []), ...halfCheckedParents]}}
                onCheck={onCheck}
                treeData={sdgsTreeData}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default injectIntl(Sdgs);
