import React, {
  useState,
} from 'react'
import { Button } from 'antd'
import { injectIntl } from 'react-intl'
import ReactMarkdown from 'react-markdown';

import './style.less'

const MAX_DESCRIPTION_CHARACTERS = 200

const Summary = ({
  summary,
}) => {
  return (
    <section className='Description'>
    <React.Fragment>
      <ReactMarkdown
        className="Description-description"
        source={summary}
      />
    </React.Fragment>
  </section>
  );
};

const Description = ({
  intl,
  description = '',
  className = '',
  expanded = false,
}) => {

  const t = intl.messages
  const [forceShowDescription, setForceShowDescription] = useState(expanded)

  return (
    <section className={`Description Description-${className}`}>
      { ((description.length < MAX_DESCRIPTION_CHARACTERS) || forceShowDescription)
        ? <React.Fragment>
            <ReactMarkdown
              className="Description-description"
              source={description}
            />
            { (description.length < MAX_DESCRIPTION_CHARACTERS) || expanded ? null :
              <section className='Description-showLess'>
                <Button onClick={() => setForceShowDescription(false)} className='Description-showLessButton'>
                  <span>{t.showLess}</span>
                </Button>
              </section>
            }
          </React.Fragment>
        : <React.Fragment>
            <ReactMarkdown
              className="Description-description-showMore"
              source={description}
            />
            <section className='Description-showMore'>
              <figure className='Description-shadow'></figure>
              <Button onClick={() => setForceShowDescription(true)} className='Description-showMoreButton'>
                <span>{t.showMore}</span>
              </Button>
            </section>
          </React.Fragment>
      }
    </section>
  );
};

Description.Summary = Summary;

export default injectIntl(Description)
