import React from 'react'
import CardFormSummary from '../CardFormSummary'

const CardFormStepWrapper = ({ children, className = '' }) => {

  return (
    <>
      <CardFormSummary />
      <div className={ `DashboardCardForm__step-wrapper ${ className }` }>
        {children}
      </div>
    </>
  )

}

export default CardFormStepWrapper