import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Collapse,
  Tooltip,
  Empty,
} from 'antd';

import {
  PlusOutlined,
  MinusOutlined,
  CalendarOutlined,
  CloseOutlined,
} from '@ant-design/icons';

import './style.less';
import ActionProgressHeader from './ActionProgressHeader';
import ActionProgress from './ActionProgress';
import { Loading } from 'tsComponents/emptyStates/Loading';
import EqualityPlanHeader from 'components/EqualityPlan/Header';
import CancelActionForm from './CancelActionForm';
import ActionsCalendar from 'components/EqualityPlanCreation/ActionsCalendar';
import CustomButton from 'components/CustomButton';


const EqualityPlanObjectiveTracking = ({
  intl,
  history,
  location,
  stage = 'tracking',
  plan,
  actions,
  objectives,
  fetchObjectives,
  fetchingObjectives,
  fetchActions,
  fetchingActions,
  onUpdateActionIndicator,
  updateAction,
  updatePlanProgress,
}) => {
  const t = intl.messages;
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [actionToCancel, setActionToCancel] = useState({});
  const [showActionsCalendar, setShowActionsCalendar] = useState(false);

  useEffect(
    () => {
      fetchActions(stage === 'tracking' ? 'general' : 'followup');
      if (stage === 'tracking') {
        fetchObjectives();
      }
    },
    [plan.id, fetchActions, fetchObjectives, stage]
  );

  const defaultCollapseActiveKey = useMemo(
    () => {
      if (location.state && location.state.actionID) {
        return [location.state.actionID];
      }
      return [];
    },
    [location.state]
  );

  const openCancelActionModal = useCallback(
    (action) => {
      setActionToCancel(action);
      setShowCancelForm(true);
    },
    [setActionToCancel, setShowCancelForm]
  );

  const onCancelAction = useCallback(
    (cancel_reason) => {
      updateAction(actionToCancel.id, { cancel_reason });
      setShowCancelForm(false);
      setActionToCancel({});
    },
    [actionToCancel, updateAction, setActionToCancel, setShowCancelForm]
  );

  const handleOnClickReviewed = useCallback(
    () => {
      updatePlanProgress(stage);
      history.push(`/equality-plan/${plan.id}/`);
    },
    [history, updatePlanProgress, plan, stage]
  );

  if (fetchingActions || fetchingObjectives) {
    return <Loading />;
  }

  return (
    <>
      <Row type="flex" align="middle" justify="space-between">
        <Col>
          <EqualityPlanHeader
            planId={plan.id}
            title={stage === 'tracking' ? t.follow_up_actions : t.follow_up_actions_followup}
            isDocumentManager={false}
          />
        </Col>
        { stage === 'tracking_followup' && actions.length > 0 && (
          <Col className="objective-tracking-calendar">
            { showActionsCalendar
              ? <Tooltip
                  placement="left"
                  title={t.equality_followup_objective_tracking_close_calendar}
                >
                  <CloseOutlined
                    onClick={() => setShowActionsCalendar(false)}
                  />
                </Tooltip>
              : <Tooltip placement="left" title={t.calendar}>
                  <CalendarOutlined
                    onClick={() => setShowActionsCalendar(true)}
                  />
                </Tooltip>
            }
          </Col>
        )}
      </Row>
      <Row type="flex" justify="end" className="objectives-reviewed-button">
        <CustomButton
          type="primary"
          onClick={handleOnClickReviewed}
        >
          {t.equality_plan_reviewed}
        </CustomButton>
      </Row>
      { showActionsCalendar
        ? <Row>
            <Col span={24}>
              <ActionsCalendar actions={actions} />
            </Col>
          </Row>
        : <>
            <Row>
              <Col span={24}>
                { actions.length > 0
                  ? <Collapse
                      defaultActiveKey={defaultCollapseActiveKey}
                      expandIcon={
                        ({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)
                      }
                    >
                      {actions.map((action, index) => {
                        return <Collapse.Panel
                          key={action.id}
                          header={
                            <ActionProgressHeader
                              action={action}
                              sequence={index + 1}
                              onDelete={
                                action.cancel_reason
                                ? null
                                : () => openCancelActionModal(action)
                              }
                              updateAction={updateAction}
                            />
                          }
                        >
                          <ActionProgress
                            sequence={index + 1}
                            action={action}
                            objectives={objectives}
                            showObjectives={stage === 'tracking'}
                            updateActionIndicator={onUpdateActionIndicator} />
                        </Collapse.Panel>
                      })}
                    </Collapse>
                  : <Empty
                      image={'/images/icon-empty-data.svg'}
                      description={t.no_action_found}
                    />
                }
              </Col>
            </Row>
            <CancelActionForm
              visible={showCancelForm}
              setVisibility={setShowCancelForm}
              cancelAction={onCancelAction}
            />
          </>
      }
    </>
  )
};

export default withRouter(injectIntl(EqualityPlanObjectiveTracking));
