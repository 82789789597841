import React, {
  useMemo,
} from 'react';

import { injectIntl } from 'react-intl';

import './style.less';

import {
  formatDateOnly,
} from 'utils/date';

import useOrganizations from 'utils/useOrganizations';

const PeriodLabel = ({
  intl,
  type = "tag",
  className,
  forceLabel = false,
  forceExpand = false,
  period,
}) => {
  const {
    organization,
  } = useOrganizations();

  const expandLabel = useMemo(() => (
    forceExpand || (
      !forceLabel &&
      organization?.config?.expand_period_label
    )
  ) || false,
  [
    organization,
    forceExpand,
    forceLabel,
  ]);

  const label = useMemo(() => expandLabel
    ? `${formatDateOnly(period.start_date, intl.messages.datadetail_period_date_format, intl)} - ${formatDateOnly(period.end_date, intl.messages.datadetail_period_date_format, intl)}`
    : period.label
    , [
      period,
      intl,
      expandLabel,
    ]
  );

  if (type === 'text') {
    return <span>{ label }</span>;
  }

  return(
    <section className={`${className || ''} PeriodLabel ${expandLabel ? 'PeriodLabel__expanded' : ''} PeriodLabel__type_environment`}>
      <span>{ label }</span>
    </section>
  )
};

export default injectIntl(PeriodLabel);
