import React, { useContext } from 'react';
import Avatar from 'components/Avatar';

import ReportingStandardTag from 'components/ReportingStandardTag';
import AplanetIcon from 'components/AplanetIcon';
import { TableDataRequestsCellsContext } from './context';

const DataRequestsInfoModalHeader = () => {
  const {
    kpi_name,
    kpiStandardInfo,
    suborganizationName,
    suborganizationLogo,
  } = useContext(TableDataRequestsCellsContext);

  return (
    <div className="DataRequests__info-modal-wrapper">
      <div className="DataRequests__info-modal-kpi">
        <AplanetIcon name="KPI" size="16px" faStyle="fas" />
        {kpiStandardInfo.map(({standard, code}) => (
          <React.Fragment
            key={`${standard}_${code}`}
          >
            <ReportingStandardTag
              className="DataRequests__kpi-standard-info"
              standard={standard}
              name={code}
              code={code}
            />
          </React.Fragment>
        ))}
        <span title={kpi_name}>{kpi_name}</span>
      </div>
      <div className="DataRequests__info-modal-org-wrapper">
        <Avatar
          src={suborganizationLogo}
          name={suborganizationName}
        />
        <span className="DataRequests__info-modal-org-name">
          {suborganizationName}
        </span>
      </div>
    </div>
  );
}

export default DataRequestsInfoModalHeader;
