import React, {
  useMemo,
  useCallback
} from 'react';
import { injectIntl } from 'react-intl';
import { Tag } from 'antd'
import EsgLogo from 'components/EsgLogo'
import { ESG_TYPES } from 'utils/kpi';
import './style.less';

const { CheckableTag } = Tag;

const EsgSelector = ({
  intl,
  value = [],
  onChange,
  error,
  showTitle = true,
  required = false
}) => {

  const esgTypes = useMemo(() => {
    return ESG_TYPES.map(slug => ({
      slug,
      name: intl.formatMessage({ id: `esg_type_${slug}` }),
    }));
  }, [
    intl,
  ]);

  const isSelected = useCallback((slug) => value.length && value.every(esg => esg === slug), [value])
  const isPartial = useCallback((slug) => value.some(esg => esg === slug), [value])

  return (
    <div className='EsgSelector'>
      {showTitle
        ? (
          <div className='EsgSelector__title'>
            {  required
              ? (
                <div className='EsgSelector__title__required'>
                  *
                </div>
              )
              : ""
            }
            {intl.formatMessage({ id: "createcustomkpi_esg_type" })}
          </div>
        )
        : ""
      }
      <div className='EsgSelector__tags'>
        {
        esgTypes.map(({ name, slug }) => (
          <CheckableTag
            className={`EsgSelector__tags__tag EsgSelector__tags__${slug}
              ${isSelected(slug) ? 'selected' : ''}
              ${isPartial(slug) ? 'partial' : ''}
            `}
            key={slug}
            checked={value.length && value.every(esg => esg === slug)}
            onChange={checked => onChange(checked ? [slug] : [])}
          >
            <div>
              <EsgLogo type={slug} size="14px" faStyle={isSelected(slug) || isPartial(slug) ? "fad" : "fal"} />
              {name}
            </div>
          </CheckableTag>
        ))
      }
      </div>
      <p className='EsgSelector__error-message'>{error}</p>
    </div>
  )
}

export default injectIntl(EsgSelector);
