import React, {
    useMemo, useCallback
} from 'react';
import {injectIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';

import OrganizationTreeSelector from 'components/OrganizationTreeSelector';
import InfoTooltip from 'components/InfoTooltip';
import KpiTreeSelector from './KpiTreeSelector';
import { useFeatureList } from 'components/FeatureSwitch';

import useOrganizations from 'utils/useOrganizations';
import {
    findTree,
    mapOverTreeFromLeafs,
} from 'utils/tree';
import { getUnionExistingPeriodicities } from 'utils/dataRequests';

import './style.less';

const FORM_KEYS = {
    selected_kpis: 'selected_kpis',
    selected_nodes: 'selected_nodes',
    periods: 'periods',
    periodicities: 'periodicities',
};

const LIMIT_SELECTION_BY_TYPE = {
    kpis: 50,
    nodes: 5,
};

const ADMIN_MEMBER_ROLE = 'admin';
const ACTIVE_MEMBER_STATUS = 'active';

const Step1 = ({
    intl,
    handleChange,
    values,
    setActiveAdmins,
}) => {

    const {
        suborganization,
        organization,
        organizationTree,
    } = useOrganizations();

    const enabledReports = useMemo(() => [
        ...organization?.config?.enabled_reports || [],
        'custom',
    ], [ organization ]);

    const {
        data: reporting_structure,
    } = useSelector(state => state.reporting_structure);

    const {
      kpi_data_ownership = [],
  } = useSelector(state => (state.profile?.data || {}));

    const {
      features: featureList,
    } = useFeatureList();

    const isRestrictedAdmin = useMemo(() => (
      featureList.has('restricted_admins') && !suborganization?.permissions?.can_affect_all_kpis
    ), [
      featureList,
      suborganization,
    ]);

    const currentOrganizationTree = useMemo(
        () => {
          const tree = findTree(
            [organizationTree], (node) => node.slug === suborganization?.slug
          );

          if (!isRestrictedAdmin) {
            return tree;
          }

          let organizationIds = [];
          const kpi_slugs = (values?.selected_kpis || []).map(({slug}) => slug);

          Object.entries(groupBy(kpi_data_ownership.filter(({kpi_slug}) => kpi_slugs.includes(kpi_slug)), 'organization_id'))
            .forEach(([organization_id, assignments]) => {
              if (assignments.length === kpi_slugs.length) {
                organizationIds.push(parseInt(organization_id))
              }
            });

          return mapOverTreeFromLeafs([tree])((organization, children = []) => {
            return {
              ...organization,
              disabledSelection: !organizationIds.includes(organization.id),
              children,
            };
          })[0] || {};
        },
        [
          organizationTree,
          suborganization.slug,
          isRestrictedAdmin,
          kpi_data_ownership,
          values?.selected_kpis,
        ]
    );

    const filterNotExistPeriodicities = useCallback((kpis, organizationNodes) => {
            const existingPeriodicities = getUnionExistingPeriodicities(kpis, organizationNodes);
            const filteredPeriods = values.periods.filter(({type}) => existingPeriodicities.includes(type));
            handleChange(FORM_KEYS.periodicities)([...new Set(filteredPeriods.map(period => period.type))]);
            handleChange(FORM_KEYS.periods)(filteredPeriods);
        }, [
            handleChange,
            values.periods
        ]
    );

    const handleSelectKpis = useCallback((checkedKpis) => {
            handleChange(FORM_KEYS.selected_kpis)(checkedKpis);
            filterNotExistPeriodicities(checkedKpis, values.selected_nodes);
        }, [
            filterNotExistPeriodicities,
            handleChange,
            values.selected_nodes
        ]
    );

    const handleSelectOrganizations = useCallback(({checked}) => {
            const selectedNodes = checked.map(checkedNode => {
                return findTree([organizationTree], (node) => checkedNode === node.slug);
            })
            const usersAlreadySelected = values?.users.map(u => u.slug).filter(Boolean);
            const activeAdmins = selectedNodes.reduce((accUsers, node) => {
                const users = (node?.users || []).filter(({member_role, member_status, member_slug}) => (
                    member_role === ADMIN_MEMBER_ROLE
                    && ACTIVE_MEMBER_STATUS === member_status
                    && !usersAlreadySelected.includes(member_slug)
                    && accUsers.every(u => u.member_slug !== member_slug)
                ));
                return [
                    ...accUsers,
                    ...users
                ]
            }, []);
            setActiveAdmins(activeAdmins);
            handleChange(FORM_KEYS.selected_nodes)(checked);
            filterNotExistPeriodicities(values?.selected_kpis, checked);
        }, [
            values?.users,
            values?.selected_kpis,
            setActiveAdmins,
            handleChange,
            filterNotExistPeriodicities,
            organizationTree
        ]
    );

    const totalSelectedColorAlert = useCallback((type) => {
        const selectedKpiSlugs = (values?.selected_kpis || []).map(({slug}) => slug);
            const totalSelected = type === 'kpis' ? selectedKpiSlugs.length : values?.selected_nodes?.length;
            if (type === 'kpis' ? (35 <= totalSelected && totalSelected <= 49) : (3 <= totalSelected && totalSelected <= 4)) {
                return 'is-alert';
            }
            if (totalSelected >= LIMIT_SELECTION_BY_TYPE[type]) {
                return 'is-wrong';
            }
            return '';
        }, [
            values?.selected_kpis,
            values?.selected_nodes?.length
        ]
    );

    return (
        <div className="Step1">
            <div className="Step1__forms">
                <div className="Step1__kpisForm">
                    <span className="Step1__title">{intl.formatMessage({id: 'step_1_indicators_selector'})}</span>
                    <div className="Step1__selectionWrapper">
                        <span className={`Step1__selection ${totalSelectedColorAlert('kpis')}`}>
                            {intl.formatMessage(
                                {id: 'step_1_indicators_selected'},
                                {value: values?.selected_kpis?.length || 0}
                            )}
                        </span>
                        <InfoTooltip
                            type='warning'
                            content={
                                <span className="Step1__infoTooltipContent">
                                    {intl.formatMessage(
                                        {id: `step_1_warning_tooltip_limit_kpis`},
                                        {boldValue: <b>{intl.formatMessage({id: `step_1_warning_tooltip_limit_kpis_bold`})}</b>}
                                    )}
                                </span>
                            }
                        />
                    </div>
                    <KpiTreeSelector
                        reportingStructure={reporting_structure || []}
                        onSelectKpis={handleSelectKpis}
                        selectedKpis={values?.selected_kpis}
                        enabledReports={enabledReports}
                    />
                </div>
                <div className="Step1__nodesForm">
                    <span className="Step1__title">{intl.formatMessage({id: 'step_1_node_selector'})}</span>
                    <div className="Step1__selectionWrapper">
                        <span className={`Step1__selection ${totalSelectedColorAlert('nodes')}`}>
                            {intl.formatMessage(
                                {id: 'step_1_nodes_selected'},
                                {value: values?.selected_nodes?.length || 0}
                            )}
                        </span>
                        <InfoTooltip
                            type='warning'
                            content={
                                <span className="Step1__warningTooltipContent">
                                    {intl.formatMessage(
                                        {id: `step_1_warning_tooltip_limit_nodes`},
                                        {boldValue: <b>{intl.formatMessage({id: `step_1_warning_tooltip_limit_nodes_bold`})}</b>}
                                    )}
                                </span>
                            }
                        />
                    </div>
                    <OrganizationTreeSelector
                        tree={currentOrganizationTree}
                        currentSelection={values?.selected_nodes}
                        onSelect={handleSelectOrganizations}
                        autoFocus={false}
                        isMultipleOrgSelection
                        showEmptySearchIcon
                        hasLimitedSelection
                    />
                </div>
            </div>
        </div>
    );
};

Step1.propTypes = {
    values: PropTypes.object,
    handleChange: PropTypes.func,
    setActiveAdmins: PropTypes.func.isRequired,
};

export default injectIntl(Step1);