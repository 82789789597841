import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'containers/Loading';
import useOrganizations from 'utils/useOrganizations';
import { requestDashboardCard } from 'actions/api';
import DashboardCardForm from '.';

const initialState = {
    id: null,
    //step1
    selectedRows: [],
    selectedRowKeys: [],
    //step2
    selectedUnit: '',
    graphAddress: [],
    graphAddressFinalValue: '',
    //step3
    periodsNumber: 1,
    periodicity: null,
    //step4
    selectedNodeLevel: 1,
    //step5
    cardIcon: 'chart-line-up',
    cardName: '',
    cardDescription: '',
    selectedGraph: '',
    showDataLabels: false,
    showDataTarget: false,
}

const convertDbData2Form = (data) => {
    
    const graphAddress = Array.isArray(data.params.value_kpis) && data.params.value_kpis.length > 0 
                        && Array.isArray(data.params.value_kpis[0].address) ? data.params.value_kpis[0].address : initialState.graphAddress;

    return {
        ...initialState,
        id: data.id,
        //step1
        selectedRows: [],
        selectedRowKeys: data.params.value_kpis.map( kpi => ({
            slug: kpi.slug,
            uuid: kpi?.category || ''
        })),
        //step2
        selectedUnit: data.params.value_unit || initialState.selectedUnit,
        graphAddress: graphAddress,
        graphAddressFinalValue: Array.isArray(graphAddress) && graphAddress.length ? graphAddress.pop() : initialState.graphAddressFinalValue,
        //step3
        periodsNumber: data.params.value_size,
        periodicity: data.params.value_periodicity,
        //step4
        selectedNodeLevel: data.params.value_node_level || initialState.selectedNodeLevel,
        //step5
        cardIcon: data.params.chart_icon || initialState.cardIcon,
        cardName: data.name,
        cardDescription: '',
        selectedGraph: data.params.chart_type,
        showDataLabels: !!data.params.chart_data_labels,
        showDataTarget: !!data.params.chart_data_target,
    }

}

const DashboardCardFormWrapper = ({
    match
  }) => {

    const activeTab = match.params.activeTab;
    const activeCard = match.params.activeCard;
    const dispatch = useDispatch();
    const { card } = useSelector(state => state.dashboard);
    const { 
        organization, 
        suborganization 
    } = useOrganizations();

    const [data, setData] = useState(undefined);

    useEffect(() => {
      
        if (activeCard){
            dispatch(
                requestDashboardCard(
                  organization.slug,
                  suborganization.slug,
                  activeTab,
                  activeCard
                )
            );
        } else {
            setData(initialState);
        }

    }, [activeCard, activeTab, dispatch, organization.slug, suborganization.slug])

    useEffect(() => {
      
        if (activeCard && card && card.data){ 
            setData(convertDbData2Form(card.data));
        }

    }, [activeCard, card])
    
    if (!data) {
        return <Loading.Block/>;
    }

    return (
        <DashboardCardForm data={ data } />
    )
}

export default withRouter(
    DashboardCardFormWrapper
);  