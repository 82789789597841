import React from 'react';
import './style.less';
import { ReactComponent as MiniAplanetLogo } from './images/miniLogo.svg';

const OrganizationLogo = ({
  name,
  logo,
  logo_small,
  className = '',
  showPoweredBy = false,
  showSmallLogo=false,
}) => {
  return (
    <div className={`OrganizationLogo ${className}`}>
      <img
        className={showSmallLogo ? "OrganizationLogo__logo-small" : "OrganizationLogo__logo-full"}
        src={showSmallLogo ? logo_small : logo} alt={name}
      />
      { !showPoweredBy ? null :
        <section className={showSmallLogo ? "OrganizationLogo__poweredBy-small" : "OrganizationLogo__poweredBy-full"}>
          <span>{'Powered by'}</span>
          <MiniAplanetLogo/>
        </section>
      }
    </div>
  );
}

export default OrganizationLogo;
