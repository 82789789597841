import React from 'react';
import { Button } from 'antd';

const Popup = ({content, onNavigation}) => {
    const link = '/';
    return (
    <div>
        <div>Default notification Body</div>
        <div>{JSON.stringify(content)}</div>
        <div>You can navigate to another page with <Button type='link' onClick={() => onNavigation(link)} style={{"padding":"0"}}>onNavigation</Button> link</div>
    </div>)
}

const DefaultType = ({content, onNavigation}) => {
    const link = '/';
    return (
    <div>
        <div>Default notification Body</div>
        <div>{JSON.stringify(content)}</div>
        <div>You can navigate to another page with <Button type='link' onClick={() => onNavigation(link)} style={{"padding":"0"}}>onNavigation</Button> link</div>
    </div>)
}
DefaultType.Popup = Popup;
export default DefaultType;