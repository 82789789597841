import React from 'react';

import {
  Row,
  Col,
  Progress
} from 'antd';

import './style.less';

const CustomProgress = ({
  title,
  ...props
}) => {

  return (
    <span className="CustomProgress">
      <Row type="flex" justify="center">
        <Col span={24}>
          <Row type="flex" justify="center">
            <Progress {...props} />
          </Row>
        </Col>
        <h4>{ title }</h4>
      </Row>
    </span>
  );
}

export default CustomProgress;
