import React, {
  useMemo,
  useState
} from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import ReportingStandardTag from 'components/ReportingStandardTag';
import CustomButton from 'components/CustomButton';

import {
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import {
  Row,
  Col,
  Select
} from 'antd';
import { sortKpiStructure } from 'utils/kpi';

const { Option } = Select;

const ReportingStandardSearch = injectIntl(({
  intl,
  reportingStandards,
  onChange,
  value,
  reportingStandardKpis
}) => {
  const [standard, setStandard] = useState()
  const [kpi, setKpi] = useState()

  const handleKpiAdd = () => { 
    if (!_.isEmpty(standard) 
        && !_.isEmpty(kpi) 
        && (!(standard in (value || {}) && (value[standard] || []).includes(kpi)))) {
      onChange({ ...value, [standard]: [...(value[standard] || []), kpi] });
    }
    setStandard('');
    setKpi('');
  }

  const options = useMemo(() => {
    const optionList = standard ? (reportingStandardKpis || {})[standard] : [];
    return optionList.sort(sortKpiStructure(intl.locale));
  }, [standard, intl.locale, reportingStandardKpis]);

  return (
    <Row
        type="flex"
        className="CreateEditCustomKPI__reporting-standard-search" 
        gutter={10}
      >
        <Col xs={6}>
          <Select
            showSearch
            optionFilterProp="children" 
            value={ standard }
            onSelect={(value) => { 
              setStandard(value); 
              setKpi('') 
            }}
            filterOption={(input, option) => 
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={reportingStandards}
          />
        </Col>
        <Col xs={18}>
        
          <Row
            type="flex"
            className="CreateEditCustomKPI__reporting-standard-search-kpi" 
            gutter={10}
          >
            <Col xs={21}>
              <Select 
                showSearch
                optionFilterProp="children"
                value={kpi}
                onSelect={ setKpi }
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              >
                {
                  options.map( kpi => (
                    <Option 
                      key={`${standard}-${kpi.kpi_slug}`} 
                      value={kpi.kpi_slug} 
                      label={`${kpi.code} ${kpi.name}`} 
                      disabled={ standard in value && value[standard].includes(kpi.kpi_slug) } 
                      className='reporting-standard-search-kpi-option'
                    >
                      <ReportingStandardTag standard={standard} code={kpi.code} style={{ marginTop: '0px' }} />
                      { kpi.name }
                    </Option>
                  ))
                }
              </Select>
            </Col>
            <Col xs={3}>
              <CustomButton 
                className="CustomButton-primary-outlined"
                onClick={handleKpiAdd} 
                icon={ <PlusOutlined /> }>
                { intl.formatMessage({ id: `createcustomkpi_reporting_standard_add` }) } 
              </CustomButton>
            </Col>
          </Row>
        </Col>
    </Row>
  );
});

const ReportingStandard = ({
  intl,
  value,
  onChange,
  error,
}) => {
  const { reporting_standard_kpis } = useSelector(state => state.taxonomies);

  const handleRemoveKpi = (standard, slug) => { 
    const newStandardKpiList = value[standard].filter(selectedSlug => selectedSlug !== slug); 
    let newValue = {...value };
    if (newStandardKpiList.length > 0){
      newValue = {
        ...value,
        [standard]: newStandardKpiList
      };
    } else {
      delete newValue[standard];
    }
    onChange(newValue);
  }

  const reportingStandards = useMemo(() => { 
    const arrStandards = Object.keys(reporting_standard_kpis || {}).sort();
    return arrStandards.map(value => ({
      value,
      label: intl.formatMessage({ id: `reporting_standard_${value}` }),
    }));
  }, [intl, reporting_standard_kpis]);

  return (
    <section className='CreateEditCustomKPI-type-reporting-standards'>
      <div className='CreateEditCustomKPI-title'>
        {intl.formatMessage({ id: "createcustomkpi_reporting_standard" })}
      </div>
      {
        !error ? null :
          <div className="CreateEditCustomKPI__error">{error}</div>
      }
      <Row
        type="flex"
        className="CreateEditCustomKPI__reporting-standard-row"
        gutter={10}
      >
        <Col xs={6}>{intl.formatMessage({ id: "createcustomkpi_reporting_standard_standard" })}</Col>
        <Col xs={18}>{ intl.formatMessage({ id: "createcustomkpi_reporting_standard_indicator" }) }</Col>
      </Row>

      <ReportingStandardSearch
        reportingStandards={reportingStandards}
        onChange={onChange}
        value={value || {}}
        reportingStandardKpis={reporting_standard_kpis || {}}
      />

      {
        Object.values(value || {}).length > 0 && 
        (
          <Row
            type="flex"
            className="CreateEditCustomKPI__reporting-standard-row"
            gutter={10}
          >
            <Col xs={6}>{ intl.formatMessage({ id: "createcustomkpi_reporting_standard_standard" }) }</Col>
            <Col xs={18}>{ intl.formatMessage({ id: "createcustomkpi_reporting_standard_indicator" }) }</Col>
          </Row>
        )
      }

      {
        Object.keys(value || {}).map(standard => (
          <Row
            type="flex"
            className="CreateEditCustomKPI__reporting-standard-row"
            gutter={10} 
            key={standard}
          >
            <Col xs={6}>
              <h4>{intl.formatMessage({ id: `reporting_standard_${standard}` })}</h4>
            </Col>
            <Col xs={18}>
              <ul>
                {value[standard].map(slug => {
                  const kpi = reporting_standard_kpis[standard].find(({ kpi_slug }) => kpi_slug === slug)
                  return (
                    <li key={`${standard}-${slug}`}>
                      <span className='CreateEditCustomKPI__reporting-standard-row-name'>
                        <ReportingStandardTag standard={standard} code={kpi.code} style={{ marginTop: '0px' }} />
                        {kpi.name}
                      </span>
                      <CustomButton 
                          icon={<DeleteOutlined />}
                          onClick={ () => handleRemoveKpi(standard, slug) }
                      />
                    </li>
                  )
                })}
              </ul>
            </Col>
          </Row>
        ))
      }
    </section>
  )
}

export default injectIntl(ReportingStandard);