import { useEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux";
import { validateKpiForm } from "actions/api";

const isTableChoiceSecureUpdate = (oldSchema, newSchema) => {

  if (oldSchema.source !== newSchema?.source) return false;
  if (oldSchema.source === 'organization' && (oldSchema.by !== newSchema?.by)) return false;
  if (oldSchema.source === 'standard') {
    const oldNames = (oldSchema.standardItems || []).map(item => item.name);
    const newNames = (newSchema?.standardItems || []).map(item => item.name);
    if (oldNames.some((oldName, idxName) => idxName < newNames.length && newNames[idxName] !== oldName)) return false;
  }

  return true;

}

const isTableSecureUpdate = (oldSchema, newSchema) => {

  const isOldAutoextended = (oldSchema.dimensions || [])[0].source === 'user';
  const isNowAutoextended = (newSchema.dimensions || [])[0].source === 'user';
  if (isOldAutoextended !== isNowAutoextended) return false;

  if ((oldSchema.dimensions || []).length !== (newSchema.dimensions || []).length) return false;

  if ((oldSchema.dimensions || []).some((oldDimension, inxOld) => {

    const newDimension = (newSchema.dimensions || [])[inxOld];
    if (!isTableChoiceSecureUpdate(oldDimension, newDimension)) return true;
    if (oldDimension.presentation !== newDimension?.presentation) return true;

    return false;

  })) return false;

  if (oldSchema.innerSchema.type !== newSchema.innerSchema.type) return false;

  return true;
}

const isQuantitativeUnitsSecureUpdate = (oldSchema, newSchema) => {
  const newAllowedUnitSlugs = (newSchema.allowedUnitSlugs || []);
  const missingUnitSlugs = (oldSchema.allowedUnitSlugs || []).filter(unitSlug => !newAllowedUnitSlugs.includes(unitSlug));
  return missingUnitSlugs.length === 0;
}

const convertSchemaData = (data) => {

  switch (data.type) {

    case 'quantitative':
      const { allowedUnitSlugs, metricSlug } = data;
      return {
        allowedUnitSlugs,
        metricSlug,
        type: data.type
      };
    case 'table':
      const { dimensions, innerSchema } = data;
      return {
        dimensions,
        innerSchema,
        type: data.type
      };
    default:
      return {};

  }

}

const useKpiSchemaValidation = (
  organization,
  suborganization,
  kpi_slug,
  oldSchema,
  callback
) => {

  const dispatch = useDispatch();
  const [isValidating, setIsValidating] = useState(false);
  const [isSecureUpdate, setIsSecureUpdate] = useState(false);
  const { form_validation } = useSelector(state => state.kpi_detail);

  const doCallback = useCallback((isSecureUpdate) => {
    setIsValidating(false);
    setIsSecureUpdate(isSecureUpdate);
    if (callback) {
      callback({ isSecureUpdate });
    }
  }, [callback]);

  useEffect(() => {

    if (!isSecureUpdate && form_validation) {
      const { loading, data } = form_validation;
      if (isValidating && !loading && data) {
        doCallback(!!data?.isSecureUpdate);
      }
    }

  }, [isSecureUpdate, form_validation, isValidating, doCallback]);


  const validateSchemaForm = useCallback((newSchema) => {

    setIsValidating(true);
    setIsSecureUpdate(false);
    let isValidationOnServer = false;

    switch (newSchema.type) {

      case 'quantitative':
        if (!isQuantitativeUnitsSecureUpdate(oldSchema, newSchema)) {
          isValidationOnServer = true;
        }
        break;
      case 'table':
        if (!isTableSecureUpdate(oldSchema, newSchema)) {
          return doCallback(false);
        }
        switch (newSchema.innerSchema.type) {
          case 'quantitative':
            if (!isQuantitativeUnitsSecureUpdate(oldSchema.innerSchema, newSchema.innerSchema)) {
              isValidationOnServer = true;
            }
            break;
          case 'choice':
            if (!isTableChoiceSecureUpdate(oldSchema.innerSchema.options, newSchema.innerSchema.options)) {
              return doCallback(false);
            }
            break;

          case 'tuple':
            if ((newSchema.innerSchema.components || []).some((newComponent) => {

              const oldComponent = oldSchema.innerSchema.components.find(
                ({name}) => name === newComponent.name
              );

              if (!oldComponent) {
                return false;
              }

              if (oldComponent.type !== newComponent.type) {
                return true;
              }

              switch (newComponent.type) {
                case 'quantitative':
                  if (!isQuantitativeUnitsSecureUpdate(oldComponent, newComponent)) {
                    isValidationOnServer = true;
                    return false;
                  }
                  break;
                case 'choice':
                  if (!isTableChoiceSecureUpdate(oldComponent.options, newComponent.options)) return true;
                  break;
                default:
                  break;
              }

              return false

            })) {
              return doCallback(false);
            };
            break;

          default:
            break;

        }
        break;
      default:
        break;
    }

    if (isValidationOnServer) {
      dispatch(
        validateKpiForm({
          organization_slug: organization.slug,
          suborganization_slug: suborganization.slug,
          kpi_slug,
          schema: newSchema.type ? convertSchemaData(newSchema) : undefined
        })
      );
    } else {
      doCallback(true);
    }

  }, [
    dispatch,
    kpi_slug,
    oldSchema,
    organization.slug,
    suborganization.slug,
    doCallback
  ]);

  return {
    isFetching: form_validation && form_validation.loading,
    isSecureUpdate,
    validateSchemaForm
  }

}

export default useKpiSchemaValidation;