const TARGET = ['standards_categories', 'standards_kpis'];
const isTarget = (target) => TARGET.includes(target);

const initialState = {
  data: null,
  standard_categories: null,
  standard_kpis: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API_CALL_REQUEST':
      if (!isTarget(action.target)) return state;
      return {
        standard_categories: state.standard_categories,
        standard_kpis: state.standard_kpis,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if (!action.response || !isTarget(action.response.target)) return state;
      if (action.response.target === 'standards_categories') {
        return {
          standard_categories: action.response.result,
          standard_kpis: state.standard_kpis,
          loading: false,
          error: null,
        };
      }
      else {
        return {
          standard_categories: state.standard_categories,
          standard_kpis: action.response.result,
          loading: false,
          error: null,
        };
      }
    default:
      return state;
  }
};

export {
  reducer as standards_categories_kpis,
};
