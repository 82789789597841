import React, {
  useCallback,
} from 'react';
import { injectIntl } from 'react-intl';
import './style.less';
import Avatar from 'components/Avatar';
import Answer from 'components/Answer';
import KpiSourceIcon from 'components/KpiSourceIcon';
import CustomCheckbox from 'components/CustomCheckbox';
import {
  DeleteOutlined,
} from '@ant-design/icons';

import { formatMessage } from '../../intl';

import {
  Empty,
} from 'antd';
import { DATA_REQUEST_STATUS } from 'utils/dataRequests';

const DEFAULT_AVATAR_PROPS = {
  icon: 'user',
};

const getAvatarProps = (avatar) => {
  if(avatar) {
    return { src: avatar };
  }
  return DEFAULT_AVATAR_PROPS;
};

const NO_OP = () => {};

const DataAnswerCard = ({
  intl,
  onClick = NO_OP,
  name,
  avatar,
  email,
  value,
  status,
  attachments = [],
  selectable = false,
  selected,
  select,
  id,
  date,
  schema,
  loading,
  showRemind = true,
  onRemind = NO_OP,
  reminded,
  supportNotApply = false,
  applies = true,
  not_apply_comment = '',
  source,
  requestStatus,
  requestParams,
  newDataRequest = false,
}) => {
  const t = intl.messages;

  const handleSelect = useCallback((e) => {
    if(loading) {
      return;
    }
    e.stopPropagation()
    select(id)
  }, [
    loading,
    select,
    id,
  ]);

  const handleRemind = useCallback((e) => {
    if(loading) {
      return;
    }
    e.stopPropagation()
    onRemind()
  }, [
    loading,
    onRemind,
  ]);

  const isChildrenSource = source === 'children';

  return (
    <section
      className={`DataAnswerCard__card${selectable && selected ? ' selected-answer' : ''}`}
      onClick={onClick}
    >
      <div className="DataAnswerCard__header">
        {status === 'deleted' 
        ?
          <Avatar className='DataAnswerCard__avatar' size={62} icon={<DeleteOutlined />} />
        :
          <Avatar
            className='DataAnswerCard__avatar'
            size={62}
            {...getAvatarProps(avatar)}
          />
        }
        <span className="DataAnswerCard__name">{ formatMessage(name) }</span>
        {
          !selectable ? null : (
            <span
              className="CategoryCard__checkbox"
            >
              <CustomCheckbox
                checked={selected}
                onClick={handleSelect}
                disabled={(supportNotApply && !applies) || !value}
              />
            </span>
          )
        }
      </div>
      <div className="DataAnswerCard__body">
      {
        isChildrenSource
        ? (
          <Empty
            image={
              <KpiSourceIcon
                className="DataAnswerCard__childrenicon"
                gray
                source={source}
              />
            }
            description={t.dataanswercard_children_detail}
          />
        )
        : typeof value === 'undefined' || value === null
          ? (
            <Empty
              image={'/images/icon-empty-data.svg'}
              description={t.kpi_detail_empty}
            />
          )
          : (
            <Answer.Summary
              schema={schema}
              value={value}
            />
          )
      }
      </div>
      <div className="DataAnswerCard__footer">
        { !isChildrenSource ? null :
          <section className='DataAnswerCard__children'>
            { t.dataanswercard_children }
          </section>
        }
        { isChildrenSource || !supportNotApply || applies ? null :
          <section className='DataAnswerCard__notapply'>
            { t.kpi_status_notapplies }
          </section>
        }
        { isChildrenSource || (supportNotApply && !applies) || value ? null :
          <section className='DataAnswerCard__pending'>
            <span>{ t.pending }</span>
            { !showRemind || newDataRequest ? null :
              <figure
                className='DataAnswerCard__warn'
                onClick={!reminded && handleRemind}
              >
                {
                  reminded
                  ? <div className="DataAnswerCard__pending__reminded">&#10004;</div>
                  : <img src={'/images/assets/icon-bell.svg'} alt='' />
                }
              </figure>
            }
          </section>
        }
        { isChildrenSource || (supportNotApply && !applies) || !value ? null :
          <section className='DataAnswerCard__recieved'>
            {requestStatus === DATA_REQUEST_STATUS.in_use || requestStatus === DATA_REQUEST_STATUS.partially_in_use ? (
              <span className={`DataAnswerCard__used ${requestStatus}`}>
                {intl.formatMessage({
                  id: `admin_data_requests_table_request_status_${requestStatus}`
                })}
              </span>
            ) : (
              <span>{ t.recieved }</span>
            )}
            <span className='DataAnswerCard__recieved-sub'>{ date }</span>
          </section>
        }
        <section>
          <figure
            className='DataAnswerCard__attachments'
          >
            <span>{attachments.length}</span>
            <img src='/images/assets/icon-attachments.svg' alt=''/>
          </figure>
        </section>
      </div>
    </section>
  );
};

export default injectIntl(DataAnswerCard);
