import React, { forwardRef } from 'react'

import { Chart as ChartJS, CategoryScale, LinearScale, RadialLinearScale, BarElement, PointElement, LineElement, ArcElement, Title, Tooltip, Legend } from 'chart.js'; 
import ChartDataLabels from 'chartjs-plugin-datalabels';
import UnknownChart from './UnknownChart';
import SingleNumber from './SingleNumber';
import BooleanCard from './BooleanCard';
import TextCard from './TextCard';
import SDGScore from './SDGScore';
import Bars from './Bars';
import Pie from './Pie';
import Spider from './Spider';
import Lines from './Lines';
import { formatNumberAbbr } from 'utils/formatNumber';

ChartJS.register(ChartDataLabels,CategoryScale, LinearScale, RadialLinearScale, BarElement, PointElement, LineElement, ArcElement, Title, Tooltip, Legend);

const chartsWeCanDownload = new Set([
  'vertical-bars',
  'horizontal-bars',
  'vertical-stacks',
  'horizontal-stacks',
  'pie',
  'donut',
  'ring',
  'spider',
  'smooth-lines',
  'sharp-lines',
]);

export const hasDownloadButton = (type) => type && chartsWeCanDownload.has(type);

export const cardMap = {
  'sdg-score': SDGScore,
  'boolean': BooleanCard,
  'text': TextCard,
  'number': SingleNumber,
  'vertical-bars': forwardRef(({ parameters, ...props }, ref) => (
    <Bars
      ref={ref}
      parameters={{
        ...parameters,
        layout: 'vertical',
        type: 'vertical-bars',
      }} 
      {...props}
    />)),
  'horizontal-bars': forwardRef(({ parameters, ...props }, ref) => (
    <Bars
      ref={ref}
      parameters={{
        ...parameters,
        layout: 'horizontal',
        type: 'horizontal-bars',
      }}
      {...props}
    />)),
  'vertical-stacks': forwardRef(({ parameters, ...props }, ref) => (
    <Bars
      ref={ref}
      parameters={{
        ...parameters,
        layout: 'vertical',
        stacked: true,
        type: 'vertical-stacks',
      }}
      {...props}
    />)),
  'horizontal-stacks': forwardRef(({ parameters, ...props }, ref) => (
    <Bars
      ref={ref}
      parameters={{
        ...parameters,
        layout: 'horizontal',
        stacked: true,
        type: 'horizontal-stacks',
      }}
      {...props}
    />)),
  'pie': forwardRef(({ parameters, ...props }, ref) => (
    <Pie
      ref={ref}
      parameters={{
        ...parameters,
        type: 'pie',
      }}
      {...props}
    />)),
  'donut': forwardRef(({ parameters, ...props }, ref) => (
    <Pie
      ref={ref}
      parameters={{
        ...parameters,
        type: 'donut',
      }}
      {...props}
    />)),
  'ring': forwardRef(({ parameters, ...props }, ref) => (
    <Pie
      ref={ref}
      parameters={{
        ...parameters,
        type: 'ring', 
      }}
      {...props}
    />)),
  'spider': forwardRef(({ parameters, ...props }, ref) => (
    <Spider
      ref={ref}
      parameters={{
        ...parameters,
        type: 'spider',
      }}
      {...props}
    />)),
  'smooth-lines': forwardRef(({ parameters, ...props }, ref) => (
    <Lines
      ref={ref}
      parameters={{
        ...parameters,
        smooth: true,
        type: 'smooth-lines',
      }}
      {...props}
    />)),
  'sharp-lines': forwardRef(({ parameters, ...props }, ref) => (
    <Lines
      ref={ref}
      parameters={{
        ...parameters,
        type: 'sharp-lines',
      }}
      {...props}
    />)),
  'default': UnknownChart,
};

export const iconMap = {
  'number': 'calculator',
  'vertical-bars': 'chart-bar',
  'horizontal-bars': 'chart-bar',
  'vertical-stacks': 'chart-bar',
  'horizontal-stacks': 'chart-bar',
  'pie': 'chart-pie',
  'donut': 'chart-pie',
  'ring': 'chart-pie',
  'smooth-lines': 'chart-line',
  'sharp-lines': 'chart-line',
  'spider': 'spider',
  'default': 'question',
};

export const getParametersFromData = (data = {}) => {
  const {
    series = [],
    schema,
  } = data;

  return {
    index: 'id',
    keys: series,
    key: (series[0] || 'value'),
    schema,
  };
};

export const convertData = (intl, chart_type, data = {}, parameters = {}, dashboardTheme, showDataTarget = false) => {
  const {
    series = [],
    keys = [],
    samples = [],
  } = data;

  const defaultParameters = getParametersFromData(data);

  const {
    key: singleKeyName = defaultParameters.key,
  } = parameters;

  if(samples.length === 0 || series.length === 0 || keys.length === 0){

    switch(chart_type){
      case 'text':
        return null;
      default:
        return {}; // No data
    }

  }

  let values;

  switch(chart_type) {
    case 'text':
      return samples[0].values[0];
    case 'boolean':
      values = series.map((serie, index) => ({
        label: serie,
        data: samples.map(({ key, values, targetValues }) => ({
          value: values[index],
          target: showDataTarget ? targetValues[index] : null
        }))
      }));

      return {
        labels: keys,
        values
      };

    case 'number':
      // More than 1 value? We compare
      
      if(samples.length > 1) {
        return series.map((serie, index) => ({ 
          [singleKeyName]: { 
            value: samples[1].values[index],
            target: showDataTarget ? samples[1].targetValues[index] : undefined,
          },
          period: samples[1].key,
          compare: {
            value: samples[0].values[index],
            target: showDataTarget ? samples[0].targetValues[index] : undefined,
          },
          compare_period: samples[0].key,
        }));
      }

      // Only one value, we do not compare
      return series.map((serie, index) => ({
        [singleKeyName]: { 
          value: samples[0].values[index],
          target: showDataTarget ? samples[0].targetValues[index] : undefined,
        },
        period: serie,
      }));

    case 'spider':

      values = series.map((serie, index) => {

        return {
          label: serie,
          backgroundColor: dashboardTheme[index],
          data: samples.map(({ values }) => values[index]),
        }

      });

      return {
        labels: keys,
        values
      };

    case 'vertical-stacks':
    case 'horizontal-stacks':
    case 'vertical-bars':
    case 'horizontal-bars':
    case 'smooth-lines':
    case 'sharp-lines':

      const isHorizontalChart = ['horizontal-bars', 'horizontal-stacks'].includes(chart_type);

      values = series.map((serie, index) => ({
          label: serie,
          backgroundColor: dashboardTheme[index],
          borderColor: dashboardTheme[index],
          data: samples.map(({ key, values, targetValues }) => ({
            [isHorizontalChart ? 'y' : 'x'] : key,
            value: values[index],
            target: showDataTarget ? targetValues[index] : null
          })),
          parsing: {
            [isHorizontalChart ? 'xAxisKey' : 'yAxisKey']: 'value'
          }
      }));

      let targets = [];

      if (showDataTarget && ['smooth-lines', 'sharp-lines'].includes(chart_type)){

        targets = series.map((serie, index) => {
  
          // const serieColor = convertHex2RGB(dashboardTheme[index]);
  
          let targetOptions = {
            backgroundColor: dashboardTheme[index],
            borderWidth: 2,
            borderColor: dashboardTheme[index],
            borderDash: [6, 6],
            fill: false
          };
  
          return {
            label: `${ intl.formatMessage({ id: 'dashboard_card_form_target_chart_label' }) } - ${ serie }`,
            order: (index === 0) ? index : index * 2, 
            data: samples.map(({ key, values, targetValues }) => ({
              x : key,
              value: values[index],
              target: showDataTarget ? targetValues[index] : null
            })),
            parsing: {
              yAxisKey: 'target'
            },
            ...targetOptions
          }
        });

      }

      return {
        labels: keys,
        values: [
          ...targets,
          ...values,
        ]
      };

    case 'pie':
    case 'donut':
    case 'ring':
      // More than one key and only one serie? Let's flip this
      if(samples.length > 1 && series.length === 1) {
        return samples.reduce((result, sample) => {
          return {
            labels: [...result.labels, sample.key],
            values: [...result.values, sample.values[0]]
          };
        }, {labels: [], values: []})
      }

      if(samples.length > 1) {
        console.log(`WARNING: misformatted information while converting for ${chart_type}`, samples);
      }

      return {
        labels: series,
        values: samples[0].values,
      };
    case 'sdg-score':
      return data.samples.reduce((obj, sample) => ({
        ...obj, [sample.slug]: sample.values[0]}),
        {}
      );
    default:
      return data;
  }
};

export const getDatalabelOptions = ({ type, locale, schema, showDataTarget }) => {

  const formattingOptions = getFormattingOptions(schema, locale);

  const commonOptions = {
    backgroundColor: '#EBEAEA',
    borderRadius: 4,
    color: function(context){
       return context.dataset.backgroundColor; 
    },
    font: {
      weight: 'bold'
    },
    padding: {
      top: 1,
      right: 4,
      bottom: 1,
      left: 4
    },
    clamp: true,
    anchor: 'end',
    align: 'end',
    offset: 4,
    textAlign: 'center',
    formatter: function (value, context){ 

      if (['vertical-stacks', 'horizontal-stacks'].includes(type)){

        return (isNaN(value.value) ? value.value : getFormattedValue(value.value, formattingOptions)) || '-';

      }

      if (['vertical-bars', 'horizontal-bars', 'smooth-lines', 'sharp-lines'].includes(type)){

        const dataValue = (isNaN(value.value) ? value.value : getFormattedValue(value.value, formattingOptions)) || '-';

        if (showDataTarget){
        
          const targetValue = value.target 
                                ? (isNaN(value.target) ? value.target : getFormattedValue(value.target, formattingOptions)) 
                                : null;
  
          if (type === 'vertical-bars' && targetValue){

            const model = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
            const datalabelWithTargetWidth = (dataValue.length * 6) + 8 + (targetValue.length * 6) + 1;
            const modelProps = model.getProps(['width'], true);

            if (datalabelWithTargetWidth > modelProps.width){
              return `${ dataValue }\n(${ targetValue })`;
            }

          }
          
          return targetValue ? `${ dataValue } (${ targetValue })` : dataValue;
  
        } else {

          return dataValue;

        }

      }

      if (isNaN(value)){
        return value;
      }

      return getFormattedValue(value, formattingOptions);
    }
  }

  switch (type) {
    case 'horizontal-bars':
    case 'vertical-bars':
      return {
        ...commonOptions,
        display: function(context) {
          const data = context.dataset.data[context.dataIndex];
          return data.value || data.target;
        },
        align: function(context) { 
          return getDatalabelAlign(type, context, schema, locale, showDataTarget);
        },
        offset: function(context) {
          
          const defaultOffset = 4;
          
          if (showDataTarget){ 
            const { chart } = context;
            const data = context.dataset.data[context.dataIndex];
            const valueNum = data.value ? data.value : 0;

            if (data.target && (data.target > valueNum)){
              const model = chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
              const scale = model.horizontal ? chart.scales.x : chart.scales.y;
              let diffPx = 0;
              
              if (model.horizontal){
                const align = getDatalabelAlign(type, context, schema, locale, showDataTarget);
                diffPx = align === 'end' 
                            ? scale.getPixelForValue(data.target) - scale.getPixelForValue(valueNum)
                            : scale.getPixelForValue(valueNum) - scale.getPixelForValue(data.target);

              } else {
                diffPx = scale.getPixelForValue(valueNum) - scale.getPixelForValue(data.target);
              }
              
              return defaultOffset + diffPx;
            }
          }
          return defaultOffset;
        }
      }
    case 'vertical-stacks':
    case 'horizontal-stacks':
    case 'pie':
    case 'donut':
    case 'ring':
      return {
        ...commonOptions,
        align: 'center',
        anchor: 'center',
      }
    case 'smooth-lines':
    case 'sharp-lines':
      return {
        ...commonOptions,
        align: function (context){
          if (context.dataIndex === 0) return 'right';
          if ((context.dataIndex + 1) === context.dataset.data.length) return 'left';
          return 'end';
        },
        display: function(context) {

          if (showDataTarget){
            const datasets = context.chart.config.data.datasets;
            const numDatasets = (datasets.length / 2);
            if (context.datasetIndex < numDatasets){
              return false;
            }
    
          }
          return true;
        }
      }
    
    default:
      break;
  }

  return false;

}

const getDatalabelAlign = (type, context, schema, locale, showDataTarget) => {

  const formattingOptions = getFormattingOptions(schema, locale);

  switch( type ) {
    case 'vertical-bars':
      return 'end';
    case 'horizontal-bars':
      const chartWidth = context.chart.chartArea.width;
      const data = context.dataset.data[context.dataIndex];
      const model = context.chart.getDatasetMeta(context.datasetIndex).data[context.dataIndex];
      const formattedValue = (isNaN(data.value) ? data.value : getFormattedValue(data.value, formattingOptions)) || '';
      const formattedTarget = (showDataTarget && data.target ? isNaN(data.target) ? data.target : getFormattedValue(data.target, formattingOptions) : '') || '';

      const datalabelWidth = (formattedValue.length * 6) + 8 + (showDataTarget ? (formattedTarget.length * 6) + 1 : 0);

      const modelProps = model.getProps(['width', 'base', 'x'], true);
      const targetNum = data.target ? data.target : 0;
      const valueNum = data.value ? data.value : 0;
      const xTarget = context.chart.scales.x.getPixelForValue(targetNum);
      const elmWidth = data.target && (data.target > valueNum) ? xTarget - modelProps.base: modelProps.width;
      
      return (elmWidth + 4 + datalabelWidth) <= chartWidth ? 'end' : 'start';
    default:
      return null;
  }
  
}

const getFormattingOptions = (schema, locale) => {
  
  const formattingOptions = { 
    locale, 
    maximumFractionDigits: schema && schema.type === 'quantitative' ? schema.decimalPoints : undefined, 
    minimumFractionDigits: schema && schema.type === 'quantitative' ? schema.decimalPoints : undefined,
  };
  return formattingOptions;

}

const getFormattedValue = (value, formattingOptions) => {

  const decimalPart = value - Math.floor(value);
  const decimalPlaces = decimalPart > 0 ? value.toString().split('.')[1].length : 0;
  const minimumFractionDigits = Math.min(decimalPart > 0 ? decimalPlaces : 0, formattingOptions.minimumFractionDigits || 0);
  const maximumFractionDigits = Math.min(decimalPart > 0 ? decimalPlaces : 0, 3);

  const newOptions = {
    ...formattingOptions,
    minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits > minimumFractionDigits ? maximumFractionDigits : minimumFractionDigits
  }
  
  return formatNumberAbbr(value, newOptions);

}