import React from 'react';

import {
  Tooltip,
} from 'antd';

import {
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import './style.less';

const ICON_PER_TYPE = {
  info: InfoCircleOutlined,
  warning: WarningOutlined,
  default: InfoCircleOutlined,
};

const COLOR_ICON_PER_TYPE = {
  info: 'is-info',
  warning: 'is-warning',
  default: 'is-info',
};

const WARNING_COLOR = '#D38106';

const InfoTooltip = ({
  type='info',
  placement='bottomRight',
  trigger = 'hover',
  content
}) => {
    const Icon = ICON_PER_TYPE[type] || ICON_PER_TYPE.default;
    return (
        <div className={`InfoTooltip ${COLOR_ICON_PER_TYPE[type]}`}>
          <Tooltip
            overlayClassName={`InfoTooltip__tooltip ${COLOR_ICON_PER_TYPE[type]}`}
            placement={placement}
            title={content}
            trigger={trigger}
            color={type === 'warning' && WARNING_COLOR}
            arrowPointAtCenter
          >
            <Icon className="InfoTooltip__icon" />
          </Tooltip>
        </div>
    )
};

InfoTooltip.propTypes = {
  placement: PropTypes.oneOf([
    'top', 'left', 'right', 'bottom', 'topLeft', 'topRight', 'bottomLeft',
    'bottomRight', 'leftTop', 'leftBottom', 'rightTop', 'rightBottom',
  ]),
  content: PropTypes.element.isRequired,
  type: PropTypes.oneOf(['info', 'warning']),
  trigger: PropTypes.oneOf(['hover', 'focus', 'click']),
};

export default InfoTooltip;