import React from 'react';

import Form from './Form'

import {
  Row,
  Col,
} from 'antd';

import './style.less';


const EqualityPlanEvaluationOfResults = ({
  plan,
  updatePlan,
}) => {
  return (
    <Row>
      <Col span={24}>
        <Form
          plan={plan}
          updatePlan={updatePlan} />
      </Col>
    </Row>
  );
};

export default EqualityPlanEvaluationOfResults;
