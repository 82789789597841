import {isEmpty} from 'lodash';

const LIMIT_SELECTION_BY_TYPE = {
  kpis: 50,
  nodes: 5,
};

export const validateStep1 = (intl, values) => {
  const {
    selected_kpis,
    selected_nodes
  } = values;
  
  let errors = {};

  if (isEmpty(selected_kpis)) {
      errors.selected_kpis = intl.formatMessage({id: 'step_1_error_selected_kpis_empty'});
  }

  if (selected_kpis.length > LIMIT_SELECTION_BY_TYPE.kpis) {
    errors.selected_kpis_limit = intl.formatMessage({id: 'step_1_error_selected_kpis_limit'});
}

  if (isEmpty(selected_nodes)) {
      errors.selected_nodes = intl.formatMessage({id: 'step_1_error_selected_nodes_empty'});
  }

  if (selected_nodes.length > LIMIT_SELECTION_BY_TYPE.nodes) {
    errors.selected_nodes_limit = intl.formatMessage({id: 'step_1_error_selected_nodes_limit'});
  }

  return errors;
};