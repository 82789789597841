import React, {
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useIntl } from 'react-intl';

import { useDispatch } from 'react-redux';
import useOrganizations from 'utils/useOrganizations';

import {
  findTree,
} from 'utils/tree';

import {
  setApplies,
} from 'actions/api';

import CustomSelect from 'components/CustomSelect';
import { ORGANIZATION_TARGET_OPTIONS } from 'components/CustomSelect/targets';
import { Alert } from 'antd';

import CustomModalType from 'components/CustomModalType';
import './style.less';

const ModalApplyNotApply = ({
  visible,
  onClose,
  onSubmit,
  applying = false, // true if we are setting applies: true
  kpi_slugs: _kpi_slugs = [],
  clearSelection,
  selectedKpisUnderValidation = [],
  hasNodesSelector = false 
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [target, setTarget] = useState('self');

  const {
    organization,
    suborganization,
    organizationTree,
  } = useOrganizations();

  const hasChildren = useMemo(() => {
    if(!visible && !hasNodesSelector) {
      return false; // Not worth processing
    }
    const treeNode = findTree(
      [ organizationTree ],
      ({ slug }) => slug === suborganization.slug,
    );

    // TODO: MAYBE consider 'restricted' children as non-existing??
    return treeNode && treeNode.children && treeNode.children.length > 0;
  }, [
    visible,
    suborganization,
    organizationTree,
    hasNodesSelector,
  ]);

  const kpi_slugs = useMemo(() => {
    return _kpi_slugs.filter(
      slug => !(selectedKpisUnderValidation || []).includes(slug)
    );
  }, [
    _kpi_slugs,
    selectedKpisUnderValidation,
  ]);

  const handleClose = useCallback(() => {
    setTarget('self');
    onClose();
    if(clearSelection) {
      clearSelection();
    }
  }, [
    onClose,
    clearSelection
  ]);

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit();
    } else {
      dispatch(
        setApplies(
          organization.slug,
          suborganization.slug,
          kpi_slugs,
          applying,
          '',
          (hasChildren ? target : 'self'),
        )
      )
    }

    setTarget('self');
    onClose();
    if(clearSelection) {
      clearSelection();
    }
  }, [
    onSubmit,
    onClose,
    clearSelection,
    applying,
    dispatch,
    organization.slug,
    suborganization.slug,
    kpi_slugs,
    hasChildren,
    target
  ]);

  return (
    <CustomModalType
    // NOTICE: now when mark not apply a kpi its data requests are deleted until the iteration start-stop of data requests it's finished
      className='ModalApplyNotApply'
      showModal={visible}
      type={visible ? applying ? 'main' : 'warning' : null}
      title={applying ? intl.formatMessage({id: "modal_apply_title"}) : intl.formatMessage({id: "modal_not_apply_title"})}
      icon={applying ? null :  'Alert'}
      iconType='fad'
      onOk={ handleSubmit }
      onCancel={ handleClose }
      onOkText={ applying ? intl.formatMessage({ id: "applies_confirmation_ok"}) : intl.formatMessage({ id: "not_applies_confirmation_ok"})}
      onCancelText={ intl.formatMessage({ id: "applies_confirmation_cancel"}) }
      content={visible ? (
        <div className="ModalApplyNotApply__content">
           {
            !hasChildren || !hasNodesSelector
            ? null
            : (
              <>
                <span className="ModalApplyNotApply__dropdown-title">{intl.formatMessage({ id: "applies_not_applies_direction"})}</span>
                <CustomSelect
                  className="ModalApplyNotApply__dropdown"
                  title={intl.formatMessage({ id: "applies_not_applies_direction"})}
                  selected={target}
                  options={ORGANIZATION_TARGET_OPTIONS.map(slug => ({
                    name: intl.formatMessage({ id: `applies_not_applies_direction_${slug}`}),
                    slug,
                  }))}
                  onSelect={setTarget}
                />
              </>
            )
          }
          { !!selectedKpisUnderValidation?.length &&
            <Alert
              type="warning"
              message={intl.formatMessage({id: 'applies_not_applies_under_validation'})}
            />
          }
          {applying ? null : (
            <div className="ModalApplyNotApply__messages">
              <span>
                {intl.formatMessage(
                  {id: `not_apply_message_1`},
                  {notApply: <b style={{color: '#D38106'}}>{intl.formatMessage({id: "not_apply"})}</b>}
                )}
              </span>
              <span>
              {intl.formatMessage(
                {id: `not_apply_message_2`},
                {dataRequestsDeleted: <b style={{color: '#D38106'}}>{intl.formatMessage({id: "not_apply_requests_deleted"})}</b>}
              )}
              </span>
            </div>
          )}
          <span className="ModalApplyNotApply__question">{intl.formatMessage({id: applying ? 'apply_question' : 'not_apply_question'})}</span>
      </div>
      ) : null}
    />
  )
};

export default ModalApplyNotApply;
