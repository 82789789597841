// Based on https://stackoverflow.com/a/55285375
const ABBREVIATIONS = {
  'bytes': [
    { value: 1e12, symbol: 'TB' },
    { value: 1e9, symbol: 'GB' },
    { value: 1e6, symbol: 'MB' },
    { value: 1e3, symbol: 'KB' },
    { value: 1, symbol: 'B' },
  ],
  'es': [
    { value: 1e12, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    //{ value: 1e3, symbol: 'K' }
  ],
  'es-ES': [
    { value: 1e12, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    //{ value: 1e3, symbol: 'K' }
  ],
  'pt-PT': [
    { value: 1e12, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    //{ value: 1e3, symbol: 'K' }
  ],
  'fr': [
    { value: 1e12, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    //{ value: 1e3, symbol: 'K' }
  ],
  'fr-FR': [
    { value: 1e12, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    //{ value: 1e3, symbol: 'K' }
  ],
  'ca': [
    { value: 1e12, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    //{ value: 1e3, symbol: 'K' }
  ],
  'ca-ES': [
    { value: 1e12, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    //{ value: 1e3, symbol: 'K' }
  ],
  default: [
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'B' },
    { value: 1e6, symbol: 'M' },
    //{ value: 1e3, symbol: 'K' }
  ],
};

// NOTICE: This is a workaround because UNICODE says 1000 in es-ES is '1000' but popular knowledge has it as '1.000'
const INTL_LOCALE_MAP = {
  'es': 'de-DE',
  'es-ES': 'de-DE',
  'pt': 'cs-CZ',
  'pt-PT': 'cs-CZ',
  'pl': 'cs-CZ',
  'pl-PL': 'cs-CZ',
  'fr': 'cs-CZ',
  'fr-FR': 'cs-CZ',
  'fr-CH': 'cs-CZ',
};

const formatNumberWithIntl = (intl) => (
  _num,
  opts,
) => {
  let num;
  try {
    num = Number(_num);
  } catch(err) {
    num = null;
  }

  if (num == null || typeof num !== 'number' || isNaN(num)) {
    // null, undefined, non-numeric, return what was provided
    return _num;
  }

  const locale = intl.locale || 'en-US';
  return num.toLocaleString(
    (INTL_LOCALE_MAP[locale] || locale),
    opts,
  );
};

const formatNumberAbbr = (
  num,
  {
    locale = 'en-US',
    minimumFractionDigits = undefined,
    maximumFractionDigits = undefined,
  },
) => {
  if (num == null || typeof num !== 'number') {
    // null, undefined, non-numeric, return what was provided
    return num;
  }

  const abbreviations = ABBREVIATIONS[locale] || ABBREVIATIONS[locale.slice(0, 2)] || ABBREVIATIONS.default;
  const format = abbreviations.find(f => num >= f.value)

  if (format != null) {
    const {
      value,
      symbol
    } = format;

    const formatted = (num / value).toLocaleString(locale, {
      minimumFractionDigits,
      maximumFractionDigits
    });
    const parts = formatted.match(/([\D]*)([\d.,]+)([\D]*)/)
    return `${parts[1]}${parts[2]}${symbol}${parts[3]}`
  }

  return num.toLocaleString(locale, {
    minimumFractionDigits,
    maximumFractionDigits
  });
};

export {
  formatNumberWithIntl,
  formatNumberAbbr,
};

