const TARGET = 'organization_archive';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if(action.target !== TARGET) return state;
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'API_CALL_COMPLETE':
      if(!action.response || action.response.target !== TARGET) return state;
      return {
        ...state,
        data: action.response.result,
        fetching: false,
      };
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        loading: false,
        error: action.code,
      };

    case 'ORG_ARCHIVE:GET':
      return {
        ...state,
        data: action.data
      }
    case 'ORG_ARCHIVE:CREATE':
      return {
        ...state,
        data: [action.data,...state.data],
        fetching:false
      }

    case 'ORG_ARCHIVE:ADD':
      // // Merge and replace objects from 2 arrays based on a key:  https://stackoverflow.com/a/54321701
      // return {
      //   ...state,
      //   data: Object.values(
      //     [].concat(action.data, state.data, action.data)
      //       .reduce((r, c) => (r[c.id] = Object.assign((r[c.id] || {}), c), r), {})
      //  )
      // }
      console.log('org_archive:add ', action.data);
      const indexEl = state.data.flatMap(el => el.id).indexOf(action.data.id);
      console.log('indexEl ', indexEl);
      if (indexEl !== -1 ) {
        // element is already present in store
        const updatedArray = [...state.data];
        updatedArray.splice(indexEl,1,action.data);
        return {
          ...state,
          data: updatedArray
        };

      }else{
        return {
          ...state,
          data: [action.data, ...state.data],
          fetching:false
        }
      }


    case 'ORG_ARCHIVE:DELETE':
      return {
        ...state,
        data: state.data.filter(el => el.id !== action.data.id)
      }

    case 'ORG_ARCHIVE:COMPLETED':
      return {
        ...state,
        data: state.data.map(el => {
          if(el.id === action.data.id){
            el.status = action.data.status
          }
          return el;
        }),
        fetching: false
      }
    
    case 'ORG_ARCHIVE:ACTION':
      return {
        ...state,
        data: state.data.map(el => {
          if(el.id === action.data.id){
            switch(action.data.action) {
              case 'bookmark':
                el.bookmark = action.data.bookmark || false;
                break;
              case 'download':
                el.status = 'downloaded';
                el.downloaded_at = action.data.downloaded_at;
                break;
              default:
                break;
            }
          }
          return el;
        }),
        fetching: false
      }
    
    default:
      return state;
  }
};

export {
  reducer as organization_archive,
};
