const TARGET = 'organization_detail';
const MEMBER_TARGET = 'member';
const ID_FIELD = 'subject_id'; // NOTICE: This is specific for permissions

const isTarget = (target) => [TARGET, MEMBER_TARGET].includes(target);
const isGeneralTarget = (target) => target === TARGET;

const initialState = {
  fetching: false,
  pushing: false,
  data: null,
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'API_CALL_REQUEST':
      if (!isTarget(action.target)) return state;
      return {
        ...state,
        data: action.method === 'GET' ? null : state.data,
        fetching: action.method === 'GET' || state.fetching,
        pushing: action.method !== 'GET' || state.pushing,
      };
    case 'API_CALL_COMPLETE':
      if (!action.response || !isTarget(action.response.target)) return state;
      if(isGeneralTarget(action.response.target)) {
        return {
          ...state,
          data: action.response.result,
          fetching: false,
          error: null
        };
      }

      const targetMember = action.response.result;
      if(!targetMember || !['POST', 'PUT', 'DELETE'].includes(action.response.method) || !state.data || !state.data.users) {
        // This is not a request for me, bailing out
        return state;
      }

      const index = state.data.users.findIndex((user) => user[ID_FIELD] === targetMember[ID_FIELD]);
      if(index >= 0 || action.response.method === 'POST') {
        let users;
        switch(action.response.method) {
          case 'POST':
            users = index >= 0 ?
              [ targetMember, ...state.data.users.slice(0, index), ...state.data.users.slice(index+1) ] :
              [ targetMember, ...state.data.users ];
            break;
          case 'DELETE':
            users = [ ...state.data.users.slice(0, index), ...state.data.users.slice(index+1) ];
            break;
          default:
            users = [ ...state.data.users.slice(0, index), targetMember, ...state.data.users.slice(index+1) ];
            break;
        }

        return {
          ...state,
          data: {
            ...state.data,
            users,
          },
          pushing: false,
          error: null,
        };
      } else {
        return {
          ...state,
          pushing: false,
          error: null,
        };
      }

    case 'API_CALL_FAILED':
      if (!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state,
        fetching: false,
        pushing: false,
        error: action.code
      };
    case 'REFRESH_TOKEN_FAILED':
      return initialState;
    default:
      return state;
  }
};

export { reducer as organization_detail };
