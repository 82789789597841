import React, { useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { Line } from 'react-chartjs-2';

import {
  getYear,
  fromDbDate,
} from 'utils/date';

import {
  Row,
  Col,
  Empty,
} from 'antd';

import './style.less';
import CustomSelect from 'components/CustomSelect';
import { defaultColors } from 'components/Dashboard/useColors';
import CustomChart from 'components/CustomChart';


const Quartiles = injectIntl(({
  intl,
  title,
  value,
}) => {
  const t = intl.messages;

  const graphData = {
    labels: [
      t.equality_diagnosis_salary_audit_graphs_quartile_min,
      t.equality_diagnosis_salary_audit_graphs_quartile_q1,
      t.equality_diagnosis_salary_audit_graphs_quartile_med,
      t.equality_diagnosis_salary_audit_graphs_quartile_q3,
      t.equality_diagnosis_salary_audit_graphs_quartile_max,
    ],
    datasets: [{
      label: t.equality_diagnosis_salary_audit_gender_male,
      backgroundColor: defaultColors[0],
      borderColor: defaultColors[0],
      fill: false,
      data: [
        'min_salary',
        'qone_salary',
        'med_salary',
        'qthree_salary',
        'max_salary',
      ].map(field => value.male?.[field]?.value),
    }, {
      label: t.equality_diagnosis_salary_audit_gender_female,
      backgroundColor: defaultColors[1],
      borderColor: defaultColors[1],
      fill: false,
      data: [
        'min_salary',
        'qone_salary',
        'med_salary',
        'qthree_salary',
        'max_salary',
      ].map(field => value.female?.[field]?.value),
    }],
  };

  return (
    <Row>
      <CustomChart
        title={title}
        Chart={Line}
        data={graphData}
        height='300px'
        options={{
          maintainAspectRatio: false,
          lineTension: 0,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                
              }
            }
          },
          plugins: {
            legend: {
              align: 'start',
              position: 'bottom',
              labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded'
              }
            },
          },
        }}
      />
    </Row>
  );
});

const SalaryAuditGraphs = ({
  intl,
  plan,
  reportingStartDate,
}) => {
  const t = intl.messages;
  const [period, setPeriod] = useState(getYear(new Date()));

  const periods = useMemo(
    () => {
      const start_year = getYear(fromDbDate(reportingStartDate));
      const end_year = getYear(new Date());

      if(end_year < start_year) {
        return [];
      }

      const years = new Array(end_year - start_year + 1)
        .fill()
        .map((_, offset) => start_year + offset);
      return years.map(year => ({name: year, slug: year}));
    },
    [reportingStartDate]
  );

  const data = useMemo(
    () => {
      if (!period) {
        return {};
      }

      const salaryAuditData = plan?.salary_audit_data || {};
      let responsibilityLevels = {};
      let jobPositions = {};
      const selectedPeriod = period.toString();

      const responsibilityDataFemale = salaryAuditData.responsibility_level_female
        ?.find(value => value.period === selectedPeriod)?.value;
      const responsibilityDataMale = salaryAuditData.responsibility_level_male
        ?.find(value => value.period === selectedPeriod)?.value;

      Object.keys(responsibilityDataFemale || {}).forEach(level => {
        responsibilityLevels[level] = {
          ...(responsibilityLevels[level] || {}),
          female: responsibilityDataFemale[level],
        };
      });
      Object.keys(responsibilityDataMale || {}).forEach(level => {
        responsibilityLevels[level] = {
          ...(responsibilityLevels[level] || {}),
          male: responsibilityDataMale[level],
        };
      });

      const jobPositionDataFemale = salaryAuditData.job_position_female
        ?.find(value => value.period === selectedPeriod)?.value;
      const jobPositionDataMale = salaryAuditData.job_position_male
        ?.find(value => value.period === selectedPeriod)?.value;

      Object.keys(jobPositionDataFemale || {}).forEach(jobPosition => {
        jobPositions[jobPosition] = {
          ...(jobPositions[jobPosition] || {}),
          female: jobPositionDataFemale[jobPosition],
        };
      });
      Object.keys(jobPositionDataMale || {}).forEach(jobPosition => {
        jobPositions[jobPosition] = {
          ...(jobPositions[jobPosition] || {}),
          male: jobPositionDataMale[jobPosition],
        };
      });

      return {
        responsibilityLevels,
        jobPositions,
      };
    },
    [period, plan]
  );

  return (
    <div className="SalaryAuditGraphs">
      <Row
        type='flex'
        justify='center'
      >
        <Col span={5}>
          <CustomSelect
            title={"Select period"}
            selected={period}
            options={periods}
            onSelect={setPeriod}
          />
        </Col>
      </Row>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <h2>{t.equality_diagnosis_salary_audit_graphs_responsibility}</h2>
        </Col>
      </Row>
      {Object.keys(data.responsibilityLevels).length === 0 &&
        <Empty
          image={'/images/icon-empty-data.svg'}
          description={t.no_data}
        />
      }
      {Object.entries(data.responsibilityLevels).map(([level, value]) => {
        return (
          <React.Fragment key={level}>
            <Row type="flex" justify="center" className="SalaryAuditGraphs-title">
              <h3>{level}</h3>
            </Row>
            <Row type="flex" justify="center">
              <Col span={16}>
                <Quartiles value={value} title={level} />
              </Col>
            </Row>
          </React.Fragment>
        );
      })}
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <h2>{t.equality_diagnosis_salary_audit_graphs_position}</h2>
        </Col>
      </Row>
      {Object.keys(data.jobPositions).length === 0 &&
        <Empty
          image={'/images/icon-empty-data.svg'}
          description={t.no_data}
        />
      }
      {Object.entries(data.jobPositions).map(([jobPosition, value]) => {
        return (
          <React.Fragment key={jobPosition}>
            <Row type="flex" justify="center" className="SalaryAuditGraphs-title">
              <h3>{jobPosition}</h3>
            </Row>
            <Row type="flex" justify="center">
              <Col span={16}>
                <Quartiles value={value} />
              </Col>
            </Row>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default injectIntl(SalaryAuditGraphs);
