import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';

import { formatNumberAbbr } from 'utils/formatNumber';
import { useAvailableUnits } from 'utils/useAvailableUnits';
import DataTargetIcon from 'components/DataTargets/DataTargetIcon';

const PERCENT_REPLACER = '#PERCENT#';
const PERIOD_REPLACER = '#PERIOD#';
const DEFAULT_PARAMETERS = {
  key: 'value',
  compareKey: 'compare',
  periodKey: 'period',
  comparePeriodKey: 'compare_period',
  compareDecimals: 0,
  decimals: 0,
  suffix: '',
  prefix: '',
  sameValueMsg: 'roughly the same',
  increaseMsg: `since ${PERIOD_REPLACER}`,
  decreaseMsg: `since ${PERIOD_REPLACER}`,
  increaseByMsg: `by ${PERCENT_REPLACER}% since ${PERIOD_REPLACER}`,
  decreaseByMsg: `by ${PERCENT_REPLACER}% since ${PERIOD_REPLACER}`,
  locale: undefined,
  schema: {},
  showDataTarget: false
};

const SingleNumber = React.forwardRef(({ parameters = DEFAULT_PARAMETERS, data }, ref) => {

  const intl = useIntl();
  
  const {
    key = DEFAULT_PARAMETERS.key,
    compareKey = DEFAULT_PARAMETERS.compareKey,
    //periodKey = DEFAULT_PARAMETERS.periodKey,
    comparePeriodKey = DEFAULT_PARAMETERS.comparePeriodKey,
    compareDecimals = DEFAULT_PARAMETERS.compareDecimals,
    decimals = DEFAULT_PARAMETERS.decimals,
    suffix = DEFAULT_PARAMETERS.suffix,
    prefix = DEFAULT_PARAMETERS.prefix,
    sameValueMsg = DEFAULT_PARAMETERS.sameValueMsg,
    increaseMsg = DEFAULT_PARAMETERS.increaseMsg,
    decreaseMsg = DEFAULT_PARAMETERS.decreaseMsg,
    increaseByMsg = DEFAULT_PARAMETERS.increaseByMsg,
    decreaseByMsg = DEFAULT_PARAMETERS.decreaseByMsg,
    nodata = intl.messages.no_data,
    locale = DEFAULT_PARAMETERS.locale,
    schema = DEFAULT_PARAMETERS.schema,
    showDataTarget = DEFAULT_PARAMETERS.showDataTarget
  } = parameters;

  const maybeAvailableUnits = useAvailableUnits(schema);
  const availableUnits = useMemo(() => maybeAvailableUnits || [], [maybeAvailableUnits]);

  const unitSymbol = useMemo(() => (
    availableUnits.length ? availableUnits[0].symbol : ''
  ), [
    availableUnits,
  ]);

  const currentValue = useMemo(() => {
    if(!data[0] || typeof data[0][key].value === 'undefined') {
      return null;
    }
    return `${prefix}${ formatNumberAbbr(Number(data[0][key].value), { locale, minimumFractionDigits: decimals }) }${suffix}`;
  }, [ data, key, prefix, suffix, decimals, locale ]);

  const currentTarget = useMemo(() => {
    if(!showDataTarget || !data[0] || typeof data[0][key].target === 'undefined') {
      return null;
    }
    return `${prefix}${ formatNumberAbbr(Number(data[0][key].target), { locale, minimumFractionDigits: decimals }) }${suffix}`;
  }, [ showDataTarget, data, key, prefix, suffix, decimals, locale ]);

  const comparison = useMemo(() => {

    if(!data[0] || typeof data[0][compareKey] === 'undefined' || data[0][compareKey].value === null) {
      return null;
    }

    const currentValue = Number(data[0][key].value);
    //const currentPeriod = data[0][periodKey];
    const compareValue = Number(data[0][compareKey].value);
    const comparePeriod = data[0][comparePeriodKey];

    const threshold = 1 / (10 ** decimals+1);

    const isEqual = Math.abs(currentValue - compareValue) < threshold;
    const isIncrease = currentValue > compareValue;
    const icon = isEqual ? 'equals' : ( isIncrease ? 'arrow-up' : 'arrow-down' );
    const className = isEqual ?
      'compare_equal' :
      (
        isIncrease ?
        'compare_greater' :
        'compare_lower'
      );

    const percent = Math.abs( 100.0 * ( currentValue - compareValue ) / compareValue ).toFixed(compareDecimals);
    const text = (
      isEqual
      ? sameValueMsg
      : (
        isIncrease
        ? (compareValue ? increaseByMsg : increaseMsg)
        : (compareValue ? decreaseByMsg : decreaseMsg)
      )
    ).replace(PERCENT_REPLACER, percent).replace(PERIOD_REPLACER, comparePeriod);

    return {
      icon,
      className,
      text,
    };
  }, [
    compareKey,
    key,
    data,
    compareDecimals,
    comparePeriodKey,
    decimals,
    decreaseMsg,
    increaseMsg,
    decreaseByMsg,
    increaseByMsg,
    //periodKey,
    sameValueMsg,
  ]);

  return (
    <div className="DashboardComponent__card_content">
      {
        !currentValue
        ?
          <div
            className="DashboardComponent__nodata"
          >
            { nodata }
          </div>
        :
          <div
            className="DashboardComponent__singlenumber"
          >
            <output>
              { currentValue }
              <div className={`unit_${unitSymbol.length > 5 ? 'big' : 'small'}`}>
                { unitSymbol }
              </div>
            </output>
            {
              !comparison ? null :
              <div
                className={comparison.className}
              >
                <FontAwesomeIcon
                  icon={comparison.icon}
                  size="sm"
                />
                { ' ' }
                { comparison.text }
              </div>
            }
            {
                showDataTarget && currentTarget && 
                <div className='DashboardComponent__singlenumber__target'>
                  <span className='singlenumber_target__icon'>
                    <DataTargetIcon />
                  </span>
                  { currentTarget } 
                  <span className={`unit_${unitSymbol.length > 5 ? 'big' : 'small'}`}>
                    { unitSymbol }
                  </span>
                </div>
              }
          </div>
      }
    </div>
  );
});

SingleNumber.propTypes = {
  parameters: PropTypes.object,
  data: PropTypes.arrayOf( PropTypes.object ),
};

export default SingleNumber;

