import React from 'react';
import { injectIntl } from 'react-intl';

import Show from './Show';
import Edit from './Edit';
import {Row, Col } from 'antd';


const NO_OP = () => {};

const BooleanAnswer = ({
  intl,
  value,
  previous_value,
  previous_comment,
  previous_attachments,
  comment,
  source,
  source_params,
  schema,
  edit,
  loading,
  onEdit = NO_OP,
  onSave = NO_OP,
  onClear = NO_OP,
  onCancel = NO_OP,
  onShowLastPeriod = NO_OP,
  onUseLastValue = NO_OP,
  onUseLastWholeData = NO_OP,
  onUseLastAttachments = NO_OP,
  onUseLastPeriod = NO_OP,
  canWrite = false,
  target,
  onTarget,
  config
}) => {
  const t = intl.messages;

  return (
    <section className='KpiDetail__answer'>
      <Row>
        <Col className='KpiDetail__title' span={6}>{ t.answer }</Col>
        {target && 
          <Col span={6} offset={2}>
            <div className='KpiDetail__title'>{t.kpi_detail_target_answer_title}</div>
        </Col>         
        }
      </Row>

      {
        edit && canWrite
          ? <Edit
              source={source}
              source_params={source_params}
              schema={schema}
              value={value}
              comment={comment}
              onSave={onSave}
              onClear={onClear}
              onCancel={onCancel}
              previous_value={previous_value}
              previous_comment={previous_comment}
              previous_attachments={previous_attachments}
              onShowLastPeriod={onShowLastPeriod}
              onUseLastValue={onUseLastValue}
              onUseLastWholeData={onUseLastWholeData}
              onUseLastAttachments={onUseLastAttachments}
              onUseLastPeriod={onUseLastPeriod}
              target={target}
              onTarget={onTarget}
              config={config}
            />
          : <Show
              source={source}
              source_params={source_params}
              schema={schema}
              value={value}
              comment={comment}
              previous_value={previous_value}
              previous_comment={previous_comment}
              previous_attachments={previous_attachments}
              onEdit={onEdit}
              onShowLastPeriod={onShowLastPeriod}
              onUseLastValue={onUseLastValue}
              onUseLastWholeData={onUseLastWholeData}
              onUseLastAttachments={onUseLastAttachments}
              onUseLastPeriod={onUseLastPeriod}
              canWrite={canWrite}
              target={target}
              onTarget={onTarget}
              config={config}
            />
      }
    </section>
  );
}

BooleanAnswer.Show = Show.Component;
BooleanAnswer.Edit = Edit.Component;
BooleanAnswer.TableShow = Show.TableComponent;
BooleanAnswer.TableEdit = Edit.TableComponent;
BooleanAnswer.Summary = Show.SummaryComponent;

export default injectIntl(BooleanAnswer);
