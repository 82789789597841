import _ from 'lodash';

const TARGET = 'screen_filters';
const isTarget = (target) => target === TARGET;

const initialState = {};

const reducer = (state = initialState, action) => {
  const screenFilters = state[action.screen] || {}; 
  switch (action.type) {
    case 'SET_STATE_TOGGLE':
      if(!isTarget(action.target)) {
        return state;
      }
      const filterState = screenFilters[action.filter] || action.defaultValue;
      const filterValue = Array.isArray(filterState) ?
      filterState.includes(action.param) ? filterState.filter((e) => e !== action.param) : filterState.concat(action.param)
      : action.param;
      return {
        ...state,
        [action.screen]: {
          ...screenFilters,
          [action.filter]: filterValue
        }
      };
    case 'SET_STATE_REPLACE_ALL':
      if(!isTarget(action.target)) {
        return state;
      }
      return {
        ...state,
        [action.screen]: {
          ...screenFilters,
          [action.filter]: typeof action.param === 'undefined' ? [] : action.param
        }
      };
    case 'SET_ALL':
      if(!isTarget(action.target)) {
        return state;
      }
      return {
        ...state,
        [action.screen]: {
          ...action.param
        }
      };
    case 'RESET_ALL':
      if(!isTarget(action.target)) {
        return state;
      }
      if (action.param) {
        return {
          ...state,
          [action.screen]: {
            ...action.param
          }
        };
      }
      return _.omit(state, action.screen);
    case 'ORGANIZATION_RESET':
    case 'LOGOUT_REQUEST':
      return initialState;
    case 'HYDRATE_STORE':
      let filters = action.state && action.state.screen_filters;

      if (typeof filters?.fill_and_report?.kpis?.[0] === 'object') {
        // Backward compatibility for selected KPIs in fill and report page
        filters.fill_and_report.kpis = filters.fill_and_report.kpis.map(({uuid}) => uuid);
      }
      return filters || state;
    default:
      return state;
  }
};

export { reducer as screen_filters };
