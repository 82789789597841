import React, { useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';

import {
  Row,
  Col,
  Form,
  InputNumber,
  Input,
  Modal,
} from 'antd';

import { beneficiaryByCategoryValidation as validation } from './validation';
import useForm from 'utils/useForm';

import './style.less';


const BeneficiariesByCategoryForm = ({
  intl,
  record,
  index,
  onSave,
  onCancel,
  visible,
}) => {
  const t = intl.messages;
  const defaults = record;
  const validateForm = useMemo(() => validation(t), [ t ]);

  const submitForm = () => {
    const {
      category, male, female,
    } = values;
    onSave(
      'results', 'beneficiaries_by_category', index, {
        category, male, female,
      }
    );
  };

  const {
    values,
    handleChange,
    handleChangeEvent,
    handleSubmit,
    isDirty,
    errors,
  } = useForm({
    callback: submitForm,
    validate: validateForm,
    defaultValues: defaults,
  });

  const getValue = useCallback(
    (name) => (typeof values[name] === 'undefined' ? defaults[name] : values[name]),
    [values, defaults]
  );
  const showError = useCallback(
    (name) => (!isDirty(name) && errors[name]) || '',
    [isDirty, errors]
  );

  return (
    <Modal
      title={t.equality_evaluation_of_results_beneficiaries_by_category_add}
      visible={visible}
      onOk={handleSubmit}
      onCancel={() => onCancel('results', 'beneficiaries_by_category', index)}
      okText={t.save}
      cancelText={t.cancel}
    >
      <Row>
        <Col span={24} className="form-input-wrapper">
          <Row gutter={10}>
            <Col span={24}>
              <Form.Item
                label={t.equality_evaluation_of_results_category}
                hasFeedback
                validateStatus={ showError('category') ? 'error' : '' }
                help={ showError('category') }
                colon={false}
                required
              >
                <Input
                  name="category"
                  value={ getValue('category') }
                  onChange={handleChangeEvent}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('female') ? 'error' : '' }
                help={ showError('female') }
                label={ t.equality_evaluation_of_results_beneficiaries_female }
                colon={false}
                required
              >
                <InputNumber
                  name="female"
                  value={ getValue('female') }
                  onChange={(value) => handleChange('female')(value)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                hasFeedback
                validateStatus={ showError('male') ? 'error' : '' }
                help={ showError('male') }
                label={ t.equality_evaluation_of_results_beneficiaries_male }
                colon={false}
                required
              >
                <InputNumber
                  name="male"
                  value={ getValue('male') }
                  onChange={(value) => handleChange('male')(value)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
};

export default injectIntl(BeneficiariesByCategoryForm);
