import {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  refreshAccessToken as _refreshAccessToken,
} from 'actions/auth';

// To use in <AuthorizedLink>
// TODO: progress
// TODO: abort

const NO_OP = () => {};

const useAuthorizedRequest = (params) => {
  const linkRef = useRef(null);

  const dispatch = useDispatch();
  const api_requests = useSelector(state => state.api_requests);
  const refreshAccessToken = useCallback(() => dispatch(_refreshAccessToken()), [ dispatch ]);

  const { access_token, refreshing_tokens } = api_requests;

  const [authHeader, setAuthHeader] = useState(`Bearer ${access_token}`);
  const [started, setStarted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //const [file, setFile] = useState(null);
  useEffect(() => {
    setAuthHeader(`Bearer ${access_token}`);
    if(started && !success) {
      // NOTICE: We do NOT relaunch on changed params
      launchRequest(params);
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    access_token
  ]);

  const launchRequest = useCallback(
    (
      {
        url,
        method = 'GET',
        body = null,
        onSuccess = NO_OP,
        onError = NO_OP,
      }
    ) => {
      setStarted(true);
      setError(false);
      setSuccess(false);
      return fetch(url, {
        method,
        headers: {
          'Authorization': authHeader,
        },
        body,
      }).then(response => {
        if(response.ok) {
          return response.blob(); // This was response.text() before
        }
        if(response.status === 401) {
          if(!refreshing_tokens) {
            refreshAccessToken();
          }
          // if no error => we are retrying
          return Promise.reject(false);
        }
        return Promise.reject(response.statusText);
      })
      .then(data => {
        setStarted(false);
        setSuccess(true);
        onSuccess(data);
      })
      .catch(error => {
        // if no error => we are retrying
        if(error) {
          setError(true);
          onError(error);
        }
      });
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    authHeader
  ]);

  const handleClick = useCallback((params) => () => {
    if(!started) {
      launchRequest(params);
    }
  }, [
    started,
    launchRequest,
  ]);

  const authorizedRequest = useMemo(() => {
    return {
      onClick: handleClick(params),
      linkRef,
      started,
      success,
      error,
    };
  }, [
    params,
    handleClick,
    started,
    success,
    error,
  ]);

  return authorizedRequest;
};
export default useAuthorizedRequest;

