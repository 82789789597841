import React from 'react';

import {
  Row,
  Col,
} from 'antd';

import SdgLogo from 'components/SdgLogo';

const KPI_SDG_MAP = {
  'sdg1-score': "no-poverty",
  'sdg2-score': "zero-hunger",
  'sdg3-score': "good-health-and-wellbeing",
  'sdg4-score': "quality-education",
  'sdg5-score': "gender-equality",
  'sdg6-score': "clean-water-and-sanitation",
  'sdg7-score': "affordable-and-clean-energy",
  'sdg8-score': "decent-work-and-economic-growth",
  'sdg9-score': "industry-innovation-and-infrastructure",
  'sdg10-score': "reduced-inequalities",
  'sdg11-score': "sustainable-cities-and-communities",
  'sdg12-score': "responsible-consumption-and-production",
  'sdg13-score': "climate-action",
  'sdg14-score': "life-below-water",
  'sdg15-score': "life-on-land",
  'sdg16-score': "peace-justice-and-strong-institutions",
  'sdg17-score': "partnership-for-the-goals"
};

const getScoreTagColor = (score) => {
  if(score < 1) {
    return '#EC1C2E';
  }
  if(score >= 1 && score <= 1.5) {
    return '#F36C25';
  }
  if(score >= 1.5 && score <= 2) {
    return '#CF8D2A';
  }
  if(score >= 2) {
    return '#48763E';
  }
  return '#7F7F7F';
};

const SDGScore = ({
  data,
}) => {
  return (
    <Row type="flex" justify="center" gutter={[14, 20]} className="SDGScore">
      {Object.entries(KPI_SDG_MAP).map(([kpiSlug, sdgSlug]) => (
        <Col key={kpiSlug}>
          <SdgLogo sdg={KPI_SDG_MAP[kpiSlug]} />
          <div className="score" style={{ backgroundColor: getScoreTagColor(data[kpiSlug])}}>
            {data[kpiSlug] ? data[kpiSlug].toFixed(2) : 'N/A'}
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default SDGScore;

