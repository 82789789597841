const TARGET = 'kpi_variable';
const STATUS_TARGET = 'kpi_variable_status';

const isTarget = (target) => [
  TARGET,
  STATUS_TARGET,
].includes(target);

const initialState = {
  error: null,
  fetching: false,
  pushing: false,
  data: null,
  fetchingStatus: false,
  status: {},
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if (!isTarget(action.target)) return state;
      return {
        ...state,
        error: null,
        fetching: action.method === 'GET' && action.target === TARGET,
        fetchingStatus: action.method === 'GET' && action.target === STATUS_TARGET,
        pushing: ['PUT', 'POST', 'DELETE'].includes(action.method),
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || !isTarget(response.target)) return state;
      const result = response.result;
      switch(response.method) {
        case 'GET':
          if (response.target === STATUS_TARGET) {
            return {
              ...state,
              status: {
                ...state.status,
                [result.slug]: {
                  is_used: result.is_used,
                  number_of_kpis: result.number_of_kpis,
                },
              },
              fetchingStatus: false,
            };
          }
          return {
            ...state,
            fetching: false,
            data: result,
          };
        case 'POST':
          let data = [
            ...(state.data || []),
            result,
          ];

          if (result.default_variable_id) {
            data = data.filter(({id}) => id !== result.default_variable_id);
          }
          return {
            ...state,
            pushing: false,
            data,
          };
        case 'PUT':
          const index = state.data.map(
            variable => variable.id
          ).indexOf(parseInt(result.id));
          return {
            ...state,
            pushing: false,
            data: [
              ...state.data.slice(0, index),
              result,
              ...state.data.slice(index + 1)
            ],
          };
        case 'DELETE':
          let updatedData = [...state.data.filter(({slug}) => slug !== action.response.variable_slug)];

          if (result?.id) {
            // If a custom variable was deleted, we need to restore the default variable
            updatedData.push(result);
          }

          return {
            ...state,
            pushing: false,
            data: updatedData,
          };
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || !isTarget(action.request.target)) return state;
      return {
        ...state,
        fetching: false,
        fetchingStatus: false,
        pushing: false,
        error: action.code,
      };
    default:
      return state;
  }
};

export {
  reducer as kpi_variable,
};
