import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { chain } from 'lodash';

import useOrganizations from 'utils/useOrganizations';

import MainLayout from 'components/MainLayout';
import SurveySelectRecipients from 'components/SurveySelectRecipients';
import SurveySelectSubject from 'components/SurveySelectSubject';
import SurveyEmailForm from 'components/SurveyEmailForm';
import SurveyResults from 'components/SurveyResults';
import GroupsOfInterest from 'components/GroupsOfInterest';
import { Loading } from 'tsComponents/emptyStates/Loading';
import CustomButton from 'components/CustomButton';

import {
  Tabs,
  Row,
} from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';

import {
  requestGroupsOfInterest,
  addSurveyRecipient,
  updateSurveyRecipient,
  deleteSurveyRecipient,
  requestSurvey,
  requestAnalysisSubjects,
  saveSurveySubjects,
  requestSurveySubjects,
  sendSurveyEmail,
  requestSurveyResponses,
  createGroupOfInterest,
  deleteGroupOfInterest,
  editGroupOfInterest,
  createGoiMember,
  editGoiMember,
  deleteGoiMember,
  closeSurvey,
  sendSurveyRecipientEmail
} from '../../actions/api';
import { changeCurrentGroup } from 'actions/goi';
import { flattenTree } from 'utils/tree';

import './style.less';

const { TabPane } = Tabs;

const TABS = {
  'select_recipients': '0',
  'choose_subjects': '1',
  'send_survey': '2',
  'results': '3',
};

const SurveyBuilder = ({
  intl,
  match,
  history,
  requestGroupsOfInterest,
  addSurveyRecipient,
  updateSurveyRecipient,
  deleteSurveyRecipient,
  requestSurvey,
  groups,
  group,
  recipients,
  requestAnalysisSubjects,
  subjects: unprocessedSubjects,
  surveySubjects,
  saveSurveySubjects,
  requestSurveySubjects,
  sendSurveyEmail,
  requestSurveyResponses,
  responses,
  createGroupOfInterest,
  deleteGroupOfInterest,
  editGroupOfInterest,
  createGoiMember,
  editGoiMember,
  deleteGoiMember,
  changeCurrentGroup,
  closeSurvey,
  survey,
  sendSurveyRecipientEmail,
  sendingSurveyEmail,
}) => {
  const t = intl.messages;
  const { organization, suborganization } = useOrganizations();
  const { analysisId, surveyId, stage } = match.params;

  const {
    slug: mainOrgSlug,
  } = organization;
  const {
    slug: subOrgSlug,
  } = suborganization;

  const changeTab = useCallback(
    tab => {
      const tabKey = Object.keys(TABS).find(key => TABS[key] === tab)
      history.push(`${tabKey}`);
    },
    [history]
  );

  const isSurveyClosed = useMemo(
    () => survey ? survey.status === 'close' : false,
    [survey]
  );
  const [activeTabKey, setActiveTabKey] = useState(TABS[stage]);

  const subjects = useMemo(
    () => {
      return unprocessedSubjects?.map(category => {
        const kpis = Object.values(
          flattenTree(category)
        )
        .map(({ kpis }) => kpis || [])
        .reduce((arr, el) => arr.concat(el), []);

        return {
          ...category,
          esg_types: chain(kpis.map(kpi => kpi.esgs)).flatten().uniq().compact().value(),
          sdgs: chain(kpis.map(kpi => kpi.sdgs)).flatten().uniq().compact().value(),
          standards: chain(kpis.map(kpi => Object.values(kpi.standard_info).map(({standard}) => standard))).flatten().uniq().compact().value(),
        };
      });
    },
    [unprocessedSubjects]
  );

  const onRequestAnalysisSubjects = useCallback(
    (filter) => {
      requestAnalysisSubjects(mainOrgSlug, subOrgSlug, analysisId, filter);
    },
    [requestAnalysisSubjects, mainOrgSlug, subOrgSlug, analysisId]
  );

  const onSaveSurveySubjects = useCallback(
    (toAdd, toRemove) => {
      saveSurveySubjects(
        mainOrgSlug, subOrgSlug, analysisId, surveyId,
        {toAdd, toRemove}
      );
      changeTab(TABS.send_survey);
    },
    [
      saveSurveySubjects, mainOrgSlug, subOrgSlug, analysisId,
      surveyId, changeTab
    ]
  );

  const onSendSurveyEmail = useCallback(
    (data) => {
      sendSurveyEmail(mainOrgSlug, subOrgSlug, analysisId, surveyId, data);
      changeTab(TABS.results);
    },
    [
      sendSurveyEmail, mainOrgSlug, subOrgSlug, analysisId,
      surveyId, changeTab
    ]
  );

  const onSendSurveyRecipientEmail = useCallback(
    (recipientId) => sendSurveyRecipientEmail(
      mainOrgSlug, subOrgSlug, analysisId, surveyId, recipientId
    ),
    [
      sendSurveyRecipientEmail, mainOrgSlug, subOrgSlug, analysisId, surveyId
    ]
  );

  const onCloseSurvey = useCallback(
    () => {
      closeSurvey(mainOrgSlug, subOrgSlug, analysisId, surveyId);
    },
    [
      closeSurvey, mainOrgSlug, subOrgSlug, analysisId, surveyId
    ]
  );

  const onRequestSurveyResponses = useCallback(
    () => {
      requestSurveyResponses(mainOrgSlug, subOrgSlug, analysisId, surveyId);
    },
    [requestSurveyResponses, mainOrgSlug, subOrgSlug, analysisId, surveyId],
  )

  useEffect(() => {
    requestGroupsOfInterest(mainOrgSlug, subOrgSlug);
  }, [mainOrgSlug, requestGroupsOfInterest, subOrgSlug]);

  useEffect(() => {
    requestSurvey(mainOrgSlug, subOrgSlug, analysisId, surveyId);
  }, [
    requestSurvey, mainOrgSlug, subOrgSlug, analysisId, surveyId
  ]);

  useEffect(() => {
    requestAnalysisSubjects(mainOrgSlug, subOrgSlug, analysisId);
  }, [mainOrgSlug, subOrgSlug, analysisId, requestAnalysisSubjects]);

  useEffect(() => {
    requestSurveySubjects(mainOrgSlug, subOrgSlug, analysisId, surveyId);
  }, [mainOrgSlug, subOrgSlug, analysisId, surveyId, requestSurveySubjects]);

  useEffect(() => {
    setActiveTabKey(TABS[stage || 'select_recipients']);
  },
    [stage]
  );

  const emailContext = useMemo(
    () => survey ? {
      subject: survey.email_subject,
      email: survey.email_content
    } : {},
    [survey]
  );

  const enabledReports = useMemo(() => {
    return [
      ...suborganization.product_config?.atlas?.enabled_reports || [],
      'custom',
    ];
  }, [
    suborganization,
  ]);

  return (
    <Fragment>
      <MainLayout>
        <Row type="flex" justify="start">
          <CustomButton
            type="primary"
            icon={<ArrowLeftOutlined />}
            onClick={() => history.push(`/analysis/${analysisId}/${survey.type}`)}
          >
            { t.back }
          </CustomButton>
        </Row>
        { surveySubjects && subjects
          ? <Tabs activeKey={activeTabKey} onChange={changeTab} tabBarStyle={{textAlign: 'center'}} disabled>
              <TabPane
                tab={t.select_recipients}
                key={TABS.select_recipients}
                disabled={isSurveyClosed}
              >
                <Tabs tabBarStyle={{textAlign: 'center'}}>
                  <TabPane tab={t.select_recipients_from_goi} key="select-recipients">
                    <SurveySelectRecipients
                      addRecipient={(body) => addSurveyRecipient(mainOrgSlug, subOrgSlug, analysisId, surveyId, body)}
                      updateRecipient={(body) => updateSurveyRecipient(mainOrgSlug, subOrgSlug, analysisId, surveyId, body)}
                      deleteRecipient={(body) => deleteSurveyRecipient(mainOrgSlug, subOrgSlug, analysisId, surveyId, body)}
                      groups={groups}
                      recipients={recipients}
                    />
                    <Row type="flex" justify="end" className='survey-actions'>
                      <CustomButton
                        type="primary"
                        onClick={() => changeTab(TABS.choose_subjects)}>
                        { t.next }
                      </CustomButton>
                    </Row>
                  </TabPane>
                  <TabPane tab={t.manage_goi} key="manage-goi">
                    <GroupsOfInterest
                      requestGroupsOfInterest={requestGroupsOfInterest}
                      createGroupOfInterest={createGroupOfInterest}
                      deleteGroupOfInterest={deleteGroupOfInterest}
                      editGroupOfInterest={editGroupOfInterest}
                      createGoiMember={createGoiMember}
                      editGoiMember={editGoiMember}
                      deleteGoiMember={deleteGoiMember}
                      changeCurrentGroup={changeCurrentGroup}
                      groups={groups}
                      group={group}
                    />
                  </TabPane>
                </Tabs>
              </TabPane>
              <TabPane
                tab={t.choose_subjects}
                key={TABS.choose_subjects}
                disabled={
                  isSurveyClosed
                  || (survey && survey.recipients.length > 0 ? true : false)
                }
              >
                <SurveySelectSubject
                  mainOrgSlug={mainOrgSlug}
                  subjects={subjects}
                  surveySubjects={surveySubjects}
                  requestAnalysisSubjects={onRequestAnalysisSubjects}
                  saveSurveySubjects={onSaveSurveySubjects}
                  enabledReports={enabledReports}
                />
              </TabPane>
              <TabPane
                tab={t.send_survey}
                key={TABS.send_survey}
                disabled={isSurveyClosed || surveySubjects.length <= 0 || survey.recipients.length > 0}
              >
                <SurveyEmailForm
                  recipients={recipients}
                  surveySubjects={surveySubjects}
                  sendSurveyEmail={onSendSurveyEmail}
                  emailContext={emailContext}
                />
              </TabPane>
              <TabPane
                tab={t.see_results}
                key={TABS.results}
                disabled={surveySubjects.length <= 0}
              >
                <SurveyResults
                  subjects={subjects}
                  surveySubjects={surveySubjects}
                  recipients={recipients}
                  requestSurveyResponses={onRequestSurveyResponses}
                  responses={responses}
                  closeSurvey={onCloseSurvey}
                  isSurveyClosed={isSurveyClosed}
                  sendSurveyRecipientEmail={onSendSurveyRecipientEmail}
                  sendingSurveyEmail={sendingSurveyEmail}
                />
              </TabPane>
            </Tabs>
          : <Loading />
        }
      </MainLayout>
    </Fragment>
  )
};

const mapStateToProps = ({
  groups_of_interest,
  survey_recipient,
  analysis_subjects,
  survey_subjects,
  survey_responses,
  survey
}) => ({
  survey: survey.item,
  sendingSurveyEmail: survey.sending_survey_email,
  groups: groups_of_interest.data,
  group: groups_of_interest.currentGroup,
  recipients: survey_recipient.data,
  subjects: analysis_subjects.data,
  surveySubjects: survey_subjects.data,
  responses: survey_responses.data,
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      requestGroupsOfInterest,
      addSurveyRecipient,
      updateSurveyRecipient,
      deleteSurveyRecipient,
      requestSurvey,
      requestAnalysisSubjects,
      saveSurveySubjects,
      requestSurveySubjects,
      sendSurveyEmail,
      requestSurveyResponses,
      changeCurrentGroup,
      createGroupOfInterest,
      deleteGroupOfInterest,
      editGroupOfInterest,
      createGoiMember,
      editGoiMember,
      deleteGoiMember,
      closeSurvey,
      sendSurveyRecipientEmail
    }
  )(SurveyBuilder)
);
