const TARGET = 'company';

const initialState = {
  error: null,
  data: [],
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || response.target !== TARGET) return state;
      const result = response.result;
      switch(response.method) {
        case 'GET':
          return {
            ...state,
            data: result,
          };
        case 'POST':
          return {
            ...state,
            data: [...state.data, result],
          };
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        error: action.code,
      };
    default:
      return state;
  }
};

export {
  reducer as company,
};
