import React, {
  useCallback,
  useState,
} from 'react';
import { injectIntl } from 'react-intl';
import {
  Button,
  DatePicker,
} from 'antd';
import './style.less';
import moment from 'moment';

const DEFAULT_POPUP = () => document.body;

const DateRangePicker = ({
  intl,
  value,
  picker='month',
  onChange,
  dropdownClassName = '',
  format="YYYY-MM",
  allowClear = false,
  suffixIcon,
  getPopupContainer = DEFAULT_POPUP
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const momentValues = (value || []).map(v => moment(v, format));
  
  const handleOnChange = useCallback((momentValues, stringValues) => onChange(stringValues), [onChange]);

  return (
    <DatePicker.RangePicker
      className="DateRangePicker"
      placeholder={[intl.formatMessage({id: 'start_month'}), intl.formatMessage({id: 'end_month'})]}
      format={format}
      open={datePickerOpen}
      onOpenChange={setDatePickerOpen}
      value={momentValues}
      picker={picker}
      onChange={handleOnChange}
      renderExtraFooter={() =>
        <Button
          onClick={() => {
            setDatePickerOpen(false);
            onChange([]);
          }}
          disabled={!value || value.length === 0}
          size="small"
        >{ intl.formatMessage({id: 'clear'}) }</Button>
      }
      dropdownClassName={dropdownClassName}
      allowClear={allowClear}
      suffixIcon={suffixIcon}
      getPopupContainer = {getPopupContainer}
    />
  );
}

export default injectIntl(DateRangePicker);
