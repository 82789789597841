import React, {useCallback} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  DatePicker,
} from 'antd';
import { formatDateMoment } from 'utils/date';

const FORM_KEY_RECURRENCY = 'recurrency';
const FORM_KEY_RECURRENCY_ONE_TIME = 'one_time';

const Calendar = ({
    title = '',
    disabled,
    handleChange,
    formName,
    value,
    recurrency,
}) => {

    const getDisabledDate = useCallback(
        (current) => current && current < moment().startOf('day'),
        []
    );

    const onChangeDate = useCallback((valString) => {
        if (!recurrency) {
            handleChange(FORM_KEY_RECURRENCY)(FORM_KEY_RECURRENCY_ONE_TIME);
        }
        handleChange(formName)(valString);
    }, [
        handleChange,
        formName,
        recurrency,
    ]);

    return (
        <div className="Calendar">
            <div className={`Calendar__label is-required ${disabled ? 'is-disabled' : ''}`}>{title}</div>
            <DatePicker
                className="Calendar__datePicker"
                allowClear
                placeholder={title}
                disabled={disabled}
                onChange={(_, valString) => onChangeDate(valString)}
                value={formatDateMoment(value)}
                disabledDate={getDisabledDate}
            />
        </div>
    )
};

Calendar.propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    formName: PropTypes.string.isRequired,
    value: PropTypes.string,
    recurrency: PropTypes.string,
};

export default Calendar;