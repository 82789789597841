import { loop, Cmd } from 'redux-loop';

const TARGET = 'equality_plan_document';
const TEMPLATE_TARGET = 'equality_plan_document_template';

const isTarget = (target) => [TARGET, TEMPLATE_TARGET].includes(target);
const isGeneralTarget = (target) => target === TARGET;

const initialState = {
  error: null,
  fetching: false,
  pushing: false,
  data: [],
  template: {
    processing: false,
    done: false,
    ready: false,
    error: null,
    endpoint: '',
    ticket: '',
    url: '',
  },
};

const waitForTemplate = (endpoint) => ({
  type: 'ACTIVE_WAIT_REQUEST',
  endpoint,
});


const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'ADD_EQUALITY_PLAN_DOCUMENT':
      return {
        ...state,
        data: [
          action.document,
          ...state.data,
        ]
      }
    case 'API_CALL_REQUEST':
      if (!isTarget(action.target)) return state;

      if(isGeneralTarget(action.target)) {
        return {
          ...state,
          fetching: action.method === 'GET',
          pushing: action.method === 'PUT' || action.method === 'POST',
        };
      }

      switch(action.target) {
        case TEMPLATE_TARGET:
          return {
            ...state,
            template: {...initialState.template}
          };
        default:
          return state;
      };
    case 'API_CALL_COMPLETE':
      if (!action.response || !isTarget(action.response.target)) return state;
      const response = action.response;

      if(isGeneralTarget(response.target)) {
        const result = response.result;
        switch(response.method) {
          case 'GET':
            if (result.url) {
              return {
                ...state,
                downloadURL: result.url,
                downloading: false
              }
            }
            return {
              ...state,
              fetching: false,
              data: result,
            };
          case 'POST':
            return {
              ...state,
              pushing: false,
              data: [result, ...state.data],
            };
          default:
            return state;
        }
      }
      
      switch(response.target) {
        case TEMPLATE_TARGET:
          const { ticket } = action.response.result || {};
          if (!ticket) return {
            ...state,
            pushing: false,
            template: initialState.template,
          }

          const questIndex = (action.response.endpoint || '').indexOf('?')
          const search = questIndex >= 0
            ? action.response.endpoint.slice( questIndex )
            : ''

         const base = questIndex >= 0
          ? action.response.endpoint.slice( 0, questIndex )
          : action.response.endpoint

          const endpoint = `${base}/status/${ticket}${search}`;
          return loop({
            ...state,
            pushing: false,
            template: {
              ...(action.response.result || initialState.template),
              processing: true,
              ready: false,
              error: null,
              endpoint,
            },
          }, Cmd.action(waitForTemplate(endpoint)));
        default:
          return state;
      }

    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        fetching: false,
        pushing: false,
        error: action.code,
      };
    case 'ACTIVE_WAIT_COMPLETE':
      if(!state.template.endpoint || state.template.endpoint !== action.endpoint) return state;
      return {
        ...state,
        template: {
          ...state.template,
          done: true,
          processing: false,
          error: null,
        }
      };
    case 'ACTIVE_WAIT_FAILED':
      if(!state.template.endpoint || state.template.endpoint !== action.endpoint) return state;
      return {
        ...state,
        template: {
          ...state.template,
          done: false,
          processing: false,
          error: 500,
        }
      };
    default:
      return state;
  }
};

export {
  reducer as equality_plan_document,
};
