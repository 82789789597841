import React, { useMemo, useCallback } from 'react'
import { withRouter } from 'react-router';
import { Tree } from 'antd';

import { useSelector } from 'react-redux';
import { sortBy } from 'lodash'
import { findTree } from 'utils/tree';
import { formatMessage } from '../../../intl';


import AplanetIcon from 'components/AplanetIcon';

import useOrganizations from 'utils/useOrganizations';
import { injectIntl } from 'react-intl';

const DashboardMenu = ({
  intl,
  match,
  history,
}) => {
  const t = intl.messages;
  const {
    suborganization,
    organizationTree
  } = useOrganizations();

  const {
    data: dashboardTypes,
    loading: isDashboardTypesLoading
  } = useSelector(state => state.dashboard_list);

    const enabledDashboards = useMemo(() => {

    if (isDashboardTypesLoading) {
      return [];
    }

    const sortedDashboardTypes = sortBy(dashboardTypes, (dashboard) => dashboard.name.toLowerCase());
    return sortedDashboardTypes.map(({ organization_id, slug, name, grid_layout }) => {

      const dashboard_org = findTree([organizationTree], (node) => node.id === organization_id)
      return {
        slug,
        name: (
          <div style={{display: 'flex', width: "100%", gap: '5px'}}>
            <span style={{textOverflow: 'ellipsis', flexShrink: '2', overflow: 'hidden'}}>{name || t[`reporting_standard_${slug}`] || t.reporting_standard_custom}</span>
            {organization_id === suborganization.id ? null : (
              <AplanetIcon
                name="Refresh"
                size="15px"
                title={formatMessage('dashboard_shared_by', { id: dashboard_org.name })}
              />
            )}
          </div>
        ),
        grid_layout,
      };
    });
  }, [dashboardTypes, organizationTree, suborganization.id, t, isDashboardTypesLoading]);

    const treeData = useMemo(() => {
    return enabledDashboards.map(item => ({
      title: item.name,
      key: item.slug,
    }))
  }, [enabledDashboards]);

    const setActiveStandardTab = useCallback((tab) => {
    history.push(`/dashboard/${tab}`);
  }, [history]);

  return (
    <Tree defaultExpandAll treeData={treeData} onSelect={setActiveStandardTab} selectedKeys={[match.params.activeTab]} />
  )
}

export default injectIntl(withRouter(DashboardMenu))