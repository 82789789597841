import React, { useMemo } from 'react';

import './style.less';

import {
  Avatar as AntdAvatar,
} from 'antd';

const SATURATION = 30;
const LIGHTNESS = 80;
const MAX_WORDS = 2;
const DEFAULT_REGION = 'ES';

const Avatar = ({
  src,
  name,
  icon,
  size,
  region = DEFAULT_REGION,
  className,
  ...props
}) => {
  const backgroundColor = useMemo(() => {
    if(src || !name) {
      return null;
    }
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    var hue = hash % 360;
    // See https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
    return `hsl(${ hue }, ${ SATURATION }%, ${ LIGHTNESS }%)`;
  }, [ src, name ]);

  const initials = useMemo(() => {
    if(src || !name) {
      return null;
    }

    const words = name.split(' ').filter(str => str.length > 0);

    switch(region) {
      case 'PT':
      case 'BR':
        // For reasons passing human understanding we support different initials when the
        // names correspond to Portugal or Brazil. See, in those countries we need to use
        // the FIRST and LAST words instead of the first two...
        if(words.length === 1) {
          return words[0][0].toUpperCase();
        }
        // This assumes MAX_WORDS = 2, because what should we do otherwise?
        return [
          words[0],
          words.slice(-1)[0],
        ].map(word => word[0].toUpperCase()).join('');
      default:
        return words.slice(0, MAX_WORDS).map(word => word[0].toUpperCase()).join('');
    }
  }, [ src, name, region ]);

  const classNames = useMemo(() => {
    return `${className} AplanetAvatar ${ src ? 'AplanetAvatar-img' : name ? 'AplanetAvatar-initials' : 'AplanetAvatar-icon' }`;
  }, [ src, name, className ]);

  const fontSize = useMemo(() => {
    return size && typeof size === 'number' ? Math.ceil(size / 2) : 'inherit';
  }, [ size ]);

  return (
    src
    ? <AntdAvatar src={src} size={size} className={classNames} {...props} />
    : name
    ? <AntdAvatar style={{ backgroundColor, fontSize }} size={size} className={classNames} {...props}>{ initials }</AntdAvatar>
    : <AntdAvatar icon={icon} size={size} className={classNames} {...props} />
  );
}

export default Avatar;
