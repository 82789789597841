export const kebabToCamel = (str = '') => {
  const arr = str.split('-');
  const capital = arr.map(
    (item, index) => {
      if(index > 0) {
        return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
      }
      return item.toLowerCase();
    }
  );
  return capital.join('');
};

export const camelToKebab = (str = '') => {
  return str.split(/(?=[A-Z])/).join('-').toLowerCase();
};

