import React, {useMemo} from 'react';

import SdgMiniLogo from 'components/SdgMiniLogo';
import { useSDGsClustering } from 'hooks/sdg';


const DataSourcesSdgs = ({
  kpi,
}) => {
  const clusterSDGs = useSDGsClustering();

  const sdgs = useMemo(
    () => kpi.sdgs,
    [kpi]
  );

  const clusteredSDGs = useMemo(() => {
    return clusterSDGs(sdgs).sort((a, b) => a.code - b.code);
  }, [sdgs, clusterSDGs]);
  return (
    <>
      { clusteredSDGs.map(sdg => <SdgMiniLogo key={sdg.slug} sdg={sdg}/> )}
    </>
  );
}

export default DataSourcesSdgs;