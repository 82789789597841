const TARGET = 'analysis_result';

const initialState = {
  error: null,
  fetching: false,
  data: null,
  activity: null,
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case 'API_CALL_REQUEST':
      if (action.target !== TARGET) return state;
      return {
        ...state,
        fetching: action.method === 'GET',
      };
    case 'API_CALL_COMPLETE':
      const { response } = action;
      if(!response || response.target !== TARGET) return state;
      const result = response.result;
      switch(response.method) {
        case 'GET':
        case 'PUT':
          return {
            ...state,
            data: result.result,
            activity: result.activity,
            fetching: false,
          };
        default:
          return state;
      }
    case 'API_CALL_FAILED':
      if(!action.request || action.request.target !== TARGET) return state;
      return {
        ...state,
        error: action.code,
        fetching: false,
      };
    default:
      return state;
  }
};

export {
  reducer as analysis_result,
};
